import {
	createCloudAlbum,
	createCloudRecording,
	deleteCloudAlbumById,
	deleteCloudRecordingById,
	getCloudAlbumById,
	getCloudRecordingById,
	getUserProjects,
	updateCloudAlbum,
	updateCloudRecording,
	addRecordingsToCloudAlbum,
	removeRecordingFromCloudAlbum,
	reorderCloudAlbumRecordings,
	getProjectUsage,
	getUserEditableProjects,
	searchUserProjects,
} from '../../api/services/projectsService';
import translateAlbumFormToApi from '../../helpers/translateAlbumFormToApi';
import translateApiAlbumToLocal from '../../helpers/translateApiAlbumToLocal';
import {
	UPDATE_CURRENT_RECORDING_REQUEST,
	FETCH_RECORDING_REQUEST,
	CREATE_NEW_RECORDING_REQUEST,
	FETCH_USER_PROJECTS_REQUEST,
	FETCH_ALBUM_REQUEST,
	CREATE_NEW_ALBUM_REQUEST,
	UPDATE_ALBUM_REQUEST,
	ADD_RECORDINGS_TO_ALBUM_REQUEST,
	DELETE_ALBUM_REQUEST,
	DELETE_RECORDING_REQUEST,
	REMOVE_RECORDING_FROM_ALBUM_REQUEST,
	REORDER_ALBUM_RECORDINGS_REQUEST,
	FETCH_RECORDING_EDITORS_REQUEST,
	FETCH_ALBUM_EDITORS_REQUEST,
	GET_PROJECT_USAGE_REQUEST,
	CHANGE_RECORDING_OWNER_REQUEST,
	CHANGE_ALBUM_OWNER_REQUEST,
	FETCH_USER_EDITABLE_PROJECTS_REQUEST,
	SEARCH_USER_PROJECTS_REQUEST,
} from '../../constants/requestLabelTypes';
import {
	SET_USER_PROJECTS,
	UPDATE_LOCAL_RECORDING,
	SET_PROJECTS_SEARCH_FILTER,
	CLOUD_PROJECTS_REQUEST_AUTH_ERROR,
	CLOUD_PROJECTS_REQUEST_ERROR,
	CLOUD_PROJECTS_REQUEST_SUCCESS,
	START_CLOUD_PROJECTS_REQUEST,
	SET_CURRENT_RECORDING_ID,
	ADD_LOCAL_RECORDING,
	SET_CURRENT_ALBUM_ID,
	ADD_LOCAL_RECORDINGS_TO_ALBUM,
	ADD_LOCAL_ALBUM,
	UPDATE_LOCAL_ALBUM,
	DELETE_LOCAL_ALBUM_BY_ID,
	DELETE_LOCAL_RECORDING_BY_ID,
	REORDER_LOCAL_ALBUM_RECORDINGS,
	CLEAR_SELECTED_PROJECT,
	ADD_EXPORTS_TO_LOCAL_RECORDING,
	ADD_EXPORTS_TO_LOCAL_ALBUM,
	DELETE_EXPORT_FROM_LOCAL_RECORDING,
	DELETE_EXPORT_FROM_LOCAL_ALBUM,
	REMOVE_RECORDING_FROM_LOCAL_ALBUM,
	SAVE_EDITOR_PROFILE,
	CLEAR_PROJECTS,
	DECREMENT_LOCAL_ALBUM_ASSETS_COUNT,
	DECREMENT_LOCAL_RECORDING_ASSETS_COUNT,
	INCREMENT_LOCAL_ALBUM_ASSETS_COUNT,
	INCREMENT_LOCAL_RECORDING_ASSETS_COUNT,
	SET_EXPORTS_TO_LOCAL_ALBUM,
	SET_EXPORTS_TO_LOCAL_RECORDING,
	SET_LOCAL_ALBUM_ASSETS_COUNT,
	SET_LOCAL_RECORDING_ASSETS_COUNT,
	SET_ALBUM_ARCHIVED,
	SET_PROJECT_LIMIT,
	SET_PROJECT_USAGE,
	SET_USER_EDITABLE_PROJECTS,
	SET_SEARCHED_ALBUMS,
	SET_SEARCHED_RECORDINGS,
	SET_EDITOR_PROFILE_ACTIVE,
} from '../actionTypes';
import { showAlertToast, showErrorAlert } from '../alertToast/actions';
import { invalidTokenAction } from '../auth/actions';
import { importLocalProfilesIntoRecordingAction } from '../profiles/actions';
import {
	getAlbumRecordingIds,
	removeUnauthorizedRecordings,
} from '../../helpers/albumTools';
import emptyRecording from '../../constants/recording.json';
import { flattenPublishers } from '../../helpers/publisherTools';
import { fetchProjectExportsAction } from '../exports/actions';
import { getExportsByProjectId } from '../exports/selectors';
import { getFilesByProjectId } from '../files/selectors';
import {
	getProjectFilesMetadataAction,
	getStorageUsageAction,
} from '../files/actions';
import {
	changeAlbumOwner,
	changeRecordingOwner,
	claimActiveEditor,
	getAlbumEditors,
	getRecordingEditors,
} from '../../api/services/editorService';
import {
	createNewProfileFromParticipant,
	createProfileFromFullProfile,
} from '../../helpers/profileTools';
import { showModalAction } from '../modal/actions';
import {
	CHANGE_OWNER_LIMIT_MODAL,
	VALIDATE_GRID_MODAL,
} from '../../constants/modalTypes';
import { AppDispatch, GetState } from '..';
import { v4 } from 'uuid';
import { FormikProps } from 'formik';
import { generateGRID } from '../../api/services/codesService';

export const handleCloudProjectsError = (
	error: any,
	dispatch: AppDispatch,
	message?: string
) => {
	if (error.response) {
		let errorMessage =
			message ||
			'Hiccup detected:  The cloud needs a coffee break.  Give it another go!';

		switch (error.response.status) {
			case 401:
				dispatch(cloudProjectsRequestAuthErrorAction());
				break;
			case 402:
				dispatch(getProjectUsageAction()); // update project usage in case front-end is out of sync
				errorMessage =
					message ||
					"Your current subscription doesn't allow this action. Upgrade your subscription to continue!";
			// intentional fallthrough
			case 403:
				errorMessage =
					message || 'You do not have permission to perform this action.';
			// intentional fallthrough
			default:
				dispatch(showErrorAlert(`${errorMessage} (${error.response.status})`));
				dispatch(cloudProjectsRequestErrorAction(errorMessage));
		}
	} else {
		throw error;
	}
};

export const clearProjectsAction = () => ({
	type: CLEAR_PROJECTS,
});

export const removeRecordingFromLocalAlbumAction = (recordingId: number) => ({
	type: REMOVE_RECORDING_FROM_LOCAL_ALBUM,
	recordingId,
});

export const fetchMultipleCloudProjectsAction =
	(recordingIds: number[], onFetched: (recordings: Recording[]) => any) =>
	(dispatch: AppDispatch, getState: GetState) => {
		const recPromises = recordingIds.map(recordingId => {
			return dispatch(fetchRecordingByIdAction({ id: recordingId }));
		});

		return Promise.all([...recPromises])
			.then(() => {
				console.log(getState().projects.recordingsById);
				if (onFetched) {
					const recordings = recordingIds
						.map(
							recordingId => getState().projects?.recordingsById?.[recordingId]
						)
						.filter((recording): recording is Recording => !!recording);

					onFetched(recordings);
				}
			})
			.catch(error => handleCloudProjectsError(error, dispatch));
	};

export const addExportsToLocalRecordingAction = (
	recordingId: number,
	exports: number[]
) => ({
	type: ADD_EXPORTS_TO_LOCAL_RECORDING,
	recordingId,
	exports,
});

export const addExportsToLocalAlbumAction = (
	albumId: number,
	exports: number[]
) => ({
	type: ADD_EXPORTS_TO_LOCAL_ALBUM,
	albumId,
	exports,
});

export const deleteExportFromLocalRecordingAction = (
	recordingId: number,
	exportId: number
) => ({
	type: DELETE_EXPORT_FROM_LOCAL_RECORDING,
	recordingId,
	exportId,
});

export const deleteExportFromLocalAlbumAction = (
	albumId: number,
	exportId: number
) => ({
	type: DELETE_EXPORT_FROM_LOCAL_ALBUM,
	albumId,
	exportId,
});

export const clearSelectedProjectAction = () => ({
	type: CLEAR_SELECTED_PROJECT,
});

export const reorderLocalAlbumRecordingsAction = (
	albumId: number,
	newRecordingOrder: Record<number, number>
) => ({
	type: REORDER_LOCAL_ALBUM_RECORDINGS,
	albumId,
	newRecordingOrder,
});

export const reorderCloudAlbumRecordingsAction =
	(albumId: number, newRecordingOrder: Record<number, number>) =>
	(dispatch: AppDispatch, getState: GetState) => {
		dispatch(startCloudProjectsRequestAction(REORDER_ALBUM_RECORDINGS_REQUEST));

		const album = getState().projects?.albumsById?.[albumId];
		console.log('REORDERING ALBUM', album, albumId);

		return reorderCloudAlbumRecordings(albumId, newRecordingOrder)
			.then(_ => dispatch(cloudProjectsRequestSuccessAction()))
			.catch(error => handleCloudProjectsError(error, dispatch));
	};

export const deleteLocalAlbumByIdAction = (
	albumId: number,
	cascadeDelete = false
) => ({
	type: DELETE_LOCAL_ALBUM_BY_ID,
	albumId,
	cascadeDelete,
});

export const deleteCloudAlbumByIdAction =
	(albumId: number) => (dispatch: AppDispatch) => {
		dispatch(startCloudProjectsRequestAction(DELETE_ALBUM_REQUEST));

		return deleteCloudAlbumById(albumId)
			.then(_ => {
				dispatch(cloudProjectsRequestSuccessAction());
				dispatch(getStorageUsageAction());
			})
			.catch(error => {
				handleCloudProjectsError(error, dispatch);
			});
	};

export const addRecordingsToCloudAlbumAction =
	(albumId: number, recordings: number[], onAdd?: () => Promise<any> | void) =>
	(dispatch: AppDispatch) => {
		dispatch(startCloudProjectsRequestAction(ADD_RECORDINGS_TO_ALBUM_REQUEST));

		// local recordings might have already been updated
		// thus we remove duplicates from the list to avoid race conditions
		return addRecordingsToCloudAlbum(albumId, [...new Set(recordings)])
			.then(async _ => {
				dispatch(cloudProjectsRequestSuccessAction());

				dispatch(addLocalRecordingsToAlbumAction(albumId, recordings));

				if (onAdd) {
					await onAdd();
				}
			})
			.catch(error => handleCloudProjectsError(error, dispatch));
	};

export const swapRecordingBetweenCloudAlbumsAction =
	(recordingId: number, destinationAlbumId: number) =>
	(dispatch: AppDispatch) => {
		dispatch(
			removeRecordingFromCloudAlbumAction(recordingId, () => {
				dispatch(
					addRecordingsToCloudAlbumAction(destinationAlbumId, [recordingId])
				);
			})
		);
	};

export const removeRecordingFromCloudAlbumAction =
	(recordingId: number, onRemove: () => any) =>
	(dispatch: AppDispatch, getState: GetState) => {
		dispatch(
			startCloudProjectsRequestAction(REMOVE_RECORDING_FROM_ALBUM_REQUEST)
		);

		const recording = getState().projects?.recordingsById?.[recordingId];

		if (!recording || !recording.albumId) {
			return;
		}

		const albumId = recording.albumId;

		removeRecordingFromCloudAlbum(albumId, recordingId)
			.then(() => {
				dispatch(removeRecordingFromLocalAlbumAction(recordingId));
				dispatch(cloudProjectsRequestSuccessAction());

				if (onRemove) {
					onRemove();
				}
			})
			.catch(error => handleCloudProjectsError(error, dispatch));
	};

export const updateLocalAlbumAction = (album: AlbumContent) => ({
	type: UPDATE_LOCAL_ALBUM,
	album,
});

export const addLocalRecordingsToAlbumAction = (
	albumId: number,
	recordings: number[]
) => ({
	type: ADD_LOCAL_RECORDINGS_TO_ALBUM,
	albumId,
	recordings,
});

export const addLocalAlbumAction = (album: Album) => ({
	type: ADD_LOCAL_ALBUM,
	album,
});

export const fetchAlbumByIdAction =
	(
		albumId: number,
		onFetch?: ((album: Album) => any) | ((album: Album) => Promise<any>)
	) =>
	(dispatch: AppDispatch, getState: GetState) => {
		dispatch(startCloudProjectsRequestAction(FETCH_ALBUM_REQUEST));

		const albumOwner = getState().projects?.albumsById?.[albumId]?.ownerName;

		return getCloudAlbumById(albumId)
			.then(response => {
				const album = removeUnauthorizedRecordings(
					response.data.album,
					getState().projects.recordingsById!
				);

				dispatch(
					addLocalAlbumAction(translateApiAlbumToLocal(album, albumOwner))
				);
				dispatch(cloudProjectsRequestSuccessAction());

				if (onFetch) {
					const album = getState().projects?.albumsById?.[albumId];

					if (!album) {
						return;
					}

					return onFetch(album);
				}
			})
			.catch(error => handleCloudProjectsError(error, dispatch));
	};

export const createCloudAlbumAction =
	(album: AlbumContent, onCreate: (albumId: number) => Promise<any> | void) =>
	(dispatch: AppDispatch) => {
		dispatch(startCloudProjectsRequestAction(CREATE_NEW_ALBUM_REQUEST));

		return createCloudAlbum(album)
			.then(async response => {
				console.log('CREATED ALBUM', response.data);
				const id = response.data.album_id;
				const newAlbum = {
					id,
					title: album.title,
					artist: album.artistName,
					recordings: {},
					album: { ...album, id },
					isSingle: !!album.isSingle,
				} as Album;
				dispatch(addLocalAlbumAction(newAlbum));
				dispatch(cloudProjectsRequestSuccessAction());

				dispatch(
					updateCloudAlbumAction(newAlbum.album as AlbumContent, () =>
						dispatch(fetchAlbumByIdAction(id))
					)
				);

				if (onCreate) {
					await onCreate(id);
				}
			})
			.catch(error => {
				if (error?.response?.status === 402) {
					throw error;
				}
				handleCloudProjectsError(error, dispatch);
			});
	};

export const updateCloudAlbumAction =
	(album: AlbumContent, onUpdate?: () => any) => (dispatch: AppDispatch) => {
		dispatch(startCloudProjectsRequestAction(UPDATE_ALBUM_REQUEST));
		dispatch(updateLocalAlbumAction(album));

		return updateCloudAlbum(translateAlbumFormToApi(album))
			.then(_ => {
				dispatch(cloudProjectsRequestSuccessAction());

				if (onUpdate) {
					onUpdate();
				}
			})
			.catch(error => handleCloudProjectsError(error, dispatch));
	};

export const setProjectsSearchFilter = (filter: string | null) => ({
	type: SET_PROJECTS_SEARCH_FILTER,
	payload: {
		filter,
	},
});

export const deleteCloudRecordingByIdAction =
	(id: number) => (dispatch: AppDispatch) => {
		dispatch(startCloudProjectsRequestAction(DELETE_RECORDING_REQUEST));

		return deleteCloudRecordingById(id)
			.then(_ => {
				dispatch(cloudProjectsRequestSuccessAction());
				dispatch(getStorageUsageAction());
			})
			.catch(error => {
				handleCloudProjectsError(error, dispatch);
			});
	};

export const deleteLocalRecordingByIdAction = (id: number) => ({
	type: DELETE_LOCAL_RECORDING_BY_ID,
	id,
});

export const updateLocalRecordingAction = ({
	recordingForm = null,
	fullRecording = null,
}: {
	recordingForm?: RecordingContent | null;
	fullRecording?: Recording | null;
}) => ({
	type: UPDATE_LOCAL_RECORDING,
	recordingForm,
	fullRecording,
});

export const setUserProjectsAction = (recordings: any[], albums: any[]) => ({
	type: SET_USER_PROJECTS,
	recordings,
	albums,
});

export const getUserProjectsAction = () => (dispatch: AppDispatch) => {
	dispatch(startCloudProjectsRequestAction(FETCH_USER_PROJECTS_REQUEST));

	return getUserProjects()
		.then(response => {
			const { recordings, albums } = response.data;
			dispatch(setUserProjectsAction(recordings, albums));
			dispatch(cloudProjectsRequestSuccessAction());

			return Promise.all([
				dispatch(fetchUserEditableProjectsAction()),
				dispatch(getProjectUsageAction()),
			]);
		})
		.catch(error => handleCloudProjectsError(error, dispatch));
};

export const setCurrentAlbumIdAction = (
	albumId: number,
	recordingId: number
) => ({
	type: SET_CURRENT_ALBUM_ID,
	albumId,
	recordingId,
});

export const setCurrentRecordingIdAction = (id: number) => ({
	type: SET_CURRENT_RECORDING_ID,
	id,
});

export const updateCloudRecordingAction =
	({
		recordingForm,
		fullRecording = null,
		onCreate = null,
	}: {
		recordingForm: RecordingContent;
		fullRecording?: Recording | null;
		onCreate?: (() => Promise<any>) | null;
	}) =>
	(dispatch: AppDispatch, getState: GetState) => {
		dispatch(startCloudProjectsRequestAction(UPDATE_CURRENT_RECORDING_REQUEST));

		dispatch(updateLocalRecordingAction({ recordingForm, fullRecording }));

		let recording = fullRecording;

		if (!recordingForm.id) {
			return Promise.reject('No recording id found');
		}

		if (!recording) {
			recording =
				getState().projects.recordingsById?.[recordingForm.id] || null;

			if (!recording) {
				return Promise.reject('No recording found');
			}
		}

		return updateCloudRecording(recording, true, recordingForm)
			.then(response => {
				dispatch(cloudProjectsRequestSuccessAction());
				console.log('UPDATE RESPONSE', response);

				if (onCreate) {
					return Promise.resolve(onCreate());
				}
			})
			.catch(error => handleCloudProjectsError(error, dispatch));
	};

export const fetchRecordingByIdAction =
	({
		id,
		onFetch,
		forceIsArchived = null,
	}: {
		id: number;
		onFetch?:
			| ((recording?: Recording) => any)
			| ((recording?: Recording) => Promise<any>);
		forceIsArchived?: boolean | null;
	}) =>
	(dispatch: AppDispatch, getState: GetState) => {
		dispatch(startCloudProjectsRequestAction(FETCH_RECORDING_REQUEST));

		const recordingOwnerName =
			getState().projects.recordingsById?.[id]?.ownerName;

		return getCloudRecordingById(id)
			.then(recording => {
				console.log('FETCHED RECORDING', recording);

				if (forceIsArchived !== null) {
					recording.isArchived = forceIsArchived;
				}

				dispatch(
					addLocalRecordingAction({
						...recording,
						ownerName: recordingOwnerName ?? null,
					})
				);
				dispatch(cloudProjectsRequestSuccessAction());

				if (onFetch) {
					return onFetch(recording);
				}
			})
			.catch(error => handleCloudProjectsError(error, dispatch));
	};

export const startCloudProjectsRequestAction = (
	requestLabel: string | null
) => ({
	type: START_CLOUD_PROJECTS_REQUEST,
	requestLabel,
});

export const cloudProjectsRequestSuccessAction = () => ({
	type: CLOUD_PROJECTS_REQUEST_SUCCESS,
});

export const cloudProjectsRequestErrorAction = (
	errorMessage: string | null,
	errorCode?: number | null
) => ({
	type: CLOUD_PROJECTS_REQUEST_ERROR,
	errorMessage,
	errorCode,
});

export const cloudProjectsRequestAuthErrorAction =
	() => (dispatch: AppDispatch) => {
		dispatch(invalidTokenAction());

		return {
			type: CLOUD_PROJECTS_REQUEST_AUTH_ERROR,
		};
	};

export const addLocalRecordingAction = (recording: Recording) => ({
	type: ADD_LOCAL_RECORDING,
	recording,
});

export const createCloudRecordingAction =
	(
		recording: RecordingContent,
		onCreate?: ((recId: number) => Promise<any> | any) | null,
		albumId: number | null = null
	) =>
	(dispatch: AppDispatch, getState: GetState) => {
		dispatch(startCloudProjectsRequestAction(CREATE_NEW_RECORDING_REQUEST));

		return createCloudRecording({ ...recording, albumId }, albumId)
			.then(response => {
				const username = getState().user.userProfile?.username;

				const id = response.data.recording_id;
				const newRecording = {
					id,
					title: recording.title,
					artist: recording.mainArtist,
					ownerName: username,
					recording: {
						...recording,
						id,
					},
				} as Recording;
				console.log(newRecording);

				dispatch(addLocalRecordingAction(newRecording));

				if (albumId) {
					dispatch(addLocalRecordingsToAlbumAction(albumId, [id]));
				}

				dispatch(cloudProjectsRequestSuccessAction());

				return Promise.resolve(
					dispatch(
						updateCloudRecordingAction({
							recordingForm: newRecording.recording as RecordingContent,
							onCreate: async () => {
								dispatch(fetchRecordingByIdAction({ id }));

								if (onCreate) {
									await onCreate(id);
								}
							},
						})
					)
				);
			})
			.catch(error => {
				let errorMessage = '';

				if (error.response.status === 402) {
					throw error;
					// errorMessage =
					// 	'You have reached your limit of recordings. Upgrade your subscription to create more.';
				}

				handleCloudProjectsError(error, dispatch, errorMessage);
			});
	};

export const deleteParticipantAction =
	({ id }: { id: number }) =>
	(dispatch: AppDispatch, getState: GetState) => {
		const currentRecordingId = getState().projects.currentRecordingId;

		if (!currentRecordingId) {
			console.error('No current recording set');
			return;
		}

		const currentRecording =
			getState().projects.recordingsById?.[currentRecordingId];

		if (!currentRecording || !currentRecording.recording) {
			console.error('No current recording found');
			return;
		}

		const newRecording = {
			...currentRecording,
			recording: {
				...currentRecording.recording,
				participants: currentRecording.recording.participants.filter(
					participant => participant.id !== id
				),
			},
		};

		const newParticipants = newRecording.recording.participants;

		// recalculate publishers in case one of them's no longer referenced after deleting this participant
		const newPublisherIds = [
			...new Set(
				flattenPublishers(
					newParticipants.flatMap(participant => participant.publishers)
				).map(publisher => publisher.publisherId)
			),
		];

		newRecording.recording.publishers =
			newRecording.recording.publishers.filter(publisher =>
				newPublisherIds.includes(publisher.id)
			);

		// recalculate studios too
		const newStudioIds = [
			...new Set(
				newParticipants
					.flatMap(p => p.roles)
					.filter(r => r.studio)
					.map(role => role.studio!.studioId)
			),
		];

		newRecording.recording.studios = newRecording.recording.studios.filter(
			studio => newStudioIds.includes(studio.id)
		);

		if (!newParticipants.length && !id) {
			console.log('newParticipants'.toUpperCase(), newParticipants);
			console.log(
				'current recording participants'.toUpperCase(),
				currentRecording.recording.participants
			);
			dispatch(
				showErrorAlert('Hiccup detected while trying to delete a participant')
			);

			return;
		}

		return dispatch(
			updateCloudRecordingAction({ recordingForm: newRecording.recording })
		);
	};

export const fetchMyRecordingEditorProfileAction =
	(recordingId: number) => (dispatch: AppDispatch, getState: GetState) => {
		dispatch(startCloudProjectsRequestAction(FETCH_RECORDING_EDITORS_REQUEST));

		return getRecordingEditors(recordingId)
			.then(res => {
				const userId = getState().auth.userId;
				const editors = res.data.editors;

				const myEditorProfile = editors.find(
					(editor: ApiRecordingEditor) => editor.user_id === userId
				);

				if (!myEditorProfile) {
					throw new Error('No editor profile found');
				}

				dispatch(cloudProjectsRequestSuccessAction());
				return dispatch(saveEditorProfile(myEditorProfile));
			})
			.catch(error =>
				handleCloudProjectsError(
					error,
					dispatch,
					'Whoops! Hiccup detected while retrieving the recording editors.'
				)
			);
	};

export const fetchMyAlbumEditorProfileAction =
	(albumId: number) => (dispatch: AppDispatch, getState: GetState) => {
		dispatch(startCloudProjectsRequestAction(FETCH_ALBUM_EDITORS_REQUEST));

		return getAlbumEditors(albumId)
			.then(res => {
				const userId = getState().auth.userId;
				const editors = res.data.editors;

				const myEditorProfile = editors.find(
					editor => editor.user_id === userId
				);

				if (!myEditorProfile) {
					throw new Error('No editor profile found');
				}

				dispatch(saveEditorProfile(myEditorProfile));

				dispatch(cloudProjectsRequestSuccessAction());
			})
			.catch(error =>
				handleCloudProjectsError(
					error,
					dispatch,
					'Whoops! Hiccup detected while retrieving the album editors.'
				)
			);
	};

export const setLocalRecordingExportsAction = (
	recordingId: number,
	exports: number[]
) => ({
	type: SET_EXPORTS_TO_LOCAL_RECORDING,
	recordingId,
	exports,
});

export const setLocalAlbumExportsAction = (
	albumId: number,
	exports: number[]
) => ({
	type: SET_EXPORTS_TO_LOCAL_ALBUM,
	albumId,
	exports,
});

export const incrementLocalAlbumAssetsCountAction = (
	albumId: number,
	increment: number
) => ({
	type: INCREMENT_LOCAL_ALBUM_ASSETS_COUNT,
	albumId,
	increment,
});

export const decrementLocalAlbumAssetsCountAction = (
	albumId: number,
	decrement: number
) => ({
	type: DECREMENT_LOCAL_ALBUM_ASSETS_COUNT,
	albumId,
	decrement,
});

export const setLocalAlbumAssetsCountAction = (
	albumId: number,
	assetsCount: number
) => ({
	type: SET_LOCAL_ALBUM_ASSETS_COUNT,
	albumId,
	assetsCount,
});

export const incrementLocalRecordingAssetsCountAction = (
	recordingId: number,
	increment: number
) => ({
	type: INCREMENT_LOCAL_RECORDING_ASSETS_COUNT,
	recordingId,
	increment,
});

export const decrementLocalRecordingAssetsCountAction = (
	recordingId: number,
	decrement: number
) => ({
	type: DECREMENT_LOCAL_RECORDING_ASSETS_COUNT,
	recordingId,
	decrement,
});

export const setLocalRecordingAssetsCountAction = (
	recordingId: number,
	assetsCount: number
) => ({
	type: SET_LOCAL_RECORDING_ASSETS_COUNT,
	recordingId,
	assetsCount,
});

export const fetchProjectUploadsAndExportsAction =
	({
		albumId,
		recordingId,
	}: {
		albumId?: number | null;
		recordingId?: number | null;
	}) =>
	async (dispatch: AppDispatch, getState: GetState) => {
		await dispatch(fetchProjectExportsAction({ albumId, recordingId }));
		const exports = getExportsByProjectId(getState(), { albumId, recordingId });

		await dispatch(getProjectFilesMetadataAction({ albumId, recordingId }));
		const uploads = Object.values(
			getFilesByProjectId(getState(), { albumId, recordingId })
		);

		const assetsCount = uploads.length + (exports?.length ?? 0);

		if (recordingId) {
			dispatch(setLocalRecordingAssetsCountAction(recordingId, assetsCount));
		} else if (albumId) {
			dispatch(setLocalAlbumAssetsCountAction(albumId, assetsCount));
		}
	};

export const saveEditorProfile = <EditorType extends ApiProjectEditor>(
	myEditorProfile: EditorType | null
) => ({
	type: SAVE_EDITOR_PROFILE,
	myEditorProfile,
});

export const setEditorProfileActive = (isActive: boolean) => ({
	type: SET_EDITOR_PROFILE_ACTIVE,
	isActive,
});

export const setAlbumArchivedAction = (
	albumId: number,
	isArchived: boolean
) => ({
	type: SET_ALBUM_ARCHIVED,
	albumId,
	isArchived,
});

export const setRecordingArchivedAction =
	(recordingId: number, isArchived: boolean) =>
	async (dispatch: AppDispatch, getState: GetState) => {
		let recording = getState().projects.recordingsById?.[recordingId];

		if (!recording) {
			throw new Error('Recording not found');
		}

		// TODO: check for permissions
		dispatch(
			updateLocalRecordingAction({
				fullRecording: { ...recording, isArchived } as Recording,
			})
		);

		try {
			const userId = getState().auth.userId;

			await claimActiveEditor(userId!, recordingId);

			if (!recording.recording) {
				await Promise.resolve(
					dispatch(
						fetchRecordingByIdAction({
							id: recordingId,
							forceIsArchived: isArchived,
						})
					)
				);
			}
		} catch (error) {
			console.log('error', error);

			dispatch(
				showErrorAlert('Whoops! Hiccup detected while moving the recording.')
			);

			return;
		}

		// refresh recording after fetch
		recording = getState().projects.recordingsById?.[recordingId];
		if (!recording) {
			throw new Error('Recording not found');
		}

		dispatch(
			updateCloudRecordingAction({
				recordingForm: recording.recording as RecordingContent,
				fullRecording: { ...recording, isArchived },
			})
		);
	};

export const createCloudAlbumWithRecordingsAction =
	({
		album,
		onCreate,
		newRecordingTitles,
		addRecordingIdsToAlbum,
	}: {
		album: AlbumContent;
		onCreate?: (albumId: number) => Promise<any> | void;
		newRecordingTitles?: string[];
		addRecordingIdsToAlbum?: number[];
	}) =>
	(dispatch: AppDispatch) => {
		dispatch(startCloudProjectsRequestAction(CREATE_NEW_ALBUM_REQUEST));

		/**
		 * SE CREA EL ALBUM PRIMERO, SIN RECORDINGS.
		 * LUEGO, POR CADA RECORDING QUE SE VA CREANDO, LLAMA A:
		 * 		* dispatch(addRecordingsToCloudAlbumAction(newAlbumId, [recordingId]))
		 * PARA QUE SE AGREGUEN AL ALBUM.
		 */

		const afterCreate = async (albumId: number) => {
			if (newRecordingTitles) {
				for (const recordingName of newRecordingTitles) {
					const recordingForm = {
						...emptyRecording,
						title: recordingName,
						artist: album.artistName,
						mainArtist: album.artistName,
					} as RecordingContent;

					await Promise.resolve(
						dispatch(createCloudRecordingAction(recordingForm, null, albumId))
					);
				}
			}

			if (addRecordingIdsToAlbum) {
				await dispatch(
					addRecordingsToCloudAlbumAction(albumId, addRecordingIdsToAlbum)
				);
			}

			if (onCreate) {
				await onCreate(albumId);
			}
		};

		return dispatch(createCloudAlbumAction(album, afterCreate));
	};

export const copyCreditsToOtherRecordingsAction =
	({
		fromRecordingId,
		toRecordingIds,
		participantIds,
	}: {
		fromRecordingId: number;
		toRecordingIds: number[];
		participantIds: number[];
	}) =>
	async (dispatch: AppDispatch, getState: GetState) => {
		try {
			console.log(toRecordingIds, 'toRecordingIds');
			// console.log(JSON.stringiafy(toRecordingIds), 'toRecordingIds');

			// claim active editor for all destination recordings
			await Promise.all(
				toRecordingIds.map(async recId => {
					await claimActiveEditor(getState().auth.userId!, recId);
				})
			);

			// fetch all recordings, we need the latest version
			// in case they were edited in the meantime
			await Promise.all(
				[fromRecordingId, ...toRecordingIds].map(async recId => {
					await Promise.resolve(
						dispatch(fetchRecordingByIdAction({ id: recId }))
					);

					// we need permissions to edit the recording
					await claimActiveEditor(getState().auth.userId!, recId);
				})
			);

			const fromRecording =
				getState().projects.recordingsById?.[fromRecordingId];

			if (!fromRecording?.recording) {
				throw new Error('Recording was not found or is not loaded');
			}

			const selectedParticipants = participantIds.map(participantId =>
				fromRecording?.recording?.participants.find(
					participant => participant.id === participantId
				)
			);

			// convert all participants to profiles for portability
			const profiles: LocalProfile[] = selectedParticipants.map(participant =>
				createProfileFromFullProfile({
					id: v4(),
					...createNewProfileFromParticipant({
						participant: participant!,
						recordingPublishers: fromRecording.recording!.publishers,
						recordingStudios: fromRecording?.recording!.studios,
						removeStudios: false, // preserve studios
					}),
				})
			);

			// add profiles to all recordings
			await Promise.all(
				toRecordingIds.map(async toRecId => {
					await Promise.resolve(
						dispatch(
							importLocalProfilesIntoRecordingAction(profiles, toRecId, false)
						)
					);
				})
			);
		} catch (error) {
			handleCloudProjectsError(
				error,
				dispatch,
				'Whoops! Hiccup detected while copying the credits.'
			);
		}
	};

export const setProjectLimitAction = (projectLimit: number) => ({
	type: SET_PROJECT_LIMIT,
	projectLimit,
});

export const setProjectUsageAction = (projectUsage: number) => ({
	type: SET_PROJECT_USAGE,
	projectUsage,
});

export const getProjectUsageAction = () => (dispatch: AppDispatch) => {
	dispatch(startCloudProjectsRequestAction(GET_PROJECT_USAGE_REQUEST));

	return getProjectUsage()
		.then(res => {
			dispatch(cloudProjectsRequestSuccessAction());
			return dispatch(setProjectUsageAction(res.data));
		})
		.catch(error => {
			handleCloudProjectsError(
				error,
				dispatch,
				'Whoops! Hiccup detected while getting profile information.'
			);
		});
};

export const changeRecordingOwnerAction =
	(recordingId: number, newOwnerId: number) =>
	async (dispatch: AppDispatch) => {
		dispatch(startCloudProjectsRequestAction(CHANGE_RECORDING_OWNER_REQUEST));

		try {
			await changeRecordingOwner({ recordingId, editorId: newOwnerId });

			dispatch(cloudProjectsRequestSuccessAction());

			await dispatch(fetchRecordingByIdAction({ id: recordingId })); // refetch recording for latest version
			await dispatch(getProjectUsageAction());
			await dispatch(getStorageUsageAction());
		} catch (error: any) {
			if (error.response && error.response.status === 402) {
				dispatch(showModalAction(CHANGE_OWNER_LIMIT_MODAL, { size: 'md' }));
			} else {
				handleCloudProjectsError(
					error,
					dispatch,
					'Whoops! Hiccup detected while changing the recording owner.'
				);
			}
		}
	};

export const changeAlbumOwnerAction =
	(albumId: number, newOwnerId: number) => async (dispatch: AppDispatch) => {
		dispatch(startCloudProjectsRequestAction(CHANGE_ALBUM_OWNER_REQUEST));

		try {
			await changeAlbumOwner({ albumId, editorId: newOwnerId });

			dispatch(cloudProjectsRequestSuccessAction());

			await dispatch(fetchAlbumByIdAction(albumId)); // refetch album for latest version
			await dispatch(getProjectUsageAction());
			await dispatch(getStorageUsageAction());
		} catch (error: any) {
			// catch 402 error separately
			if (error.response && error.response.status === 402) {
				dispatch(showModalAction(CHANGE_OWNER_LIMIT_MODAL, { size: 'md' }));
			} else {
				handleCloudProjectsError(
					error,
					dispatch,
					'Whoops! Hiccup detected while changing the album owner.'
				);
			}
		}
	};

export const changeOwnerAction =
	({
		recordingId = null,
		albumId = null,
		newOwnerId,
	}: {
		recordingId?: number | null;
		albumId?: number | null;
		newOwnerId: number;
	}) =>
	(dispatch: AppDispatch) => {
		if (recordingId) {
			return dispatch(changeRecordingOwnerAction(recordingId, newOwnerId));
		}

		if (albumId) {
			return dispatch(changeAlbumOwnerAction(albumId, newOwnerId));
		}
	};

export const setUserEditableProjectsAction = ({
	albumIds,
	recordingIds,
}: {
	albumIds: number[];
	recordingIds: number[];
}) => ({
	type: SET_USER_EDITABLE_PROJECTS,
	albumIds,
	recordingIds,
});

export const fetchUserEditableProjectsAction =
	() => (dispatch: AppDispatch) => {
		dispatch(
			startCloudProjectsRequestAction(FETCH_USER_EDITABLE_PROJECTS_REQUEST)
		);

		return getUserEditableProjects()
			.then(res => {
				dispatch(cloudProjectsRequestSuccessAction());

				const mapProjectIds = (
					projects: {
						id: number;
					}[]
				) => projects.map(project => project.id);

				return dispatch(
					setUserEditableProjectsAction({
						albumIds: mapProjectIds(res.data.albums),
						recordingIds: mapProjectIds(res.data.recordings),
					})
				);
			})
			.catch(error => {
				handleCloudProjectsError(
					error,
					dispatch,
					'Whoops! Hiccup detected while fetching project permissions.'
				);
			});
	};

export const setSearchedAlbumsAction = (albums: Album[]) => ({
	type: SET_SEARCHED_ALBUMS,
	albums,
});

export const setSearchedRecordingsAction = (recordings: Recording[]) => ({
	type: SET_SEARCHED_RECORDINGS,
	recordings,
});

/**
 * API search for user albums and recordings based on a search term.
 * Should be called from a debounced function triggered by changes to
 * the searchTerm state in the Projects Redux state
 */
export const searchUserProjectsAction =
	(searchTerm: string) => (dispatch: AppDispatch) => {
		dispatch(startCloudProjectsRequestAction(SEARCH_USER_PROJECTS_REQUEST));

		return searchUserProjects(searchTerm)
			.then(
				({
					albums,
					recordings,
				}: {
					albums: Album[];
					recordings: Recording[];
				}) => {
					dispatch(cloudProjectsRequestSuccessAction());

					dispatch(setSearchedAlbumsAction(albums));
					dispatch(setSearchedRecordingsAction(recordings));
				}
			)
			.catch(error => {
				handleCloudProjectsError(
					error,
					dispatch,
					'Whoops! Hiccup detected while searching for projects.'
				);
			});
	};

export const assignIsniToRecordingParticipantAction =
	({
		recordingId,
		participantId,
		isni,
	}: {
		recordingId: number;
		participantId: number;
		isni: string;
	}) =>
	async (dispatch: AppDispatch, getState: GetState) => {
		await dispatch(fetchRecordingByIdAction({ id: recordingId }));

		const recording = getState().projects.recordingsById?.[recordingId];

		if (!recording?.recording) {
			throw new Error('Recording not found');
		}

		const participant = recording.recording.participants.find(
			p => p.id === participantId
		);

		if (!participant) {
			throw new Error('Participant not found');
		}

		const newParticipant = {
			...participant,
			isni,
		};

		const newRecording = {
			...recording,
			recording: {
				...recording.recording,
				participants: recording.recording.participants.map(p =>
					p.id === participantId ? newParticipant : p
				),
			},
		};

		await dispatch(
			updateCloudRecordingAction({
				recordingForm: newRecording.recording,
			})
		);
	};

export const editProjectTitleAndArtistAction =
	({
		albumId,
		recordingId,
		title,
		artist,
	}: {
		albumId?: number | null;
		recordingId?: number | null;
		title: string;
		artist: string;
	}) =>
	async (dispatch: AppDispatch, getState: GetState) => {
		if (albumId) {
			await dispatch(fetchAlbumByIdAction(albumId));
			const album = getState().projects.albumsById?.[albumId];
			await dispatch(
				updateCloudAlbumAction({
					...album!.album!,
					title,
					artistName: artist,
				})
			);

			return;
		}

		if (!recordingId) {
			throw new Error('No album or recording id provided');
		}

		// if it's a recording, it may be being edited by another user
		// so we need to claim active editor permissions first
		const userId = getState().auth.userId;
		await claimActiveEditor(userId!, recordingId);

		await dispatch(fetchRecordingByIdAction({ id: recordingId }));
		const rec = getState().projects.recordingsById?.[recordingId];
		await dispatch(
			updateCloudRecordingAction({
				recordingForm: {
					...rec!.recording!,
					title,
					mainArtist: artist,
				},
			})
		);
	};

export const generateGRidAction =
	({
		albumForm,
		albumId,
		recordingId,
	}: {
		albumForm: FormikProps<AlbumContent>;
		albumId: Album['id'];
		recordingId?: number;
	}) =>
	async (dispatch: AppDispatch, getState: GetState) => {
		const { albumsById, myEditorProfile } = getState().projects;
		let currentAlbum = albumsById![albumId];

		if (!currentAlbum) {
			throw new Error('No album found');
		}

		const userIsReadOnly = myEditorProfile
			? myEditorProfile.is_read_only
			: true;

		if (userIsReadOnly) return;

		let gridCode = null;
		let recordingsWithoutRecordingDate = [];
		let fetchedSelectedRecordings = [];

		if (albumForm.values.grid) {
			dispatch(
				showAlertToast('A GRid can only be generated once per release.')
			);

			return false;
		}

		if (currentAlbum) {
			fetchedSelectedRecordings = await Promise.all(
				getAlbumRecordingIds(currentAlbum).map(
					async recording => await getCloudRecordingById(recording)
				)
			);

			recordingsWithoutRecordingDate = fetchedSelectedRecordings.filter(
				recording => !recording?.recording?.recordingDate
			);

			let errorMessages: string[] = [];

			if (recordingsWithoutRecordingDate.length)
				recordingsWithoutRecordingDate.forEach(recording => {
					errorMessages.push(
						`"${recording.title}" doesn't have a 'Date of Recording'.`
					);
				});

			if (recordingsWithoutRecordingDate.length) {
				dispatch(
					showModalAction(VALIDATE_GRID_MODAL, {
						size: 'lg',
						errorList: errorMessages,
						// errorList: GRidErrors
					})
				);

				return false;
			}
		} else if (recordingId) {
			let errorMessages = [];

			const fetchedSelectedRecording = await getCloudRecordingById(recordingId);

			if (!fetchedSelectedRecording.recording!.recordingDate)
				errorMessages.push(
					`"${
						fetchedSelectedRecording.recording!.title
					}" doesn't have a 'Date of Recording'.`
				);

			if (errorMessages.length) {
				dispatch(
					showModalAction(VALIDATE_GRID_MODAL, {
						size: 'lg',
						errorList: errorMessages,
						// errorList: GRidErrors
					})
				);

				return false;
			}
		}

		const { userId } = getState().auth;

		const { data } = await generateGRID({
			album: albumsById![albumId],
			userId: userId!,
		});

		if (data.response_code === 215)
			gridCode = (Object.values(data.codes)[0] as any).grid.replace(
				'found:',
				''
			); // TODO: TS Migration: FIX ANY
		if (data.response_code === 200)
			gridCode = (Object.values(data.codes)[0] as any).grid; // TODO: TS Migration: FIX ANY

		// Change the value (the GRid is the same for all the recordings)
		albumForm.setFieldValue('grid', gridCode);
	};
