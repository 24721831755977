import { createSelector } from 'reselect';
import { RootState } from '..';

const playlistsById = (state: RootState) => state.playlists.playlistsById;
const playlistStatsById = (state: RootState) =>
	state.playlists.playlistStatsById;
const dripStatsById = (state: RootState) => state.playlists.dripStatsById;
const currentPlaylistId = (state: RootState) =>
	state.playlists.currentPlaylistId;

export const getCurrentPlaylist = createSelector(
	playlistsById,
	currentPlaylistId,
	(playlists, id) => (playlists && id ? playlists[id] : null)
);

export const getCurrentPlaylistStats = createSelector(
	playlistStatsById,
	currentPlaylistId,
	(playlistStats, id) => (playlistStats && id ? playlistStats[id] : null)
);

export const getCurrentDripStats = createSelector(
	dripStatsById,
	currentPlaylistId,
	(dripStats, id) => (dripStats && id ? dripStats[id] : null)
);

export const getPlaylistById = createSelector(
	[
		playlistsById,
		(_: RootState, { playlistId }: { playlistId: Playlist['id'] }) => playlistId,
	],
	(playlists, id) => (playlists && id ? playlists[id] : null)
);
