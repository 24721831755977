import React, { useCallback, useEffect, useRef } from 'react';
import { Modal } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { TierDescriptions } from '../../../constants/tierDescriptions';
import TierOption from './TierOption';
import {
	hideModal,
	setModalTitle,
	showModalAction,
} from '../../../store/modal/actions';
import {
	BENEFITS_CODE_MODAL,
	CONFIRMATION_MODAL,
} from '../../../constants/modalTypes';
import {
	canUpgradeInPortal,
	openUpgradeWebsite,
} from '../../../helpers/tiersTools';
import { InputSwitch } from 'primereact/inputswitch';
import ProductTier from '../../../constants/productTiers';
import CouponGeneratorService from '../../../api/services/couponGeneratorService';
import SoundCreditLoader from '../SoundCreditLoader';

const NEW_USER_DISCOUNT_PERCENTAGE = 20;

export enum PaymentInterval {
	Yearly = 'yearly',
	Monthly = 'monthly',
}

// used for the onboarding flow
type UpgradeTierModalProps = {
	isNewUserOnboardingFlow?: boolean;
};

const UpgradeTierModal = ({
	isNewUserOnboardingFlow = false,
}: UpgradeTierModalProps) => {
	const { userEmail } = useAppSelector(state => state.auth);
	const dispatch = useAppDispatch();
	const { userPermissions } = useAppSelector(state => state.auth);

	const [isTierActionLoading, setIsTierActionLoading] = React.useState(false);
	const [isGeneratingCoupon, setIsGeneratingCoupon] = React.useState(false);
	const [couponCode, setCouponCode] = React.useState('');
	const wasCouponGenerated = useRef(false);
	const [paymentInterval, setPaymentInterval] = React.useState<PaymentInterval>(
		PaymentInterval.Monthly
	);

	const showFreeTiers =
		!canUpgradeInPortal(userPermissions!.subscriptionTier) &&
		!isNewUserOnboardingFlow;

	const handleUpgrade = (tier: TierDescription) => {
		if (isTierActionLoading) return;

		setIsTierActionLoading(true);

		try {
			let productCode: ProductTier | null = null;

			switch (tier.id) {
				case TierDescriptions.LITE.LITE_PLUS.id:
					dispatch(showModalAction(BENEFITS_CODE_MODAL, { size: 'md' }));
					return;
				case TierDescriptions.LITE.LITE.id:
					return;
				case TierDescriptions.PAID.STARTER.id:
					productCode =
						paymentInterval === PaymentInterval.Monthly
							? ProductTier.STARTER_MONTHLY
							: ProductTier.STARTER_ANNUAL;
					break;
				case TierDescriptions.PAID.PRO.id:
					productCode =
						paymentInterval === PaymentInterval.Monthly
							? ProductTier.PRO_MONTHLY
							: ProductTier.PRO_ANNUAL;
					break;
				case TierDescriptions.PAID.BUSINESS.id:
					productCode =
						paymentInterval === PaymentInterval.Monthly
							? ProductTier.BUSINESS_MONTHLY
							: ProductTier.BUSINESS_ANNUAL;
					break;
				default:
					return;
			}

			// TODO: Uncomment this when the in-app upgrade logic is ready
			// if (canUpgradeInPortal(userPermissions!.subscriptionTier)) {
			// 	dispatch(
			// 		showModalAction(CONFIRM_UPGRADE_MODAL, { size: 'lg', tier })
			// 	);
			// 	return;
			// }
			openUpgradeWebsite(
				isNewUserOnboardingFlow
					? {
							product: productCode as ProductTier,
							coupon: couponCode ?? '',
							email: userEmail,
					  }
					: undefined
			);
		} catch (error) {
			dispatch(
				showModalAction(CONFIRMATION_MODAL, {
					size: 'md',
					title: 'Whoops!',
					description:
						'Something went wrong while generating your coupon. Please try again later!',
					confirmAction: {
						label: 'Dismiss',
						onClick: () => dispatch(hideModal()),
					},
				})
			);
			console.log(error);
		} finally {
			setIsTierActionLoading(false);
		}
	};

	useEffect(() => {
		dispatch(
			setModalTitle(
				isNewUserOnboardingFlow
					? 'GET STARTED WITH SOUND CREDIT'
					: 'UPGRADE YOUR SUBSCRIPTION'
			)
		);
	}, [dispatch, isNewUserOnboardingFlow]);

	const generateCoupon = useCallback(async () => {
		setIsGeneratingCoupon(true);
		try {
			const { data } = await CouponGeneratorService.generateNewUserCoupon(
				userEmail
			);
			setCouponCode(data.coupon);
		} catch (error) {
			dispatch(
				showModalAction(CONFIRMATION_MODAL, {
					size: 'md',
					title: 'Whoops!',
					description:
						'Something went wrong while generating your coupon. Please try again later!',
					confirmAction: {
						label: 'Dismiss',
						onClick: () => dispatch(hideModal()),
					},
				})
			);
			console.log(error);
		} finally {
			setIsGeneratingCoupon(false);
		}
	}, [dispatch, userEmail]);

	useEffect(() => {
		if (wasCouponGenerated.current || couponCode || !isNewUserOnboardingFlow) {
			return;
		}

		wasCouponGenerated.current = true;
		generateCoupon();
	}, [isGeneratingCoupon, couponCode, generateCoupon, isNewUserOnboardingFlow]);

	return (
		<>
			<Modal.Body>
				{isGeneratingCoupon ? (
					<SoundCreditLoader />
				) : (
					<div className='upgrade-tier-modal'>
						<h2>The best decision in music.</h2>
						<p className='subheader'>
							Join thousands of music creators, starting at{' '}
							<strong>
								{TierDescriptions.PAID.STARTER.currency?.symbol}
								{parseFloat(TierDescriptions.PAID.STARTER.monthlyPrice) *
									(1 -
										(isNewUserOnboardingFlow
											? NEW_USER_DISCOUNT_PERCENTAGE
											: 0) /
											100)}
							</strong>
							/mo.
						</p>

						{isNewUserOnboardingFlow && (
							<div className='one-time-award-badge'>
								<div>🎉 🙌</div>
								<div>
									LIFETIME {NEW_USER_DISCOUNT_PERCENTAGE}% OFF AWARD!
								</div>{' '}
								<div>🙌 🎉</div>
							</div>
						)}
						<div className='d-flex align-items-center'>
							<div className='payment-interval-option mr-2'>Bill monthly</div>

							<InputSwitch
								checked={paymentInterval === PaymentInterval.Yearly}
								onChange={e =>
									setPaymentInterval(
										e.value ? PaymentInterval.Yearly : PaymentInterval.Monthly
									)
								}
							/>

							<div className='payment-interval-option ml-2'>
								Bill yearly & save
							</div>
						</div>

						<div className='paid-tiers'>
							{Object.values(TierDescriptions.PAID).map(tier => (
								<TierOption
									key={tier.id}
									tier={tier}
									onUpgrade={handleUpgrade}
									paymentInterval={paymentInterval}
									showNewUserStrings={isNewUserOnboardingFlow}
									isLoading={isTierActionLoading}
									discountPercentage={
										isNewUserOnboardingFlow
											? NEW_USER_DISCOUNT_PERCENTAGE
											: undefined
									}
								/>
							))}
						</div>
						{showFreeTiers && (
							<div className='free-tiers'>
								{Object.values(TierDescriptions.LITE).map(tier => (
									<TierOption
										key={tier.id}
										tier={tier}
										onUpgrade={handleUpgrade}
										paymentInterval={null}
										showNewUserStrings={isNewUserOnboardingFlow}
										isLoading={isTierActionLoading}
									/>
								))}
							</div>
						)}
					</div>
				)}
			</Modal.Body>
		</>
	);
};

export default UpgradeTierModal;
