import React, { useMemo } from 'react';
import { formatDateToHtml } from '../../utils/dateFormatters';
import TextField from '../TextField';

export type DatePickerProps = {
	disableFuture?: boolean;
	disablePast?: boolean;
	isDisabled?: boolean;
	value: string;
	as?: React.ElementType;
	placeholder?: string;
	id?: string;
	rows?: number;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
	isValid?: boolean;
	isInvalid?: boolean;
	label?: string;
	name?: string;
	errorMessage?: string;
	min?: string;
	max?: string;
	readOnly?: boolean;
};

const today = formatDateToHtml(new Date());

const DatePicker = ({
	disableFuture = false,
	disablePast = false,
	isDisabled = false,
	value,
	as,
	placeholder,
	id,
	rows,
	onChange,
	onBlur,
	isValid,
	isInvalid,
	label,
	name,
	errorMessage,
	min,
	max,
	readOnly,
}: DatePickerProps) => {
	const maxValue = useMemo(
		() => (disableFuture ? today : max),
		[disableFuture, max]
	);
	const minValue = useMemo(
		() => (disablePast ? today : min),
		[disablePast, min]
	);

	return (
		<TextField
			type='date'
			value={value}
			as={as}
			placeholder={placeholder}
			id={id}
			rows={rows}
			onChange={onChange}
			onBlur={onBlur}
			isValid={isValid}
			isInvalid={isInvalid}
			label={label}
			name={name}
			errorMessage={errorMessage}
			min={minValue}
			max={maxValue}
			isDisabled={isDisabled}
			readOnly={readOnly}
		/>
	);
};

export default DatePicker;
