import React, { useMemo } from 'react';
import {
	getExportPreviewData,
	getExportsOverflowMenuOptions,
} from '../../../../../helpers/fileTools';
import { downloadExportAction } from '../../../../../store/exports/actions';
import FilePreviewModal from '../FilePreviewModal';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';

const ExportPreviewModal = ({ exportId }: { exportId: number }) => {
	const dispatch = useAppDispatch();
	const { exportsById } = useAppSelector(state => state.exports);
	const { myEditorProfile } = useAppSelector(state => state.projects);

	const file = useMemo(
		() => exportsById[exportId] ?? null,
		[exportsById, exportId]
	);

	const downloadFileFn = file
		? () => dispatch(downloadExportAction(exportId, file.filename, true))
		: null;

	const isReadOnly = useMemo(
		() => (myEditorProfile ? myEditorProfile.is_read_only : true),
		[myEditorProfile]
	);

	const previewOptions = useMemo(
		() =>
			getExportsOverflowMenuOptions(file, dispatch, !isReadOnly).filter(
				o => o.name !== 'Preview'
			),
		[file, dispatch, isReadOnly]
	);

	return (
		<FilePreviewModal
			file={file}
			getPreviewDataFn={getExportPreviewData}
			downloadFileFn={downloadFileFn}
			previewOptions={previewOptions}
		/>
	);
};

export default ExportPreviewModal;
