import React from 'react';
import { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal, setModalTitle } from '../../../../store/modal/actions';
import Button from '../../../layout/Button';
import { deletePersonalProfile as deleteCloudProfile } from '../../../../api/services/localProfilesService';
import { deleteProfile } from '../../../../store/profiles/actions';

const DeleteAllProfilesModal = () => {
	const dispatch = useDispatch();
	const { profiles } = useSelector(state => state.profiles);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		console.log('PARTICIPANT IN DELETE ALL PROFILES MODAL: ');
		dispatch(setModalTitle('DELETE ALL PROFILES'));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleClose = () => {
		dispatch(hideModal());
	};

	const handleDelete = async () => {
		try {
			if (profiles && !profiles.length) return false;

			setIsLoading(true);
			await Promise.all(
				profiles.map(async profile => {
					console.log(profile.id);
					await deleteCloudProfile(profile.id);
					dispatch(deleteProfile(profile));
					// console.log(profile)
				})
			);

			setIsLoading(false);
			handleClose();
		} catch (e) {
			console.log(e);
		}
	};

	return (
		<>
			<Modal.Body>
				<div className='px-3'>
					<p style={{ fontWeight: 500 }}>
						Are you sure you want to delete <strong>ALL</strong> of your
						profiles?
					</p>
					<p
						className=' fw-bold text-danger'
						style={{ textDecoration: 'underline' }}
					>
						This action cannot be undone
					</p>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Row>
					<Col
						xs={12}
						className='d-flex justify-content-end align-items-center'
					>
						<Button label='Cancel' onClick={handleClose} />
						<Button
							theme='danger'
							onClick={handleDelete}
							style={{ fontWeight: 600 }}
							isLoading={isLoading}
							isDisabled={isLoading}
							label='Delete'
						/>
					</Col>
				</Row>
			</Modal.Footer>
		</>
	);
};

export default DeleteAllProfilesModal;
