import { useSelector } from 'react-redux';
import './PlaylistStatsChart.scss';
import {
	getCurrentDripStats,
	getCurrentPlaylistStats,
} from '../../../../../store/playlists/selectors';
import { useCallback, useMemo, useState } from 'react';
import {
	BarChart,
	ResponsiveContainer,
	XAxis,
	YAxis,
	Legend,
	Tooltip,
	Bar,
	PieChart,
	Pie,
	Sector,
	Cell,
} from 'recharts';
import { formatNumberWithUnit } from '../../../../utils/numberFormatter';
import Select from 'react-select';
import React from 'react';

const truncate = (str, num) => {
	if (str.length > num) {
		return str.slice(0, num - 2) + '...';
	} else {
		return str;
	}
};

const CustomizedLabel = props => {
	// eslint-disable-next-line react/prop-types
	const { x, y, payload } = props;

	return (
		<g transform={`translate(${x},${y})`}>
			<title>{payload.value}</title>
			<text x={0} y={0} dx={-10} textAnchor='end' fontSize={12} fill='#666'>
				{truncate(payload.value, 13)}
			</text>
		</g>
	);
};

const stringToColor = str => {
	let hash = 0;
	for (let i = 0; i < str.length; i++) {
		hash = str.charCodeAt(i) + ((hash << 5) - hash);
	}
	let color = '#';
	for (let i = 0; i < 3; i++) {
		let value = (hash >> (i * 8)) & 0xff;
		color += ('00' + value.toString(16)).substr(-2);
	}
	return color;
};

const formatTime = time => {
	const minutes = Math.floor(time / 60);
	const seconds = Math.floor(time - minutes * 60);

	return `${minutes}m ${seconds < 10 ? '0' : ''}${seconds}s`;
};

const PlaylistBarChart = ({
	isDrip = false,
	dataKey,
	title,
	isTime = false,
}) => {
	const { data } = useSelector(
		isDrip ? getCurrentDripStats : getCurrentPlaylistStats
	);

	const chartData = useMemo(
		() => (data?.trackData ?? []).filter(item => !!item[dataKey]),
		[data?.trackData, dataKey]
	);

	const [activeIndex, setActiveIndex] = useState(-1);

	const onPieEnter = (_, index) => {
		setActiveIndex(index);
	};

	const onSelectTrack = item => {
		setActiveIndex(item.value);
	};

	const selectMusicOptions = useMemo(
		() => [
			{ value: -1, label: 'All Tracks' },
			...chartData.map((item, index) => {
				return { value: index, label: item.title };
			}),
		],
		[chartData]
	);

	const totalValue = useMemo(() => {
		return (data?.trackData ?? []).reduce((a, b) => a + b?.[dataKey], 0);
	}, [data, dataKey]);

	const renderActiveShape = useCallback(
		props => {
			const RADIAN = Math.PI / 180;
			const {
				cx,
				cy,
				midAngle,
				innerRadius,
				outerRadius,
				startAngle,
				endAngle,
				fill,
				payload,
				percent,
				value,
				title,
			} = props;
			const sin = Math.sin(-RADIAN * midAngle);
			const cos = Math.cos(-RADIAN * midAngle);
			const sx = cx + (outerRadius + 10) * cos;
			const sy = cy + (outerRadius + 10) * sin;
			const mx = cx + (outerRadius + 30) * cos;
			const my = cy + (outerRadius + 30) * sin;
			const ex = mx + (cos >= 0 ? 1 : -1) * 22;
			const ey = my;
			const textAnchor = cos >= 0 ? 'start' : 'end';

			return (
				<g>
					<text x={cx} y={cy} dy={8} textAnchor='middle' fill={fill}>
						{payload.name}
					</text>
					<Sector
						cx={cx}
						cy={cy}
						innerRadius={innerRadius}
						outerRadius={outerRadius}
						startAngle={startAngle}
						endAngle={endAngle}
						fill={fill}
					/>
					<Sector
						cx={cx}
						cy={cy}
						startAngle={startAngle}
						endAngle={endAngle}
						innerRadius={outerRadius + 6}
						outerRadius={outerRadius + 10}
						fill={fill}
					/>
					<path
						d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
						stroke={fill}
						fill='none'
					/>
					<circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
					<text
						x={ex + (cos >= 0 ? 1 : -1) * 12}
						y={ey}
						textAnchor={textAnchor}
						fill={stringToColor(title)}
						fontSize={12}
					>{`${title?.length > 20 ? truncate(title, 20) : title}  ${
						isTime
							? formatTime(Math.floor(value / 1000))
							: formatNumberWithUnit(value)
					}`}</text>
					<text
						x={ex + (cos >= 0 ? 1 : -1) * 12}
						y={ey}
						dy={18}
						textAnchor={textAnchor}
						fill={stringToColor(title)}
						fontSize={10}
					>
						{`(Rate ${(percent * 100).toFixed(2)}%)`}
					</text>
				</g>
			);
		},
		[isTime]
	);

	return (
		<div className='playlist-ranking-chart__container'>
			<div className='select-group-title__container'>
				<div className='select-group-title'>
					<i className='fas fa-chart-bar'></i>
					<div>
						<h3>{title}</h3>
					</div>
				</div>
			</div>
			{chartData.length ? (
				<>
					<div className='bar-chart__container'>
						{(data?.trackData ?? []).length ? (
							<ResponsiveContainer
								height={(data?.trackData?.length ?? 0) * 30 + 50}
							>
								<BarChart
									data={data?.trackData ?? []}
									layout='vertical'
									margin={{ top: 0, left: 50, right: 0, bottom: 0 }}
								>
									<YAxis
										type='category'
										dataKey='title'
										tick={<CustomizedLabel />}
										interval={0}
									/>
									<XAxis
										type='number'
										tickCount={5}
										fontSize={12}
										tickFormatter={(value, index) =>
											isTime
												? formatTime(Math.floor(value / 1000))
												: formatNumberWithUnit(value, 2)
										}
									/>
									<Legend
										verticalAlign='top'
										height={32}
										wrapperStyle={{ fontSize: '12px' }}
									/>
									<Tooltip
										formatter={(value, name) =>
											isTime
												? `${formatTime(Math.floor(value / 1000))}`
												: `${formatNumberWithUnit(value)} (${(
														(value * 100) /
														totalValue
												  ).toFixed(2)}%)`
										}
									/>
									<Bar
										name={title}
										dataKey={dataKey}
										fill='#8500d1'
										radius={[0, 10, 10, 0]}
										barSize={15}
									/>
								</BarChart>
							</ResponsiveContainer>
						) : (
							<div className='empty__container'>
								<i class='fas fa-times' title='no data'></i>
							</div>
						)}
					</div>
					<hr />
					<div className='pie-chart__container'>
						<div className='chart-track-select__container'>
							<p className='chart-track-select__filter-label w-50'>
								Filter by Track
							</p>
							<div>
								<Select
									value={selectMusicOptions[activeIndex + 1]}
									onChange={onSelectTrack}
									options={selectMusicOptions}
									classNamePrefix='music-select'
									theme={theme => ({
										...theme,
										colors: {
											...theme.colors,
											primary: '#8500d1',
											primary50: '#8500d170',
											primary25: '#8500d138',
										},
									})}
								/>
							</div>
						</div>
						<ResponsiveContainer height={350}>
							<PieChart width={400} height={400}>
								<Pie
									activeIndex={activeIndex}
									activeShape={renderActiveShape}
									inactiveShape={activeIndex < 0 && renderActiveShape}
									data={chartData}
									cx='50%'
									cy='50%'
									innerRadius={60}
									outerRadius={80}
									fill='#8500d1'
									dataKey={dataKey}
									onMouseEnter={onPieEnter}
								>
									{chartData.map((entry, index) => (
										<Cell
											key={`cell-${index}`}
											fill={stringToColor(data?.trackData?.[index].title)}
										/>
									))}
								</Pie>
							</PieChart>
						</ResponsiveContainer>
						{/* <div className='chart-title__container'>{title}</div> */}
					</div>
				</>
			) : (
				<div className='empty__container'>
					<p>No data available yet</p>
				</div>
			)}
		</div>
	);
};

export default PlaylistBarChart;
