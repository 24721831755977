import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Alert, Col, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal, setModalTitle } from '../../../../store/modal/actions';
import Button from '../../../layout/Button';
import { Button as BSButton } from 'react-bootstrap';
import {
	deleteCloudRecordingByIdAction,
	deleteLocalRecordingByIdAction,
	removeRecordingFromCloudAlbumAction,
} from '../../../../store/projects/actions';
import { claimActiveEditor } from '../../../../api/services/editorService';
import {
	getRecordingEditors,
	deleteRecordingEditor,
} from '../../../../api/services/editorService';
import {
	enterpriseExportTypes,
	proExportTypes,
	regularExportTypes,
} from '../../../../constants/extendedExportTypes';
import { deleteExportFormAction } from '../../../../store/session/actions';
import { getSavedExportRecordingIds } from '../../../../helpers/savedExportsTools';
import SoundCreditLoader from '../../SoundCreditLoader';
import { fetchUserPlaylistsAction } from '../../../../store/playlists/actions';

const extendedExportTypes = {
	...proExportTypes,
	...regularExportTypes,
	...enterpriseExportTypes,
};

const DeleteRecordingModal = ({ id, project }) => {
	const dispatch = useDispatch();
	const { userId } = useSelector(state => state.auth);
	const { savedExportFormsById } = useSelector(state => state.session);
	const { recordingsById, albumsById } = useSelector(state => state.projects);
	const [isDeleting, setIsDeleting] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isCoOwner, setIsCoOwner] = useState(false);

	useEffect(() => {
		dispatch(setModalTitle('DELETE RECORDING'));
	}, [dispatch]);

	const isOwner = useMemo(
		() => recordingsById[id]?.userId === userId,
		[userId, id, recordingsById]
	);

	useEffect(() => {
		const setMyEditorProfile = async () => {
			const myEditorProfile = await fetchMyEditorProfile(id);

			if (!myEditorProfile.is_read_only) setIsCoOwner(true);

			setIsLoading(false);
		};

		setMyEditorProfile();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const isAlbumTrack = useMemo(() => {
		return !!(
			recordingsById[id]?.albumId &&
			!albumsById[recordingsById[id].albumId].isSingle
		);
	}, [recordingsById, albumsById, id]);

	const referencedSavedExports = useMemo(
		() =>
			Object.values(savedExportFormsById).filter(
				savedExport =>
					savedExport.recordingId === id ||
					(savedExport.albumId === recordingsById[id]?.albumId &&
						getSavedExportRecordingIds(savedExport).includes(id))
			),
		[savedExportFormsById, recordingsById, id]
	);

	const handleClose = () => {
		dispatch(hideModal());
	};

	const deleteReferencedExports = useCallback(() => {
		referencedSavedExports.forEach(savedExport => {
			dispatch(deleteExportFormAction(savedExport.id));
		});
	}, [referencedSavedExports, dispatch]);

	const handleDelete = async () => {
		try {
			setIsDeleting(true);
			if (!isAlbumTrack) await claimActiveEditor(userId, id);

			const editorProfile = await fetchMyEditorProfile(id);

			if (!editorProfile.is_owner) {
				await deleteRecordingEditor({
					recording_id: id,
					editor_id: userId,
					editor_email: editorProfile.email,
				});

				deleteReferencedExports();
				dispatch(fetchUserPlaylistsAction()); // invalidate playlists cache to avoid null references
				dispatch(deleteLocalRecordingByIdAction(id));
				dispatch(hideModal());
				return;
			}

			deleteReferencedExports();
			dispatch(deleteLocalRecordingByIdAction(id));
			await Promise.resolve(dispatch(deleteCloudRecordingByIdAction(id)));
			dispatch(fetchUserPlaylistsAction());
			setIsDeleting(false);
			dispatch(hideModal());
		} catch (e) {
			setIsDeleting(false);
			console.log(e);
		}
	};

	const fetchMyEditorProfile = async id => {
		try {
			if (!id) throw new Error(`Can't retrieve editors for this project`);

			const { data } = await getRecordingEditors(id);
			const { editors } = data;
			const myEditorProfile = editors.find(editor => editor.user_id === userId);

			return myEditorProfile;
		} catch (e) {
			console.log(e);
			console.log(`fetchEditors: ID was undefined!`);
		}
	};

	const handleDeleteFromAlbum = async () => {
		try {
			const editorProfile = await fetchMyEditorProfile(id);
			const isReadOnly = editorProfile.is_read_only;

			if (isReadOnly) return;

			deleteReferencedExports();
			await Promise.resolve(dispatch(removeRecordingFromCloudAlbumAction(id)));
			dispatch(fetchUserPlaylistsAction());
		} catch (error) {
			console.log(error);
		} finally {
			dispatch(hideModal());
		}
	};
	if (isLoading)
		return (
			<>
				<Modal.Body>
					<SoundCreditLoader />
				</Modal.Body>
			</>
		);

	return (
		<>
			<Modal.Body>
				<div className='px-4'>
					<p style={{ fontWeight: 500 }}>
						{isOwner ? (
							<>
								Are you sure you want to delete this recording? All files
								associated with this recording will be deleted, as well as their
								references in playlists.
							</>
						) : (
							<>
								Are you sure you want to unshare this recording? All files
								associated with this recording will be unshared, as well as
								their references in playlists.
							</>
						)}
					</p>
					{referencedSavedExports.length > 0 && (
						<Alert variant='danger'>
							<p>
								<strong>WARNING</strong>: If you delete this recording, all
								progress made in the following export forms that reference this
								recording will be lost:
							</p>
							<ul>
								{referencedSavedExports.map(savedExport => (
									<li key={savedExport.id}>
										{extendedExportTypes[savedExport.exportType].label}
									</li>
								))}
							</ul>
							<p>
								If you wish to keep the progress, go into the listed export
								forms and unselect the recording.
							</p>
						</Alert>
					)}
					<p style={{ fontWeight: 700, marginBottom: 0 }}>
						This action cannot be undone.
					</p>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Row>
					<Col
						xs={12}
						className='d-flex justify-content-end align-items-center'
					>
						<Button label='Cancel' onClick={handleClose} />
						{isAlbumTrack && isCoOwner && (
							<BSButton
								variant='danger'
								onClick={handleDeleteFromAlbum}
								style={{ fontWeight: 600 }}
								className='ml-2'
							>
								Remove From Album
							</BSButton>
						)}
						<Button
							className='ml-2'
							theme='danger-filled'
							onClick={handleDelete}
							style={{ fontWeight: 600 }}
							isDisabled={isDeleting}
							isLoading={isDeleting}
							label={isDeleting ? 'Loading...' : 'Delete Recording'}
						/>
					</Col>
				</Row>
			</Modal.Footer>
		</>
	);
};

export default DeleteRecordingModal;
