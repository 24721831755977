import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import isniStrings, { IsniString } from '../isniStrings';
import TextField from '../../../form/TextField/TextField';
import Button from '../../../layout/Button/Button';
import DatePicker from '../../../form/DatePicker';
import { formatDateToHtml } from '../../../utils/dateFormatters';

type BasicInfoStepProps = {
	onSubmit: (values: BasicInfoFormikValues) => void;
	initialFormValues?: BasicInfoFormikValues | null;
	getIsniStringFn: (isniString: IsniString) => string;
};

export type BasicInfoFormikValues = {
	title: string;
	firstName: string;
	middleName: string;
	lastName: string;
	suffix: string;
	birthdate: string;
};

const validationSchema = Yup.object({
	title: Yup.string(),
	firstName: Yup.string().required('Required'),
	middleName: Yup.string(),
	lastName: Yup.string().required('Required'),
	suffix: Yup.string(),
	birthdate: Yup.date().max(new Date(), 'Invalid date').required('Required'),
});

const BasicInfoStep = ({
	onSubmit,
	initialFormValues,
	getIsniStringFn,
}: BasicInfoStepProps) => {
	const formik = useFormik<BasicInfoFormikValues>({
		initialValues: initialFormValues ?? {
			title: '',
			firstName: '',
			middleName: '',
			lastName: '',
			suffix: '',
			birthdate: '',
		},
		onSubmit: values => {
			console.log('values', values);
			onSubmit(values);
		},
		validationSchema,
		validateOnMount: true,
		enableReinitialize: true,
	});

	return (
		<FormikProvider value={formik}>
			<h3>{getIsniStringFn(isniStrings.BasicInfo.title)}</h3>

			<h4>{getIsniStringFn(isniStrings.BasicInfo.description)}</h4>

			<div className='isni-registration__required text-muted'>
				* indicates a required field. All other fields are optional.
			</div>

			<TextField
				label={getIsniStringFn(isniStrings.BasicInfo.form.title.label)}
				name='title'
				placeholder={getIsniStringFn(isniStrings.BasicInfo.form.title.label)}
				informationText={getIsniStringFn(isniStrings.BasicInfo.form.title.info)}
				value={formik.values.title}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
			/>

			<TextField
				label={getIsniStringFn(isniStrings.BasicInfo.form.firstName.label)}
				name='firstName'
				placeholder={getIsniStringFn(
					isniStrings.BasicInfo.form.firstName.label
				)}
				informationText={getIsniStringFn(
					isniStrings.BasicInfo.form.firstName.info
				)}
				value={formik.values.firstName}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				errorMessage={formik.errors.firstName}
				isInvalid={formik.touched.firstName && !!formik.errors.firstName}
				showRequiredAsterisk
			/>

			<TextField
				label={getIsniStringFn(isniStrings.BasicInfo.form.middleName.label)}
				name='middleName'
				placeholder={getIsniStringFn(
					isniStrings.BasicInfo.form.middleName.label
				)}
				informationText={getIsniStringFn(
					isniStrings.BasicInfo.form.middleName.info
				)}
				value={formik.values.middleName}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
			/>

			<TextField
				label={getIsniStringFn(isniStrings.BasicInfo.form.lastName.label)}
				name='lastName'
				placeholder={getIsniStringFn(isniStrings.BasicInfo.form.lastName.label)}
				informationText={getIsniStringFn(
					isniStrings.BasicInfo.form.lastName.info
				)}
				value={formik.values.lastName}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				errorMessage={formik.errors.lastName}
				isInvalid={formik.touched.lastName && !!formik.errors.lastName}
				showRequiredAsterisk
			/>

			<TextField
				label={getIsniStringFn(isniStrings.BasicInfo.form.suffix.label)}
				name='suffix'
				placeholder={getIsniStringFn(isniStrings.BasicInfo.form.suffix.label)}
				informationText={getIsniStringFn(
					isniStrings.BasicInfo.form.suffix.info
				)}
				value={formik.values.suffix}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
			/>

			<DatePicker
				name='birthdate'
				label='Date of Birth'
				placeholder='Select date of birth'
				value={formik.values.birthdate}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				isInvalid={formik.touched.birthdate && !!formik.errors.birthdate}
				errorMessage={formik.errors.birthdate}
				max={formatDateToHtml(new Date())}
			/>

			<div className='w-100 d-flex justify-content-end'>
				<Button
					label='Next'
					rightIcon='fas fa-arrow-right'
					theme='dark'
					onClick={() => {
						formik.handleSubmit();
					}}
				/>
			</div>
		</FormikProvider>
	);
};

export default BasicInfoStep;
