import clsx from 'clsx';
import React, {
	forwardRef,
	useCallback,
	useContext,
	useMemo,
	useState,
} from 'react';
import IconButton from '../../../../../layout/IconButton';
import theme from '@/theme.module.scss';
import SortableDndPlaylistFileTableRow from '../PlaylistFileTableRow/SortableDndPlaylistFileTableRow';
import {
	createDndItemId,
	getItemsInGroup,
	isAncestorOf,
	rowClickHandler,
} from '../utilities';
import { SelectedPlaylistContext } from '../../../../../../context/SelectedPlaylistContext';
import { Spinner } from 'react-bootstrap';
import SortableDndPlaylistFileTableFolder from './SortableDndPlaylistFileTableFolder';
import { isAudioFile } from '../../../../../../helpers/fileTools';
import { setPlayerPlayingAction } from '../../../../../../store/player/actions';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import NowPlayingIcon from '../../../../../layout/NowPlayingIcon';

type Props = {
	folder: PlaylistFileGroup;
	rowIndex?: number;
	depth: number;
	isDragging?: boolean;
	clone?: boolean; // used for component to be able to tell if it's a clone, and render differently in that case
	[key: string]: any;
};
// const DndPlaylistFileTableRow =
const DndPlaylistFileTableFolder = forwardRef<HTMLTableRowElement, Props>(
	(
		{ folder, rowIndex, clone = false, depth, isDragging = false, ...props },
		ref
	) => {
		const dispatch = useAppDispatch();
		const { playing } = useAppSelector(state => state.player);

		const [isExpanded, setIsExpanded] = useState(false);
		const {
			currentTrackId,
			setHoverRowId,
			hoverRowId,
			selectedItemIds,
			setSelectedItemIds,
			contextMenuRef,
			groupIdToParentGroupIdIndex,
			fileIdToGroupIdIndex,
			groupIdToGroupContentIndex,
			selectionAnchorPoint,
			setSelectionAnchorPoint,
			isDeletingGroupIds,
			sortedFolders,
			playlistFiles,
			handleSelectTrack,
		} = useContext(SelectedPlaylistContext)!;

		const isDeleting = isDeletingGroupIds?.includes(folder.id);
		const itemId = useMemo(() => createDndItemId(folder), [folder]);
		// TODO: use state for these items for optimistically updating the UI when reordering
		const groupItems = useMemo(
			() =>
				getItemsInGroup({
					playlistFiles,
					sortedFolders,
					parentGroupId: folder.id,
					groupIdToGroupContentIndex,
					fileIdToGroupIdIndex,
				}),
			[
				playlistFiles,
				sortedFolders,
				folder,
				fileIdToGroupIdIndex,
				groupIdToGroupContentIndex,
			]
		);

		const isSelected = useMemo(
			() => selectedItemIds?.includes(itemId),
			[selectedItemIds, itemId]
		);

		const containsAudioFiles = useMemo(() => {
			return groupItems.files.some(file => isAudioFile(file.filename));
		}, [groupItems.files]);

		const isDescendantPlaying = useMemo(
			() =>
				currentTrackId &&
				(folder.id === fileIdToGroupIdIndex[currentTrackId] ||
					isAncestorOf({
						folderAId: folder.id,
						folderBId: fileIdToGroupIdIndex[currentTrackId],
						groupIdToParentGroupIdIndex,
					})),
			[
				currentTrackId,
				fileIdToGroupIdIndex,
				groupIdToParentGroupIdIndex,
				folder,
			]
		);

		const isEmpty =
			groupItems.folders.length === 0 && groupItems.files.length === 0;

		const handlePlayFolder = useCallback(() => {
			setSelectedItemIds([itemId]);
			const firstAudioFile = groupItems.files.find(file =>
				isAudioFile(file.filename)
			);

			if (!firstAudioFile) return;

			handleSelectTrack(firstAudioFile);

			// preserve the expanded state of the folder (undo the toggle that happened in handleClick)
			setIsExpanded(prev => !prev);
		}, [groupItems, handleSelectTrack, setSelectedItemIds, itemId]);

		const handleClick = (
			e: React.MouseEvent<HTMLTableRowElement, MouseEvent>
		) => {
			if (isDeleting) return;

			rowClickHandler(e, {
				rowItemId: itemId,
				setSelectedItemIds,
				rowIndex: rowIndex!,
				groupIdToParentGroupIdIndex,
				groupIdToGroupContentIndex,
				fileIdToGroupIdIndex,
				playlistFiles,
				sortedFolders,
				selectionAnchorPoint,
				setSelectionAnchorPoint,
			});
			if (e.ctrlKey || e.metaKey || e.shiftKey) return;

			setIsExpanded(prev => !prev);
		};

		return clone ? (
			<div className='playlist-file-table__file-row--drag-clone p-2'>
				<i className='fas fa-folder mr-2'></i>
				<span>{folder.name}</span>
			</div>
		) : (
			<div
				className={clsx('playlist-file-table__group-row-container', {
					// 'playlist-file-table__row--dragging': isDragging,
				})}
				style={{
					marginLeft: depth > 0 ? theme.playlistTableNestedFileIndentation : 0,
				}}
			>
				<tr
					ref={ref}
					{...props}
					onClick={handleClick}
					onDoubleClick={handlePlayFolder}
					onMouseEnter={() => setHoverRowId(itemId)}
					onMouseLeave={() => setHoverRowId(null)}
					className={clsx('playlist-file-table__group-row', props.className, {
						'playlist-file-table__group-row--expanded': isExpanded,
						'playlist-file-table__active-row': isSelected,
						'playlist-file-table__row--dragging': isDragging,
					})}
					onContextMenu={e => {
						if (isDeleting) return;

						// a right click is meant to select the file if it's not already selected
						if (!selectedItemIds?.includes(itemId)) {
							setSelectedItemIds([itemId]);
						}

						// if it was already selected, then it could be part of a multi-select, so we don't want to change the selected fileIds
						contextMenuRef.current?.show(e);
						// setSelectedFileId((e.value as PlaylistTableFile).id);
					}}
				>
					<td>
						{isDeleting ? (
							<Spinner size='sm' animation='border' />
						) : isExpanded ? (
							<i className='fas fa-chevron-down'></i>
						) : (
							<i className='fas fa-chevron-right'></i>
						)}
					</td>
					<td>
						<div className='d-flex align-items-center'>
							<div
								className='d-flex justify-content-center align-items-center mr-2'
								style={{
									width: '1.6rem',
								}}
							>
								{/* if the file playing is not a direct descendant of this folder, then only show the now playing icon */}
								{isDescendantPlaying &&
								currentTrackId &&
								playing &&
								folder.id !== fileIdToGroupIdIndex[currentTrackId] ? (
									<NowPlayingIcon />
								) : isDescendantPlaying && playing ? ( // this means it's a direct descendant
									hoverRowId === itemId ? (
										<i
											className='fas fa-pause mr-2'
											onClick={e => {
												e.stopPropagation();
												dispatch(setPlayerPlayingAction(false));
											}}
										/>
									) : (
										<NowPlayingIcon />
									)
								) : containsAudioFiles && hoverRowId === itemId ? (
									<i className='fas fa-play' onClick={handlePlayFolder}></i>
								) : (
									<i className='fas fa-folder'></i>
								)}
							</div>
							<span>{folder.name}</span>
						</div>
					</td>
					<td>
						<IconButton
							icon='fas fa-ellipsis-v'
							onClick={e => {
								setSelectedItemIds([itemId]);
								contextMenuRef.current?.show(e);
							}}
							color={theme.primary}
							tooltipText='More Options'
						/>
					</td>
				</tr>
				{isExpanded &&
					!isDeleting &&
					(isEmpty ? (
						<div className='playlist-file-table__group-row__children'>
							<div className='playlist-file-table__empty-folder-message'>
								<i className='fas fa-folder-open mr-2'></i>
								<span>This folder is empty</span>
							</div>
						</div>
					) : (
						<div className='playlist-file-table__group-row__children'>
							{groupItems.folders.map((folder, index) => (
								<SortableDndPlaylistFileTableFolder
									key={folder.id}
									folder={folder}
									depth={depth + 1}
									rowIndex={index}
									id={createDndItemId(folder)}
								/>
							))}
							{groupItems.files.map((file, index) => (
								<SortableDndPlaylistFileTableRow
									key={file.id}
									file={file}
									rowIndex={index + groupItems.folders.length}
									id={createDndItemId(file)}
									// isSortingActive={activeDndItemId === file.id}
								/>
							))}
						</div>
					))}
			</div>
		);
	}
);

export default DndPlaylistFileTableFolder;
