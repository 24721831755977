import {
	Form,
	FormikHelpers,
	FormikProps,
	FormikProvider,
	useFormik,
	useFormikContext,
} from 'formik';
import { Card, Col, Row, Container } from 'react-bootstrap';
import Button from '../../../layout/Button/Button';
import TextField from '../../../form/TextField';
import { useState, useEffect, useMemo, useCallback } from 'react';
import recordLabelOptions from '../../../../constants/recordLabelOptions.json';
import CreatableSelect from '../../../form/CreatableSelect';
import {
	updateCloudAlbumAction,
	addRecordingsToCloudAlbumAction,
	fetchAlbumByIdAction,
	fetchRecordingByIdAction,
	generateGRidAction,
	setCurrentAlbumIdAction,
} from '../../../../store/projects/actions';
import { getCurrentRecording } from '../../../../store/projects/selectors';
import albumSchema from '../../../../constants/album.json';
import { createCloudAlbumAction } from '../../../../store/projects/actions';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import genreOptions from '../../../../constants/genreOptions.json';
import { REQUEST_FAILURE } from '../../../../constants/requestStatusTypes';
import {
	ADD_RECORDINGS_TO_ALBUM_REQUEST,
	UPDATE_ALBUM_REQUEST,
} from '../../../../constants/requestLabelTypes';
import SoundCreditLoader from '../../SoundCreditLoader';
import { useDebounceEffect } from '../../../../hooks/useDebounceEffect';
import useTaskBeforeUnmount from '../../../../hooks/useTaskBeforeUnmount';
import {
	getAlbumEditors,
	getRecordingEditors,
} from '../../../../api/services/editorService';
import { matchSorter } from 'match-sorter';
import ROUTES from '../../../../router/routes';
import DatePicker from '../../../form/DatePicker';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { Helmet } from 'react-helmet';

const schema = yup.object().shape({
	title: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === null ? '' : curr))
		.required('Title is required'),
	artistName: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === null ? '' : curr))
		.required('Artist Name is required'),
	recordLabel: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === null ? '' : curr)),
	// .required('Record Label is required'),
	genre: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === null ? '' : curr)),
	// .required('Genre is required'),
	upcEan: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === null ? '' : curr)),
	// .required('UPC/EAN is required'),
	releaseDate: yup
		.date()
		.nullable()
		.transform((curr, orig) => (orig === '' ? null : curr)),
	// .required('Release Date Is Required'),
	catalogId: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === null ? '' : curr)),
	// .required('Catalog ID is required'),
	grid: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === null ? '' : curr)),
	// .required('GRid is required'),
});

export type ReleaseDetailsProps = {
	onSave: (
		values: RecordingContent,
		dirty: boolean,
		validateForm: FormikHelpers<RecordingContent>['validateForm']
	) => void;
};

function ReleaseDetails({ onSave }: ReleaseDetailsProps) {
	const recordingFormik = useFormikContext<RecordingContent>();

	/*
	 * Redux Hooks
	 */
	const currentRecording = useAppSelector(getCurrentRecording);
	const {
		myEditorProfile,
		currentAlbumId,
		albumsById,
		currentRecordingId,
		requestLabel,
		requestStatus,
	} = useAppSelector(state => state.projects);
	const { userId, userPermissions } = useAppSelector(state => state.auth);
	const dispatch = useAppDispatch();

	/*
	 * React Router Hooks
	 */
	const navigate = useNavigate();

	/*
	 * Local State
	 */
	const [isSaving, setIsSaving] = useState(false);
	// Needed to move recordLabels piece of state after releaseFormik declaration.
	const [isGenerateLoading, setIsGenerateLoading] = useState(false);
	const [isCoOwner, setIsCoOwner] = useState(false);
	const [coOwnerLoading, setCoOwnerLoading] = useState(true);
	const isReadOnly = myEditorProfile ? myEditorProfile.is_read_only : true;

	const currentAlbum = useMemo(
		() =>
			albumsById && currentRecording && currentRecording.albumId
				? albumsById[currentRecording.albumId]?.album
				: null,
		[albumsById, currentRecording]
	);

	const isFetchingAlbum = useMemo(
		() =>
			Boolean(currentRecording && currentRecording.albumId && !currentAlbum),
		[currentRecording, currentAlbum]
	);

	const initialValues = useMemo(() => {
		if (currentRecording?.albumId) {
			return currentAlbum ?? albumSchema;
		}

		return {
			...albumSchema,
			title: recordingFormik.values.title,
			artistName: recordingFormik.values.mainArtist,
			genre: recordingFormik.values.genre,
		};
	}, [currentRecording, currentAlbum, recordingFormik.values]);

	/*
	 * Callbacks
	 */
	const getEditorPermission = useCallback(async () => {
		if (!currentRecording) {
			throw new Error('No recording selected');
		}

		try {
			const { data } = currentRecording.albumId
				? await getAlbumEditors(currentRecording.albumId)
				: await getRecordingEditors(currentRecording.id);

			// Make sure TypeScript knows what type `editors` is here. If it can be two types, you need to handle that.
			// For example, if it's an array of ApiProjectEditor, or if it's a union, narrow it down.
			const editors: ApiProjectEditor[] = data.editors;
			const myEditorProfile = editors.find(editor => editor.user_id === userId);

			if (myEditorProfile?.is_owner || !myEditorProfile?.is_read_only) {
				setIsCoOwner(true);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setCoOwnerLoading(false);
		}
	}, [userId, currentRecording]);

	const generateGRid = async (releaseFormik: FormikProps<AlbumContent>) => {
		if (!currentAlbumId && !currentRecording?.albumId) {
			throw new Error('No album ID provided');
		}

		const albumId = currentAlbumId || currentRecording!.albumId!;

		try {
			setIsGenerateLoading(true);
			await dispatch(
				generateGRidAction({
					albumForm: releaseFormik,
					albumId,
					recordingId: currentRecordingId!,
				})
			);
		} catch (e) {
			console.error(e);
		} finally {
			setIsGenerateLoading(false);
		}
	};

	const handleSubmit = (
		values: AlbumContent,
		navigateOnSubmit: boolean = true
	) => {
		setIsSaving(true);

		const handleProceed = () => {
			setIsSaving(false);

			if (navigateOnSubmit)
				navigate(`../${ROUTES.EditRecordingCredits.relativePath}`);
		};

		if (currentRecording?.albumId) {
			dispatch(
				updateCloudAlbumAction(
					{
						...values,
					},
					handleProceed
				)
			);
		} else {
			dispatch(
				createCloudAlbumAction(
					{
						...values,
						isSingle: true,
					},
					async albumId => {
						await dispatch(
							addRecordingsToCloudAlbumAction(
								albumId,
								[currentRecordingId!],
								handleProceed
							)
						);

						await dispatch(
							fetchRecordingByIdAction({ id: currentRecordingId! })
						);

						dispatch(setCurrentAlbumIdAction(albumId, currentRecordingId!));
					}
				)
			);
		}
	};

	const handleSave = (
		isDirty: boolean,
		values: AlbumContent,
		hasAlbum: boolean,
		submit: typeof handleSubmit
	) => {
		if (isDirty && hasAlbum) {
			submit(values, false);
		}
	};

	/*
	 * Formik
	 */
	const releaseFormik = useFormik<AlbumContent>({
		initialValues,
		enableReinitialize: true,
		validationSchema: schema,
		onSubmit: values => handleSubmit(values),
	});

	const [recordLabels, setRecordLabels] = useState([
		...recordLabelOptions.options,
		{
			label: releaseFormik.values.recordLabel,
			value: releaseFormik.values.recordLabel,
		},
	]);

	const [genres, setGenres] = useState([
		...genreOptions.options,
		{
			label: releaseFormik.values.genre,
			value: releaseFormik.values.genre,
		},
	]);

	/*
	 * Effects
	 */
	useEffect(() => {
		getEditorPermission();
	}, [userId, currentAlbum, getEditorPermission]);

	useEffect(() => {
		if (isFetchingAlbum && currentRecording && currentRecording.albumId) {
			dispatch(fetchAlbumByIdAction(currentRecording.albumId));
		}
	}, [isFetchingAlbum, currentAlbum, currentRecording, dispatch]);

	useEffect(() => {
		if (
			requestStatus === REQUEST_FAILURE &&
			(requestLabel === UPDATE_ALBUM_REQUEST ||
				requestLabel === ADD_RECORDINGS_TO_ALBUM_REQUEST)
		) {
			setIsSaving(false);
		}
	}, [requestStatus, requestLabel]);

	useDebounceEffect(
		() =>
			handleSave(
				releaseFormik.dirty,
				releaseFormik.values,
				!!currentAlbum,
				handleSubmit
			),
		1000,
		[releaseFormik.dirty, releaseFormik.values, currentAlbum, handleSubmit]
	);

	useTaskBeforeUnmount(handleSave, [
		releaseFormik.dirty,
		releaseFormik.values,
		!!currentAlbum,
		handleSubmit,
	]);

	return (
		<>
			<Helmet>
				<title>
					{currentRecording?.albumId
						? `${currentAlbum?.title} - Album Info `
						: `${currentRecording?.title} - Release Info `}
					{process.env.REACT_APP_TAB_TITLE}
				</title>
			</Helmet>
			<FormikProvider value={releaseFormik}>
				<Form
					placeholder={null}
					className='h-100'
					onSubmit={releaseFormik.handleSubmit}
				>
					<Container className='h-100'>
						{isFetchingAlbum || coOwnerLoading ? (
							<SoundCreditLoader message='Retrieving Release Data...' />
						) : (
							<>
								<div className='pt-3' />

								<Card>
									<Card.Body>
										<Row>
											<Col xs={11}>
												<h2>{currentAlbumId ? 'ALBUM ' : 'RELEASE '}INFO</h2>
											</Col>
										</Row>
										<>
											<Row id='collapseRelease'>
												<>
													<Col xs={6}>
														<TextField
															label='Title'
															type='text'
															name='title'
															value={releaseFormik.values.title}
															isDisabled={!currentAlbumId}
															informationText={
																!currentAlbumId
																	? "To modify the title of the release, you must modify the song's name."
																	: ''
															}
															onChange={releaseFormik.handleChange}
															onBlur={releaseFormik.handleBlur}
															isInvalid={
																releaseFormik.touched.title &&
																!!releaseFormik.errors.title
															}
															errorMessage={releaseFormik.errors.title}
															readOnly={!isCoOwner}
														/>
													</Col>
													<Col xs={6}>
														<TextField
															label='Artist Name'
															type='text'
															name='artistName'
															value={releaseFormik.values.artistName}
															isDisabled={!currentAlbumId}
															informationText={
																!currentAlbumId
																	? "To modify the release's artist name, you must modify the song's main artist."
																	: ''
															}
															onChange={releaseFormik.handleChange}
															onBlur={releaseFormik.handleBlur}
															isInvalid={
																releaseFormik.touched.artistName &&
																!!releaseFormik.errors.artistName
															}
															errorMessage={releaseFormik.errors.artistName}
															readOnly={!isCoOwner}
														/>
													</Col>
													<Col xs={6}>
														<CreatableSelect
															label='Genre'
															name='genre'
															id='genre'
															options={genres}
															placeholder='Search...'
															value={genres.find(
																obj => releaseFormik.values.genre === obj.value
															)}
															onCreateOption={value => {
																const newOption = {
																	value: value,
																	label: value,
																};
																setGenres(options => [...options, newOption]);
																releaseFormik.setFieldValue('genre', value);
															}}
															onChange={option =>
																releaseFormik.setFieldValue(
																	'genre',
																	option?.value ?? ''
																)
															}
															isDisabled={!isCoOwner}
															errorFieldName='genre'
														/>
													</Col>
													<Col xs={6}>
														<CreatableSelect
															label='Record Label'
															name='recordLabel'
															id='recordLabel'
															options={recordLabels}
															placeholder='Search...'
															value={recordLabels.find(
																obj =>
																	releaseFormik.values.recordLabel === obj.value
															)}
															onCreateOption={value => {
																const newOption = {
																	value: value,
																	label: value,
																};
																setRecordLabels(options => [
																	...options,
																	newOption,
																]);
																releaseFormik.setFieldValue(
																	'recordLabel',
																	value
																);
															}}
															onChange={option =>
																releaseFormik.setFieldValue(
																	'recordLabel',
																	option?.value ?? ''
																)
															}
															onInputChange={val => {
																setRecordLabels(
																	matchSorter(
																		[...recordLabelOptions.options],
																		val,
																		{
																			keys: ['label', 'value'],
																		}
																	)
																);
															}}
															errorFieldName='recordLabel'
															isDisabled={!isCoOwner}
														/>
													</Col>

													<Col xs={6}>
														<DatePicker
															label='Release Date'
															name='releaseDate'
															value={releaseFormik.values.releaseDate}
															onChange={releaseFormik.handleChange}
															onBlur={releaseFormik.handleBlur}
															isDisabled={isReadOnly}
															isInvalid={
																releaseFormik.touched.releaseDate &&
																!!releaseFormik.errors.releaseDate
															}
															errorMessage={releaseFormik.errors.releaseDate}
															readOnly={!isCoOwner}
														/>
													</Col>
													<Col xs={6}>
														<Row>
															<Col xs={12}>
																{/* TODO: Uncomment when implementing GRid <Col xs={isCoOwner ? 9 : 12}> */}
																<TextField
																	label='GRid'
																	type='text'
																	name='grid'
																	value={releaseFormik.values.grid}
																	onChange={releaseFormik.handleChange}
																	onBlur={releaseFormik.handleBlur}
																	isDisabled={
																		isReadOnly ||
																		(!currentRecording?.albumId &&
																			!currentAlbumId)
																	}
																	buttonTooltipText={
																		!isReadOnly &&
																		!currentRecording?.albumId &&
																		!currentAlbumId
																			? 'You must create the release before generating a GRid'
																			: undefined
																	}
																	isInvalid={
																		releaseFormik.touched.grid &&
																		!!releaseFormik.errors.grid
																	}
																	errorMessage={releaseFormik.errors.grid}
																	readOnly={!isCoOwner}
																	buttonLabel={isCoOwner ? 'Generate' : ''}
																	onButtonClick={() =>
																		generateGRid(releaseFormik)
																	}
																	isButtonLoading={isGenerateLoading}
																	isButtonLocked={
																		!userPermissions?.canGenerateCodes
																	}
																/>
															</Col>
														</Row>
													</Col>

													<Col xs={6}>
														<TextField
															label='UPC/EAN'
															type='text'
															name='upcEan'
															value={releaseFormik.values.upcEan}
															onChange={releaseFormik.handleChange}
															onBlur={releaseFormik.handleBlur}
															readOnly={!isCoOwner}
															isInvalid={
																releaseFormik.touched.upcEan &&
																!!releaseFormik.errors.upcEan
															}
															errorMessage={releaseFormik.errors.upcEan}
														/>
													</Col>

													<Col xs={6}>
														<TextField
															label='Catalog ID'
															type='text'
															name='catalogId'
															value={releaseFormik.values.catalogId}
															onChange={releaseFormik.handleChange}
															onBlur={releaseFormik.handleBlur}
															isDisabled={isReadOnly}
															isInvalid={
																releaseFormik.touched.catalogId &&
																!!releaseFormik.errors.catalogId
															}
															errorMessage={releaseFormik.errors.catalogId}
															readOnly={!isCoOwner}
														/>
													</Col>

													<Col xs={6}>
														<TextField
															label='Version'
															type='text'
															id='version'
															name='version'
															onChange={releaseFormik.handleChange}
															onBlur={releaseFormik.handleBlur}
															value={releaseFormik.values.version}
															placeholder='(Optional)'
															readOnly={!isCoOwner}
														/>
													</Col>
												</>
											</Row>
										</>
									</Card.Body>
								</Card>
								<Col xs={{ span: 3, offset: 9 }} className='text-right'>
									{isCoOwner ? (
										<Button
											label={
												!currentRecording?.albumId
													? 'Create Release & Proceed'
													: 'Save & Proceed'
											}
											theme='dark'
											type='submit'
											isLoading={isSaving}
										/>
									) : (
										<Button
											label='Proceed'
											theme='dark'
											onClick={() =>
												navigate(
													`../${ROUTES.EditRecordingCredits.relativePath}`
												)
											}
										/>
									)}
								</Col>
							</>
						)}
					</Container>
				</Form>
			</FormikProvider>
		</>
	);
}

export default ReleaseDetails;
