import React, { useEffect, useMemo, useState } from 'react';
import './help.scss';
import Lottie from 'react-lottie';
import lightAnimationData from '../../../assets/animations/blackLoaderOnLightGrey.json';
import { Helmet } from 'react-helmet';
import helpVideos from '../../../constants/helpVideos';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { fetchHelpArticlesAction } from '@/store/help/actions';
import Markdown from 'react-markdown';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Card, Col, Container, Row } from 'react-bootstrap';
import SoundCreditLoader from '../SoundCreditLoader';
import { Carousel, CarouselResponsiveOption } from 'primereact/carousel';

const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: lightAnimationData,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice',
	},
};

function Help() {
	const dispatch = useAppDispatch();
	const { helpArticles } = useAppSelector(state => state.help);

	const [videos, setVideos] = useState([
		{
			src: helpVideos.TRANSFER_OVERVIEW_VIDEO,
			active: true,
			name: 'video-0',
		},
		{
			src: helpVideos.ADDING_PROJECTS_VIDEO,
			name: 'video-1',
			active: false,
		},
		{
			src: helpVideos.ADDING_PLAYLISTS_VIDEO,
			name: 'video-2',
			active: false,
		},
		{
			src: helpVideos.ABOUT_DRIPS_VIDEO,
			name: 'video-3',
			active: false,
		},
		{
			src: helpVideos.DRIP_STATS_VIDEO,
			name: 'video-4',
			active: false,
		},
	]);

	const inactiveVideos = useMemo(
		() => videos.filter(video => !video.active),
		[videos]
	);

	const [isLoading, setIsLoading] = useState(true);

	const hideSpinner = () => {
		let a = document.querySelectorAll('.fake-play-btn');

		setTimeout(() => {
			a.forEach(el => el.classList.remove('fake-play-btn-hidden'));
			setIsLoading(false);
		}, 400);
	};

	const obtainArticleVideo = (article: PortalHelpArticle) => {
		const parsedUrl: URL = new URL(article.videolink!);
		const host = parsedUrl.hostname;

		let videoData = {
			src: article.videolink!,
			active: true,
			name: article.title,
		};

		if (host === 'vimeo.com') {
			let videoId = parsedUrl.pathname;
			if (videoId.charAt(0) === '/') {
				videoId = videoId.substring(1);
			}

			videoData['src'] =
				'https://player.vimeo.com/video/' +
				videoId +
				'?title=0&byline=0&portrait=0';
			// videoData['src'] = 'https://player.vimeo.com/video/600532294?title=0&byline=0&portrait=0';
		}
		return videoData;
	};

	useEffect(() => {
		dispatch(fetchHelpArticlesAction());
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const VideoWrapper = ({ index }: { index?: number }) => {
		return (
			<div className='video-wrapper test-click'>
				<button
					className='fake-play-btn fake-play-btn-hidden'
					onClick={e => {
						if (index == null) return;
						e.preventDefault();
						changeActiveVideo(index);
					}}
				>
					<i className='fas fa-play'></i>
				</button>
			</div>
		);
	};

	const VideoTemplate = ({
		video,
		index,
	}: {
		video: Video;
		index?: number;
	}) => {
		const vidIndex = videos.findIndex(vid => vid.src === video.src);

		return (
			<div className='video-box'>
				{isLoading ? (
					<div className='video-box fake-video-box'>
						<Lottie
							options={defaultOptions}
							isClickToPauseDisabled
							height={75}
							width={85}
							style={{
								cursor: 'default',
								backgroundColor: 'transparent',
							}}
						/>
					</div>
				) : null}
				<iframe
					title={video.name}
					onLoad={hideSpinner}
					style={{
						width: '100%',
						height: '100%',
						display: isLoading ? 'none' : 'block',
					}}
					src={video.src + (!video.active ? '&controls=0' : '')}
					frameBorder='0'
					allow='fullscreen'
					allowFullScreen
				></iframe>
				{!video.active && <VideoWrapper index={vidIndex} />}
			</div>
		);
	};

	type Video = {
		src: string;
		active: boolean;
		name: string;
	};

	const VideoCarousel = ({ videoList }: { videoList: Video[] }) => {
		const responsiveOptions: CarouselResponsiveOption[] = [
			{
				breakpoint: '1400px',
				numVisible: 3,
				numScroll: 1,
			},
			{
				breakpoint: '1199px',
				numVisible: 3,
				numScroll: 1,
			},
			{
				breakpoint: '767px',
				numVisible: 2,
				numScroll: 1,
			},
			{
				breakpoint: '580px',
				numVisible: 1,
				numScroll: 1,
			},
		];

		return (
			<>
				{/* <div style={{ maxWidth: '100%' }}> */}
				<Carousel
					className='w-100'
					style={{ maxWidth: '100%' }}
					value={videoList}
					numScroll={1}
					numVisible={3}
					responsiveOptions={responsiveOptions}
					itemTemplate={(item: Video) => <VideoTemplate video={item} />}
				/>
				{/* </div> */}
			</>
		);
	};

	const changeActiveVideo = (changedVideoIndex: number) => {
		setIsLoading(true);

		// console.log("VIDEO CHANGED: ", videos[ changedVideoIndex ].name)
		let newVideos = [...videos];
		newVideos.map((video, index) => {
			if (index === changedVideoIndex) {
				video.active = true;
				return video;
			}

			if (video.active) {
				video.active = false;
				return video;
			}

			return video;
		});

		newVideos.sort((a, b) => {
			if (a.active > b.active) return -1;

			return 0;
		});
		// console.log(newVideos)

		setVideos(newVideos);
	};

	console.log(videos);

	return (
		<>
			<Helmet>
				<title>Help {process.env.REACT_APP_TAB_TITLE}</title>
			</Helmet>
			<Container>
				<div className='pt-3' />
				<Row>
					<Col>
						<Card className='py-4 px-5'>
							<h3>Videos</h3>
							<hr />
							<div className='box-wrapper'>
								<div className='large-box'>
									<VideoTemplate video={videos.find(video => video.active)!} />
								</div>
								<div className='boxes' style={{ maxWidth: '100%' }}>
									<VideoCarousel videoList={inactiveVideos} />
								</div>
								{/* <div className='boxes'>
									{videos.map((video, index) =>
										!video.active ? (
											<div className='video-box'>
												<VideoTemplate video={video} index={index} />
											</div>
										) : (
											<></>
										)
									)}
								</div> */}
							</div>
						</Card>
					</Col>
				</Row>

				{helpArticles?.length === 0 ? (
					<></>
				) : (
					<Row>
						<Col>
							<Card className='py-4 px-5'>
								<h3>Frequently Asked Questions (FAQ)</h3>
								<hr />

								{helpArticles ? (
									<Accordion>
										{helpArticles.map((article, index) => (
											<AccordionTab header={article.title}>
												<Markdown>{article.body}</Markdown>
												{article.videolink && (
													<div className='box-wrapper'>
														<div className='large-box'>
															<VideoTemplate
																video={obtainArticleVideo(article)}
															/>
														</div>
													</div>
												)}
											</AccordionTab>
										))}
									</Accordion>
								) : (
									<SoundCreditLoader />
								)}
							</Card>
						</Col>
					</Row>
				)}

				<Row>
					<Col>
						<Card className='p-5 box-footer'>
							<div style={{ textAlign: 'center' }}>
								{/* <span className='CONTACT-US-TO-HIRE-O'>
									CONTACT US TO HIRE OUR FULL SERVICE PRODUCTION MANAGERS
								</span> */}
								<span className='CONTACT-US-TO-HIRE-O'>
									NEED HELP OR HAVE A SUGGESTION?
								</span>
							</div>
							<div style={{ textAlign: 'center' }}>
								{/* <p className='Our-production-manag'>
									Our production managers will do all the work for you. Contact
									us at
									<span
										className='ml-1 text-purple text-decoration-underline'
										style={{ textDecoration: 'underline' }}
									>
										pm@soundcredit.com
									</span>
								</p> */}
								<p className='Our-production-manag'>
									Reach out to us at
									<span
										className='ml-1 text-purple text-decoration-underline'
										style={{ textDecoration: 'underline' }}
									>
										support@soundcredit.com
									</span>
									!
								</p>
							</div>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default React.memo(Help);
