import { formatDateToHtml } from '../components/utils/dateFormatters';

const translateApiAlbumToLocal = (apiAlbum, owner) => {
	const localAlbum = {
		...apiAlbum,
		createdAt: apiAlbum.created_at,
		updatedAt: apiAlbum.updated_at,
		userId: apiAlbum.user_id,
		assetsCount: apiAlbum.assets_count,
		exports: [],
		isSingle: apiAlbum.is_single,
		isArchived: apiAlbum.is_archived || false,
		ownerName: apiAlbum.owner_name,
		album: apiAlbum.album
			? {
					...apiAlbum.album,
					releaseDate: formatDateToHtml(apiAlbum.album.releaseDate),
			  }
			: apiAlbum.album,
	};

	// When the album is fully fetched, it has a null owner_name
	// As opposed to the user projects endpoint, which has the correct owner_name
	if (!localAlbum.ownerName) {
		localAlbum.ownerName = owner;
	}

	delete localAlbum.user_id;
	delete localAlbum.created_at;
	delete localAlbum.updated_at;
	delete localAlbum.assets_count;
	delete localAlbum.is_single;
	delete localAlbum.is_archived;
	delete localAlbum.owner_name;

	// console.log('FETCHED ALBUM', apiAlbum, 'lOCAL ALBUM', localAlbum);
	return localAlbum;
};

export default translateApiAlbumToLocal;
