import { ErrorMessage } from 'formik';
import React, { useLayoutEffect, useMemo } from 'react';
import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import ReactCreatableSelect, { CreatableProps } from 'react-select/creatable';
import theme from '../../../theme.module.scss';
import selectStyles from '../Select/selectStyles';
import { v4 as uuidv4 } from 'uuid';
import { isArray } from 'lodash';
import {
	GetOptionLabel,
	GroupBase,
	OnChangeValue,
	PropsValue,
	StylesConfig,
} from 'react-select';

const creatableStyles = {
	...selectStyles,
	input: (provided: any) => ({
		...provided,
		input: {
			opacity: '1 !important', // This is a hack to fix a bug in our CreatableSelect where the input is not visible when the menu closes after selecting an option.
		},
	}),
};

export type CreatableSelectProps<OptionType, IsMulti extends boolean> = {
	name?: string;
	id?: string;
	options: ({ options: OptionType[] } | OptionType)[];
	placeholder?: string;
	value: PropsValue<OptionType> | undefined;
	onCreateOption: (newValue: string) => void;
	onChange: CreatableProps<
		OptionType,
		IsMulti,
		GroupBase<OptionType>
	>['onChange'];
	// onChange: (newValue: OptionType) => void;
	label?: string | React.ReactNode;
	isMulti?: IsMulti;
	errorFieldName?: string;
	getOptionLabel?: GetOptionLabel<OptionType>;
	getOptionValue?: CreatableProps<
		OptionType,
		IsMulti,
		GroupBase<OptionType>
	>['getOptionValue'];
	getNewOptionData?: (inputValue: string, optionLabel: React.ReactNode) => any;
	isDisabled?: boolean;
	isSearchable?: boolean;
	className?: string;
	onInputChange?: (inputValue: string, actionMeta: any) => void;
	noOptionsMessage?: (obj: any) => React.ReactNode;
	onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
	onBlur?: React.FocusEventHandler<HTMLInputElement>;
	isGrouped?: boolean;
	hideDropdownIndicator?: boolean;
	menuIsOpen?: boolean;
	inputValue?: string;
	dense?: boolean;
	createOnBlur?: boolean;
	preserveInputOnBlur?: boolean;
	createOptionPosition?: 'first' | 'last';
	isClearable?: boolean;
	styles?: StylesConfig;
	isOptionDisabled?: (option: OptionType) => boolean;
	closeMenuOnSelect?: boolean;
	defaultValue?: OptionType;
	filterOption?: (option: any, rawInput: string) => boolean;
	formatOptionLabel?: CreatableProps<
		OptionType,
		IsMulti,
		GroupBase<OptionType>
	>['formatOptionLabel'];
	informationText?: React.ReactNode;
	// only used to hide info message when there is a validation error
	isInvalid?: boolean;
};

const getInputValue = <OptionType extends unknown>(
	getOptionLabel: GetOptionLabel<OptionType>,
	selectedValue: PropsValue<OptionType> | undefined
) =>
	selectedValue
		? isArray(selectedValue)
			? ''
			: getOptionLabel(selectedValue as NonNullable<OptionType>)
		: '';

const CreatableSelect = <
	OptionType extends unknown = any,
	IsMulti extends boolean = false
>({
	name,
	id = uuidv4(),
	options,
	placeholder,
	value: selectedValue,
	onCreateOption,
	onChange,
	onBlur,
	label,
	isMulti,
	errorFieldName,
	getOptionLabel = (option: OptionType) =>
		(!!(option && typeof option === 'object' && 'label' in option) &&
			(option?.label as string)) ||
		'',
	getOptionValue,
	getNewOptionData,
	isDisabled,
	isSearchable,
	className,
	onInputChange,
	noOptionsMessage,
	onKeyDown,
	isGrouped = false,
	hideDropdownIndicator = undefined,
	menuIsOpen = undefined,
	inputValue,
	dense = false,
	createOnBlur = true,
	preserveInputOnBlur = true,
	createOptionPosition = 'first',
	isClearable = false,
	styles = {},
	isOptionDisabled,
	closeMenuOnSelect = true,
	defaultValue,
	filterOption,
	formatOptionLabel,
	informationText,
	isInvalid = false,
}: CreatableSelectProps<OptionType, IsMulti>) => {
	const [_inputValue, setInputValue] = React.useState(
		getInputValue(getOptionLabel, selectedValue)
	);
	const flattenedOptions = useMemo(() => {
		return options.reduce((acc, option) => {
			// type gymnastics, don't ask why
			if (option && typeof option === 'object' && 'options' in option) {
				return [...acc, ...option.options];
			}
			return [...acc, option];
		}, [] as OptionType[]);
	}, [options]);

	useLayoutEffect(() => {
		const inputEl = document.getElementById(id);
		if (!inputEl) {
			return;
		}
		// prevent input from being hidden after selecting
		inputEl.style.opacity = '1';
	}, [selectedValue, id]);

	const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		if (!createOnBlur) {
			onBlur?.(e);

			return;
		}

		const { value: newValue } = e.target;

		const existingOption = flattenedOptions.find(
			(option: any) => getOptionLabel(option)?.trim() === newValue?.trim()
		);

		if (newValue && !existingOption) {
			// create and select the new option
			onCreateOption(newValue);
		}

		if (existingOption) {
			// select the existing option
			onChange?.(existingOption as OnChangeValue<OptionType, IsMulti>, {
				action: 'select-option',
				option: existingOption,
			});
		}

		if (!preserveInputOnBlur) {
			setInputValue('');
		}

		onBlur?.(e);
	};

	const handleInputChange = (
		newValue: string,
		{
			action,
		}: {
			action: string;
		}
	) => {
		if (action === 'input-change') {
			setInputValue(newValue);
		}

		if (action === 'menu-close' && !newValue && selectedValue) {
			setInputValue(
				isArray(selectedValue)
					? ''
					: getOptionLabel(selectedValue as NonNullable<OptionType>)
			);
		}

		if (onInputChange) {
			onInputChange(newValue, { action });
		}
	};

	const previousValue = React.useRef(selectedValue);

	const _onChange = (
		newValue: OnChangeValue<OptionType, IsMulti>,
		actionMeta: any
	) => {
		if (!newValue) {
			setInputValue('');
		}

		if (onChange) {
			onChange(newValue, actionMeta);
		}
	};

	useEffect(() => {
		if (
			preserveInputOnBlur &&
			selectedValue &&
			!isArray(selectedValue) &&
			getOptionLabel(selectedValue as NonNullable<OptionType>) &&
			previousValue.current !== selectedValue
		) {
			setInputValue(getOptionLabel(selectedValue as NonNullable<OptionType>));
			previousValue.current = selectedValue;
		}
	}, [selectedValue, getOptionLabel, _inputValue, preserveInputOnBlur]);

	// const handleChange = (value, { action }) => {

	return (
		<Form.Group className={`${!dense ? 'mb-4' : ''}`}>
			<Form.Label htmlFor={name}>{label}</Form.Label>
			<ReactCreatableSelect
				// components={
				// 	hideDropdownIndicator
				// 		? { DropdownIndicator: null }
				// 		: isGrouped
				// 		? {}
				// 		: { MenuList }
				// }
				createOptionPosition={createOptionPosition}
				styles={{ ...creatableStyles }}
				isMulti={isMulti}
				name={name}
				id={id || `select-${name}`}
				inputId={id || `select-${name}`}
				options={options}
				className={className}
				classNamePrefix='select'
				placeholder={placeholder}
				value={selectedValue}
				onCreateOption={onCreateOption}
				onChange={_onChange}
				getOptionLabel={getOptionLabel}
				getOptionValue={getOptionValue}
				getNewOptionData={getNewOptionData}
				isDisabled={isDisabled}
				isSearchable={isSearchable}
				onInputChange={handleInputChange}
				noOptionsMessage={noOptionsMessage}
				menuIsOpen={menuIsOpen && !hideDropdownIndicator}
				onKeyDown={onKeyDown}
				inputValue={preserveInputOnBlur ? _inputValue : inputValue}
				onBlur={handleBlur}
				isClearable={isClearable}
				closeMenuOnSelect={closeMenuOnSelect}
				isOptionDisabled={isOptionDisabled}
				defaultValue={defaultValue}
				filterOption={filterOption}
				formatOptionLabel={formatOptionLabel}
			/>
			{!isInvalid && informationText && (
				<Form.Text
					className='text-muted'
					style={{ height: 0, position: 'absolute' }}
				>
					{informationText}
				</Form.Text>
			)}
			{errorFieldName && (
				<ErrorMessage name={errorFieldName}>
					{msg => {
						console.log('msg', msg);

						return (
							<small
								style={{ color: theme.error, height: 0, position: 'absolute' }}
								className='mt-1'
							>
								{msg}
							</small>
						);
					}}
				</ErrorMessage>
			)}
		</Form.Group>
	);
};

export default CreatableSelect;
