import axios from 'axios';
import { store } from '../store';
import humps from 'humps';

const profilesApi = axios.create({
	baseURL:
		process.env.NODE_ENV === 'production'
			? process.env.REACT_APP_LOCAL_PROFILES_API
			: '/local-profiles',
});

profilesApi.interceptors.request.use(config => {
	const { userId, userToken } = store.getState().auth;

	if (userId && userToken && config.headers) {
		config.headers['publisher-user-id'] = userId;
		config.headers['publisher-user-token'] = userToken;
	}

	// Do not decamelize body data, since it should be stored in the database as is
	// And other platforms potentially depend on it being camelCased

	if (config.params) {
		config.params = humps.decamelizeKeys(config.params);
	}

	return config;
});

profilesApi.interceptors.response.use(response => {
	if (response.data) {
		response.data = humps.camelizeKeys(response.data);
	}

	return response;
});

export default profilesApi;
