const formatListToLanguage = (list: string[]) => {
	if (list.length === 1) {
		return list[0];
	}

	// join all items with commas except the last one
	const joined = list.slice(0, -1).join(', ');

	// add the last item with 'and' before it
	return `${joined} and ${list.slice(-1)}`;
};

export default formatListToLanguage;
