import { camelizeKeys } from 'humps';

const translateApiExportToLocal = (
	apiExport: ApiExportMetadata
): ExportMetadata => {
	const localExport = {
		id: apiExport.id,
		path: apiExport.path,
		uuid: apiExport.uuid,
		filename: apiExport.file_name,
		exportType: apiExport.export_type,
		userId: apiExport.user_id,
		albumId: apiExport.album_id,
		recordingId: apiExport.recording_id,
		createdAt: apiExport.created_at,
		updatedAt: apiExport.updated_at,
		esignature: apiExport.esignature
			? (camelizeKeys(apiExport.esignature) as ESignature)
			: null,
	};

	return localExport;
};

export default translateApiExportToLocal;
