import styles from './PlaylistFileProjectInfo.module.scss';
import IconButton from '../../../../../layout/IconButton/IconButton';
import theme from '@/theme.module.scss';
import { navigateToProjectEditor } from '../../../../../../helpers/projectTools';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import ROUTES from '../../../../../../router/routes';
import {
	getFileProject,
	isAudioFile,
} from '../../../../../../helpers/fileTools';
import { EDIT_FILE_PROJECT_MODAL } from '../../../../../../constants/modalTypes';
import { showModalAction } from '../../../../../../store/modal/actions';
import PillButton from '../../../../../layout/PillButton/PillButton';
import clsx from 'clsx';
import { Tooltip } from 'primereact/tooltip';
import { useMemo } from 'react';

export type PlaylistFileProjectInfoProps = {
	file: PlaylistTableFile;
	className?: string;
	style?: React.CSSProperties;
	isSelected?: boolean;
	hideActions?: boolean;
};

const PlaylistFileProjectInfo = ({
	file,
	className = '',
	style = {},
	isSelected = false,
	hideActions = false,
}: PlaylistFileProjectInfoProps) => {
	const navigate = useNavigate();
	const { currentPlaylistId } = useAppSelector(state => state.playlists);
	const dispatch = useAppDispatch();
	const { albumsById } = useAppSelector(state => state.projects);

	const project = useMemo(() => getFileProject(file), [file]);

	// show filename for non-audio files
	// for audio files, show project title
	const title = isAudioFile(file.filename) ? project?.title : file.filename;

	const subtitle = isAudioFile(file.filename) ? (
		<span className={styles['artist']}>{project?.artist}</span>
	) : (
		<>
			<span className={styles['artist']}>{project?.title}</span>
			<span className={styles['artist']}>{project?.artist}</span>
		</>
	);

	const titleId = `file-project-title-${file.id}-${currentPlaylistId}`;
	return (
		<>
			<div
				className={className}
				style={{
					...style,
				}}
			>
				<div
					className={clsx(styles['info-container'], {
						[styles['selected']]: isSelected,
					})}
				>
					<div className={styles['info-content']}>
						<div>
							{!hideActions && (
								<Tooltip
									target={`#${titleId}`}
									content={title}
									position='top'
									showDelay={2000}
								/>
							)}
							<span
								id={titleId}
								className={styles['title']}
								// data-pr-tooltip={project?.title}
							>
								{title}
							</span>
							<div>{subtitle}</div>
						</div>
					</div>

					{!hideActions && (
						<div className={styles['info-actions']}>
							<IconButton
								icon='fas fa-edit'
								tooltipText={`Edit ${file.album ? 'Album' : 'Recording'} Name`}
								color={theme.primary}
								style={{
									width: '2rem',
									height: '2rem',
								}}
								onClick={() => {
									dispatch(
										showModalAction(EDIT_FILE_PROJECT_MODAL, {
											recordingId: file.recording?.id ?? null,
											albumId: file.album?.id ?? null,
											playlistId: currentPlaylistId,
											size: 'md',
										})
									);
								}}
							/>
							<PillButton
								// icon='fas fa-users'
								// theme='solid-light'
								label='Credits'
								// tooltipText='View Credits'
								// color={theme.primary}
								style={{
									fontSize: '0.8rem',
								}}
								onClick={() => {
									if (!('recordingId' in file)) {
										return;
									}

									navigateToProjectEditor({
										albumId: file.albumId || file.recording?.albumId,
										recordingId: file.recordingId,
										navigate,
										dispatch,
										route: ROUTES.EditRecordingCredits.path,
										albumsById,
									});
								}}
							/>
						</div>
					)}
				</div>
			</div>
		</>
	);
};

export default PlaylistFileProjectInfo;
