import Lottie from 'react-lottie';
import waveformAnimation from '@/assets/animations/waveform-primary.json';

const NowPlayingIcon = () => {
	return (
		<Lottie
			options={{
				loop: true,
				autoplay: true,
				animationData: waveformAnimation,
				rendererSettings: {
					preserveAspectRatio: 'xMidYMid slice',
				},
			}}
			isClickToPauseDisabled
			height={30}
			width={30}
			style={{ cursor: 'default' }}
		/>
	);
};

export default NowPlayingIcon;
