import { StylesConfig } from 'react-select';
import theme from '../../../theme.module.scss';

const selectStyles = {
	dropdownIndicator: provided => ({
		...provided,
		svg: {
			fill: theme.primary,
		},
	}),
	control: (provided, state) => ({
		...provided,
		fontSize: theme.formInputFontSize,
		fontWeight: theme.formInputFontWeight,
		border: theme.formInputBorder,
		borderColor: state.isFocused
			? theme.focusFormInputBorderColor
			: theme.formInputBorderColor,
		boxShadow: state.isFocused
			? `0 0 0 1px ${theme.focusFormInputBorderColor}`
			: 'none',
		'&:hover': {
			borderColor: state.isFocused
				? theme.focusFormInputBorderColor
				: theme.formInputBorderColor,
		},
	}),
	option: (provided, state) => ({
		...provided,
		fontSize: theme.formInputFontSize,
		backgroundColor: state.isFocused ? theme.primaryTransparent : 'white',
		color: 'black',
	}),
	placeholder: provided => ({
		...provided,
		color: theme.placeholderTextColor,
	}),
	menu: provided => ({
		...provided,
		maxHeight: 350,
	}),
	menuList: provided => ({
		...provided,
		maxHeight: 350,
	}),
} as StylesConfig;

export default selectStyles;
