import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';

const ActionTopBar = props => {
	const { children } = props;

	return (
		<div {...props}>
			<Row className='w-100 m-0'>
				<Col xs={12} className='p-0'>
					<Card className='w-100'>
						<Card.Body className='container-horizontal-padding'>
							{children}
						</Card.Body>
					</Card>
				</Col>
			</Row>
		</div>
	);
};

export default ActionTopBar;
