import IconButton from '../../../../layout/IconButton';
import FileThumbnail from '../../FileThumbnail/FileThumbnail';
import '../../FileListItem/FileListItem.scss';
import theme from '../../../../../theme.module.scss';
import OverflowMenu from '../../../../layout/OverflowMenu';
import React, { useCallback, useMemo } from 'react';
import FileListItem from '../../FileListItem';
import { getUploadedOverflowMenuOptions } from '../../../../../helpers/fileTools';
import FileTagChip from '../../FileTagChip';
import { showModalAction } from '../../../../../store/modal/actions';
import {
	EDIT_FILE_TAG_MODAL,
	EDIT_PLAYLIST_FILE_MODAL,
} from '../../../../../constants/modalTypes';
import { selectDownloadPathAndDownload } from '../../../../../helpers/downloadTools';
import { useNavigate } from 'react-router-dom';
import FileVersionsButton from '../../../../layout/FileVersionsButton/FileVersionsButton';
import { BrowserFileSelection } from '../../FilesBrowser/FilesBrowser';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';

export type ProjectFilesListItemProps = {
	file: FileMetadata;
	isSelected: boolean;
	setSelectedFiles: React.Dispatch<React.SetStateAction<BrowserFileSelection>>;
};

const ProjectFilesListItem = ({
	file,
	isSelected,
	setSelectedFiles,
}: ProjectFilesListItemProps) => {
	const dispatch = useAppDispatch();
	const { myEditorProfile } = useAppSelector(state => state.projects);
	const { userPermissions } = useAppSelector(state => state.auth);
	const { filesByProjectId } = useAppSelector(state => state.files);

	const navigate = useNavigate();

	const isReadOnly = useMemo(
		() => (myEditorProfile ? myEditorProfile.is_read_only : true),
		[myEditorProfile]
	);

	const overflowMenuOptions = useMemo(
		() =>
			getUploadedOverflowMenuOptions(
				file,
				dispatch,
				userPermissions!.canShareFiles,
				!isReadOnly,
				filesByProjectId,
				navigate
			),
		[file, dispatch, isReadOnly, userPermissions, filesByProjectId, navigate]
	);

	const handlePreview: React.MouseEventHandler<HTMLElement> = useCallback(
		e => {
			document.body.click(); // close the overflow menu

			overflowMenuOptions.find(option => option.name === 'Preview')?.onClick(e);
		},
		[overflowMenuOptions]
	);

	const handleTitleClick: React.MouseEventHandler<HTMLElement> = useCallback(
		e => handlePreview(e),
		[handlePreview]
	);

	const handleFileDownload: React.MouseEventHandler<HTMLElement> = useCallback(
		e => {
			e.stopPropagation();

			selectDownloadPathAndDownload({ file, dispatch });
		},
		[dispatch, file]
	);

	// const handleSaveFileCover = useCallback(
	// 	imageBlob => dispatch(uploadFileCoverImageAction(file.id, imageBlob)),
	// 	[file, dispatch]
	// );

	const handleEditFileCover: React.MouseEventHandler<HTMLElement> = useCallback(
		e => {
			e.stopPropagation();

			dispatch(
				showModalAction(EDIT_PLAYLIST_FILE_MODAL, {
					removeHeaderPadding: true,
					size: 'sm',
					fileId: file.id,
				})
			);
		},
		[dispatch, file]
	);

	const renderThumbnail = useCallback(
		() => (
			<FileThumbnail
				file={file}
				onHoverClick={!isReadOnly ? handleEditFileCover : undefined}
			/>
		),
		[file, handleEditFileCover, isReadOnly]
	);

	const renderTag = useCallback(() => {
		const handleTagClick = (e: React.MouseEvent<HTMLElement>) => {
			e.stopPropagation();

			dispatch(
				showModalAction(EDIT_FILE_TAG_MODAL, {
					fileId: file.id,
					size: 'md',
				})
			);
		};

		return (
			<FileTagChip
				tag={file.label}
				onClick={handleTagClick}
				isReadOnly={isReadOnly}
			/>
		);
	}, [file.label, file.id, dispatch, isReadOnly]);

	const renderActions = useCallback(
		() => (
			<>
				<FileVersionsButton file={file} />
				<IconButton
					icon='fas fa-download'
					onClick={handleFileDownload}
					color={theme.primary}
					tooltipText='Download'
				/>
				<OverflowMenu
					options={overflowMenuOptions}
					id={`file-${file.id}`}
					className='m-0'
				/>
			</>
		),
		[handleFileDownload, overflowMenuOptions, file]
	);

	return (
		<FileListItem
			id={file.id}
			filename={file.filename}
			size={file.fileSize}
			creationDate={file.createdAt}
			isSelected={isSelected}
			setSelectedFiles={setSelectedFiles}
			thumbnail={renderThumbnail}
			actions={renderActions}
			onBodyClick={handlePreview}
			onTitleClick={handleTitleClick}
			tag={renderTag}
			version={file?.versions?.[file?.activeVersion]}
		/>
	);
};

export default ProjectFilesListItem;
