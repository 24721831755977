import { formatDateToHtml } from '../components/utils/dateFormatters';

const translateApiRecordingToLocal = (
	recording: any,
	owner: string | null = null
): Recording => {
	// change snake_case to camelCase for local use, change date strings to objects
	if (recording.recording) console.log('TRANSLATING RECORDING', recording);

	const translatedRec = {
		...recording,
		userId: recording.user_id,
		albumId: recording.album_id,
		createdAt: recording.created_at,
		updatedAt: recording.updated_at,
		assetsCount: recording.assets_count,
		exports: [],
		isArchived: recording.is_archived || false,
		ownerName: recording.owner_name,
		recording: recording.recording
			? {
					...recording.recording,
					dateMastered: formatDateToHtml(recording.recording.dateMastered),
					recordingDate: formatDateToHtml(recording.recording.recordingDate),
					participants: recording.recording.participants.map(
						(p: Participant) => ({
							...p,
							dateOfBirth: formatDateToHtml(p.dateOfBirth),
							participationDate: formatDateToHtml(p.participationDate),
						})
					),
			  }
			: recording.recording,
	};

	if (!translatedRec.ownerName) {
		translatedRec.ownerName = owner;
	}

	// Some recordings in the database do not have an ID field, so we need to add it here
	if (translatedRec.recording && !translatedRec.recording.id)
		translatedRec.recording.id = translatedRec.id;

	delete translatedRec.user_id;
	delete translatedRec.album_id;
	delete translatedRec.created_at;
	delete translatedRec.updated_at;
	delete translatedRec.assets_count;
	delete translatedRec.is_archived;
	delete translatedRec.owner_name;

	return translatedRec;
};

export default translateApiRecordingToLocal;
