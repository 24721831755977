import { Button, ButtonProps } from 'primereact/button';
import styles from './ProjectsPlaceholderButton.module.scss';
import React from 'react';
import clsx from 'clsx';
import SoundCreditLoader from '../../../SoundCreditLoader';

interface ProjectsPlaceholderButtonProps extends ButtonProps {
	imageSrc: string;
	title: string;
	featureList: string[];
}

const ProjectsPlaceholderButton = ({
	imageSrc,
	title,
	featureList,
	onClick,
	loading,
	...props
}: ProjectsPlaceholderButtonProps) => {
	return (
		<Button
			text
			raised
			onClick={onClick}
			{...props}
			className={clsx('p-0', props.className)}
			disabled={loading || props.disabled}
		>
			<div className={styles['button-container']}>
				{loading ? (
					<SoundCreditLoader />
				) : (
					<img draggable={false} src={imageSrc} alt={title} />
				)}
				<div className={styles['button-content']}>
					<h3>{title}</h3>
					<hr className='my-1' />
					<ul>
						{featureList.map((feature, index) => (
							<li key={index}>{feature}</li>
						))}
					</ul>
				</div>
			</div>
		</Button>
	);
};

export default ProjectsPlaceholderButton;
