import React, { useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
	validateMagicLinkAction,
	validateTokenAction,
} from '../../store/auth/actions';
import { VALIDATING_TOKEN, VALID_TOKEN } from '../../store/actionTypes';
import {
	fetchNotificationsAction,
	fetchUserDataAction,
} from '../../store/user/actions';
import { fetchFileLabelDetailsAction } from '../../store/files/actions';
import {
	fetchUserEditableProjectsAction,
	getUserProjectsAction,
} from '../../store/projects/actions';
import { useCallback } from 'react';
import SoundCreditLoader from '../screens/SoundCreditLoader';
import theme from '../../theme.module.scss';
import { isMobile } from 'react-device-detect';
import { isAuthenticatedSelector } from '../../store/auth/selectors';
import { useSearchParams } from 'react-router-dom';

type AuthWrapperProps = {
	children: React.ReactNode;
};

const AuthWrapper = ({ children }: AuthWrapperProps) => {
	const dispatch = useAppDispatch();
	const { userId, userToken, tokenStatus, isSigningUp } = useAppSelector(
		state => state.auth
	);
	const { storageUsage, fileLabelDetails } = useAppSelector(
		state => state.files
	);
	const { projectUsage, recordingsById } = useAppSelector(
		state => state.projects
	);
	const isAuthenticated = useAppSelector(isAuthenticatedSelector);

	const [searchParams, setSearchParams] = useSearchParams();

	const isLoading = useMemo(() => {
		return (
			!isSigningUp && // the sign up flow validates the token at some point, and we don't want to show the loader in that case
			userToken &&
			(tokenStatus === VALIDATING_TOKEN ||
				!storageUsage ||
				!projectUsage ||
				!fileLabelDetails ||
				!recordingsById)
		);
	}, [
		tokenStatus,
		storageUsage,
		projectUsage,
		fileLabelDetails,
		recordingsById,
		isSigningUp,
		userToken,
	]);

	const fetchUserData = useCallback(async () => {
		dispatch(fetchUserDataAction());
		dispatch(fetchFileLabelDetailsAction());
		dispatch(fetchUserEditableProjectsAction()); // fetch recording and album editable permissions
		dispatch(getUserProjectsAction());
	}, [dispatch]);

	const fetchNotifications = useCallback(async () => {
		if (!isAuthenticated) return;

		await Promise.resolve(dispatch(fetchNotificationsAction()));
	}, [isAuthenticated, dispatch]);

	// fetch notifications
	useEffect(() => {
		const notificationsInterval = setInterval(async () => {
			fetchNotifications();
		}, 10000);

		return () => clearInterval(notificationsInterval);
	}, [dispatch, fetchNotifications]);

	useEffect(() => {
		if (userToken && userId && !tokenStatus) {
			dispatch(validateTokenAction(userToken, userId));
		}

		if (!isSigningUp && tokenStatus === VALID_TOKEN) {
			console.log(`LOGGED IN WITH TOKEN ${userToken} AND ID ${userId}`);
			fetchUserData();
		}
	}, [userToken, userId, tokenStatus, dispatch, fetchUserData, isSigningUp]);

	useEffect(() => {
		if (isMobile) {
			window.location.replace('https://www.soundcredit.com');
		}
	}, []);

	useEffect(() => {
		const magicLinkToken = searchParams.get('email_link');
		if (!magicLinkToken) return;
		dispatch(validateMagicLinkAction(magicLinkToken));
	}, []);

	if (isLoading || isMobile) {
		// if we're not logged in, show the login screen
		return (
			// <div style={{ height: '100vh', backgroundColor: theme.backgroundDark }}>
			<div style={{ height: '100vh', backgroundColor: '#121128' }}>
				<SoundCreditLoader theme='dark' />
			</div>
		);
	}

	return children;
};

export default AuthWrapper;
