import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../layout/Button';
import {
	nextExportStepAction,
	setExportPayloadAction,
	setUnionExportRecordingRowsAction,
} from '../../../../../store/exports/actions';
import SelectRecordings from '../../../SelectRecordings/SelectRecordings';
import { fetchMultipleCloudProjectsAction } from '../../../../../store/projects/actions';
import exportsIds from '../../../../../constants/exportsIds';
import { getCurrentAlbum } from '../../../../../store/projects/selectors';
import theme from '../../../../../theme.module.scss';
import exportTypes from '../../../../../constants/exportType';
import { getTrackNumberById } from '../../../../../helpers/albumTools';

const LoadRecordingsStep = ({ onCancel }) => {
	/*
	 * Redux Hooks
	 */
	const { exportType } = useSelector(state => state.exports);
	const { currentAlbumId } = useSelector(state => state.projects);
	const { selectedRecordingRows } = useSelector(
		state => state.exports.unionForm
	);
	const currentAlbum = useSelector(getCurrentAlbum);
	const dispatch = useDispatch();

	/*
	 * React Hooks
	 */
	const [loading, setLoading] = useState(false);
	const [showValidationError, setShowValidationError] = useState(false);

	const initialSelectedRecordingRowIds = useMemo(() => {
		const rowIds = {};

		selectedRecordingRows.forEach(row => {
			rowIds[row.rowId] = true;
		});

		return rowIds;
	}, [selectedRecordingRows]);

	const selectedRecordingIds = useMemo(
		() => selectedRecordingRows.map(row => row.recordingId),
		[selectedRecordingRows]
	);

	const validationError = useMemo(() => {
		console.log('recompute validation error');
		if (selectedRecordingRows.length === 0) {
			return 'You must select at least one recording.';
		}

		if (exportType === exportTypes.AFM_B4 && selectedRecordingRows.length > 6) {
			return 'You cannot select more than 6 recordings.';
		}

		setShowValidationError(false);
		return '';
	}, [selectedRecordingRows, exportType]);

	/*
	 * Callbacks
	 */
	const navigateToNextStep = () => {
		if (validationError) {
			setShowValidationError(true);
			return;
		}

		setLoading(true);

		dispatch(
			fetchMultipleCloudProjectsAction(selectedRecordingIds, recordings => {
				// preserve original track order, as the selection or fetch order might be different
				dispatch(
					setExportPayloadAction(
						{
							report_type: exportsIds[exportType],
							recording_id: null,
							album_id: currentAlbumId,
							recordings: recordings.map((recording, index) => ({
								...recording.recording,
								albums: [
									{
										...currentAlbum.album,
										trackNumber: getTrackNumberById(
											currentAlbum.recordings,
											recording.id
										),
									},
								],
							})),
							is_final_version: true,
						},
						exportType
					)
				);

				setLoading(false);

				dispatch(nextExportStepAction());
			})
		);
	};

	const recordingFilter = useCallback(
		recording => recording.albumId === currentAlbumId,
		[currentAlbumId]
	);

	const onSelect = rows => {
		console.log('select', rows);
		dispatch(setUnionExportRecordingRowsAction(rows));
	};

	useEffect(() => {
		console.log(
			'INITIAL SELECTED RECORDING ROW IDS',
			initialSelectedRecordingRowIds
		);
	}, [initialSelectedRecordingRowIds]);

	return (
		<>
			<Card>
				<Card.Body>
					<h2>SELECT RECORDING(S)</h2>

					{showValidationError && (
						<p className='body-text' style={{ color: theme.error }}>
							{validationError}
						</p>
					)}

					<SelectRecordings
						onSelect={onSelect}
						recordingFilter={recordingFilter}
						rowMapper={row => ({
							recordingId: row.original.id,
							rowId: row.id,
						})}
						initialSelectedRowIds={initialSelectedRecordingRowIds}
						memoizeInitialSelectedRowIds={false}
					/>
					{/* <FileInput label='Select and Load Recording(s)' placeholder='Browse' /> */}
				</Card.Body>
			</Card>
			<Row>
				<Col xs={12} className='d-flex justify-content-end align-items-center'>
					<Button label='Cancel' className='mr-2' onClick={onCancel} />
					<Button
						label='Next'
						onClick={navigateToNextStep}
						theme='dark'
						isLoading={loading}
					/>
				</Col>
			</Row>
		</>
	);
};

export default LoadRecordingsStep;
