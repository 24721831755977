import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import {
	computeFilenames,
	navigateToProjectFiles,
	validateStorageUsageAndCorruptedFiles,
} from '../../../../helpers/fileTools';
import {
	addToUploadQueueAction,
	getProjectFilesMetadataAction,
	getStorageUsageAction,
} from '../../../../store/files/actions';
import {
	getUploadingStorageUsage,
	groupUploadsByProjectId,
} from '../../../../store/files/selectors';
import { hideModal, setModalTitle } from '../../../../store/modal/actions';
import Button from '../../../layout/Button';
import InputFileList from './InputFileList';
import './UploadFilesModal.scss';
import FILE_TABS from '../../../../constants/fileTabs.json';
import { useNavigate } from 'react-router-dom';
import FileStorageStatus from '../FileStorageStatus/FileStorageStatus';
import SoundCreditLoader from '../../SoundCreditLoader';
import { createUploads } from '../../../../helpers/uploadTools';
import FileDragAndDropInput from '../../../layout/FileDragAndDropInput';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

export type UploadFilesModalProps = {
	recordingId: Recording['id'] | null;
	albumId: Album['id'] | null;
	navigateToFilesOnUpload?: boolean;
	title?: string;
	showStorageUsage?: boolean;
};

const UploadFilesModal = ({
	recordingId,
	albumId,
	navigateToFilesOnUpload = true,
	title = 'Upload Files',
}: UploadFilesModalProps) => {
	const dispatch = useAppDispatch();
	const uploadingStorageUsage = useAppSelector(getUploadingStorageUsage);
	const { storageUsage, filesByProjectId } = useAppSelector(
		state => state.files
	);
	const uploadsByProjectId = useAppSelector(groupUploadsByProjectId);

	const navigate = useNavigate();
	const [inputFiles, setInputFiles] = useState<File[]>([]);
	const [isLoading, setIsLoading] = useState(true);

	// Function that will be called when the user selects file(s) on click the file area only
	const handleInputChange = async (selectedFiles: FileList) => {
		console.log('On select Selected Files:', selectedFiles);
		// check if input size exceeds storage limit
		const allFiles = await validateStorageUsageAndCorruptedFiles({
			dispatch,
			storageUsage,
			uploadingStorageUsage,
			newSelectedFiles: selectedFiles,
			existingInputFiles: inputFiles,
		});

		if (!allFiles) {
			return;
		}

		setInputFiles(allFiles);
	};

	// Function that will be called when the user drops file(s) on the drop area only
	const handleFilesDrop = async (selectedFiles: FileList) => {
		console.log('Drop Selected Files:', selectedFiles);

		// check if input size exceeds storage limit
		const allFiles = await validateStorageUsageAndCorruptedFiles({
			dispatch,
			storageUsage,
			uploadingStorageUsage,
			newSelectedFiles: selectedFiles,
			existingInputFiles: inputFiles,
		});

		if (!allFiles) {
			return;
		}

		setInputFiles(allFiles);
	};

	const handleUpload = async () => {
		console.log('About to computeFilenames');

		const inputFolders = inputFiles.filter(f => f.type === '');
		const files = inputFiles.filter(f => f.type !== '');

		console.log('InputFolders:', inputFolders);
		console.log('InputFiles:', files);

		const filenames = computeFilenames(
			// add recordingId and albumId to each file as the computeFilenames function needs it
			inputFiles.map(f => ({
				filename: f.name,
				recordingId,
				albumId,
			})),
			filesByProjectId,
			uploadsByProjectId
		);

		console.log('About to createUploads');
		const uploads = await createUploads({
			inputFiles,
			projectId: {
				recordingId,
				albumId,
			},
			filenames,
		});

		// Add files to upload queue in Redux
		// The FileUploadMenu component will pick up the files from the queue and upload them
		dispatch(addToUploadQueueAction(uploads));

		dispatch(hideModal());

		if (!navigateToFilesOnUpload) {
			return;
		}

		navigateToProjectFiles({
			albumId,
			recordingId,
			navigate,
			section: FILE_TABS.UPLOADS,
		});
	};

	// only run on mount
	useEffect(() => {
		dispatch(setModalTitle(title?.toUpperCase()));

		Promise.resolve(
			Promise.all([
				dispatch(getStorageUsageAction()), // update storage usage as this will be opened from Projects
				dispatch(getProjectFilesMetadataAction({ recordingId, albumId })), // update filesByProjectId as computing new file names will need this to check for duplicates
			])
		).then(() => setIsLoading(false));
	}, [dispatch, title, recordingId, albumId]);

	return isLoading ? (
		<div
			style={{
				height: '40vh',
			}}
		>
			<SoundCreditLoader />
		</div>
	) : (
		<>
			<Modal.Body className='px-4'>
				<Row className='mb-4 mx-4'>
					<Col
						xs={12}
						className='d-flex justify-content-end align-items-center'
					>
						<FileStorageStatus />
					</Col>
				</Row>
				<div className='px-4'>
					<FileDragAndDropInput
						className='upload-files-modal-drop-area'
						inputFiles={inputFiles}
						onSelect={handleInputChange}
						onDrop={handleFilesDrop}
					/>
				</div>

				{inputFiles?.length > 0 && (
					<InputFileList
						inputFiles={inputFiles}
						setInputFiles={setInputFiles}
						className='px-4'
					/>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button label='Close' onClick={() => dispatch(hideModal())} />
				<Button
					label='Upload'
					theme='dark'
					onClick={handleUpload}
					isDisabled={inputFiles.length === 0}
				/>
			</Modal.Footer>
		</>
	);
};

export default UploadFilesModal;
