import React, { useMemo, useRef, useState } from 'react';
import { useCallback } from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { getFilesById } from '../../../../store/files/selectors';
import PillButton from '../../../layout/PillButton';
import SelectableTable from '../../../layout/SelectableTable';
import EmptyPlaceholder from '../../../layout/EmptyPlaceholder';
import '../SelectProjectFilesModal.scss';
import selectedFilesColumns from './selectedFilesColumns';

const SelectProjectFilesRightTable = ({
	selectedFileIds,
	setSelectedFileIds,
}) => {
	const { recordingsById, albumsById } = useSelector(state => state.projects);
	const filesById = useSelector(getFilesById);
	const [checkedFileIds, setCheckedFileIds] = useState([]);
	const tableRef = useRef(null); // only used for clearing row selections imperatively

	const tableRows = useMemo(
		() =>
			selectedFileIds.map(fileId => {
				const file = filesById[fileId];
				const project = file.recordingId
					? recordingsById[file.recordingId]
					: albumsById[file.albumId];

				return {
					id: file.id,
					filename: file.filename,
					label: file.label,
					project: {
						title: project.title,
						artist: project.artist,
					},
					fileSize: file.fileSize,
				};
			}),
		[selectedFileIds, filesById, recordingsById, albumsById]
	);

	const columns = useMemo(() => selectedFilesColumns, []);

	const handleFileRemove = useCallback(
		fileId => {
			setSelectedFileIds(prevSelected =>
				prevSelected.filter(id => id !== fileId)
			);
		},
		[setSelectedFileIds]
	);

	const handleRowSelect = useCallback((selectedRowIds, rows) => {
		const newSelectedFileIds = rows
			.filter(row => selectedRowIds[row.id])
			.map(row => row.original.id);

		setCheckedFileIds(newSelectedFileIds);
	}, []);

	const handleRemoveSelected = useCallback(() => {
		setSelectedFileIds(prevSelected => {
			const newSelected = prevSelected.filter(
				id => !checkedFileIds.includes(id)
			);
			return newSelected;
		});

		tableRef.current?.toggleAllRowsSelected(false);
	}, [checkedFileIds, setSelectedFileIds]);

	return (
		<Card className='select-project-files__menu-container'>
			<h3>SELECTED FILES</h3>
			{/* <div className='select-project-files__header' /> */}
			<div>
				<PillButton
					className='my-2'
					label={'Remove Selected'}
					onClick={handleRemoveSelected}
					isDisabled={checkedFileIds.length === 0}
				/>
			</div>
			<div className='select-project-files__table-container'>
				<SelectableTable
					tableRows={tableRows}
					columns={columns}
					cellProps={{ onFileRemove: handleFileRemove }}
					onRowSelect={handleRowSelect}
					ref={tableRef}
				/>
				{selectedFileIds.length === 0 && (
					<EmptyPlaceholder
						title='NO FILES SELECTED'
						description='Search your project files in the left table and select the files you want to add.'
						icon='fas fa-file fa-3x'
					/>
				)}
			</div>
		</Card>
	);
};

export default SelectProjectFilesRightTable;
