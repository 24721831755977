import axios from 'axios';
import humps from 'humps';
import { store } from '../store';
import axiosRetry from 'axios-retry';
import { shouldRetry } from '../helpers/networkTools';

export const createApiInstance = (
	apiBaseUrl: string,
	{
		translateCase = false,
		devProxyPath = null,
		usePortalAuthHeaders = true,
		retryOnTimeout = true,
		bearerToken = null,
	}: {
		translateCase?: boolean;
		devProxyPath?: string | null;
		usePortalAuthHeaders?: boolean;
		retryOnTimeout?: boolean;
		bearerToken?: string | null;
	} = {}
) => {
	const api = axios.create({
		baseURL:
			process.env.NODE_ENV === 'production'
				? apiBaseUrl
				: devProxyPath || apiBaseUrl,
	});

	// set request interceptors
	if (usePortalAuthHeaders || translateCase || bearerToken) {
		api.interceptors.request.use(config => {
			if (usePortalAuthHeaders) {
				const { userId, userToken } = store.getState().auth;

				if (userId && userToken && config.headers) {
					config.headers['publisher-user-id'] = userId;
					config.headers['publisher-user-token'] = userToken;
				}
			}

			if (bearerToken && config.headers) {
				config.headers['Authorization'] = `Bearer ${bearerToken}`;
			}

			if (translateCase) {
				if (config.data) {
					config.data = humps.decamelizeKeys(config.data);
				}

				if (config.params) {
					config.params = humps.decamelizeKeys(config.params);
				}
			}

			return config;
		});
	}

	// set response interceptors
	if (translateCase) {
		api.interceptors.response.use(response => {
			if (response.data) {
				response.data = humps.camelizeKeys(response.data);
			}

			return response;
		});
	}

	if (retryOnTimeout) {
		axiosRetry(api, {
			retries: 3,
			retryDelay: retryCount => {
				return retryCount * 1000;
			},
			shouldResetTimeout: true,
			retryCondition: shouldRetry,
		});
	}

	return api;
};
