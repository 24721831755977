enum ProductTier {
	STARTER_ANNUAL = 'sound-credit-publisher',
	PRO_ANNUAL = 'sound-credit-publisher-pro',
	BUSINESS_ANNUAL = 'sound-credit-publisher-business',
	STARTER_MONTHLY = 'sound-credit-publisher-monthly',
	PRO_MONTHLY = 'sound-credit-publisher-pro-monthly',
	BUSINESS_MONTHLY = 'sound-credit-publisher-business-monthly',
}

export default ProductTier;
