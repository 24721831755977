import ProductTier from '../../constants/productTiers';
import camelize from '../../helpers/camelize';
import { subscriptionsApi } from '../subscriptionsApi';

export const viewPrices = () => subscriptionsApi.get('/user/view-prices');

export const getSubscriptionInfo = () =>
	subscriptionsApi
		.get<GetSubscriptionInfoResponse>('/user')
		.then(response => response.data);

export const changeSubscription = (newTier: ProductTier, preview: boolean) =>
	subscriptionsApi.post('/user/change-subscription', {
		product: newTier,
		preview,
	});

export const activateBenefits = (code: string) =>
	subscriptionsApi
		.post<ActivateBenefitsInfoResponse>('/user/activate-benefits', { code })
		.then(response => camelize(response.data.permissions) as UserPermissions);
