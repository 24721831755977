import { FormikErrors, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import {
	UploadVersionsToPlaylistForm,
	VersionPlaylistUploadInput,
} from '../UploadVersionsToPlaylistModal';
import AiVersionReconcilerService from '../../../../../../api/services/aiVersionReconcilerService';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { getPlaylistById } from '../../../../../../store/playlists/selectors';
import { Modal } from 'react-bootstrap';
import SoundCreditLoader from '../../../../SoundCreditLoader';
import { DataTable } from 'primereact/datatable';
import {
	Column,
	ColumnBodyOptions,
	ColumnEditorOptions,
} from 'primereact/column';
import {
	detectActiveVersionsInVersionMatches,
	getFileProject,
} from '../../../../../../helpers/fileTools';
import Button from '../../../../../layout/Button';
import { Toast } from 'primereact/toast';
import { Message } from 'primereact/message';
import { Tag } from 'primereact/tag';
import styles from '../UploadVersionsToPlaylistModal.module.scss';
import { Dropdown } from 'primereact/dropdown';
import { Button as PrimeButton } from 'primereact/button';

type VersionMatchingStepProps = {
	playlistId: Playlist['id'];
	onBack: () => void;
	onSubmit: () => Promise<void>;
};

const WARNING_MESSAGE_STYLE = {
	text: {
		style: {
			fontSize: '0.9rem',
			// maxWidth: '45rem',
		},
	},
	icon: {
		style: {
			minWidth: '1.2rem',
			minHeight: '1.2rem',
		},
	},
};

const VersionMatchingStep = ({
	playlistId,
	onSubmit,
	onBack,
}: VersionMatchingStepProps) => {
	const dispatch = useAppDispatch();
	const playlist = useAppSelector(state =>
		getPlaylistById(state, { playlistId })
	);

	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [error, setError] = React.useState<boolean>(false);
	const [isUploading, setIsUploading] = React.useState<boolean>(false);
	const detectedRef = React.useRef<boolean>(false);
	const errorToastRef = React.useRef<Toast>(null);

	const formik = useFormikContext<UploadVersionsToPlaylistForm>();
	const inputFiles = formik.values.files.map(file => file.file);
	const manualMatchingNeeded = React.useMemo(
		() => formik.values.files.some(file => !file.detectedFileId),
		[formik.values.files]
	);

	const playlistFileHeaderTemplate = (data: VersionPlaylistUploadInput) => {
		if (!data.detectedFileId) return <h4>Unknown</h4>;

		const playlistFile = playlist?.playlist?.files.find(
			file => file.id === data.detectedFileId
		);

		if (!playlistFile) throw new Error('Playlist file not found');

		const fileProject = getFileProject(playlistFile);

		return (
			<div>
				{/* <div
				className={}
				>Version files matched with</div> */}

				<h4>{playlistFile.filename}</h4>
				<div className={styles['version-match-project']}>
					from "{fileProject?.title}" - {fileProject?.artist}
				</div>
			</div>
		);
	};

	const computeFormikValues = React.useCallback(
		<T extends VersionMatchBase>(versionMatches: T[]) => {
			const activeVersionsByIndex = detectActiveVersionsInVersionMatches(
				versionMatches,
				inputFiles
			);

			formik.setFieldValue(
				'files',
				formik.values.files
					.map((file, index) => {
						// avoid using spread operator to preserve File reference
						const newFile = file;
						newFile.isActiveVersion = activeVersionsByIndex[index] ?? false;
						newFile.detectedFileId = versionMatches[index].detectedFileId;

						return newFile;
					})
					.sort((a, b) => {
						// we need to sort the files due to the way the DataTable handles row grouping
						// i.e. rows that belong in the same group but aren't next to each other in the list will be grouped separately
						// so sorting by the grouping criteria is a workaround for this quirk
						// link to issue: https://github.com/primefaces/primereact/issues/5009

						// leave all null detectedFileIds at the end
						// if they are both not null, sort by detectedFileId
						if (!a.detectedFileId && !b.detectedFileId) return 0;

						if (!a.detectedFileId) return 1;

						if (!b.detectedFileId) return -1;

						return a.detectedFileId - b.detectedFileId;
					})
			);
		},
		[formik, inputFiles]
	);

	const renderFileNameColumn = (
		data: VersionPlaylistUploadInput,
		options: ColumnBodyOptions
	) => (
		<div className='d-flex align-items-center w-100 pl-3'>
			<></>
			<i className='fas fa-file mr-2'></i>
			{data.detectedFileId ? (
				<>
					<span className={styles['version-file-name']}>{data.file.name}</span>
					<Tag
						value='NEW VERSION'
						className='py-0 px-1 ml-1'
						style={{
							whiteSpace: 'nowrap',
						}}
					/>
					{data.isActiveVersion ? (
						<Tag value='LATEST' className='py-0 px-1 ml-1' severity='info' />
					) : null}
				</>
			) : (
				<Message
					text={data.file.name}
					severity={
						formik.touched?.files?.[options.rowIndex]?.detectedFileId &&
						(
							formik.errors?.files?.[
								options.rowIndex
							] as FormikErrors<VersionPlaylistUploadInput>
						)?.detectedFileId
							? 'error'
							: 'warn'
					}
					pt={WARNING_MESSAGE_STYLE}
				/>
			)}
		</div>
	);

	const renderEditDetectedFileIdColumn = (options: ColumnEditorOptions) => (
		<span className='p-float-label w-100'>
			<Dropdown
				className='w-100'
				inputId='detectedFileId'
				value={options.value}
				options={playlist?.playlist?.files.map(file => ({
					label: file.filename,
					value: file.id,
					projectTitle: getFileProject(file)?.title,
					projectArtist: getFileProject(file)?.artist,
				}))}
				onChange={e => {
					options?.editorCallback?.(e.value);
				}}
				placeholder='Select a file'
				itemTemplate={option => (
					<div>
						"{option.projectTitle}" - {option.projectArtist}
						<div
							style={{
								fontSize: '0.8rem',
							}}
						>
							{option.label}
						</div>
					</div>
				)}
			/>
			<label
				style={{
					top: '-0.4rem',
				}}
				className='mb-0'
				htmlFor='detectedFileId'
			>
				Select Associated File
			</label>
		</span>
	);

	useEffect(() => {
		if (!playlist) throw new Error('Playlist not found');

		if (detectedRef.current || error) return;

		setIsLoading(true);
		detectedRef.current = true;

		AiVersionReconcilerService.matchVersionsToPlaylistFiles({
			playlistFiles: playlist.playlist!.files,
			uploadFiles: inputFiles,
		})
			.then(response => computeFormikValues(response.data.matches))
			.catch(error => {
				setError(true);
				errorToastRef.current?.show({
					severity: 'error',
					summary: 'Whoops!',
					detail: `Hiccup detected while detecting version files in playlist. ${
						error?.response?.status ? ` (${error.response.status})` : ''
					}`,
					sticky: true,
				});
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [
		isLoading,
		playlist,
		inputFiles,
		formik,
		dispatch,
		error,
		computeFormikValues,
	]);

	return (
		<>
			<Toast ref={errorToastRef} />

			<Modal.Body>
				{isLoading ? (
					<SoundCreditLoader
						theme='ai-light'
						iconHeight={200}
						iconWidth={200}
						message='Processing'
					/>
				) : (
					<div className='px-4'>
						<h4 className='mb-2'>
							We detected the following matches between your uploaded files and
							the files that are already in this playlist. Please review and
							make any changes if necessary.
						</h4>

						{manualMatchingNeeded ? (
							<Message
								severity='warn'
								text='This icon indicates that the version file could not be automatically matched to a file in the playlist. You can manually match the file by clicking the Edit button.'
								pt={WARNING_MESSAGE_STYLE}
							/>
						) : null}

						{formik.errors.files && formik.touched.files ? (
							<Message
								severity='error'
								text='Please select an associated playlist file for each version file.'
								pt={WARNING_MESSAGE_STYLE}
								className='mt-2'
							/>
						) : null}

						<DataTable
							className={styles['version-matching-table']}
							value={formik.values.files}
							rowGroupMode='subheader'
							groupRowsBy='detectedFileId'
							// scrollable
							scrollHeight='60vh'
							rowGroupHeaderTemplate={playlistFileHeaderTemplate}
							editMode='row'
							// onRowEditInit={e => {}
							onRowEditComplete={e => {
								const newFiles = formik.values.files.map((file, formIndex) => {
									if (formIndex === e.index) {
										return e.newData as VersionPlaylistUploadInput;
									}

									return file;
								});

								computeFormikValues(newFiles);
							}}
						>
							<Column header='File Name' body={renderFileNameColumn} />

							<Column
								field='detectedFileId'
								headerStyle={{
									maxWidth: '15rem',
								}}
								style={{
									maxWidth: '15rem',
								}}
								body={() => <></>}
								editor={renderEditDetectedFileIdColumn}
							/>
							<Column
								rowEditor
								className='text-right'
								style={{
									width: '8rem',
								}}
								header=''
							></Column>
							<Column
								style={{
									padding: 0,
								}}
								headerStyle={{
									width: '3rem',
								}}
								body={(data, options) => (
									<PrimeButton
										icon='fas fa-trash'
										severity='danger'
										rounded
										text
										onClick={(event) => {
											event.preventDefault();
											
											const newFiles = formik.values.files.filter(
												(file, formIndex) => formIndex !== options.rowIndex
											);

											formik.setFieldValue('files', newFiles);
										}}
										tooltip='Remove File'
									/>
								)}
							/>
						</DataTable>
					</div>
				)}
			</Modal.Body>

			<Modal.Footer style={{ gap: '0.5rem' }}>
				<Button label='Back' leftIcon='fas fa-arrow-left' onClick={onBack} />
				<Button
					label='Upload Versions'
					leftIcon='fas fa-upload'
					onClick={() => {
						setIsUploading(true);
						onSubmit().finally(() => {
							setIsUploading(false);
						});
					}}
					theme='dark'
					isDisabled={isLoading}
					isLoading={isUploading}
				/>
			</Modal.Footer>
		</>
	);
};

export default VersionMatchingStep;
