import { AnyAction } from 'redux';
import { v4 as uuidv4 } from 'uuid';
import {
	HIDE_ALL_MODALS,
	HIDE_MODAL,
	HIDE_MODAL_BY_TYPE,
	REMOVE_MODAL,
	POP_MODALS_UNTIL_TYPE,
	REMOVE_MODAL_BY_ID,
	SET_MODAL_TITLE,
	SHOW_MODAL,
} from '../actionTypes';

const _hideModal = (modal: ModalStackItem) => ({
	...modal,
	showModal: false,
	title: '',
	props: {
		...modal.props,
		size: modal.props?.size || '', // keep size
		hideHeader: modal.props?.hideHeader || false,
		fullscreen: modal.props?.fullscreen || false,
		removeHeaderPadding: modal.props?.removeHeaderPadding || false,
	},
});

export type ModalStackItem = {
	id: string;
	modalType: string;
	title: string;
	showModal: boolean;
	props: any;
};

const baseModalState: ModalStackItem = {
	id: '',
	modalType: '',
	title: '',
	showModal: false,
	props: {},
};

export type ModalState = {
	modalStack: ModalStackItem[];
};

const initialState: ModalState = {
	modalStack: [],
};

const reducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case SHOW_MODAL:
			return {
				...state,
				modalStack: [
					...state.modalStack,
					{
						...baseModalState,
						id: uuidv4(),
						modalType: action.modalType,
						title: action.title,
						showModal: true,
						props: action.props,
					},
				],
			};
		case HIDE_MODAL:
			const lastModal = state.modalStack[state.modalStack.length - 1];

			if (!lastModal) {
				return state;
			}

			console.log('hideModal', state.modalStack);
			return {
				...state,
				modalStack: [...state.modalStack.slice(0, -1), _hideModal(lastModal)],
			};
		case HIDE_ALL_MODALS:
			return {
				...state,
				modalStack: [...state.modalStack.map(modal => _hideModal(modal))],
			};
		case REMOVE_MODAL:
			// remove last element from modal stack
			const newModalStack = [
				...state.modalStack.slice(0, state.modalStack.length - 1),
			];
			console.log('newModalStack', newModalStack);
			return {
				...state,
				modalStack: newModalStack,
			};
		case REMOVE_MODAL_BY_ID:
			// remove element from modal stack by id
			return {
				...state,
				modalStack: [
					...state.modalStack.filter(modal => modal.id !== action.id),
				],
			};
		case HIDE_MODAL_BY_TYPE:
			return {
				...state,
				modalStack: [
					...state.modalStack.map(modal => {
						if (modal.modalType === action.modalType) {
							return _hideModal(modal);
						}

						return modal;
					}),
				],
			};
		case SET_MODAL_TITLE:
			const lastModal2 = state.modalStack[state.modalStack.length - 1];
			const updatedModal2 = { ...lastModal2, title: action.title };

			return {
				...state,
				modalStack: [...state.modalStack.slice(0, -1), updatedModal2],
			};

		case POP_MODALS_UNTIL_TYPE:
			const index = state.modalStack.findIndex(
				modal => modal.modalType === action.modalType
			);

			if (index === -1) {
				return state;
			}

			return {
				...state,
				modalStack: [...state.modalStack.slice(0, index + 1)],
			};
		default:
			return state;
	}
};

export default reducer;
