import axios from 'axios';

export const moodsApi = axios.create({
	baseURL:
		process.env.NODE_ENV === 'production'
			? process.env.REACT_APP_MOODS_API
			: '/moods',
});

moodsApi.interceptors.request.use(config => {
	// config.headers['sound-credit-user-code'] = 'MkKJDE98JIkd86JOK9s7FIjkd8';

	return config;
});
