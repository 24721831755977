import { useMemo } from 'react';
import { selectDownloadPathAndDownload } from '../../../../../helpers/downloadTools';
import {
	getFilePreviewData,
	getUploadedOverflowMenuOptions,
} from '../../../../../helpers/fileTools';
import { getFilesById } from '../../../../../store/files/selectors';
import FilePreviewModal from '../FilePreviewModal';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';

const UploadPreviewModal = ({ fileId }: { fileId: FileMetadata['id'] }) => {
	const dispatch = useAppDispatch();
	const filesById = useAppSelector(state => getFilesById(state));
	const { myEditorProfile } = useAppSelector(state => state.projects);
	const { userPermissions } = useAppSelector(state => state.auth);

	const isReadOnly = useMemo(
		() => (myEditorProfile ? myEditorProfile.is_read_only : true),
		[myEditorProfile]
	);

	const file = useMemo(() => filesById[fileId] ?? null, [filesById, fileId]);

	const downloadFileFn = file
		? () => selectDownloadPathAndDownload({ file, dispatch })
		: null;

	const previewOptions = useMemo(
		() =>
			file
				? getUploadedOverflowMenuOptions(
						file,
						dispatch,
						userPermissions!.canShareFiles,
						!isReadOnly
				  ).filter(o => o.name !== 'Preview')
				: null,
		[file, dispatch, isReadOnly, userPermissions]
	);

	return (
		<FilePreviewModal
			file={file}
			fileId={fileId}
			getPreviewDataFn={getFilePreviewData}
			downloadFileFn={downloadFileFn}
			previewOptions={previewOptions}
		/>
	);
};

export default UploadPreviewModal;
