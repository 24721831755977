import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Route, Routes, useMatch, useNavigate } from 'react-router-dom';
import ROUTES from '../../../router/routes';
import {
	fetchPlaylistAction,
	fetchPlaylistEditorsAction,
	fetchUserPlaylistsAction,
	pollPlaylistUpdatesAction,
	setCurrentPlaylistAction,
} from '../../../store/playlists/actions';
import PlaylistDetails from './PlaylistDetails';
import PlaylistStats from './PlaylistStats';
import PlaylistSubmenu from './PlaylistSubmenu';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getCurrentPlaylist } from '../../../store/playlists/selectors';
import SoundCreditLoader from '../SoundCreditLoader';
import { showErrorAlert } from '../../../store/alertToast/actions';
import { Helmet } from 'react-helmet';
import TransferFiles from './TransferFiles';
import SelectedPlaylistContextProvider from '../../../context/SelectedPlaylistContext';

const Playlists = () => {
	const { currentPlaylistId, currentPlaylistEditors, playlistsById } =
		useAppSelector(state => state.playlists);
	const match = useMatch(`${ROUTES.PlaylistDetails.path}/*`);
	const currentPlaylist = useAppSelector(getCurrentPlaylist);
	const dispatch = useAppDispatch();
	const isLoadingUserPlaylistsRef = useRef(false);
	const fetchedPlaylistRef = useRef(false);
	const [isLoadingPlaylist, setIsLoadingPlaylist] = useState(false);

	const parsedCurrentPlaylistId = useMemo(
		() => parseInt((match?.params as any)?.playlistId ?? null),
		[match]
	);

	const navigate = useNavigate();

	const pollingEnabled = useMemo(
		() =>
			currentPlaylistEditors?.playlistId === currentPlaylistId &&
			currentPlaylistEditors.editors?.length > 1,
		[currentPlaylistEditors, currentPlaylistId]
	);

	// effect for fetching playlists on every currentPlaylistId change
	useEffect(() => {
		if (!isLoadingUserPlaylistsRef.current) {
			isLoadingUserPlaylistsRef.current = true;
			Promise.resolve(dispatch(fetchUserPlaylistsAction())).then(() => {
				isLoadingUserPlaylistsRef.current = false;
			});
		}
	}, [dispatch, isLoadingUserPlaylistsRef, currentPlaylistId]);

	useEffect(() => {
		if (!currentPlaylistId || fetchedPlaylistRef.current) {
			return;
		}

		if (playlistsById && !playlistsById[currentPlaylistId]) {
			// this means playlist was not found
			dispatch(
				showErrorAlert('Whoops! It looks like that playlist does not exist.')
			);
			navigate(ROUTES.Playlists.path);
			dispatch(setCurrentPlaylistAction(null));
			return;
		}

		// always fetch playlist on mount, or force fetch if playlist is not loaded
		if (
			currentPlaylistId &&
			(!fetchedPlaylistRef.current || !currentPlaylist?.playlist) &&
			!isLoadingPlaylist
		) {
			fetchedPlaylistRef.current = true;
			setIsLoadingPlaylist(true);
			dispatch(fetchPlaylistAction(currentPlaylistId)).finally(() => {
				setIsLoadingPlaylist(false);
			});
		}
	}, [
		currentPlaylistId,
		currentPlaylist,
		dispatch,
		isLoadingPlaylist,
		navigate,
		playlistsById,
	]);

	// if the currentPlaylistId changes, set the current playlist ID to the parsed ID
	// and reset the fetchedPlaylistRef to false so that the playlist is fetched again
	useEffect(() => {
		if (
			parsedCurrentPlaylistId &&
			!Number.isNaN(parsedCurrentPlaylistId) &&
			parsedCurrentPlaylistId !== currentPlaylistId
		) {
			dispatch(setCurrentPlaylistAction(parsedCurrentPlaylistId));
			fetchedPlaylistRef.current = false;
		}
	}, [currentPlaylistId, dispatch, parsedCurrentPlaylistId]);

	useEffect(() => {
		if (!currentPlaylistId || !currentPlaylist) {
			return;
		}

		dispatch(fetchPlaylistEditorsAction(currentPlaylistId));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentPlaylistId]);

	useEffect(() => {
		if (!currentPlaylistId || !pollingEnabled) {
			return;
		}

		const timerId = setInterval(() => {
			if (!currentPlaylistId || !pollingEnabled) {
				clearInterval(timerId);
				return;
			}

			try {
				dispatch(pollPlaylistUpdatesAction(currentPlaylistId));
			} catch (e) {
				console.error(e);
			}
		}, 15000);

		return () => {
			clearInterval(timerId);
		};
	}, [dispatch, currentPlaylistId, pollingEnabled]);

	return (
		<>
			<Helmet>
				<title>Playlists {process.env.REACT_APP_TAB_TITLE}</title>
			</Helmet>
			<Row className='h-100 m-0'>
				<Col
					xs={3}
					lg={3}
					xl={2}
					className='bg-dark-credit animate__animated animate__fadeInLeft animate__fast p-0 h-100'
					style={{ userSelect: 'none' }}
				>
					<PlaylistSubmenu />
				</Col>
				<Col
					xs={9}
					lg={9}
					xl={10}
					style={{
						height: '100%',
						overflowY: 'auto',
					}}
				>
					{currentPlaylistId &&
					(!currentPlaylist?.playlist ||
						(!currentPlaylist?.playlist && isLoadingPlaylist)) ? (
						<SoundCreditLoader />
					) : (
						<Routes>
							<Route index element={<TransferFiles />} />
							<Route
								path={ROUTES.TransferFiles.relativePath}
								element={<TransferFiles />}
							/>

							<Route path={`${ROUTES.PlaylistDetails.relativePath}/*`}>
								<Route
									index
									element={
										// context used for small-scale state management (mostly used in PlaylistFileTable)
										<SelectedPlaylistContextProvider key={currentPlaylistId}>
											<PlaylistDetails key={currentPlaylistId} />
										</SelectedPlaylistContextProvider>
									}
								/>

								<Route
									path={ROUTES.PlaylistStats.relativePath}
									element={<PlaylistStats key={currentPlaylistId} />}
								/>

								<Route
									path={ROUTES.DripStats.relativePath}
									element={
										<PlaylistStats key={currentPlaylistId} isDrip={true} />
									}
								/>

								<Route path='*' element={<TransferFiles />} />
							</Route>
						</Routes>
					)}
				</Col>
			</Row>
		</>
	);
};

export default Playlists;
