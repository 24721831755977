import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import {
	createQueueDownload,
	downloadToFileSystem,
	selectDownloadPathAndDownload,
} from './downloadTools';
import axios from 'axios';
import { getFileDownloadLink } from '@/api/services/filesService';
import {
	addFolderToDownloadQueueAction,
	addToFolderDownloadQueueAction,
	deleteFolderDownloadQueueAction,
	setFileDownloadAbortControllerAction,
} from '@/store/files/actions';
import { AppDispatch } from '@/store';
import { removeLastNumberInParentheses } from './fileTools';

const addFolderToQueue = async (
	folder: PlaylistFolder,
	dispatch: AppDispatch
) => {
	await dispatch(
		addToFolderDownloadQueueAction({
			folder: folder,
		})
	);
};
const deleteFolderQueue = async (dispatch: AppDispatch) => {
	await dispatch(deleteFolderDownloadQueueAction());
};

const createBlobFromFile = async (
	file: PlaylistFileMetadata,
	dispatch: AppDispatch
) => {
	const fileHandle = null;
	const nativeHandle = null;
	const download = createQueueDownload({
		fileId: file.id,
		versionId: file.activeVersion,
		filename: file.filename,
		fileHandle: fileHandle,
		fileSize: file.fileSize,
		nativeHandle: nativeHandle,
	});

	try {
		const assetLinkRes = await getFileDownloadLink({
			fileId: download.metadata.fileId,
			versionId: download.metadata.versionId,
		});

		const assetLink = assetLinkRes?.data?.asset_link;
		// console.log('Asset Link:', assetLink);

		// const previewDataPromise = await axios.get<ArrayBuffer>(assetLink, {
		// 	responseType: 'arraybuffer',
		// });
		const previewDataPromise = await axios.get<Blob>(assetLink, {
			responseType: 'blob',
		});

		const imgPreviewRes = previewDataPromise.data;

		return imgPreviewRes;
	} catch (e: any) {
		console.log('Error:', e);
	}
};

const traverseFolders = async (
	folder: PlaylistFolder | PlaylistFileGroup,
	parentFolder: JSZip,
	filesById: { [key: number]: PlaylistFileMetadata },
	dispatch: AppDispatch
) => {
	const curFolder = parentFolder.folder(folder.name);
	if (!curFolder) return;
	// For each file
	// const promises = folder.fileIds.map(async fileId => {
	// for (const fileId of folder.fileIds) {
	for (let i: number = 0; i < folder.fileIds.length; i++) {
		const fileId = folder.fileIds[i];
		// folder.fileIds.forEach(async (fileId: number) => {
		// Obtain file from Id
		const file = filesById[fileId];

		// selectDownloadPathAndDownload({
		//     file: selectedContextMenuItem,
		//     versionId: selectedContextMenuItem.activeVersion,
		//     dispatch,
		// });
		// Add file to zip
		const fileArray = await createBlobFromFile(file, dispatch);
		// curFolder.file(file.filename, file, {
		//     binary: true,
		// });
		if (fileArray) {
			curFolder.file(removeLastNumberInParentheses(file.filename), fileArray, {
				binary: true,
			});
		}

		// curFolder.file(file.filename, file, dispatch);
		// });
	}
	// await Promise.all(promises);
	// For each folder
	if (folder.folders.length === 0) return;
	for (const f of folder.folders) {
		await traverseFolders(f, curFolder, filesById, dispatch);
	}
};

export const zipFilesAndFolders = async (
	folder: PlaylistFolder | PlaylistFileGroup,
	allFiles: PlaylistFileMetadata[],
	dispatch: AppDispatch
) => {
	const filesById: { [key: number]: PlaylistFileMetadata } = {};

	allFiles.forEach((file: PlaylistFileMetadata) => {
		filesById[file.id] = file;
	});
	const zip = new JSZip();

	// Example structure
	// rootFolder
	//   ├── file1.txt
	//   └── subFolder
	//        └── file2.txt

	const rootFolder = zip.folder(folder.name);
	if (!rootFolder) return;
	await addFolderToQueue(folder, dispatch);
	await traverseFolders(folder, rootFolder, filesById, dispatch);
	await deleteFolderQueue(dispatch);
	// console.log('rootFolder:', rootFolder);

	zip.generateAsync({ type: 'blob' }).then(async function (content) {
		// saveAs(content, folder.name + '.zip');

		const newQueueDownload = createQueueDownload({
			fileId: 0,
			versionId: 1,
			filename: folder.name + '.zip',
			fileHandle: null,
			fileSize: 0,
			nativeHandle: null,
		});
		// await dispatch(
		// 	addFolderToDownloadQueueAction({ folder: newQueueDownload })
		// );
		const fileHandle = null;
		const nativeHandle = null;
		// const fileTimestamps = {updatedAt: folder.;
		//     createdAt: number;}
		const fileTimestamps = null;

		await downloadToFileSystem({
			fileHandle: fileHandle,
			blob: content,
			suggestedName: folder.name + '.zip',
			suggestedTimestamps: fileTimestamps,
			nativeHandle: nativeHandle,
		});
	});
};
