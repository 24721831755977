import { Form, FormikProvider, useFormik } from 'formik';
import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Col, Row } from 'react-bootstrap';
import {
	CREATE_PLAYLIST_FOLDER_MODAL,
	PROJECT_LIMIT_MODAL,
	SELECT_PROJECT_FILES_MODAL,
} from '@/constants/modalTypes';
import { getStorageUsageAction } from '@/store/files/actions';
import { getUploadingStorageUsage } from '@/store/files/selectors';
import { hideModal, showModalAction } from '@/store/modal/actions';
import PlaylistInputFileList from '../UploadFilesToPlaylistModal/PlaylistInputFileList';
import * as yup from 'yup';
import {
	addFilesToPlaylistAction,
	createPlaylistFileGroupAction,
} from '@/store/playlists/actions';
import { validateStorageUsageAndCorruptedFiles } from '@/helpers/fileTools';
import { fetchUserEditableProjectsAction } from '@/store/projects/actions';
import FileDragAndDropInput from '../../../../layout/FileDragAndDropInput';
import { setDetectTitleAndArtistAction } from '@/store/session/actions';
import ToggleInput from '../../../../layout/ToggleInput';
import CreatableProjectSelect, {
	createProjectOption,
} from '../../../../form/CreatableProjectSelect/CreatableProjectSelect';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { v4 as uuid } from 'uuid';
import { SingleValue } from 'react-select';
import TextField from '../../../../form/TextField';
import InfoTooltip from '../../../../layout/InfoTooltip';
import PillButton from '../../../../layout/PillButton';
import SoundCreditLoader from '../../../SoundCreditLoader';
import { hasExceededProjectLimit } from '@/helpers/tiersTools';
import uniq from 'lodash';
import {
	PlaylistUploadFileForm,
	UploadFilesToPlaylistForm,
	createFileForm,
	detectFileInfo,
	handleChangeProjectOption,
	handleCreateProjectOption,
} from '../../../../../helpers/playlistUploadTools';
import { UploadFilesToPlaylistProps } from './UploadFilesToPlaylist';
import { ProjectSelectOption } from '../../../../form/CreatableProjectSelect/CreatableProjectSelect';
import PlaylistFileUploadContext, {
	PlaylistFileUploadContextType,
} from './PlaylistFileUploadContext';

yup.addMethod<yup.ArraySchema<any>>(
	yup.array,
	'unique',
	function (
		this: yup.ArraySchema<any>,
		message = 'must be unique',
		mapper: (a: any) => any = (a: any) => a
	) {
		return this.test('unique', message, function (value: any[] | undefined) {
			const list = value ?? [];
			return list.length === new Set(list.map(mapper)).size;
		});
	}
);

const schema = yup.object().shape({
	includeInAlbum: yup.boolean(),
	album: yup
		.object()
		.nullable()
		.when('includeInAlbum', {
			is: true,
			then: yup.object().nullable().required('Select an Album'),
		}),
	albumTitle: yup.string().when('includeInAlbum', {
		is: true,
		then: yup.string().nullable().required('Enter an Album Title'),
	}),
	albumArtist: yup.string().when('includeInAlbum', {
		is: true,
		then: yup.string().nullable().required('Enter an Album Artist'),
	}),
	isExistingAlbum: yup.boolean(),
	files: yup
		.array()
		.required('Please select at least one file')
		.min(1, 'Please select at least one file')
		.of(
			yup.object<any>().shape({
				isLinkedToProject: yup.boolean(),
				associatedProjectId: yup
					.object()
					.nullable()
					.when('isLinkedToProject', {
						is: false,
						then: yup
							.object()
							.nullable()
							.required('Select or enter a recording'),
					}),
				projectTitle: yup.string().when('isLinkedToProject', {
					is: false,
					then: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Enter a title'),
				}),
				projectArtist: yup.string().when('isLinkedToProject', {
					is: false,
					then: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Enter an artist'),
				}),
				filename: yup.string(),
				displayName: yup.string(),
				projectType: yup.string(),
				isAudioFile: yup.boolean(),
				useDifferentDisplayName: yup.boolean(),
				label: yup.number(), // file tag
				isDetecting: yup.boolean(),
			})
		)
		.unique(
			'Duplicate file names. Make sure that all files are named differently',
			(file: any) => file?.filename
		),
});

const UploadFilesToPlaylistContent = ({
	playlistId,
	initialFiles = [],
	fromNewPlaylist = false,
	onSubmit,
	hideToggles = false,
	className = '',
	renderFooter,
}: UploadFilesToPlaylistProps) => {
	const dispatch = useAppDispatch();

	const uploadingStorageUsage = useAppSelector(getUploadingStorageUsage);
	const { storageUsage } = useAppSelector(state => state.files);
	const { detectTitleAndArtistByDefault } = useAppSelector(
		state => state.session
	);
	const { recordingsById, albumsById, projectUsage } = useAppSelector(
		state => state.projects
	);
	const { playlistsById } = useAppSelector(state => state.playlists);

	const {
		newProjects,
		inputFiles,
		setInputFiles,
		setInputTimestamp,
		useFilenameAsTitle,
		setUseFilenameAsTitle,
		userProjects,
		setNewProjects,
		titleIndex,
	} = useContext<PlaylistFileUploadContextType | null>(
		PlaylistFileUploadContext
	) as PlaylistFileUploadContextType;

	// const inputRef = useRef<Element | null>(null);
	const inputRef = useRef<any | null>(null);
	const [inputFolderDict, setInputFolderDict] = useState<{
		[key: string]: { [key: string]: File[] };
	}>({});
	const [folderFilesList, setFolderFilesList] = useState<{
		[key: string]: File[];
	}>({});
	const [folderFilesMetadata, setFolderFilesMetadata] = useState<{
		[key: string]: any[];
	}>({});

	const [createdAlbum, setCreatedAlbum] = useState<ProjectSelectOption | null>(
		null
	);
	const [isLoading, setIsLoading] = useState(false);

	const playlistAlbumIds = useMemo(() => {
		if (!playlistId) return [];

		const playlist = playlistsById?.[playlistId]?.playlist;

		if (!playlist) {
			return [];
		}

		const ids =
			playlist.files
				?.map(file => file?.recording?.albumId)
				?.filter((albumId): albumId is number => Boolean(albumId)) ?? [];

		return Array.from(new Set(ids));
	}, [playlistsById, playlistId]);

	// const addValueToInputFolderDict = (key: string, value: File | null) => {
	// 	setInputFolderDict(prevState => ({
	// 		...prevState,
	// 		[key]: value ? [...prevState[key], value] : [],
	// 	}));
	// };

	const initialValues = useMemo(
		(): UploadFilesToPlaylistForm => ({
			files: [],
			includeInAlbum: false,
			album: null,
			albumTitle: '',
			albumArtist: '',
			isExistingAlbum: false,
		}),
		[]
	);

	const computeNewProjectsInUse = useCallback(
		(form: UploadFilesToPlaylistForm) =>
			uniq(
				form.files
					.filter(
						file =>
							!file.isLinkedToProject && file.associatedProjectId?.recordingId
					)
					.map(file => file.associatedProjectId?.recordingId as string)
			)
				.map(recId =>
					newProjects.find(project => project.value.recordingId === recId)
				)
				.filter((project): project is ProjectSelectOption => Boolean(project))
				.value(),
		[newProjects]
	);

	const formik = useFormik({
		initialValues,
		onSubmit: async (values, { setSubmitting }) => {
			const newProjectsInUse = computeNewProjectsInUse(values);

			if (
				hasExceededProjectLimit({
					...projectUsage!,
					used: projectUsage!.used + newProjectsInUse.length,
				})
			) {
				dispatch(
					showModalAction(PROJECT_LIMIT_MODAL, {
						size: 'md',
						message: `You're attempting to create ${
							newProjectsInUse.length
						} new project${
							newProjectsInUse.length > 1 ? 's' : ''
						}, but your current subscription plan only allows ${
							projectUsage!.limit - projectUsage!.used
						} more projects (${projectUsage!.limit} total).`,
					})
				);

				setSubmitting(false);
				return;
			}

			await onSubmit(
				values,
				inputFiles,
				inputFolderDict,
				folderFilesList,
				folderFilesMetadata
			);
		},
		// validateOnMount: true,
		validationSchema: schema,
		// enableReinitialize: true,
	});

	const isDetectingTitleAndArtists = useMemo(
		() =>
			formik?.values?.files?.some(file => file.isDetecting && file.isAudioFile),
		[formik?.values?.files]
	);

	const projectCount = useMemo(
		() =>
			Object.values(recordingsById ?? {}).length +
			Object.values(albumsById ?? {}).length,
		[recordingsById, albumsById]
	);

	const setIndividualFileForm = useCallback(
		(fileForm: PlaylistUploadFileForm) => {
			const previousFile = formik.values.files.find(
				prevFile => prevFile.filename === fileForm.filename
			);

			// if the same file was already in the previous selection, keep the previous values
			// this is to prevent the user from having to re-enter the project details
			// or re-detect the title and artist
			let newFileForm = previousFile ?? fileForm;

			// formik.setFieldValue('files', fileForm);

			// return value only used to have latest snapshot of form
			return newFileForm;
		},
		[formik]
	);

	const setFileForms = useCallback(
		(fileForms: PlaylistUploadFileForm[]) => {
			const newFileForms = fileForms.map((newFile, index) => {
				const previousFile = formik.values.files.find(
					prevFile => prevFile.filename === newFile.filename
				);

				// if the same file was already in the previous selection, keep the previous values
				// this is to prevent the user from having to re-enter the project details
				// or re-detect the title and artist
				return previousFile ?? newFile;
			});

			formik.setFieldValue('files', newFileForms);

			// return value only used to have latest snapshot of form
			return newFileForms;
		},
		[formik]
	);

	const getFileFolders = (file: any) => {
		const folders: string[] = [];
		const parts = file.path.split('/');
		for (let i = 1; i < parts.length; i++) {
			const folderPath = parts[i];
			folders.push(folderPath);
		}
		return folders.slice(0, -1);
	};

	async function getFile(fileEntry: any) {
		try {
			return await new Promise((resolve, reject) =>
				fileEntry.file(resolve, reject)
			);
		} catch (err) {
			console.log(err);
		}
	}

	const convertFileEntryToFile = async (entry: any) => {
		let file: any = await getFile(entry);
		Object.defineProperty(file, 'path', {
			value: null,
		});
		Object.defineProperty(file, 'webkitRelativePath', {
			value: entry.fullPath.substring(0, entry.fullPath.lastIndexOf('/')),
		});
		return file;
	};

	function traverse_directory(
		entry: any,
		result: { [key: string]: File[] },
		key: string,
		folderFiles: File[]
	) {
		let reader = entry.createReader();
		const errorHandler = (e: any) => console.log('Error:', e);
		// Resolved when the entire directory is traversed
		return new Promise(resolve_directory => {
			var iteration_attempts: any[] = [];
			(function read_entries() {
				// According to the FileSystem API spec, readEntries() must be called until
				// it calls the callback with an empty array.  Seriously??
				reader.readEntries((entries: any) => {
					if (!entries.length) {
						// Done iterating this particular directory
						resolve_directory(Promise.all(iteration_attempts));
					} else {
						// Add a list of promises for each directory entry.  If the entry is itself
						// a directory, then that promise won't resolve until it is fully traversed.
						iteration_attempts.push(
							Promise.all(
								entries.map(async (entry: any) => {
									if (entry.isFile) {
										let file: any = await convertFileEntryToFile(entry);

										result[key].push(file);
										folderFiles.push(file);

										return entry;
									} else {
										// Folder
										let file = new File([], entry.fullPath);
										Object.defineProperty(file, 'type', {
											value: 'folder',
										});
										result[key].push(file);
										// file.type = "folder"
										return traverse_directory(entry, result, key, folderFiles);
									}
								})
							)
						);
						// Try calling readEntries() again for the same dir, according to spec
						read_entries();
					}
				}, errorHandler);
			})();
		});
	}

	const handleDropzoneInputChange = useCallback(
		async (acceptedFiles: any[]) => {
			let individualFiles: File[] = [];

			let webkitItems = acceptedFiles;
			let directories: { [key: string]: File[] } = {};

			const promises: any[] = [];

			const folderFilesDict: { [key: string]: File[] } = {};

			// First, we take a look at all the files/folders uploaded
			// We traverse each folder received in drag and drop/select
			let directoriesCount: number = 0;

			for (var i = 0; i < webkitItems.length; i++) {
				if (webkitItems[i].isDirectory) {
					let folderFiles: File[] = [];
					console.log('Webkit Dir:', webkitItems[i]);
					const key = webkitItems[i].name;
					directories[key] = [];
					directoriesCount++;

					const promise = traverse_directory(
						webkitItems[i],
						directories,
						key,
						folderFiles
					).then((items: any) => {
						// AT THIS POINT THE DIRECTORY SHOULD BE FULLY TRAVERSED.

						folderFilesDict[key] = folderFiles;
						// return result;
					});
					promises.push(promise);
				} else if (webkitItems[i].isFile) {
					const file = await convertFileEntryToFile(webkitItems[i]);
					individualFiles.push(file);
				}
			}

			if (directoriesCount === 0 && individualFiles.length === 0) {
				individualFiles = acceptedFiles;
			}

			await Promise.all(promises);

			const folderFiles = Object.values(folderFilesDict).flat();

			// We save a list of files for each folder for use in Submit
			setFolderFilesList(prevState => ({
				...prevState,
				...folderFilesDict,
			}));

			const folderStructures: { [key: string]: { [key: string]: File[] } } = {};
			const rootFolders: File[] = [];
			Object.keys(directories).forEach((key: string) => {
				const foldersDict: { [key: string]: File[] } = {};
				let folderFile: File = new File([], key);
				Object.defineProperty(folderFile, 'type', {
					value: 'folder',
				});

				rootFolders.push(folderFile);
				directories[key].forEach(async (file: any) => {
					if (file.type === 'folder') {
						if (!foldersDict[file.name]) {
							foldersDict[file.name] = [];
						}
					} else {
						const path = await file.webkitRelativePath;
						if (!foldersDict[path]) {
							foldersDict[path] = [];
						}
						foldersDict[path].push(file);
					}
				});
				folderStructures[key] = foldersDict;
				if (!folderStructures[key].hasOwnProperty('/' + key)) {
					folderStructures[key]['/' + key] = [];
				}
			});

			setInputFolderDict(prevState => ({
				...prevState,
				...folderStructures,
			}));

			const allFilesList = [...individualFiles, ...folderFiles];

			let selectedFiles = acceptedFiles;
			setIsLoading(true);

			// check if input size exceeds storage limit
			const allFilesMixed: File[] | undefined =
				await validateStorageUsageAndCorruptedFiles({
					dispatch,
					storageUsage,
					uploadingStorageUsage,
					existingInputFiles: allFilesList,
					newSelectedFiles: selectedFiles,
				});

			if (!allFilesMixed) return; // if some validation failed, then return

			let allFiles = await validateStorageUsageAndCorruptedFiles({
				dispatch,
				storageUsage,
				uploadingStorageUsage,
				existingInputFiles: allFilesList,
				newSelectedFiles: individualFiles,
			});

			if (!allFiles && !allFilesMixed) return;

			// allFiles = [...allFiles, ...folderFiles];
			allFiles = [...individualFiles, ...rootFolders];

			const sortedFiles = allFiles.sort((a, b) => a.name.localeCompare(b.name));

			setInputFiles(sortedFiles);

			setInputTimestamp(Date.now());

			const fileForms = await Promise.all(
				sortedFiles.map(file =>
					createFileForm(file, detectTitleAndArtistByDefault)
				)
			);

			// Separate metadata recollection for folder files
			const folderFileForms: { [key: string]: any[] } = {};
			const folderPromises = Object.keys(folderFilesDict).map(
				async (key: string) => {
					const folderForms = await Promise.all(
						folderFilesDict[key].map(file =>
							createFileForm(file, detectTitleAndArtistByDefault)
						)
					);
					const newFolderFileForms = setFileForms(folderForms);
					folderFileForms[key] = newFolderFileForms;
				}
			);
			await Promise.all(folderPromises);

			setFolderFilesMetadata(prevState => ({
				...prevState,
				...folderFileForms,
			}));

			// Metadata recollection for individual files and root project file forms
			// push new files to formik
			const newFileForms = setFileForms(fileForms);

			// set isDetecting to true for all audio files
			newFileForms.forEach((fileForm, index) => {
				if (fileForm.isAudioFile) {
					formik.setFieldValue(`files[${index}].isDetecting`, true);
				}
			});

			// this loader is only used for metadata parsing
			setIsLoading(false);
			// the "AI processing" loader will be showed if there's at least 1 file that has isDetecting set to true

			// array containing the project detection matches for each file
			// in the same order of the files array
			const infoDetections = await Promise.all(
				newFileForms.map(fileForm =>
					detectFileInfo({
						itemFormValues: fileForm,
						fuseIndex: titleIndex.toJSON(),
						userProjects,
						useFilenameAsTitle,
					})
				)
			);

			infoDetections.forEach(({ projectMatch, prediction }, index) => {
				const file = fileForms[index];

				// dont want any detections for non-audio-files
				if (!file.isAudioFile) {
					return;
				}

				if (projectMatch) {
					// set project
					handleChangeProjectOption({
						detected: !useFilenameAsTitle,
						option: projectMatch,
						setFieldValue: formik.setFieldValue,
						index,
					});
					// if useFilenameAsTitle is enabled,
					// then there's no detection, it's pretty manual
				} else {
					// create new project with predicted title and artist
					handleCreateProjectOption({
						title: prediction.title,
						artist: prediction.mainArtistName ?? '',
						detected: !useFilenameAsTitle,
						setFieldValue: formik.setFieldValue,
						index,
						setNewProjects,
					});
				}

				console.debug(
					`[${file.filename.toUpperCase()}]: set isDetecting to false`
				);
				formik.setFieldValue(`files[${index}].isDetecting`, false);
			});
		},
		[
			dispatch,
			storageUsage,
			uploadingStorageUsage,
			inputFiles,
			detectTitleAndArtistByDefault,
		]
	);

	const handleInputChange = useCallback(
		async (selectedFiles: FileList | File[]) => {
			setIsLoading(true);

			// check if input size exceeds storage limit
			const allFiles = await validateStorageUsageAndCorruptedFiles({
				dispatch,
				storageUsage,
				uploadingStorageUsage,
				existingInputFiles: inputFiles,
				newSelectedFiles: selectedFiles,
			});

			if (!allFiles) return; // if some validation failed, then return

			const sortedFiles = allFiles.sort((a, b) => a.name.localeCompare(b.name));

			setInputFiles(sortedFiles);

			setInputTimestamp(Date.now());

			const fileForms = await Promise.all(
				sortedFiles.map(file =>
					createFileForm(file, detectTitleAndArtistByDefault)
				)
			);

			// push new files to formik
			const newFileForms = setFileForms(fileForms);

			// set isDetecting to true for all audio files
			newFileForms.forEach((fileForm, index) => {
				if (fileForm.isAudioFile) {
					formik.setFieldValue(`files[${index}].isDetecting`, true);
				}
			});

			// this loader is only used for metadata parsing
			setIsLoading(false);
			// the "AI processing" loader will be showed if there's at least 1 file that has isDetecting set to true

			// array containing the project detection matches for each file
			// in the same order of the files array
			const infoDetections = await Promise.all(
				newFileForms.map(fileForm =>
					detectFileInfo({
						itemFormValues: fileForm,
						fuseIndex: titleIndex.toJSON(),
						userProjects,
						useFilenameAsTitle,
					})
				)
			);

			infoDetections.forEach(({ projectMatch, prediction }, index) => {
				const file = fileForms[index];

				// dont want any detections for non-audio-files
				if (!file.isAudioFile) {
					return;
				}

				if (projectMatch) {
					// set project
					handleChangeProjectOption({
						detected: !useFilenameAsTitle,
						option: projectMatch,
						setFieldValue: formik.setFieldValue,
						index,
					});
					// if useFilenameAsTitle is enabled,
					// then there's no detection, it's pretty manual
				} else {
					// create new project with predicted title and artist
					handleCreateProjectOption({
						title: prediction.title,
						artist: prediction.mainArtistName ?? '',
						detected: !useFilenameAsTitle,
						setFieldValue: formik.setFieldValue,
						index,
						setNewProjects,
					});
				}

				console.debug(
					`[${file.filename.toUpperCase()}]: set isDetecting to false`
				);
				formik.setFieldValue(`files[${index}].isDetecting`, false);
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			dispatch,
			storageUsage,
			uploadingStorageUsage,
			inputFiles,
			detectTitleAndArtistByDefault,
		]
	);

	function readFileContents(file: any) {
		return new Promise((resolve, reject) => {
			const item = file.webkitGetAsEntry();
			const reader = new FileReader();

			reader.onload = event => {
				const content = event.target?.result;
				resolve(content); // Resolve with the file content
			};

			reader.onerror = error => {
				reject(error); // Reject with the error
			};

			// Start reading the file
			reader.readAsText(file);
		});
	}

	const dropHandler = (e: any) => {
		console.log('On drop');
		let length = e.dataTransfer?.files.length;
		if (length) {
			for (var i = 0; i < length; i++) {
				let file = e.dataTransfer?.files[i];
				console.log('File:', file);
			}
		}
	};

	useEffect(() => {
		inputRef.current = document.querySelector('input[name="files-input"]');
		let dropzone = document.getElementById('dropzone');
		if (dropzone) {
			console.log('About to set event listener');
			dropzone.addEventListener('drop', dropHandler);
		}

		if (inputRef.current) {
			console.log('Set inputRef');
			// inputRef.current.setAttribute('webkitdirectory', '');
			inputRef.current.setAttribute('webkitdirectory', 'webkitdirectory');
			// inputRef.current.setAttribute('directory', 'directory');
			console.log('Set inputRef webkit');
			console.log(inputRef.current);
			console.log(dropzone);
		}
	}, []);

	async function readEntriesPromise(directoryReader: any) {
		try {
			return await new Promise((resolve, reject) => {
				directoryReader.readEntries(resolve, reject);
			});
		} catch (err) {
			console.log(err);
		}
	}

	async function readAllDirectoryEntries(directoryReader: any) {
		let entries = [];
		let readEntries: any = await readEntriesPromise(directoryReader);
		while (readEntries.length > 0) {
			entries.push(...readEntries);
			readEntries = await readEntriesPromise(directoryReader);
		}
		return entries;
	}

	async function handleFilesDrop(dataTransferItemList: any[]) {
		console.log('dataTransferItemList:', dataTransferItemList);
		// let fileEntries = [];
		// // Use BFS to traverse entire directory/file structure
		// let queue = [];
		// // Unfortunately dataTransferItemList is not iterable i.e. no forEach
		// for (let i = 0; i < dataTransferItemList.length; i++) {
		// 	// Note webkitGetAsEntry a non-standard feature and may change
		// 	// Usage is necessary for handling directories
		// 	queue.push(dataTransferItemList[i].webkitGetAsEntry());
		// }
		// console.log('Queue:', queue);
		// while (queue.length > 0) {
		// 	let entry: any = queue.shift();
		// 	if (entry.isFile) {
		// 		fileEntries.push(entry);
		// 	} else if (entry.isDirectory) {
		// 		queue.push(...(await readAllDirectoryEntries(entry.createReader())));
		// 	}
		// }
		// return fileEntries;
	}

	const handleCreateOption = useCallback(
		({ title, artist = '' }: { title: string; artist?: string }) => {
			const id = uuid();
			const newOption = createProjectOption({
				recordingId: null,
				albumId: id,
				title,
				artist,
				isAlbum: true,
			});

			setCreatedAlbum(newOption);

			formik.setFieldValue('album', {
				recordingId: null,
				albumId: id,
			});

			formik.setFieldValue('albumTitle', title);
			formik.setFieldValue('albumArtist', artist);

			formik.setFieldValue('isExistingAlbum', false);
		},
		[formik]
	);

	const handleAlbumChange = useCallback(
		(option: SingleValue<ProjectSelectOption>) => {
			formik.setFieldValue('album', option?.value);

			// if id is number, then it is an existing project
			// if id is string, then it is a new project
			const isExistingProject = typeof option?.value?.albumId === 'number';

			formik.setFieldValue('isExistingAlbum', isExistingProject);

			formik.setFieldValue(`albumTitle`, option?.title);

			formik.setFieldValue(`albumArtist`, option?.artist);
		},
		[formik]
	);

	const handleAlbumSelectBlur = useCallback(
		(e: React.FocusEvent<HTMLInputElement>) => {
			// debugger;
			// if a new album is selected, then we edit the album title
			// otherwise, a new album should be created (nothing needs to be done here for this last condition)

			// by default, a new album will be created on blur. We need to disable this by setting "createOnBlur" to false
			// in the CreatableSelect component, only when a new album is selected
			if (!formik.values.isExistingAlbum && formik.values.album?.albumId) {
				// we need the text value from the blur event
				const { value } = e.target;
				// only edit it if it's non-empty and different from the current value
				if (value && value !== formik.values?.albumTitle) {
					formik.setFieldValue('albumTitle', value);

					setCreatedAlbum(
						prev =>
							({
								...prev!,
								title: value,
							} as ProjectSelectOption)
					);
				}
			}

			formik.handleBlur(e);
		},
		[formik]
	);

	// only run on mount
	useEffect(() => {
		dispatch(getStorageUsageAction()); // update storage usage as this will be opened from Projects
	}, [dispatch]);

	useEffect(() => {
		// push initial files to formik
		if (initialFiles.length) {
			handleInputChange(initialFiles);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [initialFiles]);

	useEffect(() => {
		dispatch(fetchUserEditableProjectsAction()); // refresh editable projects on mount
	}, [dispatch]);

	useEffect(() => {
		if (!localStorage.getItem('preferFilenameAsTitle')) {
			localStorage.setItem('preferFilenameAsTitle', 'false');
			setUseFilenameAsTitle(false);
		} else {
			if (localStorage.getItem('preferFilenameAsTitle') === 'true') {
				setUseFilenameAsTitle(true);
			} else {
				setUseFilenameAsTitle(false);
			}
		}
	}, [setUseFilenameAsTitle]);

	return (
		<div className={className}>
			<FormikProvider value={formik}>
				<Form onSubmit={formik.handleSubmit} placeholder={null}>
					{isDetectingTitleAndArtists ? (
						<div
							style={{
								height: '40vh',
							}}
						>
							<SoundCreditLoader theme='ai-light' message='Processing' />
						</div>
					) : isLoading ? (
						<div
							style={{
								height: '40vh',
							}}
						>
							<SoundCreditLoader theme='light' />
						</div>
					) : (
						<div>
							<div className='mb-4'>
								{/* // TODO: Adapt this so that it works with fromNewPlaylist */}
								{!fromNewPlaylist && projectCount > 0 && (
									<Row className='mb-2'>
										<Col xs={12} className='d-flex justify-content-end'>
											<PillButton
												label='Import Files From Existing Project(s)'
												leftIcon='fas fa-folder-open'
												onClick={() => {
													Promise.resolve(dispatch(hideModal())).then(() => {
														dispatch(
															showModalAction(SELECT_PROJECT_FILES_MODAL, {
																onSubmit: async (files: FileMetadata[]) => {
																	if (!playlistId)
																		throw new Error('No playlist ID');
																	await dispatch(
																		addFilesToPlaylistAction(playlistId, files)
																	);
																},
																size: 'xl',
															})
														);
													});
												}}
											/>
										</Col>
									</Row>
								)}

								{!hideToggles && (
									<Row className='mb-2'>
										<Col
											xs={12}
											className='d-flex justify-content-between align-items-center mb-3'
										>
											<ToggleInput
												label='Detect title and artist from audio files using AI'
												name='detectTitleAndArtist'
												checked={detectTitleAndArtistByDefault}
												value={detectTitleAndArtistByDefault}
												isDisabled={useFilenameAsTitle}
												onChange={e =>
													dispatch(
														setDetectTitleAndArtistAction(e.target.value!)
													)
												}
											/>
										</Col>
										<Col
											xs={12}
											className='d-flex justify-content-between align-items-center mb-3'
										>
											<ToggleInput
												label='Use file names as titles instead of detected names'
												name='useFilenameAsTitle'
												checked={useFilenameAsTitle}
												value={useFilenameAsTitle}
												onChange={e => {
													// formik.setFieldValue(`files[${index}].projectTitle`, file.name);
													localStorage.setItem(
														'preferFilenameAsTitle',
														String(e.target.value!)
													);
													setUseFilenameAsTitle(e.target.value!);
												}}
											/>
										</Col>
										<Col xs={12}>
											<ToggleInput
												label='Include new projects in an album'
												name='includeInAlbum'
												id='includeInAlbum'
												checked={formik?.values?.includeInAlbum}
												value={formik?.values?.includeInAlbum}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
											/>
										</Col>
									</Row>
								)}
								{formik?.values?.includeInAlbum && (
									<Row className='px-5'>
										<Col xs={12} md={6}>
											<CreatableProjectSelect
												name='album'
												label='Select Album'
												placeholder='Select or Create Album'
												value={formik?.values?.album}
												onChange={handleAlbumChange}
												onBlur={handleAlbumSelectBlur}
												createOnBlur={
													formik?.values?.isExistingAlbum ||
													!formik?.values?.album?.albumId
												}
												errorFieldName='album'
												removeReadOnly
												playlistAlbumIds={playlistAlbumIds}
												onCreateOption={value =>
													handleCreateOption({ title: value })
												}
												createdOptions={createdAlbum ? [createdAlbum] : []}
												createNewProjectLabel='Create New Album With Title'
												recordingFilterFn={_ => false} // don't show recordings
												createdOptionsLabel='New Albums'
											/>
										</Col>

										{formik?.values?.album &&
											!formik?.values?.isExistingAlbum && (
												<Col xs={12} md={6}>
													<TextField
														value={
															createdAlbum?.artist ||
															formik?.values?.albumArtist ||
															''
														}
														name='albumArtist'
														label={
															<div className='d-flex align-items-center'>
																Album Main Artist
																<InfoTooltip
																	iconClassName='ml-1'
																	message='These details will be used to create a new album under the Projects tab and include all new projects in it.'
																/>
															</div>
														}
														placeholder='Enter Artist'
														// outerStyle={{
														// 	flex: 5,
														// 	marginLeft: '1rem',
														// }}
														// labelStyle={textFieldLabelStyle}
														errorMessage={formik?.errors?.albumArtist}
														isInvalid={
															!!formik?.errors?.albumArtist &&
															!!formik?.touched?.albumArtist
														}
														onChange={({ target: { value } }) => {
															// if it's an existing project, then don't update the value
															if (formik?.values?.isExistingAlbum) {
																return;
															}

															formik.setFieldValue('albumArtist', value);

															setCreatedAlbum(prev => ({
																...prev!,
																artist: value,
															}));
														}}
														onBlur={formik.handleBlur}
													/>
												</Col>
											)}
									</Row>
								)}
							</div>
							<div>
								<FileDragAndDropInput
									className='upload-files-modal-drop-area'
									inputFiles={inputFiles}
									// onSelect={handleInputChange}
									onSelect={handleDropzoneInputChange}
									onDrop={handleFilesDrop}
								/>
							</div>

							{inputFiles?.length > 0 && <PlaylistInputFileList />}
							{formik?.errors?.files?.length &&
								typeof formik.errors.files === 'string' && (
									<div
										className='text-danger mt-2'
										style={{ fontSize: '0.9rem', textAlign: 'center' }}
									>
										{formik.errors.files}
									</div>
								)}
						</div>
					)}

					{renderFooter && (
						<>
							<div className='mt-4' />
							{renderFooter({
								formik,
								inputFiles,
								isLoading: isDetectingTitleAndArtists || isLoading,
							})}
						</>
					)}
				</Form>
			</FormikProvider>
		</div>
	);
};
export default UploadFilesToPlaylistContent;
