import { shouldRetry } from '../../helpers/networkTools';
import { publisherApi } from '../publishersApi';
// https://search-sound-credit-publishers-rcvkpsqm662x6u46s63bzbp2ey.us-west-2.cloudsearch.amazonaws.com/2013-01-01/search?q=star
import axiosRetry from 'axios-retry';

axiosRetry(publisherApi, {
	retries: 3,
	retryDelay: retryCount => {
		return retryCount * 1000;
	},
	shouldResetTimeout: true,
	retryCondition: shouldRetry,
});

// TODO: TS MIGRATION: CHECK RESPONSE TYPE
export const getPublishers = (query: string) =>
	publisherApi.get<{
		status: {
			rid: string;
			time_ms: number;
		};
		suggest: {
			found: number;
			query: string;
			suggestions: {
				suggestion: string;
				score: number;
				id: string;
			}[];
		};
	}>('/suggest', {
		params: {
			q: query,
			suggester: 'publisher_name_suggester',
		},
	});
