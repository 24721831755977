import { debounce } from 'lodash';
import React, { useEffect, useState, useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { setProfilesSearchFilter } from '../../../store/profiles/actions';
import ActionTopBar from '../../layout/ActionTopBar';
import HeaderButton from '../../layout/HeaderButton';
import SearchBar from '../../layout/SearchBar';
import ProfilesTable from './ProfilesTable';
import SoundCreditLoader from '../SoundCreditLoader/SoundCreditLoader';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Helmet } from 'react-helmet';
import ProfileModal from './ProfileModal';

const ProfileManager = () => {
	const [searchFilter, setSearchFilter] = React.useState('');
	const [showModal, setShowModal] = React.useState(false);
	const dispatch = useAppDispatch();
	const { profiles } = useAppSelector(state => state.profiles);
	const [editing, setEditing] = useState(true);
	// const [importProfilesLoading, setImportProfilesLoading] = useState(false);
	const [newProfile, setNewProfile] = useState<LocalProfile | null>(null);

	const isLoadingProfiles = useMemo(() => profiles === null, [profiles]);

	const toggleShow = () => setShowModal(p => !p);

	const dispatchSearchFilter = (filter: string) => {
		dispatch(setProfilesSearchFilter(filter));
	};

	const debouncedDispatchSearchFilter = useMemo(
		() => debounce(dispatchSearchFilter, 250),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	useEffect(() => {
		debouncedDispatchSearchFilter(searchFilter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchFilter]);

	// TODO: Move to Redux
	// const exportProfiles = () => {
	// 	const data = {
	// 		profiles,
	// 		target: 'portal5',
	// 	};

	// 	const a = document.createElement('a');
	// 	const file = new Blob([JSON.stringify(data)], { type: 'text/plain' });
	// 	a.href = URL.createObjectURL(file);
	// 	a.download = 'profiles.profiles';
	// 	console.log({ a });
	// 	a.click();
	// };

	// TODO: Move to Redux
	// const importProfiles = async () => {
	// 	try {
	// 		const results = await window.showOpenFilePicker();
	// 		const storedProfiles = profiles;
	// 		const file = await results[0].getFile();
	// 		const data = await file.text();
	// 		const { profiles: fetchedProfiles, target } = await JSON.parse(data);

	// 		if (!target || target !== 'portal5') {
	// 			dispatch(
	// 				showAlertToast('Only profiles exported from Portal 5 are accepted.')
	// 			);
	// 			return;
	// 		}
	// 		setImportProfilesLoading(true);

	// 		await Promise.all(
	// 			fetchedProfiles.map(async profile => {
	// 				// Filter possible already saved profiles.
	// 				if (!storedProfiles.find(p => isProfileEqual(p, profile))) {
	// 					const subPublishers = profile.publishers
	// 						.filter(publisher => publisher.subPublishers)
	// 						.map(publisher => publisher.subPublishers)
	// 						.flat()
	// 						.map(p => ({
	// 							id:
	// 								typeof p.publisherId !== 'number'
	// 									? parseInt(p.publisherId)
	// 									: p.publisherId,
	// 							name: p.name,
	// 							pro: p.pro,
	// 							email: p.email,
	// 							ipi: p.ipi,
	// 						}));

	// 					// Add publishers to recording object
	// 					const participantPublishers = [
	// 						...profile.publishers.map(p => ({
	// 							id:
	// 								typeof p.publisherId !== 'number'
	// 									? parseInt(p.publisherId)
	// 									: p.publisherId,
	// 							name: p.name,
	// 							pro: p.pro,
	// 							email: p.email,
	// 							ipi: p.ipi,
	// 						})),
	// 					];

	// 					profile.associatedPublishers = [
	// 						...participantPublishers,
	// 						...subPublishers,
	// 					];

	// 					await createPersonalProfile(profile);
	// 					dispatch(addProfiles([profile]));
	// 				} else {
	// 					console.log('HA! didnt happen', profile.creditedName);
	// 				}
	// 			})
	// 		);
	// 		setImportProfilesLoading(false);
	// 		// const newProfiles = JSON.parse(results.getFile())
	// 	} catch (e) {
	// 		console.log(e);
	// 		setImportProfilesLoading(false);
	// 	}
	// };

	return (
		<>
			<Helmet>
				<title>Saved Profiles {process.env.REACT_APP_TAB_TITLE}</title>
			</Helmet>
			{isLoadingProfiles ? (
				<SoundCreditLoader message='Loading Profiles...' />
			) : (
				<>
					<ActionTopBar>
						<Row>
							<Col
								xs={12}
								className='d-flex justify-content-end header-buttons'
							>
								{/* <HeaderButton
							label='Export All'
							icon='fa fa-file-export'
							onClick={exportProfiles}
						/> */}

								{/* <HeaderButton
							label='Import Profiles'
							icon='fa fa-file-import'
							onClick={importProfiles}
							isDisabled={importProfilesLoading}
							isLoading={importProfilesLoading}
						/> */}

								<HeaderButton
									label='Add Profile'
									icon='fa fa-user-plus'
									onClick={() => {
										setEditing(false);
										setShowModal(true);
									}}
								/>
							</Col>
						</Row>
					</ActionTopBar>
					<Container className='mt-5 container-horizontal-padding'>
						{/* Search bar */}
						<Row>
							<Col xs={8} />
							<Col xs={4}>
								<SearchBar
									value={searchFilter}
									onChange={val => setSearchFilter(val)}
									fullWidth
								/>
							</Col>
						</Row>
						<Row className='mt-3'>
							<Col xs={12}>
								<ProfilesTable editing={editing} setEditing={setEditing} />
							</Col>
						</Row>
					</Container>
					<ProfileModal
						show={showModal}
						toggleShow={toggleShow}
						editing={editing}
						profile={newProfile}
						setProfile={setNewProfile}
					/>
				</>
			)}
		</>
	);
};

export default ProfileManager;
