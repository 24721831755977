import React, { useState } from 'react';
import { useCallback } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import SoundCreditLoader from '../../screens/SoundCreditLoader';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import './PdfPreview.scss';

const PdfPreview = ({ file }) => {
	const [numPages, setNumPages] = useState(null);
	const [progress, setProgress] = useState(0);

	const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const onLoadProgress = ({ loaded, total }) => {
		setProgress(Math.round((loaded / total) * 100));
	};

	const renderLoading = useCallback(
		() => (
			<SoundCreditLoader
				style={{ backgroundColor: 'white', borderRadius: 5 }}
				progress={progress}
			/>
		),
		[progress]
	);

	return (
		<div>
			<div style={{ height: '30vh' }}></div>

			<Document
				file={file}
				onLoadSuccess={onDocumentLoadSuccess}
				onLoadProgress={onLoadProgress}
				loading={renderLoading}
				className='vh-100'
			>
				{Array.from(new Array(numPages), (el, index) => (
					<Page
						key={`page_${index + 1}`}
						pageNumber={index + 1}
						className='mb-4'
						scale={1.5}
					/>
				))}
			</Document>
		</div>
	);
};

export default PdfPreview;
