import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { Col, Modal, Row } from 'react-bootstrap';
import {
	DELETE_MODAL,
	EXPORT_IMAGE_INPUT_MODAL,
} from '../../../../../constants/modalTypes';
import { getDripShareLink } from '../../../../../helpers/playlistTools';
import {
	hideAllModalsAction,
	hideModal,
	setModalTitle,
	showModalAction,
} from '../../../../../store/modal/actions';
import { unpublishDripAction } from '../../../../../store/playlists/actions';
import TextField from '../../../../form/TextField';
import PillButton from '../../../../layout/PillButton';
import './PlaylistDripDashboard.scss';
import { replacePathVariables } from '../../../../../helpers/routeTools';
import ROUTES from '../../../../../router/routes';
import DripDashboardCard from './DripDashboardCard';
import { shortEnglishHumanizer } from '../../../../../helpers/dateTools';
import { formatDateToStr } from '../../../../utils/dateFormatters';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { getPlaylistById } from '../../../../../store/playlists/selectors';
import {
	downloadDripImagesAction,
	generateDripImagesAction,
} from '../../../../../store/exports/actions';

const PlaylistDripDashboard = ({ playlistId }: { playlistId: Playlist['id'] }) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const playlist = useAppSelector(state =>
		getPlaylistById(state, { playlistId })
	);

	const [isDownloadingImage, setIsDownloadingImage] = React.useState(false);

	const [copyTooltipText, setCopyTooltipText] =
		React.useState('Copy Drip Link');

	const dripEnded = useMemo(() => {
		if (!playlist?.playlist?.dripEnd) return false;

		const now = new Date();
		return new Date(playlist?.playlist?.dripEnd) < now;
	}, [playlist]);

	const dripDuration = useMemo(() => {
		if (
			!playlist?.playlist?.dripStart ||
			!playlist?.playlist?.dripEnd ||
			new Date(playlist?.playlist?.dripStart) >
				new Date(playlist?.playlist?.dripEnd)
		)
			return null;

		return shortEnglishHumanizer(
			new Date(playlist?.playlist?.dripEnd)?.getTime() -
				new Date(playlist?.playlist?.dripStart)?.getTime()
		);
	}, [playlist]);

	const publishedDate = useMemo(() => {
		if (!playlist?.playlist?.dripStart) return null;

		return formatDateToStr(playlist?.playlist?.dripStart);
	}, [playlist]);

	const publishedDuration = useMemo(() => {
		if (!playlist?.playlist?.dripStart) return null;

		return shortEnglishHumanizer(
			Date.now() - new Date(playlist?.playlist?.dripStart)?.getTime(),
			{
				largest: 1,
			}
		);
	}, [playlist]);

	const dripShareLink = useMemo(
		() => (playlist ? getDripShareLink(playlist) : ''),
		[playlist]
	);

	const handleCopy = () => {
		navigator.clipboard.writeText(dripShareLink);
		setCopyTooltipText('Copied!');

		setTimeout(() => {
			setCopyTooltipText('Copy Drip Link');
		}, 2000);
	};

	const handleStats = () => {
		navigate(
			replacePathVariables(ROUTES.DripStats.path, {
				playlistId: playlistId,
			})
		);
		dispatch(hideModal());
	};

	const handleDripImageUpload = (image: string, mimeType: string) =>
		dispatch(generateDripImagesAction(playlistId, image, mimeType))?.then(
			() => {
				dispatch(hideModal());
			}
		);

	useEffect(() => {
		dispatch(setModalTitle('DRIP DASHBOARD'));
	}, [dispatch]);

	return (
		<>
			<Modal.Body>
				<div className='px-4'>
					<Row>
						<Col xs={12}>
							<div className='drip-dashboard__share-link'>
								<TextField
									label='Drip Link'
									value={dripShareLink}
									readOnly
									buttonLabel='Copy'
									buttonLeftIcon='fas fa-copy'
									onButtonClick={handleCopy}
									buttonTooltipText={copyTooltipText}
									forceEnableButton
									onChange={() => {}}
								/>
							</div>
						</Col>
						<Col xs={12} className='d-flex align-items-center'>
							<PillButton
								label='View Drip Stats'
								leftIcon='fas fa-chart-line'
								onClick={handleStats}
								className='mr-2'
							/>
							<PillButton
								label='Unpublish Drip'
								theme='outline-danger'
								onClick={() => {
									dispatch(
										showModalAction(DELETE_MODAL, {
											size: 'md',
											title: 'UNPUBLISH DRIP',
											body: <>Are you sure you want to unpublish this Drip?</>,
											onDelete: () =>
												dispatch(unpublishDripAction(playlistId)).then(() => {
													dispatch(hideAllModalsAction());
												}),
											confirmText: 'Confirm',
											showUndoWarning: false,
										})
									);
								}}
								leftIcon='fas fa-tint-slash'
								className='mr-2'
							/>
							{playlist?.playlist?.dripImageId && (
								<PillButton
									label='Download Promotional Image'
									leftIcon='fas fa-download'
									className='mr-2'
									isLoading={isDownloadingImage}
									onClick={() => {
										setIsDownloadingImage(true);
										dispatch(downloadDripImagesAction(playlist!.id))?.finally(
											() => {
												setIsDownloadingImage(false);
											}
										);
									}}
								/>
							)}
							<PillButton
								label={`Get ${
									playlist?.playlist?.dripImageId ? 'New' : ''
								} Promotional Image`}
								leftIcon='fas fa-images'
								onClick={() =>
									dispatch(
										showModalAction(EXPORT_IMAGE_INPUT_MODAL, {
											size: 'lg',
											onSubmit: handleDripImageUpload,
											title: 'GET PROMOTIONAL IMAGE',
										})
									)
								}
							/>
						</Col>
					</Row>

					<hr />

					{dripEnded ? (
						<div className='drip-dashboard__notice'>
							<>
								<i className='fas fa-check-circle mr-2' />
								This Drip is complete! | Completed on{' '}
								{formatDateToStr(playlist?.playlist?.dripEnd)}
							</>
						</div>
					) : publishedDate ? (
						<div className='drip-dashboard__publish-date'>
							<i className='fas fa-calendar-day mr-2' />
							Published on {publishedDate} ({publishedDuration} ago)
						</div>
					) : (
						<></>
					)}
					<Row className='mt-4'>
						<Col xs={4}>
							<DripDashboardCard
								number={playlist?.playlist?.dripTickets!}
								title='Ticket Capacity'
								icon='fas fa-ticket-alt mr-2'
							/>
						</Col>
						<Col xs={4}>
							<DripDashboardCard
								number={playlist?.playlist?.dripTicketsClaimed!}
								title='Tickets Claimed'
								icon='fas fa-ticket-alt mr-2'
							/>
						</Col>
						{dripDuration && (
							<Col xs={4}>
								<DripDashboardCard
									className='drip-dashboard-card__duration'
									number={dripDuration}
									title='Drip Duration'
									icon='fas fa-clock mr-2'
								/>
							</Col>
						)}
					</Row>
				</div>

				<br />
			</Modal.Body>
		</>
	);
};

export default PlaylistDripDashboard;
