import fileSize from 'filesize';
import path from 'path-browserify';
import React, { useMemo } from 'react';
import { Col, ProgressBar, Row, Toast, ToastContainer } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import DownloadStatus from '../../../constants/downloadStatus';
import {
	clearDownloadFromQueueAction,
	downloadFileFromQueueAction,
	retryFileDownloadAction,
} from '../../../store/files/actions';
import IconButton from '../IconButton';
import './DownloadToast.scss';

const DownloadToast = () => {
	const { downloadQueueById } = useSelector(state => state.files);
	const dispatch = useDispatch();
	const [collapsed, setCollapsed] = React.useState(false);

	const downloads = useMemo(
		() =>
			Object.values(downloadQueueById).sort(
				(a, b) => new Date(b.createdAt) - new Date(a.createdAt)
			),
		[downloadQueueById]
	);

	const show = useMemo(() => Object.keys(downloads).length > 0, [downloads]);

	React.useEffect(() => {
		// poll for downloads
		const filesReadyToDownload = downloads.filter(
			download => download.status === DownloadStatus.READY_TO_DOWNLOAD
		);

		if (filesReadyToDownload.length > 0) {
			// start download for first file
			const download = filesReadyToDownload[0];

			// start download
			dispatch(downloadFileFromQueueAction(download.id));
		}
	}, [dispatch, downloads]);

	return (
		<ToastContainer position='bottom-end' className='p-3'>
			<Toast show={show}>
				<Toast.Header closeButton={false} className='download-toast__header'>
					<div>
						<strong className='mr-auto'>Downloads</strong>
					</div>
					<IconButton
						icon={collapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up'}
						onClick={() => setCollapsed(!collapsed)}
					/>
				</Toast.Header>
				{!collapsed && (
					<Toast.Body className='download-toast__body'>
						{downloads.map((download, index) => (
							<React.Fragment key={download.id}>
								<DownloadItem download={download} />
								{index < downloads.length - 1 && <hr />}
							</React.Fragment>
						))}
					</Toast.Body>
				)}
			</Toast>
		</ToastContainer>
	);
};

const DownloadItem = ({ download }) => {
	const dispatch = useDispatch();

	const extension = useMemo(
		() =>
			path.extname(download?.metadata?.filename).replace('.', '').toUpperCase(),
		[download]
	);

	const showProgress = useMemo(
		() =>
			[DownloadStatus.IN_PROGRESS, DownloadStatus.READY_TO_DOWNLOAD].includes(
				download.status
			),
		[download]
	);

	const handleCancel = () => {
		download?.abortController?.abort();
	};

	const handleRetry = () => {
		dispatch(retryFileDownloadAction(download.id));
	};

	const handleClear = () => {
		dispatch(clearDownloadFromQueueAction(download.id));
	};

	const renderActions = () => {
		switch (download.status) {
			case DownloadStatus.READY_TO_DOWNLOAD:
				return (
					<>
						<IconButton icon='fas fa-redo' isDisabled />
						<IconButton
							icon='fas fa-times'
							onClick={handleCancel}
							tooltipText='Cancel'
						/>
					</>
				);
			case DownloadStatus.IN_PROGRESS:
				return (
					<>
						<IconButton icon='fas fa-redo' isDisabled />
						<IconButton
							icon='fas fa-times'
							onClick={handleCancel}
							tooltipText='Cancel'
						/>
					</>
				);
			case DownloadStatus.SUCCESS:
				return (
					<IconButton
						icon='fas fa-times'
						onClick={handleClear}
						tooltipText='Clear'
					/>
				);
			case DownloadStatus.ERROR:
			case DownloadStatus.CANCELLED:
				return (
					<>
						<IconButton
							icon='fas fa-redo'
							onClick={handleRetry}
							tooltipText='Retry'
						/>
						<IconButton
							icon='fas fa-times'
							onClick={handleClear}
							tooltipText='Clear'
						/>
					</>
				);
			default:
				return <></>;
		}
	};

	const renderIcon = () => {
		switch (download.status) {
			case DownloadStatus.READY_TO_DOWNLOAD:
			case DownloadStatus.IN_PROGRESS:
				return <i className='fas fa-file-download ' />;
			case DownloadStatus.SUCCESS:
				return (
					<i className='fas fa-check-circle download-item__success-icon' />
				);
			case DownloadStatus.ERROR:
			case DownloadStatus.CANCELLED:
				return (
					<i className='fas fa-exclamation-triangle download-item__error-icon' />
				);
			default:
				return <></>;
		}
	};

	return (
		<div>
			<Row>
				<Col xs={1}>
					<div className='download-item__icon'>{renderIcon()}</div>
				</Col>
				<Col xs={8} className='d-flex justify-content-center flex-column pl-3'>
					<div style={{ lineHeight: '1.1rem', fontSize: '0.9rem' }}>
						<div className='fw-600 download-item__title'>
							{download?.metadata?.filename}
						</div>
						{extension && (
							<div className='download-item__extension'>
								{`${extension ? extension + ' - ' : ''}`}
								{fileSize(download?.metadata?.fileSize)}
							</div>
						)}
					</div>
					{showProgress && (
						<ProgressBar
							now={download?.progress}
							animated
							striped
							className='mt-2'
						/>
					)}
					{download?.errorMessage && (
						<div className='download-item__error-message'>
							{download?.errorMessage}
						</div>
					)}
				</Col>
				<Col xs={3} className='d-flex align-items-center justify-content-end'>
					{renderActions()}
				</Col>
			</Row>
		</div>
	);
};

export default DownloadToast;
