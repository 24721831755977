import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import isniStrings, { IsniString } from '../isniStrings';
import TextField from '../../../form/TextField/TextField';
import IsniRoles from '../../../../constants/isniRoles';
import Button from '../../../layout/Button/Button';
import { Form } from 'react-bootstrap';
import { Dropdown } from 'primereact/dropdown';

type PriorWorkStepProps = {
	onBack: (values: PriorWorkFormikValues) => void;
	onSubmit: (values: PriorWorkFormikValues) => Promise<void>;
	initialFormValues?: PriorWorkFormikValues | null;
	getIsniStringFn: (isniString: IsniString) => string;
};

export type PriorWorkFormikValues = {
	title: string;
	artistName: string;
	role: string;
};

const roleOptions = Object.values(IsniRoles).map(role => ({
	name: role.valueOf(),
	value: role.valueOf(),
}));

const validationSchema = Yup.object({
	title: Yup.string().required('Required'),
	artistName: Yup.string().required('Required'),
	role: Yup.string()
		.oneOf(roleOptions.map(opt => opt.name))
		.required('Required'),
});

const PriorWorkStep = ({
	onSubmit,
	initialFormValues,
	getIsniStringFn,
	onBack,
}: PriorWorkStepProps) => {
	const formik = useFormik<PriorWorkFormikValues>({
		initialValues: initialFormValues ?? {
			title: '',
			artistName: '',
			role: '',
		},
		onSubmit: async values => {
			console.log('values', values);
			await onSubmit(values);
		},
		validationSchema,
		validateOnMount: true,
		enableReinitialize: true,
	});

	return (
		<FormikProvider value={formik}>
			<h3>{getIsniStringFn(isniStrings.PriorWork.title)}</h3>

			<h4>{getIsniStringFn(isniStrings.PriorWork.description)}</h4>

			<div className='isni-registration__required text-muted'>
				* indicates a required field. All other fields are optional.
			</div>

			<TextField
				label={getIsniStringFn(isniStrings.PriorWork.form.title.label)}
				name='title'
				placeholder={getIsniStringFn(isniStrings.PriorWork.form.title.label)}
				showRequiredAsterisk
				value={formik.values.title}
				onChange={formik.handleChange}
				isInvalid={formik.touched.title && !!formik.errors.title}
				errorMessage={formik.errors.title}
				onBlur={formik.handleBlur}
			/>

			<TextField
				label={getIsniStringFn(isniStrings.PriorWork.form.artistName.label)}
				name='artistName'
				placeholder={getIsniStringFn(
					isniStrings.PriorWork.form.artistName.label
				)}
				showRequiredAsterisk
				value={formik.values.artistName}
				onChange={formik.handleChange}
				isInvalid={formik.touched.artistName && !!formik.errors.artistName}
				errorMessage={formik.errors.artistName}
				onBlur={formik.handleBlur}
			/>

			<Form.Label htmlFor='role'>
				{getIsniStringFn(isniStrings.PriorWork.form.role.label)}*
			</Form.Label>
			<Dropdown
				value={formik.values.role}
				onChange={e => formik.setFieldValue('role', e.value)}
				options={roleOptions}
				optionLabel='name'
				name='role'
				id='role'
				className='w-100'
				placeholder={getIsniStringFn(isniStrings.PriorWork.form.role.label)}
				valueTemplate={(option, props) =>
					option ? option.name : props.placeholder
				}
				optionValue='value'
				itemTemplate={role => role.name}
			/>

			{formik.touched.role && !!formik.errors.role && (
				<small className='text-danger'>{formik.errors.role}</small>
			)}

			<div className='isni-registration__buttons mt-5'>
				<Button
					label='Back'
					onClick={() => onBack(formik.values)}
					leftIcon='fas fa-arrow-left'
					theme='dark'
				/>
				<Button
					label='Next'
					onClick={formik.submitForm}
					rightIcon='fas fa-arrow-right'
					theme='dark'
					isLoading={formik.isSubmitting}
				/>
			</div>
		</FormikProvider>
	);
};

export default PriorWorkStep;
