import fileSize from 'filesize';
import path from 'path-browserify';
import React, { useMemo } from 'react';
import { Col, ProgressBar, Row, Toast, ToastContainer } from 'react-bootstrap';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import DownloadStatus from '../../../constants/downloadStatus';
import {
	clearDownloadFromQueueAction,
	downloadFileFromQueueAction,
	retryFileDownloadAction,
} from '../../../store/files/actions';
import IconButton from '../IconButton';
import './FolderZipToast.scss';

const FolderZipToast = () => {
	const { folderDownloadQueueById } = useSelector(
		(state: RootStateOrAny) => state.files
	);
	const dispatch = useDispatch();
	const [collapsed, setCollapsed] = React.useState(false);

	const folderDownloads: PlaylistFolder[] = useMemo(
		() =>
			// Object.values(folderDownloadQueueById).sort(
			// 	(a, b) => new Date(b.createdAt) - new Date(a.createdAt)
			// ),
			Object.values(folderDownloadQueueById),
		[folderDownloadQueueById]
	);

	const show = useMemo(
		() => Object.keys(folderDownloads).length > 0,
		[folderDownloads]
	);

	return (
		<ToastContainer position='bottom-end' className='p-3'>
			<Toast show={show}>
				<Toast.Header closeButton={false} className='download-toast__header'>
					<div>
						<strong className='mr-auto'>Packaging Folder</strong>
					</div>
					<IconButton
						icon={collapsed ? 'fas fa-chevron-down' : 'fas fa-chevron-up'}
						onClick={() => setCollapsed(!collapsed)}
					/>
				</Toast.Header>
				{!collapsed && (
					<Toast.Body className='download-toast__body'>
						{folderDownloads.map((download, index) => (
							<React.Fragment key={download.id}>
								<DownloadItem download={download} />
								{index < folderDownloadQueueById.length - 1 && <hr />}
							</React.Fragment>
						))}
					</Toast.Body>
				)}
			</Toast>
		</ToastContainer>
	);
};

const DownloadItem = ({ download }: { download: PlaylistFolder }) => {
	const dispatch = useDispatch();

	const handleCancel = () => {
		// TODO Cancel
		// download?.abortController?.abort();
	};

	const renderActions = () => {
		return (
			<>
				{/* <IconButton icon='fas fa-redo' isDisabled /> */}
				{/* <IconButton
					icon='fas fa-times'
					onClick={handleCancel}
					tooltipText='Cancel'
				/> */}
			</>
		);
	};

	const renderIcon = () => {
		return <i className='fas fa-file-download ' />;
	};

	return (
		<div>
			<Row>
				<Col xs={1}>
					<div className='download-item__icon'>{renderIcon()}</div>
				</Col>
				<Col xs={8} className='d-flex justify-content-center flex-column pl-3'>
					<div style={{ lineHeight: '1.1rem', fontSize: '0.9rem' }}>
						<div className='fw-600 download-item__title'>{download.name}</div>
					</div>
				</Col>
				<Col xs={3} className='d-flex align-items-center justify-content-end'>
					{renderActions()}
				</Col>
			</Row>
		</div>
	);
};

export default FolderZipToast;
