import { AnyAction } from 'redux';
import {
	START_PLAYLIST_JULY_2023_PROMO_TIMER,
	SET_PROMO_ACTIVE,
	CLEAR_PROMOS,
	EXTEND_PLAYLIST_JULY_2023_PROMO,
	SET_PROMO_SEEN,
} from '../actionTypes';
import { createTimerExpirationDate } from '../../helpers/dateTools';

type PromosState = {
	activePromos: ActivePromos;
	seenPromos: SeenPromos;
	playlistJuly2023PromoTimerExpiration?: string | null;
	extendedPlaylistJuly2023Promo: boolean;
};

const initialState: PromosState = {
	activePromos: {},
	seenPromos: {},
	playlistJuly2023PromoTimerExpiration: null,
	extendedPlaylistJuly2023Promo: false,
};

const JULY_2023_PROMO_TIMER_DURATION = 10; // minutes

const reducer = (state = initialState, action: AnyAction): PromosState => {
	switch (action.type) {
		case SET_PROMO_ACTIVE:
			return {
				...state,
				activePromos: {
					...state.activePromos,
					[action.promo]: action.active,
				},
			};
		case SET_PROMO_SEEN:
			return {
				...state,
				seenPromos: {
					...state.seenPromos,
					[action.promo]: action.seen,
				},
			};
		case START_PLAYLIST_JULY_2023_PROMO_TIMER:
			return {
				...state,
				playlistJuly2023PromoTimerExpiration: createTimerExpirationDate(
					JULY_2023_PROMO_TIMER_DURATION
				).toISOString(),
			};
		case EXTEND_PLAYLIST_JULY_2023_PROMO:
			if (state.extendedPlaylistJuly2023Promo) {
				return state;
			}

			return {
				...state,
				extendedPlaylistJuly2023Promo: true,
				playlistJuly2023PromoTimerExpiration:
					createTimerExpirationDate(5).toISOString(),
			};
		case CLEAR_PROMOS:
			return initialState;
		default:
			return state;
	}
};

export default reducer;
