import React, { useCallback, useMemo } from 'react';
import Select from '../Select';
import { useAppSelector } from '../../../store/hooks';
import { DEFAULT_FILE_LABEL } from '../../../helpers/fileTools';

type FileLabel = FileMetadata['label'];

export type FileTagSelectProps = {
	setFileTag: (fileTag: FileLabel | null) => void;
	fileTag: FileLabel | null;
	dense?: boolean;
	inputLabel?: string;
	name?: string;
	labelStyle?: React.CSSProperties;
	defaultValue?: FileLabel | null;
};

const FileTagSelect = ({
	setFileTag,
	fileTag,
	dense = false,
	inputLabel = '',
	name = '',
	labelStyle = {},
	defaultValue = DEFAULT_FILE_LABEL,
}: FileTagSelectProps) => {
	const { fileLabelDetails } = useAppSelector(state => state.files);

	const options = useMemo(
		() =>
			fileLabelDetails
				? Object.entries(fileLabelDetails)
						.map(([key, value]) => ({
							value: parseInt(key),
							label: value,
						}))
						.filter(option => option.value !== 0)
				: [],
		[fileLabelDetails]
	);

	const selectedLabel = useMemo(
		() =>
			fileTag === 0
				? null
				: options.find(option => option?.value === fileTag) || null,
		[fileTag, options]
	);

	const handleSetFileLabel = useCallback(
		(option: (typeof options)[number] | null) =>
			setFileTag(option?.value ?? defaultValue),
		[setFileTag, defaultValue]
	);

	return (
		<div>
			<Select
				name={name}
				dense={dense}
				label={inputLabel}
				options={options}
				value={selectedLabel}
				onChange={handleSetFileLabel}
				placeholder={<div>Select Tag</div>}
				className='mr-2'
				isClearable
				labelStyle={labelStyle}
			/>
		</div>
	);
};

export default FileTagSelect;
