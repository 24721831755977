import '../master.scss';
import ModalPopup from '../layout/ModalPopup';
import ModalBody from '../layout/ModalBody';
import { useEffect } from 'react';
import { showAlertToast, hideAlertToast } from '../../store/alertToast/actions';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import AppRouter from '../../router/AppRouter';
import { ErrorLevel } from '../../helpers/errors';
import AudioPlayerBar from '../layout/AudioPlayerBar/AudioPlayerBar';
import theme from '../../theme.module.scss';

function App() {
	const { modalStack } = useAppSelector(state => state.modal);
	const { playlistId: playingPlaylistId } = useAppSelector(
		state => state.player
	);
	const dispatch = useAppDispatch();

	// Internet disconnect event
	useEffect(() => {
		window.addEventListener('offline', () => {
			dispatch(
				showAlertToast(
					'Gremlins detected: Could not connect to the Internet.',
					ErrorLevel.ERROR
				)
			);
		});

		window.addEventListener('online', () => dispatch(hideAlertToast()));

		return () => {
			window.removeEventListener('offline', () =>
				dispatch(
					showAlertToast(
						'Gremlins detected: Could not connect to the Internet.',
						ErrorLevel.ERROR
					)
				)
			);
			window.removeEventListener('online', () => dispatch(hideAlertToast()));
		};
	}, [dispatch]);

	// debugger;
	return (
		<div className='wrapper'>
			{/* Global Modal used with Redux */}
			{modalStack.map(modal => (
				<ModalPopup
					show={modal.showModal}
					modalId={modal.id}
					key={modal.id}
					title={modal.title}
					modalProps={modal.props}
				>
					<ModalBody modalType={modal.modalType} modalProps={modal.props} />
				</ModalPopup>
			))}

			<div
				style={{
					height: playingPlaylistId
						? `calc(100vh - ${theme.audioPlayerBarHeight})`
						: '100vh',
				}}
			>
				<AppRouter />
			</div>
			{playingPlaylistId ? <AudioPlayerBar /> : null}
		</div>
	);
}

export default App;
