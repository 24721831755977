import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import SearchTable from '../../../layout/SearchTable';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
	addRecordingsToCloudAlbumAction,
	fetchUserEditableProjectsAction,
} from '../../../../store/projects/actions';
import {
	hideModal,
	setModalTitle,
	showModalAction,
} from '../../../../store/modal/actions';
import Button from '../../../layout/Button/Button';
import {
	CONFIRMATION_MODAL,
	CREATE_NEW_ALBUM_MODAL,
} from '../../../../constants/modalTypes';
import PillButton from '../../../layout/PillButton/PillButton';

export type GroupToAlbumModalProps = {
	recordingIds: number[];
};

const albumColumns = [
	{
		Header: () => <span className='Project-Name'>Album Title</span>,
		accessor: 'title',
		Cell: (col: any) => <span className='Name1'>{col.value}</span>,
	},
	{
		Header: () => <span className='Project-Name'>Artist</span>,
		accessor: 'artist',
		Cell: (col: any) => <span className='Name1'>{col.value}</span>,
	},
];

const GroupToAlbumModal = ({ recordingIds }: GroupToAlbumModalProps) => {
	const { userEditableAlbumIds, userEditableRecordingIds, albumsById } =
		useAppSelector(state => state.projects);
	const [isGrouping, setIsGrouping] = useState(false);
	const [selectedAlbumId, setSelectedAlbumId] = useState<number | null>(null);
	const dispatch = useAppDispatch();

	const columns = useMemo(() => albumColumns, []);
	const userEditableAlbums = useMemo(
		() =>
			(userEditableAlbumIds ?? [])
				.map(id => albumsById?.[id])
				.filter(Boolean)
				.sort((a, b) => b!.id - a!.id),
		[userEditableAlbumIds, albumsById]
	);

	const handleCreateNewAlbum = () => {
		dispatch(
			showModalAction(CREATE_NEW_ALBUM_MODAL, {
				recordingIds,
				onCreate: () => dispatch(hideModal()),
				size: 'lg',
			})
		);
	};
	const handleSelect = (selection: any) => {
		console.log('handleSelect', selection?.[0]?.original?.id);
		setSelectedAlbumId(selection?.[0]?.original?.id);
	};

	const handleGroup = async () => {
		if (!selectedAlbumId) return;

		if (recordingIds.some(id => !userEditableRecordingIds?.includes(id))) {
			dispatch(
				showModalAction(CONFIRMATION_MODAL, {
					size: 'md',
					title: "That's Not Allowed!",
					description:
						'You can only group recordings that you own or have been shared with you with co-admin access.',
					confirmAction: {
						onClick: () => dispatch(hideModal()),
						label: 'OK',
					},
				})
			);

			return;
		}

		setIsGrouping(true);

		try {
			await dispatch(
				addRecordingsToCloudAlbumAction(selectedAlbumId, recordingIds)
			);
		} finally {
			setIsGrouping(false);
			dispatch(hideModal());
		}
	};

	useEffect(() => {
		dispatch(setModalTitle('GROUP SELECTED RECORDINGS TO ALBUM'));
	}, [dispatch]);

	useEffect(() => {
		// fetch user editable albums and recordings
		dispatch(fetchUserEditableProjectsAction());
	}, [dispatch]);

	return (
		<>
			<Modal.Body>
				<div className='px-4'>
					<div className='d-flex w-100 justify-content-end mb-3'>
						<PillButton
							label='Create New Album'
							onClick={handleCreateNewAlbum}
							leftIcon='fas fa-plus'
							theme='solid-light'
						/>
					</div>
					<SearchTable
						columns={columns}
						data={userEditableAlbums}
						singleSelect
						onSelect={handleSelect}
						searchBarPlaceholder='Search by Artist or Album Title'
					/>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					theme='light'
					className='mr-2'
					onClick={() => {
						dispatch(hideModal());
					}}
					label='Cancel'
				/>
				<Button
					theme='dark'
					label='Group'
					isLoading={isGrouping}
					onClick={handleGroup}
				/>
			</Modal.Footer>
		</>
	);
};

export default GroupToAlbumModal;
