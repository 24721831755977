import React from 'react';
import { BasicInfoFormikValues } from './BasicInfoStep';
import { PriorWorkFormikValues } from './PriorWorkStep';
import { CareerFormikValues } from './CareerStep';
import { WorkRelationFormikValues } from './WorkRelationStep';
import PillButton from '../../../layout/PillButton/PillButton';
import { Divider } from 'primereact/divider';
import Button from '../../../layout/Button/Button';

type ReviewInfoStepProps = {
	onSubmit: () => Promise<void>;
	onBack: () => void;
	basicInfoForm: BasicInfoFormikValues;
	priorWorkForm: PriorWorkFormikValues;
	careerForm: CareerFormikValues;
	workRelationForm: WorkRelationFormikValues;
	onEditBasicInfo: () => void;
	onEditPriorWork: () => void;
	onEditCareer: () => void;
	onEditWorkRelation: () => void;
};

const ReviewInfoStep = ({
	onSubmit,
	onBack,
	basicInfoForm,
	priorWorkForm,
	careerForm,
	workRelationForm,
	onEditBasicInfo,
	onEditPriorWork,
	onEditCareer,
	onEditWorkRelation,
}: ReviewInfoStepProps) => {
	return (
		<div>
			<h3>Review Information</h3>

			<h4>
				Please confirm that the information you have entered is complete and
				accurate before submitting your request.
			</h4>

			<div className='my-3' />

			<h5>
				<strong>Name and Date of Birth</strong>
			</h5>

			<div className='d-flex w-100 justify-content-between align-items-center'>
				<div>
					<p className='mb-0'>
						<strong>Name:</strong> {basicInfoForm.firstName}
					</p>

					<p className='mb-0'>
						<strong>Middle/Last Name:</strong> {basicInfoForm.middleName}{' '}
						{basicInfoForm.lastName}
					</p>

					<p className='mb-0'>
						<strong>Date of Birth:</strong> {basicInfoForm.lastName}
					</p>
				</div>
				<PillButton
					label='Edit'
					leftIcon='fas fa-pencil-alt'
					onClick={onEditBasicInfo}
					theme='solid-light'
				/>
			</div>

			<Divider />

			<h5>
				<strong>Noted Prior Work</strong>
			</h5>

			<div className='d-flex w-100 justify-content-between align-items-center'>
				<div>
					<p className='mb-0'>
						<strong>Title:</strong> {priorWorkForm.title}
					</p>

					<p className='mb-0'>
						<strong>Artist:</strong> {priorWorkForm.artistName}
					</p>

					<p className='mb-0'>
						<strong>Role:</strong> {priorWorkForm.role}
					</p>
				</div>

				<PillButton
					label='Edit'
					leftIcon='fas fa-pencil-alt'
					onClick={onEditPriorWork}
					theme='solid-light'
				/>
			</div>
			<Divider />

			<h5>
				<strong>Website Resource</strong>
			</h5>

			<div className='d-flex w-100 justify-content-between align-items-center'>
				<div>
					<p className='mb-0'>
						<strong>Website URL:</strong> {careerForm.websiteAddress}
					</p>

					<p className='mb-0'>
						<strong>Website Title:</strong> {careerForm.websiteTitle}
					</p>
				</div>
				<PillButton
					label='Edit'
					leftIcon='fas fa-pencil-alt'
					onClick={onEditCareer}
					theme='solid-light'
				/>
			</div>

			<Divider />

			{workRelationForm.relatedPersonOrOrganization === 'person' ? (
				<>
					<h5>
						<strong>Related Person</strong>
					</h5>

					<div className='d-flex w-100 justify-content-between align-items-center'>
						<div>
							<p className='mb-0'>
								<strong>First Name:</strong> {workRelationForm.relatedForename}
							</p>

							<p className='mb-0'>
								<strong>Last Name:</strong> {workRelationForm.relatedSurname}
							</p>
						</div>

						<PillButton
							label='Edit'
							leftIcon='fas fa-pencil-alt'
							onClick={onEditWorkRelation}
							theme='solid-light'
						/>
					</div>
				</>
			) : (
				<>
					<h5>
						<strong>Related Organization</strong>

						<div className='d-flex w-100 justify-content-between align-items-center'>
							<div>
								<p className='mb-0'>
									<strong>Name:</strong>{' '}
									{workRelationForm.relatedOrganizationName}
								</p>
							</div>

							<PillButton
								label='Edit'
								leftIcon='fas fa-pencil-alt'
								onClick={onEditWorkRelation}
								theme='solid-light'
							/>
						</div>
					</h5>
				</>
			)}

			<Divider />

			<div className='isni-registration__buttons'>
				<Button label='Back' leftIcon='fas fa-arrow-left' onClick={onBack} />

				<Button
					label='Submit'
					onClick={onSubmit}
					rightIcon='fas fa-arrow-right'
					theme='dark'
				/>
			</div>
		</div>
	);
};

export default ReviewInfoStep;
