import _ from 'lodash';
import generateRandomId from './generateRandomId';

export const isPublisherEqual = <
	P1 extends
		| ParticipantFormPublisher
		| ParticipantFormSubpublisher
		| RecordingPublisher
		| LocalProfileAssociatedPublisher
		| ProfileFormPublisher,
	P2 extends
		| ParticipantFormPublisher
		| ParticipantFormSubpublisher
		| RecordingPublisher
		| LocalProfileAssociatedPublisher
		| ProfileFormPublisher
>(
	p1: P1,
	p2: P2
) => {
	if (p1?.pro === p2?.pro && p1?.name === p2?.name) {
		// we consider them equal if at least one of them has empty ipi or email
		// this is done so that later they will be merged, preserving the non-empty ipi and email
		if ((!p1.email || !p2.email) && (!p1.ipi || !p2.ipi)) {
			return true;
		}

		return p1.email === p2.email && p1.ipi === p2.ipi;
	}

	return false;
};

export const mergeEqualPublishers = <
	P1 extends
		| ParticipantFormPublisher
		| ParticipantFormSubpublisher
		| RecordingPublisher
		| LocalProfileAssociatedPublisher,
	P2 extends
		| ParticipantFormPublisher
		| ParticipantFormSubpublisher
		| RecordingPublisher
		| LocalProfileAssociatedPublisher
>(
	p1: P1,
	p2: P2
) => {
	const mergedPublisher = _.cloneDeep(p1);

	if (!p1.ipi) mergedPublisher.ipi = p2.ipi;
	if (!p1.email) mergedPublisher.email = p2.email;

	return mergedPublisher;
};

export const getUniqueAssociatedPublishers = <
	P extends RecordingPublisher | LocalProfileAssociatedPublisher
>(
	publishers: P[]
): P[] =>
	publishers?.reduce((uniquePubs, publisher) => {
		const foundPublisher = uniquePubs.find(accPublisher =>
			isPublisherEqual(accPublisher, publisher)
		);

		if (publisher.name === 'Self Published' || !foundPublisher) {
			// check if we can match by ID
			const foundPublisherById = uniquePubs.find(
				accPublisher => accPublisher.id === publisher.id
			);

			if (!foundPublisherById) {
				return [...uniquePubs, publisher];
			}

			// if we found a publisher with the same ID, we need to create a new ID for one of them
			const newPublisher = { ...publisher, id: generateRandomId() };

			return [...uniquePubs, newPublisher];
		}

		const mergedPublisher = mergeEqualPublishers(
			foundPublisher,
			publisher
		) as P;

		return uniquePubs.map(accPublisher =>
			isPublisherEqual(accPublisher, foundPublisher)
				? mergedPublisher
				: accPublisher
		);
	}, [] as P[]);

export const mergeProfileAssociatedPublishersAndRecordingPublishers = (
	recordingPublishers: RecordingPublisher[],
	profileAssociatedPublishers: (LocalProfileAssociatedPublisher & {
		profileId: string;
		oldPublisherId: number;
	})[]
): (RecordingPublisher & {
	profileId?: string;
	oldPublisherId?: number;
})[] => {
	// Compute unique new publishers
	const uniqueNewPublishers = getUniqueAssociatedPublishers(
		profileAssociatedPublishers
	);

	// Now we need to merge the new publishers with the existing ones running the same computation
	const mergedPublishers = getUniqueAssociatedPublishers([
		...recordingPublishers,
		...uniqueNewPublishers,
	]);

	return mergedPublishers;
};

export const createParticipantPublisher = (values: {
	id?: number;
	publisherId?: number;
	splitPercentage?: number;
	territory?: string[];
}): ParticipantPublisher => {
	if (!values.id && !values.publisherId)
		throw new Error('Either id or publisherId must be provided');

	return {
		publisherId: values.id! || values.publisherId!,
		splitPercentage: values.splitPercentage ?? null,
		territory: values.territory ?? [],
		subPublishers: [],
	};
};

export const createPublisher = (values: {
	id: number;
	name: string;
	pro?: string;
	email?: string;
	ipi?: string;
}): LocalProfileAssociatedPublisher => ({
	id: values.id,
	name: values.name,
	pro: values.pro ?? '',
	email: values.email ?? '',
	ipi: values.ipi ?? '',
});

type FormikParticipantSubpublisher = Omit<
	ParticipantFormSubpublisher,
	'publisherId'
> & {
	publisherId: number | string;
};

type FormikParticipantPublisher = FormikParticipantSubpublisher & {
	subPublishers: FormikParticipantSubpublisher[];
};

type FormikProfileSubpublisher = Omit<
	ProfileFormSubpublisher,
	'publisherId'
> & {
	publisherId: number | string;
};

type FormikProfilePublisher = FormikProfileSubpublisher & {
	subPublishers: FormikProfileSubpublisher[];
};

export const createNewFormikParticipantSubPublisher =
	(): FormikParticipantSubpublisher => ({
		publisherId: '',
		pro: '',
		territory: [],
		splitPercentage: null,
		name: '',
		ipi: '',
		email: '',
	});

export const createNewFormikParticipantPublisher =
	(): FormikParticipantPublisher => {
		return {
			publisherId: '',
			pro: '',
			territory: [],
			splitPercentage: null,
			name: '',
			email: '',
			ipi: '',
			subPublishers: [],
		};
	};

export const createNewFormikProfileSubPublisher =
	(): FormikProfileSubpublisher => {
		return {
			publisherId: '',
			pro: '',
			territory: [],
			name: '',
			ipi: '',
			email: '',
		};
	};

export const createNewFormikProfilePublisher = (): FormikProfilePublisher => {
	return {
		publisherId: '',
		pro: '',
		territory: [],
		name: '',
		email: '',
		ipi: '',
		subPublishers: [],
	};
};

export const flattenPublishers = <
	P extends
		| ParticipantPublisher
		| ParticipantFormPublisher
		| ProfileFormPublisher
		| LocalProfilePublisher
>(
	publishers: P[]
): Omit<P, 'subPublishers'>[] => {
	const subPublishers = publishers
		?.filter(publisher => publisher.subPublishers)
		?.flatMap(publisher => publisher.subPublishers) as Omit<
		P,
		'subPublishers'
	>[];

	const publishersWithoutSubPublishers = publishers?.map(publisher =>
		_.cloneDeep(_.omit(publisher, 'subPublishers'))
	) as Omit<P, 'subPublishers'>[];

	return [...publishersWithoutSubPublishers, ...subPublishers];
};

export const createFullPublisherFromFormPublisher = <
	P extends RecordingPublisher | LocalProfileAssociatedPublisher
>(
	publisher:
		| ParticipantFormPublisher
		| ProfileFormPublisher
		| Omit<ParticipantFormPublisher, 'subPublishers'>
		| Omit<ProfileFormPublisher, 'subPublishers'>
): P =>
	({
		id:
			typeof publisher.publisherId !== 'number'
				? parseInt(publisher.publisherId)
				: publisher.publisherId,
		name: publisher.name,
		pro: publisher.pro,
		email: publisher.email,
		ipi: publisher.ipi, // ipi should be string
	} as P);

export const createPublisherSelectOption = ({
	id,
	name,
	pro = '',
	email = '',
	ipi = '',
}: {
	id: number | string;
	name: string;
	pro?: string;
	email?: string;
	ipi?: string;
}): PublisherSelectOption => ({
	id,
	name,
	pro,
	email,
	ipi,
});
