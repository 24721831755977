import {
	FieldArray,
	ErrorMessage,
	useFormikContext,
	useFormik,
	FormikProvider,
} from 'formik';
import { Modal, Row, Col, Accordion, Form } from 'react-bootstrap';
import CreatableSelect from '../../../form/CreatableSelect';
import Select from '../../../form/Select';
import Button from '../../../layout/Button';
import { useEffect, useMemo, useState } from 'react';
import TextField from '../../../form/TextField';
import BooleanRadioInput from '../../../form/BooleanRadioInput';
import IconButton from '../../../layout/IconButton';
import ToggleInput from '../../../layout/ToggleInput';
import theme from '../../../../theme.module.scss';
import './ParticipantModal.scss';
import DatePicker from '../../../form/DatePicker';
import roleList from '../../../../constants/roles.json';
import defaultStudios from '../../../../constants/studios.json';
import { createLocalProfileAction } from '../../../../store/profiles/actions';
import WriterDetails from './WriterDetails';
import CopyrightOwnerDetails from './CopyrightOwnerDetails';
import { getCurrentRecording } from '../../../../store/projects/selectors';
import usStates from '../../../../constants/usStates.json';
import { matchSorter } from 'match-sorter';
import initialParticipant from '../../../../constants/participant.json';
import countries from '../../../../constants/countries.json';
import { saveSelectedCountry } from '../../../../store/session/actions';
import {
	hideAllModalsAction,
	hideModal,
	showModalAction,
} from '../../../../store/modal/actions';
import {
	CONFIRMATION_MODAL,
	DELETE_PARTICIPANT_MODAL,
	ISNI_REGISTRATION_MODAL,
} from '../../../../constants/modalTypes';
import { showAlertToast } from '../../../../store/alertToast/actions';
import _ from 'lodash';
import React from 'react';
import generateRandomId from '../../../../helpers/generateRandomId';
import {
	calculateParticipantWithStudiosAndPublishers,
	convertParticipantToFormParticipant,
	convertProfileToParticipantForm,
	isProfileEqual,
	participantValidationSchema,
} from '../../../../helpers/participantTools';
import { createNewProfileFromParticipant } from '../../../../helpers/profileTools';
import { isStudioEqual } from '../../../../helpers/studioTools';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { v4 as uuidv4 } from 'uuid';
import {
	createNewFormikParticipantPublisher,
	createNewFormikParticipantSubPublisher,
	flattenPublishers,
} from '../../../../helpers/publisherTools';
import { Toast } from 'primereact/toast';

const statesOptions = usStates.map(({ abbreviation, name }) => ({
	value: abbreviation,
	label: name,
}));

const countryOptions = countries.map(country => ({
	label: country.name,
	name: country.name,
}));

type StudioOption = {
	name: string;
	id: string | number;
};

export type CountryOption = {
	label: string;
	name: string;
};

type RoleOption = {
	label: string;
	detail: string;
	category: string;
};

const isDebug =
	process.env.NODE_ENV === 'development' ||
	process.env.REACT_APP_STAGING === 'true';

export type ParticipantModalProps = {
	show: boolean;
	toggleShow: () => void;
	participant?: Participant | null;
	importProfile?: LocalProfile | null;
	setImportProfile?: React.Dispatch<React.SetStateAction<LocalProfile | null>>;
	enableDelete?: boolean;
	editing?: boolean;
	setParticipant: (participant: Participant | null) => void;
	initialCreditedName?: string | null;
	toastErrors?: string[] | null;
	afterSubmit?: () => void;
};

export default function ParticipantModal({
	show,
	toggleShow,
	importProfile,
	setImportProfile,
	participant,
	enableDelete = true,
	editing = false,
	setParticipant,
	initialCreditedName,
	toastErrors,
	afterSubmit,
}: ParticipantModalProps) {
	/*
	 * Redux Hooks
	 */
	const dispatch = useAppDispatch();
	const { myEditorProfile, currentRecordingId } = useAppSelector(
		state => state.projects
	);
	const { profiles: localProfiles } = useAppSelector(state => state.profiles);

	const { savedCountry } = useAppSelector(state => state.session);
	const currentRecording = useAppSelector(getCurrentRecording);

	/*
	 * Formik Hooks
	 */
	const recordingFormik = useFormikContext<RecordingContent>();

	/*
	 * React State
	 */
	const defaultValues = initialParticipant;
	const [initialValues, setInitialValues] =
		useState<ParticipantForm>(defaultValues);
	const [errorMsg, setErrorMsg] = useState('');
	const [roleOptions, setRoleOptions] =
		useState<RoleOption[]>(defaultRoleOptions);
	const [customStudios, setCustomStudios] = useState<StudioOption[]>([]);
	const [publishers, setPublishers] = useState<PublisherSelectOption[]>([]);
	const [loading, setLoading] = useState(false);
	const toastRef = React.useRef<Toast>(null);

	const isReadOnly = useMemo(
		() => Boolean(myEditorProfile && myEditorProfile.is_read_only),
		[myEditorProfile]
	);

	const initialFilteredOptions = useMemo(
		() => countryOptions.filter(c => c.name !== savedCountry),
		[savedCountry]
	);

	const initialCountryOptions = useMemo(
		() => [
			{ name: savedCountry, label: savedCountry },
			...initialFilteredOptions,
		],
		[savedCountry, initialFilteredOptions]
	);

	const [filteredCountryOptions, setFilteredCountryOptions] = useState(
		initialCountryOptions
	);

	const selectCountryOptions: CountryOption[] = useMemo(
		() => [
			{ name: savedCountry, label: savedCountry },
			...filteredCountryOptions,
		],
		[savedCountry, filteredCountryOptions]
	);

	const [statesOpts, setStatesOpts] = useState(statesOptions);

	const associatedStudios = useMemo(
		() => [
			...(recordingFormik?.values?.studios ?? []),
			...(importProfile?.profile?.associatedStudios ?? []),
		],
		[recordingFormik, importProfile]
	);

	const studioOptions = useMemo(() => {
		const projectStudios = [...customStudios, ...associatedStudios];

		return [
			{
				label: `Studios in this Project`,
				options: projectStudios,
			},
			{
				label: 'Cloud Studios',
				options: defaultStudios.filter(
					cloudStudio =>
						!projectStudios.some(projectStudio =>
							isStudioEqual(cloudStudio, projectStudio)
						) // remove studios referenced in this recording
				),
			},
		];
	}, [customStudios, associatedStudios]);

	const flattenedStudioOptions = useMemo(
		() => studioOptions.flatMap(o => o.options),
		[studioOptions]
	);

	/*
	 * Effects
	 */
	useEffect(() => {
		const excludeSelection = countryOptions.filter(
			c => c.name !== savedCountry
		);
		setFilteredCountryOptions(excludeSelection);
	}, [savedCountry]);

	useEffect(() => {
		if (toastErrors && toastErrors.length > 0) {
			toastRef.current?.show(
				toastErrors.map(e => ({ severity: 'error', detail: e, life: 10000000 }))
			);
		}
	}, [toastErrors]);

	// Sets initial values for the form and PROs for each publisher
	useEffect(() => {
		let participantForm: ParticipantForm;

		// when importing profiles, the participant is null
		if (!participant && importProfile) {
			participantForm = convertProfileToParticipantForm(importProfile);
		} else if (!participant) {
			// new participant
			// For new participants, set the initial values to the default values
			participantForm = {
				...defaultValues,
				creditedName: initialCreditedName ?? '', // this is for when "Add manually" is clicked from AddParticipant
			};
		} else {
			// ! We assume the participant has an ID at this point

			// For existing participants, or for new participants being created from a template set the initial values to the participant
			let associatedPublishers: RecordingPublisher[] = [];

			if (participant?.publishers?.length > 0 && currentRecording?.recording) {
				associatedPublishers = currentRecording.recording.publishers;
			}

			participantForm = convertParticipantToFormParticipant(
				participant,
				associatedPublishers
			);
		}

		setInitialValues({
			...participantForm,
			creditedName: initialCreditedName ?? participantForm.creditedName,
		});
	}, [
		participant,
		currentRecording,
		defaultValues,
		initialValues.creditedName,
		importProfile,
		initialCreditedName,
	]);

	/*
	 * Callbacks
	 */
	const cleanUpModal = async () => {
		await recordingFormik.submitForm();
		participantFormik.resetForm();
		toggleShow();
		setLoading(false);

		if (setParticipant) setParticipant(null);

		setInitialValues(defaultValues);

		if (afterSubmit) afterSubmit();
	};

	const isParticipantDuplicated = (newParticipant: ParticipantForm) => {
		const currentParticipants = recordingFormik.values.participants || [];
		const isDuplicated = currentParticipants.find(participant =>
			isProfileEqual(participant, newParticipant)
		);

		if (isDuplicated && !editing) {
			setLoading(false);

			setErrorMsg(
				"Oops, it looks like this person has already been entered. Don't worry, you can add any additional roles or information to their existing entry!"
			);

			return true;
		}

		return false;
	};

	const handleSubmit = (values: ParticipantForm) => {
		if (isReadOnly) return;

		setErrorMsg('');
		setLoading(true);

		// deep copy values and publishers array

		if (isParticipantDuplicated(values)) return;

		// Null safety check
		if (!recordingFormik.values.publishers)
			recordingFormik.setFieldValue('publishers', []);

		const recordingStudios = recordingFormik.values.studios || [];
		const recordingPublishers = recordingFormik.values.publishers || [];
		const studioIdsInUse = _.uniq(
			recordingFormik.values.participants
				.filter(p => (editing ? p.id !== participant?.id : true))
				.flatMap(p => p.roles)
				.flatMap(r => r.studio?.studioId)
				.filter((id): id is number => id != null)
		);
		const publisherIdsInUse = _.uniq(
			flattenPublishers(
				recordingFormik.values.participants
					.filter(p => (editing ? p.id !== participant?.id : true))
					.flatMap(p => p.publishers)
			).map(p => p.publisherId)
		);

		// --------- STUDIO AND PUBLISHER LOGIC ---------
		const {
			newParticipant,
			newAssociatedStudios: newRecordingStudios,
			newAssociatedPublishers: newRecordingPublishers,
		} = calculateParticipantWithStudiosAndPublishers({
			participantForm: values,
			associatedStudios: recordingStudios,
			associatedPublishers: recordingPublishers,
			studioIdsInUse,
			publisherIdsInUse,
		});

		recordingFormik.setFieldValue('studios', newRecordingStudios);
		recordingFormik.setFieldValue('publishers', newRecordingPublishers);

		// clear custom studios, this patches a bug where this piece of state
		// remains the same after a participant is entered with a custom studio
		// and when adding a new participant the state is preserved
		setCustomStudios([]);

		// --------- EDIT LOGIC ---------
		// edit participant from record
		if (editing) {
			if (!participant) throw new Error('Participant is null when editing');
			editRecordingParticipant(participant!, newParticipant);
			return;
		}

		// --------- PROFILES LOGIC  ---------
		const participantIsAlreadySaved =
			(participant || importProfile) &&
			localProfiles?.find(localProfile =>
				isProfileEqual(localProfile, newParticipant)
			);

		// clear import profile data, otherwise it will show the next time the modal is opened
		setImportProfile?.(null);

		// Participant is a saved profile
		if (participantIsAlreadySaved) {
			addExistentProfileToRecording(newParticipant);

			return;
		}

		// Not a saved profile
		addNewProfileToRecording(newParticipant, newRecordingPublishers);
		setLoading(false);
	};

	const addExistentProfileToRecording = (newParticipant: Participant) => {
		console.log('SAVED PROFILE SELECTED FLAG');

		// Profiles have a string UUID, but the recording participants have an integer ID
		newParticipant.id = generateRandomId();

		// Add new participant to recording
		recordingFormik.setFieldValue('participants', [
			...recordingFormik.values.participants,
			newParticipant,
		]);

		cleanUpModal();
	};

	const addNewProfileToRecording = async (
		newParticipant: Participant,
		recordingPublishers: RecordingPublisher[]
	) => {
		// Not a saved profile
		console.log('NOT SAVED PROFILE FLAG');

		// New profiles don't have an ID, so we add one
		newParticipant.id = generateRandomId();

		// Copy the participant to the recording object
		recordingFormik.setFieldValue('participants', [
			...recordingFormik.values.participants,
			newParticipant,
		]);

		// Create profile for cloud saving
		// TODO: PROFILE REFACTOR - make sure this profile is being created correctly
		const profileForCloudSaving = createNewProfileFromParticipant({
			participant: newParticipant,
			recordingPublishers,
			recordingStudios: [],
			removeStudios: true,
		});

		console.log(profileForCloudSaving);

		try {
			await dispatch(createLocalProfileAction(profileForCloudSaving));

			cleanUpModal();
		} catch (e) {
			participantFormik.resetForm();
			toggleShow();
			setInitialValues(defaultValues);
			setParticipant(null);
			console.error(e);

			dispatch(
				showAlertToast(
					'Whoops, there was an issue in saving the participant, look over their info and save again.'
				)
			);
		}
	};

	const editRecordingParticipant = (
		participant: Participant,
		newParticipant: Participant
	) => {
		// TODO: CHECK IF THIS COMMENTED LOGIC IS NEEDED, THERE MIGHT BE PARTICIPANTS WITH NON INTEGER IDs
		// const randomID = generateRandomId();

		// if (typeof newParticipant.id === 'string' || !newParticipant.id)
		// 	newParticipant.id = randomID;

		recordingFormik.setFieldValue(
			'participants',
			recordingFormik.values.participants.map(p => {
				// Swaps with the new participant info.
				if (p.id && participant.id) {
					console.log('BOTH HAVE AN ID', p.id, participant.id);
					if (p.id === participant.id) return newParticipant;

					return p;
				}

				console.log('NOT EQUAL, IDS: ', p.id, participant.id);
				return p;
			})
		);

		cleanUpModal();
	};

	const handleDelete = () => {
		dispatch(
			showModalAction(DELETE_PARTICIPANT_MODAL, {
				participant,
				onClose: () => {
					dispatch(hideModal());
				},
				deleteFromRecording: true,
				recordingId: currentRecordingId,
			})
		);
	};

	const handleHide = () => {
		toggleShow();
		setCustomStudios([]);
		setPublishers([]);
	};

	const participantFormik = useFormik<ParticipantForm>({
		initialValues,
		onSubmit: values => handleSubmit(values),
		validationSchema: participantValidationSchema,
		enableReinitialize: true,
	});

	return (
		<>
			<FormikProvider value={participantFormik}>
				<form onSubmit={participantFormik.handleSubmit}>
					<Modal
						show={show}
						size='xl'
						onHide={handleHide}
						className='participant-modal'
					>
						<Modal.Header closeButton>
							<Modal.Title>{participantFormik.values.creditedName}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div className='participant-modal__tips'>
								<div className='fw-500'>
									<i className='fas fa-lightbulb mr-2'></i>
									Quick Tip: Add info as you learn it, it's okay to leave
									blanks!
								</div>
								<div className='text-muted mb-4'>
									(we'll let you know if something's missing)
								</div>
								<div className='text-muted mb-4'>
									* Indicates required field
								</div>
							</div>
							<Row>
								<Col xs={6}>
									<TextField
										label='Credited Name *'
										type='text'
										name='creditedName'
										value={participantFormik.values.creditedName}
										onChange={participantFormik.handleChange}
										onBlur={participantFormik.handleBlur}
										errorMessage={participantFormik.errors.creditedName}
										isInvalid={
											participantFormik.touched.creditedName &&
											!!participantFormik.errors.creditedName
										}
										readOnly={isReadOnly}
									/>
									<Select<CountryOption>
										label='Country of Performance'
										name='participationCountry'
										id='participationCountry'
										options={selectCountryOptions}
										placeholder='Search...'
										errorFieldName='participationCountry'
										value={
											participantFormik.values.participationCountry &&
											countryOptions.find(
												c =>
													c.name ===
													participantFormik.values.participationCountry
											)
										}
										getOptionValue={option => option.name}
										getOptionLabel={option => option.label}
										onChange={option => {
											setFilteredCountryOptions(
												countryOptions.filter(c => c.name !== option?.name)
											);

											participantFormik.setFieldValue(
												'participationCountry',
												option?.name
											);

											if (option) dispatch(saveSelectedCountry(option.name));
										}}
										isDisabled={isReadOnly}
										onInputChange={val =>
											setFilteredCountryOptions(
												matchSorter(countryOptions, val, {
													keys: ['name', 'label'],
												})
											)
										}
									/>

									<Form.Group className='mb-4'>
										<Row>
											<Col>
												<ToggleInput
													label='Featured Performer'
													name='isFeatured'
													onChange={participantFormik.handleChange}
													onBlur={participantFormik.handleBlur}
													checked={participantFormik.values.isFeatured}
													value={participantFormik.values.isFeatured}
													isDisabled={isReadOnly}
												/>
											</Col>
										</Row>
										<Row>
											<Col xs={12}>
												<small
													style={{
														color: theme.disclaimerTextColor,
														fontStyle: 'italic',
													}}
												>
													Featured performers are the main artist and any others
													listed alongside the main artist as a feature on a
													recording
												</small>
											</Col>
										</Row>
									</Form.Group>
								</Col>
								<Col xs={6}>
									<TextField
										label='Legal Name (if different from credited name)'
										type='text'
										name='legalName'
										onChange={participantFormik.handleChange}
										onBlur={participantFormik.handleBlur}
										value={participantFormik.values.legalName}
										readOnly={isReadOnly}
									/>

									<DatePicker
										label='Participation Date'
										name='participationDate'
										onChange={participantFormik.handleChange}
										onBlur={participantFormik.handleBlur}
										value={participantFormik.values.participationDate}
										isDisabled={isReadOnly}
									/>

									<Form.Group className='mb-4'>
										<Row>
											<Col xs={12}>
												<ToggleInput
													label='Copyright Owner?'
													name='isCopyrightOwner'
													onChange={participantFormik.handleChange}
													onBlur={participantFormik.handleBlur}
													checked={participantFormik.values.isCopyrightOwner}
													value={participantFormik.values.isCopyrightOwner}
													isDisabled={isReadOnly}
												/>
											</Col>
										</Row>
										<Row>
											<Col xs={12}>
												<small
													style={{
														color: theme.disclaimerTextColor,
														fontStyle: 'italic',
													}}
												>
													Copyright Owners are participants that can make a
													copyright owner claim with a CMO such as Sound
													Exchange or PPL
												</small>
											</Col>
										</Row>
									</Form.Group>
								</Col>
							</Row>

							<Row className='mb-3'>
								<Col xs={12}>
									<FieldArray name='roles'>
										{({ remove, push }) => (
											<div>
												<Row>
													<Col xs={12}>
														<div className='form-label d-flex align-items-center mb-2'>
															Role and Studio
															<button
																className='ml-2 btn text-purple border-purple rounded-circle'
																style={{ padding: '1px 6px' }}
																type='button'
																onClick={() =>
																	push({
																		studio: null,
																		category: '',
																		detail: '',
																	})
																}
																disabled={isReadOnly}
															>
																<i className='fa fa-plus'></i>
															</button>
														</div>
													</Col>
												</Row>
												{participantFormik.values.roles &&
													participantFormik.values.roles.map(
														(element, index) => (
															<Row
																className='row rounded p-3 bg-purple-light'
																key={`roles${index}`}
															>
																<Col xs={5}>
																	<Select<RoleOption>
																		label='Role'
																		name={`roles[${index}].detail`}
																		id={`roles[${index}].detail`}
																		options={roleOptions}
																		getOptionValue={option => option.detail}
																		value={roleOptions.find(
																			option =>
																				option.detail ===
																				participantFormik.values.roles[index]
																					.detail
																		)}
																		placeholder='Search...'
																		onChange={option => {
																			participantFormik.setFieldValue(
																				`roles[${index}]`,
																				{
																					studio:
																						participantFormik.values.roles[
																							index
																						].studio || null,
																					category: option?.category,
																					detail: option?.detail,
																				}
																			);
																		}}
																		errorFieldName={`roles[${index}].detail`}
																		isDisabled={isReadOnly}
																		// filterOption={createFilter({
																		// 	matchFrom: 'any',
																		// })}

																		onInputChange={val =>
																			setRoleOptions(
																				matchSorter(defaultRoleOptions, val, {
																					keys: ['detail', 'label'],
																				})
																			)
																		}
																	/>
																</Col>
																<Col xs={6}>
																	<CreatableSelect
																		label='Studio (with location in parenthesis)'
																		name={`roles[${index}].studio`}
																		id={`roles[${index}].studio`}
																		isGrouped
																		options={studioOptions}
																		placeholder='Search...'
																		errorFieldName={`roles[${index}].studio`}
																		value={
																			(participantFormik.values.roles[index]
																				.studio &&
																				flattenedStudioOptions.find(
																					studio =>
																						studio &&
																						studio.id &&
																						studio.id ===
																							participantFormik.values.roles[
																								index
																							].studio?.studioId
																				)) ||
																			null
																		}
																		getOptionValue={option =>
																			option?.id?.toString()
																		}
																		getOptionLabel={option => option.name}
																		onChange={pickedStudio => {
																			participantFormik.setFieldValue(
																				`roles[${index}].studio`,
																				pickedStudio
																					? {
																							studioId: pickedStudio.id,
																							sessionType: null,
																							label: pickedStudio.name,
																					  }
																					: null
																			);
																		}}
																		onCreateOption={value => {
																			const studioId = uuidv4();

																			const newOption = {
																				id: studioId,
																				name: value,
																			};

																			setCustomStudios(prevStudios => [
																				...prevStudios,
																				newOption,
																			]);

																			participantFormik.setFieldValue(
																				`roles[${index}].studio`,
																				{
																					studioId: studioId,
																					label: value,
																					sessionType: null,
																				}
																			);
																		}}
																		isDisabled={isReadOnly}
																	/>
																</Col>
																<Col xs={1} style={{ top: '30px' }}>
																	<IconButton
																		onClick={() => remove(index)}
																		color={theme.error}
																		icon='fa fa-trash'
																	/>
																</Col>
															</Row>
														)
													)}
											</div>
										)}
									</FieldArray>
								</Col>

								<ErrorMessage name='roles'>
									{msg => {
										return (
											<small style={{ color: theme.error }}>
												{typeof msg === 'string' && msg}
											</small>
										);
									}}
								</ErrorMessage>
							</Row>

							<hr />
							<Row>
								<Col xs={12}>
									<WriterDetails<Participant, ParticipantForm>
										participant={participant}
										publishers={publishers}
										setPublishers={setPublishers}
										createPublisher={createNewFormikParticipantPublisher as any}
										createSubpublisher={
											createNewFormikParticipantSubPublisher as any
										}
									/>
									<hr />

									<CopyrightOwnerDetails<ParticipantForm> />
								</Col>
							</Row>

							<hr />
							<Row>
								<Col xs={12}>
									<Accordion defaultActiveKey=''>
										<Accordion.Item eventKey='0'>
											<Accordion.Header>
												<h2>MORE DETAILS</h2>
											</Accordion.Header>
											<Accordion.Body>
												<Row>
													<Col xs={12}>
														<Row>
															<Col xs={6}>
																<BooleanRadioInput
																	label='Part Dropped'
																	name='partDropped'
																	trueLabel='Yes'
																	falseLabel='No'
																	onChange={participantFormik.handleChange}
																	onBlur={participantFormik.handleBlur}
																	value={participantFormik.values.partDropped}
																	isDisabled={isReadOnly}
																/>
															</Col>
														</Row>
													</Col>
													<Col xs={6}>
														<TextField
															label='Email'
															type='email'
															name='email'
															onChange={participantFormik.handleChange}
															onBlur={participantFormik.handleBlur}
															value={participantFormik.values.email}
															readOnly={isReadOnly}
															isInvalid={Boolean(
																participantFormik.touched.email &&
																	participantFormik.errors.email
															)}
															errorMessage={participantFormik.errors.email}
														/>
														<TextField
															label='Phone Number'
															type='text'
															name='phone'
															onChange={participantFormik.handleChange}
															onBlur={participantFormik.handleBlur}
															value={participantFormik.values.phone}
															readOnly={isReadOnly}
														/>

														<DatePicker
															disableFuture
															label='Date of Birth'
															name='dateOfBirth'
															onChange={participantFormik.handleChange}
															onBlur={participantFormik.handleBlur}
															value={participantFormik.values.dateOfBirth}
															isDisabled={isReadOnly}
														/>

														<TextField
															label='ISNI'
															type='text'
															name='isni'
															onChange={participantFormik.handleChange}
															onBlur={participantFormik.handleBlur}
															value={participantFormik.values.isni}
															readOnly={isReadOnly}
															buttonLabel='Create / Find'
															isButtonDisabled={
																isReadOnly || !!participantFormik.values.isni
															}
															onButtonClick={() => {
																if (isReadOnly || participantFormik.values.isni)
																	return;

																dispatch(
																	showModalAction(ISNI_REGISTRATION_MODAL, {
																		size: 'lg',
																		participantId: participant?.id,
																		recordingId: currentRecordingId,
																		onAssignIsni: (isni: string) => {
																			// necessary to update form in real time
																			participantFormik.setFieldValue(
																				'isni',
																				isni
																			);
																		},
																		onHide: () =>
																			dispatch(
																				showModalAction(CONFIRMATION_MODAL, {
																					size: 'md',
																					title:
																						'ARE YOU SURE YOU WANT TO EXIT?',
																					description:
																						'You will lose all progress in the ISNI registration process',
																					confirmAction: {
																						label: 'Exit',
																						onClick: () => {
																							dispatch(hideAllModalsAction());
																						},
																					},
																					cancelAction: {
																						label: 'Cancel',
																						onClick: () =>
																							dispatch(hideModal()),
																					},
																				})
																			),
																	})
																);
															}}
														/>
														<TextField
															label='SSN Last 4'
															type='text'
															name='socialLastFour'
															onChange={participantFormik.handleChange}
															onBlur={participantFormik.handleBlur}
															value={participantFormik.values.socialLastFour}
															isInvalid={Boolean(
																participantFormik?.touched?.socialLastFour &&
																	participantFormik?.errors?.socialLastFour
															)}
															errorMessage={
																participantFormik?.errors?.socialLastFour
															}
															readOnly={isReadOnly}
															informationText='Do not use dashes or spaces'
															dense
														/>
														<TextField
															label='IPN'
															type='text'
															name='ipn'
															onChange={participantFormik.handleChange}
															onBlur={participantFormik.handleBlur}
															value={participantFormik.values.ipn}
															readOnly={isReadOnly}
														/>
													</Col>
													<Col xs={6}>
														<TextField
															label='Address Line 1'
															type='text'
															name='address1'
															onChange={participantFormik.handleChange}
															onBlur={participantFormik.handleBlur}
															value={participantFormik.values.address1}
															readOnly={isReadOnly}
														/>
														<TextField
															label='Address Line 2'
															type='text'
															name='address2'
															onChange={participantFormik.handleChange}
															onBlur={participantFormik.handleBlur}
															value={participantFormik.values.address2}
															readOnly={isReadOnly}
														/>
														<TextField
															label='City'
															type='text'
															name='city'
															onChange={participantFormik.handleChange}
															onBlur={participantFormik.handleBlur}
															value={participantFormik.values.city}
															readOnly={isReadOnly}
														/>
														<Row>
															<Col xs={6}>
																{/* <Select */}
																<CreatableSelect
																	label='State / Province / Region'
																	name='state'
																	id='state'
																	options={statesOpts}
																	onChange={option => {
																		participantFormik.setFieldValue(
																			'state',
																			option?.value ?? ''
																		);
																	}}
																	value={statesOpts.find(
																		option =>
																			option.value ===
																			participantFormik.values.state
																	)}
																	isDisabled={isReadOnly}
																	onCreateOption={value => {
																		let newOption = {
																			value: value,
																			label: value,
																		};

																		setStatesOpts([...statesOpts, newOption]);
																		participantFormik.setFieldValue(
																			'state',
																			value
																		);
																	}}
																/>
															</Col>
															<Col xs={6}>
																<TextField
																	label='Zip / Postal Code'
																	type='text'
																	name='postalCode'
																	onChange={participantFormik.handleChange}
																	onBlur={participantFormik.handleBlur}
																	value={participantFormik.values.postalCode}
																	readOnly={isReadOnly}
																/>
															</Col>
														</Row>
														<Select<CountryOption>
															label='Country'
															name='country'
															id='country'
															options={selectCountryOptions}
															placeholder='Search...'
															errorFieldName='country'
															value={
																// formik.values.country &&
																countryOptions.find(
																	c =>
																		c.name === participantFormik.values.country
																	// 	 ||
																	// c.name === savedCountry
																)
															}
															getOptionValue={option => option.name}
															getOptionLabel={option => option.label}
															onChange={option => {
																setFilteredCountryOptions(
																	countryOptions.filter(
																		c => c.name !== option?.name
																	)
																);
																participantFormik.setFieldValue(
																	'country',
																	option?.name
																);

																if (option)
																	dispatch(saveSelectedCountry(option.name));
															}}
															isDisabled={isReadOnly}
															onInputChange={val =>
																setFilteredCountryOptions(
																	matchSorter(countryOptions, val, {
																		keys: ['name', 'label'],
																	})
																)
															}
														/>
														<TextField
															label='Other Aliases'
															type='text'
															name='otherAliases'
															onChange={participantFormik.handleChange}
															onBlur={participantFormik.handleBlur}
															value={participantFormik.values.otherAliases}
															readOnly={isReadOnly}
														/>
													</Col>
													<Col xs={12}>
														<TextField
															label='Notes'
															as='textarea'
															name='notes'
															id='notes'
															cols={30}
															rows={4}
															onChange={participantFormik.handleChange}
															onBlur={participantFormik.handleBlur}
															value={participantFormik.values.notes}
															readOnly={isReadOnly}
														/>
													</Col>
												</Row>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</Col>
							</Row>
							{isDebug && (
								<>
									<pre>{JSON.stringify(participantFormik.values, null, 2)}</pre>
									<pre>
										{currentRecording &&
											currentRecording.recording &&
											JSON.stringify(
												currentRecording.recording.publishers,
												null,
												2
											) +
												'\n' +
												JSON.stringify(
													currentRecording.recording.studios,
													null,
													2
												) +
												'\n' +
												JSON.stringify([...customStudios], null, 2)}
									</pre>
								</>
							)}
						</Modal.Body>
						<Modal.Footer className='modal-footer'>
							<div className='d-flex flex-column w-100'>
								<span
									style={{
										transition: 'none',
									}}
									className={`share-table-error  mb-2 ${
										errorMsg && 'show-share-error'
									}`}
								>
									{errorMsg}
								</span>
								<div className='d-flex justify-content-end'>
									<Button label='Cancel' onClick={() => toggleShow()} />

									{enableDelete && participant?.id && (
										<Button
											label='Delete'
											theme='danger'
											isDisabled={isReadOnly}
											onClick={handleDelete}
											className='ml-2'
										/>
									)}
									{/* <Button
										label='Copy to All Loaded RINs'
										onClick={onCopyToRecordings}
									/> */}
									<Button
										label='Save & Proceed'
										theme='dark'
										type='submit'
										onClick={() => {
											participantFormik.submitForm();
										}}
										isLoading={loading}
										isDisabled={isReadOnly}
										className='ml-2'
									/>
								</div>
							</div>
						</Modal.Footer>
					</Modal>
				</form>
			</FormikProvider>

			<Toast ref={toastRef} />
		</>
	);
}

const defaultRoleOptions = Object.entries(roleList)
	.flatMap(([category, subRoles]) =>
		subRoles.map(detail => ({
			label: `${detail}`,
			detail,
			category,
		}))
	)
	.sort((a, b) => {
		if (a.label < b.label) {
			return -1;
		} else if (a.label > b.label) {
			return 1;
		}

		return 0;
	});
