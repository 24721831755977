import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import './DripAgreementModal.scss';
import * as Yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import Button from '../../../../layout/Button';

const DripAgreementModalSchema = Yup.object().shape({
	termsAgreed: Yup.boolean().oneOf([true], 'Required'),
	confirmSampleLicenses: Yup.boolean().oneOf([true], 'Required'),
	confirmCoverLicenses: Yup.boolean().oneOf([true], 'Required'),
});

const DripAgreementModal = ({ onAgree, onDecline }) => {
	const handleAgree = () => {
		return onAgree();
	};

	const formik = useFormik({
		initialValues: {
			termsAgreed: false,
			confirmSampleLicenses: false,
			confirmCoverLicenses: false,
		},
		validationSchema: DripAgreementModalSchema,
		onSubmit: handleAgree,
		validateOnMount: true,
	});

	return (
		<FormikProvider value={formik}>
			<Modal.Body>
				<div className='px-4 pt-4  drip-agreement__container'>
					<h2>Drip Music Streaming Platform Submission Agreement</h2>
					<p className='drip-agreement__intro'>
						By clicking "I Agree" and submitting your music to the Drip music
						streaming platform, a component of Sound Credit (the "Platform"),
						you, the artist or authorized representative ("you" or "your"),
						agree to the following terms:
					</p>

					<div className='drip-agreement__body'>
						<div className='drip-agreement__clause'>
							<h3>Representations and Warranties:</h3>
							<p>You represent and warrant that:</p>

							<ol type='a'>
								<li>
									You have the full right, power, and authority to enter into
									this agreement and grant the rights and licenses granted
									herein.
								</li>
								<li>
									You own, control, or have secured all necessary approvals for
									the rights, including, but not limited to, copyright,
									publishing, and master rights, for the music, artwork, and any
									other materials submitted to the Platform.
								</li>
								<li>
									The music submitted is original, does not include a cover
									song, and does not contain any material owned, in whole or in
									part, by any third party, other than any licenses or
									permissions you may have obtained.
								</li>
								<li>
									Your submission does not infringe upon any third party's
									rights, including, but not limited to, intellectual property
									rights, privacy rights, or publicity rights.
								</li>
								<li>
									You have obtained all necessary clearances and permissions for
									any samples or other third-party material included in your
									submission.
								</li>
							</ol>
						</div>

						<div className='drip-agreement__clause'>
							<h3>License Grant:</h3>
							<p>
								You hereby grant the Platform a non-exclusive, worldwide,
								royalty-free license to stream your submitted music for a
								limited time as determined by the Platform, solely for the
								purpose of allowing users to preview the music before its
								official release on non-ephemeral music streaming services such
								as Spotify, Apple Music, Amazon Music and Tidal. For clarity,
								the license granted to the Platform includes a direct license of
								all applicable public performance, mechanical reproduction, and
								distribution rights necessary to transmit your music as
								described herein.
							</p>
						</div>

						<div className='drip-agreement__clause'>
							<h3>Indemnification:</h3>
							<p>
								You agree to indemnify, defend, and hold harmless the Platform,
								its affiliates, and their respective officers, directors,
								employees, and agents from and against any and all claims,
								liabilities, damages, losses, or expenses, including reasonable
								attorneys' fees and costs, arising out of or in any way
								connected with your submission or any breach of your
								representations and warranties set forth in this agreement.
							</p>
						</div>

						<div className='drip-agreement__clause'>
							<h3>Limitation of Liability:</h3>
							<p>
								In no event shall the Platform be liable for any indirect,
								incidental, special, or consequential damages, or damages for
								loss of profits, revenue, data, or use, incurred by you or any
								third party, whether in an action in contract or tort, arising
								from your submission or this agreement.
							</p>
						</div>

						<div className='drip-agreement__clause'>
							<h3>Governing Law:</h3>
							<p>
								This agreement shall be governed by and construed in accordance
								with the laws of the jurisdiction in which the Platform is
								located, without regard to its conflict of law principles.
							</p>
						</div>
					</div>

					<div className='drip-agreement__buttons'>
						<Form.Check
							type='checkbox'
							name='termsAgreed'
							id='termsAgreed'
							label='I Agree to all of the above'
							onChange={formik?.handleChange}
							onBlur={formik?.handleBlur}
							value={formik?.values?.termsAgreed}
						/>

						<Form.Check
							type='checkbox'
							name='confirmSampleLicenses'
							id='confirmSampleLicenses'
							label='I confirm that I have obtained all necessary licenses and permissions for any samples or other third-party material included in my submission.'
							onChange={formik?.handleChange}
							onBlur={formik?.handleBlur}
							value={formik?.values?.confirmSampleLicenses}
						/>

						<Form.Check
							type='checkbox'
							name='confirmCoverLicenses'
							id='confirmCoverLicenses'
							label='I confirm that I have secured any necessary mechanical licenses for cover songs or adaptations, if applicable, and that my submission complies with all legal requirements related to such licenses.'
							onChange={formik?.handleChange}
							onBlur={formik?.handleBlur}
							value={formik?.values?.confirmCoverLicenses}
						/>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button label='Decline' onClick={onDecline} className='mr-2' />
				<Button
					label='Confirm'
					onClick={formik.handleSubmit}
					isDisabled={!formik.isValid}
					theme='dark'
				/>
			</Modal.Footer>
		</FormikProvider>
	);
};

export default DripAgreementModal;
