import React from 'react';
import './PlaylistDripDashboard.scss';
import { Card } from 'react-bootstrap';

export type DripDashboardCardProps = {
	number?: number | string | null;
	title?: string;
	description?: string;
	icon?: string;
	style?: React.CSSProperties;
	className?: string;
};

const DripDashboardCard = ({
	number = null,
	title = '',
	description = '',
	icon = '',
	style = {},
	className = '',
}: DripDashboardCardProps) => {
	return (
		<Card
			className={`drip-dashboard-card__container ${className}`}
			style={style}
		>
			{title && (
				<div className='drip-dashboard-card__title'>
					{icon && (
						<div className='drip-dashboard-card__icon'>
							<i className={icon} />
						</div>
					)}

					{title}
				</div>
			)}
			{number != null && (
				<div className='drip-dashboard-card__number'>{number}</div>
			)}
			{description && (
				<div className='drip-dashboard-card__description'>{description}</div>
			)}
		</Card>
	);
};

export default DripDashboardCard;
