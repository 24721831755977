import { Formik, FormikHelpers, useFormikContext } from 'formik';
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	Navigate,
	Route,
	Routes,
	useNavigate,
	useParams,
} from 'react-router-dom';
import {
	getCurrentAlbum,
	getCurrentRecording,
} from '../../../store/projects/selectors';
import Lyrics from '../Lyrics';
import Notes from '../Notes';
import ReleaseDetails from '../Projects/ReleaseDetails';
import Credits from '../Credits';
import RecordingDetails from '../RecordingDetails';
import initialRecording from '../../../constants/recording.json';
import * as yup from 'yup';
import _, { debounce, isEmpty } from 'lodash';
import {
	fetchAlbumByIdAction,
	fetchMyRecordingEditorProfileAction,
	fetchRecordingByIdAction,
	setCurrentAlbumIdAction,
	setCurrentRecordingIdAction,
	setEditorProfileActive,
	updateCloudAlbumAction,
	updateCloudRecordingAction,
} from '../../../store/projects/actions';
import SoundCreditLoader from '../SoundCreditLoader';
import { Col, Container, Row } from 'react-bootstrap';
import '../../layout/Page/Page.scss';
import EditorSubMenu from '../../layout/EditorSubMenu';
import {
	showAlertToast,
	hideAlertToast,
} from '../../../store/alertToast/actions';
import { setModalTitle, showModalAction } from '../../../store/modal/actions';
import Button from '../../layout/Button';
import {
	claimActiveEditor,
	checkActiveEditor,
} from '../../../api/services/editorService';
import { CHAT_MODAL } from '../../../constants/modalTypes';
import ErrorPage from './ErrorPage';
import ExportValidation from '../Export/ExportValidation';
import ExportPreview from '../Export/ExportPreview';
import SelectParticipantsForExport from '../Export/SelectParticipantsForExport';
import UnionFormExport from '../Export/UnionFormExport';
import ROUTES from '../../../router/routes';
import SyncLyrics from '../Lyrics/SyncLyrics';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { replacePathVariables } from '@/helpers/routeTools';
import GuardedRoutes from '../../../router/GuardedRoutes';
import { Helmet } from 'react-helmet';
import { Toast } from 'primereact/toast';
import { setExportRecordingErrorsAction } from '../../../store/exports/actions';

const schema = yup.object().shape({
	title: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === null ? '' : curr))
		.required('Song name is required'),
	mainArtist: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === null ? '' : curr))
		.required('Artist name is required'),
	genre: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === null ? '' : curr)),
	explicit: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === null ? '' : curr)),
	containsSamples: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === null ? '' : curr)),
	// isrc: yup.string().max(15, "ISRC must be at most 15 characters."),
	// iswc: yup.string().max(15, "ISWC must be at most 15 characters."),
	moods: yup.array(),
});

const Editor = () => {
	const currentRecording = useAppSelector(getCurrentRecording);
	const { currentRecordingId, albumsById, recordingsById, currentAlbumId } =
		useAppSelector(state => state.projects);
	const { exportRecordingErrors } = useAppSelector(state => state.exports);
	const myEditorProfile = useAppSelector(
		state => state.projects.myEditorProfile
	) as ApiRecordingEditor | null;
	const [showingInactiveUserToast, setShowingInactiveUserToast] =
		useState<boolean>(false);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const { recordingId: recordingIdParam } = useParams<{
		recordingId: string;
	}>();

	const pathRecordingId = recordingIdParam ? parseInt(recordingIdParam) : null;
	const { userId } = useAppSelector(state => state.auth);

	const [isFetchingRecording, setIsFetchingRecording] = useState(false);
	const [isFetchingEditorProfile, setIsFetchingEditorProfile] = useState(false);
	const [isClaimingActive, setIsClaimingActive] = useState(false);

	// Just for displaying the active username.
	const [activeUsername, setActiveUsername] = useState<string | null>(null);
	const exportErrorsToastRef = useRef<Toast>(null);
	const showedExportErrorsToast = useRef(false);

	const disableContent = useMemo(
		() =>
			myEditorProfile &&
			!myEditorProfile.is_active_editor &&
			!myEditorProfile.is_read_only,
		[myEditorProfile]
	);

	const openChat = useCallback(() => {
		dispatch(setModalTitle('Chat'));
		dispatch(
			showModalAction(CHAT_MODAL, {
				size: 'lg',
				recordingId: currentRecordingId,
			})
		);
	}, [dispatch, currentRecordingId]);

	const renderReadOnlyModeToast = useCallback(() => {
		dispatch(
			showAlertToast(
				<>
					<button className='btn btn-link toast-chat-wrapper'>
						Read only mode.
						<span className=' ml-2 mr-1 text-purple'>Chat is available.</span>
						<span className='toast-chat-btn' onClick={openChat}>
							Click here to chat!
							<i className=' pl-2 far fa-comment-alt'></i>
						</span>
					</button>
				</>,
				'read-only'
			)
		);
	}, [dispatch, openChat]);

	const saveRecording = useCallback(
		async (
			values: RecordingContent,
			dirty: boolean,
			validateForm: FormikHelpers<RecordingContent>['validateForm']
		) => {
			const isDirty = values && dirty;
			console.log('SAVE RECORDING TRIGGERED');

			if (isDirty) {
				const errors = validateForm();

				if (!_.isEmpty(errors)) return;
				console.log('LOCAL/CLOUD RECORDING ACTION TRIGGERED!');

				await dispatch(
					updateCloudRecordingAction({
						recordingForm: { ...values },
					})
				);

				// update the release information if it's a single release (i.e. it's contained by an album)
				// Changes to the recording's title and mainArtist must be propagated to the album object.
				// only update if the album's already been fetched.
				if (
					currentRecording?.albumId &&
					albumsById?.[currentRecording?.albumId]?.isSingle
				) {
					const cascadeToRelease = async (release: Album) => {
						if (!release.album) {
							throw new Error(
								'No album object found on release. Cannot cascade changes.'
							);
						}

						// we only update the release if the title or mainArtist has changed.
						if (
							release.album.title === values.title &&
							release.album.artistName === values.mainArtist
						) {
							return;
						}

						await dispatch(
							updateCloudAlbumAction({
								...release.album,
								title: values.title,
								artistName: values.mainArtist,
							})
						);
					};

					const release = albumsById[currentRecording.albumId];

					if (!release.album) {
						await dispatch(
							fetchAlbumByIdAction(currentRecording.albumId, cascadeToRelease)
						);

						return;
					}

					await cascadeToRelease(release);
				}
			}
		},
		[dispatch, currentRecording, albumsById]
	);

	const checkActive = useCallback(async () => {
		try {
			if (!myEditorProfile) {
				console.error('No editor profile found');
				return;
			}

			if (!currentRecording || !currentRecording.id) {
				console.error('No current recording found');
				return;
			}

			// Save id in auxiliary variable for checking if currentRecordingId changed while the blocking API call happens.
			const recordingId = currentRecording.id;
			const { data } = await checkActiveEditor(currentRecording.id);
			// const lastEditorActivityTime = new Date(data.updated_at);
			// const lastEditorActivityMillis = lastEditorActivityTime.getTime();
			// const lastRecordingUpdateMillis = currentRecording.updatedAt.getTime();
			const isActive = data.is_active;

			// We need to check if the user switched currentRecordingId while the API call was blocking.
			if (currentRecordingId !== recordingId) return;

			dispatch(setEditorProfileActive(isActive));

			if (!isActive) {
				console.log('INACTIVE USER');
				setActiveUsername(data.active_user_name);
			}

			// Refetch the recording if it's been updated.
			// ! Currently, we're refetching the recording when we enter editor mode, thus is this block necessary?
			// if (!isActive && lastEditorActivityMillis > lastRecordingUpdateMillis) {
			// 	await Promise.resolve(dispatch(getUserProjectsAction()));
			// 	dispatch(
			// 		updateLocalRecordingAction(recordingsById[currentRecordingId])
			// 	);
			// }
		} catch (e) {
			console.log(e);
			navigate(
				replacePathVariables(ROUTES.EditorError.path, {
					recordingId: currentRecordingId,
				})
			);
		}
	}, [
		myEditorProfile,
		currentRecording,
		currentRecordingId,
		dispatch,
		navigate,
	]);

	const claimActive = useCallback(async () => {
		if (!currentRecording?.id) return;

		try {
			if (!myEditorProfile) {
				console.error('No editor profile found');
				return;
			}

			if (!userId) throw new Error('No user id found');

			setIsFetchingRecording(true);
			setIsClaimingActive(true);

			await claimActiveEditor(userId, currentRecording?.id);

			dispatch(setEditorProfileActive(true));

			await dispatch(
				fetchRecordingByIdAction({
					id: currentRecordingId!,
				})
			);
		} catch (e) {
			navigate(
				replacePathVariables(ROUTES.EditorError.path, {
					recordingId: currentRecordingId,
				})
			);

			console.error(e);
		} finally {
			setIsFetchingRecording(false);
			setIsClaimingActive(false);
		}
	}, [
		dispatch,
		myEditorProfile,

		currentRecordingId,
		userId,
		currentRecording,
		navigate,
	]);

	// Effect for showing inactive user toast.
	useEffect(() => {
		if (myEditorProfile?.cloud_recording_id !== currentRecordingId) return;

		if (myEditorProfile?.is_active_editor && showingInactiveUserToast) {
			dispatch(hideAlertToast());
			setShowingInactiveUserToast(false);
			return;
		}

		if (myEditorProfile?.is_active_editor) {
			return;
		}

		setShowingInactiveUserToast(true);
		dispatch(
			showAlertToast(
				<>
					{activeUsername ? `${activeUsername} ` : 'Someone '}
					is in editor mode. You can start editing by clicking here!
					<Button
						label='Enter editor mode'
						theme='light'
						onClick={claimActive}
						className='ml-2'
						isLoading={isClaimingActive}
					/>
				</>,
				'editor'
			)
		);
	}, [
		activeUsername,
		dispatch,
		claimActive,
		showingInactiveUserToast,
		myEditorProfile,
		currentRecordingId,
		isClaimingActive,
	]);

	// Effect for fetching editor profile
	useEffect(() => {
		// We need to fetch the editor profile every time the recording is switched.
		// And whenever the local editor profile data is empty or non-existent
		if (
			currentRecordingId &&
			!isFetchingEditorProfile &&
			(_.isEmpty(myEditorProfile) ||
				!myEditorProfile ||
				myEditorProfile?.cloud_recording_id !== currentRecordingId)
		) {
			if (showingInactiveUserToast) {
				dispatch(hideAlertToast());
				setShowingInactiveUserToast(false);
			}

			setIsFetchingEditorProfile(true);
			dispatch(fetchMyRecordingEditorProfileAction(currentRecordingId))
				.then(() => {
					checkActive();
				})
				.finally(() => {
					setIsFetchingEditorProfile(false);
				});
		}
	}, [
		dispatch,
		currentRecordingId,
		myEditorProfile,
		checkActive,
		showingInactiveUserToast,
		isFetchingEditorProfile,
	]);

	// Inactive editor effect.
	useEffect(() => {
		if (!myEditorProfile) return;

		const userIsNotActiveEditor =
			myEditorProfile &&
			!myEditorProfile.is_active_editor &&
			!myEditorProfile.is_read_only;

		if (!currentRecording) return;
		if (myEditorProfile && myEditorProfile.is_read_only) return;

		if (!userIsNotActiveEditor) {
			dispatch(hideAlertToast());
			setActiveUsername(null);
		}

		// if (myEditorProfile && !myEditorProfile.is_active_editor) {
		// 	clearInterval(checkInterval);
		// 	setIsActive(false);
		// 	setDisableContent(true);
		// }
	}, [myEditorProfile, activeUsername, dispatch, currentRecording]);

	// fetch recording effect
	useEffect(() => {
		// debugger;
		if (pathRecordingId && !recordingsById?.[pathRecordingId]) {
			navigate(
				replacePathVariables(ROUTES.Editor.path, {
					replace: true,
				})
			);
			return;
		}
		// will throw 404

		if (
			(!currentRecordingId || currentRecordingId !== pathRecordingId) &&
			pathRecordingId &&
			!isFetchingRecording
		) {
			// ! IMPORTANT: currentRecordingId should ONLY be set in this effect
			// ! currentRecordingId should depend on pathRecordingId, not the other way around
			// ! if currentRecordingId is set anywhere else, this condition will also be triggered
			// ! since currentRecordingId will be different from pathRecordingId
			dispatch(setCurrentRecordingIdAction(pathRecordingId));
			setIsFetchingRecording(true);
			dispatch(
				fetchRecordingByIdAction({
					id: pathRecordingId,
					onFetch: async (rec?: Recording) => {
						try {
							if (rec?.albumId) {
								await dispatch(fetchAlbumByIdAction(rec.albumId));
								dispatch(setCurrentAlbumIdAction(rec.albumId, pathRecordingId));
							}
						} finally {
							setIsFetchingRecording(false);
						}
					},
				})
			);
		}
	}, [
		currentRecordingId,
		pathRecordingId,
		isFetchingRecording,
		dispatch,
		recordingsById,
		navigate,
	]);

	// Check active editor status effect.
	useEffect(() => {
		if (!myEditorProfile || _.isEmpty(myEditorProfile)) return;

		const userIsReadOnly = myEditorProfile && myEditorProfile.is_read_only;

		if (userIsReadOnly) return;

		const checkInterval = setInterval(checkActive, 6000);

		// This makes sure that whenever a dependency changes then the interval is cleared and restarted with new values.
		return () => {
			clearInterval(checkInterval);
		};
	}, [myEditorProfile, currentRecordingId, checkActive]);

	// Read only toast status effect.
	useEffect(() => {
		if (myEditorProfile && myEditorProfile.is_read_only)
			renderReadOnlyModeToast();

		return () => {
			dispatch(hideAlertToast());
		};
	}, [myEditorProfile, dispatch, renderReadOnlyModeToast]);

	// // if the current recording is changed, then instantly check for editor permissions.
	// useEffect(() => {
	// 	if (myEditorProfile && currentRecordingId) checkActive();
	// 	// This should only happen when the recording is changed.
	// 	// Otherwise, the interval will take care of checking for active editor status periodically.
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [currentRecordingId]);

	// effect used for navigating to new currentRecordingId if the pathRecordingId is different from the currentRecordingId
	// ! BUG: This breaks back/forward navigation if switching between recordings in an album.
	// useEffect(() => {
	// 	if (
	// 		pathRecordingId &&
	// 		currentRecordingId &&
	// 		pathRecordingId !== currentRecordingId &&
	// 		recordingsById?.[pathRecordingId]
	// 	) {
	// 		navigate(replaceRecordingIdInCurrentUrl(currentRecordingId));
	// 	}
	// }, [
	// 	pathRecordingId,
	// 	currentRecordingId,
	// 	navigate,
	// 	recordingsById,
	// 	replaceRecordingIdInCurrentUrl,
	// ]);

	// Effect for showing export validation error toasts whenever the user clicks "Fix issue(s)"
	// in the export validation page from a recording-level error.
	useEffect(() => {
		if (
			exportRecordingErrors &&
			(exportRecordingErrors.recordingId === currentRecordingId ||
				(exportRecordingErrors.albumId &&
					exportRecordingErrors.albumId === currentAlbumId)) &&
			exportRecordingErrors.errors.length &&
			!showedExportErrorsToast.current
		) {
			const recordingTitle =
				recordingsById![exportRecordingErrors.recordingId!]?.recording?.title;
			exportErrorsToastRef.current?.show({
				severity: 'error',
				summary: `Missing or incorrect info in ${
					recordingTitle || 'recording'
				}`,
				detail: (
					<ul className='m-0 p-0 ml-4'>
						{exportRecordingErrors.errors.map((error, index) => (
							<li key={index}>{error}</li>
						))}
					</ul>
				),
				sticky: true,
				closable: true,
				life: 10_000_000, // 10.000 seconds (about 2.7 hours)
			});

			showedExportErrorsToast.current = true;
		}
	}, [
		exportRecordingErrors,
		recordingsById,
		currentRecordingId,
		currentAlbumId,
	]);

	return (
		<Formik<RecordingContent>
			initialValues={
				(currentRecording && currentRecording.recording) || initialRecording
			}
			validationSchema={schema}
			enableReinitialize
			onSubmit={async (values, { resetForm, validateForm }) => {
				await saveRecording(values, true, validateForm);
				resetForm({ values });
			}}
		>
			{_ => (
				<>
					<Toast
						ref={exportErrorsToastRef}
						onRemove={() => {
							dispatch(setExportRecordingErrorsAction(null));
							showedExportErrorsToast.current = false;
						}}
					/>
					<Row className='h-100' style={{ margin: 0 }}>
						{currentRecordingId ? (
							<Col
								xs={2}
								className='bg-dark-credit animate__animated animate__fadeInLeft animate__fast p-0 h-100'
								style={{
									userSelect: 'none',
									overflowY: 'auto',
									overflowX: 'hidden',
								}}
							>
								<EditorSubMenu />
							</Col>
						) : (
							<></>
						)}
						<Col
							xs={currentRecordingId ? 10 : 12}
							className='p-0 d-flex justify-content-center align-items-center'
							style={{ height: '100%', overflow: 'auto' }}
						>
							{currentRecordingId &&
							(!myEditorProfile ||
								isEmpty(myEditorProfile) ||
								myEditorProfile?.cloud_recording_id !== currentRecordingId) ? ( // loading editor profile
								<SoundCreditLoader message='Retrieving Recording Editors...' />
							) : (
								!disableContent && (
									<div
										className={`page-container h-100 w-100 ${
											myEditorProfile && myEditorProfile.is_read_only
												? 'read-only-padding'
												: ''
										}`}
									>
										<EditorContent
											onSave={saveRecording}
											isFetchingRecording={isFetchingRecording}
											key={currentRecordingId} // This is to force a re-render when the recording is changed to avoid stale data.
										/>
									</div>
								)
							)}
						</Col>
					</Row>
				</>
			)}
		</Formik>
	);
};

export type EditorContentProps = {
	onSave: (
		values: RecordingContent,
		dirty: boolean,
		validateForm: FormikHelpers<RecordingContent>['validateForm']
	) => void;
	isFetchingRecording?: boolean;
};

const EditorContent = ({
	onSave,
	isFetchingRecording = false,
}: EditorContentProps) => {
	const { currentRecordingId, currentAlbumId, recordingsById } = useAppSelector(
		state => state.projects
	);
	const { exportParams, exportPayload, exportType } = useAppSelector(
		state => state.exports
	);
	const { profiles } = useAppSelector(state => state.profiles);
	const currentRecording = useAppSelector(getCurrentRecording);
	const currentAlbum = useAppSelector(getCurrentAlbum);
	// state => state.projects.recordingsById[state.projects.currentRecordingId]
	const [isLoading, setIsLoading] = useState(true);
	const dispatch = useAppDispatch();
	const formik = useFormikContext<RecordingContent>();

	useEffect(() => {
		// fetch recording if currentRecording does not contain the recording content
		if (currentAlbumId && currentAlbum && !currentAlbum.album) {
			setIsLoading(true);
			dispatch(fetchAlbumByIdAction(currentAlbumId));
		} else if (
			currentRecordingId &&
			currentRecording &&
			!currentRecording.recording
		) {
			setIsLoading(true);
			console.log(currentRecording);
			dispatch(
				fetchRecordingByIdAction({
					id: currentRecordingId,
				})
			);
		} else if (
			currentRecording &&
			currentRecording.id === currentRecordingId &&
			currentRecording.recording &&
			currentAlbumId
				? currentAlbum &&
				  currentAlbum.id === currentAlbumId &&
				  currentAlbum.album
				: true
		) {
			setIsLoading(false);
		}
	}, [
		currentRecording,
		currentRecordingId,
		currentAlbum,
		currentAlbumId,
		dispatch,
	]);

	const debouncedSaveRecording = useMemo(
		() =>
			debounce(
				(values, dirty, validateForm) => onSave(values, dirty, validateForm),
				2500
			),
		[onSave]
	);

	useEffect(() => {
		// if the formik id is different, then it means the record is switching, and therefore it must be saved
		if (
			formik.values &&
			formik.values.id &&
			formik.values.id !== currentRecordingId
		) {
			onSave(formik.values, formik.dirty, formik.validateForm);
		} else {
			debouncedSaveRecording(formik.values, formik.dirty, formik.validateForm);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentRecordingId, formik.values, formik.dirty]);

	return (
		<>
			<Helmet>
				<title>
					{currentRecording?.title ? `${currentRecording?.title} - ` : ''}Editor{' '}
					{process.env.REACT_APP_TAB_TITLE}
				</title>
			</Helmet>
			<Container className='h-100 prepare-credits-container'>
				{!recordingsById || isLoading || isFetchingRecording || !formik ? (
					<SoundCreditLoader
						theme='light'
						message='Loading Recording Details...'
					/>
				) : !profiles ? (
					<SoundCreditLoader theme='light' message='Loading Profiles...' />
				) : (
					<Routes>
						<Route
							index
							element={
								<Navigate
									to={ROUTES.EditRecordingDetails.relativePath}
									replace
								/>
							}
						/>

						<Route
							path={ROUTES.EditorError.relativePath}
							element={<ErrorPage />}
						/>
						<Route
							path={ROUTES.EditRecordingDetails.relativePath}
							element={<RecordingDetails onSave={onSave} />}
						/>

						<Route
							path={ROUTES.EditRecordingCredits.relativePath}
							element={<Credits />}
						/>
						<Route
							path={ROUTES.SyncedLyrics.relativePath}
							element={<SyncLyrics />}
						/>
						<Route
							path={ROUTES.EditLyrics.relativePath}
							element={<Lyrics onSave={onSave} />}
						/>
						<Route
							path={ROUTES.EditRecordingNotes.relativePath}
							element={<Notes onSave={onSave} />}
						/>
						<Route
							path={ROUTES.EditReleaseDetails.relativePath}
							element={<ReleaseDetails onSave={onSave} />}
						/>
						<Route
							element={
								<GuardedRoutes
									isRouteAccessible={
										exportParams || exportPayload || exportType
									}
									redirectRoute={ROUTES.Editor.path}
								/>
							}
						>
							<Route
								path={ROUTES.ExportValidation.relativePath}
								element={<ExportValidation />}
							/>
							<Route
								path={ROUTES.ExportPreview.relativePath}
								element={<ExportPreview />}
							/>
							<Route
								path={ROUTES.SelectExportParticipants.relativePath}
								element={<SelectParticipantsForExport />}
							/>
							<Route
								path={ROUTES.UnionFormExport.relativePath}
								element={<UnionFormExport />}
							/>
						</Route>

						<Route path='*' element={<Navigate to={ROUTES.Editor.path} />} />
					</Routes>
				)}
			</Container>
		</>
	);
};

export default Editor;
