import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'primereact/button';

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
	computePlayerTracklistAction,
	resetPlayerStateAction,
	setPlayerAudioLoadedAction,
	setPlayerCurrentTimeAction,
	setPlayerDurationAction,
	setPlayerPlayingAction,
	setSeekTimeFunctionAction,
	skipPlayerTrackAction,
	togglePlayerLoopAction,
	togglePlayerShuffleAction,
} from '../../../store/player/actions';
import { Slider, SliderChangeEvent } from 'primereact/slider';
import { getFileProject } from '../../../helpers/fileTools';
import { getPlaylistById } from '../../../store/playlists/selectors';
import {
	SKIP_TO_PREVIOUS_THRESHOLD,
	formatFileTime,
	getTracklistFromPlaylist,
	isHighResAudio,
	isTracklistEqual,
} from '../../../helpers/audioTools';
import styles from './AudioPlayerBar.module.scss';
import clsx from 'clsx';
import { Spinner } from 'react-bootstrap';
import { Badge } from 'primereact/badge';
import LoopMode from '../../../constants/loopMode';
import { hideModal, showModalAction } from '../../../store/modal/actions';
import { CONFIRMATION_MODAL } from '../../../constants/modalTypes';
import { isElectron } from 'react-device-detect';
import VolumeControl from './VolumeControl';
import LosslessSwitch from './LosslessSwitch';
import AudioPlayerMetadata from './AudioPlayerMetadata';

const AudioPlayerBar = () => {
	const {
		loopMode,
		shuffle,
		currentTrackIndex,
		tracklist,
		currentTime,
		playing,
		duration,
		audioSrc,
	} = useAppSelector(state => state.player);
	const { volume, muted } = useAppSelector(state => state.session);
	const { playlistId, audioLoaded, currentTrackVersionNumber, groupIndexes } =
		useAppSelector(state => state.player);
	const [localVolume, setLocalVolume] = useState<number>(volume); // used for slider value state
	const playlist = useAppSelector(state =>
		getPlaylistById(state, { playlistId: state.player.playlistId! })
	);
	const dispatch = useAppDispatch();

	const audioRef = useRef<HTMLAudioElement>(null);

	const [isDragging, setIsDragging] = useState<boolean>(false);
	const [sliderValue, setSliderValue] = useState<number>(0); // used to maintain slider state, independent of currentTime (helps mimick typical behavior)
	const sliderValueRef = useRef<number>(0); // for some reason, stale value is being used in handleDragEnd, thus using an extra ref to use value in callbacks
	// const [startTime, setStartTime] = useState<number>();

	const curAudio = useMemo(
		() =>
			playlist?.playlist?.files?.find(
				file => file.id === tracklist[currentTrackIndex]
			) ?? null,
		[playlist, tracklist, currentTrackIndex]
	);

	const disableControls = !playlistId || !curAudio;

	const fileProject = curAudio ? getFileProject(curAudio) : null;

	const currentTrackVersionId = useMemo(
		() =>
			(curAudio &&
				Object.values(curAudio?.versions).find(
					version => version.versionNo === currentTrackVersionNumber
				)?.id) ||
			null,
		[curAudio, currentTrackVersionNumber]
	);

	const currentVersion = useMemo(
		() =>
			(currentTrackVersionId && curAudio?.versions?.[currentTrackVersionId]) ??
			null,
		[curAudio, currentTrackVersionId]
	);

	const playErrorHandler = useCallback(
		(e: DOMException) => {
			switch (e.name) {
				case 'NotSupportedError':
					console.error(e, e.name);
					dispatch(
						showModalAction(CONFIRMATION_MODAL, {
							size: 'md',
							title: `Whoops! ${isElectron ? '' : 'Browser'} Cannot Play File`,
							description: (
								<>
									<div>
										Oops! Looks like {isElectron ? "we're" : 'your browser is'}{' '}
										feeling a little tone-deaf and can't play the original file
										format.
										{!isElectron ? (
											<>
												<br />
												<br />
												Maybe it needs a music lesson or two!
											</>
										) : (
											<></>
										)}
									</div>
								</>
							),
							confirmAction: {
								label: 'Dismiss',
								onClick: () => dispatch(hideModal()),
							},
						})
					);

					dispatch(setPlayerPlayingAction(false));
					break;
				case 'AbortError':
					// do nothing
					break;
				default:
					console.error(e, e.name);
			}
		},
		[dispatch]
	);

	const seekTime: PlayerState['seekTime'] = useCallback(
		(value: number, forcePlayAudio: boolean = false) => {
			if (!audioRef.current) return;

			sliderValueRef.current = value;
			// convert to seconds, as the native audio elementcurrentTime is in seconds
			audioRef.current.currentTime = value / 1000;

			// if our local state says we're playing, but the audio element is paused, play it
			// this is needed when looping, as the audio element will pause itself when ending
			if (
				forcePlayAudio &&
				playing &&
				audioRef.current.paused &&
				audioSrc &&
				audioLoaded
			) {
				audioRef.current.play().catch(playErrorHandler);
			}

			dispatch(setPlayerCurrentTimeAction(value));
			setSliderValue(value);
		},
		[dispatch, playing, playErrorHandler, audioSrc, audioLoaded]
	);

	// we need to use the seekTime function in other components, so we need to set it in the store
	useEffect(() => {
		dispatch(setSeekTimeFunctionAction(seekTime));
	}, [dispatch, seekTime]);

	const timeUpdateHandler = useCallback(
		(e: any) => {
			if (isDragging) {
				return;
			}

			const current = Math.floor(e.target.currentTime * 1000);
			const detectedDuration = isNaN(e.target.duration)
				? 0
				: Math.floor(e.target.duration * 1000);

			if (detectedDuration !== duration) {
				// both are guaranteed to be in milliseconds and integers
				// the detected duration may be different from the duration in the store
				// this is the real duration, so we need to update it
				dispatch(setPlayerDurationAction(detectedDuration));
			}

			// we need to avoid the currentTime being larger than the duration
			// due to floating point errors, so we use Math.min
			dispatch(setPlayerCurrentTimeAction(Math.min(current, detectedDuration)));
			setSliderValue(current);
			sliderValueRef.current = current;
		},
		[isDragging, dispatch, duration]
	);

	const loadedHandler = (e: any) => {
		dispatch(setPlayerAudioLoadedAction(true));

		timeUpdateHandler(e);
	};

	const playSongHandler = useCallback(
		(value: boolean) => {
			dispatch(setPlayerPlayingAction(value));
		},
		[dispatch]
	);

	const nextSongHandler = useCallback(
		(
			{ forceSkip }: { forceSkip: boolean } = {
				forceSkip: false,
			}
		) =>
			dispatch(
				skipPlayerTrackAction({
					next: true,
					forceSkip,
				})
			),
		[dispatch]
	);

	const prevSongHandler = useCallback(
		(
			{ forceSkip }: { forceSkip: boolean } = {
				forceSkip: false,
			}
		) => {
			if (currentTime > SKIP_TO_PREVIOUS_THRESHOLD || currentTrackIndex === 0) {
				seekTime(0);
				return;
			}

			dispatch(
				skipPlayerTrackAction({
					prev: true,
					forceSkip,
				})
			);
		},
		[currentTime, dispatch, currentTrackIndex, seekTime]
	);

	const handleToggleShuffle = useCallback(() => {
		dispatch(togglePlayerShuffleAction());

		if (tracklist?.length) {
			dispatch(computePlayerTracklistAction());
		}
	}, [dispatch, tracklist]);

	const handleDragChange = useCallback((e: SliderChangeEvent) => {
		setIsDragging(true);

		if (Array.isArray(e.value)) {
			return;
		}

		const value = e.value as number;

		setSliderValue(value);
		sliderValueRef.current = value;
	}, []);

	useEffect(() => {
		if (!audioRef.current) return;

		if (playing) {
			// this makes the song instantly cutoff when switching between songs
			if (!audioSrc) {
				audioRef.current.pause();
				return;
			}

			if (audioRef.current.src !== audioSrc) {
				audioRef.current.src = audioSrc;
			}

			audioRef.current.play().catch(playErrorHandler);
		} else {
			console.log('PAUSE AUDIO');

			audioRef.current.pause();
		}
	}, [playing, audioSrc, playErrorHandler]);

	// Set local volume effect
	useEffect(() => {
		if (!audioRef.current) return;
		audioRef.current.volume = localVolume / 100;
	}, [localVolume]);

	// Set muted effect
	useEffect(() => {
		if (!audioRef.current) return;

		audioRef.current.muted = muted;
	}, [muted]);

	// TODO: add folders logic
	// Recompute tracklist whenever the same playlist changes
	useEffect(() => {
		if (playlistId && !playlist) {
			console.log('playlist not found');
			dispatch(resetPlayerStateAction());
			return;
		}

		if (!playlist) return;
		const { fileIdToGroupIdIndex, groupIdToGroupContentIndex } = groupIndexes;

		if (!fileIdToGroupIdIndex || !groupIdToGroupContentIndex) {
			return;
		}

		const currentTrackId =
			currentTrackIndex > -1 ? tracklist[currentTrackIndex] : null;

		const currentGroupId = currentTrackId
			? fileIdToGroupIdIndex[currentTrackId]
			: null;

		if (
			isTracklistEqual(
				tracklist,
				getTracklistFromPlaylist({
					playlist,
					isShuffle: false,
					currentGroupId,
					fileIdToGroupIdIndex,
					groupIdToGroupContentIndex,
				}),
				shuffle
			)
		) {
			console.log('tracklist equal');
			return;
		}

		console.log('playlist changed, recomputing tracklist');

		dispatch(
			computePlayerTracklistAction({
				playlistFilesChanged: true,
			})
		);
	}, [
		playlist,
		playlist?.playlist?.files,
		dispatch,
		tracklist,
		shuffle,
		playlistId,
		groupIndexes,
		currentTrackIndex,
	]);

	useEffect(() => {
		if ('mediaSession' in navigator) {
			navigator.mediaSession.setActionHandler('nexttrack', () =>
				nextSongHandler({ forceSkip: true })
			);
			navigator.mediaSession.setActionHandler('previoustrack', () =>
				prevSongHandler({ forceSkip: true })
			);
			navigator.mediaSession.setActionHandler('play', () =>
				playSongHandler(true)
			);
			navigator.mediaSession.setActionHandler('pause', () => {
				console.log('PAUSE MEDIA SESSION');

				playSongHandler(false);
			});
			navigator.mediaSession.setActionHandler('seekto', (e: any) => {
				seekTime(e.seekTime * 1000);
			});
		}

		return () => {
			if ('mediaSession' in navigator) {
				navigator.mediaSession.setActionHandler('nexttrack', null);
				navigator.mediaSession.setActionHandler('previoustrack', null);
				navigator.mediaSession.setActionHandler('play', null);
				navigator.mediaSession.setActionHandler('pause', null);
				navigator.mediaSession.setActionHandler('seekto', null);
			}
		};

		// We only need to set this once, so we don't need to add any dependencies
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if ('mediaSession' in navigator) {
			navigator.mediaSession.metadata = new MediaMetadata({
				title: fileProject?.title,
				artist: fileProject?.artist,
				album: playlist?.name,
				artwork: [
					{
						src: curAudio?.coverUrl ?? playlist?.playlist?.coverUrl ?? '',
						sizes: '96x96,128x128,192x192,256x256,384x384,512x512',
						type: 'image/jpg',
					},
				],
			});
		}
	}, [playlist, curAudio, fileProject]);

	return (
		<div className={styles['audio-player-bar']}>
			<div className={styles['left-side']}>
				<AudioPlayerMetadata file={curAudio} />
			</div>
			<div className={styles['center-side']}>
				<div className={styles['control-section']}>
					<Button
						className={clsx(styles['control-button'], {
							[styles['selected']]: shuffle,
						})}
						onClick={handleToggleShuffle}
						icon='fas fa-random'
						rounded
						disabled={disableControls}
						text
					/>
					<Button
						className={clsx(styles['control-button'])}
						onClickCapture={() => prevSongHandler({ forceSkip: true })}
						icon='fas fa-step-backward'
						disabled={disableControls}
						rounded
						text
					/>

					<Button
						className={clsx(
							styles['play-pause-button'],
							styles['control-button']
						)}
						onClickCapture={() => playSongHandler(!playing)}
						icon={playing ? 'fas fa-pause-circle' : 'fas fa-play-circle'}
						rounded
						text
						loading={currentTrackIndex > -1 && !audioLoaded && playing}
						loadingIcon={
							<Spinner
								style={{
									fontSize: '0.75rem',
								}}
							/>
						}
						disabled={disableControls}
					/>

					<Button
						className={clsx(styles['control-button'])}
						onClickCapture={() => nextSongHandler({ forceSkip: true })}
						icon='fas fa-step-forward'
						rounded
						text
						disabled={disableControls}
					/>

					<Button
						className={clsx(styles['control-button'], {
							[styles['selected']]: loopMode !== LoopMode.DISABLED,
						})}
						onClick={() => dispatch(togglePlayerLoopAction())}
						icon={
							<i className='fas fa-sync-alt p-overlay-badge'>
								{loopMode === LoopMode.LOOP_TRACK && <Badge value='1'></Badge>}
							</i>
						}
						rounded
						text
						disabled={disableControls}
					/>
				</div>
				<div
					className={clsx(styles['progress-section'], {
						[styles['disabled']]: disableControls,
					})}
				>
					<span className={styles['progress-time']}>
						{formatFileTime(sliderValue)}
					</span>
					<Slider
						pt={{
							range: {
								className: styles['slider-range'],
							},
							handle: {
								className: styles['slider-handle'],
							},
						}}
						className={clsx(styles['slider'], styles['progress-slider'])}
						disabled={disableControls || !audioLoaded}
						value={disableControls || !audioLoaded ? 0 : sliderValue}
						max={duration}
						onChange={handleDragChange}
						onSlideEnd={() => {
							setIsDragging(false);

							seekTime(sliderValueRef.current);
						}}
					/>
					<span className={styles['progress-time']}>
						{formatFileTime(duration ?? 0)}
					</span>
				</div>
			</div>

			<div className={styles['right-side']}>
				{currentVersion &&
					isHighResAudio(currentVersion) &&
					currentVersion?.displayFileId && <LosslessSwitch />}
				<VolumeControl
					localVolume={localVolume}
					setLocalVolume={setLocalVolume}
				/>
				<Button
					icon='fas fa-times'
					rounded
					style={{ background: 'transparent !important' }}
					text
					onClick={() => dispatch(resetPlayerStateAction())}
					className={styles['close-button']}
				/>
			</div>

			<audio
				onTimeUpdate={timeUpdateHandler}
				onLoadedMetadata={loadedHandler}
				ref={audioRef}
				onEnded={() => nextSongHandler()}
				loop={false}
				preload='auto'
			></audio>
		</div>
	);
};

export default AudioPlayerBar;
