import React from 'react';
import styles from './SignUpHeader.module.scss';
import clsx from 'clsx';
import { getAssetPath } from '../../../helpers/assetTools';

type SignUpHeaderProps = {
	title?: React.ReactNode;
	className?: string;
	style?: React.CSSProperties;
	rightMenu?: React.ReactNode | null;
};

const SignUpHeader = ({
	title,
	className = '',
	style = {},
	rightMenu,
}: SignUpHeaderProps) => {
	return (
		<div className={clsx(styles['sign-up-header'], className)} style={style}>
			<div className={clsx(styles['logo'], 'd-flex align-items-center')}>
				<img
					src={getAssetPath('img/logo.svg')}
					alt='logo'
					className='h-100 mr-2'
				/>
				{title}
			</div>
			{rightMenu}
		</div>
	);
};

export default SignUpHeader;
