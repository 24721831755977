import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import TextField from '../../form/TextField/TextField';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import Button from '../../layout/Button';
import {
	hideAllModalsAction,
	hideModal,
	setModalTitle,
} from '../../../store/modal/actions';
import { useAppDispatch } from '../../../store/hooks';
import { activateBenefits } from '../../../api/services/subscriptionsService';
import { setUserPermissionsAndLimitsAction } from '../../../store/auth/actions';

type BenefitsCodeModalFormValues = {
	code: string;
};

const BenefitsCodeModalSchema = Yup.object().shape({
	code: Yup.string().required('Required'),
});

const BenefitsCodeModal = () => {
	const dispatch = useAppDispatch();
	const [error, setError] = useState<string>('');

	const handleSubmit = async (values: BenefitsCodeModalFormValues) => {
		setError('');
		try {
			const newPermissions = await activateBenefits(values.code);
			dispatch(setUserPermissionsAndLimitsAction(newPermissions));
			dispatch(hideAllModalsAction());
		} catch (error: any) {
			let errorMessage = 'Something went wrong, please try again later.';
			// catch 401 and 404 errors
			if (error?.response?.status === 401 || error?.response?.status === 404) {
				errorMessage = error?.response?.data?.detail;
			}

			setError(errorMessage);
		}
	};

	const formik = useFormik<BenefitsCodeModalFormValues>({
		initialValues: {
			code: '',
		},
		validationSchema: BenefitsCodeModalSchema,
		onSubmit: handleSubmit,
		validateOnMount: true,
	});

	useEffect(() => {
		dispatch(setModalTitle('REDEEM YOUR CODE'));
	}, [dispatch]);

	return (
		<FormikProvider value={formik}>
			<Modal.Body>
				<div className='px-4'>
					<TextField
						label='Enter your Avid or partner code to upgrade to Lite+'
						name='code'
						placeholder='Enter your code'
						value={formik?.values?.code}
						onChange={formik?.handleChange}
						onBlur={formik?.handleBlur}
						errorMessage={formik?.errors?.code}
						isInvalid={formik?.touched?.code && !!formik?.errors?.code}
					/>

					{error && <div className='text-danger form-label'>{error}</div>}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					onClick={() => dispatch(hideModal())}
					className='mr-2'
					label='Cancel'
				/>

				<Button
					isDisabled={!formik.isValid}
					onClick={formik.submitForm}
					label='Redeem Code'
					theme='dark'
					isLoading={formik.isSubmitting}
				/>
			</Modal.Footer>
		</FormikProvider>
	);
};

export default BenefitsCodeModal;
