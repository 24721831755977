import couponGeneratorApi from '../couponGeneratorApi';

const generateNewUserCoupon = (email: string) =>
	couponGeneratorApi.post<{ coupon: string }>(
		'/new-user-coupon-generator',
		null,
		{
			params: {
				email,
			},
		}
	);

const CouponGeneratorService = {
	generateNewUserCoupon,
};

export default CouponGeneratorService;
