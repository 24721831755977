import React from 'react';
import { PlaylistFileUploadContextProvider } from './PlaylistFileUploadContext';
import { FormikProps } from 'formik';
import UploadFilesToPlaylistContent from './UploadFilesToPlaylistContent';
import { UploadFilesToPlaylistForm } from '../../../../../helpers/playlistUploadTools';

export type UploadFilesToPlaylistProps = {
	playlistId?: Playlist['id'];
	initialFiles?: File[];
	onSubmit: (
		values: UploadFilesToPlaylistForm,
		inputFiles: File[],
		folderDict: { [key: string]: { [key: string]: File[] } },
		folderFiles: {
			[key: string]: File[];
		},
		folderFilesMetadata: {
			[key: string]: any[];
		}
	) => Promise<void>;
	fromNewPlaylist?: boolean;
	hideToggles?: boolean;
	renderFooter?: (props: {
		formik: FormikProps<UploadFilesToPlaylistForm>;
		inputFiles: File[];
		isLoading: boolean;
	}) => React.ReactNode;
	className?: string;
};

const UploadFilesToPlaylist = (props: UploadFilesToPlaylistProps) => {
	return (
		<PlaylistFileUploadContextProvider playlistId={props.playlistId}>
			<UploadFilesToPlaylistContent {...props} />
		</PlaylistFileUploadContextProvider>
	);
};

export default UploadFilesToPlaylist;
