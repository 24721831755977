import React from 'react';
import IconButton from '../../../layout/IconButton';
import '../../Projects/ProjectsTable/ProjectsTable.scss';
import theme from '../../../../theme.module.scss';
import FileTagChip from '../../ProjectFiles/FileTagChip';
import ProjectInfoCell from '../ProjectInfoCell';

const selectedFilesColumns = [
	{
		Header: () => <span className='Project-Name'>File Name</span>,
		accessor: 'filename',
		Cell: col => (
			<span className='Name1 select-project-files__file-name'>{col.value}</span>
		),
		width: '45%',
	},
	{
		Header: () => <span className='Project-Name'>Tag</span>,
		accessor: 'label',
		Cell: col =>
			col.value ? (
				<span className='Name1'>
					<FileTagChip tag={col.value} isReadOnly />
				</span>
			) : (
				<>—</>
			),
		width: '30%',
	},
	{
		Header: () => <span className='Project-Name'>Project</span>,
		accessor: 'project',
		Cell: col => (
			<span className='Name1'>
				<ProjectInfoCell project={col.value} />
			</span>
		),
		width: '15%',
	},
	{
		Header: () => <></>,
		accessor: 'actions',
		Cell: ({ onFileRemove, row }) => {
			return (
				<IconButton
					color={theme.error}
					icon='fas fa-minus-circle'
					iconSize='1.3rem'
					onClick={() => onFileRemove(row.original.id)}
					tooltipText='Unselect'
					outerStyle={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				/>
			);
		},
		width: '10%',
	},
];

export default selectedFilesColumns;
