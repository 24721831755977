import { useEffect, useMemo, useState } from 'react';
import { Modal, Alert, Card, Col, Form } from 'react-bootstrap';
import { hideModal, setModalTitle } from '../../../../store/modal/actions';
import Button from '../../../layout/Button';
import { generateISRC } from '../../../../api/services/codesService';
import { getCloudRecordingById } from '../../../../api/services/projectsService';
import {
	updateCloudRecordingAction,
} from '../../../../store/projects/actions';
import { getAlbumRecordingIds } from '../../../../helpers/albumTools';
import { showAlertToast } from '../../../../store/alertToast/actions';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import SelectRecordings from '../../SelectRecordings';

const Agreement = ({
	toggleNoISRC = () => {},
	toggleAuthorized = () => {},
	noISRC = false,
	authorized = false,
}) => (
	<Card>
		<Card.Body>
			<h2>NOTICES AND WARNINGS</h2>

			<Col>
				<Form.Check
					type='checkbox'
					name='noISRC'
					id='noISRC'
					label=' I certify that I am not working for another entity that intends to
          assign an ISRC.'
					onClick={toggleNoISRC}
					checked={noISRC}
				/>
			</Col>

			<Col>
				<Form.Check
					type='checkbox'
					name='authorized'
					id='authorized'
					label=' I certify that I am fully authorized to generate codes for this release
          by the rights holders of the recording.'
					onClick={toggleAuthorized}
					checked={authorized}
				/>
			</Col>
		</Card.Body>
	</Card>
);

const GenerateCodeModal = () => {
	const dispatch = useAppDispatch();
	const { albumsById, recordingsById, currentAlbumId, currentRecordingId } =
		useAppSelector(state => state.projects);
	const { userId } = useAppSelector(state => state.auth);

	const [selectedRecordingIds, setSelectedRecordingIds] = useState<number[]>(
		[]
	);
	const [noISRC, setNoISRC] = useState(false);
	const [authorized, setAuthorized] = useState(false);
	const [errorMessages, setErrorMessages] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState(false);

	const toggleNoISRC = () => setNoISRC(!noISRC);
	const toggleAuthorized = () => setAuthorized(!authorized);
	const isAlbum =
		currentAlbumId && albumsById && Boolean(albumsById?.[currentAlbumId]);
	const recordingList = useMemo(
		() =>
			!recordingsById
				? []
				: isAlbum
				? getAlbumRecordingIds(albumsById?.[currentAlbumId]).map(
						id => recordingsById?.[id]
				  )
				: [recordingsById?.[currentRecordingId!]],
		[isAlbum, currentAlbumId, recordingsById, albumsById, currentRecordingId]
	);

	useEffect(() => {
		dispatch(setModalTitle('GENERATE CODES'));
	}, [dispatch]);

	const handleSubmit = async () => {
		try {
			setIsLoading(true);
			const selectedRecordings = recordingList.filter(recording =>
				selectedRecordingIds.includes(recording.id)
			);

			const fetchedSelectedRecordings = await Promise.all(
				selectedRecordings.map(
					async recording => await getCloudRecordingById(recording.id)
				)
			);

			const recordingsWithoutRecordingDate = fetchedSelectedRecordings.filter(
				recording => !recording.recording?.recordingDate
			);

			if (recordingsWithoutRecordingDate.length) {
				setErrorMessages(
					recordingsWithoutRecordingDate.map(
						recording =>
							`"${recording.title}" doesn't have a 'Date of Recording'.`
					)
				);
				return false;
			}

			const recordingsToFetch = recordingList
				.filter(recording => selectedRecordingIds.includes(recording.id))
				.map(recording => getCloudRecordingById(recording.id));

			const fetchedRecordings = await Promise.all(recordingsToFetch);

			const recordingsForGenerate = fetchedRecordings.map(recording =>
				generateISRC(recording.recording!, userId!)
			);

			const codesResponse = await Promise.all(recordingsForGenerate);
			let codesGenerated = null;

			codesGenerated = codesResponse.map(res => {
				if (res.status === 215) {
					return res.data.codes.recording_id.isrc.replace('found:', '');
				}

				if (res.data.codes.recording_id.isrc.includes('failed')) return '';

				return res.data.codes.recording_id.isrc;
			});

			console.log('codesGenerated: ', codesGenerated);

			if (codesGenerated.length === 1) {
				dispatch(
					updateCloudRecordingAction({
						recordingForm: {
							...fetchedRecordings[0].recording!,
							isrc: codesGenerated[0],
						},
					})
				);
			} else {
				codesGenerated.forEach((isrc, i) => {
					dispatch(
						updateCloudRecordingAction({
							recordingForm: {
								...fetchedRecordings[i].recording!,
								isrc,
							},
						})
					);
				});
			}
			setIsLoading(false);
			dispatch(hideModal());
		} catch (e) {
			dispatch(
				showAlertToast(
					"Oops! We couldn't generate the ISRC. Don't give up, try again!"
				)
			);
			dispatch(hideModal());
			console.log(e);
		} finally {
			setIsLoading(false);
		}
	};

	const onSelect = (newValues: number[]) => {
		setSelectedRecordingIds([...newValues]);
	};

	return (
		<>
			<Modal.Body>
				<Card>
					<Card.Body>
						<h2>SELECT RECORDING(S)</h2>
						<Col>
							<SelectRecordings
								customRows
								projectRows={recordingList}
								onSelect={onSelect}
							/>
						</Col>
					</Card.Body>
				</Card>
				<Agreement
					toggleNoISRC={toggleNoISRC}
					toggleAuthorized={toggleAuthorized}
					noISRC={noISRC}
					authorized={authorized}
				/>
				{errorMessages.length ? (
					<Alert
						key='danger'
						variant='danger'
						style={{
							fontSize: '0.9rem',
						}}
					>
						{errorMessages.map(err => (
							<p className='mb-0'>{err}</p>
						))}
					</Alert>
				) : (
					<></>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button
					label='Generate Codes'
					theme='dark'
					onClick={handleSubmit}
					isDisabled={
						!selectedRecordingIds.length || !noISRC || !authorized || isLoading
					}
					isLoading={isLoading}
				/>
			</Modal.Footer>
		</>
	);
};

export default GenerateCodeModal;
