import React from 'react';
import 'pure-react-carousel/dist/react-carousel.es.css';
import { CarouselProvider, DotGroup, Slide, Slider } from 'pure-react-carousel';
import './Carousel.scss';

const Carousel = ({ currentIndex, onChangeIndex, slides, showDots = true }) => {
	return (
		<CarouselProvider
			totalSlides={slides.length}
			currentSlide={currentIndex}
			isIntrinsicHeight
			dragEnabled={false}
		>
			<Slider>
				{slides.map((slide, index) => (
					<Slide key={index} index={index}>
						{slide}
					</Slide>
				))}
			</Slider>
			{showDots && <DotGroup />}
		</CarouselProvider>
	);
};

export default Carousel;
