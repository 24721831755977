import React, { useMemo } from 'react';
import { Col, Nav, Row, Tab } from 'react-bootstrap';
import { read, utils } from 'xlsx';
import './SpreadsheetPreview.scss';

const SpreadsheetPreview = ({ file }) => {
	// const [activeSheet, setActiveSheet] = React.useState(0);

	const tableSheets = useMemo(() => {
		const wb = read(file); // parse the array buffer

		return wb.SheetNames.map((sheetName, i) => {
			const sheet = wb.Sheets[sheetName];
			const tableHtml = utils.sheet_to_html(sheet);
			return {
				name: sheetName,
				html: tableHtml,
			};
		});
	}, [file]);

	return (
		<div className='spreadsheet-preview-container'>
			<Tab.Container defaultActiveKey={0}>
				<Row className='w-100 h-100'>
					<Col xs={2} className='h-100'>
						<h2>SHEETS</h2>
						<hr />
						<div className='sheet-preview-selector'>
							<Nav variant='pills' className='flex-column'>
								{tableSheets.map((sheet, i) => (
									<Nav.Item key={i}>
										<Nav.Link
											eventKey={i}
											// onClick={() => setActiveSheet(i)}
										>
											{sheet.name}
										</Nav.Link>
									</Nav.Item>
								))}
							</Nav>
						</div>
					</Col>
					<Col xs={10} className='h-100'>
						<Tab.Content className='h-100'>
							{tableSheets.map((sheet, i) => (
								<Tab.Pane key={i} eventKey={i} className='h-100'>
									<div
										className='sheet-preview-container'
										dangerouslySetInnerHTML={{ __html: sheet.html }}
									/>
								</Tab.Pane>
							))}
						</Tab.Content>
					</Col>
				</Row>
			</Tab.Container>
		</div>
	);
};

export default SpreadsheetPreview;
