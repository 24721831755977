import fileSize from 'filesize';
import React, { useMemo } from 'react';
import { STORAGE_LIMIT_MODAL } from '../../../../constants/modalTypes';
import { showModalAction } from '../../../../store/modal/actions';
import './FileStorageStatus.scss';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

export type FileStorageStatusProps = {
	currentProjectStorageUsage?: number | null;
	isShared?: boolean;
};

const FileStorageStatus = ({
	currentProjectStorageUsage = null,
	isShared = false,
}: FileStorageStatusProps) => {
	const dispatch = useAppDispatch();
	const { storageUsage } = useAppSelector(state => state.files);

	const usedPercentage = useMemo(
		() => (storageUsage!.used / storageUsage!.limit) * 100,
		[storageUsage]
	);

	const readableUsed = useMemo(
		() => fileSize(storageUsage!.used),
		[storageUsage]
	);

	const readableLimit = useMemo(
		() => fileSize(storageUsage!.limit),
		[storageUsage]
	);

	const readableProjectStorageUsage = useMemo(
		() =>
			currentProjectStorageUsage !== null
				? fileSize(currentProjectStorageUsage)
				: '',
		[currentProjectStorageUsage]
	);

	const showAlert = useMemo(
		() => !isShared && usedPercentage >= 90,
		[usedPercentage, isShared]
	);

	const handleClick = () => {
		if (isShared) {
			return;
		}

		dispatch(showModalAction(STORAGE_LIMIT_MODAL, { size: 'md' }));
	};

	return (
		<div
			className={`d-flex align-items-${
				isShared ? 'end' : 'center'
			} flex-column`}
		>
			<div
				onClick={handleClick}
				className={`d-flex align-items-center justify-content-center ${
					isShared ? 'shared-storage-usage-pill' : 'storage-usage-status-pill'
				} ${showAlert ? 'storage-usage-status-danger' : ''}`}
			>
				{isShared ? (
					<>
						<i
							className='fas fa-user-friends mr-2'
							style={{ fontSize: '1rem' }}
						></i>
						<div className='fw-500' style={{ fontSize: '0.8rem' }}>
							SHARED
						</div>
					</>
				) : (
					<>
						<i
							className='fas fa-hdd mr-2 mt-1'
							style={{ fontSize: '1.3rem' }}
						></i>
						<div className='d-flex align-items-center justify-content-center flex-column'>
							<progress
								className={`storage-progress mb-1 ${
									showAlert ? 'storage-progress-danger' : ''
								}`}
								value={usedPercentage}
								max='100'
							/>
							<span
								className=' fw-500'
								style={{ fontSize: '0.8rem', lineHeight: '0.8rem' }}
							>
								{readableUsed} of {readableLimit} used
							</span>
						</div>
					</>
				)}
			</div>
			{readableProjectStorageUsage ? (
				<div className='current-project-storage-usage'>
					In Current Project: {readableProjectStorageUsage}
				</div>
			) : (
				<></>
			)}
		</div>
	);
};

export default FileStorageStatus;
