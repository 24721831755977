import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import Select from '../../../../form/Select';
import CreatableSelect from '../../../../form/CreatableSelect';
import TextField from '../../../../form/TextField';
import DatePicker from '../../../../form/DatePicker';
import Button from '../../../../layout/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
	nextExportStepAction,
	previousExportStepAction,
	setUnionExportStudioFormAction,
} from '../../../../../store/exports/actions';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import exportTypes from '../../../../../constants/exportType';
import usStates from '../../../../../constants/usStates.json';
import afmLocalNumbers from '../../../../../constants/unionForm/afmLocalNumbers.json';
import { useDebounceEffect } from '../../../../../hooks/useDebounceEffect';

const schema = yup.object().shape({
	studio: yup
		.object()
		.shape({
			label: yup.string(),
			value: yup.string(),
		})
		.nullable()
		.required('Studio Is Required'),
	city: yup.string().required('City Is Required'),
	state: yup.string().required('State Is Required'),
	recordingDate: yup
		.date()
		.nullable()
		.transform((curr, orig) => (orig === '' ? null : curr))
		.required('Recording Date Is Required'),
});

const SelectStudioStep = ({ onCancel }) => {
	/*
	 * Redux Hooks
	 */
	const dispatch = useDispatch();
	const { currentAlbumId, recordingsById } = useSelector(
		state => state.projects
	);
	const { exportType } = useSelector(state => state.exports);
	const { studioForm, selectedRecordingRows } = useSelector(
		state => state.exports.unionForm
	);

	/*
	 * React Hooks
	 */
	const [afmLocalNumbersOptions, setAfmLocalNumbersOptions] = useState([]);

	const [studioOptions, setStudioOptions] = useState([]);

	const selectedRecordingIds = useMemo(
		() => selectedRecordingRows.map(row => row.recordingId),
		[selectedRecordingRows]
	);

	const statesOptions = useMemo(
		() =>
			usStates.map(state => ({
				label: state.name,
				value: state.abbreviation,
			})),
		[]
	);

	const initialRecordingDate = useMemo(() => {
		const existingDate = studioForm?.recordingDate;

		if (existingDate) {
			return existingDate;
		}

		// if there's more than one recording, default to empty string
		if (selectedRecordingIds.length > 1) {
			return '';
		}

		// if there is only one recording, use its date if it has any
		return (
			(recordingsById &&
				recordingsById[selectedRecordingIds[0]] &&
				recordingsById[selectedRecordingIds[0]].recording &&
				recordingsById[selectedRecordingIds[0]].recording.recordingDate) ||
			''
		);
	}, [recordingsById, selectedRecordingIds, studioForm]);

	const formInitialValues = useMemo(() => {
		return {
			...studioForm,
			recordingDate: initialRecordingDate,
		};
	}, [initialRecordingDate, studioForm]);

	/*
	 * Callbacks
	 */
	const handleSubmit = values => {
		dispatch(setUnionExportStudioFormAction(values));

		dispatch(nextExportStepAction());
	};

	/*
	 * Formik Hooks
	 */
	const studioFormik = useFormik({
		initialValues: formInitialValues,
		validationSchema: schema.concat(
			exportType === exportTypes.AFM_B4 || exportType === exportTypes.AFM_B9
				? yup.object().shape({
						afmLocalNumber: yup
							.string()
							.nullable()
							.transform((curr, orig) => (orig === '' ? null : curr))
							.required('AFM Local Number Is Required'),
				  })
				: yup.object()
		),
		onSubmit: handleSubmit,
	});

	const navigateToPrevStep = useCallback(() => {
		dispatch(setUnionExportStudioFormAction(studioFormik.values));

		if (currentAlbumId) {
			dispatch(previousExportStepAction());
			return;
		}

		if (onCancel) {
			onCancel();
		}
	}, [currentAlbumId, dispatch, onCancel, studioFormik]);

	/*
	 * Effects
	 */
	useDebounceEffect(
		() => dispatch(setUnionExportStudioFormAction(studioFormik.values)),
		500,
		[studioFormik.values, dispatch]
	);

	useEffect(() => {
		// get unique studios from all selected recordings
		const studiosById = {};

		selectedRecordingIds.forEach(recordingId => {
			const recording = recordingsById[recordingId].recording;
			console.log('recording', recording);
			recording.studios.forEach(studio => {
				console.log('studio', studio);
				studiosById[studio.id] = { label: studio.name, value: studio.id };
			});
		});

		// add custom studio in case it's not in the list
		if (
			!studiosById[studioFormik.values.studio?.value] &&
			studioFormik.values.studio
		) {
			studiosById[studioFormik.values.studio?.value] = {
				label: studioFormik.values.studio?.label,
				value: studioFormik.values.studio?.value,
			};
		}

		setStudioOptions([...Object.values(studiosById)]);
	}, [recordingsById, selectedRecordingIds, studioFormik.values]);

	useEffect(() => {
		const defaultOptions = afmLocalNumbers.map(({ name, number }) => ({
			label: `${number} (${name})`,
			value: number,
		}));

		// add custom afm local number in case it's not in the list
		if (
			!defaultOptions.find(
				option => option.value === studioFormik.values.afmLocalNumber
			) &&
			studioFormik.values.afmLocalNumber
		) {
			defaultOptions.push({
				label: `${studioFormik.values.afmLocalNumber}`,
				value: studioFormik.values.afmLocalNumber,
			});
		}

		setAfmLocalNumbersOptions([...defaultOptions]);
	}, [studioFormik.values]);

	return (
		<>
			<FormikProvider value={studioFormik}>
				<Form onSubmit={studioFormik.handleSubmit}>
					<>
						<Card>
							<Card.Body>
								<h2>SELECT STUDIO</h2>
								<p className='body-text'>
									Select the studio here to have the musicians for a session
									automatically populate.
								</p>
								<Row>
									{/* Studio dropdown */}
									<Col xs={7}>
										<CreatableSelect
											label='Studio'
											name='studio'
											id='studio'
											placeholder='Select or Enter a Studio'
											options={studioOptions}
											value={studioOptions.find(studio => {
												return (
													studio.value === studioFormik.values.studio?.value ||
													''
												);
											})}
											onChange={option =>
												studioFormik.setFieldValue('studio', option)
											}
											onCreateOption={newOption => {
												const newStudio = {
													label: newOption,
													value: newOption,
												};

												studioFormik.setFieldValue('studio', newStudio);
											}}
											errorFieldName='studio'
											createOnBlur
										/>
									</Col>

									{(exportType === exportTypes.AFM_B4 ||
										exportType === exportTypes.AFM_B9) && (
										<Col xs={5}>
											<CreatableSelect
												label='AFM Local Number'
												name='afmLocalNumber'
												id='afmLocalNumber'
												placeholder='Enter number'
												options={afmLocalNumbersOptions}
												value={
													afmLocalNumbersOptions.find(
														afmLocalNumber =>
															afmLocalNumber.value ===
															studioFormik.values.afmLocalNumber
													) || ''
												}
												onChange={option =>
													studioFormik.setFieldValue(
														'afmLocalNumber',
														option.value
													)
												}
												errorFieldName='afmLocalNumber'
												onCreateOption={value => {
													const newAfmLocalNumber = {
														value: value,
														label: value,
													};

													studioFormik.setFieldValue(
														'afmLocalNumber',
														newAfmLocalNumber.value
													);
												}}
											/>
										</Col>
									)}
								</Row>

								<Row>
									{/* City */}
									<Col xs={5}>
										<TextField
											label='City'
											name='city'
											value={studioFormik.values.city}
											onChange={studioFormik.handleChange}
											onBlur={studioFormik.handleBlur}
											isInvalid={
												studioFormik.touched.city && !!studioFormik.errors.city
											}
											errorMessage={studioFormik.errors.city}
										/>
									</Col>

									{/* State/region dropdown */}
									<Col xs={4}>
										<Select
											label='State / Region'
											id='state'
											name='state'
											placeholder='Select a state'
											options={statesOptions}
											value={statesOptions.find(
												state => state.value === studioFormik.values.state || ''
											)}
											onChange={option =>
												studioFormik.setFieldValue('state', option.value)
											}
											onBlur={studioFormik.handleBlur}
											errorFieldName='state'
										/>
									</Col>

									{/* Date of Recording */}
									<Col xs={3}>
										<DatePicker
											label='Date of Recording'
											name='recordingDate'
											value={studioFormik.values.recordingDate}
											onChange={studioFormik.handleChange}
											onBlur={studioFormik.handleBlur}
											isInvalid={
												studioFormik.touched.recordingDate &&
												!!studioFormik.errors.recordingDate
											}
											errorMessage={studioFormik.errors.recordingDate}
										/>
									</Col>
								</Row>
							</Card.Body>
						</Card>

						<Row>
							<Col className='d-flex justify-content-end align-items-center'>
								<Button
									label={`${currentAlbumId ? 'Back' : 'Cancel'}`}
									className='mr-3'
									onClick={navigateToPrevStep}
								/>

								<Button label='Next' theme='dark' type='submit' />
							</Col>
						</Row>
					</>
				</Form>
			</FormikProvider>
		</>
	);
};

export default SelectStudioStep;
