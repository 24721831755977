import React, { useEffect } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { hideModal, setModalTitle } from '../../../../store/modal/actions';
import Button from '../../../layout/Button';
import './SendModal.scss';

const SendModal = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setModalTitle('Send'));
	}, [dispatch]);

	return (
		<>
			<Modal.Body>
				<div className='sendModal-wrapper'>
					<Row>
						<Col>
							<Button label='Select Recording (s)' />
						</Col>
					</Row>
					<hr />
					<Row>
						<Col>
							<p className='ptext'>Selected RINS</p>
							<div className='stripe'>Nelson Country</div>
						</Col>
					</Row>
					<Row>
						<Col>
							<p className='ptext'>Selected Releases</p>
							<div className='stripe'>Nelson Country</div>
						</Col>
					</Row>
					<hr />
					<Row>
						<Col lg={7}>
							<p>Recipient Email Address</p>
							<p className='user-email-text'>
								Enter the email address for the Sound Credit user who should
								receive the project.
							</p>
							<div className='email-address-field'>
								<div className='form-group email'>
									<Form.Control
										type='text'
										name='upcEan'
										placeholder='Email Address'
										// value={formik.values.upcEan}
										// onChange={formik.handleChange}
										// onBlur={formik.handleBlur}
										// isValid={formik.touched.upcEan && !formik.errors.upcEan}
										// isInvalid={formik.touched.upcEan && !!formik.errors.upcEan}
									/>
									<Form.Control.Feedback type='invalid'>
										{/* {formik.errors.upcEan} */}
									</Form.Control.Feedback>
								</div>
								<div className='form-group submit-btn'>
									<Button label='Send' theme='dark' />
								</div>
							</div>
						</Col>
						<Col lg={5}></Col>
					</Row>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Row>
					<Col
						xs={{ span: 4, offset: 8 }}
						className='d-flex justify-content-end'
					>
						<button
							onClick={() => dispatch(hideModal())}
							className='btn border-purple text-purple bg-white fw-600 mr-3'
							type='button'
						>
							Cancel
						</button>
						<button type='submit' className='btn btn-purple'>
							Save
						</button>
					</Col>
				</Row>
			</Modal.Footer>
		</>
	);
};

export default SendModal;
