import { ErrorMessage } from 'formik';
import React, { useLayoutEffect, useMemo } from 'react';
import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import ReactAsyncCreatableSelect from 'react-select/async-creatable';
import theme from '../../../theme.module.scss';
import MenuList from '../MenuList';
import selectStyles from '../Select/selectStyles';
import { v4 as uuidv4 } from 'uuid';
import { GroupBase, SelectComponentsConfig } from 'react-select';

const creatableStyles = {
	...selectStyles,
	input: (provided: any) => ({
		...provided,
		input: {
			opacity: '1 !important', // This is a hack to fix a bug in our CreatableSelect where the input is not visible when the menu closes after selecting an option.
		},
	}),
};

export type AsyncCreatableSelectProps = {
	name?: string;
	id?: string;
	options: any[];
	placeholder?: string;
	value: any;
	onCreateOption: (newValue: string) => void;
	onChange: (newValue: any) => void;
	label?: string | React.ReactNode;
	isMulti?: boolean;
	errorFieldName?: string;
	getOptionLabel?: (option: any) => string;
	getOptionValue?: (option: any) => string;
	getNewOptionData?: (inputValue: string, optionLabel: React.ReactNode) => any;
	isDisabled?: boolean;
	isSearchable?: boolean;
	className?: string;
	onInputChange?: (inputValue: string, actionMeta: any) => void;
	noOptionsMessage?: (obj: any) => React.ReactNode;
	onKeyDown?: (e: React.KeyboardEvent<HTMLElement>) => void;
	isGrouped?: boolean;
	hideDropdownIndicator?: boolean;
	menuIsOpen?: boolean;
	inputValue?: string;
	dense?: boolean;
	createOnBlur?: boolean;
	preserveInputOnBlur?: boolean;
	loadOptions?: (inputValue: string) => Promise<any>;
	cacheOptions?: boolean;
	components?: SelectComponentsConfig<any, boolean, GroupBase<any>>;
};

const AsyncCreatableSelect = ({
	name,
	id = uuidv4(),
	cacheOptions = true,
	options,
	placeholder,
	value: selectedValue,
	onCreateOption,
	onChange,
	label,
	isMulti,
	errorFieldName,
	getOptionLabel = option => option.label,
	getOptionValue = option => option.value,
	getNewOptionData,
	isDisabled,
	isSearchable,
	className,
	onInputChange,
	loadOptions,
	noOptionsMessage,
	onKeyDown,
	isGrouped = false,
	hideDropdownIndicator = undefined,
	menuIsOpen = undefined,
	inputValue,
	dense = false,
	createOnBlur = true,
	preserveInputOnBlur = true,
	components,
}: AsyncCreatableSelectProps) => {
	const [_inputValue, setInputValue] = React.useState('');
	const flattenedOptions = useMemo(() => {
		return options.reduce((acc, option) => {
			if (option.options) {
				return [...acc, ...option.options];
			}
			return [...acc, option];
		}, []);
	}, [options]);

	useLayoutEffect(() => {
		const inputEl = document.getElementById(id);
		if (!inputEl) {
			return;
		}
		// prevent input from being hidden after selecting
		inputEl.style.opacity = '1';
	}, [selectedValue, id]);

	const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
		if (!createOnBlur) return;

		const { value: newValue } = e.target;

		if (newValue === getOptionLabel(selectedValue)) {
			return;
		}

		// This is used so that users don't need to manually select the option they just entered.
		// If it already existed, it will select it. If it didn't exist, it will create it and select it.
		// ! WARNING: This logic may break cases where uniqueness is not computed by the label.
		// ! For example, we had a bug where the "Self Published" publisher option was always defaulting
		// ! to the same option, because the label was always the same, even though we want users to have
		// ! more than one "Self Published" option with different PROs, IPIs, etc.

		// ! This is why we do the newValue === getOptionLabel(selectedValue) check above. If there was already
		// ! a fully created matching option selected beforehand, we don't want to create a new one.
		const existingOption = flattenedOptions.find(
			(option: any) => getOptionLabel(option)?.trim() === newValue?.trim()
		);

		if (newValue && !existingOption) {
			// create and select the new option
			onCreateOption(newValue);
		}

		if (existingOption) {
			// select the existing option
			onChange(existingOption);
		}

		if (!preserveInputOnBlur) {
			setInputValue('');
		}
	};

	const handleInputChange = (
		newValue: string,
		{
			action,
		}: {
			action: string;
		}
	) => {
		if (action === 'input-change') {
			setInputValue(newValue);
		}

		if (action === 'menu-close' && !newValue && selectedValue) {
			setInputValue(getOptionLabel(selectedValue));
		}

		if (onInputChange) {
			onInputChange(newValue, { action });
		}
	};

	const previousValue = React.useRef(selectedValue);

	useEffect(() => {
		if (
			preserveInputOnBlur &&
			selectedValue &&
			getOptionLabel(selectedValue) &&
			previousValue.current !== selectedValue
		) {
			setInputValue(getOptionLabel(selectedValue));
			previousValue.current = selectedValue;
		}
	}, [selectedValue, getOptionLabel, _inputValue, preserveInputOnBlur]);

	// const handleChange = (value, { action }) => {


	return (
		<Form.Group className={`${!dense ? 'mb-4' : ''}`}>
			<Form.Label htmlFor={name}>{label}</Form.Label>
			<ReactAsyncCreatableSelect
				components={{
					...(hideDropdownIndicator
						? { DropdownIndicator: null }
						: isGrouped
						? {}
						: { MenuList }),
					...(components ?? {}),
				}}
				cacheOptions={cacheOptions}
				styles={creatableStyles}
				isMulti={isMulti}
				name={name}
				id={id || `select-${name}`}
				inputId={id || `select-${name}`}
				options={options}
				className={className}
				classNamePrefix='select'
				placeholder={placeholder}
				value={selectedValue}
				onCreateOption={onCreateOption}
				onChange={onChange}
				getOptionLabel={getOptionLabel}
				getOptionValue={getOptionValue}
				getNewOptionData={getNewOptionData}
				isDisabled={isDisabled}
				isSearchable={isSearchable}
				loadOptions={loadOptions}
				onInputChange={handleInputChange}
				noOptionsMessage={noOptionsMessage}
				menuIsOpen={menuIsOpen && !hideDropdownIndicator}
				onKeyDown={onKeyDown}
				inputValue={preserveInputOnBlur ? _inputValue : inputValue}
				onBlur={handleBlur}
			/>
			{errorFieldName && (
				<ErrorMessage name={errorFieldName}>
					{msg => {
						return (
							<small
								style={{ color: theme.error, height: 0, position: 'absolute' }}
								className='mt-1'
							>
								{msg}
							</small>
						);
					}}
				</ErrorMessage>
			)}
		</Form.Group>
	);
};

export default AsyncCreatableSelect;
