import './Notification.scss';
import React, { useMemo, useState } from 'react';
import { showErrorAlert } from '../../../store/alertToast/actions';
import { getUserProjectsAction } from '../../../store/projects/actions';
import {
	acceptRecordingInvite,
	acceptAlbumInvite,
	declineAlbumInvite,
	declineRecordingInvite,
} from '../../../api/services/editorService';
import { Col, Row, Spinner } from 'react-bootstrap';
import Button from '../Button';
import { readNotificationAction } from '../../../store/user/actions';
import { declinePlaylistEditorInvite } from '../../../api/services/filesService';
import { NotificationTypes } from './types';
import { acceptPlaylistEditorInviteAction } from '@/store/playlists/actions';
import { acceptPlaylistTransferInviteAction } from '../../../store/files/actions';
import { navigateToProjectFiles } from '../../../helpers/fileTools';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useNavigate } from 'react-router-dom';
import FILE_TABS from '@/constants/fileTabs.json';
import { getTrackChatFileVersionLink } from '../../../helpers/playlistTools';
import { replacePathVariables } from '../../../helpers/routeTools';
import ROUTES from '../../../router/routes';
import { showModalAction } from '../../../store/modal/actions';
import { CREDIT_REQUESTS_MODAL } from '../../../constants/modalTypes';

const dateFmtOptions: Intl.DateTimeFormatOptions = {
	year: 'numeric',
	month: 'numeric',
	day: 'numeric',
	hour: 'numeric',
	minute: 'numeric',
};

const Notification = ({ notification }: { notification: UserNotification }) => {
	const { userId } = useAppSelector(state => state.auth);

	// TODO: FIX TYPE
	const stopPropagation = (e: any) => e.stopPropagation();
	const [loading, setLoading] = useState(false);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const localeDate = useMemo(
		() =>
			notification.createdAt
				? new Date(notification.createdAt).toLocaleDateString(
						'en-US',
						dateFmtOptions
				  )
				: '',
		[notification]
	);

	const acceptInvitation = async () => {
		try {
			setLoading(true);

			switch (notification.notificationType) {
				case NotificationTypes.PROJECT_INVITE:
					await acceptRecordingInvite({
						editorId: userId!,
						recordingId: notification.data.recordingId,
					});
					break;

				case NotificationTypes.ALBUM_INVITE:
					await acceptAlbumInvite({
						editorId: userId!,
						albumId: notification.data.albumId ?? notification.data.recordingId, // may have to fall back to recordingId for legacy reasons
					});
					break;

				case NotificationTypes.PLAYLIST_TRANSFER:
					await Promise.resolve(
						dispatch(
							acceptPlaylistTransferInviteAction(
								notification.data.shareLinkSlug,
								true
							)
						)
					);
					break;

				case NotificationTypes.PLAYLIST_INVITE:
					await Promise.resolve(
						dispatch(
							acceptPlaylistEditorInviteAction({
								playlistId: notification.data.shareLinkId,
							})
						)
					);

					break;
				default:
					break;
			}

			await Promise.resolve(
				dispatch(readNotificationAction(notification.uuid))
			);

			dispatch(getUserProjectsAction());
		} catch (e: any) {
			console.error(e);
			if (e?.response?.status === 410) {
				dispatch(
					showErrorAlert(
						"Whoops! Looks like this invite is no longer valid. We're on it, please try again later. Rock on!"
					)
				);

				return;
			}

			dispatch(
				showErrorAlert(
					"Whoops! Notification not accepted. We're on it, please try again later. Rock on!"
				)
			);
		} finally {
			setLoading(false);
		}
	};

	const declineInvitation = async () => {
		try {
			setLoading(true);

			if (!notification.uuid) {
				dispatch(
					showErrorAlert(
						"Whoops! We couldn't decline the notification. Keep on rockin'!"
					)
				);
				console.log("DOESN'T HAVE uuid", notification.uuid);
				return;
			}

			switch (notification.notificationType) {
				case NotificationTypes.PROJECT_INVITE:
					await declineRecordingInvite({
						editorId: userId!,
						recordingId: notification.data.recordingId,
					});
					break;

				case NotificationTypes.ALBUM_INVITE:
					await declineAlbumInvite({
						editorId: userId!,
						albumId: notification.data.recordingId, // Yes, it still uses recordingId even though it's an album invite due to legacy reasons
					});
					break;

				case NotificationTypes.PLAYLIST_TRANSFER:
					await Promise.resolve(
						dispatch(
							acceptPlaylistTransferInviteAction(
								notification.data.shareLinkSlug,
								false
							)
						)
					);
					break;

				case NotificationTypes.PLAYLIST_INVITE:
					await declinePlaylistEditorInvite({
						playlistId: notification.data.shareLinkId,
					});

					break;
				default:
					break;
			}

			await Promise.resolve(
				dispatch(readNotificationAction(notification.uuid))
			);
		} catch (e) {
			console.error(e);
			dispatch(
				showErrorAlert(
					"Whoops! Can't read notification now. We'll fix it soon, try again later. Keep rockin'!"
				)
			);
		} finally {
			setLoading(false);
		}
	};

	const markAsRead = async () => {
		try {
			setLoading(true);

			await Promise.resolve(
				dispatch(readNotificationAction(notification.uuid))
			);
		} catch (e) {
			console.log(e, { e });
			dispatch(
				showErrorAlert(
					"Whoops! Notification trouble. We're fixing it up now. Please try again later. Keep on rockin'!"
				)
			);
		} finally {
			setLoading(false);
		}
	};

	const renderActionButtons = () => {
		switch (notification.notificationType) {
			case NotificationTypes.PROJECT_INVITE:
			case NotificationTypes.ALBUM_INVITE:
			case NotificationTypes.PLAYLIST_TRANSFER:
			case NotificationTypes.PLAYLIST_INVITE:
				return (
					<div
						className='d-flex'
						style={{
							width: '100%',
							justifyContent: 'space-between',
						}}
					>
						<Button
							// TODO: FIX TYPE
							onClick={(e: any) => {
								stopPropagation(e);
								acceptInvitation();
							}}
							isDisabled={loading}
							theme='dark'
							label='Accept'
							className='notification-button'
							size='lg'
						/>
						<div
							style={{
								width: '1rem',
							}}
						/>
						<Button
							// TODO: FIX TYPE
							onClick={(e: any) => {
								stopPropagation(e);
								declineInvitation();
							}}
							isDisabled={loading}
							label='Decline'
							className='notification-button'
							size='lg'
						/>
					</div>
				);
			case NotificationTypes.ESIGNATURE_SIGNED:
			case NotificationTypes.ESIGNATURE_COMPLETE:
				const { albumId, recordingId } =
					notification.data as ESignatureNotificationBody;
				return (
					<div
						className='d-flex'
						style={{
							width: '100%',
							justifyContent: 'space-between',
						}}
					>
						<Button
							// TODO: FIX TYPE
							onClick={(e: any) => {
								stopPropagation(e);
								navigateToProjectFiles({
									albumId,
									recordingId,
									navigate,
									section: FILE_TABS.EXPORTS,
								});
							}}
							isDisabled={loading}
							theme='dark'
							label='View in Exports'
							leftIcon='fas fa-share'
							className='notification-button px-1'
							size='lg'
						/>
						<div
							style={{
								width: '1rem',
							}}
						/>
						<Button
							// TODO FIX TYPE
							onClick={(e: any) => {
								stopPropagation(e);
								markAsRead();
							}}
							isDisabled={loading}
							label='Mark as Read'
							className='notification-button px-1'
							size='lg'
						/>
					</div>
				);
			case NotificationTypes.TRACKCHAT_COMMENT:
				const { shareLinkSlug, assetFileId, versionId } =
					notification.data as TrackChatCommentNotificationBody;

				return (
					<div
						className='d-flex'
						style={{
							width: '100%',
							justifyContent: 'space-between',
						}}
					>
						<Button
							// TODO: FIX TYPE
							onClick={(e: any) => {
								stopPropagation(e);
								window.open(
									getTrackChatFileVersionLink({
										assetFileId,
										slug: shareLinkSlug,
										versionId: versionId,
									}),
									'_blank'
								);
							}}
							isDisabled={loading}
							theme='dark'
							label='View in TrackChat'
							leftIcon='fas fa-external-link-alt'
							className='notification-button px-1'
							size='lg'
						/>
						<div
							style={{
								width: '1rem',
							}}
						/>
						<Button
							// TODO FIX TYPE
							onClick={(e: any) => {
								stopPropagation(e);
								markAsRead();
							}}
							isDisabled={loading}
							label='Mark as Read'
							className='notification-button px-1'
							size='lg'
						/>
					</div>
				);
			case NotificationTypes.PLAYLIST_CREDIT_REQUEST:
				const { shareLinkId } =
					notification.data as PlaylistCreditRequestNotificationBody;

				return (
					<div
						className='d-flex'
						style={{
							width: '100%',
							justifyContent: 'space-between',
						}}
					>
						<Button
							// TODO: FIX TYPE
							onClick={(e: any) => {
								stopPropagation(e);
								navigate(
									replacePathVariables(ROUTES.PlaylistDetails.path, {
										playlistId: shareLinkId,
									})
								);
								dispatch(
									showModalAction(CREDIT_REQUESTS_MODAL, {
										playlistId: shareLinkId,
										size: 'xl',
									})
								);
							}}
							isDisabled={loading}
							theme='dark'
							label='View in Playlist'
							leftIcon='fas fa-share'
							className='notification-button px-1'
							size='lg'
						/>
						<div
							style={{
								width: '1rem',
							}}
						/>
						<Button
							// TODO FIX TYPE
							onClick={(e: any) => {
								stopPropagation(e);
								markAsRead();
							}}
							isDisabled={loading}
							label='Mark as Read'
							className='notification-button px-1'
							size='lg'
						/>
					</div>
				);

			default:
				return (
					<div
						className='notification-buttons d-flex'
						style={{
							width: '100%',
							justifyContent: 'space-around',
						}}
					>
						<Button
							label='Mark as Read'
							// TODO: FIX TYPE
							onClick={(e: any) => {
								stopPropagation(e);
								markAsRead();
							}}
							size='lg'
							theme='dark'
							isLoading={loading}
							className='notification-button'
						/>
					</div>
				);
		}
	};

	return (
		<div
			onClick={stopPropagation}
			className={`dropdown-item notification-item ${
				notification.read && 'notification-read'
			}`}
		>
			{}
			<div>
				<Row>
					<Col
						xs={12}
						className='d-flex align-items-start justify-content-between'
					>
						<i
							className={`text-purple fas ${
								notification.read ? 'fa-envelope-open' : 'fa-envelope'
							} mr-2`}
						></i>
						<p className='notification-title mb-3'>
							{notification.notificationTitle}
						</p>
						<p />
					</Col>
					<Col className='pl-3 notification-body' xs={12}>
						<p>{notification.notificationBody}</p>
						<time className='text-secondary mt-2' dateTime={localeDate}>
							{localeDate}
						</time>

						{!notification.read && (
							<div className='pt-3 w-100'>
								{loading ? (
									<div className='w-100 d-flex justify-content-center align-items-center'>
										<Spinner size='sm' variant='dark' animation='border' />
									</div>
								) : (
									renderActionButtons()
								)}
							</div>
						)}
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default Notification;
