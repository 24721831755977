import { Col, Form, Row } from 'react-bootstrap';
import './FileListItem.scss';
import React, { useMemo } from 'react';
import fileSize from 'filesize';
import theme from '../../../../theme.module.scss';
import { formatDateToStr } from '../../../utils/dateFormatters';
import { BrowserFileSelection } from '../FilesBrowser/FilesBrowser';

export type FileListItemProps<
	FileType extends FileMetadata | ProjectFileUpload | ExportMetadata
> = {
	id: FileType['id'];
	filename: string;
	size?: number;
	creationDate?: string | null;
	setSelectedFiles: React.Dispatch<
		React.SetStateAction<BrowserFileSelection<FileType['id']>>
	>;
	isSelected: boolean;
	thumbnail: React.FC;
	actions: React.FC;
	errorMessage?: string | null;
	onBodyClick?: (() => void) | React.MouseEventHandler<HTMLElement>;
	onTitleClick?: (() => void) | React.MouseEventHandler<HTMLDivElement>;
	bottomContent?: React.FC
	isClickable?: boolean;
	tag?: React.FC;
	version?: FileVersion | null;
};

const FileListItem = <
	FileType extends FileMetadata | ProjectFileUpload | ExportMetadata
>({
	id,
	filename,
	size = 0,
	creationDate = null,
	setSelectedFiles,
	isSelected,
	thumbnail: Thumbnail,
	actions: Actions,
	errorMessage = '',
	bottomContent: BottomContent,
	onBodyClick = () => {},
	onTitleClick = () => {},
	isClickable = true,
	tag: Tag,
	version = null,
}: FileListItemProps<FileType>) => {
	const finalCreationDate = useMemo(
		() =>
			version
				? new Date(version.createdAt)
				: creationDate
				? new Date(creationDate)
				: null,
		[version, creationDate]
	);

	const creationDateStr = useMemo(
		() => (finalCreationDate ? formatDateToStr(finalCreationDate) : ''),
		[finalCreationDate]
	);

	const handleSelect = () => {
		setSelectedFiles(prevSelected => {
			return { ...prevSelected, [id]: !prevSelected[id] };
		});
	};

	return (
		<Row
			className={`file-list-item-container m-0 ${
				isClickable ? 'clickable-file-list-item' : ''
			}`}
			onClick={onBodyClick}
		>
			<Col xs={Tag ? 8 : 10} className='h-100 d-flex'>
				<Form.Check
					type='checkbox'
					className='h-100 d-flex justify-content-center align-items-center mx-4'
					checked={isSelected}
					onChange={handleSelect}
					onClick={e => e.stopPropagation()}
				/>
				<div className='d-flex align-items-center justify-content-center h-100 mx-2'>
					<Thumbnail />
				</div>
				<div className='d-flex justify-content-start align-items-center h-100'>
					<div className='file-list-item-metadata ml-3'>
						<div>
							<div
								style={{
									fontSize: '0.85rem',
									cursor: isClickable ? 'pointer' : 'default',
								}}
								className='fw-600 file-list-item-name'
								onClick={onTitleClick}
							>
								{filename}
							</div>
						</div>
						{creationDate && (
							<span className='disclaimer-text' style={{ fontSize: '0.7rem' }}>
								{version && (
									<span
										className='disclaimer-text'
										style={{ fontSize: '0.7rem' }}
									>
										Version #{version.versionNo} -{' '}
									</span>
								)}
								Created {creationDateStr}
							</span>
						)}
						{size ? (
							<span className='disclaimer-text' style={{ fontSize: '0.7rem' }}>
								{fileSize(size)}
							</span>
						) : (
							<></>
						)}
						{BottomContent && <BottomContent />}
						{errorMessage ? (
							<small
								style={{
									color: theme.error,
									lineHeight: '0.8rem',
									fontSize: '0.75rem',
								}}
								className='mt-1'
							>
								{errorMessage}
							</small>
						) : (
							<></>
						)}
					</div>
				</div>
			</Col>

			<Col
				xs={Tag ? 4 : 2}
				className='d-flex justify-content-end align-items-center h-100'
			>
				{Tag && (
					<div className='mr-2'>
						<Tag />
					</div>
				)}
				<Actions />
			</Col>
		</Row>
	);
};

export default FileListItem;
