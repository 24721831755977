import ExportType from '../../constants/exportType';
import { ParticipantTableColumn } from '../../helpers/participantTools';
import {
	CLEAR_ACTIVE_EXPORT_FORM,
	CLEAR_SESSION_DATA,
	DELETE_EXPORT_FORM,
	SAVE_EXPORT_FORM,
	SET_ACTIVE_EXPORT_FORM,
	SAVE_SELECTED_COUNTRY,
	SET_VOLUME,
	SET_DETECT_TITLE_AND_ARTIST,
	SET_USER_DRIP_AGREEMENT,
	SET_MUTE,
	SET_VISIBLE_PARTICIPANT_TABLE_COLUMNS,
} from '../actionTypes';

export const saveExportFormAction = (
	exportType: ExportType, // TODO: TS MIGRATION,
	exportForm: PersistedExportFormData['form'], // TODO: TS MIGRATION,
	savedExportId: string,
	recordingId: number | null,
	albumId: number | null,
	isUpdating: boolean
) => ({
	type: SAVE_EXPORT_FORM,
	form: exportForm,
	exportType,
	id: savedExportId,
	recordingId,
	albumId,
	isUpdating,
});

export const deleteExportFormAction = (exportFormId: string) => ({
	type: DELETE_EXPORT_FORM,
	id: exportFormId,
});

export const clearSessionDataAction = () => ({
	type: CLEAR_SESSION_DATA,
});

export const setActiveExportFormAction = (id: string) => ({
	type: SET_ACTIVE_EXPORT_FORM,
	id,
});

export const clearActiveExportFormAction = () => ({
	type: CLEAR_ACTIVE_EXPORT_FORM,
});

export const saveSelectedCountry = (country: string) => ({
	type: SAVE_SELECTED_COUNTRY,
	country,
});

export const setVolumeAction = (volume: number) => ({
	type: SET_VOLUME,
	volume,
});

export const setMutedAction = (muted: boolean) => ({
	type: SET_MUTE,
	muted,
});

export const setDetectTitleAndArtistAction = (
	detectTitleAndArtist: boolean
) => ({
	type: SET_DETECT_TITLE_AND_ARTIST,
	detectTitleAndArtist,
});

export const setUserDripAgreementAction = (agreed: boolean) => ({
	type: SET_USER_DRIP_AGREEMENT,
	agreed,
});

export const setVisibleParticipantTableColumnsAction = (
	columns: ParticipantTableColumn[]
) => ({
	type: SET_VISIBLE_PARTICIPANT_TABLE_COLUMNS,
	columns,
});