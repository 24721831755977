import { isEqual } from 'lodash';
import { createSelector } from 'reselect';
import { RootState } from '..';

const exportsById = (state: RootState) => state.exports.exportsById;
const albumsById = (state: RootState) => state.projects.albumsById;
const recordingsById = (state: RootState) => state.projects.recordingsById;

export const getExportsByProjectId = createSelector(
	[
		exportsById,
		recordingsById,
		albumsById,
		(_, { albumId, recordingId }) => ({ albumId, recordingId }),
	],
	(exportsById, recordingsById, albumsById, { albumId, recordingId }) => {
		if (recordingId) {
			if (!recordingsById) return [];
			return recordingsById[recordingId]
				? recordingsById[recordingId].exports
						.map(id => exportsById[id])
						.filter((e): e is ExportMetadata => !!e)
				: [];
		} else if (albumId) {
			if (!albumsById) return [];
			return albumsById[albumId]
				? albumsById[albumId].exports
						.map(id => exportsById[id])
						.filter((e): e is ExportMetadata => !!e)
				: [];
		}

		return [];
	},
	{
		memoizeOptions: {
			resultEqualityCheck: isEqual,
			equalityCheck: isEqual,
			maxSize: 1,
		},
	}
);
