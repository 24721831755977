import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Col, Modal, Row, Form as BSForm } from 'react-bootstrap';
import {
	hideModal,
	setModalTitle,
	showModalAction,
} from '../../../../../store/modal/actions';
import TextField from '../../../../form/TextField';
import Button from '../../../../layout/Button';
import * as yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import {
	editPlaylistDetailsAction,
	uploadPlaylistImageAction,
	addPlaylistCoverImageAction,
	fetchPlaylistAction,
} from '../../../../../store/playlists/actions';
import './EditPlaylistDetailsModal.scss';
import PlaylistImage from '../PlaylistDetailsHeader/PlaylistImage/PlaylistImage';
import {
	DELETE_MODAL,
	IMAGE_CROP_MODAL,
} from '../../../../../constants/modalTypes';
import { COVER_IMAGE_ASPECT_RATIO } from '../../../../../helpers/fileTools';
import { IMAGE_FILE_TYPES } from '../../../../form/ImageCropInput/ImageCropInput';
import PillButton from '../../../../layout/PillButton/PillButton';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';

const schema = yup.object().shape({
	name: yup.string().required('Required'),
	artist: yup.string(),
	description: yup.string(),
});

export type EditPlaylistDetailsModalProps = {
	playlistId: Playlist['id'];
};

export type EditPlaylistDetailsForm = {
	name: string;
	artist: string;
	description: string;
};

const EditPlaylistDetailsModal = ({
	playlistId,
}: EditPlaylistDetailsModalProps) => {
	const dispatch = useAppDispatch();
	const { playlistsById } = useAppSelector(state => state.playlists);
	const [isLoading, setIsLoading] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const fetched = useRef(false);

	const playlist = useMemo(
		() => playlistsById?.[playlistId],
		[playlistsById, playlistId]
	);

	const playlistName = useMemo(() => playlist?.name ?? '', [playlist]);

	const handleClose = () => {
		dispatch(hideModal());
	};

	const handleEdit = async (detailsForm: EditPlaylistDetailsForm) => {
		setIsLoading(true);
		try {
			await Promise.resolve(
				dispatch(editPlaylistDetailsAction(playlistId, detailsForm))
			);
		} finally {
			setIsLoading(false);
			handleClose();
		}
	};

	const handleEditCoverImage = () => {
		dispatch(
			showModalAction(IMAGE_CROP_MODAL, {
				size: 'lg',
				aspectRatio: COVER_IMAGE_ASPECT_RATIO,
				onSave: (image: File) =>
					dispatch(uploadPlaylistImageAction(playlistId, image, false)),
				title: `Edit ${playlistName} Cover Image`,
				outputType: IMAGE_FILE_TYPES.blob,
			})
		);
	};

	const handleDeleteCoverImage = useCallback(async () => {
		dispatch(
			showModalAction(DELETE_MODAL, {
				title: 'Delete Playlist Cover Image',
				body: "Are you sure you want to delete this playlist's cover image?",
				onDelete: async () => {
					await dispatch(addPlaylistCoverImageAction(playlistId, null));
				},
				size: 'md',
			})
		);
	}, [dispatch, playlistId]);

	const formik = useFormik({
		initialValues: {
			name: playlistName,
			artist: playlist?.playlist?.artist ?? '',
			description: playlist?.playlist?.description ?? '',
		},
		validationSchema: schema,
		enableReinitialize: true,
		onSubmit: handleEdit,
		validateOnMount: true,
	});

	useEffect(() => {
		dispatch(setModalTitle('EDIT PLAYLIST'));
	}, [dispatch]);

	useEffect(() => {
		if (fetched.current || isFetching) return;

		fetched.current = true;
		setIsFetching(true);

		dispatch(fetchPlaylistAction(playlistId)).finally(() => {
			setIsFetching(false);
		});
	}, [dispatch, isFetching, playlist, playlistId]);

	return (
		<FormikProvider value={formik}>
			<Form placeholder={null} onSubmit={formik.handleSubmit}>
				<Modal.Body>
					<Row className='px-4'>
						<Col
							xs={12}
							lg={5}
							className='px-2 pb-2 edit-playlist-details-modal__cover-image w-100'
						>
							<div>
								<BSForm.Label className='mt-2'>Cover Image</BSForm.Label>

								<PlaylistImage
									onClickToUpload={handleEditCoverImage}
									src={playlist?.playlist?.coverUrl}
									forceLoadingPlaceholder={!playlist?.playlist}
								/>

								{playlist?.playlist?.coverUrl && (
									<div className='d-flex justify-content-center'>
										<PillButton
											theme='outline-danger'
											className='mt-2'
											onClick={handleDeleteCoverImage}
											label='Remove'
											leftIcon='fas fa-trash'
										/>
									</div>
								)}
							</div>
						</Col>
						<Col xs={12} lg={7} className='px-2'>
							<TextField
								name='name'
								label='Playlist Name'
								placeholder='Enter Playlist Name'
								value={formik?.values?.name}
								className='mb-3'
								errorMessage={formik?.errors?.name?.toString()}
								isInvalid={formik?.touched?.name && !!formik?.errors?.name}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								showRequiredAsterisk
							/>

							<TextField
								name='artist'
								label='Artist'
								placeholder='Enter Artist'
								value={formik?.values?.artist}
								className='mb-3'
								errorMessage={formik?.errors?.artist?.toString()}
								isInvalid={formik?.touched?.artist && !!formik?.errors?.artist}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
							/>

							<TextField
								as='textarea'
								name='description'
								label='Description'
								placeholder='Enter Description'
								value={formik?.values?.description}
								className='mb-3'
								errorMessage={formik?.errors?.description?.toString()}
								isInvalid={
									formik?.touched?.description && !!formik?.errors?.description
								}
								onBlur={formik.handleBlur}
								onChange={formik.handleChange}
								maxLength={300}
								showMaxLength
							/>
						</Col>

						{/* <Col
							md={12}
							lg={9}
							className='px-4 edit-playlist-details-modal__header-image'
						>
							<BSForm.Label className='mt-2 d-flex align-items-center'>
								Header Image
							
							</BSForm.Label>
							<PlaylistImage
								onClickToUpload={handleEditHeaderImage}
								src={playlist?.playlist?.headerUrl}
							/>

							{playlist?.playlist?.headerUrl && (
								<PillButton
									theme='outline-danger'
									className='mt-2'
									onClick={handleDeleteHeaderImage}
									label='Remove'
									leftIcon='fas fa-trash'
								/>
							)}
						</Col> */}
						{/* <Col xs={12}>image input</Col> next iteration */}
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<div className='d-flex justify-content-end'>
						<Button label='Cancel' className='mr-2' onClick={handleClose} />
						<Button
							label='Save'
							theme='dark'
							onClick={formik.handleSubmit as any}
							type='submit'
							isLoading={isLoading}
						/>
					</div>
				</Modal.Footer>
			</Form>
		</FormikProvider>
	);
};

export default EditPlaylistDetailsModal;
