import { FormikProvider, useFormik } from 'formik';
import React, { useEffect, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { sendPlaylistEmail } from '../../../../../api/services/filesService';
import * as yup from 'yup';
import TextField from '../../../../form/TextField';
import Button from '../../../../layout/Button';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
	hideAllModalsAction,
	hideModal,
	setModalTitle,
	showModalAction,
} from '../../../../../store/modal/actions';
import confetti from 'canvas-confetti';
import { getCurrentPlaylist } from '../../../../../store/playlists/selectors';
import { computeLocalPlaylistAccessControl } from '../../../../../helpers/playlistTools';
import PlaylistAccessControlType from '../../../../../constants/playlistAccessControl';
import { CONFIRMATION_MODAL } from '../../../../../constants/modalTypes';
import { setPlaylistAccessControlAction } from '../../../../../store/playlists/actions';

type SendPlaylistLinkModalProps = {
	playlistId: Playlist['id'];
	isOnboarding?: boolean;
};

const validationSchema = yup.object().shape({
	recipientEmail: yup.string().email('Invalid email').required('Required'),
});

const SendPlaylistLinkModal = ({
	playlistId,
	isOnboarding = false,
}: SendPlaylistLinkModalProps) => {
	const dispatch = useAppDispatch();

	const currentPlaylist = useAppSelector(getCurrentPlaylist);
	const { userEmail } = useAppSelector(state => state.auth);
	const accessControlType = useMemo(
		() => computeLocalPlaylistAccessControl(currentPlaylist?.playlist),
		[currentPlaylist]
	);

	const handleSendPlaylistLink = async (values: { recipientEmail: string }) => {
		try {
			await sendPlaylistEmail({
				playlistId: playlistId,
				recipientEmail: values.recipientEmail,
			});

			if (isOnboarding) {
				confetti({
					particleCount: 100,
					spread: 70,
					origin: { y: 0.6 },
				});
			}
		} catch (error) {
			console.error(error);
		} finally {
			dispatch(hideModal());
		}
	};

	const formik = useFormik({
		initialValues: {
			recipientEmail: isOnboarding ? userEmail : '',
		},
		onSubmit: async values => {
			if (accessControlType === PlaylistAccessControlType.PRIVATE) {
				dispatch(
					showModalAction(CONFIRMATION_MODAL, {
						size: 'md',
						title: 'Hidden Playlist',
						description:
							'This playlist is currently hidden to everyone. If you send it to someone, it will become visible to anyone with the link.',
						confirmAction: {
							label: 'Send Anyway',
							icon: 'fas fa-paper-plane',
							onClick: async () => {
								await dispatch(
									setPlaylistAccessControlAction({
										playlistId,
										accessControlType: PlaylistAccessControlType.PUBLIC,
										allowDownloads: currentPlaylist!.playlist!.allowDownloads,
										expandVersions: currentPlaylist!.playlist!.expandVersions,
										hideTrackchatLinks:
											currentPlaylist!.playlist!.hideTrackchatLinks,
										enableCreditRequests:
											currentPlaylist!.playlist!.enableCreditRequests,
									})
								);

								await handleSendPlaylistLink(values);

								dispatch(hideAllModalsAction());
							},
						},
						cancelAction: {
							label: 'Cancel',
							onClick: () => {
								dispatch(hideModal());
							},
						},
					})
				);
			} else {
				await handleSendPlaylistLink(values);
			}
		},
		validationSchema,
	});

	useEffect(() => {
		dispatch(setModalTitle('Send Playlist Link via Email'));
	}, [dispatch]);

	return (
		<FormikProvider value={formik}>
			<Modal.Body>
				<div className='px-4'>
					{isOnboarding && (
						<p>Try sending a playlist to yourself to see how it works!</p>
					)}
					<TextField
						name='recipientEmail'
						label='Recipient Email'
						value={formik.values.recipientEmail}
						onChange={formik.handleChange}
						placeholder='Enter Recipient Email'
						onBlur={formik.handleBlur}
						errorMessage={formik.errors.recipientEmail}
						isInvalid={
							!!(formik.touched.recipientEmail && formik.errors.recipientEmail)
						}
					/>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button onClick={() => dispatch(hideModal())} label='Cancel' />
				<Button
					onClick={() => formik.handleSubmit()}
					label='Send'
					theme='dark'
					leftIcon='fas fa-paper-plane'
					isLoading={formik.isSubmitting}
				/>
			</Modal.Footer>
		</FormikProvider>
	);
};

export default SendPlaylistLinkModal;
