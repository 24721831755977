import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
	Breadcrumb,
	Col,
	Container,
	Row,
	Button as BootStrapButton,
	Card,
} from 'react-bootstrap';
import WaveSurfer from 'wavesurfer.js';

import WaveSurferCursor from 'wavesurfer.js/dist/plugin/wavesurfer.cursor.js';

import { getCurrentRecording } from '../../../../store/projects/selectors';

import IconButton from '../../../layout/IconButton';
import Button from '../../../layout/Button';

import {
	export2Lrc,
	export2Srt,
	export2Ttml,
} from '../../../../helpers/syncLyricGenerator';

import theme from '../../../../theme.module.scss';

import './AudioPreview.scss';
import './SyncedLyrics.scss';
import FileDragAndDropInput from '../../../layout/FileDragAndDropInput';
import { PREVIEWABLE_AUDIO_FORMATS } from '../../../../helpers/fileTools';
import ROUTES from '../../../../router/routes';
import { useAppSelector } from '../../../../store/hooks';
import { Helmet } from 'react-helmet';
import {
	convertFileEntryToFile,
	traverse_directory,
} from '@/helpers/fileTools';

const PLAYING_STATUS = {
	NOT_STARTED: 1,
	PLAYING: 2,
	PAUSED: 3,
	FINISHED: 4,
};

const STEPS = {
	CHOOSE_FILE: 0,
	SYNC_LYRICS: 1,
	EXPORT: 2,
};

const FILE = {
	TTML: 0,
	SRT: 1,
	LRC: 2,
};

const LYRICS_PADDING = 4;

const initializeSyncLyrics = (lyrics = []) => ({
	status: PLAYING_STATUS.NOT_STARTED,
	ready: false,
	active: false,
	current: 0,
	lyrics: lyrics,
});

const SPACE_KEY_CODE = 32;
const DELETE_KEY_CODE = 46;
const BACKSPACE_KEY_CODE = 8;
const ESCAPE_KEY_CODE = 27;

const SyncLyricsStep = ({
	syncLyrics,
	setSyncLyrics,
	setSavedSyncLyrics,
	audioFile,
	onSubmit,
	onBack,
}) => {
	const syncLyricsRef = useRef();

	useMemo(() => (syncLyricsRef.current = syncLyrics), [syncLyrics]);

	const waveformRef = useRef(null);

	const currentRecording = useSelector(getCurrentRecording);

	const lyrics = useMemo(
		() => currentRecording?.recording?.lyrics.split('\n'),
		[currentRecording]
	);

	const [isLoading, setIsLoading] = useState(true);
	const [loadingProgress, setLoadingProgress] = useState(0);
	const { volume: savedVolume } = useSelector(state => state.session);

	const [volume] = useState([savedVolume ?? 100]);

	const buttonRef = useRef();

	const audioFileUrl = useMemo(
		() => URL.createObjectURL(audioFile),
		[audioFile]
	);

	const isPlaying = useMemo(() => {
		return syncLyrics.status === PLAYING_STATUS.PLAYING;
	}, [syncLyrics]);

	useEffect(() => {
		// if (syncLyrics.status === PLAYING_STATUS.FINISHED) return;

		const track = document.querySelector('#audio-preview-track');

		waveformRef.current = WaveSurfer.create({
			container: '#audio-preview-waveform',
			barWidth: 2,
			height: 100,
			waveColor: '#c1c1c1',
			progressColor: theme.primary,
			responsive: true,
			plugins: [
				WaveSurferCursor.create({
					showTime: true,
					opacity: 1,
					customShowTimeStyle: {
						'background-color': 'rgba(0, 0, 0, 0.8)',
						color: '#fff',
						padding: '0.2rem',
						'font-size': '0.8rem',
					},
				}),
			],
		});

		waveformRef?.current?.load(track);

		waveformRef?.current?.on('ready', () => {
			setIsLoading(false);

			waveformRef?.current?.setVolume(volume[0] / 100);
		});

		waveformRef?.current?.on('loading', progress => {
			setLoadingProgress(progress);
		});

		const updateTime = () => {};

		waveformRef?.current?.on('audioprocess', updateTime);

		waveformRef?.current?.on('seek', updateTime);

		waveformRef?.current?.on('finish', () => {
			setSyncLyrics(prev => ({ ...prev, status: PLAYING_STATUS.FINISHED }));
		});

		waveformRef?.current?.on('error', error => {
			console.error(error);
		});

		waveformRef?.current?.on('destroy', () => {
			track.pause();
			track.currentTime = 0;
		});
	}, [audioFile]); //eslint-disable-line

	const handleReset = () => {
		setSyncLyrics(initializeSyncLyrics(lyrics));

		waveformRef?.current?.stop();
	};

	const handlePlay = () => {
		setSyncLyrics(prev => ({
			...prev,
			status:
				prev.status === PLAYING_STATUS.PLAYING
					? PLAYING_STATUS.PAUSED
					: PLAYING_STATUS.PLAYING,
		}));
		waveformRef?.current?.playPause();
		buttonRef?.current?.blur();
	};

	const handleSpaceDownHandler = e => {
		if (
			e.keyCode !== SPACE_KEY_CODE ||
			syncLyricsRef?.current?.status === PLAYING_STATUS.NOT_STARTED ||
			syncLyricsRef?.current?.status === PLAYING_STATUS.FINISHED ||
			syncLyricsRef?.current?.current >=
				syncLyricsRef?.current?.lyrics?.length ||
			syncLyricsRef?.current?.active
		) {
			return;
		}

		if (syncLyricsRef?.current?.status === PLAYING_STATUS.PAUSED) {
			setSyncLyrics(prev => ({
				...prev,
				status: PLAYING_STATUS.PLAYING,
			}));
			waveformRef?.current?.playPause();
		}

		setSyncLyrics(prev => ({
			...prev,
			active: true,
		}));

		setSavedSyncLyrics(prev => {
			const current = syncLyricsRef?.current?.current;
			prev[current] = {
				startTime: waveformRef?.current?.getCurrentTime(),
				lyric: syncLyricsRef?.current?.lyrics[syncLyricsRef?.current?.current],
			};
			return prev;
		});
	};

	const handleSpaceUpHandler = e => {
		if (
			e.keyCode === BACKSPACE_KEY_CODE ||
			e.keyCode === DELETE_KEY_CODE ||
			e.keyCode === ESCAPE_KEY_CODE
		) {
			if (syncLyricsRef?.current?.active) {
				setSyncLyrics(prev => ({
					...prev,
					active: false,
				}));
			}

			return;
		}

		if (e.keyCode !== SPACE_KEY_CODE || !syncLyricsRef?.current?.active) {
			return;
		}

		// If the last lyric is being synced, finish the sync
		if (
			syncLyricsRef?.current?.current ===
			syncLyricsRef?.current?.lyrics.length - 1
		) {
			setSyncLyrics(prev => ({
				...prev,
				active: false,
				status: PLAYING_STATUS.FINISHED,
			}));
			waveformRef?.current?.playPause();
		}

		setSavedSyncLyrics(prev => {
			const current = syncLyricsRef?.current?.current;
			prev[current] = {
				...prev[current],
				endTime: waveformRef?.current?.getCurrentTime(),
			};
			return prev;
		});

		setSyncLyrics(prev => ({
			...prev,
			current: prev.current + 1,
			active: false,
		}));
	};

	useEffect(() => {
		document.addEventListener('keydown', handleSpaceDownHandler);
		document.addEventListener('keyup', handleSpaceUpHandler);

		return () => {
			document.removeEventListener('keydown', handleSpaceDownHandler);
			document.removeEventListener('keyup', handleSpaceUpHandler);

			if (waveformRef.current) {
				waveformRef?.current?.destroy();
			}
		};
	}, []); //eslint-disable-line

	useEffect(() => {
		if (syncLyrics.status === PLAYING_STATUS.NOT_STARTED) {
			handleReset();
		}
	}, [lyrics]); //eslint-disable-line

	return (
		<>
			<Row>
				<Col xs={7}>
					<div
						style={{
							width: '70vw',
							borderRadius: 5,
							position: 'relative',
						}}
						className={'audio-preview'}
					>
						<div className='d-flex justify-content-center align-items-center p-3'>
							<PlayButton
								isPlaying={isPlaying}
								onClick={handlePlay}
								isLoading={isLoading}
								ref={buttonRef}
								className='mx-2'
							/>

							<div style={{ width: '80vw' }} id='audio-preview-waveform' />
							<audio id='audio-preview-track' src={audioFileUrl} />
						</div>
						{isLoading && (
							<progress
								max={100}
								value={loadingProgress}
								className='w-100 progress-light'
								style={{ position: 'absolute' }}
							/>
						)}
					</div>
				</Col>
				<Col
					xs={5}
					className='d-flex flex-column justify-content-center text-right sync-explanation'
				>
					<p>
						<strong>Hold SPACEBAR down</strong> when the line begins
					</p>
					<p>
						<strong>Release SPACEBAR</strong> when the line is finished
					</p>
					<p>
						<strong>Press BACKSPACE, DELETE or ESCAPE</strong> to undo a
						spacebar press.
					</p>
					<p>
						<strong> Click Reset</strong> if a mistake is made
					</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<p style={{ color: theme.primary }} className='fw-bold mt-3'>
						Click Play to Begin
					</p>
				</Col>
			</Row>
			<Row>
				<Col>
					<div
						style={{ border: '1px solid #ced4da' }}
						className='position-relative d-flex flex-column text-center justify-content-center align-items-center rounded lyrics-container '
					>
						<div className='position-absolute w-100 h-100 top-0 start-0 lyric-mask-container'></div>
						{Array(LYRICS_PADDING * 2 + 1)
							.fill(0)
							.map((_, ind) => (
								<p
									className={
										ind === LYRICS_PADDING
											? syncLyrics.active
												? 'active'
												: 'ready'
											: ''
									}
									key={`lyric-${ind}`}
								>
									{syncLyrics.lyrics?.[
										syncLyrics.current + ind - LYRICS_PADDING
									] || <>&nbsp;</>}
								</p>
							))}
					</div>
				</Col>
				<Col
					xs={12}
					className='d-flex justify-content-between align-items-center w-100 mt-3'
				>
					<Button
						label='Reset'
						theme='dark'
						className='mr-2'
						onClick={() => {
							handleReset();
						}}
					/>

					<div className='d-flex align-items-center'>
						<Button label='Back' className='mr-2' onClick={onBack} />
						<Button
							label='Export'
							theme='dark'
							onClick={() => {
								onSubmit();
							}}
							isDisabled={syncLyrics.status !== PLAYING_STATUS.FINISHED}
						/>
					</div>
				</Col>
			</Row>
		</>
	);
};

const ChooseFileStep = ({ audioFile, setAudioFile, onSubmit }) => {
	const [inputFile, setInputFile] = useState(null);

	const handleInputChange = file => setInputFile(file);

	const handleDropzoneInputChange = useCallback(async acceptedFiles => {
		let individualFiles = [];

		let webkitItems = acceptedFiles;
		let directories = {};

		const promises = [];

		const folderFilesDict = {};

		// First, we take a look at all the files/folders uploaded
		// We traverse each folder received in drag and drop/select
		let directoriesCount = 0;

		for (var i = 0; i < webkitItems.length; i++) {
			if (webkitItems[i].isDirectory) {
				let folderFiles = [];
				console.log('Webkit Dir:', webkitItems[i]);
				const key = webkitItems[i].name;
				directories[key] = [];
				directoriesCount++;

				const promise = traverse_directory(
					webkitItems[i],
					directories,
					key,
					folderFiles
				).then(items => {
					// AT THIS POINT THE DIRECTORY SHOULD BE FULLY TRAVERSED.

					folderFilesDict[key] = folderFiles;
					// return result;
				});
				promises.push(promise);
			} else if (webkitItems[i].isFile) {
				const file = await convertFileEntryToFile(webkitItems[i]);
				individualFiles.push(file);
			}
		}

		if (directoriesCount === 0 && individualFiles.length === 0) {
			individualFiles = acceptedFiles;
		}

		await Promise.all(promises);

		const folderFiles = Object.values(folderFilesDict).flat();

		const allFilesList = [...individualFiles, ...folderFiles];

		const dataTransfer = new DataTransfer();
		allFilesList.forEach(file => dataTransfer.items.add(file));
		// const files = await onSubmit(dataTransfer.files);
		setInputFile(allFilesList[0]);
	}, []);

	const navigate = useNavigate();

	const noFileSelected = useMemo(
		() => !inputFile && !audioFile,
		[inputFile, audioFile]
	);

	const handleSubmit = () => {
		if (noFileSelected) return;

		if (!audioFile || (inputFile && inputFile !== audioFile)) {
			setAudioFile(inputFile);
		}

		onSubmit();
	};

	// choose a file from the user's computer or import from a project
	return (
		<>
			<Card className='p-4'>
				<div
					style={{
						width: '100%',
					}}
				>
					<h2>SELECT THE SONG'S FILE TO START SYNCING LYRICS</h2>
					<FileDragAndDropInput
						types={PREVIEWABLE_AUDIO_FORMATS}
						onSelect={handleDropzoneInputChange}
						onDrop={handleInputChange}
						inputFiles={inputFile}
						multiple={false}
					/>
				</div>
				{(audioFile || inputFile) && (
					<div className='d-flex mt-4 align-items-center'>
						<i className='fas fa-file mr-2'></i>
						<span className='fw-bold mr-2'>Selected File:</span>
						<span>{(inputFile || audioFile).name}</span>
					</div>
				)}
			</Card>

			<div className='d-flex align-items-center justify-content-end mt-2'>
				<Button label='Back' onClick={() => navigate(-1)} className='mr-2' />
				<Button
					label='Continue'
					isDisabled={noFileSelected}
					onClick={handleSubmit}
					theme='dark'
				/>
			</div>
		</>
	);
};

const ExportStep = ({ savedSyncLyrics, onBack }) => {
	const exportData = type => {
		if (type === FILE.TTML) export2Ttml(savedSyncLyrics);
		else if (type === FILE.SRT) export2Srt(savedSyncLyrics);
		else if (type === FILE.LRC) export2Lrc(savedSyncLyrics);
	};

	const navigate = useNavigate();

	return (
		<div>
			<Card>
				<div className='lyric-download'>
					<p>
						<BootStrapButton
							variant='link'
							onClick={() => exportData(FILE.TTML)}
						>
							Download TTML file
						</BootStrapButton>
						- Recommended for iTunes & YouTube
					</p>
					<p>
						<BootStrapButton
							variant='link'
							onClick={() => exportData(FILE.SRT)}
						>
							Download SRT file
						</BootStrapButton>
						- Recommended for Facebook videos
					</p>
					<p>
						<BootStrapButton
							variant='link'
							onClick={() => exportData(FILE.LRC)}
						>
							Download LRC file
						</BootStrapButton>
						- Supported by some karaoke systems
					</p>
				</div>
			</Card>

			<div className='d-flex align-items-center justify-content-end mt-2'>
				<Button label='Back' onClick={onBack} className='mr-2' />
				<Button
					label='Return to Lyrics'
					onClick={() => navigate(`../${ROUTES.EditLyrics.relativePath}`)}
					theme='dark'
				/>
			</div>
		</div>
	);
};

const SyncLyricsStepper = () => {
	const currentRecording = useAppSelector(getCurrentRecording);
	const [step, setStep] = useState(STEPS.CHOOSE_FILE);
	const [syncLyrics, setSyncLyrics] = useState(initializeSyncLyrics());
	const [audioFile, setAudioFile] = useState(null);
	const [savedSyncLyrics, setSavedSyncLyrics] = useState([]);

	const navigate = useNavigate();

	const setNextStep = () => {
		setStep(prevStep => prevStep + 1);
	};

	const setPrevStep = () => {
		setStep(prevStep => prevStep - 1);
	};

	const renderStep = useCallback(() => {
		switch (step) {
			case STEPS.CHOOSE_FILE:
				return (
					<ChooseFileStep
						audioFile={audioFile}
						setAudioFile={setAudioFile}
						onSubmit={setNextStep}
					/>
				);
			case STEPS.SYNC_LYRICS:
				return (
					<SyncLyricsStep
						syncLyrics={syncLyrics}
						setSyncLyrics={setSyncLyrics}
						setSavedSyncLyrics={setSavedSyncLyrics}
						audioFile={audioFile}
						onSubmit={setNextStep}
						onBack={setPrevStep}
					/>
				);
			case STEPS.EXPORT:
				return (
					<ExportStep savedSyncLyrics={savedSyncLyrics} onBack={setPrevStep} />
				);
			default:
				return <></>;
		}
	}, [syncLyrics, savedSyncLyrics, step, audioFile]);

	return (
		<>
			<Helmet>
				<title>
					{currentRecording?.title ?? ''} - Synced Lyrics{' '}
					{process.env.REACT_APP_TAB_TITLE}
				</title>
			</Helmet>
			<Container className='h-100 d-flex flex-column'>
				<Row>
					<Col xs={12} sm={8}>
						<Breadcrumb className='pt-4'>
							<Breadcrumb.Item onClick={() => navigate(-1)}>
								LYRICS
							</Breadcrumb.Item>
							<Breadcrumb.Item active>SYNCED LYRICS</Breadcrumb.Item>
						</Breadcrumb>
					</Col>
				</Row>
				<div
					className={`sync-lyrics ${
						syncLyrics.status === PLAYING_STATUS.FINISHED ? 'done' : ''
					} d-flex flex-column flex-grow-1`}
				>
					{renderStep()}
				</div>
			</Container>
		</>
	);
};

const PlayButton = React.forwardRef(
	({ isPlaying, onClick, isLoading = false, className = '' }, ref) => {
		return (
			<IconButton
				icon={isPlaying ? 'fas fa-pause' : 'fas fa-play'}
				onClick={onClick}
				tooltipText={isPlaying ? 'Pause' : 'Play'}
				color='white'
				backgroundColor={theme.primary}
				iconSize='1.5rem'
				isDisabled={isLoading}
				style={{
					width: '3.5rem',
					height: '3.5rem',
				}}
				className={className}
				ref={ref}
			/>
		);
	}
);

export default SyncLyricsStepper;
