import { codesApi } from '../codesApi';
import { getCloudRecordingById } from './projectsService';
import { checkInvalidToken } from '../../helpers/checkInvalidToken';
import { getAlbumRecordingIds } from '../../helpers/albumTools';
import axiosRetry from 'axios-retry';
import {
	createCodeResourceGroup,
	translateApiGridCodes,
	translateApiIsrcCodes,
} from '../../helpers/codeTools';
import { shouldRetry } from '../../helpers/networkTools';

axiosRetry(codesApi, {
	retries: 3,
	retryDelay: retryCount => {
		return retryCount * 1000;
	},
	shouldResetTimeout: true,
	retryCondition: shouldRetry,
});

export const generateISRC = async (
	recording: RecordingContent,
	userId: number
) => {
	console.log('recording on generateISRC:', recording);

	let durationValues = recording.duration.split(':');

	durationValues = durationValues.map(str => {
		if (str.length === 1) {
			str = '0' + str;
			console.log('FLAG', str);
		}

		return str;
	});

	const formattedDuration = `${durationValues[0]}:${durationValues[1]}:${durationValues[2]}`;

	console.log('formattedDuration: ', formattedDuration);
	console.log('durationValues: ', durationValues);

	try {
		return await codesApi.post('/isrc/batch', {
			recording_id: createCodeResourceGroup({
				userId,
				recording,
				isSequenced: false,
				sequenceNumber: null,
			}),
		});
	} catch (err) {
		checkInvalidToken(err);
		return Promise.reject(err);
	}
};

export const generateGRID = async ({
	album,
	userId,
}: {
	album: Album;
	userId: number;
}) => {
	try {
		if (!album.isSingle) {
			const recordingsToFetch = getAlbumRecordingIds(album).map(recordingId =>
				getCloudRecordingById(recordingId)
			);
			const res = await Promise.all(recordingsToFetch);
			const albumRecordings = res.map(recording => recording.recording!);

			const resourceGroups = [
				albumRecordings.reduce((acc, recording, index) => {
					acc[recording.id!] = createCodeResourceGroup({
						userId,
						recording,
						isSequenced: true,
						sequenceNumber: index + 1,
					});
					return acc;
				}, {} as Record<string, CodeResourceGroup>),
			];

			return codesApi.post('/grid', {
				id: null,
				identifier: null,
				title: album.title, // notnull
				version: album.album?.version || null,
				main_artist: album.artist,
				resource_context: null,
				issuer_name: 'Soundways, Inc.', // notnull
				authorizing_user: userId,
				sequenced_release: true, // notnull
				sequenced_resources: {
					resource_groups: resourceGroups,
				}, // effectively notnull
				unsequenced_resources: null,
			});
		} else {
			// Is a single recording, AKA single release.
			const fetchedRecording = (
				await getCloudRecordingById(getAlbumRecordingIds(album)[0])
			).recording!;

			// return fetchedRecording
			return codesApi.post('/grid', {
				id: null,
				identifier: null,
				title: fetchedRecording.title, // notnull
				version: fetchedRecording.version || null,
				main_artist: fetchedRecording.mainArtist,
				resource_context: null,
				issuer_name: 'Soundways, Inc.', // notnull
				authorizing_user: userId,
				sequenced_release: true, // notnull
				sequenced_resources: {
					resource_groups: [
						{
							recording_id: createCodeResourceGroup({
								recording: fetchedRecording,
								userId,
								isSequenced: true,
								sequenceNumber: 1,
							}),
						},
					],
				}, // effectively notnull
				unsequenced_resources: null,
			});
		}
	} catch (e) {
		console.log(e);
		checkInvalidToken(e);

		return Promise.reject(e);
	}
};

export const getUserGridCodes = () =>
	codesApi.get('/grid').then(res => translateApiGridCodes(res.data?.codes));

export const getUserIsrcCodes = () =>
	codesApi.get('/isrc').then(res => translateApiIsrcCodes(res.data?.codes));
