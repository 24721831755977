import React, { useCallback } from 'react';
import FileListItem from '../../FileListItem';
import FileUploadItemActions from '../../../../layout/FileUploadMenu/FileUploadItem/FileUploadItemActions';
import FileUploadItemProgress from '../../../../layout/FileUploadMenu/FileUploadItem/FileUploadItemProgress';
import { BrowserFileSelection } from '../../FilesBrowser/FilesBrowser';

export type ProjectUploadListItemProps = {
	file: ProjectFileUpload;
	setSelectedFiles: React.Dispatch<
		React.SetStateAction<BrowserFileSelection<ProjectFileUpload['id']>>
	>;
	isSelected: boolean;
};

const ProjectUploadListItem = ({
	file: upload,
	setSelectedFiles,
	isSelected,
}: ProjectUploadListItemProps) => {
	const renderActions = useCallback(
		() => <FileUploadItemActions upload={upload} showViewInFolder={false} />,
		[upload]
	);

	const renderThumbnail = useCallback(
		() => <FileUploadItemProgress upload={upload} progressBarWidth='70%' />,
		[upload]
	);

	return (
		<FileListItem
			id={upload.id}
			filename={upload.metadata.filename}
			isSelected={isSelected}
			size={upload.metadata.fileSize}
			setSelectedFiles={setSelectedFiles}
			actions={renderActions}
			thumbnail={renderThumbnail}
			errorMessage={upload.errorMessage}
			isClickable={false}
		/>
	);
};

export default ProjectUploadListItem;
