import React, { useMemo } from 'react';
import { Col, Row, Card } from 'react-bootstrap';
import Button from '../../../layout/Button';
import SearchTable from '../../../layout/SearchTable';
import { useAppSelector } from '../../../../store/hooks';
import { Row as RowType } from 'react-table';

const SelectParticipantsForExport = () => {
	const { exportPayload, exportParams } = useAppSelector(
		state => state.exports
	);

	const [selectedParticipants, setSelectedParticipants] = React.useState<
		Participant[]
	>([]);

	const {
		columns,
		participantFilter = () => true,
		onSubmit,
		onCancel,
		onSelect,
		title,
		isDisabled: isInvalid,
		singleSelect = false,
		rowMapper = (participantRow: RowType<Participant>) => participantRow.original,
		getValidationMessage,
	} = useMemo(() => {
		console.log(exportParams);
		return exportParams as {
			columns: any[];
			participantFilter?: (participant: Participant) => boolean;
			onSubmit: (form: any) => void;
			onCancel: () => void;
			onSelect?: (participants: Participant[]) => void;
			title: string;
			isDisabled: (participant: any) => boolean;
			singleSelect?: boolean;
			rowMapper?: (participant: Participant) => any;
			getValidationMessage?: (form: any) => string;
		};
	}, [exportParams]);

	const participants = useMemo(
		() =>
			exportPayload.recordings[0].participants.filter(
				participantFilter ?? (() => true)
			),
		[exportPayload, participantFilter]
	);

	const memoizedColumns = useMemo(() => columns, [columns]);

	return (
		<div className='container-horizontal-padding mt-4'>
			<Card>
				<Card.Body>
					<h2>{title}</h2>
					<div className='p-4'>
						<SearchTable
							columns={memoizedColumns}
							data={participants}
							onSelect={(participants: Participant[]) => {
								console.log('select', participants);
								setSelectedParticipants(participants);
								onSelect?.(participants);
							}}
							rowMapper={rowMapper}
							singleSelect={singleSelect}
						/>
					</div>
				</Card.Body>
			</Card>
			<Row>
				<Col xs={12} className='d-flex justify-content-end align-items-center'>
					<Button label='Cancel' onClick={onCancel} className='mr-2' />
					<Button
						label='Continue'
						onClick={() => onSubmit(selectedParticipants)}
						isDisabled={isInvalid(selectedParticipants)}
						theme='dark'
					/>
				</Col>
				{isInvalid(selectedParticipants) &&
					getValidationMessage?.(selectedParticipants) && (
						<Col
							xs={12}
							className='d-flex justify-content-end align-items-center'
						>
							<p className='text-danger mt-1' style={{
								fontSize: '0.8rem',
								fontWeight: 500,
							}}>
								{getValidationMessage(selectedParticipants)}
							</p>
						</Col>
					)}
			</Row>
		</div>
	);
};

export default SelectParticipantsForExport;
