import humanizeDuration from 'humanize-duration';

export const shortEnglishHumanizer = humanizeDuration.humanizer({
	language: 'shortEn',
	languages: {
		shortEn: {
			y: () => 'y',
			mo: () => 'mo',
			w: () => 'w',
			d: () => 'd',
			h: () => 'h',
			m: () => 'm',
			s: () => 's',
		},
	},
	round: true,
	units: ['y', 'mo', 'w', 'd', 'h', 'm', 's'],
	spacer: '',
	serialComma: false,
});

export const isDateExpired = (date: Date) => {
	return new Date().getTime() > date.getTime();
};

export const createTimerExpirationDate = (minutes: number) => {
	return new Date(new Date().getTime() + minutes * 60 * 1000);
};

