import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { showErrorAlert } from '../../../../../store/alertToast/actions';
import SoundCreditLoader from '../../../SoundCreditLoader';
import styles from './PlaylistCollabModal.module.scss';
import clsx from 'clsx';
import {
	hideModal,
	setModalTitle,
	showModalAction,
} from '../../../../../store/modal/actions';
import PillButton from '../../../../layout/PillButton/PillButton';
import IconButton from '../../../../layout/IconButton/IconButton';
import theme from '../../../../../theme.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { INVITE_PLAYLIST_EDITOR_MODAL } from '../../../../../constants/modalTypes';
import {
	addPlaylistEditorAction,
	fetchPlaylistEditorsAction,
	fetchUserPlaylistsAction,
	removePlaylistEditorAction,
	setCurrentPlaylistAction,
} from '../../../../../store/playlists/actions';
import ROUTES from '../../../../../router/routes';
import { useNavigate } from 'react-router-dom';
import SoundCreditAvatar from '../../../../layout/SoundCreditAvatar/SoundCreditAvatar';

export type PlaylistCollabModalProps = {
	playlistId: Playlist['id'];
};

const PlaylistCollabModal = ({ playlistId }: PlaylistCollabModalProps) => {
	const dispatch = useAppDispatch();
	const { currentPlaylistEditors } = useAppSelector(state => state.playlists);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isRemovingEditor, setIsRemovingEditor] = useState<boolean>(false);
	const { userId } = useAppSelector(state => state.auth);

	const navigate = useNavigate();

	const handleAddEditor = useCallback(() => {
		dispatch(
			showModalAction(INVITE_PLAYLIST_EDITOR_MODAL, {
				size: 'md',
				onSubmit: async (email: string, message: string) => {
					await dispatch(
						addPlaylistEditorAction({
							playlistId,
							email,
							message,
						})
					);
				},
			})
		);
	}, [playlistId, dispatch]);

	const handleRemoveEditor = useCallback(
		(editor: PlaylistEditor) => async () => {
			if (editor.isOwner) return;

			try {
				setIsRemovingEditor(true);

				if (editor.userId === userId) {
					navigate(ROUTES.Playlists.path);
					dispatch(setCurrentPlaylistAction(null));

					await dispatch(
						removePlaylistEditorAction({
							playlistId,
							editorId: editor.userId,
						})
					);

					await Promise.resolve(dispatch(fetchUserPlaylistsAction()));

					dispatch(hideModal());

					return;
				}

				await dispatch(
					removePlaylistEditorAction({
						playlistId,
						editorId: editor.userId,
						editorEmail: editor.invitedEmail,
					})
				);
			} catch (error) {
				console.error(error);
				dispatch(showErrorAlert('Whoops! Failed to remove playlist editor'));
			} finally {
				setIsRemovingEditor(false);
			}
		},
		[playlistId, dispatch, navigate, userId]
	);

	const fetchEditors = useCallback(async () => {
		setIsLoading(true);

		try {
			await dispatch(fetchPlaylistEditorsAction(playlistId));
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	}, [playlistId, dispatch]);

	useEffect(() => {
		dispatch(setModalTitle('Playlist Collaborators'));
		fetchEditors();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<Modal.Body>
				<div className={clsx('px-3', 'pb-2', styles['playlist-collab-modal'])}>
					{isLoading ? (
						<SoundCreditLoader />
					) : (
						<>
							<PillButton
								label='Invite New'
								onClick={handleAddEditor}
								leftIcon='fas fa-user-plus'
								className='mb-4'
							/>
							<div
								className={styles['playlist-collab-modal__collaborators-label']}
							>
								Collaborators in this Playlist
								<hr />
							</div>
							{currentPlaylistEditors?.editors?.map((editor, index) => (
								<React.Fragment key={editor.userId}>
									{index !== 0 && <hr />}
									<div className={styles['playlist-collab-modal__editor-row']}>
										<div className='d-flex align-items-center' style={{
											gap: '0.5rem'
										}}>
											{/* <UserAvatar
												src={editor.imagePath}
											/> */}
											<SoundCreditAvatar
												name={editor.name}
												image={editor.imagePath}
											/>
											<div>
												<p
													className={
														styles['playlist-collab-modal__editor-name']
													}
												>
													{editor.name || editor.invitedEmail}
													{editor.isOwner && (
														<span className='ml-2 badge badge-pill'>Owner</span>
													)}

													{!editor.isAccepted && (
														<span
															className={clsx(
																'ml-2',
																'badge',
																'badge-pill',
																styles['badge-warning']
															)}
														>
															Pending
														</span>
													)}
												</p>

												<p
													className={
														styles['playlist-collab-modal__editor-email']
													}
												>
													{editor.email ?? 'Invited'}
												</p>
											</div>
										</div>

										<div>
											{!editor.isOwner && ( // the owner cannot be removed, not even by itself
												<IconButton
													icon='fas fa-user-times'
													onClick={handleRemoveEditor(editor)}
													className='mr-2'
													color={theme.error}
													tooltipText='Remove'
													isLoading={isRemovingEditor}
												/>
											)}
										</div>
									</div>
								</React.Fragment>
							))}
						</>
					)}
				</div>
			</Modal.Body>
		</>
	);
};

export default PlaylistCollabModal;
