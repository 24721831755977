const b4Types = {
	REGULAR_B4: {
		label: 'Regular AFM B4',
		id: 'REGULAR_B4',
	} as B4Type,
	PRODUCER_B4: {
		label: 'Producer AFM B4',
		id: 'PRODUCER_B4',
	} as B4Type,
};

export default b4Types;
