import React, { useCallback, useMemo } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import './SubLink.scss';
import { Spinner } from 'react-bootstrap';

export type SubLinkProps = {
	to?: string;
	label: string | JSX.Element;
	activeOnlyWhenExact?: boolean;
	isInactive?: boolean;
	isDisabled?: boolean;
	isAction?: boolean;
	icon?: string;
	className?: string;
	onClick?: () => void;
	isLoading?: boolean;
	fullPath?: string | string[];
	style?: React.CSSProperties;
	innerStyle?: React.CSSProperties;
};

function SubLink({
	to = '',
	label,
	activeOnlyWhenExact = true,
	isInactive = false,
	isDisabled = false,
	isAction = false,
	icon = '',
	fullPath = '',
	className = '',
	onClick = () => {},
	isLoading = false,
	style = {},
	innerStyle = {},
}: SubLinkProps) {
	const location = useLocation();

	const match = useMemo(() => {
		const paths = typeof fullPath === 'string' ? [fullPath] : fullPath;

		for (const path of paths) {
			if (matchPath({ path }, location.pathname)) return true;
		}

		return false;
	}, [fullPath, location.pathname]);

	const baseClass = `sub-link ${className} ${
		isAction ? 'action-sub-link' : ''
	} ${isDisabled || isLoading ? 'disabled-sub-link' : ''}`;

	const handleClick = () => {
		if (isDisabled || isLoading) {
			return;
		}

		onClick();
	};

	const renderIcon = useCallback(() => {
		if (isLoading) {
			return <Spinner size='sm' className='mr-2' />;
		}

		if (icon) {
			return <i className={`${icon} mr-2`} />;
		}

		return <></>;
	}, [icon, isLoading]);

	const link = (
		<div
			className={`${baseClass} ${match ? 'sub-link-active' : ''}`}
			onClick={handleClick}
			style={style}
		>
			{!to ? (
				<div className='sub-link-action-container' style={innerStyle}>
					{renderIcon()}
					<p style={{ cursor: 'pointer' }}>{label}</p>
				</div>
			) : (
				<Link
					onClick={handleClick}
					to={to}
					style={innerStyle}
					className='sub-link-nav-container'
				>
					{renderIcon()}
					<p>{label}</p>
				</Link>
			)}
		</div>
	);

	return (
		<>
			{!isInactive || !isAction ? (
				link
			) : (
				<div onClick={handleClick} className={baseClass} style={style}>
					{renderIcon()}
					<p>{label}</p>
				</div>
			)}
		</>
	);
}

export default SubLink;
