import axios from 'axios';
import { store } from '../store';
import humps from 'humps';

const aiVersionReconcilerApi = axios.create({
	baseURL:
		process.env.NODE_ENV === 'production'
			? process.env.REACT_APP_AI_VERSION_RECONCILER_API
			: '/ai-version-reconciler',
});

aiVersionReconcilerApi.interceptors.request.use(config => {
	const { userId, userToken } = store.getState().auth;

	if (userId && userToken && config.headers) {
		config.headers['publisher-user-id'] = userId;
		config.headers['publisher-user-token'] = userToken;
	}

	if (config.data) {
		config.data = humps.decamelizeKeys(config.data);
	}

	return config;
});

aiVersionReconcilerApi.interceptors.response.use(response => {
	if (response.data) {
		response.data = humps.camelizeKeys(response.data);
	}

	return response;
});

export default aiVersionReconcilerApi;
