import React, { useEffect, useMemo } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { openUpgradeWebsite } from '../../../../helpers/tiersTools';
import { hideModal, setModalTitle } from '../../../../store/modal/actions';
import Button from '../../../layout/Button';

const ProjectLimitModal = ({ message }) => {
	const { projectUsage } = useSelector(state => state.projects);

	const dispatch = useDispatch();

	const limitMessage = useMemo(
		() =>
			projectUsage.limit === projectUsage.used
				? `You have reached your limit of ${projectUsage.limit} projects.`
				: `Your current subscription plan allows you to own up to
        ${projectUsage.limit} projects. Currently, you are the owner of ${
						projectUsage.used
				  } project${projectUsage.used === 1 ? '' : 's'}.`,
		[projectUsage]
	);

	const modalTitle = useMemo(
		() =>
			projectUsage.limit === projectUsage.used
				? 'Project Limit Reached'
				: 'Project Limit',
		[projectUsage]
	);

	useEffect(() => {
		dispatch(setModalTitle(modalTitle));
	}, [dispatch, modalTitle]);

	return (
		<>
			<Modal.Body>
				<div className='px-4 fw-500'>
					<p>{message ?? limitMessage}</p>
					<p>Unlock unlimited projects and more! Upgrade your plan now!</p>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Row>
					<Col xs={12}>
						<Button label='Dismiss' onClick={() => dispatch(hideModal())} />
						<Button
							label='Upgrade'
							leftIcon='fas fa-angle-double-up'
							className='ml-2'
							theme='dark'
							onClick={() => openUpgradeWebsite()}
						/>
					</Col>
				</Row>
			</Modal.Footer>
		</>
	);
};

export default ProjectLimitModal;
