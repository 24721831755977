import { NavigateFunction, PathMatch } from 'react-router-dom';
import ROUTES from '../router/routes';
import { AppDispatch } from '../store';
import {
	clearSelectedProjectAction,
	fetchMyRecordingEditorProfileAction,
	saveEditorProfile,
	setCurrentAlbumIdAction,
} from '../store/projects/actions';
import { getAlbumRecordingIds } from './albumTools';
import { replacePathVariables } from './routeTools';

export const navigateToProjectEditor = ({
	albumId,
	recordingId,
	navigate,
	dispatch,
	route = ROUTES.EditRecordingDetails.path,
	albumsById,
}: {
	albumId?: number | null;
	recordingId?: number | null;
	navigate: NavigateFunction;
	dispatch: AppDispatch;
	route: string;
	albumsById: any;
}) => {
	if (!recordingId && !albumId) {
		throw new Error('No recordingId or albumId provided');
	}

	// We need to clean the user editor state in order to fetch it correctly on the fetchEditors call.
	dispatch(saveEditorProfile(null));

	if (albumId) {
		const album = albumsById[albumId];

		const recordingIds = getAlbumRecordingIds(album);

		const firstRecordingId = recordingIds[0];

		let recId = recordingId ?? firstRecordingId;

		dispatch(fetchMyRecordingEditorProfileAction(recId));
		dispatch(setCurrentAlbumIdAction(albumId, recId));
		navigate(replacePathVariables(route, { recordingId: recId }));
	} else if (recordingId) {
		dispatch(fetchMyRecordingEditorProfileAction(recordingId));

		dispatch(clearSelectedProjectAction());
		navigate(replacePathVariables(route, { recordingId }));
	}
};

export const sortRecordingsByUpdatedAtDescFn = (a: Recording, b: Recording) =>
	!a.updatedAt || !b.updatedAt
		? a.id > b.id
			? -1
			: 1
		: a.updatedAt > b.updatedAt
		? -1
		: 1;

/**
 * Sorts all recordings by leaving the ones in the album first, and the rest by updatedAt in descending order
 * If updatedAt is not available, it will sort by id in descending order
 * @param recordings - the recordings to sort
 * @param albumId - the album id to sort by
 */
export const sortRecordingsInAlbumDesc = (
	recordings: Recording[],
	albumId?: number | null
) => {
	const albumRecordings = albumId
		? recordings.filter(rec => rec.albumId === albumId)
		: [];
	const otherRecordings = albumId
		? recordings.filter(rec => rec.albumId !== albumId)
		: recordings;

	albumRecordings.sort(sortRecordingsByUpdatedAtDescFn);
	otherRecordings.sort(sortRecordingsByUpdatedAtDescFn);

	return [...albumRecordings, ...otherRecordings];
};

export const isAlbum = (project: Album | Recording): project is Album =>
	'album' in project;

export const isRecording = (project: Album | Recording): project is Recording =>
	'recording' in project;

export const replaceRecordingIdInCurrentUrl = (
	newRecordingId: number,
	match: PathMatch<'recordingId' | '*'> | null
) => {
	// Match the current URL against the pattern for your nested routes

	// Extract the nested section from the matched params
	const nestedSection = match?.params?.['*'] || '';

	// Construct the new path using the new recording ID and the extracted nested section
	const newPath = `/recordings/${newRecordingId}/${nestedSection}`;

	return newPath;
};
