import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
	denyCreditRequest,
	getPlaylistCreditRequests,
} from '../../../../../api/services/filesService';
import { showErrorAlert } from '../../../../../store/alertToast/actions';
import { Col, Modal, Row } from 'react-bootstrap';
import { setModalTitle } from '../../../../../store/modal/actions';
import SoundCreditLoader from '../../../SoundCreditLoader';
import { DataTable, DataTableExpandedRows } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { getPlaylistById } from '../../../../../store/playlists/selectors';
import { getFileProject } from '../../../../../helpers/fileTools';
import styles from './CreditRequestsModal.module.scss';
import clsx from 'clsx';
import { containsWriterRoles } from '../../../../../helpers/participantTools';
import {
	approveCreditRequestAction,
	fetchPlaylistAction,
	setPlaylistCreditRequestCountAction,
} from '../../../../../store/playlists/actions';

type CreditRequestsModalProps = {
	playlistId: Playlist['id'];
};

type CreditRequestWithRecordingInfo = CreditRequest & {
	recording: {
		id: Recording['id'];
		title: Recording['title'];
		artist: Recording['artist'];
	};
};

const addRecordingInfoToCreditRequests = (
	requests: CreditRequest[],
	playlist: Playlist
) =>
	requests.map(request => {
		const file = playlist?.playlist?.files.find(
			playlistFile => playlistFile.id === request.assetFileId
		);

		if (!file) throw new Error('File not found');

		const { title, artist } = getFileProject(file!)!;

		return {
			...request,
			recording: {
				id: file.recordingId,
				title,
				artist,
			},
		} as CreditRequestWithRecordingInfo;
	});

const CreditRequestsModal = ({ playlistId }: CreditRequestsModalProps) => {
	const dispatch = useAppDispatch();
	const playlist = useAppSelector(state =>
		getPlaylistById(state, { playlistId })
	);

	const [isLoadingRequests, setIsLoadingRequests] = React.useState(false);
	const [requests, setRequests] = React.useState<
		CreditRequestWithRecordingInfo[] | null
	>(null);
	const [expandedRows, setExpandedRows] = React.useState<
		DataTableExpandedRows | undefined
	>(undefined);

	const RenderActions = (creditRequest: CreditRequest) => {
		const [isApproving, setIsApproving] = React.useState(false);
		const [isDenying, setIsDenying] = React.useState(false);

		return (
			<div className='d-flex justify-content-end'>
				<Button
					icon='fas fa-check fa-lg'
					tooltip='Approve'
					text
					rounded
					onClick={async () => {
						setIsApproving(true);

						try {
							await dispatch(
								approveCreditRequestAction({
									creditRequest,
									playlistId,
								})
							);

							const requests = await getPlaylistCreditRequests({ playlistId });

							dispatch(
								setPlaylistCreditRequestCountAction({
									playlistId,
									requestCount: requests.length,
								})
							);

							setRequests(
								addRecordingInfoToCreditRequests(requests, playlist!)
							);
						} catch (e: any) {
							dispatch(
								showErrorAlert(
									`Whoops! Something went wrong while approving this credit request. Please try again later. (${e?.response?.status})`
								)
							);
							console.error(e);
						} finally {
							setIsApproving(false);
						}
					}}
					loading={isApproving}
					disabled={isDenying}
				/>
				<Button
					icon='fas fa-times fa-lg'
					tooltip='Deny'
					text
					rounded
					severity='danger'
					onClick={async () => {
						setIsDenying(true);

						try {
							const requests = await denyCreditRequest({
								playlistId,
								creditRequestId: creditRequest.id,
							});

							dispatch(
								setPlaylistCreditRequestCountAction({
									playlistId,
									requestCount: requests.length,
								})
							);

							setRequests(
								addRecordingInfoToCreditRequests(requests, playlist!)
							);
						} catch (e: any) {
							dispatch(
								showErrorAlert(
									`Whoops! Something went wrong while denying this credit request. Please try again later. (${e?.response?.status})`
								)
							);
							console.error(e);
						} finally {
							setIsDenying(false);
						}
					}}
					loading={isDenying}
					disabled={isApproving}
				/>
			</div>
		);
	};

	// effect for fetching credit requests on mount
	useEffect(() => {
		if (isLoadingRequests || requests || !playlist?.playlist) return;

		setIsLoadingRequests(true);

		getPlaylistCreditRequests({ playlistId })
			.then(fetchedRequests => {
				if (fetchedRequests.length !== playlist.playlist?.creditRequestCount) {
					// if the credit request count is stale, fetch the playlist again
					dispatch(fetchPlaylistAction(playlistId));
				}

				setRequests(
					addRecordingInfoToCreditRequests(fetchedRequests, playlist)
				);
			})
			.catch(e => {
				setRequests([]);
				dispatch(
					showErrorAlert(
						`Whoops! Something went wrong while fetching credit requests. Please try again later. (${e?.response?.status})`
					)
				);
				console.error(e);
			})
			.finally(() => {
				setIsLoadingRequests(false);
			});
	}, [isLoadingRequests, requests, playlistId, dispatch, playlist]);

	// effect for setting modal title
	useEffect(() => {
		dispatch(setModalTitle('Credit Requests'));
	}, [dispatch]);

	return (
		<Modal.Body>
			{!requests || !playlist?.playlist ? (
				<div
					style={{
						height: '40vh',
					}}
				>
					<SoundCreditLoader message='Loading Requests' />
				</div>
			) : (
				<div>
					<DataTable
						className={clsx(styles['credit-requests-table'])}
						expandedRows={expandedRows}
						onRowToggle={e => setExpandedRows(e.data as DataTableExpandedRows)}
						rowGroupMode='subheader'
						groupRowsBy='recording.id'
						sortField='recording.id'
						rowGroupHeaderTemplate={(data: any) => {
							return (
								<div>
									<i className='fas fa-music mr-2'></i>
									{data.recording.title} - {data.recording.artist}
								</div>
							);
						}}
						value={requests!}
						emptyMessage={
							<div
								className='d-flex justify-content-center align-items-center'
								style={{
									height: '10vh',
								}}
							>
								There are currently no credit requests for this playlist.
							</div>
						}
						rowExpansionTemplate={(credit: CreditRequestWithRecordingInfo) => {
							return (
								<div
									className={clsx(styles['credit-request-details-container'])}
								>
									<div className={clsx(styles['credit-request-details-card'])}>
										{' '}
										<Row>
											<Col xs={12} md={6}>
												<h3 className='mb-2'>General Credit Info</h3>
												<h4>LEGAL NAME</h4>
												<p>{credit.legalName || 'No legal name provided'}</p>
												<h4>CREDITED NAME</h4>
												<p>{credit.creditedName}</p>
												<h4>ROLES</h4>
												{!credit.roles.length ? (
													<p className='text-muted'>No roles listed</p>
												) : (
													credit.roles.map((role, index) => (
														<span>
															<span className='mr-1'>{role.detail}</span>
															{role.studio && (
																<span className='text-muted'>
																	({role.studio})
																</span>
															)}

															{index !== credit.roles.length - 1 && (
																<span className='mx-2'>|</span>
															)}
														</span>
													))
												)}
											</Col>
											{containsWriterRoles(credit.roles) && (
												<Col xs={12} md={6}>
													<div className='d-flex flex-column'>
														<h3 className='mb-2'>Writer Details</h3>
														<>
															<h4>PERFORMANCE RIGHTS ORGANIZATION (PRO)</h4>
															<p>{credit.pro}</p>
															<h4>IPI / CAE NUMBER</h4>
															<p>{credit.ipi || 'No IPI number listed'}</p>
															<h4>PUBLISHERS</h4>
															{!credit.publishers.length ? (
																<p className='text-muted'>
																	No publishers listed
																</p>
															) : (
																<ul className='pl-3 mb-0'>
																	{credit.publishers.map(publisher => (
																		<li>
																			<div>{publisher.name}</div>
																			{publisher.email && (
																				<div className='text-muted'>
																					Email Address: {publisher.email}
																				</div>
																			)}
																			{publisher.ipi && (
																				<div className='text-muted'>
																					IPI: {publisher.ipi}
																				</div>
																			)}
																		</li>
																	))}
																</ul>
															)}
														</>
													</div>
												</Col>
											)}
										</Row>
									</div>
								</div>
							);
						}}
					>
						<Column expander style={{ width: '2rem' }} />

						<Column field='creditedName' header='Credited Name' />
						<Column
							field='roles'
							header='Roles'
							body={(creditRequest: CreditRequest) => {
								return (
									<>
										{creditRequest.roles
											.map(role => role.detail)
											.slice(0, 3)
											.join(', ')}
									</>
								);
							}}
						/>
						<Column field='requesterEmail' header='Requester Email' />
						<Column
							field='createdAt'
							header='Requested At'
							body={request => (
								<>
									{new Date(request.createdAt).toLocaleDateString(undefined, {
										month: 'short',
										day: 'numeric',
										year: 'numeric',
										hour: 'numeric',
										minute: 'numeric',
									})}
								</>
							)}
						/>
						<Column
							header=''
							body={RenderActions}
							style={{
								width: '2rem',
							}}
						/>
					</DataTable>
				</div>
			)}
		</Modal.Body>
	);
};

export default CreditRequestsModal;
