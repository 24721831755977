import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { toggleLosslessAction } from '../../../../store/player/actions';
import { Button } from 'primereact/button';
import styles from '../AudioPlayerBar.module.scss';

const LosslessSwitch = () => {
	const dispatch = useAppDispatch();
	const { lossless } = useAppSelector(state => state.player);

	return (
		<div className={styles['lossless-switch']}>
			<Button
				outlined={!lossless}
				className={clsx('d-flex align-items-center', {
					[styles['active-lossless']]: lossless,
				})}
				onClick={() => dispatch(toggleLosslessAction())}
				style={{
					fontWeight: 700,
					padding: '0.25rem 0.5rem',
					// color: lossless ? 'black' : undefined,
					fontSize: '0.8rem',
					lineHeight: '1rem',
					borderWidth: '0.15rem',
				}}
			>
				LOSSLESS
			</Button>
		</div>
	);
};

export default LosslessSwitch;
