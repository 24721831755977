import axiosRetry from 'axios-retry';
import accountApi from '../accountApi';
import { shouldRetry } from '../../helpers/networkTools';

axiosRetry(accountApi, {
	retries: 3,
	retryDelay: retryCount => {
		return retryCount * 1000;
	},
	shouldResetTimeout: true,
	retryCondition: shouldRetry,
});

/**
 * Checks that the user's email doesn't belong to an existing account and whether there's previously-uploaded data to retrieve.
 * This function is part of the 1. Check email process and does not require authentication headers.
 *
 * @function checkEmail
 */
const checkEmail = (
	email: string // The email entered by the user.
) =>
	accountApi.post<{
		accountExists: boolean; // Whether the email is already registered as a user.
		hasExistingProfile: boolean; // Whether profile data has been entered for that email via indie upload. Mostly legacy support but can rarely return true.
		profileType: string; // Deprecated and should be ignored. Only returned if `hasExistingProfile` is true.
		foundProfile: IndieProfile; // The profile data for the given email. Only returned if `hasExistingProfile` is true.
		foundProfileId: number; // The database ID of the found profile. Only returned if `hasExistingProfile` is true.
	}>('/app-create/check', {
		email,
	});

/**
 * Creates a new user account and sends the verification email upon successful account creation.
 * This function is part of the 2b. Create account process and does not require authentication headers.
 * It is recommended to collect each field one-at-a-time in the mobile app and not to dump all the fields on the user at once.
 *
 * @function createAccount
 *
 * @example
 * ```
 * const response = await createAccount({
 *   email: "user@example.com",
 *   password: "base64encodedpassword",
 *   legalName: "John Musicguy",
 *   creditedName: "Johnny Musiclad",
 *   country: "Canada",
 *   dateOfBirth: "1970-01-01",
 *   claimingProfile: false,
 *   claimingProfileId: null,
 * });
 * console.log(response.data.creationSuccessful);  // Outputs: true
 * console.log(response.data.token);  // Outputs: "mobileauthtoken"
 * ```
 */
const createAccount = ({
	email, // The previously-verified email.
	password, // The user's entered password, encoded to base64.
	legalName, // The user's legal name.
	creditedName, // The user's credited name. If none was provided, it must be the same as their legal name. This field can't be null.
	country, // The user's full country of residence.
	dateOfBirth, // The user's date of birth in yyyy-mm-dd format or any ISO format.
	claimingProfile, // True if there was a found profile and the user identified it as themselves, false otherwise.
	claimingProfileId, // Must be provided if `claimingProfile` is True.
}: CreateAccountFormValues) =>
	accountApi.post<{ creationSuccessful: boolean; token: string }>(
		'/app-create/create',
		{
			email,
			password,
			legalName,
			creditedName,
			country,
			dateOfBirth,
			profileType: 'professional', // Deprecated - this should always be the string 'professional'
			claimingProfile,
			claimingProfileId,
		}
	);

/**
 * Verifies the user's email with a verification code. Part of the step 3 in the user verification process.
 * This endpoint does not require authentication headers.
 *
 * @function verifyEmail
 *
 * @example
 * ```
 * const response = await verifyEmail("user@example.com", "012345");
 * console.log(response.data.verified);  // Outputs: true
 * ```
 */
const verifyEmail = (
	email: string, // The email to verify.
	code: string // The user-entered verification code.
) =>
	accountApi.post<{
		verified: boolean;
		details: {
			claimedPromo?: boolean;
			promoText?: string | null;
			claimedSubscription?: boolean;
			userPermissions?: UserPermissions | null;
		};
	}>('/app-create/verify', { email, code });

/**
 * Updates the union information for a user who is part of a musician's union with the last four digits of their Social Security Number (SSN).
 * This is part of the 4a. Union info process and requires authentication headers.
 * This step should only be requested if the user confirms they are part of a musician's union and is in the US.
 *
 * @function updateUnionSsn
 *
 * @example
 * ```
 * const response = await updateUnionSsn({ ssnLastFour: "1234" });
 * console.log(response.data.ssnLastFour);  // Outputs: true
 * ```
 */
const updateUnionSsn = (
	{ ssnLastFour }: { ssnLastFour: string } // The last four digits of the user's Social Security Number (SSN).
) =>
	accountApi.put<{ ssnLastFour: boolean }>('/profile/union/info', {
		ssnLastFour,
	});

/**
 * Updates the address information of a user who is part of a union. This is part of the 4b. Union info process.
 *
 * Note: This API is US-centric and the `state` field is analogous to `region` in normal forms. All fields are required except for `address2`, `city`, and `postCode`.
 *
 * @function updateProfileAddress
 *
 * @example
 * ```
 * updateProfileAddress({
 *   address1: "123 street road",
 *   address2: "apt 20",
 *   city: "Townsville",
 *   state: "Statesburg",
 *   postCode: "12345",
 *   country: "Canada"
 * });
 * ```
 */
const updateProfileAddress = ({
	address1, // The first line of the user's address.
	address2, // The second line of the user's address. This field is optional.
	city, // The city of the user's address. This field is optional.
	state, // The state of the user's address. This is analogous to `region` in normal forms.
	postCode, // The post code of the user's address. This field is optional.
	country, // The country of the user's address.
}: UnionAddress) =>
	accountApi.put('/profile/contact/address', {
		address1,
		address2,
		city,
		state,
		postCode,
		country,
	});

/**
 * Registers the supplied ISNI (International Standard Name Identifier) to the user's profile.
 * This is part of the 5b-1 ISNI Registration process.
 *
 * @function claimIsni
 *
 * @example
 * ```
 * claimIsni({ isni: "000011112222333X" });
 * ```
 */
const claimIsni = (
	{ isni }: { isni: string } // The ISNI code from the found name response.
) =>
	accountApi.put<{ isni: string }>('/profile/id-codes/isni', {
		isni,
	});

const AccountService = {
	checkEmail,
	createAccount,
	verifyEmail,
	updateUnionSsn,
	updateProfileAddress,
	claimIsni,
};

export default AccountService;
