import { camelCase, isArray, isObject, transform } from 'lodash';

// const DATE_FIELDS = ['createdAt', 'updatedAt', 'dripEnd', 'dripStart'];

/**
 * Converts an object from the API to a local object,
 * by converting date strings to Date objects and camelCasing keys.
 * @param {Object} apiObject - The object from the API
 * @returns {Object} - The local object
 * @example
 * const apiObject = {
 *   "id": 1,
 *   "file_name": "test.jpg",
 *   "file_size": 123456,
 *   "file_type": "image/jpeg",
 *   "created_at": "2020-01-01T00:00:00.000Z",
 * };
 *
 * const localObject = convertApiObjectToLocal(apiObject);
 * // =>
 * //{
 * //  id: 1,
 * //  filename: "test.jpg",
 * //  fileSize: 123456,
 * //  fileType: "image/jpeg",
 * //  createdAt: Date("2020-01-01T00:00:00.000Z"),
 * //}
 *
 */
export const translateApiObjectToLocal = (apiObject: object) =>
	transform(apiObject, (acc: any, value: any, key: string, target: any) => {
		const camelKey = isArray(target) ? key : camelCase(key);

		acc[camelKey] = isObject(value) ? translateApiObjectToLocal(value) : value;
	});
