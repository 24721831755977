import React, { useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import '../ProjectsTable.scss';
import FILE_TABS from '../../../../../constants/fileTabs.json';
import { navigateToProjectFiles } from '../../../../../helpers/fileTools';

const FilesColumn = ({ fileCount, row }) => {
	const navigate = useNavigate();

	const isLoading = useMemo(() => !(row && row.original.createdAt), [row]);

	const handleClick = useCallback(() => {
		if (isLoading) return;

		navigateToProjectFiles({
			albumId: row.canExpand ? row.original.id : null,
			recordingId: row.canExpand ? null : row.original.id,
			section: FILE_TABS.FILES,
			navigate,
		});
	}, [isLoading, navigate, row]);

	return (
		<div
			className={`file-column Name1 ${
				fileCount > 0 ? '' : 'no-files-pill-text'
			} ${isLoading ? 'disabled' : ''}`}
			onClick={handleClick}
		>
			<i className='fas fa-file mr-1' />

			{`${fileCount > 0 ? fileCount : 'No'} File${fileCount !== 1 ? 's' : ''}`}
		</div>
	);
};

export default FilesColumn;
