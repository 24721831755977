import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Card, Container, Modal } from 'react-bootstrap';
import { getUserEditableProjects } from '../../../../api/services/projectsService';
import { showErrorAlert } from '../../../../store/alertToast/actions';
import { hideModal, setModalTitle } from '../../../../store/modal/actions';
import { copyCreditsToOtherRecordingsAction } from '../../../../store/projects/actions';
import { getCurrentRecording } from '../../../../store/projects/selectors';
import Button from '../../../layout/Button';
import SearchTable from '../../../layout/SearchTable';
import SoundCreditLoader from '../../SoundCreditLoader';
import SelectRecordings from '../../SelectRecordings/SelectRecordings';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { sortRecordingsInAlbumDesc } from '../../../../helpers/projectTools';
import PillButton from '../../../layout/PillButton';
import { getAlbumRecordingIds } from '../../../../helpers/albumTools';

enum CopyCreditsToOtherRecordingsSteps {
	SelectParticipants,
	SelectRecordings,
}

export type CopyCreditsToOtherRecordingsProps = {
	onSubmit: () => void;
};

type SelectTargetRecordingsProps = {
	onSelect: (ids: Recording['id'][]) => void;
	onSubmit: ((recordingIds?: Recording['id'][]) => void) | (() => void);
	onBack: () => void;
	selectedRecordingIds: Recording['id'][];
	isLoading: boolean;
	recordingOptionsIds: Recording['id'][] | null;
	albumId?: Album['id'] | null;
};

const SelectTargetRecordings = ({
	onSelect,
	onSubmit,
	onBack,
	selectedRecordingIds,
	isLoading,
	recordingOptionsIds,
}: SelectTargetRecordingsProps) => {
	const { recordingsById, albumsById, currentAlbumId, currentRecordingId } =
		useAppSelector(state => state.projects);
	const otherAlbumRecordingIds = useMemo(() => {
		if (!currentAlbumId) return [];

		if (!albumsById?.[currentAlbumId]) throw new Error('Album not found');

		return getAlbumRecordingIds(albumsById?.[currentAlbumId]).filter(
			recId => recId !== currentRecordingId
		);
	}, [currentAlbumId, albumsById, currentRecordingId]);

	const recordingOptions = useMemo(
		() =>
			sortRecordingsInAlbumDesc(
				recordingOptionsIds
					?.map(id => recordingsById?.[id])
					?.filter((r): r is Recording => !!r) ?? [],
				currentAlbumId
			),
		[recordingOptionsIds, recordingsById, currentAlbumId]
	);

	if (!recordingOptionsIds)
		return (
			<div style={{ height: '50vh' }}>
				<SoundCreditLoader />;
			</div>
		);

	return (
		<>
			<Modal.Body>
				{!recordingOptions.length ? (
					<NoRecordings />
				) : (
					<div className='px-3'>
						<>
							{otherAlbumRecordingIds.length ? (
								<div className='d-flex justify-content-end align-items-center mb-3'>
									<PillButton
										label='Copy to Entire Album'
										onClick={() => {
											onSelect(otherAlbumRecordingIds);
											onSubmit(otherAlbumRecordingIds);
										}}
										isLoading={isLoading}
										leftIcon='fas fa-record-vinyl'
									/>
								</div>
							) : null}
							<SelectRecordings
								onSelect={onSelect}
								projectRows={recordingOptions}
								customRows
							/>
						</>
					</div>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button label='Back' onClick={onBack} isDisabled={isLoading} />
				<Button
					label='Next'
					onClick={() => onSubmit()}
					isLoading={isLoading}
					isDisabled={
						!recordingOptions?.length || !selectedRecordingIds?.length
					}
					className='ml-2'
					theme='dark'
				/>
			</Modal.Footer>
		</>
	);
};

type SelectParticipantsProps = {
	onSelect: (ids: number[]) => void;
	onSubmit: () => void;
	selectedParticipants: number[];
	isLoading: boolean;
	options: Participant[];
};

const SelectParticipants = ({
	onSubmit,
	onSelect,
	isLoading,
	options,
	selectedParticipants,
}: SelectParticipantsProps) => {
	const dispatch = useAppDispatch();

	const columns = useMemo(
		() => [
			{
				Header: () => <span className='Project-Name'>Name</span>,
				accessor: 'creditedName',
				Cell: (col: any) => <span className='Name1'>{col.value}</span>, // TODO: fix any
			},
			{
				Header: () => <span className='Project-Name'>Roles</span>,
				accessor: 'roles',
				Cell: (
					col: any // TODO: fix any
				) => (
					<ul className='Name1 m-0 p-0'>
						{col.value.map((role: ParticipantRole) => (
							<li className='my-2' style={{ listStyleType: 'none' }}>
								{role.detail}
							</li>
						))}
					</ul>
				),
			},
		],
		[]
	);
	console.log('options', options);

	if (!options)
		return (
			<div style={{ height: '50vh' }}>
				<SoundCreditLoader />;
			</div>
		);

	return (
		<>
			<Modal.Body>
				<Container>
					{!options.length ? (
						<NoParticipants />
					) : (
						<>
							<Card>
								<Card.Body>
									<SearchTable
										data={options}
										onSelect={onSelect}
										columns={columns}
										rowMapper={row => row.original.id}
										searchBarPlaceholder='Search by Name or Role'
									/>
								</Card.Body>
							</Card>
						</>
					)}
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Button
					label='Cancel'
					onClick={() => dispatch(hideModal())}
					isDisabled={isLoading}
				/>
				<Button
					label='Next'
					onClick={onSubmit}
					isLoading={isLoading}
					isDisabled={!selectedParticipants?.length}
					theme='dark'
					className='ml-2'
				/>
			</Modal.Footer>
		</>
	);
};

const NoParticipants = () => {
	return (
		<div
			style={{ height: '40vh' }}
			className='d-flex justify-content-center align-items-center flex-column'
		>
			<i className='fas fa-users-slash mb-3' style={{ fontSize: '5rem' }}></i>
			<div>
				<h4>This recording currently has no participants</h4>
			</div>
		</div>
	);
};

const NoRecordings = () => {
	return (
		<div
			style={{ height: '40vh' }}
			className='d-flex justify-content-center align-items-center flex-column'
		>
			<i className='fas fa-compact-disc mb-3' style={{ fontSize: '5rem' }}></i>
			<div>
				<h4>You currently have no other recordings to copy participants to</h4>
			</div>
		</div>
	);
};

const CopyCreditsToOtherRecordings = () => {
	const dispatch = useAppDispatch();
	const { currentRecordingId } = useAppSelector(state => state.projects);
	const currentRecording = useAppSelector(getCurrentRecording);

	const [isLoading, setIsLoading] = useState(false);
	const [selectedRecordingIds, setSelectedRecordingIds] = useState<number[]>(
		[]
	);
	const [selectedParticipantIds, setSelectedParticipantIds] = useState<
		number[]
	>([]);
	const [currentStep, setCurrentStep] = useState(
		CopyCreditsToOtherRecordingsSteps.SelectParticipants
	);
	const [recordingOptionsIds, setRecordingOptionsIds] = useState<
		number[] | null
	>(null);

	const recordingParticipants = useMemo(() => {
		return currentRecording!.recording!.participants;
	}, [currentRecording]);

	// Add an optional recordingIds parameter to override the selectedRecordingIds state
	// This is needed for functions that set the selectedRecordingIds state and then call this function in the
	// same closure, which would otherwise use the old state value (e.g. copy to entire album)
	const handleSubmitRecordings = useCallback((recordingIds?: Recording['id'][]) => {
		setIsLoading(true);

		Promise.resolve(
			dispatch(
				copyCreditsToOtherRecordingsAction({
					fromRecordingId: currentRecordingId!,
					toRecordingIds: recordingIds ?? selectedRecordingIds,
					participantIds: selectedParticipantIds,
				})
			)
		).finally(() => {
			setIsLoading(false);
			dispatch(hideModal());
		});
	}, [
		selectedRecordingIds,
		dispatch,
		currentRecordingId,
		selectedParticipantIds,
	]);

	const handleSubmitParticipants = useCallback(() => {
		setIsLoading(true);
		getUserEditableProjects()
			.then(res => {
				console.log('USER EDITABLE PROJECTS', res);
				setRecordingOptionsIds(
					res.data.recordings
						.map(r => r.id)
						.filter(recId => recId !== currentRecordingId)
				);
				setCurrentStep(CopyCreditsToOtherRecordingsSteps.SelectRecordings);
			})
			.catch(() => {
				dispatch(
					showErrorAlert(
						'Hiccup detected while fetching project permissions. Try refreshing projects.'
					)
				);
				dispatch(hideModal());
			})
			.finally(() => {
				setIsLoading(false);
			});
	}, [currentRecordingId, dispatch]);

	const handleBack = () => {
		setCurrentStep(CopyCreditsToOtherRecordingsSteps.SelectParticipants);
	};

	const renderStep = useCallback(() => {
		switch (currentStep) {
			case CopyCreditsToOtherRecordingsSteps.SelectParticipants:
				return (
					<SelectParticipants
						onSelect={setSelectedParticipantIds}
						onSubmit={handleSubmitParticipants}
						isLoading={isLoading}
						options={recordingParticipants}
						selectedParticipants={selectedParticipantIds}
					/>
				);
			case CopyCreditsToOtherRecordingsSteps.SelectRecordings:
				return (
					<SelectTargetRecordings
						onSelect={setSelectedRecordingIds}
						onSubmit={handleSubmitRecordings}
						recordingOptionsIds={recordingOptionsIds}
						selectedRecordingIds={selectedRecordingIds}
						isLoading={isLoading}
						onBack={handleBack}
					/>
				);
			default:
				return <></>;
		}
	}, [
		currentStep,
		isLoading,
		handleSubmitParticipants,
		handleSubmitRecordings,
		recordingOptionsIds,
		selectedRecordingIds,
		recordingParticipants,
		selectedParticipantIds,
	]);

	useEffect(() => {
		dispatch(setModalTitle('COPY CREDITS TO OTHER RECORDING(S)'));
	}, [dispatch]);

	return renderStep();
};

export default CopyCreditsToOtherRecordings;
