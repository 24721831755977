import _ from 'lodash';
import React, { useMemo } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import hours from '../../../../../constants/unionForm/hours.json';
import sessionTimes from '../../../../../constants/unionForm/sessionTimes.json';
import Select from '../../../../form/Select';
import theme from '../../../../../theme.module.scss';

const SessionTimesPicker = ({ formik, sessionTime, index, errorMessage }) => {
	const [other, setOther] = React.useState(false);
	const hoursOptions = useMemo(() => {
		return hours.map(hour => ({
			label: hour,
			value: hour,
		}));
	}, []);

	const showError = useMemo(() => {
		return (
			formik &&
			formik.touched.sessionTimes &&
			formik.touched.sessionTimes[index] &&
			formik.touched.sessionTimes[index].startTime &&
			formik.errors.sessionTimes &&
			formik.errors.sessionTimes[index] &&
			formik.errors.sessionTimes[index].startTime
		);
	}, [formik, index]);

	return (
		<Col xs={5}>
			<Row className='align-items-center'>
				{other ? (
					<>
						<Col xs={5}>
							<Select
								label='Start Time'
								name={`sessionTimes[${index}].startTime`}
								id={`sessionTimes[${index}].startTime`}
								options={hoursOptions}
								value={hoursOptions.find(
									hour => hour.value === sessionTime.startTime
								)}
								onChange={option => {
									formik.setFieldValue(
										`sessionTimes[${index}].startTime`,
										option.value
									);
								}}
								onBlur={formik.handleBlur}
								errorFieldName={`sessionTimes[${index}].startTime`}
							/>
						</Col>
						<Col xs={5}>
							<Select
								label='End Time'
								name={`sessionTimes[${index}].endTime`}
								id={`sessionTimes[${index}].endTime`}
								options={hoursOptions}
								value={hoursOptions.find(
									hour => hour.value === sessionTime.endTime
								)}
								onChange={option => {
									formik.setFieldValue(
										`sessionTimes[${index}].endTime`,
										option.value
									);
								}}
								onBlur={formik.handleBlur}
								errorFieldName={`sessionTimes[${index}].endTime`}
							/>
						</Col>
					</>
				) : (
					<Col xs={10} className='d-flex flex-column'>
						<Select
							name={`sessionTimes[${index}].startTime`}
							label='Session Time'
							options={sessionTimes}
							getOptionLabel={option =>
								option.startTime + ' - ' + option.endTime
							}
							value={sessionTimes.find(option =>
								_.isEqual(option, {
									startTime: sessionTime.startTime,
									endTime: sessionTime.endTime,
								})
							)}
							onChange={option => {
								formik.setFieldValue(
									`sessionTimes[${index}].startTime`,
									option.startTime
								);
								formik.setFieldValue(
									`sessionTimes[${index}].endTime`,
									option.endTime
								);
							}}
							onBlur={formik.handleBlur}
						/>
						{showError && (
							<small
								style={{
									color: theme.error,
									height: 0,
									position: 'absolute',
									bottom: 20,
								}}
								className='mt-1'
							>
								{errorMessage}
							</small>
						)}
					</Col>
				)}
				<Col xs={2}>
					<Form.Check
						type='checkbox'
						label='Other'
						value={`${other}`}
						onChange={() => {
							setOther(!other);
						}}
					/>
				</Col>
			</Row>
		</Col>
	);
};

export default SessionTimesPicker;
