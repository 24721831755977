import { useCallback, useMemo } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Button } from 'react-bootstrap';
import isSpecialRole from '../../../../helpers/isSpecialRole';
import { useEffect, useState } from 'react';
import { hideModal, showModalAction } from '../../../../store/modal/actions';
import {
	DELETE_PARTICIPANT_MODAL,
	LOADING_MODAL,
} from '../../../../constants/modalTypes';
import { v4 as uuidv4 } from 'uuid';
import { fetchLocalProfileAction } from '../../../../store/profiles/actions';
import { useAppDispatch } from '../../../../store/hooks';

export type ProfileRowProps = {
	profile: LocalProfile;
	setEditing: (editing: boolean) => void;
	index: number;
	selectProfile: (profile: LocalProfile) => void;
	toggleShowParticipantModal: () => void;
};

function ProfileRow({
	profile,
	setEditing = (editing: boolean) => {},
	index,
	selectProfile,
	toggleShowParticipantModal,
}: ProfileRowProps) {
	const dispatch = useAppDispatch();
	const [isFetchingProfile, setIsFetchingProfile] = useState(false);

	const showEditModal = useCallback(() => {
		selectProfile(profile);
		setEditing(true);
		toggleShowParticipantModal();
	}, [profile, selectProfile, setEditing, toggleShowParticipantModal]);

	const handleEdit = useCallback(async () => {
		// fetch participant in case the full profile is not in the store
		try {
			if (profile.profile) {
				showEditModal();
				return;
			}

			setIsFetchingProfile(true);
			dispatch(
				showModalAction(LOADING_MODAL, {
					size: 'sm',
					hideHeader: true,
				})
			);

			await Promise.resolve(dispatch(fetchLocalProfileAction(profile.id)));
		} catch (error) {
			setIsFetchingProfile(false);
			dispatch(hideModal());
		}
	}, [profile, dispatch, showEditModal]);

	const draggableId = useMemo(
		() => profile?.id?.toString() || uuidv4(),
		[profile]
	);

	const getRoleName = useCallback((role: string | LocalProfileDTORole) => {
		if (typeof role === 'string') {
			return role;
		}

		return role.detail;
	}, []);

	const handleDelete = () => {
		dispatch(
			showModalAction(DELETE_PARTICIPANT_MODAL, {
				participant: profile,
				size: 'md',
				deleteFromRecording: false,
			})
		);
	};

	/*
	? This is a hack to get the latest version of the participant 
	? in the Redux store after it's been fetched from the API
	*/
	useEffect(() => {
		if (isFetchingProfile && profile.profile) {
			setIsFetchingProfile(false);
			dispatch(hideModal());

			showEditModal();
		}
	}, [profile, selectProfile, isFetchingProfile, dispatch, showEditModal]);

	return (
		<Draggable
			draggableId={draggableId}
			index={index}
			key={profile.id.toString()}
		>
			{provided => (
				<tr {...provided.draggableProps} ref={provided.innerRef}>
					<td className='align-middle' {...provided.dragHandleProps}>
						{/* <i className='fas fa-grip-lines' /> */}
					</td>
					<td className='fw-600' style={{ textAlign: 'left' }}>
						{profile.creditedName}
					</td>
					<td>
						{profile.roles &&
							profile.roles.map((role, index) => {
								return (
									<p
										className='mb-0'
										key={`${profile.id}-${getRoleName(role)}-${index}`}
									>
										{getRoleName(role)}
									</p>
								);
							})}
					</td>
					<td style={{ textAlign: 'center' }}>
						{!profile.roles.find(isSpecialRole) ? (
							<p className='mb-0 text-center'>—</p>
						) : profile.publishers.length > 0 ? (
							profile.publishers.map(publisher => (
								<span className=' d-block text-center'>{publisher.name}</span>
							))
						) : (
							<>
								<p className='mb-0 text-center'>No publishing company</p>
								{/* TODO: Replace with LinkButton component */}
								<Button
									variant='link'
									className='p-0'
									onClick={() => {
										selectProfile(profile);
										toggleShowParticipantModal();
									}}
								>
									<small>Click here to add publishing company</small>
								</Button>
							</>
						)}
					</td>
					<td className=''>
						<div className='row d-flex justify-content-around'>
							<button className='btn p-0' type='button' onClick={handleDelete}>
								<i className='fa fa-trash-alt text-red'></i>
							</button>
							<button className='btn p-0' type='button' onClick={handleEdit}>
								<i className='fa fa-edit text-purple'></i>
							</button>
						</div>
					</td>
				</tr>
			)}
		</Draggable>
	);
}

export default ProfileRow;
