import React, { useMemo } from 'react';
import { Alert, Collapse } from 'react-bootstrap';
import { ErrorLevel } from '../../../helpers/errors';
import { hideAlertToast } from '../../../store/alertToast/actions';
import './AlertToast.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

const AlertToast = () => {
	// const [show, setShow] = useState(false);
	const { message, level } = useAppSelector(state => state.alertToast);
	const dispatch = useAppDispatch();

	const alertClass = useMemo(() => {
		switch (level) {
			case ErrorLevel.ERROR:
				return 'error';
			case ErrorLevel.INFO:
				return 'info';
			case ErrorLevel.WARNING:
				return 'warning';
			case 'editor':
				return 'inactive-editor';
			case 'read-only':
				return 'read-only';
			default:
				return 'info';
		}
	}, [level]);

	return (
		<Collapse in={message !== ''}>
			<Alert
				show={message !== ''}
				dismissible={level === 'read-only' || level === 'editor' ? false : true}
				onClose={() => dispatch(hideAlertToast())}
				className={`alert-toast-container 
					${alertClass}`}
			>
				{message}
			</Alert>
		</Collapse>
	);
};

export default AlertToast;
