import React, { useMemo, useState } from 'react';
import {
	ProjectSelectOption,
	createProjectOption,
} from '../../../../form/CreatableProjectSelect/CreatableProjectSelect';
import Fuse from 'fuse.js';
import { useAppSelector } from '../../../../../store/hooks';

export type PlaylistFileUploadContextType = {
	newProjects: ProjectSelectOption[];
	setNewProjects: React.Dispatch<React.SetStateAction<ProjectSelectOption[]>>;
	userProjects: ProjectSelectOption[];
	inputFiles: File[];
	setInputFiles: React.Dispatch<React.SetStateAction<File[]>>;
	playlistId: number | undefined;
	titleIndex: Fuse.FuseIndex<ProjectSelectOption>;
	inputTimestamp: number;
	setInputTimestamp: React.Dispatch<React.SetStateAction<number>>;
	useFilenameAsTitle: boolean;
	setUseFilenameAsTitle: React.Dispatch<React.SetStateAction<boolean>>;
};

const PlaylistFileUploadContext =
	React.createContext<PlaylistFileUploadContextType | null>(null);

export const PlaylistFileUploadContextProvider = ({
	children,
	playlistId,
}: {
	children: React.ReactNode;
	playlistId?: Playlist['id'];
}) => {
	const [newProjects, setNewProjects] = useState<ProjectSelectOption[]>([]);
	const [inputFiles, setInputFiles] = useState<File[]>([]);
	const [inputTimestamp, setInputTimestamp] = useState(Date.now()); // used to force a re-render of the input file list items when required
	const [useFilenameAsTitle, setUseFilenameAsTitle] = useState(false);

	const { recordingsById, albumsById } = useAppSelector(
		state => state.projects
	);

	const allUserProjects = useMemo(() => {
		const albums = Object.values(albumsById ?? {})
			.filter(album => !album.isSingle)
			.map(album =>
				createProjectOption({
					isAlbum: true,
					albumId: album.id,
					recordingId: null,
					title: album.title,
					artist: album.artist,
				})
			);

		const recordings = Object.values(recordingsById ?? {}).map(recording =>
			createProjectOption({
				isAlbum: false,
				albumId: null,
				recordingId: recording.id,
				title: recording.title,
				artist: recording.artist,
			})
		);

		return [...newProjects, ...recordings, ...albums];
	}, [albumsById, recordingsById, newProjects]);

	const titleIndex = useMemo(() => {
		const index = Fuse.createIndex(['title'], allUserProjects);

		return index;
	}, [allUserProjects]);

	return (
		<PlaylistFileUploadContext.Provider
			value={{
				newProjects,
				setNewProjects,
				userProjects: allUserProjects,
				inputFiles,
				setInputFiles,
				playlistId,
				titleIndex,
				inputTimestamp,
				setInputTimestamp,
				useFilenameAsTitle,
				setUseFilenameAsTitle,
			}}
		>
			{children}
		</PlaylistFileUploadContext.Provider>
	);
};

export default PlaylistFileUploadContext;
