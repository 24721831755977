import { Fragment } from 'react';
import './PlaylistStatsRanking.scss';

const PlaylistStatsRanking = props => {
	const { type, data } = props;
	const total = (data ?? []).reduce((a, b) => a + b.plays, 0);

	return (
		<div className='ranking-list__container'>
			<div className='ranking-header-title'>
				<h5 className='ranking-name'>{type} Name</h5>
				<h5 className='ranking-value'>Plays</h5>
			</div>
			{(data ?? []).map(({ name, plays }) => (
				<Fragment key={name}>
					<div className='ranking-title'>
						<h5 className='ranking-name'>{name}</h5>
						<h5 className='ranking-value'>{plays}</h5>
					</div>
					<div className='ranking-track'>
						<div
							className='ranking-thumb'
							style={{ width: `${(plays * 100) / total}%` }}
						/>
					</div>
				</Fragment>
			))}
			{!data?.length && (
				<div className='ranking-title'>
					<h5 className='ranking-error'>No data</h5>
				</div>
			)}
		</div>
	);
};

export default PlaylistStatsRanking;
