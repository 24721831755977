import translateApiAlbumToLocal from './translateApiAlbumToLocal';

const mergeFetchedAlbums = (fetchedAlbums, albumsById) => {
	const mergedAlbums = { ...albumsById };

	fetchedAlbums.forEach(fetchedAlbum => {
		fetchedAlbum = translateApiAlbumToLocal(fetchedAlbum);
		const localAlbum = mergedAlbums[fetchedAlbum.id];

		// We only want to merge the albums that don't already exist
		// thus we avoid overwriting the properly cached albums objects
		// If they're stale, then they will be overwritten with a null album field,
		// forcing a re-fetch of the specific album the next time it's needed
		const fetchedUpdatedAt = new Date(fetchedAlbum.updatedAt);
		const localUpdatedAt = new Date(localAlbum?.updatedAt || 0);

		if (!localAlbum || fetchedUpdatedAt > localUpdatedAt) {
			mergedAlbums[fetchedAlbum.id] = fetchedAlbum;
		} else if (localAlbum && !localAlbum.createdAt) {
			// for new albums
			mergedAlbums[fetchedAlbum.id].createdAt = fetchedAlbum.createdAt;
			mergedAlbums[fetchedAlbum.id].updatedAt = fetchedAlbum.updatedAt;
			mergedAlbums[fetchedAlbum.id].userId = fetchedAlbum.userId;
		}
	});

	// if some fetched albums are null but we have them locally, we want to delete them
	// as they might have been deleted on the server
	const fetchedAlbumsById = fetchedAlbums.reduce((acc, album) => {
		acc[album.id] = album;
		return acc;
	}, {});

	Object.keys(mergedAlbums).forEach(albumId => {
		if (!fetchedAlbumsById[albumId]) {
			delete mergedAlbums[albumId];
		}
	});

	return mergedAlbums;
};

export default mergeFetchedAlbums;
