import { AppDispatch, GetState } from '..';
import {
	reportPlaylistJuly2023PromoPass,
	togglePlaylistJuly2023PromoSeen,
} from '../../api/services/authService';
import {
	CONFIRMATION_MODAL,
	UPGRADE_TIER_MODAL,
} from '../../constants/modalTypes';
import { isPlaylistJuly2023PromoActive } from '../../helpers/promoTools';
import {
	CLEAR_PROMOS,
	SET_PROMO_ACTIVE,
	SET_PROMO_SEEN,
	EXTEND_PLAYLIST_JULY_2023_PROMO,
	START_PLAYLIST_JULY_2023_PROMO_TIMER,
} from '../actionTypes';
import { showErrorAlert } from '../alertToast/actions';
import { invalidTokenAction } from '../auth/actions';
import {
	hideAllModalsAction,
	hideModal,
	showModalAction,
} from '../modal/actions';
import PromoType from './promoType';

const _handlePromoError = (
	error: any,
	dispatch: AppDispatch,
	message?: string
) => {
	if (error.response) {
		let errorMessage =
			message ||
			'Hiccup detected:  The cloud needs a coffee break.  Give it another go!';

		switch (error.response.status) {
			case 401:
				dispatch(invalidTokenAction());
				break;
			case 403:
				errorMessage =
					message || 'You do not have permission to perform this action.';
			// intentional fallthrough
			default:
				dispatch(showErrorAlert(`${errorMessage} (${error.response.status})`));
		}
	} else {
		throw error;
	}
};

export const setPromoActiveAction = (promo: PromoType, active: boolean) => ({
	type: SET_PROMO_ACTIVE,
	promo,
	active,
});

export const setPromoSeenAction = (promo: PromoType, seen: boolean) => ({
	type: SET_PROMO_SEEN,
	promo,
	seen,
});

export const startPlaylistJuly2023PromoTimerAction = () => ({
	type: START_PLAYLIST_JULY_2023_PROMO_TIMER,
});

export const reportPlaylistJuly2023PromoSeenAction =
	() => (dispatch: AppDispatch) =>
		togglePlaylistJuly2023PromoSeen(true)
			.then(() => {
				dispatch(setPromoSeenAction(PromoType.PlaylistJuly2023, true));
				dispatch(setPromoActiveAction(PromoType.PlaylistJuly2023, true)); // we need this to show the UI related to the promo
				dispatch(startPlaylistJuly2023PromoTimerAction());
			})
			.catch((error: any) => {
				console.error('Failed to report seen promo');
				_handlePromoError(error, dispatch);
			});

export const reportPlaylistJuly2023PromoPassAction =
	(playlistId: Playlist['id']) =>
	(dispatch: AppDispatch, getState: GetState) => {
		const { playlistsById } = getState().playlists;
		const { activePromos, playlistJuly2023PromoTimerExpiration } =
			getState().promos;

		const playlist = playlistsById?.[playlistId];

		if (!playlist?.playlist) {
			throw new Error('Playlist not found');
		}

		// if July 2023 promo is active and if playlist is user's own, report July 2023 timer promo pass
		if (
			playlist.playlist.userId !== getState().auth.userId ||
			!isPlaylistJuly2023PromoActive(
				activePromos,
				playlistJuly2023PromoTimerExpiration
			)
		)
			return;

		reportPlaylistJuly2023PromoPass()
			.then(() => {
				dispatch(setPromoActiveAction(PromoType.PlaylistJuly2023, false));

				dispatch(
					showModalAction(CONFIRMATION_MODAL, {
						size: 'md',
						title: 'Congratulations!',
						description: (
							<div>
								You've earned a lifetime 20% reduction on Sound Credit
								subscriptions for creating your first playlist. 🎉
								<br />
								<br />
								<strong>This exclusive benefit is available only now!</strong>
							</div>
						),
						confirmAction: {
							label: 'Claim Award',
							icon: 'fas fa-award',
							onClick: () => {
								dispatch(hideModal());
								dispatch(
									showModalAction(UPGRADE_TIER_MODAL, {
										size: 'xl',
										isNewUserOnboardingFlow: true,
									})
								);
							},
						},
						cancelAction: {
							label: 'No Thanks',
							onClick: () => {
								dispatch(
									showModalAction(CONFIRMATION_MODAL, {
										size: 'md',
										title: 'Are you sure?',
										description: (
											<div>
												You can always upgrade later, but you'll lose this award
												forever if you don't claim it right now!
											</div>
										),
										confirmAction: {
											label: 'Claim Award',
											icon: 'fas fa-award',
											onClick: () => {
												dispatch(hideAllModalsAction());
												dispatch(
													showModalAction(UPGRADE_TIER_MODAL, {
														size: 'xl',
														isNewUserOnboardingFlow: true,
													})
												);
											},
										},
										cancelAction: {
											label: "Yes, I'm sure",
											onClick: () => {
												dispatch(hideAllModalsAction());
											},
										},
									})
								);
							},
						},
					})
				);

				// show upgrade tier modal call to action
				// dispatch(
				// 	setPromoActiveAction(PromoType.NewUserDiscountJanuary2024, true)
				// );
			})
			.catch((error: any) => {
				console.error('Failed to report promo pass');
				_handlePromoError(error, dispatch);
			});
	};

export const extendPlaylistJuly2023PromoAction = () => ({
	type: EXTEND_PLAYLIST_JULY_2023_PROMO,
});

export const clearPromosAction = () => ({
	type: CLEAR_PROMOS,
});
