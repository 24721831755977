import React, { useEffect, useMemo, useState } from 'react';
import {
	useTable,
	useRowSelect,
	useGlobalFilter,
	useAsyncDebounce,
} from 'react-table';
import './SearchTable.scss';
import SearchBar from '../SearchBar';

const IndeterminateCheckbox = React.forwardRef(
	({ indeterminate, singleSelect = false, ...rest }, ref) => {
		const defaultRef = React.useRef();
		const resolvedRef = ref || defaultRef;

		React.useEffect(() => {
			resolvedRef.current.indeterminate = indeterminate;
		}, [resolvedRef, indeterminate]);

		return (
			<div className='checkbox-container'>
				{singleSelect ? (
					<input type='radio' ref={resolvedRef} {...rest} />
				) : (
					<input type='checkbox' ref={resolvedRef} {...rest} />
				)}
			</div>
		);
	}
);

function SearchTable({
	columns,
	data,
	onSelect = selectedRows => {},
	rowMapper = row => row,
	singleSelect = false,
	initialSelectedRowIds = {},
	memoizeInitialSelectedRowIds = true,
	searchBarPlaceholder = 'Search by Artist or Song Name',
}) {
	const [canEdit, setCanEdit] = useState(true);

	// Use the state and functions returned from useTable to build your UI

	const memoizedInitialSelectedRowIds = useMemo(
		() => initialSelectedRowIds,
		[initialSelectedRowIds]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		toggleAllRowsSelected,
		toggleRowSelected,
		selectedFlatRows,
		state: { selectedRowIds, globalFilter },
		setGlobalFilter,
	} = useTable(
		{
			columns,
			data,
			autoResetSelectedRows: false,
			autoResetHiddenColumns: false,
			autoResetGlobalFilter: false,
			initialState: {
				selectedRowIds: memoizeInitialSelectedRowIds
					? memoizedInitialSelectedRowIds
					: initialSelectedRowIds,
			},
		},
		useRowSelect,
		useGlobalFilter,
		hooks => {
			hooks.visibleColumns.push(columns => [
				// Let's make a column for selection
				{
					id: 'selection',
					// The header can use the table's getToggleAllRowsSelectedProps method
					// to render a checkbox
					Header: ({ getToggleAllRowsSelectedProps }) =>
						singleSelect ? (
							<div></div>
						) : (
							<div>
								<IndeterminateCheckbox
									{...getToggleAllRowsSelectedProps()}
									onClick={() => setCanEdit(true)}
								/>
							</div>
						),
					// The cell can use the individual row's getToggleRowSelectedProps method
					// to the render a checkbox
					Cell: ({ row }) => (
						<div>
							<IndeterminateCheckbox
								{...row.getToggleRowSelectedProps()}
								singleSelect={singleSelect}
								onClick={() => {
									setCanEdit(true);
									if (singleSelect) {
										toggleAllRowsSelected(false);
										toggleRowSelected(row.id, true);
									}
								}}
							/>
						</div>
					),
				},
				...columns,
			]);
		}
	);

	useEffect(() => {
		Object.entries(initialSelectedRowIds).forEach(([id, selected]) => {
			toggleRowSelected(id, selected);
		});
	}, [initialSelectedRowIds, toggleRowSelected]);

	useEffect(() => {
		console.log('selectedFlatRows', selectedFlatRows, selectedRowIds);

		if (canEdit) {
			onSelect(selectedFlatRows.map(rowMapper));
			setCanEdit(false);
		}
	}, [
		canEdit,
		onSelect,
		rowMapper,
		selectedFlatRows,
		selectedRowIds,
		setCanEdit,
	]);

	// Render the UI for your table
	return (
		<div className='h-100'>
			<GlobalFilter
				preGlobalFilteredRows={data}
				globalFilter={globalFilter}
				setGlobalFilter={setGlobalFilter}
				placeholder={searchBarPlaceholder}
			/>
			<div className='mt-2 search-table-container'>
				<table className='search-table' {...getTableProps()}>
					<thead>
						{headerGroups.map(headerGroup => (
							<tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column, index) => (
									<th {...column.getHeaderProps()}>
										{column.render('Header')}
									</th>
								))}
							</tr>
						))}
					</thead>
					<tbody
						className='search-table-body-container'
						{...getTableBodyProps()}
					>
						{rows.map(row => {
							prepareRow(row);
							return (
								<tr {...row.getRowProps()}>
									{row.cells.map((cell, index) => {
										return (
											<td {...cell.getCellProps()}>{cell.render('Cell')}</td>
										);
									})}
								</tr>
							);
						})}
					</tbody>
				</table>
			</div>
		</div>
	);
}

function GlobalFilter({
	preGlobalFilteredRows,
	globalFilter,
	setGlobalFilter,
	placeholder,
}) {
	const [value, setValue] = React.useState(globalFilter);
	const onChange = useAsyncDebounce(value => {
		setGlobalFilter(value || undefined);
	}, 200);

	return (
		<SearchBar
			value={value || ''}
			onChange={val => {
				setValue(val);
				onChange(val);
			}}
			placeholder={placeholder}
			fullWidth
		/>
	);
}

export default SearchTable;
