enum ExportType {
	LABEL_STYLE_SINGLE = 'LABEL_STYLE_SINGLE',
	LABEL_STYLE_RELEASE_LONG = 'LABEL_STYLE_RELEASE_LONG',
	LABEL_STYLE_RELEASE_REGULAR = 'LABEL_STYLE_RELEASE_REGULAR',
	LYRIC_SHEET = 'LYRIC_SHEET',
	CD_INSERT_SHORT = 'CD_INSERT_SHORT',
	CD_INSERT_LONG = 'CD_INSERT_LONG',
	LABEL_STYLE_PERSONNEL_SPREADSHEET = 'LABEL_STYLE_PERSONNEL_SPREADSHEET',
	SOUND_CREDIT_SINGLE = 'SOUND_CREDIT_SINGLE',
	SOUND_CREDIT_RELEASE = 'SOUND_CREDIT_RELEASE',
	VINYL_SLEEVE_LONG = 'VINYL_SLEEVE_LONG',
	VINYL_SLEEVE_SHORT = 'VINYL_SLEEVE_SHORT',
	METADATA_MASTERING = 'METADATA_MASTERING',
	ALL_MUSIC_SPREADSHEET = 'ALL_MUSIC_SPREADSHEET',
	THE_ORCHARD_SPREADSHEET = 'THE_ORCHARD_SPREADSHEET',
	SOUND_EXCHANGE_SPREADSHEET = 'SOUND_EXCHANGE_SPREADSHEET',
	MLC_SPREADSHEET = 'MLC_SPREADSHEET',
	AFM_B4 = 'AFM_B4',
	AFM_B9 = 'AFM_B9',
	SYNCHTANK = 'SYNCHTANK',
	SAG_AFTRA = 'SAG_AFTRA',
	PUBLISHING_LABEL_COPY_DOCUMENT = 'PUBLISHING_LABEL_COPY_DOCUMENT',
	PUBLISHING_LABEL_COPY_SPREADSHEET = 'PUBLISHING_LABEL_COPY_SPREADSHEET',
	BIG_MACHINE_SINGLE = 'BIG_MACHINE_SINGLE',
	BIG_MACHINE_ALBUM = 'BIG_MACHINE_ALBUM',
	INSTAGRAM_IMAGE = 'INSTAGRAM_IMAGE',
	STORIES = 'STORIES',
	FACEBOOK_TWITTER_IMAGE = 'FACEBOOK_TWITTER_IMAGE',
	CD_BABY_EXPORT_LONG = 'CD_BABY_EXPORT_LONG',
	CD_BABY_EXPORT_SHORT = 'CD_BABY_EXPORT_SHORT',
	SOUND_CREDIT_EMAIL = 'SOUND_CREDIT_EMAIL',
	EBR_STANDARD_TEMPLATE = 'EBR_STANDARD_TEMPLATE',
	PPL_REGISTRATION = 'PPL_REGISTRATION',
	PRODUCER_ROYALTY_AGREEMENT = 'PRODUCER_ROYALTY_AGREEMENT',
	SPLIT_SHEET_STANDARD = 'SPLIT_SHEET_STANDARD',
	SPLIT_SHEET_SINGLE_WRITER = 'SPLIT_SHEET_SINGLE_WRITER',
	PRODUCER_AGREEMENT = 'PRODUCER_AGREEMENT',
	SIDE_ARTIST_AGREEMENT = 'SIDE_ARTIST_AGREEMENT',
	RIN_EXPORT = 'RIN_EXPORT',
	INDIE_UPLOAD = 'INDIE_UPLOAD',
	DIGITAL_DISTRIBUTION = 'DIGITAL_DISTRIBUTION',
	DRIP_IMAGES = 'DRIP_IMAGES',
}

export default ExportType;
