import { useEffect } from 'react';

export function useDebounceEffect(
	fn: Function,
	waitTime: number,
	dependencies: any[] = []
) {
	useEffect(() => {
		const t = setTimeout(() => {
			fn.apply(undefined, dependencies);
		}, waitTime);

		return () => {
			clearTimeout(t);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, dependencies);
}
