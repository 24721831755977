import React, { useMemo } from "react";
import { Placeholder } from "react-bootstrap";
import styles from "./Image.module.scss";
import clsx from "clsx";

export type ImageProps = {
	src?: string | null;
	alt: string;
	className?: string;
	style?: React.CSSProperties;
	height?: string | number;
	width?: string | number;
	objectFit?: "cover" | "contain" | "fill" | "none" | "scale-down";
	borderRadius?: string | number;
	forcePlaceholder?: boolean;
	[key: string]: any;
};

const Image = ({
	src,
	alt,
	className = "",
	style = {},
	height,
	width,
	borderRadius,
	objectFit,
	forcePlaceholder = false,
	...props
}: ImageProps) => {
	const [loaded, setLoaded] = React.useState(false);

	const _style = useMemo(() => {
		let __style = { ...style };

		if (height) {
			__style = { ...__style, height };
		}
		if (width) {
			__style = { ...__style, width };
		}
		if (borderRadius) {
			__style = { ...__style, borderRadius };
		}
		if (objectFit) {
			__style = { ...__style, objectFit };
		}

		return __style;
	}, [height, width, style, borderRadius, objectFit]);

	return (
		<>
			{(!loaded || forcePlaceholder) && (
				<Placeholder
					as="div"
					className={`p-0 ${className}`}
					style={_style}
					animation="wave"
					// xs={12}
				>
					<Placeholder className="h-100 w-100" />
				</Placeholder>
			)}
			<img
				style={loaded ? _style : { ..._style, display: "none" }}
				className={clsx(loaded ? styles.loaded : styles.loading, className)}
				src={src ?? ""}
				alt={alt}
				onLoad={() => setLoaded(true)}
				{...props}
			/>
		</>
	);
};

export default Image;
