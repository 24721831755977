import React, { ReactElement, useCallback } from 'react';
import { ListGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import IconButton from '../IconButton';
import theme from '../../../theme.module.scss';

export type SortOption<T> = {
	name: string;
	compareFn: (
		reverse: boolean
	) => (a: ReactElement<T>, b: ReactElement<T>) => number;
};

export type SortOptions<T> = Record<string, SortOption<T>>;

export type SortMenuProps<T> = {
	sortOptions: SortOptions<T>;
	setActiveSortKey: React.Dispatch<React.SetStateAction<string>>;
	activeSortKey: string;
	defaultActiveKey?: string;
	ascending: boolean;
	setAscending: React.Dispatch<React.SetStateAction<boolean>>;
	label?: string;
};

// Receives an object of sorting options (sortingOptions) with the following properties:
// key: the key of the option to be displayed
// each key has an object with the following properties:
// name: the name of the option to be displayed
// compareFn: a function that returns -1, 0, or 1 depending on whether the first argument is less than, equal to, or greater than the second argument
// Also receives a function called setSortedList, which is a React state setter that sets the list to be sorted
const SortMenu = <T extends unknown>({
	sortOptions,
	setActiveSortKey,
	activeSortKey,
	defaultActiveKey,
	ascending,
	setAscending,
	label = 'Sort by: ',
}: SortMenuProps<T>) => {
	const sortingMenu = (props: any) => (
		<Popover style={{ zIndex: 3000 }} {...props}>
			<ListGroup
				activeKey={activeSortKey}
				defaultActiveKey={defaultActiveKey ?? Object.keys(sortOptions)[0]}
			>
				{Object.entries(sortOptions).map(([sortKey, sortOption]) => (
					<ListGroup.Item
						key={sortOption.name}
						onClick={() => {
							setActiveSortKey(sortKey);
							document.body.click();
						}}
						action
						eventKey={sortOption.name}
						className={
							activeSortKey === sortKey
								? 'fw-600 background-purple text-white'
								: ''
						}
						style={{ fontSize: '0.8rem' }}
					>
						{sortOption.name}
					</ListGroup.Item>
				))}
			</ListGroup>
		</Popover>
	);

	const toggleAscending = useCallback(() => {
		setAscending(prevAscending => !prevAscending);
	}, [setAscending]);

	return (
		<div
			className='d-flex fw-500 align-items-center'
			style={{ fontSize: '0.9rem' }}
		>
			<IconButton
				icon={`fas ${
					ascending ? 'fa-sort-amount-up-alt' : 'fa-sort-amount-down'
				}`}
				onClick={toggleAscending}
				color={theme.primary}
				className='mr-2'
				tooltipText={`Change sort order to ${
					ascending ? 'descending' : 'ascending'
				} `}
			/>
			<span className='mr-1'>{label}</span>
			<OverlayTrigger
				placement='bottom'
				trigger='click'
				rootClose
				overlay={sortingMenu}
			>
				<div className='text-purple fw-600 cursor-pointer no-highlight'>
					{sortOptions[activeSortKey].name}
				</div>
			</OverlayTrigger>
		</div>
	);
};

export default SortMenu;
