import { getAssetPath } from '@/helpers/assetTools';
import React from 'react';

const ProjectTypeIcon = ({ isAlbum }) => {
	return isAlbum ? (
		<div className='d-flex justify-content-center align-items-center mr-2'>
			<img
				src={getAssetPath('img/album-black-24-dp.svg')}
				alt='album'
				draggable='false'
			/>
		</div>
	) : (
		<img
			className='mr-2'
			src={getAssetPath('img/music-video-black-24-dp.svg')}
			alt='recording'
			draggable='false'
		/>
	);
};

export default ProjectTypeIcon;
