import projectsApi from '../projectsApi';
import { checkInvalidToken } from '../../helpers/checkInvalidToken';
import axiosRetry from 'axios-retry';
import { shouldRetry } from '../../helpers/networkTools';

axiosRetry(projectsApi, {
	retries: 3,
	retryDelay: retryCount => {
		return retryCount * 1000;
	},
	shouldResetTimeout: true,
	retryCondition: shouldRetry,
});

// TODO: Migrate to camelCase
// TODO: Add response type
export const getRecordingEditors = (recordingId: number) =>
	projectsApi
		.get<{
			editors: ApiRecordingEditor[];
		}>('/recording/editor', {
			params: {
				recording_id: recordingId,
			},
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

// TODO: Migrate to camelCase
// TODO: Add response type
export const addRecordingEditor = ({
	recording_id,
	editor_email,
	message,
	is_read_only,
}: {
	recording_id: number;
	editor_email: string;
	message: string;
	is_read_only: boolean;
}) =>
	projectsApi
		.post('/recording/editor', {
			recording_id,
			editor_email,
			message,
			is_read_only,
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

// TODO: Migrate to camelCase
// TODO: Add response type
export const deleteRecordingEditor = ({
	recording_id,
	editor_id,
	editor_email,
}: {
	recording_id: number;
	editor_id: number;
	editor_email: string;
}) =>
	projectsApi
		.delete('/recording/editor', {
			params: {
				recording_id,
				editor_id,
				editor_email,
			},
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

// TODO: Add response type
export const checkActiveEditor = (recordingId: number) =>
	projectsApi
		.get<{
			active_user_name: string;
			is_active: boolean;
			updated_at: string;
		}>('/recording/editor/check-active', {
			params: {
				recording_id: recordingId,
			},
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

// TODO: Add response type
export const acceptRecordingInvite = ({
	editorId,
	recordingId,
}: {
	editorId: number;
	recordingId: number;
}) =>
	projectsApi
		.put('/recording/editor/accept-invite', {
			editor_id: editorId,
			recording_id: recordingId,
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

// TODO: Add response type
export const declineRecordingInvite = ({
	editorId,
	recordingId,
}: {
	editorId: number;
	recordingId: number;
}) =>
	projectsApi
		.put('/recording/editor/decline-invite', {
			editor_id: editorId,
			recording_id: recordingId,
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

// TODO: Add response type
export const claimActiveEditor = (editorId: number, recordingId: number) =>
	projectsApi
		.put('/recording/editor/claim-active', {
			editor_id: editorId,
			recording_id: recordingId,
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

// TODO: Add response type
export const changeRecordingReadOnly = ({
	editorId,
	recordingId,
	readOnly = false,
}: {
	editorId: number;
	recordingId: number;
	readOnly?: boolean;
}) =>
	projectsApi
		.put('/recording/editor/change-read-only', {
			editor_id: editorId,
			recording_id: recordingId,
			read_only: readOnly,
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

// TODO: Add response type
export const changeRecordingOwner = ({
	editorId,
	recordingId,
}: {
	editorId: number;
	recordingId: number;
}) =>
	projectsApi
		.put('/recording/editor/change-owner', {
			editor_id: editorId,
			recording_id: recordingId,
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

// TODO: Add response type
export const getAlbumEditors = (albumId: number) =>
	projectsApi
		.get<{
			editors: ApiAlbumEditor[];
		}>('/album/editor?album_id', {
			params: {
				album_id: albumId,
			},
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

// TODO: Migrate to camelCase
// TODO: Add response type
export const addAlbumEditor = ({
	album_id,
	editor_email,
	message,
	is_read_only,
}: {
	album_id: number;
	editor_email: string;
	message: string;
	is_read_only: boolean;
}) =>
	projectsApi
		.post('/album/editor', {
			album_id,
			editor_email,
			message,
			is_read_only,
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

// TODO: Migrate to camelCase
// TODO: Add response type
export const deleteAlbumEditor = ({
	album_id,
	editor_id,
	editor_email,
}: {
	album_id: number;
	editor_id: number;
	editor_email: string;
}) =>
	projectsApi
		.delete('/album/editor', {
			params: {
				album_id,
				editor_id,
				editor_email,
			},
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

// TODO: Add response type
export const checkAlbumEditor = (albumId: number) =>
	projectsApi
		.get('/album/editor/check-active', {
			params: {
				album_id: albumId,
			},
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

// TODO: Add response type
export const acceptAlbumInvite = ({
	editorId,
	albumId,
}: {
	editorId: number;
	albumId: number;
}) =>
	projectsApi
		.put('/album/editor/accept-invite', {
			editor_id: editorId,
			album_id: albumId,
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

// TODO: Add response type
export const declineAlbumInvite = ({
	editorId,
	albumId,
}: {
	editorId: number;
	albumId: number;
}) =>
	projectsApi
		.put('/album/editor/decline-invite', {
			editor_id: editorId,
			album_id: albumId,
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

// TODO: Add response type
export const changeAlbumReadOnly = ({
	editorId,
	albumId,
	readOnly = false,
}: {
	editorId: number;
	albumId: number;
	readOnly?: boolean;
}) =>
	projectsApi
		.put('/album/editor/change-read-only', {
			editor_id: editorId,
			album_id: albumId,
			read_only: readOnly,
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

// TODO: Add response type
export const changeAlbumOwner = ({
	editorId,
	albumId,
}: {
	editorId: number;
	albumId: number;
}) =>
	projectsApi
		.put('/album/editor/change-owner', {
			editor_id: editorId,
			album_id: albumId,
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});
