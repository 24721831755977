export const FETCH_RECORDING_REQUEST = 'FETCH_RECORDING_REQUEST';
export const UPDATE_CURRENT_RECORDING_REQUEST =
	'UPDATE_CURRENT_RECORDING_REQUEST';
export const CREATE_NEW_RECORDING_REQUEST = 'CREATE_NEW_RECORDING_REQUEST';
export const DELETE_RECORDING_REQUEST = 'DELETE_RECORDING_REQUEST';

export const GET_PROJECT_USAGE_REQUEST = 'GET_PROJECT_USAGE_REQUEST';
export const GET_STORAGE_USAGE_REQUEST = 'GET_STORAGE_USAGE_REQUEST';

export const FETCH_RECORDING_EDITORS_REQUEST =
	'FETCH_RECORDING_EDITORS_REQUEST';
export const FETCH_ALBUM_REQUEST = 'FETCH_ALBUM_REQUEST';
export const UPDATE_ALBUM_REQUEST = 'UPDATE_ALBUM_REQUEST';
export const CREATE_NEW_ALBUM_REQUEST = 'CREATE_NEW_ALBUM_REQUEST';
export const ADD_RECORDINGS_TO_ALBUM_REQUEST =
	'ADD_RECORDINGS_TO_ALBUM_REQUEST';
export const REMOVE_RECORDING_FROM_ALBUM_REQUEST =
	'REMOVE_RECORDING_FROM_ALBUM_REQUEST';
export const DELETE_ALBUM_REQUEST = 'DELETE_ALBUM_REQUEST';
export const REORDER_ALBUM_RECORDINGS_REQUEST =
	'REORDER_ALBUM_RECORDINGS_REQUEST';
export const FETCH_ALBUM_EDITORS_REQUEST = 'FETCH_ALBUM_EDITORS_REQUEST';
export const CHANGE_ALBUM_OWNER_REQUEST = 'CHANGE_ALBUM_OWNER_REQUEST';

export const FETCH_USER_PROJECTS_REQUEST = 'FETCH_USER_PROJECTS_REQUEST';
export const FETCH_USER_EDITABLE_PROJECTS_REQUEST =
	'FETCH_USER_EDITABLE_PROJECTS_REQUEST';
export const SEARCH_USER_PROJECTS_REQUEST = 'SEARCH_USER_PROJECTS_REQUEST';

export const FETCH_RECORDING_EXPORTS_REQUEST =
	'FETCH_RECORDING_EXPORTS_REQUEST';
export const FETCH_RECORDING_BY_ID_REQUEST = 'FETCH_RECORDING_BY_ID_REQUEST';
export const FETCH_ALBUM_EXPORTS_REQUEST = 'FETCH_ALBUM_EXPORTS_REQUEST';
export const DELETE_EXPORT_REQUEST = 'DELETE_RECORDING_EXPORT_REQUEST';
export const CREATE_EXPORT_REQUEST = 'CREATE_EXPORT_REQUEST';
export const DOWNLOAD_EXPORT_REQUEST = 'DOWNLOAD_EXPORT_REQUEST';
export const EXPORT_TO_RIN_REQUEST = 'EXPORT_TO_RIN_REQUEST';
export const IMPORT_RIN_REQUEST = 'IMPORT_RIN_REQUEST';
export const GET_LATEST_CONTRACT_NUMBER_REQUEST =
	'GET_LATEST_CONTRACT_NUMBER_REQUEST';
export const SET_LATEST_CONTRACT_NUMBER_REQUEST =
	'SET_LATEST_CONTRACT_NUMBER_REQUEST';
export const GET_DRIP_IMAGE_EXPORT_REQUEST = 'GET_DRIP_IMAGE_EXPORT_REQUEST';

export const CHANGE_RECORDING_OWNER_REQUEST = 'CHANGE_RECORDING_OWNER_REQUEST';

export const UPDATE_FILE_METADATA_REQUEST = 'UPDATE_FILE_METADATA_REQUEST';
export const UPLOAD_FILE_METADATA_REQUEST = 'UPLOAD_FILE_METADATA_REQUEST';
export const DELETE_FILES_REQUEST = 'DELETE_FILES_REQUEST';
export const GET_PROJECT_FILES_METADATA_REQUEST =
	'GET_PROJECT_FILES_METADATA_REQUEST';
export const GET_FILE_METADATA_REQUEST = 'GET_FILE_METADATA_REQUEST';
export const GENERATE_TEMP_SHARE_REQUEST = 'GENERATE_TEMP_SHARE_REQUEST';
export const GET_FILE_LABEL_DETAILS_REQUEST = 'GET_FILE_LABEL_DETAILS_REQUEST';
export const ADD_FILE_COVER_IMAGE_REQUEST = 'ADD_FILE_COVER_IMAGE_REQUEST';
export const UPDATE_FILE_VERSION_METADATA_REQUEST =
	'UPDATE_FILE_VERSION_METADATA_REQUEST';
export const UPLOAD_FILE_VERSION_METADATA_REQUEST =
	'UPLOAD_FILE_VERSION_METADATA_REQUEST';
export const GET_FILE_VERSION_METADATA_REQUEST =
	'GET_FILE_VERSION_METADATA_REQUEST';
export const DELETE_FILE_VERSION_REQUEST = 'DELETE_FILE_VERSION_REQUEST';

export const GET_NOTIFICATIONS_REQUEST = 'GET_NOTIFICATIONS_REQUEST';
export const DELETE_NOTIFICATION_REQUEST = 'DELETE_NOTIFICATION_REQUEST';
export const READ_NOTIFICATION_REQUEST = 'READ_NOTIFICATION_REQUEST';

/*
 * Profile request types
 */
export const FETCH_USER_PROFILES_REQUEST = 'FETCH_USER_PROFILES_REQUEST';
export const FETCH_FULL_PROFILE_REQUEST = 'FETCH_FULL_PROFILE_REQUEST';

export const FETCH_USER_DATA_REQUEST = 'FETCH_USER_DATA_REQUEST';

/*
 * Playlist request types
 */
export const FETCH_USER_PLAYLISTS_REQUEST = 'FETCH_USER_PLAYLISTS_REQUEST';
export const FETCH_PLAYLIST_REQUEST = 'FETCH_PLAYLIST_REQUEST';
export const FETCH_PLAYLIST_STAT_REQUEST = 'FETCH_PLAYLIST_STAT_REQUEST';
export const CREATE_PLAYLIST_REQUEST = 'CREATE_PLAYLIST_REQUEST';
export const UPDATE_PLAYLIST_REQUEST = 'UPDATE_PLAYLIST_REQUEST';
export const DELETE_PLAYLIST_REQUEST = 'DELETE_PLAYLIST_REQUEST';
export const DELETE_PLAYLIST_FILE_REQUEST = 'DELETE_PLAYLIST_FILE_REQUEST';
export const CHANGE_PLAYLIST_FILE_NAMES_REQUEST =
	'CHANGE_PLAYLIST_FILE_NAMES_REQUEST';
export const REORDER_PLAYLIST_FILES_REQUEST = 'REORDER_PLAYLIST_FILES_REQUEST';
export const ADD_PLAYLIST_COVER_IMAGE_REQUEST =
	'ADD_PLAYLIST_COVER_IMAGE_REQUEST';
export const ADD_PLAYLIST_HEADER_IMAGE_REQUEST =
	'ADD_PLAYLIST_HEADER_IMAGE_REQUEST';
export const PUBLISH_DRIP_REQUEST = 'PUBLISH_DRIP_REQUEST';
export const UNPUBLISH_DRIP_REQUEST = 'UNPUBLISH_DRIP_REQUEST';
export const DUPLICATE_PLAYLIST_REQUEST = 'DUPLICATE_PLAYLIST_REQUEST';
export const GENERATE_DRIP_STATS_AI_ANALYSIS_REQUEST =
	'GENERATE_DRIP_STATS_AI_ANALYSIS_REQUEST';
export const ACCEPT_PLAYLIST_EDITOR_INVITE_REQUEST =
	'ACCEPT_PLAYLIST_EDITOR_INVITE_REQUEST';
export const FETCH_PLAYLIST_EDITORS_REQUEST = 'FETCH_PLAYLIST_EDITORS_REQUEST';
export const ACCEPT_PLAYLIST_TRANSFER_INVITE_REQUEST =
	'ACCEPT_PLAYLIST_TRANSFER_INVITE_REQUEST';
export const ADD_PLAYLIST_EDITOR_REQUEST = 'ADD_PLAYLIST_EDITOR_REQUEST';
export const REMOVE_PLAYLIST_EDITOR_REQUEST = 'REMOVE_PLAYLIST_EDITOR_REQUEST';
export const CREATE_PLAYLIST_FILE_GROUP_REQUEST =
	'CREATE_PLAYLIST_FILE_GROUP_REQUEST';
export const DELETE_PLAYLIST_FILE_GROUP_REQUEST =
	'DELETE_PLAYLIST_FILE_GROUP_REQUEST';
export const RENAME_PLAYLIST_FILE_GROUP_REQUEST =
	'RENAME_PLAYLIST_FILE_GROUP_REQUEST';
export const REORDER_PLAYLIST_FILE_GROUPS_REQUEST =
	'REORDER_PLAYLIST_FILE_GROUPS_REQUEST';
export const UPDATE_PLAYLIST_FILE_GROUP_FILES_REQUEST =
	'UPDATE_PLAYLIST_FILE_GROUP_FILES_REQUEST';
export const UPDATE_PLAYLIST_SUB_GROUPS_REQUEST =
	'UPDATE_PLAYLIST_SUB_GROUPS_REQUEST';
