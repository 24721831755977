import { Modal } from 'react-bootstrap';
import { Formik, Field } from 'formik';
import Button from '../../../layout/Button';

import { convertOtherAliasesToArray } from '../../../../helpers/participantTools';

const TableRow = ({ alias, i }: { alias: string; i: number }) => {
	return (
		<tr>
			<td>
				<Field
					style={{ width: '1rem', height: '1rem' }}
					type='radio'
					name='checked'
					value={`${i}`}
				/>
			</td>
			<td>{alias}</td>
		</tr>
	);
};

export type SelectCreditedNameModalProps = {
	show: boolean;
	toggleShow: () => void;
	toggleParticipantModal: () => void;
	setInitialCreditedName: (creditedName: string) => void;
	profile: LocalProfile | null;
};

const SelectCreditedNameModal = ({
	show,
	toggleShow,
	toggleParticipantModal,
	setInitialCreditedName,
	profile,
}: SelectCreditedNameModalProps) => {
	const handleSubmit = (index: number) => {
		const selectedCreditedName = aliases[index] ?? '';
		console.log(selectedCreditedName);

		setInitialCreditedName(selectedCreditedName);
		toggleShow();
		toggleParticipantModal();
	};

	const aliases = profile?.profile
		? [
				...convertOtherAliasesToArray(profile.profile.otherAliases),
				profile.profile.legalName,
		  ]
		: [];

	return (
		<Formik
			onSubmit={values =>
				handleSubmit(values.checked ? parseInt(values.checked) : 0)
			}
			initialValues={{ checked: '0' }}
		>
			{formik => (
				<form onSubmit={formik.handleSubmit}>
					<Modal size={undefined} show={show} onHide={toggleShow} centered>
						<Modal.Header closeButton>
							<Modal.Title>
								<h2>SELECT CREDITED NAME</h2>
							</Modal.Title>
						</Modal.Header>
						<Modal.Body className='p-5'>
							<h3 className='w-100' style={{ fontWeight: 'bold' }}>
								Select the name to appear in credits
							</h3>
							<hr />
							<table className='search-table'>
								<tbody>
									{aliases &&
										aliases.length &&
										aliases.map((alias, i) => (
											<TableRow key={i} alias={alias} i={i} />
										))}
								</tbody>
							</table>
						</Modal.Body>
						<Modal.Footer>
							<Button label='Close' onClick={() => toggleShow()} />
							<Button
								label='Next'
								theme='dark'
								type='submit'
								onClick={() => formik.submitForm()}
							/>
						</Modal.Footer>
					</Modal>
				</form>
			)}
		</Formik>
	);
};

export default SelectCreditedNameModal;
