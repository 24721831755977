import React, { useCallback, useEffect } from 'react';
import BasicInfoStep, { BasicInfoFormikValues } from './Steps/BasicInfoStep';
import CareerStep, { CareerFormikValues } from './Steps/CareerStep';
import PriorWorkStep, { PriorWorkFormikValues } from './Steps/PriorWorkStep';
import SearchStep from './Steps/SearchStep';
import WorkRelationStep, {
	WorkRelationFormikValues,
} from './Steps/WorkRelationStep';
import RequestIsniStep from './Steps/RequestIsniStep';
import ReviewInfoStep from './Steps/ReviewInfoStep';
import { Modal } from 'react-bootstrap';
import { useAppDispatch } from '../../../store/hooks';
import { hideModal, setModalTitle } from '../../../store/modal/actions';
import './IsniRegistration.scss';
import { Steps } from 'primereact/steps';
import isniStrings, { IsniString, getIsniString } from './isniStrings';
import { Divider } from 'primereact/divider';
import IsniRetrievedStep from './Steps/IsniRetrievedStep';
import { assignIsniToProfileAction } from '../../../store/profiles/actions';
import { assignIsniToRecordingParticipantAction } from '../../../store/projects/actions';
import { claimUserIsniAction } from '../../../store/user/actions';
import IsniService from '../../../api/services/isniService';
import IsniCreatedStep from './Steps/IsniCreatedStep';
import { Helmet } from 'react-helmet';

export type IsniRegistrationProps = {
	profileId?: string;
	recordingId?: number;
	participantId?: number;
	onAssignIsni?: (isni: string) => Promise<void>;
};

export enum IsniRegistrationFormStep {
	BASIC_INFO,
	SEARCH,
	PRIOR_WORK,
	CAREER,
	WORK_RELATION,
	REVIEW_INFO,
	REQUEST_ISNI,
	ISNI_RETRIEVED,
	ISNI_CREATED,
}

const IsniRegistration = ({
	profileId,
	recordingId,
	participantId,
	onAssignIsni,
}: IsniRegistrationProps) => {
	const isUserClaimingIsni =
		!profileId && !recordingId && !participantId && !onAssignIsni;
	const dispatch = useAppDispatch();
	const [formStep, setFormStep] = React.useState<IsniRegistrationFormStep>(
		IsniRegistrationFormStep.BASIC_INFO
	);

	const [basicInfoForm, setBasicInfoForm] =
		React.useState<BasicInfoFormikValues | null>(null);

	const [claimedIsni, setClaimedIsni] = React.useState<string | null>(null);
	const [isniCertificateLink, setIsniCertificateLink] = React.useState<
		string | null
	>(null);

	const [isniSearchResults, setIsniSearchResults] = React.useState<
		string[] | null
	>(null);

	const [priorWorkForm, setPriorWorkForm] =
		React.useState<PriorWorkFormikValues | null>(null);

	const [careerForm, setCareerForm] = React.useState<CareerFormikValues | null>(
		null
	);

	const [workRelationForm, setWorkRelationForm] =
		React.useState<WorkRelationFormikValues | null>(null);

	const isMounted = React.useRef<boolean | null>(null);

	React.useEffect(() => {
		isMounted.current = true;

		return () => {
			isMounted.current = false;
		};
	}, []);

	const _getIsniString = useCallback(
		(isniString: IsniString) => getIsniString(isniString, isUserClaimingIsni),
		[isUserClaimingIsni]
	);

	const handleBasicInfoSubmit = useCallback(
		async (values: BasicInfoFormikValues) => {
			setBasicInfoForm(values);
			setFormStep(IsniRegistrationFormStep.SEARCH);
		},
		[]
	);

	const handleSearchSubmit = useCallback(async (isni?: string) => {
		if (isni) {
			setClaimedIsni(isni);
			setFormStep(IsniRegistrationFormStep.ISNI_RETRIEVED);
			return;
		}

		setFormStep(IsniRegistrationFormStep.PRIOR_WORK);
	}, []);

	const handlePriorWorkSubmit = useCallback(
		async (values: PriorWorkFormikValues) => {
			setPriorWorkForm(values);
			setFormStep(IsniRegistrationFormStep.CAREER);
		},
		[]
	);

	const handleCareerSubmit = useCallback(async (values: CareerFormikValues) => {
		setCareerForm(values);
		setFormStep(IsniRegistrationFormStep.WORK_RELATION);
	}, []);

	const handleWorkRelationSubmit = useCallback(
		async (values: WorkRelationFormikValues) => {
			setWorkRelationForm(values);
			setFormStep(IsniRegistrationFormStep.REVIEW_INFO);
		},
		[]
	);

	const skipToRequestIsniStep = useCallback(async () => {
		setFormStep(IsniRegistrationFormStep.REQUEST_ISNI);
	}, []);

	const handleRequestIsniSubmit = useCallback(async () => {
		setFormStep(IsniRegistrationFormStep.ISNI_CREATED);
	}, []);

	const handleAssignIsni = useCallback(
		async () =>
			await new Promise<void>(async (resolve, reject) => {
				if (!claimedIsni) {
					reject('No claimed isni');
					return;
				}

				if (isUserClaimingIsni) {
					await dispatch(claimUserIsniAction(claimedIsni));
					resolve();
				} else if (profileId) {
					await dispatch(assignIsniToProfileAction(profileId, claimedIsni));
				} else if (recordingId && participantId) {
					await dispatch(
						assignIsniToRecordingParticipantAction({
							recordingId,
							participantId,
							isni: claimedIsni,
						})
					);
				}

				if (onAssignIsni) {
					await onAssignIsni(claimedIsni);
					resolve();
				}
			}),
		[
			claimedIsni,
			dispatch,
			isUserClaimingIsni,
			participantId,
			profileId,
			recordingId,
			onAssignIsni,
		]
	);

	const handleGenerateIsni = useCallback(async () => {
		return new Promise<void>(async (resolve, reject) => {
			if (
				!basicInfoForm ||
				!priorWorkForm ||
				!careerForm ||
				!workRelationForm
			) {
				reject('Missing form data');
				return;
			}

			const tokenRes = await IsniService.generateToken({
				requestingIsniForSelf: isUserClaimingIsni,
				forename: basicInfoForm.firstName,
				surname:
					`${basicInfoForm.middleName} ${basicInfoForm.lastName} ${basicInfoForm.suffix}`.trim(),
				birthdate: basicInfoForm.birthdate,
				artistName: priorWorkForm.artistName,
				role: priorWorkForm.role as IsniRole,
				title: priorWorkForm.title,
				websiteAddress: careerForm.websiteAddress,
				websiteTitle: careerForm.websiteTitle,
				// we do not support ISNI requests for organizations currently, so we hardcode
				// this value to 'person'
				personOrOrganization: 'person',
				relatedForename: workRelationForm.relatedForename,
				relatedSurname: workRelationForm.relatedSurname,
				isnotIsniList: isniSearchResults ?? [],
				mainName: null,
				relatedOrganizationName: workRelationForm.relatedOrganizationName,
			});

			const RETRY_DURATION = 4 * 60 * 1000; // 4 minutes
			const RETRY_DELAY = 5 * 1000; // 5 seconds

			const startTime = new Date().getTime();
			let retryGenerate = true;

			while (retryGenerate && isMounted.current) {
				try {
					const isniRes = await IsniService.retrieveIsni(
						tokenRes.data.isniToken
					);

					setClaimedIsni(isniRes.data.isni);
					setIsniCertificateLink(isniRes.data.isniCertificate);
					retryGenerate = false;
					resolve();
				} catch (err: any) {
					// If it's a 404, try again (up to 4 minutes of retrying)
					if (err.response?.status === 404) {
						if (new Date().getTime() - startTime < RETRY_DURATION) {
							console.debug('Retrying generate isni');

							await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));

							continue;
						} else {
							await IsniService.reportTimeout(tokenRes.data.isniToken);
						}
					}

					retryGenerate = false;
					// otherwise, throw the error
					console.error('Error generating isni', err);
					reject(err);
				}
			}
		});
	}, [
		basicInfoForm,
		careerForm,
		isUserClaimingIsni,
		priorWorkForm,
		workRelationForm,
		isniSearchResults,
	]);

	const getBackFn = useCallback((_formStep: IsniRegistrationFormStep) => {
		switch (_formStep) {
			case IsniRegistrationFormStep.BASIC_INFO:
				throw new Error('Cannot go back from basic info step');
			case IsniRegistrationFormStep.SEARCH:
				return () => setFormStep(IsniRegistrationFormStep.BASIC_INFO);
			case IsniRegistrationFormStep.PRIOR_WORK:
				return (values: PriorWorkFormikValues) => {
					setPriorWorkForm(values);
					setFormStep(IsniRegistrationFormStep.SEARCH);
				};
			case IsniRegistrationFormStep.CAREER:
				return (values: CareerFormikValues) => {
					setCareerForm(values);
					setFormStep(IsniRegistrationFormStep.PRIOR_WORK);
				};
			case IsniRegistrationFormStep.WORK_RELATION:
				return (values: WorkRelationFormikValues) => {
					setWorkRelationForm(values);
					setFormStep(IsniRegistrationFormStep.CAREER);
				};
			case IsniRegistrationFormStep.REVIEW_INFO:
				return () => setFormStep(IsniRegistrationFormStep.WORK_RELATION);
			case IsniRegistrationFormStep.REQUEST_ISNI:
				throw new Error('Cannot go back from request isni step');
			case IsniRegistrationFormStep.ISNI_RETRIEVED:
				throw new Error('Cannot go back from isni retrieved step');
			default:
				throw new Error('Unknown form step');
		}
	}, []);

	const renderStep = useCallback(() => {
		switch (formStep) {
			case IsniRegistrationFormStep.BASIC_INFO:
				return (
					<BasicInfoStep
						onSubmit={handleBasicInfoSubmit}
						getIsniStringFn={_getIsniString}
						initialFormValues={basicInfoForm}
					/>
				);
			case IsniRegistrationFormStep.SEARCH:
				return (
					<SearchStep
						onSubmit={handleSearchSubmit}
						getIsniStringFn={_getIsniString}
						onBack={getBackFn(IsniRegistrationFormStep.SEARCH) as () => void}
						searchFields={{
							forename: basicInfoForm?.firstName ?? '',
							surname: basicInfoForm?.lastName ?? '',
						}}
						setIsniResults={setIsniSearchResults}
					/>
				);
			case IsniRegistrationFormStep.PRIOR_WORK:
				return (
					<PriorWorkStep
						onSubmit={handlePriorWorkSubmit}
						getIsniStringFn={_getIsniString}
						onBack={
							getBackFn(IsniRegistrationFormStep.PRIOR_WORK) as (
								values: PriorWorkFormikValues
							) => void
						}
						initialFormValues={priorWorkForm}
					/>
				);
			case IsniRegistrationFormStep.CAREER:
				return (
					<CareerStep
						onBack={
							getBackFn(IsniRegistrationFormStep.CAREER) as (
								values: CareerFormikValues
							) => void
						}
						onSubmit={handleCareerSubmit}
						getIsniStringFn={_getIsniString}
						initialFormValues={careerForm}
					/>
				);
			case IsniRegistrationFormStep.WORK_RELATION:
				return (
					<WorkRelationStep
						onBack={
							getBackFn(IsniRegistrationFormStep.WORK_RELATION) as (
								values: WorkRelationFormikValues
							) => void
						}
						onSubmit={handleWorkRelationSubmit}
						getIsniStringFn={_getIsniString}
					/>
				);
			case IsniRegistrationFormStep.REVIEW_INFO:
				return (
					<ReviewInfoStep
						onSubmit={skipToRequestIsniStep}
						onBack={
							getBackFn(IsniRegistrationFormStep.REVIEW_INFO) as () => void
						}
						basicInfoForm={basicInfoForm!}
						priorWorkForm={priorWorkForm!}
						careerForm={careerForm!}
						workRelationForm={workRelationForm!}
						onEditBasicInfo={() =>
							setFormStep(IsniRegistrationFormStep.BASIC_INFO)
						}
						onEditPriorWork={() =>
							setFormStep(IsniRegistrationFormStep.PRIOR_WORK)
						}
						onEditCareer={() => setFormStep(IsniRegistrationFormStep.CAREER)}
						onEditWorkRelation={() =>
							setFormStep(IsniRegistrationFormStep.WORK_RELATION)
						}
					/>
				);
			case IsniRegistrationFormStep.REQUEST_ISNI:
				return (
					<RequestIsniStep
						generateIsni={handleGenerateIsni}
						onSubmit={handleRequestIsniSubmit}
						getIsniStringFn={_getIsniString}
						isUserClaimingIsni={isUserClaimingIsni}
					/>
				);
			case IsniRegistrationFormStep.ISNI_RETRIEVED:
				return (
					<IsniRetrievedStep
						isni={claimedIsni!}
						onDismiss={() => dispatch(hideModal())}
						getIsniStringFn={_getIsniString}
						onAssignIsni={handleAssignIsni}
					/>
				);
			case IsniRegistrationFormStep.ISNI_CREATED:
				return (
					<IsniCreatedStep
						isni={claimedIsni!}
						onDismiss={() => dispatch(hideModal())}
						getIsniStringFn={_getIsniString}
						onAssignIsni={handleAssignIsni}
						isniCertificateLink={isniCertificateLink}
					/>
				);
			default:
				console.error('Unknown form step', formStep);
				return null;
		}
	}, [
		formStep,
		handleBasicInfoSubmit,
		handleSearchSubmit,
		handlePriorWorkSubmit,
		handleCareerSubmit,
		handleWorkRelationSubmit,
		skipToRequestIsniStep,
		_getIsniString,
		basicInfoForm,
		claimedIsni,
		dispatch,
		priorWorkForm,
		careerForm,
		getBackFn,
		workRelationForm,
		handleAssignIsni,
		isUserClaimingIsni,
		isniCertificateLink,
		handleGenerateIsni,
		handleRequestIsniSubmit,
	]);

	const stepTitles = [
		{
			label: _getIsniString(isniStrings.BasicInfo.stepperLabel),
		},
		{
			label: _getIsniString(isniStrings.Search.stepperLabel),
		},
		{
			label: _getIsniString(isniStrings.PriorWork.stepperLabel),
		},
		{
			label: _getIsniString(isniStrings.Career.stepperLabel),
		},
		{
			label: _getIsniString(isniStrings.WorkRelation.stepperLabel),
		},
	];

	useEffect(() => {
		dispatch(setModalTitle('ISNI REGISTRATION'));
	}, [dispatch]);

	return (
		<>
			<Helmet>
				<title>Register ISNI {process.env.REACT_APP_TAB_TITLE}</title>
			</Helmet>
			<Modal.Body>
				<div className='px-5 isni-registration pb-3'>
					<Steps model={stepTitles} activeIndex={formStep} className='mb-2' />
					<Divider />
					{renderStep()}
				</div>
			</Modal.Body>
		</>
	);
};

export default IsniRegistration;
