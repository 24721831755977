import React, { useCallback, useMemo } from 'react';
import { Button, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import './PillButton.scss';
import { handleLockedClick } from '../../../helpers/tiersTools';
import { useAppDispatch } from '../../../store/hooks';
import IconButton from '../IconButton/IconButton';

export type PillButtonProps = {
	label: string | React.ReactNode;
	leftIcon?: string;
	rightIcon?: string;
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	borderRadius?: string | number;
	isDisabled?: boolean;
	isLoading?: boolean;
	theme?:
		| 'outline'
		| 'solid-dark'
		| 'solid-light'
		| 'outline-danger'
		| 'text'
		| 'solid-pink'
		| 'animated-gradient'
		| 'navbar-solid-light'
		| 'colorful-gradient';
	tooltipText?: string | React.ReactNode;
	className?: string;
	style?: React.CSSProperties;
	fullWidth?: boolean;
	leftIconClassName?: string;
	rightIconClassName?: string;
	leftIconStyle?: React.CSSProperties;
	rightIconStyle?: React.CSSProperties;
	outerClassName?: string;
	isLocked?: boolean;
	onLockedClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	forceShowTooltip?: boolean;
};

const PillButton = ({
	label,
	leftIcon,
	rightIcon,
	onClick,
	borderRadius,
	isDisabled,
	isLoading = false,
	theme = 'outline',
	tooltipText = '',
	className = '',
	style = {},
	fullWidth = false,
	leftIconClassName = 'mr-2',
	rightIconClassName = 'ml-2',
	leftIconStyle = {},
	rightIconStyle = {},
	outerClassName = '',
	isLocked = false,
	onLockedClick,
	forceShowTooltip = false,
}: PillButtonProps) => {
	const [_showTooltip, setShowTooltip] = React.useState(true); // allows overriding forceShowTooltip

	const dispatch = useAppDispatch();

	const handleClick = useCallback(
		(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			if (isDisabled) return;

			if (onClick) onClick(e);
		},
		[isDisabled, onClick]
	);

	const buttonClass = useMemo(() => {
		switch (theme) {
			case 'outline':
				return 'pill-button-outline';
			case 'solid-dark':
				return 'pill-button-solid-dark';
			case 'solid-light':
				return 'pill-button-solid-light';
			case 'outline-danger':
				return 'pill-button-outline-danger';
			case 'text':
				return 'pill-button-text';
			case 'solid-pink':
				return 'pill-button-solid-pink';
			case 'animated-gradient':
				return 'pill-button-animated-gradient';
			case 'colorful-gradient':
				return 'pill-button-colorful-gradient';
			case 'navbar-solid-light':
				return 'pill-button-navbar-solid-light';
			default:
				return 'pill-button-outline';
		}
	}, [theme]);
	return (
		<OverlayTrigger
			show={!forceShowTooltip ? undefined : forceShowTooltip && _showTooltip}
			placement='auto'
			overlay={
				tooltipText ? (
					<Tooltip>
						<div className='d-flex'>
							<div>{tooltipText}</div>
							{forceShowTooltip && (
								<>
									<div className='ml-3' />
									<div className='d-flex align-items-center'>
										<IconButton
											icon='fas fa-times'
											onClick={() => {
												setShowTooltip(false);
											}}
											color='white'
											style={{
												position: 'absolute',
												right: '0',
												top: '-0.08rem',
											}}
										/>
									</div>
								</>
							)}
						</div>
					</Tooltip>
				) : (
					<></>
				)
			}
		>
			<div
				style={{ display: 'inline-block' }}
				className={`${fullWidth ? 'w-100' : ''} ${outerClassName}`}
			>
				<Button
					className={`${buttonClass} ${className} ${
						isDisabled || isLoading ? 'disabled-pill-button' : ''
					}`}
					onClick={e =>
						handleLockedClick({
							e,
							isLocked,
							onLockedClick,
							onClick: handleClick,
							dispatch,
							isDisabled: isDisabled || isLoading,
						})
					}
					style={{
						...(borderRadius ? { borderRadius } : {}),
						position: 'relative',
						...style,
					}}
				>
					{isLoading && (
						<Spinner
							as='span'
							animation='border'
							size='sm'
							role='status'
							aria-hidden='true'
							className='mr-2'
						/>
					)}
					{leftIcon && (
						<i
							className={`${leftIcon} ${leftIconClassName}`}
							style={leftIconStyle}
						></i>
					)}
					{label}
					{rightIcon && (
						<i
							className={`${rightIcon} ${rightIconClassName}`}
							style={rightIconStyle}
						></i>
					)}
					{isLocked && (
						<i
							className='fas fa-lock'
							style={{
								position: 'absolute',
								top: -5,
								right: -3,
							}}
						/>
					)}
				</Button>
			</div>
		</OverlayTrigger>
	);
};

export default PillButton;
