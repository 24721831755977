// this regex matches with either the last 4 digits of a SSN or the whole number, with or without dashes
export const lastFourOrFullSsnRegex =
	/^((?!000|666)[0-8][0-9]{2}(?!00)[0-9]{2}(?!0000)[0-9]{4}|(?!0000)[0-9]{4})$/;

export const lastFourSsnRegex = /^([1-9]\d{3}|0[1-9]\d{2}|00[1-9]\d|000[1-9])$/;

export const fullSsnNoDashesRegex =
	/^(?!000|666)[0-8][0-9]{2}(?!00)[0-9]{2}(?!0000)[0-9]{4}$/;

export const ipiCaeRegex = /^[0-9]+$/;

export const sha256Regex = /^[a-fA-F0-9]{64}$/;
