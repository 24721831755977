import axiosRetry from 'axios-retry';
import { aiAnalysisApi } from '../aiAnalysisApi';
import { shouldRetry } from '../../helpers/networkTools';

axiosRetry(aiAnalysisApi, {
	retries: 3,
	retryDelay: retryCount => {
		return retryCount * 1000;
	},
	shouldResetTimeout: true,
	retryCondition: shouldRetry,
});

export const generateAIAnalysis = ({ stats }: any) =>
	aiAnalysisApi
		.post('/drip_ai_analytics', { drip_data: stats })
		.then(res => res.data);
