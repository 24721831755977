/**
 * This is an adaptation of the FileSystemFileHandle class from the File System Access API.
 * But for use with Electron's native file system API, which provides access to privileged
 * file system operations, such as modifying timestamp metadata. Do not attempt to use this
 * in a browser environment.
 */
export default class WritableNativeFileHandle {
	transactionId: WritableNativeFileTransactionId;

	constructor(transactionId: WritableNativeFileTransactionId) {
		this.transactionId = transactionId;
	}

	async writeToFile(data: ArrayBuffer) {
		await window.electronApi.writeToFile(this.transactionId, data);
	}

	async setTimestamps(timestamps: FileTimestamp) {
		await window.electronApi.setFileTimestamps(this.transactionId, {
			btime: timestamps.createdAt,
			mtime: timestamps.updatedAt,
		});
	}

	async close() {
		await window.electronApi.closeWritableFile(this.transactionId);
	}
}
