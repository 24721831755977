import React from 'react';
import { Nav, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import './NavLink.scss';
import { getAssetPath } from '../../../helpers/assetTools';
import clsx from 'clsx';

export type NavLinkProps = {
	to: string;
	label: string;
	imageIcon?: string;
	textIcon?: string;
	hidden?: boolean;
	onClick?: () => void;
	selectableLabel?: boolean;
	svgIcon?: React.ReactNode;
	enableWhiteSpaceWrap?: boolean;
};

const matchesCurrentLocation = (to: string, currentPath: any) => {
	// get first part of path before first slash
	const currentSection = currentPath.split('/')[1];
	const toSection = to.split('/')[1];

	return currentSection === toSection;
};

const NavLink = ({
	to,
	label,
	imageIcon = '',
	textIcon = '',
	hidden,
	onClick = () => {},
	svgIcon,
	enableWhiteSpaceWrap = false,
	selectableLabel = false,
}: NavLinkProps) => {
	const location = useLocation();

	// check if path in `to` is substring of current path
	let match = matchesCurrentLocation(to, location.pathname);

	return (
		<OverlayTrigger
			key={'right'}
			placement={'right'}
			overlay={
				hidden ? <Tooltip id={`tooltip-${'right'}`}>{label} </Tooltip> : <></>
			}
		>
			<Nav.Item className='mb-2' onClick={onClick}>
				<Nav.Link
					as={Link}
					to={to}
					className={`${match ? 'active' : ''} sidebar-nav-link`}
					draggable='false'
				>
					<>
						{textIcon && (
							<i
								className={`${textIcon} nav-icon mr-4`}
								style={{ userSelect: 'none' }}
							></i>
						)}
						{imageIcon && (
							<img
								src={getAssetPath(`img/${imageIcon}`)}
								style={{ userSelect: 'none' }}
								className='nav-icon mr-4'
								alt='icon'
								draggable='false'
							/>
						)}
						{svgIcon && (
							<div
								style={{ userSelect: 'none' }}
								className='nav-icon mr-4'
								draggable='false'
							>
								{svgIcon}
							</div>
						)}
						<p
							className={clsx('nav-label', {
								'white-space-wrap': enableWhiteSpaceWrap,
							})}
							style={{
								userSelect: 'none',
							}}
						>
							{!hidden ? label : ''}
						</p>
					</>
				</Nav.Link>
			</Nav.Item>
		</OverlayTrigger>
	);
};

export default NavLink;
