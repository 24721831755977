export enum ErrorLevel {
	INFO = 'info',
	WARNING = 'warning',
	ERROR = 'error',
}

export const ERRORS = {
	PLAYLISTS: {
		GENERAL: {
			500: {
				message:
					"Whoops! Something went wrong while syncing playlists. Keep rockin'!",
				level: ErrorLevel.ERROR,
			},
			402: {
				message:
					"The current subscription level doesn't allow this action. Time to upgrade and get serious with us!",
				level: ErrorLevel.WARNING,
			},
			403: {
				message:
					"Hiccup detected, you don't currently have permission for that right now.",
				level: ErrorLevel.WARNING,
			},
		},
		GET_USER_PLAYLISTS: {
			GENERIC: {
				message:
					"Whoops! Hiccup detected while fetching your playlists. Keep on rollin'!",
				level: ErrorLevel.ERROR,
			},
		},
		GET_PLAYLIST: {
			GENERIC: {
				message:
					'Whoops! Hiccup detected while fetching this playlist. Keep driving forward!',
				level: ErrorLevel.ERROR,
			},
		},
		CREATE_PLAYLIST: {
			GENERIC: {
				message:
					'Whoops! Hiccup detected while creating the playlist. Keep driving forward!',
				level: ErrorLevel.ERROR,
			},
		},
		UPDATE_PLAYLIST: {
			GENERIC: {
				message:
					'Whoops! Hiccup detected while editing this playlist. Keep driving forward!',
				level: ErrorLevel.ERROR,
			},
		},
		DELETE_PLAYLIST: {
			GENERIC: {
				message:
					'Whoops! Hiccup detected while deleting this playlist. Keep driving forward!',
				level: ErrorLevel.ERROR,
			},
		},
		DELETE_PLAYLIST_FILE: {
			GENERIC: {
				message:
					'Whoops! Hiccup detected while deleting the playlist file. Keep driving forward!',
				level: ErrorLevel.ERROR,
			},
		},
		CASCADE_FILE_CHANGE_TO_RECORDING: {
			GENERIC: {
				message:
					'Whoops! Hiccup detected while updating the recording. Keep driving forward!',
				level: ErrorLevel.ERROR,
			},
		},
		ADD_PLAYLIST_COVER_IMAGE: {
			GENERIC: {
				message:
					'Whoops! Hiccup detected while adding the playlist cover image. Stay in the groove!',
				level: ErrorLevel.ERROR,
			},
		},
		ADD_PLAYLIST_HEADER_IMAGE: {
			GENERIC: {
				message:
					"Whoops! Hiccup detected while adding the playlist header image. Keep on jammin'!",
				level: ErrorLevel.ERROR,
			},
		},
		PUBLISH_DRIP: {
			GENERIC: {
				message:
					'Whoops! Something went wrong while publishing the Drip. Please try again.',
				level: ErrorLevel.ERROR,
			},
		},
		UNPUBLISH_DRIP: {
			GENERIC: {
				message:
					'Whoops! Something went wrong while unpublishing the Drip. Please try again.',
				level: ErrorLevel.ERROR,
			},
		},
		DUPLICATE_PLAYLIST: {
			GENERIC: {
				message:
					'Whoops! Something went wrong while duplicating the playlist. Please try again.',
				level: ErrorLevel.ERROR,
			},
		},
		GENERATE_DRIP_STATS_AI_ANALYSIS: {
			GENERIC: {
				message:
					'Whoops! Something went wrong while generating the AI analysis. Please try again.',
				level: ErrorLevel.ERROR,
			},
		},
		POLL_PLAYLIST_UPDATES: {
			GENERIC: {
				message:
					'Whoops! Something went wrong while getting playlist updates. Please try again.',
				level: ErrorLevel.ERROR,
			},
		},
		ACCEPT_PLAYLIST_EDITOR_INVITE: {
			GENERIC: {
				message:
					'Whoops! Something went wrong while accepting the invite. Please try again.',
				level: ErrorLevel.ERROR,
			},
		},
		APPROVE_CREDIT_REQUEST: {
			GENERIC: {
				message:
					'Whoops! Something went wrong while approving the credit request. Please try again.',
				level: ErrorLevel.ERROR,
			},
		},
		CHANGE_PLAYLIST_FILE_NAMES: {
			GENERIC: {
				message:
					'Whoops! Something went wrong while changing the file notes. Please try again.',
				level: ErrorLevel.ERROR,
			},
		},
		REORDER_PLAYLIST_FILES: {
			GENERIC: {
				message:
					'Whoops! Something went wrong while reordering the files. Please try again.',
				level: ErrorLevel.ERROR,
			},
		},
		DUPLICATE: {
			GENERIC: {
				message:
					'Whoops! Something went wrong while duplicating the playlist. Please try again.',
				level: ErrorLevel.ERROR,
			},
		},
		FETCH_PLAYLIST_EDITORS: {
			GENERIC: {
				message:
					'Whoops! Something went wrong while fetching the playlist collaborators. Please try again.',
				level: ErrorLevel.ERROR,
			},
		},
		ADD_PLAYLIST_EDITOR: {
			GENERIC: {
				message:
					'Whoops! Something went wrong while inviting the playlist collaborator. Please try again.',
				level: ErrorLevel.ERROR,
			},
		},
		REMOVE_PLAYLIST_EDITOR: {
			GENERIC: {
				message:
					'Whoops! Something went wrong while removing the playlist collaborator. Please try again.',
				level: ErrorLevel.ERROR,
			},
		},
		CREATE_PLAYLIST_FILE_GROUP: {
			GENERIC: {
				message:
					'Whoops! Something went wrong while creating the playlist folder. Please try again.',
				level: ErrorLevel.ERROR,
			},
		},
		DELETE_PLAYLIST_FILE_GROUP: {
			GENERIC: {
				message:
					'Whoops! Something went wrong while deleting the playlist folder. Please try again.',
				level: ErrorLevel.ERROR,
			},
		},
		RENAME_PLAYLIST_FILE_GROUP: {
			GENERIC: {
				message:
					'Whoops! Something went wrong while renaming the playlist folder. Please try again.',
				level: ErrorLevel.ERROR,
			},
		},
		REORDER_PLAYLIST_FILE_GROUPS: {
			GENERIC: {
				message:
					'Whoops! Something went wrong while reordering the playlist folders. Please try again.',
				level: ErrorLevel.ERROR,
			},
		},
		UPDATE_PLAYLIST_FILE_GROUP_FILES: {
			GENERIC: {
				message:
					'Whoops! Something went wrong while updating the playlist folder files. Please try again.',
				level: ErrorLevel.ERROR,
			},
		},
		UPDATE_PLAYLIST_SUB_GROUPS: {
			GENERIC: {
				message:
					'Whoops! Something went wrong while updating the playlist subfolders. Please try again.',
				level: ErrorLevel.ERROR,
			},
		},
	},
};
