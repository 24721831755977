import { createApiInstance } from './utils';

const couponGeneratorApi = createApiInstance(
	process.env.REACT_APP_COUPON_GENERATOR_API!,
	{
		translateCase: true,
		devProxyPath: '/new-user-coupon-generator',
		usePortalAuthHeaders: true,
		retryOnTimeout: true,
	}
);

export default couponGeneratorApi;
