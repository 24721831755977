import React, { useCallback, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { CONFIRMATION_MODAL } from '../../../../../constants/modalTypes';
import { hideModal, showModalAction } from '../../../../../store/modal/actions';
import { getCurrentPlaylist } from '../../../../../store/playlists/selectors';
import IconButton from '../../../../layout/IconButton';
import PillButton from '../../../../layout/PillButton';
import './PlaylistStatsHeader.scss';
import theme from '../../../../../theme.module.scss';
import {
	getDripShareLink,
	getPlaylistShareLink,
} from '../../../../../helpers/playlistTools';
import { FallbackPlaylistCoverImage } from '../../PlaylistDetails/PlaylistDetailsHeader/PlaylistImage/PlaylistImage';
import { downloadDripEmailsAsCsvZip } from '@/helpers/dripTools';
import { showErrorAlert } from '@/store/alertToast/actions';
import NoCollectedEmailsError from '../../../../../errors/NoCollectedEmailsError';

const PlaylistStatsHeader = ({ isDrip = false }) => {
	const currentPlaylist = useSelector(getCurrentPlaylist);
	const dispatch = useDispatch();
	const [isDownloadingEmails, setIsDownloadingEmails] = React.useState(false);
	const [copyTooltipText, setCopyTooltipText] =
		React.useState('Copy Playlist Link');
	const playlistShareLink = useMemo(
		() => (isDrip ? getDripShareLink : getPlaylistShareLink)(currentPlaylist),
		[currentPlaylist, isDrip]
	);
	const displayPlaylistShareLink = useMemo(
		() => playlistShareLink.replace('https://', ''),
		[playlistShareLink]
	);

	const handleDownloadCollectedEmails = useCallback(async () => {
		if (!currentPlaylist) return;

		try {
			setIsDownloadingEmails(true);
			await downloadDripEmailsAsCsvZip(currentPlaylist);
		} catch (error) {
			if (error instanceof NoCollectedEmailsError) {
				dispatch(
					showModalAction(CONFIRMATION_MODAL, {
						title: 'No Collected Emails',
						description: 'There are no collected emails for this drip yet.',
						confirmAction: {
							label: 'Dismiss',
							onClick: () => dispatch(hideModal()),
						},
						size: 'md',
					})
				);

				return;
			}

			console.error(error);
			dispatch(
				showErrorAlert(
					'Whoops! Something went wrong while downloading the collected emails. Please try again later.'
				)
			);
		} finally {
			setIsDownloadingEmails(false);
		}
	}, [currentPlaylist, dispatch]);

	return (
		<Row className='w-100'>
			<Col sm={12} className='d-flex'>
				<div className='mr-3'>
					<div
						className='playlist-image__container'
						style={{
							height: '18vh',
							width: '18vh',
						}}
					>
						{currentPlaylist?.playlist?.coverUrl ? (
							<img
								className='h-100 w-100'
								style={{ objectFit: 'cover' }}
								src={currentPlaylist?.playlist?.coverUrl}
								alt='playlist cover'
								draggable={false}
							/>
						) : (
							<FallbackPlaylistCoverImage fontSize='4rem' />
						)}
					</div>
				</div>

				<div className='d-flex justify-content-center flex-column'>
					<h4 className='playlist-stats-title'>
						{currentPlaylist?.playlist?.name}
					</h4>

					<div className='playlist-stats-link-container mt-2'>
						<i className='fas fa-link playlist-stats-icon' />
						<PillButton
							label={displayPlaylistShareLink}
							rightIcon='fas fa-external-link-alt'
							theme='solid-light'
							tooltipText='Open Playlist Link'
							onClick={() => {
								window.open(playlistShareLink, '_blank');
							}}
						/>
						<IconButton
							icon='fas fa-copy'
							tooltipText={copyTooltipText}
							onClick={() => {
								navigator.clipboard.writeText(playlistShareLink);

								setCopyTooltipText('Copied!');
								setTimeout(() => {
									setCopyTooltipText('Copy Playlist Link');
								}, 2000);
							}}
							color={theme.primary}
						/>
					</div>
					{isDrip && (
						<div className='playlist-stats-link-container mt-2'>
							<i className='fas fa-mail-bulk playlist-stats-icon' />
							<PillButton
								label='Download Collected Emails'
								leftIcon='fas fa-download'
								theme='solid-light'
								onClick={handleDownloadCollectedEmails}
								isLoading={isDownloadingEmails}
							/>
						</div>
					)}
				</div>
			</Col>
		</Row>
	);
};

export default PlaylistStatsHeader;
