import { FormikProvider, useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, Form as BSForm, Row, Col } from 'react-bootstrap';
import {
	changePlaylistNoteAction,
	fetchPlaylistAction,
} from '../../../../../../store/playlists/actions';
import * as yup from 'yup';
import Button from '../../../../../layout/Button';
import {
	hideModal,
	setModalTitle,
	showModalAction,
} from '../../../../../../store/modal/actions';
import TextField from '../../../../../form/TextField';
import { fetchUserEditableProjectsAction } from '../../../../../../store/projects/actions';
import PlaylistImage from '../../PlaylistDetailsHeader/PlaylistImage/PlaylistImage';
import {
	DELETE_MODAL,
	IMAGE_CROP_MODAL,
} from '../../../../../../constants/modalTypes';
import {
	COVER_IMAGE_ASPECT_RATIO,
	isAudioFile,
} from '../../../../../../helpers/fileTools';
import { IMAGE_FILE_TYPES } from '../../../../../form/ImageCropInput/ImageCropInput';
import {
	deleteFileCoverImageAction,
	uploadFileCoverImageAction,
} from '../../../../../../store/files/actions';
import PillButton from '../../../../../layout/PillButton/PillButton';
import { getFilesById } from '../../../../../../store/files/selectors';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';

const schema = yup.object().shape({
	displayName: yup.string(),
});

type EditPlaylistFileForm = {
	displayName: string;
};

export type EditPlaylistFileModalProps = {
	playlistId?: Playlist['id'] | null;
	fileId: FileMetadata['id'];
};

const EditPlaylistFileModal = ({
	playlistId,
	fileId,
}: EditPlaylistFileModalProps) => {
	const { playlistsById } = useAppSelector(state => state.playlists);
	const { userEditableRecordingIds } = useAppSelector(state => state.projects);
	const dispatch = useAppDispatch();

	const [isLoading, setIsLoading] = useState(false);
	const playlist = useMemo(
		() => (playlistId ? playlistsById![playlistId] : null),
		[playlistId, playlistsById]
	);
	const filesById = useAppSelector(getFilesById);
	const file = useMemo(
		() =>
			playlistId
				? playlist?.playlist?.files?.find(file => file.id === fileId)!
				: filesById[fileId]!,
		[fileId, playlist, filesById, playlistId]
	);
	const canEditAssociatedRecording = useMemo(
		() =>
			file.recordingId
				? userEditableRecordingIds?.includes(file.recordingId)
				: false,
		[file, userEditableRecordingIds]
	);

	const _isAudioFile = useMemo(() => isAudioFile(file?.filename), [file]);

	const initialValues = useMemo(
		() => ({
			displayName: file?.displayName || '',
		}),
		[file]
	);

	const handleClose = useCallback(() => {
		dispatch(hideModal());
	}, [dispatch]);

	const handleSubmitNoteForm = useCallback(
		async (form: EditPlaylistFileForm) => {
			setIsLoading(true);

			try {
				await dispatch(
					changePlaylistNoteAction(playlistId!, fileId, form.displayName)
				);
			} finally {
				setIsLoading(false);
				handleClose();
			}
		},
		[dispatch, playlistId, fileId, handleClose]
	);

	const handleEditFileCover = useCallback(() => {
		dispatch(
			showModalAction(IMAGE_CROP_MODAL, {
				size: 'lg',
				onSave: async (image: File) => {
					await dispatch(uploadFileCoverImageAction(file.id, image));
					if (playlistId) await dispatch(fetchPlaylistAction(playlistId));
				},
				aspectRatio: COVER_IMAGE_ASPECT_RATIO,
				title: `Edit cover for ${file.filename}`,
				outputType: IMAGE_FILE_TYPES.blob,
			})
		);
	}, [dispatch, file, playlistId]);

	const handleDeleteFileCover = useCallback(async () => {
		dispatch(
			showModalAction(DELETE_MODAL, {
				title: 'Delete Cover Image',
				body: 'Are you sure you want to delete this cover image?',
				onDelete: async () => {
					await dispatch(deleteFileCoverImageAction(file.id));
					if (playlistId) await dispatch(fetchPlaylistAction(playlistId));
				},
				size: 'md',
			})
		);
	}, [dispatch, file, playlistId]);

	const formik = useFormik<EditPlaylistFileForm>({
		initialValues,
		onSubmit: handleSubmitNoteForm,
		validationSchema: schema,
		validateOnMount: true,
	});

	useEffect(() => {
		if (playlistId)
			dispatch(setModalTitle(`EDIT DETAILS FOR ${file?.filename}`));
		dispatch(fetchUserEditableProjectsAction()); // refresh user editable projects to check for edit permissions
	}, [dispatch, file, playlistId]);

	return (
		<FormikProvider value={formik}>
			<Modal.Body>
				<div className='px-4'>
					{
						<Row>
							{canEditAssociatedRecording && _isAudioFile && (
								<Col xs={12} className='mb-4'>
									<BSForm.Label>Cover Image</BSForm.Label>
									<div className='d-flex justify-content-center align-items-center flex-column'>
										<PlaylistImage
											src={file?.coverUrl}
											height={'10rem'}
											width={'10rem'}
											fontSize={'5rem'}
											onClickToUpload={handleEditFileCover}
										/>
										{file?.coverUrl && (
											<PillButton
												theme='outline-danger'
												className='mt-2'
												label='Remove'
												onClick={handleDeleteFileCover}
												leftIcon='fas fa-trash'
											/>
										)}
									</div>
								</Col>
							)}
							{playlistId && (
								<Col xs={12}>
									<TextField
										maxLength={80}
										showMaxLength
										name='displayName'
										label='Note'
										placeholder='e.g. With Auto-Tune, 1st Take, etc.'
										value={formik?.values?.displayName}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										errorMessage={formik.errors.displayName}
										isInvalid={
											formik?.touched?.displayName &&
											!!formik?.errors?.displayName
										}
										dense
									/>
								</Col>
							)}
						</Row>
					}
				</div>
			</Modal.Body>
			{playlistId && (
				<Modal.Footer>
					<div className='d-flex justify-content-end'>
						<Button label='Cancel' onClick={handleClose} className='mr-2' />
						<Button
							label='Save'
							theme='dark'
							type='submit'
							isLoading={isLoading}
							onClick={formik.handleSubmit as any}
						/>
					</div>
				</Modal.Footer>
			)}
		</FormikProvider>
	);
};

export default EditPlaylistFileModal;
