import b4Types from './unionForm/b4Types';

export enum UnionFormStep {
	LOAD_RECORDINGS = 0,
	SELECT_STUDIO = 1,
	SELECT_PARTICIPANTS = 2,
	COMPLETE_PARTICIPANTS = 3,
	FINALIZE_AND_EXPORT = 4,
}

const getExportSteps = (b4Type?: B4Type) => {
	const participantType =
		b4Type?.id === b4Types.PRODUCER_B4.id ? 'Producers' : 'Musicians';

	return [
		{ title: 'Load Recordings', id: UnionFormStep.LOAD_RECORDINGS },
		{ title: 'Select Studio', id: UnionFormStep.SELECT_STUDIO },
		{
			title: `Select ${participantType}`,
			id: UnionFormStep.SELECT_PARTICIPANTS,
		},
		{
			title: `Complete ${participantType}`,
			id: UnionFormStep.COMPLETE_PARTICIPANTS,
		},
		{ title: 'Finalize & Export', id: UnionFormStep.FINALIZE_AND_EXPORT },
	];
};

export default getExportSteps;
