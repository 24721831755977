import { Form, FormikProvider, useFormik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import SelectVersionFilesStep from './Steps/SelectVersionFilesStep';
import VersionMatchingStep from './Steps/VersionMatchingStep';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { hideModal, setModalTitle } from '../../../../../store/modal/actions';
import {
	computeFilenames,
	validateStorageUsageAndCorruptedFiles,
} from '../../../../../helpers/fileTools';
import {
	getUploadingStorageUsage,
	groupUploadsByProjectId,
} from '../../../../../store/files/selectors';
import { createUploads } from '../../../../../helpers/uploadTools';
import { addToUploadQueueAction } from '../../../../../store/files/actions';
import { getPlaylistById } from '../../../../../store/playlists/selectors';
import * as yup from 'yup';

type UploadVersionsToPlaylistModalProps = {
	playlistId: Playlist['id'];
	initialFiles?: File[];
};

enum UploadVersionsToPlaylistStep {
	SelectVersionFiles,
	VersionMatching,
}

export type VersionPlaylistUploadInput = {
	file: File;
	isActiveVersion: boolean | null;
	detectedFileId: FileMetadata['id'] | null;
};

export type UploadVersionsToPlaylistForm = {
	files: VersionPlaylistUploadInput[];
};

const validationSchema = yup.object().shape({
	files: yup.array().of(
		yup.object().shape({
			detectedFileId: yup.number().required('Please select an associated file'),
		})
	),
});

const UploadVersionsToPlaylistModal = ({
	playlistId,
	initialFiles,
}: UploadVersionsToPlaylistModalProps) => {
	const dispatch = useAppDispatch();
	const { storageUsage, filesByProjectId } = useAppSelector(
		state => state.files
	);
	const uploadsGroupedByProjectId = useAppSelector(groupUploadsByProjectId);
	const uploadingStorageUsage = useAppSelector(getUploadingStorageUsage);
	const playlist = useAppSelector(state =>
		getPlaylistById(state, { playlistId })
	);

	const [activeStep, setActiveStep] =
		React.useState<UploadVersionsToPlaylistStep>(
			initialFiles
				? UploadVersionsToPlaylistStep.VersionMatching
				: UploadVersionsToPlaylistStep.SelectVersionFiles
		);

	const handleUpload = async (formValues: UploadVersionsToPlaylistForm) => {
		if (!playlist) throw new Error('Playlist not found');
		if (!playlist.playlist) throw new Error('Playlist not fetched');

		const filenames = computeFilenames(
			// add recordingId and albumId to each file as the computeFilenames function needs it
			formValues.files.map(inputFile => {
				if (!inputFile.detectedFileId) throw new Error('File not detected');
				const fileMetadata = playlist.playlist!.files!.find(
					file => file.id === inputFile.detectedFileId
				);

				if (!fileMetadata) throw new Error('File not found');

				return {
					filename: inputFile.file.name,
					recordingId: fileMetadata.recordingId,
					albumId: fileMetadata.albumId,
				};
			}),
			filesByProjectId,
			uploadsGroupedByProjectId
		);

		const uploads = await createUploads({
			inputFiles: formValues.files.map(file => file.file),
			projectIds: formValues.files.map(file => {
				const fileMetadata = playlist.playlist!.files!.find(
					f => f.id === file.detectedFileId
				);

				if (!fileMetadata) throw new Error('File not found');

				return {
					recordingId: fileMetadata.recordingId ?? null,
					albumId: fileMetadata.albumId ?? null,
				};
			}),
			filenames,
			fileVersionsMetadata: formValues.files.map(file => ({
				comment: '',
				fileId: file.detectedFileId!,
				playlistId,
				isActiveVersion: file.isActiveVersion!,
			})),
		});

		// Add files to upload queue in Redux
		// The FileUploadMenu component will pick up the files from the queue and upload them
		dispatch(addToUploadQueueAction(uploads));

		dispatch(hideModal());
	};

	const formik = useFormik<UploadVersionsToPlaylistForm>({
		initialValues: {
			files:
				initialFiles?.map(file => ({
					file,
					isActiveVersion: false,
					detectedFileId: null,
				})) || [],
		},
		validationSchema,
		onSubmit: handleUpload,
	});

	const handleFileSelect = useCallback(
		async (inputFiles: FileList) => {
			const validatedFiles = await validateStorageUsageAndCorruptedFiles({
				dispatch,
				storageUsage,
				uploadingStorageUsage,
				newSelectedFiles: inputFiles,
			});

			if (!validatedFiles) return;

			const newFiles = [...validatedFiles].map(file => ({
				file,
				isActiveVersion: null,
				detectedFileId: null,
			}));

			formik.setFieldValue('files', newFiles);

			setActiveStep(UploadVersionsToPlaylistStep.VersionMatching);
		},
		[formik, storageUsage, uploadingStorageUsage, dispatch]
	);

	const handleBack = useCallback(() => {
		formik.resetForm();
		setActiveStep(UploadVersionsToPlaylistStep.SelectVersionFiles);
	}, [formik]);

	const renderActiveStep = useCallback(() => {
		switch (activeStep) {
			case UploadVersionsToPlaylistStep.SelectVersionFiles:
				return <SelectVersionFilesStep onSubmit={handleFileSelect} />;
			case UploadVersionsToPlaylistStep.VersionMatching:
				return (
					<VersionMatchingStep
						playlistId={playlistId}
						onBack={handleBack}
						onSubmit={formik.submitForm}
					/>
				);
			default:
				return null;
		}
	}, [activeStep, formik, playlistId, handleFileSelect, handleBack]);

	useEffect(() => {
		dispatch(setModalTitle('Upload Versions To Playlist'));
	}, [dispatch]);

	return (
		<FormikProvider value={formik}>
			<Form onSubmit={formik.handleSubmit} placeholder={null}>
				{renderActiveStep()}
			</Form>
		</FormikProvider>
	);
};

export default UploadVersionsToPlaylistModal;
