import React, { useCallback, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from '../../../layout/Button';
import theme from '../../../../theme.module.scss';
import { showModalAction } from '../../../../store/modal/actions';
import {
	SHARE_FILES_MODAL,
	UPLOAD_FILES_MODAL,
} from '../../../../constants/modalTypes';
import FilesBrowser from '../FilesBrowser';
import ProjectFilesListItem from './ProjectFilesListItem';
import { getFilesByProjectId } from '../../../../store/files/selectors';
import {
	BrowserFileSelection,
	FileBrowserSortOptions,
} from '../FilesBrowser/FilesBrowser';
import { ProjectFilesListItemProps } from './ProjectFilesListItem/ProjectFilesListItem';
import { AppDispatch } from '../../../../store';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

const sortingOptions: FileBrowserSortOptions<ProjectFilesListItemProps> = {
	newest: {
		name: 'Newest',
		compareFn: reverse => (a, b) =>
			(new Date(b.props.file.createdAt).getTime() -
				new Date(a.props.file.createdAt).getTime()) *
			(reverse ? -1 : 1),
	},
	oldest: {
		name: 'Oldest',
		compareFn: reverse => (a, b) =>
			new Date(a.props.file.createdAt).getTime() -
			new Date(b.props.file.createdAt).getTime() * (reverse ? -1 : 1),
	},
	filename: {
		name: 'File Name',
		compareFn: reverse => (a, b) =>
			a.props.file.filename.localeCompare(b.props.file.filename) *
			(reverse ? -1 : 1),
	},
	fileSize: {
		name: 'File Size',
		compareFn: reverse => (a, b) =>
			(a.props.file.fileSize - b.props.file.fileSize) * (reverse ? -1 : 1),
	},
};

// const projectFiles = [
// 	{
// 		id: 1,
// 		recordingId: 1,
// 		albumId: null,
// 		path: '/file4.mp3',
// 		label: 0,
// 		filename: 'file4.mp3',
// 		createdAt: new Date(),
// 		updatedAt: new Date(),
// 		size: 10204256,
// 	},
// 	{
// 		id: 2,
// 		recordingId: 1,
// 		albumId: null,
// 		path: '/file2.mp3',
// 		label: 0,
// 		filename: 'file2.mp3',
// 		createdAt: new Date(),
// 		updatedAt: new Date(),
// 		size: 10204256,
// 	},
// 	{
// 		id: 3,
// 		recordingId: 1,
// 		albumId: null,
// 		path: '/file3.mp3',
// 		label: 0,
// 		filename: 'file3.mp3',
// 		createdAt: new Date(),
// 		updatedAt: new Date(),
// 		size: 10204256,
// 	},
// ];

const showUploadModal = (
	dispatch: AppDispatch,
	recordingId: Recording['id'] | null,
	albumId: Album['id'] | null
) => {
	dispatch(
		showModalAction(UPLOAD_FILES_MODAL, { size: 'lg', recordingId, albumId })
	);
};

export type ProjectFilesBrowserProps = {
	recordingId: Recording['id'] | null;
	albumId: Album['id'] | null;
};

const ProjectFilesBrowser = ({
	recordingId,
	albumId,
}: ProjectFilesBrowserProps) => {
	/*
	 * Redux Hooks
	 */
	const { recordingsById, albumsById } = useAppSelector(
		state => state.projects
	);
	const filesById = useAppSelector(state =>
		getFilesByProjectId(state, { albumId, recordingId })
	);

	/*
	 * React Hooks
	 */
	const projectFiles = useMemo(() => {
		console.log(recordingId, albumId);
		return Object.values(filesById);
	}, [albumId, recordingId, filesById]);

	const pageTitle = useMemo(
		() =>
			`FILES FOR ${
				recordingId
					? recordingsById![recordingId].title
					: albumId
					? albumsById![albumId].title
					: ''
			}`.toUpperCase(),
		[recordingId, albumId, recordingsById, albumsById]
	);

	const renderHeader = useCallback(
		({ selectedFiles }: { selectedFiles: BrowserFileSelection }) => (
			<Header
				pageTitle={pageTitle}
				selectedFiles={selectedFiles}
				recordingId={recordingId}
				albumId={albumId}
			/>
		),
		[pageTitle, recordingId, albumId]
	);

	const renderNoFiles = useCallback(
		() => <NoFilesPlaceholder albumId={albumId} recordingId={recordingId} />,
		[albumId, recordingId]
	);

	return (
		<FilesBrowser<FileMetadata, ProjectFilesListItemProps>
			id='files'
			sortingOptions={sortingOptions}
			files={projectFiles}
			header={renderHeader}
			fileCard={FileCard}
			noFilesPlaceholder={renderNoFiles}
			showTagFilter
		/>
	);
};

type HeaderProps = {
	pageTitle: string;
	selectedFiles: BrowserFileSelection;
	recordingId: Recording['id'] | null;
	albumId: Album['id'] | null;
};

const Header = ({
	pageTitle,
	selectedFiles,
	recordingId,
	albumId,
}: HeaderProps) => {
	const dispatch = useAppDispatch();

	const { myEditorProfile } = useAppSelector(state => state.projects);

	const selectedFileCount = useMemo(
		() => Object.values(selectedFiles).filter(Boolean).length,
		[selectedFiles]
	);

	const isReadOnly = useMemo(
		() => (myEditorProfile ? myEditorProfile.is_read_only : true),
		[myEditorProfile]
	);

	const handleShare = () => {
		if (selectedFileCount === 0) return;

		const selectedFileIds = Object.keys(selectedFiles)
			.filter(key => selectedFiles[parseInt(key)])
			.map(key => parseInt(key));

		dispatch(
			showModalAction(SHARE_FILES_MODAL, {
				size: 'lg',
				fileIds: selectedFileIds,
				recordingId,
				albumId,
			})
		);
	};

	return (
		<Row className='w-100'>
			<Col xs={12} md={8}>
				<h2 className='mb-2'>{pageTitle}</h2>
				<small className='disclaimer-text'>
					Recordings must be a 16-bit master for every song and have an album
					artwork in order to be eligible for digital distribution
				</small>
			</Col>
			{!isReadOnly && (
				<Col xs={12} md={4} className='d-flex justify-content-end p-0'>
					<Button
						label='Share'
						leftIcon='fas fa-share-alt'
						isDisabled={selectedFileCount === 0}
						onClick={handleShare}
					/>
					<Button
						label='Upload'
						theme='dark'
						className='ml-4'
						leftIcon='fas fa-cloud-upload-alt'
						onClick={() => showUploadModal(dispatch, recordingId, albumId)}
					/>
				</Col>
			)}
		</Row>
	);
};

type FileCardProps = {
	file: FileMetadata;
	isSelected: boolean;
	setSelectedFiles: React.Dispatch<React.SetStateAction<BrowserFileSelection>>;
};

const FileCard = ({ file, isSelected, setSelectedFiles }: FileCardProps) => (
	<ProjectFilesListItem
		key={file.id}
		file={file}
		setSelectedFiles={setSelectedFiles}
		isSelected={isSelected}
	/>
);

const NoFilesPlaceholder = ({
	albumId,
	recordingId,
}: {
	albumId: Album['id'] | null;
	recordingId: Recording['id'] | null;
}) => {
	const dispatch = useAppDispatch();
	const { myEditorProfile } = useAppSelector(state => state.projects);

	const isReadOnly = useMemo(
		() => (myEditorProfile ? myEditorProfile.is_read_only : true),
		[myEditorProfile]
	);

	return (
		<Row className='w-100'>
			<Col xs={12} className='my-2'>
				<div
					className='d-flex flex-column justify-content-center align-items-center fw-600'
					style={{
						color: theme.darkGreyTextColor2,
						fontSize: '1rem',
						height: '40vh',
					}}
				>
					<i
						className='fas fa-file mb-4'
						style={{
							fontSize: '5rem',
						}}
					></i>
					<p>
						This project has no files.{' '}
						{!isReadOnly && (
							<>
								<span
									style={{
										color: theme.primary,
										cursor: 'pointer',
									}}
									onClick={() =>
										showUploadModal(dispatch, recordingId, albumId)
									}
								>
									Upload
								</span>{' '}
								some files to get started.
							</>
						)}
					</p>
				</div>
			</Col>
		</Row>
	);
};

export default ProjectFilesBrowser;
