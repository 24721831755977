import { AppDispatch } from '..';
import DocsService from '../../api/services/docsService';
import { SET_HELP_ARTICLES } from '../actionTypes';

export const setHelpArticlesAction = (helpArticles: PortalHelpArticle[]) => ({
	type: SET_HELP_ARTICLES,
	helpArticles,
});

export const fetchHelpArticlesAction = () => (dispatch: AppDispatch) =>
	DocsService.getPortalHelpArticles().then(helpArticles => {
		dispatch(setHelpArticlesAction(helpArticles));
	});
