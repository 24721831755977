import translateRecordingFormToApi from '../../helpers/translateRecordingFormToApi';
import projectsApi from '../projectsApi';
import { checkInvalidToken } from '../../helpers/checkInvalidToken';
import axiosRetry from 'axios-retry';
import translateApiAlbumToLocal from '../../helpers/translateApiAlbumToLocal';
import translateApiRecordingToLocal from '../../helpers/translateApiRecordingToLocal';
import { shouldRetry } from '../../helpers/networkTools';

axiosRetry(projectsApi, {
	retries: 3,
	retryDelay: retryCount => {
		return retryCount * 1000;
	},
	shouldResetTimeout: true,
	retryCondition: shouldRetry,
});

export const getUserProjects = () => projectsApi.get('/user');

export const createCloudRecording = (
	recording: RecordingContent,
	albumId: number | null = null
) =>
	projectsApi
		.post('/recording', {
			title: recording.title,
			artist: recording.mainArtist,
			recording,
			album_id: albumId,
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

export const updateCloudRecording = (
	fullRecording: Recording,
	translate = true,
	recordingForm: RecordingContent | null = null
) => {
	let recording = translate
		? translateRecordingFormToApi(fullRecording, recordingForm)
		: fullRecording;

	return projectsApi.put('/recording', recording);
};

export const getCloudRecordingById = (id: number) =>
	projectsApi
		.get('/recording', {
			params: {
				recording_id: id,
			},
		})
		.then(({ data }) => translateApiRecordingToLocal(data.recording));

export const deleteCloudRecordingById = (id: number) =>
	projectsApi.delete('/recording', {
		params: {
			recording_id: id,
		},
	});

export const getCloudAlbumById = (id: number) =>
	projectsApi.get('/album', {
		params: {
			album_id: id,
		},
	});

export const createCloudAlbum = (album: AlbumContent) =>
	projectsApi.post('/album', {
		title: album.title,
		artist: album.artistName,
		recordings: {},
		album,
		is_single: !!album.isSingle,
	});

export const deleteCloudAlbumById = (id: number) =>
	projectsApi.delete('/album', {
		params: {
			album_id: id,
		},
	});

export const updateCloudAlbum = (album: any) =>
	projectsApi.put('/album', album);

export const removeRecordingFromCloudAlbum = (
	albumId: number,
	recordingId: number
) =>
	projectsApi.delete('/album/remove-recording', {
		params: {
			album_id: albumId,
			recording_id: recordingId,
		},
	});

export const addRecordingsToCloudAlbum = (
	albumId: number,
	recordings: number[]
) => {
	return projectsApi.put('/album/add-recordings', {
		album_id: albumId,
		recording_ids: recordings,
	});
};

export const reorderCloudAlbumRecordings = (
	albumId: number,
	recordings: Record<number, number>
) => {
	return projectsApi.put('/album/reorder-recordings', {
		album_id: albumId,
		recordings,
	});
};

export const getUserEditableProjects = () =>
	projectsApi.get<{
		albums: { id: number }[];
		recordings: { id: number }[];
	}>('/user/editable');

export const getProjectUsage = () => projectsApi.get('/user/project-count');

export const searchUserProjects = (search: string) =>
	projectsApi
		.get('/user/search', {
			params: {
				search,
			},
		})
		.then(res => ({
			albums: res.data.albums.map(translateApiAlbumToLocal),
			recordings: res.data.recordings.map(translateApiRecordingToLocal),
		}));
