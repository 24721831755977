import React from 'react';
import { useDispatch } from 'react-redux';
import openShareModal from '../../../../helpers/openShareModal';
import ActionsCell from '../ActionsCell';
import AdministratorColumn from './AdministratorColumn';
import FilesColumn from './FilesColumn';
import './ProjectsTable.scss';
import ReorderTrackArrows from './ReorderTrackArrows';

const albumsColumns = [
	{
		id: 'dragHandle',
		Header: () => <></>,
		Cell: ({ dragHandleProps, isCoOwner }) =>
			isCoOwner ? (
				<i
					className='project-drag-handle fas fa-grip-lines'
					{...dragHandleProps}
				/>
			) : (
				<></>
			),
	},
	{
		Header: () => <span className='Project-Name'>Track</span>,
		accessor: 'trackNumber',
		Cell: col => <span className='Name1'>{col.value}</span>,
	},
	{
		id: 'arrows',
		Header: () => <></>,
		Cell: ({
			onUpClick,
			onDownClick,
			isFirst,
			isLast,
			index,
			row,
			isCoOwner,
		}) =>
			isCoOwner ? (
				<ReorderTrackArrows
					{...{ onUpClick, onDownClick, isFirst, isLast, index, row }}
				/>
			) : (
				<></>
			),
	},

	{
		Header: () => <span className='Project-Name'>Title</span>,
		accessor: 'title',
		Cell: col => <span className='Name1'>{col.value}</span>,
	},
	{
		Header: () => <span className='Project-Name'>Artist Name</span>,
		accessor: 'artist',
		Cell: col => <span className='Name1'>{col.value}</span>,
	},
	{
		Header: () => <span className='Project-Name'>Files</span>,
		accessor: 'assetsCount',
		Cell: ({ value, row }) => <FilesColumn fileCount={value} row={row} />,
	},
	{
		Header: () => <span className='Project-Name'>Administrator</span>,
		accessor: 'owner',
		Cell: col => {
			const dispatch = useDispatch();

			return (
				<AdministratorColumn
					onClick={() => openShareModal(col, dispatch)}
					adminData={col.value}
					row={col.row}
				/>
			);
		},
	},
	{
		Header: () => <></>,
		accessor: 'actions',
		Cell: col => (
			<div className='d-flex justify-content-end'>
				<ActionsCell cell={col} />
			</div>
		),
	},
];

export default albumsColumns;
