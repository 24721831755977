import {
	createContext,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { getCurrentPlaylist } from '../store/playlists/selectors';
import UPLOAD_STATUS from '@/constants/uploadStatus.json';
import { filterPlaylistFilesBySearchTerm } from '../helpers/playlistTools';
import { isAudioFile, isFileUploading } from '../helpers/fileTools';
import {
	setPlayerPlayingAction,
	setPlayerPlaylistAction,
} from '../store/player/actions';
import { getPlaylistUploadsById } from '../store/files/selectors';
import { chain } from 'lodash';
import { ContextMenu } from 'primereact/contextmenu';
import {
	createParentGroupIndexes,
	getItemsInGroup,
} from '../components/screens/Playlists/PlaylistDetails/PlaylistFileTable/utilities';

export const SelectedPlaylistContext =
	createContext<SelectedPlaylistContextType | null>(null);

const SelectedPlaylistContextProvider = ({
	children,
}: {
	children: React.ReactNode;
}) => {
	const dispatch = useAppDispatch();
	const {
		recordingsById,
		albumsById,
		userEditableAlbumIds,
		userEditableRecordingIds,
	} = useAppSelector(state => state.projects);
	const { uploadsQueueById, fileLabelDetails } = useAppSelector(
		state => state.files
	);
	const {
		currentTrackIndex,
		tracklist,
		playlistId: playerPlaylistId,
	} = useAppSelector(state => state.player);
	const { seekTime } = useAppSelector(state => state.player);
	const currentPlaylist = useAppSelector(getCurrentPlaylist);
	const playlistUploads = useAppSelector(state =>
		getPlaylistUploadsById(state, { playlistId: currentPlaylist?.id! })
	);

	const [selectedItemIds, setSelectedItemIds] = useState<
		SelectedPlaylistContextType['selectedItemIds']
	>([]);
	const [selectionAnchorPoint, setSelectionAnchorPoint] =
		useState<SelectedPlaylistContextType['selectionAnchorPoint']>(null);
	const [hoverRowId, setHoverRowId] =
		useState<SelectedPlaylistContextType['hoverRowId']>(null);
	const [searchTerm, setSearchTerm] = useState('');
	const [sortedFolders, setSortedFolders] = useState<PlaylistFolder[]>(
		currentPlaylist?.playlist?.folders ?? []
	);
	const {
		fileIdToGroupIdIndex,
		groupIdToParentGroupIdIndex,
		groupIdToGroupContentIndex,
	} = useMemo(() => {
		// these are also computed in the global player state (player reducer)
		const indexes = !currentPlaylist?.playlist
			? {
					fileIdToGroupIdIndex: {},
					groupIdToParentGroupIdIndex: {},
					groupIdToGroupContentIndex: {},
			  }
			: createParentGroupIndexes({
					playlistFiles: currentPlaylist?.playlist?.files,
					playlistFolders: sortedFolders,
			  });

		return indexes;
	}, [currentPlaylist, sortedFolders]);

	// context menu stuff
	const contextMenuRef = useRef<ContextMenu>(null);

	// we need to keep this in state since we want to change the order indexes of the playlist files
	// in some cases (e.g. when removing a file from a folder and it goes to the end of the playlist)
	const [playlistFiles, setPlaylistFiles] = useState<PlaylistFileMetadata[]>(
		[]
	);

	// if the current playlist changes, we want to update the playlist files
	useEffect(() => {
		setPlaylistFiles(currentPlaylist?.playlist?.files ?? []);
	}, [currentPlaylist]);

	const currentTrackId = useMemo(
		() =>
			playerPlaylistId &&
			playerPlaylistId === currentPlaylist?.id &&
			currentTrackIndex > -1
				? tracklist[currentTrackIndex]
				: null,
		[playerPlaylistId, currentPlaylist, currentTrackIndex, tracklist]
	);

	const uploadingFiles = useMemo(() => {
		const uploads = currentPlaylist?.uploads
			?.map(uploadId => uploadsQueueById[uploadId])
			?.filter(upload =>
				[UPLOAD_STATUS.IN_PROGRESS, UPLOAD_STATUS.READY_TO_UPLOAD].includes(
					upload?.status
				)
			)
			?.map(
				upload =>
					({
						displayName: upload?.playlistMetadata?.displayName,
						recording: upload?.metadata?.recordingId
							? recordingsById?.[upload.metadata.recordingId]
							: null,
						album: upload?.metadata?.albumId
							? albumsById?.[upload.metadata.albumId]
							: null,
						label: upload?.metadata?.label,
						isUploading: true,
						filename: upload.metadata.filename,
						id: upload?.id,
					} as UploadingPlaylistFile)
			);

		return uploads ?? [];
	}, [currentPlaylist, uploadsQueueById, recordingsById, albumsById]);

	const allFiles = useMemo(
		() => [
			...uploadingFiles,
			...playlistFiles.sort((a, b) => a.orderIndex! - b.orderIndex!),
		],
		[uploadingFiles, playlistFiles]
	);

	const ungroupedFiles = useMemo(() => {
		const { files } = getItemsInGroup({
			sortedFolders,
			playlistFiles,
			parentGroupId: null,
			fileIdToGroupIdIndex,
			groupIdToGroupContentIndex,
		});

		return files;
	}, [
		playlistFiles,
		sortedFolders,
		fileIdToGroupIdIndex,
		groupIdToGroupContentIndex,
	]);

	const filteredFiles = useMemo(
		() =>
			fileLabelDetails
				? filterPlaylistFilesBySearchTerm(
						playlistFiles,
						searchTerm,
						fileLabelDetails
				  )
				: [],
		[playlistFiles, searchTerm, fileLabelDetails]
	);

	const filteredUploads = useMemo(
		() =>
			fileLabelDetails
				? filterPlaylistFilesBySearchTerm(
						uploadingFiles,
						searchTerm,
						fileLabelDetails
				  )
				: [],
		[uploadingFiles, searchTerm, fileLabelDetails]
	);

	const [sortedUngroupedFiles, setSortedUngroupedFiles] =
		useState(ungroupedFiles);
	const [isDeletingGroupIds, setIsDeletingGroupIds] = useState<
		PlaylistFileGroup['id'][]
	>([]);

	// used to determine if cursor is near the center of a row,
	// which is then used to highlight the folder it's being dragged over
	// (we want to differentiate between inserting a file/folder into a folder,
	// reordering folders, and dragging a file out of a folder)
	const [isAbovePointerDistanceThreshold, setIsAbovePointerDistanceThreshold] =
		useState(false);

	// effect to keep sortedFiles up to date with ungroupedFiles in case the playlist files change
	useEffect(() => {
		setSortedUngroupedFiles(ungroupedFiles);
	}, [ungroupedFiles]);

	// initialize sortedFolders with the current playlist's folders
	// this is necessary in case the playlist is null at the time of the first render
	useEffect(() => {
		setSortedFolders(currentPlaylist?.playlist?.folders ?? []);
	}, [currentPlaylist?.playlist?.folders]);

	useEffect(() => {
		// if the playlist files change, we want to filter out any selected files that are no longer in the playlist
		setSelectedItemIds(prevSelectedFileIds =>
			prevSelectedFileIds.filter(fileId =>
				allFiles.find(file => file.id === fileId)
			)
		);
	}, [allFiles]);

	// calculate uploading progress for each version being uploaded in playlist
	const uploadingVersionsProgressByFileId = useMemo(
		() =>
			chain(playlistUploads)
				.groupBy(upload => upload.versionMetadata?.fileId)
				.mapValues(
					uploads =>
						uploads.reduce((acc, upload) => acc + (upload.progress || 0), 0) /
						uploads.length
				)
				.value(),
		[playlistUploads]
	);

	const uploadingFilesCount = useMemo(
		() => allFiles.filter(isFileUploading).length,
		[allFiles]
	);

	// TODO: change sortedUngroupedFiles to playlist files list
	const editableFilesById = useMemo(
		() =>
			playlistFiles?.reduce((acc, file) => {
				if (
					('recordingId' in file &&
						file.recordingId &&
						userEditableRecordingIds?.includes(file.recordingId)) ||
					('albumId' in file &&
						file.albumId &&
						userEditableAlbumIds?.includes(file.albumId))
				) {
					acc[file.id] = true;
				} else {
					acc[file.id] = false;
				}

				return acc;
			}, {} as Record<PlaylistTableFile['id'], boolean>),
		[playlistFiles, userEditableRecordingIds, userEditableAlbumIds]
	);

	const handleSelectTrack = useCallback(
		(file: PlaylistTableFile, isDoubleClick: boolean = false) => {
			if (!isAudioFile(file.filename) || isFileUploading(file)) return;

			// if it's double click, we want to restart the track, so we avoid this block
			if (currentTrackId === file.id) {
				if (isDoubleClick && seekTime) {
					seekTime(0);
				}

				dispatch(setPlayerPlayingAction(true));
				return;
			}

			dispatch(
				setPlayerPlaylistAction({
					playlistId: currentPlaylist?.id!,
					fromTrackId: file.id,
				})
			);
		},
		[currentPlaylist, dispatch, currentTrackId, seekTime]
	);

	return (
		<SelectedPlaylistContext.Provider
			value={{
				currentTrackId,
				uploadingFilesCount,
				selectedItemIds: selectedItemIds,
				setSelectedItemIds,
				hoverRowId,
				setHoverRowId,
				uploadingFiles,
				searchTerm,
				setSearchTerm,
				filteredFiles,
				handleSelectTrack,
				uploadingVersionsProgressByFileId,
				sortedUngroupedFiles,
				setSortedUngroupedFiles,
				editableFilesById,
				contextMenuRef,
				filteredUploads,
				sortedFolders,
				setSortedFolders,
				fileIdToGroupIdIndex,
				groupIdToParentGroupIdIndex,
				groupIdToGroupContentIndex,
				selectionAnchorPoint,
				setSelectionAnchorPoint,
				isDeletingGroupIds,
				setIsDeletingGroupIds,
				isAbovePointerDistanceThreshold,
				setIsAbovePointerDistanceThreshold,
				playlistFiles,
				setPlaylistFiles,
			}}
		>
			{children}
		</SelectedPlaylistContext.Provider>
	);
};

export default SelectedPlaylistContextProvider;
