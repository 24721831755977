import { useState, useEffect } from 'react';
import Lottie from 'react-lottie';
import './ChatModal.scss';
import { Modal } from 'react-bootstrap';
import lightAnimationData from '../../../../assets/animations/blackLoaderOnLightGrey.json';
import {
	getComments,
	addComment,
} from '../../../../api/services/notificationService';
import TextField from '../../../form/TextField';
import Button from '../../../layout/Button';
import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { setModalTitle } from '../../../../store/modal/actions';

const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: lightAnimationData,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice',
	},
};

export type ChatModalProps = {
	recordingId: number;
};

const ChatModal = ({ recordingId }: ChatModalProps) => {
	const [comments, setComments] = useState<ProjectComment[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const { recordingsById } = useAppSelector(state => state.projects);
	const dispatch = useAppDispatch();

	const recording = recordingsById?.[recordingId];

	const dateFormatter = new Intl.DateTimeFormat(undefined, {
		dateStyle: 'long',
		timeStyle: 'short',
	});

	const sendMessage = (e: KeyboardEvent) => {
		const btn = document.getElementById('message-submit-button');
		const message = document.querySelector(
			'#input-message'
		) as HTMLInputElement;
		const modal = document.querySelector('.modal-body');

		if (!message) return;

		if (!message.value?.trim()) return;

		if (modal) modal.scrollTo(0, modal.scrollHeight);

		if (message && message.value) message.value.trim();

		if (e.key === 'Enter' && !e.shiftKey && message && btn) {
			btn.click();

			if (modal) modal.scrollTo(0, modal.scrollHeight);
		}
	};

	const fetchComments = useCallback(async () => {
		try {
			// return
			const res = await getComments(recordingId);
			const { comments } = res.data;

			updateComments(comments);
			setIsLoading(false);
		} catch (error) {
			console.error(error);

			setIsLoading(true);
		}
	}, [recordingId]);

	const updateComments = (newComments: ProjectComment[]) =>
		setComments(
			newComments.sort((a, b) => {
				let dateA = new Date(a.createdAt).getTime();
				let dateB = new Date(b.createdAt).getTime();

				return dateA - dateB; // You can switch dateB - dateA for descending order
			})
		);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		if (!recordingId) throw new Error('No recordingId');
		try {
			e.preventDefault();

			setIsSubmitting(true);
			// const form = e.target
			const messageInput = document.querySelector(
				'#input-message'
			) as HTMLInputElement;
			const message = messageInput.value;
			messageInput.value = '';
			console.log(message);

			const res = await addComment({
				recordingId: recordingId,
				message,
			});
			const { comments } = res.data;

			updateComments(comments);

			setIsSubmitting(false);
		} catch (e) {
			console.error(e);
			setIsSubmitting(false);
		}
	};

	useEffect(() => {
		fetchComments();

		document.addEventListener('keypress', sendMessage);

		return () => {
			document.removeEventListener('keypress', sendMessage);
		};
	}, [fetchComments]);

	useEffect(() => {
		const modal = document.querySelector('.modal-body');
		if (comments && !isLoading && modal) {
			console.log(modal, modal.scrollHeight);
			modal.scrollTo(0, modal.scrollHeight);
		}
	}, [isLoading, comments]);

	useEffect(() => {
		dispatch(setModalTitle(`Chat - ${recording?.title}`));
	}, [dispatch, recording]);

	const Message = ({ msg }: { msg: ProjectComment }) => {
		return (
			<div className='chat-item mb-4 w-100'>
				<header className='d-flex align-items-center'>
					<figure
						className='m-0 d-flex align-items-center'
						style={{ flex: '3' }}
					>
						{msg.userImagePath ? (
							<img
								className='fallback-image mr-3'
								style={{
									borderRadius: '7px',
									maxWidth: '30px',
									maxHeight: '30px',
								}}
								src={msg.userImagePath}
								alt={`${msg.userName} avatar`}
							/>
						) : (
							<div className='mr-3 chat-fallback-image'>
								<i style={{ color: 'white' }} className='fas fa-user'></i>
							</div>
						)}
						<figcaption>
							<h4>{msg.userName}</h4>
						</figcaption>
					</figure>
					<small
						style={{
							color: '#808080',
						}}
					>
						{dateFormatter.format(new Date(msg.createdAt))}
						{/* {msg.createdAt} */}
					</small>
				</header>
				<main>
					<p style={{ fontSize: '0.90rem' }}>{msg.message}</p>
				</main>
			</div>
		);
	};

	if (isLoading) {
		return (
			<div style={{ display: 'grid', placeItems: 'center' }}>
				<Lottie
					options={defaultOptions}
					isClickToPauseDisabled
					height={150}
					width={150}
				/>
			</div>
		);
	}

	return (
		<>
			<Modal.Body
				style={{
					overflowY: 'scroll',
					overflowX: 'hidden',
					height: '50vh',
				}}
			>
				{/* Messages */}
				{comments.length ? (
					comments.map((msg, i) => <Message key={`message ${i}`} msg={msg} />)
				) : (
					<h2 className='text-center'>Be the first to send a message!</h2>
				)}
			</Modal.Body>
			<Modal.Footer>
				{/* TODO: MIGRATE TO FORMIK FORM */}
				<form action='' className='w-100 text-right' id='send-message-form'>
					<TextField
						placeholder='Type a message...'
						type='text'
						as='textarea'
						required
						id='input-message'
					/>
					<Button
						label={
							!comments.length ? 'Send' : isSubmitting ? 'Sending...' : 'Reply'
						}
						theme='light'
						type='submit'
						isDisabled={isSubmitting}
						isLoading={isSubmitting}
						onClick={handleSubmit as any} // TODO: TS MIGRATION: fix any
						id='message-submit-button'
					/>
				</form>
			</Modal.Footer>
		</>
	);
};

export default ChatModal;
