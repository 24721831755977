import isniStrings, { IsniString } from '../isniStrings';
import { DataView } from 'primereact/dataview';
import { useEffect, useState } from 'react';
import Button from '../../../layout/Button/Button';
import SoundCreditLoader from '../../SoundCreditLoader/SoundCreditLoader';
import IsniService from '../../../../api/services/isniService';
import { Message } from 'primereact/message';
import { PaginatorPageChangeEvent } from 'primereact/paginator';
import { Card } from 'react-bootstrap';

type SearchStepProps = {
	onSubmit: (isni?: string) => Promise<void>;
	onBack: () => void;
	getIsniStringFn: (isniString: IsniString) => string;
	searchFields: {
		forename: string;
		surname: string;
	};
	setIsniResults: React.Dispatch<React.SetStateAction<string[] | null>>;
};

const RESULT_LIST_LIMIT = 5;

type IsniNameSearchResultRow = IsniNameSearchResult & {
	rowNumber: number;
};

const SearchStep = ({
	onBack,
	onSubmit,
	getIsniStringFn,
	searchFields,
	setIsniResults,
}: SearchStepProps) => {
	const [results, setResults] = useState<IsniNameSearchResultRow[] | null>(
		null
	);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	const resultTemplate = (result: IsniNameSearchResultRow) => (
		<Card className='mb-2 p-4'>
			<div className='d-flex justify-content-end'>
				<span className='text-muted'>
					{result.rowNumber} of {results?.length}
				</span>
			</div>

			<strong>Names:</strong>
			<ul>
				{result.namesForDisplay.map(name => (
					<li>{name}</li>
				))}
			</ul>

			{result.pastRoles.length > 0 && (
				<>
					<strong>Past Roles:</strong>
					<ul>
						{result.pastRoles.slice(0, RESULT_LIST_LIMIT).map(role => (
							<li>{role}</li>
						))}
					</ul>
				</>
			)}

			{result.pastWorks.length > 0 && (
				<>
					<strong>Past Works:</strong>
					<ul>
						{result.pastWorks.slice(0, RESULT_LIST_LIMIT).map(work => (
							<li>{work}</li>
						))}
					</ul>
				</>
			)}

			{result.externalInformationSources.length > 0 && (
				<>
					<strong>Resources:</strong>
					<ul>
						{result.externalInformationSources
							.slice(0, RESULT_LIST_LIMIT)
							.map(source => (
								<li>{source}</li>
							))}
					</ul>
				</>
			)}

			<div className='w-100 d-flex justify-content-end'>
				<Button
					label={getIsniStringFn(isniStrings.Search.claimButton)}
					onClick={() => onSubmit(result.isniCode)}
					theme='dark'
				/>
			</div>
		</Card>
	);

	useEffect(() => {
		if (!loading && !results) {
			setLoading(true);
			IsniService.nameSearch(searchFields.forename, searchFields.surname)
				.then(results => {
					setResults(
						results.data.names.map((name, index) => ({
							...name,
							rowNumber: index + 1,
						}))
					);

					setIsniResults(results.data.names.map(name => name.isniCode));
				})
				.catch(err => {
					console.error(err);
					setError(
						'Whoops! Something went wrong while searching for existing ISNIs that match your details. Please try again.'
					);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [
		loading,
		results,
		searchFields.forename,
		searchFields.surname,
		setIsniResults,
	]);

	if ((!results && !error) || loading) {
		return <SoundCreditLoader message='Searching for ISNIs...' />;
	}

	if (!results || error) {
		return (
			<div className='d-flex align-items-center flex-column justify-content-center'>
				<Message severity='error' text={error} className='mb-2' />
				<Button
					label='Retry'
					rightIcon='fas fa-redo'
					onClick={() => setError(null)}
					theme='dark'
				/>
			</div>
		);
	}

	if (results.length === 0) {
		return (
			<>
				<h3>{getIsniStringFn(isniStrings.Search.notFoundTitle)}</h3>

				<h4>{getIsniStringFn(isniStrings.Search.notFoundDescription)}</h4>
				<div className='isni-registration__buttons mt-5'>
					<Button
						label='Back'
						leftIcon='fas fa-arrow-left'
						onClick={() => onBack()}
					/>

					<Button
						label='Next'
						rightIcon='fas fa-arrow-right'
						onClick={() => onSubmit()}
						theme='dark'
					/>
				</div>
			</>
		);
	}

	return (
		<>
			<h3>{getIsniStringFn(isniStrings.Search.foundTitle)}</h3>

			<h4>{getIsniStringFn(isniStrings.Search.foundDescription)}</h4>

			<DataView
				value={results}
				itemTemplate={resultTemplate}
				paginator
				rows={5}
				first={0}
				totalRecords={results.length}
				dataKey='rowNumber'
				// @ts-ignore
				pt={{
					paginator: {
						onPageChange: (e: PaginatorPageChangeEvent) => {
							window.scrollTo(0, 0);
						},
					},
				}}
			/>

			<div className='my-3' />
			<h4>{getIsniStringFn(isniStrings.Search.ifNoMatch)}</h4>

			<div className='isni-registration__buttons'>
				<Button
					label='Back'
					leftIcon='fas fa-arrow-left'
					onClick={() => onBack()}
				/>

				<Button
					label={getIsniStringFn(isniStrings.Search.noMatchButton)}
					onClick={() => onSubmit()}
					theme='dark'
				/>
			</div>
		</>
	);
};

export default SearchStep;
