import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Header from '../Header';
import './Page.scss';

const Page = ({ submenu = null, children, withHeader = true }) => (
	<>
		<div className='content-wrapper'>
			<Container className='h-100' fluid>
				<Row className='h-100'>
					{!submenu && (
						<>
							<Col
								xs={12}
								className='p-0'
								style={{ height: '100%', overflow: 'auto' }}
							>
								{withHeader ? <Header /> : <></>}
								<div className={withHeader ? 'page-container' : 'h-100'}>
									{children}
								</div>
							</Col>
						</>
					)}
					{submenu && (
						<>
							<Col
								xs={2}
								className='bg-dark-credit animate__animated animate__fadeInLeft animate__fast p-0'
								style={{ userSelect: 'none' }}
							>
								{submenu}
							</Col>

							<Col
								xs={10}
								className='p-0'
								style={{ height: '100%', overflow: 'auto' }}
							>
								{withHeader ? <Header /> : <></>}
								<div className={withHeader ? 'page-container' : 'h-100'}>
									{children}
								</div>
							</Col>
						</>
					)}
				</Row>
			</Container>
		</div>
	</>
);

export default Page;
