import React, { useMemo } from 'react';
import styles from './Chip.module.scss';
import clsx from 'clsx';

export type ChipProps = {
	label: string;
	onClick?: (e: React.MouseEvent<Element, MouseEvent>) => void;
	isReadOnly?: boolean;
	isDisabled?: boolean;
	style?: React.CSSProperties;
	variant?: 'primary' | 'secondary';
	leftIcon?: string;
	rightIcon?: string;
	stretch?: boolean;
	className?: string;
};

const Chip = ({
	label,
	onClick,
	isReadOnly = false,
	isDisabled = false,
	style = {},
	variant = 'primary',
	leftIcon,
	rightIcon,
	stretch = false,
	className = '',
}: ChipProps) => {
	const handleClick = (e: React.MouseEvent<Element, MouseEvent>) => {
		e.stopPropagation();

		if (isReadOnly) return;

		if (onClick) {
			onClick(e);
			return;
		}
	};

	const variantClass = useMemo(() => {
		switch (variant) {
			case 'primary':
				return 'primary-chip';
			case 'secondary':
				return 'secondary-chip';
			default:
				return 'primary-chip';
		}
	}, [variant]);

	return (
		<div
			onClick={handleClick}
			className={clsx(
				styles[variantClass],
				isReadOnly ? styles['read-only'] : '',
				isDisabled ? styles['disabled'] : '',
				stretch ? styles['stretch'] : '',
				className
			)}
			style={{
				...style,
			}}
		>
			{leftIcon && <i className={`${leftIcon} mr-2`}></i>}
			{label}
			{rightIcon && <i className={`${rightIcon} ml-2`}></i>}
		</div>
	);
};

export default Chip;
