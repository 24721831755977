import React, { useMemo } from 'react';
import TextField from '../TextField';
import './PillSearchBar.scss';

export type PillSearchBarProps = {
	placeholder?: string;
	value: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	inputClassName?: string;
	className?: string;
	theme?: 'dark' | 'light';
	isDisabled?: boolean;
	isLoading?: boolean;
};

const PillSearchBar = ({
	placeholder = '',
	value,
	onChange,
	inputClassName = '',
	className = '',
	theme = 'dark',
	isDisabled = false,
	isLoading = false,
}: PillSearchBarProps) => {
	const searchBarClass = useMemo(() => {
		switch (theme) {
			case 'dark':
				return 'pill-search-bar-input-dark';
			case 'light':
				return 'pill-search-bar-input-light';
			default:
				return 'pill-search-bar-input-dark';
		}
	}, [theme]);

	return (
		<div className={`pill-search-bar-container  ${className}`}>
			<i className='fas fa-search pill-search-bar-icon'></i>
			<TextField
				id='pill-search-bar'
				type='text'
				name='pill-search-bar'
				placeholder={placeholder}
				dense
				className={`w-100 ${isLoading ? 'placeholder-glow' : ''}`}
				inputClassName={`pill-search-bar-input ${searchBarClass} ${inputClassName} ${
					isLoading ? 'placeholder' : ''
				}`}
				isDisabled={isDisabled || isLoading}
				value={value}
				onChange={onChange}
			/>
		</div>
	);
};

export default PillSearchBar;
