const formatFullAddress = (
	address1?: string | null,
	address2?: string | null,
	city?: string | null,
	state?: string | null,
	postalCode?: string | null,
	country?: string | null,
) => {
	return [address1, address2, city, state, postalCode, country]
		.filter(address => !!address)
		.join(', ');
};

export default formatFullAddress;
