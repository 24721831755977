import React from 'react';
import {
	CREATE_NEW_ALBUM_MODAL,
	CREATE_NEW_RECORDING_MODAL,
	EXPORT_SELECTION_MODAL,
	PROJECTS_FILE_PREVIEW_MODAL,
	SELECT_RECORDING_MODAL,
	SEND_PROJECT_MODAL,
	GENERATE_CODE_MODAL,
	ALBUM_DETAILS_MODAL,
	DELETE_RECORDING_MODAL,
	DELETE_ALBUM_MODAL,
	DELETE_PARTICIPANT_MODAL,
	EXPORT_IMAGE_INPUT_MODAL,
	SHARE_MODAL,
	CHAT_MODAL,
	PROJECTS_SHARE_MODAL,
	DELETE_ALL_PROFILES_MODAL,
	IMPORT_RIN_MODAL,
	EDIT_ADDRESS_MODAL,
	VALIDATE_GRID_MODAL,
	EXIT_UNION_FORM_MODAL,
	VALIDATE_MOODS_GENERATOR,
	DELETE_FILE_MODAL,
	SHARE_FILES_MODAL,
	UPLOAD_FILES_MODAL,
	RENAME_FILE_MODAL,
	UPLOAD_PREVIEW_MODAL,
	EXPORT_PREVIEW_MODAL,
	LOADING_MODAL,
	COPY_CREDITS_FROM_RECORDING_MODAL,
	COPY_CREDITS_TO_RECORDINGS_MODAL,
	SELECT_REPRESENTATIVE_MODAL,
	TIER_LOCK_MODAL,
	PROJECT_LIMIT_MODAL,
	STORAGE_LIMIT_MODAL,
	CHANGE_OWNER_LIMIT_MODAL,
	EDIT_FILE_TAG_MODAL,
	SHARE_PLAYLIST_MODAL,
	NEW_PLAYLIST_MODAL,
	EDIT_PLAYLIST_DETAILS_MODAL,
	ADD_FILES_TO_PLAYLIST_MODAL,
	UPLOAD_FILES_TO_PLAYLIST_MODAL,
	SELECT_PROJECT_FILES_MODAL,
	DELETE_MODAL,
	EDIT_PLAYLIST_FILE_MODAL,
	FRESH_INSTALL_INFO_MODAL,
	PLAYLISTS_RUNDOWN_MODAL,
	IMAGE_CROP_MODAL,
	CORRUPTED_FILES_MODAL,
	CREATE_DRIP_MODAL,
	WHAT_IS_A_DRIP_MODAL,
	DRIP_DASHBOARD_MODAL,
	DRIP_AGREEMENT_MODAL,
	TRANSFER_PLAYLIST_MODAL,
	FILE_VERSION_LIST_MODAL,
	UPLOAD_FILE_VERSION_MODAL,
	EDIT_FILE_VERSION_MODAL,
	DUPLICATE_PLAYLIST_MODAL,
	PLAYLIST_COLLAB_MODAL,
	INVITE_PLAYLIST_EDITOR_MODAL,
	CONFIRMATION_MODAL,
	SET_PLAYLIST_PASSWORD_MODAL,
	GROUP_TO_ALBUM_MODAL,
	UPGRADE_TIER_MODAL,
	BENEFITS_CODE_MODAL,
	CONFIRM_UPGRADE_MODAL,
	CHECK_EMAIL_SIGNUP_MODAL,
	ARE_YOU_UNION_MEMBER_MODAL,
	ACCOUNT_DETAILS_MODAL,
	ISNI_REGISTRATION_MODAL,
	PLAYLIST_CTA_MODAL_JULY_2023,
	EDIT_FILE_PROJECT_MODAL,
	UPLOAD_VERSIONS_TO_PLAYLIST_MODAL,
	MANAGE_UPLOADING_VERSIONS_MODAL,
	PRODUCER_AGREEMENT_SHARE_MODAL,
	EXPORT_ESIGNATURE_MODAL,
	CREDIT_REQUESTS_MODAL,
	CREATE_PLAYLIST_FOLDER_MODAL,
	RENAME_PLAYLIST_FOLDER_MODAL,
	SEND_PLAYLIST_LINK_MODAL,
} from '../../../constants/modalTypes';
import ExportSelectionModal from '../../screens/Export/ExportSelectionModal';
import SelectRecordingModal from '../../screens/Export/SelectRecordingModal/SelectRecordingModal';
import FilePreview from '../../screens/Projects/FilePreview/FilePreview';
import NewRecordingModal from '../../screens/Projects/NewRecordingModal';
import SendModal from '../../screens/Projects/SendModal';
import GenerateCodeModal from '../../screens/GenerateCodes/GenerateCodeModal';
import AlbumDetailsModal from '../../screens/AlbumDetailsModal';
import DeleteRecordingModal from '../../screens/Projects/DeleteRecordingModal';
import DeleteAlbumModal from '../../screens/Projects/DeleteAlbumModal';
import DeleteParticipantModal from '../../screens/Credits/DeleteParticipantModal/DeleteParticipantModal';
import ExportImageInputModal from '../../screens/Export/ExportImageInputModal';
import ShareModal from '../../screens/Projects/ShareModal';
import ChatModal from '../../screens/Projects/ChatModal';
import ProjectsShareModal from '../../screens/Projects/ProjectsShareModal';
import DeleteAllProfilesModal from '../../screens/Settings/DeleteAllProfilesModal';
import ImportRinModal from '../../screens/Import/ImportRinModal';
import EditAddressModal from '../../screens/EditAddressModal/EditAddressModal';
import ValidateGRidModal from '../../screens/Credits/ValidateGRidModal/ValidateGRidModal';
import ExitUnionFormModal from '../../screens/Export/UnionFormExport/ExitUnionFormModal';
import ValidateMoodsGenerator from '../../screens/Lyrics/ValidateMoodsGenerator/ValidateMoodsGenerator';
import DeleteFileModal from '../../screens/Export/DeleteFileModal';
import ShareFilesModal from '../../screens/ProjectFiles/ShareFileModal';
import UploadFilesModal from '../../screens/ProjectFiles/UploadFilesModal';
import RenameFileModal from '../../screens/ProjectFiles/RenameFileModal';
import UploadPreviewModal from '../../screens/ProjectFiles/FilePreviewModal/UploadPreviewModal';
import ExportPreviewModal from '../../screens/ProjectFiles/FilePreviewModal/ExportPreviewModal';
import LoadingModal from '../../screens/LoadingModal';
import CopyCreditsFromOtherRecording from '../../screens/Credits/CopyCreditsFromOtherRecording';
import CopyCreditsToOtherRecordings from '../../screens/Credits/CopyCreditsToOtherRecordings';
import SelectRepresentativeProfileModal from '../../screens/Export/UnionFormExport/SelectRepresentativeProfileModal';
import ProjectLimitModal from '../../screens/Projects/ProjectLimitModal/ProjectLimitModal';
import StorageLimitModal from '../../screens/ProjectFiles/StorageLimitModal';
import ChangeOwnerLimitModal from '../../screens/ChangeOwnerLimitModal';
import EditFileTagModal from '../../screens/ProjectFiles/EditFileTagModal';
import SharePlaylistModal from '../../screens/Playlists/PlaylistDetails/SharePlaylistModal';
import NewPlaylistModal from '../../screens/Playlists/NewPlaylistModal';
import EditPlaylistDetailsModal from '../../screens/Playlists/PlaylistDetails/EditPlaylistDetailsModal';
import AddFilesToPlaylistModal from '../../screens/Playlists/PlaylistDetails/AddFilesToPlaylistModal';
import UploadFilesToPlaylistModal from '../../screens/Playlists/PlaylistDetails/UploadFilesToPlaylistModal';
import SelectProjectFilesModal from '../../screens/SelectProjectFilesModal/SelectProjectFilesModal';
import DeleteModal from '../../screens/DeleteModal';
import EditPlaylistFileModal from '../../screens/Playlists/PlaylistDetails/PlaylistFileTable/EditPlaylistFileModal';
import FreshInstallInfoModal from '../../screens/FreshInstallInfoModal';
import PlaylistsRundownModal from '../../screens/FreshInstallInfoModal/PlaylistsRundownModal';
import ImageCropModal from '../../screens/ImageCropModal';
import CorruptedFilesModal from '../../screens/CorruptedFilesModal';
import CreateDripModal from '../../screens/Playlists/PlaylistDetails/CreateDripModal';
import WhatIsADripModal from '../../screens/Playlists/PlaylistDetails/WhatIsADripModal';
import PlaylistDripDashboard from '../../screens/Playlists/PlaylistDetails/PlaylistDripDashboard';
import DripAgreementModal from '../../screens/Playlists/PlaylistDetails/DripAgreementModal/DripAgreementModal';
import TransferPlaylistModal from '../../screens/Playlists/PlaylistDetails/TransferPlaylistModal';
import FileVersionListModal from '../../screens/ProjectFiles/FileVersionListModal/FileVersionListModal';
import UploadFileVersionModal from '../../screens/ProjectFiles/UploadFileVersionModal';
import EditFileVersionModal from '../../screens/ProjectFiles/EditFileVersionModal';
import DuplicatePlaylistModal from '../../screens/Playlists/PlaylistDetails/DuplicatePlaylistModal';
import PlaylistCollabModal from '../../screens/Playlists/PlaylistDetails/PlaylistCollabModal';
import InvitePlaylistEditorModal from '@/components/screens/Playlists/PlaylistDetails/PlaylistCollabModal/InvitePlaylistEditorModal';
import ConfirmationModal from '../../screens/ConfirmationModal';
import SetPlaylistPasswordModal from '../../screens/Playlists/PlaylistDetails/SharePlaylistModal/SetPlaylistPasswordModal/SetPlaylistPasswordModal';
import GroupToAlbumModal from '../../screens/Projects/GroupToAlbumModal/GroupToAlbumModal';
import CreateNewAlbumModal from '../../screens/RecordingDetails/CreateNewAlbumModal/CreateNewAlbumModal';
import TierLockModal from '../../screens/TierLockModal';
import UpgradeTierModal from '../../screens/UpgradeTierModal';
import BenefitsCodeModal from '@/components/screens/UpgradeTierModal/BenefitsCodeModal';
import ConfirmUpgradeModal from '@/components/screens/UpgradeTierModal/ConfirmUpgradeModal';
import CheckEmailSignUpModal from '../../screens/CheckEmailSignUpModal';
import AreYouUnionMemberModal from '@/components/screens/AreYouUnionMemberModal';
import AccountDetailsModal from '@/components/screens/AccountDetailsModal/AccountDetailsModal';
import IsniRegistration from '@/components/screens/IsniRegistration/IsniRegistration';
import PlaylistJuly2023PromoModal from '../../modals/PlaylistJuly2023PromoModal/PlaylistJuly2023PromoModal';
import EditFileProjectModal from '../../screens/Playlists/PlaylistDetails/PlaylistFileTable/EditFileProjectModal/EditFileProjectModal';
import UploadVersionsToPlaylistModal from '../../screens/Playlists/PlaylistDetails/UploadVersionsToPlaylistModal';
import ManageUploadingVersionsModal from '../../screens/ManageUploadingVersionsModal';
import ProducerAgreementShareModal from '../../screens/Export/ProducerAgreementShareModal';
import ExportESignatureModal from '../../screens/ExportESignatureModal';
import CreditRequestsModal from '../../screens/Playlists/PlaylistDetails/CreditRequestsModal/CreditRequestsModal';
import SendPlaylistLinkModal from '../../screens/Playlists/PlaylistDetails/SendPlaylistLinkModal';
import CreatePlaylistFolderModal from '../../screens/CreatePlaylistFolderModal';
import RenamePlaylistFolderModal from '../../screens/Playlists/PlaylistDetails/PlaylistFileTable/RenamePlaylistFolderModal/RenamePlaylistFolderModal';

const ModalBody = ({
	modalType,
	modalProps,
}: {
	modalType: string;
	modalProps: any;
}) => {
	switch (modalType) {
		case SEND_PROJECT_MODAL:
			return <SendModal />;
		case PROJECTS_FILE_PREVIEW_MODAL:
			return <FilePreview />;

		case EXPORT_SELECTION_MODAL:
			return <ExportSelectionModal />;
		case SELECT_RECORDING_MODAL:
			return (
				<SelectRecordingModal
					onSubmit={modalProps.onSubmit}
					title={modalProps.title}
					recordingFilter={modalProps.recordingFilter}
					recordingSortFn={modalProps.recordingSortFn}
				/>
			);
		case CREATE_NEW_ALBUM_MODAL:
			return (
				<CreateNewAlbumModal
					onCreate={modalProps.onCreate}
					recordingIds={modalProps.recordingIds}
				/>
			);
		case CREATE_NEW_RECORDING_MODAL:
			return (
				<NewRecordingModal
					onCreate={modalProps.onCreate}
					albumId={modalProps.albumId}
					clearCurrentAlbumIdAfterImport={
						modalProps.clearCurrentAlbumIdAfterImport
					}
				/>
			);
		case GENERATE_CODE_MODAL:
			return <GenerateCodeModal />;
		case ALBUM_DETAILS_MODAL:
			return (
				<AlbumDetailsModal
					albumId={modalProps.albumId}
					afterSubmit={modalProps.afterSubmit}
					toastErrors={modalProps.toastErrors}
				/>
			);
		case DELETE_RECORDING_MODAL:
			return (
				<DeleteRecordingModal project={modalProps.project} id={modalProps.id} />
			);
		case DELETE_ALBUM_MODAL:
			return <DeleteAlbumModal id={modalProps.id} />;
		case DELETE_PARTICIPANT_MODAL:
			return (
				<DeleteParticipantModal
					participant={modalProps.participant}
					deleteFromRecording={modalProps.deleteFromRecording}
					recordingId={modalProps.recordingId}
					onClose={modalProps.onClose}
				/>
			);
		case EXPORT_IMAGE_INPUT_MODAL:
			return (
				<ExportImageInputModal
					onSubmit={modalProps.onSubmit}
					title={modalProps.title}
					inputLabel={modalProps.inputLabel}
				/>
			);
		case CHAT_MODAL:
			return <ChatModal recordingId={modalProps.recordingId} />;
		case SHARE_MODAL:
			return (
				<ShareModal
					albumId={modalProps.albumId}
					recordingId={modalProps.recordingId}
				/>
			);
		case PROJECTS_SHARE_MODAL:
			return (
				<ProjectsShareModal
					values={modalProps.values}
					shareAlbum={modalProps.shareAlbum}
				/>
			);
		case DELETE_ALL_PROFILES_MODAL:
			return <DeleteAllProfilesModal />;
		case IMPORT_RIN_MODAL:
			return (
				<ImportRinModal
					albumId={modalProps.albumId}
					clearCurrentAlbumIdAfterImport={
						modalProps.clearCurrentAlbumIdAfterImport
					}
				/>
			);
		case EDIT_ADDRESS_MODAL:
			return (
				<EditAddressModal
					initialValues={modalProps.initialValues}
					onSubmit={modalProps.onSubmit}
					validate={modalProps.validate}
					title={modalProps.title}
				/>
			);
		case VALIDATE_GRID_MODAL:
			return <ValidateGRidModal errorList={modalProps.errorList} />;
		case VALIDATE_MOODS_GENERATOR:
			return <ValidateMoodsGenerator errorList={modalProps.errorList} />;
		case EXIT_UNION_FORM_MODAL:
			return (
				<ExitUnionFormModal
					onSave={modalProps.onSave}
					onDismiss={modalProps.onDismiss}
					onDiscard={modalProps.onDiscard}
				/>
			);
		case DELETE_FILE_MODAL:
			return (
				<DeleteFileModal
					onDelete={modalProps.onDelete}
					isExport={modalProps.isExport}
				/>
			);
		case SHARE_FILES_MODAL:
			return (
				<ShareFilesModal
					fileIds={modalProps.fileIds}
					recordingId={modalProps.recordingId}
					albumId={modalProps.albumId}
				/>
			);
		case UPLOAD_FILES_MODAL:
			return (
				<UploadFilesModal
					recordingId={modalProps.recordingId}
					albumId={modalProps.albumId}
					navigateToFilesOnUpload={modalProps.navigateToFilesOnUpload}
					title={modalProps.title}
				/>
			);
		case RENAME_FILE_MODAL:
			return (
				<RenameFileModal
					fileId={modalProps.fileId}
					playlistId={modalProps.playlistId}
				/>
			);
		case UPLOAD_PREVIEW_MODAL:
			return <UploadPreviewModal fileId={modalProps.fileId} />;
		case EXPORT_PREVIEW_MODAL:
			return <ExportPreviewModal exportId={modalProps.exportId} />;
		case LOADING_MODAL:
			return <LoadingModal message={modalProps.message} />;
		case COPY_CREDITS_FROM_RECORDING_MODAL:
			return <CopyCreditsFromOtherRecording />;
		case COPY_CREDITS_TO_RECORDINGS_MODAL:
			return <CopyCreditsToOtherRecordings />;
		case SELECT_REPRESENTATIVE_MODAL:
			return <SelectRepresentativeProfileModal formik={modalProps.formik} />;
		case TIER_LOCK_MODAL:
			return <TierLockModal />;
		case PROJECT_LIMIT_MODAL:
			return <ProjectLimitModal message={modalProps.message} />;
		case STORAGE_LIMIT_MODAL:
			return <StorageLimitModal message={modalProps.message} />;
		case CHANGE_OWNER_LIMIT_MODAL:
			return <ChangeOwnerLimitModal message={modalProps.message} />;
		case EDIT_FILE_TAG_MODAL:
			return (
				<EditFileTagModal
					fileId={modalProps.fileId}
					playlistId={modalProps.playlistId}
				/>
			);
		case SHARE_PLAYLIST_MODAL:
			return (
				<SharePlaylistModal
					playlistId={modalProps.playlistId}
					showTransferSecurityWarning={modalProps.showTransferSecurityWarning}
				/>
			);
		case NEW_PLAYLIST_MODAL:
			return <NewPlaylistModal />;
		case EDIT_PLAYLIST_DETAILS_MODAL:
			return <EditPlaylistDetailsModal playlistId={modalProps.playlistId} />;
		case ADD_FILES_TO_PLAYLIST_MODAL:
			return (
				<AddFilesToPlaylistModal
					playlistId={modalProps.playlistId}
					initialFiles={modalProps.initialFiles}
				/>
			);
		case UPLOAD_FILES_TO_PLAYLIST_MODAL:
			return (
				<UploadFilesToPlaylistModal
					playlistId={modalProps.playlistId}
					initialFiles={modalProps.initialFiles}
				/>
			);
		case SELECT_PROJECT_FILES_MODAL:
			return <SelectProjectFilesModal onSubmit={modalProps.onSubmit} />;
		case DELETE_MODAL:
			return (
				<DeleteModal
					onCancel={modalProps.onCancel}
					onDelete={modalProps.onDelete}
					title={modalProps.title}
					body={modalProps.body}
					confirmText={modalProps.confirmText}
					cancelText={modalProps.cancelText}
					showUndoWarning={modalProps.showUndoWarning}
				/>
			);
		case EDIT_PLAYLIST_FILE_MODAL:
			return (
				<EditPlaylistFileModal
					playlistId={modalProps.playlistId}
					fileId={modalProps.fileId}
				/>
			);
		case FRESH_INSTALL_INFO_MODAL:
			return <FreshInstallInfoModal />;
		case PLAYLISTS_RUNDOWN_MODAL:
			return <PlaylistsRundownModal />;
		case IMAGE_CROP_MODAL:
			return (
				<ImageCropModal
					aspectRatio={modalProps.aspectRatio}
					onSave={modalProps.onSave}
					title={modalProps.title}
					outputType={modalProps.outputType}
				/>
			);
		case CORRUPTED_FILES_MODAL:
			return <CorruptedFilesModal corruptedFiles={modalProps.corruptedFiles} />;
		case CREATE_DRIP_MODAL:
			return <CreateDripModal playlistId={modalProps.playlistId} />;
		case WHAT_IS_A_DRIP_MODAL:
			return <WhatIsADripModal />;
		case DRIP_DASHBOARD_MODAL:
			return <PlaylistDripDashboard playlistId={modalProps.playlistId} />;
		case DRIP_AGREEMENT_MODAL:
			return (
				<DripAgreementModal
					onAgree={modalProps.onAgree}
					onDecline={modalProps.onDecline}
				/>
			);
		case TRANSFER_PLAYLIST_MODAL:
			return <TransferPlaylistModal playlistId={modalProps.playlistId} />;
		case FILE_VERSION_LIST_MODAL:
			return (
				<FileVersionListModal
					fileId={modalProps.fileId}
					playlistId={modalProps.playlistId}
				/>
			);
		case UPLOAD_FILE_VERSION_MODAL:
			return (
				<UploadFileVersionModal
					fileId={modalProps.fileId}
					playlistId={modalProps.playlistId}
				/>
			);
		case EDIT_FILE_VERSION_MODAL:
			return (
				<EditFileVersionModal
					fileId={modalProps.fileId}
					versionId={modalProps.versionId}
				/>
			);
		case DUPLICATE_PLAYLIST_MODAL:
			return <DuplicatePlaylistModal playlistId={modalProps.playlistId} />;
		case PLAYLIST_COLLAB_MODAL:
			return <PlaylistCollabModal playlistId={modalProps.playlistId} />;
		case INVITE_PLAYLIST_EDITOR_MODAL:
			return <InvitePlaylistEditorModal onSubmit={modalProps.onSubmit} />;
		case CONFIRMATION_MODAL:
			return (
				<ConfirmationModal
					title={modalProps.title}
					description={modalProps.description}
					confirmAction={modalProps.confirmAction}
					cancelAction={modalProps.cancelAction}
				/>
			);
		case SET_PLAYLIST_PASSWORD_MODAL:
			return <SetPlaylistPasswordModal onSubmit={modalProps.onSubmit} />;
		case GROUP_TO_ALBUM_MODAL:
			return <GroupToAlbumModal recordingIds={modalProps.recordingIds} />;
		case UPGRADE_TIER_MODAL:
			return (
				<UpgradeTierModal
					isNewUserOnboardingFlow={modalProps.isNewUserOnboardingFlow}
				/>
			);
		case BENEFITS_CODE_MODAL:
			return <BenefitsCodeModal />;
		case CONFIRM_UPGRADE_MODAL:
			return <ConfirmUpgradeModal />;
		case CHECK_EMAIL_SIGNUP_MODAL:
			return (
				<CheckEmailSignUpModal
					onConfirm={modalProps.onConfirm}
					onDecline={modalProps.onDecline}
					foundProfile={modalProps.foundProfile}
				/>
			);
		case ARE_YOU_UNION_MEMBER_MODAL:
			return (
				<AreYouUnionMemberModal
					onPositiveClick={modalProps.onPositiveClick}
					onNegativeClick={modalProps.onNegativeClick}
				/>
			);
		case ACCOUNT_DETAILS_MODAL:
			return <AccountDetailsModal />;
		case ISNI_REGISTRATION_MODAL:
			return (
				<IsniRegistration
					recordingId={modalProps.recordingId}
					participantId={modalProps.participantId}
					onAssignIsni={modalProps.onAssignIsni}
					profileId={modalProps.profileId}
				/>
			);
		case PLAYLIST_CTA_MODAL_JULY_2023:
			return <PlaylistJuly2023PromoModal />;
		case EDIT_FILE_PROJECT_MODAL:
			return (
				<EditFileProjectModal
					playlistId={modalProps.playlistId}
					albumId={modalProps.albumId}
					recordingId={modalProps.recordingId}
				/>
			);
		case UPLOAD_VERSIONS_TO_PLAYLIST_MODAL:
			return (
				<UploadVersionsToPlaylistModal
					playlistId={modalProps.playlistId}
					initialFiles={modalProps.initialFiles}
				/>
			);
		case MANAGE_UPLOADING_VERSIONS_MODAL:
			return (
				<ManageUploadingVersionsModal
					playlistId={modalProps.playlistId}
					fileId={modalProps.fileId}
				/>
			);
		case PRODUCER_AGREEMENT_SHARE_MODAL:
			return <ProducerAgreementShareModal onSubmit={modalProps.onSubmit} />;
		case EXPORT_ESIGNATURE_MODAL:
			return <ExportESignatureModal exportId={modalProps.exportId} />;
		case CREDIT_REQUESTS_MODAL:
			return <CreditRequestsModal playlistId={modalProps.playlistId} />;
		case SEND_PLAYLIST_LINK_MODAL:
			return (
				<SendPlaylistLinkModal
					playlistId={modalProps.playlistId}
					isOnboarding={modalProps.isOnboarding}
				/>
			);
		case CREATE_PLAYLIST_FOLDER_MODAL:
			return (
				<CreatePlaylistFolderModal
					playlistId={modalProps.playlistId}
					fileIds={modalProps.fileIds}
					parentGroupId={modalProps.parentGroupId}
					folderIds={modalProps.folderIds}
				/>
			);
		case RENAME_PLAYLIST_FOLDER_MODAL:
			return (
				<RenamePlaylistFolderModal
					playlistId={modalProps.playlistId}
					folderId={modalProps.folderId}
					initialName={modalProps.initialName}
				/>
			);
		default:
			return <></>;
	}
};

export default ModalBody;
