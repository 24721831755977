import React, { useEffect, useMemo } from 'react';
import { renderAsync } from 'docx-preview';
import { Card, Col, Container, Row } from 'react-bootstrap';
import SoundCreditLoader from '../../SoundCreditLoader';
import './ExportPreview.scss';
import Button from '../../../layout/Button';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import {
	clearAllCurrentExportDataAction,
	confirmExportAction,
} from '../../../../store/exports/actions';
import exportTypes from '../../../../constants/exportType';
import ExportWarningModal from '../ExportSelectionModal/ExportWarningModal';
import writerRoles from '../../../../constants/writerRoles.json';
import nonMusicianRoleCategories from '../../../../constants/nonMusicianRoleCategories.json';
import ROUTES from '../../../../router/routes';
import { replacePathVariables } from '../../../../helpers/routeTools';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import ExportType from '../../../../constants/exportType';
import { AppDispatch } from '../../../../store';

const ExportPreview = () => {
	const { currentRecordingId } = useAppSelector(state => state.projects);
	const [isLoading, setIsLoading] = React.useState(true);
	const [showWarningModal, setShowWarningModal] = React.useState(false);
	const { previewExportData, exportPayload, exportType } = useAppSelector(
		state => state.exports
	);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const previewRefContainer = React.useRef(null);

	useEffect(() => {
		if (!previewExportData || !previewRefContainer.current) return;

		const reader = new FileReader();
		reader.onload = () => {
			renderAsync(previewExportData, previewRefContainer.current!).then(() =>
				setIsLoading(false)
			);
		};

		reader.readAsArrayBuffer(previewExportData);
	}, [previewExportData]);

	const warningModalProps = useMemo(() => {
		return getExportWarningModalProps({
			exportType: exportType!,
			exportPayload,
			setShowWarningModal,
			dispatch,
			navigate,
			currentRecordingId: currentRecordingId!,
		});
	}, [
		exportType,
		exportPayload,
		dispatch,
		navigate,
		setShowWarningModal,
		currentRecordingId,
	]);

	const handleCancel = () => {
		dispatch(clearAllCurrentExportDataAction());
		navigate(ROUTES.Editor.path, { replace: true });
	};

	const handleContinue = () => {
		if (
			getExportWarningModalProps({
				exportType: exportType!,
				exportPayload,
				setShowWarningModal,
				dispatch,
				navigate,
				currentRecordingId: currentRecordingId!,
			})
		) {
			setShowWarningModal(true);
		} else {
			dispatch(confirmExportAction());
			navigate(
				replacePathVariables(ROUTES.ExportValidation.path, {
					recordingId: currentRecordingId,
				}),
				{ replace: true }
			);
		}
	};

	return (
		<>
			<Container fluid className='container-horizontal-padding py-5'>
				<Card>
					<Card.Body>
						<h2>PREVIEW</h2>
						{isLoading && (
							<div
								style={{
									width: '100%',
									height: '50vh',
								}}
							>
								<SoundCreditLoader message='Generating Preview...' />
							</div>
						)}
						<div
							ref={previewRefContainer}
							id='export-file-preview'
							style={{ display: isLoading ? 'none' : 'block' }}
						/>
					</Card.Body>
				</Card>
				<Row>
					<Col className='d-flex justify-content-end align-items-center'>
						<Button label='Cancel' onClick={handleCancel} className='mr-2' />
						<Button label='Continue' theme='dark' onClick={handleContinue} />
					</Col>
				</Row>
			</Container>
			{warningModalProps && (
				<ExportWarningModal
					show={showWarningModal}
					onConfirm={warningModalProps.onConfirm}
					onCancel={warningModalProps.onCancel}
					titleText={warningModalProps.titleText}
					bodyText={warningModalProps.bodyText}
					cancelText={warningModalProps.cancelText}
					confirmText={warningModalProps.confirmText}
				/>
			)}
		</>
	);
};

export default ExportPreview;

const getExportWarningModalProps = ({
	exportType,
	exportPayload,
	setShowWarningModal,
	dispatch,
	navigate,
	currentRecordingId,
}: {
	exportType: ExportType;
	exportPayload: any;
	setShowWarningModal: React.Dispatch<React.SetStateAction<boolean>>;
	dispatch: AppDispatch;
	navigate: NavigateFunction;
	currentRecordingId: Recording['id'];
}) => {
	switch (exportType) {
		case exportTypes.SPLIT_SHEET_STANDARD:
		case exportTypes.SPLIT_SHEET_SINGLE_WRITER:
			return {
				titleText: 'NOTICE',
				bodyText: (
					<div>
						This document will be sent to the following participants:
						<ul>
							{(exportPayload.recordings[0] as RecordingContent).participants
								.filter(participant =>
									participant.roles.some(role =>
										writerRoles.includes(role.detail)
									)
								)
								.map((participant, index) => (
									<li key={index}>{participant.creditedName}</li>
								))}
						</ul>
						Do you wish to continue?
					</div>
				),
				cancelText: 'Cancel',
				confirmText: 'Continue',
				onCancel: () => setShowWarningModal(false),
				onConfirm: () => {
					setShowWarningModal(false);
					dispatch(confirmExportAction());
					navigate(
						replacePathVariables(ROUTES.ExportValidation.path, {
							recordingId: currentRecordingId,
						})
					);
				},
			};
		case exportTypes.PRODUCER_AGREEMENT:
			return {
				titleText: 'NOTICE',
				bodyText: (
					<div>
						This document will be sent to the following participants:
						<ul>
							{(exportPayload.main_artists as ApiExportPayloadMainArtist[]).map(
								(participant, index) => (
									<li key={index}>{participant.name}</li>
								)
							)}
						</ul>
						Do you wish to continue?
					</div>
				),
				cancelText: 'Cancel',
				confirmText: 'Continue',
				onCancel: () => setShowWarningModal(false),
				onConfirm: () => {
					setShowWarningModal(false);
					dispatch(confirmExportAction());
					navigate(
						replacePathVariables(ROUTES.ExportValidation.path, {
							recordingId: currentRecordingId,
						})
					);
				},
			};
		case exportTypes.SIDE_ARTIST_AGREEMENT:
			return {
				titleText: 'NOTICE',
				bodyText: (
					<div>
						This document will be sent to the following participants:
						<ul>
							{/* TODO: Create dedicated type for ExportPayloadRecording.
							 It's almost identical to RecordingContent, but with the albums array
							(see ExportPayloadSetters in setExportPayloadTools.ts) */}
							{(exportPayload.recordings[0] as RecordingContent).participants
								.filter(
									participant =>
										!participant.roles.some(role =>
											nonMusicianRoleCategories.includes(role.category)
										)
								)
								.map((participant, index) => (
									<li key={index}>{participant.creditedName}</li>
								))}
						</ul>
						Do you wish to continue?
					</div>
				),
				cancelText: 'Cancel',
				confirmText: 'Continue',
				onCancel: () => setShowWarningModal(false),
				onConfirm: () => {
					setShowWarningModal(false);
					dispatch(confirmExportAction());
					navigate(
						replacePathVariables(ROUTES.ExportValidation.path, {
							recordingId: currentRecordingId,
						})
					);
				},
			};
		default:
			return null;
	}
};
