import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { handleLockedClick } from '../../../../helpers/tiersTools';

export type OverflowMenuButtonProps = {
	onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
	leftIcon?: string;
	rightIcon?: string;
	className?: string;
	label: string | React.ReactNode;
	style?: React.CSSProperties;
	isLocked?: boolean;
	onLockedClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
};

const OverflowMenuButton = ({
	onClick,
	leftIcon = '',
	rightIcon = '',
	className = '',
	label,
	style,
	isLocked = false,
	onLockedClick,
}: OverflowMenuButtonProps) => {
	const dispatch = useDispatch();
	const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		document.body.click(); // close the overflow menu

		handleLockedClick({
			e,
			isLocked,
			onLockedClick,
			onClick,
			dispatch,
		});
	};

	return (
		<ListGroup.Item
			onClick={handleClick}
			action
			style={style}
			className={className}
		>
			{leftIcon && (
				<i
					className={leftIcon + ' mr-2'}
					style={{ width: '1rem', textAlign: 'center' }}
				/>
			)}
			{label}
			{rightIcon && (
				<i
					className={rightIcon + ' ml-2'}
					style={{ width: '1rem', textAlign: 'center' }}
				/>
			)}
		</ListGroup.Item>
	);
};

export default OverflowMenuButton;
