import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { hideModal, setModalTitle } from '../../../store/modal/actions';
import Button from '../../layout/Button';
import './CorruptedFilesModal.scss';
import { isFirefox } from 'react-device-detect';

const CorruptedFilesModal = ({ corruptedFiles }) => {
	const dispatch = useDispatch();
	const [emptyFolder, setEmptyFolder] = useState(undefined);

	const corruptedFilenames = useMemo(() => {
		return corruptedFiles.map(f => f.name);
	}, []);
	console.log('corruptedFiles:', corruptedFiles);

	useEffect(() => {
		dispatch(setModalTitle('CORRUPTED FILES'));
		if (isFirefox) {
			corruptedFiles.forEach(file => {
				if (file.size === 0 && file.type === '') {
					setEmptyFolder(file);
				}
			});
		}
	}, [dispatch]);

	return (
		<>
			<Modal.Body>
				<div className='px-4'>
					<p>
						<strong>
							{emptyFolder
								? 'The following files cannot be uploaded:'
								: 'The following files are corrupted and cannot be uploaded:'}
						</strong>
					</p>
					<ul>
						{corruptedFilenames.map((filename, index) => (
							<li key={index} className='corrupted-files-modal__file-item'>
								{filename}
							</li>
						))}
					</ul>

					<p>
						{emptyFolder ? (
							<>
								Make sure to use Drag & Drop to upload the file
								{corruptedFiles.length >= 1 ? 's' : ''}.
								<br />
								<br />
								<strong>TIP:</strong> Use Drag & Drop to upload folders and DAW
								project files in Firefox.
							</>
						) : (
							<>
								Make sure that the files are not corrupted and try again.
								<br />
								<br />
								<strong>TIP:</strong> Check that the file size is not 0 bytes
								and that the file extension matches the actual file type.
							</>
						)}
					</p>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button label='Dismiss' onClick={() => dispatch(hideModal())} />
			</Modal.Footer>
		</>
	);
};

export default CorruptedFilesModal;
