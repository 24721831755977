import { createSelector } from 'reselect';
import { RootState } from '..';
import { VALID_TOKEN } from '../actionTypes';

const isSigningUp = (state: RootState) => state.auth.isSigningUp;
const tokenStatus = (state: RootState) => state.auth.tokenStatus;
const userToken = (state: RootState) => state.auth.userToken;
const isEmailVerified = (state: RootState) => state.auth.isEmailVerified;

export const isAuthenticatedSelector = createSelector(
	[tokenStatus, userToken, isSigningUp, isEmailVerified],
	(tokenStatus, userToken, isSigningUp, isEmailVerified) => {
		return (
			!isSigningUp &&
			tokenStatus === VALID_TOKEN &&
			!!userToken &&
			// isEmailVerified can be null, only keeping this
			// for legacy reasons (to avoid signing out users who don't get this property
			// from the check token endpoint)
			isEmailVerified !== false
		);
	}
);
