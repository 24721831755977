import { AnyAction } from 'redux';
import {
	CLEAR_PARTICIPANTS,
	SET_PARTICIPANTS_ROLE_FILTERS,
	SET_PARTICIPANTS_SEARCH_FILTER,
} from '../actionTypes';

type ParticipantsState = {
	roleFilters: RoleFilter[];
	searchFilter: string;
};

const initialState: ParticipantsState = {
	roleFilters: [],
	searchFilter: '',
};

const reducer = (
	state = initialState,
	action: AnyAction
): ParticipantsState => {
	switch (action.type) {
		case SET_PARTICIPANTS_ROLE_FILTERS:
			return {
				...state,
				roleFilters: action.roleFilters,
			};
		case SET_PARTICIPANTS_SEARCH_FILTER:
			return {
				...state,
				searchFilter: action.searchFilter,
			};
		case CLEAR_PARTICIPANTS:
			return {
				...initialState,
			};
		default:
			return state;
	}
};

export default reducer;
