import { useFormikContext, FieldArray, ArrayHelpers, getIn } from 'formik';
import { useRef, useEffect, useState, useMemo } from 'react';
import { Row, Col, Accordion } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import theme from '../../../../../theme.module.scss';
import Select from '../../../../form/Select';
import TextField from '../../../../form/TextField';
import IconButton from '../../../../layout/IconButton';
import PillButton from '../../../../layout/PillButton';
import proList from '../../../../../constants/pros.json';
import { getCurrentRecording } from '../../../../../store/projects/selectors';
import territories from '../../../../../constants/territories';
import { matchSorter } from 'match-sorter';
import { uniqBy } from 'lodash';
import { containsWriterRoles } from '../../../../../helpers/participantTools';
import React from 'react';
import PublisherSelect from './PublisherSelect';

type WriterDetailsProps<
	ProfileType extends Participant | LocalProfile,
	FormType extends ParticipantForm | ProfileForm
> = {
	participant?: ProfileType | null;
	importProfile?: LocalProfile;
	publishers: PublisherSelectOption[];
	setPublishers: React.Dispatch<React.SetStateAction<PublisherSelectOption[]>>;
	createPublisher: () => FormType['publishers'][number];
	createSubpublisher: () => FormType['publishers'][number]['subPublishers'][number];
};

const isParticipantForm = (
	form: ParticipantForm | ProfileForm
): form is ParticipantForm =>
	form?.publishers?.[0] && 'splitPercentage' in form?.publishers?.[0];

type TerritoryOption = {
	value: string;
	label: string;
};

const WriterDetails = <
	ProfileType extends Participant | LocalProfile,
	FormType extends ParticipantForm | ProfileForm
>({
	participant,
	importProfile,
	publishers,
	createPublisher,
	createSubpublisher,
	setPublishers,
}: WriterDetailsProps<ProfileType, FormType>) => {
	const currentRecording = useSelector(getCurrentRecording);
	// ** for disabling/enabling the writer details section
	const formik = useFormikContext<FormType>();
	const accordionHeaderRef = useRef<HTMLHeadingElement>(null);
	const [filteredProOptions, setFilteredProOptions] = useState(proOptions);
	const territoriesOptions: TerritoryOption[] = useMemo(
		() =>
			territories.map(({ TIS_A, Name }) => ({
				value: TIS_A,
				label: Name,
			})),
		[]
	);
	const [filteredTerritories, setFilteredTerritories] =
		useState(territoriesOptions);

	const isDisabled = useMemo(
		() => !containsWriterRoles(formik.values.roles),
		[formik.values.roles]
	);

	const _isParticipantForm = useMemo(
		() => isParticipantForm(formik.values),
		[formik.values]
	);

	useEffect(() => {
		// console.log(formik.values,currentRecording)
		let associatedPublishers: PublisherSelectOption[] = [];

		if (currentRecording?.recording?.publishers) {
			associatedPublishers = [
				...(currentRecording?.recording?.publishers ?? []),
				...(importProfile?.profile?.associatedPublishers ?? []),
			];
		}

		setPublishers(prevPublishers =>
			uniqBy([...prevPublishers, ...associatedPublishers], 'id')
		);
	}, [currentRecording, setPublishers, importProfile]);

	useEffect(() => {
		if (accordionHeaderRef.current) {
			accordionHeaderRef.current.getElementsByTagName('button')[0].disabled =
				isDisabled;
		}
	}, [accordionHeaderRef, isDisabled]);

	const handleSplitPercentageBlur = (e: any, index: number) => {
		if (Number(e.target.value) > 100) e.target.value = 100;
		if (Number(e.target.value) < 0) e.target.value = 0;

		formik.setFieldValue(
			`publishers[${index}].splitPercentage`,
			Number(Number(e.target.value).toFixed(2))
		);
	};

	const handleSplitPercentageSubPubBlur = (
		e: any,
		index: number,
		subIndex: number
	) => {
		if (Number(e.target.value) > 100) e.target.value = 100;
		if (Number(e.target.value) < 0) e.target.value = 0;

		formik.setFieldValue(
			`publishers[${index}].subPublishers[${subIndex}].splitPercentage`,
			Number(Number(e.target.value).toFixed(2))
		);
	};

	const renderPublishersFields = (remove: ArrayHelpers['remove']) =>
		formik.values.publishers &&
		formik.values.publishers.map((_, index) => (
			<Row className='row rounded p-3 bg-purple-light mb-4' key={index}>
				<Col xs={12} className='d-flex align-items-center justify-content-end'>
					<IconButton icon='fa fa-times' onClick={() => remove(index)} />
				</Col>
				<Col xs={12}>
					<Row>
						<Col xs={12}>
							<PublisherSelect<FormType>
								index={index}
								formik={formik}
								isSubPublisher={false}
								publishers={publishers}
								setPublishers={setPublishers}
							/>
						</Col>
						<Col xs={_isParticipantForm ? 3 : 4}>
							<Select<ProOption>
								label="Publisher's P.R.O."
								name={`publishers[${index}].pro`}
								id={`publishers[${index}].pro`}
								options={filteredProOptions}
								value={
									formik?.values?.publishers?.[index]?.pro &&
									proOptions.find(
										pro => pro.value === formik.values.publishers[index].pro
									)
								}
								placeholder='Search...'
								onChange={option => {
									formik.setFieldValue(
										`publishers[${index}].pro`,
										option?.value
									);
								}}
								isDisabled={isDisabled}
								onInputChange={val =>
									setFilteredProOptions(
										matchSorter(proOptions, val, { keys: ['value', 'label'] })
									)
								}
							/>
						</Col>

						<Col xs={_isParticipantForm ? 3 : 4}>
							<TextField
								label='IPI (Optional)'
								type='text'
								placeholder='9 to 11 digits'
								name={`publishers[${index}].ipi`}
								id={`publishers[${index}].ipi`}
								value={formik?.values?.publishers?.[index]?.ipi}
								onChange={event => {
									formik.setFieldValue(
										`publishers[${index}].ipi`,
										event.target.value
									);
								}}
								isInvalid={Boolean(
									getIn(formik?.errors, `publishers[${index}].ipi`) &&
										getIn(formik?.touched, `publishers[${index}].ipi`)
								)}
								errorMessage={getIn(formik?.errors, `publishers[${index}].ipi`)}
								isDisabled={isDisabled}
							/>
						</Col>

						<Col xs={isParticipantForm(formik?.values) ? 3 : 4}>
							<Select<TerritoryOption[]>
								isMulti
								label='Territory'
								name={`publishers[${index}].territory`}
								id={`publishers[${index}].territory`}
								value={formik?.values?.publishers?.[index]?.territory?.map(
									value =>
										territoriesOptions.find(country => country.value === value)
								)}
								onChange={option => {
									formik.setFieldValue(
										`publishers[${index}].territory`,
										option?.map(({ value }) => value)
									);
								}}
								options={filteredTerritories}
								isDisabled={isDisabled}
								onInputChange={val =>
									setFilteredTerritories(
										matchSorter(territoriesOptions, val, {
											keys: ['label', 'value'],
										})
									)
								}
							/>
						</Col>
						{_isParticipantForm && (
							<Col xs={3}>
								<TextField
									label='Split Percentage'
									type='number'
									min='0'
									max='100'
									name={`publishers[${index}].splitPercentage`}
									id={`publishers[${index}].splitPercentage`}
									placeholder='Split Percentage %'
									onChange={formik.handleChange}
									// onBlur={formik.handleBlur}
									onBlur={e => handleSplitPercentageBlur(e, index)}
									value={
										(
											formik?.values?.publishers?.[
												index
											] as ParticipantPublisher
										)?.splitPercentage ?? ''
									}
								/>
							</Col>
						)}
						<FieldArray
							name={`publishers[${index}].subPublishers`}
							render={({ push: subPush, remove: subRemove }) => (
								<>
									<Col xs={12}>
										{formik?.values?.publishers?.[index]?.subPublishers
											?.length > 0 ? (
											<div>
												<div className='d-flex align-items-center mb-2'>
													<h4>Sub-publishers / Administrators</h4>
													<button
														className='ml-2 btn text-purple border-purple rounded-circle'
														style={{ padding: '1px 6px' }}
														type='button'
														onClick={() => subPush(createSubpublisher())}
													>
														<i className='fa fa-plus'></i>
													</button>
												</div>

												{formik.values.publishers[index].subPublishers &&
													formik.values.publishers[index].subPublishers.map(
														(_, subIndex) =>
															renderSubPublisherFields(
																index,
																subIndex,
																subRemove
															)
													)}
											</div>
										) : (
											<></>
										)}
									</Col>

									{formik.values.publishers &&
										formik.values.publishers[index] &&
										(!formik.values.publishers[index].subPublishers ||
											formik.values.publishers[index].subPublishers.length ===
												0) && (
											<Col xs={12} className='d-flex justify-content-center'>
												<PillButton
													onClick={() => subPush(createSubpublisher())}
													label='Add sub-publisher/administrator'
													leftIcon='fa fa-plus'
												/>
											</Col>
										)}
								</>
							)}
						/>
					</Row>
				</Col>
			</Row>
		));

	const renderSubPublisherFields = (
		index: number,
		subIndex: number,
		subRemove: ArrayHelpers['remove']
	) => (
		<Row className='ml-3' key={subIndex}>
			<Col xs={12} className='pr-4'>
				{subIndex > 0 && <hr />}
			</Col>
			<Col xs={11}>
				<Row>
					<Col xs={7}>
						<PublisherSelect
							index={index}
							subIndex={subIndex}
							formik={formik}
							isSubPublisher={true}
							publishers={publishers}
							setPublishers={setPublishers}
						/>
					</Col>

					<Col xs={5}>
						<TextField
							label='Publisher email (optional)'
							type='text'
							name={`publishers[${index}].subPublishers[${subIndex}].email`}
							id={`publishers[${index}].subPublishers[${subIndex}].email`}
							isDisabled={isDisabled}
							value={
								formik?.values?.publishers?.[index]?.subPublishers?.[subIndex]
									?.email
							}
							// value={isSubPublisher ?
							// 	formik.values.publishers[index].subPublishers[subIndex].email :
							// 	formik.values.publishers[index].email
							// }
							onBlur={formik.handleBlur}
							placeholder='example@hotmail.com'
							onChange={ev => {
								formik.setFieldValue(
									`publishers[${index}].subPublishers[${subIndex}].email`,
									ev.target.value
								);
							}}
							isInvalid={Boolean(
								getIn(
									formik.touched,
									`publishers[${index}].subPublishers[${subIndex}].email`
								) &&
									getIn(
										formik.errors,
										`publishers[${index}].subPublishers[${subIndex}].email`
									)
							)}
							errorMessage={getIn(
								formik.errors,
								`publishers[${index}].subPublishers[${subIndex}].email`
							)}
						/>
					</Col>

					<Col xs={_isParticipantForm ? 3 : 4}>
						<Select<ProOption>
							label="Publisher's P.R.O."
							name={`publishers[${index}].subPublishers[${subIndex}].pro`}
							id={`publishers[${index}].subPublishers[${subIndex}].pro`}
							options={filteredProOptions}
							value={filteredProOptions.find(
								pro =>
									pro.value ===
									formik.values.publishers[index].subPublishers[subIndex].pro
							)}
							placeholder='Search...'
							onChange={option => {
								formik.setFieldValue(
									`publishers[${index}].subPublishers[${subIndex}].pro`,
									option?.value
								);
							}}
							isDisabled={isDisabled}
							onInputChange={val =>
								setFilteredProOptions(
									matchSorter(proOptions, val, { keys: ['value'] })
								)
							}
						/>
					</Col>

					<Col xs={_isParticipantForm ? 3 : 4}>
						<TextField
							label='IPI (Optional)'
							placeholder='9 to 11 digits'
							name={`publishers[${index}].subPublishers[${subIndex}].ipi`}
							id={`publishers[${index}].subPublishers[${subIndex}].ipi`}
							value={
								formik?.values?.publishers?.[index]?.subPublishers?.[subIndex]
									?.ipi
							}
							onChange={event => {
								formik.setFieldValue(
									`publishers[${index}].subPublishers[${subIndex}].ipi`,
									event.target.value
								);
							}}
							isInvalid={Boolean(
								getIn(
									formik.touched,
									`publishers[${index}].subPublishers[${subIndex}].ipi`
								) &&
									getIn(
										formik.errors,
										`publishers[${index}].subPublishers[${subIndex}].ipi`
									)
							)}
							errorMessage={getIn(
								formik.errors,
								`publishers[${index}].subPublishers[${subIndex}].ipi`
							)}
							isDisabled={isDisabled}
						/>
					</Col>

					<Col xs={_isParticipantForm ? 3 : 4}>
						<Select<TerritoryOption[]>
							isMulti
							label='Territory'
							name={`publishers[${index}].subPublishers[${subIndex}].territory`}
							id={`publishers[${index}].subPublishers[${subIndex}].territory`}
							options={filteredTerritories}
							value={formik?.values?.publishers?.[index]?.subPublishers?.[
								subIndex
							]?.territory?.map(value =>
								territoriesOptions.find(country => country.value === value)
							)}
							onChange={option => {
								formik.setFieldValue(
									`publishers[${index}].subPublishers[${subIndex}].territory`,
									option?.map(({ value }) => value)
								);
							}}
							isDisabled={isDisabled}
							onInputChange={val =>
								setFilteredTerritories(
									matchSorter(territoriesOptions, val, {
										keys: ['label', 'value'],
									})
								)
							}
						/>
					</Col>

					{isParticipantForm(formik.values) && (
						<Col xs={3}>
							<TextField
								label='Split Percentage'
								type='number'
								min='0'
								max='100'
								name={`publishers[${index}].subPublishers[${subIndex}].splitPercentage`}
								id={`publishers[${index}].subPublishers[${subIndex}].splitPercentage`}
								placeholder='Split Percentage %'
								onChange={formik.handleChange}
								onBlur={e =>
									handleSplitPercentageSubPubBlur(e, index, subIndex)
								}
								value={
									(
										formik?.values?.publishers?.[index]?.subPublishers?.[
											subIndex
										] as ParticipantFormSubpublisher
									)?.splitPercentage ?? ''
								}
							/>
						</Col>
					)}
				</Row>
			</Col>

			<Col xs={1} className='d-flex align-items-center justify-content-center'>
				<IconButton
					icon='fa fa-trash'
					color={theme.error}
					onClick={() => subRemove(subIndex)}
				/>
			</Col>
		</Row>
	);

	const disableAccordionClickPropagation = (roles: FormType['roles']) =>
		!containsWriterRoles(roles)
			? (e: React.MouseEvent<HTMLHeadingElement, MouseEvent>) =>
					e.stopPropagation()
			: undefined;

	const accordionKey = '0';

	return (
		<>
			<Accordion defaultActiveKey={participant?.id ? accordionKey : ''}>
				<Accordion.Item eventKey={isDisabled ? (null as any) : accordionKey}>
					<Accordion.Header ref={accordionHeaderRef}>
						<h2 onClick={disableAccordionClickPropagation(formik.values.roles)}>
							WRITER DETAILS
						</h2>

						<small
							className='ml-2'
							style={{ color: theme.disclaimerTextColor }}
						>
							<i
								onClick={disableAccordionClickPropagation(formik.values.roles)}
							>
								(only for credits with <strong>Songwriter</strong>,{' '}
								<strong>Composer</strong> or <strong>Lyricist</strong> roles)
							</i>
						</small>
					</Accordion.Header>
					<Accordion.Body>
						<Row>
							<Col xs={6}>
								<Select<ProOption>
									label='Performance Rights Organization (PRO)'
									name={`pro`}
									id={`pro`}
									options={filteredProOptions}
									value={filteredProOptions.find(
										pro => pro.value === formik.values.pro
									)}
									placeholder='Search...'
									onChange={option => {
										formik.setFieldValue(`pro`, option?.value);
									}}
									isDisabled={isDisabled}
									onInputChange={val =>
										setFilteredProOptions(
											matchSorter(proOptions, val, { keys: ['value', 'label'] })
										)
									}
								/>
							</Col>

							<Col xs={6}>
								<TextField
									label="Writer's IPI/CAE"
									type='text'
									name='ipiCae'
									placeholder='9 - 11 digits long'
									isDisabled={isDisabled}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.ipiCae}
									isInvalid={
										formik?.touched?.ipiCae && !!formik?.errors?.ipiCae
									}
									errorMessage={formik?.errors?.ipiCae as string}
								/>
							</Col>

							<Col xs={12} className='mb-3'>
								<FieldArray
									name='publishers'
									render={({ remove, push }) => (
										<div>
											<Row>
												<Col xs={12}>
													<div className='form-label d-flex align-items-center mb-2'>
														Publishers
														<button
															disabled={isDisabled}
															className='ml-2 btn text-purple border-purple rounded-circle'
															style={{ padding: '1px 6px' }}
															type='button'
															onClick={() => push(createPublisher())}
														>
															<i className='fa fa-plus'></i>
														</button>
													</div>
												</Col>
											</Row>

											{renderPublishersFields(remove)}
										</div>
									)}
								/>
							</Col>
						</Row>
					</Accordion.Body>
				</Accordion.Item>
			</Accordion>
		</>
	);
};

export default WriterDetails;

export type ProOption = {
	label: string;
	value: string;
};

const proOptions: ProOption[] = proList.pros.map(pro => ({
	label: pro,
	value: pro,
}));
