import {
	CLEAR_PARTICIPANTS,
	SET_PARTICIPANTS_ROLE_FILTERS,
	SET_PARTICIPANTS_SEARCH_FILTER,
} from '../actionTypes';

export const setParticipantsRoleFilters = (roleFilters: RoleFilter[]) => ({
	type: SET_PARTICIPANTS_ROLE_FILTERS,
	roleFilters,
});

export const setParticipantsSearchFilter = (searchFilter: string) => ({
	type: SET_PARTICIPANTS_SEARCH_FILTER,
	searchFilter,
});

export const clearParticipantsAction = () => ({
	type: CLEAR_PARTICIPANTS,
});
