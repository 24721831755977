import PlaylistAccessControlType from '../constants/playlistAccessControl';
import camelize from './camelize';
import { Buffer } from 'buffer';
import { filterItemsBySearchTerm } from './searchTools';
import translateApiFileToLocal from './translateApiFileToLocal';
import { translateApiObjectToLocal } from './apiTools';
import { AppDispatch } from '../store';
import { hideModal, showModalAction } from '../store/modal/actions';
import {
	CONFIRMATION_MODAL,
	SHARE_PLAYLIST_MODAL,
} from '../constants/modalTypes';
import { setPlaylistAccessControlAction } from '../store/playlists/actions';
import { reportPlaylistJuly2023PromoPassAction } from '../store/promos/actions';
import { matchSorter } from 'match-sorter';

export const playlistArrayToObject = (
	playlists: Playlist[]
): Record<number, Playlist> => {
	return playlists.reduce((acc: any, playlist: any) => {
		acc[playlist.id] = playlist;

		return acc;
	}, {});
};

export const computeApiPlaylistAccessControl = (
	access: PlaylistAccessControlType,
	password: string | null = null
) => {
	const accessControl = {
		isPublished: false,
		isPasswordProtected: false,
		password: null as string | null,
	};

	switch (access) {
		case PlaylistAccessControlType.PUBLIC:
			accessControl.isPublished = true;
			break;
		case PlaylistAccessControlType.PASSWORD:
			accessControl.isPublished = true;
			accessControl.isPasswordProtected = true;
			accessControl.password = Buffer.from(password ?? '').toString('base64');
			break;
		case PlaylistAccessControlType.PRIVATE:
			break;
		default:
			break;
	}

	return accessControl;
};

export const computeLocalPlaylistAccessControl = (apiPlaylist: any) => {
	if (apiPlaylist?.isPublished) {
		if (apiPlaylist?.isPasswordProtected) {
			return PlaylistAccessControlType.PASSWORD;
		}

		return PlaylistAccessControlType.PUBLIC;
	}

	return PlaylistAccessControlType.PRIVATE;
};

export const mergePlaylists = (
	existingPlaylists: PlaylistState['playlistsById'],
	newPlaylists: Record<number, Playlist>,
	replaceAll = false
) => {
	// debugger;
	const mergedPlaylists = { ...(existingPlaylists ?? {}) };

	Object.values(newPlaylists).forEach(newPlaylist => {
		// TODO: Add updatedAt check for better cache management
		// Only overwrite existing playlists if the new playlist is not null
		// (i.e. if the new playlist is a full playlist has just been fetched)
		// or if the existing playlist doesn't exist

		// If replaceAll is true, overwrite all stale playlists and delete any playlists that are not in the new playlists
		if (newPlaylist?.playlist || !mergedPlaylists[newPlaylist.id]) {
			mergedPlaylists[newPlaylist.id] = {
				uploads: existingPlaylists?.[newPlaylist.id]?.uploads ?? [], // Keep existing uploads if they exist, they are not included in the API response
				...newPlaylist,
			};
		}
	});

	if (replaceAll) {
		Object.keys(mergedPlaylists).forEach(playlistId => {
			const id = parseInt(playlistId);

			if (!newPlaylists[id]) {
				delete mergedPlaylists[id];
			}
		});
	}

	return mergedPlaylists;
};

export const mergePlaylistStats = (
	existingPlaylistStats: any,
	newPlaylistStats: any,
	replaceAll = false
) => {
	const mergedPlaylists = { ...(existingPlaylistStats ?? {}) };

	Object.values(newPlaylistStats).forEach((newPlaylists: any) => {
		// TODO: Add updatedAt check for better cache management
		// Only overwrite existing playlists if the new playlist is not null
		// (i.e. if the new playlist is a full playlist has just been fetched)
		// or if the existing playlist doesn't exist

		// If replaceAll is true, overwrite all stale playlists and delete any playlists that are not in the new playlists
		if (newPlaylists?.data || !mergedPlaylists[newPlaylists.id]) {
			mergedPlaylists[newPlaylists.id] = newPlaylists;
		}
	});

	if (replaceAll) {
		Object.keys(mergedPlaylists).forEach(playlistId => {
			if (!newPlaylistStats[playlistId]) {
				delete mergedPlaylists[playlistId];
			}
		});
	}

	console.log(mergedPlaylists);

	return mergedPlaylists;
};

export const translateApiPlaylistToLocal = (apiPlaylist: any): Playlist => ({
	id: apiPlaylist.id,
	name: apiPlaylist.name,
	updatedAt: apiPlaylist.updated_at,
	slug: apiPlaylist.slug,
	playlist: {
		// keep full playlist in a nested object for checking if it's been fetched (if it's null, it hasn't been fetched)
		...camelize(apiPlaylist),
		files: apiPlaylist.files.map((file: any) =>
			translateApiFileToLocal(file)
		) as FileMetadata[],
	},
});

export const translateToPlaylistRequestFile = (
	file: Partial<FileMetadata>
) => ({
	fileId: file.id,
	displayName: file.displayName ?? '', // used for note field
	coverImageKey: file?.coverImageKey ?? null,
});

export const getPlaylistShareLink = (playlist: Playlist) =>
	`${process.env.REACT_APP_PLAYLISTS_URL}${playlist?.playlist?.slug}`;

export const getDripShareLink = (playlist: Playlist) =>
	`${process.env.REACT_APP_DRIP_URL}${playlist?.playlist?.slug}`;

export const getTrackChatShareLink = (playlist: Playlist) =>
	`${process.env.REACT_APP_TRACKCHAT_URL}${playlist?.playlist?.slug}`;

export const getTrackChatFileVersionLink = ({
	assetFileId,
	slug,
	versionId,
}: {
	assetFileId: FileMetadata['id'];
	slug: Playlist['slug'];
	versionId: FileVersion['id'];
}) =>
	`${process.env.REACT_APP_TRACKCHAT_URL}${slug}/file/${assetFileId}/version/${versionId}`;

export const getSingleTrackShareLink = (file: PlaylistFileMetadata) =>
	`${process.env.REACT_APP_PLAYLISTS_URL}track/${file.singleSlug}`;

export const getSingleFolderShareLink = (folder: PlaylistFolder) =>
	`${process.env.REACT_APP_PLAYLISTS_URL}group/${folder.groupSlug}`;

export const getSingleFolderTrackChatShareLink = (folder: PlaylistFolder) =>
	`${process.env.REACT_APP_TRACKCHAT_URL}group/${folder.groupSlug}`;

export const getSingleTrackChatShareLink = (file: PlaylistFileMetadata) =>
	`${process.env.REACT_APP_TRACKCHAT_URL}track/${file.singleSlug}`;

export const filterPlaylistFilesBySearchTerm = <
	PlaylistFileType extends PlaylistTableFile
>(
	files: PlaylistFileType[],
	searchTerm: string,
	fileLabelDetails: Record<number, string>
): PlaylistFileType[] => {
	return matchSorter(
		files.map(file => ({
			...file,
			labelDetail: fileLabelDetails[file.label],
			title: file.recording ? file.recording.title : file!.album!.title,
			artist: file.recording ? file.recording.artist : file!.album!.artist,
		})),
		searchTerm,
		{ keys: ['displayName', 'labelDetail', 'title', 'artist'] }
	);
};

export const filterPlaylistsBySearchTerm = (
	playlists: Playlist[],
	searchTerm: string
) => {
	return filterItemsBySearchTerm(searchTerm, playlists, ['name']);
};

export const translateApiPlaylistEditorsToLocal = (
	apiPlaylistEditors: AddPlaylistEditorResponse['editors']
): PlaylistEditor[] => apiPlaylistEditors.map(translateApiObjectToLocal);

export const openShareSettingsModal = (
	playlistId: Playlist['id'],
	dispatch: AppDispatch
) =>
	dispatch(
		showModalAction(SHARE_PLAYLIST_MODAL, {
			size: 'md',
			playlistId,
		})
	);

export const showOnboardingPrivatePlaylistModal = ({
	dispatch,
	playlist,
}: {
	dispatch: AppDispatch;
	playlist: Playlist;
}) =>
	dispatch(
		showModalAction(CONFIRMATION_MODAL, {
			size: 'md',
			title: 'ONE LAST THING!',
			description: (
				<div>
					<div>
						All new playlists are private by default. In order to view it in a
						browser, you first need to make it public.
						<br />
						<br />
						We'll handle it for you this time, but you can change this in the
						future in Share &gt; Share Settings.
					</div>
				</div>
			),
			confirmAction: {
				label: 'Make Public and Open in Browser',
				flashy: true,
				onClick: async () => {
					await dispatch(
						setPlaylistAccessControlAction({
							playlistId: playlist.id,
							accessControlType: PlaylistAccessControlType.PUBLIC,
							password: null,
							allowDownloads: playlist.playlist!.allowDownloads,
							hideTrackchatLinks: playlist.playlist!.hideTrackchatLinks,
							expandVersions: playlist.playlist!.expandVersions,
							enableCreditRequests: playlist.playlist!.enableCreditRequests,
						})
					);
					dispatch(reportPlaylistJuly2023PromoPassAction(playlist.id));
					window.open(getPlaylistShareLink(playlist), '_blank');
					dispatch(hideModal());
				},
			},
		})
	);

/**
 * Should be used whenever the user tries to perform a share-related
 * action that cannot be performed unless the playlist is not private.
 */
export const tryShareAction = ({
	action,
	playlist,
	dispatch,
}: {
	action: () => void;
	playlist: Playlist;
	dispatch: AppDispatch;
}) => {
	const accessControlType = computeLocalPlaylistAccessControl(
		playlist.playlist
	);

	if (accessControlType !== PlaylistAccessControlType.PRIVATE) {
		action();
		return;
	}

	dispatch(
		showModalAction(CONFIRMATION_MODAL, {
			size: 'md',
			title: 'THIS PLAYLIST IS HIDDEN FOR EVERYONE',
			description: (
				<div>
					This playlist is currently <strong>Private</strong>. Change this in
					settings and click the link again.
				</div>
			),
			confirmAction: {
				label: 'Open Share Settings',
				onClick: () => {
					dispatch(hideModal());
					openShareSettingsModal(playlist.id, dispatch);
				},
			},
		})
	);
};
