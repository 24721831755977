import axios from 'axios';

export const explicitApi = axios.create({
	baseURL:
		process.env.NODE_ENV === 'production'
			? process.env.REACT_APP_EXPLICIT_API
			: '/explicit',
});

explicitApi.interceptors.request.use(config => {
	if (config.headers)
		config.headers['sound-credit-lyrics-ai-code'] = 'YqMhYuyKQbpNargg7rNatc';

	return config;
});
