import React, { useCallback, useEffect, useMemo, useState } from 'react';
import AudioPreview from '../../../layout/AudioPreview';
import DocxPreview from '../../../layout/DocxPreview';
import { useDispatch } from 'react-redux';
import ImagePreview from '../../../layout/ImagePreview';
import PdfPreview from '../../../layout/PdfPreview';
import SpreadsheetPreview from '../../../layout/SpreadsheetPreview';
import SoundCreditLoader from '../../SoundCreditLoader';
import {
	getFileMetadataAction,
	handleFileError,
} from '../../../../store/files/actions';
import { hideModal } from '../../../../store/modal/actions';
import { Col, Row } from 'react-bootstrap';
import IconButton from '../../../layout/IconButton';
import './FilePreviewModal.scss';
import fileSize from 'filesize';
import OverflowMenu from '../../../layout/OverflowMenu';
import { formatDateToStr } from '../../../utils/dateFormatters';
import { getExtensionFromFileMetadata } from '../../../../helpers/fileTools';
import Button from '../../../layout/Button';
import { OverflowMenuOption } from '../../../layout/OverflowMenu/OverflowMenu';
import { useAppDispatch } from '../../../../store/hooks';
import clsx from 'clsx';
import { isElectron, isMacOs } from 'react-device-detect';
import path from 'path-browserify';
import { Badge } from 'primereact/badge';

export const PREVIEW_MAY_VARY_EXTENSIONS = ['.docx', '.xlsx', '.xls'];

// TODO: Implement fetch export ID on demand (requires fetch export by ID endpoint)
export type FilePreviewModalProps = {
	fileId?: number;
	file: ExportMetadata | FileMetadata | null;
	getPreviewDataFn: ((file: any) => Promise<any>) | null;
	downloadFileFn: (() => Promise<void>) | null;
	previewOptions?: OverflowMenuOption[] | null;
};

const FilePreviewModal = ({
	fileId,
	file,
	getPreviewDataFn,
	downloadFileFn,
	previewOptions,
}: FilePreviewModalProps) => {
	const dispatch = useAppDispatch();

	const [isLoading, setIsLoading] = useState(false);
	const [previewData, setPreviewData] = useState<any>();

	const closeModal = useCallback(() => {
		dispatch(hideModal());
	}, [dispatch]);

	const stopPropagation = useCallback((e: React.MouseEvent) => {
		e.stopPropagation();
	}, []);

	const [failedToLoad, setFailedToLoad] = useState(false);
	const fileExtension = useMemo(
		() => (file ? getExtensionFromFileMetadata(file) : ''),
		[file]
	);

	useEffect(() => {
		if (!file && !isLoading && !failedToLoad) {
			if (!fileId) {
				throw new Error(
					'FilePreviewModal must be passed a fileId if no file is passed'
				);
			}

			setIsLoading(true);

			dispatch(getFileMetadataAction(fileId))
				.catch(e => setFailedToLoad(true))
				.finally(() => setIsLoading(false));
		}
	}, [file, fileId, isLoading, failedToLoad, dispatch]);

	useEffect(() => {
		if (
			file &&
			previewData === undefined &&
			!isLoading &&
			!failedToLoad &&
			getPreviewDataFn
		) {
			setIsLoading(true);
			console.log('GETTING PREVIEW DATA FOR FILE', file);
			getPreviewDataFn(file)
				.then(data => {
					console.log('GOT PREVIEW DATA', data);
					setPreviewData(data);
				})
				.catch(e => {
					handleFileError(
						e,
						dispatch,
						"Whoops! The file preview wouldn't load, keep rockin'!"
					);

					setFailedToLoad(true);
					setPreviewData(null);
				})
				.finally(() => setIsLoading(false));
		}
	}, [previewData, file, isLoading, dispatch, getPreviewDataFn, failedToLoad]);

	const handleRetry = useCallback(() => {
		setFailedToLoad(false);
		setPreviewData(undefined);
	}, []);

	const renderPreview = useCallback(() => {
		if (failedToLoad) {
			return <FailedToLoadFile onRetry={handleRetry} />;
		}

		switch (fileExtension) {
			case 'mp3':
			case 'wav':
			case 'm4a':
			case 'flac':
				return <AudioPreview fileUrl={previewData} />;
			case 'docx':
				return <DocxPreview file={previewData} />;
			case 'xlsx':
			case 'xls':
				return <SpreadsheetPreview file={previewData} />;
			case 'pdf':
				return <PdfPreview file={previewData} />;
			case 'png':
			case 'jpg':
			case 'jpeg':
				return <ImagePreview imgSrc={previewData} />;
			default:
				return <NoPreviewPlaceholder />;
		}
	}, [fileExtension, previewData, failedToLoad, handleRetry]);

	return (
		<div
			style={{
				height: '100vh',
				width: '100vw',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
			onClick={closeModal}
		>
			<div onClick={stopPropagation}>
				{!previewData && previewData !== null ? (
					<SoundCreditLoader
						style={{ backgroundColor: 'white', borderRadius: 5 }}
					/>
				) : (
					renderPreview()
				)}
			</div>
			<div
				style={{
					width: '100%',
					position: 'fixed',
					top: 0,
				}}
				onClick={stopPropagation}
			>
				<FilePreviewHeader
					file={file}
					previewOptions={previewOptions}
					downloadFileFn={downloadFileFn}
				/>
			</div>
		</div>
	);
};

const NoPreviewPlaceholder = () => {
	return (
		<div
			style={{
				backgroundColor: 'white',
				padding: '2rem',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				borderRadius: 4,
				fontWeight: '700',
			}}
		>
			<i
				className='fas fa-file mb-3'
				style={{ fontSize: '4rem', color: 'black', opacity: 0.3 }}
			/>
			<p className='m-0'>NO PREVIEW AVAILABLE</p>
			<p className=''>FOR THIS FILE TYPE</p>
			<p
				style={{
					fontSize: '0.8rem',
					fontWeight: '500',
				}}
				className='m-0'
			>
				Sorry about that.
			</p>
		</div>
	);
};

const FailedToLoadFile = ({ onRetry }: { onRetry: () => void }) => {
	return (
		<div
			style={{
				backgroundColor: 'white',
				padding: '2rem',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				borderRadius: 4,
				fontWeight: '700',
			}}
		>
			<i
				className='fas fa-file mb-3'
				style={{ fontSize: '4rem', color: 'black', opacity: 0.3 }}
			/>
			<p className='m-0'>WHOOPS!</p>
			<p>AN ERROR OCCURRED</p>

			<Button label='Retry' onClick={onRetry} leftIcon='fas fa-redo' />
		</div>
	);
};

const FilePreviewHeader = ({
	file,
	downloadFileFn,
	previewOptions,
}: {
	file: ExportMetadata | FileMetadata | null;
	downloadFileFn: (() => Promise<void>) | null;
	previewOptions?: OverflowMenuOption[] | null;
}) => {
	const dispatch = useDispatch();

	const [isDownloading, setIsDownloading] = useState(false);

	const closeModal = useCallback(() => {
		dispatch(hideModal());
	}, [dispatch]);

	const fileSizeStr = useMemo(
		() => (file && 'fileSize' in file ? fileSize(file.fileSize) : ''),
		[file]
	);

	const updateDateStr = useMemo(
		() => (file ? formatDateToStr(new Date(file.updatedAt)) : ''),
		[file]
	);

	const handleFileDownload = useCallback(() => {
		if (!downloadFileFn) {
			return;
		}

		setIsDownloading(true);

		Promise.resolve(downloadFileFn()).finally(() => setIsDownloading(false));
	}, [downloadFileFn]);

	return (
		<Row
			className={clsx('p-3 file-preview-header-container', {
				'file-preview-mac-desktop-padding': isElectron && isMacOs,
			})}
		>
			<Col xs={10}>
				{file && (
					<>
						<p className='file-preview-header-title'>{file.filename}</p>
						<p className='file-preview-header-subtitle'>
							Updated {updateDateStr}
						</p>
						{fileSizeStr && (
							<p className='file-preview-header-subtitle'>
								Size: {fileSizeStr}
							</p>
						)}
						{PREVIEW_MAY_VARY_EXTENSIONS.includes(
							path.parse(file.filename).ext
						) && (
							<Badge severity='info' value='PREVIEW MAY VARY FROM ORIGINAL' />
						)}
					</>
				)}
			</Col>
			<Col xs={2}>
				<Row>
					<Col
						xs={10}
						className='d-flex justify-content-end align-items-center'
					>
						{file && (
							<>
								<IconButton
									icon='fas fa-download'
									onClick={handleFileDownload}
									color='white'
									tooltipText='Download'
									isLoading={isDownloading}
									loadingVariant='light'
								/>
								{previewOptions?.length && (
									<OverflowMenu
										id='file-preview-overflow-menu'
										options={previewOptions}
										tooltipText='More Options'
										color='white'
									/>
								)}
							</>
						)}
					</Col>
					<Col xs={2} className='d-flex align-items-center'>
						<button
							className='btn-close white mb-1'
							onClick={closeModal}
						></button>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default FilePreviewModal;
