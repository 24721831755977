import React, { useMemo, useRef } from 'react';
import { Placeholder } from 'react-bootstrap';
import { Link, PathMatch, useMatch, useNavigate } from 'react-router-dom';
import { replacePathVariables } from '../../../../../helpers/routeTools';
import ROUTES from '../../../../../router/routes';
import './PlaylistList.scss';
import { ContextMenu } from 'primereact/contextmenu';
import { showModalAction } from '@/store/modal/actions';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
	DELETE_MODAL,
	EDIT_PLAYLIST_DETAILS_MODAL,
} from '@/constants/modalTypes';
import {
	deletePlaylistAction,
	setCurrentPlaylistAction,
} from '../../../../../store/playlists/actions';
import { MenuItem } from 'primereact/menuitem';
import {
	setPlayerPlayingAction,
	setPlayerPlaylistAction,
} from '../../../../../store/player/actions';

export type PlaylistListProps = {
	className?: string;
	playlists: Playlist[];
};

const PlaylistList = ({ className = '', playlists }: PlaylistListProps) => {
	const pathMatch = useMatch(ROUTES.PlaylistDetails.path);
	const contextMenu = useRef<ContextMenu>(null);

	const dispatch = useAppDispatch();
	const { playlistsById, currentPlaylistId } = useAppSelector(
		state => state.playlists
	);
	const { playlistId: playingPlaylistId, playing } = useAppSelector(
		state => state.player
	);

	const navigate = useNavigate();

	const isLoading = useMemo(() => !playlistsById, [playlistsById]);
	const [contextMenuPlaylistId, setContextMenuPlaylistId] = React.useState<
		Playlist['id'] | null
	>(null);

	const contextMenuItems: MenuItem[] = useMemo(() => {
		if (!contextMenuPlaylistId) return [];
		const playlist = playlistsById![contextMenuPlaylistId];

		if (!playlist) return [];

		return [
			{
				label: `Edit Details`,
				icon: 'fas fa-edit',
				command: () => {
					dispatch(
						showModalAction(EDIT_PLAYLIST_DETAILS_MODAL, {
							playlistId: playlist.id,
							size: 'lg',
						})
					);
				},
			},
			{
				label: `Delete ${playlist?.name}`,
				icon: 'fas fa-trash',
				command: () => {
					dispatch(
						showModalAction(DELETE_MODAL, {
							title: 'DELETE PLAYLIST',
							body: (
								<>
									Are you sure you want to delete{' '}
									<i className='fw-600'>{playlist?.playlist?.name}</i>?
								</>
							),
							onDelete: async () => {
								if (currentPlaylistId === contextMenuPlaylistId) {
									navigate(ROUTES.Playlists.path);
									dispatch(setCurrentPlaylistAction(null));
								}

								await Promise.resolve(
									dispatch(deletePlaylistAction(playlist?.id))
								);
							},
							size: 'md',
						})
					);
				},
			},
		];
	}, [
		contextMenuPlaylistId,
		currentPlaylistId,
		playlistsById,
		dispatch,
		navigate,
	]);

	return isLoading ? (
		<PlaceholderPlaylistList className={className} />
	) : (
		<div style={{ overflowY: 'auto' }} className={className}>
			<ContextMenu
				model={contextMenuItems}
				ref={contextMenu}
				style={{
					width: '15rem',
				}}
			/>
			{playlists.map(playlist => (
				<Link
					onDoubleClick={() => {
						if (playingPlaylistId === playlist.id) {
							dispatch(setPlayerPlayingAction(!playing));
							return;
						}

						// TODO: group indexes need to be re-computed if the playlist is not yet set to current
						dispatch(setPlayerPlaylistAction({ playlistId: playlist.id }));
					}}
					onContextMenu={e => {
						e.preventDefault();

						if (contextMenu.current) {
							setContextMenuPlaylistId(playlist.id);
							contextMenu.current.show(e);
						}
					}}
					key={playlist.id}
					to={replacePathVariables(ROUTES.PlaylistDetails.path, {
						playlistId: playlist.id,
					})}
				>
					<PlaylistItem playlist={playlist} pathMatch={pathMatch} />
				</Link>
			))}
		</div>
	);
};

const PlaylistItem = ({
	playlist,
	pathMatch,
}: {
	playlist: Playlist;
	pathMatch: PathMatch<string> | null;
}) => {
	const { currentPlaylistId } = useAppSelector(state => state.playlists);
	const { playlistId: playingPlaylistId } = useAppSelector(
		state => state.player
	);

	return (
		<div>
			<button
				className={`btn playlist-menu-list-item  text-left playlist-list-item pl-4 
            ${
							playlist &&
							playlist?.id === currentPlaylistId &&
							// this check needs to be done since the /playlists/transfer is a case of /playlists/:playlistId
							// TODO: Make the /playlists/transfer route independent of /playlists/:playlistId
							pathMatch?.params?.playlistId === playlist?.id.toString()
								? 'bg-purple-gradient playlist-list-item__selected'
								: ''
						}`}
				// onClick={onClick(playlist?.id)}
			>
				<div className='d-flex align-items-center justify-content-between'>
					<span>{playlist?.name}</span>
					{playlist?.id === playingPlaylistId && (
						<i
							className='fas fa-volume-up ml-2'
							style={{
								color: 'white',
							}}
						/>
					)}
				</div>
			</button>
		</div>
	);
};

const PlaceholderPlaylistList = ({
	className = '',
}: {
	className?: string;
}) => (
	<div className={className}>
		{Array(10)
			.fill(0)
			.map((_, i) => (
				<Placeholder
					key={i}
					className='playlist-list-item__placeholder p-1'
					animation='glow'
					as='div'
				>
					<Placeholder
						xs={12}
						className='h-100'
						style={{
							borderRadius: '0.25rem',
						}}
					/>
				</Placeholder>
			))}
	</div>
);

export default PlaylistList;
