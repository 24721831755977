import React, { useCallback, useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Button from '../../../layout/Button';
import {
	hideModal,
	hideModalByTypeAction,
	setModalTitle,
} from '../../../../store/modal/actions';
import {
	EXPORT_PREVIEW_MODAL,
	UPLOAD_PREVIEW_MODAL,
} from '../../../../constants/modalTypes';
import { fetchUserPlaylistsAction } from '../../../../store/playlists/actions';

const DeleteFileModal = ({ onDelete, isExport }) => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);

	const handleClose = useCallback(() => {
		dispatch(hideModal());
	}, [dispatch]);

	const handleDelete = useCallback(async () => {
		try {
			if (onDelete) {
				// hide preview modal to avoid showing deleted file
				dispatch(
					hideModalByTypeAction(
						isExport ? EXPORT_PREVIEW_MODAL : UPLOAD_PREVIEW_MODAL
					)
				);
				setIsLoading(true);
				await Promise.resolve(onDelete());

				if (!isExport) {
					dispatch(fetchUserPlaylistsAction()); // invalidate playlists cache to avoid null references
				}
			}
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
			handleClose();
		}
	}, [onDelete, handleClose, dispatch, isExport]);

	useEffect(() => {
		dispatch(setModalTitle('DELETE FILE'));
	}, [dispatch]);

	return (
		<>
			<Modal.Body>
				<div className='px-4'>
					<p style={{ fontWeight: 500 }}>
						Are you sure you want to delete this file? All references to this
						file in playlists will be removed as well.
					</p>
					<p style={{ fontWeight: 700, marginBottom: 0 }}>
						This action cannot be undone.
					</p>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Row>
					<Col
						xs={12}
						className='d-flex justify-content-end align-items-center'
					>
						<Button label='Cancel' onClick={handleClose} />
						<Button
							label='Delete'
							isLoading={isLoading}
							theme='danger-filled'
							onClick={handleDelete}
							className='ml-2'
						/>
					</Col>
				</Row>
			</Modal.Footer>
		</>
	);
};

export default DeleteFileModal;
