import { FieldArray, FormikProvider, useFormik } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
	nextExportStepAction,
	previousExportStepAction,
	setUnionExportCompleteParticipantsFormAction,
	setUnionExportParticipantsAction,
} from '../../../../../store/exports/actions';
import Button from '../../../../layout/Button';
import * as yup from 'yup';
import '../../../../layout/SearchTable/SearchTable.scss';
import theme from '../../../../../theme.module.scss';
import './CompleteParticipantsStepAFM.scss';
import { hideModal, showModalAction } from '../../../../../store/modal/actions';
import { EDIT_ADDRESS_MODAL } from '../../../../../constants/modalTypes';
import AFMParticipantCard from './AFMParticipantCard';
import { useDebounceEffect } from '../../../../../hooks/useDebounceEffect';
import { lastFourOrFullSsnRegex } from '../../../../../helpers/regex';
import ToggleInput from '../../../../layout/ToggleInput';

yup.addMethod(yup.array, 'mustHaveOneLeader', function (errorMessage) {
	return this.test(
		'test-must-have-one-leader',
		errorMessage,
		function (participants) {
			const { path, createError } = this;

			return (
				participants.filter(participant => participant.isSessionLeader)
					.length === 1 || createError({ path, message: errorMessage })
			);
		}
	);
});

const schema = yup.object().shape({
	participants: yup
		.array()
		.of(
			yup.object().shape({
				numberOfDoubles: yup.number().required('# Of Doubles Is Required'),
				scale: yup.string().required('Scale Is Required'),
				overtime: yup
					.string()
					.nullable()
					.transform((curr, orig) => (orig === null ? '' : curr))
					.required('Overtime Is Required'),
				socialLastFour: yup
					.string()
					.nullable()
					.transform((curr, orig) => (orig === null ? '' : curr))
					.matches(lastFourOrFullSsnRegex, 'Invalid SSN')
					.required('SSN Is Required'),
				address1: yup
					.string()
					.nullable()
					.transform((curr, orig) => (orig === null ? '' : curr))
					.required('Street Address Is Required'),
				city: yup
					.string()
					.nullable()
					.transform((curr, orig) => (orig === null ? '' : curr))
					.required('City Is Required'),
				state: yup
					.string()
					.nullable()
					.transform((curr, orig) => (orig === null ? '' : curr))
					.required('State Is Required'),
			})
		)
		.mustHaveOneLeader('There must be one session leader selected.'),
});

const CompleteParticipantsStepAFM = () => {
	/*
	 * Redux Hooks
	 */
	const { completeParticipantsForm, selectedParticipants } = useSelector(
		state => state.exports.unionForm
	);
	const dispatch = useDispatch();

	/*
	 * Callbacks
	 */
	const handleSubmit = values => {
		dispatch(setUnionExportCompleteParticipantsFormAction(values));
		dispatch(nextExportStepAction());
	};

	const navigateToPrevStep = () => {
		dispatch(setUnionExportCompleteParticipantsFormAction(formik.values));
		dispatch(previousExportStepAction());
	};

	const handleDelete = useCallback(
		id => {
			const newSelectedParticipants = [
				...selectedParticipants.filter(participant => participant.id !== id),
			];

			dispatch(setUnionExportParticipantsAction(newSelectedParticipants));
		},
		[dispatch, selectedParticipants]
	);

	/*
	 * Other Hooks
	 */
	const formik = useFormik({
		initialValues: completeParticipantsForm,
		validationSchema: schema,
		onSubmit: handleSubmit,
	});

	const handleAddressEdit = participantIndex => {
		dispatch(
			showModalAction(EDIT_ADDRESS_MODAL, {
				initialValues: {
					address1:
						completeParticipantsForm.participants[participantIndex].address1,
					address2:
						completeParticipantsForm.participants[participantIndex].address2,
					city: completeParticipantsForm.participants[participantIndex].city,
					state: completeParticipantsForm.participants[participantIndex].state,
					postalCode:
						completeParticipantsForm.participants[participantIndex].postalCode,
					country:
						completeParticipantsForm.participants[participantIndex].country,
				},
				onSubmit: values => {
					formik.setFieldValue(
						`participants[${participantIndex}].address1`,
						values.address1
					);
					formik.setFieldValue(
						`participants[${participantIndex}].address2`,
						values.address2
					);
					formik.setFieldValue(
						`participants[${participantIndex}].city`,
						values.city
					);
					formik.setFieldValue(
						`participants[${participantIndex}].state`,
						values.state
					);
					formik.setFieldValue(
						`participants[${participantIndex}].postalCode`,
						values.postalCode
					);
					formik.setFieldValue(
						`participants[${participantIndex}].country`,
						values.country
					);

					dispatch(hideModal());
				},
				validate: true,
			})
		);
	};

	/*
	 * React Hooks
	 */
	const [showValidationError, setShowValidationError] = useState(false);

	const selectLeaderErrorMessage = useMemo(() => {
		if (
			formik.values.participants &&
			!formik.values.participants.find(p => p.isSessionLeader)
		) {
			return 'There must be one session leader selected.';
		}

		setShowValidationError(false);
		return '';
	}, [formik.values]);

	/*
	 * Effects
	 */
	useDebounceEffect(
		() => {
			if (formik.dirty) {
				dispatch(setUnionExportCompleteParticipantsFormAction(formik.values));
			}
		},
		500,
		[formik.values, dispatch]
	);

	return (
		<FormikProvider value={formik}>
			<Form onSubmit={formik.handleSubmit}>
				<>
					<Card>
						<Card.Body>
							<h2>COMPLETE PARTICIPANTS INFO</h2>
							<p className='body-text'>All fields below are mandatory.</p>

							<div className='d-flex justify-content-end align-items-centerr'>
								<ToggleInput
									label='Prior session occurred with a different leader'
									className='d-flex justify-content-center'
									checked={formik.values.differentLeaderOnPriorSession}
									value={formik.values.differentLeaderOnPriorSession}
									onChange={formik.handleChange}
									name='differentLeaderOnPriorSession'
								/>
							</div>
							{showValidationError && selectLeaderErrorMessage && (
								<div className='my-2 body-text' style={{ color: theme.error }}>
									{selectLeaderErrorMessage}
								</div>
							)}

							<div className='mt-2'>
								<FieldArray name='participants'>
									{arrayHelpers =>
										formik.values.participants.map((participant, index) => (
											<AFMParticipantCard
												key={participant.id}
												participant={participant}
												index={index}
												formik={formik}
												showErrorMessage={showValidationError}
												addressErrorMessage='Full Home Address Is Required'
												onDelete={() => {
													arrayHelpers.remove(index);
													handleDelete(participant.id);
												}}
												onAddressEdit={() => handleAddressEdit(index)}
											/>
										))
									}
								</FieldArray>
							</div>
						</Card.Body>
					</Card>

					<Row className='pb-4'>
						<Col className='d-flex justify-content-end align-items-center'>
							<Button
								label='Back'
								className='mr-3'
								onClick={navigateToPrevStep}
							/>

							<Button
								label='Next'
								theme='dark'
								type='submit'
								onClick={() => setShowValidationError(true)}
							/>
						</Col>
					</Row>
				</>
			</Form>
		</FormikProvider>
	);
};

export default CompleteParticipantsStepAFM;
