import { translateApiObjectToLocal } from './apiTools';

const translateApiFileToLocal = (apiFile: any) => {
	const camelizedApiFile = translateApiObjectToLocal(apiFile);

	// translate date strings to Date objects
	// and add filename property
	const localFile = {
		...camelizedApiFile,
	};

	if (!localFile.isDisplayFile && localFile.displayFile) {
		// add filename property to displayFile
		localFile.displayFile = {
			...localFile.displayFile,
		};
	}

	return localFile as FileMetadata;
};

export default translateApiFileToLocal;
