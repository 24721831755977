import React, { useEffect, useMemo, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import SearchBar from '../../../layout/SearchBar';
import Sort from '../../../layout/Sort';
import SortMenu from '../../../layout/SortMenu';
import _ from 'lodash';
import theme from '../../../../theme.module.scss';
import SoundCreditLoader from '../../SoundCreditLoader/SoundCreditLoader';
import FileTagSelect from '../../../form/FileTagSelect';
import { useAppSelector } from '../../../../store/hooks';
import { isExportFile, isFileMetadata } from '../../../../helpers/fileTools';
import { SortOptions } from '../../../layout/SortMenu/SortMenu';
import { ProjectExportsListItemProps } from '../ProjectExportsBrowser/ProjectExportsListItem/ProjectExportsListItem';
import { ProjectFilesListItemProps } from '../ProjectFilesBrowser/ProjectFilesListItem/ProjectFilesListItem';
import { ProjectUploadListItemProps } from '../ProjectUploadQueue/ProjectUploadListItem/ProjectUploadListItem';

export type FileBrowserSortOptions<
	FileListItemProps extends
		| ProjectExportsListItemProps
		| ProjectFilesListItemProps
		| ProjectUploadListItemProps
> = SortOptions<FileListItemProps>;

const defaultFilterFn = <
	FileType extends FileMetadata | ProjectFileUpload | ExportMetadata
>(
	searchFilter: string,
	file: FileType
) => {
	let filename: string;

	if (isFileMetadata(file) || isExportFile(file)) {
		filename = file.filename;
	} else {
		filename = file.metadata.filename;
	}

	return filename.toLowerCase().includes(searchFilter.trim().toLowerCase());
};

const normalfilterFn = (str: string, searchFilter: string) =>
	str.toLowerCase().includes(searchFilter.trim().toLowerCase());

export type BrowserFileSelection<IdType extends string | number = number> =
	Record<IdType, boolean>;

export type FilesBrowserProps<
	FileType extends ExportMetadata | FileMetadata | ProjectFileUpload,
	FileListItemProps extends
		| ProjectExportsListItemProps
		| ProjectFilesListItemProps
		| ProjectUploadListItemProps
> = {
	id: string;
	header: React.FC<{ selectedFiles: BrowserFileSelection<FileType['id']> }>;
	files: FileType[];
	fileCard: (props: {
		file: FileType;
		isSelected: boolean;
		setSelectedFiles: React.Dispatch<
			React.SetStateAction<BrowserFileSelection<FileType['id']>>
		>;
	}) => JSX.Element;
	noFilesPlaceholder: React.FC;
	noFilesFoundPlaceholder?: React.FC<{ searchFilter: string }>;
	sortingOptions: FileBrowserSortOptions<FileListItemProps>;
	fileFilterFn?: (searchFilter: string, file: FileType) => boolean;
	isLoading?: boolean;
	loadingMessage?: string;
	showTagFilter?: boolean;
};

const FilesBrowser = <
	FileType extends ExportMetadata | FileMetadata | ProjectFileUpload,
	FileListItemProps extends
		| ProjectExportsListItemProps
		| ProjectFilesListItemProps
		| ProjectUploadListItemProps
>({
	id,
	header: BrowserHeader,
	files,
	fileCard: FileCard,
	noFilesPlaceholder: NoFilesPlaceholder,
	noFilesFoundPlaceholder:
		NoFilesFoundPlaceholder = DefaultNoFilesFoundPlaceholder,
	sortingOptions,
	fileFilterFn = defaultFilterFn,
	isLoading = false,
	loadingMessage = 'Loading...',
	showTagFilter = false,
}: FilesBrowserProps<FileType, FileListItemProps>) => {
	/*
	 * React Hooks
	 */
	const { fileLabelDetails } = useAppSelector(state => state.files);

	const [activeSortKey, setActiveSortKey] = useState(
		Object.keys(sortingOptions)[0]
	);
	const [ascending, setAscending] = useState(true);
	const [selectedFiles, setSelectedFiles] = useState<
		BrowserFileSelection<FileType['id']>
	>({} as BrowserFileSelection<FileType['id']>);
	const [searchFilter, setSearchFilter] = useState('');
	const [selectedTagFilter, setSelectedTagFilter] = useState<
		FileMetadata['label'] | null
	>(null);

	const filteredFiles = useMemo(
		() =>
			files
				.filter(file =>
					selectedTagFilter === null || !isFileMetadata(file)
						? true
						: file.label === selectedTagFilter
				)
				.filter(file =>
					searchFilter === ''
						? true
						: fileFilterFn(searchFilter, file) ||
						  (isFileMetadata(file) &&
								file.label &&
								normalfilterFn(fileLabelDetails![file.label], searchFilter))
				),
		[searchFilter, files, fileFilterFn, fileLabelDetails, selectedTagFilter]
	);

	const handleSelectAll: React.ChangeEventHandler<HTMLInputElement> = e => {
		console.log(e);
		if (e.target.checked) {
			// select all
			const newSelectedFiles = {} as BrowserFileSelection<FileType['id']>;

			files.forEach(file => {
				newSelectedFiles[file.id as FileType['id']] = true;
			});

			setSelectedFiles(newSelectedFiles);
		} else {
			// deselect all
			const newSelectedFiles = {} as BrowserFileSelection<FileType['id']>;

			files.forEach(file => {
				newSelectedFiles[file.id as FileType['id']] = false;
			});

			setSelectedFiles(newSelectedFiles);
		}
	};

	useEffect(() => {
		// initialize selected files state object
		if (_.isEmpty(selectedFiles) && files.length > 0) {
			const initialSelectedFiles = {} as BrowserFileSelection<FileType['id']>;

			files.forEach(file => {
				initialSelectedFiles[file.id as FileType['id']] = false;
			});

			setSelectedFiles(initialSelectedFiles);
		}
	}, [files, selectedFiles]);

	return (
		<>
			<Card className='p-4'>
				{isLoading ? (
					<div style={{ height: '50vh', width: '100%' }}>
						<SoundCreditLoader message={loadingMessage} />
					</div>
				) : (
					<Row>
						<BrowserHeader selectedFiles={selectedFiles} />
						<Col xs={12} className='my-4'>
							<SearchBar
								placeholder='Search by file name, tag, or keyword'
								onChange={value => setSearchFilter(value)}
								value={searchFilter}
								fullWidth
							/>
						</Col>
						<Col xs={12} className='my-2'>
							<div className='d-flex justify-content-end align-items-center'>
								{showTagFilter && (
									<FileTagSelect
										fileTag={selectedTagFilter}
										setFileTag={setSelectedTagFilter}
										// necessary to show all files when no tag is selected
										// (otherwise it would default 0 and exclude files with non-0 tags)
										defaultValue={null}
									/>
								)}
								<SortMenu
									sortOptions={sortingOptions}
									setActiveSortKey={setActiveSortKey}
									activeSortKey={activeSortKey}
									defaultActiveKey={Object.keys(sortingOptions)[2]}
									ascending={ascending}
									setAscending={setAscending}
								/>
								{/* <OverflowMenu /> */}
							</div>
							<hr className='my-1' />
						</Col>
						{(files.length > 0 && searchFilter.length === 0) ||
						(searchFilter.length > 0 && filteredFiles.length > 0) ? (
							<>
								<Col xs={12} className='my-2'>
									<Form.Check
										label='Select All'
										className='fw-400 d-flex no-highlight'
										type='checkbox'
										name='select-all'
										id={`select-all-${id}`}
										onChange={handleSelectAll}
									/>
								</Col>
								<Col xs={12}>
									<Sort
										compareFn={sortingOptions[activeSortKey].compareFn}
										reverse={!ascending}
									>
										{filteredFiles.map((file, index) => (
											<FileCard
												key={index}
												file={file}
												isSelected={selectedFiles[file.id as FileType['id']]}
												setSelectedFiles={setSelectedFiles}
											/>
										))}
									</Sort>
								</Col>
							</>
						) : searchFilter.length > 0 && filteredFiles.length === 0 ? (
							<NoFilesFoundPlaceholder searchFilter={searchFilter} />
						) : (
							<NoFilesPlaceholder />
						)}
					</Row>
				)}
			</Card>
		</>
	);
};

const DefaultNoFilesFoundPlaceholder = ({
	searchFilter,
}: {
	searchFilter: string;
}) => (
	<Row className='w-100'>
		<Col xs={12} className='my-2'>
			<div
				className='d-flex flex-column justify-content-center align-items-center fw-600'
				style={{
					color: theme.darkGreyTextColor2,
					fontSize: '1rem',
					height: '40vh',
				}}
			>
				<i
					className='fas fa-search mb-4'
					style={{
						fontSize: '5rem',
					}}
				></i>
				<p
					style={{
						maxWidth: '30rem',
						textAlign: 'center',
					}}
				>
					No results for {`"${searchFilter}"`}
				</p>
			</div>
		</Col>
	</Row>
);

export default FilesBrowser;
