import Lottie from 'react-lottie';
import EditorRow from './EditorRow';
import './ShareModal.scss';
import lightAnimationData from '../../../../assets/animations/blackLoaderOnLightGrey.json';
import React, { useRef } from 'react';

const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: lightAnimationData,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice',
	},
};

const EditorTable = ({
	setEditors,
	recordingId,
	albumId,
	isLoading,
	editors,
	errorMsg,
	setErrorMsg,
	fetchEditors,
	myEditorProfile,
	toggleLoading,
	shareAlbum,
}) => {
	const errorRef = useRef(null);

	if (isLoading)
		return (
			<table className='search-table share-table'>
				<div style={{ display: 'grid', placeItems: 'center' }}>
					<Lottie
						options={defaultOptions}
						isClickToPauseDisabled
						height={150}
						width={150}
					/>
				</div>
			</table>
		);

	return (
		<table className='search-table share-table'>
			{myEditorProfile && !myEditorProfile.is_read_only && (
				<span className='share-table-error' ref={errorRef}>
					{errorMsg || 'dummy'}
				</span>
			)}
			<tbody>
				{editors && editors.length ? (
					editors.map((editor, i) => (
						<EditorRow
							recordingId={recordingId}
							albumId={albumId}
							key={`editor-${i}`}
							editor={editor}
							setEditors={setEditors}
							editors={editors}
							setErrorMsg={setErrorMsg}
							fetchEditors={fetchEditors}
							toggleLoading={toggleLoading}
							shareAlbum={shareAlbum}
						/>
					))
				) : (
					<tr className='w-100 d-flex justify-content-center'>
						<h2>There are no editors in this project.</h2>
					</tr>
				)}
			</tbody>
		</table>
	);
};

export default EditorTable;
