import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useExpanded, useRowSelect, useTable } from 'react-table';
import { getProjectFilesMetadataAction } from '../../../../store/files/actions';
import { getProjectsForTable } from '../../../../store/projects/selectors';
import IconButton from '../../../layout/IconButton';
import SoundCreditLoader from '../../SoundCreditLoader';
import projectFilesColumns from './projectFilesColumns';
import reducedAlbumColumns from './reducedAlbumColumns';
import reducedProjectsColumns from './reducedProjectsColumns';
import theme from '../../../../theme.module.scss';
import SearchBar from '../../../layout/SearchBar';
import './SelectProjectFilesLeftTable.scss';
import '../SelectProjectFilesModal.scss';
import SelectableTable from '../../../layout/SelectableTable';
import filterProjectsBySearchTerm from '../../../../helpers/filterProjectsBySearchTerm';
import {
	filterFilesBySearchTerm,
	navigateToProjectFiles,
} from '../../../../helpers/fileTools';
import PillButton from '../../../layout/PillButton';
import EmptyPlaceholder from '../../../layout/EmptyPlaceholder';
import { useNavigate } from 'react-router-dom';
import { hideModal } from '../../../../store/modal/actions';
import FILE_TABS from '../../../../constants/fileTabs.json';
import { getUserProjectsAction } from '../../../../store/projects/actions';

const SelectProjectFilesLeftTable = ({
	setSelectedFileIds,
	selectedFileIds,
}) => {
	const dispatch = useDispatch();
	const { recordingsById, albumsById } = useSelector(state => state.projects);
	const [selectedProject, setSelectedProject] = useState({
		albumId: null,
		recordingId: null,
	});
	const [projectsSearchTerm, setProjectsSearchTerm] = useState('');
	const [filesSearchTerm, setFilesSearchTerm] = useState('');

	const project = useMemo(() => {
		if (selectedProject.recordingId) {
			return recordingsById[selectedProject.recordingId];
		} else if (selectedProject.albumId) {
			return albumsById[selectedProject.albumId];
		}

		return null;
	}, [selectedProject, recordingsById, albumsById]);

	const [prevSelectedProject, setPrevSelectedProject] = useState(null);

	const isProjectSelected = useMemo(() => {
		return selectedProject.albumId || selectedProject.recordingId;
	}, [selectedProject]);

	const clearSelectedProject = useCallback(() => {
		setSelectedProject({
			albumId: null,
			recordingId: null,
		});
	}, []);

	const restorePrevSelectedProject = useCallback(() => {
		if (!prevSelectedProject) return;

		setSelectedProject({ ...prevSelectedProject });
	}, [prevSelectedProject]);

	const handleSelectProject = useCallback(
		project => {
			setPrevSelectedProject({ ...project });
			setSelectedProject({ ...project });
		},
		[setSelectedProject]
	);

	const renderProjectsSearchBar = useCallback(
		() => (
			<SearchBar
				placeholder='Search Projects'
				onChange={term => setProjectsSearchTerm(term)}
				value={projectsSearchTerm}
				fullWidth
			/>
		),
		[projectsSearchTerm]
	);

	const renderFilesSearchBar = useCallback(
		() => (
			<SearchBar
				placeholder='Search Files'
				onChange={term => setFilesSearchTerm(term)}
				value={filesSearchTerm}
				fullWidth
			/>
		),
		[filesSearchTerm]
	);

	useEffect(() => {
		// refresh user projects when modal is opened
		dispatch(getUserProjectsAction());
	}, [dispatch]);

	return (
		<Card className='select-project-files__menu-container'>
			<h3>
				{isProjectSelected
					? `SELECT FILES FROM ${project.title?.toUpperCase()}`
					: `SELECT A PROJECT`}
			</h3>
			<Row className='select-project-files__header'>
				<Col md={12} lg={3} xl={2} className='d-flex'>
					<IconButton
						icon='fas fa-arrow-left'
						isDisabled={!isProjectSelected}
						onClick={clearSelectedProject}
						color={theme.primary}
					/>
					<IconButton
						icon='fas fa-arrow-right'
						isDisabled={!prevSelectedProject || isProjectSelected}
						onClick={restorePrevSelectedProject}
						color={theme.primary}
					/>
				</Col>
				<Col md={12} lg={9} xl={10}>
					{isProjectSelected
						? renderFilesSearchBar()
						: renderProjectsSearchBar()}
				</Col>
			</Row>
			<div className='select-project-files__table-container'>
				{isProjectSelected ? (
					<SelectFilesTable
						selectedProject={selectedProject}
						setSelectedFileIds={setSelectedFileIds}
						selectedFileIds={selectedFileIds}
						searchTerm={filesSearchTerm}
					/>
				) : (
					<SelectProjectTable
						setSelectedProject={handleSelectProject}
						selectedProject={selectedProject}
						searchTerm={projectsSearchTerm}
					/>
				)}
			</div>
		</Card>
	);
};

const SelectProjectTable = ({
	selectedProject,
	setSelectedProject,
	searchTerm,
}) => {
	const projects = useSelector(state =>
		getProjectsForTable(state, { keepEditableOnly: true })
	);

	const filteredProjects = useMemo(
		() => filterProjectsBySearchTerm(searchTerm, projects),
		[searchTerm, projects]
	);

	const columns = React.useMemo(() => reducedProjectsColumns, []);

	console.log('selectedProject', selectedProject);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		visibleColumns,
	} = useTable(
		{
			columns,
			data: filteredProjects ?? [],
			expandSubRows: false,
			autoResetExpanded: false,
		},
		useExpanded,
		useRowSelect
	);

	const renderRecordingRow = useCallback(
		(row, key) => {
			return (
				<tr key={key} {...row.getRowProps()} className='projects-table-row'>
					{row.cells.map(cell => {
						return (
							<td className={`projects-table-cell`} {...cell.getCellProps()}>
								{cell.render('Cell', { onProjectSelect: setSelectedProject })}
							</td>
						);
					})}
				</tr>
			);
		},
		[setSelectedProject]
	);

	const renderAlbumRow = useCallback(
		(row, key) => {
			return (
				<React.Fragment key={key}>
					<tr
						{...row.getRowProps()}
						className={
							'projects-table-row' +
							(row.isExpanded ? ' expanded-album-header' : '')
						}
					>
						{row.cells.map(cell => {
							return (
								<td className={`projects-table-cell`} {...cell.getCellProps()}>
									{cell.render('Cell', { onProjectSelect: setSelectedProject })}
								</td>
							);
						})}
					</tr>
					{row.isExpanded ? (
						<tr className='expanded-album-body'>
							<td colSpan={visibleColumns.length}>
								<AlbumSubTable
									albumData={row.original}
									setSelectedProject={setSelectedProject}
								/>
							</td>
						</tr>
					) : (
						<></>
					)}
				</React.Fragment>
			);
		},
		[visibleColumns.length, setSelectedProject]
	);

	return (
		<>
			<table className='projects-table' {...getTableProps()}>
				<thead className='projects-table-header'>
					{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map(column => (
								<th {...column.getHeaderProps()}>{column.render('Header')}</th>
							))}
						</tr>
					))}
				</thead>
				<tbody className='projects-table-body' {...getTableBodyProps()}>
					{rows.map(row => {
						const key = row.original.id
							? `${row.original.id}-${row.original.subRows ? 'album' : 'rec'}`
							: '';
						prepareRow(row);
						return row.canExpand
							? renderAlbumRow(row, key)
							: renderRecordingRow(row, key);
					})}
				</tbody>
			</table>
			{filteredProjects.length === 0 && (
				<EmptyPlaceholder
					title='NO PROJECTS FOUND'
					description={
						searchTerm
							? 'No projects match your search'
							: 'Get started by creating a new recording or album in the Projects section. You can also co-own projects with other users by using the Share menu.'
					}
					icon={`fas fa-${searchTerm ? 'search' : 'music'} fa-3x`}
				/>
			)}
		</>
	);
};

const AlbumSubTable = ({ albumData, setSelectedProject }) => {
	const columns = React.useMemo(() => reducedAlbumColumns, []);
	const [recordings, setRecordings] = useState([]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable({
			columns,
			data: recordings,
		});

	useEffect(() => {
		if (albumData && albumData.subRows) {
			setRecordings(albumData.subRows);
		}
	}, [albumData]);

	return (
		<div className='p-4'>
			<Row className='mb-4'>
				<Col xs={12} md={6} className='d-flex justify-content-between'>
					<h2>{albumData.title.toUpperCase()}</h2>
				</Col>
			</Row>
			<div>
				{albumData.subRows.length > 1 ? (
					<Row>
						<Col xs={12}>
							<table className='projects-table' {...getTableProps()}>
								<thead className='projects-table-header'>
									{headerGroups.map(headerGroup => (
										<tr {...headerGroup.getHeaderGroupProps()}>
											{headerGroup.headers.map(column => (
												<th {...column.getHeaderProps()}>
													{column.render('Header')}
												</th>
											))}
										</tr>
									))}
								</thead>
								<tbody {...getTableBodyProps()}>
									{rows
										.filter(row => row.original !== '')
										.map((row, index) => {
											prepareRow(row);
											return (
												<tr className='albums-table-row' {...row.getRowProps()}>
													{row.cells.map(cell => {
														return (
															<td
																className='projects-table-cell'
																{...cell.getCellProps()}
															>
																{cell.render('Cell', {
																	row,
																	index,
																	onProjectSelect: setSelectedProject,
																})}
															</td>
														);
													})}
												</tr>
											);
										})}
								</tbody>
							</table>
						</Col>
					</Row>
				) : (
					<></>
				)}
			</div>
		</div>
	);
};

const SelectFilesTable = ({
	selectedProject,
	setSelectedFileIds,
	selectedFileIds,
	searchTerm,
	onRowSelect = null,
}) => {
	const { filesByProjectId, fileLabelDetails } = useSelector(
		state => state.files
	);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const tableRef = useRef(null); // only used for clearing row selections imperatively
	const [isLoading, setIsLoading] = useState(true);
	const [checkedFileIds, setCheckedFileIds] = useState([]);

	const projectFiles = useMemo(() => {
		if (selectedProject.recordingId) {
			return filesByProjectId.byRecordingId[selectedProject.recordingId];
		} else if (selectedProject.albumId) {
			return filesByProjectId.byAlbumId[selectedProject.albumId];
		}

		return [];
	}, [selectedProject, filesByProjectId]);

	const filteredFiles = useMemo(
		() =>
			filterFilesBySearchTerm(
				searchTerm,
				Object.values(projectFiles ?? {}),
				fileLabelDetails
			),
		[searchTerm, projectFiles, fileLabelDetails]
	);

	useEffect(() => {
		// fetch project files if they haven't been fetched yet
		if (!selectedProject.recordingId && !selectedProject.albumId) return;

		Promise.resolve(
			dispatch(getProjectFilesMetadataAction(selectedProject))
		).finally(() => setIsLoading(false));
	}, [dispatch, selectedProject]);

	const tableRows = useMemo(() => {
		if (!filteredFiles) return [];

		const selectedFileIdsObj = selectedFileIds.reduce((acc, id) => {
			acc[id] = true;
			return acc;
		}, {});

		return filteredFiles?.map(file => ({
			filename: file.filename,
			id: file.id,
			label: file.label,
			fileSize: file.fileSize,
			selected: selectedFileIdsObj[file.id],
		}));
	}, [selectedFileIds, filteredFiles]);

	const columns = useMemo(() => projectFilesColumns, []);

	const handleRowSelect = useCallback(
		(selectedRowIds, rows) => {
			const newSelectedFileIds = rows
				.filter(row => selectedRowIds[row.id])
				.map(row => row.original.id);

			if (onRowSelect) onRowSelect(newSelectedFileIds);
			else setCheckedFileIds([...newSelectedFileIds]);
		},
		[onRowSelect]
	);

	const handleFileSelect = useCallback(
		fileId => {
			setSelectedFileIds(prevFileIds => {
				if (prevFileIds.includes(fileId)) {
					return prevFileIds.filter(id => id !== fileId);
				} else {
					return [...prevFileIds, fileId];
				}
			});
		},
		[setSelectedFileIds]
	);

	const handleAddSelected = useCallback(() => {
		setSelectedFileIds(prevSelected => [
			...new Set([...prevSelected, ...checkedFileIds]),
		]);

		tableRef.current?.toggleAllRowsSelected(false);
	}, [checkedFileIds, setSelectedFileIds]);

	const handleNavigateToProjectFiles = useCallback(() => {
		Promise.resolve(
			navigateToProjectFiles({
				recordingId: selectedProject.recordingId,
				albumId: selectedProject.albumId,
				navigate,
				section: FILE_TABS.FILES,
			})
		).then(() => dispatch(hideModal()));
	}, [navigate, selectedProject, dispatch]);

	return (
		<>
			{isLoading ? (
				<SoundCreditLoader />
			) : (
				<>
					<div>
						<PillButton
							className='mb-2'
							label={'Add Selected'}
							onClick={handleAddSelected}
							isDisabled={checkedFileIds.length === 0}
						/>
					</div>
					<SelectableTable
						tableRows={tableRows}
						columns={columns}
						cellProps={{ onFileSelect: handleFileSelect }}
						onRowSelect={handleRowSelect}
						ref={tableRef}
					/>
					{tableRows.length === 0 && (
						<EmptyPlaceholder
							title='NO FILES FOUND'
							description={
								searchTerm ? (
									'No files match your search'
								) : (
									<span>
										Upload files to this project by going into its{' '}
										<span
											className='link-button'
											onClick={handleNavigateToProjectFiles}
										>
											Files menu
										</span>{' '}
										or upload directly to a playlist and assign this project to
										the uploaded files.
									</span>
								)
							}
							icon={`fas fa-${searchTerm ? 'search' : 'folder-open'} fa-3x`}
						/>
					)}
				</>
			)}
		</>
	);
};

export default SelectProjectFilesLeftTable;
