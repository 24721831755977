import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { CSSProperties, Context, useContext } from 'react';
import DndPlaylistFileTableFolder from './DndPlaylistFileTableFolder';
import { UniqueIdentifier } from '@dnd-kit/core';
import { isGroupDndId } from '../utilities';
import { SelectedPlaylistContext } from '../../../../../../context/SelectedPlaylistContext';

type SortableDndPlaylistFileTableFolderProps = {
	folder: PlaylistFileGroup | PlaylistFolder;
	rowIndex?: number;
	depth: number;
	id: UniqueIdentifier;
};

const SortableDndPlaylistFileTableFolder = ({
	folder,
	rowIndex,
	depth,
	id,
}: SortableDndPlaylistFileTableFolderProps) => {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
		isOver,
		isDragging,
		over,
	} = useSortable({ id });

	const { isAbovePointerDistanceThreshold } =
		useContext<SelectedPlaylistContextType>(
			SelectedPlaylistContext as Context<SelectedPlaylistContextType>
		);

	const isOverAnotherFolder =
		isAbovePointerDistanceThreshold &&
		isDragging &&
		over?.id &&
		isGroupDndId(over.id as string);

	const isItemOverFolder = isAbovePointerDistanceThreshold && isOver;

	const style: CSSProperties = {
		transform: CSS.Transform.toString(transform),
		transition,
		height: isOverAnotherFolder ? '0' : 'auto',
		opacity: isOverAnotherFolder ? '0' : '1',
	};

	return (
		<DndPlaylistFileTableFolder
			ref={setNodeRef}
			style={style}
			depth={depth}
			{...attributes}
			{...listeners}
			folder={folder}
			// isFileOver={isFileOverSomeFolder && isOver}
			rowIndex={rowIndex}
			isDragging={isDragging}
			className={
				isItemOverFolder ? 'playlist-file-table__group-row--file-over' : ''
			}
		/>
	);
};

export default SortableDndPlaylistFileTableFolder;
