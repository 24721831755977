import React, { useEffect, useRef, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { clearAllCurrentExportDataAction } from '../../../../store/exports/actions';
import { hideModal, setModalTitle } from '../../../../store/modal/actions';
import TextField from '../../../form/TextField/TextField';
import Button from '../../../layout/Button';
import ReactCrop, {
	Crop,
	PixelCrop,
	centerCrop,
	makeAspectCrop,
} from 'react-image-crop';
import 'react-image-crop/src/ReactCrop.scss';
import { useDebounceEffect } from '../../../../hooks/useDebounceEffect';

const ExportImageInputModal = ({
	onSubmit,
	isImageSaved = false,
	isInIndieUpload = false,
	title = 'Upload Image',
	inputLabel = 'Select an image',
}: {
	onSubmit: (image: string, imageMimeType: string) => Promise<void> | void;
	isImageSaved?: boolean;
	isInIndieUpload?: boolean;
	title?: string;
	inputLabel?: string;
}) => {
	const dispatch = useDispatch();
	const [imageToCrop, setImageToCrop] = useState<string | null>(null);
	const [inputMimeType, setInputMimeType] = useState<string | null>(null);
	const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>(null);
	const [croppedImage, setCroppedImage] = useState('');
	const imgRef = useRef<HTMLImageElement | null>(null);
	const [cropConfig, setCropConfig] = useState<Crop | undefined>();
	const [fileValidations, setFileValidations] = useState<string[]>([]);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const MAX_CROP_HEIGHT = 500;
	const MAX_CROP_WIDTH = 500;
	const ALLOWED_FILE_FORMATS = ['image/jpeg', 'image/png'];

	useEffect(() => {
		dispatch(setModalTitle(title));
	}, [dispatch, title]);

	useDebounceEffect(
		async () => {
			if (
				completedCrop &&
				completedCrop?.width &&
				completedCrop?.height &&
				imgRef.current
			) {
				// We use canvasPreview as it's much faster than imgPreview.
				const encodedCroppedImage = await getCroppedImageBase64(
					imgRef.current,
					completedCrop
				);

				setCroppedImage(encodedCroppedImage);
			}
		},
		100,
		[completedCrop]
	);

	const handleCancel = () => {
		dispatch(hideModal());
		dispatch(clearAllCurrentExportDataAction());

	};

	const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.files && e.target.files.length > 0) {
			setFileValidations([]);
			setImageToCrop(null);

			const validations = [];

			if (!ALLOWED_FILE_FORMATS.includes(e.target.files[0].type)) {
				validations.push('Image file must be a JPEG or PNG');
			}

			if (validations.length > 0) {
				setFileValidations(validations);
				return;
			}

			const reader = new FileReader();

			reader.addEventListener('load', () => {
				console.log(reader.result);

				if (typeof reader.result === 'string') {
					setImageToCrop(reader.result);
				}
			});

			reader.readAsDataURL(e.target.files[0]);
			setInputMimeType(e.target.files[0].type);
		}
	};

	const getCroppedImageBase64 = (
		sourceImage: HTMLImageElement,
		cropConfig: Crop
	) => {
		// creating the cropped image from the source image
		const scaleX = sourceImage.naturalWidth / sourceImage.width;
		const scaleY = sourceImage.naturalHeight / sourceImage.height;

		const cropHeight =
			cropConfig.height * scaleY > MAX_CROP_HEIGHT
				? MAX_CROP_HEIGHT
				: cropConfig.height * scaleY;
		const cropWidth =
			cropConfig.width * scaleX > MAX_CROP_WIDTH
				? MAX_CROP_WIDTH
				: cropConfig.width * scaleX;

		const canvasHeight = Math.ceil(cropHeight);
		const canvasWidth = Math.ceil(cropWidth);

		const canvas = document.createElement('canvas');
		canvas.width = canvasWidth;
		canvas.height = canvasHeight;
		const ctx = canvas.getContext('2d');

		if (canvas)
			ctx?.drawImage(
				sourceImage,
				cropConfig.x * scaleX,
				cropConfig.y * scaleY,
				cropConfig.width * scaleX,
				cropConfig.height * scaleY,
				0,
				0,
				cropWidth,
				cropHeight
			);

		return new Promise<string>(resolve => {
			const croppedImageBase64 = canvas
				.toDataURL(inputMimeType!)
				.split(';base64,')[1];

			resolve(croppedImageBase64);
		});
	};

	function onImageLoad(e: React.SyntheticEvent<HTMLImageElement, Event>) {
		const { width, height } = e.currentTarget;

		const crop = centerCrop(
			makeAspectCrop(
				{
					// You don't need to pass a complete crop into
					// makeAspectCrop or centerCrop.
					unit: '%',
					width: 90,
				},
				1,
				width,
				height
			),
			width,
			height
		);

		setCropConfig(crop);
	}

	const handleSubmit = async () => {
		if (isSubmitting || !croppedImage) return;

		setIsSubmitting(true);

		if (croppedImage) {
			try {
				await onSubmit(croppedImage, inputMimeType!);
			} finally {
				setIsSubmitting(false);
			}
		}
	};

	return (
		<>
			<Modal.Body className='px-5'>
				<TextField
					label={inputLabel}
					informationText={`Image must be a JPEG or PNG file`}
					type='file'
					onChange={handleFileInputChange}
					style={{ height: '100%', padding: 0 }}
					isInvalid={fileValidations.length > 0}
					errorMessage={fileValidations.join('\n')}
					value=''
				/>
				{imageToCrop ? (
					<div className='d-flex justify-content-center align-items-center'>
						<ReactCrop
							aspect={1}
							crop={cropConfig}
							onChange={(_, percentCrop) => setCropConfig(percentCrop)}
							onComplete={c => setCompletedCrop(c)}
						>
							<img
								src={imageToCrop}
								ref={imgRef}
								style={{ maxHeight: 400 }}
								onLoad={onImageLoad}
								alt='crop preview'
							/>
						</ReactCrop>
					</div>
				) : (
					<></>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Row>
					{isInIndieUpload ? (
						<Col>
							<Button
								label={isImageSaved ? 'Done!' : 'Save Image'}
								theme={isImageSaved ? 'success' : 'dark'}
								onClick={() => onSubmit(croppedImage, inputMimeType!)}
								isDisabled={croppedImage === ''}
							/>
						</Col>
					) : (
						<Col>
							<Button label='Cancel' onClick={handleCancel} />
							<Button
								label='Continue'
								theme='dark'
								onClick={handleSubmit}
								isDisabled={croppedImage === ''}
								className='ml-2'
								isLoading={isSubmitting}
							/>
						</Col>
					)}
				</Row>
			</Modal.Footer>
		</>
	);
};

export default ExportImageInputModal;
