import { CreateErrorOptions, ValidationError } from 'yup';
import recordingSchema from '@/constants/recording.json';
import { uniqBy } from 'lodash';
/**
 *
 * @param yupErrorObj - The error object returned by yup when validating a form.
 * @returns An array with all the flattened yup errors
 */
export const flattenYupValidationErrors = (yupErrorObj: ValidationError) => {
	const errors = yupErrorObj.inner.reduce((acc, curr) => {
		if (curr.inner.length) {
			acc.push(...flattenYupValidationErrors(curr));
		} else {
			acc.push(curr);
		}

		return acc;
	}, [] as ValidationError[]);

	return errors;
};

export const getExportValidationErrors = (
	yupErrors: Record<Recording['id'], ValidationError[]> | string
): ExportValidationErrorsState =>
	typeof yupErrors === 'string'
		? { album: null, other: [{ message: yupErrors }], recordings: null }
		: Object.entries(yupErrors).reduce(
				(errorState, [recordingId, errors]) => {
					const _recordingId = parseInt(recordingId);

					errors.forEach(error => {
						if (
							error.path === 'participants' ||
							error.params?.participantIds ||
							error.params?.field === 'participants'
						) {
							const participantIds = error.params?.participantIds as
								| Participant['id'][]
								| null;

							// If there are no participantIds, it means the error is not specific to a participant.
							// So we append the error to the "other" array in the participants object.
							if (!participantIds) {
								errorState.recordings = {
									other: errorState.recordings?.other || null,
									byId: {
										...errorState.recordings?.byId,
										[recordingId]: {
											...errorState.recordings?.byId?.[_recordingId],
											participants: {
												...(errorState.recordings?.byId?.[_recordingId]
													?.participants || {
													byId: {},
												}),
												other: [
													...(errorState.recordings?.byId?.[_recordingId]
														?.participants?.other || []),
													error,
												],
											},
										},
									},
								};
							} else {
								// if there
								participantIds.forEach(participantId => {
									errorState.recordings = {
										// preserve any general errors
										other: errorState.recordings?.other || null,
										byId: {
											// preserve errors for other recordings
											...errorState.recordings?.byId,
											[recordingId]: {
												// preserve any general errors we may have already had for this recording (other field)
												...errorState.recordings?.byId?.[_recordingId],
												participants: {
													// preserve any errors we may have already had for this recording,
													// considering previous participants in this collection
													...errorState.recordings?.byId?.[_recordingId]
														?.participants,
													byId: {
														...errorState.recordings?.byId?.[_recordingId]
															?.participants?.byId,
														[participantId]: [
															// preserve any errors we may have already had for this participant?
															// (not sure if this is necessary, probably not)
															...(errorState.recordings?.byId?.[_recordingId]
																?.participants?.byId?.[participantId] || []),
															// add new errors
															error,
														],
													},
												},
											},
										},
									};
								});
							}
						} else if (error.path && error.path in recordingSchema) {
							// check for general errors within the recording
							// by comparing the error path to the recording schema fields
							errorState.recordings = {
								other: errorState.recordings?.other || null,
								byId: {
									...errorState.recordings?.byId,
									[recordingId]: {
										...errorState.recordings?.byId?.[_recordingId],
										other: [
											...(errorState.recordings?.byId?.[_recordingId]?.other ||
												[]),
											error,
										],
									},
								},
							};
						} else if (error.path?.includes('albums')) {
							// since the same album is repeated entirely for each recording
							// (in the albums field for each recording in the export payload)
							// the same validation error will be repeated for each recording.
							// So we need to make sure we remove duplicates.
							errorState.album = uniqBy(
								[...(errorState.album || []), error],
								'path'
							);
						} else {
							errorState.other = [...(errorState.other || []), error];
						}
					});

					return errorState;
				},
				{
					other: null,
					album: null,
					recordings: {},
				} as ExportValidationErrorsState
		  );

// This type is used for being able to tell if the export validation
// error is fixable either from the Participant modal or the Recording editor.
export type ExportValidationErrorParams = {
	recordingId: Recording['id'] | null;
	participantIds: Participant['id'][] | null;
	field: string | null;
};

export type ErrorMapValue<
	TestDataType = any,
	InnerTestedValueType = any,
	ParentDataType = any
> = {
	message: string;
	test: (value: InnerTestedValueType) => boolean;
	getValueForTesting: (originalData: TestDataType) => InnerTestedValueType;
	getErrorParams: (
		parentData: ParentDataType,
		invalidValue: InnerTestedValueType
	) => ExportValidationErrorParams;
	dependsOn?: string[];
};

// Returns Yup validation error object
export function validateExportObject<TestDataType = any, ParentDataType = any>({
	errorMap,
	originalData,
	parent,
	type,
	createError,
}: {
	errorMap: Record<string, ErrorMapValue<TestDataType, any, ParentDataType>>;
	originalData: TestDataType;
	parent: ParentDataType;
	type: string;
	createError: (params?: CreateErrorOptions) => ValidationError;
}) {
	// keep track of which fields failed validation
	const invalidValuesMap: Partial<Record<string, any>> = Object.keys(
		errorMap
	).reduce((acc, fieldPath) => {
		const { getValueForTesting } = errorMap[fieldPath];
		const value = getValueForTesting(originalData);
		const pass = errorMap[fieldPath].test(value);

		if (!pass) {
			acc[fieldPath] = value;
		}

		return acc;
	}, {} as Partial<Record<string, any>>)

	// remove any fields that depend on fields that failed validation
	Object.keys(errorMap).forEach(fieldPath => {
		const { dependsOn } = errorMap[fieldPath];

		if (dependsOn?.some(dep => dep in invalidValuesMap)) {
			delete invalidValuesMap[fieldPath];
		}
	});
	

	// create Yup validation error object
	const yupErrors = Object.entries(invalidValuesMap).map(([fieldPath, value]) =>
		createError({
			message: errorMap[fieldPath].message,
			params: errorMap[fieldPath].getErrorParams(parent, value),
			path: fieldPath,
			type: `${type}.${fieldPath}`,
		})
	);

	if (!yupErrors.length) {
		return true;
	}

	console.error('yupErrors', yupErrors);

	return new ValidationError(yupErrors, parent, undefined, type);
}

export interface ExportValidationError extends ValidationError {
	recordingId: Recording['id'] | null;
}

// export const flattenExportValidationErrors = (
// 	validationErrors: Record<Recording['id'], ValidationError[]>
// ) =>
// 	Object.entries(validationErrors)
// 		.map(([recordingId, errors]) => {
// 			return errors.map(error => {
// 				return {
// 					...error,
// 					recordingId: recordingId ?,
// 				};
// 			});
// 		})
// 		.flat();
