import { Form, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import styles from './SignUpStep.module.scss';
import TextField from '../../../form/TextField';
import Button from '../../../layout/Button';
import { useAppSelector } from '../../../../store/hooks';

export type VerifyEmailStepProps = {
	onSubmit: (values: VerifyEmailFormikValues) => Promise<void>;
	email?: string;
};

export type VerifyEmailFormikValues = {
	code: string;
};

const validationSchema = Yup.object({
	code: Yup.string().min(6, 'Code must be 6 characters').required('Required'),
});

const VerifyEmailStep = ({ onSubmit, email }: VerifyEmailStepProps) => {
	const { userEmail } = useAppSelector(state => state.auth);

	const formik = useFormik<VerifyEmailFormikValues>({
		initialValues: {
			code: '',
		},
		onSubmit: async (values, { setStatus }) => {
			setStatus(undefined);
			console.log('values', values);
			try {
				await onSubmit(values);
			} catch (error: any) {
				if (error.response.status === 401) {
					setStatus('Invalid code');
					return;
				}

				setStatus(error.message);
			}
		},
		validationSchema,
		validateOnMount: true,
	});

	return (
		<FormikProvider value={formik}>
			<Form
				placeholder={null}
				onKeyDown={e => {
					if (e.key === 'Enter' && !formik.isSubmitting) {
						e.preventDefault();
						formik.handleSubmit();
					}
				}}
			>
				<div className={styles['sign-up-step-container']}>
					<h2>Verify your email</h2>
					<p
						style={{
							textAlign: 'center',
						}}
					>
						We sent a verification code to <b>{email || userEmail}</b>. Please
						enter it below.
					</p>

					<div className={styles['form']}>
						<TextField
							name='code'
							label='Verification code'
							placeholder='XXXXXX'
							value={formik.values.code}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							errorMessage={formik.errors.code || formik.status}
							isInvalid={
								(formik.touched.code && !!formik.errors.code) || !!formik.status
							}
						/>

						<div className='my-3' />
						<Button
							label='Submit'
							onClick={() => formik.handleSubmit()}
							isDisabled={!formik.isValid}
							isLoading={formik.isSubmitting}
							theme='dark'
							size='lg'
						/>
					</div>
				</div>
			</Form>
		</FormikProvider>
	);
};

export default VerifyEmailStep;
