import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { hideModal, setModalTitle } from '../../../../store/modal/actions';
import * as yup from 'yup';
import Button from '../../../layout/Button';
import { shareFilesTempAction } from '../../../../store/files/actions';
import { Form, Formik } from 'formik';
import TextField from '../../../form/TextField';
import { useAppDispatch } from '../../../../store/hooks';

const schema = yup.object().shape({
	email: yup
		.string()
		.email('Invalid email address')
		.required('Email address is required'),
});

export type ShareFilesModalProps = {
	fileIds: FileMetadata['id'][];
	recordingId?: number | null;
	albumId?: number | null;
};

type ShareFilesModalForm = {
	email: string;
};

const ShareFilesModal = ({
	fileIds,
	recordingId = null,
	albumId = null,
}: ShareFilesModalProps) => {
	const dispatch = useAppDispatch();

	const [isSharing, setIsSharing] = useState(false);

	const handleCancel = () => {
		dispatch(hideModal());
	};

	const handleShare = (values: ShareFilesModalForm) => {
		setIsSharing(true);

		Promise.resolve(
			dispatch(
				shareFilesTempAction({
					fileIds,
					recipientEmail: values.email,
					recordingId,
					albumId,
				})
			)
		).then(() => {
			setIsSharing(false);
			dispatch(hideModal());
		});
	};

	useEffect(() => {
		dispatch(setModalTitle('SHARE FILES'));
	}, [dispatch]);

	return (
		<Formik<ShareFilesModalForm>
			initialValues={{ email: '' }}
			validationSchema={schema}
			onSubmit={values => handleShare(values)}
			validateOnMount
		>
			{formik => (
				<Form placeholder={null}>
					<Modal.Body className='px-5'>
						<TextField
							name='email'
							type='email'
							label='Recipient Email Address'
							value={formik?.values?.email}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							errorMessage={formik?.errors?.email}
							isInvalid={Boolean(
								formik?.touched?.email && formik?.errors?.email
							)}
							placeholder='Enter email address'
						/>
					</Modal.Body>
					<Modal.Footer>
						<Button label='Cancel' onClick={handleCancel} />
						<Button
							theme='dark'
							type='submit'
							label='Share'
							className='ml-2'
							isDisabled={!formik.isValid}
							isLoading={isSharing}
						/>
					</Modal.Footer>
				</Form>
			)}
		</Formik>
	);
};

export default ShareFilesModal;
