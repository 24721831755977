import { useCallback, useMemo, useRef } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import {
	CREATE_DRIP_MODAL,
	CREATE_PLAYLIST_FOLDER_MODAL,
	CREDIT_REQUESTS_MODAL,
	DELETE_MODAL,
	DRIP_DASHBOARD_MODAL,
	DUPLICATE_PLAYLIST_MODAL,
	EDIT_PLAYLIST_DETAILS_MODAL,
	PLAYLIST_COLLAB_MODAL,
	SELECT_PROJECT_FILES_MODAL,
	SEND_PLAYLIST_LINK_MODAL,
	SHARE_PLAYLIST_MODAL,
	TRANSFER_PLAYLIST_MODAL,
	UPLOAD_FILES_TO_PLAYLIST_MODAL,
	UPLOAD_VERSIONS_TO_PLAYLIST_MODAL,
} from '@/constants/modalTypes';
import ROUTES from '@/router/routes';
import { showModalAction } from '@/store/modal/actions';
import {
	addFilesToPlaylistAction,
	deletePlaylistAction,
	setCurrentPlaylistAction,
} from '@/store/playlists/actions';
import { getCurrentPlaylist } from '@/store/playlists/selectors';
import Button from '../../../../layout/Button';
import OverflowMenu from '../../../../layout/OverflowMenu';
import { replacePathVariables } from '@/helpers/routeTools';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { AppDispatch } from '@/store';
import { isPlaylistJuly2023PromoActive } from '@/helpers/promoTools';
import { Button as PrimeButton } from 'primereact/button';
import {
	setPlayerPlayingAction,
	setPlayerPlaylistAction,
} from '@/store/player/actions';
import { removeNonAudioFiles } from '@/helpers/audioTools';
import { Menu } from 'primereact/menu';
import {
	getPlaylistShareLink,
	getTrackChatShareLink,
	tryShareAction,
} from '@/helpers/playlistTools';
import { Badge } from 'react-bootstrap';

const getOverflowMenuOptions = (
	playlist: Playlist,
	navigate: NavigateFunction,
	dispatch: AppDispatch
) => [
	{
		name: 'Edit Details',
		leftIcon: 'fas fa-edit',
		onClick: () => {
			dispatch(
				showModalAction(EDIT_PLAYLIST_DETAILS_MODAL, {
					size: 'lg',
					playlistId: playlist?.id,
				})
			);
		},
	},
	{
		name: (
			<div className='d-flex align-items-center'>
				View Credit Requests
				{playlist?.playlist?.creditRequestCount ? (
					<Badge className='ml-2 credit-count-badge' pill>
						{playlist?.playlist?.creditRequestCount}
					</Badge>
				) : null}
			</div>
		),
		leftIcon: 'fas fa-id-card',
		onClick: () => {
			dispatch(
				showModalAction(CREDIT_REQUESTS_MODAL, {
					size: 'xl',
					playlistId: playlist?.id,
				})
			);
		},
	},
	{
		name: 'Create Empty Folder',
		leftIcon: 'fas fa-folder-plus',
		onClick: () =>
			dispatch(
				showModalAction(CREATE_PLAYLIST_FOLDER_MODAL, {
					size: 'md',
					playlistId: playlist?.id,
					fileIds: [],
					folderIds: [],
					parentGroupId: null,
				})
			),
	},
	{
		name: 'Duplicate',
		leftIcon: 'fas fa-copy',
		onClick: () => {
			return dispatch(
				showModalAction(DUPLICATE_PLAYLIST_MODAL, {
					size: 'md',
					playlistId: playlist?.id,
				})
			);
		},
	},
	{
		name: 'Send a Copy',
		leftIcon: 'fas fa-file-import',
		onClick: () => {
			return dispatch(
				showModalAction(TRANSFER_PLAYLIST_MODAL, {
					size: 'lg',
					playlistId: playlist?.id,
				})
			);
		},
	},
	{
		name: 'Add from Sound Credit Project',
		leftIcon: 'fas fa-plus',
		onClick: () => {
			dispatch(
				showModalAction(SELECT_PROJECT_FILES_MODAL, {
					onSubmit: async (files: FileMetadata[]) => {
						await dispatch(addFilesToPlaylistAction(playlist.id, files));
					},
					size: 'xl',
				})
			);
		},
	},
	{
		name: 'Delete',
		leftIcon: 'fas fa-trash',
		style: {
			color: 'red',
		},
		onClick: () => {
			dispatch(
				showModalAction(DELETE_MODAL, {
					title: 'DELETE PLAYLIST',
					body: (
						<>
							Are you sure you want to delete{' '}
							<i className='fw-600'>{playlist?.playlist?.name}</i>?
						</>
					),
					onDelete: async () => {
						navigate(ROUTES.Playlists.path);
						dispatch(setCurrentPlaylistAction(null));
						await Promise.resolve(dispatch(deletePlaylistAction(playlist?.id)));
					},
					size: 'md',
				})
			);
		},
	},
];

const PlaylistActions = () => {
	const dispatch = useAppDispatch();
	const { userId } = useAppSelector(state => state.auth);
	const { currentPlaylistId } = useAppSelector(state => state.playlists);
	const { activePromos, playlistJuly2023PromoTimerExpiration } = useAppSelector(
		state => state.promos
	);
	const { playing, playlistId: playerPlaylistId } = useAppSelector(
		state => state.player
	);
	const currentPlaylist = useAppSelector(getCurrentPlaylist);

	const uploadMenuRef = useRef<Menu>(null);
	const shareMenuRef = useRef<Menu>(null);
	const isDripActive = useMemo(
		() => currentPlaylist?.playlist?.isDrip,
		[currentPlaylist]
	);

	const dripEnded = useMemo(() => {
		if (!currentPlaylist?.playlist?.dripEnd) return false;

		const now = new Date();

		return new Date(currentPlaylist?.playlist?.dripEnd) < now;
	}, [currentPlaylist]);

	const isOwner = useMemo(
		() => currentPlaylist?.playlist?.userId === userId,
		[currentPlaylist, userId]
	);
	const isPlaylistPlaying = useMemo(
		() => playing && playerPlaylistId === currentPlaylist?.id,
		[playing, playerPlaylistId, currentPlaylist]
	);
	const disablePlayback = useMemo(
		() =>
			removeNonAudioFiles(currentPlaylist?.playlist?.files ?? []).length === 0,
		[currentPlaylist]
	);

	const showFlashyAddButton = useMemo(
		() =>
			currentPlaylist?.playlist?.files?.length === 0 &&
			currentPlaylist?.uploads?.length === 0 &&
			isOwner &&
			isPlaylistJuly2023PromoActive(
				activePromos,
				playlistJuly2023PromoTimerExpiration
			),
		[
			activePromos,
			playlistJuly2023PromoTimerExpiration,
			currentPlaylist,
			isOwner,
		]
	);
	const navigate = useNavigate();

	const handleUploadNewFiles = useCallback(() => {
		dispatch(
			showModalAction(UPLOAD_FILES_TO_PLAYLIST_MODAL, {
				size: 'xl',
				playlistId: currentPlaylistId,
			})
		);
	}, [currentPlaylistId, dispatch]);

	const handleUploadClick = useCallback(
		(event: React.MouseEvent) => {
			if (currentPlaylist?.playlist?.files?.length === 0) {
				handleUploadNewFiles();
				return;
			}

			uploadMenuRef.current?.toggle(event);
		},
		[currentPlaylist, handleUploadNewFiles]
	);

	const handleUploadVersions = useCallback(() => {
		dispatch(
			showModalAction(UPLOAD_VERSIONS_TO_PLAYLIST_MODAL, {
				size: 'xl',
				playlistId: currentPlaylistId,
			})
		);
	}, [currentPlaylistId, dispatch]);

	const handleShare = useCallback(() => {
		dispatch(
			showModalAction(SHARE_PLAYLIST_MODAL, {
				size: 'md',
				playlistId: currentPlaylistId,
			})
		);
	}, [currentPlaylistId, dispatch]);

	const handleStats = useCallback(() => {
		navigate(
			replacePathVariables(ROUTES.PlaylistStats.path, {
				playlistId: currentPlaylistId,
			})
		);
	}, [currentPlaylistId, navigate]);

	const handleCollab = useCallback(() => {
		dispatch(
			showModalAction(PLAYLIST_COLLAB_MODAL, {
				size: 'md',
				playlistId: currentPlaylistId,
			})
		);
	}, [currentPlaylistId, dispatch]);

	const handleSendViaEmail = useCallback(() => {
		dispatch(
			showModalAction(SEND_PLAYLIST_LINK_MODAL, {
				size: 'md',
				playlistId: currentPlaylistId,
			})
		);
	}, [currentPlaylistId, dispatch]);

	const overflowMenuOptions = useMemo(
		() =>
			getOverflowMenuOptions(currentPlaylist!, navigate, dispatch).filter(
				option => isOwner || option.name !== 'Delete'
			),
		[dispatch, currentPlaylist, navigate, isOwner]
	);

	return (
		<div className='d-flex  align-items-center'>
			<PrimeButton
				icon={isPlaylistPlaying ? 'fas fa-pause' : 'fas fa-play'}
				disabled={disablePlayback}
				tooltip={
					disablePlayback
						? 'In order to play a playlist, it must have at least one audio file.'
						: ''
				}
				tooltipOptions={{ showOnDisabled: true }}
				rounded
				style={{ backgroundColor: '#6639e4 !important' }}
				color='#6639e4'
				className='mr-2'
				onClick={() => {
					if (!currentPlaylistId) throw new Error('No playlist selected');

					if (currentPlaylistId === playerPlaylistId) {
						dispatch(setPlayerPlayingAction(!playing));
						return;
					}

					dispatch(setPlayerPlaylistAction({ playlistId: currentPlaylistId }));
				}}
			/>

			<Button
				label='Upload'
				leftIcon='fas fa-plus'
				className={`mr-2 ${
					showFlashyAddButton ? 'playlist-flashy-button' : ''
				}`}
				theme='dark'
				onClick={handleUploadClick}
			/>
			<Menu
				ref={uploadMenuRef}
				popup
				style={{
					width: '20rem',
				}}
				model={[
					{
						label: 'Upload New Files',
						icon: (
							<i
								className='fas fa-file-upload'
								style={{
									width: '1.25rem',
									fontWeight: 600,
								}}
							/>
						),
						command: handleUploadNewFiles,
					},
					{
						label: 'Upload New Versions for Existing Files',
						icon: (
							<i
								className='fas fa-history'
								style={{
									width: '1.25rem',
									fontWeight: 600,
								}}
							/>
						),
						command: handleUploadVersions,
					},
				]}
			/>

			<Menu
				ref={shareMenuRef}
				popup
				style={{
					width: '15rem',
				}}
				model={[
					{
						label: 'Collaborate',
						icon: (
							<i
								className='fas fa-users'
								style={{
									width: '1.5rem',
									fontWeight: 600,
								}}
							/>
						),
						command: handleCollab,
					},

					{
						label: 'Share Settings',
						icon: (
							<i
								className='fas fa-cog'
								style={{
									width: '1.5rem',
									fontWeight: 600,
								}}
							/>
						),
						command: handleShare,
					},
					{
						label: 'Playlist Link',
						items: [
							{
								label: 'Send via Email',
								icon: (
									<i
										className='fas fa-envelope'
										style={{
											width: '1.5rem',
											fontWeight: 600,
										}}
									/>
								),
								command: handleSendViaEmail,
							},
							{
								label: 'Copy Playlist Link',
								icon: (
									<i
										className='fas fa-link'
										style={{
											width: '1.5rem',
											fontWeight: 600,
										}}
									/>
								),
								command: () =>
									tryShareAction({
										action: () =>
											navigator.clipboard.writeText(
												getPlaylistShareLink(currentPlaylist!)
											),
										playlist: currentPlaylist!,
										dispatch,
									}),
							},
							{
								label: 'Open Playlist in Browser',
								icon: (
									<i
										className='fas fa-external-link-alt'
										style={{
											width: '1.5rem',
											fontWeight: 600,
										}}
									/>
								),
								command: () =>
									tryShareAction({
										action: () =>
											window.open(
												getPlaylistShareLink(currentPlaylist!),
												'_blank'
											),
										playlist: currentPlaylist!,
										dispatch,
									}),
							},
						],
					},
					{
						label: 'TrackChat Link',
						items: [
							{
								label: 'Copy TrackChat Link',
								icon: (
									<i
										className='fas fa-comment-alt'
										style={{
											width: '1.5rem',
											fontWeight: 600,
										}}
									/>
								),
								command: () =>
									tryShareAction({
										action: () =>
											navigator.clipboard.writeText(
												getTrackChatShareLink(currentPlaylist!)
											),
										playlist: currentPlaylist!,
										dispatch,
									}),
							},
							{
								label: 'Open TrackChat in Browser',
								icon: (
									<i
										className='fas fa-external-link-alt'
										style={{
											width: '1.5rem',
											fontWeight: 600,
										}}
									/>
								),
								command: () =>
									tryShareAction({
										action: () =>
											window.open(
												getTrackChatShareLink(currentPlaylist!),
												'_blank'
											),
										playlist: currentPlaylist!,
										dispatch,
									}),
							},
						],
					},
				]}
			/>

			{/* <Button
				label='Collaborate'
				leftIcon='fas fa-users'
				onClick={handleCollab}
				className='mr-2'
			/> */}

			<Button
				label='Stats'
				leftIcon='fas fa-chart-bar'
				onClick={handleStats}
				className='mr-2'
			/>

			{isDripActive ? (
				<Button
					style={{
						display: 'flex',
						alignItems: 'center',
					}}
					label={
						<span className='d-flex align-items-center'>
							Drip{' '}
							<strong className='ml-1'>
								{dripEnded ? 'COMPLETE' : 'LIVE!'}
							</strong>
							{dripEnded ? (
								<i
									className='fas fa-check ml-2'
									style={{ fontSize: '0.7rem' }}
								/>
							) : (
								<i
									className='fas fa-circle live-blinking ml-2'
									style={{ fontSize: '0.7rem' }}
								/>
							)}
						</span>
					}
					leftIcon='fas fa-tint'
					// theme='solid-light'
					onClick={() => {
						dispatch(
							showModalAction(DRIP_DASHBOARD_MODAL, {
								size: 'lg',
								playlistId: currentPlaylistId,
							})
						);
					}}
					className='mr-2'
				/>
			) : (
				<Button
					label='Publish Drip'
					leftIcon='fas fa-tint'
					// theme='solid-light'
					onClick={() => {
						dispatch(
							showModalAction(CREATE_DRIP_MODAL, {
								size: 'lg',
								playlistId: currentPlaylistId,
							})
						);
					}}
					className='mr-2'
				/>
			)}

			{/* <Button
				label='Drip'
				leftIcon='fas fa-tint'
				onClick={dispatch(
					showModalAction(CREATE_DRIP_MODAL, {
						size: 'lg',
						playlistId: currentPlaylistId,
					})
				);}
				className='mr-2'
			/> */}

			<Button
				label='Share'
				leftIcon='fas fa-share-alt'
				onClick={(e: React.MouseEvent) => shareMenuRef.current?.toggle(e)}
				className='mr-2 playlist-drip-button'
			/>

			<OverflowMenu
				vertical={false}
				options={overflowMenuOptions}
				id='playlist-actions'
				counter={currentPlaylist?.playlist?.creditRequestCount || undefined}
			/>
		</div>
	);
};

export default PlaylistActions;
