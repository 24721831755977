import { useLocation } from 'react-router-dom';
import './Header.scss';
import getSectionNameByPath from '../../utils/getSectionNameByPath';
import AlertToast from '../AlertToast';
import NotificationList from '../NotificationList';
import { useState, useMemo } from 'react';
import IconButton from '../IconButton';
import FileUploadMenu from '../FileUploadMenu';
import UPLOAD_STATUS from '../../../constants/uploadStatus.json';
import TierStatusChip from '../TierStatusChip';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { showModalAction } from '../../../store/modal/actions';
import { ACCOUNT_DETAILS_MODAL } from '../../../constants/modalTypes';
import PromoType from '../../../store/promos/promoType';
import PlaylistJuly2023PromoTimer from '../PlaylistJuly2023PromoTimer/PlaylistJuly2023PromoTimer';
import { isDateExpired } from '../../../helpers/dateTools';
import NavigationMenu from '../NavigationMenu';
import PillButton from '../PillButton';
import { isElectron } from 'react-device-detect';

function Header() {
	const location = getSectionNameByPath(useLocation().pathname);
	const { userProfile, notifications } = useAppSelector(state => state.user);
	const { uploadsQueueById } = useAppSelector(state => state.files);
	const {
		activePromos,
		extendedPlaylistJuly2023Promo,
		playlistJuly2023PromoTimerExpiration,
	} = useAppSelector(state => state.promos);
	const dispatch = useAppDispatch();
	const [showNotificationsMenu, setShowNotificationsMenu] = useState(false);
	const [showFilesMenu, setShowFilesMenu] = useState(false);

	const uploadsCount = useMemo(
		() =>
			Object.values(uploadsQueueById).filter(upload =>
				[UPLOAD_STATUS.IN_PROGRESS, UPLOAD_STATUS.READY_TO_UPLOAD].includes(
					upload.status
				)
			).length,
		[uploadsQueueById]
	);

	const isPromoTimerExpired = useMemo(
		() =>
			playlistJuly2023PromoTimerExpiration &&
			isDateExpired(new Date(playlistJuly2023PromoTimerExpiration)),
		[playlistJuly2023PromoTimerExpiration]
	);

	const unreadNotificationsCount = useMemo(
		() => notifications?.filter(item => !item.read).length,
		[notifications]
	);

	const toggleNotificationsMenu = () => {
		setShowNotificationsMenu(prevShow => !prevShow);
	};

	const toggleFilesMenu = () => {
		setShowFilesMenu(prevShow => !prevShow);
	};

	return (
		<div className='header-container'>
			<div
				className='d-flex justify-content-between navbar-white container-horizontal-padding py-3 header-content header-border'
				style={{ backgroundColor: '#0c0f1e', paddingTop: '21px !important' }}
			>
				<div className='d-flex align-items-center'>
					<NavigationMenu className='mr-2' />
					<h1 style={{ color: 'white' }}>{location}</h1>
				</div>

				<div className='d-flex align-items-center justify-content-end pr-0'>
					{activePromos[PromoType.PlaylistJuly2023] &&
						!(extendedPlaylistJuly2023Promo && isPromoTimerExpired) && (
							<PlaylistJuly2023PromoTimer className='mr-2 playlist-flashy-button' />
						)}
					<TierStatusChip className='mx-2' />
					{!isElectron && (
						<PillButton
							label='Install App'
							leftIcon='fas fa-download'
							className='mx-2'
							onClick={() => {
								window.open(
									process.env.REACT_APP_DOWNLOAD_DESKTOP_APP_URL,
									'_blank'
								);
							}}
							// theme='solid-light'
							theme='navbar-solid-light'
						/>
					)}
					<IconButton
						icon='fas fa-upload'
						color='white'
						counter={uploadsCount}
						onClick={() => {
							if (!showFilesMenu) toggleFilesMenu();
						}}
						tooltipText='Uploads'
					/>

					<FileUploadMenu
						show={showFilesMenu}
						toggleShow={() => toggleFilesMenu()}
					/>

					{/* Notifications button */}
					<IconButton
						icon='fas fa-bell'
						counter={unreadNotificationsCount}
						color='white'
						onClick={() => {
							if (!showNotificationsMenu) toggleNotificationsMenu();
						}}
						className='mx-2 pill-button-notification-solid-light'
						// style={{ backgroundColor: '$pink' }}
						tooltipText='Notifications'
					/>

					<NotificationList
						show={showNotificationsMenu}
						toggleShow={() => toggleNotificationsMenu()}
					/>

					{/* Account button */}
					<IconButton
						icon='fas fa-user-circle'
						iconSize='1.25rem'
						color='white'
						onClick={() =>
							dispatch(
								showModalAction(ACCOUNT_DETAILS_MODAL, {
									size: 'lg',
								})
							)
						}
						tooltipText='My Account'
					/>

					<p
						className={`mb-0 fw-bold greeting-message ${
							userProfile?.username ? 'show-greeting-message' : ''
						}`}
					>
						Welcome Back {userProfile?.username || ''}
					</p>
				</div>
			</div>
			<AlertToast />
		</div>
	);
}

export default Header;
