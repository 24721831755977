import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import Button from '../../../../../layout/Button';

export type UserDoesNotExistStepProps = {
	onSubmit: () => void;
	onBack: () => void;
};

const UserDoesNotExistStep = ({
	onSubmit,
	onBack,
}: UserDoesNotExistStepProps) => {
	return (
		<>
			<Modal.Body>
				<div className='px-4'>
					<Row>
						<Col xs={4}>
							<i className='fas fa-user-slash fa-6x d-flex justify-content-center align-items-center h-100'></i>
						</Col>

						<Col xs={8}>
							<h4 className='mb-2'>Uh oh! User not found</h4>
							<p className='mb-0' style={{ fontSize: '0.85rem' }}>
								The email address you're trying to transfer the playlist to is
								not linked to a Sound Credit account.
								<br />
								<br />
								However, they were still sent an email (and text if you provided
								a mobile number) a link to the playlist so they will still be
								able to listen immediately without an account, although they
								will not be able to add credits.
								<br />
								<br />
								Please try again once they've created an account if you still
								wish to invite the recipient user to the projects used in the
								playlist.
							</p>
						</Col>
					</Row>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button label='Back' onClick={onBack} className='mr-2' />
				<Button label='Dismiss' onClick={onSubmit} theme='dark' />
			</Modal.Footer>
		</>
	);
};

export default UserDoesNotExistStep;
