import { useEffect, useRef } from 'react';

const useTaskBeforeUnmount = (callback, ...data) => {
	const mounted = useRef(null);

	useEffect(() => {
		mounted.current = true;
		return () => {
			mounted.current = false;
		};
	}, []);

	useEffect(
		() => () => {
			if (!mounted.current) {
				callback(...data);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[callback, ...data]
	);
};

export default useTaskBeforeUnmount;
