import React, {
	forwardRef,
	useEffect,
	useImperativeHandle,
	useMemo,
} from 'react';
import { useExpanded, useRowSelect, useTable } from 'react-table';

const IndeterminateCheckbox = React.forwardRef(
	({ indeterminate, ...rest }, ref) => {
		const defaultRef = React.useRef();
		const resolvedRef = ref || defaultRef;

		React.useEffect(() => {
			resolvedRef.current.indeterminate = indeterminate;
		}, [resolvedRef, indeterminate]);

		return (
			<div className='checkbox-container'>
				<input type='checkbox' ref={resolvedRef} {...rest} />
			</div>
		);
	}
);

const checkboxColumnWidth = '5%';

const SelectableTable = forwardRef(
	({ columns, onRowSelect, tableRows, cellProps, className = '' }, ref) => {
		const {
			getTableProps,
			getTableBodyProps,
			headerGroups,
			rows,
			prepareRow,
			toggleAllRowsSelected,
			state: { selectedRowIds },
		} = useTable(
			{
				columns,
				data: tableRows,
				expandSubRows: false,
				autoResetExpanded: false,
				autoResetSelectedRows: false,
				autoResetHiddenColumns: false,
			},
			useExpanded,
			useRowSelect,
			hooks => {
				hooks.visibleColumns.push(columns => [
					{
						id: 'selection',

						Header: ({ getToggleAllRowsSelectedProps }) => (
							<div>
								<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
							</div>
						),
						Cell: ({ row }) => (
							<div>
								<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
							</div>
						),
					},
					...columns,
				]);
			}
		);

		useImperativeHandle(
			ref,
			() => {
				return {
					toggleAllRowsSelected,
				};
			},
			[toggleAllRowsSelected]
		);

		useEffect(() => {
			onRowSelect(selectedRowIds, rows);
		}, [selectedRowIds, onRowSelect, rows]);

		const columnWidths = useMemo(
			() => [checkboxColumnWidth, ...columns.map(column => column.width)],
			[columns]
		);

		return (
			<table className={`projects-table ${className}`} {...getTableProps()}>
				<colgroup>
					{columnWidths.map((width, index) => (
						<col
							style={{
								width,
							}}
							key={index}
							span={1}
						/>
					))}
				</colgroup>

				<thead className='projects-table-header'>
					{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map(column => (
								<th {...column.getHeaderProps()}>{column.render('Header')}</th>
							))}
						</tr>
					))}
				</thead>
				<tbody className='projects-table-body' {...getTableBodyProps()}>
					{rows.map(row => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()} className='projects-table-row'>
								{row.cells.map(cell => {
									return (
										<td
											className={`projects-table-cell`}
											{...cell.getCellProps()}
										>
											{cell.render('Cell', cellProps)}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	}
);

export default SelectableTable;
