import { MenuItem } from 'primereact/menuitem';
import {
	getUploadingVersionsForFile,
	isAudioFile,
	isMediaFile,
} from '../../../../../helpers/fileTools';
import { extractIdFromDndItemId, isFileGroup, isGroupDndId } from './utilities';
import { abortFileUploadAction } from '../../../../../store/files/actions';
import { showModalAction } from '../../../../../store/modal/actions';
import { AppDispatch } from '../../../../../store';
import {
	CREATE_PLAYLIST_FOLDER_MODAL,
	DELETE_MODAL,
	FILE_VERSION_LIST_MODAL,
	MANAGE_UPLOADING_VERSIONS_MODAL,
	RENAME_FILE_MODAL,
	RENAME_PLAYLIST_FOLDER_MODAL,
	UPLOAD_PREVIEW_MODAL,
} from '../../../../../constants/modalTypes';
import {
	getSingleFolderShareLink,
	getSingleFolderTrackChatShareLink,
	getSingleTrackChatShareLink,
	getSingleTrackShareLink,
} from '../../../../../helpers/playlistTools';
import { selectDownloadPathAndDownload } from '../../../../../helpers/downloadTools';
import {
	deletePlaylistFileAction,
	deletePlaylistFileGroupAction,
} from '../../../../../store/playlists/actions';
import { Dictionary } from 'lodash';
import { zipFilesAndFolders } from '@/helpers/folderDownloadTools';

export const getContextMenuOptions = ({
	selectedItemIds,
	contextMenuItemIdsRef,
	playlist,
	allFiles,
	groupIdToGroupContentIndex,
	selectedItemsParentGroupId,
	playlistUploads,
	dispatch,
	setIsDeletingGroupIds,
}: {
	selectedItemIds: string[];
	contextMenuItemIdsRef: React.MutableRefObject<string[]>;
	playlist: Playlist;
	allFiles: PlaylistFileMetadata[];
	groupIdToGroupContentIndex: GroupIdToGroupContentIndex;
	selectedItemsParentGroupId: PlaylistFileGroup['id'] | null;
	playlistUploads: Dictionary<ProjectFileUpload>;
	dispatch: AppDispatch;
	setIsDeletingGroupIds: React.Dispatch<
		React.SetStateAction<PlaylistFileGroup['id'][]>
	>;
}): MenuItem[] => {
	// only set the context menu file ids if there are selected file ids
	if (selectedItemIds.length) {
		contextMenuItemIdsRef.current = [...selectedItemIds];
	}

	const folderItemIds = contextMenuItemIdsRef.current
		.filter(isGroupDndId)
		.map(extractIdFromDndItemId);
	const fileItemIds = contextMenuItemIdsRef.current
		.filter(item => !isGroupDndId(item))
		.map(extractIdFromDndItemId);

	const contextMenuOptions: MenuItem[] = [];

	if (contextMenuItemIdsRef.current.length === 1) {
		const itemId = contextMenuItemIdsRef.current[0];
		const innerId = extractIdFromDndItemId(itemId);

		const selectedContextMenuItem = isGroupDndId(itemId)
			? groupIdToGroupContentIndex[innerId]
			: allFiles.find(file => file.id === innerId);

		if (!selectedContextMenuItem) {
			return contextMenuOptions;
		}

		// check if it's a folder or a file
		if (isFileGroup(selectedContextMenuItem)) {
			contextMenuOptions.push(
				{
					label: 'Edit Folder Name',
					icon: 'fas fa-edit',
					command: () => {
						dispatch(
							showModalAction(RENAME_PLAYLIST_FOLDER_MODAL, {
								playlistId: playlist?.playlist?.id,
								folderId: selectedContextMenuItem.id,
								initialName: selectedContextMenuItem.name,
								size: 'md',
							})
						);
					},
				},
				{
					label: 'Ungroup Folder',
					icon: 'fas fa-folder-open',
					command: async () => {
						console.log('ungroup folder');
						setIsDeletingGroupIds(prev => [
							...prev,
							selectedContextMenuItem.id,
						]);
						try {
							await dispatch(
								deletePlaylistFileGroupAction({
									playlistId: playlist?.id!,
									groupId: selectedContextMenuItem.id,
								})
							);
						} finally {
							setIsDeletingGroupIds(prev =>
								prev.filter(id => id !== selectedContextMenuItem.id)
							);
						}
					},
				},
				{
					label: 'Copy Link to Individual Folder',
					icon: 'fas fa-link',
					command: () => {
						navigator.clipboard.writeText(
							getSingleFolderShareLink(selectedContextMenuItem)
						);
					},
				},
				{
					label: 'Copy Link to Individual TrackChat Folder',
					icon: 'fas fa-comment-alt',
					command: () => {
						navigator.clipboard.writeText(
							getSingleFolderTrackChatShareLink(selectedContextMenuItem)
						);
					},
				},
				{
					label: 'Download Folder',
					icon: 'fas fa-download',
					command: () => {
						console.log('About to download folder');
						zipFilesAndFolders(selectedContextMenuItem, allFiles, dispatch);
					},
				}
				// TODO: ADD GROUP IN FOLDER OPTION
			);
		} else {
			const uploadingVersions = getUploadingVersionsForFile(
				selectedContextMenuItem.id,
				playlistUploads
			);

			// check for versions uploading
			if (
				uploadingVersions.length === 1 &&
				uploadingVersions[0].progress < 100
			) {
				contextMenuOptions.push({
					label: 'Cancel Upload',
					icon: 'fas fa-times',
					command: () =>
						dispatch(abortFileUploadAction(uploadingVersions[0].id)),
				});
			}

			if (uploadingVersions.length > 1) {
				contextMenuOptions.push({
					label: 'Manage Uploads For This File',
					icon: 'fas fa-upload',
					command: () =>
						dispatch(
							showModalAction(MANAGE_UPLOADING_VERSIONS_MODAL, {
								playlistId: playlist?.id,
								fileId: selectedContextMenuItem.id,
								size: 'md',
							})
						),
				});
			}

			contextMenuOptions.push(
				{
					label: 'Preview',
					icon: 'fas fa-eye',
					command: () => {
						dispatch(
							showModalAction(UPLOAD_PREVIEW_MODAL, {
								size: 'lg',
								hideHeader: true,
								fileId: selectedContextMenuItem.id,
								fullscreen: true,
							})
						);
					},
				},
				{
					label: 'Edit File Name',
					icon: 'fas fa-edit',
					command: () => {
						dispatch(
							showModalAction(RENAME_FILE_MODAL, {
								playlistId: playlist?.playlist?.id,
								fileId: selectedContextMenuItem.id,
								size: 'md',
							})
						);
					},
				},
				{
					label: 'Copy Link to Individual File',
					icon: 'fas fa-link',
					command: () => {
						navigator.clipboard.writeText(
							getSingleTrackShareLink(selectedContextMenuItem)
						);
					},
				},
				{
					disabled: !isMediaFile(selectedContextMenuItem.filename),
					label: 'Copy Link to Individual TrackChat File',
					icon: 'fas fa-comment-alt',
					command: () => {
						if (!isAudioFile(selectedContextMenuItem.filename)) return;

						navigator.clipboard.writeText(
							getSingleTrackChatShareLink(selectedContextMenuItem)
						);
					},
				},
				{
					label: 'Download Active Version',
					icon: 'fas fa-download',
					command: () => {
						selectDownloadPathAndDownload({
							file: selectedContextMenuItem,
							versionId: selectedContextMenuItem.activeVersion,
							dispatch,
						});
					},
				},
				{
					label: 'File Versions',
					icon: 'fas fa-history',
					command: () => {
						dispatch(
							showModalAction(FILE_VERSION_LIST_MODAL, {
								fileId: selectedContextMenuItem.id,
								playlistId: playlist?.id,
								size: 'xl',
							})
						);
					},
				},
				{
					label: 'Group in Folder',
					icon: 'fas fa-folder',
					command: e => {
						console.log('group files');
						dispatch(
							showModalAction(CREATE_PLAYLIST_FOLDER_MODAL, {
								fileIds: [selectedContextMenuItem.id],
								playlistId: playlist?.id!,
								size: 'md',
								parentGroupId: selectedItemsParentGroupId,
								folderIds: [],
							})
						);
					},
				},
				{
					label: 'Delete File From Playlist',
					icon: 'fas fa-trash',
					command: () => {
						dispatch(
							showModalAction(DELETE_MODAL, {
								title: 'DELETE FILE FROM PLAYLIST',
								body: (
									<>
										Are you sure you want to delete
										<i className='fw-600'>
											{' '}
											{selectedContextMenuItem.filename}{' '}
										</i>
										from <i className='fw-600'>{playlist?.playlist?.name}</i>?
									</>
								),
								onDelete: () =>
									dispatch(
										deletePlaylistFileAction(
											playlist?.id!,
											selectedContextMenuItem.id
										)
									),
								size: 'md',
							})
						);
					},
				}
			);
		}
	} else {
		// show group options
		contextMenuOptions.push({
			label: 'Group in Folder',
			icon: 'fas fa-folder',
			command: e => {
				//
				// e.originalEvent.stopPropagation();
				// e.originalEvent.preventDefault();
				// dispatch(
				// 	createPlaylistFileGroupAction({
				// 		playlistId: playlist?.id!,
				// 		fileIds: contextMenuFileIds.current,
				// 		name: 'New Group',
				// 	})
				// );
				console.log('group files');
				dispatch(
					showModalAction(CREATE_PLAYLIST_FOLDER_MODAL, {
						fileIds: fileItemIds,
						playlistId: playlist?.id!,
						size: 'md',
						parentGroupId: selectedItemsParentGroupId,
						folderIds: folderItemIds,
					})
				);
			},
		});
	}

	return contextMenuOptions;
};
