import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
	getExtensionFromFileMetadata,
	isAudioFile,
	isMediaFile,
	isFileMetadata,
	THUMBNAILED_FILE_TYPES,
} from '../../../../helpers/fileTools';
import { downloadImageThumbnailAction } from '../../../../store/files/actions';
import './FileThumbnail.scss';
import Image from '../../../layout/Image/Image';
import { useAppSelector } from '@/store/hooks';
import { getCurrentPlaylist } from '@/store/playlists/selectors';

const labelIcons = {
	0: 'fas fa-file',
	1000: 'fas fa-file-audio',
	2000: 'fas fa-file-image',
	3000: 'fas fa-file-video',
	4000: 'fas fa-file-alt',
	9000: 'fas fa-file',
};

export type FileThumbnailProps<FileType extends GenericFile> = {
	// file?: PlaylistTableFile | ExportMetadata | FileMetadata;
	file?: FileType;
	forceAudio?: boolean;
	onHoverContent?: React.ReactNode | null;
	onHoverClick?: (() => void) | React.MouseEventHandler<HTMLElement>;
	forceNonAudio?: boolean;
	className?: string;
	style?: React.CSSProperties;
	forceLabelValue?: number;
	forceIcon?: string;
	iconStyle?: React.CSSProperties;
	src?: string;
};

const FileThumbnail = <FileType extends GenericFile>({
	file,
	forceLabelValue,
	forceAudio = false,
	onHoverContent = null,
	onHoverClick,
	forceNonAudio = false,
	forceIcon,
	className = '',
	style = {},
	iconStyle = {},
}: FileThumbnailProps<FileType>) => {
	const dispatch = useDispatch();

	// to avoid refetching the same thumbnail
	const isLoading = useRef(false);
	const fetchedThumbnail = useRef(false);

	// to show add thumbnail button on hover
	const [hovered, setHovered] = useState(false);

	const fileExtension = useMemo(
		() => (file ? getExtensionFromFileMetadata(file) : ''),
		[file]
	);

	const _isAudioFile = useMemo(
		() => file && isAudioFile(file?.filename),
		[file]
	);

	const _isMediaFile = useMemo(
		() => file && isMediaFile(file?.filename),
		[file]
	);

	const _labelValue = forceLabelValue
		? forceLabelValue
		: file && isFileMetadata(file)
		? file?.label
		: 0;

	const currentPlaylist = useAppSelector(getCurrentPlaylist);

	useEffect(() => {
		if (
			file &&
			isFileMetadata(file) &&
			THUMBNAILED_FILE_TYPES.includes(getExtensionFromFileMetadata(file)) &&
			file.displayFile &&
			!file.thumbnail &&
			!isLoading.current &&
			!fetchedThumbnail.current
		) {
			isLoading.current = true;
			Promise.resolve(dispatch(downloadImageThumbnailAction(file))).finally(
				() => {
					fetchedThumbnail.current = true;
					isLoading.current = false;
				}
			);
		}
	}, [file, dispatch, isLoading, fetchedThumbnail]);

	const ICON_STYLE = useMemo(
		() => ({
			fontSize: '1.1rem',
			lineHeight: 0,
			...iconStyle,
		}),
		[iconStyle]
	);

	const renderThumbnail = useCallback(() => {
		if (forceIcon) {
			return <i className={forceIcon} style={ICON_STYLE} />;
		}

		if (forceNonAudio) {
			return <i className='fas fa-file' style={ICON_STYLE} />;
		}
		if (file && file?.coverUrl) {
			return (
				<Image
					className='h-100 w-100'
					style={{ objectFit: 'cover' }}
					src={file.coverUrl}
					alt='file cover'
					draggable={false}
				/>
			);
		} else if (file && !file.coverUrl && currentPlaylist?.playlist?.coverUrl) {
			return (
				<Image
					className='h-100 w-100'
					style={{ objectFit: 'cover' }}
					src={currentPlaylist?.playlist?.coverUrl}
					alt='file cover'
					draggable={false}
				/>
			);
		} else if (forceAudio) {
			return <i className='fas fa-music' style={ICON_STYLE} />;
		}
		switch (fileExtension) {
			case 'png':
			case 'jpg':
			case 'jpeg':
				if (_labelValue === 0 || _labelValue === 2000) {
					if (
						(file &&
							isFileMetadata(file) &&
							!file.thumbnail &&
							(_labelValue === 0 || _labelValue === 2000)) ||
						!file ||
						!isFileMetadata(file)
					) {
						return (
							<i
								className='fas fa-file-image'
								style={{
									...ICON_STYLE,
									fontSize: iconStyle.fontSize ?? '1.4rem',
								}}
							/>
						);
					}

					return (
						<Image
							className='h-100 w-100'
							style={{ objectFit: 'cover' }}
							src={file.thumbnail}
							alt='thumbnail'
							draggable={false}
						/>
					);
				}

			// intentional fallthrough, priorizing the selected label
			default:
				return (
					<i
						className={
							labelIcons[
								(Math.floor(_labelValue / 1000) *
									1000) as keyof typeof labelIcons
							]
						}
						style={{
							...ICON_STYLE,
							fontSize: iconStyle.fontSize ?? '1.4rem',
						}}
					/>
				);
		}
	}, [
		file,
		fileExtension,
		forceAudio,
		forceNonAudio,
		_labelValue,
		forceIcon,
		iconStyle,
		ICON_STYLE,
	]);

	return (
		<div
			className={`file-thumbnail-container ${
				THUMBNAILED_FILE_TYPES.includes(fileExtension) &&
				file &&
				isFileMetadata(file) &&
				file.thumbnail
					? ''
					: 'file-thumbnail-primary-border'
			} ${className}`}
			style={{ ...style }}
			onMouseEnter={() => setHovered(true)}
			onMouseLeave={() => setHovered(false)}
		>
			{
				// show add thumbnail button on hover (only for uploaded files, exports don't have a path)
				onHoverClick && hovered && !forceNonAudio && _isAudioFile && (
					<div className='file-thumbnail-hover-content' onClick={onHoverClick}>
						{onHoverContent || <i className='fas fa-pen' />}
					</div>
				)
			}
			{renderThumbnail()}
		</div>
	);
};

export default FileThumbnail;
