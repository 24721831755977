import { notificationApi } from '../notificationApi';
import { checkInvalidToken } from '../../helpers/checkInvalidToken';
import axiosRetry from 'axios-retry';
import { shouldRetry } from '../../helpers/networkTools';

axiosRetry(notificationApi, {
	retries: 3,
	retryDelay: retryCount => {
		return retryCount * 1000;
	},
	shouldResetTimeout: true,
	retryCondition: shouldRetry,
});

export const getNotifications = () =>
	notificationApi
		.get<{
			notifications: UserNotification[];
		}>('/notification')
		.then(res => res.data.notifications);

export const deleteNotification = (notificationId: string) =>
	notificationApi.delete('/notification', {
		params: {
			notificationId,
		},
	});

export const readNotification = (notificationId: string) =>
	notificationApi
		.put('/notification/read', {
			notificationIds: [notificationId],
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

export const getComments = (recordingId: number) =>
	notificationApi
		.get<{ comments: ProjectComment[] }>(`comment`, {
			params: {
				recordingId,
			},
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

export const addComment = ({
	recordingId,
	message,
}: {
	recordingId: number;
	message: string;
}) =>
	notificationApi
		.post<{
			comments: ProjectComment[];
		}>(`comment`, {
			recordingId,
			message,
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});
