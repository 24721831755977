import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import theme from '../../../theme.module.scss';

export type BooleanRadioInputProps = {
	label: string;
	name: string;
	trueLabel: string;
	falseLabel: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
	value: boolean;
	isDisabled?: boolean;
	informationText?: string;
};

const BooleanRadioInput = ({
	label,
	name,
	trueLabel,
	falseLabel,
	onChange,
	onBlur,
	value,
	informationText,
	isDisabled = false,
}: BooleanRadioInputProps) => {
	return (
		<Form.Group className='mb-4'>
			<Row className='pl-2'>
				<div>
					<Form.Label htmlFor=''>{label}</Form.Label>
				</div>
				<div className='radio-input-container mb-1'>
					<Form.Check
						inline
						disabled={isDisabled}
						label={trueLabel}
						type='radio'
						name={name}
						id={`${name}-true`}
						onChange={e =>
							onChange({
								...e,
								target: {
									...e.target,
									value: JSON.parse(e.target.value),
									name,
								},
							})
						}
						onBlur={onBlur}
						checked={value}
						className='mr-2'
						value='true'
					/>

					<Form.Check
						inline
						label={falseLabel}
						disabled={isDisabled}
						type='radio'
						name={name}
						id={`${name}-false`}
						checked={!value}
						onChange={e =>
							onChange({
								...e,
								target: {
									...e.target,
									value: JSON.parse(e.target.value),
									name,
								},
							})
						}
						onBlur={onBlur}
						value='false'
					/>
				</div>
			</Row>
			{informationText && (
				<Row>
					<Col xs={12}>
						<small
							style={{
								color: theme.disclaimerTextColor,
								fontStyle: 'italic',
							}}
						>
							{informationText}
						</small>
					</Col>
				</Row>
			)}
		</Form.Group>
	);
};

export default BooleanRadioInput;
