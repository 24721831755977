import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import {
	UPLOAD_FILES_TO_PLAYLIST_MODAL,
	UPLOAD_VERSIONS_TO_PLAYLIST_MODAL,
} from '../../../../../constants/modalTypes';
import {
	hideModal,
	setModalTitle,
	showModalAction,
} from '../../../../../store/modal/actions';
import './AddFilesToPlaylistModal.scss';
import { useAppDispatch } from '../../../../../store/hooks';

export type AddFilesToPlaylistModalProps = {
	playlistId: number;
	initialFiles?: File[];
};

const AddFilesToPlaylistModal = ({
	playlistId,
	initialFiles,
}: AddFilesToPlaylistModalProps) => {
	const dispatch = useAppDispatch();

	const handleUpload = () => {
		Promise.resolve(dispatch(hideModal())).then(() => {
			dispatch(
				showModalAction(UPLOAD_FILES_TO_PLAYLIST_MODAL, {
					playlistId,
					initialFiles,
					size: 'xl',
				})
			);
		});
	};

	const handleUploadVersions = () => {
		Promise.resolve(dispatch(hideModal())).then(() => {
			dispatch(
				showModalAction(UPLOAD_VERSIONS_TO_PLAYLIST_MODAL, {
					playlistId,
					initialFiles,
					size: 'xl',
				})
			);
		});
	};

	const renderOptionButton = (
		label: string,
		icon: string,
		onClick: () => void
	) => {
		return (
			<div
				className='add-files-to-playlist-modal__option-button'
				onClick={onClick}
			>
				<i
					className={`${icon} add-files-to-playlist-modal__option-button__icon`}
				></i>

				<p
					className={`
					add-files-to-playlist-modal__option-button__label
				`}
				>
					{label}
				</p>
			</div>
		);
	};

	useEffect(() => {
		dispatch(setModalTitle('WHAT WOULD YOU LIKE TO DO WITH THE DRAGGED FILE(S)?'));
	}, [dispatch]);

	return (
		<Modal.Body>
			<div className='d-flex align-items-center justify-content-between px-4 pb-4'>
				{renderOptionButton('Upload New Files', 'fas fa-upload', handleUpload)}
				{renderOptionButton(
					'Upload New Versions for Existing Files',
					'fas fa-history',
					handleUploadVersions
				)}
			</div>
		</Modal.Body>
	);
};

export default AddFilesToPlaylistModal;
