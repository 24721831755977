import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import {
	CLEAR_ACTIVE_EXPORT_FORM,
	CLEAR_SESSION_DATA,
	DELETE_EXPORT_FORM,
	SAVE_EXPORT_FORM,
	SET_ACTIVE_EXPORT_FORM,
	SAVE_SELECTED_COUNTRY,
	SET_VOLUME,
	SET_DETECT_TITLE_AND_ARTIST,
	SET_MUTE,
	SET_VISIBLE_PARTICIPANT_TABLE_COLUMNS,
} from '../actionTypes';
import { AnyAction } from 'redux';
import { ParticipantTableColumn } from '../../helpers/participantTools';

type SessionState = {
	savedExportFormsById: Record<string, PersistedExportFormData>;
	activeExportFormId: string | null;
	savedCountry: string;
	volume: number; // 0 - 100
	muted: boolean;
	detectTitleAndArtistByDefault: boolean;
	useFilenameAsTitle: boolean;
	visibleParticipantTableColumns: ParticipantTableColumn[];
};

const initialState = {
	savedExportFormsById: {},
	activeExportFormId: null,
	savedCountry: 'United States of America',
	volume: 100,
	muted: false,
	detectTitleAndArtistByDefault: true,
	useFilenameAsTitle: false,
	visibleParticipantTableColumns: [],
} as SessionState;

const reducer = (state = initialState, action: AnyAction) => {
	switch (action.type) {
		case SAVE_EXPORT_FORM:
			if (action.isUpdating && !state.savedExportFormsById[action.id])
				return state;

			console.log('SAVING EXPORT', action);
			return {
				...state,
				savedExportFormsById: {
					...state.savedExportFormsById,
					[action.id]: {
						id: action.id,
						recordingId: action.recordingId,
						albumId: action.albumId,
						exportType: action.exportType,
						form: action.form,
					},
				},
			};
		case DELETE_EXPORT_FORM:
			const newExportForms = { ...state.savedExportFormsById };
			delete newExportForms[action.id];

			return {
				...state,
				savedExportFormsById: newExportForms,
			};
		case SET_ACTIVE_EXPORT_FORM:
			console.log('SET ACTIVE EXPORT FORM', action);

			return {
				...state,
				activeExportFormId: action.id,
			};
		case CLEAR_ACTIVE_EXPORT_FORM:
			console.log('CLEAR ACTIVE EXPORT FORM');

			return {
				...state,
				activeExportFormId: null,
			};
		case SAVE_SELECTED_COUNTRY:
			return {
				...state,
				savedCountry: action.country,
			};
		case SET_VOLUME:
			return {
				...state,
				volume: action.volume,
			};
		case SET_MUTE:
			return {
				...state,
				muted: !state.muted,
			};
		case CLEAR_SESSION_DATA:
			return {
				...initialState,
			};
		case SET_DETECT_TITLE_AND_ARTIST:
			return {
				...state,
				detectTitleAndArtistByDefault: action.detectTitleAndArtist,
			};
		case SET_VISIBLE_PARTICIPANT_TABLE_COLUMNS:
			return {
				...state,
				visibleParticipantTableColumns: action.columns,
			};
		default:
			return state;
	}
};

const persistConfig = {
	key: 'session',
	storage,
	stateReconciler: autoMergeLevel2,
};

export default persistReducer<SessionState>(persistConfig, reducer);
