import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../../../../store/modal/actions';
import DescriptionContainer from '../../../../layout/DescriptionContainer';
import { getAssetPath } from '@/helpers/assetTools';

const WhatIsADripModal = () => {
	const dispatch = useDispatch();

	return (
		<>
			<Modal.Body>
				<DescriptionContainer
					image={getAssetPath('img/drip-1.png')}
					title='What is a Drip?'
					description={
						<p>
							A Drip is a powerful tool for artists and labels to engage with
							their fans and test the popularity of new music.
							<br />
							<br />
							By releasing a limited number of streams through social media,
							they can gather data on how the music is received and which songs
							are most popular. This valuable feedback helps inform their
							decisions before releasing the music to a wider audience.
							<br />
							<br />
							Sharing the Drip links on social media also provides an exclusive
							and valuable experience for fans, strengthening the connection
							between the artist and their audience.
						</p>
					}
					buttonLabel='Got it!'
					onButtonClick={() => dispatch(hideModal())}
				/>
			</Modal.Body>
		</>
	);
};

export default WhatIsADripModal;
