const helpVideos = {
	TRANSFER_OVERVIEW_VIDEO:
		'https://player.vimeo.com/video/864964689?h=7ae46b64fb&title=0&byline=0&portrait=0',
	ADDING_PROJECTS_VIDEO:
		'https://player.vimeo.com/video/865717257?h=d32fd8cfc5&title=0&byline=0&portrait=0',
	ADDING_PLAYLISTS_VIDEO:
		'https://player.vimeo.com/video/865717178?h=d32fd8cfc5&title=0&byline=0&portrait=0',
	ABOUT_DRIPS_VIDEO:
		'https://player.vimeo.com/video/865717093?h=d32fd8cfc5&title=0&byline=0&portrait=0',
	DRIP_STATS_VIDEO:
		'https://player.vimeo.com/video/865717018?h=d32fd8cfc5&title=0&byline=0&portrait=0',
};

export default helpVideos;
