import blankRecording from '../constants/unionForm/sagAftraRecording.json';
import blankParticipant from '../constants/unionForm/sagAftraParticipant.json';
import blankSagAftraForm from '../constants/unionForm/sagAftraForm.json';
import { getTrackNumberById } from './albumTools';
import { findParticipantRecordingReferences } from './unionFormTools';

export const createSagAftraForm = (
	selectedRecordings,
	album,
	studioForm,
	completeParticipantsForm,
	finalizeForm
) => {
	const [sagAftraRecordings, recordingIdToReferenceMap] =
		convertRecordingsToSagAftra(selectedRecordings, album);

	const sagAftraParticipants = convertParticipantsToSagAftra(
		completeParticipantsForm.participants,
		selectedRecordings,
		recordingIdToReferenceMap
	);

	return {
		...blankSagAftraForm.sag_form,
		artist_name: album ? album.artistName : selectedRecordings[0].mainArtist,
		release_name: album ? album.title : selectedRecordings[0].title,
		job_number: finalizeForm.jobNumber,
		record_label: '',
		record_co: finalizeForm.recordCompanyName ?? '',
		record_co_address: finalizeForm.recordCompanyAddress,
		record_co_city: '',
		record_co_state: '',
		record_co_post_code: '',
		recording_studio: studioForm.studio.label,
		recording_date: studioForm.recordingDate,
		producer: finalizeForm.sessionProducer.label,
		producer_address: '',
		producer_phone: '',
		recording_type: finalizeForm.recordingType,
		recordings: sagAftraRecordings,
		session_times: finalizeForm.sessionTimes.map(sessionTime => ({
			start_time: sessionTime.startTime,
			end_time: sessionTime.endTime,
			recording_ids: sessionTime.recordingIds.map(
				id => recordingIdToReferenceMap[id]
			),
		})),
		professionals: sagAftraParticipants,
		hours_of_employment: finalizeForm.sessionTimes
			.map(sessionTime => `${sessionTime.startTime} - ${sessionTime.endTime}`)
			.join('; '),
	};
};

export const convertRecordingsToSagAftra = (recordings, album) => {
	const recordingIdToReferenceMap = {};

	const sagAftraRecordings = recordings.map((recording, index) => {
		const sagAftraRecording = {
			...blankRecording,
			title: recording.title,
			track_number: (album
				? getTrackNumberById(album.recordings, recording.id)
				: 1
			).toString(),
			num_minutes: recording.duration,
			recording_reference: index,
		};

		recordingIdToReferenceMap[recording.id] =
			sagAftraRecording.recording_reference;

		return sagAftraRecording;
	});

	return [sagAftraRecordings, recordingIdToReferenceMap];
};

export const convertParticipantsToSagAftra = (
	participants,
	recordings,
	recordingIdToReferenceMap
) =>
	participants.map(participant => ({
		...blankParticipant,
		name: participant.legalName
			? participant.legalName
			: participant.creditedName,
		address: participant.address1,
		city: participant.city,
		state: participant.state,
		post_code: participant.postalCode ?? null,
		ssn:
			participant.socialLastFour?.length === 4
				? `XXX-XX-${participant.socialLastFour}`
				: participant.socialLastFour?.toString(), // if a full SSN is entered, then we use that
		recording_ids: findParticipantRecordingReferences(
			participant,
			recordings,
			recordingIdToReferenceMap
		),
	}));
