import ExportType from '../../../../constants/exportType';
import ExportId from '../../../../constants/exportsIds';
import { getTrackNumberById } from '../../../../helpers/albumTools';
import { replacePathVariables } from '../../../../helpers/routeTools';
import { AppDispatch } from '../../../../store';
import {
	setExportParamsAction,
	setExportPayloadAction,
} from '../../../../store/exports/actions';

const setSingleExportPayload = ({
	dispatch,
	exportType,
	recordingId,
	recording,
	album,
}: {
	dispatch: AppDispatch;
	exportType:
		| ExportType.LABEL_STYLE_SINGLE
		| ExportType.SOUND_CREDIT_SINGLE
		| ExportType.LYRIC_SHEET
		| ExportType.LABEL_STYLE_PERSONNEL_SPREADSHEET
		| ExportType.BIG_MACHINE_SINGLE;
	recordingId: Recording['id'];
	recording: Recording;
	album: Album | null;
}) => {
	dispatch(
		setExportPayloadAction(
			{
				report_type: ExportId[exportType],
				recording_id: recordingId,
				recordings: [
					{
						...recording?.recording,
						albums: album?.album ? [album.album] : null,
					},
				],
				album_id: null,
				is_final_version: true,
			},
			exportType
		)
	);
};

const setSplitSheetExportPayload = ({
	exportType,
	recording,
	album,
	dispatch,
	producerAgreementShare,
	skipGenerate,
	routeAfterValidation,
	hasPreview,
	nextParams,
	createOnValidation,
}: {
	exportType:
		| ExportType.SPLIT_SHEET_STANDARD
		| ExportType.SPLIT_SHEET_SINGLE_WRITER
		| ExportType.PRODUCER_AGREEMENT
		| ExportType.SIDE_ARTIST_AGREEMENT;
	recording: Recording;
	album: Album | null;
	dispatch: AppDispatch;
	producerAgreementShare?: number;
	skipGenerate: boolean;
	routeAfterValidation: string | null;
	hasPreview: boolean;
	nextParams: any;
	createOnValidation: boolean;
}) => {
	dispatch(
		setExportPayloadAction(
			{
				report_type: ExportId[exportType],
				recording_id: recording.id,
				recordings: [
					{
						...recording?.recording,
						albums: album?.album ? [album.album] : null,
					},
				],
				album_id: null,
				is_final_version: false,
				// if removed, the backend lambda fails, even if it's only
				// needed for the SPLIT_SHEET_SINGLE_WRITER export
				lead_writer_name: '',
				producer_share:
					exportType === ExportType.PRODUCER_AGREEMENT
						? producerAgreementShare
						: undefined,
			},
			exportType
		)
	);

	// const nextExportParams = exportData.getNextExportParams
	// 	? exportData.getNextExportParams(navigate, dispatch)
	// 	: null;

	dispatch(
		setExportParamsAction({
			// * this export needs selecting lead writer before generating
			// * therefore we skip the call to the export generation
			// * logic in the validation screen
			skipGenerate: Boolean(skipGenerate),
			nextRoute: replacePathVariables(routeAfterValidation!, {
				recordingId: recording.id,
			}),
			createOnValidation,
			hasPreview: hasPreview,
			nextExportParams: nextParams,
		})
	);
};

const setPublishingLabelCopyExportPayload = ({
	exportType,
	recordings,
	currentRecordingId,
	album,
	dispatch,
	routeAfterValidation,
	hasPreview,
	createOnValidation,
}: {
	exportType: ExportType.PUBLISHING_LABEL_COPY_DOCUMENT;
	recordings: Recording[];
	currentRecordingId: Recording['id'];
	album: Album | null;
	dispatch: AppDispatch;
	producerAgreementShare?: number;
	routeAfterValidation: string | null;
	hasPreview: boolean;
	createOnValidation: boolean;
}) => {
	dispatch(
		setExportPayloadAction(
			{
				report_type: ExportId[exportType],
				recording_id: album ? null : currentRecordingId,
				album_id: album ? album.id : null,
				recordings: recordings.map((recording, index) => ({
					...recording.recording,
					albums: album
						? [
								{
									...album.album,
									trackNumber: getTrackNumberById(
										album!.recordings,
										recording.id
									),
								},
						  ]
						: null,
				})),
				is_final_version: false,
			},
			exportType
		)
	);

	dispatch(
		setExportParamsAction({
			nextRoute: replacePathVariables(routeAfterValidation!, {
				recordingId: currentRecordingId,
			}),
			createOnValidation: createOnValidation,
			hasPreview: hasPreview,
			nextExportParams: null,
		})
	);
};

const setReleaseExportPayload = ({
	dispatch,
	exportType,
	recordings,
	currentRecordingId,
	album,
}: {
	dispatch: AppDispatch;
	exportType:
		| ExportType.SOUND_CREDIT_RELEASE
		| ExportType.LABEL_STYLE_RELEASE_REGULAR
		| ExportType.LABEL_STYLE_RELEASE_LONG
		| ExportType.BIG_MACHINE_ALBUM
		| ExportType.CD_INSERT_LONG
		| ExportType.CD_INSERT_SHORT
		| ExportType.VINYL_SLEEVE_LONG
		| ExportType.VINYL_SLEEVE_SHORT
		| ExportType.DIGITAL_DISTRIBUTION;
	recordings: Recording[];
	currentRecordingId: Recording['id'];
	album: Album | null;
}) => {
	dispatch(
		setExportPayloadAction(
			{
				report_type: ExportId[exportType],
				recording_id: album ? null : currentRecordingId,
				album_id: album ? album.id : null,
				recordings: recordings.map((recording, index) => ({
					...recording.recording,
					albums: album
						? [
								{
									...album.album,
									trackNumber: getTrackNumberById(
										album.recordings,
										recording.id
									),
								},
						  ]
						: null,
				})),
				is_final_version: true,
			},
			exportType
		)
	);
};

const setSpreadSheetExportPayload = ({
	dispatch,
	recordings,
	currentRecordingId,
	album,
	exportType,
}: {
	dispatch: AppDispatch;
	recordings: Recording[];
	currentRecordingId: Recording['id'];
	album: Album | null;
	exportType:
		| ExportType.THE_ORCHARD_SPREADSHEET
		| ExportType.ALL_MUSIC_SPREADSHEET
		| ExportType.METADATA_MASTERING
		| ExportType.PUBLISHING_LABEL_COPY_SPREADSHEET;
}) => {
	dispatch(
		setExportPayloadAction(
			{
				report_type: ExportId[exportType],
				recording_id: !album ? currentRecordingId : null,
				album_id: album ? album.id : null,
				recordings: album
					? recordings.map((recording, index) => ({
							...recording.recording,
							albums: [
								{
									...album!.album,
									trackNumber: getTrackNumberById(
										album!.recordings,
										recording.id
									),
								},
							],
					  }))
					: recordings.map(recording => recording.recording),
				is_final_version: true,
			},
			exportType
		)
	);
};

const setSelectRecordingsExportPayload = ({
	dispatch,
	recordings,
	currentRecordingId,
	album,
	exportType,
}: {
	dispatch: AppDispatch;
	recordings: Recording[];
	currentRecordingId: Recording['id'];
	album: Album | null;
	exportType:
		| ExportType.SOUND_EXCHANGE_SPREADSHEET
		| ExportType.MLC_SPREADSHEET
		| ExportType.PPL_REGISTRATION
		| ExportType.EBR_STANDARD_TEMPLATE;
}) => {
	dispatch(
		setExportPayloadAction(
			{
				report_type: ExportId[exportType],
				recording_id: album ? null : currentRecordingId,
				album_id: album ? album.id : null,
				recordings: recordings.map((recording, index) => ({
					...recording.recording,
					recordLabel:
						exportType === ExportType.PPL_REGISTRATION
							? album?.album?.recordLabel ?? ''
							: '',
					releaseDate:
						exportType === ExportType.PPL_REGISTRATION
							? album?.album?.releaseDate ?? ''
							: '',
					albums: album
						? [
								{
									...album.album,
									trackNumber: getTrackNumberById(
										album.recordings,
										recording.id
									),
								},
						  ]
						: null,
				})),
				is_final_version: true,
			},
			exportType
		)
	);
};

const setSocialExportPayload = ({
	dispatch,
	exportType,
	recording,
	album,
	croppedImage,
	mimeType,
}: {
	dispatch: AppDispatch;
	exportType:
		| ExportType.FACEBOOK_TWITTER_IMAGE
		| ExportType.STORIES
		| ExportType.INSTAGRAM_IMAGE;
	recording: Recording;
	album: Album | null;
	croppedImage: string;
	mimeType: string;
}) => {
	dispatch(
		setExportPayloadAction(
			{
				report_type: ExportId[exportType],
				recording_id: recording.id,
				recordings: [
					{
						...recording?.recording,
						album: album?.album ? [album.album] : null,
					},
				],
				album_id: album?.id ?? null,
				album_cover_image: croppedImage,
				album_cover_mime_type: mimeType,
				is_final_version: true,
			},
			exportType
		)
	);
};

const setUnionFormExportPayload = ({
	dispatch,
	exportType,
	recordings,
	currentRecordingId,
	album,
}: {
	dispatch: AppDispatch;
	exportType: ExportType.AFM_B4 | ExportType.AFM_B9 | ExportType.SAG_AFTRA;
	recordings: Recording[];
	currentRecordingId?: Recording['id'] | null;
	album: Album | null;
}) => {
	if (!album && !currentRecordingId) {
		throw new Error('Missing album or recording id');
	}

	dispatch(
		setExportPayloadAction(
			{
				report_type: ExportId[exportType],
				recording_id: album ? null : currentRecordingId,
				album_id: album ? album.id : null,
				recordings: recordings.map((recording, index) => ({
					...recording.recording,
					albums: album
						? [
								{
									...album.album,
									trackNumber: getTrackNumberById(
										album.recordings,
										recording.id
									),
								},
						  ]
						: null,
				})),
				is_final_version: true,
			},
			exportType
		)
	);
};

export const ExportPayloadSetters = {
	setSingleExportPayload,
	setSplitSheetExportPayload,
	setPublishingLabelCopyExportPayload,
	setReleaseExportPayload,
	setSpreadSheetExportPayload,
	setSelectRecordingsExportPayload,
	setSocialExportPayload,
	setUnionFormExportPayload,
};
