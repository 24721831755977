import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { matchSorter } from 'match-sorter';
import { uniqBy } from 'lodash';
import emptyRecording from '../../../constants/recording.json';

import {
	Field,
	ErrorMessage,
	FormikProvider,
	Form,
	useFormikContext,
} from 'formik';
import CreatableSelect from '../../form/CreatableSelect';
import Button from '../../layout/Button';
import ToggleInput from '../../layout/ToggleInput';
import { generateMoods } from '../../../api/services/moodsService';
import { explicitLyrics } from '../../../api/services/explicitService';

import { showModalAction } from '../../../store/modal/actions';

import { VALIDATE_MOODS_GENERATOR } from '../../../constants/modalTypes';
import ROUTES from '../../../router/routes';

import genreOptions from '../../../constants/genreOptions.json';

import './Lyrics.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Helmet } from 'react-helmet';
import { getCurrentRecording } from '../../../store/projects/selectors';

function Lyrics({
	onSave,
}: {
	onSave: (
		formikValues: RecordingContent,
		isFormikDirty: boolean,
		validateFormik: (values?: any) => Promise<any>
	) => void;
}) {
	const currentRecording = useAppSelector(getCurrentRecording);
	const { currentRecordingId, myEditorProfile } = useAppSelector(
		state => state.projects
	);
	const { userPermissions } = useAppSelector(state => state.auth);
	const navigate = useNavigate();
	const formik = useFormikContext<RecordingContent>();
	const [genres, setGenres] = useState([
		...genreOptions.options,
		{
			label: formik.values.genre,
			value: formik.values.genre,
		},
	]);
	const [saved, setSaved] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [moodOptions, setMoodOptions] = useState<
		{
			value: string;
			label: string;
		}[]
	>(
		uniqBy(
			[
				{ value: 'Happy', label: 'Happy' },
				{ value: 'Sad', label: 'Sad' },
				{ value: 'Angry', label: 'Angry' },
				{ value: 'Energetic', label: 'Energetic' },
				{ value: 'Chill', label: 'Chill' },
				{ value: 'Romantic', label: 'Romantic' },
				{ value: 'Somber', label: 'Somber' },
				...formik?.values?.moods?.map(m => ({ value: m, label: m })),
			],
			'value'
		)
	);

	const dispatch = useAppDispatch();

	const isReadOnly = useMemo(
		() => Boolean(myEditorProfile && myEditorProfile.is_read_only),
		[myEditorProfile]
	);

	const canDetectMood = useMemo(
		() =>
			userPermissions?.canDetectMood &&
			formik?.values?.genre &&
			formik?.values?.lyrics,
		[userPermissions, formik]
	);

	useEffect(() => {
		if (!currentRecordingId) {
			navigate(ROUTES.Projects.path);
		}
	}, [currentRecordingId, navigate]);

	const handleGenerateMoods = async () => {
		try {
			const payloadGenre = genres.find(
				obj => formik.values.genre === obj.value
			);

			if (!payloadGenre) {
				throw new Error('Genre not found');
			}

			const payload = {
				...emptyRecording,
				genre: payloadGenre.value,
				tempo: formik.values.tempo,
				title: formik.values.title,
				lyrics: formik.values.lyrics,
				mainArtist: formik.values.mainArtist,
			} as RecordingContent;

			const explicitPayload = {
				title: formik.values.title,
				mainArtist: formik.values.mainArtist,
				genre: payloadGenre.value,
				lyrics: formik.values.lyrics,
			};

			setIsLoading(true);

			// const data = {}
			const { data } = await generateMoods(payload);
			const { data: explicitData } = await explicitLyrics(explicitPayload);
			const moods: string[] = data.mood_list?.moods;
			const newMoods = moods.map(m => ({ value: m, label: m }));

			formik.setFieldValue('moods', [...moods]);
			formik.setFieldValue('explicit', explicitData.explicit);

			// console.log('MOODS GENERATED!', newMoods);
			setMoodOptions(prev => uniqBy([...prev, ...newMoods], 'value'));
			setIsLoading(false);
		} catch (e: any) {
			console.log({ e });
			setIsLoading(false);
			dispatch(
				showModalAction(VALIDATE_MOODS_GENERATOR, {
					size: 'md',
					errorList: [e?.response?.data?.message],
				})
			);
		}
	};

	const handleSyncLyrics = async () => {
		// dispatch(showModalAction(UPLOAD_FILES_MODAL, { size: 'md' }));
		// const lyrics = formik.values.lyrics.split('\n');
		navigate(`../${ROUTES.SyncedLyrics.relativePath}`);
	};

	return (
		<>
			<Helmet>
				<title>
					{currentRecording?.title ?? ''} - Lyrics{' '}
					{process.env.REACT_APP_TAB_TITLE}
				</title>
			</Helmet>
			<Container>
				<div className='pt-3' />
				<Row>
					<Col>
						<Card>
							<Card.Body>
								<h2>LYRICS</h2>
								<p className='fs-14'>
									<b className='mr-4'>Enter lyrics as sung.</b> Listen to the
									full recording and enter lyrics exactly as recorded.
								</p>
								<p className='fs-14'>
									<b className='mr-4'>Type repeated lines.</b> Type out every
									line. Do not use expressions like "repeat x4".
								</p>
								<p className='fs-14 mb-4'>
									<b className='mr-4'>Use empty line to separate actions.</b> Do
									not label sections as chorus or verse. Separate sections with
									empty line.
								</p>
								<FormikProvider value={formik}>
									<Form placeholder={null}>
										<Row>
											<Col xs={12} className='d-flex justify-content-start'>
												<ToggleInput
													label='Explicit Content'
													name='explicit'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													checked={formik.values.explicit}
													value={formik.values.explicit}
													isDisabled={isReadOnly}
												/>
												<ErrorMessage
													name='explicit'
													component='div'
													className='invalid-feedback'
												/>
											</Col>
										</Row>
										<div className='my-3' />
										<p className='fs-14 mb-0'>
											<b className='mr-4'>Review Apple lyrics guidelines.</b>
										</p>
										<p className='fs-14'>
											Use asterisks for censored content, omit spoken word
											unless it is part of the songwriting, and put background
											vocals in parenthesis.
										</p>
										<Row>
											<Col xs={5}>
												<CreatableSelect
													label='Genre'
													name='genre'
													id='genre'
													options={genres}
													placeholder='Search...'
													value={genres.find(
														obj => formik.values.genre === obj.value
													)}
													onCreateOption={value => {
														const newOption = { value: value, label: value };
														formik.setFieldValue('genre', value);
														setGenres([...genreOptions.options, newOption]);
													}}
													onInputChange={input => {
														if (!input) return;
														setGenres(
															matchSorter(genreOptions.options, input, {
																keys: ['value', 'label'],
															})
														);
													}}
													onChange={option =>
														formik.setFieldValue('genre', option?.value ?? '')
													}
													isDisabled={isReadOnly || isLoading}
													isSearchable
												/>
											</Col>

											<Col xs={7}>
												<Row>
													<Col xs={9}>
														<CreatableSelect<
															{ label: string; value: string },
															true
														>
															label='Moods'
															isMulti
															name='moods'
															id='moods'
															options={moodOptions}
															placeholder='Search...'
															value={moodOptions.filter(
																obj =>
																	formik.values.moods &&
																	formik.values.moods.includes(obj.value)
															)}
															onCreateOption={value => {
																let newOption = { value: value, label: value };
																setMoodOptions(prev => [...prev, newOption]);
																formik.setFieldValue(
																	'moods',
																	formik.values.moods.concat(value)
																);
															}}
															onChange={option =>
																formik.setFieldValue(
																	'moods',
																	option.map((element: any) => element.value)
																)
															}
															errorFieldName='moods'
															isDisabled={isReadOnly || isLoading}
															isSearchable={true}
															className='mood-select'
														/>
													</Col>
													<Col
														xs={3}
														className='d-flex align-items-center justify-content-center pt-1'
													>
														<Button
															label='Mood GPT'
															onClick={handleGenerateMoods}
															className='w-100'
															isDisabled={
																isLoading || isReadOnly || !canDetectMood
															}
															isLoading={isLoading}
															isLocked={!userPermissions?.canDetectMood}
															tooltipText={
																!canDetectMood
																	? 'Please add lyrics and genre to detect moods'
																	: ''
															}
														/>
													</Col>
												</Row>
											</Col>
										</Row>
										<Row>
											<Col xs={12}>
												<Field
													name='lyrics'
													as='textarea'
													className={
														'form-control ' +
														(formik.errors.lyrics && formik.touched.lyrics
															? ' is-invalid'
															: '')
													}
													rows='12'
													disabled={isReadOnly || isLoading}
												/>
												<ErrorMessage
													name='lyrics'
													component='div'
													className='invalid-feedback'
												/>
											</Col>
										</Row>

										<div className='my-3' />

										<Col xs={{ span: 6, offset: 6 }} className='text-right'>
											{/* <Button
												label='Sync Lyrics'
												className='mr-2'
												onClick={() => {
													handleSyncLyrics();
												}}
												isDisabled={!formik.values.lyrics}
											/> */}
											<Button
												label={saved ? 'Saved!' : 'Save & Proceed'}
												theme={saved ? 'success' : 'dark'}
												onClick={() => {
													onSave(
														formik.values,
														formik.dirty,
														formik.validateForm
													);
													setSaved(true);
													setTimeout(() => {
														setSaved(false);
													}, 2000);
												}}
												isDisabled={isReadOnly}
											/>
										</Col>
									</Form>
								</FormikProvider>
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		</>
	);
}

export default Lyrics;
