import ActionsCell from '../ActionsCell';
import { Spinner } from 'react-bootstrap';
import './ProjectsTable.scss';
import { useDispatch } from 'react-redux';
import AdministratorColumn from './AdministratorColumn';
import FilesColumn from './FilesColumn';
import React, { useMemo } from 'react';
import openShareModal from '../../../../helpers/openShareModal';
import ProjectTypeIcon from '../../../layout/ProjectTypeIcon';

// TODO: Migrate to TS

const projectsColumns = [
	{
		id: 'releaseType',
		Header: () => <></>,
		Cell: ({ row }) => <ProjectTypeIcon isAlbum={row.canExpand} />,
	},
	{
		id: 'expander',
		Header: () => <></>,
		// accessor: 'albumId',
		Cell: ({ row }) =>
			// Use the row.canExpand and row.getToggleRowExpandedProps prop getter
			// to build the toggle for expanding a row
			row.canExpand ? (
				<i
					className={`text-purple fas ${
						row.isExpanded ? 'fa-chevron-down' : 'fa-chevron-up'
					}`}
					{...row.getToggleRowExpandedProps()}
				/>
			) : null,
	},
	{
		Header: () => <span className='Project-Name'>Title</span>,
		accessor: 'title',
		Cell: col => <span className='Name1'>{col.value}</span>,
	},
	{
		Header: () => <span className='Project-Name'>Artist Name</span>,
		accessor: 'artist',
		Cell: col => <span className='Name1'>{col.value}</span>,
	},
	{
		Header: () => <span className='Project-Name'>Files</span>,
		accessor: 'assetsCount',
		Cell: ({ value, row }) => <FilesColumn fileCount={value} row={row} />,
	},
	{
		Header: () => <span className='Project-Name'>Date Created</span>,
		accessor: 'createdAt',
		Cell: col => {
			const date = useMemo(
				() => (col.value ? new Date(col.value) : null),
				[col.value]
			);

			return (
				<span className='Name1'>
					{col.value ? (
						date.toLocaleDateString()
					) : (
						<Spinner size='sm' animation='border' />
					)}
				</span>
			);
		},
	},
	{
		Header: () => <span className='Project-Name'>Administrator</span>,
		accessor: 'owner',
		Cell: col => {
			const dispatch = useDispatch();

			const adminData = useMemo(() => ({ ...col.value }), [col.value]);

			return (
				<AdministratorColumn
					onClick={() => openShareModal(col, dispatch)}
					adminData={adminData}
					row={col.row}
				/>
			);
		},
	},
	{
		Header: () => <span className='Project-Name'>Actions</span>,
		accessor: 'actions',
		Cell: col => <ActionsCell cell={col} />,
	},
];

export default projectsColumns;
