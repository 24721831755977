import { FieldArray } from 'formik';
import React, { useCallback, useContext } from 'react';
import PlaylistInputFileItem from './PlaylistInputFileItem';
import PlaylistFileUploadContext, {
	PlaylistFileUploadContextType,
} from '../../UploadFilesToPlaylist/PlaylistFileUploadContext';

export type PlaylistInputFileListProps = {
	className?: string;
};

const PlaylistInputFileList = ({
	className = '',
	...props
}: PlaylistInputFileListProps) => {
	const { inputFiles, setInputFiles, inputTimestamp } =
		useContext<PlaylistFileUploadContextType | null>(
			PlaylistFileUploadContext
		) as PlaylistFileUploadContextType;

	const handleDelete = useCallback(
		(index: number) => {
			setInputFiles(prevFiles => {
				const a = [...prevFiles];
				a.splice(index, 1);

				return a;
			});
		},
		[setInputFiles]
	);

	const renderInputFiles = useCallback(() => {
		if (!inputFiles?.length) return null;

		return (
			<FieldArray name='files'>
				{({ remove }) =>
					inputFiles?.map((file, index) => (
						<div
							key={`${file.name}-${inputTimestamp.valueOf()}
					`}
						>
							<PlaylistInputFileItem
								key={`${file.name}-${inputTimestamp.valueOf()}`}
								index={index}
								onDelete={handleDelete}
								file={file}
								formikRemove={remove}
							/>
							{index !== inputFiles.length - 1 && <hr className='m-0' />}
						</div>
					))
				}
			</FieldArray>
		);
	}, [inputFiles, handleDelete, inputTimestamp]);

	return (
		<div className={`w-100 input-file-list ${className}`} {...props}>
			{renderInputFiles()}
		</div>
	);
};

export default PlaylistInputFileList;
