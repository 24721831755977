import React, { useState, useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import BasicTable from '../../../../layout/BasicTable';
import Lottie from 'react-lottie';
import checkmarkAnimation from '../../../../../assets/animations/blackLoaderOnLightGrey.json';
import { getUserIsrcCodes } from '../../../../../api/services/codesService';

const defaultOptions = {
	loop: true,
	autoplay: true,
	animationData: checkmarkAnimation,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice',
	},
};

const GeneratedISRCCodes = () => {
	const [rows, setRows] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		fetchIsrcCodes();
	}, []);

	const fetchIsrcCodes = async () => {
		try {
			setIsLoading(true);
			const isrcCodes = await getUserIsrcCodes();

			const newData = isrcCodes
				.map(codeData => ({
					artistName: codeData.mainArtist,
					songVersion: codeData.version,
					isrcCode: codeData.identifier,
					dateGenerated: codeData.createdAt,
					songName: codeData.title,
				}))
				.sort((a, b) => b.dateGenerated - a.dateGenerated);

			setRows(newData);
		} catch (e) {
			console.log(e);
		} finally {
			setIsLoading(false);
		}
	};

	const columns = [
		{
			Header: () => <span className='Project-Name'>Date Generated</span>,
			accessor: 'dateGenerated',
			Cell: col => {
				const date = useMemo(() => new Date(col.value), [col.value]);
				return <span className='Name1'>{date?.toLocaleDateString()}</span>;
			},
		},
		{
			Header: () => <span className='Project-Name'>Main Artist Name</span>,
			accessor: 'artistName',
			Cell: col => <span className='Name1'>{col.value}</span>,
		},
		{
			Header: () => <span className='Project-Name'>Song Title</span>,
			accessor: 'songName',
			Cell: col => <span className='Name1'>{col.value}</span>,
		},
		{
			Header: () => <span className='Project-Name'>Song Version</span>,
			accessor: 'songVersion',
			Cell: col => <span className='Name1'>{col.value || '____'}</span>,
		},
		{
			Header: () => <span className='Project-Name'>ISRC Code</span>,
			accessor: 'isrcCode',
			Cell: col => <span className='Name1'>{col.value}</span>,
		},
	];

	if (isLoading) {
		return (
			<Row>
				<Col>
					<div style={{ overflowX: 'auto' }}>
						<Lottie
							options={defaultOptions}
							isClickToPauseDisabled
							height={150}
							width={150}
							style={{ cursor: 'default' }}
						/>
					</div>
				</Col>
			</Row>
		);
	}

	return (
		<Row>
			<Col>
				<div style={{ overflowX: 'auto' }}>
					<BasicTable hideCheckboxes columns={columns} data={rows} />
				</div>
			</Col>
		</Row>
	);
};

export default GeneratedISRCCodes;
