import _ from 'lodash';
import {
	editPersonalProfile,
	getLocalProfileById,
	getLocalProfiles,
	createLocalProfile,
} from '../../api/services/localProfilesService';
import {
	FETCH_FULL_PROFILE_REQUEST,
	FETCH_USER_PROFILES_REQUEST,
} from '../../constants/requestLabelTypes';
import generateRandomId from '../../helpers/generateRandomId';
import { isProfileEqual } from '../../helpers/participantTools';
import {
	createProfileFromFullProfile,
	translateApiFullPersonalProfileToLocal,
	translateApiPersonalProfileToLocal,
	translateLocalFullPersonalProfileToApi,
} from '../../helpers/profileTools';
import { mergeProfileAssociatedPublishersAndRecordingPublishers } from '../../helpers/publisherTools';
import {
	mergeParticipantAndAssociatedRoles,
	isStudioEqual,
	mergeParticipantAndAssociatedStudios as mergeProfileAndRecordingStudios,
} from '../../helpers/studioTools';
import {
	ADD_PROFILES,
	EDIT_PROFILE,
	DELETE_PROFILE,
	SET_PROFILES_SEARCH_FILTER,
	CLEAR_PROFILES,
	START_PROFILE_REQUEST,
	PROFILE_REQUEST_SUCCESS,
	PROFILE_REQUEST_ERROR,
	PROFILE_REQUEST_AUTH_ERROR,
} from '../actionTypes';
import { showErrorAlert } from '../alertToast/actions';
import { invalidTokenAction } from '../auth/actions';
import {
	updateCloudRecordingAction,
} from '../projects/actions';
import { AppDispatch, GetState } from '..';

const _handleProfileError = (
	error: any,
	dispatch: AppDispatch,
	message: string
) => {
	if (error.response) {
		switch (error.response.status) {
			case 401:
				dispatch(profileRequestAuthErrorAction());
				break;
			default:
				const errorMessage =
					message ??
					'Hiccup detected while syncing profiles with the cloud. Please try again.';
				dispatch(showErrorAlert(errorMessage));
				dispatch(profileRequestErrorAction(errorMessage));
		}
	} else {
		throw error;
	}
};

export const startProfileRequestAction = (requestLabel: string) => ({
	type: START_PROFILE_REQUEST,
	requestLabel,
});

export const profileRequestSuccessAction = () => ({
	type: PROFILE_REQUEST_SUCCESS,
});

export const profileRequestErrorAction = (errorMessage: string) => ({
	type: PROFILE_REQUEST_ERROR,
	errorMessage,
});

export const profileRequestAuthErrorAction = () => (dispatch: AppDispatch) => {
	dispatch(invalidTokenAction());

	return {
		type: PROFILE_REQUEST_AUTH_ERROR,
	};
};

export const addProfiles = (profiles: LocalProfile[]) => ({
	type: ADD_PROFILES,
	profiles,
});

export const editProfile = (profile: LocalProfile) => ({
	type: EDIT_PROFILE,
	profile,
});

export const deleteProfile = (profile: LocalProfile) => ({
	type: DELETE_PROFILE,
	profile,
});

export const setProfilesSearchFilter = (searchFilter: string) => ({
	type: SET_PROFILES_SEARCH_FILTER,
	searchFilter,
});

export const clearProfilesAction = () => ({
	type: CLEAR_PROFILES,
});

export const createLocalProfileAction =
	(
		profile: NewFullLocalProfile,
		onSave?: (newProfile: LocalProfile) => void,
		onError?: (err: any) => void
	) =>
	(dispatch: AppDispatch) => {
		const translatedProfile = translateLocalFullPersonalProfileToApi(profile);

		return createLocalProfile(translatedProfile)
			.then(res => {
				const profileId = res.data.profileId;

				const newProfile = createProfileFromFullProfile({
					...profile,
					id: profileId,
				});

				// Save the profile to local cache
				dispatch(addProfiles([newProfile]));

				// Update database with new UUID retrieved from the API response
				dispatch(editLocalProfileAction(newProfile));

				if (onSave) {
					onSave(newProfile);
				}
			})
			.catch(err => {
				if (onError) {
					onError(err);
				}

				_handleProfileError(
					err,
					dispatch,
					'Whoops! Hiccup detected while saving the profile.'
				);
			});
	};

export const fetchUserProfilesAction = () => (dispatch: AppDispatch) => {
	dispatch(startProfileRequestAction(FETCH_USER_PROFILES_REQUEST));

	return getLocalProfiles()
		.then(res => {
			dispatch(profileRequestSuccessAction());

			const { profiles } = res.data;

			const translatedProfiles = profiles?.map(p =>
				translateApiPersonalProfileToLocal(p)
			);

			dispatch(addProfiles(translatedProfiles));
		})
		.catch(e => {
			_handleProfileError(
				e,
				dispatch,
				'Whoops! Hiccup detected while fetching profiles.'
			);
		});
};

export const fetchLocalProfileAction =
	(profileId: string) => async (dispatch: AppDispatch) => {
		dispatch(startProfileRequestAction(FETCH_FULL_PROFILE_REQUEST));

		try {
			const res = await getLocalProfileById(profileId);

			dispatch(profileRequestSuccessAction());

			const profile = res.data;

			const translatedProfile = translateApiFullPersonalProfileToLocal(
				profile,
				profileId
			);

			dispatch(editProfile(translatedProfile));
		} catch (err) {
			_handleProfileError(
				err,
				dispatch,
				'Whoops! Hiccup detected while fetching the profile.'
			);
		}
	};

export const editLocalProfileAction =
	(profile: LocalProfile) => (dispatch: AppDispatch) => {
		// edit cached profile
		const translatedProfile = translateLocalFullPersonalProfileToApi(
			profile.profile!
		);
		return editPersonalProfile(translatedProfile)
			.then(res => {
				dispatch(editProfile(profile));
				dispatch(profileRequestSuccessAction());
			})
			.catch(err => {
				_handleProfileError(
					err,
					dispatch,
					'Whoops! Hiccup detected while saving profile changes.'
				);
			});
	};

const _importPersonalProfilesIntoRecording = (
	profiles: FullLocalProfile[],
	destRecording: Recording
): RecordingContent => {
	if (!destRecording.recording) {
		throw new Error('Recording is not initialized');
	}

	let newProfiles = profiles.filter(
		profile =>
			!destRecording.recording?.participants.find(
				(
					participant // remove duplicates
				) => isProfileEqual(profile, participant)
			)
	);

	// extract publishers from selected profiles
	// add reference to profile id to compute merging of duplicated publishers
	// profile.publishers will be overwritten with the new IDs of the newly created publishers
	const newProfileAssociatedPublishers = newProfiles.flatMap(p =>
		p.associatedPublishers!.map(ap => ({
			...ap,
			profileId: p.id,
			oldPublisherId: ap.id,
		}))
	);

	// merge new associated publishers with existing publishers in the recording by recomputing their IDs
	let newRecordingPublishers =
		mergeProfileAssociatedPublishersAndRecordingPublishers(
			destRecording.recording?.publishers,
			newProfileAssociatedPublishers
		);

	// for each profile, replace the publishers in profile.publishers with the new IDs of the newly created publishers
	newRecordingPublishers.forEach(newPublisher => {
		if (!newPublisher.profileId) {
			return;
		}

		const profile = newProfiles.find(p => p.id === newPublisher.profileId);

		if (!profile) {
			throw new Error('Profile not found');
		}

		profile.publishers = profile!.publishers.map(p => {
			if (p.publisherId === newPublisher.oldPublisherId) {
				return {
					...p,
					publisherId: newPublisher.id,
				};
			}

			if (p.subPublishers) {
				p.subPublishers = p.subPublishers.map(sp => {
					if (sp.publisherId === newPublisher.oldPublisherId) {
						return {
							...sp,
							publisherId: newPublisher.id,
						};
					}

					return sp;
				});
			}

			return p;
		});
	});

	// delete the profileId property from the publishers
	newRecordingPublishers = newRecordingPublishers.map(
		({ profileId, oldPublisherId, ...p }) => p
	);

	// extract studios from selected profiles
	const newProfileStudios = _.uniqWith(
		newProfiles.flatMap(profile => profile.associatedStudios ?? []),
		isStudioEqual
	);

	// merge studios from selected profiles with existing studios by recomputing IDs
	const newRecordingStudios = mergeProfileAndRecordingStudios(
		destRecording.recording.studios,
		newProfileStudios
	);

	// overwrite the profile roles with the new studio IDs
	newProfiles = newProfiles.map(profile => ({
		...profile,
		roles: mergeParticipantAndAssociatedRoles(
			newRecordingStudios,
			profile.associatedStudios,
			profile.roles
		),
	}));

	// Finally, remove the associatedPublishers and associatedStudios from the participants, as they are only used in profiles
	const newRecordingParticipants = newProfiles.map(
		({ associatedPublishers, associatedStudios, ...p }) =>
			({
				...p,
				id: generateRandomId(),
				participationCountry: '',
				participationDate: '',
				copyrightOwnerClaim: {
					cmo: p.copyrightOwnerClaim.cmo,
					cmoId: p.copyrightOwnerClaim.cmoId,
					splitPercentage: null,
					territoryOwnership: '',
				},
				publishers: p.publishers.map(p => ({
					...p,
					splitPercentage: null,
					subPublishers: p.subPublishers?.map(sp => ({
						...sp,
						splitPercentage: null,
					})),
				})),
			} as Participant)
	);

	// Update the recording's publishers, studios, and participants
	return {
		...destRecording.recording,
		publishers: newRecordingPublishers,
		studios: newRecordingStudios,
		participants: [
			...destRecording.recording.participants,
			...newRecordingParticipants,
		],
	};
};

export const importLocalProfilesIntoRecordingAction =
	(profiles: LocalProfile[], recordingId: number, fetchProfiles = true) =>
	async (dispatch: AppDispatch, getState: GetState) => {
		const destRecording = getState().projects.recordingsById?.[recordingId];
		if (!destRecording) {
			throw new Error('Recording not found');
		}

		let fullProfiles = profiles;

		if (fetchProfiles) {
			const fullProfilesPromises = profiles.map(async p => {
				console.log('BEFORE FETCH', p);
				await Promise.resolve(dispatch(fetchLocalProfileAction(p.id)));
				const fetchedProfile = getState().profiles.profiles?.find(
					p2 => p2.id === p.id
				);

				if (!fetchedProfile) {
					throw new Error('Profile not found');
				}

				console.log('AFTER FETCH', fetchedProfile);
				// TODO: Test if store is updated before returning
				return fetchedProfile;
			});

			fullProfiles = await Promise.all(fullProfilesPromises);
		} else {
			if (fullProfiles.some(p => !p))
				throw new Error('Error: importing profile without fetching it first');
		}

		const updatedRecording = _importPersonalProfilesIntoRecording(
			fullProfiles.map(p => p.profile!),
			destRecording
		);

		await dispatch(
			updateCloudRecordingAction({ recordingForm: updatedRecording })
		);
	};

export const assignIsniToProfileAction =
	(profileId: string, isni: string) =>
	async (dispatch: AppDispatch, getState: GetState) => {
		await dispatch(fetchLocalProfileAction(profileId));
		const profile = getState().profiles.profiles?.find(p => p.id === profileId);
		if (!profile) {
			throw new Error('Profile not found');
		}

		const updatedProfile = {
			...profile,
			profile: {
				...profile.profile!,
				isni,
			},
		};

		await dispatch(editLocalProfileAction(updatedProfile));
	};
