const countries = [
  {
    "TIS_N": "2136",
    "Exists from": "01.01.1000",
    "Exists until": "31/12/3999",
    "Type": "GLG",
    "Remark": "",
    "TIS_A": "2WL",
    "TIS_A_Ext": "",
    "Name from": "01.01.1000",
    "Name until": "31/12/3999",
    "Name": "WORLD",
    "Official name": "",
    "Abbreviated name": "WORLD",
    "Unofficial name": ""
  },
  {
      "TIS_N": "4",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "AF",
      "TIS_A_Ext": "AFG",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "AFGHANISTAN",
      "Official name": "",
      "Abbreviated name": "AFGHANISTAN",
      "Unofficial name": ""
  },
  {
      "TIS_N": "8",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "AL",
      "TIS_A_Ext": "ALB",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "ALBANIA",
      "Official name": "REPUBLIC OF ALBANIA",
      "Abbreviated name": "ALBANIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "12",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "DZ",
      "TIS_A_Ext": "DZA",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "ALGERIA",
      "Official name": "PEOPLE'S DEMOCRATIC REPUBLIC OF ALGERIA",
      "Abbreviated name": "ALGERIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "20",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "AD",
      "TIS_A_Ext": "AND",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "ANDORRA",
      "Official name": "PRINCIPALITY OF ANDORRA",
      "Abbreviated name": "ANDORRA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "24",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "AO",
      "TIS_A_Ext": "AGO",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "ANGOLA",
      "Official name": "REPUBLIC OF ANGOLA",
      "Abbreviated name": "ANGOLA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "28",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "AG",
      "TIS_A_Ext": "ATG",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "ANTIGUA AND BARBUDA",
      "Official name": "",
      "Abbreviated name": "ANTIGUA+BARBUDA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "31",
      "Exists from": "1/1/1992",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "AZ",
      "TIS_A_Ext": "AZE",
      "Name from": "1/1/1992",
      "Name until": "31/12/3999",
      "Name": "AZERBAIJAN",
      "Official name": "REPUBLIC OF AZERBAIJAN",
      "Abbreviated name": "AZERBAIJAN",
      "Unofficial name": ""
  },
  {
      "TIS_N": "32",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "AR",
      "TIS_A_Ext": "ARG",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "ARGENTINA",
      "Official name": "ARGENTINE REPUBLIC",
      "Abbreviated name": "ARGENTINA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "36",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "AU",
      "TIS_A_Ext": "AUS",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "AUSTRALIA",
      "Official name": "",
      "Abbreviated name": "AUSTRALIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "40",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "AT",
      "TIS_A_Ext": "AUT",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "AUSTRIA",
      "Official name": "REPUBLIC OF AUSTRIA",
      "Abbreviated name": "AUSTRIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "44",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "BS",
      "TIS_A_Ext": "BHS",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "BAHAMAS",
      "Official name": "COMMONWEALTH OF THE BAHAMAS",
      "Abbreviated name": "BAHAMAS",
      "Unofficial name": ""
  },
  {
      "TIS_N": "48",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "BH",
      "TIS_A_Ext": "BHR",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "BAHRAIN",
      "Official name": "KINGDOM OF BAHRAIN",
      "Abbreviated name": "BAHRAIN",
      "Unofficial name": ""
  },
  {
      "TIS_N": "50",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "BD",
      "TIS_A_Ext": "BGD",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "BANGLADESH",
      "Official name": "PEOPLE'S REPUBLIC OF BANGLADESH",
      "Abbreviated name": "BANGLADESH",
      "Unofficial name": ""
  },
  {
      "TIS_N": "51",
      "Exists from": "1/1/1992",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "AM",
      "TIS_A_Ext": "ARM",
      "Name from": "1/1/1992",
      "Name until": "31/12/3999",
      "Name": "ARMENIA",
      "Official name": "REPUBLIC OF ARMENIA",
      "Abbreviated name": "ARMENIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "52",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "BB",
      "TIS_A_Ext": "BRB",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "BARBADOS",
      "Official name": "",
      "Abbreviated name": "BARBADOS",
      "Unofficial name": ""
  },
  {
      "TIS_N": "56",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "BE",
      "TIS_A_Ext": "BEL",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "BELGIUM",
      "Official name": "KINGDOM OF BELGIUM",
      "Abbreviated name": "BELGIUM",
      "Unofficial name": ""
  },
  {
      "TIS_N": "64",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "BT",
      "TIS_A_Ext": "BTN",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "BHUTAN",
      "Official name": "KINGDOM OF BHUTAN",
      "Abbreviated name": "BHUTAN",
      "Unofficial name": ""
  },
  {
      "TIS_N": "68",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "BO",
      "TIS_A_Ext": "BOL",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "BOLIVIA",
      "Official name": "PLURINATIONAL STATE OF BOLIVIA",
      "Abbreviated name": "BOLIVIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "70",
      "Exists from": "1/1/1992",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "BA",
      "TIS_A_Ext": "BIH",
      "Name from": "1/1/1992",
      "Name until": "31/12/3999",
      "Name": "BOSNIA AND HERZEGOVINA",
      "Official name": "",
      "Abbreviated name": "BOSNIA+HERZEGO.",
      "Unofficial name": ""
  },
  {
      "TIS_N": "72",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "BW",
      "TIS_A_Ext": "BWA",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "BOTSWANA",
      "Official name": "REPUBLIC OF BOTSWANA",
      "Abbreviated name": "BOTSWANA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "76",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "BR",
      "TIS_A_Ext": "BRA",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "BRAZIL",
      "Official name": "FEDERATIVE REPUBLIC OF BRAZIL",
      "Abbreviated name": "BRAZIL",
      "Unofficial name": ""
  },
  {
      "TIS_N": "84",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "BZ",
      "TIS_A_Ext": "BLZ",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "BELIZE",
      "Official name": "",
      "Abbreviated name": "BELIZE",
      "Unofficial name": ""
  },
  {
      "TIS_N": "90",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "SB",
      "TIS_A_Ext": "SLB",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "SOLOMON ISLANDS",
      "Official name": "",
      "Abbreviated name": "SOLOMON ISLANDS",
      "Unofficial name": ""
  },
  {
      "TIS_N": "96",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "BN",
      "TIS_A_Ext": "BRN",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "BRUNEI DARUSSALAM",
      "Official name": "",
      "Abbreviated name": "BRUNEI",
      "Unofficial name": ""
  },
  {
      "TIS_N": "100",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "BG",
      "TIS_A_Ext": "BGR",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "BULGARIA",
      "Official name": "REPUBLIC OF BULGARIA",
      "Abbreviated name": "BULGARIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "104",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "BU",
      "TIS_A_Ext": "BUR",
      "Name from": "01.01.1000",
      "Name until": "31/12/1988",
      "Name": "BURMA",
      "Official name": "",
      "Abbreviated name": "BURMA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "104",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "MM",
      "TIS_A_Ext": "MMR",
      "Name from": "1/1/1989",
      "Name until": "31/12/3999",
      "Name": "MYANMAR",
      "Official name": "REPUBLIC OF THE UNION OF MYANMAR",
      "Abbreviated name": "MYANMAR",
      "Unofficial name": ""
  },
  {
      "TIS_N": "108",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "BI",
      "TIS_A_Ext": "BDI",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "BURUNDI",
      "Official name": "REPUBLIC OF BURUNDI",
      "Abbreviated name": "BURUNDI",
      "Unofficial name": ""
  },
  {
      "TIS_N": "112",
      "Exists from": "1/1/1992",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "BY",
      "TIS_A_Ext": "BLR",
      "Name from": "1/1/1992",
      "Name until": "31/12/3999",
      "Name": "BELARUS",
      "Official name": "REPUBLIC OF BELARUS",
      "Abbreviated name": "BELARUS",
      "Unofficial name": ""
  },
  {
      "TIS_N": "116",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "KH",
      "TIS_A_Ext": "KHM",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "CAMBODIA",
      "Official name": "KINGDOM OF CAMBODIA",
      "Abbreviated name": "CAMBODIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "120",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "CM",
      "TIS_A_Ext": "CMR",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "CAMEROON",
      "Official name": "REPUBLIC OF CAMEROON",
      "Abbreviated name": "CAMEROON",
      "Unofficial name": ""
  },
  {
      "TIS_N": "124",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "CA",
      "TIS_A_Ext": "CAN",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "CANADA",
      "Official name": "",
      "Abbreviated name": "CANADA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "132",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "CV",
      "TIS_A_Ext": "CPV",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "CAPE VERDE",
      "Official name": "REPUBLIC OF CAPE VERDE",
      "Abbreviated name": "CAPE VERDE",
      "Unofficial name": ""
  },
  {
      "TIS_N": "140",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "CF",
      "TIS_A_Ext": "CAF",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "CENTRAL AFRICAN REPUBLIC",
      "Official name": "",
      "Abbreviated name": "CENT.AFRIC.REP.",
      "Unofficial name": ""
  },
  {
      "TIS_N": "144",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "LK",
      "TIS_A_Ext": "LKA",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "SRI LANKA",
      "Official name": "DEMOCRATIC SOCIALIST REPUBLIC OF SRI LANKA",
      "Abbreviated name": "SRI LANKA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "148",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "TD",
      "TIS_A_Ext": "TCD",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "CHAD",
      "Official name": "REPUBLIC OF CHAD",
      "Abbreviated name": "CHAD",
      "Unofficial name": ""
  },
  {
      "TIS_N": "152",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "CL",
      "TIS_A_Ext": "CHL",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "CHILE",
      "Official name": "REPUBLIC OF CHILE",
      "Abbreviated name": "CHILE",
      "Unofficial name": ""
  },
  {
      "TIS_N": "156",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "TIS-N 156 CHINA IN THE MEANING OF THIS STANDARD DOES NOT INCLUDE HONG KONG. CF. ENTRY TIS-N 344 HONG KONG.",
      "TIS_A": "CN",
      "TIS_A_Ext": "CHN",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "CHINA",
      "Official name": "PEOPLE'S REPUBLIC OF CHINA",
      "Abbreviated name": "CHINA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "158",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "TW",
      "TIS_A_Ext": "TWN",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "TAIWAN, PROVINCE OF CHINA",
      "Official name": "",
      "Abbreviated name": "TAIWAN",
      "Unofficial name": ""
  },
  {
      "TIS_N": "170",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "CO",
      "TIS_A_Ext": "COL",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "COLOMBIA",
      "Official name": "REPUBLIC OF COLOMBIA",
      "Abbreviated name": "COLOMBIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "174",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "THIS ENTRY REFERS TO THE STATE COMOROS, NOT TO THE ARCHIPELAGO WITH THAT NAME. IT COMPRISES THEREFORE ANJOUAN, GRANDE COMORE AND MOHELI.",
      "TIS_A": "KM",
      "TIS_A_Ext": "COM",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "COMOROS",
      "Official name": "UNION OF THE COMOROS",
      "Abbreviated name": "COMOROS",
      "Unofficial name": ""
  },
  {
      "TIS_N": "178",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "CG",
      "TIS_A_Ext": "COG",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "CONGO",
      "Official name": "REPUBLIC OF THE CONGO",
      "Abbreviated name": "CONGO",
      "Unofficial name": ""
  },
  {
      "TIS_N": "180",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "ZR",
      "TIS_A_Ext": "ZAR",
      "Name from": "01.01.1000",
      "Name until": "29/5/1997",
      "Name": "ZAIRE",
      "Official name": "REPUBLIC OF ZAIRE",
      "Abbreviated name": "ZAIRE",
      "Unofficial name": ""
  },
  {
      "TIS_N": "180",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "CD",
      "TIS_A_Ext": "COD",
      "Name from": "30/5/1997",
      "Name until": "31/12/3999",
      "Name": "CONGO, THE DEMOCRATIC REPUBLIC OF THE",
      "Official name": "THE DEMOCRATIC REPUBLIC OF THE CONGO",
      "Abbreviated name": "DEMOC.REP.CONGO",
      "Unofficial name": ""
  },
  {
      "TIS_N": "188",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "CR",
      "TIS_A_Ext": "CRI",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "COSTA RICA",
      "Official name": "REPUBLIC OF COSTA RICA",
      "Abbreviated name": "COSTA RICA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "191",
      "Exists from": "1/1/1992",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "HR",
      "TIS_A_Ext": "HRV",
      "Name from": "1/1/1992",
      "Name until": "31/12/3999",
      "Name": "CROATIA",
      "Official name": "REPUBLIC OF CROATIA",
      "Abbreviated name": "CROATIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "192",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "CU",
      "TIS_A_Ext": "CUB",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "CUBA",
      "Official name": "REPUBLIC OF CUBA",
      "Abbreviated name": "CUBA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "196",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "CY",
      "TIS_A_Ext": "CYP",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "CYPRUS",
      "Official name": "REPUBLIC OF CYPRUS",
      "Abbreviated name": "CYPRUS",
      "Unofficial name": ""
  },
  {
      "TIS_N": "203",
      "Exists from": "1/1/1993",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "CZ",
      "TIS_A_Ext": "CZE",
      "Name from": "1/1/1993",
      "Name until": "31/12/3999",
      "Name": "CZECH REPUBLIC",
      "Official name": "",
      "Abbreviated name": "CZECH REPUBLIC",
      "Unofficial name": ""
  },
  {
      "TIS_N": "204",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "BJ",
      "TIS_A_Ext": "BEN",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "BENIN",
      "Official name": "REPUBLIC OF BENIN",
      "Abbreviated name": "BENIN",
      "Unofficial name": ""
  },
  {
      "TIS_N": "208",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "DK",
      "TIS_A_Ext": "DNK",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "DENMARK",
      "Official name": "KINGDOM OF DENMARK",
      "Abbreviated name": "DENMARK",
      "Unofficial name": ""
  },
  {
      "TIS_N": "212",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "DM",
      "TIS_A_Ext": "DMA",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "DOMINICA",
      "Official name": "COMMONWEALTH OF DOMINICA",
      "Abbreviated name": "DOMINICA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "214",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "DO",
      "TIS_A_Ext": "DOM",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "DOMINICAN REPUBLIC",
      "Official name": "",
      "Abbreviated name": "DOMINICAN REP.",
      "Unofficial name": ""
  },
  {
      "TIS_N": "218",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "EC",
      "TIS_A_Ext": "ECU",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "ECUADOR",
      "Official name": "REPUBLIC OF ECUADOR",
      "Abbreviated name": "ECUADOR",
      "Unofficial name": ""
  },
  {
      "TIS_N": "222",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "SV",
      "TIS_A_Ext": "SLV",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "EL SALVADOR",
      "Official name": "REPUBLIC OF EL SALVADOR",
      "Abbreviated name": "EL SALVADOR",
      "Unofficial name": ""
  },
  {
      "TIS_N": "226",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "GQ",
      "TIS_A_Ext": "GNQ",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "EQUATORIAL GUINEA",
      "Official name": "REPUBLIC OF EQUATORIAL GUINEA",
      "Abbreviated name": "EQUAT.GUINEA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "231",
      "Exists from": "24/5/1993",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "ET",
      "TIS_A_Ext": "ETH",
      "Name from": "24/5/1993",
      "Name until": "31/12/3999",
      "Name": "ETHIOPIA",
      "Official name": "FEDERAL DEMOCRATIC REPUBLIC OF ETHIOPIA",
      "Abbreviated name": "ETHIOPIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "232",
      "Exists from": "24/5/1993",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "ER",
      "TIS_A_Ext": "ERI",
      "Name from": "24/5/1993",
      "Name until": "31/12/3999",
      "Name": "ERITREA",
      "Official name": "",
      "Abbreviated name": "ERITREA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "233",
      "Exists from": "1/1/1992",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "EE",
      "TIS_A_Ext": "EST",
      "Name from": "1/1/1992",
      "Name until": "31/12/3999",
      "Name": "ESTONIA",
      "Official name": "REPUBLIC OF ESTONIA",
      "Abbreviated name": "ESTONIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "242",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "FJ",
      "TIS_A_Ext": "FJI",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "FIJI",
      "Official name": "REPUBLIC OF FIJI",
      "Abbreviated name": "FIJI",
      "Unofficial name": ""
  },
  {
      "TIS_N": "246",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "FI",
      "TIS_A_Ext": "FIN",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "FINLAND",
      "Official name": "REPUBLIC OF FINLAND",
      "Abbreviated name": "FINLAND",
      "Unofficial name": ""
  },
  {
      "TIS_N": "250",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "TIS-N 250 FRANCE IN THE MEANING OF THIS STANDARD DOES NOT INCLUDE FRENCH POLYNESIA AND NEW CALEDONIA. CF. ENTRIES TIS-N 258 FRENCH POLYNESIA AND TIS-N 540 NEW CALEDONIA.",
      "TIS_A": "FR",
      "TIS_A_Ext": "FRA",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "FRANCE",
      "Official name": "FRENCH REPUBLIC",
      "Abbreviated name": "FRANCE",
      "Unofficial name": ""
  },
  {
      "TIS_N": "258",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "PF",
      "TIS_A_Ext": "PYF",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "FRENCH POLYNESIA",
      "Official name": "",
      "Abbreviated name": "FR.POLYNESIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "262",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "DJ",
      "TIS_A_Ext": "DJI",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "DJIBOUTI",
      "Official name": "REPUBLIC OF DJIBOUTI",
      "Abbreviated name": "DJIBOUTI",
      "Unofficial name": ""
  },
  {
      "TIS_N": "266",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "GA",
      "TIS_A_Ext": "GAB",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "GABON",
      "Official name": "GABONESE REPUBLIC",
      "Abbreviated name": "GABON",
      "Unofficial name": ""
  },
  {
      "TIS_N": "268",
      "Exists from": "1/1/1992",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "GE",
      "TIS_A_Ext": "GEO",
      "Name from": "1/1/1992",
      "Name until": "31/12/3999",
      "Name": "GEORGIA",
      "Official name": "",
      "Abbreviated name": "GEORGIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "270",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "GM",
      "TIS_A_Ext": "GMB",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "GAMBIA",
      "Official name": "REPUBLIC OF THE GAMBIA",
      "Abbreviated name": "GAMBIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "276",
      "Exists from": "3/10/1990",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "DE",
      "TIS_A_Ext": "DEU",
      "Name from": "3/10/1990",
      "Name until": "31/12/3999",
      "Name": "GERMANY",
      "Official name": "FEDERAL REPUBLIC OF GERMANY",
      "Abbreviated name": "GERMANY",
      "Unofficial name": ""
  },
  {
      "TIS_N": "288",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "GH",
      "TIS_A_Ext": "GHA",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "GHANA",
      "Official name": "REPUBLIC OF GHANA",
      "Abbreviated name": "GHANA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "296",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "KI",
      "TIS_A_Ext": "KIR",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "KIRIBATI",
      "Official name": "REPUBLIC OF KIRIBATI",
      "Abbreviated name": "KIRIBATI",
      "Unofficial name": ""
  },
  {
      "TIS_N": "300",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "GR",
      "TIS_A_Ext": "GRC",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "GREECE",
      "Official name": "HELLENIC REPUBLIC",
      "Abbreviated name": "GREECE",
      "Unofficial name": ""
  },
  {
      "TIS_N": "308",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "GD",
      "TIS_A_Ext": "GRD",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "GRENADA",
      "Official name": "",
      "Abbreviated name": "GRENADA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "320",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "GT",
      "TIS_A_Ext": "GTM",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "GUATEMALA",
      "Official name": "REPUBLIC OF GUATEMALA",
      "Abbreviated name": "GUATEMALA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "324",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "GN",
      "TIS_A_Ext": "GIN",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "GUINEA",
      "Official name": "REPUBLIC OF GUINEA",
      "Abbreviated name": "GUINEA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "328",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "GY",
      "TIS_A_Ext": "GUY",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "GUYANA",
      "Official name": "REPUBLIC OF GUYANA",
      "Abbreviated name": "GUYANA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "332",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "HT",
      "TIS_A_Ext": "HTI",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "HAITI",
      "Official name": "REPUBLIC OF HAITI",
      "Abbreviated name": "HAITI",
      "Unofficial name": ""
  },
  {
      "TIS_N": "336",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "VA",
      "TIS_A_Ext": "VAT",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "HOLY SEE (VATICAN CITY STATE)",
      "Official name": "",
      "Abbreviated name": "VATICAN",
      "Unofficial name": ""
  },
  {
      "TIS_N": "340",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "HN",
      "TIS_A_Ext": "HND",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "HONDURAS",
      "Official name": "REPUBLIC OF HONDURAS",
      "Abbreviated name": "HONDURAS",
      "Unofficial name": ""
  },
  {
      "TIS_N": "344",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "HK",
      "TIS_A_Ext": "HKG",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "HONG KONG",
      "Official name": "HONG KONG SPECIAL ADMINISTRATIVE REGION OF CHINA",
      "Abbreviated name": "HONG KONG",
      "Unofficial name": ""
  },
  {
      "TIS_N": "348",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "HU",
      "TIS_A_Ext": "HUN",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "HUNGARY",
      "Official name": "HUNGARY",
      "Abbreviated name": "HUNGARY",
      "Unofficial name": ""
  },
  {
      "TIS_N": "352",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "IS",
      "TIS_A_Ext": "ISL",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "ICELAND",
      "Official name": "REPUBLIC OF ICELAND",
      "Abbreviated name": "ICELAND",
      "Unofficial name": ""
  },
  {
      "TIS_N": "356",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "IN",
      "TIS_A_Ext": "IND",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "INDIA",
      "Official name": "REPUBLIC OF INDIA",
      "Abbreviated name": "INDIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "360",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "ID",
      "TIS_A_Ext": "IDN",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "INDONESIA",
      "Official name": "REPUBLIC OF INDONESIA",
      "Abbreviated name": "INDONESIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "364",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "IR",
      "TIS_A_Ext": "IRN",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "IRAN, ISLAMIC REPUBLIC OF",
      "Official name": "ISLAMIC REPUBLIC OF IRAN",
      "Abbreviated name": "IRAN",
      "Unofficial name": ""
  },
  {
      "TIS_N": "368",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "IQ",
      "TIS_A_Ext": "IRQ",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "IRAQ",
      "Official name": "REPUBLIC OF IRAQ",
      "Abbreviated name": "IRAQ",
      "Unofficial name": ""
  },
  {
      "TIS_N": "372",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "IE",
      "TIS_A_Ext": "IRL",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "IRELAND",
      "Official name": "",
      "Abbreviated name": "IRELAND",
      "Unofficial name": ""
  },
  {
      "TIS_N": "376",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "IL",
      "TIS_A_Ext": "ISR",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "ISRAEL",
      "Official name": "STATE OF ISRAEL",
      "Abbreviated name": "ISRAEL",
      "Unofficial name": ""
  },
  {
      "TIS_N": "380",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "IT",
      "TIS_A_Ext": "ITA",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "ITALY",
      "Official name": "ITALIAN REPUBLIC",
      "Abbreviated name": "ITALY",
      "Unofficial name": ""
  },
  {
      "TIS_N": "384",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "CI",
      "TIS_A_Ext": "CIV",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "COTE D'IVOIRE",
      "Official name": "REPUBLIC OF COTE D'IVOIRE",
      "Abbreviated name": "COTE D'IVOIRE",
      "Unofficial name": ""
  },
  {
      "TIS_N": "388",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "JM",
      "TIS_A_Ext": "JAM",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "JAMAICA",
      "Official name": "",
      "Abbreviated name": "JAMAICA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "392",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "JP",
      "TIS_A_Ext": "JPN",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "JAPAN",
      "Official name": "",
      "Abbreviated name": "JAPAN",
      "Unofficial name": ""
  },
  {
      "TIS_N": "398",
      "Exists from": "1/1/1992",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "KZ",
      "TIS_A_Ext": "KAZ",
      "Name from": "1/1/1992",
      "Name until": "31/12/3999",
      "Name": "KAZAKHSTAN",
      "Official name": "REPUBLIC OF KAZAKHSTAN",
      "Abbreviated name": "KAZAKHSTAN",
      "Unofficial name": ""
  },
  {
      "TIS_N": "400",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "JO",
      "TIS_A_Ext": "JOR",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "JORDAN",
      "Official name": "HASHEMITE KINGDOM OF JORDAN",
      "Abbreviated name": "JORDAN",
      "Unofficial name": ""
  },
  {
      "TIS_N": "404",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "KE",
      "TIS_A_Ext": "KEN",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "KENYA",
      "Official name": "REPUBLIC OF KENYA",
      "Abbreviated name": "KENYA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "408",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "KP",
      "TIS_A_Ext": "PRK",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
      "Official name": "DEMOCRATIC PEOPLE'S REPUBLIC OF KOREA",
      "Abbreviated name": "NORTH KOREA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "410",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "KR",
      "TIS_A_Ext": "KOR",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "KOREA, REPUBLIC OF",
      "Official name": "REPUBLIC OF KOREA",
      "Abbreviated name": "SOUTH KOREA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "414",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "KW",
      "TIS_A_Ext": "KWT",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "KUWAIT",
      "Official name": "STATE OF KUWAIT",
      "Abbreviated name": "KUWAIT",
      "Unofficial name": ""
  },
  {
      "TIS_N": "417",
      "Exists from": "1/1/1992",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "KG",
      "TIS_A_Ext": "KGZ",
      "Name from": "1/1/1992",
      "Name until": "31/12/3999",
      "Name": "KYRGYZSTAN",
      "Official name": "KYRGYZ REPUBLIC",
      "Abbreviated name": "KYRGYZSTAN",
      "Unofficial name": ""
  },
  {
      "TIS_N": "418",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "LA",
      "TIS_A_Ext": "LAO",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "LAO PEOPLE'S DEMOCRATIC REPUBLIC",
      "Official name": "",
      "Abbreviated name": "LAO",
      "Unofficial name": ""
  },
  {
      "TIS_N": "422",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "LB",
      "TIS_A_Ext": "LBN",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "LEBANON",
      "Official name": "LEBANESE REPUBLIC",
      "Abbreviated name": "LEBANON",
      "Unofficial name": ""
  },
  {
      "TIS_N": "426",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "LS",
      "TIS_A_Ext": "LSO",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "LESOTHO",
      "Official name": "KINGDOM OF LESOTHO",
      "Abbreviated name": "LESOTHO",
      "Unofficial name": ""
  },
  {
      "TIS_N": "428",
      "Exists from": "1/1/1992",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "LV",
      "TIS_A_Ext": "LVA",
      "Name from": "1/1/1992",
      "Name until": "31/12/3999",
      "Name": "LATVIA",
      "Official name": "REPUBLIC OF LATVIA",
      "Abbreviated name": "LATVIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "430",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "LR",
      "TIS_A_Ext": "LBR",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "LIBERIA",
      "Official name": "REPUBLIC OF LIBERIA",
      "Abbreviated name": "LIBERIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "434",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "LY",
      "TIS_A_Ext": "LBY",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "LIBYA",
      "Official name": "STATE OF LIBYA",
      "Abbreviated name": "LIBYA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "438",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "LI",
      "TIS_A_Ext": "LIE",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "LIECHTENSTEIN",
      "Official name": "PRINCIPALITY OF LIECHTENSTEIN",
      "Abbreviated name": "LIECHTENSTEIN",
      "Unofficial name": ""
  },
  {
      "TIS_N": "440",
      "Exists from": "1/1/1992",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "LT",
      "TIS_A_Ext": "LTU",
      "Name from": "1/1/1992",
      "Name until": "31/12/3999",
      "Name": "LITHUANIA",
      "Official name": "REPUBLIC OF LITHUANIA",
      "Abbreviated name": "LITHUANIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "442",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "LU",
      "TIS_A_Ext": "LUX",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "LUXEMBOURG",
      "Official name": "GRAND DUCHY OF LUXEMBOURG",
      "Abbreviated name": "LUXEMBOURG",
      "Unofficial name": ""
  },
  {
      "TIS_N": "446",
      "Exists from": "20/12/1999",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "MO",
      "TIS_A_Ext": "MAC",
      "Name from": "20/12/1999",
      "Name until": "31/12/3999",
      "Name": "MACAO",
      "Official name": "MACAO SPECIAL ADMINISTRATIVE REGION OF CHINA",
      "Abbreviated name": "MACAO",
      "Unofficial name": ""
  },
  {
      "TIS_N": "450",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "MG",
      "TIS_A_Ext": "MDG",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "MADAGASCAR",
      "Official name": "REPUBLIC OF MADAGASCAR",
      "Abbreviated name": "MADAGASCAR",
      "Unofficial name": ""
  },
  {
      "TIS_N": "454",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "MW",
      "TIS_A_Ext": "MWI",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "MALAWI",
      "Official name": "REPUBLIC OF MALAWI",
      "Abbreviated name": "MALAWI",
      "Unofficial name": ""
  },
  {
      "TIS_N": "458",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "MY",
      "TIS_A_Ext": "MYS",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "MALAYSIA",
      "Official name": "",
      "Abbreviated name": "MALAYSIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "462",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "MV",
      "TIS_A_Ext": "MDV",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "MALDIVES",
      "Official name": "REPUBLIC OF MALDIVES",
      "Abbreviated name": "MALDIVES",
      "Unofficial name": ""
  },
  {
      "TIS_N": "466",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "ML",
      "TIS_A_Ext": "MLI",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "MALI",
      "Official name": "REPUBLIC OF MALI",
      "Abbreviated name": "MALI",
      "Unofficial name": ""
  },
  {
      "TIS_N": "470",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "MT",
      "TIS_A_Ext": "MLT",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "MALTA",
      "Official name": "REPUBLIC OF MALTA",
      "Abbreviated name": "MALTA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "478",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "MR",
      "TIS_A_Ext": "MRT",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "MAURITANIA",
      "Official name": "ISLAMIC REPUBLIC OF MAURITANIA",
      "Abbreviated name": "MAURITANIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "480",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "MU",
      "TIS_A_Ext": "MUS",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "MAURITIUS",
      "Official name": "REPUBLIC OF MAURITIUS",
      "Abbreviated name": "MAURITIUS",
      "Unofficial name": ""
  },
  {
      "TIS_N": "484",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "MX",
      "TIS_A_Ext": "MEX",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "MEXICO",
      "Official name": "UNITED MEXICAN STATES",
      "Abbreviated name": "MEXICO",
      "Unofficial name": ""
  },
  {
      "TIS_N": "492",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "MC",
      "TIS_A_Ext": "MCO",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "MONACO",
      "Official name": "PRINCIPALITY OF MONACO",
      "Abbreviated name": "MONACO",
      "Unofficial name": ""
  },
  {
      "TIS_N": "496",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "MN",
      "TIS_A_Ext": "MNG",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "MONGOLIA",
      "Official name": "",
      "Abbreviated name": "MONGOLIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "498",
      "Exists from": "1/1/1992",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "MD",
      "TIS_A_Ext": "MDA",
      "Name from": "1/1/1992",
      "Name until": "31/12/3999",
      "Name": "MOLDOVA, REPUBLIC OF",
      "Official name": "REPUBLIC OF MOLDOVA",
      "Abbreviated name": "MOLDOVA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "499",
      "Exists from": "3/6/2006",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "ME",
      "TIS_A_Ext": "MNE",
      "Name from": "3/6/2006",
      "Name until": "31/12/3999",
      "Name": "MONTENEGRO",
      "Official name": "MONTENEGRO",
      "Abbreviated name": "MONTENEGRO",
      "Unofficial name": ""
  },
  {
      "TIS_N": "504",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "MA",
      "TIS_A_Ext": "MAR",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "MOROCCO",
      "Official name": "KINGDOM OF MOROCCO",
      "Abbreviated name": "MOROCCO",
      "Unofficial name": ""
  },
  {
      "TIS_N": "508",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "MZ",
      "TIS_A_Ext": "MOZ",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "MOZAMBIQUE",
      "Official name": "REPUBLIC OF MOZAMBIQUE",
      "Abbreviated name": "MOZAMBIQUE",
      "Unofficial name": ""
  },
  {
      "TIS_N": "512",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "OM",
      "TIS_A_Ext": "OMN",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "OMAN",
      "Official name": "SULTANATE OF OMAN",
      "Abbreviated name": "OMAN",
      "Unofficial name": ""
  },
  {
      "TIS_N": "516",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "NA",
      "TIS_A_Ext": "NAM",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "NAMIBIA",
      "Official name": "REPUBLIC OF NAMIBIA",
      "Abbreviated name": "NAMIBIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "520",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "NR",
      "TIS_A_Ext": "NRU",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "NAURU",
      "Official name": "REPUBLIC OF NAURU",
      "Abbreviated name": "NAURU",
      "Unofficial name": ""
  },
  {
      "TIS_N": "524",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "NP",
      "TIS_A_Ext": "NPL",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "NEPAL",
      "Official name": "FEDERAL DEMOCRATIC REPUBLIC OF NEPAL",
      "Abbreviated name": "NEPAL",
      "Unofficial name": ""
  },
  {
      "TIS_N": "528",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "NL",
      "TIS_A_Ext": "NLD",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "NETHERLANDS",
      "Official name": "KINGDOM OF THE NETHERLANDS",
      "Abbreviated name": "NETHERLANDS",
      "Unofficial name": ""
  },
  {
      "TIS_N": "540",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "NC",
      "TIS_A_Ext": "NCL",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "NEW CALEDONIA",
      "Official name": "",
      "Abbreviated name": "NEW CALEDONIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "548",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "VU",
      "TIS_A_Ext": "VUT",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "VANUATU",
      "Official name": "REPUBLIC OF VANUATU",
      "Abbreviated name": "VANUATU",
      "Unofficial name": ""
  },
  {
      "TIS_N": "554",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "NZ",
      "TIS_A_Ext": "NZL",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "NEW ZEALAND",
      "Official name": "",
      "Abbreviated name": "NEW ZEALAND",
      "Unofficial name": ""
  },
  {
      "TIS_N": "558",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "NI",
      "TIS_A_Ext": "NIC",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "NICARAGUA",
      "Official name": "REPUBLIC OF NICARAGUA",
      "Abbreviated name": "NICARAGUA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "562",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "NE",
      "TIS_A_Ext": "NER",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "NIGER",
      "Official name": "REPUBLIC OF THE NIGER",
      "Abbreviated name": "NIGER",
      "Unofficial name": ""
  },
  {
      "TIS_N": "566",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "NG",
      "TIS_A_Ext": "NGA",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "NIGERIA",
      "Official name": "FEDERAL REPUBLIC OF NIGERIA",
      "Abbreviated name": "NIGERIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "578",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "NO",
      "TIS_A_Ext": "NOR",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "NORWAY",
      "Official name": "KINGDOM OF NORWAY",
      "Abbreviated name": "NORWAY",
      "Unofficial name": ""
  },
  {
      "TIS_N": "583",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "FM",
      "TIS_A_Ext": "FSM",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "MICRONESIA, FEDERATED STATES OF",
      "Official name": "FEDERATED STATES OF MICRONESIA",
      "Abbreviated name": "MICRONESIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "584",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "MH",
      "TIS_A_Ext": "MHL",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "MARSHALL ISLANDS",
      "Official name": "REPUBLIC OF THE MARSHALL ISLANDS",
      "Abbreviated name": "MARSHALL ISL.",
      "Unofficial name": ""
  },
  {
      "TIS_N": "585",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "PW",
      "TIS_A_Ext": "PLW",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "PALAU",
      "Official name": "REPUBLIC OF PALAU",
      "Abbreviated name": "PALAU",
      "Unofficial name": ""
  },
  {
      "TIS_N": "586",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "PK",
      "TIS_A_Ext": "PAK",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "PAKISTAN",
      "Official name": "ISLAMIC REPUBLIC OF PAKISTAN",
      "Abbreviated name": "PAKISTAN",
      "Unofficial name": ""
  },
  {
      "TIS_N": "591",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "PA",
      "TIS_A_Ext": "PAN",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "PANAMA",
      "Official name": "REPUBLIC OF PANAMA",
      "Abbreviated name": "PANAMA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "598",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "PG",
      "TIS_A_Ext": "PNG",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "PAPUA NEW GUINEA",
      "Official name": "",
      "Abbreviated name": "PAPUA N.GUINEA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "600",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "PY",
      "TIS_A_Ext": "PRY",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "PARAGUAY",
      "Official name": "REPUBLIC OF PARAGUAY",
      "Abbreviated name": "PARAGUAY",
      "Unofficial name": ""
  },
  {
      "TIS_N": "604",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "PE",
      "TIS_A_Ext": "PER",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "PERU",
      "Official name": "REPUBLIC OF PERU",
      "Abbreviated name": "PERU",
      "Unofficial name": ""
  },
  {
      "TIS_N": "608",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "PH",
      "TIS_A_Ext": "PHL",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "PHILIPPINES",
      "Official name": "REPUBLIC OF THE PHILIPPINES",
      "Abbreviated name": "PHILIPPINES",
      "Unofficial name": ""
  },
  {
      "TIS_N": "616",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "PL",
      "TIS_A_Ext": "POL",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "POLAND",
      "Official name": "REPUBLIC OF POLAND",
      "Abbreviated name": "POLAND",
      "Unofficial name": ""
  },
  {
      "TIS_N": "620",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "PT",
      "TIS_A_Ext": "PRT",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "PORTUGAL",
      "Official name": "PORTUGUESE REPUBLIC",
      "Abbreviated name": "PORTUGAL",
      "Unofficial name": ""
  },
  {
      "TIS_N": "624",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "GW",
      "TIS_A_Ext": "GNB",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "GUINEA-BISSAU",
      "Official name": "REPUBLIC OF GUINEA-BISSAU",
      "Abbreviated name": "GUINEA-BISSAU",
      "Unofficial name": ""
  },
  {
      "TIS_N": "626",
      "Exists from": "20/5/2002",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "TL",
      "TIS_A_Ext": "TLS",
      "Name from": "20/5/2002",
      "Name until": "31/12/3999",
      "Name": "TIMOR-LESTE",
      "Official name": "DEMOCRATIC REPUBLIC OF TIMOR-LESTE",
      "Abbreviated name": "TIMOR-LESTE",
      "Unofficial name": ""
  },
  {
      "TIS_N": "630",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "PR",
      "TIS_A_Ext": "PRI",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "PUERTO RICO",
      "Official name": "",
      "Abbreviated name": "PUERTO RICO",
      "Unofficial name": ""
  },
  {
      "TIS_N": "634",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "QA",
      "TIS_A_Ext": "QAT",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "QATAR",
      "Official name": "STATE OF QATAR",
      "Abbreviated name": "QATAR",
      "Unofficial name": ""
  },
  {
      "TIS_N": "642",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "RO",
      "TIS_A_Ext": "ROU",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "ROMANIA",
      "Official name": "",
      "Abbreviated name": "ROMANIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "643",
      "Exists from": "1/1/1992",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "RU",
      "TIS_A_Ext": "RUS",
      "Name from": "1/1/1992",
      "Name until": "31/12/3999",
      "Name": "RUSSIAN FEDERATION",
      "Official name": "",
      "Abbreviated name": "RUSSIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "646",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "RW",
      "TIS_A_Ext": "RWA",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "RWANDA",
      "Official name": "RWANDESE RUPUBLIC",
      "Abbreviated name": "RWANDA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "659",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "KN",
      "TIS_A_Ext": "KNA",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "SAINT KITTS AND NEVIS",
      "Official name": "",
      "Abbreviated name": "ST.KITTS+NEVIS",
      "Unofficial name": ""
  },
  {
      "TIS_N": "662",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "LC",
      "TIS_A_Ext": "LCA",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "SAINT LUCIA",
      "Official name": "",
      "Abbreviated name": "ST.LUCIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "670",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "VC",
      "TIS_A_Ext": "VCT",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "SAINT VINCENT AND THE GRENADINES",
      "Official name": "",
      "Abbreviated name": "ST.VINCENT+GR",
      "Unofficial name": ""
  },
  {
      "TIS_N": "674",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "SM",
      "TIS_A_Ext": "SMR",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "SAN MARINO",
      "Official name": "REPUBLIC OF SAN MARINO",
      "Abbreviated name": "SAN MARINO",
      "Unofficial name": ""
  },
  {
      "TIS_N": "678",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "ST",
      "TIS_A_Ext": "STP",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "SAO TOME AND PRINCIPE",
      "Official name": "DEMOCRATIC REPUBLIC OF SAO TOME AND PRINCIPE",
      "Abbreviated name": "S.TOME+PRINCIPE",
      "Unofficial name": ""
  },
  {
      "TIS_N": "682",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "SA",
      "TIS_A_Ext": "SAU",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "SAUDI ARABIA",
      "Official name": "KINGDOM OF SAUDI ARABIA",
      "Abbreviated name": "SAUDI ARABIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "686",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "SN",
      "TIS_A_Ext": "SEN",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "SENEGAL",
      "Official name": "REPUBLIC OF SENEGAL",
      "Abbreviated name": "SENEGAL",
      "Unofficial name": ""
  },
  {
      "TIS_N": "688",
      "Exists from": "3/6/2006",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "RS",
      "TIS_A_Ext": "SRB",
      "Name from": "3/6/2006",
      "Name until": "31/12/3999",
      "Name": "SERBIA",
      "Official name": "REPUBLIC OF SERBIA",
      "Abbreviated name": "SERBIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "690",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "SC",
      "TIS_A_Ext": "SYC",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "SEYCHELLES",
      "Official name": "REPUBLIC OF SEYCHELLES",
      "Abbreviated name": "SEYCHELLES",
      "Unofficial name": ""
  },
  {
      "TIS_N": "694",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "SL",
      "TIS_A_Ext": "SLE",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "SIERRA LEONE",
      "Official name": "REPUBLIC OF SIERRA LEONE",
      "Abbreviated name": "SIERRA LEONE",
      "Unofficial name": ""
  },
  {
      "TIS_N": "702",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "SG",
      "TIS_A_Ext": "SGP",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "SINGAPORE",
      "Official name": "REPUBLIC OF SINGAPORE",
      "Abbreviated name": "SINGAPORE",
      "Unofficial name": ""
  },
  {
      "TIS_N": "703",
      "Exists from": "1/1/1993",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "SK",
      "TIS_A_Ext": "SVK",
      "Name from": "1/1/1993",
      "Name until": "31/12/3999",
      "Name": "SLOVAKIA",
      "Official name": "SLOVAK REPUBLIC",
      "Abbreviated name": "SLOVAKIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "704",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "VN",
      "TIS_A_Ext": "VNM",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "VIET NAM",
      "Official name": "SOCIALIST REPUBLIC OF VIET NAM",
      "Abbreviated name": "VIET NAM",
      "Unofficial name": ""
  },
  {
      "TIS_N": "705",
      "Exists from": "1/1/1992",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "SI",
      "TIS_A_Ext": "SVN",
      "Name from": "1/1/1992",
      "Name until": "31/12/3999",
      "Name": "SLOVENIA",
      "Official name": "REPUBLIC OF SLOVENIA",
      "Abbreviated name": "SLOVENIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "706",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "SO",
      "TIS_A_Ext": "SOM",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "SOMALIA",
      "Official name": "SOMALI REPUBLIC",
      "Abbreviated name": "SOMALIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "710",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "ZA",
      "TIS_A_Ext": "ZAF",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "SOUTH AFRICA",
      "Official name": "REPUBLIC OF SOUTH AFRICA",
      "Abbreviated name": "SOUTH AFRICA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "716",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "ZW",
      "TIS_A_Ext": "ZWE",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "ZIMBABWE",
      "Official name": "REPUBLIC OF ZIMBABWE",
      "Abbreviated name": "ZIMBABWE",
      "Unofficial name": ""
  },
  {
      "TIS_N": "724",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "ES",
      "TIS_A_Ext": "ESP",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "SPAIN",
      "Official name": "KINGDOM OF SPAIN",
      "Abbreviated name": "SPAIN",
      "Unofficial name": ""
  },
  {
      "TIS_N": "728",
      "Exists from": "9/7/2011",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "SS",
      "TIS_A_Ext": "SSD",
      "Name from": "9/7/2011",
      "Name until": "31/12/3999",
      "Name": "SOUTH SUDAN",
      "Official name": "REPUBLIC OF SOUTH SUDAN",
      "Abbreviated name": "SOUTH SUDAN",
      "Unofficial name": ""
  },
  {
      "TIS_N": "729",
      "Exists from": "9/7/2011",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "SD",
      "TIS_A_Ext": "SDN",
      "Name from": "9/7/2011",
      "Name until": "31/12/3999",
      "Name": "SUDAN",
      "Official name": "REPUBLIC OF THE SUDAN",
      "Abbreviated name": "SUDAN",
      "Unofficial name": ""
  },
  {
      "TIS_N": "732",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "EH",
      "TIS_A_Ext": "ESH",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "WESTERN SAHARA",
      "Official name": "",
      "Abbreviated name": "WESTERN SAHARA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "740",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "SR",
      "TIS_A_Ext": "SUR",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "SURINAME",
      "Official name": "REPUBLIC OF SURINAME",
      "Abbreviated name": "SURINAME",
      "Unofficial name": ""
  },
  {
      "TIS_N": "748",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "SZ",
      "TIS_A_Ext": "SWZ",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "ESWATINI",
      "Official name": "THE KINGDOM OF ESWATINI",
      "Abbreviated name": "ESWATINI",
      "Unofficial name": ""
  },
  {
      "TIS_N": "752",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "SE",
      "TIS_A_Ext": "SWE",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "SWEDEN",
      "Official name": "KINGDOM OF SWEDEN",
      "Abbreviated name": "SWEDEN",
      "Unofficial name": ""
  },
    {
    "TIS_N": "756",
    "Exists from": "01.01.1000",
    "Exists until": "31/12/3999",
    "Type": "LND",
    "Remark": "",
    "TIS_A": "CH",
    "TIS_A_Ext": "CHE",
    "Name from": "01.01.1000",
    "Name until": "31/12/3999",
    "Name": "SWITZERLAND",
    "Official name": "SWISS CONFEDERATION",
    "Abbreviated name": "SWITZERLAND",
    "Unofficial name": ""
    },
    {
        "TIS_N": "760",
        "Exists from": "01.01.1000",
        "Exists until": "31/12/3999",
        "Type": "LND",
        "Remark": "",
        "TIS_A": "SY",
        "TIS_A_Ext": "SYR",
        "Name from": "01.01.1000",
        "Name until": "31/12/3999",
        "Name": "SYRIAN ARAB REPUBLIC",
        "Official name": "",
        "Abbreviated name": "SYRIA",
        "Unofficial name": ""
    },
  {
      "TIS_N": "762",
      "Exists from": "1/1/1992",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "TJ",
      "TIS_A_Ext": "TJK",
      "Name from": "1/1/1992",
      "Name until": "31/12/3999",
      "Name": "TAJIKISTAN",
      "Official name": "REPUBLIC OF TAJIKISTAN",
      "Abbreviated name": "TAJIKISTAN",
      "Unofficial name": ""
  },
  {
      "TIS_N": "764",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "TH",
      "TIS_A_Ext": "THA",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "THAILAND",
      "Official name": "KINGDOM OF THAILAND",
      "Abbreviated name": "THAILAND",
      "Unofficial name": ""
  },
  {
      "TIS_N": "768",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "TG",
      "TIS_A_Ext": "TGO",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "TOGO",
      "Official name": "TOGOLESE REPUBLIC",
      "Abbreviated name": "TOGO",
      "Unofficial name": ""
  },
  {
      "TIS_N": "776",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "TO",
      "TIS_A_Ext": "TON",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "TONGA",
      "Official name": "KINGDOM OF TONGA",
      "Abbreviated name": "TONGA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "780",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "TT",
      "TIS_A_Ext": "TTO",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "TRINIDAD AND TOBAGO",
      "Official name": "REPUBLIC OF TRINIDAD AND TOBAGO",
      "Abbreviated name": "TRINIDAD+TOBAGO",
      "Unofficial name": ""
  },
  {
      "TIS_N": "784",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "AE",
      "TIS_A_Ext": "ARE",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "UNITED ARAB EMIRATES",
      "Official name": "",
      "Abbreviated name": "UNIT.ARAB.EMIR.",
      "Unofficial name": ""
  },
  {
      "TIS_N": "788",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "TN",
      "TIS_A_Ext": "TUN",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "TUNISIA",
      "Official name": "REPUBLIC OF TUNISIA",
      "Abbreviated name": "TUNISIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "792",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "TR",
      "TIS_A_Ext": "TUR",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "TURKEY",
      "Official name": "REPUBLIC OF TURKEY",
      "Abbreviated name": "TURKEY",
      "Unofficial name": ""
  },
  {
      "TIS_N": "795",
      "Exists from": "1/1/1992",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "TM",
      "TIS_A_Ext": "TKM",
      "Name from": "1/1/1992",
      "Name until": "31/12/3999",
      "Name": "TURKMENISTAN",
      "Official name": "",
      "Abbreviated name": "TURKMENISTAN",
      "Unofficial name": ""
  },
  {
      "TIS_N": "798",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "TV",
      "TIS_A_Ext": "TUV",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "TUVALU",
      "Official name": "",
      "Abbreviated name": "TUVALU",
      "Unofficial name": ""
  },
  {
      "TIS_N": "800",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "UG",
      "TIS_A_Ext": "UGA",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "UGANDA",
      "Official name": "REPUBLIC OF UGANDA",
      "Abbreviated name": "UGANDA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "804",
      "Exists from": "1/1/1992",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "UA",
      "TIS_A_Ext": "UKR",
      "Name from": "1/1/1992",
      "Name until": "31/12/3999",
      "Name": "UKRAINE",
      "Official name": "",
      "Abbreviated name": "UKRAINE",
      "Unofficial name": ""
  },
  {
      "TIS_N": "807",
      "Exists from": "1/1/1992",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "MK",
      "TIS_A_Ext": "MKD",
      "Name from": "1/1/1992",
      "Name until": "31/12/3999",
      "Name": "NORTH MACEDONIA",
      "Official name": "THE REPUBLIC OF NORTH MACEDONIA",
      "Abbreviated name": "NORTH MACEDONIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "818",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "EG",
      "TIS_A_Ext": "EGY",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "EGYPT",
      "Official name": "ARAB REPUBLIC OF EGYPT",
      "Abbreviated name": "EGYPT",
      "Unofficial name": ""
  },
  {
      "TIS_N": "826",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "GB",
      "TIS_A_Ext": "GBR",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "UNITED KINGDOM",
      "Official name": "UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND",
      "Abbreviated name": "UNITED KINGDOM",
      "Unofficial name": ""
  },
  {
      "TIS_N": "834",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "TZ",
      "TIS_A_Ext": "TZA",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "TANZANIA, UNITED REPUBLIC OF",
      "Official name": "UNITED REPUBLIC OF TANZANIA",
      "Abbreviated name": "TANZANIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "840",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "TIS-N 840 UNITED STATES IN THE MEANING OF THIS STANDARD DOES NOT INCLUDE PUERTO RICO. CF. ENTRY TIS-N 630 PUERTO RICO.",
      "TIS_A": "US",
      "TIS_A_Ext": "USA",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "UNITED STATES",
      "Official name": "UNITED STATES OF AMERICA",
      "Abbreviated name": "USA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "854",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "BF",
      "TIS_A_Ext": "BFA",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "BURKINA FASO",
      "Official name": "",
      "Abbreviated name": "BURKINA FASO",
      "Unofficial name": ""
  },
  {
      "TIS_N": "858",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "UY",
      "TIS_A_Ext": "URY",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "URUGUAY",
      "Official name": "EASTERN REPUBLIC OF URUGUAY",
      "Abbreviated name": "URUGUAY",
      "Unofficial name": ""
  },
  {
      "TIS_N": "860",
      "Exists from": "1/1/1992",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "UZ",
      "TIS_A_Ext": "UZB",
      "Name from": "1/1/1992",
      "Name until": "31/12/3999",
      "Name": "UZBEKISTAN",
      "Official name": "REPUBLIC OF UZBEKISTAN",
      "Abbreviated name": "UZBEKISTAN",
      "Unofficial name": ""
  },
  {
      "TIS_N": "862",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "VE",
      "TIS_A_Ext": "VEN",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "VENEZUELA",
      "Official name": "BOLIVARIAN REPUBLIC OF VENEZUELA",
      "Abbreviated name": "VENEZUELA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "882",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "WS",
      "TIS_A_Ext": "WSM",
      "Name from": "01.01.1000",
      "Name until": "8/10/1997",
      "Name": "SAMOA",
      "Official name": "INDEPENDENT STATE OF WESTERN SAMOA",
      "Abbreviated name": "SAMOA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "882",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "WS",
      "TIS_A_Ext": "WSM",
      "Name from": "9/10/1997",
      "Name until": "31/12/3999",
      "Name": "SAMOA",
      "Official name": "INDEPENDENT STATE OF SAMOA",
      "Abbreviated name": "SAMOA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "887",
      "Exists from": "22/5/1990",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "YE",
      "TIS_A_Ext": "YEM",
      "Name from": "22/5/1990",
      "Name until": "31/12/3999",
      "Name": "YEMEN",
      "Official name": "REPUBLIC OF YEMEN",
      "Abbreviated name": "YEMEN",
      "Unofficial name": ""
  },
  {
      "TIS_N": "894",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "LND",
      "Remark": "",
      "TIS_A": "ZM",
      "TIS_A_Ext": "ZMB",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "ZAMBIA",
      "Official name": "REPUBLIC OF ZAMBIA",
      "Abbreviated name": "ZAMBIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2100",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "GLG",
      "Remark": "",
      "TIS_A": "2AF",
      "TIS_A_Ext": "",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "AFRICA",
      "Official name": "",
      "Abbreviated name": "AFRICA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2101",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "GLG",
      "Remark": "",
      "TIS_A": "2AM",
      "TIS_A_Ext": "",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "AMERICA",
      "Official name": "",
      "Abbreviated name": "AMERICA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2102",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "GLG",
      "Remark": "",
      "TIS_A": "2AC",
      "TIS_A_Ext": "",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "AMERICAN CONTINENT",
      "Official name": "",
      "Abbreviated name": "AMERICAN CONT.",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2103",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "GLG",
      "Remark": "",
      "TIS_A": "2AN",
      "TIS_A_Ext": "",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "ANTILLES",
      "Official name": "",
      "Abbreviated name": "ANTILLES",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2104",
      "Exists from": "7/11/1989",
      "Exists until": "31/12/3999",
      "Type": "WLG",
      "Remark": "",
      "TIS_A": "2AP",
      "TIS_A_Ext": "",
      "Name from": "7/11/1989",
      "Name until": "31/12/3999",
      "Name": "APEC COUNTRIES",
      "Official name": "",
      "Abbreviated name": "APEC",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2105",
      "Exists from": "8/8/1967",
      "Exists until": "31/12/3999",
      "Type": "WLG",
      "Remark": "",
      "TIS_A": "2AE",
      "TIS_A_Ext": "",
      "Name from": "8/8/1967",
      "Name until": "31/12/3999",
      "Name": "ASEAN COUNTRIES",
      "Official name": "",
      "Abbreviated name": "ASEAN",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2106",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "GLG",
      "Remark": "",
      "TIS_A": "2AS",
      "TIS_A_Ext": "",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "ASIA",
      "Official name": "",
      "Abbreviated name": "ASIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2107",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "GLG",
      "Remark": "",
      "TIS_A": "2AA",
      "TIS_A_Ext": "",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "AUSTRALASIA",
      "Official name": "",
      "Abbreviated name": "AUSTRALASIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2108",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "GLG",
      "Remark": "",
      "TIS_A": "2BA",
      "TIS_A_Ext": "",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "BALKANS",
      "Official name": "",
      "Abbreviated name": "BALKANS",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2109",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "GLG",
      "Remark": "",
      "TIS_A": "2BS",
      "TIS_A_Ext": "",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "BALTIC STATES",
      "Official name": "",
      "Abbreviated name": "BALTIC STATES",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2110",
      "Exists from": "1/1/1948",
      "Exists until": "31/12/3999",
      "Type": "WLG",
      "Remark": "",
      "TIS_A": "2BE",
      "TIS_A_Ext": "",
      "Name from": "1/1/1948",
      "Name until": "31/12/3999",
      "Name": "BENELUX",
      "Official name": "",
      "Abbreviated name": "BENELUX",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2111",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "GLG",
      "Remark": "",
      "TIS_A": "2BI",
      "TIS_A_Ext": "",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "BRITISH ISLES",
      "Official name": "",
      "Abbreviated name": "BRITISH ISLES",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2112",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "PLG",
      "Remark": "",
      "TIS_A": "2BW",
      "TIS_A_Ext": "",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "BRITISH WEST INDIES",
      "Official name": "",
      "Abbreviated name": "BRIT.WEST IND.",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2113",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "GLG",
      "Remark": "",
      "TIS_A": "2CA",
      "TIS_A_Ext": "",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "CENTRAL AMERICA",
      "Official name": "",
      "Abbreviated name": "CENTRAL AMERICA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2114",
      "Exists from": "1/1/1931",
      "Exists until": "31/12/3999",
      "Type": "PLG",
      "Remark": "",
      "TIS_A": "2CO",
      "TIS_A_Ext": "",
      "Name from": "1/1/1931",
      "Name until": "31/12/3999",
      "Name": "COMMONWEALTH",
      "Official name": "",
      "Abbreviated name": "COMMONWEALTH",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2115",
      "Exists from": "1/1/1931",
      "Exists until": "31/12/3999",
      "Type": "PLG",
      "Remark": "",
      "TIS_A": "2CF",
      "TIS_A_Ext": "",
      "Name from": "1/1/1931",
      "Name until": "31/12/3999",
      "Name": "COMMONWEALTH AFRICAN TERRITORIES",
      "Official name": "",
      "Abbreviated name": "CO AFRICA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2116",
      "Exists from": "1/1/1931",
      "Exists until": "31/12/3999",
      "Type": "PLG",
      "Remark": "",
      "TIS_A": "2CS",
      "TIS_A_Ext": "",
      "Name from": "1/1/1931",
      "Name until": "31/12/3999",
      "Name": "COMMONWEALTH ASIAN TERRITORIES",
      "Official name": "",
      "Abbreviated name": "CO ASIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2117",
      "Exists from": "1/1/1931",
      "Exists until": "31/12/3999",
      "Type": "PLG",
      "Remark": "",
      "TIS_A": "2CU",
      "TIS_A_Ext": "",
      "Name from": "1/1/1931",
      "Name until": "31/12/3999",
      "Name": "COMMONWEALTH AUSTRALASIAN TERRITORIES",
      "Official name": "",
      "Abbreviated name": "CO AUSTRALASIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2118",
      "Exists from": "1/1/1992",
      "Exists until": "31/12/3999",
      "Type": "PLG",
      "Remark": "",
      "TIS_A": "2CI",
      "TIS_A_Ext": "",
      "Name from": "1/1/1992",
      "Name until": "31/12/3999",
      "Name": "COMMONWEALTH OF INDEPENDENT STATES",
      "Official name": "",
      "Abbreviated name": "CIS",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2119",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "GLG",
      "Remark": "",
      "TIS_A": "2EE",
      "TIS_A_Ext": "",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "EASTERN EUROPE",
      "Official name": "",
      "Abbreviated name": "EASTERN EUROPE",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2120",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "GLG",
      "Remark": "",
      "TIS_A": "2EU",
      "TIS_A_Ext": "",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "EUROPE",
      "Official name": "",
      "Abbreviated name": "EUROPE",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2121",
      "Exists from": "1/1/1994",
      "Exists until": "31/12/3999",
      "Type": "WLG",
      "Remark": "",
      "TIS_A": "2EM",
      "TIS_A_Ext": "",
      "Name from": "1/1/1994",
      "Name until": "31/12/3999",
      "Name": "EUROPEAN ECONOMIC AREA",
      "Official name": "",
      "Abbreviated name": "EUROP.ECON.AREA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2122",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "GLG",
      "Remark": "",
      "TIS_A": "2EC",
      "TIS_A_Ext": "",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "EUROPEAN CONTINENT",
      "Official name": "",
      "Abbreviated name": "EUROPEAN CONT.",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2123",
      "Exists from": "1/1/1958",
      "Exists until": "31/12/3999",
      "Type": "PLG",
      "Remark": "",
      "TIS_A": "2EY",
      "TIS_A_Ext": "",
      "Name from": "1/1/1958",
      "Name until": "31/10/1993",
      "Name": "EUROPEAN ECONOMIC COMMUNITY",
      "Official name": "",
      "Abbreviated name": "EEC",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2123",
      "Exists from": "1/1/1958",
      "Exists until": "31/12/3999",
      "Type": "PLG",
      "Remark": "",
      "TIS_A": "2EN",
      "TIS_A_Ext": "",
      "Name from": "1/11/1993",
      "Name until": "31/12/3999",
      "Name": "EUROPEAN UNION",
      "Official name": "",
      "Abbreviated name": "EUROPEAN UNION",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2124",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "GLG",
      "Remark": "",
      "TIS_A": "2GC",
      "TIS_A_Ext": "",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "GSA COUNTRIES",
      "Official name": "",
      "Abbreviated name": "GSA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2125",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "GLG",
      "Remark": "",
      "TIS_A": "2ME",
      "TIS_A_Ext": "",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "MIDDLE EAST",
      "Official name": "",
      "Abbreviated name": "MIDDLE EAST",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2126",
      "Exists from": "1/1/1994",
      "Exists until": "31/12/3999",
      "Type": "WLG",
      "Remark": "",
      "TIS_A": "2NT",
      "TIS_A_Ext": "",
      "Name from": "1/1/1994",
      "Name until": "31/12/3999",
      "Name": "NAFTA COUNTRIES",
      "Official name": "",
      "Abbreviated name": "NAFTA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2127",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "GLG",
      "Remark": "",
      "TIS_A": "2NC",
      "TIS_A_Ext": "",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "NORDIC COUNTRIES",
      "Official name": "",
      "Abbreviated name": "NORDIC COUNTR.",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2128",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "GLG",
      "Remark": "",
      "TIS_A": "2NF",
      "TIS_A_Ext": "",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "NORTH AFRICA",
      "Official name": "",
      "Abbreviated name": "NORTH AFRICA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2129",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "GLG",
      "Remark": "",
      "TIS_A": "2NA",
      "TIS_A_Ext": "",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "NORTH AMERICA",
      "Official name": "",
      "Abbreviated name": "NORTH AMERICA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2130",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "GLG",
      "Remark": "",
      "TIS_A": "2OC",
      "TIS_A_Ext": "",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "OCEANIA",
      "Official name": "",
      "Abbreviated name": "OCEANIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2131",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "GLG",
      "Remark": "",
      "TIS_A": "2SC",
      "TIS_A_Ext": "",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "SCANDINAVIA",
      "Official name": "",
      "Abbreviated name": "SCANDINAVIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2132",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "GLG",
      "Remark": "",
      "TIS_A": "2SA",
      "TIS_A_Ext": "",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "SOUTH AMERICA",
      "Official name": "",
      "Abbreviated name": "SOUTH AMERICA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2133",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "GLG",
      "Remark": "",
      "TIS_A": "2SE",
      "TIS_A_Ext": "",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "SOUTH EAST ASIA",
      "Official name": "",
      "Abbreviated name": "SOUTH EAST ASIA",
      "Unofficial name": ""
  },
  {
      "TIS_N": "2134",
      "Exists from": "01.01.1000",
      "Exists until": "31/12/3999",
      "Type": "GLG",
      "Remark": "",
      "TIS_A": "2WI",
      "TIS_A_Ext": "",
      "Name from": "01.01.1000",
      "Name until": "31/12/3999",
      "Name": "WEST INDIES",
      "Official name": "",
      "Abbreviated name": "WEST INDIES",
      "Unofficial name": ""
  },
  {
    "TIS_N":2135,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"AFXX",
    "TIS_A_Ext":"AFG",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: AFGHANISTAN",
    "Official name":"",
    "Abbreviated name":"AFGHANISTAN",
    "Unofficial name":""
},
{
    "TIS_N":2136,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"ALXX",
    "TIS_A_Ext":"ALB",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: ALBANIA",
    "Official name":"REPUBLIC OF ALBANIA",
    "Abbreviated name":"ALBANIA",
    "Unofficial name":""
},
{
    "TIS_N":2137,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"DZXX",
    "TIS_A_Ext":"DZA",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: ALGERIA",
    "Official name":"PEOPLE'S DEMOCRATIC REPUBLIC OF ALGERIA",
    "Abbreviated name":"ALGERIA",
    "Unofficial name":""
},
{
    "TIS_N":2138,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"ADXX",
    "TIS_A_Ext":"AND",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: ANDORRA",
    "Official name":"PRINCIPALITY OF ANDORRA",
    "Abbreviated name":"ANDORRA",
    "Unofficial name":""
},
{
    "TIS_N":2139,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"AOXX",
    "TIS_A_Ext":"AGO",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: ANGOLA",
    "Official name":"REPUBLIC OF ANGOLA",
    "Abbreviated name":"ANGOLA",
    "Unofficial name":""
},
{
    "TIS_N":2140,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"AGXX",
    "TIS_A_Ext":"ATG",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: ANTIGUA AND BARBUDA",
    "Official name":"",
    "Abbreviated name":"ANTIGUA+BARBUDA",
    "Unofficial name":""
},
{
    "TIS_N":2141,
    "Exists from":"1/1/1992",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"AZXX",
    "TIS_A_Ext":"AZE",
    "Name from":"1/1/1992",
    "Name until":"31/12/3999",
    "Name":"Exclude: AZERBAIJAN",
    "Official name":"REPUBLIC OF AZERBAIJAN",
    "Abbreviated name":"AZERBAIJAN",
    "Unofficial name":""
},
{
    "TIS_N":2142,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"ARXX",
    "TIS_A_Ext":"ARG",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: ARGENTINA",
    "Official name":"ARGENTINE REPUBLIC",
    "Abbreviated name":"ARGENTINA",
    "Unofficial name":""
},
{
    "TIS_N":2143,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"AUXX",
    "TIS_A_Ext":"AUS",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: AUSTRALIA",
    "Official name":"",
    "Abbreviated name":"AUSTRALIA",
    "Unofficial name":""
},
{
    "TIS_N":2144,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"ATXX",
    "TIS_A_Ext":"AUT",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: AUSTRIA",
    "Official name":"REPUBLIC OF AUSTRIA",
    "Abbreviated name":"AUSTRIA",
    "Unofficial name":""
},
{
    "TIS_N":2145,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"BSXX",
    "TIS_A_Ext":"BHS",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: BAHAMAS",
    "Official name":"COMMONWEALTH OF THE BAHAMAS",
    "Abbreviated name":"BAHAMAS",
    "Unofficial name":""
},
{
    "TIS_N":2146,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"BHXX",
    "TIS_A_Ext":"BHR",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: BAHRAIN",
    "Official name":"KINGDOM OF BAHRAIN",
    "Abbreviated name":"BAHRAIN",
    "Unofficial name":""
},
{
    "TIS_N":2147,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"BDXX",
    "TIS_A_Ext":"BGD",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: BANGLADESH",
    "Official name":"PEOPLE'S REPUBLIC OF BANGLADESH",
    "Abbreviated name":"BANGLADESH",
    "Unofficial name":""
},
{
    "TIS_N":2148,
    "Exists from":"1/1/1992",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"AMXX",
    "TIS_A_Ext":"ARM",
    "Name from":"1/1/1992",
    "Name until":"31/12/3999",
    "Name":"Exclude: ARMENIA",
    "Official name":"REPUBLIC OF ARMENIA",
    "Abbreviated name":"ARMENIA",
    "Unofficial name":""
},
{
    "TIS_N":2149,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"BBXX",
    "TIS_A_Ext":"BRB",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: BARBADOS",
    "Official name":"",
    "Abbreviated name":"BARBADOS",
    "Unofficial name":""
},
{
    "TIS_N":2150,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"BEXX",
    "TIS_A_Ext":"BEL",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: BELGIUM",
    "Official name":"KINGDOM OF BELGIUM",
    "Abbreviated name":"BELGIUM",
    "Unofficial name":""
},
{
    "TIS_N":2151,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"BTXX",
    "TIS_A_Ext":"BTN",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: BHUTAN",
    "Official name":"KINGDOM OF BHUTAN",
    "Abbreviated name":"BHUTAN",
    "Unofficial name":""
},
{
    "TIS_N":2152,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"BOXX",
    "TIS_A_Ext":"BOL",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: BOLIVIA",
    "Official name":"PLURINATIONAL STATE OF BOLIVIA",
    "Abbreviated name":"BOLIVIA",
    "Unofficial name":""
},
{
    "TIS_N":2153,
    "Exists from":"1/1/1992",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"BAXX",
    "TIS_A_Ext":"BIH",
    "Name from":"1/1/1992",
    "Name until":"31/12/3999",
    "Name":"Exclude: BOSNIA AND HERZEGOVINA",
    "Official name":"",
    "Abbreviated name":"BOSNIA+HERZEGO.",
    "Unofficial name":""
},
{
    "TIS_N":2154,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"BWXX",
    "TIS_A_Ext":"BWA",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: BOTSWANA",
    "Official name":"REPUBLIC OF BOTSWANA",
    "Abbreviated name":"BOTSWANA",
    "Unofficial name":""
},
{
    "TIS_N":2155,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"BRXX",
    "TIS_A_Ext":"BRA",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: BRAZIL",
    "Official name":"FEDERATIVE REPUBLIC OF BRAZIL",
    "Abbreviated name":"BRAZIL",
    "Unofficial name":""
},
{
    "TIS_N":2156,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"BZXX",
    "TIS_A_Ext":"BLZ",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: BELIZE",
    "Official name":"",
    "Abbreviated name":"BELIZE",
    "Unofficial name":""
},
{
    "TIS_N":2157,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"SBXX",
    "TIS_A_Ext":"SLB",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: SOLOMON ISLANDS",
    "Official name":"",
    "Abbreviated name":"SOLOMON ISLANDS",
    "Unofficial name":""
},
{
    "TIS_N":2158,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"BNXX",
    "TIS_A_Ext":"BRN",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: BRUNEI DARUSSALAM",
    "Official name":"",
    "Abbreviated name":"BRUNEI",
    "Unofficial name":""
},
{
    "TIS_N":2159,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"BGXX",
    "TIS_A_Ext":"BGR",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: BULGARIA",
    "Official name":"REPUBLIC OF BULGARIA",
    "Abbreviated name":"BULGARIA",
    "Unofficial name":""
},
{
    "TIS_N":2160,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"BUXX",
    "TIS_A_Ext":"BUR",
    "Name from":"01.01.1000",
    "Name until":"31/12/1988",
    "Name":"Exclude: BURMA",
    "Official name":"",
    "Abbreviated name":"BURMA",
    "Unofficial name":""
},
{
    "TIS_N":2161,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"MMXX",
    "TIS_A_Ext":"MMR",
    "Name from":"1/1/1989",
    "Name until":"31/12/3999",
    "Name":"Exclude: MYANMAR",
    "Official name":"REPUBLIC OF THE UNION OF MYANMAR",
    "Abbreviated name":"MYANMAR",
    "Unofficial name":""
},
{
    "TIS_N":2162,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"BIXX",
    "TIS_A_Ext":"BDI",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: BURUNDI",
    "Official name":"REPUBLIC OF BURUNDI",
    "Abbreviated name":"BURUNDI",
    "Unofficial name":""
},
{
    "TIS_N":2163,
    "Exists from":"1/1/1992",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"BYXX",
    "TIS_A_Ext":"BLR",
    "Name from":"1/1/1992",
    "Name until":"31/12/3999",
    "Name":"Exclude: BELARUS",
    "Official name":"REPUBLIC OF BELARUS",
    "Abbreviated name":"BELARUS",
    "Unofficial name":""
},
{
    "TIS_N":2164,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"KHXX",
    "TIS_A_Ext":"KHM",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: CAMBODIA",
    "Official name":"KINGDOM OF CAMBODIA",
    "Abbreviated name":"CAMBODIA",
    "Unofficial name":""
},
{
    "TIS_N":2165,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"CMXX",
    "TIS_A_Ext":"CMR",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: CAMEROON",
    "Official name":"REPUBLIC OF CAMEROON",
    "Abbreviated name":"CAMEROON",
    "Unofficial name":""
},
{
    "TIS_N":2166,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"CAXX",
    "TIS_A_Ext":"CAN",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: CANADA",
    "Official name":"",
    "Abbreviated name":"CANADA",
    "Unofficial name":""
},
{
    "TIS_N":2167,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"CVXX",
    "TIS_A_Ext":"CPV",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: CAPE VERDE",
    "Official name":"REPUBLIC OF CAPE VERDE",
    "Abbreviated name":"CAPE VERDE",
    "Unofficial name":""
},
{
    "TIS_N":2168,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"CFXX",
    "TIS_A_Ext":"CAF",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: CENTRAL AFRICAN REPUBLIC",
    "Official name":"",
    "Abbreviated name":"CENT.AFRIC.REP.",
    "Unofficial name":""
},
{
    "TIS_N":2169,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"LKXX",
    "TIS_A_Ext":"LKA",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: SRI LANKA",
    "Official name":"DEMOCRATIC SOCIALIST REPUBLIC OF SRI LANKA",
    "Abbreviated name":"SRI LANKA",
    "Unofficial name":""
},
{
    "TIS_N":2170,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"TDXX",
    "TIS_A_Ext":"TCD",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: CHAD",
    "Official name":"REPUBLIC OF CHAD",
    "Abbreviated name":"CHAD",
    "Unofficial name":""
},
{
    "TIS_N":2171,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"CLXX",
    "TIS_A_Ext":"CHL",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: CHILE",
    "Official name":"REPUBLIC OF CHILE",
    "Abbreviated name":"CHILE",
    "Unofficial name":""
},
{
    "TIS_N":2172,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"TIS-N 156 CHINA IN THE MEANING OF THIS STANDARD DOES NOT INCLUDE HONG KONG. CF. ENTRY TIS-N 344 HONG KONG.",
    "TIS_A":"CNXX",
    "TIS_A_Ext":"CHN",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: CHINA",
    "Official name":"PEOPLE'S REPUBLIC OF CHINA",
    "Abbreviated name":"CHINA",
    "Unofficial name":""
},
{
    "TIS_N":2173,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"TWXX",
    "TIS_A_Ext":"TWN",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: TAIWAN, PROVINCE OF CHINA",
    "Official name":"",
    "Abbreviated name":"TAIWAN",
    "Unofficial name":""
},
{
    "TIS_N":2174,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"COXX",
    "TIS_A_Ext":"COL",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: COLOMBIA",
    "Official name":"REPUBLIC OF COLOMBIA",
    "Abbreviated name":"COLOMBIA",
    "Unofficial name":""
},
{
    "TIS_N":2175,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"THIS ENTRY REFERS TO THE STATE COMOROS, NOT TO THE ARCHIPELAGO WITH THAT NAME. IT COMPRISES THEREFORE ANJOUAN, GRANDE COMORE AND MOHELI.",
    "TIS_A":"KMXX",
    "TIS_A_Ext":"COM",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: COMOROS",
    "Official name":"UNION OF THE COMOROS",
    "Abbreviated name":"COMOROS",
    "Unofficial name":""
},
{
    "TIS_N":2176,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"CGXX",
    "TIS_A_Ext":"COG",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: CONGO",
    "Official name":"REPUBLIC OF THE CONGO",
    "Abbreviated name":"CONGO",
    "Unofficial name":""
},
{
    "TIS_N":2177,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"ZRXX",
    "TIS_A_Ext":"ZAR",
    "Name from":"01.01.1000",
    "Name until":"29/5/1997",
    "Name":"Exclude: ZAIRE",
    "Official name":"REPUBLIC OF ZAIRE",
    "Abbreviated name":"ZAIRE",
    "Unofficial name":""
},
{
    "TIS_N":2178,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"CDXX",
    "TIS_A_Ext":"COD",
    "Name from":"30/5/1997",
    "Name until":"31/12/3999",
    "Name":"Exclude: CONGO, THE DEMOCRATIC REPUBLIC OF THE",
    "Official name":"THE DEMOCRATIC REPUBLIC OF THE CONGO",
    "Abbreviated name":"DEMOC.REP.CONGO",
    "Unofficial name":""
},
{
    "TIS_N":2179,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"CRXX",
    "TIS_A_Ext":"CRI",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: COSTA RICA",
    "Official name":"REPUBLIC OF COSTA RICA",
    "Abbreviated name":"COSTA RICA",
    "Unofficial name":""
},
{
    "TIS_N":2180,
    "Exists from":"1/1/1992",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"HRXX",
    "TIS_A_Ext":"HRV",
    "Name from":"1/1/1992",
    "Name until":"31/12/3999",
    "Name":"Exclude: CROATIA",
    "Official name":"REPUBLIC OF CROATIA",
    "Abbreviated name":"CROATIA",
    "Unofficial name":""
},
{
    "TIS_N":2181,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"CUXX",
    "TIS_A_Ext":"CUB",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: CUBA",
    "Official name":"REPUBLIC OF CUBA",
    "Abbreviated name":"CUBA",
    "Unofficial name":""
},
{
    "TIS_N":2182,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"CYXX",
    "TIS_A_Ext":"CYP",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: CYPRUS",
    "Official name":"REPUBLIC OF CYPRUS",
    "Abbreviated name":"CYPRUS",
    "Unofficial name":""
},
{
    "TIS_N":2183,
    "Exists from":"1/1/1993",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"CZXX",
    "TIS_A_Ext":"CZE",
    "Name from":"1/1/1993",
    "Name until":"31/12/3999",
    "Name":"Exclude: CZECH REPUBLIC",
    "Official name":"",
    "Abbreviated name":"CZECH REPUBLIC",
    "Unofficial name":""
},
{
    "TIS_N":2184,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"BJXX",
    "TIS_A_Ext":"BEN",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: BENIN",
    "Official name":"REPUBLIC OF BENIN",
    "Abbreviated name":"BENIN",
    "Unofficial name":""
},
{
    "TIS_N":2185,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"DKXX",
    "TIS_A_Ext":"DNK",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: DENMARK",
    "Official name":"KINGDOM OF DENMARK",
    "Abbreviated name":"DENMARK",
    "Unofficial name":""
},
{
    "TIS_N":2186,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"DMXX",
    "TIS_A_Ext":"DMA",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: DOMINICA",
    "Official name":"COMMONWEALTH OF DOMINICA",
    "Abbreviated name":"DOMINICA",
    "Unofficial name":""
},
{
    "TIS_N":2187,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"DOXX",
    "TIS_A_Ext":"DOM",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: DOMINICAN REPUBLIC",
    "Official name":"",
    "Abbreviated name":"DOMINICAN REP.",
    "Unofficial name":""
},
{
    "TIS_N":2188,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"ECXX",
    "TIS_A_Ext":"ECU",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: ECUADOR",
    "Official name":"REPUBLIC OF ECUADOR",
    "Abbreviated name":"ECUADOR",
    "Unofficial name":""
},
{
    "TIS_N":2189,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"SVXX",
    "TIS_A_Ext":"SLV",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: EL SALVADOR",
    "Official name":"REPUBLIC OF EL SALVADOR",
    "Abbreviated name":"EL SALVADOR",
    "Unofficial name":""
},
{
    "TIS_N":2190,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"GQXX",
    "TIS_A_Ext":"GNQ",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: EQUATORIAL GUINEA",
    "Official name":"REPUBLIC OF EQUATORIAL GUINEA",
    "Abbreviated name":"EQUAT.GUINEA",
    "Unofficial name":""
},
{
    "TIS_N":2191,
    "Exists from":"24/5/1993",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"ETXX",
    "TIS_A_Ext":"ETH",
    "Name from":"24/5/1993",
    "Name until":"31/12/3999",
    "Name":"Exclude: ETHIOPIA",
    "Official name":"FEDERAL DEMOCRATIC REPUBLIC OF ETHIOPIA",
    "Abbreviated name":"ETHIOPIA",
    "Unofficial name":""
},
{
    "TIS_N":2192,
    "Exists from":"24/5/1993",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"ERXX",
    "TIS_A_Ext":"ERI",
    "Name from":"24/5/1993",
    "Name until":"31/12/3999",
    "Name":"Exclude: ERITREA",
    "Official name":"",
    "Abbreviated name":"ERITREA",
    "Unofficial name":""
},
{
    "TIS_N":2193,
    "Exists from":"1/1/1992",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"EEXX",
    "TIS_A_Ext":"EST",
    "Name from":"1/1/1992",
    "Name until":"31/12/3999",
    "Name":"Exclude: ESTONIA",
    "Official name":"REPUBLIC OF ESTONIA",
    "Abbreviated name":"ESTONIA",
    "Unofficial name":""
},
{
    "TIS_N":2194,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"FJXX",
    "TIS_A_Ext":"FJI",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: FIJI",
    "Official name":"REPUBLIC OF FIJI",
    "Abbreviated name":"FIJI",
    "Unofficial name":""
},
{
    "TIS_N":2195,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"FIXX",
    "TIS_A_Ext":"FIN",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: FINLAND",
    "Official name":"REPUBLIC OF FINLAND",
    "Abbreviated name":"FINLAND",
    "Unofficial name":""
},
{
    "TIS_N":2196,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"TIS-N 250 FRANCE IN THE MEANING OF THIS STANDARD DOES NOT INCLUDE FRENCH POLYNESIA AND NEW CALEDONIA. CF. ENTRIES TIS-N 258 FRENCH POLYNESIA AND TIS-N 540 NEW CALEDONIA.",
    "TIS_A":"FRXX",
    "TIS_A_Ext":"FRA",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: FRANCE",
    "Official name":"FRENCH REPUBLIC",
    "Abbreviated name":"FRANCE",
    "Unofficial name":""
},
{
    "TIS_N":2197,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"PFXX",
    "TIS_A_Ext":"PYF",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: FRENCH POLYNESIA",
    "Official name":"",
    "Abbreviated name":"FR.POLYNESIA",
    "Unofficial name":""
},
{
    "TIS_N":2198,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"DJXX",
    "TIS_A_Ext":"DJI",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: DJIBOUTI",
    "Official name":"REPUBLIC OF DJIBOUTI",
    "Abbreviated name":"DJIBOUTI",
    "Unofficial name":""
},
{
    "TIS_N":2199,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"GAXX",
    "TIS_A_Ext":"GAB",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: GABON",
    "Official name":"GABONESE REPUBLIC",
    "Abbreviated name":"GABON",
    "Unofficial name":""
},
{
    "TIS_N":2200,
    "Exists from":"1/1/1992",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"GEXX",
    "TIS_A_Ext":"GEO",
    "Name from":"1/1/1992",
    "Name until":"31/12/3999",
    "Name":"Exclude: GEORGIA",
    "Official name":"",
    "Abbreviated name":"GEORGIA",
    "Unofficial name":""
},
{
    "TIS_N":2201,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"GMXX",
    "TIS_A_Ext":"GMB",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: GAMBIA",
    "Official name":"REPUBLIC OF THE GAMBIA",
    "Abbreviated name":"GAMBIA",
    "Unofficial name":""
},
{
    "TIS_N":2202,
    "Exists from":"3/10/1990",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"DEXX",
    "TIS_A_Ext":"DEU",
    "Name from":"3/10/1990",
    "Name until":"31/12/3999",
    "Name":"Exclude: GERMANY",
    "Official name":"FEDERAL REPUBLIC OF GERMANY",
    "Abbreviated name":"GERMANY",
    "Unofficial name":""
},
{
    "TIS_N":2203,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"GHXX",
    "TIS_A_Ext":"GHA",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: GHANA",
    "Official name":"REPUBLIC OF GHANA",
    "Abbreviated name":"GHANA",
    "Unofficial name":""
},
{
    "TIS_N":2204,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"KIXX",
    "TIS_A_Ext":"KIR",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: KIRIBATI",
    "Official name":"REPUBLIC OF KIRIBATI",
    "Abbreviated name":"KIRIBATI",
    "Unofficial name":""
},
{
    "TIS_N":2205,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"GRXX",
    "TIS_A_Ext":"GRC",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: GREECE",
    "Official name":"HELLENIC REPUBLIC",
    "Abbreviated name":"GREECE",
    "Unofficial name":""
},
{
    "TIS_N":2206,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"GDXX",
    "TIS_A_Ext":"GRD",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: GRENADA",
    "Official name":"",
    "Abbreviated name":"GRENADA",
    "Unofficial name":""
},
{
    "TIS_N":2207,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"GTXX",
    "TIS_A_Ext":"GTM",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: GUATEMALA",
    "Official name":"REPUBLIC OF GUATEMALA",
    "Abbreviated name":"GUATEMALA",
    "Unofficial name":""
},
{
    "TIS_N":2208,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"GNXX",
    "TIS_A_Ext":"GIN",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: GUINEA",
    "Official name":"REPUBLIC OF GUINEA",
    "Abbreviated name":"GUINEA",
    "Unofficial name":""
},
{
    "TIS_N":2209,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"GYXX",
    "TIS_A_Ext":"GUY",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: GUYANA",
    "Official name":"REPUBLIC OF GUYANA",
    "Abbreviated name":"GUYANA",
    "Unofficial name":""
},
{
    "TIS_N":2210,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"HTXX",
    "TIS_A_Ext":"HTI",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: HAITI",
    "Official name":"REPUBLIC OF HAITI",
    "Abbreviated name":"HAITI",
    "Unofficial name":""
},
{
    "TIS_N":2211,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"VAXX",
    "TIS_A_Ext":"VAT",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: HOLY SEE (VATICAN CITY STATE)",
    "Official name":"",
    "Abbreviated name":"VATICAN",
    "Unofficial name":""
},
{
    "TIS_N":2212,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"HNXX",
    "TIS_A_Ext":"HND",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: HONDURAS",
    "Official name":"REPUBLIC OF HONDURAS",
    "Abbreviated name":"HONDURAS",
    "Unofficial name":""
},
{
    "TIS_N":2213,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"HKXX",
    "TIS_A_Ext":"HKG",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: HONG KONG",
    "Official name":"HONG KONG SPECIAL ADMINISTRATIVE REGION OF CHINA",
    "Abbreviated name":"HONG KONG",
    "Unofficial name":""
},
{
    "TIS_N":2214,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"HUXX",
    "TIS_A_Ext":"HUN",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: HUNGARY",
    "Official name":"HUNGARY",
    "Abbreviated name":"HUNGARY",
    "Unofficial name":""
},
{
    "TIS_N":2215,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"ISXX",
    "TIS_A_Ext":"ISL",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: ICELAND",
    "Official name":"REPUBLIC OF ICELAND",
    "Abbreviated name":"ICELAND",
    "Unofficial name":""
},
{
    "TIS_N":2216,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"INXX",
    "TIS_A_Ext":"IND",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: INDIA",
    "Official name":"REPUBLIC OF INDIA",
    "Abbreviated name":"INDIA",
    "Unofficial name":""
},
{
    "TIS_N":2217,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"IDXX",
    "TIS_A_Ext":"IDN",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: INDONESIA",
    "Official name":"REPUBLIC OF INDONESIA",
    "Abbreviated name":"INDONESIA",
    "Unofficial name":""
},
{
    "TIS_N":2218,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"IRXX",
    "TIS_A_Ext":"IRN",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: IRAN, ISLAMIC REPUBLIC OF",
    "Official name":"ISLAMIC REPUBLIC OF IRAN",
    "Abbreviated name":"IRAN",
    "Unofficial name":""
},
{
    "TIS_N":2219,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"IQXX",
    "TIS_A_Ext":"IRQ",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: IRAQ",
    "Official name":"REPUBLIC OF IRAQ",
    "Abbreviated name":"IRAQ",
    "Unofficial name":""
},
{
    "TIS_N":2220,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"IEXX",
    "TIS_A_Ext":"IRL",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: IRELAND",
    "Official name":"",
    "Abbreviated name":"IRELAND",
    "Unofficial name":""
},
{
    "TIS_N":2221,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"ILXX",
    "TIS_A_Ext":"ISR",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: ISRAEL",
    "Official name":"STATE OF ISRAEL",
    "Abbreviated name":"ISRAEL",
    "Unofficial name":""
},
{
    "TIS_N":2222,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"ITXX",
    "TIS_A_Ext":"ITA",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: ITALY",
    "Official name":"ITALIAN REPUBLIC",
    "Abbreviated name":"ITALY",
    "Unofficial name":""
},
{
    "TIS_N":2223,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"CIXX",
    "TIS_A_Ext":"CIV",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: COTE D'IVOIRE",
    "Official name":"REPUBLIC OF COTE D'IVOIRE",
    "Abbreviated name":"COTE D'IVOIRE",
    "Unofficial name":""
},
{
    "TIS_N":2224,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"JMXX",
    "TIS_A_Ext":"JAM",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: JAMAICA",
    "Official name":"",
    "Abbreviated name":"JAMAICA",
    "Unofficial name":""
},
{
    "TIS_N":2225,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"JPXX",
    "TIS_A_Ext":"JPN",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: JAPAN",
    "Official name":"",
    "Abbreviated name":"JAPAN",
    "Unofficial name":""
},
{
    "TIS_N":2226,
    "Exists from":"1/1/1992",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"KZXX",
    "TIS_A_Ext":"KAZ",
    "Name from":"1/1/1992",
    "Name until":"31/12/3999",
    "Name":"Exclude: KAZAKHSTAN",
    "Official name":"REPUBLIC OF KAZAKHSTAN",
    "Abbreviated name":"KAZAKHSTAN",
    "Unofficial name":""
},
{
    "TIS_N":2227,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"JOXX",
    "TIS_A_Ext":"JOR",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: JORDAN",
    "Official name":"HASHEMITE KINGDOM OF JORDAN",
    "Abbreviated name":"JORDAN",
    "Unofficial name":""
},
{
    "TIS_N":2228,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"KEXX",
    "TIS_A_Ext":"KEN",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: KENYA",
    "Official name":"REPUBLIC OF KENYA",
    "Abbreviated name":"KENYA",
    "Unofficial name":""
},
{
    "TIS_N":2229,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"KPXX",
    "TIS_A_Ext":"PRK",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF",
    "Official name":"DEMOCRATIC PEOPLE'S REPUBLIC OF KOREA",
    "Abbreviated name":"NORTH KOREA",
    "Unofficial name":""
},
{
    "TIS_N":2230,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"KRXX",
    "TIS_A_Ext":"KOR",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: KOREA, REPUBLIC OF",
    "Official name":"REPUBLIC OF KOREA",
    "Abbreviated name":"SOUTH KOREA",
    "Unofficial name":""
},
{
    "TIS_N":2231,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"KWXX",
    "TIS_A_Ext":"KWT",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: KUWAIT",
    "Official name":"STATE OF KUWAIT",
    "Abbreviated name":"KUWAIT",
    "Unofficial name":""
},
{
    "TIS_N":2232,
    "Exists from":"1/1/1992",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"KGXX",
    "TIS_A_Ext":"KGZ",
    "Name from":"1/1/1992",
    "Name until":"31/12/3999",
    "Name":"Exclude: KYRGYZSTAN",
    "Official name":"KYRGYZ REPUBLIC",
    "Abbreviated name":"KYRGYZSTAN",
    "Unofficial name":""
},
{
    "TIS_N":2233,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"LAXX",
    "TIS_A_Ext":"LAO",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: LAO PEOPLE'S DEMOCRATIC REPUBLIC",
    "Official name":"",
    "Abbreviated name":"LAO",
    "Unofficial name":""
},
{
    "TIS_N":2234,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"LBXX",
    "TIS_A_Ext":"LBN",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: LEBANON",
    "Official name":"LEBANESE REPUBLIC",
    "Abbreviated name":"LEBANON",
    "Unofficial name":""
},
{
    "TIS_N":2235,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"LSXX",
    "TIS_A_Ext":"LSO",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: LESOTHO",
    "Official name":"KINGDOM OF LESOTHO",
    "Abbreviated name":"LESOTHO",
    "Unofficial name":""
},
{
    "TIS_N":2236,
    "Exists from":"1/1/1992",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"LVXX",
    "TIS_A_Ext":"LVA",
    "Name from":"1/1/1992",
    "Name until":"31/12/3999",
    "Name":"Exclude: LATVIA",
    "Official name":"REPUBLIC OF LATVIA",
    "Abbreviated name":"LATVIA",
    "Unofficial name":""
},
{
    "TIS_N":2237,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"LRXX",
    "TIS_A_Ext":"LBR",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: LIBERIA",
    "Official name":"REPUBLIC OF LIBERIA",
    "Abbreviated name":"LIBERIA",
    "Unofficial name":""
},
{
    "TIS_N":2238,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"LYXX",
    "TIS_A_Ext":"LBY",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: LIBYA",
    "Official name":"STATE OF LIBYA",
    "Abbreviated name":"LIBYA",
    "Unofficial name":""
},
{
    "TIS_N":2239,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"LIXX",
    "TIS_A_Ext":"LIE",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: LIECHTENSTEIN",
    "Official name":"PRINCIPALITY OF LIECHTENSTEIN",
    "Abbreviated name":"LIECHTENSTEIN",
    "Unofficial name":""
},
{
    "TIS_N":2240,
    "Exists from":"1/1/1992",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"LTXX",
    "TIS_A_Ext":"LTU",
    "Name from":"1/1/1992",
    "Name until":"31/12/3999",
    "Name":"Exclude: LITHUANIA",
    "Official name":"REPUBLIC OF LITHUANIA",
    "Abbreviated name":"LITHUANIA",
    "Unofficial name":""
},
{
    "TIS_N":2241,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"LUXX",
    "TIS_A_Ext":"LUX",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: LUXEMBOURG",
    "Official name":"GRAND DUCHY OF LUXEMBOURG",
    "Abbreviated name":"LUXEMBOURG",
    "Unofficial name":""
},
{
    "TIS_N":2242,
    "Exists from":"20/12/1999",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"MOXX",
    "TIS_A_Ext":"MAC",
    "Name from":"20/12/1999",
    "Name until":"31/12/3999",
    "Name":"Exclude: MACAO",
    "Official name":"MACAO SPECIAL ADMINISTRATIVE REGION OF CHINA",
    "Abbreviated name":"MACAO",
    "Unofficial name":""
},
{
    "TIS_N":2243,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"MGXX",
    "TIS_A_Ext":"MDG",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: MADAGASCAR",
    "Official name":"REPUBLIC OF MADAGASCAR",
    "Abbreviated name":"MADAGASCAR",
    "Unofficial name":""
},
{
    "TIS_N":2244,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"MWXX",
    "TIS_A_Ext":"MWI",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: MALAWI",
    "Official name":"REPUBLIC OF MALAWI",
    "Abbreviated name":"MALAWI",
    "Unofficial name":""
},
{
    "TIS_N":2245,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"MYXX",
    "TIS_A_Ext":"MYS",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: MALAYSIA",
    "Official name":"",
    "Abbreviated name":"MALAYSIA",
    "Unofficial name":""
},
{
    "TIS_N":2246,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"MVXX",
    "TIS_A_Ext":"MDV",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: MALDIVES",
    "Official name":"REPUBLIC OF MALDIVES",
    "Abbreviated name":"MALDIVES",
    "Unofficial name":""
},
{
    "TIS_N":2247,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"MLXX",
    "TIS_A_Ext":"MLI",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: MALI",
    "Official name":"REPUBLIC OF MALI",
    "Abbreviated name":"MALI",
    "Unofficial name":""
},
{
    "TIS_N":2248,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"MTXX",
    "TIS_A_Ext":"MLT",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: MALTA",
    "Official name":"REPUBLIC OF MALTA",
    "Abbreviated name":"MALTA",
    "Unofficial name":""
},
{
    "TIS_N":2249,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"MRXX",
    "TIS_A_Ext":"MRT",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: MAURITANIA",
    "Official name":"ISLAMIC REPUBLIC OF MAURITANIA",
    "Abbreviated name":"MAURITANIA",
    "Unofficial name":""
},
{
    "TIS_N":2250,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"MUXX",
    "TIS_A_Ext":"MUS",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: MAURITIUS",
    "Official name":"REPUBLIC OF MAURITIUS",
    "Abbreviated name":"MAURITIUS",
    "Unofficial name":""
},
{
    "TIS_N":2251,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"MXXX",
    "TIS_A_Ext":"MEX",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: MEXICO",
    "Official name":"UNITED MEXICAN STATES",
    "Abbreviated name":"MEXICO",
    "Unofficial name":""
},
{
    "TIS_N":2252,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"MCXX",
    "TIS_A_Ext":"MCO",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: MONACO",
    "Official name":"PRINCIPALITY OF MONACO",
    "Abbreviated name":"MONACO",
    "Unofficial name":""
},
{
    "TIS_N":2253,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"MNXX",
    "TIS_A_Ext":"MNG",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: MONGOLIA",
    "Official name":"",
    "Abbreviated name":"MONGOLIA",
    "Unofficial name":""
},
{
    "TIS_N":2254,
    "Exists from":"1/1/1992",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"MDXX",
    "TIS_A_Ext":"MDA",
    "Name from":"1/1/1992",
    "Name until":"31/12/3999",
    "Name":"Exclude: MOLDOVA, REPUBLIC OF",
    "Official name":"REPUBLIC OF MOLDOVA",
    "Abbreviated name":"MOLDOVA",
    "Unofficial name":""
},
{
    "TIS_N":2255,
    "Exists from":"3/6/2006",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"MEXX",
    "TIS_A_Ext":"MNE",
    "Name from":"3/6/2006",
    "Name until":"31/12/3999",
    "Name":"Exclude: MONTENEGRO",
    "Official name":"MONTENEGRO",
    "Abbreviated name":"MONTENEGRO",
    "Unofficial name":""
},
{
    "TIS_N":2256,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"MAXX",
    "TIS_A_Ext":"MAR",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: MOROCCO",
    "Official name":"KINGDOM OF MOROCCO",
    "Abbreviated name":"MOROCCO",
    "Unofficial name":""
},
{
    "TIS_N":2257,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"MZXX",
    "TIS_A_Ext":"MOZ",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: MOZAMBIQUE",
    "Official name":"REPUBLIC OF MOZAMBIQUE",
    "Abbreviated name":"MOZAMBIQUE",
    "Unofficial name":""
},
{
    "TIS_N":2258,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"OMXX",
    "TIS_A_Ext":"OMN",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: OMAN",
    "Official name":"SULTANATE OF OMAN",
    "Abbreviated name":"OMAN",
    "Unofficial name":""
},
{
    "TIS_N":2259,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"NAXX",
    "TIS_A_Ext":"NAM",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: NAMIBIA",
    "Official name":"REPUBLIC OF NAMIBIA",
    "Abbreviated name":"NAMIBIA",
    "Unofficial name":""
},
{
    "TIS_N":2260,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"NRXX",
    "TIS_A_Ext":"NRU",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: NAURU",
    "Official name":"REPUBLIC OF NAURU",
    "Abbreviated name":"NAURU",
    "Unofficial name":""
},
{
    "TIS_N":2261,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"NPXX",
    "TIS_A_Ext":"NPL",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: NEPAL",
    "Official name":"FEDERAL DEMOCRATIC REPUBLIC OF NEPAL",
    "Abbreviated name":"NEPAL",
    "Unofficial name":""
},
{
    "TIS_N":2262,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"NLXX",
    "TIS_A_Ext":"NLD",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: NETHERLANDS",
    "Official name":"KINGDOM OF THE NETHERLANDS",
    "Abbreviated name":"NETHERLANDS",
    "Unofficial name":""
},
{
    "TIS_N":2263,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"NCXX",
    "TIS_A_Ext":"NCL",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: NEW CALEDONIA",
    "Official name":"",
    "Abbreviated name":"NEW CALEDONIA",
    "Unofficial name":""
},
{
    "TIS_N":2264,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"VUXX",
    "TIS_A_Ext":"VUT",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: VANUATU",
    "Official name":"REPUBLIC OF VANUATU",
    "Abbreviated name":"VANUATU",
    "Unofficial name":""
},
{
    "TIS_N":2265,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"NZXX",
    "TIS_A_Ext":"NZL",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: NEW ZEALAND",
    "Official name":"",
    "Abbreviated name":"NEW ZEALAND",
    "Unofficial name":""
},
{
    "TIS_N":2266,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"NIXX",
    "TIS_A_Ext":"NIC",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: NICARAGUA",
    "Official name":"REPUBLIC OF NICARAGUA",
    "Abbreviated name":"NICARAGUA",
    "Unofficial name":""
},
{
    "TIS_N":2267,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"NEXX",
    "TIS_A_Ext":"NER",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: NIGER",
    "Official name":"REPUBLIC OF THE NIGER",
    "Abbreviated name":"NIGER",
    "Unofficial name":""
},
{
    "TIS_N":2268,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"NGXX",
    "TIS_A_Ext":"NGA",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: NIGERIA",
    "Official name":"FEDERAL REPUBLIC OF NIGERIA",
    "Abbreviated name":"NIGERIA",
    "Unofficial name":""
},
{
    "TIS_N":2269,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"NOXX",
    "TIS_A_Ext":"NOR",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: NORWAY",
    "Official name":"KINGDOM OF NORWAY",
    "Abbreviated name":"NORWAY",
    "Unofficial name":""
},
{
    "TIS_N":2270,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"FMXX",
    "TIS_A_Ext":"FSM",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: MICRONESIA, FEDERATED STATES OF",
    "Official name":"FEDERATED STATES OF MICRONESIA",
    "Abbreviated name":"MICRONESIA",
    "Unofficial name":""
},
{
    "TIS_N":2271,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"MHXX",
    "TIS_A_Ext":"MHL",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: MARSHALL ISLANDS",
    "Official name":"REPUBLIC OF THE MARSHALL ISLANDS",
    "Abbreviated name":"MARSHALL ISL.",
    "Unofficial name":""
},
{
    "TIS_N":2272,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"PWXX",
    "TIS_A_Ext":"PLW",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: PALAU",
    "Official name":"REPUBLIC OF PALAU",
    "Abbreviated name":"PALAU",
    "Unofficial name":""
},
{
    "TIS_N":2273,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"PKXX",
    "TIS_A_Ext":"PAK",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: PAKISTAN",
    "Official name":"ISLAMIC REPUBLIC OF PAKISTAN",
    "Abbreviated name":"PAKISTAN",
    "Unofficial name":""
},
{
    "TIS_N":2274,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"PAXX",
    "TIS_A_Ext":"PAN",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: PANAMA",
    "Official name":"REPUBLIC OF PANAMA",
    "Abbreviated name":"PANAMA",
    "Unofficial name":""
},
{
    "TIS_N":2275,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"PGXX",
    "TIS_A_Ext":"PNG",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: PAPUA NEW GUINEA",
    "Official name":"",
    "Abbreviated name":"PAPUA N.GUINEA",
    "Unofficial name":""
},
{
    "TIS_N":2276,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"PYXX",
    "TIS_A_Ext":"PRY",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: PARAGUAY",
    "Official name":"REPUBLIC OF PARAGUAY",
    "Abbreviated name":"PARAGUAY",
    "Unofficial name":""
},
{
    "TIS_N":2277,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"PEXX",
    "TIS_A_Ext":"PER",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: PERU",
    "Official name":"REPUBLIC OF PERU",
    "Abbreviated name":"PERU",
    "Unofficial name":""
},
{
    "TIS_N":2278,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"PHXX",
    "TIS_A_Ext":"PHL",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: PHILIPPINES",
    "Official name":"REPUBLIC OF THE PHILIPPINES",
    "Abbreviated name":"PHILIPPINES",
    "Unofficial name":""
},
{
    "TIS_N":2279,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"PLXX",
    "TIS_A_Ext":"POL",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: POLAND",
    "Official name":"REPUBLIC OF POLAND",
    "Abbreviated name":"POLAND",
    "Unofficial name":""
},
{
    "TIS_N":2280,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"PTXX",
    "TIS_A_Ext":"PRT",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: PORTUGAL",
    "Official name":"PORTUGUESE REPUBLIC",
    "Abbreviated name":"PORTUGAL",
    "Unofficial name":""
},
{
    "TIS_N":2281,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"GWXX",
    "TIS_A_Ext":"GNB",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: GUINEA-BISSAU",
    "Official name":"REPUBLIC OF GUINEA-BISSAU",
    "Abbreviated name":"GUINEA-BISSAU",
    "Unofficial name":""
},
{
    "TIS_N":2282,
    "Exists from":"20/5/2002",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"TLXX",
    "TIS_A_Ext":"TLS",
    "Name from":"20/5/2002",
    "Name until":"31/12/3999",
    "Name":"Exclude: TIMOR-LESTE",
    "Official name":"DEMOCRATIC REPUBLIC OF TIMOR-LESTE",
    "Abbreviated name":"TIMOR-LESTE",
    "Unofficial name":""
},
{
    "TIS_N":2283,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"PRXX",
    "TIS_A_Ext":"PRI",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: PUERTO RICO",
    "Official name":"",
    "Abbreviated name":"PUERTO RICO",
    "Unofficial name":""
},
{
    "TIS_N":2284,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"QAXX",
    "TIS_A_Ext":"QAT",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: QATAR",
    "Official name":"STATE OF QATAR",
    "Abbreviated name":"QATAR",
    "Unofficial name":""
},
{
    "TIS_N":2285,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"ROXX",
    "TIS_A_Ext":"ROU",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: ROMANIA",
    "Official name":"",
    "Abbreviated name":"ROMANIA",
    "Unofficial name":""
},
{
    "TIS_N":2286,
    "Exists from":"1/1/1992",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"RUXX",
    "TIS_A_Ext":"RUS",
    "Name from":"1/1/1992",
    "Name until":"31/12/3999",
    "Name":"Exclude: RUSSIAN FEDERATION",
    "Official name":"",
    "Abbreviated name":"RUSSIA",
    "Unofficial name":""
},
{
    "TIS_N":2287,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"RWXX",
    "TIS_A_Ext":"RWA",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: RWANDA",
    "Official name":"RWANDESE RUPUBLIC",
    "Abbreviated name":"RWANDA",
    "Unofficial name":""
},
{
    "TIS_N":2288,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"KNXX",
    "TIS_A_Ext":"KNA",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: SAINT KITTS AND NEVIS",
    "Official name":"",
    "Abbreviated name":"ST.KITTS+NEVIS",
    "Unofficial name":""
},
{
    "TIS_N":2289,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"LCXX",
    "TIS_A_Ext":"LCA",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: SAINT LUCIA",
    "Official name":"",
    "Abbreviated name":"ST.LUCIA",
    "Unofficial name":""
},
{
    "TIS_N":2290,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"VCXX",
    "TIS_A_Ext":"VCT",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: SAINT VINCENT AND THE GRENADINES",
    "Official name":"",
    "Abbreviated name":"ST.VINCENT+GR",
    "Unofficial name":""
},
{
    "TIS_N":2291,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"SMXX",
    "TIS_A_Ext":"SMR",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: SAN MARINO",
    "Official name":"REPUBLIC OF SAN MARINO",
    "Abbreviated name":"SAN MARINO",
    "Unofficial name":""
},
{
    "TIS_N":2292,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"STXX",
    "TIS_A_Ext":"STP",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: SAO TOME AND PRINCIPE",
    "Official name":"DEMOCRATIC REPUBLIC OF SAO TOME AND PRINCIPE",
    "Abbreviated name":"S.TOME+PRINCIPE",
    "Unofficial name":""
},
{
    "TIS_N":2293,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"SAXX",
    "TIS_A_Ext":"SAU",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: SAUDI ARABIA",
    "Official name":"KINGDOM OF SAUDI ARABIA",
    "Abbreviated name":"SAUDI ARABIA",
    "Unofficial name":""
},
{
    "TIS_N":2294,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"SNXX",
    "TIS_A_Ext":"SEN",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: SENEGAL",
    "Official name":"REPUBLIC OF SENEGAL",
    "Abbreviated name":"SENEGAL",
    "Unofficial name":""
},
{
    "TIS_N":2295,
    "Exists from":"3/6/2006",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"RSXX",
    "TIS_A_Ext":"SRB",
    "Name from":"3/6/2006",
    "Name until":"31/12/3999",
    "Name":"Exclude: SERBIA",
    "Official name":"REPUBLIC OF SERBIA",
    "Abbreviated name":"SERBIA",
    "Unofficial name":""
},
{
    "TIS_N":2296,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"SCXX",
    "TIS_A_Ext":"SYC",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: SEYCHELLES",
    "Official name":"REPUBLIC OF SEYCHELLES",
    "Abbreviated name":"SEYCHELLES",
    "Unofficial name":""
},
{
    "TIS_N":2297,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"SLXX",
    "TIS_A_Ext":"SLE",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: SIERRA LEONE",
    "Official name":"REPUBLIC OF SIERRA LEONE",
    "Abbreviated name":"SIERRA LEONE",
    "Unofficial name":""
},
{
    "TIS_N":2298,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"SGXX",
    "TIS_A_Ext":"SGP",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: SINGAPORE",
    "Official name":"REPUBLIC OF SINGAPORE",
    "Abbreviated name":"SINGAPORE",
    "Unofficial name":""
},
{
    "TIS_N":2299,
    "Exists from":"1/1/1993",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"SKXX",
    "TIS_A_Ext":"SVK",
    "Name from":"1/1/1993",
    "Name until":"31/12/3999",
    "Name":"Exclude: SLOVAKIA",
    "Official name":"SLOVAK REPUBLIC",
    "Abbreviated name":"SLOVAKIA",
    "Unofficial name":""
},
{
    "TIS_N":2300,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"VNXX",
    "TIS_A_Ext":"VNM",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: VIET NAM",
    "Official name":"SOCIALIST REPUBLIC OF VIET NAM",
    "Abbreviated name":"VIET NAM",
    "Unofficial name":""
},
{
    "TIS_N":2301,
    "Exists from":"1/1/1992",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"SIXX",
    "TIS_A_Ext":"SVN",
    "Name from":"1/1/1992",
    "Name until":"31/12/3999",
    "Name":"Exclude: SLOVENIA",
    "Official name":"REPUBLIC OF SLOVENIA",
    "Abbreviated name":"SLOVENIA",
    "Unofficial name":""
},
{
    "TIS_N":2302,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"SOXX",
    "TIS_A_Ext":"SOM",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: SOMALIA",
    "Official name":"SOMALI REPUBLIC",
    "Abbreviated name":"SOMALIA",
    "Unofficial name":""
},
{
    "TIS_N":2303,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"ZAXX",
    "TIS_A_Ext":"ZAF",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: SOUTH AFRICA",
    "Official name":"REPUBLIC OF SOUTH AFRICA",
    "Abbreviated name":"SOUTH AFRICA",
    "Unofficial name":""
},
{
    "TIS_N":2304,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"ZWXX",
    "TIS_A_Ext":"ZWE",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: ZIMBABWE",
    "Official name":"REPUBLIC OF ZIMBABWE",
    "Abbreviated name":"ZIMBABWE",
    "Unofficial name":""
},
{
    "TIS_N":2305,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"ESXX",
    "TIS_A_Ext":"ESP",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: SPAIN",
    "Official name":"KINGDOM OF SPAIN",
    "Abbreviated name":"SPAIN",
    "Unofficial name":""
},
{
    "TIS_N":2306,
    "Exists from":"9/7/2011",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"SSXX",
    "TIS_A_Ext":"SSD",
    "Name from":"9/7/2011",
    "Name until":"31/12/3999",
    "Name":"Exclude: SOUTH SUDAN",
    "Official name":"REPUBLIC OF SOUTH SUDAN",
    "Abbreviated name":"SOUTH SUDAN",
    "Unofficial name":""
},
{
    "TIS_N":2307,
    "Exists from":"9/7/2011",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"SDXX",
    "TIS_A_Ext":"SDN",
    "Name from":"9/7/2011",
    "Name until":"31/12/3999",
    "Name":"Exclude: SUDAN",
    "Official name":"REPUBLIC OF THE SUDAN",
    "Abbreviated name":"SUDAN",
    "Unofficial name":""
},
{
    "TIS_N":2308,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"EHXX",
    "TIS_A_Ext":"ESH",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: WESTERN SAHARA",
    "Official name":"",
    "Abbreviated name":"WESTERN SAHARA",
    "Unofficial name":""
},
{
    "TIS_N":2309,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"SRXX",
    "TIS_A_Ext":"SUR",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: SURINAME",
    "Official name":"REPUBLIC OF SURINAME",
    "Abbreviated name":"SURINAME",
    "Unofficial name":""
},
{
    "TIS_N":"2310",
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"SZXX",
    "TIS_A_Ext":"SWZ",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: ESWATINI",
    "Official name":"THE KINGDOM OF ESWATINI",
    "Abbreviated name":"ESWATINI",
    "Unofficial name":""
},
{
    "TIS_N":2311,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"SEXX",
    "TIS_A_Ext":"SWE",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: SWEDEN",
    "Official name":"KINGDOM OF SWEDEN",
    "Abbreviated name":"SWEDEN",
    "Unofficial name":""
},
{
    "TIS_N":2312,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"CHXX",
    "TIS_A_Ext":"CHE",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: SWITZERLAND",
    "Official name":"SWISS CONFEDERATION",
    "Abbreviated name":"SWITZERLAND",
    "Unofficial name":""
},
{
    "TIS_N":2313,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"SYXX",
    "TIS_A_Ext":"SYR",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: SYRIAN ARAB REPUBLIC",
    "Official name":"",
    "Abbreviated name":"SYRIA",
    "Unofficial name":""
},
{
    "TIS_N":2314,
    "Exists from":"1/1/1992",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"TJXX",
    "TIS_A_Ext":"TJK",
    "Name from":"1/1/1992",
    "Name until":"31/12/3999",
    "Name":"Exclude: TAJIKISTAN",
    "Official name":"REPUBLIC OF TAJIKISTAN",
    "Abbreviated name":"TAJIKISTAN",
    "Unofficial name":""
},
{
    "TIS_N":2315,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"THXX",
    "TIS_A_Ext":"THA",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: THAILAND",
    "Official name":"KINGDOM OF THAILAND",
    "Abbreviated name":"THAILAND",
    "Unofficial name":""
},
{
    "TIS_N":2316,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"TGXX",
    "TIS_A_Ext":"TGO",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: TOGO",
    "Official name":"TOGOLESE REPUBLIC",
    "Abbreviated name":"TOGO",
    "Unofficial name":""
},
{
    "TIS_N":2317,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"TOXX",
    "TIS_A_Ext":"TON",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: TONGA",
    "Official name":"KINGDOM OF TONGA",
    "Abbreviated name":"TONGA",
    "Unofficial name":""
},
{
    "TIS_N":2318,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"TTXX",
    "TIS_A_Ext":"TTO",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: TRINIDAD AND TOBAGO",
    "Official name":"REPUBLIC OF TRINIDAD AND TOBAGO",
    "Abbreviated name":"TRINIDAD+TOBAGO",
    "Unofficial name":""
},
{
    "TIS_N":2319,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"AEXX",
    "TIS_A_Ext":"ARE",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: UNITED ARAB EMIRATES",
    "Official name":"",
    "Abbreviated name":"UNIT.ARAB.EMIR.",
    "Unofficial name":""
},
{
    "TIS_N":2320,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"TNXX",
    "TIS_A_Ext":"TUN",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: TUNISIA",
    "Official name":"REPUBLIC OF TUNISIA",
    "Abbreviated name":"TUNISIA",
    "Unofficial name":""
},
{
    "TIS_N":2321,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"TRXX",
    "TIS_A_Ext":"TUR",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: TURKEY",
    "Official name":"REPUBLIC OF TURKEY",
    "Abbreviated name":"TURKEY",
    "Unofficial name":""
},
{
    "TIS_N":2322,
    "Exists from":"1/1/1992",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"TMXX",
    "TIS_A_Ext":"TKM",
    "Name from":"1/1/1992",
    "Name until":"31/12/3999",
    "Name":"Exclude: TURKMENISTAN",
    "Official name":"",
    "Abbreviated name":"TURKMENISTAN",
    "Unofficial name":""
},
{
    "TIS_N":2323,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"TVXX",
    "TIS_A_Ext":"TUV",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: TUVALU",
    "Official name":"",
    "Abbreviated name":"TUVALU",
    "Unofficial name":""
},
{
    "TIS_N":2324,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"UGXX",
    "TIS_A_Ext":"UGA",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: UGANDA",
    "Official name":"REPUBLIC OF UGANDA",
    "Abbreviated name":"UGANDA",
    "Unofficial name":""
},
{
    "TIS_N":2325,
    "Exists from":"1/1/1992",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"UAXX",
    "TIS_A_Ext":"UKR",
    "Name from":"1/1/1992",
    "Name until":"31/12/3999",
    "Name":"Exclude: UKRAINE",
    "Official name":"",
    "Abbreviated name":"UKRAINE",
    "Unofficial name":""
},
{
    "TIS_N":2326,
    "Exists from":"1/1/1992",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"MKXX",
    "TIS_A_Ext":"MKD",
    "Name from":"1/1/1992",
    "Name until":"31/12/3999",
    "Name":"Exclude: NORTH MACEDONIA",
    "Official name":"THE REPUBLIC OF NORTH MACEDONIA",
    "Abbreviated name":"NORTH MACEDONIA",
    "Unofficial name":""
},
{
    "TIS_N":2327,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"EGXX",
    "TIS_A_Ext":"EGY",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: EGYPT",
    "Official name":"ARAB REPUBLIC OF EGYPT",
    "Abbreviated name":"EGYPT",
    "Unofficial name":""
},
{
    "TIS_N":2328,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"GBXX",
    "TIS_A_Ext":"GBR",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: UNITED KINGDOM",
    "Official name":"UNITED KINGDOM OF GREAT BRITAIN AND NORTHERN IRELAND",
    "Abbreviated name":"UNITED KINGDOM",
    "Unofficial name":""
},
{
    "TIS_N":2329,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"TZXX",
    "TIS_A_Ext":"TZA",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: TANZANIA, UNITED REPUBLIC OF",
    "Official name":"UNITED REPUBLIC OF TANZANIA",
    "Abbreviated name":"TANZANIA",
    "Unofficial name":""
},
{
    "TIS_N":2330,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"TIS-N 840 UNITED STATES IN THE MEANING OF THIS STANDARD DOES NOT INCLUDE PUERTO RICO. CF. ENTRY TIS-N 630 PUERTO RICO.",
    "TIS_A":"USXX",
    "TIS_A_Ext":"USA",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: UNITED STATES",
    "Official name":"UNITED STATES OF AMERICA",
    "Abbreviated name":"USA",
    "Unofficial name":""
},
{
    "TIS_N":2331,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"BFXX",
    "TIS_A_Ext":"BFA",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: BURKINA FASO",
    "Official name":"",
    "Abbreviated name":"BURKINA FASO",
    "Unofficial name":""
},
{
    "TIS_N":2332,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"UYXX",
    "TIS_A_Ext":"URY",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: URUGUAY",
    "Official name":"EASTERN REPUBLIC OF URUGUAY",
    "Abbreviated name":"URUGUAY",
    "Unofficial name":""
},
{
    "TIS_N":2333,
    "Exists from":"1/1/1992",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"UZXX",
    "TIS_A_Ext":"UZB",
    "Name from":"1/1/1992",
    "Name until":"31/12/3999",
    "Name":"Exclude: UZBEKISTAN",
    "Official name":"REPUBLIC OF UZBEKISTAN",
    "Abbreviated name":"UZBEKISTAN",
    "Unofficial name":""
},
{
    "TIS_N":2334,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"VEXX",
    "TIS_A_Ext":"VEN",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: VENEZUELA",
    "Official name":"BOLIVARIAN REPUBLIC OF VENEZUELA",
    "Abbreviated name":"VENEZUELA",
    "Unofficial name":""
},
{
    "TIS_N":2335,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"WSXX",
    "TIS_A_Ext":"WSM",
    "Name from":"01.01.1000",
    "Name until":"8/10/1997",
    "Name":"Exclude: SAMOA",
    "Official name":"INDEPENDENT STATE OF WESTERN SAMOA",
    "Abbreviated name":"SAMOA",
    "Unofficial name":""
},
{
    "TIS_N":2336,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"WSXX",
    "TIS_A_Ext":"WSM",
    "Name from":"9/10/1997",
    "Name until":"31/12/3999",
    "Name":"Exclude: SAMOA",
    "Official name":"INDEPENDENT STATE OF SAMOA",
    "Abbreviated name":"SAMOA",
    "Unofficial name":""
},
{
    "TIS_N":2337,
    "Exists from":"22/5/1990",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"YEXX",
    "TIS_A_Ext":"YEM",
    "Name from":"22/5/1990",
    "Name until":"31/12/3999",
    "Name":"Exclude: YEMEN",
    "Official name":"REPUBLIC OF YEMEN",
    "Abbreviated name":"YEMEN",
    "Unofficial name":""
},
{
    "TIS_N":2338,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"LND",
    "Remark":"",
    "TIS_A":"ZMXX",
    "TIS_A_Ext":"ZMB",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: ZAMBIA",
    "Official name":"REPUBLIC OF ZAMBIA",
    "Abbreviated name":"ZAMBIA",
    "Unofficial name":""
},
{
    "TIS_N":2339,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"GLG",
    "Remark":"",
    "TIS_A":"2AFXX",
    "TIS_A_Ext":"",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: AFRICA",
    "Official name":"",
    "Abbreviated name":"AFRICA",
    "Unofficial name":""
},
{
    "TIS_N":2340,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"GLG",
    "Remark":"",
    "TIS_A":"2AMXX",
    "TIS_A_Ext":"",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: AMERICA",
    "Official name":"",
    "Abbreviated name":"AMERICA",
    "Unofficial name":""
},
{
    "TIS_N":2341,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"GLG",
    "Remark":"",
    "TIS_A":"2ACXX",
    "TIS_A_Ext":"",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: AMERICAN CONTINENT",
    "Official name":"",
    "Abbreviated name":"AMERICAN CONT.",
    "Unofficial name":""
},
{
    "TIS_N":2342,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"GLG",
    "Remark":"",
    "TIS_A":"2ANXX",
    "TIS_A_Ext":"",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: ANTILLES",
    "Official name":"",
    "Abbreviated name":"ANTILLES",
    "Unofficial name":""
},
{
    "TIS_N":2343,
    "Exists from":"7/11/1989",
    "Exists until":"31/12/3999",
    "Type":"WLG",
    "Remark":"",
    "TIS_A":"2APXX",
    "TIS_A_Ext":"",
    "Name from":"7/11/1989",
    "Name until":"31/12/3999",
    "Name":"Exclude: APEC COUNTRIES",
    "Official name":"",
    "Abbreviated name":"APEC",
    "Unofficial name":""
},
{
    "TIS_N":2344,
    "Exists from":"8/8/1967",
    "Exists until":"31/12/3999",
    "Type":"WLG",
    "Remark":"",
    "TIS_A":"2AEXX",
    "TIS_A_Ext":"",
    "Name from":"8/8/1967",
    "Name until":"31/12/3999",
    "Name":"Exclude: ASEAN COUNTRIES",
    "Official name":"",
    "Abbreviated name":"ASEAN",
    "Unofficial name":""
},
{
    "TIS_N":2345,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"GLG",
    "Remark":"",
    "TIS_A":"2ASXX",
    "TIS_A_Ext":"",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: ASIA",
    "Official name":"",
    "Abbreviated name":"ASIA",
    "Unofficial name":""
},
{
    "TIS_N":2346,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"GLG",
    "Remark":"",
    "TIS_A":"2AAXX",
    "TIS_A_Ext":"",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: AUSTRALASIA",
    "Official name":"",
    "Abbreviated name":"AUSTRALASIA",
    "Unofficial name":""
},
{
    "TIS_N":2347,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"GLG",
    "Remark":"",
    "TIS_A":"2BAXX",
    "TIS_A_Ext":"",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: BALKANS",
    "Official name":"",
    "Abbreviated name":"BALKANS",
    "Unofficial name":""
},
{
    "TIS_N":2348,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"GLG",
    "Remark":"",
    "TIS_A":"2BSXX",
    "TIS_A_Ext":"",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: BALTIC STATES",
    "Official name":"",
    "Abbreviated name":"BALTIC STATES",
    "Unofficial name":""
},
{
    "TIS_N":2349,
    "Exists from":"1/1/1948",
    "Exists until":"31/12/3999",
    "Type":"WLG",
    "Remark":"",
    "TIS_A":"2BEXX",
    "TIS_A_Ext":"",
    "Name from":"1/1/1948",
    "Name until":"31/12/3999",
    "Name":"Exclude: BENELUX",
    "Official name":"",
    "Abbreviated name":"BENELUX",
    "Unofficial name":""
},
{
    "TIS_N":2350,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"GLG",
    "Remark":"",
    "TIS_A":"2BIXX",
    "TIS_A_Ext":"",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: BRITISH ISLES",
    "Official name":"",
    "Abbreviated name":"BRITISH ISLES",
    "Unofficial name":""
},
{
    "TIS_N":2351,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"PLG",
    "Remark":"",
    "TIS_A":"2BWXX",
    "TIS_A_Ext":"",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: BRITISH WEST INDIES",
    "Official name":"",
    "Abbreviated name":"BRIT.WEST IND.",
    "Unofficial name":""
},
{
    "TIS_N":2352,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"GLG",
    "Remark":"",
    "TIS_A":"2CAXX",
    "TIS_A_Ext":"",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: CENTRAL AMERICA",
    "Official name":"",
    "Abbreviated name":"CENTRAL AMERICA",
    "Unofficial name":""
},
{
    "TIS_N":2353,
    "Exists from":"1/1/1931",
    "Exists until":"31/12/3999",
    "Type":"PLG",
    "Remark":"",
    "TIS_A":"2COXX",
    "TIS_A_Ext":"",
    "Name from":"1/1/1931",
    "Name until":"31/12/3999",
    "Name":"Exclude: COMMONWEALTH",
    "Official name":"",
    "Abbreviated name":"COMMONWEALTH",
    "Unofficial name":""
},
{
    "TIS_N":2354,
    "Exists from":"1/1/1931",
    "Exists until":"31/12/3999",
    "Type":"PLG",
    "Remark":"",
    "TIS_A":"2CFXX",
    "TIS_A_Ext":"",
    "Name from":"1/1/1931",
    "Name until":"31/12/3999",
    "Name":"Exclude: COMMONWEALTH AFRICAN TERRITORIES",
    "Official name":"",
    "Abbreviated name":"CO AFRICA",
    "Unofficial name":""
},
{
    "TIS_N":2355,
    "Exists from":"1/1/1931",
    "Exists until":"31/12/3999",
    "Type":"PLG",
    "Remark":"",
    "TIS_A":"2CSXX",
    "TIS_A_Ext":"",
    "Name from":"1/1/1931",
    "Name until":"31/12/3999",
    "Name":"Exclude: COMMONWEALTH ASIAN TERRITORIES",
    "Official name":"",
    "Abbreviated name":"CO ASIA",
    "Unofficial name":""
},
{
    "TIS_N":2356,
    "Exists from":"1/1/1931",
    "Exists until":"31/12/3999",
    "Type":"PLG",
    "Remark":"",
    "TIS_A":"2CUXX",
    "TIS_A_Ext":"",
    "Name from":"1/1/1931",
    "Name until":"31/12/3999",
    "Name":"Exclude: COMMONWEALTH AUSTRALASIAN TERRITORIES",
    "Official name":"",
    "Abbreviated name":"CO AUSTRALASIA",
    "Unofficial name":""
},
{
    "TIS_N":2357,
    "Exists from":"1/1/1992",
    "Exists until":"31/12/3999",
    "Type":"PLG",
    "Remark":"",
    "TIS_A":"2CIXX",
    "TIS_A_Ext":"",
    "Name from":"1/1/1992",
    "Name until":"31/12/3999",
    "Name":"Exclude: COMMONWEALTH OF INDEPENDENT STATES",
    "Official name":"",
    "Abbreviated name":"CIS",
    "Unofficial name":""
},
{
    "TIS_N":2358,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"GLG",
    "Remark":"",
    "TIS_A":"2EEXX",
    "TIS_A_Ext":"",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: EASTERN EUROPE",
    "Official name":"",
    "Abbreviated name":"EASTERN EUROPE",
    "Unofficial name":""
},
{
    "TIS_N":2359,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"GLG",
    "Remark":"",
    "TIS_A":"2EUXX",
    "TIS_A_Ext":"",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: EUROPE",
    "Official name":"",
    "Abbreviated name":"EUROPE",
    "Unofficial name":""
},
{
    "TIS_N":2360,
    "Exists from":"1/1/1994",
    "Exists until":"31/12/3999",
    "Type":"WLG",
    "Remark":"",
    "TIS_A":"2EMXX",
    "TIS_A_Ext":"",
    "Name from":"1/1/1994",
    "Name until":"31/12/3999",
    "Name":"Exclude: EUROPEAN ECONOMIC AREA",
    "Official name":"",
    "Abbreviated name":"EUROP.ECON.AREA",
    "Unofficial name":""
},
{
    "TIS_N":2361,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"GLG",
    "Remark":"",
    "TIS_A":"2ECXX",
    "TIS_A_Ext":"",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: EUROPEAN CONTINENT",
    "Official name":"",
    "Abbreviated name":"EUROPEAN CONT.",
    "Unofficial name":""
},
{
    "TIS_N":2362,
    "Exists from":"1/1/1958",
    "Exists until":"31/12/3999",
    "Type":"PLG",
    "Remark":"",
    "TIS_A":"2EYXX",
    "TIS_A_Ext":"",
    "Name from":"1/1/1958",
    "Name until":"31/10/1993",
    "Name":"Exclude: EUROPEAN ECONOMIC COMMUNITY",
    "Official name":"",
    "Abbreviated name":"EEC",
    "Unofficial name":""
},
{
    "TIS_N":2363,
    "Exists from":"1/1/1958",
    "Exists until":"31/12/3999",
    "Type":"PLG",
    "Remark":"",
    "TIS_A":"2ENXX",
    "TIS_A_Ext":"",
    "Name from":"1/11/1993",
    "Name until":"31/12/3999",
    "Name":"Exclude: EUROPEAN UNION",
    "Official name":"",
    "Abbreviated name":"EUROPEAN UNION",
    "Unofficial name":""
},
{
    "TIS_N":2364,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"GLG",
    "Remark":"",
    "TIS_A":"2GCXX",
    "TIS_A_Ext":"",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: GSA COUNTRIES",
    "Official name":"",
    "Abbreviated name":"GSA",
    "Unofficial name":""
},
{
    "TIS_N":2365,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"GLG",
    "Remark":"",
    "TIS_A":"2MEXX",
    "TIS_A_Ext":"",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: MIDDLE EAST",
    "Official name":"",
    "Abbreviated name":"MIDDLE EAST",
    "Unofficial name":""
},
{
    "TIS_N":2366,
    "Exists from":"1/1/1994",
    "Exists until":"31/12/3999",
    "Type":"WLG",
    "Remark":"",
    "TIS_A":"2NTXX",
    "TIS_A_Ext":"",
    "Name from":"1/1/1994",
    "Name until":"31/12/3999",
    "Name":"Exclude: NAFTA COUNTRIES",
    "Official name":"",
    "Abbreviated name":"NAFTA",
    "Unofficial name":""
},
{
    "TIS_N":2367,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"GLG",
    "Remark":"",
    "TIS_A":"2NCXX",
    "TIS_A_Ext":"",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: NORDIC COUNTRIES",
    "Official name":"",
    "Abbreviated name":"NORDIC COUNTR.",
    "Unofficial name":""
},
{
    "TIS_N":2368,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"GLG",
    "Remark":"",
    "TIS_A":"2NFXX",
    "TIS_A_Ext":"",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: NORTH AFRICA",
    "Official name":"",
    "Abbreviated name":"NORTH AFRICA",
    "Unofficial name":""
},
{
    "TIS_N":2369,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"GLG",
    "Remark":"",
    "TIS_A":"2NAXX",
    "TIS_A_Ext":"",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: NORTH AMERICA",
    "Official name":"",
    "Abbreviated name":"NORTH AMERICA",
    "Unofficial name":""
},
{
    "TIS_N":2370,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"GLG",
    "Remark":"",
    "TIS_A":"2OCXX",
    "TIS_A_Ext":"",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: OCEANIA",
    "Official name":"",
    "Abbreviated name":"OCEANIA",
    "Unofficial name":""
},
{
    "TIS_N":2371,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"GLG",
    "Remark":"",
    "TIS_A":"2SCXX",
    "TIS_A_Ext":"",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: SCANDINAVIA",
    "Official name":"",
    "Abbreviated name":"SCANDINAVIA",
    "Unofficial name":""
},
{
    "TIS_N":2372,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"GLG",
    "Remark":"",
    "TIS_A":"2SAXX",
    "TIS_A_Ext":"",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: SOUTH AMERICA",
    "Official name":"",
    "Abbreviated name":"SOUTH AMERICA",
    "Unofficial name":""
},
{
    "TIS_N":2373,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"GLG",
    "Remark":"",
    "TIS_A":"2SEXX",
    "TIS_A_Ext":"",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: SOUTH EAST ASIA",
    "Official name":"",
    "Abbreviated name":"SOUTH EAST ASIA",
    "Unofficial name":""
},
{
    "TIS_N":2374,
    "Exists from":"01.01.1000",
    "Exists until":"31/12/3999",
    "Type":"GLG",
    "Remark":"",
    "TIS_A":"2WIXX",
    "TIS_A_Ext":"",
    "Name from":"01.01.1000",
    "Name until":"31/12/3999",
    "Name":"Exclude: WEST INDIES",
    "Official name":"",
    "Abbreviated name":"WEST INDIES",
    "Unofficial name":""
}
];

export default countries;