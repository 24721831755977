import React from 'react';
import { Row, Col, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../../../store/modal/actions';
import './filePreview.scss';

const FilePreview = () => {
	const dispatch = useDispatch();

	return (
		<>
			<Modal.Body>
				<div className='file-preview-container container'>
					<div className='row file-container'>
						<Col>
							<p className='File-Namexls'>File Name.xls</p>
						</Col>
					</div>
					<div className='row'>
						<Col xs={12} className='third-row'>
							<p className='Created-Aug-20-2021'>
								Created Aug 20, 2021 2:00 PM
							</p>
							<div className='Utility-Icons-internal_share'>
								<i className='fas fa-share'></i>
								<i className='fas fa-download'></i>
								<i className='fas fa-ellipsis-v'></i>
							</div>
						</Col>
					</div>
					<Row>
						<Col>
							<div className='Line-2'></div>
						</Col>
					</Row>
					<div className='row'>
						<Col>
							<div className='newfile'>
								<i className='far fa-file'></i>
							</div>
						</Col>
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Row>
					<Col
						xs={{ span: 4, offset: 8 }}
						className='d-flex justify-content-end'
					>
						<button
							onClick={() => dispatch(hideModal())}
							className='btn border-purple text-purple bg-white fw-600 mr-3'
							type='button'
						>
							Cancel
						</button>
						<button type='submit' className='btn btn-purple'>
							Save
						</button>
					</Col>
				</Row>
				)
			</Modal.Footer>
		</>
	);
};

export default FilePreview;
