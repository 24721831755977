import React, { useCallback } from 'react';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { hideModal, removeModalByIdAction } from '../../../store/modal/actions';
import './ModalPopup.scss';

// const MODAL_Z_INDEX_BASE = 100;

export default function ModalPopup({
	children,
	show,
	title,
	modalProps,
	modalId,
}) {
	const dispatch = useDispatch();

	const handleHide = useCallback(() => {
		if (modalProps?.noHide) {
			return;
		}

		if (modalProps?.onHide) {
			modalProps.onHide();
			return;
		}

		dispatch(hideModal());
	}, [dispatch, modalProps]);

	const handleExited = useCallback(() => {
		dispatch(removeModalByIdAction(modalId));
	}, [dispatch, modalId]);

	return (
		<Modal
			show={show}
			onHide={handleHide}
			onExited={handleExited}
			size={(modalProps && modalProps?.size) || 'xl'}
			dialogClassName='modal-90w'
			aria-labelledby='contained-modal-title-vcenter'
			centered
			// fullscreen={props?.fullscreen || false}
			className={`${modalProps?.hideHeader ? 'no-header-modal' : ''} ${
				modalProps?.fullscreen ? 'fullscreen-modal' : ''
			}`}
			// style={{ zIndex: MODAL_Z_INDEX_BASE + stackIndex }}
		>
			{!modalProps?.hideHeader && (
				<Modal.Header
					closeButton={!modalProps?.removeHeaderPadding && !modalProps?.noHide}
					className={modalProps?.removeHeaderPadding ? 'p-0' : ''}
				>
					<Modal.Title id='contained-modal-title-vcenter'>
						<p className='title'>{title}</p>
						{/* <button type='button' className='close'>
						<span aria-hidden='true'>×</span>
						<span className='sr-only'>Close</span>
					</button> */}
					</Modal.Title>
				</Modal.Header>
			)}
			{children}
		</Modal>
	);
}
