import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ParticipantsTable from './ParticipantsTable';
import AddParticipant from './AddParticipant';
import { Col, Row } from 'react-bootstrap';
import Select from '../../form/Select';
import HeaderButton from '../../layout/HeaderButton';
import {
	setParticipantsRoleFilters,
} from '../../../store/participants/actions';
import roleFilterOptions from '../../../constants/roleFilterOptions.json';
import ActionTopBar from '../../layout/ActionTopBar';
import { getCurrentRecording } from '../../../store/projects/selectors';
import './Credits.scss';
import React from 'react';
import { showModalAction } from '../../../store/modal/actions';
import {
	COPY_CREDITS_FROM_RECORDING_MODAL,
	COPY_CREDITS_TO_RECORDINGS_MODAL,
} from '../../../constants/modalTypes';
import { Helmet } from 'react-helmet';

function Credits() {
	const currentRecording = useSelector(getCurrentRecording);
	const [show, setShow] = useState(true);
	const [animate, setAnimate] = useState(false);
	const { roleFilters } = useSelector(state => state.participants);
	const dispatch = useDispatch();
	const [hasParticipants, setHasParticipants] = useState(false);
	const [isDragDisabled, setIsDragDisabled] = useState(false);
	const { myEditorProfile } = useSelector(state => state.projects);

	const isReadOnly = useMemo(
		() => myEditorProfile && myEditorProfile.is_read_only,
		[myEditorProfile]
	);

	const hasWriteAccess = useMemo(
		() => myEditorProfile && !myEditorProfile.is_read_only,
		[myEditorProfile]
	);

	useEffect(() => {
		const el = document.querySelector('.prepare-credits-container');
		const header = document.querySelector('.header-border');

		if (el) el.classList.remove('container');
		setHasParticipants(currentRecording?.recording?.participants?.length > 0);

		header.classList.add('remove-shadow');
		return () => {
			const el = document.querySelector('.prepare-credits-container');

			if (el) el.classList.add('container');
			header.classList.remove('remove-shadow');
		};
	}, [currentRecording]);

	const handleRoleFiltersChange = options => {
		if (options.length) setIsDragDisabled(true);
		else setIsDragDisabled(false);

		dispatch(setParticipantsRoleFilters(options));
	};



	return (
		<>
			<Helmet>
				<title>
					{currentRecording?.title ?? ''} - Credits{' '}
					{process.env.REACT_APP_TAB_TITLE}
				</title>
			</Helmet>

			<div className={`participants-container`}>
				{/* <ActionTopBar style={{ position: 'sticky', top: 74, zIndex: 1000 }}> */}
				<ActionTopBar className='participants-action-bar'>
					<Row>
						<Col xs={4} className='d-flex align-items-center'>
							<Select
								fullWidth
								dense
								isMulti
								options={roleFilterOptions.options}
								name='role'
								id='role'
								placeholder='Filter Roles'
								value={roleFilters}
								onChange={handleRoleFiltersChange}
							/>
						</Col>
						<Col xs={8} className='d-flex justify-content-end header-buttons'>
							<div
								style={{ borderLeft: '1px solid #979797', height: '100%' }}
							></div>

							<HeaderButton
								label='Copy Credits from another Recording'
								icon='fa fa-file-import'
								isDisabled={isReadOnly}
								onClick={() => {
									dispatch(
										showModalAction(COPY_CREDITS_FROM_RECORDING_MODAL, {
											size: 'lg',
										})
									);
								}}
							/>

							<HeaderButton
								label={'Copy Credits to other Recording(s)'}
								icon='fa fa-file-export'
								onClick={() => {
									dispatch(
										showModalAction(COPY_CREDITS_TO_RECORDINGS_MODAL, {
											size: 'lg',
										})
									);
								}}
								isDisabled={isReadOnly}
							/>

							{hasWriteAccess && (
								<HeaderButton
									label='Add Credit'
									icon='fa fa-user-plus'
									onClick={ev => {
										setShow(true);
										setAnimate(true);
										const getNthParent = (elem, num) => {
											// return the parent element of elem num times
											// or undefined if there is no such parent
											while (num-- && elem) {
												elem = elem.parentNode;
											}
											return elem;
										};
										let scrollToElem = getNthParent(ev.target, 11);
										setTimeout(
											() =>
												scrollToElem.scrollTo({
													top: scrollToElem.scrollHeight,
													behavior: 'smooth',
												}),
											900
										);
									}}
								/>
							)}
						</Col>
					</Row>
				</ActionTopBar>
				<div className='mt-3 container-horizontal-padding participants-main'
				>

					<Row className='mt-3'>
						<Col xs={12}>
							{hasParticipants ? (
								<>
									<ParticipantsTable
										deleteFromRecording
										isDragDisabled={isReadOnly ? true : isDragDisabled}
									/>
								</>
							) : (
								isReadOnly && (
									<div className='d-flex flex-column justify-content-center align-items-center h-100'>
										<i
											className='fas fa-user mb-3'
											style={{ fontSize: '4rem' }}
										></i>
										<h3 className='text-center'>
											This recording currently contains no participants.
										</h3>
									</div>
								)
							)}
							{show && hasWriteAccess && (
								<AddParticipant
									animate={animate}
									setAnimate={setAnimate}
									setShowAddParticipantBox={setShow}
								/>
							)}
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
}

export default Credits;
