import {
	DragDropContext,
	Droppable,
	OnDragEndResponder,
} from 'react-beautiful-dnd';
import React from 'react';

export type DraggableTableProps<T> = {
	renderHeaderColumns: () => React.ReactNode;
	renderRows: (values: T[]) => React.ReactNode;
	values: T[];
	columns?: any[];
	onDragEnd: OnDragEndResponder;
};

function DraggableTable<T>({
	renderHeaderColumns,
	renderRows,
	values,
	columns,
	onDragEnd,
}: DraggableTableProps<T>) {
	return (
		<DragDropContext onDragEnd={onDragEnd}>
			<table className='table participants-table'>
				{columns &&
					columns.length > 0 &&
					columns.map((column, index) => (
						<col width={column.width} key={index} />
					))}
				<thead>
					<tr>{renderHeaderColumns()}</tr>
				</thead>
				<Droppable droppableId='tableId'>
					{provided => (
						<tbody ref={provided.innerRef} {...provided.droppableProps}>
							{renderRows(values)}
							{provided.placeholder}
						</tbody>
					)}
				</Droppable>
			</table>
		</DragDropContext>
	);
}

export default DraggableTable;
