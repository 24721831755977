import React, { useEffect, useMemo, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../layout/Button';
import { getFilesById } from '../../../../store/files/selectors';
import { hideModal, setModalTitle } from '../../../../store/modal/actions';
import FileTagSelect from '../../../form/FileTagSelect';
import { useCallback } from 'react';
import {
	getFileMetadataAction,
	updateCloudFileMetadataAction,
} from '../../../../store/files/actions';
import SoundCreditLoader from '../../SoundCreditLoader/SoundCreditLoader';
import { fetchPlaylistAction } from '../../../../store/playlists/actions';

const EditFileTagModal = ({ fileId, playlistId = null }) => {
	const filesById = useSelector(getFilesById);
	const dispatch = useDispatch();

	const file = useMemo(() => filesById[fileId], [filesById, fileId]);

	const [isLoading, setIsLoading] = useState(false);
	const [selectedTagValue, setSelectedTagValue] = useState(null);

	const handleClose = useCallback(() => dispatch(hideModal()), [dispatch]);

	const handleSave = useCallback(() => {
		setIsLoading(true);

		Promise.resolve(
			dispatch(
				updateCloudFileMetadataAction({
					...file,
					label: selectedTagValue,
				})
			)
		)
			.then(() => {
				if (playlistId) {
					return dispatch(fetchPlaylistAction(playlistId));
				}
			})
			.finally(() => {
				setIsLoading(false);
				handleClose();
			});
	}, [dispatch, file, selectedTagValue, handleClose, playlistId]);

	// set initial value
	useEffect(() => {
		if (!file) return;
		setSelectedTagValue(filesById[fileId]?.label);
	}, [filesById, fileId, file]);

	useEffect(() => {
		dispatch(setModalTitle('EDIT FILE TAG'));
	}, [dispatch]);

	// fetch file on mount
	useEffect(() => {
		dispatch(getFileMetadataAction(fileId));
	}, [dispatch, fileId]);

	return !file ? (
		<div className='pb-4 w-100 h-100'>
			<SoundCreditLoader />
		</div>
	) : (
		<>
			<Modal.Body>
				<div className='px-4'>
					<FileTagSelect
						inputLabel={'Select a Tag'}
						fileTag={selectedTagValue}
						setFileTag={setSelectedTagValue}
					/>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Row>
					<Col
						xs={12}
						className='d-flex align-items-center justify-content-end'
					>
						<Button label='Cancel' className='mr-2' onClick={handleClose} />
						<Button
							label='Save'
							theme='dark'
							onClick={handleSave}
							isLoading={isLoading}
						/>
					</Col>
				</Row>
			</Modal.Footer>
		</>
	);
};

export default EditFileTagModal;
