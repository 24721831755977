import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {
	hideModal,
	setModalTitle,
	showModalAction,
} from '../../../../../store/modal/actions';
import { publishDripAction } from '../../../../../store/playlists/actions';
import Button from '../../../../layout/Button';
import * as Yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import './CreateDripModal.scss';
import PillButton from '../../../../layout/PillButton';
import {
	DRIP_AGREEMENT_MODAL,
	WHAT_IS_A_DRIP_MODAL,
} from '../../../../../constants/modalTypes';
import Select from '../../../../form/Select';

const ticketOptions = [
	{ value: 20, label: '20' },
	{ value: 50, label: '50' },
	{ value: 100, label: '100' },
	{ value: 500, label: '500' },
	{ value: 1000, label: '1000' },
];

const CreateDripModalSchema = Yup.object().shape({
	ticketCount: Yup.number().required('Required'),
});

const CreateDripModal = ({ playlistId }) => {
	const dispatch = useDispatch();
	const [userAgreed, setUserAgreed] = useState(false);

	const [isSubmitting, setIsSubmitting] = useState(false);

	function handleCreate(formValues) {
		if (!userAgreed) {
			dispatch(
				showModalAction(DRIP_AGREEMENT_MODAL, {
					size: 'lg',
					removeHeaderPadding: true,
					onAgree: () => {
						Promise.resolve(dispatch(hideModal())).then(() => {
							setUserAgreed(true);
							setIsSubmitting(true);
							dispatch(
								publishDripAction(playlistId, formValues.ticketCount)
							).finally(() => {
								setIsSubmitting(false);
								dispatch(hideModal());
							});
						});
					},
					onDecline: () => dispatch(hideModal()),
				})
			);
			return;
		}

		setIsSubmitting(true);

		return dispatch(
			publishDripAction(playlistId, formValues.ticketCount)
		).finally(() => {
			setIsSubmitting(false);
			dispatch(hideModal());
		});
	}

	const formik = useFormik({
		initialValues: {
			ticketCount: ticketOptions[0].value,
		},
		validationSchema: CreateDripModalSchema,
		onSubmit: async formValues => await handleCreate(formValues),
	});

	const handleWhatIsADrip = useCallback(() => {
		dispatch(
			showModalAction(WHAT_IS_A_DRIP_MODAL, {
				size: 'lg',
				removeHeaderPadding: true,
			})
		);
	}, [dispatch]);

	const handleClose = useCallback(() => {
		dispatch(hideModal());
	}, [dispatch]);

	useEffect(() => {
		dispatch(setModalTitle('PUBLISH DRIP'));
	}, [dispatch]);

	return (
		<>
			<FormikProvider value={formik}>
				<Form onSubmit={formik.handleSubmit}>
					<Modal.Body>
						<div className='px-4'>
							<Select
								options={ticketOptions}
								name='ticketCount'
								defaultValue={ticketOptions[0]}
								label='Choose number of tickets for this Drip'
								onChange={value => {
									formik.setFieldValue('ticketCount', value.value);
								}}
								onBlur={formik.handleBlur}
								value={ticketOptions.find(
									option => option.value === formik.values.ticketCount
								)}
								errorFieldName={'ticketCount'}
							/>

							<div className='create-drip__ticket-count'>
								<i className='fas fa-ticket-alt mr-2 ticket-icon' />
								<div className='create-drip__ticket-count-help'>
									Each ticket will allow one streamer to access the Drip. You
									will be able to see the statistics of each streamer who has
									accessed the Drip.
								</div>
							</div>
							<hr />

							<div className='create-drip__faq'>
								<PillButton
									label='What is a Drip?'
									leftIcon='fas fa-question-circle'
									onClick={handleWhatIsADrip}
								/>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button label='Cancel' onClick={handleClose} />
						<Button
							label='Publish'
							onClick={formik.handleSubmit}
							theme='dark'
							isLoading={isSubmitting}
						/>
					</Modal.Footer>
				</Form>
			</FormikProvider>
		</>
	);
};

export default CreateDripModal;
