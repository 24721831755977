import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import Select from '../../../../form/Select';
import TextField from '../../../../form/TextField';
import IconButton from '../../../../layout/IconButton';
import theme from '../../../../../theme.module.scss';
import Button from '../../../../layout/Button';
import { useDispatch, useSelector } from 'react-redux';
import {
	previousExportStepAction,
	setUnionExportFinalizeFormAction,
} from '../../../../../store/exports/actions';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import _ from 'lodash';
import blankFinalizeSagAftraForm from '../../../../../constants/unionForm/finalizeSagAftraForm.json';
import sagAftraRecordingTypes from '../../../../../constants/unionForm/sagAftraRecordingTypes.json';
import * as yup from 'yup';
import { useDebounceEffect } from '../../../../../hooks/useDebounceEffect';
import CreatableSelect from '../../../../form/CreatableSelect';
import SessionTimesPicker from '../SessionTimesPicker';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../../router/routes';

const schema = yup.object().shape({
	sessionTimes: yup.array().of(
		yup.object().shape({
			startTime: yup.string().required('Start Time Is Required'),
			endTime: yup.string().required('End Time Is Required'),
			recordingIds: yup
				.array()
				.min(1, 'Please Select At Least One Session Track'),
		})
	),
	recordingType: yup.string().required('Recording Type Is Required'),
	jobNumber: yup.string().required('Job Number Is Required'),
	sessionProducer: yup
		.object()
		.shape({
			label: yup.string(),
			value: yup.string(),
		})
		.nullable()
		.required('Session Producer Is Required'),
	recordCompanyAddress: yup.string().required('Record Co. Address Is Required'),
	recordCompanyName: yup.string().required('Record Co. Name Is Required'),
});

const FinalizeStepSAGAFTRA = ({ onFormSubmit }) => {
	/*
	 * Redux Hooks
	 */
	const dispatch = useDispatch();
	const { recordingsById } = useSelector(state => state.projects);
	const { finalizeForm, selectedRecordingRows } = useSelector(
		state => state.exports.unionForm
	);

	/*
	 * React Hooks
	 */
	const [sessionProducerOptions, setSessionProducerOptions] = useState([]);

	const selectedRecordingIds = useMemo(
		() => selectedRecordingRows.map(row => row.recordingId),
		[selectedRecordingRows]
	);

	const sessionTracksOptions = useMemo(
		() =>
			selectedRecordingIds.map(recordingId => ({
				label: recordingsById[recordingId].recording.title,
				value: recordingId,
			})),
		[selectedRecordingIds, recordingsById]
	);

	const setInitialValues = useRef(false);

	const initialValues = useMemo(() => {
		if (setInitialValues.current) {
			return finalizeForm;
		}

		return blankFinalizeSagAftraForm;
		// TODO: Check if dependencies are correct
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setInitialValues.current]);

	/*
	 * Formik Hooks
	 */
	const finalizeFormik = useFormik({
		initialValues: initialValues,
		enableReinitialize: true,
		validationSchema: schema,
		onSubmit: values => {
			dispatch(setUnionExportFinalizeFormAction(finalizeFormik.values));
			onFormSubmit(values);
		},
	});

	/*
	 * Effects
	 */
	useDebounceEffect(
		() => {
			if (finalizeFormik.dirty && !_.isEmpty(finalizeFormik.values)) {
				dispatch(setUnionExportFinalizeFormAction(finalizeFormik.values));
			}
		},
		500,
		[finalizeFormik.values, dispatch]
	);

	// initialize form values
	useEffect(() => {
		if (_.isEmpty(finalizeForm)) {
			const initialForm = { ...blankFinalizeSagAftraForm };

			// force session tracks to contain only recording if only one recording is selected
			if (selectedRecordingRows.length === 1) {
				initialForm.sessionTimes = [
					{
						...initialForm.sessionTimes[0],
						recordingIds: [selectedRecordingRows[0].recordingId],
					},
				];
			}

			dispatch(setUnionExportFinalizeFormAction(initialForm));
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [finalizeForm, dispatch]);

	useEffect(() => {
		const recordings = selectedRecordingIds.map(
			recordingId => recordingsById[recordingId].recording
		);

		// get unique producers from all selected recordings
		const producersById = {};

		recordings
			.flatMap(recording => recording.participants)
			.filter(participant =>
				participant.roles.some(role => role.detail === 'Producer')
			)
			.forEach(participant => {
				const name = participant.legalName
					? participant.legalName
					: participant.creditedName;

				producersById[participant.id] = {
					label: name,
					value: participant.id,
				};
			});

		// add custom producer in case it's not in the list
		if (
			finalizeFormik.values.sessionProducer &&
			!producersById[finalizeFormik.values.sessionProducer?.value]
		) {
			producersById[finalizeFormik.values.sessionProducer?.value] = {
				label: finalizeFormik.values.sessionProducer.label,
				value: finalizeFormik.values.sessionProducer.value,
			};
		}

		setSessionProducerOptions([...Object.values(producersById)]);
	}, [selectedRecordingIds, recordingsById, finalizeFormik.values]);

	useEffect(() => {
		if (!setInitialValues.current && !_.isEmpty(finalizeForm)) {
			setInitialValues.current = true;
		}
	}, [finalizeForm]);

	/*
	 * React Router Hooks
	 */
	const navigate = useNavigate();

	/*
	 * Callbacks
	 */
	const navigateToPrevStep = () => {
		dispatch(setUnionExportFinalizeFormAction(finalizeFormik.values));
		dispatch(previousExportStepAction());
	};

	const handleSaveForLater = () => {
		dispatch(setUnionExportFinalizeFormAction(finalizeFormik.values));
		navigate(ROUTES.Editor.path, { replace: true });
	};

	return (
		<FormikProvider value={finalizeFormik}>
			<Form onSubmit={finalizeFormik.handleSubmit}>
				<>
					<Card>
						<Card.Body>
							<Row>
								<Col xs={6}>
									<CreatableSelect
										label='Session Producer'
										name='sessionProducer'
										options={sessionProducerOptions}
										value={
											sessionProducerOptions.find(
												option =>
													option.value ===
													finalizeFormik.values.sessionProducer?.value
											) || ''
										}
										onChange={option => {
											finalizeFormik.setFieldValue('sessionProducer', option);
										}}
										onCreateOption={newOption => {
											const newProducer = {
												value: newOption,
												label: newOption,
											};

											finalizeFormik.setFieldValue(
												'sessionProducer',
												newProducer
											);
										}}
										errorFieldName='sessionProducer'
									/>
								</Col>
								<Col xs={6} />
								<Col xs={6}>
									<TextField
										label='Record Co. Name'
										name='recordCompanyName'
										value={finalizeFormik.values.recordCompanyName}
										onChange={finalizeFormik.handleChange}
										onBlur={finalizeFormik.handleBlur}
										isInvalid={
											finalizeFormik.touched.recordCompanyName &&
											!!finalizeFormik.errors.recordCompanyName
										}
										errorMessage={finalizeFormik.errors.recordCompanyName}
									/>
								</Col>

								<Col xs={6}>
									<TextField
										label='Record Co. Address'
										name='recordCompanyAddress'
										value={finalizeFormik.values.recordCompanyAddress}
										onChange={finalizeFormik.handleChange}
										onBlur={finalizeFormik.handleBlur}
										isInvalid={
											finalizeFormik.touched.recordCompanyAddress &&
											!!finalizeFormik.errors.recordCompanyAddress
										}
										errorMessage={finalizeFormik.errors.recordCompanyAddress}
									/>
								</Col>
							</Row>

							<hr />

							<div className='my-3' />

							<Row>
								<Col xs={6}>
									<TextField
										label='Job Number'
										name='jobNumber'
										value={finalizeFormik.values.jobNumber}
										onChange={finalizeFormik.handleChange}
										onBlur={finalizeFormik.handleBlur}
										isInvalid={
											finalizeFormik.touched.jobNumber &&
											!!finalizeFormik.errors.jobNumber
										}
										informationText='Enter Session Report Number'
										errorMessage={finalizeFormik.errors.jobNumber}
									/>
								</Col>
							</Row>

							<hr />

							<div className='my-3' />

							<FieldArray
								name='sessionTimes'
								render={arrayHelpers => (
									<div>
										{finalizeFormik.values.sessionTimes &&
											finalizeFormik.values.sessionTimes.map(
												(sessionTime, index) => (
													<Row
														key={index}
														className='d-flex justify-content-between'
													>
														<SessionTimesPicker
															formik={finalizeFormik}
															index={index}
															sessionTime={sessionTime}
															errorMessage='Session Time Is Required'
														/>

														{selectedRecordingRows.length > 1 && (
															<Col xs={5}>
																<Select
																	label='Session Tracks'
																	name={`sessionTimes[${index}].recordingIds`}
																	id={`sessionTimes[${index}].recordingIds`}
																	isMulti
																	options={sessionTracksOptions}
																	value={sessionTracksOptions.filter(option =>
																		finalizeFormik.values.sessionTimes[
																			index
																		].recordingIds.includes(option.value)
																	)}
																	onChange={selections => {
																		finalizeFormik.setFieldValue(
																			`sessionTimes[${index}].recordingIds`,
																			selections.map(
																				selection => selection.value
																			)
																		);
																	}}
																	onBlur={finalizeFormik.handleBlur}
																	errorFieldName={`sessionTimes[${index}].recordingIds`}
																/>
															</Col>
														)}

														<Col
															xs={1}
															className='d-flex align-items-center justify-content-start'
														>
															<IconButton
																icon='fas fa-plus-circle'
																color={theme.primary}
																onClick={() => {
																	arrayHelpers.push({
																		startTime: '',
																		endTime: '',
																		recordingIds:
																			selectedRecordingRows.length === 1
																				? [selectedRecordingRows[0].recordingId]
																				: [],
																	});
																}}
															/>
															{(index !== 0 ||
																finalizeFormik.values.sessionTimes.length >
																	1) && (
																<IconButton
																	icon='fas fa-trash'
																	color={theme.error}
																	onClick={() => arrayHelpers.remove(index)}
																/>
															)}
														</Col>
													</Row>
												)
											)}
									</div>
								)}
							/>

							<hr />

							<div className='my-3' />

							<Row>
								<Col xs={6}>
									<Select
										label='Recording Type'
										name='recordingType'
										options={sagAftraRecordingTypes}
										value={sagAftraRecordingTypes.find(
											p => p.value === finalizeFormik.values.recordingType
										)}
										onChange={selection => {
											finalizeFormik.setFieldValue(
												'recordingType',
												selection.value
											);
										}}
										onBlur={finalizeFormik.handleBlur}
										errorFieldName='recordingType'
									/>
								</Col>
							</Row>
						</Card.Body>
					</Card>

					<Row>
						<Col
							xs={12}
							className='d-flex justify-content-end align-items-center pb-3'
						>
							<Button label='Back' onClick={navigateToPrevStep} />
							<Button
								label='Save for Later'
								className='ml-3'
								onClick={handleSaveForLater}
							/>
							<Button
								label='Export'
								theme='dark'
								className='ml-3'
								type='submit'
							/>
						</Col>
					</Row>

					{/* <Card>
						<Card.Body>
							<pre>{JSON.stringify(finalizeFormik.values, null, 2)}</pre>
						</Card.Body>
					</Card> */}
				</>
			</Form>
		</FormikProvider>
	);
};

export default FinalizeStepSAGAFTRA;
