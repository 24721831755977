import ROUTES from '../../router/routes';

const _sectionNameByParentPath = {
	[ROUTES.Editor.path]: ROUTES.Editor.label,
	[ROUTES.Projects.path]: ROUTES.Projects.label,
	[ROUTES.SavedProfiles.path]: ROUTES.SavedProfiles.label,
	[ROUTES.GeneratedCodes.path]: ROUTES.GeneratedCodes.label,
	[ROUTES.IndieUpload.path]: ROUTES.IndieUpload.label,
	[ROUTES.Settings.path]: ROUTES.Settings.label,
	[ROUTES.Help.path]: ROUTES.Help.label,
	[ROUTES.Playlists.path]: ROUTES.Playlists.label,
};

const getSectionNameByPath = (path: string) => {
	const parentPath = path.split('/')[1];

	return (
		_sectionNameByParentPath[`/${parentPath}`] || parentPath
	).toUpperCase();
};

export default getSectionNameByPath;
