import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { hideModal, setModalTitle } from '../../../store/modal/actions';
import ImageCropInput from '../../form/ImageCropInput/ImageCropInput';
import Button from '../../layout/Button';

const ImageCropModal = ({ aspectRatio, title, onSave, outputType }) => {
	const dispatch = useDispatch();

	const [croppedImage, setCroppedImage] = useState(null);
	const [inputMimeType, setInputMimeType] = useState();
	const [isLoading, setIsLoading] = useState(false);

	const handleClose = () => {
		dispatch(hideModal());
	};

	const handleSave = async () => {
		setIsLoading(true);

		try {
			await onSave(croppedImage, inputMimeType);
		} finally {
			setIsLoading(false);
			handleClose();
		}
	};

	useEffect(() => {
		dispatch(setModalTitle(title));
	}, [dispatch, title]);

	return (
		<>
			<Modal.Body>
				<div className='px-4'>
					<ImageCropInput
						setCroppedImage={setCroppedImage}
						inputMimeType={inputMimeType}
						setInputMimeType={setInputMimeType}
						aspectRatio={aspectRatio}
						outputType={outputType}
					/>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button label='Cancel' onClick={handleClose} />
				<Button
					label='Save'
					theme='dark'
					onClick={handleSave}
					isDisabled={!croppedImage}
					isLoading={isLoading}
				/>
			</Modal.Footer>
		</>
	);
};

export default ImageCropModal;
