import leadWriterSplitSheetColumns from '../components/screens/Export/SelectParticipantsForExport/leadWriterSplitSheetColumns';
import ROUTES from '../router/routes';
import {
	clearAllCurrentExportDataAction,
	createExportAction,
	setExportLeadWriterNameAction,
	setExportMainArtistsAction,
} from '../store/exports/actions';
import EXPORT_TYPES from './exportType';
import writerRoles from './writerRoles.json';
import exportTiersByExportId from './exportTiersByExportId';
import EXPORTS_IDS from './exportsIds';
import ExportTierId from './exportTierId';
import { getAssetPath } from '../helpers/assetTools';
import { replacePathVariables } from '../helpers/routeTools';
import { hideAllModalsAction, showModalAction } from '../store/modal/actions';
import {
	CONFIRMATION_MODAL,
	PRODUCER_AGREEMENT_SHARE_MODAL,
} from './modalTypes';

export const enterpriseExportTypes = {
	[EXPORT_TYPES.AFM_B4]: {
		id: EXPORTS_IDS.AFM_B4,
		tier: exportTiersByExportId[EXPORTS_IDS.AFM_B4],
		label: 'Union Form AFM B4',
		sublabel: 'Export a starting point for an AFM B4 report.',
		icon: getAssetPath('img/GenericReleaseReport.svg'),
		type: EXPORT_TYPES.AFM_B4,
	} as ExportData,
	[EXPORT_TYPES.AFM_B9]: {
		id: EXPORTS_IDS.AFM_B9,
		tier: exportTiersByExportId[EXPORTS_IDS.AFM_B9],
		label: 'Union Form AFM B9',
		sublabel: 'Export a starting point for an AFM B9 report.',
		icon: getAssetPath('img/GenericReleaseReport.svg'),
		type: EXPORT_TYPES.AFM_B9,
	} as ExportData,
	[EXPORT_TYPES.SAG_AFTRA]: {
		id: EXPORTS_IDS.SAG_AFTRA,
		tier: exportTiersByExportId[EXPORTS_IDS.SAG_AFTRA],
		label: 'SAG-AFTRA Session Report',
		sublabel: 'Export a starting point for a SAG-AFTRA session report.',
		icon: getAssetPath('img/GenericReleaseReport.svg'),
		type: EXPORT_TYPES.SAG_AFTRA,
	} as ExportData,
	[EXPORT_TYPES.DIGITAL_DISTRIBUTION]: {
		id: EXPORTS_IDS.DIGITAL_DISTRIBUTION,
		tier: exportTiersByExportId[EXPORTS_IDS.DIGITAL_DISTRIBUTION],
		label: 'Soundways Digital Distribution',
		sublabel:
			'Signed artists to Soundways Digital Distribution can submit their release here.',
		icon: getAssetPath('img/GenericReleaseReport.svg'),
		type: EXPORT_TYPES.DIGITAL_DISTRIBUTION,
	} as ExportData,
	[EXPORT_TYPES.PUBLISHING_LABEL_COPY_DOCUMENT]: {
		id: EXPORTS_IDS.PUBLISHING_LABEL_COPY_DOCUMENT,
		tier: exportTiersByExportId[EXPORTS_IDS.PUBLISHING_LABEL_COPY_DOCUMENT],
		label: 'Publishing Label Copy (Document)',
		sublabel: 'Export Publishing Label copy as a Word Document.',
		icon: getAssetPath('img/GenericReleaseReport.svg'),
		type: EXPORT_TYPES.PUBLISHING_LABEL_COPY_DOCUMENT,
		nextRoute: ROUTES.ExportPreview.path,
		createOnValidation: true,
		hasPreview: true,
	} as ExportData,
	[EXPORT_TYPES.PUBLISHING_LABEL_COPY_SPREADSHEET]: {
		id: EXPORTS_IDS.PUBLISHING_LABEL_COPY_SPREADSHEET,
		tier: exportTiersByExportId[EXPORTS_IDS.PUBLISHING_LABEL_COPY_SPREADSHEET],
		label: 'Publishing Label Copy (Spreadsheet)',
		sublabel: 'Export Publishing Label copy as an Excel Spreadsheet.',
		icon: getAssetPath('img/Spreadsheet.svg'),
		type: EXPORT_TYPES.PUBLISHING_LABEL_COPY_SPREADSHEET,
		// nextRoute: ROUTES.ExportPreview.path,
		// createOnValidation: true,
		// hasPreview: true,
	} as ExportData,
};

export const proExportTypes = {
	[EXPORT_TYPES.SOUND_CREDIT_SINGLE]: {
		id: EXPORTS_IDS.SOUND_CREDIT_SINGLE,
		tier: exportTiersByExportId[EXPORTS_IDS.SOUND_CREDIT_SINGLE],
		label: 'Sound Credit (Single)',
		sublabel: 'The standard Sound Credit report for a single.',
		icon: getAssetPath('img/SoundCreditReport.svg'),
		type: EXPORT_TYPES.SOUND_CREDIT_SINGLE,
	} as ExportData,
	[EXPORT_TYPES.SOUND_CREDIT_RELEASE]: {
		id: EXPORTS_IDS.SOUND_CREDIT_RELEASE,
		tier: exportTiersByExportId[EXPORTS_IDS.SOUND_CREDIT_RELEASE],
		label: 'Sound Credit (Album)',
		sublabel: 'The standard Sound Credit report for an album or EP.',
		icon: getAssetPath('img/GenericReleaseReport.svg'),
		type: EXPORT_TYPES.SOUND_CREDIT_RELEASE,
	} as ExportData,
	[EXPORT_TYPES.LABEL_STYLE_SINGLE]: {
		id: EXPORTS_IDS.LABEL_STYLE_SINGLE,
		tier: exportTiersByExportId[EXPORTS_IDS.LABEL_STYLE_SINGLE],
		label: 'Label Copy (Single)',
		sublabel: 'For singles, this report can be used to deliver information.',
		icon: getAssetPath('img/GenericReport.svg'),
		type: EXPORT_TYPES.LABEL_STYLE_SINGLE,
	} as ExportData,
	[EXPORT_TYPES.LABEL_STYLE_RELEASE_REGULAR]: {
		id: EXPORTS_IDS.LABEL_STYLE_RELEASE_REGULAR,
		tier: exportTiersByExportId[EXPORTS_IDS.LABEL_STYLE_RELEASE_REGULAR],
		label: 'Label Copy (Album Regular)',
		sublabel:
			'For albums and EPs, this report provides a compact credit listing used for most albums.',
		icon: getAssetPath('img/GenericReleaseReport.svg'),
		type: EXPORT_TYPES.LABEL_STYLE_RELEASE_REGULAR,
	} as ExportData,
	[EXPORT_TYPES.LABEL_STYLE_RELEASE_LONG]: {
		id: EXPORTS_IDS.LABEL_STYLE_RELEASE_LONG,
		tier: exportTiersByExportId[EXPORTS_IDS.LABEL_STYLE_RELEASE_LONG],
		label: 'Label Copy (Album Long)',
		sublabel:
			'For albums and EPs, this report can be used to deliver information for long-form printing.',
		icon: getAssetPath('img/GenericReleaseReport.svg'),
		type: EXPORT_TYPES.LABEL_STYLE_RELEASE_LONG,
	} as ExportData,
	[EXPORT_TYPES.LABEL_STYLE_PERSONNEL_SPREADSHEET]: {
		id: EXPORTS_IDS.LABEL_STYLE_PERSONNEL_SPREADSHEET,
		tier: exportTiersByExportId[EXPORTS_IDS.LABEL_STYLE_PERSONNEL_SPREADSHEET],
		label: 'Label Spreadsheet',
		sublabel:
			'This spreadsheet can be used to provide a performer and studio personnel list.',
		icon: getAssetPath('img/Spreadsheet.svg'),
		type: EXPORT_TYPES.LABEL_STYLE_PERSONNEL_SPREADSHEET,
	} as ExportData,
	[EXPORT_TYPES.BIG_MACHINE_SINGLE]: {
		id: EXPORTS_IDS.BIG_MACHINE_SINGLE,
		tier: exportTiersByExportId[EXPORTS_IDS.BIG_MACHINE_SINGLE],
		label: 'Big Machine Single Report',
		sublabel: 'Export a starting point for a Big Machine single report.',
		icon: getAssetPath('img/GenericReport.svg'),
		type: EXPORT_TYPES.BIG_MACHINE_SINGLE,
	} as ExportData,
	[EXPORT_TYPES.BIG_MACHINE_ALBUM]: {
		id: EXPORTS_IDS.BIG_MACHINE_ALBUM,
		tier: exportTiersByExportId[EXPORTS_IDS.BIG_MACHINE_ALBUM],
		label: 'Big Machine Album Report',
		sublabel: 'Export a starting point for a Big Machine album report.',
		icon: getAssetPath('img/GenericReleaseReport.svg'),
		type: EXPORT_TYPES.BIG_MACHINE_ALBUM,
	} as ExportData,
	[EXPORT_TYPES.SPLIT_SHEET_STANDARD]: {
		id: EXPORTS_IDS.SPLIT_SHEET_STANDARD,
		tier: exportTiersByExportId[EXPORTS_IDS.SPLIT_SHEET_STANDARD],
		label: 'Split Sheet (Standard)',
		sublabel:
			'Sends an eSignature request via email. All writers control usage and administration of the song.',
		icon: getAssetPath('img/SocialGraph.svg'),
		type: EXPORT_TYPES.SPLIT_SHEET_STANDARD,
		nextRoute: ROUTES.ExportPreview.path,
		createOnValidation: true,
		hasPreview: true,
	} as ExportData,
	[EXPORT_TYPES.SPLIT_SHEET_SINGLE_WRITER]: {
		id: EXPORTS_IDS.SPLIT_SHEET_SINGLE_WRITER,
		tier: exportTiersByExportId[EXPORTS_IDS.SPLIT_SHEET_SINGLE_WRITER],
		label: 'Split Sheet (Single Writer Control)',
		sublabel:
			'Sends an eSignature request via email. A single designated writer controls usage and administration of the song.',
		icon: getAssetPath('img/SocialGraph.svg'),
		type: EXPORT_TYPES.SPLIT_SHEET_SINGLE_WRITER,
		nextRoute: ROUTES.SelectExportParticipants.path,
		hasPreview: true,
		skipGenerate: true,
		// why did I try so hard to make this generic...
		//
		// these are all parameters that should be used by the next
		// screen, in this case it's the SelectExportParticipants screen.
		// But this function could be used for any screen that needs
		// parameters and is not the ExportPreview or ExportValidation screens.
		//
		// Since it's the only export that uses this function,
		// we could probably just hardcode these callbacks
		// title and selection logic into the SelectExportParticipants screen.
		getNextExportParams: ({ navigate, dispatch, recordingId }) => ({
			columns: leadWriterSplitSheetColumns,
			onSelect: (participants: Participant[]) => {
				if (participants.length)
					dispatch(setExportLeadWriterNameAction(participants[0].creditedName));
			},
			onSubmit: () => {
				dispatch(createExportAction(null, true));

				// used to use relative routing here, with ../../
				// but realized that it will not work since the navigate
				// function is not aware of the current route
				// so it tries to navigate to a nonsense route
				// for example, if these parameters are passed in:
				// /recordings/1/<editor-section>
				// then the navigate function will try to navigate to:
				// /recordings/1/<editor-section>/../../export/preview
				// which is nonsense
				navigate(
					replacePathVariables(ROUTES.ExportPreview.path, {
						recordingId,
					}),
					{
						replace: true,
					}
				);
			},
			onCancel: () => {
				dispatch(clearAllCurrentExportDataAction());
				navigate(ROUTES.Editor.path, {
					replace: true,
				});
			},
			title: 'SELECT LEAD WRITER',
			participantFilter: (participant: Participant) =>
				participant.roles.some(role => writerRoles.includes(role.detail)),
			isDisabled: (participants: Participant[]) =>
				(participants?.[0]?.creditedName ?? '').trim().length === 0,
			singleSelect: true,
		}),
	} as ExportData,
	[EXPORT_TYPES.EBR_STANDARD_TEMPLATE]: {
		id: EXPORTS_IDS.EBR_STANDARD_TEMPLATE,
		tier: exportTiersByExportId[EXPORTS_IDS.EBR_STANDARD_TEMPLATE],
		label: 'Register Indie Song with PRO',
		sublabel: 'Streamlined registration of song with ASCAP, BMI and/or SOCAN',
		icon: getAssetPath('img/SoundCreditReport.svg'),
		type: EXPORT_TYPES.EBR_STANDARD_TEMPLATE,
		getWarningModalProps: ({
			setShowWarningModal,
			handleExport,
			exportType,
		}) => ({
			titleText: 'WARNING',
			bodyText:
				'This feature should not be used with any major label release. For all other labels, do not register unless you have approval from your label.',
			confirmText: 'This is an indie recording',
			cancelText: 'Cancel',
			onCancel: () => setShowWarningModal(false),
			onConfirm: () => handleExport(exportType),
		}),
	} as ExportData,
	[EXPORT_TYPES.SOUND_EXCHANGE_SPREADSHEET]: {
		id: EXPORTS_IDS.SOUND_EXCHANGE_SPREADSHEET,
		tier: exportTiersByExportId[EXPORTS_IDS.SOUND_EXCHANGE_SPREADSHEET],
		label: 'Sound Exchange',
		sublabel: 'Transmit performer line-up to Sound Exchange.',
		icon: getAssetPath('img/sound_exchange_logo_purple.svg'),
		type: EXPORT_TYPES.SOUND_EXCHANGE_SPREADSHEET,
		getWarningModalProps: ({
			setShowWarningModal,
			handleExport,
			exportType,
		}) => ({
			titleText: 'IMPORTANT',
			bodyText:
				'The information provided will be transmitted to SoundExchange to assist with lineup claiming.  However, please still use the SoundExchange Direct Portal to directly submit claims to SoundExchange.',
			confirmText: 'OK',
			cancelText: 'Cancel',
			onCancel: () => setShowWarningModal(false),
			onConfirm: () => handleExport(exportType),
		}),
	} as ExportData,
	[EXPORT_TYPES.PPL_REGISTRATION]: {
		id: EXPORTS_IDS.PPL_REGISTRATION,
		tier: exportTiersByExportId[EXPORTS_IDS.PPL_REGISTRATION],
		label: 'PPL Registration',
		// sublabel: 'Send recording information to PPL for registration.',
		sublabel: 'Export a starting point for a PPL Spreadsheet.',
		icon: getAssetPath('img/pplLogoSVG.svg'),
		type: EXPORT_TYPES.PPL_REGISTRATION,
	} as ExportData,
	[EXPORT_TYPES.PRODUCER_AGREEMENT]: {
		id: EXPORTS_IDS.PRODUCER_AGREEMENT,
		tier: exportTiersByExportId[EXPORTS_IDS.PRODUCER_AGREEMENT],
		label: 'Producer Agreement',
		sublabel:
			'This will send a producer agreement to the producer via HelloSign for eSignature, providing a standard 17% royalty.',
		icon: getAssetPath('img/SocialGraph.svg'),
		type: EXPORT_TYPES.PRODUCER_AGREEMENT,
		nextRoute: ROUTES.SelectExportParticipants.path,
		// still need to refactor this whole set of boolean parameters, I feel like their purposes overlap
		skipGenerate: true,
		hasPreview: true,
		getWarningModalProps: ({
			setShowWarningModal,
			handleExport,
			exportType,
			dispatch,
		}) => ({
			titleText: 'WARNING',
			bodyText:
				'By proceeding you are acknowledging that this recording only has ONE producer.',
			confirmText: 'Proceed',
			cancelText: 'Cancel',
			onCancel: () => setShowWarningModal(false),
			onConfirm: () => {
				setShowWarningModal(false);

				dispatch(
					showModalAction(PRODUCER_AGREEMENT_SHARE_MODAL, {
						size: 'md',
						onSubmit: (share: number) => {
							dispatch(hideAllModalsAction());
							handleExport(exportType, { producerAgreementShare: share });
						},
					})
				);
			},
		}),
		// always use Skip Generate if you want to use the getNextExportParams function
		getNextExportParams: ({ navigate, dispatch, recordingId }) => ({
			columns: leadWriterSplitSheetColumns,
			onSubmit: (mainArtists: Participant[]) => {
				const artistsWithoutEmail = mainArtists.filter(artist => !artist.email);
				console.log(artistsWithoutEmail);

				if (artistsWithoutEmail.length > 0) {
					dispatch(
						showModalAction(CONFIRMATION_MODAL, {
							size: 'md',
							title: 'MISSING EMAILS',
							description: (
								<>
									<p>
										<strong>
											Please make sure that all artists have email addresses
											before proceeding.
										</strong>
									</p>

									<p>The following artists do not have email addresses:</p>
									<ul>
										{artistsWithoutEmail.map(artist => (
											<li key={artist.id}>{artist.creditedName}</li>
										))}
									</ul>

									<p>
										You can add email addresses to artists by editing each one
										individually from the songs's Credits screen and then come
										back from the Exports menu again.
									</p>
								</>
							),
							confirmAction: {
								label: 'Go to Credits',
								onClick: () => {
									dispatch(hideAllModalsAction());
									navigate(
										replacePathVariables(ROUTES.EditRecordingCredits.path, {
											recordingId,
										}),
										{
											replace: true,
										}
									);
								},
							},
							cancelAction: {
								label: 'Cancel',
								onClick: () => {
									dispatch(hideAllModalsAction());
								},
							},
						})
					);

					return;
				}

				dispatch(
					setExportMainArtistsAction(
						mainArtists.map(a => ({
							name: a.legalName || a.creditedName,
							email: a.email,
						}))
					)
				);

				dispatch(createExportAction(null, true));

				navigate(
					replacePathVariables(ROUTES.ExportPreview.path, {
						recordingId,
					}),
					{
						replace: true,
					}
				);
			},
			onCancel: () => {
				dispatch(clearAllCurrentExportDataAction());
				navigate(ROUTES.Editor.path, {
					replace: true,
				});
			},
			title: 'SELECT UP TO 5 MAIN ARTISTS',
			getValidationMessage: (selection: Participant[]) => {
				if (selection.length === 0) {
					return 'Please select at least one artist.';
				}

				if (selection.length > 5) {
					return 'Please select up to 5 artists.';
				}

				return '';
			},
			isDisabled: (selection: Participant[]) =>
				selection.length === 0 || selection.length > 5,
			singleSelect: false,
		}),
	} as ExportData,
	[EXPORT_TYPES.SIDE_ARTIST_AGREEMENT]: {
		id: EXPORTS_IDS.SIDE_ARTIST_AGREEMENT,
		tier: exportTiersByExportId[EXPORTS_IDS.SIDE_ARTIST_AGREEMENT],
		label: 'Side Artist Release',
		sublabel:
			'This will send a release to side artists to clarify their rights.',
		icon: getAssetPath('img/SocialGraph.svg'),
		type: EXPORT_TYPES.SIDE_ARTIST_AGREEMENT,
		nextRoute: ROUTES.ExportPreview.path,
		createOnValidation: true,
		hasPreview: true,
	} as ExportData,
	[EXPORT_TYPES.MLC_SPREADSHEET]: {
		id: EXPORTS_IDS.MLC_SPREADSHEET,
		tier: exportTiersByExportId[EXPORTS_IDS.MLC_SPREADSHEET],
		label: 'MLC Spreadsheet',
		sublabel: 'Export a starting point for an MLC spreadsheet.',
		icon: getAssetPath('img/Spreadsheet.svg'),
		type: EXPORT_TYPES.MLC_SPREADSHEET,
	} as ExportData,
	[EXPORT_TYPES.RIN_EXPORT]: {
		tier: ExportTierId.BUSINESS,
		label: 'RIN 2.0 File',
		sublabel: 'Export a Recording Information Notification (RIN) file.',
		icon: getAssetPath('img/GenericReleaseReport.svg'),
		type: EXPORT_TYPES.RIN_EXPORT,
	} as ExportData,
	[EXPORT_TYPES.LYRIC_SHEET]: {
		id: EXPORTS_IDS.LYRIC_SHEET,
		tier: exportTiersByExportId[EXPORTS_IDS.LYRIC_SHEET],
		label: 'Sound Credit Lyric Sheet',
		sublabel: 'Lyric sheets provide basic information with a focus on lyrics.',
		icon: getAssetPath('img/Microphone.svg'),
		type: EXPORT_TYPES.LYRIC_SHEET,
	} as ExportData,
	[EXPORT_TYPES.METADATA_MASTERING]: {
		id: EXPORTS_IDS.METADATA_MASTERING,
		tier: exportTiersByExportId[EXPORTS_IDS.METADATA_MASTERING],
		label: 'Metadata for Mastering',
		sublabel:
			'This spreadsheet can be used to provide perfomer and studio personnel list.',
		icon: getAssetPath('img/CDText.svg'),
		type: EXPORT_TYPES.METADATA_MASTERING,
	} as ExportData,
	[EXPORT_TYPES.ALL_MUSIC_SPREADSHEET]: {
		id: EXPORTS_IDS.ALL_MUSIC_SPREADSHEET,
		tier: exportTiersByExportId[EXPORTS_IDS.ALL_MUSIC_SPREADSHEET],
		label: 'AllMusic Spreadsheet',
		sublabel: 'Export a starting point for an AllMusic spreadsheet.',
		icon: getAssetPath('img/Spreadsheet.svg'),
		type: EXPORT_TYPES.ALL_MUSIC_SPREADSHEET,
	} as ExportData,
	[EXPORT_TYPES.THE_ORCHARD_SPREADSHEET]: {
		id: EXPORTS_IDS.THE_ORCHARD_SPREADSHEET,
		tier: exportTiersByExportId[EXPORTS_IDS.THE_ORCHARD_SPREADSHEET],
		label: 'The Orchard Spreadsheet',
		sublabel: 'Export a starting point for a spreadsheet for The Orchard.',
		icon: getAssetPath('img/Spreadsheet.svg'),
		type: EXPORT_TYPES.THE_ORCHARD_SPREADSHEET,
	} as ExportData,
	[EXPORT_TYPES.CD_INSERT_SHORT]: {
		id: EXPORTS_IDS.CD_INSERT_SHORT,
		tier: exportTiersByExportId[EXPORTS_IDS.CD_INSERT_SHORT],
		label: 'CD Insert (Short)',
		sublabel:
			'Generate CD inserts with full information listed with a minimum length.',
		icon: getAssetPath('img/CDCase.svg'),
		type: EXPORT_TYPES.CD_INSERT_SHORT,
	} as ExportData,
	[EXPORT_TYPES.CD_INSERT_LONG]: {
		id: EXPORTS_IDS.CD_INSERT_LONG,
		tier: exportTiersByExportId[EXPORTS_IDS.CD_INSERT_LONG],
		label: 'CD Insert (Long)',
		sublabel:
			'Generate CD inserts with full information listed in a long form.',
		icon: getAssetPath('img/CDCase.svg'),
		type: EXPORT_TYPES.CD_INSERT_LONG,
	} as ExportData,
	[EXPORT_TYPES.VINYL_SLEEVE_SHORT]: {
		id: EXPORTS_IDS.VINYL_SLEEVE_SHORT,
		tier: exportTiersByExportId[EXPORTS_IDS.VINYL_SLEEVE_SHORT],
		label: 'Vinyl Sleeve (Short)',
		sublabel:
			'Create a 12" vinyl sleeve that contains the full credits listed in a short way.',
		icon: getAssetPath('img/VinylSleeve.svg'),
		type: EXPORT_TYPES.VINYL_SLEEVE_SHORT,
	} as ExportData,
	[EXPORT_TYPES.VINYL_SLEEVE_LONG]: {
		id: EXPORTS_IDS.VINYL_SLEEVE_LONG,
		tier: exportTiersByExportId[EXPORTS_IDS.VINYL_SLEEVE_LONG],
		label: 'Vinyl Sleeve (Long)',
		sublabel: 'Create a 12" vinyl sleeve that contains the full credits.',
		icon: getAssetPath('img/VinylSleeve.svg'),
		type: EXPORT_TYPES.VINYL_SLEEVE_LONG,
	} as ExportData,
	// [EXPORT_TYPES.INDIE_UPLOAD]: {
	// 	tier: ExportTierId.STARTER,
	// 	label: 'Sound Credit Indie Database',
	// 	sublabel: 'Publish recording or album credits to the Indie Database.',
	// 	icon: getAssetPath('img/SocialGraph.svg'),
	// 	type: EXPORT_TYPES.INDIE_UPLOAD,
	// } as ExportData,
};

export const regularExportTypes = {
	[EXPORT_TYPES.INSTAGRAM_IMAGE]: {
		id: EXPORTS_IDS.INSTAGRAM_IMAGE,
		tier: exportTiersByExportId[EXPORTS_IDS.INSTAGRAM_IMAGE],
		label: 'Social Media Image (Instagram)',
		sublabel: 'Export an image to share on social media.',
		icon: getAssetPath('img/SocialGraph.svg'),
		type: EXPORT_TYPES.INSTAGRAM_IMAGE,
	} as ExportData,
	[EXPORT_TYPES.STORIES]: {
		id: EXPORTS_IDS.STORIES,
		tier: exportTiersByExportId[EXPORTS_IDS.STORIES],
		label: 'Social Media Image (Stories)',
		sublabel: 'Export an image to share on social media.',
		icon: getAssetPath('img/SocialGraph.svg'),
		type: EXPORT_TYPES.STORIES,
	} as ExportData,
	[EXPORT_TYPES.FACEBOOK_TWITTER_IMAGE]: {
		id: EXPORTS_IDS.FACEBOOK_TWITTER_IMAGE,
		tier: exportTiersByExportId[EXPORTS_IDS.FACEBOOK_TWITTER_IMAGE],
		label: 'Social Media Image (Facebook/Twitter)',
		sublabel: 'Export an image to share on social media.',
		icon: getAssetPath('img/SocialGraph.svg'),
		type: EXPORT_TYPES.FACEBOOK_TWITTER_IMAGE,
	} as ExportData,
};
