enum ExportId {
	LABEL_STYLE_SINGLE = 1,
	LABEL_STYLE_RELEASE_LONG = 2,
	LABEL_STYLE_RELEASE_REGULAR = 3,
	LYRIC_SHEET = 4,
	CD_INSERT_SHORT = 5,
	CD_INSERT_LONG = 6,
	LABEL_STYLE_PERSONNEL_SPREADSHEET = 7,
	SOUND_CREDIT_SINGLE = 8,
	SOUND_CREDIT_RELEASE = 9,
	VINYL_SLEEVE_LONG = 10,
	VINYL_SLEEVE_SHORT = 11,
	METADATA_MASTERING = 12,
	ALL_MUSIC_SPREADSHEET = 13,
	THE_ORCHARD_SPREADSHEET = 14,
	SOUND_EXCHANGE_SPREADSHEET = 15,
	MLC_SPREADSHEET = 16,
	AFM_B4 = 18,
	AFM_B9 = 19,
	SYNCHTANK = 20,
	SAG_AFTRA = 21,
	BIG_MACHINE_SINGLE = 22,
	BIG_MACHINE_ALBUM = 23,
	INSTAGRAM_IMAGE = 24,
	STORIES = 25,
	FACEBOOK_TWITTER_IMAGE = 26,
	CD_BABY_EXPORT_LONG = 27,
	CD_BABY_EXPORT_SHORT = 28,
	SOUND_CREDIT_EMAIL = 30,
	EBR_STANDARD_TEMPLATE = 31,
	PPL_REGISTRATION = 32,
	PRODUCER_ROYALTY_AGREEMENT = 33,
	SPLIT_SHEET_STANDARD = 34,
	SPLIT_SHEET_SINGLE_WRITER = 35,
	PRODUCER_AGREEMENT = 36,
	SIDE_ARTIST_AGREEMENT = 37,
	PUBLISHING_LABEL_COPY_DOCUMENT = 39,
	PUBLISHING_LABEL_COPY_SPREADSHEET = 41,
	DIGITAL_DISTRIBUTION = 42,
	DRIP_IMAGES = 43,
}

export default ExportId;
