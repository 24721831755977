import React, { useCallback, useContext, useMemo } from 'react';
import { Card } from 'react-bootstrap';
import PillSearchBar from '../../../form/PillSearchBar';
import PlaylistActions from './PlaylistActions/PlaylistActions';
import PlaylistDetailsHeader from './PlaylistDetailsHeader';
import './PlaylistDetails.scss';
import { showModalAction } from '@/store/modal/actions';
import {
	ADD_FILES_TO_PLAYLIST_MODAL,
	UPLOAD_FILES_TO_PLAYLIST_MODAL,
} from '@/constants/modalTypes';
import { getCurrentPlaylist } from '@/store/playlists/selectors';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import ProjectUsageStatus from '@/components/screens/Projects/ProjectUsageStatus/ProjectUsageStatus';
import { Helmet } from 'react-helmet';
import PlaylistCollabActions from './PlaylistCollabActions';
import FilesDragAndDropContainer from '../../../layout/FilesDragAndDropContainer';
import DndPlaylistFileTable from './PlaylistFileTable/DndPlaylistFileTable';
import { SelectedPlaylistContext } from '../../../../context/SelectedPlaylistContext';

const PlaylistDetails = () => {
	const dispatch = useAppDispatch();
	const params = useParams<{ playlistId: string }>();

	const { playlistId } = params;

	const { searchTerm, setSearchTerm } =
		useContext<SelectedPlaylistContextType | null>(
			SelectedPlaylistContext
		) as SelectedPlaylistContextType;

	const currentPlaylistId = useMemo(() => {
		return playlistId ? parseInt(playlistId) : null;
	}, [playlistId]);

	const { userPermissions } = useAppSelector(state => state.auth);
	const currentPlaylist = useAppSelector(getCurrentPlaylist);
	const playlistFiles = useMemo(
		() => currentPlaylist?.playlist?.files ?? [],
		[currentPlaylist]
	);

	const handleDrop = useCallback(
		(e: DragEvent) => {
			const files = e.dataTransfer?.files;

			if (!files || !files.length) {
				return;
			}

			e.preventDefault();
			e.stopPropagation();

			const showUploadVersionsOption = playlistFiles.length > 0;

			if (showUploadVersionsOption) {
				dispatch(
					showModalAction(ADD_FILES_TO_PLAYLIST_MODAL, {
						playlistId: currentPlaylistId,
						size: 'md',
						initialFiles: Array.from(files),
					})
				);

				return;
			}

			dispatch(
				showModalAction(UPLOAD_FILES_TO_PLAYLIST_MODAL, {
					playlistId: currentPlaylistId,
					size: 'xl',
					initialFiles: Array.from(files),
				})
			);
		},
		[currentPlaylistId, dispatch, playlistFiles]
	);

	return (
		<>
			<Helmet>
				<title>
					{currentPlaylist?.playlist?.name ?? 'Playlists'}{' '}
					{process.env.REACT_APP_TAB_TITLE}
				</title>
			</Helmet>

			<FilesDragAndDropContainer className='p-2' onDrop={handleDrop}>
				<Card className='p-4 playlist-details__container'>
					<PlaylistCollabActions />
					<PlaylistDetailsHeader />

					<div className='my-3' />
					<div className='d-flex justify-content-between'>
						<PlaylistActions />

						<div className='d-flex'>
							{userPermissions?.projectLimit != null &&
								userPermissions?.projectLimit > 0 && (
									<ProjectUsageStatus fetchOnMount className='mr-2' />
								)}
							<PillSearchBar
								theme='light'
								placeholder='Search...'
								value={searchTerm}
								onChange={e => setSearchTerm(e.target.value)}
							/>
						</div>
					</div>

					<div className='my-3' />
					<DndPlaylistFileTable />
				</Card>
			</FilesDragAndDropContainer>
		</>
	);
};

export default PlaylistDetails;
