import { ErrorMessage } from 'formik';
import { Form } from 'react-bootstrap';
import CloudProfileSelectStyles from './CloudProfileSelectStyles';
import theme from '../../../theme.module.scss';
import AsyncSelect from 'react-select/async';
import { GroupBase, MenuListProps, components } from 'react-select';
import './CloudProfileSelect.scss';

export type CloudProfileSelectProps = {
	name: string;
	label?: string;
	errorFieldName?: string;
	dense?: boolean;
	fullWidth?: boolean;
	className?: string;
	onChange: (value: any) => void;
	addManually: () => void;
	value: any;
	onInputChange: (inputValue: string) => Promise<any>;
	isLoading?: boolean;
	onBlur?: () => void;
	isDisabled?: boolean;
};

const CloudProfileSelect = ({
	name,
	label,
	errorFieldName,
	dense = false,
	fullWidth = false,
	className = '',
	onChange,
	addManually,
	value,
	onInputChange,
	isLoading = false,
	onBlur,
	isDisabled = false,
}: CloudProfileSelectProps) => {
	const MenuList = (props: MenuListProps<any, boolean, GroupBase<any>>) => {
		return (
			<components.MenuList {...props}>
				{props.children}
				{!isLoading && (
					<button
						className='btn font-weight-bold'
						style={{
							width: '100%',
							display: 'grid',
							placeItems: 'center',
							color: '#212132',
						}}
						onClick={() => addManually()}
					>
						ADD MANUALLY
					</button>
				)}
			</components.MenuList>
		);
	};

	return (
		<Form.Group
			className={`${dense ? '' : 'mb-4'} ${
				fullWidth ? 'w-100' : ''
			} ${className}`}
		>
			{dense ? <></> : <Form.Label htmlFor={name}>{label}</Form.Label>}
			<AsyncSelect<ProfileSelectOption>
				loadingMessage={() => 'Loading...'}
				placeholder="Enter the participant's name..."
				loadOptions={onInputChange}
				components={{ MenuList }}
				onChange={onChange}
				cacheOptions
				onBlur={onBlur}
				getOptionValue={option => option.name}
				styles={CloudProfileSelectStyles}
				isSearchable
				value={value}
				isLoading={isLoading}
				openMenuOnClick={false}
				isDisabled={isDisabled}
				menuShouldScrollIntoView
				formatOptionLabel={option => (
					<div className='cloud-profile-select-option'>
						{option.disabled ? (
							<span className={'text-secondary w-100'}>{option.name}</span>
						) : (
							<>
								{option.imagePath ? (
									<img
										className='mr-3 rounded-circle'
										alt={`${option.name} avatar`}
										width={40}
										height={40}
										src={option.imagePath}
									/>
								) : (
									<div className='mr-3 fallback-image'>
										<span>{option.name.trim().charAt(0)?.toUpperCase()}</span>
									</div>
								)}
								<div className='d-flex gap-2 flex-column'>
									{/* <span className="text-primary">{option?.id}</span> */}
									<span>{option.name}</span>
									{option?.isni && (
										<>
											<span className='cloud-profile-select-isni'>
												ISNI <strong>{option?.isni}</strong>
											</span>
										</>
									)}
									<span className='cloud-profile-select-location'>
										{option.roles?.length
											? option.roles.join(', ')
											: option.location
											? option.location
											: 'Unlisted location'}
									</span>
								</div>
							</>
						)}
					</div>
				)}
			/>
			{errorFieldName && (
				<ErrorMessage name={errorFieldName}>
					{msg => {
						return (
							<small
								style={{ color: theme.error, height: 0, position: 'absolute' }}
								className='mt-1'
							>
								{msg}
							</small>
						);
					}}
				</ErrorMessage>
			)}
		</Form.Group>
	);
};

export default CloudProfileSelect;
