import { useCallback, useEffect, useMemo } from 'react';
import { PLAYLIST_COLLAB_MODAL } from '../../../../../constants/modalTypes';
import {
	setModalTitle,
	showModalAction,
} from '../../../../../store/modal/actions';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { AvatarGroup } from 'primereact/avatargroup';
import { fetchPlaylistEditorsAction } from '../../../../../store/playlists/actions';
import { Avatar } from 'primereact/avatar';
import SoundCreditAvatar from '../../../../layout/SoundCreditAvatar/SoundCreditAvatar';
import theme from '@/theme.module.scss';
import styles from './PlaylistCollabActions.module.scss';
import clsx from 'clsx';
import PillButton from '../../../../layout/PillButton';
import { Button } from 'primereact/button';

export type PlaylistCollabActionsProps = {};

const PlaylistCollabActions = (props: PlaylistCollabActionsProps) => {
	const dispatch = useAppDispatch();
	const { currentPlaylistId, currentPlaylistEditors } = useAppSelector(
		state => state.playlists
	);

	const acceptedEditors = useMemo(() => {
		if (!currentPlaylistEditors) return null;

		return currentPlaylistEditors.editors.filter(editor => editor.isAccepted);
	}, [currentPlaylistEditors]);

	const handleCollab = useCallback(() => {
		dispatch(
			showModalAction(PLAYLIST_COLLAB_MODAL, {
				size: 'md',
				playlistId: currentPlaylistId,
			})
		);
	}, [currentPlaylistId, dispatch]);

	const fetchEditors = useCallback(async () => {
		if (!currentPlaylistId) return;

		try {
			await dispatch(fetchPlaylistEditorsAction(currentPlaylistId));
		} catch (error) {
			console.error(error);
		}
	}, [currentPlaylistId, dispatch]);

	useEffect(() => {
		dispatch(setModalTitle('Playlist Collaborators'));
		fetchEditors();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			className={clsx('w-100 d-flex justify-content-end align-items-center')}
		>
			<Button
				text
				tooltipOptions={{ position: 'bottom' }}
				tooltip='View Collaborators'
				className={clsx(styles['editor-list-container'], 'mr-2')}
				onClick={handleCollab}
			>
				<AvatarGroup>
					{acceptedEditors &&
					currentPlaylistEditors?.playlistId === currentPlaylistId ? (
						<>
							{acceptedEditors.slice(0, 5).map(editor => {
								return (
									<SoundCreditAvatar
										key={editor.userId}
										name={editor.name}
										image={editor.imagePath}
										size='normal'
										shape='circle'
									/>
								);
							})}
							{acceptedEditors.length > 5 && (
								<Avatar
									label={`+${currentPlaylistEditors.editors.length - 5}`}
									shape='circle'
									size='normal'
									style={{
										backgroundColor: theme.primary,
										color: 'white',
									}}
								/>
							)}
						</>
					) : (
						Array.from(Array(5).keys()).map(index => {
							return (
								<SoundCreditAvatar
									key={index}
									size='normal'
									shape='circle'
									className='p-skeleton p-skeleton-circle'
									isLoading
								/>
							);
						})
					)}
				</AvatarGroup>
			</Button>

			<PillButton
				label='Add Collaborators'
				onClick={handleCollab}
				leftIcon='fas fa-user-plus'
				theme='colorful-gradient'
				style={{ color: 'white' }}
			/>
		</div>
	);
};

export default PlaylistCollabActions;
