import React, { useCallback } from 'react';
import styles from './SearchBar.module.scss';
import theme from '../../../theme.module.scss';
import clsx from 'clsx';
import { InputText } from 'primereact/inputtext';

export type SearchBarProps = {
	placeholder?: string;
	onChange?: (value: string) => void;
	value?: string;
	renderWithoutRow?: boolean;
	style?: React.CSSProperties;
	isLoading?: boolean;
	className?: string;
	fullHeight?: boolean;
	fullWidth?: boolean;
	height?: React.CSSProperties['height'];
};

const SearchBar = ({
	placeholder = 'Search...',
	onChange = () => {},
	value = '',
	renderWithoutRow = false,
	style = {},
	isLoading = false,
	className = '',
	fullHeight = false,
	fullWidth = false,
	height = 'auto',
}: SearchBarProps) => {
	const renderComponent = useCallback(
		(_className = '') => {
			return (
				<span
					className={clsx('p-input-icon-left', {
						'w-100': fullWidth,
					})}
					style={{
						...style,
					}}
				>
					{isLoading ? (
						<i
							className='fas fa-circle-notch fa-spin'
							style={{
								color: theme.primary,
							}}
						/>
					) : (
						<i
							className='fas fa-search'
							style={{
								color: theme.primary,
							}}
						/>
					)}
					<InputText
						type='text'
						value={value}
						onChange={e => onChange(e.target.value)}
						placeholder={placeholder}
						className={clsx(styles['search-bar-input'], className, {
							'h-100': fullHeight,
							'w-100': fullWidth,
						})}
						style={{
							height: fullHeight ? '100%' : height,
						}}
					/>
				</span>
			);
		},
		[
			value,
			onChange,
			placeholder,
			isLoading,
			className,
			fullHeight,
			fullWidth,
			height,
			style
		]
	);

	if (renderWithoutRow) return renderComponent(`flex-grow-1`);

	return renderComponent();
};

export default SearchBar;
