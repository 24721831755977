import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useAppDispatch } from '../../../store/hooks';
import { setModalTitle } from '../../../store/modal/actions';
import Button from '../../layout/Button';
import PillButton from '../../layout/PillButton';
import React from 'react';

export interface ButtonAction {
	label: string;
	icon?: string;
	onClick: () => void | Promise<void>;
	danger?: boolean;
	flashy?: boolean;
}

export interface ConfirmationModalProps {
	title?: string;
	description?: React.ReactNode | string;
	confirmAction?: ButtonAction;
	cancelAction?: ButtonAction;
}

const ConfirmationModal = ({
	title,
	description,
	confirmAction,
	cancelAction,
}: ConfirmationModalProps) => {
	const [isLoading, setIsLoading] = React.useState(false);
	const dispatch = useAppDispatch();

	const handleConfirmClick = async () => {
		setIsLoading(true);
		try {
			await confirmAction?.onClick();
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		if (title) {
			dispatch(setModalTitle(title.toUpperCase()));
		}
	}, [title, dispatch]);

	return (
		<>
			<Modal.Body>
				{typeof description === 'string' ? (
					<p className='mb-0 px-4'>{description}</p>
				) : (
					<div className='px-4'>{description}</div>
				)}
			</Modal.Body>
			<Modal.Footer>
				{cancelAction && (
					<Button
						theme='light'
						onClick={cancelAction.onClick}
						leftIcon={cancelAction.icon}
						label={cancelAction.label}
					/>
				)}
				{confirmAction &&
					(confirmAction.flashy ? (
						<PillButton
							theme='animated-gradient'
							onClick={handleConfirmClick}
							label={confirmAction.label}
							leftIcon={confirmAction.icon}
							className='ml-2'
							isLoading={isLoading}
						/>
					) : (
						<Button
							theme={confirmAction.danger ? 'danger' : 'dark'}
							onClick={handleConfirmClick}
							leftIcon={confirmAction.icon}
							label={confirmAction.label}
							className='ml-2'
							isLoading={isLoading}
						/>
					))}
			</Modal.Footer>
		</>
	);
};

export default ConfirmationModal;
