import { Navigate, Route, Routes } from 'react-router-dom';
import GuardedRoutes from './GuardedRoutes';
import AuthenticatedLayout from '../components/AuthenticatedLayout/AuthenticatedLayout';
import { useAppSelector } from '../store/hooks';
import { isAuthenticatedSelector } from '../store/auth/selectors';
import ROUTES from './routes';
import ViewCodes from '../components/screens/GenerateCodes/ViewCodes';
import Help from '../components/screens/Help';
import Login from '../components/screens/Login';
import Playlists from '../components/screens/Playlists';
import ProfileManager from '../components/screens/ProfileManager';
import ProjectFiles from '../components/screens/ProjectFiles';
import Projects from '../components/screens/Projects';
import SignUp from '../components/screens/SignUp';
import Editor from '../components/screens/Editor';
import GetStarted from '../components/screens/Editor/GetStarted/GetStarted';
import { withSentryReactRouterV6Routing } from '@sentry/react';

const SentryRoutes = withSentryReactRouterV6Routing(Routes);

const AppRouter = () => {
	const isAuthenticated = useAppSelector(isAuthenticatedSelector);
	const { isSigningUp } = useAppSelector(state => state.auth);

	return (
		<SentryRoutes>
			<Route
				path='/'
				element={
					<Navigate
						to={
							isAuthenticated
								? ROUTES.Playlists.path
								: isSigningUp
								? ROUTES.SignUp.path
								: ROUTES.Login.path
						}
						replace
					/>
				}
			/>

			<Route
				element={
					<GuardedRoutes
						isRouteAccessible={!isAuthenticated}
						redirectRoute='/'
					/>
				}
			>
				<Route path={ROUTES.Login.path} element={<Login />} />
				<Route path={ROUTES.SignUp.path} element={<SignUp />} />
			</Route>
			<Route
				element={
					<AuthenticatedLayout>
						<GuardedRoutes
							isRouteAccessible={isAuthenticated}
							redirectRoute={ROUTES.Login.path}
						/>
					</AuthenticatedLayout>
				}
			>
				<Route index path={ROUTES.Projects.path} element={<Projects />} />
				<Route path={`${ROUTES.Playlists.path}/*`} element={<Playlists />} />
				<Route path={ROUTES.RecordingFiles.path} element={<ProjectFiles />} />
				<Route path={ROUTES.AlbumFiles.path} element={<ProjectFiles />} />
				<Route path={`${ROUTES.Editor.path}`} element={<GetStarted />} />
				<Route path={`${ROUTES.EditRecording.path}/*`} element={<Editor />} />
				{/* <Route path='/edit/create'>
							<Page submenu={<EditorSubMenu />}>
								<CreateFromTemplate />
							</Page>
						</Route> */}
				{/* <Route path='/indie-upload' exact>
							<Page submenu={<IndieUploadSubMenu />}>
								<IndieUpload />
							</Page>
						</Route> */}
				{/* <Route path='/indie-upload/published-credits'>
							<Page submenu={<IndieUploadSubMenu />}>
								<PublishedCredits />
							</Page>
						</Route> */}
				<Route path={ROUTES.GeneratedCodes.path} element={<ViewCodes />} />
				<Route path={ROUTES.SavedProfiles.path} element={<ProfileManager />} />
				{/* <Route path={ROUTES.Settings.path}>
							<Page>
								<Settings />
							</Page>
						</Route> */}
				<Route path={ROUTES.Help.path} element={<Help />} />
			</Route>
			<Route path='*' element={<Navigate to='/' replace />} />
		</SentryRoutes>
	);
};

export default AppRouter;
