import { findKey, isEqual } from 'lodash';
import { createSelector } from 'reselect';
import { getAlbumRecordingIds } from '../../helpers/albumTools';
import { RootState } from '..';

const recordingsById = (state: RootState) => state.projects.recordingsById;
const albumsById = (state: RootState) => state.projects.albumsById;
const currentAlbumId = (state: RootState) => state.projects.currentAlbumId;
const currentRecordingId = (state: RootState) =>
	state.projects.currentRecordingId;
const userId = (state: RootState) => state.auth.userId;
const username = (state: RootState) => state.user.userProfile?.username;
const userEditableRecordingIds = (state: RootState) =>
	state.projects.userEditableRecordingIds;
const userEditableAlbumIds = (state: RootState) =>
	state.projects.userEditableAlbumIds;
const expandedAlbumIds = (state: RootState) => state.projects.expandedAlbumIds;

export const getCurrentRecording = createSelector(
	[recordingsById, currentRecordingId],
	(recordings, id) => (recordings && id ? recordings[id] : null)
);

export const getCurrentAlbum = createSelector(
	[albumsById, currentAlbumId],
	(albums, id) => (albums && id ? albums[id] : null)
);

export const getProjectById = createSelector(
	[
		recordingsById,
		albumsById,
		(
			_: RootState,
			{
				albumId,
				recordingId,
			}: {
				albumId?: number | null;
				recordingId?: number | null;
			}
		) => ({ albumId, recordingId }),
	],
	(recordings, albums, { albumId, recordingId }) => {
		if (recordingId) {
			if (!recordings) {
				throw new Error('Recordings not loaded');
			}
			return recordings[recordingId];
		} else if (albumId) {
			if (!albums) {
				throw new Error('Albums not loaded');
			}
			return albums[albumId];
		}
	}
);

export const getProjectsForTable = createSelector(
	[
		recordingsById,
		albumsById,
		userId,
		username,
		userEditableRecordingIds,
		userEditableAlbumIds,
		expandedAlbumIds,
		(
			_,
			{
				keepEditableOnly,
				searchedAlbumsById,
				searchedRecordingsById,
			}: {
				keepEditableOnly?: boolean;
				searchedRecordingsById?: Record<number, Recording> | null;
				searchedAlbumsById?: Record<number, Album> | null;
			} = {
				keepEditableOnly: false,
				searchedAlbumsById: null,
				searchedRecordingsById: null,
			}
		) => ({
			keepEditableOnly,
			searchedAlbumsById,
			searchedRecordingsById,
		}),
	],
	(
		recordings,
		albums,
		userId,
		username,
		userEditableRecordingIds,
		userEditableAlbumIds,
		expandedAlbumIds,
		{ keepEditableOnly, searchedAlbumsById, searchedRecordingsById }
	): ProjectRow[] | null => {
		if (
			!recordings ||
			!albums ||
			!userEditableAlbumIds ||
			!userEditableRecordingIds
		)
			return null;

		let _albums = searchedAlbumsById ?? albums;
		let _recordings = searchedRecordingsById ?? recordings;

		const albumRows = Object.values(_albums)
			.filter(album => !album.isSingle)
			.map(album => {
				// Create subRows for each recording in album
				const albumSubRows = getAlbumRecordingIds(album)
					.map((recordingId, index) => {
						const recording = recordings[recordingId];

						if (!recording) {
							console.error(
								'Missing recording in album',
								album.id,
								recordingId
							);
							return null;
						}

						return {
							id: recording.id,
							albumId: album.id,
							title: recording.title,
							artist: recording.artist,
							assetsCount: recording.assetsCount,
							// TODO: get archived field from API
							isArchived: recording.isArchived,
							createdAt: recording.createdAt,
							// TODO: get accurate owner field from API
							owner: {
								currentUserName: username,
								currentUserId: userId,
								projectUserId: recording.userId,
								projectUserName: recording.ownerName,
							},
							isEditable: !!userEditableRecordingIds?.includes(recordingId),
							trackNumber: findKey(album.recordings, id => id === recordingId),
						} as AlbumRecordingRow;
					})
					.filter((row): row is AlbumRecordingRow => !!row);

				// Create a row for the album containing the subRows
				return {
					id: album.id,
					title: album.title,
					artist: album.artist,
					assetsCount: album.assetsCount,
					// TODO: get archived field from API
					isArchived: (albumSubRows[0] && albumSubRows[0].isArchived) || false,
					// TODO: get accurate createdAt field from API
					createdAt: album.createdAt,
					isEditable: !!userEditableAlbumIds?.includes(album.id),
					// TODO: get accurate owner field from API
					owner: {
						currentUserName: username,
						currentUserId: userId,
						projectUserId: album.userId,
						projectUserName: album.ownerName,
					},
					// if albumSubRows array is empty, the row won't be expandable. And we need for it to be expandable,
					// thus we add the empty string to force this condition.
					subRows: [...albumSubRows, ''],
					isExpanded: expandedAlbumIds.includes(album.id),
				} as AlbumRow;
			});

		// After creating rows for all recordings in albums, create rows for all recordings that aren't in albums
		const recordingRows = Object.values(_recordings)
			.filter(
				recording => !recording.albumId || albums[recording.albumId]?.isSingle
			)
			.map(recording => {
				return {
					id: recording.id,
					albumId: recording.albumId, // for singles with releases, otherwise it'll be undefined
					title: recording.title,
					artist: recording.artist,
					isArchived: recording.isArchived,
					createdAt: recording.createdAt,
					isEditable: !!userEditableRecordingIds?.includes(recording.id),
					owner: {
						currentUserName: username,
						currentUserId: userId,
						projectUserId: recording.userId,
						projectUserName: recording.ownerName,
					},
					assetsCount: recording.assetsCount,
				} as RecordingRow;
			});

		// sort the rows by date (newest first)
		const rows = [...albumRows, ...recordingRows].sort((a, b) => {
			if (!a.createdAt) {
				return -1;
			}

			if (!b.createdAt) {
				return 1;
			}

			const aDate = new Date(a.createdAt);
			const bDate = new Date(b.createdAt);

			return bDate.getTime() - aDate.getTime();
		});

		if (keepEditableOnly) {
			return rows.filter(row => row.isEditable);
		}

		return rows;
	},
	{
		memoizeOptions: {
			resultEqualityCheck: isEqual,
			equalityCheck: isEqual,
			maxSize: 1,
		},
	}
);
