import React, { useCallback, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from '../../../layout/Button';
import FilesBrowser from '../FilesBrowser';
import ProjectUploadListItem from './ProjectUploadListItem';
import theme from '../../../../theme.module.scss';
import { UPLOAD_FILES_MODAL } from '../../../../constants/modalTypes';
import { showModalAction } from '../../../../store/modal/actions';
import { getUploadsByProjectId } from '../../../../store/files/selectors';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
	BrowserFileSelection,
	FileBrowserSortOptions,
} from '../FilesBrowser/FilesBrowser';
import { ProjectUploadListItemProps } from './ProjectUploadListItem/ProjectUploadListItem';
import { AppDispatch } from '../../../../store';

const filterFn = (searchFilter: string, file: ProjectFileUpload) =>
	file.metadata.filename
		.toLowerCase()
		.includes(searchFilter.trim().toLowerCase());

const sortingOptions: FileBrowserSortOptions<ProjectUploadListItemProps> = {
	filename: {
		name: 'File Name',
		compareFn: reverse => (a, b) =>
			a.props.file.metadata.filename.localeCompare(
				b.props.file.metadata.filename
			) * (reverse ? -1 : 1),
	},
	progress: {
		name: 'Progress',
		compareFn: reverse => (a, b) =>
			(a.props.file.progress - b.props.file.progress) * (reverse ? -1 : 1),
	},
	fileSize: {
		name: 'File Size',
		compareFn: reverse => (a, b) =>
			(a.props.file.metadata.fileSize - b.props.file.metadata.fileSize) *
			(reverse ? -1 : 1),
	},
};

const showUploadModal = (
	dispatch: AppDispatch,
	recordingId: Recording['id'] | null,
	albumId: Album['id'] | null
) => {
	dispatch(
		showModalAction(UPLOAD_FILES_MODAL, { size: 'lg', recordingId, albumId })
	);
};

export type ProjectUploadQueueProps = {
	recordingId: Recording['id'] | null;
	albumId: Album['id'] | null;
};

const ProjectUploadQueue = ({
	recordingId,
	albumId,
}: ProjectUploadQueueProps) => {
	const { recordingsById, albumsById } = useAppSelector(
		state => state.projects
	);
	const uploads = useAppSelector(state =>
		getUploadsByProjectId(state, { recordingId, albumId })
	);

	const pageTitle = useMemo(
		() =>
			`UPLOADS FOR ${
				recordingId
					? recordingsById![recordingId].title
					: albumId
					? albumsById![albumId].title
					: ''
			}`.toUpperCase(),
		[recordingId, albumId, recordingsById, albumsById]
	);

	const renderHeader = useCallback(
		({ selectedFiles }: { selectedFiles: BrowserFileSelection<string> }) => (
			<Header
				pageTitle={pageTitle}
				selectedFiles={selectedFiles}
				recordingId={recordingId}
				albumId={albumId}
			/>
		),
		[pageTitle, recordingId, albumId]
	);

	const renderNoFiles = useCallback(
		() => <NoFilesPlaceholder albumId={albumId} recordingId={recordingId} />,
		[albumId, recordingId]
	);

	return (
		<FilesBrowser<ProjectFileUpload, ProjectUploadListItemProps>
			id='uploads'
			header={renderHeader}
			files={uploads}
			sortingOptions={sortingOptions}
			fileCard={ProjectUploadListItem}
			noFilesPlaceholder={renderNoFiles}
			fileFilterFn={filterFn}
		/>
	);
};

type HeaderProps = {
	pageTitle: string;
	selectedFiles: BrowserFileSelection<string>;
	recordingId: Recording['id'] | null;
	albumId: Album['id'] | null;
};

const Header = ({
	pageTitle,
	selectedFiles,
	recordingId,
	albumId,
}: HeaderProps) => {
	const dispatch = useAppDispatch();

	return (
		<Row className='w-100'>
			<Col xs={12} md={8}>
				<h2 className='mb-2'>{pageTitle}</h2>
			</Col>
			<Col xs={12} md={4} className='d-flex justify-content-end p-0'>
				<Button
					label='Upload'
					theme='dark'
					className='ml-4'
					leftIcon='fas fa-cloud-upload-alt'
					onClick={() => showUploadModal(dispatch, recordingId, albumId)}
				/>
			</Col>
		</Row>
	);
};

const NoFilesPlaceholder = ({
	albumId,
	recordingId,
}: {
	albumId: Album['id'] | null;
	recordingId: Recording['id'] | null;
}) => {
	const dispatch = useAppDispatch();

	return (
		<Row className='w-100'>
			<Col xs={12} className='my-2'>
				<div
					className='d-flex flex-column justify-content-center align-items-center fw-600'
					style={{
						color: theme.darkGreyTextColor2,
						fontSize: '1rem',
						height: '40vh',
					}}
				>
					<i
						className='fas fa-file mb-4'
						style={{
							fontSize: '5rem',
						}}
					></i>
					<p>
						This project currently has no uploads.{' '}
						<span
							style={{
								color: theme.primary,
								cursor: 'pointer',
							}}
							onClick={() => showUploadModal(dispatch, recordingId, albumId)}
						>
							Upload
						</span>{' '}
						some files to get started.
					</p>
				</div>
			</Col>
		</Row>
	);
};

export default ProjectUploadQueue;
