import { v4 as uuidv4 } from 'uuid';
import DownloadStatus from '../constants/downloadStatus';
import { addToDownloadQueueAction } from '../store/files/actions';
import { AppDispatch } from '../store';
import { isElectron } from 'react-device-detect';
import { openNativeSaveDialog } from './fileTools';

export const createQueueDownload = ({
	fileId,
	versionId,
	filename,
	fileHandle,
	fileSize,
	nativeHandle = null,
}: {
	fileId: FileMetadata['id'];
	versionId: number;
	filename: string;
	fileHandle: FileSystemFileHandle | null;
	fileSize: number;
	nativeHandle?: WritableNativeFileHandle | null;
}): ProjectFileDownload => ({
	id: uuidv4(),
	metadata: {
		fileId,
		versionId,
		filename,
		fileSize,
	},
	fileHandle,
	nativeHandle,
	progress: 0,
	status: DownloadStatus.READY_TO_DOWNLOAD,
	abortController: null,
	createdAt: new Date().toISOString(),
	errorMessage: '',
});

// export const createQueueDownloadFromFolder = ({
// 	folder,
// }: {
// 	folder: any;
// }): ProjectFileDownload => ({
// 	id: uuidv4(),
// 	metadata: {
// 		fileId,
// 		versionId,
// 		filename,
// 		fileSize,
// 	},
// 	fileHandle,
// 	nativeHandle,
// 	progress: 0,
// 	status: DownloadStatus.READY_TO_DOWNLOAD,
// 	abortController: null,
// 	createdAt: new Date().toISOString(),
// 	errorMessage: '',
// });

export const downloadToFileSystem = async ({
	blob,
	suggestedName,
	fileHandle,
	nativeHandle,
	suggestedTimestamps,
}: {
	blob: Blob;
	suggestedName?: string;
	fileHandle?: FileSystemFileHandle | null;
	nativeHandle?: WritableNativeFileHandle | null;
	suggestedTimestamps?: FileTimestamp | null;
}) => {
	// Electron downloads
	if (nativeHandle) {
		const buffer = await blob.arrayBuffer();
		await nativeHandle.writeToFile(buffer);

		if (suggestedTimestamps) {
			console.log('SET TIMESTAMP');
			await nativeHandle.setTimestamps(suggestedTimestamps);
		}
		await nativeHandle.close();
		return;
	}

	// Modern browsers with File System Access API
	if (fileHandle) {
		const writable = await fileHandle.createWritable();
		await writable.write(blob);
		await writable.close();
		return;
	}

	// fallback method for older browsers
	if (!suggestedName) {
		throw new Error(
			'A suggestedName must be provided when not using the File System Access API'
		);
	}

	const blobURL = URL.createObjectURL(blob);
	// Create the `<a download>` element and append it invisibly.
	const a = document.createElement('a');
	a.href = blobURL;
	a.download = suggestedName;
	a.style.display = 'none';
	document.body.append(a);
	// Programmatically click the element.
	a.click();
	// Revoke the blob URL and remove the element.
	setTimeout(() => {
		URL.revokeObjectURL(blobURL);
		a.remove();
	}, 1000);

	return Promise.resolve();
};

export const selectDownloadPathAndDownload = async ({
	file,
	versionId = null,
	dispatch,
}: {
	file: FileMetadata;
	versionId?: number | null;
	dispatch: AppDispatch;
}) => {
	if (!('showSaveFilePicker' in window)) {
		console.warn('showSaveFilePicker not supported');

		dispatch(
			addToDownloadQueueAction({
				fileHandle: null,
				versionId: versionId ?? file.activeVersion,
				file,
			})
		);

		return;
	}

	// Using the new File System Access API https://web.dev/patterns/files/save-a-file/
	try {
		let nativeHandle: WritableNativeFileHandle | null = null;
		let fileHandle: FileSystemFileHandle | null = null;

		if (isElectron) {
			nativeHandle = await openNativeSaveDialog({
				defaultPath:
					file?.versions?.[versionId ?? file.activeVersion]?.filename,
			});
		} else {
			fileHandle = await window.showSaveFilePicker({
				suggestedName:
					file?.versions?.[versionId ?? file.activeVersion]?.filename,
			});
		}

		console.log('nativeHandle', nativeHandle);
		console.log('fileHandle', fileHandle);

		dispatch(
			addToDownloadQueueAction({
				file,
				fileHandle,
				nativeHandle,
				versionId: versionId ?? file.activeVersion,
			})
		);
	} catch (err: any) {
		// fail silently (both showSaveFilePicker and openNativeSaveDialog throw errors when the user cancels the dialog)
		console.error(err);
	}
};
