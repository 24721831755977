const deleteByValue = (obj, val) => {
	for (const key in obj) {
		if (obj[key] === val) {
			delete obj[key];
			return obj;
		}
	}
};

export default deleteByValue;
