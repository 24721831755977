import { metricsApi } from '../metricsApi';
import camelize from '../../helpers/camelize';

export const getPlaylistStat = async (id: number) => {
	return metricsApi
		.get(`/playlist/playlist-stats`, {
			params: {
				share_link_id: id,
			},
		})
		.then(res => {
			const data = camelize(res?.data);
			return { id, data };
		});
};

export const getDripStat = async (id: number) => {
	return metricsApi
		.get(`/playlist/drip-stats`, {
			params: {
				share_link_id: id,
			},
		})
		.then(res => {
			const data = camelize(res?.data);
			return { id, data };
		});
};

export const getDripStatsForAI = async (id: number) => {
	return metricsApi.get(`/playlist/drip-stats-ai`, {
		params: {
			share_link_id: id,
		},
	});
};
