import { useCallback, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { getExportsByProjectId } from '../../../../store/exports/selectors';
import FilesBrowser from '../FilesBrowser';
import ProjectExportsListItem, { ProjectExportsListItemProps } from './ProjectExportsListItem/ProjectExportsListItem';
import theme from '../../../../theme.module.scss';
import { useAppSelector } from '../../../../store/hooks';
import { FileBrowserSortOptions } from '../FilesBrowser/FilesBrowser';

const sortingOptions: FileBrowserSortOptions<ProjectExportsListItemProps> = {
	newest: {
		name: 'Newest',
		compareFn: reverse => (a, b) =>
			(new Date(b.props.file.createdAt).getTime() -
				new Date(a.props.file.createdAt).getTime()) *
			(reverse ? -1 : 1),
	},
	oldest: {
		name: 'Oldest',
		compareFn: reverse => (a, b) =>
			new Date(a.props.file.createdAt).getTime() -
			new Date(b.props.file.createdAt).getTime() * (reverse ? -1 : 1),
	},
	filename: {
		name: 'File Name',
		compareFn: reverse => (a, b) =>
			a.props.file.filename.localeCompare(b.props.file.filename) *
			(reverse ? -1 : 1),
	},
	// fileSize: {
	// 	name: 'File Size',
	// 	compareFn: reverse => (a, b) =>
	// 		(a.props.file.size - b.props.file.size) * (reverse ? -1 : 1),
	// },
};

export type ProjectExportsBrowserProps = {
	recordingId?: Recording['id'];
	albumId?: Album['id'];
};

const ProjectExportsBrowser = ({
	recordingId,
	albumId,
}: ProjectExportsBrowserProps) => {
	const exports = useAppSelector(state =>
		getExportsByProjectId(state, { albumId, recordingId })
	);

	const { recordingsById, albumsById } = useAppSelector(
		state => state.projects
	);

	const pageTitle = useMemo(
		() =>
			`EXPORTS FOR ${
				recordingId
					? recordingsById![recordingId].title
					: albumId
					? albumsById![albumId].title
					: ''
			}`.toUpperCase(),
		[recordingId, albumId, recordingsById, albumsById]
	);

	const renderHeader = useCallback(
		() => <Header pageTitle={pageTitle} />,
		[pageTitle]
	);

	return (
		<FilesBrowser
			id='exports'
			header={renderHeader}
			files={exports}
			sortingOptions={sortingOptions}
			fileCard={ProjectExportsListItem}
			noFilesPlaceholder={NoExportsPlaceholder}
			loadingMessage='Loading Exports...'
		/>
	);
};

const Header = ({ pageTitle }: { pageTitle: string }) => {
	return (
		<Row className='w-100'>
			<Col xs={12} md={8}>
				<h2 className='mb-2'>{pageTitle}</h2>
			</Col>
		</Row>
	);
};

const NoExportsPlaceholder = () => {
	return (
		<Row className='w-100'>
			<Col xs={12} className='my-2'>
				<div
					className='d-flex flex-column justify-content-center align-items-center fw-600'
					style={{
						color: theme.darkGreyTextColor2,
						fontSize: '1rem',
						height: '40vh',
					}}
				>
					<i
						className='fas fa-file mb-4'
						style={{
							fontSize: '5rem',
						}}
					></i>
					<p>This project has no exports.</p>
				</div>
			</Col>
		</Row>
	);
};

export default ProjectExportsBrowser;
