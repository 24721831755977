import React, { useEffect, useMemo, useState } from 'react';
import { Card, Container, Modal } from 'react-bootstrap';
import { hideModal, setModalTitle } from '../../../../../store/modal/actions';
import { fetchLocalProfileAction } from '../../../../../store/profiles/actions';
import Button from '../../../../layout/Button';
import SearchTable from '../../../../layout/SearchTable';
import SoundCreditLoader from '../../../SoundCreditLoader';
import { FormikProps } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';

export type SelectRepresentativeProfileModalProps = {
	formik: FormikProps<any>; // TODO: TS MIGRATION: replace any with real form type
};

const SelectRepresentativeProfileModal = ({
	formik,
}: SelectRepresentativeProfileModalProps) => {
	const dispatch = useAppDispatch();
	const { profiles } = useAppSelector(state => state.profiles);
	const [isLoading, setIsLoading] = useState(false);
	const [selectedProfileId, setSelectedProfileId] = useState<string | null>(
		null
	);

	const isProfileLoaded = useMemo(() => {
		return selectedProfileId
			? profiles?.find(profile => profile.id === selectedProfileId)?.profile
			: false;
	}, [selectedProfileId, profiles]);

	const handleClose = () => {
		dispatch(hideModal());
	};

	const importProfile = () => {
		if (!selectedProfileId) return;
		setIsLoading(true);

		const selectedProfile = profiles?.find(
			profile => profile.id === selectedProfileId
		);

		if (!selectedProfile?.profile) {
			Promise.resolve(dispatch(fetchLocalProfileAction(selectedProfileId)))
				.then(() => {
					const fullProfile = profiles?.find(
						profile => profile.id === selectedProfileId
					);

					console.log('FULL PROFILE', fullProfile);
				})
				.catch(handleClose);
		}
	};

	const selectProfileColumns = useMemo(
		() => [
			{
				Header: () => <span className='Project-Name'>Name</span>,
				accessor: 'creditedName',
				Cell: (col: any) => <span className='Name1'>{col.value}</span>, // TODO: TS MIGRATION: replace any with real type
			},
		],
		[]
	);

	const extractCellInfo = (cellValues: any) => {
		// TODO: TS MIGRATION: replace any with real type
		if (!cellValues || (cellValues && !cellValues.length)) return;

		console.log(cellValues);

		setSelectedProfileId(cellValues[0].original.id);
	};

	useEffect(() => {
		if (isProfileLoaded && isLoading) {
			// debugger;
			const selectedProfile = profiles?.find(
				profile => profile.id === selectedProfileId
			);

			if (!selectedProfile) {
				throw new Error('Selected profile not found');
			}

			if (!selectedProfile.profile) {
				throw new Error('Selected profile has no profile data');
			}

			formik.setFieldValue(
				'recordCompany',
				selectedProfile.profile.otherAliases || ''
			);
			formik.setFieldValue(
				'recordCompanyAddress',
				`${selectedProfile.profile.address1 || ''} ${
					selectedProfile.profile.address2 || ''
				} `
			);
			formik.setFieldValue(
				'recordCompanyRepName',
				selectedProfile.profile.creditedName
			);
			formik.setFieldValue(
				'recordCompanyRepPhone',
				selectedProfile.profile.phone || ''
			);

			console.log(selectedProfile);
			dispatch(hideModal());
		}
	}, [
		isProfileLoaded,
		selectedProfileId,
		profiles,
		formik,
		dispatch,
		isLoading,
	]);

	useEffect(() => {
		dispatch(setModalTitle('SELECT REPRESENTATIVE PROFILE'));
	}, [dispatch]);

	return (
		<>
			<Modal.Body>
				{/* check if profiles have loaded */}
				{!profiles ? (
					<div style={{ height: '40vh' }}>
						<SoundCreditLoader />
					</div>
				) : (
					<Container>
						<Card>
							<Card.Body>
								<SearchTable
									data={profiles}
									onSelect={extractCellInfo}
									singleSelect
									// customRows
									columns={selectProfileColumns}
									searchBarPlaceholder='Search by Profile Name'
								/>
							</Card.Body>
						</Card>
					</Container>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button label='Cancel' onClick={handleClose} />
				<Button
					label='Select Profile'
					onClick={importProfile}
					theme='dark'
					isLoading={isLoading}
					className='ml-2'
				/>
			</Modal.Footer>
		</>
	);
};

export default SelectRepresentativeProfileModal;
