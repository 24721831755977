import './ExportSelectionModal.scss';
import { Col, Container, Modal, Row } from 'react-bootstrap';
import ExportButton from '../../../layout/ExportButton';
import { hideModal, showModalAction } from '../../../../store/modal/actions';
import ExportType from '../../../../constants/exportType';
import ExportId from '../../../../constants/exportsIds';
import {
	setExportTypeAction,
	initUnionFormExportStepAction,
	exportToRinAction,
	setB4TypeAction,
	setExportParamsAction,
	clearAllCurrentExportDataAction,
} from '../../../../store/exports/actions';
import {
	getCurrentAlbum,
	getCurrentRecording,
} from '../../../../store/projects/selectors';
import { useNavigate } from 'react-router-dom';
import {
	EXPORT_IMAGE_INPUT_MODAL,
	SELECT_RECORDING_MODAL,
} from '../../../../constants/modalTypes';
import { fetchMultipleCloudProjectsAction } from '../../../../store/projects/actions';
import ExportWarningModal from './ExportWarningModal';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	enterpriseExportTypes,
	proExportTypes,
	regularExportTypes,
} from '../../../../constants/extendedExportTypes';
import SelectB4TypeModal from '../UnionFormExport/SelectB4TypeModal';
import {
	getAlbumRecordingIds,
	getTrackNumberById,
} from '../../../../helpers/albumTools';
import ROUTES from '../../../../router/routes';
import { replacePathVariables } from '../../../../helpers/routeTools';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { UnionFormStep } from '../../../../constants/unionFormExportSteps';
import { ExportPayloadSetters } from './setExportPayloadTools';

const ExportSelectionModal = () => {
	const [showWarningModal, setShowWarningModal] = useState(false);
	const [showSelectB4TypeModal, setShowSelectB4TypeModal] = useState(false);
	const [warningModalProps, setWarningModalProps] = useState({
		titleText: '',
		bodyText: '',
		cancelText: '',
		confirmText: '',
		onCancel: () => {},
		onConfirm: () => {},
	});

	const dispatch = useAppDispatch();
	const { currentRecordingId, currentAlbumId } = useAppSelector(
		state => state.projects
	);
	const { savedExportFormsById } = useAppSelector(state => state.session);
	const { userPermissions } = useAppSelector(state => state.auth);
	const currentAlbum = useAppSelector(getCurrentAlbum);
	const currentRecording = useAppSelector(getCurrentRecording);
	const [loadingExportType, setLoadingExportType] = useState<ExportType | null>(
		null
	);
	const navigate = useNavigate();

	const handleUnionFormExport = useCallback(
		(exportType: ExportType) => {
			dispatch(hideModal());
			dispatch(setExportTypeAction(exportType));

			const firstStep = currentAlbumId
				? UnionFormStep.LOAD_RECORDINGS
				: UnionFormStep.SELECT_STUDIO;

			dispatch(initUnionFormExportStepAction(firstStep));

			if (!currentAlbumId) {
				ExportPayloadSetters.setUnionFormExportPayload({
					dispatch,
					exportType: exportType as
						| ExportType.AFM_B9
						| ExportType.SAG_AFTRA
						| ExportType.AFM_B4,
					recordings: [currentRecording!],
					album: null,
					currentRecordingId: currentRecordingId!,
				});

				dispatch(hideModal());
			}

			navigate(
				replacePathVariables(ROUTES.UnionFormExport.path, {
					recordingId: currentRecordingId,
				})
			);
		},
		[dispatch, currentAlbumId, currentRecordingId, currentRecording, navigate]
	);

	const handleExport: HandleExportFunction = useCallback(
		(exportData, { b4Type, producerAgreementShare } = {}) => {
			dispatch(clearAllCurrentExportDataAction());

			const exportValidationPath = replacePathVariables(
				ROUTES.ExportValidation.path,
				{
					recordingId: currentRecordingId,
				}
			);

			const exportType = exportData.type;
			setLoadingExportType(exportType);

			switch (exportType) {
				case ExportType.LABEL_STYLE_SINGLE:
				case ExportType.SOUND_CREDIT_SINGLE:
				case ExportType.LYRIC_SHEET:
				case ExportType.LABEL_STYLE_PERSONNEL_SPREADSHEET:
				case ExportType.BIG_MACHINE_SINGLE:
					// use special export payload function for these exports
					ExportPayloadSetters.setSingleExportPayload({
						dispatch,
						exportType,
						recordingId: currentRecordingId!,
						album: currentAlbum,
						recording: currentRecording!,
					});

					dispatch(hideModal());
					navigate(exportValidationPath);
					break;
				case ExportType.SPLIT_SHEET_STANDARD:
				case ExportType.SPLIT_SHEET_SINGLE_WRITER:
				case ExportType.PRODUCER_AGREEMENT:
				case ExportType.SIDE_ARTIST_AGREEMENT:
					ExportPayloadSetters.setSplitSheetExportPayload({
						dispatch,
						exportType,
						album: currentAlbum,
						recording: currentRecording!,
						producerAgreementShare,
						skipGenerate: Boolean(exportData.skipGenerate),
						routeAfterValidation: exportData.nextRoute!,
						createOnValidation: Boolean(exportData.createOnValidation),
						hasPreview: Boolean(exportData.hasPreview),
						nextParams: exportData.getNextExportParams
							? exportData.getNextExportParams({
									navigate,
									dispatch,
									recordingId: currentRecordingId,
							  })
							: null,
					});

					navigate(exportValidationPath);
					dispatch(hideModal());

					break;

				case ExportType.PUBLISHING_LABEL_COPY_DOCUMENT:
					dispatch(
						fetchMultipleCloudProjectsAction(
							currentAlbumId
								? getAlbumRecordingIds(currentAlbum!)
								: [currentRecordingId!],
							recordings => {
								// preserve original track order, as the selection or fetch order might be different
								if (currentAlbumId) {
									recordings = getAlbumRecordingIds(currentAlbum!)
										.map(recordingId =>
											recordings.find(recording => recording.id === recordingId)
										)
										.filter((recording): recording is Recording => !!recording);
								}

								ExportPayloadSetters.setPublishingLabelCopyExportPayload({
									exportType,
									recordings,
									currentRecordingId: currentRecordingId!,
									album: currentAlbum,
									dispatch,
									routeAfterValidation: exportData.nextRoute!,
									createOnValidation: Boolean(exportData.createOnValidation),
									hasPreview: Boolean(exportData.hasPreview),
								});

								dispatch(hideModal());

								navigate(exportValidationPath);
							}
						)
					);
					break;

				case ExportType.SOUND_CREDIT_RELEASE:
				case ExportType.LABEL_STYLE_RELEASE_REGULAR:
				case ExportType.LABEL_STYLE_RELEASE_LONG:
				case ExportType.BIG_MACHINE_ALBUM:
				case ExportType.CD_INSERT_LONG:
				case ExportType.CD_INSERT_SHORT:
				case ExportType.VINYL_SLEEVE_LONG:
				case ExportType.VINYL_SLEEVE_SHORT:
				case ExportType.DIGITAL_DISTRIBUTION:
					dispatch(
						fetchMultipleCloudProjectsAction(
							currentAlbumId
								? getAlbumRecordingIds(currentAlbum!)
								: [currentRecordingId!],
							recordings => {
								// preserve original track order, as the selection or fetch order might be different
								if (currentAlbumId) {
									recordings = getAlbumRecordingIds(currentAlbum!)
										.map(recordingId =>
											recordings.find(recording => recording.id === recordingId)
										)
										.filter((recording): recording is Recording => !!recording);
								}

								ExportPayloadSetters.setReleaseExportPayload({
									dispatch,
									exportType,
									recordings,
									currentRecordingId: currentRecordingId!,
									album: currentAlbum,
								});

								navigate(exportValidationPath);
								dispatch(hideModal());
							}
						)
					);

					break;

				case ExportType.THE_ORCHARD_SPREADSHEET:
				case ExportType.ALL_MUSIC_SPREADSHEET:
				case ExportType.METADATA_MASTERING:
				case ExportType.PUBLISHING_LABEL_COPY_SPREADSHEET:
					dispatch(
						fetchMultipleCloudProjectsAction(
							currentAlbumId
								? getAlbumRecordingIds(currentAlbum!)
								: [currentRecordingId!],
							recordings => {
								// preserve original track order, as the selection or fetch order might be different
								if (currentAlbumId) {
									recordings = getAlbumRecordingIds(currentAlbum!)
										.map(recordingId =>
											recordings.find(recording => recording.id === recordingId)
										)
										.filter((recording): recording is Recording => !!recording);
								}

								ExportPayloadSetters.setSpreadSheetExportPayload({
									dispatch,
									recordings,
									currentRecordingId: currentRecordingId!,
									album: currentAlbum,
									exportType,
								});

								navigate(exportValidationPath);
								dispatch(hideModal());
							}
						)
					);

					break;
				case ExportType.SOUND_EXCHANGE_SPREADSHEET:
				case ExportType.MLC_SPREADSHEET:
				case ExportType.PPL_REGISTRATION:
				case ExportType.EBR_STANDARD_TEMPLATE:
					dispatch(hideModal());

					dispatch(
						showModalAction(SELECT_RECORDING_MODAL, {
							onSubmit: async (selectedRecordingIds: number[]) => {
								await dispatch(
									fetchMultipleCloudProjectsAction(
										selectedRecordingIds,
										recordings => {
											// preserve original track order, as the selection or fetch order might be different
											if (currentAlbumId) {
												recordings = getAlbumRecordingIds(currentAlbum!)
													.filter(recordingId =>
														selectedRecordingIds.includes(recordingId)
													)
													.map(recordingId =>
														recordings.find(
															recording => recording.id === recordingId
														)
													)
													.filter(
														(recording): recording is Recording => !!recording
													);
											}

											ExportPayloadSetters.setSelectRecordingsExportPayload({
												dispatch,
												recordings,
												currentRecordingId: currentRecordingId!,
												album: currentAlbum,
												exportType,
											});

											dispatch(hideModal());
											navigate(exportValidationPath);
										}
									)
								);
							},
							title: 'SELECT RECORDINGS TO EXPORT',
							recordingFilter: currentAlbumId
								? (recording: Recording) => recording.albumId === currentAlbumId
								: (recording: Recording) => recording.id === currentRecordingId,
							recordingSortFn:
								currentAlbumId && currentAlbum
									? (recordingA: Recording, recordingB: Recording) =>
											getTrackNumberById(
												currentAlbum.recordings,
												recordingA.id
											) -
											getTrackNumberById(currentAlbum.recordings, recordingB.id)
									: undefined,
						})
					);
					break;
				case ExportType.FACEBOOK_TWITTER_IMAGE:
				case ExportType.STORIES:
				case ExportType.INSTAGRAM_IMAGE:
					dispatch(hideModal());

					dispatch(
						showModalAction(EXPORT_IMAGE_INPUT_MODAL, {
							inputLabel: 'Select an image for the album artwork',
							onSubmit: (croppedImage: string, mimeType: string) => {
								ExportPayloadSetters.setSocialExportPayload({
									dispatch,
									exportType,
									recording: currentRecording!,
									album: currentAlbum,
									croppedImage,
									mimeType,
								});

								dispatch(hideModal());

								navigate(exportValidationPath);
							},
							size: 'lg',
						})
					);

					break;
				case ExportType.AFM_B4:
					if (!b4Type) {
						throw new Error('B4 type not set');
					}

					dispatch(setB4TypeAction(b4Type));
					handleUnionFormExport(exportType);

					break;
				case ExportType.AFM_B9:
				case ExportType.SAG_AFTRA:
					handleUnionFormExport(exportType);

					break;
				case ExportType.RIN_EXPORT:
					dispatch(hideModal());

					if (!currentRecording?.recording) {
						throw new Error('Current recording not set');
					}

					dispatch(
						setExportParamsAction({
							overrideCreate: (
								getOnCreate: (
									recordingId: number,
									albumId: number | null
								) => () => void
							) => {
								dispatch(
									exportToRinAction(
										currentRecording.recording!,
										getOnCreate(currentRecordingId!, null)
									)
								);
							},
							skipValidation: true,
						})
					);

					navigate(exportValidationPath);

					break;
				default:
					console.log('EXPORT DEFAULT', exportData);
					alert('EXPORT TYPE NOT YET IMPLEMENTED');
					return;
			}
		},
		[
			currentAlbum,
			currentAlbumId,
			currentRecording,
			currentRecordingId,
			dispatch,
			navigate,
			handleUnionFormExport,
		]
	);

	const handleExportButtonClick = useCallback(
		(exportType: ExportData) => {
			if (exportType.getWarningModalProps) {
				setWarningModalProps(
					exportType.getWarningModalProps({
						setShowWarningModal,
						handleExport,
						exportType,
						dispatch,
					})
				);
				setShowWarningModal(true);
			} else if (exportType.type === ExportType.AFM_B4) {
				setShowSelectB4TypeModal(true);
			} else {
				handleExport(exportType);
			}
		},
		[handleExport, dispatch]
	);

	const isSingle = useMemo(() => {
		return Boolean(
			currentRecording &&
				(!currentRecording.albumId ||
					(currentAlbum &&
						currentAlbum.recordings &&
						getAlbumRecordingIds(currentAlbum).length === 1))
		);
	}, [currentRecording, currentAlbum]);

	const disableExport = useCallback(
		(exportType: ExportType) => {
			switch (exportType) {
				case ExportType.INDIE_UPLOAD:
				case ExportType.SIDE_ARTIST_AGREEMENT:
					return true;
				case ExportType.SOUND_CREDIT_RELEASE:
				case ExportType.LABEL_STYLE_RELEASE_LONG:
				case ExportType.LABEL_STYLE_RELEASE_REGULAR:
				case ExportType.BIG_MACHINE_ALBUM:
					return isSingle;
				default:
					return false;
			}
		},
		[isSingle]
	);

	const exportTypesInProgressById = useMemo(() => {
		const exportTypesById: Partial<Record<ExportId, boolean>> = {};

		// TODO: TS MIGRATION - assign correct type to savedExportFormsById
		Object.values(savedExportFormsById).forEach((form: any) => {
			if (
				form?.recordingId === currentRecordingId ||
				(currentAlbumId && form.albumId === currentAlbumId)
			) {
				exportTypesById[ExportId[form.exportType as keyof typeof ExportId]] =
					true;
			}
		});

		return exportTypesById;
	}, [savedExportFormsById, currentRecordingId, currentAlbumId]);

	useEffect(() => {
		console.log('IN PROGRESS', exportTypesInProgressById);
	}, [exportTypesInProgressById]);

	const addAdditionalExportFields = useCallback(
		(exportData: ExportData) => ({
			...exportData,
			onClick: () => handleExportButtonClick(exportData),
			isDisabled: disableExport(exportData.type),
			inProgress: exportTypesInProgressById[exportData.id],
			isLocked:
				exportData.tier > userPermissions!.exportTier &&
				!(userPermissions!.extraExports ?? []).includes(exportData.id),
		}),
		[
			disableExport,
			exportTypesInProgressById,
			handleExportButtonClick,
			userPermissions,
		]
	);

	const { _enterpriseExportTypes, _proExportTypes, _regularExportTypes } =
		useMemo(() => {
			return {
				_enterpriseExportTypes: Object.values(enterpriseExportTypes).map(
					addAdditionalExportFields
				),
				_proExportTypes: Object.values(proExportTypes).map(
					addAdditionalExportFields
				),
				_regularExportTypes: Object.values(regularExportTypes).map(
					addAdditionalExportFields
				),
			};
		}, [addAdditionalExportFields]);

	// <Modal show={show} onHide={onClose} dialogClassName='export-modal'>
	return (
		<>
			<Modal.Body className='px-5'>
				<h2>ENTERPRISE TIER</h2>
				<div className='export-buttons'>
					<Container fluid>
						<Row>
							{_enterpriseExportTypes.map((exportType, index) => (
								<Col key={exportType.label} xs={6} className='export-col'>
									<ExportButton
										className='mb-3'
										label={exportType.label}
										sublabel={exportType.sublabel}
										icon={exportType.icon}
										onClick={exportType.onClick}
										isDisabled={exportType.isDisabled}
										isLocked={exportType.isLocked}
										inProgress={exportType.inProgress}
										isLoading={loadingExportType === exportType.type}
									/>
								</Col>
							))}
						</Row>
					</Container>
				</div>
				<div className='my-3' />
				<h2>PRO TIER</h2>
				<div className='export-buttons'>
					<Container fluid>
						<Row>
							{_proExportTypes.map((exportType, index) => (
								<Col key={exportType.label} xs={6} className='export-col'>
									<ExportButton
										className='mb-3'
										label={exportType.label}
										sublabel={exportType.sublabel}
										icon={exportType.icon}
										onClick={exportType.onClick}
										isDisabled={exportType.isDisabled}
										isLocked={exportType.isLocked}
										inProgress={exportType.inProgress}
										isLoading={loadingExportType === exportType.type}
									/>
								</Col>
							))}
						</Row>
					</Container>
				</div>
				<div className='my-3' />
				<h2>FREE TIER</h2>
				<div className='export-buttons'>
					<Container fluid>
						<Row>
							{_regularExportTypes.map(exportType => (
								<Col key={exportType.label} xs={6} className='export-col'>
									<ExportButton
										className='mb-3'
										label={exportType.label}
										sublabel={exportType.sublabel}
										icon={exportType.icon}
										onClick={exportType.onClick}
										isDisabled={exportType.isDisabled}
										isLocked={exportType.isLocked}
										inProgress={exportType.inProgress}
										isLoading={loadingExportType === exportType.type}
									/>
								</Col>
							))}
						</Row>
					</Container>
				</div>
			</Modal.Body>
			<ExportWarningModal
				show={showWarningModal}
				onCancel={warningModalProps.onCancel}
				onConfirm={warningModalProps.onConfirm}
				cancelText={warningModalProps.cancelText}
				confirmText={warningModalProps.confirmText}
				titleText={warningModalProps.titleText}
				bodyText={warningModalProps.bodyText}
			/>
			<SelectB4TypeModal
				show={showSelectB4TypeModal}
				onHide={() => setShowSelectB4TypeModal(false)}
				onSelect={(b4Type: B4Type) => {
					setShowSelectB4TypeModal(false);
					handleExport(enterpriseExportTypes[ExportType.AFM_B4], { b4Type });
				}}
			/>
		</>
	);
};

export default ExportSelectionModal;
