import { UnionFormStep } from '../../../../constants/unionFormExportSteps';
import LoadRecordingsStep from './LoadRecordingsStep';
import SelectStudioStep from './SelectStudioStep';
import ExportStepper from './ExportStepper';
import ExportType from '../../../../constants/exportType';
import FinalizeStepB4 from './FinalizeStepB4';
import FinalizeStepB9 from './FinalizeStepB9';
import FinalizeStepSAGAFTRA from './FinalizeStepSAGAFTRA';
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import getExportSteps from '../../../../constants/unionFormExportSteps';
import SelectParticipantsStep from './SelectParticipantsStep/SelectParticipantsStep';
import { createAfmForm } from '../../../../helpers/afmFormTools';
import { createSagAftraForm } from '../../../../helpers/sagAftraFormTools';
import { useNavigate } from 'react-router-dom';
import {
	clearAllCurrentExportDataAction,
	loadUnionFormAction,
	setCloudLatestContractNumberAction,
	setExportParamsAction,
	setSagFormAction,
	setUnionFormAction,
} from '../../../../store/exports/actions';
import CompleteParticipantsStepAFM from './CompleteParticipantsStepAFM';
import CompleteParticipantsStepSAGAFTRA from './CompleteParticipantsStepSAGAFTRA';
import {
	clearActiveExportFormAction,
	deleteExportFormAction,
	saveExportFormAction,
	setActiveExportFormAction,
} from '../../../../store/session/actions';
import useTaskBeforeUnmount from '../../../../hooks/useTaskBeforeUnmount';
import { fetchMultipleCloudProjectsAction } from '../../../../store/projects/actions';
import SoundCreditLoader from '../../SoundCreditLoader';
import selectStudioForm from '../../../../constants/unionForm/selectStudioForm.json';
import { hideModal, showModalAction } from '../../../../store/modal/actions';
import { EXIT_UNION_FORM_MODAL } from '../../../../constants/modalTypes';
import './UnionFormExport.scss';
import Button from '../../../layout/Button';
import { getCurrentAlbum } from '../../../../store/projects/selectors';
import _ from 'lodash';
import ROUTES from '../../../../router/routes';
import { formatDateToApi } from '../../../utils/dateFormatters';
import { replacePathVariables } from '../../../../helpers/routeTools';
import { AppDispatch } from '../../../../store';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { Helmet } from 'react-helmet';
import { v4 as uuidv4 } from 'uuid';
import { ExportPayloadSetters } from '../ExportSelectionModal/setExportPayloadTools';

const UnionFormExport = () => {
	const { exportType } = useAppSelector(state => state.exports);
	const { b4Type } = useAppSelector(state => state.exports.unionForm);
	const { activeExportFormId } = useAppSelector(state => state.session);
	return (
		<UnionFormExportContent
			key={(exportType ?? '') + (b4Type?.id ?? '') + (activeExportFormId ?? '')}
		/>
	);
};

const UnionFormExportContent = () => {
	/*
	 * Redux Hooks
	 */
	const { activeUnionFormStep, exportType } = useAppSelector(
		state => state.exports
	);
	const { savedExportFormsById, activeExportFormId } = useAppSelector(
		state => state.session
	);

	const { currentAlbumId, currentRecordingId, recordingsById, albumsById } =
		useAppSelector(state => state.projects);
	const currentAlbum = useAppSelector(getCurrentAlbum);
	const { unionForm } = useAppSelector(state => state.exports);

	const dispatch = useAppDispatch();

	/*
	 * React Hooks
	 */
	const savedUnionFormId = useRef<string | null>(null);
	const isFindingSavedForm = useRef(true);
	const shouldClearActiveExportForm = useRef(true);

	const [isLoading, setIsLoading] = useState(true);

	const {
		id: unionFormId,
		selectedRecordingRows,
		studioForm,
		selectedParticipants,
		completeParticipantsForm,
		finalizeForm,
		b4Type,
	} = useMemo(() => {
		if (unionForm.id) {
			return {
				...unionForm,
			};
		}

		return {
			id: null,
			selectedRecordingRows: [],
			studioForm: {},
			selectedParticipants: [],
			completeParticipantsForm: {},
			finalizeForm: {},
			b4Type: unionForm.b4Type,
		} as Partial<UnionFormData>;
	}, [unionForm]);

	const steps = useMemo(
		() =>
			currentAlbumId ? getExportSteps(b4Type) : getExportSteps(b4Type).slice(1),
		[currentAlbumId, b4Type]
	);

	const activeStep = useMemo(
		() => steps.findIndex(step => step.id === activeUnionFormStep),
		[activeUnionFormStep, steps]
	);

	const selectedRecordingIds = useMemo(
		() => selectedRecordingRows?.map(row => row.recordingId) ?? [], // TODO: TS MIGRATION: fix type
		[selectedRecordingRows]
	);

	/*
	 * Other Hooks
	 */
	const navigate = useNavigate();

	/*
	 * Callbacks
	 */
	const saveUnionForm = useCallback(
		(
			dispatch: AppDispatch,
			exportType: ExportType,
			savedUnionFormId: React.MutableRefObject<string | null>,
			activeExportFormId: string | null,
			currentRecordingId: number,
			currentAlbumId: number | null,
			selectedRecordingRows: UnionFormRecordingRow[],
			studioForm: Partial<UnionFormStudioForm>,
			selectedParticipants: Participant[],
			completeParticipantsForm: Partial<UnionFormCompleteParticipantsForm>,
			finalizeForm: Partial<UnionFormFinalizeForm>,
			activeUnionFormStep: Partial<UnionFormStep>,
			b4Type: B4Type
		) => {
			let formId = savedUnionFormId.current;
			let form: UnionFormData = {
				id: formId,
				selectedRecordingRows,
				studioForm,
				selectedParticipants,
				completeParticipantsForm,
				finalizeForm,
				activeUnionFormStep,
				b4Type,
			};
			let isUpdating = true;

			if (!formId && !activeExportFormId) {
				console.log('CREATING NEW FORM');
				formId = uuidv4();
				savedUnionFormId.current = formId;

				// Create a new clean form
				form = {
					id: formId,
					selectedRecordingRows: currentAlbumId
						? []
						: [{ rowId: '0', recordingId: currentRecordingId }],
					selectedParticipants: [],
					studioForm: selectStudioForm,
					completeParticipantsForm: {},
					finalizeForm: {},
					activeUnionFormStep: currentAlbumId
						? UnionFormStep.LOAD_RECORDINGS
						: UnionFormStep.SELECT_STUDIO,
					b4Type,
				};

				isUpdating = false;
			}

			console.log('SAVING UNION FORM', form);

			dispatch(
				saveExportFormAction(
					exportType,
					form,
					formId!,
					currentAlbumId ? null : currentRecordingId,
					currentAlbumId,
					isUpdating
				)
			);

			if (!isUpdating) {
				dispatch(setActiveExportFormAction(formId!));
			}
		},
		[]
	);

	const saveAndCloseForm = useCallback(
		(
			dispatch: AppDispatch,
			exportType: ExportType,
			savedUnionFormId: React.MutableRefObject<string>,
			activeExportFormId: string,
			currentRecordingId: number,
			currentAlbumId: number | null,
			selectedRecordingRows: UnionFormRecordingRow[],
			studioForm: UnionFormStudioForm,
			selectedParticipants: Participant[],
			completeParticipantsForm: UnionFormCompleteParticipantsForm,
			finalizeForm: UnionFormFinalizeForm,
			activeUnionFormStep: UnionFormStep,
			b4Type: B4Type
		) => {
			console.log('UNION FORM: UNMOUNT', b4Type);
			if (shouldClearActiveExportForm.current) {
				dispatch(clearActiveExportFormAction());
			}

			// if unionFormId is null it means it was not loaded yet,
			// thus the function must return to avoid race conditions
			if (
				isFindingSavedForm.current ||
				(!unionFormId && savedUnionFormId.current)
			)
				return;

			saveUnionForm(
				dispatch,
				exportType,
				savedUnionFormId,
				activeExportFormId,
				currentRecordingId,
				currentAlbumId,
				selectedRecordingRows,
				studioForm,
				selectedParticipants,
				completeParticipantsForm,
				finalizeForm,
				activeUnionFormStep,
				b4Type
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			saveUnionForm,
			unionFormId,
			isFindingSavedForm.current,
			shouldClearActiveExportForm.current,
		]
	);

	const handleSubmit = () => {
		let createUnionForm, setFormAction;
		switch (exportType) {
			case ExportType.AFM_B4:
			case ExportType.AFM_B9:
				createUnionForm = createAfmForm;
				setFormAction = setUnionFormAction;
				break;
			case ExportType.SAG_AFTRA:
				createUnionForm = createSagAftraForm;
				setFormAction = setSagFormAction;
				break;
			default:
				throw new Error('Invalid Union Form export type');
		}

		const apiTranslatedStudioForm = {
			...studioForm,
			recordingDate: formatDateToApi(studioForm?.recordingDate),
		};

		const unionForm = createUnionForm(
			selectedRecordingIds.map((id: number) => recordingsById![id].recording!),
			currentAlbumId ? albumsById![currentAlbumId]?.album : null,
			apiTranslatedStudioForm as UnionFormStudioForm,
			completeParticipantsForm as UnionFormCompleteParticipantsForm,
			finalizeForm as UnionFormFinalizeForm
		);

		// The active export form ID is required in the next screen, therefore
		// we must set this property so that it's not cleared when the component
		// is unmounted
		shouldClearActiveExportForm.current = false;

		dispatch(setFormAction(unionForm));

		dispatch(
			setExportParamsAction({
				afterCreate: (_: number, __: number | null) => {
					if (
						exportType === ExportType.AFM_B4 ||
						exportType === ExportType.AFM_B9
					) {
						dispatch(
							setCloudLatestContractNumberAction(finalizeForm!.contractNumber!)
						);
					}
				},
			})
		);

		navigate(
			replacePathVariables(ROUTES.ExportValidation.path, {
				recordingId: currentRecordingId,
			}),
			{
				replace: true,
			}
		);
	};

	const handleDiscardForm = useCallback(() => {
		dispatch(deleteExportFormAction(activeExportFormId!));
		dispatch(clearAllCurrentExportDataAction());

		navigate(ROUTES.Editor.path, { replace: true });
	}, [dispatch, navigate, activeExportFormId]);

	const handleResetForm = () => {
		dispatch(deleteExportFormAction(activeExportFormId!));
		dispatch(clearActiveExportFormAction());
	};

	const handleCancel = useCallback(() => {
		dispatch(
			showModalAction(EXIT_UNION_FORM_MODAL, {
				size: 'lg',
				onSave: () => {
					dispatch(hideModal());
					dispatch(clearAllCurrentExportDataAction());

					navigate(ROUTES.Editor.path, {
						replace: true,
					});
				},
				onDiscard: () => {
					dispatch(hideModal());
					handleDiscardForm();
				},
				onDismiss: () => {
					dispatch(hideModal());
				},
			})
		);
	}, [dispatch, navigate, handleDiscardForm]);

	const setExportPayload = useCallback(
		(recordings: Recording[]) => {
			ExportPayloadSetters.setUnionFormExportPayload({
				dispatch,
				exportType: exportType as
					| ExportType.AFM_B4
					| ExportType.AFM_B9
					| ExportType.SAG_AFTRA,
				recordings,
				album: currentAlbum,
			});
		},
		[dispatch, exportType, currentAlbum]
	);

	const renderFinalizeStep = (exportType: ExportType) => {
		switch (exportType) {
			case ExportType.AFM_B4:
				return <FinalizeStepB4 onFormSubmit={handleSubmit} />;
			case ExportType.AFM_B9:
				return <FinalizeStepB9 onFormSubmit={handleSubmit} />;
			case ExportType.SAG_AFTRA:
				return <FinalizeStepSAGAFTRA onFormSubmit={handleSubmit} />;
			default:
				return <></>;
		}
	};

	const renderCompleteParticipantsStep = (exportType: ExportType) => {
		switch (exportType) {
			case ExportType.AFM_B4:
			case ExportType.AFM_B9:
				return <CompleteParticipantsStepAFM />;
			case ExportType.SAG_AFTRA:
				return <CompleteParticipantsStepSAGAFTRA />;
			default:
				return <></>;
		}
	};

	const renderCurrentStep = (activeUnionFormStep: UnionFormStep) => {
		switch (activeUnionFormStep) {
			case UnionFormStep.LOAD_RECORDINGS:
				return <LoadRecordingsStep onCancel={handleCancel} />;
			case UnionFormStep.SELECT_STUDIO:
				return <SelectStudioStep onCancel={handleCancel} />;
			case UnionFormStep.SELECT_PARTICIPANTS:
				return <SelectParticipantsStep />;
			case UnionFormStep.COMPLETE_PARTICIPANTS:
				return renderCompleteParticipantsStep(exportType as ExportType);
			case UnionFormStep.FINALIZE_AND_EXPORT:
				return renderFinalizeStep(exportType as ExportType);
			default:
				return <></>;
		}
	};

	/*
	 * Effects
	 */
	const firstRender = useRef(true);

	// Load previously saved union form (only on first render)
	useEffect(() => {
		if (!firstRender.current) {
			return;
		}

		firstRender.current = false;

		const savedUnionForm = Object.values(savedExportFormsById).find(
			({
				exportType: savedExportType,
				albumId: savedAlbumId,
				recordingId: savedRecordingId,
				form: savedForm,
			}) => {
				if (savedExportType !== exportType) return false;
				// ADDITIONAL CHECK FOR PRODUCER B4 REPORT
				if (
					exportType === ExportType.AFM_B4 &&
					savedForm.b4Type?.id !== b4Type?.id
				)
					return false;
				if (!currentAlbumId) return savedRecordingId === currentRecordingId;

				return savedAlbumId === currentAlbumId;
			}
		);

		console.log(
			`UNION FORM ${savedUnionForm ? '' : 'NOT'} FOUND`,
			savedUnionForm
		);
		isFindingSavedForm.current = false;

		// if there were no previously saved union forms, return
		if (!savedUnionForm) {
			setIsLoading(false);
			return;
		}

		console.log('LOADING SAVED UNION FORM');
		dispatch(loadUnionFormAction(savedUnionForm.form));

		savedUnionFormId.current = savedUnionForm.id;

		dispatch(setActiveExportFormAction(savedUnionForm.id!));

		// fetch any missing recordings
		const recordingsToFetch = savedUnionForm.form.selectedRecordingRows
			.filter(
				({ recordingId }) =>
					!(
						recordingsById?.[recordingId] &&
						recordingsById[recordingId].recording
					)
			)
			.map(({ recordingId }) => recordingId);

		if (!selectedRecordingRows) {
			throw new Error(
				'Undefined or null recording rows found in union form state'
			);
		}

		const selectedRecordings = selectedRecordingRows
			.map(({ recordingId }) => recordingsById?.[recordingId])
			.filter((recording): recording is Recording => !!recording);

		if (recordingsToFetch.length === 0) {
			// if there's no currentAlbumId, this is handled in ExportSelectionModal.js inside
			// the handleUnionFormExport function
			if (currentAlbumId) {
				setExportPayload(selectedRecordings);
			}

			setIsLoading(false);
			return;
		}

		dispatch(
			fetchMultipleCloudProjectsAction(recordingsToFetch, fetchedRecordings => {
				// if there's no currentAlbumId, this is handled in ExportSelectionModal.js inside
				// the handleUnionFormExport function
				if (currentAlbumId) {
					// merge fetched recordings with all selected recordings
					// uniqBy chooses the first occurrence of each recording (we want this since fetchedRecordings contains the newly fetched data)
					const recordings = _.uniqBy(
						[...fetchedRecordings, ...selectedRecordings],
						'id'
					);
					setExportPayload(recordings);
				}

				setIsLoading(false);
			})
		);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		dispatch,
		exportType,
		savedExportFormsById,
		currentAlbumId,
		currentRecordingId,
		recordingsById,
		b4Type,
		currentAlbum,
	]);

	// Save union form data
	useEffect(() => {
		// if unionFormId is null it means it was not loaded yet,
		// thus the function must return to avoid race conditions
		if (
			isFindingSavedForm.current ||
			(!unionFormId && savedUnionFormId.current)
		)
			return;

		saveUnionForm(
			dispatch,
			exportType as ExportType,
			savedUnionFormId,
			activeExportFormId,
			currentRecordingId!,
			currentAlbumId,
			selectedRecordingRows || [],
			studioForm as UnionFormStudioForm,
			selectedParticipants!,
			completeParticipantsForm!,
			finalizeForm!,
			activeUnionFormStep,
			b4Type!
		);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		dispatch,
		exportType,
		savedUnionFormId.current,
		activeExportFormId,
		currentRecordingId,
		currentAlbumId,
		selectedRecordingRows,
		studioForm,
		selectedParticipants,
		completeParticipantsForm,
		finalizeForm,
		activeUnionFormStep,
		saveUnionForm,
		isFindingSavedForm.current,
		b4Type,
		unionFormId,
	]);

	// Save union form data on unmount
	useTaskBeforeUnmount(
		saveAndCloseForm,
		dispatch,
		exportType,
		savedUnionFormId,
		activeExportFormId,
		currentRecordingId,
		currentAlbumId,
		selectedRecordingRows,
		studioForm,
		selectedParticipants,
		completeParticipantsForm,
		finalizeForm,
		activeUnionFormStep,
		b4Type,
		savedUnionFormId.current,
		shouldClearActiveExportForm.current
	);

	return isLoading ? (
		<SoundCreditLoader />
	) : (
		<>
			<Helmet>
				<title>Union Form {process.env.REACT_APP_TAB_TITLE}</title>
			</Helmet>
			<ExportStepper steps={steps} activeStep={activeStep} />
			<div className='mt-5 container-horizontal-padding'>
				<div className='d-flex w-100 justify-content-end mb-2'>
					<Button
						label='Reset Form'
						onClick={handleResetForm}
						theme='danger'
						leftIcon='fas fa-redo'
						className='union-form-button'
					/>
					<Button
						label='Discard Form'
						onClick={handleDiscardForm}
						theme='danger'
						className='ml-2 union-form-button'
						leftIcon='fas fa-trash'
					/>
				</div>
				{renderCurrentStep(activeUnionFormStep)}
			</div>
		</>
	);
};

export default UnionFormExport;
