import clsx from 'clsx';
import { Avatar, AvatarProps } from 'primereact/avatar';
import React from 'react';
import styles from './SoundCreditAvatar.module.scss';

type SoundCreditAvatarProps = {
	name?: string | null;
	image?: string | null;
	icon?: string | null;
	size?: AvatarProps['size'];
	shape?: AvatarProps['shape'];
	isLoading?: boolean;
	className?: string;
	onClick?: () => void;
	style?: React.CSSProperties;
};

const SoundCreditAvatar = ({
	name,
	image,
	icon = 'fas fa-user',
	size = 'normal',
	shape = 'circle',
	isLoading = false,
	className = '',
	onClick,
	style,
	...props
}: SoundCreditAvatarProps) => {
	const [isLoadingImage, setIsLoadingImage] = React.useState(true);

	// extract first 2 initials from name
	const _name =
		name
			?.split(' ')
			.slice(0, 2)
			.map(n => n[0])
			.join('') ?? undefined;

	return (
		<>
			{image && isLoadingImage && (
				<Avatar
					className={clsx(styles['sound-credit-avatar'], className, {
						'p-skeleton p-skeleton-circle': isLoading || isLoadingImage,
					})}
					size={size}
					shape={shape}
					style={{
						...style,
						display: isLoadingImage ? 'block' : 'none',
					}}
					{...props}
				/>
			)}
			<Avatar
				icon={!image && !_name && !isLoading ? icon : undefined}
				label={!image ? _name : undefined}
				image={image ?? undefined}
				onLoad={() => setIsLoadingImage(false)}
				size={size}
				shape={shape}
				className={clsx(styles['sound-credit-avatar'], className, {
					'p-skeleton p-skeleton-circle': isLoading,
				})}
				onClick={onClick}
				style={{
					...style,
					display: image && isLoadingImage ? 'none' : 'inline-flex',
				}}
				{...props}
			/>
		</>
	);
};

export default SoundCreditAvatar;
