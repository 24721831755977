import { omitBy } from 'lodash';
import {
	mergePlaylists,
	mergePlaylistStats,
} from '../../helpers/playlistTools';
import {
	ADD_UPLOADS_TO_PLAYLIST,
	CLEAR_PLAYLISTS,
	DELETE_LOCAL_PLAYLIST,
	DELETE_LOCAL_PLAYLIST_FILE,
	DELETE_UPLOADS_FROM_PLAYLIST,
	SET_CURRENT_PLAYLIST_EDITORS,
	SET_CURRENT_PLAYLIST_ID,
	SET_FULL_DRIP_STATS,
	SET_FULL_PLAYLISTS,
	SET_FULL_PLAYLIST_STATS,
	SET_PLAYLIST_CREDIT_REQUEST_COUNT,
	SET_USER_PLAYLISTS,
	SET_USER_PLAYLIST_STATS,
} from '../actionTypes';
import { AnyAction } from 'redux';
// import playlistData from '../../components/screens/Playlists/PlaylistSubmenu/PlaylistList/playlistData';

const initialState: PlaylistState = {
	playlistsById: null,
	playlistStatsById: null,
	dripStatsById: null,
	currentPlaylistId: null,
	errorMessage: '',
	requestLabel: '',
	requestStatus: '',
	currentPlaylistEditors: null,
};

const playlistsReducer = (
	state = initialState,
	action: AnyAction
): PlaylistState => {
	switch (action.type) {
		case SET_CURRENT_PLAYLIST_ID:
			return {
				...state,
				currentPlaylistId: action.id,
			};
		case SET_USER_PLAYLISTS:
			return {
				...state,
				playlistsById: mergePlaylists(
					state.playlistsById,
					action.playlists,
					true
				),
			};
		case SET_USER_PLAYLIST_STATS:
			return {
				...state,
				playlistStatsById: mergePlaylistStats(
					state.playlistStatsById,
					action.playlistStats,
					true
				),
			};
		case SET_FULL_PLAYLISTS:
			return {
				...state,
				playlistsById: mergePlaylists(state.playlistsById, action.playlists),
			};
		case SET_FULL_PLAYLIST_STATS:
			return {
				...state,
				playlistStatsById: mergePlaylistStats(
					state.playlistStatsById,
					action.playlistStats
				),
			};
		case SET_FULL_DRIP_STATS:
			return {
				...state,
				dripStatsById: mergePlaylistStats(
					state.dripStatsById,
					action.dripStats
				),
			};
		case ADD_UPLOADS_TO_PLAYLIST:
			if (!state.playlistsById?.[action.playlistId]) {
				return state;
			}

			return {
				...state,
				playlistsById: {
					...state.playlistsById,
					[action.playlistId]: {
						...(state.playlistsById?.[action.playlistId] as Playlist),
						uploads: [
							...action.uploadIds,
							...(state.playlistsById?.[action.playlistId]?.uploads ?? []),
						],
					},
				},
			};
		case DELETE_UPLOADS_FROM_PLAYLIST:
			if (!state.playlistsById?.[action.playlistId]) {
				return state;
			}

			return {
				...state,
				playlistsById: {
					...state.playlistsById,
					[action.playlistId]: {
						...(state.playlistsById?.[action.playlistId] as Playlist),
						uploads: state.playlistsById?.[action.playlistId]?.uploads?.filter(
							uploadId => !action.uploadIds.includes(uploadId)
						),
					},
				},
			};
		case DELETE_LOCAL_PLAYLIST_FILE:
			const playlist = state.playlistsById?.[action.playlistId] as Playlist;

			if (
				!state.playlistsById ||
				!action.playlistId ||
				!action.fileId ||
				!playlist?.playlist
			) {
				return state;
			}

			return {
				...state,
				playlistsById: {
					...(state.playlistsById ?? {}),
					[action.playlistId]: {
						...(state.playlistsById[action.playlistId] as any),
						playlist: {
							...playlist,
							files: playlist.playlist.files?.filter(
								file => file.id !== action.fileId
							),
						},
					},
				},
			};
		case DELETE_LOCAL_PLAYLIST:
			return {
				...state,
				playlistsById: {
					...omitBy(
						state.playlistsById as Record<Playlist['id'], Playlist>,
						playlist => playlist.id === action.id
					),
				},
			};
		case SET_CURRENT_PLAYLIST_EDITORS:
			return {
				...state,
				currentPlaylistEditors: {
					playlistId: action.playlistId,
					editors: action.editors,
				},
			};
		case SET_PLAYLIST_CREDIT_REQUEST_COUNT:
			if (!state.playlistsById?.[action.playlistId]?.playlist) {
				console.warn('No playlist found for credit request count update');
				return state;
			}

			return {
				...state,
				playlistsById: {
					...state.playlistsById,
					[action.playlistId]: {
						...state.playlistsById[action.playlistId]!,
						playlist: {
							...state.playlistsById[action.playlistId]!.playlist!,
							creditRequestCount: action.count,
						},
					},
				},
			};

		case CLEAR_PLAYLISTS:
			return {
				...initialState,
			};
		default:
			return state;
	}
};

export default playlistsReducer;
