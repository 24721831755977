import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import { useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import ProjectEditorRoles from '../../../../../../constants/projectEditorRoles';
import Select from '../../../../../form/Select';
import Button from '../../../../../layout/Button';
import '../TransferPlaylistModal.scss';
import { InitiatePlaylistTransferResponse } from '../../../../../../api/services/filesService';

export type CompleteTransferForm = {
	editorRole: (typeof ProjectEditorRoles)[keyof typeof ProjectEditorRoles];
};

export type CompletePlaylistTransferStepProps = {
	onSubmit: (values: CompleteTransferForm) => void;
	onBack: () => void;
	initiateResponse: InitiatePlaylistTransferResponse | null;
};

const CompletePlaylistTransferStep = ({
	onSubmit,
	onBack,
	initiateResponse,
}: CompletePlaylistTransferStepProps) => {
	const editorRoleOptions = useMemo(() => {
		const options = [
			{ label: 'Read-Only', value: ProjectEditorRoles.READ_ONLY },
			{ label: 'Share Playlist Link Only', value: null },
		];

		if (initiateResponse?.canAssignCoadmin) {
			options.push({
				label: 'Co-Admin',
				value: ProjectEditorRoles.COADMIN,
			});
		}

		if (initiateResponse?.canAssignOwner) {
			options.push({
				label: 'Owner',
				value: ProjectEditorRoles.OWNER,
			});
		}

		return options;
	}, [initiateResponse]);

	const formik = useFormik<CompleteTransferForm>({
		initialValues: {
			editorRole: ProjectEditorRoles.READ_ONLY,
		},
		onSubmit: values => onSubmit(values),
	});

	const requiresProjectInvite = useMemo(
		() =>
			[
				ProjectEditorRoles.COADMIN,
				ProjectEditorRoles.READ_ONLY,
				ProjectEditorRoles.OWNER,
			].includes(formik?.values?.editorRole),
		[formik?.values?.editorRole]
	);

	const includedRecordings = useMemo(
		() => Object.values(initiateResponse?.includedProjects?.recordings || {}),
		[initiateResponse?.includedProjects?.recordings]
	);

	const includedAlbums = useMemo(
		() => Object.values(initiateResponse?.includedProjects?.albums || {}),
		[initiateResponse?.includedProjects?.albums]
	);

	return (
		<>
			<FormikProvider value={formik}>
				<Modal.Body>
					<div className='px-4'>
						<Select
							name='editorRole'
							options={editorRoleOptions}
							label='Recipient Access'
							value={editorRoleOptions.find(
								option => option.value === formik?.values?.editorRole
							)}
							onChange={(option: any) =>
								formik.setFieldValue('editorRole', option.value)
							}
							onBlur={formik.handleBlur}
							errorFieldName='editorRole'
						/>

						{requiresProjectInvite && (
							<div className='mt-3' style={{ fontSize: '0.9rem' }}>
								<p className='mb-2'>
									<strong>
										The recipient will be invited to collaborate in the
										following projects with{' '}
										{
											editorRoleOptions.find(
												option => option.value === formik?.values?.editorRole
											)?.label
										}{' '}
										access:
									</strong>
								</p>
								{includedRecordings?.length > 0 && (
									<>
										<p className='mb-0'>Recordings</p>
										<hr className='my-1' />

										<ul>
											{includedRecordings?.map((projectName, i) => (
												<li key={i} className='transfer-playlist__project'>
													{projectName}
												</li>
											))}
										</ul>
									</>
								)}

								{includedAlbums?.length > 0 && (
									<>
										<p className='mb-0'>Albums</p>
										<hr className='my-1' />

										<ul>
											{includedAlbums?.map((projectName, i) => (
												<li key={i} className='transfer-playlist__project'>
													{projectName}
												</li>
											))}
										</ul>
									</>
								)}
							</div>
						)}
					</div>
				</Modal.Body>

				<Modal.Footer>
					<Button label='Back' onClick={onBack} className='mr-2' />
					<Button
						label='Transfer'
						onClick={formik.handleSubmit as any}
						theme='dark'
						isLoading={formik.isSubmitting}
					/>
				</Modal.Footer>
			</FormikProvider>
		</>
	);
};

export default CompletePlaylistTransferStep;
