import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Button from '../../layout/Button';
import { hideModal, setModalTitle } from '../../../store/modal/actions';
import { useAppDispatch } from '../../../store/hooks';
import styles from './CheckEmailSignUpModal.module.scss';

type CheckEmailSignUpModalProps = {
	onConfirm: () => void;
	onDecline: () => void;
	foundProfile: IndieProfile;
};

const CheckEmailSignUpModal = ({
	onConfirm,
	onDecline,
	foundProfile,
}: CheckEmailSignUpModalProps) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setModalTitle('USE EXISTING PROFILE?'));
	}, [dispatch]);

	return (
		<>
			<Modal.Body>
				<div className='px-4'>
					<p>
						We found a profile that matches your email address. Is this you?
					</p>

					<div className={styles['found-profile-card']}>
						<strong>Name</strong>
						<p>
							{foundProfile.name}
							{foundProfile.creditedName &&
								foundProfile.creditedName !== foundProfile.name && (
									<span> (a.k.a. {foundProfile.creditedName})</span>
								)}
						</p>
						{'title' in foundProfile.previousCredit && (
							<>
								<hr />
								<strong>Latest Credit</strong>
								<p>Title: {foundProfile.previousCredit.title}</p>

								{foundProfile.previousCredit.role && (
									<p>Role: {foundProfile.previousCredit.role}</p>
								)}
							</>
						)}
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button label='Dismiss' onClick={() => dispatch(hideModal())} />
				<Button label={`No, that's not me`} onClick={onDecline} />
				<Button label={`Yep, that's me`} onClick={onConfirm} theme='dark' />
			</Modal.Footer>
		</>
	);
};

export default CheckEmailSignUpModal;
