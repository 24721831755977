import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { lastFourSsnRegex } from '../../../../helpers/regex';
import styles from './SignUpStep.module.scss';
import TextField from '../../../form/TextField/TextField';
import Button from '../../../layout/Button';
import clsx from 'clsx';

const US_COUNTRY_NAME = 'United States of America';

export type UnionStepFormikValues = UnionAddress & {
	ssnLastFour: string;
};

export type UnionStepProps = {
	onSubmit: (values: UnionStepFormikValues) => Promise<void>;
	initialFormValues: Partial<UnionStepFormikValues>;
};

const validationSchema = Yup.object({
	address1: Yup.string().required('Required'),
	address2: Yup.string(),
	city: Yup.string(),
	state: Yup.string().required('Required'),
	postCode: Yup.string(),
	country: Yup.string(),
	ssnLastFour: Yup.string().when('country', {
		is: US_COUNTRY_NAME,
		then: Yup.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.matches(lastFourSsnRegex, 'Invalid digits')
			.required('Required'),
	}),
});

const UnionStep = ({ onSubmit, initialFormValues }: UnionStepProps) => {
	const formik = useFormik<UnionStepFormikValues>({
		initialValues: {
			address1: '',
			address2: '',
			city: '',
			state: '',
			postCode: '',
			country: '',
			ssnLastFour: '',
			...initialFormValues,
		},
		onSubmit: async values => {
			console.log('values', values);
			await onSubmit(values);
		},
		validateOnMount: true,
		validationSchema,
	});

	return (
		<FormikProvider value={formik}>
			<div className={styles['sign-up-step-container']}>
				<h2>Enter your address as you want it to appear on union forms</h2>

				<div className={styles['form']}>
					<i className={clsx(styles['required-field'], 'text-muted')}>
						* Required field
					</i>

					<TextField
						name='address1'
						label='Address Line 1*'
						placeholder='Address Line 1'
						value={formik.values.address1}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						errorMessage={formik.errors.address1}
						isInvalid={formik.touched.address1 && !!formik.errors.address1}
					/>

					<TextField
						name='address2'
						label='Address Line 2'
						placeholder='Address Line 2'
						value={formik.values.address2!}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
					/>

					<TextField
						name='city'
						label='City'
						placeholder='City'
						value={formik.values.city!}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
					/>

					<TextField
						name='state'
						label='State / Region*'
						placeholder='State / Region'
						value={formik.values.state!}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						errorMessage={formik.errors.state}
						isInvalid={formik.touched.state && !!formik.errors.state}
					/>

					<TextField
						name='postCode'
						label='Zip / Postal Code'
						placeholder='Zip / Postal Code'
						value={formik.values.postCode!}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
					/>

					{formik.values.country === US_COUNTRY_NAME && (
						<TextField
							name='ssnLastFour'
							label='Last 4 digits of SSN*'
							placeholder='Last 4 digits of SSN'
							value={formik.values.ssnLastFour!}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							errorMessage={formik.errors.ssnLastFour}
							isInvalid={
								formik.touched.ssnLastFour && !!formik.errors.ssnLastFour
							}
						/>
					)}

					<div className='my-3' />

					<Button
						label='Submit'
						onClick={() => formik.handleSubmit()}
						isDisabled={!formik.isValid}
						isLoading={formik.isSubmitting}
						theme='dark'
						size='lg'
					/>
				</div>
			</div>
		</FormikProvider>
	);
};

export default UnionStep;
