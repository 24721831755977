import ExportTierId from './exportTierId';
import ExportId from './exportsIds';

const exportTiersByExportId = {
	[ExportId.LABEL_STYLE_SINGLE]: ExportTierId.PRO,
	[ExportId.LABEL_STYLE_RELEASE_LONG]: ExportTierId.PRO,
	[ExportId.LABEL_STYLE_RELEASE_REGULAR]: ExportTierId.PRO,
	[ExportId.LYRIC_SHEET]: ExportTierId.STARTER,
	[ExportId.CD_INSERT_SHORT]: ExportTierId.STARTER,
	[ExportId.CD_INSERT_LONG]: ExportTierId.STARTER,
	[ExportId.LABEL_STYLE_PERSONNEL_SPREADSHEET]: ExportTierId.BUSINESS,
	[ExportId.SOUND_CREDIT_SINGLE]: ExportTierId.PRO,
	[ExportId.SOUND_CREDIT_RELEASE]: ExportTierId.PRO,
	[ExportId.VINYL_SLEEVE_LONG]: ExportTierId.STARTER,
	[ExportId.VINYL_SLEEVE_SHORT]: ExportTierId.STARTER,
	[ExportId.METADATA_MASTERING]: ExportTierId.STARTER,
	[ExportId.ALL_MUSIC_SPREADSHEET]: ExportTierId.STARTER,
	[ExportId.THE_ORCHARD_SPREADSHEET]: ExportTierId.PRO,
	[ExportId.SOUND_EXCHANGE_SPREADSHEET]: ExportTierId.PRO,
	[ExportId.MLC_SPREADSHEET]: ExportTierId.PRO,
	[ExportId.AFM_B4]: ExportTierId.BUSINESS,
	[ExportId.AFM_B9]: ExportTierId.BUSINESS,
	[ExportId.SYNCHTANK]: ExportTierId.BUSINESS,
	[ExportId.SAG_AFTRA]: ExportTierId.BUSINESS,
	[ExportId.BIG_MACHINE_SINGLE]: ExportTierId.BUSINESS,
	[ExportId.BIG_MACHINE_ALBUM]: ExportTierId.BUSINESS,
	[ExportId.INSTAGRAM_IMAGE]: ExportTierId.FREE,
	[ExportId.STORIES]: ExportTierId.FREE,
	[ExportId.FACEBOOK_TWITTER_IMAGE]: ExportTierId.FREE,
	[ExportId.CD_BABY_EXPORT_LONG]: ExportTierId.PRO,
	[ExportId.CD_BABY_EXPORT_SHORT]: ExportTierId.PRO,
	[ExportId.SOUND_CREDIT_EMAIL]: ExportTierId.PRO,
	[ExportId.EBR_STANDARD_TEMPLATE]: ExportTierId.PRO,
	[ExportId.PPL_REGISTRATION]: ExportTierId.PRO,
	[ExportId.PRODUCER_ROYALTY_AGREEMENT]: ExportTierId.STARTER,
	[ExportId.SPLIT_SHEET_STANDARD]: ExportTierId.PRO,
	[ExportId.SPLIT_SHEET_SINGLE_WRITER]: ExportTierId.PRO,
	[ExportId.PRODUCER_AGREEMENT]: ExportTierId.STARTER,
	[ExportId.SIDE_ARTIST_AGREEMENT]: ExportTierId.STARTER,
	[ExportId.PUBLISHING_LABEL_COPY_DOCUMENT]: ExportTierId.BUSINESS,
	[ExportId.PUBLISHING_LABEL_COPY_SPREADSHEET]: ExportTierId.BUSINESS,
	[ExportId.DIGITAL_DISTRIBUTION]: ExportTierId.BUSINESS,
};

export default exportTiersByExportId;
