import React, { useCallback } from 'react';
import './ToggleInput.scss';
import { InputSwitch, InputSwitchChangeEvent } from 'primereact/inputswitch';

function ToggleInput({
	label,
	name,
	id,
	onChange,
	onBlur,
	checked,
	value,
	className = '',
	isDisabled = false,
	style = {},
	labelStyle = {},
	stacked = false,
}: {
	label?: string | JSX.Element;
	name?: string;
	id?: string;
	onChange: (e: InputSwitchChangeEvent) => void;
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
	checked: boolean;
	value: string | number | boolean;
	className?: string;
	isDisabled?: boolean;
	style?: React.CSSProperties;
	labelStyle?: React.CSSProperties;
	stacked?: boolean;
}) {
	const renderLabel = useCallback(
		() => (
			<span className='form-label' style={labelStyle}>
				{label}
			</span>
		),
		[label, labelStyle]
	);

	return (
		<div
			className={`switch-container ${className} 
		${stacked ? 'd-flex flex-column' : ''}
		`}
			style={style}
		>
			{stacked && renderLabel()}

			<InputSwitch
				type='checkbox'
				name={name}
				onChange={onChange}
				onBlur={onBlur}
				checked={checked}
				value={value?.toString()}
				id={id}
				className='mr-2'
				disabled={isDisabled}
			/>
			{!stacked && renderLabel()}
		</div>
	);
}

export default ToggleInput;
