import { renderAsync } from 'docx-preview';
import React, { useEffect, useRef } from 'react';
import './DocxPreview.scss';

export type DocxPreviewProps = {
	file: File | Blob | ArrayBuffer;
};

const DocxPreview = ({ file }: DocxPreviewProps) => {
	const previewRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (!file) return;

		renderAsync(file, previewRef.current!);
	}, [file]);

	return (
		<div>
			<div style={{ height: '30vh' }}></div>
			<div className='docx-preview-modal-content' ref={previewRef} />;
		</div>
	);
};

export default DocxPreview;
