import { FileWithPath, fromEvent } from 'file-selector';
import { toFileWithPath } from 'file-selector/dist/file';
import { flatten } from 'lodash';
import { ConnectedOverlayScrollHandler } from 'primereact/utils';
import React, { useCallback, useState } from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import { JsxElement } from 'typescript';

async function getFile(fileEntry: any) {
	try {
		return await new Promise((resolve, reject) =>
			fileEntry.file(resolve, reject)
		);
	} catch (err) {
		console.log(err);
	}
}

async function processDirectory(directoryHandle: FileSystemDirectoryHandle) {
	for await (const entry of directoryHandle.values()) {
		if (entry.kind === 'file') {
			const fileHandle = entry as FileSystemFileHandle;
			const file = await fileHandle.getFile();
			console.log(`File: ${file.name}`);
		} else if (entry.kind === 'directory') {
			const subDirectoryHandle = entry as FileSystemDirectoryHandle;
			console.log(`Directory: ${subDirectoryHandle.name}`);
			// Recursively process subdirectories
			await processDirectory(subDirectoryHandle);
		}
	}
}

function traverse_directory(entry: any, result: any[]) {
	let reader = entry.createReader();
	const errorHandler = (e: any) => console.log('Error:', e);
	// Resolved when the entire directory is traversed
	return new Promise(resolve_directory => {
		var iteration_attempts: any[] = [];
		(function read_entries() {
			// According to the FileSystem API spec, readEntries() must be called until
			// it calls the callback with an empty array.  Seriously??
			reader.readEntries((entries: any) => {
				if (!entries.length) {
					// Done iterating this particular directory
					resolve_directory(Promise.all(iteration_attempts));
				} else {
					// Add a list of promises for each directory entry.  If the entry is itself
					// a directory, then that promise won't resolve until it is fully traversed.
					iteration_attempts.push(
						Promise.all(
							entries.map(async (entry: any) => {
								if (entry.isFile) {
									let file: any = await getFile(entry);
									Object.defineProperty(file, 'path', {
										value: null,
									});
									// result.push(fileGet);
									console.log('File:', file);

									result.push(file);

									return entry;
								} else {
									console.log('Folder:', entry);
									let file = new File([], entry.fullPath);
									Object.defineProperty(file, 'type', {
										value: 'folder',
									});
									result.push(file);
									// file.type = "folder"
									return traverse_directory(entry, result);
								}
							})
						)
					);
					// Try calling readEntries() again for the same dir, according to spec
					read_entries();
				}
			}, errorHandler);
		})();
	});
}

async function traverseFileTree(
	item: any,
	path: any,
	result: any,
	emptyFolders: any
) {
	path = path || '';
	if (item.isFile) {
		const fileGet = await getFile(item);
		await Object.defineProperty(fileGet, 'path', {
			value: path,
		});
		result.push(fileGet);
	} else if (item.isDirectory) {
		const folder: any = {};
		folder.path = path;
		folder.name = item?.name;
		folder.children = [];

		let dirReader = await item.createReader();

		await dirReader.readEntries(async function (entries: any) {
			for (var i = 0; i < entries.length; i++) {
				await traverseFileTree(
					entries[i],
					path + item.name + '/',
					folder.children,
					emptyFolders
				);
			}
		});
	}
}

let el: any | null;

function fromList<T>(items: DataTransferItemList | FileList | null): T[] {
	if (items === null) {
		return [];
	}

	const files = [];

	// tslint:disable: prefer-for-of
	for (let i = 0; i < items.length; i++) {
		const file = items[i];
		files.push(file);
	}

	return files as any;
}

async function fromFileEntry(entry: any) {
	return new Promise<FileWithPath>((resolve, reject) => {
		entry.file(
			(file: FileWithPath) => {
				const fwp = toFileWithPath(file, entry.fullPath);
				resolve(fwp);
			},
			(err: any) => {
				reject(err);
			}
		);
	});
}

async function fromEntry(entry: any) {
	return entry.isDirectory ? fromDirEntry(entry) : fromFileEntry(entry);
}

interface FileArray extends Array<FileValue> {}
type FileValue = FileWithPath | FileArray[];

function fromDirEntry(entry: any) {
	const reader = entry.createReader();

	return new Promise<FileArray[]>((resolve, reject) => {
		const entries: Promise<FileValue[]>[] = [];

		function readEntries() {
			// https://developer.mozilla.org/en-US/docs/Web/API/FileSystemDirectoryEntry/createReader
			// https://developer.mozilla.org/en-US/docs/Web/API/FileSystemDirectoryReader/readEntries
			reader.readEntries(
				async (batch: any[]) => {
					if (!batch.length) {
						// Done reading directory
						try {
							const files = await Promise.all(entries);
							resolve(files);
						} catch (err) {
							reject(err);
						}
					} else {
						const items = Promise.all(batch.map(fromEntry));
						entries.push(items);

						// Continue reading
						readEntries();
					}
				},
				(err: any) => {
					reject(err);
				}
			);
		}

		readEntries();
	});
}

function fromDataTransferItem(item: DataTransferItem) {
	const file = item.getAsFile();
	if (!file) {
		return Promise.reject(`${item} is not a File`);
	}
	const fwp = toFileWithPath(file);
	return Promise.resolve(fwp);
}

function toFilePromises(item: DataTransferItem) {
	if (typeof item.webkitGetAsEntry !== 'function') {
		return fromDataTransferItem(item);
	}

	const entry = item.webkitGetAsEntry();

	// Safari supports dropping an image node from a different window and can be retrieved using
	// the DataTransferItem.getAsFile() API
	// NOTE: FileSystemEntry.file() throws if trying to get the file
	if (entry && entry.isDirectory) {
		return fromDirEntry(entry) as any;
	}

	return fromDataTransferItem(item);
}

async function getDataTransferFiles(dt: DataTransfer, type: string) {
	// IE11 does not support dataTransfer.items
	// See https://developer.mozilla.org/en-US/docs/Web/API/DataTransfer/items#Browser_compatibility
	if (dt.items) {
		const items = fromList<DataTransferItem>(dt.items).filter(
			item => item.kind === 'file'
		);

		// According to https://html.spec.whatwg.org/multipage/dnd.html#dndevents,
		// only 'dragstart' and 'drop' has access to the data (source node)
		if (type !== 'drop') {
			return items;
		}

		const files = await Promise.all(
			items.map((item: any) => item.webkitGetAsEntry())
		);
		return files;
	}

	return fromList<FileWithPath>(dt.files).map(file => toFileWithPath(file));
}

function isDataTransfer(value: any): value is DataTransfer {
	return isObject(value);
}

function isChangeEvt(value: any): value is Event {
	return isObject<Event>(value) && isObject(value.target);
}

function isObject<T>(v: any): v is T {
	return typeof v === 'object' && v !== null;
}

async function getFsHandleFiles(handles: any[]) {
	const files = await Promise.all(handles.map(h => h.getFile()));
	return files.map(file => toFileWithPath(file));
}

async function readFolderContents(handle: any): Promise<FileSystemHandle[]> {
	const entries: FileSystemHandle[] = [];

	const dirReader = handle.createReader();
	let hasMoreEntries = true;

	while (hasMoreEntries) {
		const { value, done } = await dirReader.read();
		hasMoreEntries = !done;

		if (value) {
			entries.push(...value);

			for (const entry of value) {
				if (entry.kind === 'directory') {
					entries.push(
						...(await readFolderContents(entry as FileSystemFileHandle))
					);
				}
			}
		}
	}

	return entries;
}

const listHasZip = (list: any[]) => {
	for (let i = 0; i < list.length; i++) {
		if (list[i].type === 'application/zip') {
			return true;
		}
	}
	return false;
};

async function myCustomFileGetter1(
	event: any,
	inputRef: React.RefObject<HTMLInputElement>
) {
	// Case File and Zipped Folder Select on Chrome
	if (inputRef.current && inputRef.current.files) {
		let fls = inputRef.current.files;

		if (fls.length > 0) {
			return Array.from(fls);
		}
	}
	// else {
	// 	console.log('No fls');
	// }

	let baseWebkitFiles: any[] = [];
	// Case Drag & Drop
	if (isObject<DragEvent>(event) && isDataTransfer(event.dataTransfer)) {
		baseWebkitFiles = await getDataTransferFiles(
			event.dataTransfer,
			event.type
		);
	}

	// Case Select on Chrome
	if (
		Array.isArray(event) &&
		event.every(
			(item: any) => 'getFile' in item && typeof item.getFile === 'function'
		)
	) {
		let files = await getFsHandleFiles(event);

		return files;
	}

	var items = event.dataTransfer?.items || [];

	let result: any[] = [];
	let emptyFolders: any[] = [];
	let promises: Promise<void>[] = [];

	let webkitItems = [];
	let itemsHasZip = listHasZip(items);

	if (baseWebkitFiles.length === 0 || itemsHasZip) {
		for (var i = 0; i < items.length; i++) {
			let item = await items[i].webkitGetAsEntry();

			if (item) {
				if (itemsHasZip) {
					webkitItems.push(items[i].getAsFile());
				} else {
					webkitItems.push(item);
				}
			}
		}
	} else {
		// Using previously obtained webkit files
		webkitItems = baseWebkitFiles;
	}

	return webkitItems;

	// for (var i = 0; i < webkitItems.length; i++) {
	// 	if (webkitItems[i].isDirectory) {
	// 		// console.log('Directory:', webkitItems[i]);
	// 		// // promises.push(
	// 		// await traverseFileTree(webkitItems[i], null, result, emptyFolders);
	// 		// // );
	// 		// console.log('R:', result);
	// 		traverse_directory(webkitItems[i], result).then((items: any) => {
	// 			// AT THIS POINT THE DIRECTORY SHOULD BE FULLY TRAVERSED.
	// 			console.log('Traversal finished');
	// 			console.log(items);
	// 			console.log('Result:', result);
	// 			return result;
	// 		});
	// 	}
	// }
}

async function myCustomFileGetter(event: any) {
	const files = [];
	const fileList = event.dataTransfer
		? event.dataTransfer.files
		: event.target.files;

	for (var i = 0; i < fileList.length; i++) {
		const file = fileList.item(i);

		Object.defineProperty(file, 'myProp', {
			value: true,
		});

		files.push(file);
	}

	const filesDataPromise = fromEvent(event);

	const heirarchyDetails = await probeFolders(event);

	const filesData = await filesDataPromise;
	// console.log(JSON.stringify(heirarchyDetails, null, 2), filesData);

	return files;
}

// async function droppedItemHeirarchyProber(e: any) {
// 	const filesDataPromise = fromEvent(e);
// 	const heirarchyDetails = await probeFolders(e);

// 	const filesData = await filesDataPromise;
// 	// console.log(JSON.stringify(heirarchyDetails, null, 2), filesData);

// 	return { filesData, heirarchyDetails };
// }

async function probeFolders(event: any) {
	const heirarchyDetails: {
		emptyFolders: any[];
		allFolders: any[];
		files: any[];
	} = {
		emptyFolders: [],
		allFolders: [],
		files: [],
	};

	// IMPORTANT: FileSystemDirectoryHandle or FileSystemFileHandle are experimental.
	// ref 1 : https://developer.mozilla.org/en-US/docs/Web/API/FileSystemFileHandle
	// ref 2: https://developer.mozilla.org/en-US/docs/Web/API/FileSystemDirectoryHandle
	// The app needs to be served with secure contexts (HTTPS) using ngrok: https://frontendguruji.com/blog/run-next-js-app-locally-in-https/
	const rootHandle = await event.dataTransfer.items[0].getAsFileSystemHandle();
	// rootHandle can be "FileSystemFileHandle" or "FileSystemDirectoryHandle"
	// rootHandle.kind can be "file" or "directory"

	console.log('Before path');
	const path = `/${rootHandle.name}`;
	if (rootHandle.kind === 'directory') {
		await traverseDirectory(rootHandle, path, heirarchyDetails);
	} else if (rootHandle.kind === 'file') {
		const file: any = { name: rootHandle.name, kind: rootHandle.kind, path };
		heirarchyDetails.files.push(file);
	}

	return heirarchyDetails;
}

async function traverseDirectory(
	dirHandle: any,
	currentPath: any,
	heirarchyDetails: any
) {
	const folderDetails: {
		name: any;
		kind: any;
		path: any;
		children: any[];
	} = {
		name: dirHandle.name,
		kind: dirHandle.kind,
		path: currentPath,
		children: [],
	};

	for await (const [name, handle] of dirHandle.entries()) {
		const path = `${currentPath}/${name}`;

		if (handle.kind === 'file') {
			const file = { path, name: handle.name, kind: handle.kind };
			heirarchyDetails.files.push(file);
			folderDetails.children.push(file);
		} else if (handle.kind === 'directory') {
			const childDetails = //
				await traverseDirectory(handle, path, heirarchyDetails);

			if (childDetails.children.length === 0) {
				heirarchyDetails.emptyFolders.push(childDetails);
			}

			folderDetails.children.push(childDetails);
		}
	}

	heirarchyDetails.allFolders.push(folderDetails);
	return folderDetails;
}

const MyDropzone = ({
	children,
	multiple,
	onSelect,
}: {
	children?: any;
	multiple: boolean;
	onSelect: (acceptedFiles: any) => Promise<void> | void;
}) => {
	const [files, setFiles] = useState<any[]>([]);

	// const onDrop = React.useCallback(
	// 	(acceptedFiles: any, fileRejections: FileRejection[], event: DropEvent) => {
	// 		console.log('File Rejections:', fileRejections);
	// 		setFiles((prev: any) => [...prev, ...acceptedFiles]);
	// 	},
	// 	[]
	// );

	const fileList = files.map(file => (
		<li key={file.path}>
			{file.path} - {file.size} bytes
		</li>
	));

	// const handleFileChange = useCallback((e: any) => {
	// 	// Extract the FileList object from the input event
	// 	const files = e.target.files;

	// 	// Convert FileList object to an array
	// 	const filesArray = Array.from(files);

	// 	// Update state with the array of files
	// 	// setSelectedFiles(filesArray);

	// 	// Optionally, you can also log file details here
	// 	console.log(filesArray);
	// }, []);

	const { getRootProps, getInputProps, isDragActive, inputRef } = useDropzone({
		onDrop: onSelect,
		// onDrop,
		multiple,
		getFilesFromEvent: event => myCustomFileGetter1(event, inputRef),
		useFsAccessApi: false,
	});

	return (
		<div {...getRootProps()} className='dropzone'>
			<input {...getInputProps()} />
			{children}
		</div>
	);
};

export default MyDropzone;
