import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import WaveSurfer from 'wavesurfer.js';
import WaveSurferCursor from 'wavesurfer.js/dist/plugin/wavesurfer.cursor.js';
import theme from '../../../theme.module.scss';
import IconButton from '../IconButton';
import { Range, Direction, getTrackBackground } from 'react-range';
import './AudioPreview.scss';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { setVolumeAction } from '../../../store/session/actions';
import Color from 'color';

const formatTime = time => {
	const minutes = Math.floor(time / 60);
	const seconds = Math.floor(time - minutes * 60);

	return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
};

const AudioPreview = ({ fileUrl, className = '' }) => {
	const { volume: savedVolume } = useSelector(state => state.session);
	const dispatch = useDispatch();

	const [isPlaying, setIsPlaying] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [loadingProgress, setLoadingProgress] = useState(0);
	const [currentTime, setCurrentTime] = useState('-:--');
	const [remainingTime, setRemainingTime] = useState('-:--');
	const [volume, setVolume] = useState([savedVolume ?? 100]);

	const waveformRef = useRef(null);

	useEffect(() => {
		if (!fileUrl) return;

		const track = document.querySelector('#audio-preview-track');

		waveformRef.current = WaveSurfer.create({
			container: '#audio-preview-waveform',
			barWidth: 2,
			height: 100,
			waveColor: '#c1c1c1',
			progressColor: theme.primary,
			responsive: true,
			plugins: [
				WaveSurferCursor.create({
					showTime: true,
					opacity: 1,
					customShowTimeStyle: {
						'background-color': 'rgba(0, 0, 0, 0.8)',
						color: '#fff',
						padding: '0.2rem',
						'font-size': '0.8rem',
					},
				}),
			],
		});

		waveformRef.current.load(track);

		waveformRef.current.on('ready', () => {
			setIsLoading(false);

			waveformRef.current.setVolume(volume[0] / 100);
		});

		waveformRef.current.on('loading', progress => {
			setLoadingProgress(progress);
		});

		const updateTime = () => {
			setCurrentTime(formatTime(waveformRef.current.getCurrentTime()));

			const remaining =
				waveformRef.current.getDuration() -
				waveformRef.current.getCurrentTime();
			setRemainingTime(`-${formatTime(remaining)}`);
		};

		waveformRef.current.on('audioprocess', updateTime);

		waveformRef.current.on('seek', updateTime);

		waveformRef.current.on('finish', () => {
			setIsPlaying(false);
		});

		waveformRef.current.on('error', error => {
			console.error(error);
		});

		waveformRef.current.on('destroy', () => {
			track.pause();
			track.currentTime = 0;
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fileUrl]);

	useEffect(() => {
		return () => {
			if (waveformRef.current) {
				waveformRef.current.destroy();
			}
		};
	}, []);

	const handlePlay = () => {
		setIsPlaying(prevPlaying => !prevPlaying);
		waveformRef.current.playPause();
	};

	const debouncedSetSessionVolume = useMemo(
		() =>
			_.debounce(volume => {
				dispatch(setVolumeAction(volume));
			}, 1000),
		[dispatch]
	);

	const handleVolumeChange = useCallback(
		values => {
			setVolume(values);
			waveformRef.current.setVolume(values[0] / 100);

			debouncedSetSessionVolume(values[0]);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[waveformRef]
	);

	return (
		<div
			style={{
				backgroundColor: 'white',
				width: '70vw',
				borderRadius: 5,
				position: 'relative',
			}}
			className={className}
		>
			<div className='d-flex justify-content-center align-items-center p-4'>
				<PlayButton
					isPlaying={isPlaying}
					onClick={handlePlay}
					isLoading={isLoading}
					className='mx-2'
				/>

				{/* <VolumeSlider values={volume} onChange={handleVolumeChange} /> */}
				<div className='mx-2 volume-container'>
					<Volume
						values={volume}
						onChange={handleVolumeChange}
						isDisabled={isLoading}
					/>
				</div>
				<div className='d-flex justify-content-end align-items-center'>
					<div className='audio-preview-time'>{currentTime}</div>
				</div>

				<div style={{ width: '80vw' }} id='audio-preview-waveform' />
				<audio id='audio-preview-track' src={fileUrl} />
				<div
					xs='auto'
					className='d-flex justify-content-start align-items-center'
				>
					<div className='audio-preview-time'>{remainingTime}</div>
				</div>
			</div>
			{isLoading && (
				<progress
					max={100}
					value={loadingProgress}
					className='w-100 progress-light'
					style={{ position: 'absolute' }}
				/>
			)}
		</div>
	);
};

const Volume = ({
	values,
	onChange,
	min = 0,
	max = 100,
	isDisabled = false,
}) => {
	const [showSlider, setShowSlider] = useState(false);

	const volumeBeforeMute = useRef(values[0]);

	const handleMouseEnter = () => {
		if (isDisabled) return;

		setShowSlider(true);
	};

	const handleMouseLeave = () => {
		if (isDisabled) return;

		setShowSlider(false);
	};

	const handleMute = () => {
		if (values[0] === 0) {
			onChange([volumeBeforeMute.current]);
		} else {
			volumeBeforeMute.current = values[0];
			onChange([0]);
		}
	};

	return (
		<div
			style={{ position: 'relative' }}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<i
				className={`fas ${
					values[0] === 0 ? 'fa-volume-mute' : 'fa-volume-up'
				} p-2 volume-button ${isDisabled ? 'disabled' : ''}`}
				onClick={handleMute}
				style={{
					fontSize: '1.5rem',
					width: '2.5rem',
				}}
			/>

			<VolumeSlider
				values={values}
				onChange={onChange}
				min={min}
				max={max}
				containerStyle={{
					position: 'absolute',
					left: 0,
					right: 0,
					marginLeft: 'auto',
					marginRight: 'auto',
					display: 'flex',
					justifyContent: 'center',
					flexWrap: 'wrap',
					height: showSlider ? '15vh' : 0,
					opacity: showSlider ? 1 : 0,
					transition: 'height 0.5s ease, opacity 0.5s ease',
					backgroundColor: 'white',
					padding: '1rem 0',
					boxShadow: '0 0 0.5rem rgba(0, 0, 0, 0.5)',
					borderRadius: 5,
					bottom: '2.5rem',
				}}
			/>
		</div>
	);
};

const VolumeSlider = ({
	values,
	onChange,
	min = 0,
	max = 100,
	trackStyle = {},
	containerStyle = {},
}) => {
	const renderTrack = useCallback(
		({ props, children }) => (
			<div
				onMouseDown={props.onMouseDown}
				onTouchStart={props.onTouchStart}
				style={{
					...props.style,
					height: '100%',
					display: 'flex',
					justifyContent: 'center',
					width: '1rem',
					flexGrow: 1,
					...trackStyle,
				}}
			>
				<div
					ref={props.ref}
					style={{
						width: '0.5rem',
						height: '100%',
						borderRadius: '4px',
						background: getTrackBackground({
							values,
							colors: [theme.primary, '#ccc'],
							min,
							max,
							direction: Direction.Up,
						}),
						alignSelf: 'center',
					}}
				>
					{children}
				</div>
			</div>
		),
		[values, min, max, trackStyle]
	);

	const renderThumb = useCallback(
		({ props, isDragged }) => (
			<div
				{...props}
				style={{
					...props.style,
					height: '1rem',
					width: '1rem',
					borderRadius: '50%',
					backgroundColor: Color(theme.primary).lighten(0.5).hex(),
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					boxShadow: '0px 2px 6px #AAA',
				}}
			></div>
		),
		[]
	);

	return (
		<div style={{ ...containerStyle }}>
			<Range
				direction={Direction.Up}
				values={values}
				min={0}
				max={100}
				step={1}
				onChange={onChange}
				renderTrack={renderTrack}
				renderThumb={renderThumb}
			/>
		</div>
	);
};

const PlayButton = ({
	isPlaying,
	onClick,
	isLoading = false,
	className = '',
}) => {
	return (
		<IconButton
			icon={isPlaying ? 'fas fa-pause' : 'fas fa-play'}
			onClick={onClick}
			tooltipText={isPlaying ? 'Pause' : 'Play'}
			color='white'
			backgroundColor={theme.primary}
			iconSize='1.5rem'
			isDisabled={isLoading}
			style={{
				width: '3.5rem',
				height: '3.5rem',
			}}
			className={className}
		/>
	);
};

export default AudioPreview;
