import React, { useState, useEffect } from 'react';
import {
	useNavigate,
	useLocation,
	useNavigationType,
	Location as ReactRouterLocation,
} from 'react-router-dom';
import IconButton from '../IconButton';
import clsx from 'clsx';

const NavigationMenu = ({
	className = '',
	style = {},
}: {
	className?: string;
	style?: React.CSSProperties;
}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const navigationType = useNavigationType();
	const [historyStack, setHistoryStack] = useState<ReactRouterLocation[]>([
		location,
	]);
	const [currentIndex, setCurrentIndex] = useState(0);

	useEffect(() => {
		let newStack = [...historyStack];

		if (navigationType === 'PUSH') {
			newStack = [...newStack.slice(0, currentIndex + 1), location];
			setCurrentIndex(newStack.length - 1);
		} else if (navigationType === 'REPLACE') {
			newStack[currentIndex] = location;
		} else if (navigationType === 'POP') {
			// the initial render is considered a POP for some reason
			// so to avoid setting the currentIndex to -1, we check if the stack is empty
			if (!historyStack.length) {
				return;
			}

			// Assuming the current location is in sync with the stack
			setCurrentIndex(
				historyStack.findIndex(item => item.key === location.key)
			);
		}

		console.debug('newStack', newStack, currentIndex, navigationType, location);

		setHistoryStack(newStack);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, navigationType]);

	const goBack = () => {
		if (currentIndex > 0) {
			navigate(-1);
		}
	};

	const goForward = () => {
		if (currentIndex < historyStack.length - 1) {
			navigate(1);
		}
	};

	return (
		<div className={clsx('d-flex', className)} style={style}>
			<IconButton
				icon='fas fa-chevron-left'
				color='white'
				onClick={goBack}
				isDisabled={currentIndex <= 0}
				tooltipText='Go back'
				hideTooltipOnDisabled
			/>
			<IconButton
				icon='fas fa-chevron-right'
				color='white'
				onClick={goForward}
				isDisabled={currentIndex >= historyStack.length - 1}
				tooltipText='Go forward'
				hideTooltipOnDisabled
				// tooltipOptions={{
				//     showOnDisabled: true
				// }}
			/>
		</div>
	);
};

export default NavigationMenu;
