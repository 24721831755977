import './SongTitle.scss';
import React from 'react';
import { replaceRecordingIdInCurrentUrl } from '../../../helpers/projectTools';
import { useMatch, useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks';

export type SongTitleProps = {
	trackNumber: number;
	recording: Recording;
};

function SongTitle({ trackNumber, recording }: SongTitleProps) {
	const navigate = useNavigate();
	const { currentRecordingId } = useAppSelector(state => state.projects);
	const match = useMatch('/recordings/:recordingId/*');

	const handleClick = () => {
		// dispatch(setCurrentRecordingIdAction(recording.id));
		navigate(replaceRecordingIdInCurrentUrl(recording.id, match));
	};

	return (
		<button
			className={`btn editor-song-title text-left pl-3 
				${
					recording && recording.id === currentRecordingId
						? 'bg-purple-gradient text-white'
						: ''
				}`}
			style={{ color: '#c5c5c5' }}
			onClick={handleClick}
		>
			<span className='song-title__track-number mr-3'>{trackNumber}</span>
			{`${recording && recording.title}`}
		</button>
	);
}

export default SongTitle;
