import '../../Projects/ProjectsTable/ProjectsTable.scss';
import React from 'react';
import IconButton from '../../../layout/IconButton';
import theme from '../../../../theme.module.scss';
import { getAssetPath } from '@/helpers/assetTools';

const reducedProjectsColumns = [
	{
		id: 'releaseType',
		Header: () => <></>,
		Cell: ({ row }) =>
			row.canExpand ? (
				<div className='d-flex justify-content-center align-items-center mr-2'>
					<img
						src={getAssetPath('img/album-black-24-dp.svg')}
						alt='album'
						draggable='false'
					/>
				</div>
			) : (
				<img
					className='mr-2'
					src={getAssetPath('img/music-video-black-24-dp.svg')}
					alt='recording'
					draggable='false'
				/>
			),
	},
	{
		id: 'expander',
		Header: () => <></>,
		// accessor: 'albumId',
		Cell: ({ row }) =>
			// Use the row.canExpand and row.getToggleRowExpandedProps prop getter
			// to build the toggle for expanding a row
			row.canExpand ? (
				<i
					className={`text-purple fas ${
						row.isExpanded ? 'fa-chevron-down' : 'fa-chevron-up'
					}`}
					{...row.getToggleRowExpandedProps()}
				/>
			) : null,
	},
	{
		Header: () => <span className='Project-Name'>Title</span>,
		accessor: 'title',
		Cell: col => <span className='Name1'>{col.value}</span>,
	},
	{
		Header: () => <span className='Project-Name'>Artist Name</span>,
		accessor: 'artist',
		Cell: col => <span className='Name1'>{col.value}</span>,
	},
	{
		Header: () => <></>,
		accessor: 'actions',
		Cell: ({ onProjectSelect, row }) => {
			return (
				<IconButton
					color={theme.primary}
					icon='fas fa-chevron-right'
					onClick={() =>
						onProjectSelect({
							albumId: row.canExpand ? row.original.id : null,
							recordingId: !row.canExpand ? row.original.id : null,
						})
					}
					tooltipText='View Project Files'
					outerStyle={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				/>
			);
		},
	},
];

export default reducedProjectsColumns;
