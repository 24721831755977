import React, {
	Context,
	forwardRef,
	useCallback,
	useContext,
	useMemo,
} from 'react';
import {
	getUploadingVersionsForFile,
	isAudioFile,
	isMediaFile,
} from '../../../../../../helpers/fileTools';
import FileUploadItemProgress from '../../../../../layout/FileUploadMenu/FileUploadItem/FileUploadItemProgress';
import { setPlayerPlayingAction } from '../../../../../../store/player/actions';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { getPlaylistUploadsById } from '../../../../../../store/files/selectors';
import { getCurrentPlaylist } from '../../../../../../store/playlists/selectors';
import { SelectedPlaylistContext } from '../../../../../../context/SelectedPlaylistContext';
import NowPlayingIcon from '../../../../../layout/NowPlayingIcon';
import FileThumbnail from '../../../../ProjectFiles/FileThumbnail';
import { Tooltip } from 'primereact/tooltip';
import { ProgressBar } from 'primereact/progressbar';
import PlaylistFileProjectInfo from '../PlaylistFileProjectInfo';
import { showModalAction } from '../../../../../../store/modal/actions';
import {
	EDIT_PLAYLIST_FILE_MODAL,
	FILE_VERSION_LIST_MODAL,
} from '../../../../../../constants/modalTypes';
import theme from '@/theme.module.scss';
import IconButton from '../../../../../layout/IconButton';
import PillButton from '../../../../../layout/PillButton';
import Chip from '../../../../../layout/Chip';
import FileTagChip from '../../../../ProjectFiles/FileTagChip';
import {
	createDndItemId,
	getItemsInGroup,
	rowClickHandler,
} from '../utilities';

type DndPlaylistFileTableRowProps = {
	file: PlaylistFileMetadata;
	rowIndex?: number;
	isDragging?: boolean;
	clone?: boolean; // used for component to be able to tell if it's a clone, and render differently in that case
	[key: string]: any;
};

const DndPlaylistFileTableRow = forwardRef<
	HTMLTableRowElement,
	DndPlaylistFileTableRowProps
>(({ file, rowIndex, isDragging = false, clone = false, ...props }, ref) => {
	const currentPlaylist = useAppSelector(getCurrentPlaylist);

	const {
		hoverRowId,
		setHoverRowId,
		currentTrackId,
		handleSelectTrack,
		uploadingVersionsProgressByFileId,
		editableFilesById,
		contextMenuRef,
		setSelectedItemIds,
		selectedItemIds,
		fileIdToGroupIdIndex,
		groupIdToParentGroupIdIndex,
		groupIdToGroupContentIndex,
		selectionAnchorPoint,
		playlistFiles,
		sortedFolders,
		setSelectionAnchorPoint,
		searchTerm,
	} = useContext<SelectedPlaylistContextType>(
		SelectedPlaylistContext as Context<SelectedPlaylistContextType>
	);

	const itemId = useMemo(() => createDndItemId(file), [file]);

	const isSelected = useMemo(
		() => selectedItemIds?.includes(itemId),
		[selectedItemIds, itemId]
	);

	const parentGroupId = useMemo(
		() => fileIdToGroupIdIndex[file.id],
		[file, fileIdToGroupIdIndex]
	);

	const { folders: foldersInParentGroup } = useMemo(
		() =>
			getItemsInGroup({
				playlistFiles,
				sortedFolders,
				parentGroupId,
				groupIdToGroupContentIndex,
				fileIdToGroupIdIndex,
			}),
		[
			fileIdToGroupIdIndex,
			groupIdToGroupContentIndex,
			parentGroupId,
			playlistFiles,
			sortedFolders,
		]
	);

	const { playing } = useAppSelector(state => state.player);
	const playlistUploads = useAppSelector(state =>
		getPlaylistUploadsById(state, { playlistId: currentPlaylist?.id! })
	);
	const dispatch = useAppDispatch();
	// todo: fix with arbitrarily deep nesting
	const filePlaylistIndex =
		rowIndex != null ? rowIndex + 1 - foldersInParentGroup.length : null;

	const renderRowNumber = useCallback(() => {
		if (isAudioFile(file.filename)) {
			return (
				<div className='playlist-file-table__playlist-track-number'>
					{currentTrackId === file.id && playing ? (
						hoverRowId === itemId ? (
							<i
								className='fas fa-pause mr-2'
								onClick={() => dispatch(setPlayerPlayingAction(false))}
							/>
						) : (
							<NowPlayingIcon />
						)
					) : hoverRowId === itemId ? (
						<i
							className='fas fa-play mr-2'
							onClick={() => handleSelectTrack(file)}
						></i>
					) : (
						<span
							className={clsx({
								'playlist-file-table__active-playlist-track':
									currentTrackId === file.id,
							})}
						>
							{filePlaylistIndex ?? ''}
						</span>
					)}
				</div>
			);
		} else {
			return (
				<div className='playlist-file-table__playlist-track-number'>
					<span
						className={clsx({
							'playlist-file-table__active-playlist-track':
								currentTrackId === file.id,
						})}
					>
						{filePlaylistIndex ?? ''}
					</span>
				</div>
			);
		}
	}, [
		currentTrackId,
		dispatch,
		handleSelectTrack,
		hoverRowId,
		playing,
		file,
		filePlaylistIndex,
		itemId,
	]);

	const handleEditFile = useCallback(() => {
		dispatch(
			showModalAction(EDIT_PLAYLIST_FILE_MODAL, {
				playlistId: currentPlaylist?.playlist?.id,
				fileId: file.id,
				size: 'md',
			})
		);
	}, [currentPlaylist, dispatch, file]);

	const renderProjectInfo = useCallback(() => {
		const uploadingVersions = getUploadingVersionsForFile(
			file.id,
			playlistUploads
		);

		return (
			<div
				className='w-100 align-items-center'
				style={{
					display: 'grid',
					gridTemplateColumns: '2.5rem 1fr',
				}}
			>
				{uploadingVersions.length === 1 ? (
					<FileUploadItemProgress
						upload={uploadingVersions[0]}
						progress={uploadingVersionsProgressByFileId[file.id]}
						progressBarWidth='2.4rem'
					/>
				) : uploadingVersions.length > 1 ? (
					<>
						<Tooltip target={`#u${file.id.toString()}`}>
							{uploadingVersions.map((upload, index) => (
								<>
									<div
										key={upload.id}
										className='mx-2'
										style={{
											fontSize: '0.85rem',
										}}
									>
										<div
											style={{
												fontWeight: 600,
											}}
										>
											{upload.metadata.filename}
										</div>
										<div className='d-flex align-items-center'>
											<ProgressBar
												color='grey'
												value={upload.progress}
												showValue={false}
												className='mt-1 mr-1'
												style={{
													height: '0.25rem',
													width: '70%',
												}}
											/>
											{upload.progress?.toFixed(1)}%
										</div>
									</div>
									{index < uploadingVersions.length - 1 ? (
										<hr
											className='my-1'
											style={{
												borderColor: 'white',
												opacity: 0.5,
											}}
										/>
									) : null}
								</>
							))}
						</Tooltip>

						<FileUploadItemProgress
							id={`u${file.id.toString()}`}
							progress={0}
							progressBarWidth='2.4rem'
							content={
								<i
									className='fas fa-ellipsis-h'
									style={{
										color: theme.primary,
									}}
								/>
							}
						/>
					</>
				) : (
					<FileThumbnail
						className='mr-2'
						file={file}
						forceAudio={isAudioFile(file.filename)}
						onHoverClick={handleEditFile}
						forceNonAudio={!isMediaFile(file.filename)}
					/>
				)}
				<PlaylistFileProjectInfo
					file={file}
					className='flex-fill'
					isSelected={currentTrackId === file.id}
				/>
			</div>
		);
	}, [
		handleEditFile,
		currentTrackId,
		playlistUploads,
		uploadingVersionsProgressByFileId,
		file,
	]);

	const renderNote = useCallback(
		() =>
			file?.displayName ? (
				<div className='d-flex align-items-center'>
					<span className='playlist-file-table__table-cell-text__note'>
						{' '}
						{file?.displayName ? file?.displayName : '-'}
					</span>
					{editableFilesById[file.id] && (
						<IconButton
							icon='fas fa-edit'
							onClick={handleEditFile}
							color={theme.primary}
							tooltipText='Edit Note'
						/>
					)}
				</div>
			) : editableFilesById[file.id] ? (
				<PillButton
					style={{
						fontSize: '0.8rem',
					}}
					label='Add Note'
					theme='solid-light'
					// leftIcon='fas fa-sticky-note'
					onClick={handleEditFile}
					className='d-flex align-items-center'
				/>
			) : (
				<div
					style={{
						fontSize: '0.8rem',
					}}
					className='text-muted'
				>
					No note
				</div>
			),
		[editableFilesById, handleEditFile, file]
	);

	const renderVersionAndTag = useCallback(
		() => (
			<div
				className='d-flex flex-wrap'
				style={{
					gap: '0.5rem',
				}}
			>
				<>
					<Chip
						variant='secondary'
						label={`V${file.activeVersionNo}`}
						isReadOnly={!editableFilesById[file.id]}
						onClick={() =>
							dispatch(
								showModalAction(FILE_VERSION_LIST_MODAL, {
									fileId: file.id,
									playlistId: currentPlaylist?.id,
									size: 'xl',
								})
							)
						}
					/>
					<FileTagChip
						tag={file.label}
						fileId={file.id}
						playlistId={currentPlaylist?.id}
						isReadOnly={!editableFilesById[file.id]}
					/>
				</>
			</div>
		),
		[editableFilesById, currentPlaylist, dispatch, file]
	);

	// const addFileIdToSelected = useCallback(() => {
	// 	setSelectedFileIds(prev => Array.from(new Set([...(prev ?? []), file.id])));
	// }, [file, setSelectedFileIds]);

	return clone ? (
		<div className='playlist-file-table__file-row--drag-clone'>
			<PlaylistFileProjectInfo file={file} hideActions />
		</div>
	) : (
		<tr
			ref={ref}
			{...props}
			onContextMenu={e => {
				// a right click is meant to select the file if it's not already selected
				if (!selectedItemIds?.includes(itemId)) {
					setSelectedItemIds([itemId]);
				}

				// if it was already selected, then it could be part of a multi-select, so we don't want to change the selected fileIds
				contextMenuRef.current?.show(e);
				// setSelectedFileId((e.value as PlaylistTableFile).id);
			}}
			onMouseEnter={() => setHoverRowId(itemId)}
			onMouseLeave={() => setHoverRowId(null)}
			onDoubleClick={() => {
				setSelectedItemIds([itemId]);
				handleSelectTrack(file, true);
			}}
			onClick={e =>
				rowClickHandler(e, {
					setSelectedItemIds,
					rowItemId: itemId,
					playlistFiles,
					sortedFolders,
					groupIdToParentGroupIdIndex,
					groupIdToGroupContentIndex,
					fileIdToGroupIdIndex,
					rowIndex: rowIndex!,
					selectionAnchorPoint,
					setSelectionAnchorPoint,
				})
			}
			className={clsx(props.className, 'playlist-file-table__file-row', {
				'playlist-file-table__active-row': isSelected,
				'playlist-file-table__grouped-file-row': !!parentGroupId && !searchTerm, // if a search term is present, all files are shown as ungrouped
				'playlist-file-table__row--dragging': isDragging,
			})}
		>
			{/* row number */}
			<td>{renderRowNumber()}</td>
			{/* project & artist */}
			<td>{renderProjectInfo()}</td>
			{/* note */}
			<td>{renderNote()}</td>
			{/* version & tag */}
			<td>{renderVersionAndTag()}</td>
			{/* ellipsis menu */}
			<td>
				<IconButton
					icon='fas fa-ellipsis-v'
					onClick={e => {
						setSelectedItemIds([itemId]);
						contextMenuRef.current?.show(e);
					}}
					color={theme.primary}
					tooltipText='More Options'
				/>
			</td>
			{/* DndPlaylistFileTableRow {file.id} */}
		</tr>
	);
});

export default DndPlaylistFileTableRow;
