import Button from '../../../layout/Button';
import { useState, useEffect } from 'react';
import ParticipantModal from '../ParticipantModal';
import { Card, Col, Row } from 'react-bootstrap';
import CloudProfileSelect from '../../../form/CloudProfileSelect';
import { getCloudProfileById } from '../../../../api/services/cloudProfilesService';
import ImportPersonalProfilesModal from '../ImportPersonalProfilesModal';
import SelectCreditedNameModal from '../SelectCreditedNameModal/SelectCreditedNameModal';
import {
	showAlertToast,
	hideAlertToast,
} from '../../../../store/alertToast/actions';
import { useFormikContext } from 'formik';
import { useNavigate } from 'react-router';
import './AddParticipant.scss';
import React from 'react';
import ROUTES from '../../../../router/routes';
import {
	fetchCloudProfileSuggestions,
	translateApiFullPersonalProfileToLocal,
	translateCloudProfileToLocal,
} from '../../../../helpers/profileTools';
import { getLocalProfileById } from '../../../../api/services/localProfilesService';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { convertOtherAliasesToArray } from '../../../../helpers/participantTools';
import { ErrorLevel } from '../../../../helpers/errors';

export type AddParticipantProps = {
	setAnimate: React.Dispatch<React.SetStateAction<boolean>>;
	animate: boolean;
};

function AddParticipant({ setAnimate, animate }: AddParticipantProps) {
	const [showParticipantModal, setShowParticipantModal] = useState(false);
	const { profiles: savedProfiles } = useAppSelector(state => state.profiles);
	const toggleShowParticipantModal = () => setShowParticipantModal(p => !p);
	const [participant, setParticipant] = useState<Participant | null>(null);
	const [importProfile, setImportProfile] = useState<LocalProfile | null>(null);
	const [selectedProfile, setSelectedProfile] = useState(null); // ! SETTER ONLY SETS NULL UNLESS FOR ADD MANUALLY, WHICH IS A STRING
	const [showImportPersonalProfileModal, setShowImportPersonalProfileModal] =
		useState(false);
	const toggleShowProfileModal = () =>
		setShowImportPersonalProfileModal(p => !p);
	const controller = new AbortController();
	const [initialCreditedName, setInitialCreditedName] = useState<string | null>(
		null
	);
	const [isLoading, setIsLoading] = useState(false);
	const [isDisabled, setIsDisabled] = useState(false);
	const dispatch = useAppDispatch();
	const [showCreditedNameModal, setShowCreditedNameModal] = useState(false);
	const formik = useFormikContext<RecordingContent>();
	const navigate = useNavigate();

	const fetchSuggestions = async (username: string) => {
		if (!savedProfiles) {
			throw new Error('No saved profiles');
		}

		setIsLoading(true);
		try {
			const suggestions = await fetchCloudProfileSuggestions(
				username,
				savedProfiles,
				controller,
				formik?.values?.participants
			);

			return suggestions;
		} catch (e) {
			console.error('Something happened in fetchSuggestions: ', e);
			return [];
		} finally {
			setIsLoading(false);
		}
	};

	const handleChange = async (selectedProfileOption: LocalProfile) => {
		try {
			setIsDisabled(true);
			// setSelectedProfile({ name: 'Loading...', disabled: true });

			console.log('DATA IN ADD PARTICIPANT: ', selectedProfileOption);

			const isCloudProfile = selectedProfileOption?.isCreatorID;

			let selectedProfile = selectedProfileOption;

			if (isCloudProfile) {
				// NOT saved profile, need to fetch data from the cloud
				const cloudProfile = await getCloudProfileById(
					selectedProfileOption.id
				);

				// adapt cloud profile to participant structure
				selectedProfile = translateCloudProfileToLocal(cloudProfile);
			} else {
				// fetch personal profile if not cached
				if (!selectedProfile.profile) {
					const res = await getLocalProfileById(selectedProfile.id);

					// adapt personal profile to participant structure
					selectedProfile = translateApiFullPersonalProfileToLocal(
						res.data,
						selectedProfile.id
					);
				}
			}

			// check for duplicate when user submits participant modal
			setImportProfile(selectedProfile);
			setSelectedProfile(null);
			setInitialCreditedName(null);
			setIsDisabled(false);

			if (
				isCloudProfile &&
				!selectedProfile.creditedName &&
				[
					...convertOtherAliasesToArray(selectedProfile.profile!.otherAliases),
					selectedProfile.profile?.legalName,
				].length > 1
			) {
				//If it has more than 1 alias, show modal for selecting the alias
				setShowCreditedNameModal(true);
				return;
			}

			toggleShowParticipantModal();
		} catch (error) {
			dispatch(
				showAlertToast(
					'Whoops, probabably a good idea to review what you just entered to make sure it properly saved.',
					ErrorLevel.ERROR
				)
			);
			setTimeout(() => dispatch(hideAlertToast()), 1500);

			setSelectedProfile(null);
			setInitialCreditedName(null);
			setIsDisabled(false);
			console.log(error);
		}
	};

	const addManually = () => {
		toggleShowParticipantModal();
	};

	const handleNext = () => {
		formik.submitForm();
		navigate(`../${ROUTES.EditLyrics.relativePath}`);
	};

	useEffect(() => {
		if (animate && setAnimate) setTimeout(() => setAnimate(false), 3000);
	}, [animate, setAnimate]);

	return (
		<div className={`blink-container ${animate ? 'blink-animation' : ''}`}>
			<Card style={{ marginBottom: 0 }}>
				<Card.Body>
					{/* Added id to use on scrolling functionality on the "Credits" screen */}
					<h2 id='Credits_AddCreditCard'>ADD CREDIT</h2>
					<Row>
						<Col xs={6}>
							<CloudProfileSelect
								isDisabled={isDisabled}
								name="Participant's Name"
								onInputChange={async input => {
									//TODO: DEBOUNCE THIS LOGIC
									if (input) setInitialCreditedName(input);
									setIsLoading(true);

									const suggestions = await fetchSuggestions(input);
									// setCloudProfiles(suggestions);
									setIsLoading(false);

									return suggestions;
								}}
								onChange={handleChange}
								addManually={addManually}
								value={selectedProfile}
								// options={profileOptions}
								onBlur={() => setIsLoading(false)}
								isLoading={isLoading}
							/>
						</Col>
					</Row>
				</Card.Body>

				<Row className='p-3'>
					<Col
						xs={{ span: 5, offset: 7 }}
						className='d-flex justify-content-end'
					>
						{/*
						<Button
							label='Cancel'
							className='ml-3'
							onClick={() => setShowAddParticipantBox(false)}
						/>
						*/}
						<Button
							label='Select From Saved Profiles'
							className='ml-3'
							onClick={toggleShowProfileModal}
						/>
						<Button
							label='Add Manually'
							className='ml-3'
							onClick={addManually}
						/>
						<Button
							rightIcon='fas fa-chevron-right'
							className='ml-3'
							label='Next'
							theme='dark'
							onClick={handleNext}
						/>
					</Col>
				</Row>
			</Card>

			<ParticipantModal
				participant={participant}
				setParticipant={setParticipant}
				show={showParticipantModal}
				importProfile={importProfile}
				setImportProfile={setImportProfile}
				toggleShow={toggleShowParticipantModal}
				initialCreditedName={initialCreditedName}
			/>

			<ImportPersonalProfilesModal
				show={showImportPersonalProfileModal}
				toggleShow={toggleShowProfileModal}
			/>

			<SelectCreditedNameModal
				show={showCreditedNameModal}
				toggleShow={() => setShowCreditedNameModal(false)}
				setInitialCreditedName={setInitialCreditedName}
				toggleParticipantModal={toggleShowParticipantModal}
				profile={importProfile}
			/>
		</div>
	);
}

export default AddParticipant;
