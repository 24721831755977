import { AnyAction } from 'redux';
import { SET_HELP_ARTICLES } from '../actionTypes';

type HelpState = {
	helpArticles: PortalHelpArticle[] | null;
};

const initialState: HelpState = {
	helpArticles: null,
};

const reducer = (state = initialState, action: AnyAction): HelpState => {
	switch (action.type) {
		case SET_HELP_ARTICLES:
			return {
				...state,
				helpArticles: action.helpArticles,
			};
		default:
			return state;
	}
};

export default reducer;
