import { SHARE_MODAL } from '../constants/modalTypes';
import { showModalAction } from '../store/modal/actions';

const openShareModal = (cell, dispatch) => {
	const recordingId = cell.row.original.id;
	const albumId = cell.row.canExpand && cell.row.original.id;

	dispatch(
		showModalAction(SHARE_MODAL, {
			size: 'lg',
			albumId,
			recordingId,
		})
	);
};

export default openShareModal;
