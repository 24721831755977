import React, { forwardRef, useCallback } from 'react';
import { isAudioFile, isMediaFile } from '../../../../../../helpers/fileTools';
import FileUploadItemProgress from '../../../../../layout/FileUploadMenu/FileUploadItem/FileUploadItemProgress';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { getPlaylistUploadsById } from '../../../../../../store/files/selectors';
import { getCurrentPlaylist } from '../../../../../../store/playlists/selectors';
import FileThumbnail from '../../../../ProjectFiles/FileThumbnail';
import PlaylistFileProjectInfo from '../PlaylistFileProjectInfo';
import theme from '@/theme.module.scss';
import IconButton from '../../../../../layout/IconButton';
import { abortFileUploadAction } from '../../../../../../store/files/actions';

type PlaylistFileTableUploadProps = {
	upload: UploadingPlaylistFile;
	[key: string]: any;
};

const PlaylistFileTableUpload = forwardRef<
	HTMLTableRowElement,
	PlaylistFileTableUploadProps
>(({ upload, ...props }, ref) => {
	const currentPlaylist = useAppSelector(getCurrentPlaylist);

	const playlistUploads = useAppSelector(state =>
		getPlaylistUploadsById(state, { playlistId: currentPlaylist?.id! })
	);
	const dispatch = useAppDispatch();

	const renderUploadProgress = useCallback(() => {
		if (!playlistUploads[upload.id]) return <></>;

		return (
			<FileUploadItemProgress
				upload={playlistUploads[upload.id]}
				progressBarWidth='2.4rem'
			/>
		);
	}, [playlistUploads, upload]);

	const renderProjectInfo = useCallback(() => {
		return (
			<div
				className='w-100 align-items-center'
				style={{
					display: 'grid',
					gridTemplateColumns: '2.5rem 1fr',
				}}
			>
				<FileThumbnail
					className='mr-2'
					file={upload}
					forceAudio={isAudioFile(upload.filename)}
					forceNonAudio={!isMediaFile(upload.filename)}
				/>

				<PlaylistFileProjectInfo file={upload} className='flex-fill' />
			</div>
		);
	}, [upload]);

	const renderNote = useCallback(
		() =>
			upload?.displayName ? (
				<div className='d-flex align-items-center'>
					<span className='playlist-file-table__table-cell-text__note'>
						{' '}
						{upload?.displayName ? upload?.displayName : '-'}
					</span>
				</div>
			) : (
				<div
					style={{
						fontSize: '0.8rem',
					}}
					className='text-muted'
				>
					No note
				</div>
			),
		[upload]
	);

	return (
		<tr
			ref={ref}
			{...props}
			// onMouseEnter={() => setHoverRowId(upload.id)}
			// onMouseLeave={() => setHoverRowId(null)}
			className={clsx(props.className, 'playlist-file-table__file-row')}
		>
			{/* row number */}
			<td>{renderUploadProgress()}</td>
			{/* project & artist */}
			<td>{renderProjectInfo()}</td>
			{/* note */}
			<td>{renderNote()}</td>
			{/* extra space to align with file rows */}
			<td></td>
			{/* ellipsis menu */}
			<td>
				<IconButton
					icon='fas fa-times'
					onClick={() => dispatch(abortFileUploadAction(upload.id))}
					color={theme.error}
					tooltipText='Cancel Upload'
				/>
			</td>
		</tr>
	);
});

export default PlaylistFileTableUpload;
