import { AnyAction } from 'redux';
import { SET_SHOW_FRESH_INSTALL_INFO } from '../actionTypes';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { persistReducer } from 'redux-persist';

export type LocalDataState = {
	showFreshInstallInfo: boolean;
};

const initialState: LocalDataState = {
	showFreshInstallInfo: true, // modals fired on first load from Sidebar component
};

const reducer = (state = initialState, action: AnyAction): LocalDataState => {
	switch (action.type) {
		case SET_SHOW_FRESH_INSTALL_INFO:
			return {
				...state,
				showFreshInstallInfo: action.show,
			};

		default:
			return state;
	}
};

const persistConfig = {
	key: 'localData',
	storage,
	stateReconciler: autoMergeLevel2,
};

export default persistReducer<LocalDataState>(persistConfig, reducer);
