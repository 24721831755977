import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
	initializeCompleteParticipantsFormAction,
	nextExportStepAction,
	previousExportStepAction,
	setUnionExportParticipantsAction,
} from '../../../../../store/exports/actions';
import Button from '../../../../layout/Button';
import SearchTable from '../../../../layout/SearchTable';
import theme from '../../../../../theme.module.scss';
import exportTypes from '../../../../../constants/exportType';
import nonMusicianRoleCategories from '../../../../../constants/nonMusicianRoleCategories.json';
import './SelectParticipantsStep.scss';
import b4Types from '../../../../../constants/unionForm/b4Types';
import React from 'react';
import { isMusicianEqual } from '../../../../../helpers/unionFormTools';
import _ from 'lodash';

const participantsColumns = [
	{
		Header: () => <span className='Project-Name'>Participant Name</span>,
		id: 'legalName',
		Cell: col => {
			return (
				<span className='Name1'>
					{col.row.original.legalName
						? col.row.original.legalName
						: col.row.original.creditedName}
				</span>
			);
		},
	},
	{
		Header: () => <span className='Project-Name'>Home Address</span>,
		accessor: 'address1',
		Cell: col => <span className='Name1'>{col.value}</span>,
	},
	{
		Header: () => <span className='Project-Name'>Listed Studios</span>,
		accessor: 'listedStudiosStrings',
		Cell: col =>
			col.value.length > 0 ? (
				col.value.map((studio, index) => (
					<p className='Name1 mb-0' key={index}>
						{studio}
					</p>
				))
			) : (
				<span
					className='Name1'
					style={{ color: theme.disclaimerTextColor, fontStyle: 'italic' }}
				>
					No Studios Listed
				</span>
			),
	},
	{
		Header: () => (
			<span className='Project-Name'>Listed in Selected Studio</span>
		),
		accessor: 'isListedInSelectedStudio',
		Cell: col =>
			col.value ? (
				<i className='fas fa-check'></i>
			) : (
				<i className='fas fa-times'></i>
			),
	},
];

const SelectParticipantsStep = () => {
	/*
	 * Redux Hooks
	 */
	const { exportType } = useSelector(state => state.exports);
	const { selectedParticipants, selectedRecordingRows, studioForm, b4Type } =
		useSelector(state => state.exports.unionForm);
	const { recordingsById } = useSelector(state => state.projects);
	const dispatch = useDispatch();

	/*
	 * React Hooks
	 */
	const [showErrorMessage, setShowErrorMessage] = useState(false);
	const columns = useMemo(() => participantsColumns, []);
	const [participants, setParticipants] = useState([]);

	const selectedRecordingIds = useMemo(
		() => selectedRecordingRows.map(row => row.recordingId),
		[selectedRecordingRows]
	);

	const selectedStudioId = useMemo(
		() => studioForm.studio?.value,
		[studioForm]
	);

	const firstRender = useRef(true);

	useEffect(() => {
		// this should only be updated when when the component is first mounted
		// if (!firstRender.current) {
		// 	return;
		// }

		firstRender.current = false;

		const participantsById = {};

		// first add any previously selected participants
		// this patches unexpected bugs in case the participant's roles are edited and the participant's no longer a musician
		// ! deprecated fix, it's buggy and really not necessary, we should just discard the selected participants if the user changes the recording
		// selectedParticipants.forEach(participant => {
		// 	participantsById[participant.id] = participant; // selectedParticipants contain original rowId
		// });

		// then add all musicians from the selected recordings
		selectedRecordingIds.forEach(recordingId => {
			const recording = recordingsById[recordingId].recording;

			recording.participants
				.filter(p =>
					p.roles.some(role =>
						b4Type?.id === b4Types.PRODUCER_B4.id
							? role.detail === 'Producer'
							: !nonMusicianRoleCategories.includes(role.category)
					)
				)
				.forEach((participant, index) => {
					let listedStudiosById = {};

					// initialize studiosById with existing participants studios (in case participant is multiple recordings)
					if (participantsById[participant.id]) {
						listedStudiosById = {
							...participantsById[participant.id].listedStudiosById,
						};
					}

					// and merge with new participant studios
					participant.roles
						.map(role => role.studio)
						.filter(studio => !!studio)
						.forEach(studio => {
							listedStudiosById[studio.studioId] = {
								id: studio.studioId,
								name: recording.studios.find(s => s.id === studio.studioId)
									?.name,
							};
						});

					const isListedInSelectedStudio =
						!!listedStudiosById[selectedStudioId];

					participantsById[participant.id] = {
						...participant,
						listedStudiosById,
						isListedInSelectedStudio,
					};
				});
		});

		setParticipants(
			_.uniqWith(Object.values(participantsById), isMusicianEqual)
		);
	}, [
		selectedRecordingIds,
		selectedStudioId,
		recordingsById,
		selectedParticipants,
		b4Type,
	]);

	const participantRows = useMemo(() => {
		console.log('participants', participants);
		return participants.map((participant, index) => {
			// if participant is already formatted, return it
			if (
				participant &&
				!participant.listedStudiosById &&
				participant.listedStudios &&
				participant.listedStudiosStrings
			) {
				return {
					...participant,
					rowId: index, // necessary for preserving selection
				};
			}

			const listedStudios = Object.values(participant.listedStudiosById);

			const participantRow = {
				...participant,
				listedStudios,
				listedStudiosStrings: listedStudios.map(studio => studio.name),
				rowId: index, // necessary for preserving selection
			};

			delete participantRow.listedStudiosById;

			return participantRow;
		});
	}, [participants]);

	const initialSelectedRowIds = useMemo(() => {
		const rowIds = {};

		selectedParticipants
			.map(sp => participantRows.find(p => sp.id === p.id)) // find their respective rowIds
			.filter(Boolean) // null safety
			.forEach(participant => {
				rowIds[participant.rowId.toString()] = true;
			});

		return rowIds;
	}, [selectedParticipants, participantRows]);

	const errorMessage = useMemo(() => {
		if (selectedParticipants.length === 0) {
			return 'Please select at least one participant';
		}

		if (exportType === exportTypes.AFM_B4 && selectedParticipants.length > 30) {
			return 'Please select no more than 30 participants';
		}

		setShowErrorMessage(false);
		return '';
	}, [selectedParticipants, exportType]);

	/*
	 * Callbacks
	 */
	const navigateToPrevStep = () => dispatch(previousExportStepAction());

	const navigateToNextStep = useCallback(() => {
		if (selectedParticipants.length === 0) {
			setShowErrorMessage(true);
			return;
		}

		dispatch(initializeCompleteParticipantsFormAction());
		dispatch(nextExportStepAction());
	}, [selectedParticipants, dispatch]);

	const onSelect = useCallback(
		participants => {
			dispatch(setUnionExportParticipantsAction(participants));
		},
		[dispatch]
	);

	const renderNoParticipants = useCallback(() => {
		const participantType =
			b4Type?.id === b4Types.PRODUCER_B4.id ? 'producer' : 'musician';
		const errorMessage =
			selectedRecordingRows.length === 1
				? `No ${participantType}s found for this recording. Please make sure the recording has at least one ${participantType}.`
				: `No ${participantType}s found for selected recordings. Please make sure the selected recordings have at least one ${participantType}.`;
		return (
			<div className='no-participants-found-union-form'>
				<i className='fas fa-exclamation-triangle'></i>
				<p>{errorMessage}</p>
			</div>
		);
	}, [selectedRecordingRows, b4Type]);

	return (
		<>
			<Card>
				<Card.Body>
					<h2>SELECT MUSICIANS</h2>

					{showErrorMessage && errorMessage && (
						<p className='body-text' style={{ color: theme.error }}>
							{errorMessage}
						</p>
					)}

					<div className='mt-2'>
						{participants.length > 0 ? (
							<>
								<div className='d-flex align-items-center mb-2'>
									<i
										className='fas fa-exclamation-circle mr-2'
										style={{ fontSize: '1.1rem' }}
									></i>
									<p className='fw-500 mb-0' style={{ fontSize: '0.9rem' }}>
										Musicians with the same name will be detected as a single
										musician unless SSN is different
									</p>
								</div>
								<SearchTable
									columns={columns}
									data={participantRows}
									rowMapper={row => ({ ...row.original, rowId: row.id })}
									onSelect={onSelect}
									initialSelectedRowIds={initialSelectedRowIds}
									searchBarPlaceholder='Search Musicians by Name'
								/>
							</>
						) : (
							renderNoParticipants()
						)}
					</div>
				</Card.Body>
			</Card>

			<Row>
				<Col className='d-flex justify-content-end align-items-center'>
					<Button label='Back' className='mr-3' onClick={navigateToPrevStep} />
					<Button
						label='Next'
						theme='dark'
						onClick={navigateToNextStep}
						isDisabled={selectedParticipants.length === 0}
					/>
				</Col>
			</Row>
		</>
	);
};

export default SelectParticipantsStep;
