import React from 'react';

const Sort = ({ children, compareFn, reverse = false }) => {
	if (!compareFn) {
		return children;
	}

	return React.Children.toArray(children).sort(compareFn(reverse));
};

export default Sort;
