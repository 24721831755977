import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { hideModal, setModalTitle } from '../../../store/modal/actions';
import Button from '../../layout/Button';

const ChangeOwnerLimitModal = ({ message }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setModalTitle('ERROR'));
	}, [dispatch]);

	return (
		<>
			<Modal.Body>
				<div className='d-flex align-items-center justify-content-between px-4'>
					<i
						className='fas fa-exclamation-circle'
						style={{ fontSize: '3rem', color: 'red', flex: 1 }}
					/>
					<p style={{ flex: 5 }} className='fw-500 mb-0'>
						{message ?? (
							<>
								The user you're trying to transfer ownership to does not have
								sufficient resources to own this project. <br /> <br /> Please
								contact them to resolve this issue.
							</>
						)}
					</p>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button label='OK' theme='dark' onClick={() => dispatch(hideModal())} />
			</Modal.Footer>
		</>
	);
};

export default ChangeOwnerLimitModal;
