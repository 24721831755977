import { useMemo, useState } from 'react';
import { Card } from 'react-bootstrap';
import DraggableTable from '../../../layout/DraggableTable';
import ProfileRow from './ProfileRow';
import { useAppSelector } from '../../../../store/hooks';
import ProfileModal from '../ProfileModal/ProfileModal';

export type ProfilesTableProps = {
	editing: boolean;
	setEditing: (editing: boolean) => void;
};

const ProfilesTable = ({ editing, setEditing }: ProfilesTableProps) => {
	const { profiles, searchFilter } = useAppSelector(state => state.profiles);
	const [show, setShow] = useState(false);
	const [profile, setProfile] = useState<LocalProfile | null>(null);

	const toggleShow = () => setShow(p => !p);

	const filteredProfiles = useMemo(
		() =>
			!searchFilter
				? profiles ?? []
				: profiles?.filter(p =>
						p.creditedName
							.toLowerCase()
							.trim()
							.normalize()
							.includes(searchFilter.toLowerCase().trim().normalize())
				  ) ?? [],
		[profiles, searchFilter]
	);

	return (
		<>
			<Card className='p-4'>
				<h2>SAVED PROFILES</h2>

				<DraggableTable
					renderHeaderColumns={() => (
						<>
							<th scope='col'></th>
							<th scope='col'>Name</th>
							<th scope='col'>Roles</th>
							<th scope='col' style={{ textAlign: 'center' }}>
								Publishing Company
							</th>
							<th scope='col' className='col-2'></th>
						</>
					)}
					renderRows={(profiles: LocalProfile[]) =>
						profiles.map((profile, index) => {
							return (
								<ProfileRow
									profile={profile}
									key={profile.id}
									index={index}
									selectProfile={setProfile}
									toggleShowParticipantModal={toggleShow}
									setEditing={setEditing}
								/>
							);
						})
					}
					values={filteredProfiles}
					onDragEnd={(result: any) => {}} // TODO: Implement drag and drop
				/>
			</Card>

			{show && (
				<ProfileModal
					show={show}
					toggleShow={toggleShow}
					profile={profile!}
					editing={editing}
					setProfile={setProfile}
				/>
			)}
		</>
	);
};

export default ProfilesTable;
