import React, { useMemo } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import theme from '../../../../../../theme.module.scss';
import TextField from '../../../../../form/TextField';
import IconButton from '../../../../../layout/IconButton';
import ToggleInput from '../../../../../layout/ToggleInput';
import scaleOptions from '../../../../../../constants/unionForm/scaleOptions.json';
import cartageOptions from '../../../../../../constants/unionForm/cartageOptions.json';
import overtimeOptions from '../../../../../../constants/unionForm/overtimeOptions.json';
import Select from '../../../../../form/Select';
import formatFullAddress from '../../../../../../helpers/formatFullAddress';

const AFMParticipantCard = ({
	participant,
	onAddressEdit,
	onDelete,
	showErrorMessage,
	addressErrorMessage,
	index,
	formik,
}) => {
	const fullAddress = useMemo(() => {
		const { address1, address2, city, state, postalCode, country } =
			participant;

		return formatFullAddress(
			address1,
			address2,
			city,
			state,
			postalCode,
			country
		);
	}, [participant]);

	const invalidAddress = useMemo(() => {
		const { address1, city, state } = participant;

		return !address1 || !city || !state;
	}, [participant]);

	return (
		<div className='complete-participants-row'>
			<Row>
				<Col xs={11} className='d-flex justify-content-start'>
					<div style={{ fontWeight: 700, fontSize: '1rem' }} className='d-flex'>
						<i
							className='fas fa-user d-flex align-items-center mr-2'
							style={{ lineHeight: 0 }}
						></i>
						<div
							className='d-flex align-items-center'
							style={{ lineHeight: 0 }}
						>
							{participant.legalName
								? participant.legalName
								: participant.creditedName}
						</div>
					</div>
				</Col>

				<Col xs={1} className='d-flex justify-content-end'>
					<IconButton icon='fas fa-times' onClick={onDelete} />
				</Col>

				<Col
					xs={12}
					className='d-flex justify-content-start align-items-center'
				>
					<div className='d-flex justify-content-start align-items-center'>
						<div
							className='d-flex justify-content-center align-items-center'
							style={{
								fontSize: '0.85rem',
								fontWeight: 600,
							}}
						>
							<i className='fas fa-home d-flex align-items-center mr-2'></i>
							Home Address:
						</div>
						&nbsp;
						<div
							style={{
								fontWeight: 500,
								fontSize: '0.85rem',
								fontStyle: 'italic',
							}}
						>
							{fullAddress ? (
								fullAddress
							) : (
								<div>
									<Button
										style={{
											fontSize: '0.85rem',
										}}
										variant='link'
										onClick={onAddressEdit}
									>
										Click here to add address
									</Button>
								</div>
							)}
						</div>
						{fullAddress && (
							<IconButton
								icon='fas fa-edit'
								color={theme.darkPurple}
								onClick={onAddressEdit}
							/>
						)}
						{showErrorMessage && invalidAddress && (
							<small style={{ color: theme.error }}>
								{addressErrorMessage}
							</small>
						)}
					</div>
				</Col>
			</Row>

			<hr />

			<Row className='align-items-center'>
				<Col xs={2}>
					<Form.Label className='d-flex justify-content-center mb-0'>
						Session Leader
					</Form.Label>

					<ToggleInput
						className='d-flex justify-content-center'
						checked={formik.values.participants[index].isSessionLeader}
						value={formik.values.participants[index].isSessionLeader}
						onChange={() => {
							formik.setFieldValue('participants', [
								...formik.values.participants.map(p => ({
									...p,
									isSessionLeader:
										p.id === participant.id ? !p.isSessionLeader : false,
								})),
							]);
						}}
					/>

					{formik.values.differentLeaderOnPriorSession && (
						<>
							<Form.Label className='d-flex justify-content-center mb-0'>
								Not On Prior Session
							</Form.Label>

							<ToggleInput
								className='d-flex justify-content-center'
								checked={formik.values.participants[index].notOnPriorSession}
								value={formik.values.participants[index].notOnPriorSession}
								onChange={e => {
									formik.setFieldValue('participants', [
										...formik.values.participants.map(p => ({
											...p,
											notOnPriorSession:
												p.id === participant.id
													? e.target.checked
													: p.notOnPriorSession,
										})),
									]);
								}}
							/>
						</>
					)}
				</Col>

				<Col xs={10} className='h-100'>
					<Row className='justify-content-end align-items-center h-100'>
						<Col
							xs={3}
							className='d-flex align-items-center'
							// style={{ marginTop: '1.45rem' }}
						>
							<TextField
								name={`participants[${index}].socialLastFour`}
								label={
									<div className='d-flex'>
										<span className='d-flex mr-1'>SSN</span>
										<span className='d-flex optional-italic-blue-label'>
											(Full or Last 4 Digits)
										</span>
									</div>
								}
								placeholder='XXXX'
								value={formik.values.participants[index].socialLastFour}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								isInvalid={
									formik?.touched?.participants?.[index]?.socialLastFour &&
									formik?.errors?.participants?.[index]?.socialLastFour
								}
								errorMessage={
									formik?.errors?.participants?.[index]?.socialLastFour
								}
								dense
								informationText='Do not use dashes or spaces.'
							/>
						</Col>

						<Col xs={2}>
							<TextField
								name={`participants[${index}].numberOfDoubles`}
								label='# of Doubles'
								placeholder='#'
								value={formik.values.participants[index].numberOfDoubles}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								isInvalid={
									formik?.touched?.participants?.[index]?.numberOfDoubles &&
									formik?.errors?.participants?.[index]?.numberOfDoubles
								}
								errorMessage={
									formik?.errors?.participants?.[index]?.numberOfDoubles
								}
							/>
						</Col>

						<Col xs={2}>
							<Select
								label='Scale'
								name={`participants[${index}].scale`}
								value={scaleOptions.find(
									option =>
										option.value === formik.values.participants[index].scale
								)}
								options={scaleOptions}
								onChange={option =>
									formik.setFieldValue(
										`participants[${index}].scale`,
										option.value
									)
								}
								onBlur={formik.handleBlur}
								errorFieldName={`participants[${index}].scale`}
							/>
						</Col>

						<Col xs={3}>
							<Select
								label='Cartage'
								name={`participants[${index}].cartage`}
								value={cartageOptions.find(
									option =>
										option.value === formik.values.participants[index].cartage
								)}
								options={cartageOptions}
								onChange={option =>
									formik.setFieldValue(
										`participants[${index}].cartage`,
										option.value
									)
								}
								onBlur={formik.handleBlur}
								errorFieldName={`participants[${index}].cartage`}
							/>
						</Col>
						<Col xs={2}>
							<Select
								label='Overtime'
								name={`participants[${index}].overtime`}
								value={overtimeOptions.find(
									option =>
										option.value === formik.values.participants[index].overtime
								)}
								options={overtimeOptions}
								onChange={option =>
									formik.setFieldValue(
										`participants[${index}].overtime`,
										option.value
									)
								}
								onBlur={formik.handleBlur}
								errorFieldName={`participants[${index}].overtime`}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
};

export default AFMParticipantCard;
