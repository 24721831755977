import _ from 'lodash';
import { uniqWith } from 'lodash';

export const isStudioEqual = <
	S1 extends RecordingStudio | FormikStudio,
	S2 extends RecordingStudio | FormikStudio
>(
	studio1: S1,
	studio2: S2
) => studio1?.name === studio2?.name;

export const createNewStudio = ({
	name,
	id,
}: {
	name: string;
	id: number;
}): RecordingStudio => ({
	name,
	id,
});

export const mergeParticipantAndAssociatedStudios = (
	existingAssociatedStudios: RecordingStudio[],
	participantStudios: RecordingStudio[]
) => {
	// convert profile studios, that have string IDs, to autoincremented integer IDs
	const newAssociatedStudios = [...existingAssociatedStudios];

	const lastId = _.max(existingAssociatedStudios.map(s => s.id)) ?? 0;

	// filter out any studios that are already in the associated studios
	// first create studio objects from the participant studios, so we can compare them
	const newStudiosToAdd = participantStudios.filter(
		newStudio =>
			!existingAssociatedStudios.some(recordingStudio =>
				isStudioEqual(newStudio, recordingStudio)
			)
	);

	newStudiosToAdd.forEach((newStudio, index) => {
		newAssociatedStudios.push(
			createNewStudio({
				name: newStudio.name,
				id: lastId + index + 1,
			})
		);
	});

	return newAssociatedStudios;
};

export const extractRecordingParticipantStudios = (
	fullStudios: RecordingStudio[],
	participant: Participant
) =>
	_.uniqBy(
		participant.roles
			.map(role =>
				fullStudios.find(studio => studio.id === role?.studio?.studioId)
			)
			.filter((studio): studio is RecordingStudio => studio != null),
		s => s.id
	);

export const getParticipantAssociatedStudios = (
	participantRoles: ParticipantRole[],
	recordingStudios: RecordingStudio[]
) =>
	_.uniq(
		participantRoles
			.map(role => role.studio?.studioId)
			.filter((id): id is number => id != null)
	)
		.map(studioId => recordingStudios.find(studio => studio.id === studioId))
		.filter((studio): studio is RecordingStudio => studio != null);

export const getAllAssociatedStudios = <
	P extends FullLocalProfile | Participant | ProfileForm | ParticipantForm
>(
	participant: P
) =>
	uniqWith(
		participant.roles
			.map(role => role.studio)
			.filter((studio): studio is ParticipantStudio => studio != null)
			.map(s => createNewStudio({ name: s.label, id: s.studioId })),
		isStudioEqual
	);

export const mergeParticipantAndAssociatedRoles = <
	Role extends ParticipantRole | LocalProfileRole
>(
	newAssociatedStudios: RecordingStudio[],
	participantStudios: RecordingStudio[],
	participantRoles: Role[]
): Role[] =>
	participantRoles.map(role => {
		if (!role.studio) {
			return role;
		}
		const participantStudio = participantStudios.find(
			studio => studio.id === role.studio?.studioId
		);

		if (!participantStudio) {
			console.error(
				participantRoles,
				participantStudios,
				role,
				newAssociatedStudios
			);
			throw new Error('Could not find participant studio for role');
		}

		const associatedStudio = newAssociatedStudios.find(recStudio =>
			isStudioEqual(participantStudio, recStudio)
		);

		if (!associatedStudio) {
			throw new Error('Could not find associated studio for role');
		}

		return {
			category: role.category,
			detail: role.detail,
			studio: {
				label: associatedStudio.name,
				sessionType: role.studio.sessionType ?? null,
				studioId: associatedStudio.id,
			},
		} as Role;
	});

export const removeStudiosNotInUse = (
	studiosInUse: ParticipantStudio[],
	associatedStudios: RecordingStudio[]
) =>
	associatedStudios
		.map(associatedStudio =>
			studiosInUse.find(
				studioInUse => associatedStudio.id === studioInUse.studioId
			)
		)
		.filter(Boolean);
