import { FormikHelpers, useFormikContext } from 'formik';
import { Card, Col, Container, Row } from 'react-bootstrap';
import Button from '../../layout/Button/Button';
import TextField from '../../form/TextField';
import { useState } from 'react';
import React from 'react';
import './Notes.scss';
import { useAppSelector } from '../../../store/hooks';
// import { setAlbum } from '../../../store/album/actions';
import { Helmet } from 'react-helmet';
import { getCurrentRecording } from '../../../store/projects/selectors';

export type NotesProps = {
	onSave: (
		values: RecordingContent,
		dirty: boolean,
		validateForm: FormikHelpers<RecordingContent>['validateForm']
	) => void;
};

const Notes = ({ onSave }: NotesProps) => {
	const currentRecording = useAppSelector(getCurrentRecording);
	const { myEditorProfile } = useAppSelector(state => state.projects);
	const [saved, setSaved] = useState(false);

	const formik = useFormikContext<RecordingContent>();

	const isReadOnly = myEditorProfile ? myEditorProfile.is_read_only : true;

	const renderNotes = () => {
		return (
			<Card className='h-100'>
				<Card.Body className='h-100'>
					<h2>NOTES</h2>
					<TextField
						className='notes-textarea'
						name='notes'
						id='notes'
						rows={22}
						as='textarea'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.notes}
						readOnly={isReadOnly}
					/>
				</Card.Body>
			</Card>
		);
	};

	return (
		<>
			<Helmet>
				<title>
					{currentRecording?.title ?? ''} - Notes{' '}
					{process.env.REACT_APP_TAB_TITLE}
				</title>
			</Helmet>
			<Container
				style={{ height: '100%' }}
				className='d-flex justify-content-center flex-column'
			>
				<div className='my-3' />
				<Row className='h-100'>
					<Col xs={12} className='h-100'>
						<Row style={{ height: '90%' }} className='pt-2'>
							<Col xs={12}>{renderNotes()}</Col>
						</Row>
						<Row style={{ height: '10%' }} className='align-items-center'>
							<Col xs={12} className='d-flex justify-content-end'>
								<Button
									label={saved ? 'Saved!' : 'Save & Proceed'}
									theme={saved ? 'success' : 'dark'}
									onClick={() => {
										onSave(formik.values, formik.dirty, formik.validateForm);
										setSaved(true);
										setTimeout(() => {
											setSaved(false);
										}, 2000);
									}}
									isDisabled={isReadOnly}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default Notes;
