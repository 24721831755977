import * as React from 'react';
import { AppDispatch } from '../../../../../store/index';
import {
	CollisionDetection,
	UniqueIdentifier,
	closestCenter,
	pointerWithin,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { isFileUploading } from '../../../../../helpers/fileTools';
import {
	reorderPlaylistFileGroupsAction,
	reorderPlaylistFilesAction,
	updatePlaylistFileGroupFilesAction,
} from '../../../../../store/playlists/actions';
import _ from 'lodash';

// wrapper type to unify the file and group types
export type DndItem<T> = {
	id: UniqueIdentifier;
	data: T;
	groupId: PlaylistFileGroup['id'] | null; // used for sorting
};

export type PlaylistTableDndItem = DndItem<
	PlaylistFolder | PlaylistFileMetadata | PlaylistFileGroup
>;

export const isFileGroup = (
	fileOrGroup: PlaylistFileMetadata | PlaylistFileGroup | PlaylistFolder
): fileOrGroup is PlaylistFileGroup | PlaylistFolder =>
	'files' in fileOrGroup || 'fileIds' in fileOrGroup;

export const createDndItemId = (
	fileOrGroup: PlaylistFileMetadata | PlaylistFileGroup | PlaylistFolder
) => createDndItemIdFromId(fileOrGroup.id, isFileGroup(fileOrGroup));

export const createDndItemIdFromId = (id: number, isGroup: boolean) =>
	isGroup ? `group-${id}` : `file-${id}`;

export const extractIdFromDndItemId = (id: string) =>
	parseInt(id.split('-')[1]);

export const isGroupDndId = (id: string) => id.startsWith('group-');

/**
 * Extends the pointerWithin collision detection algorithm to include
 * threshold logic for highlighting a folder or determining drop action.
 */
export const pointerWithinWithCenterLineThreshold: (
	onThresholdChange: (isAboveThreshold: boolean) => void
) => CollisionDetection = onThresholdChange => args => {
	// Call the original pointerAboveOrBelowCenter function to get the collisions
	let collisions = pointerWithin(args);

	// if there are no collisions, use closestCenter to get the closest item
	// and be more forgiving
	if (collisions.length === 0) {
		collisions = closestCenter(args);
	}

	// Check if pointerCoordinates are provided
	if (args.pointerCoordinates) {
		const { pointerCoordinates, droppableRects } = args;

		// calculate if first collision is above or below the threshold
		// if there is a collision
		if (collisions.length > 0) {
			const closestCollision = collisions[0];
			const rect = droppableRects.get(closestCollision.id);

			if (rect) {
				// Calculate the distance from the pointer to the center line of the rectangle
				const centerY = rect.top + rect.height / 2;

				// as long as it's within a range of 80% of the height of the rectangle with respect to the center line
				// then it's considered above the threshold
				const THRESHOLD = 0.8;

				const distanceToCenterLine = Math.abs(pointerCoordinates.y - centerY);

				const isAboveThreshold =
					distanceToCenterLine < (rect.height * THRESHOLD) / 2;
				// Notify the consumer of the threshold change
				onThresholdChange(isAboveThreshold);
			}
		}
	}

	// Return the original collisions computed by pointerWithin
	return collisions;
};

/**
 * @deprecated formerly used when folder structure was flat
 */
export const reorderUngroupedFiles = ({
	setSortedUngroupedFiles,
	activeItem,
	overItem,
	dispatch,
	playlistId,
}: {
	setSortedUngroupedFiles: React.Dispatch<
		React.SetStateAction<PlaylistFileMetadata[]>
	>;
	activeItem: PlaylistTableDndItem;
	overItem: PlaylistTableDndItem;
	dispatch: AppDispatch;
	playlistId: Playlist['id'];
}) => {
	setSortedUngroupedFiles(items => {
		const oldIndex = items.findIndex(item => item.id === activeItem.data.id);
		const newIndex = items.findIndex(item => item.id === overItem.data.id);

		const newSortedList = arrayMove(items, oldIndex, newIndex);

		const newPlaylistFileList = newSortedList.filter(
			file => !isFileUploading(file)
		) as FileMetadata[];

		dispatch(reorderPlaylistFilesAction(playlistId, newPlaylistFileList));

		return newSortedList;
	});
};

/**
 * @deprecated formerly used when folder structure was flat
 */
export const reorderFilesInGroup = ({
	setSortedGroups,
	activeItem,
	overItem,
	dispatch,
	playlistId,
}: {
	setSortedGroups: React.Dispatch<React.SetStateAction<PlaylistFileGroup[]>>;
	activeItem: PlaylistTableDndItem;
	overItem: PlaylistTableDndItem;
	dispatch: AppDispatch;
	playlistId: Playlist['id'];
}) => {
	setSortedGroups(prevGroups => {
		const group = prevGroups.find(g => g.id === activeItem.groupId)!;
		const newGroupFiles = arrayMove(
			group.files,
			group.files.findIndex(file => file.id === activeItem.data.id),
			group.files.findIndex(file => file.id === overItem.data.id)
		);

		dispatch(
			updatePlaylistFileGroupFilesAction({
				playlistId,
				groupId: group.id,
				fileIds: newGroupFiles.map(file => file.id),
			})
		);

		return prevGroups.map(g => {
			if (g.id === group.id) {
				return {
					...g,
					files: newGroupFiles,
				};
			}

			return g;
		});
	});
};

/**
 * @deprecated formerly used when folder structure was flat
 */
export const moveGroupedFileToGroupAtPosition = ({
	setSortedGroups,
	activeItem,
	overItem,
	dispatch,
	playlistId,
}: {
	setSortedGroups: React.Dispatch<React.SetStateAction<PlaylistFileGroup[]>>;
	activeItem: PlaylistTableDndItem;
	overItem: PlaylistTableDndItem;
	dispatch: AppDispatch;
	playlistId: Playlist['id'];
}) => {
	setSortedGroups(prevGroups => {
		const newGroups = prevGroups.map(g => {
			if (g.id === activeItem.groupId) {
				const fromGroup = g;
				const newFromGroupFiles = fromGroup.files.filter(
					file => file.id !== activeItem.data.id
				);

				return {
					...g,
					files: newFromGroupFiles,
				};
			}

			if (g.id === overItem.groupId) {
				const toGroup = g;

				const newIndex = toGroup.files.findIndex(
					file => file.id === overItem.data.id
				);

				// insert at destination group in new index

				const newToGroupFiles = [
					...toGroup.files.slice(0, newIndex),
					activeItem.data as PlaylistFileMetadata,
					...toGroup.files.slice(newIndex),
				];

				return {
					...g,
					files: newToGroupFiles,
				};
			}

			return g;
		});

		dispatch(
			updatePlaylistFileGroupFilesAction({
				playlistId,
				groupId: activeItem.groupId!,
				fileIds: newGroups
					.find(g => g.id === activeItem.groupId)!
					.files.map(file => file.id),
			})
		);

		return newGroups;
	});
};

/**
 * @deprecated formerly used when folder structure was flat
 */
export const removeFileFromGroupAtPosition = ({
	setSortedGroups,
	setSortedUngroupedFiles,
	sortedGroups,
	sortedUngroupedFiles,
	activeItem,
	overItem,
	dispatch,
	playlistId,
}: {
	setSortedGroups: React.Dispatch<React.SetStateAction<PlaylistFileGroup[]>>;
	setSortedUngroupedFiles: React.Dispatch<
		React.SetStateAction<PlaylistFileMetadata[]>
	>;
	sortedGroups: PlaylistFileGroup[];
	sortedUngroupedFiles: PlaylistFileMetadata[];
	activeItem: PlaylistTableDndItem;
	overItem: PlaylistTableDndItem;
	dispatch: AppDispatch;
	playlistId: Playlist['id'];
}) => {
	const fromGroup = sortedGroups.find(g => g.id === activeItem.groupId)!;
	const newFromGroupFiles = fromGroup.files.filter(
		file => file.id !== activeItem.data.id
	);

	setSortedGroups(prevGroups => {
		const newGroups = prevGroups.map(g => {
			if (g.id === activeItem.groupId) {
				return {
					...g,
					files: newFromGroupFiles,
				};
			}

			return g;
		});

		return newGroups;
	});

	const toIndex = sortedUngroupedFiles.findIndex(
		file => file.id === overItem.data.id
	);

	const newUngroupedFiles = [
		...sortedUngroupedFiles.slice(0, toIndex),
		activeItem.data as PlaylistFileMetadata,
		...sortedUngroupedFiles.slice(toIndex),
	];

	setSortedUngroupedFiles(newUngroupedFiles);

	dispatch(reorderPlaylistFilesAction(playlistId, newUngroupedFiles));
};

/**
 * @deprecated formerly used when folder structure was flat
 */
export const moveUngroupedFileToGroupAtPosition = ({
	setSortedGroups,
	setSortedUngroupedFiles,
	sortedGroups,
	activeItem,
	overItem,
	dispatch,
	playlistId,
}: {
	setSortedGroups: React.Dispatch<React.SetStateAction<PlaylistFileGroup[]>>;
	setSortedUngroupedFiles: React.Dispatch<
		React.SetStateAction<PlaylistFileMetadata[]>
	>;
	sortedGroups: PlaylistFileGroup[];
	activeItem: PlaylistTableDndItem;
	overItem: PlaylistTableDndItem;
	dispatch: AppDispatch;
	playlistId: Playlist['id'];
}) => {
	const toGroup = sortedGroups.find(g => g.id === overItem.groupId)!;

	const toIndex = toGroup.files.findIndex(file => file.id === overItem.data.id);

	const newToGroupFiles = [
		...toGroup.files.slice(0, toIndex),
		activeItem.data as PlaylistFileMetadata,
		...toGroup.files.slice(toIndex),
	];

	setSortedGroups(prevGroups => {
		const newGroups = prevGroups.map(g => {
			if (g.id === toGroup.id) {
				return {
					...g,
					files: newToGroupFiles,
				};
			}

			return g;
		});

		return newGroups;
	});

	setSortedUngroupedFiles(prevFiles =>
		prevFiles.filter(file => file.id !== activeItem.data.id)
	);

	dispatch(
		updatePlaylistFileGroupFilesAction({
			playlistId: playlistId,
			groupId: toGroup.id,
			fileIds: newToGroupFiles.map(file => file.id),
		})
	);
};

/**
 * @deprecated formerly used when folder structure was flat
 */
export const removeFileFromGroup = ({
	setSortedGroups,
	setSortedUngroupedFiles,
	sortedGroups,
	activeItem,
	dispatch,
	playlistId,
}: {
	setSortedGroups: React.Dispatch<React.SetStateAction<PlaylistFileGroup[]>>;
	setSortedUngroupedFiles: React.Dispatch<
		React.SetStateAction<PlaylistFileMetadata[]>
	>;
	sortedGroups: PlaylistFileGroup[];
	activeItem: PlaylistTableDndItem;
	dispatch: AppDispatch;
	playlistId: Playlist['id'];
}) => {
	const fromGroup = sortedGroups.find(g => g.id === activeItem.groupId)!;
	const newFromGroupFiles = fromGroup.files.filter(
		file => file.id !== activeItem.data.id
	);

	setSortedGroups(prevGroups => {
		const newGroups = prevGroups.map(g => {
			if (g.id === activeItem.groupId) {
				return {
					...g,
					files: newFromGroupFiles,
				};
			}

			return g;
		});

		return newGroups;
	});

	setSortedUngroupedFiles(prevFiles => [
		activeItem.data as PlaylistFileMetadata,
		...prevFiles,
	]);

	dispatch(
		updatePlaylistFileGroupFilesAction({
			playlistId: playlistId,
			groupId: fromGroup.id,
			fileIds: newFromGroupFiles.map(file => file.id),
		})
	);
};

/**
 * @deprecated formerly used when folder structure was flat
 */
export const insertFileIntoGroup = ({
	setSortedGroups,
	setSortedUngroupedFiles,
	activeItem,
	overItem,
	dispatch,
	playlistId,
}: {
	setSortedGroups: React.Dispatch<React.SetStateAction<PlaylistFileGroup[]>>;
	setSortedUngroupedFiles: React.Dispatch<
		React.SetStateAction<PlaylistFileMetadata[]>
	>;
	sortedGroups: PlaylistFileGroup[];
	activeItem: PlaylistTableDndItem;
	overItem: PlaylistTableDndItem;
	dispatch: AppDispatch;
	playlistId: Playlist['id'];
}) => {
	const toGroup = overItem.data as PlaylistFileGroup;

	const newToGroupFiles = [
		...toGroup.files,
		activeItem.data as PlaylistFileMetadata,
	];

	setSortedGroups(prevGroups => {
		const newGroups = prevGroups.map(g => {
			if (g.id === toGroup.id) {
				return {
					...g,
					files: newToGroupFiles,
				};
			}

			return g;
		});

		return newGroups;
	});

	setSortedUngroupedFiles(prevFiles =>
		prevFiles.filter(file => file.id !== activeItem.data.id)
	);

	dispatch(
		updatePlaylistFileGroupFilesAction({
			playlistId: playlistId,
			groupId: toGroup.id,
			fileIds: newToGroupFiles.map(file => file.id),
		})
	);
};

export const reorderGroups = ({
	setSortedGroups,
	activeItem,
	overItem,
	dispatch,
	playlistId,
}: {
	setSortedGroups: React.Dispatch<React.SetStateAction<PlaylistFileGroup[]>>;
	activeItem: PlaylistTableDndItem;
	overItem: PlaylistTableDndItem;
	dispatch: AppDispatch;
	playlistId: Playlist['id'];
}) => {
	setSortedGroups(prevGroups => {
		const oldIndex = prevGroups.findIndex(
			group => group.id === activeItem.data.id
		);
		const newIndex = prevGroups.findIndex(
			group => group.id === overItem.data.id
		);

		const newSortedList = arrayMove(prevGroups, oldIndex, newIndex);

		dispatch(
			reorderPlaylistFileGroupsAction({
				playlistId,
				groupIds: newSortedList.map(g => g.id),
			})
		);

		return newSortedList;
	});
};

export const computeShiftClickSelectionRange = ({
	selectedItemIds: prevSelectedIds,
	itemIds: allItemIds,
	rowIndex: newItemIndex,
	anchorIndex,
}: {
	selectedItemIds: string[];
	itemIds: string[];
	rowIndex: number;
	anchorIndex: number;
}) => {
	// Initialize the new selection with the previously selected items
	let newSelectedIds = [...prevSelectedIds];

	// Determine the range between the anchor and the new item
	const rangeStart = Math.min(anchorIndex, newItemIndex);
	const rangeEnd = Math.max(anchorIndex, newItemIndex);

	// Get the IDs within this range
	const rangeIds = allItemIds.slice(rangeStart, rangeEnd + 1);

	// If the new item is beyond the anchor, select all within range
	// Otherwise, determine the new selection based on the existing one and the range
	if (prevSelectedIds.includes(allItemIds[anchorIndex])) {
		// If anchor is still selected, adjust selection normally
		newSelectedIds = rangeIds;
	} else {
		// If anchor has been deselected, adjust the logic as needed
		// This is a placeholder for any specific logic you might want to implement
		// For example, resetting the anchor to the first of the previously selected items
		// Or handling the selection differently
		newSelectedIds = rangeIds
			.filter(id => !prevSelectedIds.includes(id))
			.concat(prevSelectedIds.filter(id => rangeIds.includes(id)));
	}

	return newSelectedIds;
};

export const rowClickHandler = (
	e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
	{
		setSelectedItemIds,
		rowItemId,
		rowIndex,
		groupIdToGroupContentIndex,
		groupIdToParentGroupIdIndex,
		fileIdToGroupIdIndex,
		sortedFolders,
		playlistFiles,
		selectionAnchorPoint,
		setSelectionAnchorPoint,
	}: {
		setSelectedItemIds: React.Dispatch<React.SetStateAction<string[]>>;
		rowItemId: string;
		rowIndex: number;
		groupIdToGroupContentIndex: GroupIdToGroupContentIndex;
		groupIdToParentGroupIdIndex: GroupIdToParentGroupIdIndex;
		fileIdToGroupIdIndex: FileIdToGroupIdIndex;
		sortedFolders: PlaylistFolder[];
		playlistFiles: PlaylistFileMetadata[];
		selectionAnchorPoint: number | null;
		setSelectionAnchorPoint: React.Dispatch<
			React.SetStateAction<number | null>
		>;
	}
) => {
	// logic goes like this:
	// all selected items can only be selected if they're in the same parent group
	// if they differ, we need to deselect all and select only the clicked item
	setSelectedItemIds(prevSelected => {
		let newSelectedItemIds: string[];
		const isFolderItem = isGroupDndId(rowItemId);
		const id = extractIdFromDndItemId(rowItemId);

		// we can exit early if the parent group IDs don't match at this point
		const parentGroupId = isFolderItem
			? groupIdToParentGroupIdIndex[id] ?? null
			: fileIdToGroupIdIndex[id] ?? null;

		// if at least the first selected item has a different parent group,
		// we need to deselect all and select only the clicked item
		// this property should be valid as long as we make sure that the selected items
		// are always in the same parent group in the logic that follows this check
		if (prevSelected.length > 0) {
			if (
				getSelectedItemsParentGroupId({
					selectedItemIds: prevSelected,
					fileIdToGroupIdIndex,
					groupIdToParentGroupIdIndex,
				}) !== parentGroupId
			) {
				newSelectedItemIds = [rowItemId];
				setSelectionAnchorPoint(rowIndex);
				return newSelectedItemIds;
			}
		}

		// ctrl key for windows, meta key for mac
		if (e.ctrlKey || e.metaKey) {
			// if it's already selected, remove it
			if (prevSelected.includes(rowItemId)) {
				newSelectedItemIds = prevSelected?.filter(
					selectedItemId => selectedItemId !== rowItemId
				);
			} else {
				// otherwise, add it
				newSelectedItemIds = Array.from(
					new Set([...(prevSelected ?? []), rowItemId])
				);
			}
		} else if (e.shiftKey) {
			const parentGroupItems = getItemIdsInGroup({
				sortedFolders,
				playlistFiles,
				parentGroupId,
				fileIdToGroupIdIndex,
				groupIdToGroupContentIndex,
			});

			// compute selection range
			newSelectedItemIds = computeShiftClickSelectionRange({
				itemIds: parentGroupItems,
				selectedItemIds: prevSelected,
				rowIndex,
				anchorIndex: selectionAnchorPoint ?? rowIndex,
			});
		} else {
			// otherwise, just select this file
			newSelectedItemIds = [rowItemId];
		}

		if (newSelectedItemIds.length === 1) {
			setSelectionAnchorPoint(rowIndex);
		}

		return newSelectedItemIds;
	});
};

// creates 2 indexes for quick lookup of parent group id for any file or folder
export const createParentGroupIndexes = ({
	playlistFiles,
	playlistFolders,
}: {
	playlistFiles: PlaylistFileMetadata[];
	playlistFolders: PlaylistFolder[];
}): {
	fileIdToGroupIdIndex: FileIdToGroupIdIndex;
	groupIdToParentGroupIdIndex: GroupIdToParentGroupIdIndex;
	groupIdToGroupContentIndex: GroupIdToGroupContentIndex;
} => {
	const fileIdToGroupIdIndex: FileIdToGroupIdIndex = {};
	const groupIdToParentGroupIdIndex: GroupIdToParentGroupIdIndex = {};
	const groupIdToGroupContentIndex: GroupIdToGroupContentIndex = {};

	function traverse(
		folders: PlaylistFolder[],
		parentId: PlaylistFolder['id'] | null
	) {
		for (const folder of folders) {
			groupIdToParentGroupIdIndex[folder.id] = parentId;
			groupIdToGroupContentIndex[folder.id] = folder;

			folder.fileIds.forEach(fileId => {
				fileIdToGroupIdIndex[fileId] = folder.id;
			});

			traverse(folder.folders, folder.id);
		}
	}

	traverse(playlistFolders, null); // Start traversal with null as the parent ID for root folders

	// any file that's not in a group is in the root folder
	playlistFiles.forEach(file => {
		if (!fileIdToGroupIdIndex[file.id]) {
			fileIdToGroupIdIndex[file.id] = null;
		}
	});

	return {
		fileIdToGroupIdIndex,
		groupIdToParentGroupIdIndex,
		groupIdToGroupContentIndex,
	};
};

export const mapPlaylistFolders = ({
	playlistFolders,
	fn,
}: {
	playlistFolders: PlaylistFolder[];
	fn: (
		folder: PlaylistFolder,
		parentGroupId: PlaylistFolder['id'] | null
	) => PlaylistFolder;
}): PlaylistFolder[] => {
	// traverse the folders and apply the function to each folder recursively
	const traverse = (
		folders: PlaylistFolder[],
		parentGroupId: PlaylistFolder['id'] | null
	): PlaylistFolder[] =>
		folders.map(folder => {
			const newFolder = fn(folder, parentGroupId);

			return {
				...newFolder,
				folders: traverse(newFolder.folders, folder.id),
			};
		});

	return traverse(playlistFolders, null);
};

/**
 *
 * @deprecated // does two traversals of the playlist, use moveGroup instead
 * @returns
 */
export const moveGroup_ = ({
	playlist,
	groupId,
	oldParentGroupId,
	newParentGroupId,
	newPosition,
}: {
	playlist: Playlist;
	groupId: PlaylistFolder['id'];
	oldParentGroupId: PlaylistFolder['id'] | null;
	newParentGroupId: PlaylistFolder['id'] | null;
	newPosition: number;
}): PlaylistFolder[] => {
	// use mapPlaylistFolders to apply the changes to the playlist:
	// we need to get both the old and new parent groups and update their folders array
	// the old one needs to remove the group, the new one needs to insert it at the new position

	let group: PlaylistFolder | null = null;

	// TODO: if old and new parent group are the same, we can just reorder the folders and save one traversal
	const foldersWithoutGroup = mapPlaylistFolders({
		playlistFolders: playlist.playlist?.folders ?? [],
		fn: (folder, parentGroupId) => {
			// if we found the old parent group, remove the group from its folders
			// and save the group for later
			if (oldParentGroupId === parentGroupId) {
				group = folder.folders.find(f => f.id === groupId)!;

				const newFolder = {
					...folder,
					folders: folder.folders.filter(f => f.id !== groupId),
				};

				return newFolder;
			}

			return folder;
		},
	});

	if (!group) throw new Error('Group not found');

	// then we need to insert the group at the new position in the new parent group
	const foldersWithGroup = mapPlaylistFolders({
		playlistFolders: foldersWithoutGroup,
		fn: (folder, parentGroupId) => {
			if (newParentGroupId === parentGroupId) {
				return {
					...folder,
					folders: [
						...folder.folders.slice(0, newPosition),
						group!,
						...folder.folders.slice(newPosition),
					],
				};
			}

			return folder;
		},
	});

	return foldersWithGroup;
};

export const removeFileFromGroupRecursive = ({
	playlistFolders,
	fileId,
	fileIdToGroupIdIndex,
}: {
	playlistFolders: PlaylistFolder[];
	fileId: PlaylistFileMetadata['id'];
	fileIdToGroupIdIndex: FileIdToGroupIdIndex;
}): {
	newFolders: PlaylistFolder[];
	newFileIds: PlaylistFileMetadata['id'][];
} => {
	let newFileIds: PlaylistFileMetadata['id'][] | null = null;

	const parentGroupId = fileIdToGroupIdIndex[fileId];

	// traverse the folders and remove the file from the group
	const traverse = (folders: PlaylistFolder[]): PlaylistFolder[] =>
		folders.map(folder => {
			if (folder.id === parentGroupId) {
				newFileIds = folder.fileIds.filter(id => id !== fileId);
			}

			return {
				...folder,
				fileIds: folder.id === parentGroupId ? newFileIds! : folder.fileIds,
				folders: traverse(folder.folders),
			};
		});

	const newFolders = traverse(playlistFolders);

	if (!newFileIds) throw new Error('File not found');

	return {
		newFolders,
		newFileIds,
	};
};

export const moveGroup = ({
	playlistFolders,
	playlistFiles,
	groupId,
	overItem,
	insertInOverItem, // used to determine if the group should be inserted in the overItem or in the parent group
	removeFromFolder, // used to determine if the group should be removed from its old parent group
	groupIdToGroupContentIndex,
	groupIdToParentGroupIdIndex,
	fileIdToGroupIdIndex,
}: {
	playlistFolders: PlaylistFolder[];
	playlistFiles: PlaylistFileMetadata[];
	groupId: PlaylistFolder['id'];
	overItem: PlaylistTableDndItem;
	insertInOverItem: boolean;
	removeFromFolder: boolean;
	groupIdToGroupContentIndex: GroupIdToGroupContentIndex;
	groupIdToParentGroupIdIndex: GroupIdToParentGroupIdIndex;
	fileIdToGroupIdIndex: FileIdToGroupIdIndex;
}): {
	newFolders: PlaylistFolder[];
	newParentGroupFolderIds: PlaylistFolder['id'][];
} => {
	if (insertInOverItem && !isFileGroup(overItem.data))
		throw new Error('Cannot insert a group in a file');

	let oldParentGroupFolders: PlaylistFolder[] | null = null;
	let newParentGroupFolders: PlaylistFolder[] | null = null;

	const oldParentGroupId = groupIdToParentGroupIdIndex[groupId];
	const newParentGroupId = removeFromFolder
		? overItem.groupId
		: insertInOverItem
		? overItem.data.id
		: overItem.groupId;

	// if it's over a folder, then insert at the end of the folder
	// (the isFileGroup check is only for type narrowing,
	// it's not necessary since insertInOverItem should be false if it's a file)
	const newPosition = removeFromFolder
		? 0
		: insertInOverItem && isFileGroup(overItem.data)
		? overItem.data.folders.length
		: calculateOverItemPosition({
				playlistFiles,
				playlistFolders,
				overItem,
				isActiveItemGroup: true,
				groupIdToGroupContentIndex,
				groupIdToParentGroupIdIndex,
				fileIdToGroupIdIndex,
		  });

	const newFolders: PlaylistFolder[] = _.cloneDeep(playlistFolders);

	const findFolderToMoveAndNewParentFolder = (
		folders: PlaylistFolder[],
		parentGroupId: PlaylistFolder['id'] | null
	) => {
		// parentGroupId is the id for the folder that contains the current folders

		if (parentGroupId === newParentGroupId && !newParentGroupFolders) {
			newParentGroupFolders = folders;
		}

		if (parentGroupId === oldParentGroupId && !oldParentGroupFolders) {
			oldParentGroupFolders = folders;
		}

		// we can exit early if we found both the old and new parent groups
		if (oldParentGroupFolders && newParentGroupFolders) return;

		// traverse the folders and find the old and new parent groups if they haven't been found yet
		for (const folder of folders) {
			findFolderToMoveAndNewParentFolder(folder.folders, folder.id);
		}
	};

	findFolderToMoveAndNewParentFolder(newFolders, null);

	if (!oldParentGroupFolders) throw new Error('Folder not found');
	if (!newParentGroupFolders) throw new Error('New parent group not found');

	// remove the folder from its old position and insert it at the new position
	const [folderToMove] = (oldParentGroupFolders as PlaylistFolder[]).splice(
		(oldParentGroupFolders as PlaylistFolder[]).findIndex(
			f => f.id === groupId
		),
		1
	);

	(newParentGroupFolders as PlaylistFolder[]).splice(
		newPosition,
		0,
		folderToMove
	);

	return {
		newFolders,
		newParentGroupFolderIds: (newParentGroupFolders as PlaylistFolder[]).map(
			f => f.id
		),
	};
};

export const calculateOverItemPosition = ({
	playlistFiles,
	playlistFolders,
	isActiveItemGroup,
	overItem,
	groupIdToGroupContentIndex,
	groupIdToParentGroupIdIndex,
	fileIdToGroupIdIndex,
}: {
	playlistFiles: PlaylistFileMetadata[];
	playlistFolders: PlaylistFolder[];
	overItem: PlaylistTableDndItem;
	isActiveItemGroup: boolean;
	groupIdToGroupContentIndex: GroupIdToGroupContentIndex;
	groupIdToParentGroupIdIndex: GroupIdToParentGroupIdIndex;
	fileIdToGroupIdIndex: FileIdToGroupIdIndex;
}) => {
	const isOverItemGroup = isGroupDndId(overItem.id as string);
	const overItemId = extractIdFromDndItemId(overItem.id as string);

	const overItemParentGroupId = isOverItemGroup
		? groupIdToParentGroupIdIndex[overItemId]
		: fileIdToGroupIdIndex[overItemId];

	// if it's a top-level folder or file, the parent group id is null
	// in that case, we re-create the parent group with the top-level folders and files
	const parentGroup = overItemParentGroupId
		? groupIdToGroupContentIndex[overItemParentGroupId]
		: {
				folders: playlistFolders,
				fileIds: playlistFiles
					.filter(file => !fileIdToGroupIdIndex[file.id]) // only keep files in top-level
					.sort((a, b) => a.orderIndex! - b.orderIndex!)
					.map(file => file.id),
		  };

	// if the active item is a group, and the over item is a file, then position the group at the
	// end of the folders in the file's parent group
	if (isActiveItemGroup && !isOverItemGroup) {
		return parentGroup.folders.length;
	}

	const overItemContentIds = isOverItemGroup
		? parentGroup.folders.map(f => f.id)
		: parentGroup.fileIds;

	const index = overItemContentIds.findIndex(id => id === overItem.data.id);

	return index;
};

export const reorderGroupsRecursive = ({
	playlistFolders,
	playlistFiles,
	dispatch,
	groupId,
	parentGroupId,
	overItem,
	groupIdToGroupContentIndex,
	groupIdToParentGroupIdIndex,
	fileIdToGroupIdIndex,
}: {
	playlistFolders: PlaylistFolder[];
	playlistFiles: PlaylistFileMetadata[];
	dispatch: AppDispatch;
	groupId: PlaylistFolder['id'];
	parentGroupId: PlaylistFolder['id'] | null;
	overItem: PlaylistTableDndItem;
	groupIdToGroupContentIndex: GroupIdToGroupContentIndex;
	groupIdToParentGroupIdIndex: GroupIdToParentGroupIdIndex;
	fileIdToGroupIdIndex: FileIdToGroupIdIndex;
}) => {
	const newPosition = calculateOverItemPosition({
		playlistFiles,
		playlistFolders,
		overItem,
		isActiveItemGroup: true,
		groupIdToGroupContentIndex,
		groupIdToParentGroupIdIndex,
		fileIdToGroupIdIndex,
	});

	let reorderedParentFolder: PlaylistFolder | null = null;

	// Define a function to recursively find and reorder a folder within its parent
	const reorderWithinParent = (
		folders: PlaylistFolder[],
		parentId: PlaylistFolder['id'] | null
	): PlaylistFolder[] => {
		return folders.map(folder => {
			if (parentId === parentGroupId) {
				// Found the parent folder, now reorder its child folders
				const folderIndex = folder.folders.findIndex(f => f.id === groupId);
				if (folderIndex !== -1) {
					const [folderToMove] = folder.folders.splice(folderIndex, 1); // Remove the folder from its current position
					folder.folders.splice(newPosition, 0, folderToMove); // Insert the folder at the new position
				}

				reorderedParentFolder = folder;

				return {
					...folder,
					folders: [...folder.folders], // Ensure we're returning a new array instance if needed
				};
			} else {
				// Recursively handle other folders, in case the parent is nested deeper
				return {
					...folder,
					folders: reorderWithinParent(folder.folders, folder.id),
				};
			}
		});
	};

	return {
		newFolders: reorderWithinParent(playlistFolders, null),
		reorderedParentFolder: reorderedParentFolder!,
	};
};

export const moveFile = ({
	playlistFolders,
	playlistFiles,
	fileId,
	overItem,
	groupIdToGroupContentIndex,
	groupIdToParentGroupIdIndex,
	fileIdToGroupIdIndex,
	insertInOverItem = false, // used to determine if the file should be inserted in the overItem or in the parent group
	removeFromFolder = false,
}: {
	playlistFolders: PlaylistFolder[];
	playlistFiles: PlaylistFileMetadata[];
	fileId: PlaylistFileMetadata['id'];
	overItem: PlaylistTableDndItem;
	groupIdToGroupContentIndex: GroupIdToGroupContentIndex;
	groupIdToParentGroupIdIndex: GroupIdToParentGroupIdIndex;
	fileIdToGroupIdIndex: FileIdToGroupIdIndex;
	insertInOverItem?: boolean;
	removeFromFolder?: boolean;
}): {
	newFolders: PlaylistFolder[];
	newParentGroupFileIds: PlaylistFileMetadata['id'][];
} => {
	const isOverItemAFolder = isGroupDndId(overItem.id as string);

	if (insertInOverItem && !isOverItemAFolder)
		throw new Error('Cannot insert a file in a file');

	let newParentGroupFileIds: PlaylistFileMetadata['id'][] | null = null;

	const oldParentGroupId = fileIdToGroupIdIndex[fileId];
	const newParentGroupId = // it needs to be taken to 1 level above the item it's being dragged over
		removeFromFolder
			? overItem.groupId
			: insertInOverItem
			? overItem.data.id
			: overItem.groupId;

	// If the file is being removed from a folder, then it's being moved to the top of the playlist
	// TODO: TEST IF THIS WORKS WHEN removeFromFolder IS TRUE
	const newPosition = removeFromFolder
		? 0
		: insertInOverItem && isOverItemAFolder
		? (overItem.data as PlaylistFolder).fileIds.length // insert at the end of the folder if insertInOverItem is true
		: calculateOverItemPosition({
				playlistFiles,
				playlistFolders,
				overItem,
				isActiveItemGroup: false,
				groupIdToGroupContentIndex,
				groupIdToParentGroupIdIndex,
				fileIdToGroupIdIndex,
		  });

	if (newPosition === -1) throw new Error('New position not found');

	if (oldParentGroupId === newParentGroupId && !isOverItemAFolder) {
		// If the old and new parent are the same,
		// and the overItem is also a file,
		// then apply reordering within the same parent
		return reorderGroupFiles({
			playlistFolders,
			fileId,
			parentGroupId: oldParentGroupId,
			newPosition: newPosition ?? 0, // Ensure newPosition is a valid number
		});
	}

	// Define a helper function to traverse and update folders
	const traverseAndUpdate = (folders: PlaylistFolder[]): PlaylistFolder[] =>
		folders.map(folder => {
			// Check if current folder is the old parent to remove the file ID
			// if the file is in the top level, this will be skipped, since it's not
			// in any fileIds array in the structure
			if (folder.id === oldParentGroupId) {
				folder = {
					...folder,
					fileIds: folder.fileIds.filter(id => id !== fileId), // Remove the file ID
				};
			}

			// Recursively handle subfolders
			const updatedFolders = traverseAndUpdate(folder.folders);

			// Check if current folder is the new parent to add the file ID
			if (folder.id === newParentGroupId) {
				let updatedFileIds = folder.fileIds;
				if (
					newPosition != null &&
					newPosition >= 0 &&
					newPosition <= updatedFileIds.length
				) {
					// Insert at specified position, ensuring it's within bounds
					updatedFileIds = [
						...updatedFileIds.slice(0, newPosition),
						fileId,
						...updatedFileIds.slice(newPosition),
					];
				} else {
					// If no position specified, or it's invalid, insert at the end
					updatedFileIds = [...updatedFileIds, fileId];
				}

				newParentGroupFileIds = updatedFileIds;

				folder = {
					...folder,
					fileIds: updatedFileIds,
				};
			}

			return {
				...folder,
				folders: updatedFolders,
			};
		});

	const newFolders = traverseAndUpdate(playlistFolders);

	// if it's being moved to the top level, then there's no new parent group file IDs
	// ! this specific case needs to be handled wherever this function is called, and make sure
	// ! that the PUT API call is done over the old parent group, filtering out the file ID from it
	if (!newParentGroupFileIds && newParentGroupId)
		throw new Error('New parent group file IDs not found');

	// Kick off the traversal and update process
	return {
		newFolders,
		newParentGroupFileIds: newParentGroupFileIds ?? [],
	};
};

// TODO: HANDLE CASE WHERE PARENT GROUP ID IS NULL
export const reorderGroupFiles = ({
	playlistFolders,
	fileId,
	parentGroupId,
	newPosition,
}: {
	playlistFolders: PlaylistFolder[];
	fileId: PlaylistFileMetadata['id'];
	parentGroupId: PlaylistFolder['id'] | null;
	newPosition: number;
}) => {
	let newParentGroupFileIds: PlaylistFileMetadata['id'][] | null = null;

	// Define a function to recursively find and reorder a folder within its parent
	const reorderWithinParent = (folders: PlaylistFolder[]): PlaylistFolder[] => {
		return folders.map(folder => {
			if (folder.id === parentGroupId) {
				// Found the parent folder, now reorder its child folders
				const fileIndex = folder.fileIds.findIndex(id => id === fileId);
				if (fileIndex !== -1) {
					const [fileToMove] = folder.fileIds.splice(fileIndex, 1); // Remove the folder from its current position
					folder.fileIds.splice(newPosition, 0, fileToMove); // Insert the folder at the new position
				}

				newParentGroupFileIds = folder.fileIds;

				return {
					...folder,
					folders: [...folder.folders], // Ensure we're returning a new array instance if needed
				};
			} else {
				// Recursively handle other folders, in case the parent is nested deeper
				return {
					...folder,
					folders: reorderWithinParent(folder.folders),
				};
			}
		});
	};

	const newFolders = reorderWithinParent(playlistFolders);

	if (!newParentGroupFileIds)
		throw new Error('New parent group file IDs not found');

	return {
		newFolders,
		newParentGroupFileIds,
	};
};

export const createDndItem = (
	fileOrGroup: PlaylistFileMetadata | PlaylistFileGroup | PlaylistFolder,
	groupId: PlaylistFileGroup['id'] | null
) => {
	const dndItem = {
		data: fileOrGroup,
		id: createDndItemId(fileOrGroup),
		groupId,
	} as PlaylistTableDndItem;

	return dndItem;
};

export const createDndItems = ({
	playlist,
	sortedFolders, // we need to use the sorted folders state to always correspond to the current state of the playlist
	// including when it's optimistically updated and waiting for the server response
	fileIdToGroupIdIndex,
}: {
	playlist: Playlist | null | undefined;
	sortedFolders: PlaylistFolder[];
	fileIdToGroupIdIndex: FileIdToGroupIdIndex;
}) => {
	if (!playlist?.playlist) return [];

	// Define a function to recursively flatten the folder structure and map the items to Dnd IDs
	const createItems = (
		folders: PlaylistFolder[],
		parentGroupId: PlaylistFileMetadata['id'] | null
	) =>
		folders.reduce<PlaylistTableDndItem[]>((acc, folder) => {
			// Add the current folder to the accumulator
			acc.push(
				createDndItem(folder, parentGroupId),
				// order in which they're displayed:
				// folders at the top
				...createItems(folder.folders, folder.id),
				// then files
				...folder.fileIds.map(fileId =>
					createDndItem(
						playlist.playlist?.files.find(file => file.id === fileId)!,
						folder.id
					)
				)
				// Recursively handle subfolders
			);

			return acc;
		}, []);

	const flattenedFolders = createItems(sortedFolders, null);

	// Add the top-level files to the accumulator
	const topLevelFiles = playlist.playlist.files
		.filter(file => !fileIdToGroupIdIndex[file.id])
		.map(file => createDndItem(file, null));

	return [...flattenedFolders, ...topLevelFiles];
};

export const matchFolderWithFiles = ({
	folder,
	playlistFiles,
}: {
	folder: PlaylistFolder;
	playlistFiles: PlaylistFileMetadata[];
}) =>
	({
		...folder,
		files: folder.fileIds.map(
			fileId => playlistFiles.find(file => file.id === fileId)!
		),
	} as PlaylistFileGroup);

export const getItemsInGroup = ({
	sortedFolders,
	playlistFiles,
	parentGroupId,
	fileIdToGroupIdIndex,
	groupIdToGroupContentIndex,
}: {
	playlistFiles: PlaylistFileMetadata[];
	sortedFolders: PlaylistFolder[];
	parentGroupId: PlaylistFolder['id'] | null;
	fileIdToGroupIdIndex: FileIdToGroupIdIndex;
	groupIdToGroupContentIndex: GroupIdToGroupContentIndex;
}) => {
	let folders: PlaylistFolder[] = [];
	let files: PlaylistFileMetadata[] = [];

	// if parentGroupId is null, we're looking for ungrouped files and top-level folders
	if (parentGroupId === null) {
		files = playlistFiles.filter(file => !fileIdToGroupIdIndex[file.id]) ?? [];

		folders = sortedFolders;
	} else {
		// otherwise, we're looking for files and folders in a specific group
		const group = groupIdToGroupContentIndex[parentGroupId];

		folders = groupIdToGroupContentIndex[parentGroupId]?.folders ?? [];

		files =
			group?.fileIds
				.map(fileId => playlistFiles.find(file => file.id === fileId)!)
				// we may get undefined files if the playlistFiles are not yet loaded in
				.filter(Boolean) ?? [];
	}

	return { folders, files };
};

export const getItemIdsInGroup = ({
	sortedFolders,
	playlistFiles,
	parentGroupId,
	fileIdToGroupIdIndex,
	groupIdToGroupContentIndex,
}: {
	playlistFiles: PlaylistFileMetadata[];
	sortedFolders: PlaylistFolder[];
	parentGroupId: PlaylistFolder['id'] | null;
	fileIdToGroupIdIndex: FileIdToGroupIdIndex;
	groupIdToGroupContentIndex: GroupIdToGroupContentIndex;
}) =>
	Object.values(
		getItemsInGroup({
			sortedFolders,
			playlistFiles,
			parentGroupId,
			fileIdToGroupIdIndex,
			groupIdToGroupContentIndex,
		})
	)
		.flat()
		.map(item => createDndItemId(item));

export const getSelectedItemsParentGroupId = ({
	selectedItemIds,
	fileIdToGroupIdIndex,
	groupIdToParentGroupIdIndex,
}: {
	selectedItemIds: string[];
	fileIdToGroupIdIndex: FileIdToGroupIdIndex;
	groupIdToParentGroupIdIndex: GroupIdToParentGroupIdIndex;
}) => {
	if (selectedItemIds.length === 0) return null;

	const firstSelectedId = extractIdFromDndItemId(selectedItemIds[0]);
	const isFirstItemAFolder = isGroupDndId(selectedItemIds[0]);
	const firstSelectedParentGroupId = isFirstItemAFolder
		? groupIdToParentGroupIdIndex[firstSelectedId] ?? null
		: fileIdToGroupIdIndex[firstSelectedId] ?? null;

	return firstSelectedParentGroupId;
};

/**
 *
 * checks if folderA is an ancestor of folderB, given both their IDs
 */
export const isAncestorOf = ({
	folderAId,
	folderBId,
	groupIdToParentGroupIdIndex,
}: {
	folderAId: PlaylistFolder['id'] | null;
	folderBId: PlaylistFolder['id'] | null;
	groupIdToParentGroupIdIndex: GroupIdToParentGroupIdIndex;
}) => {
	console.log('checking if', folderAId, 'is an ancestor of', folderBId);

	let currentParentId: PlaylistFolder['id'] | null = folderBId;

	// a folder is not a parent of itself
	if (folderAId === folderBId) return false;

	while (currentParentId) {
		if (currentParentId === folderAId) {
			console.log('yes, it is');

			return true;
		}

		currentParentId = groupIdToParentGroupIdIndex[currentParentId] ?? null;
	}

	console.log('no, it is not');
	return false;
};
