import translateApiExportToLocal from './translateApiExportToLocal';

const convertExportsArrayToObject = (exports: ApiExportMetadata[]) => {
	const exportsObj: Record<ExportMetadata['id'], ExportMetadata> = {};
	
	exports.forEach(exportObj => {
		exportsObj[exportObj.id] = translateApiExportToLocal(exportObj);
	});

	return exportsObj;
};

export default convertExportsArrayToObject;
