import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import {
	getFilesById,
	getUploadsByFileId,
} from '../../../../store/files/selectors';
import {
	hideModal,
	setModalTitle,
	showModalAction,
} from '../../../../store/modal/actions';
import './FileVersionListModal.scss';
import PillButton from '../../../layout/PillButton/PillButton';
import IconButton from '../../../layout/IconButton/IconButton';
import theme from '../../../../theme.module.scss';
import {
	DELETE_MODAL,
	EDIT_FILE_VERSION_MODAL,
	UPLOAD_FILE_VERSION_MODAL,
} from '../../../../constants/modalTypes';
import { selectDownloadPathAndDownload } from '../../../../helpers/downloadTools';
import {
	abortFileUploadAction,
	deleteFileVersionAction,
	getFileMetadataAction,
	updateFileVersionMetadataAction,
} from '../../../../store/files/actions';
import SoundCreditLoader from '../../SoundCreditLoader/SoundCreditLoader';
import { fetchUserEditableProjectsAction } from '../../../../store/projects/actions';
import { fetchPlaylistAction } from '@/store/playlists/actions';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { isFileVersionUploading } from '../../../../helpers/fileTools';

export type FileVersionListModalProps = {
	fileId: FileMetadata['id'];
	playlistId?: Playlist['id'] | null;
};

const FileVersionListModal = ({
	fileId,
	playlistId = null,
}: FileVersionListModalProps) => {
	const dispatch = useAppDispatch();
	const filesById = useAppSelector(getFilesById);
	const uploadingVersions = useAppSelector(state =>
		getUploadsByFileId(state, { fileId, includeCanceledUploads: false })
	);
	const { userEditableAlbumIds, userEditableRecordingIds } = useAppSelector(
		state => state.projects
	);

	const [isLoading, setIsLoading] = useState(false);

	const isReadOnly = useMemo(() => {
		if (!userEditableAlbumIds || !userEditableRecordingIds) return true;

		const albumId = filesById?.[fileId]?.albumId;
		const recordingId = filesById?.[fileId]?.recordingId;

		return (
			!userEditableAlbumIds?.find(id => id === albumId) &&
			!userEditableRecordingIds?.find(id => id === recordingId)
		);
	}, [filesById, fileId, userEditableAlbumIds, userEditableRecordingIds]);

	const file = useMemo(() => filesById?.[fileId], [filesById, fileId]);
	const activeVersionId = useMemo(() => file?.activeVersion, [file]);

	const versions = useMemo(
		() =>
			[
				...Object.values(file?.versions || {}),
				...uploadingVersions.map(
					upload =>
						({
							comment: upload?.versionMetadata?.comment,
							id: upload.id,
							filename: upload.metadata.filename,
							progress: upload.progress,
							uploading: true,
						} as FileVersionUpload)
				),
			].reverse(),

		[file, uploadingVersions]
	);

	const [isSettingActiveId, setIsSettingActive] = useState<
		FileVersion['id'] | null
	>(null);

	const handleUploadVersion = useCallback(() => {
		dispatch(
			showModalAction(UPLOAD_FILE_VERSION_MODAL, {
				fileId,
				playlistId,
				size: 'lg',
			})
		);
	}, [dispatch, fileId, playlistId]);

	const handleDownload = useCallback(
		(versionId: FileVersion['id']) => {
			if (!file) throw new Error('File not found');

			selectDownloadPathAndDownload({ file, versionId, dispatch });
		},
		[dispatch, file]
	);

	const handleSetActive = useCallback(
		async (versionId: FileVersion['id']) => {
			if (isSettingActiveId) return;

			setIsSettingActive(versionId);

			try {
				await dispatch(
					updateFileVersionMetadataAction({
						fileId,
						versionId,
						makeActive: true,
					})
				);

				if (playlistId) {
					await dispatch(fetchPlaylistAction(playlistId));
				}
			} finally {
				setIsSettingActive(null);
			}
		},
		[dispatch, isSettingActiveId, fileId, playlistId]
	);

	const handleDelete = useCallback(
		(versionId: FileVersion['id']) => {
			dispatch(
				showModalAction(DELETE_MODAL, {
					onDelete: async () =>
						dispatch(
							deleteFileVersionAction({ fileId, versionId, playlistId })
						),
					title: `DELETE FILE VERSION #${file?.versions?.[versionId]?.versionNo}`,
					body: `Are you sure you want to delete this file version?`,
					size: 'md',
				})
			);
		},
		[dispatch, fileId, file, playlistId]
	);

	const handleEdit = useCallback(
		(versionId: FileVersion['id']) => {
			dispatch(
				showModalAction(EDIT_FILE_VERSION_MODAL, {
					fileId,
					versionId,
					size: 'md',
				})
			);
		},
		[dispatch, fileId]
	);

	const renderVersion = useCallback(
		(version: FileVersion, index: number) => {
			const createdAtDate = new Date(version.createdAt);

			return (
				<tr key={index}>
					<td>
						<span>{version.versionNo}</span>
					</td>
					<td>
						<span>{version.filename}</span>
					</td>
					<td>
						{version.comment ? (
							<div className='d-flex align-items-center version-description'>
								<span>{version.comment}</span>
								{!isReadOnly && (
									<IconButton
										icon='fas fa-edit'
										onClick={() => handleEdit(version.id)}
										color={theme.primary}
									/>
								)}
							</div>
						) : isReadOnly ? (
							<span className='text-muted'>No description</span>
						) : (
							<PillButton
								label='Add'
								onClick={() => handleEdit(version.id)}
								leftIcon='fas fa-plus'
							/>
						)}
					</td>
					<td className='created-at'>
						<span>
							{createdAtDate?.toLocaleString(undefined, {
								year: 'numeric',
								month: 'short',
								day: 'numeric',
								hour: 'numeric',
								minute: '2-digit',
							})}
						</span>
					</td>
					<td className='actions'>
						{version.id === activeVersionId ? (
							<div className='active-file-version-tag'>
								<i className='fas fa-check-circle mr-2' />
								Active Version
							</div>
						) : isReadOnly ? (
							<></>
						) : (
							<PillButton
								label='Set as Active'
								onClick={() => handleSetActive(version.id)}
								className='ml-2'
								isLoading={isSettingActiveId === version.id}
								isDisabled={Boolean(
									isSettingActiveId && isSettingActiveId !== version.id
								)}
							/>
						)}
						<IconButton
							icon='fas fa-download'
							className='ml-2'
							onClick={() => handleDownload(version.id)}
							color={theme.primary}
							tooltipText='Download'
						/>
						{!isReadOnly && (
							<>
								{versions.length > 1 && (
									<IconButton
										icon='fas fa-trash-alt'
										className='ml-2'
										onClick={() => handleDelete(version.id)}
										color={theme.error}
										tooltipText='Delete Version'
										isDisabled={Boolean(isSettingActiveId)}
									/>
								)}
							</>
						)}
					</td>
				</tr>
			);
		},
		[
			activeVersionId,
			handleDelete,
			handleDownload,
			handleEdit,
			handleSetActive,
			isSettingActiveId,
			versions.length,
			isReadOnly,
		]
	);

	const renderUploadingVersion = useCallback(
		(versionUpload: FileVersionUpload, index: number) => (
			<tr key={index}>
				<td>
					<Spinner
						animation='border'
						size='sm'
						variant='dark'
						className='mr-2'
					/>
				</td>
				<td>
					<span>{versionUpload?.filename ?? '—'}</span>
				</td>
				<td>
					<span>{versionUpload?.comment ?? '—'}</span>
				</td>
				<td className='created-at'>
					<span>—</span>
				</td>
				<td className='actions'>
					<div className='upload-progress-container'>
						<i className='fas fa-upload mr-2' />
						Uploading:
						<progress
							className='ml-2'
							value={versionUpload.progress}
							max='100'
						/>
					</div>
					<IconButton
						icon='fas fa-times'
						className='ml-2'
						onClick={() => dispatch(abortFileUploadAction(versionUpload.id))}
					/>
				</td>
			</tr>
		),
		[dispatch]
	);

	useEffect(() => {
		dispatch(
			setModalTitle(
				file
					? `FILE VERSIONS - "${file?.filename.toUpperCase()}"`
					: 'FILE VERSIONS'
			)
		);
	}, [dispatch, file]);

	useEffect(() => {
		if (isLoading) return;

		setIsLoading(true);

		Promise.all([
			dispatch(fetchUserEditableProjectsAction()),
			dispatch(getFileMetadataAction(fileId)),
		])
			.catch(() => {
				dispatch(hideModal());
			})
			.finally(() => {
				setIsLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	return (
		<Modal.Body>
			{!file ? (
				<SoundCreditLoader />
			) : (
				<div className='px-4 file-version-list-table'>
					{!isReadOnly && (
						<div className='d-flex justify-content-end'>
							<PillButton
								label='Upload New Version'
								onClick={handleUploadVersion}
								className='mb-3'
								theme='solid-light'
								leftIcon='fas fa-upload'
							/>
						</div>
					)}

					<table>
						<thead>
							<tr>
								<th>Version Number</th>
								<th>File Name</th>
								<th>Description</th>
								<th>Created At</th>
								<th>{/* Actions */}</th>
							</tr>
						</thead>
						<tbody>
							{versions?.map((version, index) =>
								isFileVersionUploading(version)
									? renderUploadingVersion(version, index)
									: renderVersion(version, index)
							)}
						</tbody>
					</table>
				</div>
			)}
		</Modal.Body>
	);
};

export default FileVersionListModal;
