import { AnyAction } from 'redux';
import { ErrorLevel } from '../../helpers/errors';
import {
	HIDE_ALERT_TOAST,
	SHOW_ALERT_TOAST,
	SHOW_ERROR_ALERT,
} from '../actionTypes';

export type AlertToastState = {
	message: string | React.ReactNode;
	level: AlertToastLevel;
	inactiveEditor?: boolean;
	readOnly?: boolean;
};

const initialState: AlertToastState = {
	message: '',
	level: ErrorLevel.ERROR,
};

const reducer = (state = initialState, action: AnyAction): AlertToastState => {
	switch (action.type) {
		case SHOW_ALERT_TOAST:
			return {
				...state,
				message: action.message,
				level: action.level,
				inactiveEditor: action.inactiveEditor,
				readOnly: action.readOnly,
			};
		case HIDE_ALERT_TOAST:
			return {
				...state,
				message: '',
			};
		case SHOW_ERROR_ALERT:
			return {
				...state,
				message: action.message,
				level: ErrorLevel.ERROR,
			};
		default:
			return {
				...state,
			};
	}
};

export default reducer;
