import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import './InfoTooltip.scss';

const InfoTooltip = ({
	placement = 'auto',
	message,
	icon = 'fas fa-info-circle',
	iconClassName = '',
	tooltipClassName = '',
	iconStyle = {},
	tooltipStyle = {},
}) => {
	return (
		<OverlayTrigger
			placement={placement}
			overlay={
				<Tooltip id={`info-tooltip ${tooltipClassName}`} style={tooltipStyle}>
					<div className='info-tooltip__content'>{message}</div>
				</Tooltip>
			}
		>
			<div
				className={`info-tooltip__button ${iconClassName}`}
				style={iconStyle}
			>
				<i className={icon} />
			</div>
		</OverlayTrigger>
	);
};

export default InfoTooltip;
