import React, { useState, useEffect, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { Formik, Field, useFormikContext, FormikHelpers } from 'formik';
import SearchBar from '../../../layout/SearchBar';
import './ImportPersonalProfilesModal.scss';
import Button from '../../../layout/Button';
import { isProfileEqual } from '../../../../helpers/participantTools';
import { matchSorter } from 'match-sorter';
import { importLocalProfilesIntoRecordingAction } from '../../../../store/profiles/actions';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

type ProfileTableRowProps = {
	profile: LocalProfile;
	selectedProfiles: string[];
	onSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ProfileTableRow = ({
	profile,
	selectedProfiles,
	onSelect,
}: ProfileTableRowProps) => {
	const [check, setCheck] = useState(false);

	useEffect(() => {
		setCheck(selectedProfiles.includes(profile?.id?.toString()));
	}, [selectedProfiles, profile.id]);

	return (
		<tr
			style={{
				display: 'table',
				width: '100%',
				tableLayout: 'fixed',
			}}
		>
			<td
				style={{
					width: '8%',
				}}
			>
				<Field
					style={{ width: '1rem', height: '1rem' }}
					type='checkbox'
					name='checked'
					value={profile.id}
					checked={check}
					onChange={onSelect}
				/>
			</td>
			<td>{profile.creditedName}</td>
			<td>
				{profile.roles &&
					profile.roles.map((role, index) => (
						<p className='mb-0' key={index}>
							{role.detail}
						</p>
					))}
			</td>
		</tr>
	);
};

export type ImportPersonalProfilesModalProps = {
	show: boolean;
	toggleShow: () => void;
};

type FormValues = {
	checked: string[];
};

const ImportPersonalProfilesModal = ({
	show,
	toggleShow,
}: ImportPersonalProfilesModalProps) => {
	const dispatch = useAppDispatch();
	const { profiles: savedProfiles } = useAppSelector(state => state.profiles);
	const [searchValue, setSearchValue] = useState('');
	const recordingFormik = useFormikContext<RecordingContent>();
	const [selectedProfileIds, setSelectedProfileIds] = useState<string[]>([]);
	const [isImporting, setIsImporting] = useState(false);
	const { currentRecordingId } = useAppSelector(state => state.projects);

	const profilesNotInRecording = useMemo(
		() =>
			savedProfiles?.filter(
				savedProfile =>
					!recordingFormik.values.participants.find(recordingParticipant =>
						isProfileEqual(recordingParticipant, savedProfile)
					)
			) ?? [],
		[savedProfiles, recordingFormik.values.participants]
	);

	const filteredProfiles = useMemo(
		() =>
			matchSorter(profilesNotInRecording, searchValue, {
				keys: ['creditedName'],
			}),
		[profilesNotInRecording, searchValue]
	);

	const handleSubmit = async (
		resetForm: FormikHelpers<FormValues>['resetForm']
	) => {
		if (selectedProfileIds.length === 0) return;

		try {
			setIsImporting(true);
			const selectedProfiles = selectedProfileIds
				.map(profileId =>
					filteredProfiles.find(
						profile => String(profile.id) === String(profileId) // profileId is a string since Formik's Field component converts the value prop into a string
					)
				)
				.filter((profile): profile is LocalProfile => !!profile);

			await Promise.resolve(
				dispatch(
					importLocalProfilesIntoRecordingAction(
						selectedProfiles,
						currentRecordingId!
					)
				)
			);

			resetForm();
			toggleShow();
			setSelectedProfileIds([]);
		} catch (e) {
			console.log(e);
		} finally {
			setIsImporting(false);
		}
	};

	const handleSelection = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedProfileIds(prevSelection => {
			const selectedValue = e.target.value;
			let newSelection = [];

			if (prevSelection.includes(selectedValue)) {
				newSelection = [
					...prevSelection.filter(value => value !== selectedValue),
				];
			} else {
				newSelection = [...prevSelection, selectedValue];
			}
			console.log(newSelection);

			return newSelection;
		});
	};

	return (
		<Formik<FormValues>
			onSubmit={(values, { resetForm }) => handleSubmit(resetForm)}
			initialValues={{
				checked: selectedProfileIds,
			}}
		>
			{formik => (
				<form onSubmit={formik.handleSubmit}>
					<Modal
						size='xl'
						show={show}
						onHide={() => {
							toggleShow();
							setSelectedProfileIds([]);
						}}
					>
						<Modal.Header closeButton>
							<Modal.Title>
								<h2>SELECT PROFILES</h2>
							</Modal.Title>
						</Modal.Header>
						<Modal.Body className='p-5'>
							<SearchBar
								placeholder='Profile name...'
								value={searchValue}
								onChange={value => {
									setSearchValue(value);
								}}
								fullWidth
							/>
							{filteredProfiles && filteredProfiles.length ? (
								<table className='search-table add-profile-modal-table-text'>
									<thead
										style={{
											display: 'table',
											width: '100%',
											tableLayout: 'fixed',
										}}
									>
										<tr className=''>
											<th
												style={{
													width: '8%',
												}}
											>
												Select
											</th>
											<th>Name</th>
											<th>Roles</th>
										</tr>
									</thead>
									<tbody
										style={{
											display: 'block',
											height: '50vh',
											overflowY: 'auto',
										}}
									>
										{filteredProfiles.map((profile, i) => (
											<ProfileTableRow
												key={i}
												profile={profile}
												selectedProfiles={selectedProfileIds}
												onSelect={handleSelection}
											/>
										))}
									</tbody>
								</table>
							) : (
								<table className='search-table'>
									<thead>
										<tr>
											<th style={{ gridColumn: '1/4', width: '100%' }}>
												{searchValue.trim()
													? 'No profiles found.'
													: savedProfiles?.length === 0
													? "You don't have any saved profiles."
													: 'No profiles available for this recording.'}
											</th>
										</tr>
									</thead>
								</table>
							)}
						</Modal.Body>
						<Modal.Footer>
							<Button label='Close' onClick={() => toggleShow()} />
							{profilesNotInRecording && !!profilesNotInRecording.length && (
								<Button
									label='Add To Recording'
									theme='dark'
									type='submit'
									onClick={() => formik.submitForm()}
									isDisabled={!selectedProfileIds.length}
									isLoading={isImporting}
								/>
							)}
						</Modal.Footer>
					</Modal>
				</form>
			)}
		</Formik>
	);
};

export default ImportPersonalProfilesModal;
