import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import formatFullAddress from '../../../../../../helpers/formatFullAddress';
import TextField from '../../../../../form/TextField';
import IconButton from '../../../../../layout/IconButton';

const SAGAFTRAParticipantCard = ({
	participant,
	onAddressEdit,
	onDelete,
	index,
	formik,
	addressErrorMessage,
}) => {
	const fullAddress = useMemo(() => {
		const { address1, address2, city, state, postalCode, country } =
			participant;

		return formatFullAddress(
			address1,
			address2,
			city,
			state,
			postalCode,
			country
		);
	}, [participant]);

	const invalidAddress = useMemo(() => {
		const { address1, city, state } = participant;

		return !address1 || !city || !state;
	}, [participant]);

	return (
		<div className='complete-participants-row'>
			<Row>
				<Col xs={11} className='d-flex justify-content-start'>
					<i
						className='fas fa-user d-flex align-items-center mr-2'
						style={{ lineHeight: 0 }}
					></i>

					<div
						style={{
							fontWeight: 700,
							fontSize: '1rem',
							lineHeight: 0,
						}}
						className='d-flex align-items-center'
					>
						{participant.legalName
							? participant.legalName
							: participant.creditedName}
					</div>
				</Col>

				<Col xs={1} className='d-flex justify-content-end align-items-start'>
					<IconButton icon='fas fa-times' onClick={onDelete} />
				</Col>

				<Col xs={12}>
					<hr />
				</Col>

				<Col xs={8}>
					<TextField
						label={
							<span className='d-flex'>
								<i className='fas fa-home d-flex align-items-center mr-2'></i>
								<span className='d-flex'>Home Address</span>
							</span>
						}
						readOnly
						value={fullAddress}
						onClick={onAddressEdit}
						isInvalid={invalidAddress}
						errorMessage={addressErrorMessage}
					/>
				</Col>

				<Col xs={4}>
					<TextField
						name={`participants[${index}].socialLastFour`}
						label={
							<span className='d-flex'>
								<i className='fas fa-address-card d-flex align-items-center mr-2'></i>
								<span className='d-flex mr-1'>SSN</span>
								<span className='d-flex optional-italic-blue-label'>
									(Full or Last 4 Digits)
								</span>
							</span>
						}
						placeholder='XXXX'
						value={formik.values.participants[index].socialLastFour}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						isInvalid={
							formik?.touched?.participants?.[index]?.socialLastFour &&
							formik?.errors?.participants?.[index]?.socialLastFour
						}
						errorMessage={formik?.errors?.participants?.[index]?.socialLastFour}
						informationText='Do not use dashes or spaces.'
					/>
				</Col>
			</Row>
		</div>
	);
};

export default SAGAFTRAParticipantCard;
