import React from 'react';
import IconButton from '../../../layout/IconButton';
import '../../Projects/ProjectsTable/ProjectsTable.scss';
import theme from '../../../../theme.module.scss';

const reducedAlbumColumns = [
	{
		Header: () => <span className='Project-Name'>Track</span>,
		accessor: 'trackNumber',
		Cell: col => <span className='Name1'>{col.value}</span>,
	},
	{
		Header: () => <span className='Project-Name'>Title</span>,
		accessor: 'title',
		Cell: col => <span className='Name1'>{col.value}</span>,
	},
	{
		Header: () => <span className='Project-Name'>Artist Name</span>,
		accessor: 'artist',
		Cell: col => <span className='Name1'>{col.value}</span>,
	},
	{
		Header: () => <></>,
		accessor: 'actions',
		Cell: ({ onProjectSelect, row }) => {
			return (
				<IconButton
					color={theme.primary}
					icon='fas fa-chevron-right'
					onClick={() =>
						onProjectSelect({
							albumId: row.canExpand ? row.original.id : null,
							recordingId: !row.canExpand ? row.original.id : null,
						})
					}
				/>
			);
		},
	},
];

export default reducedAlbumColumns;
