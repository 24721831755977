import exportTypes from '../constants/exportType';

export const getSavedExportRecordingIds = savedExport => {
	switch (savedExport.exportType) {
		case exportTypes.AFM_B4:
		case exportTypes.AFM_B9:
		case exportTypes.SAG_AFTRA:
			return savedExport.form.selectedRecordingRows.map(row => row.recordingId);
		default:
			console.log('Unknown export type:', savedExport.exportType);
			return [];
	}
};

export const getSavedExportParticipantIds = savedExport => {
	switch (savedExport.exportType) {
		case exportTypes.AFM_B4:
		case exportTypes.AFM_B9:
		case exportTypes.SAG_AFTRA:
			return savedExport.form.selectedParticipants.map(p => p.id);
		default:
			console.log('Unknown export type:', savedExport.exportType);
			return [];
	}
};
