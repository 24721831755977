import axiosRetry from 'axios-retry';
import { getAudioDuration, getFileProject } from '../../helpers/fileTools';
import { shouldRetry } from '../../helpers/networkTools';
import aiVersionReconcilerApi from '../aiVersionReconcilerApi';

axiosRetry(aiVersionReconcilerApi, {
	retries: 3,
	retryDelay: retryCount => {
		return retryCount * 1000;
	},
	shouldResetTimeout: true,
	retryCondition: shouldRetry,
});

const matchVersionsToPlaylistFiles = async ({
	playlistFiles,
	uploadFiles,
}: {
	playlistFiles: PlaylistFileMetadata[];
	uploadFiles: File[];
}) => {
	const _playlistFiles = playlistFiles.map(file => ({
		id: file.id,
		filename: file.filename,
		duration: file.duration,
		projectTitle: getFileProject(file)!.title,
		projectArtist: getFileProject(file)!.artist,
	}));

	const _uploadFiles = await Promise.all(
		uploadFiles.map(async file => ({
			filename: file.name,
			duration: await getAudioDuration(file),
		}))
	);

	return aiVersionReconcilerApi.post<MatchVersionsToPlaylistFilesResponse>(
		'/ai-version-reconciler',
		{
			playlistFiles: _playlistFiles,
			uploadFiles: _uploadFiles,
		} as MatchVersionsToPlaylistFilesRequest
	);
};

const AiVersionReconcilerService = {
	matchVersionsToPlaylistFiles,
};

export default AiVersionReconcilerService;
