import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useMemo } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { hideModal, setModalTitle } from '../../../../store/modal/actions';
import TextField from '../../../form/TextField';
import Button from '../../../layout/Button';
import * as yup from 'yup';
import {
	getFileMetadataAction,
	updateFileVersionMetadataAction,
} from '../../../../store/files/actions';
import { getFilesById } from '../../../../store/files/selectors';
import path from 'path-browserify';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import SoundCreditLoader from '../../SoundCreditLoader/SoundCreditLoader';
import { fetchPlaylistAction } from '../../../../store/playlists/actions';

const schema = yup.object().shape({
	filename: yup.string().trim().required('File name must not be empty.'),
});

type RenameFileModalForm = {
	filename: string;
};

const RenameFileModal = ({
	fileId,
	playlistId,
}: {
	fileId: FileMetadata['id'];
	playlistId?: Playlist['id'] | null;
}) => {
	const dispatch = useAppDispatch();
	const filesById = useAppSelector(getFilesById);

	const [isFetching, setIsFetching] = React.useState(false);
	const [isSubmitting, setIsSubmitting] = React.useState(false);
	const fetchedRef = React.useRef(false);
	const file = useMemo(() => filesById[fileId] ?? null, [filesById, fileId]);
	const [originalFilename, originalFileExtension] = useMemo(() => {
		if (!file) return ['', ''];

		const { name, ext } = path.parse(file.filename);

		return [name, ext];
	}, [file]);

	const [fileAlreadyExists, setFileAlreadyExists] = React.useState(false);

	const handleCancel = () => {
		dispatch(hideModal());
	};

	const handleSubmit = async (values: RenameFileModalForm) => {
		if (!file) return;

		setIsSubmitting(true);

		try {
			// find if the file name is already in use
			console.log('values', values);
			const newFilename = values.filename + originalFileExtension;
			// TODO: adapt to folder system
			const filenames = Object.values(filesById).map(file => file!.filename);

			if (filenames.includes(newFilename)) {
				setFileAlreadyExists(true);
				return;
			}

			await dispatch(
				updateFileVersionMetadataAction({
					fileId,
					versionId: file?.activeVersion,
					filename: newFilename,
				})
			);

			if (playlistId) {
				await dispatch(fetchPlaylistAction(playlistId));
			}

			dispatch(hideModal());
		} finally {
			setIsSubmitting(false);
		}
	};

	useEffect(() => {
		dispatch(setModalTitle('RENAME FILE'));
	}, [dispatch]);

	useEffect(() => {
		if (file || isFetching || fetchedRef.current) return;

		setIsFetching(true);
		dispatch(getFileMetadataAction(fileId))
			.catch(() => dispatch(hideModal()))
			.finally(() => {
				fetchedRef.current = true;
				setIsFetching(false);
			});
	}, [file, fileId, dispatch, isFetching]);

	return !file ? (
		<Modal.Body>
			<SoundCreditLoader />
		</Modal.Body>
	) : (
		<Formik
			initialValues={{ filename: originalFilename }}
			enableReinitialize
			onSubmit={handleSubmit}
			validationSchema={schema}
			validateOnMount
		>
			{formik => (
				<>
					<Modal.Body className='px-4'>
						<TextField
							dense
							id='filename'
							name='filename'
							value={formik.values.filename}
							onChange={e => {
								setFileAlreadyExists(false);
								formik.handleChange(e);
							}}
							onBlur={formik.handleBlur}
							isInvalid={Boolean(
								formik.errors.filename && formik.touched.filename
							)}
							errorMessage={formik.errors.filename}
						/>

						{fileAlreadyExists && (
							<small className='text-danger'>
								File name is already in use. Please choose another name.
							</small>
						)}
					</Modal.Body>
					<Modal.Footer>
						<Row>
							<Col>
								<Button label='Cancel' onClick={handleCancel} />
								<Button
									label='Rename'
									theme='dark'
									className='ml-2'
									type='submit'
									isLoading={isSubmitting}
									isDisabled={!formik.isValid}
									onClick={formik.handleSubmit as any}
								/>
							</Col>
						</Row>
					</Modal.Footer>
				</>
			)}
		</Formik>
	);
};

export default RenameFileModal;
