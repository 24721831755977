import React from 'react';
import { OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { handleLockedClick } from '../../../helpers/tiersTools';

export type ButtonProps = {
	label: string | React.ReactNode;
	fontSize?: string | number;
	rightIcon?: string;
	leftIcon?: string;
	className?: string;
	onClick?:
		| ((e?: React.MouseEvent<Element, MouseEvent>) => void)
		| ((e: React.MouseEvent<Element, MouseEvent>) => void)
		| (React.MouseEventHandler<Element> & (() => any | Promise<any>));
	theme?: 'dark' | 'light' | 'success' | 'danger' | 'danger-filled';
	type?: 'button' | 'submit' | 'reset';
	isLoading?: boolean;
	size?: 'lg' | null;
	isDisabled?: boolean;
	id?: string;
	isLocked?: boolean;
	onLockedClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	tooltipText?: string | React.ReactNode;
	style?: React.CSSProperties;
};

function Button({
	label,
	fontSize,
	rightIcon = '',
	leftIcon = '',
	className = '',
	onClick,
	theme = 'light',
	type = 'button',
	isLoading = false,
	size = null,
	isDisabled = false,
	id,
	isLocked = false,
	onLockedClick,
	tooltipText = '',
	style = {},
}: ButtonProps) {
	const dispatch = useDispatch();

	return (
		<OverlayTrigger
			placement='auto'
			overlay={tooltipText ? <Tooltip>{tooltipText}</Tooltip> : <></>}
		>
			<div
				style={{ display: 'inline-block', width: size === 'lg' ? '100%' : '' }}
			>
				<button
					id={id}
					className={`
				${
					(theme === 'dark' && `btn text-white bg-purple fw-600`) ||
					(theme === 'light' &&
						`btn border-purple text-purple bg-white fw-600`) ||
					(theme === 'success' && `btn btn-success fw-600`) ||
					(theme === 'danger' && `btn btn-outline-danger fw-600`) ||
					(theme === 'danger-filled' && `btn btn-danger fw-600`)
				} ${className}`}
					style={{
						whiteSpace: 'nowrap',
						width: size === 'lg' ? '100%' : '',
						fontSize: fontSize,
						maxHeight: '45px',
						position: 'relative',
						pointerEvents: isLoading || isDisabled ? 'none' : 'auto',
						...style,
					}}
					onClick={e =>
						handleLockedClick({
							e,
							isLocked,
							onLockedClick,
							onClick,
							dispatch,
							isDisabled: isLoading || isDisabled,
						})
					}
					type={type}
					disabled={isLoading || isDisabled}
				>
					<div className='d-flex justify-content-center align-items-center'>
						{isLoading && (
							<Spinner
								as='span'
								animation='border'
								size='sm'
								role='status'
								aria-hidden='true'
								className='mr-2'
							/>
						)}
						{leftIcon ? <i className={leftIcon + ' mr-2'}></i> : <></>}
						{label}
						{rightIcon ? <i className={rightIcon + ' ml-2'}></i> : <></>}
						{isLocked && (
							<i
								className='fas fa-lock'
								style={{
									position: 'absolute',
									top: -12,
									right: -7,
								}}
							/>
						)}
					</div>
				</button>
			</div>
		</OverlayTrigger>
	);
}

export default Button;
