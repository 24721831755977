import * as yup from 'yup';
import exportTypes from '../../../../../constants/exportType';
import exportValidationTypes from './exportValidationTypes.json';
import writerRoles from '../../../../../constants/writerRoles.json';
import nonMusicianRoleCategories from '../../../../../constants/nonMusicianRoleCategories.json';
import ascendingNumberSort from '../../../../../helpers/ascendingNumberSort';
import {
	flattenYupValidationErrors,
	validateExportObject,
} from './yupExportValidationTools';

// Many exports share the same validation schema, so we can use a map to get the
// validation schema for a given export type.
const getExportValidationType = {
	[exportTypes.LABEL_STYLE_SINGLE]: exportValidationTypes.REGULAR_SINGLE,
	[exportTypes.LABEL_STYLE_RELEASE_LONG]:
		exportValidationTypes.REGULAR_MULTIPLE_CONTINUOUS,
	[exportTypes.LABEL_STYLE_RELEASE_REGULAR]:
		exportValidationTypes.REGULAR_MULTIPLE_CONTINUOUS,
	[exportTypes.LYRIC_SHEET]: exportValidationTypes.LYRIC_SHEET,
	[exportTypes.CD_INSERT_SHORT]: exportValidationTypes.REGULAR,
	[exportTypes.CD_INSERT_LONG]:
		exportValidationTypes.REGULAR_MULTIPLE_CONTINUOUS,
	[exportTypes.LABEL_STYLE_PERSONNEL_SPREADSHEET]:
		exportValidationTypes.REGULAR,
	[exportTypes.SOUND_CREDIT_SINGLE]: exportValidationTypes.REGULAR_SINGLE,
	[exportTypes.SOUND_CREDIT_RELEASE]:
		exportValidationTypes.REGULAR_MULTIPLE_CONTINUOUS,
	[exportTypes.VINYL_SLEEVE_LONG]: exportValidationTypes.REGULAR_MULTIPLE,
	[exportTypes.VINYL_SLEEVE_SHORT]: exportValidationTypes.REGULAR_CONTINUOUS,
	[exportTypes.METADATA_MASTERING]: exportValidationTypes.REGULAR,
	[exportTypes.ALL_MUSIC_SPREADSHEET]: exportValidationTypes.REGULAR_CONTINUOUS,
	[exportTypes.THE_ORCHARD_SPREADSHEET]:
		exportValidationTypes.REGULAR_CONTINUOUS,
	[exportTypes.SOUND_EXCHANGE_SPREADSHEET]:
		exportValidationTypes.SOUND_EXCHANGE,
	[exportTypes.MLC_SPREADSHEET]: exportValidationTypes.MLC,
	[exportTypes.AFM_B4]: exportValidationTypes.UNION_AFM_B4_REGULAR,
	[exportTypes.AFM_B9]: exportValidationTypes.UNION_AFM_B9_REGULAR,
	[exportTypes.SAG_AFTRA]: exportValidationTypes.SAG_AFTRA,
	[exportTypes.BIG_MACHINE_SINGLE]: exportValidationTypes.REGULAR_SINGLE,
	[exportTypes.BIG_MACHINE_ALBUM]:
		exportValidationTypes.REGULAR_MULTIPLE_CONTINUOUS,
	[exportTypes.INSTAGRAM_IMAGE]: exportValidationTypes.REGULAR,
	[exportTypes.STORIES]: exportValidationTypes.REGULAR,
	[exportTypes.FACEBOOK_TWITTER_IMAGE]: exportValidationTypes.REGULAR,
	[exportTypes.CD_BABY_EXPORT_LONG]: exportValidationTypes.REGULAR,
	[exportTypes.CD_BABY_EXPORT_SHORT]: exportValidationTypes.REGULAR,
	[exportTypes.SOUND_CREDIT_EMAIL]: exportValidationTypes.REGULAR,
	[exportTypes.EBR_STANDARD_TEMPLATE]: exportValidationTypes.PRO,
	[exportTypes.PPL_REGISTRATION]: exportValidationTypes.PPL,
	[exportTypes.SPLIT_SHEET_STANDARD]: exportValidationTypes.SPLIT_SHEET,
	[exportTypes.SPLIT_SHEET_SINGLE_WRITER]: exportValidationTypes.SPLIT_SHEET,
	[exportTypes.PRODUCER_AGREEMENT]: exportValidationTypes.PRODUCER_AGREEMENT,
	[exportTypes.SIDE_ARTIST_AGREEMENT]:
		exportValidationTypes.SIDE_ARTIST_AGREEMENT,
	[exportTypes.PUBLISHING_LABEL_COPY_DOCUMENT]:
		exportValidationTypes.PUBLISHING_LABEL_COPY,
	[exportTypes.PUBLISHING_LABEL_COPY_SPREADSHEET]:
		exportValidationTypes.PUBLISHING_LABEL_COPY,
	[exportTypes.DIGITAL_DISTRIBUTION]:
		exportValidationTypes.DIGITAL_DISTRIBUTION,
};

const getCreatorParticipants = participants =>
	participants.filter(participant =>
		participant.roles.some(role =>
			[
				'Arranger',
				'Songwriter',
				'Translator',
				'Lyricist',
				'Sub-Arranger',
			].includes(role.detail)
		)
	);

yup.addMethod(yup.object, 'mlcParticipants', function (errorMessage) {
	return this.test('test-mlc-participants', errorMessage, function (recording) {
		const { createError } = this;

		return validateExportObject({
			createError,
			errorMap: {
				atLeastOneCreator: {
					message:
						'There must be at least one participant with Arranger, Songwriter, Translator, Lyricist or Sub-Arranger role',
					test: participants => getCreatorParticipants(participants).length > 0,
					getValueForTesting: participants => participants,
					getErrorParams: (recording, participants) => ({
						recordingId: recording.id,
						participantIds: null,
						field: 'participants',
					}),
				},
				atMost300CreatorRoles: {
					message:
						'There can be no more than 300 total participants with the roles Arranger, Songwriter, Translator, Lyricist or Sub-Arranger.',
					test: participants =>
						getCreatorParticipants(participants).length <= 300,
					getValueForTesting: participants => participants,
					getErrorParams: (recording, participants) => ({
						recordingId: recording.id,
						participantIds: null,
						field: 'participants',
					}),
				},
				creatorParticipantsHaveOnePublisher: {
					message:
						'Participants with Arranger, Songwriter, Translator, Lyricist or Sub-Arranger roles must have 1 publisher assigned',
					test: participants =>
						getCreatorParticipants(participants).every(
							participant => participant.publishers.length === 1
						),
					getValueForTesting: participants => participants,
					getErrorParams: (recording, participants) => ({
						recordingId: recording.id,
						participantIds: getCreatorParticipants(participants)
							.filter(participant => participant.publishers.length !== 1)
							.map(participant => participant.id), // keep track of which participants have the wrong number of publishers
						field: 'publishers',
					}),
					dependsOn: ['atLeastOneCreator'], // if there are no creators, we don't need to check for publishers
				},
				publisherSplit: {
					message:
						"A participant's publisher must have a split percentage if the participant has Arranger, Songwriter, Translator, Lyricist or Sub-Arranger roles.",
					test: participants =>
						findParticipantsWithSplitlessPublishers(
							getCreatorParticipants(participants)
						).length === 0,
					getValueForTesting: participants => participants,
					getErrorParams: (recording, participants) => ({
						recordingId: recording.id,
						participantIds: findParticipantsWithSplitlessPublishers(
							getCreatorParticipants(participants)
						).map(participant => participant.id), // keep track of which participants have publishers with missing splits
						field: 'publishers',
					}),
					dependsOn: ['atLeastOneCreator'], // if there are no creators, we don't need to check for publishers
				},
				publisherName: {
					message:
						"A participant's publisher must have a name if the participant has Arranger, Songwriter, Translator, Lyricist or Sub-Arranger roles.",
					test: participants =>
						findParticipantsWithNamelessPublishers(
							recording,
							getCreatorParticipants(participants)
						).length === 0,
					getValueForTesting: participants => participants,
					getErrorParams: (recording, participants) => ({
						recordingId: recording.id,
						participantIds: findParticipantsWithNamelessPublishers(
							recording,
							getCreatorParticipants(participants)
						).map(participant => participant.id), // keep track of which participants have publishers with missing names
						field: 'publishers',
					}),
					dependsOn: ['atLeastOneCreator'], // if there are no creators, we don't need to check for publishers
				},
				publisherIpi: {
					message:
						"A participant's publisher must have an IPI if the participant has Arranger, Songwriter, Translator, Lyricist or Sub-Arranger roles.",
					test: participants =>
						findParticipantsWithIpilessPublishers(
							recording,
							getCreatorParticipants(participants)
						).length === 0,
					getValueForTesting: participants => participants,
					getErrorParams: (recording, participants) => ({
						recordingId: recording.id,
						participantIds: findParticipantsWithIpilessPublishers(
							recording,
							getCreatorParticipants(participants)
						).map(participant => participant.id), // keep track of which participants have publishers with missing ipis
						field: 'publishers',
					}),
					dependsOn: ['atLeastOneCreator'], // if there are no creators, we don't need to check for publishers
				},
			},
			parent: recording,
			originalData: recording.participants,
		});
	});
});

const getParticipantsWithInvalidProPublishers = (recording, participants) =>
	participants.filter(participant =>
		participant.publishers.some(publisher => {
			const recordingPublisher = recording.publishers.find(
				recordingPublisher => recordingPublisher.id === publisher.publisherId
			);

			return (
				!recordingPublisher?.name?.trim()?.length ||
				!recordingPublisher?.ipi?.trim()?.length ||
				publisher?.splitPercentage == null
			);
		})
	);

yup.addMethod(yup.object, 'proParticipants', function (errorMessage) {
	return this.test('test-pro-participants', errorMessage, function (recording) {
		const { createError } = this;

		return validateExportObject({
			createError,
			errorMap: {
				atLeastOneCreator: {
					message:
						'There must be at least one participant with Arranger, Songwriter, Translator, Lyricist or Sub-Arranger role',
					test: participants => getCreatorParticipants(participants).length > 0,
					getValueForTesting: participants => participants,
					getErrorParams: (recording, participants) => ({
						recordingId: recording.id,
						participantIds: null,
						field: 'participants',
					}),
				},
				creditedName: {
					message:
						'A participant with Arranger, Songwriter, Translator, Lyricist or Sub-Arranger roles must have a credited name.',
					test: participants =>
						getCreatorParticipants(participants).every(
							participant => !!participant?.creditedName?.trim()
						),
					getValueForTesting: participants => participants,
					getErrorParams: (recording, participants) => ({
						recordingId: recording.id,
						participantIds: getCreatorParticipants(participants)
							.filter(participant => !participant?.creditedName?.trim())
							.map(participant => participant.id), // keep track of which participants have missing credited names
						field: 'creditedName',
					}),
					dependsOn: ['atLeastOneCreator'], // if there are no creators, we don't need to check for credited names
				},
				pro: {
					message:
						'A participant with Arranger, Songwriter, Translator, Lyricist or Sub-Arranger roles must have a PRO.',
					test: participants =>
						getCreatorParticipants(participants).every(
							participant => !!participant?.pro?.trim()
						),
					getValueForTesting: participants => participants,
					getErrorParams: (recording, participants) => ({
						recordingId: recording.id,
						participantIds: getCreatorParticipants(participants)
							.filter(participant => !participant?.pro?.trim())
							.map(participant => participant.id), // keep track of which participants have missing pros
						field: 'pro',
					}),
					dependsOn: ['atLeastOneCreator'], // if there are no creators, we don't need to check for credited names
				},
				ipiCae: {
					message:
						'A participant with Arranger, Songwriter, Translator, Lyricist or Sub-Arranger roles must have an IPI/CAE.',
					test: participants =>
						getCreatorParticipants(participants).every(
							participant => !!participant?.ipiCae?.trim()
						),
					getValueForTesting: participants => participants,
					getErrorParams: (recording, participants) => ({
						recordingId: recording.id,
						participantIds: getCreatorParticipants(participants)
							.filter(participant => !participant?.ipiCae?.trim())
							.map(participant => participant.id), // keep track of which participants have missing ipis
						field: 'ipiCae',
					}),
					dependsOn: ['atLeastOneCreator'], // if there are no creators, we don't need to check for credited names
				},
				publishers: {
					message:
						"A participant's publishers must have name, IPI and a split percentage if the participant has Arranger, Songwriter, Translator, Lyricist or Sub-Arranger roles.",
					test: participants =>
						getParticipantsWithInvalidProPublishers(
							recording,
							getCreatorParticipants(participants)
						).length === 0,
					getValueForTesting: participants => participants,
					getErrorParams: (recording, participants) => ({
						recordingId: recording.id,
						participantIds: getParticipantsWithInvalidProPublishers(
							recording,
							getCreatorParticipants(participants)
						).map(participant => participant.id), // keep track of which participants have publishers with missing fields
						field: 'publishers',
					}),
					dependsOn: ['mustHavePublishers'], // if there are no creators, we don't need to check for credited names
				},
				mustHavePublishers: {
					message:
						'A participant with Arranger, Songwriter, Translator, Lyricist or Sub-Arranger roles must have at least one publisher. If they do not have a publishing company, enter self-published under publisher.',
					test: participants =>
						getCreatorParticipants(participants).every(
							participant => participant.publishers.length > 0
						),
					getValueForTesting: participants => participants,
					getErrorParams: (recording, participants) => ({
						recordingId: recording.id,
						participantIds: getCreatorParticipants(participants)
							.filter(participant => participant.publishers.length === 0)
							.map(participant => participant.id), // keep track of which participants have no publishers
						field: 'publishers',
					}),
					dependsOn: ['atLeastOneCreator'], // if there are no creators, we don't need to check for credited names
				},
			},
			parent: recording,
			originalData: recording.participants,
		});
	});
});

const findParticipantsWithIpilessPublishers = (recording, participants) =>
	participants.filter(participant =>
		participant.publishers.some(
			publisher =>
				!recording.publishers.find(
					recordingPublisher => recordingPublisher.id === publisher.publisherId
				).ipi
		)
	);

const findParticipantsWithNamelessPublishers = (recording, participants) =>
	participants.filter(participant =>
		participant.publishers.some(
			publisher =>
				!recording.publishers.find(
					recordingPublisher => recordingPublisher.id === publisher.publisherId
				).name
		)
	);

const findParticipantsWithSplitlessPublishers = participants =>
	participants.filter(participant =>
		participant.publishers.some(publisher => publisher.splitPercentage == null)
	);

yup.addMethod(yup.object, 'splitSheetParticipants', function (errorMessage) {
	return this.test(
		'test-split-sheet-participants',
		errorMessage,
		function (recording) {
			const { createError } = this;

			return validateExportObject({
				createError,
				errorMap: {
					atLeastOneWriter: {
						message:
							'There must be at least one participant with Songwriter, Composer or Lyricist roles.',
						test: participants =>
							getWriterParticipants(participants).length > 0,
						getValueForTesting: participants => participants,
						getErrorParams: (recording, participants) => ({
							recordingId: recording.id,
							participantIds: null,
							field: 'participants',
						}),
					},
					atMostFifteenWriters: {
						message:
							'There can be no more than 15 total participants with the roles Songwriter, Composer or Lyricist.',
						test: participants =>
							getWriterParticipants(participants).length <= 15,
						getValueForTesting: participants => participants,
						getErrorParams: (recording, participants) => ({
							recordingId: recording.id,
							participantIds: null,
							field: 'participants',
						}),
					},
					email: {
						message:
							'A participant with Songwriter, Composer or Lyricist roles must have an email address in the More Details section.',
						test: writerParticipants =>
							writerParticipants.every(
								participant => participant.email?.trim().length > 0
							),
						getValueForTesting: participants =>
							getWriterParticipants(participants),
						getErrorParams: (recording, writerParticipants) => ({
							recordingId: recording.id,
							participantIds: writerParticipants
								.filter(participant => participant.email?.trim()?.length === 0)
								.map(participant => participant.id), // keep track of which participants are missing emails
							field: 'email',
						}),
						dependsOn: ['atLeastOneWriter'], // if there are no writers, we don't need to check for emails
					},
					publishers: {
						message:
							'A participant with Songwriter, Composer or Lyricist roles must have at least one publisher. If they do not have a publishing company, enter self-published under publisher.',
						test: writerParticipants =>
							writerParticipants.every(
								participant => participant.publishers?.length > 0
							),
						getValueForTesting: participants =>
							getWriterParticipants(participants),
						getErrorParams: (recording, writerParticipants) => ({
							recordingId: recording.id,
							participantIds: writerParticipants
								.filter(participant => participant.publishers?.length === 0)
								.map(participant => participant.id), // keep track of which participants are missing publishers
							field: 'publishers',
						}),
						dependsOn: ['atLeastOneWriter'], // if there are no writers, we don't need to check for publishers
					},
					publisherName: {
						message:
							'Every publisher of a participant with Songwriter, Composer or Lyricist roles must have a name.',
						test: writerParticipants =>
							findParticipantsWithNamelessPublishers(
								recording,
								writerParticipants
							).length === 0,
						getValueForTesting: participants =>
							getWriterParticipants(participants),
						getErrorParams: (recording, writerParticipants) => ({
							recordingId: recording.id,
							participantIds: findParticipantsWithNamelessPublishers(
								recording,
								writerParticipants
							).map(participant => participant.id), // keep track of which participants are missing publisher names
							field: 'publishers',
						}),
						dependsOn: ['publishers'], // if there are no publishers, we don't need to check for publisher names
					},
					publisherSplit: {
						message:
							'Every publisher of a participant with Songwriter, Composer or Lyricist roles must have a split percentage.',
						test: writerParticipants =>
							findParticipantsWithSplitlessPublishers(writerParticipants)
								.length === 0,
						getValueForTesting: participants =>
							getWriterParticipants(participants),
						getErrorParams: (recording, writerParticipants) => ({
							recordingId: recording.id,
							participantIds: findParticipantsWithSplitlessPublishers(
								writerParticipants
							).map(participant => participant.id), // keep track of which participants are missing publisher splits
							field: 'publishers',
						}),
						dependsOn: ['publishers'], // if there are no publishers, we don't need to check for publisher splits
					},
				},
				parent: recording,
				originalData: recording.participants,
			});
		}
	);
});

const getWriterParticipants = participants =>
	participants.filter(
		participant =>
			participant.roles.filter(role => writerRoles.includes(role.detail))
				.length > 0
	);

// need to check that all publishers have a non-null split percentage
// and that there's at least one participant with a writer role
yup.addMethod(
	yup.object,
	'publishingLabelCopyParticipants',
	function (errorMessage) {
		return this.test(
			'test-publishing-label-copy-participants',
			errorMessage,
			function (recording) {
				const { createError } = this;

				return validateExportObject({
					createError,
					errorMap: {
						writer: {
							message:
								'There must be at least one participant with Songwriter, Composer or Lyricist roles.',
							test: participants =>
								getWriterParticipants(participants).length > 0,
							getValueForTesting: participants => participants,
							getErrorParams: (recording, participants) => ({
								recordingId: recording.id,
								participantIds: null,
								field: 'participants',
							}),
						},
						publishers: {
							message:
								'Every publisher of a participant with Songwriter, Composer or Lyricist roles must have a split percentage.',
							test: writerParticipants =>
								findParticipantsWithSplitlessPublishers(writerParticipants)
									.length === 0,
							getValueForTesting: participants =>
								getWriterParticipants(participants),
							getErrorParams: (recording, writerParticipants) => ({
								recordingId: recording.id,
								participantIds: findParticipantsWithSplitlessPublishers(
									writerParticipants
								).map(participant => participant.id), // keep track of which participants are missing publisher splits
								field: 'publishers',
							}),
							dependsOn: ['writer'], // if there are no writers, we don't need to check for publisher splits
						},
					},
					parent: recording,
					originalData: recording.participants,
				});
			}
		);
	}
);

// TODO: Refactor to new validation system after we bring back this export
yup.addMethod(yup.array, 'sideArtistAgreementRoles', function (errorMessage) {
	return this.test(
		'test-side-artist-agreement-roles',
		errorMessage,
		function (participants) {
			const { path, createError } = this;

			const musicianParticipants = participants.filter(
				participant =>
					participant.roles.filter(
						role => !nonMusicianRoleCategories.includes(role.category)
					).length > 0
			);

			if (musicianParticipants.length === 0) {
				return createError({
					path,
					message:
						'There must be at least one participant with Musician roles.',
				});
			}

			const errorMap = {
				email: {
					pass: true,
					message:
						'A participant with Musician roles must have an email address in the More Details section.',
					invalidValue: null,
				},
			};

			musicianParticipants.forEach(participant => {
				if (!participant.email || participant.email.trim().length === 0) {
					errorMap.email.pass = false;
					errorMap.email.invalidValue = participant;
				}
			});

			const errors = Object.values(errorMap)
				.filter(error => !error.pass)
				.map(error => new yup.ValidationError(error.message));

			return (
				errors.length === 0 || createError({ path, message: () => errors })
			);
		}
	);
});

const getProducerParticipants = participants =>
	participants.filter(
		participant =>
			participant.roles.filter(role => role.detail === 'Producer').length > 0
	);

yup.addMethod(yup.array, 'producerAgreementRoles', function (errorMessage) {
	return this.test(
		'test-producer-agreement-roles',
		errorMessage,
		function (participants) {
			const { parent, createError } = this;

			return validateExportObject({
				createError,
				errorMap: {
					producer: {
						message:
							'There must be exactly one participant with Producer role.',
						test: participants =>
							getProducerParticipants(participants).length === 1,
						getValueForTesting: participants => participants,
						getErrorParams: (recording, participants) => ({
							recordingId: recording.id,
							participantIds: null,
							field: 'participants',
						}),
					},
					producerEmail: {
						message:
							'A participant with Producer role must have an email address in the More Details section.',
						test: producerParticipant =>
							producerParticipant?.email?.trim().length > 0,
						getValueForTesting: participants =>
							getProducerParticipants(participants)[0],
						getErrorParams: (recording, producerParticipant) => ({
							recordingId: recording.id,
							participantIds: [producerParticipant.id],
							field: 'email',
						}),
						dependsOn: ['producer'], // if there is no producer, we don't need to check for an email
					},
				},
				parent,
				originalData: participants,
				type: 'producerAgreementRoles',
			});
		}
	);
});

yup.addMethod(yup.array, 'atLeastOneFeatured', function (errorMessage) {
	return this.test(
		'test-at-least-one-featured',
		errorMessage,
		function (participants) {
			const { createError } = this;

			return validateExportObject({
				createError,
				errorMap: {
					atLeastOneFeaturedParticipant: {
						message: 'At least one participant must be a Featured Artist.',
						test: participants =>
							participants.filter(participant => participant.isFeatured)
								.length > 0,
						getValueForTesting: participants => participants,
						getErrorParams: (recording, participants) => ({
							recordingId: recording.id,
							participantIds: null,
							field: 'participants',
						}),
					},
				},
			});
		}
	);
});

yup.addMethod(yup.array, 'mustNotSkipTrackNumbers', function (errorMessage) {
	return this.test(
		'test-must-not-skip-track-numbers',
		errorMessage,
		function (recordings) {
			const { createError } = this;

			if (recordings?.length === 1 && !recordings[0].albums) {
				return true;
			}

			return validateExportObject({
				createError,
				errorMap: {
					trackNumbers: {
						message: 'Track numbers must not skip in order.',
						test: recordings => {
							const trackNumbers = recordings
								.filter(recording => recording.albums[0].trackNumber) // trackNumber starts at 1, no need to check for 0
								.map(recording => recording.albums[0].trackNumber)
								.sort(ascendingNumberSort);
							const minTrackNumber = trackNumbers[0];

							const expectedTrackNumbers = [
								...Array(trackNumbers.length).keys(),
							].map(i => i + minTrackNumber);

							return (
								JSON.stringify(trackNumbers) ===
								JSON.stringify(expectedTrackNumbers)
							);
						},
						getValueForTesting: recordings => recordings,
						getErrorParams: (recording, recordings) => ({
							recordingId: null,
							participantIds: null,
							field: 'recordings',
						}),
					},
				},
				parent: null,
				originalData: recordings,
			});
		}
	);
});

const getRecordingValidationSchema = {
	[exportValidationTypes.REGULAR]: yup.object().shape({
		title: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Recording song name is required'),
		mainArtist: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Recording artist name is required'),
		participants: yup
			.array()
			.of(
				yup.object().shape({
					roles: yup
						.array()
						.min(1, 'At least one role per participant is required'),
				})
			)
			.min(1, 'At least one participant is required'),
		albums: yup
			.array()
			.of(
				yup.object().shape({
					title: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album title is required'),
					artistName: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album main artist name is required'),
				})
			)
			.nullable(),
	}),
	[exportValidationTypes.REGULAR_SINGLE]: yup.object().shape({
		title: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Recording song name is required'),
		mainArtist: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Recording artist name is required'),
		participants: yup
			.array()
			.of(
				yup.object().shape({
					roles: yup
						.array()
						.min(1, 'At least one role per participant is required'),
				})
			)
			.min(1, 'At least one participant is required'),
		albums: yup
			.array()
			.of(
				yup.object().shape({
					title: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album title is required'),
					artistName: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album main artist name is required'),
				})
			)
			.nullable(),
	}),
	[exportValidationTypes.SPLIT_SHEET]: yup
		.object()
		.shape({
			title: yup
				.string()
				.nullable()
				.transform((curr, orig) => (orig === null ? '' : curr))
				.required('Recording song name is required'),
			mainArtist: yup
				.string()
				.nullable()
				.transform((curr, orig) => (orig === null ? '' : curr))
				.required('Recording artist name is required'),
			participants: yup
				.array()
				.of(
					yup.object().shape({
						roles: yup
							.array()
							.min(1, 'At least one role per participant is required'),
					})
				)
				.min(1, 'At least one participant is required'),
			albums: yup
				.array()
				.of(
					yup.object().shape({
						title: yup
							.string()
							.nullable()
							.transform((curr, orig) => (orig === null ? '' : curr))
							.required('Album title is required'),
						artistName: yup
							.string()
							.nullable()
							.transform((curr, orig) => (orig === null ? '' : curr))
							.required('Album main artist name is required'),
					})
				)
				.nullable(),
		})
		.splitSheetParticipants(),
	[exportValidationTypes.PUBLISHING_LABEL_COPY]: yup
		.object()
		.shape({
			title: yup
				.string()
				.nullable()
				.transform((curr, orig) => (orig === null ? '' : curr))
				.required('Recording song name is required'),
			mainArtist: yup
				.string()
				.nullable()
				.transform((curr, orig) => (orig === null ? '' : curr))
				.required('Recording artist name is required'),
			participants: yup
				.array()
				.of(
					yup.object().shape({
						roles: yup
							.array()
							.min(1, 'At least one role per participant is required'),
					})
				)
				.min(1, 'At least one participant is required'),
			albums: yup
				.array()
				.of(
					yup.object().shape({
						title: yup
							.string()
							.nullable()
							.transform((curr, orig) => (orig === null ? '' : curr))
							.required('Album title is required'),
						artistName: yup
							.string()
							.nullable()
							.transform((curr, orig) => (orig === null ? '' : curr))
							.required('Album main artist name is required'),
					})
				)
				.nullable(),
		})
		.publishingLabelCopyParticipants(),
	[exportValidationTypes.PRODUCER_AGREEMENT]: yup.object().shape({
		title: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Recording song name is required'),
		mainArtist: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Recording artist name is required'),
		participants: yup
			.array()
			.of(
				yup.object().shape({
					roles: yup
						.array()
						.min(1, 'At least one role per participant is required'),
				})
			)
			.min(1, 'At least one participant is required')
			.producerAgreementRoles(),
		albums: yup
			.array()
			.of(
				yup.object().shape({
					title: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album title is required'),
					artistName: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album main artist name is required'),
				})
			)
			.nullable(),
	}),
	[exportValidationTypes.SIDE_ARTIST_AGREEMENT]: yup.object().shape({
		title: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Recording song name is required'),
		mainArtist: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Recording artist name is required'),
		participants: yup
			.array()
			.of(
				yup.object().shape({
					roles: yup
						.array()
						.min(1, 'At least one role per participant is required'),
				})
			)
			.min(1, 'At least one participant is required')
			.sideArtistAgreementRoles(),
		albums: yup
			.array()
			.of(
				yup.object().shape({
					title: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album title is required'),
					artistName: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album main artist name is required'),
				})
			)
			.nullable(),
	}),
	[exportValidationTypes.REGULAR_MULTIPLE]: yup.object().shape({
		title: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Recording song name is required'),
		mainArtist: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Recording artist name is required'),
		participants: yup
			.array()
			.of(
				yup.object().shape({
					roles: yup
						.array()
						.min(1, 'At least one role per participant is required'),
				})
			)
			.min(1, 'At least one participant is required'),
		albums: yup
			.array()
			.of(
				yup.object().shape({
					title: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album title is required'),
					artistName: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album main artist name is required'),
				})
			)
			.nullable(),
	}),
	[exportValidationTypes.LYRIC_SHEET]: yup.object().shape({
		title: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Recording song name is required'),
		mainArtist: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Recording artist name is required'),
		lyrics: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Lyrics are required'),
		participants: yup
			.array()
			.of(
				yup.object().shape({
					roles: yup
						.array()
						.min(1, 'At least one role per participant is required'),
				})
			)
			.min(1, 'At least one participant is required'),
		albums: yup
			.array()
			.of(
				yup.object().shape({
					title: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album title is required'),
					artistName: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album main artist name is required'),
				})
			)
			.nullable(),
	}),
	[exportValidationTypes.SOUND_EXCHANGE]: yup.object().shape({
		title: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Recording song name is required'),
		mainArtist: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Recording artist name is required'),
		releaseDate: yup
			.date()
			.nullable()
			.transform((curr, orig) => (orig === '' ? null : curr))
			.required('Release date is required'),
		participants: yup
			.array()
			.of(
				yup.object().shape({
					roles: yup
						.array()
						.min(1, 'At least one role per participant is required'),
				})
			)
			.min(1, 'At least one participant is required')
			.atLeastOneFeatured(),
		albums: yup
			.array()
			.of(
				yup.object().shape({
					title: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album title is required'),
					artistName: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album main artist name is required'),
					trackNumber: yup.number().required('Track number is required'),
				})
			)
			.nullable(),
	}),
	[exportValidationTypes.MLC]: yup
		.object()
		.shape({
			title: yup
				.string()
				.nullable()
				.transform((curr, orig) => (orig === null ? '' : curr))
				.required('Recording song name is required'),
			mainArtist: yup
				.string()
				.nullable()
				.transform((curr, orig) => (orig === null ? '' : curr))
				.required('Recording artist name is required'),
			participants: yup
				.array()
				.of(
					yup.object().shape({
						roles: yup
							.array()
							.min(1, 'At least one role per participant is required'),
					})
				)
				.min(1, 'At least one participant is required'),
			// .array()
			// .of(
			// 	yup.object().shape({
			// 		roles: yup
			// 			.array()
			// 			.min(1, 'At least one role per participant is required'),
			// 		publishers: yup.array().when('roles', {
			// 			is: roles =>
			// 				roles.some(role =>
			// 					[
			// 						'Arranger',
			// 						'Songwriter',
			// 						'Translator',
			// 						'Lyricist',
			// 						'Sub-Arranger',
			// 					].includes(role.detail)
			// 				),
			// 			then: yup
			// 				.array()
			// 				.of(
			// 					yup.object().shape({
			// 						splitPercentage: yup
			// 							.number()
			// 							.required('Publisher Split Percentage Is Required'),
			// 					})
			// 				)
			// 				.length(
			// 					1,
			// 					'Arranger, Songwriter, Translator, Lyricist, and Sub-Arranger Participants Must Have 1 Publisher'
			// 				),
			// 		}),
			// 	})
			// )
			// .min(1, 'At least one participant is required'),
			albums: yup
				.array()
				.of(
					yup.object().shape({
						title: yup
							.string()
							.nullable()
							.transform((curr, orig) => (orig === null ? '' : curr))
							.required('Album title is required'),
						artistName: yup
							.string()
							.required('Album main artist name is required'),
					})
				)
				.nullable(),
		})
		.mlcParticipants(),
	[exportValidationTypes.UNION_AFM_B4_REGULAR]: yup.object().shape({
		title: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Recording song name is required'),
		mainArtist: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Recording artist name is required'),
		albums: yup
			.array()
			.of(
				yup.object().shape({
					title: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album title is required'),
					artistName: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album main artist name is required'),
				})
			)
			.nullable(),
	}),
	[exportValidationTypes.UNION_AFM_B9_REGULAR]: yup.object().shape({
		title: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Recording song name is required'),
		mainArtist: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Recording artist name is required'),
		albums: yup
			.array()
			.of(
				yup.object().shape({
					title: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album title is required'),
					artistName: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album main artist name is required'),
				})
			)
			.nullable(),
	}),
	[exportValidationTypes.SAG_AFTRA]: yup.object().shape({
		title: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Recording song name is required'),
		mainArtist: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Recording artist name is required'),
		albums: yup
			.array()
			.of(
				yup.object().shape({
					title: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album title is required'),
					artistName: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album main artist name is required'),
				})
			)
			.nullable(),
	}),
	[exportValidationTypes.PPL]: yup.object().shape({
		title: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Recording song name is required'),
		mainArtist: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Recording artist name is required'),
		isrc: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('ISRC is required'),
		recordLabel: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Song Record label is required'),
		releaseDate: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Release Date is required'),
		participants: yup
			.array()
			.of(
				yup.object().shape({
					roles: yup
						.array()
						.min(1, 'At least one role per participant is required'),
				})
			)
			.min(1, 'At least one participant is required')
			.test(
				'at-least-one-copyright-owner',
				'At least one participant must be a Copyright Owner',
				participants =>
					participants.some(
						participant => participant.isCopyrightOwner === true
					)
			)
			.test(
				'copyright-owner-legalName',
				'Copyright Owner legal name is required',
				participants =>
					participants.every(
						participant =>
							!participant.isCopyrightOwner ||
							(participant.legalName && participant.legalName.trim() !== '')
					)
			)
			.test(
				'copyright-owner-country',
				'Copyright Owner country is required',
				participants =>
					participants.every(
						participant =>
							!participant.isCopyrightOwner ||
							(participant.country && participant.country.trim() !== '')
					)
			),
		albums: yup
			.array()
			.of(
				yup.object().shape({
					title: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album title is required'),
					artistName: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album main artist name is required'),
					recordLabel: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Record label is required'),
				})
			)
			.nullable(),
	}),
	[exportValidationTypes.PRO]: yup
		.object()
		.shape({
			title: yup
				.string()
				.nullable()
				.transform((curr, orig) => (orig === null ? '' : curr))
				.required('Recording song name is required'),
			mainArtist: yup
				.string()
				.nullable()
				.transform((curr, orig) => (orig === null ? '' : curr))
				.required('Recording artist name is required'),
			participants: yup.array(),
		})
		.proParticipants(),
	[exportValidationTypes.REGULAR_CONTINUOUS]: yup.object().shape({
		title: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Recording song name is required'),
		mainArtist: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Recording artist name is required'),
		participants: yup
			.array()
			.of(
				yup.object().shape({
					roles: yup
						.array()
						.min(1, 'At least one role per participant is required'),
				})
			)
			.min(1, 'At least one participant is required'),
		albums: yup
			.array()
			.of(
				yup.object().shape({
					title: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album title is required'),
					artistName: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album main artist name is required'),
					trackNumber: yup.number().required('Track number is required'),
				})
			)
			.nullable(),
	}),
	[exportValidationTypes.REGULAR_MULTIPLE_CONTINUOUS]: yup.object().shape({
		title: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Recording song name is required'),
		mainArtist: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Recording artist name is required'),
		participants: yup
			.array()
			.of(
				yup.object().shape({
					roles: yup
						.array()
						.min(1, 'At least one role per participant is required'),
				})
			)
			.min(1, 'At least one participant is required'),
		albums: yup
			.array()
			.of(
				yup.object().shape({
					title: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album title is required'),
					artistName: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album main artist name is required'),
					trackNumber: yup.number().required('Track number is required'),
				})
			)
			.nullable(),
	}),
	[exportValidationTypes.DIGITAL_DISTRIBUTION]: yup.object().shape({
		title: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Recording song name is required'),
		mainArtist: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Recording artist name is required'),
		participants: yup
			.array()
			.of(
				yup.object().shape({
					roles: yup
						.array()
						.min(1, 'At least one role per participant is required'),
				})
			)
			.min(1, 'At least one participant is required'),
		duration: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Song duration is required'),
		genre: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('Song genre is required'),
		isrc: yup
			.string()
			.nullable()
			.transform((curr, orig) => (orig === null ? '' : curr))
			.required('ISRC is required'),

		albums: yup
			.array()
			.of(
				yup.object().shape({
					title: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album/Release title is required'),
					artistName: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album/Release main artist name is required'),
					trackNumber: yup.number().required('Track number is required'),
					genre: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album/Release genre is required'),
					releaseDate: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album/Release release date is required'),
					upcEan: yup
						.string()
						.nullable()
						.transform((curr, orig) => (orig === null ? '' : curr))
						.required('Album/Release UPC/EAN is required'),
				})
			)
			.nullable(),
	}),
};

const getRecordingArrayValidationSchema = {
	[exportValidationTypes.REGULAR_SINGLE]: yup
		.array()
		.max(1, 'Only One Recording Is Allowed For Singles'),
	[exportValidationTypes.SPLIT_SHEET]: yup
		.array()
		.max(1, 'Only One Recording Is Allowed For This Type Of Export'),
	[exportValidationTypes.REGULAR_MULTIPLE]: yup
		.array()
		.min(2, 'At Least Two Recordings Are Required'),
	[exportValidationTypes.REGULAR_MULTIPLE_CONTINUOUS]: yup
		.array()
		.mustNotSkipTrackNumbers()
		.min(2, 'At Least Two Recordings Are Required'),
	[exportValidationTypes.REGULAR_CONTINUOUS]: yup
		.array()
		.mustNotSkipTrackNumbers(),
	[exportValidationTypes.SOUND_EXCHANGE]: yup.array().mustNotSkipTrackNumbers(),
};

/**
 *
 * @param {ExportPayload} payload
 * @param {ExportType} exportType
 * @returns
 */
export const validateExport = (payload, exportType) => {
	console.log('EXPORT PAYLOAD', payload);
	const validationType = getExportValidationType[exportType];

	// Validation recording-level fields
	const recordingValidationSchema =
		getRecordingValidationSchema[validationType];

	// Some exports contain multiple recordings, so for those cases we need to validate the array of recordings
	const recordingArrayValidationSchema =
		getRecordingArrayValidationSchema[validationType] ?? yup.array();

	// We treat single-recording exports as an array of one recording,
	// so we still use the array validation schema
	const validationSchema = yup.object().shape({
		recordings: recordingArrayValidationSchema.test(
			'recordings',
			'',
			/**
			 * @param {Recording[]} recordings
			 */
			async function (recordings) {
				const { path, createError } = this;

				// Use map to remove duplicated error messages for multiple participants
				// TODO: List participant name in error message
				// This object looks like this:
				// {
				//    2147: {   (recording ID)
				//			'participants': {  (or whatever other path property that failed validation)
				//					... yup validation error object
				//					params: {
				//						... yup validation error params object
				//						participantIds: [Array of participant IDs] // in case the error is related to a specific list of participants
				//						recordingId: 2147 // in case the error is related to a specific recording.
				//						field: 'email' // in case the error is related to a specific field in the participant object
				//						-- ideally we want the user to be able to recover from the error by fixing the field in question
				//						-- and in general, the field will be fixable if there's a non-null participantIds array
				//						-- and a non-null field property that references a field in the participant object

				// 						-- if the validation is not from a custom method (i.e. added using yup.addMethod), then
				//						-- the recordingId, participantIds, and field properties will not be present in the params object.
				//					}
				//			}
				//    }
				// }
				//
				const errors = {};

				for (const recording of recordings) {
					try {
						await recordingValidationSchema.validate(recording, {
							abortEarly: false,
						});
					} catch (err) {
						console.error('VALIDATION ERROR 2', err, err?.errors);

						if (!(err instanceof yup.ValidationError)) {
							throw err;
						}

						errors[recording.id] = flattenYupValidationErrors(err);

						// OLD ERROR STRUCTURE (plain ol' string)
						// err?.errors?.forEach(
						// 	error => (errors[`${recording.title} - ${error}`] = true)
						// );
					}
				}

				return (
					Object.keys(errors).length === 0 ||
					createError({
						path,
						params: { result: errors },
						// we store the custom error object in the params property
						// since the validate object HAS to return a yup.ValidationError object

						// It will be accessible from the first element in the "inner" array of the
						// return value of this function (i.e. returnValue.inner[0].params.result)
					})
				);
			}
		),
	});

	return validationSchema.validate(payload, { abortEarly: false });
};
