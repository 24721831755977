import { useFormikContext } from 'formik';
import { UploadVersionsToPlaylistForm } from '../UploadVersionsToPlaylistModal';
import { Modal } from 'react-bootstrap';
import FileDragAndDropInput from '../../../../../layout/FileDragAndDropInput';
import { useCallback } from 'react';
import {
	convertFileEntryToFile,
	traverse_directory,
} from '@/helpers/fileTools';

type SelectVersionFilesStepProps = {
	onSubmit: (inputFiles: FileList) => void | Promise<void>;
};

const SelectVersionFilesStep = ({ onSubmit }: SelectVersionFilesStepProps) => {
	const formik = useFormikContext<UploadVersionsToPlaylistForm>();

	const handleDropzoneInputChange = useCallback(
		async (acceptedFiles: any[]) => {
			let individualFiles: File[] = [];

			let webkitItems = acceptedFiles;
			let directories: { [key: string]: File[] } = {};

			const promises: any[] = [];

			const folderFilesDict: { [key: string]: File[] } = {};

			// First, we take a look at all the files/folders uploaded
			// We traverse each folder received in drag and drop/select
			let directoriesCount: number = 0;

			for (var i = 0; i < webkitItems.length; i++) {
				if (webkitItems[i].isDirectory) {
					let folderFiles: File[] = [];
					console.log('Webkit Dir:', webkitItems[i]);
					const key = webkitItems[i].name;
					directories[key] = [];
					directoriesCount++;

					const promise = traverse_directory(
						webkitItems[i],
						directories,
						key,
						folderFiles
					).then((items: any) => {
						// AT THIS POINT THE DIRECTORY SHOULD BE FULLY TRAVERSED.

						folderFilesDict[key] = folderFiles;
						// return result;
					});
					promises.push(promise);
				} else if (webkitItems[i].isFile) {
					const file = await convertFileEntryToFile(webkitItems[i]);
					individualFiles.push(file);
				}
			}

			if (directoriesCount === 0 && individualFiles.length === 0) {
				individualFiles = acceptedFiles;
			}

			await Promise.all(promises);

			const folderFiles = Object.values(folderFilesDict).flat();

			const allFilesList = [...individualFiles, ...folderFiles];

			const dataTransfer = new DataTransfer();
			allFilesList.forEach(file => dataTransfer.items.add(file));
			return await onSubmit(dataTransfer.files);
		},
		[]
	);

	return (
		<Modal.Body>
			<div className='px-2'>
				<FileDragAndDropInput
					inputFiles={formik.values.files.map(file => file.file)}
					// onSelect={onSubmit}
					onSelect={handleDropzoneInputChange}
					onDrop={onSubmit}
				/>
			</div>
		</Modal.Body>
	);
};

export default SelectVersionFilesStep;
