import { Dictionary, groupBy, omit } from 'lodash';
import path from 'path-browserify';
import { getFileDownloadLink } from '../api/services/filesService';
import translateApiFileToLocal from './translateApiFileToLocal';
import axios from 'axios';
import { Buffer } from 'buffer';
import { hideModal, showModalAction } from '../store/modal/actions';
import { deleteExportAction } from '../store/exports/actions';
import {
	CONFIRMATION_MODAL,
	CORRUPTED_FILES_MODAL,
	DELETE_FILE_MODAL,
	EXPORT_PREVIEW_MODAL,
	IMAGE_CROP_MODAL,
	RENAME_FILE_MODAL,
	SHARE_FILES_MODAL,
	STORAGE_LIMIT_MODAL,
	UPLOAD_PREVIEW_MODAL,
} from '../constants/modalTypes';
import { downloadExport } from '../api/services/exportsService';
import { replacePathVariables } from './routeTools';
import ROUTES from '../router/routes';
import { filterItemsBySearchTerm } from './searchTools';
import { MimeType, fileTypeFromStream, FileExtension } from 'file-type';
import { supportedExtensions, supportedMimeTypes } from 'file-type/core';
import {
	deleteFilesAction,
	uploadFileCoverImageAction,
} from '../store/files/actions';
import { IMAGE_FILE_TYPES } from '../components/form/ImageCropInput/ImageCropInput';
import { getUserProjectsAction } from '../store/projects/actions';
import { sha256Regex } from './regex';
import { v4 as uuidv4 } from 'uuid';
import { AppDispatch } from '../store';
import { NavigateFunction } from 'react-router-dom';
import { OverflowMenuOption } from '../components/layout/OverflowMenu/OverflowMenu';
import { hasReachedStorageLimit } from './tiersTools';
import async from 'async';
import { FilesState } from '../store/files/reducer';
import { isElectron, isSafari } from 'react-device-detect';
import WritableNativeFileHandle from './writableNativeFileHandle';
import { IAudioMetadata } from 'music-metadata-browser';

export const MAX_IMAGE_SIZE = 50000000; // 50 MB
export const COVER_IMAGE_MAX_CROP_WIDTH = 500;
export const COVER_IMAGE_MAX_CROP_HEIGHT = 500;
export const COVER_IMAGE_ASPECT_RATIO =
	COVER_IMAGE_MAX_CROP_WIDTH / COVER_IMAGE_MAX_CROP_HEIGHT;
export const PLAYLIST_HEADER_MAX_CROP_WIDTH = 2660;
export const PLAYLIST_HEADER_MAX_CROP_HEIGHT = 1140;
export const PLAYLIST_HEADER_ASPECT_RATIO =
	PLAYLIST_HEADER_MAX_CROP_WIDTH / PLAYLIST_HEADER_MAX_CROP_HEIGHT;

export const SAFARI_MAX_FILE_SIZE = 4_000_000_000; // 4 GB

export const DEFAULT_FILE_LABEL = 0;

const validateFilenameWithHashFormat = (filename: string) => {
	const extension = getExtensionFromFileMetadata({ filename });
	const partWithTag = extension === '' ? filename : extension;

	const parts = partWithTag.split('_');

	if (parts.length < 3) {
		console.debug(
			'validateFilenameWithHashFormat: filename tag format is pre-versions'
		);
	} else if (!parts[parts.length - 1].match(sha256Regex)) {
		// filenameParts[2] is the SHA256 hash
		console.debug(
			'validateFilenameWithHashFormat: filename tag hash format does not match SHA256'
		);
	} else {
		return true;
	}

	return false;
};

const getPathBaseName = (filePath: string) =>
	decodeURIComponent(path.basename(filePath));

export const getFileHashFromPath = (filePath: string) => {
	const filename = getPathBaseName(filePath);

	if (!validateFilenameWithHashFormat(filename)) {
		return null;
	}

	const filenameParts = filename.split('_');

	return filenameParts[filenameParts.length - 1];
};

const removeTag = (tagged: string) => {
	const parts = tagged.split('_');
	return parts[0];
};

export const getFilenameFromMetadata = ({
	metadata,
	isDisplayFile = false,
}: {
	metadata: FileMetadata;
	isDisplayFile?: boolean;
}) => {
	if (isDisplayFile) {
		return metadata.filename;
	}

	const activeVersionId = metadata.activeVersion;
	const activeVersion = metadata?.versions?.[activeVersionId];

	return activeVersion?.filename ?? '';
};

/**
 * @deprecated
 * @param {string} filePath : the corresponding internal file system path, containing URL encoded characters
 * @returns {string} : the name of the file, with any URL encoded characters replaced with their original form
 */
export const getFilenameFromPath = (filePath: string) => {
	const basename = getPathBaseName(filePath);

	if (!validateFilenameWithHashFormat(basename)) {
		return basename;
	}

	// remove _<timestamp>_<hash> from the extension (or the whole filename if there is no extension)
	const { name, ext } = path.parse(basename);

	if (ext === '') {
		return removeTag(name);
	}
	return name + removeTag(ext);
};

const createFilenameWithHashFormat = (
	filename: string,
	fileHash?: string | null
) => {
	if (!fileHash) {
		return filename;
	}

	return `${filename}_${new Date().getTime()}_${fileHash}`;
};

export const getFilePathFromNewName = (newName: string, prevPath: string) =>
	path.dirname(prevPath) +
	'/' +
	encodeURIComponent(
		createFilenameWithHashFormat(newName, getFileHashFromPath(prevPath))
	);

export const generateFilePath = ({ filename }: { filename: string }) => {
	const ext = path.extname(filename);
	const uuid = uuidv4();

	return `${uuid}${ext}`;
};

// /**
//  * @deprecated
//  * @param {object} file : the file object to be formatted
//  * @param {File} file.file : the file blob
//  * @param {string} file.filename : the name of the file
//  * @param {string} file.folderPath : the path of the folder that contains the file
//  * @param {number} [file.albumId] : the id of the album that contains the file
//  * @param {number} [file.recordingId] : the id of the recording that contains the file
//  * @param {number} file.userId : the id of the user that owns the file
//  */
// export const generateFilePath = async ({
// 	file,
// 	filename,
// 	folderPath,
// 	albumId,
// 	recordingId,
// 	userId,
// }) => {
// 	if (!albumId && !recordingId) {
// 		throw new Error(
// 			'generateFilePath: albumId or recordingId must be provided'
// 		);
// 	}

// 	const fileHash = await sha256FromBlob(file);
// 	const timestamp = new Date().getTime();

// 	const newFilename = `${filename}_${timestamp}_${fileHash}`;

// 	// ! root is '', thus every folder path that is not root must end with '/'
// 	return `${userId}/${recordingId ? 'recording' : 'album'}/${
// 		albumId || recordingId
// 	}${folderPath}/${encodeURIComponent(newFilename)}`;
// };

/**
 * @param {object} imageData : the image data to be used to generate the image path
 * @param {File} imageData.image : the image file, required for hash
 * @param {number} [imageData.playlistId] : the id of the playlist that contains the image
 * @param {number} [imageData.fileId] : the id of the file that contains the image
 * @param {number} imageData.userId : the id of the user that owns the image
 * @param {boolean} [imageData.isHeader] : whether the image is a header image. Use false for cover images (default)
 * @returns {Promise<string>} : the key of the image file in the S3 bucket
 */
export const generateCoverImagePath = async ({
	image,
	playlistId,
	fileId,
	userId,
	isHeader = false,
}: {
	image: File;
	playlistId?: number;
	fileId?: number;
	userId: number;
	isHeader?: boolean;
}) => {
	if (!playlistId && !fileId) {
		throw new Error(
			'generateCoverImagePath: playlistId or fileId must be provided'
		);
	}

	const imageHash = await sha256FromBlob(image);

	const extension = (await getFileTypeFromFileObj(image))?.ext ?? '';

	const timestamp = new Date().getTime();

	return `${userId}/${playlistId ? 'playlist' : 'file'}/${
		playlistId || fileId
	}/images/${
		isHeader ? 'header' : 'cover'
	}/${imageHash}-${timestamp}.${extension}`;
};

export const computeFilenames = (
	newFiles: {
		filename: string;
		recordingId?: number | null;
		albumId?: number | null;
	}[],
	filesByProjectId: FilesByProjectIdType,
	uploadsByProjectId: UploadsByProjectIdType
) => {
	type FilenamesByProjectIdType = {
		byAlbumId: { [albumId: number]: string[] };
		byRecordingId: { [recordingId: number]: string[] };
	};

	type IndexedFilenamesByProjectIdType = {
		byAlbumId: { [albumId: number]: { name: string; index: number }[] };
		byRecordingId: {
			[recordingId: number]: { name: string; index: number }[];
		};
	};

	const computedFilenamesByProjectId: IndexedFilenamesByProjectIdType = {
		byAlbumId: {},
		byRecordingId: {},
	};
	// ! WE NEED TO GUARANTEE THAT THE FILES FOR ALL REFERENCED PROJECTS HAVE ALREADY BEEN FETCHED
	const storedFilenamesByProjectId: FilenamesByProjectIdType = {
		byAlbumId: {},
		byRecordingId: {},
	};

	// Get used file names from the store for all referenced projects
	newFiles.forEach(newFile => {
		if (
			newFile.recordingId &&
			!storedFilenamesByProjectId.byRecordingId[newFile.recordingId]
		) {
			storedFilenamesByProjectId.byRecordingId[newFile.recordingId] = [
				...Object.values(
					filesByProjectId.byRecordingId[newFile.recordingId] ?? {}
				)?.map(file => file.filename),
				...Object.values(
					uploadsByProjectId.byRecordingId[newFile.recordingId] ?? {}
				)?.map(upload => upload.metadata.filename),
			];

			computedFilenamesByProjectId.byRecordingId[newFile.recordingId] = [];

			return;
		}

		if (
			newFile.albumId &&
			!storedFilenamesByProjectId.byAlbumId[newFile.albumId]
		) {
			storedFilenamesByProjectId.byAlbumId[newFile.albumId] = [
				...Object.values(
					filesByProjectId.byAlbumId[newFile.albumId] ?? {}
				)?.map(file => file.filename),
				...Object.values(
					uploadsByProjectId.byAlbumId[newFile.albumId] ?? {}
				)?.map(upload => upload.metadata.filename),
			];

			computedFilenamesByProjectId.byAlbumId[newFile.albumId] = [];
		}
	});

	// keep track of file index in newFiles
	for (const [index, newFile] of newFiles.entries()) {
		let existingFilenamesInFileProject = [
			...(newFile.recordingId
				? storedFilenamesByProjectId.byRecordingId[newFile.recordingId]
				: storedFilenamesByProjectId.byAlbumId[newFile.albumId!] ?? []),
			...(newFile.recordingId
				? computedFilenamesByProjectId.byRecordingId[newFile.recordingId]?.map(
						file => file.name
				  )
				: computedFilenamesByProjectId.byAlbumId[newFile.albumId!]?.map(
						file => file.name
				  ) ?? []),
		];

		const appendedNumberRegex = /\s\(\d+\)$/;
		let newFilename = newFile.filename;
		// console.log('About to parse path');
		// console.log('newFile:', newFile);

		let parsedPath = path.parse(newFilename);
		let baseFilename = parsedPath.name.replace(appendedNumberRegex, ''); // Remove number in parentheses at the end, if any
		let ext = parsedPath.ext;

		let similarFilenames = existingFilenamesInFileProject.filter(
			filename =>
				path.parse(filename).name.replace(appendedNumberRegex, '') ===
					baseFilename && path.parse(filename).ext === ext
		); // Filter files with same base name

		// Check if there are similar filenames (i.e. same base name and extension, with the appended number in parentheses at the end),
		// and also check that the filename without the number in parentheses is already taken
		// otherwise we would waste a number (e.g. if we have 'file (1).jpg' but no 'file.jpg', we don't want to create 'file (2).jpg'
		// but rather 'file.jpg')

		if (similarFilenames.length > 0 && similarFilenames.includes(newFilename)) {
			// Extract existing indices
			let indices = similarFilenames
				.map(filename => {
					const match = path.parse(filename).name.match(appendedNumberRegex);
					return match ? parseInt(match[0][2]) : 0;
					// match is an array of matches, the first element is the full match,
					// so we take the second element, which is the number in parentheses (e.g. ' (2)')
				})
				.sort((a, b) => a - b);

			// Find the smallest available number
			let i = 1;
			while (indices.includes(i)) {
				i++;
			}

			newFilename = `${baseFilename} (${i})${ext}`; // Append smallest available number
		} else {
			newFilename = `${baseFilename}${ext}`; // No similar filenames, use original filename
		}

		const filenameWithIndex = { name: newFilename, index };

		if (newFile.recordingId) {
			computedFilenamesByProjectId.byRecordingId[newFile.recordingId].push(
				filenameWithIndex
			);
		} else if (newFile.albumId) {
			computedFilenamesByProjectId.byAlbumId[newFile.albumId].push(
				filenameWithIndex
			);
		}
	}

	// change map to array, following index field
	const computedFilenamesByProjectIdArray = [
		...Object.values(computedFilenamesByProjectId.byRecordingId),
		...Object.values(computedFilenamesByProjectId.byAlbumId),
	]
		.flat()
		.sort((a, b) => a.index - b.index)
		.map(({ name }) => name);

	return computedFilenamesByProjectIdArray;
};

export const mergeFetchedFiles = (
	fetchedFiles: any[],
	currentLocalFilesById: FilesByProjectIdType,
	recordingId: number | null,
	albumId: number | null,
	replace = false
) => {
	const updatedFiles: FileMetadata[] = [];
	fetchedFiles.forEach(fetchedFile => {
		fetchedFile = translateApiFileToLocal(fetchedFile);
		const isAlbumLevelFile = fetchedFile.albumId && !fetchedFile.recordingId;

		const localFile = isAlbumLevelFile
			? (currentLocalFilesById.byAlbumId[fetchedFile.albumId] ?? {})[
					fetchedFile.id
			  ]
			: (currentLocalFilesById.byRecordingId[fetchedFile.recordingId] ?? {})[
					fetchedFile.id
			  ];

		// We only want to merge the files if they don't already exist
		// thus we avoid overwriting the properly cached file objects
		// If they're stale, then they will be overwritten.
		const fetchedDate = new Date(fetchedFile.updatedAt).getTime();
		const localDate = localFile ? new Date(localFile.updatedAt).getTime() : 0;

		if (!localFile || fetchedDate > localDate) {
			updatedFiles.push(fetchedFile);
		}
	});

	const fetchedFilesById = fetchedFiles.reduce((acc, file) => {
		acc[file.id] = file;
		return acc;
	}, {});

	if (replace) {
		// if some of the current local files are not in the fetched files, we must remove them

		if (recordingId) {
			Object.values(
				currentLocalFilesById.byRecordingId[recordingId] ?? {}
			).forEach(localFile => {
				if (!fetchedFilesById[localFile.id]) {
					delete currentLocalFilesById.byRecordingId[recordingId][localFile.id];
				}
			});
		}

		if (albumId) {
			Object.values(currentLocalFilesById.byAlbumId[albumId] ?? {}).forEach(
				localFile => {
					if (!fetchedFilesById[localFile.id]) {
						delete currentLocalFilesById.byAlbumId[albumId][localFile.id];
					}
				}
			);
		}
	}

	return addLocalFiles(
		updatedFiles,
		currentLocalFilesById,
		recordingId,
		albumId
	);
};

export const addLocalFiles = (
	newFiles: FileMetadata[],
	currentFilesByProjectId: FilesByProjectIdType,
	recordingId?: number | null,
	albumId?: number | null
) => {
	const newFilesByProjectId = { ...currentFilesByProjectId };

	// * Recording files are stored within the byRecordingId nested object, regardless of whether they're in an album or not
	// * Album files are stored within the byAlbumId nested object. THIS IS ONLY FOR ALBUM-LEVEL FILES
	newFiles.forEach(newFile => {
		if (newFile.recordingId) {
			newFilesByProjectId.byRecordingId = {
				...newFilesByProjectId.byRecordingId,
				[newFile.recordingId]: {
					...(newFilesByProjectId.byRecordingId[newFile.recordingId] ?? {}),
					[newFile.id]: newFile,
				},
			};
		} else if (newFile.albumId) {
			newFilesByProjectId.byAlbumId = {
				...newFilesByProjectId.byAlbumId,
				[newFile.albumId]: {
					...(newFilesByProjectId.byAlbumId[newFile.albumId] ?? {}),
					[newFile.id]: newFile,
				},
			};
		}
	});

	// In case there are no files being added, we need to initialize the object
	// This is necessary to detect when there are no files in a recording or album after fetching
	if (recordingId && !newFilesByProjectId.byRecordingId[recordingId]) {
		newFilesByProjectId.byRecordingId = {
			...newFilesByProjectId.byRecordingId,
			[recordingId]: {},
		};
	}

	if (albumId && !newFilesByProjectId.byAlbumId[albumId]) {
		newFilesByProjectId.byAlbumId = {
			...newFilesByProjectId.byAlbumId,
			[albumId]: {},
		};
	}

	return newFilesByProjectId;
};

export const flattenToFilesById = (filesByProjectId: FilesByProjectIdType) => {
	const filesById: Partial<Record<number, FileMetadata>> = {};

	Object.values(filesByProjectId.byRecordingId).forEach(recordingFiles => {
		Object.values(recordingFiles).forEach(file => {
			filesById[file.id] = file;
		});
	});

	Object.values(filesByProjectId.byAlbumId).forEach(albumFiles => {
		Object.values(albumFiles).forEach(file => {
			filesById[file.id] = file;
		});
	});

	return filesById;
};

export const deleteLocalFiles = (
	fileIds: number[],
	currentFilesByProjectId: FilesByProjectIdType
) => {
	const newFilesByProjectId = { ...currentFilesByProjectId };
	const filesById = flattenToFilesById(currentFilesByProjectId);

	fileIds.forEach(fileId => {
		const file = filesById[fileId];

		if (!file) {
			throw new Error('deleteLocalFiles: file not found' + fileId);
		}

		if (file.recordingId) {
			newFilesByProjectId.byRecordingId = {
				...newFilesByProjectId.byRecordingId,
				[file.recordingId]: {
					...omit(newFilesByProjectId.byRecordingId[file.recordingId] ?? {}, [
						fileId,
					]),
				},
			};
		} else if (file.albumId) {
			newFilesByProjectId.byAlbumId = {
				...newFilesByProjectId.byAlbumId,
				[file.albumId]: {
					...omit(newFilesByProjectId.byAlbumId[file.albumId] ?? {}, [fileId]),
				},
			};
		}
	});

	return newFilesByProjectId;
};

export const updateLocalFile = (
	file: FileMetadata,
	currentFilesByProjectId: FilesByProjectIdType
) => {
	const newFilesByProjectId = { ...currentFilesByProjectId };
	console.log('UPDATE LOCAL FILE', file);
	if (file.recordingId) {
		newFilesByProjectId.byRecordingId = {
			...newFilesByProjectId.byRecordingId,
			[file.recordingId]: {
				...newFilesByProjectId.byRecordingId[file.recordingId],
				[file.id]: file,
			},
		};
	} else if (file.albumId) {
		newFilesByProjectId.byAlbumId = {
			...newFilesByProjectId.byAlbumId,
			[file.albumId]: {
				...newFilesByProjectId.byAlbumId[file.albumId],
				[file.id]: file,
			},
		};
	}

	return newFilesByProjectId;
};

export const updateLocalFileById = (
	fileId: FileMetadata['id'],
	currentFilesByProjectId: FilesByProjectIdType,
	updates: Partial<FileMetadata>
) => {
	const filesById = flattenToFilesById(currentFilesByProjectId);

	const file = filesById[fileId];

	if (!file) {
		return currentFilesByProjectId;
	}

	return updateLocalFile({ ...file, ...updates }, currentFilesByProjectId);
};

export const getFileExtension = (filename: string) =>
	path
		.extname(filename ?? '')
		.replace('.', '')
		.toLowerCase();

export const getExtensionFromFileMetadata = (
	file: Partial<PlaylistTableFile | FileMetadata | ExportMetadata>
) => getFileExtension(file?.filename ?? '');

export const THUMBNAILED_FILE_TYPES = ['jpg', 'jpeg', 'png'];
export const PREVIEWABLE_AUDIO_FORMATS = ['mp3', 'wav', 'm4a', 'ogg', 'flac'];

export const PREVIEWABLE_UPLOAD_FILE_TYPES = [
	...PREVIEWABLE_AUDIO_FORMATS,
	'jpg',
	'jpeg',
	'png',
	'docx',
	'pdf',
	'xls',
	'xlsx',
];

export const PREVIEWABLE_EXPORT_FILE_TYPES = [
	'jpg',
	'jpeg',
	'png',
	'docx',
	'pdf',
	'xls',
	'xlsx',
];

export const getExportPreviewData = async (exportData: ExportMetadata) => {
	const fileExtension = getExtensionFromFileMetadata(exportData);

	if (!PREVIEWABLE_EXPORT_FILE_TYPES.includes(fileExtension)) {
		return null;
	}

	const exportPreviewRes = await downloadExport(exportData.id);

	const previewData = await exportPreviewRes.data.arrayBuffer();

	switch (fileExtension) {
		case 'jpg':
		case 'jpeg':
		case 'png':
			const base64Data = Buffer.from(previewData).toString('base64');
			return `data:${exportPreviewRes.headers['content-type']};base64,${base64Data}`;
		case 'docx':
		case 'xlsx':
		case 'xls':
			return previewData;
		case 'pdf':
			return { data: new Uint8Array(previewData) };
		default:
			return null;
	}
};

export const getFilePreviewData = async (file: FileMetadata) => {
	const fileExtension = getExtensionFromFileMetadata(file);

	if (!PREVIEWABLE_UPLOAD_FILE_TYPES.includes(fileExtension)) {
		return null;
	}

	const filePreviewId = file?.displayFile?.id ?? file?.id;

	const versionId = file?.displayFile?.activeVersion ?? file?.activeVersion;
	const assetLinkRes = await getFileDownloadLink({
		fileId: filePreviewId,
		versionId: versionId,
	});

	const assetLink = assetLinkRes?.data?.asset_link;

	const previewDataPromise = axios.get<ArrayBuffer>(assetLink, {
		responseType: 'arraybuffer',
	});

	switch (fileExtension) {
		case 'jpg':
		case 'jpeg':
		case 'png':
			const imgPreviewRes = await previewDataPromise;
			const base64Data = Buffer.from(imgPreviewRes.data).toString('base64');

			return `data:${imgPreviewRes.headers['content-type']};base64,${base64Data}`;

		case 'mp3':
		case 'wav':
		case 'm4a':
		case 'ogg':
		case 'flac':
			return assetLink;
		case 'docx':
		case 'xlsx':
		case 'xls':
			const docPreviewRes = await previewDataPromise;

			return docPreviewRes.data;
		case 'pdf':
			const pdfPreviewRes = await previewDataPromise;
			return { data: new Uint8Array(pdfPreviewRes.data) };
		default:
			return null;
	}
};

export const getExportsOverflowMenuOptions = (
	exportData: any,
	dispatch: AppDispatch,
	isCoAdmin?: boolean
) =>
	[
		{
			name: 'Preview',
			leftIcon: 'fas fa-eye',
			onClick: (e: React.MouseEvent<Element, MouseEvent>) => {
				e.stopPropagation();
				dispatch(
					showModalAction(EXPORT_PREVIEW_MODAL, {
						size: 'lg',
						hideHeader: true,
						exportId: exportData.id,
						fullscreen: true,
					})
				);
			},
		},
		{
			name: 'Delete',
			leftIcon: 'fas fa-trash-alt',
			style: { color: 'red' },
			onClick: (e: React.MouseEvent<Element, MouseEvent>) => {
				e.stopPropagation();
				dispatch(
					showModalAction(DELETE_FILE_MODAL, {
						size: 'md',
						isExport: true,
						onDelete: () => dispatch(deleteExportAction(exportData.id)),
					})
				);
			},
		},
	].filter(option => {
		if (option.name === 'Delete') {
			return isCoAdmin;
		}

		return true;
	});

export const getUploadedOverflowMenuOptions = (
	file: FileMetadata,
	dispatch: AppDispatch,
	canShare: boolean,
	isCoAdmin: boolean,
	filesByProjectId?: FilesByProjectIdType,
	navigate?: NavigateFunction
): OverflowMenuOption[] =>
	[
		{
			name: 'Rename',
			leftIcon: 'fas fa-edit',
			onClick: (e: React.MouseEvent<Element, MouseEvent>) => {
				e.stopPropagation();

				dispatch(
					showModalAction(RENAME_FILE_MODAL, { size: 'md', fileId: file.id })
				);
			},
		},
		{
			name: 'Share',
			rightIcon: !canShare ? 'fas fa-lock' : null,
			leftIcon: 'fas fa-share-alt',
			isLocked: !canShare,
			onClick: (e: React.MouseEvent<Element, MouseEvent>) => {
				e.stopPropagation();

				dispatch(
					showModalAction(SHARE_FILES_MODAL, {
						size: 'lg',
						fileIds: [file.id],
						recordingId: file.recordingId,
						albumId: file.albumId,
					})
				);
			},
		},
		{
			name: 'Preview',
			leftIcon: 'fas fa-eye',
			onClick: (e: React.MouseEvent<Element, MouseEvent>) => {
				e.stopPropagation();
				dispatch(
					showModalAction(UPLOAD_PREVIEW_MODAL, {
						size: 'lg',
						hideHeader: true,
						fileId: file.id,
						fullscreen: true,
					})
				);
			},
		},
		{
			name: 'Add/Edit Cover Image',
			leftIcon: 'fas fa-image',
			onClick: (e: React.MouseEvent<Element, MouseEvent>) => {
				e.stopPropagation();
				dispatch(
					showModalAction(IMAGE_CROP_MODAL, {
						size: 'lg',
						onSave: (imageBlob: File) =>
							dispatch(uploadFileCoverImageAction(file.id, imageBlob)),
						aspectRatio: COVER_IMAGE_ASPECT_RATIO,
						title: `Edit cover for ${file.filename}`,
						outputType: IMAGE_FILE_TYPES.blob,
					})
				);
			},
		},
		{
			name: 'Delete',
			leftIcon: 'fas fa-trash-alt',
			style: { color: 'red' },
			onClick: (e: React.MouseEvent<Element, MouseEvent>) => {
				e.stopPropagation();

				dispatch(
					showModalAction(DELETE_FILE_MODAL, {
						size: 'md',
						isExport: false,
						onDelete: async () => {
							const { albumId, recordingId, id } = file;

							return dispatch(deleteFilesAction([file.id]))
								.then(() => {
									if (!filesByProjectId) {
										return Promise.reject();
									}

									const projectFiles = albumId
										? filesByProjectId.byAlbumId[albumId]
										: filesByProjectId.byRecordingId[recordingId!];

									return (
										Object.keys(projectFiles).filter(
											fileId => parseInt(fileId) !== id
										).length === 0
									);
								})
								.then(shouldNavigate => {
									if (shouldNavigate) {
										// If there are no files left in the album or recording, refetch user projects
										// and navigate to the projects page
										if (!navigate) {
											return Promise.reject();
										}

										navigate(ROUTES.Projects.path);

										return dispatch(getUserProjectsAction());
									}

									return Promise.resolve();
								});
						},
					})
				);
			},
		},
	].filter(option => {
		switch (option.name) {
			case 'Share':
			case 'Delete':
			case 'Rename':
				return isCoAdmin;
			case 'Add/Edit Cover Image':
				return isCoAdmin && isAudioFile(file.filename);
			default:
				return true;
		}
	});

export const navigateToProjectFiles = ({
	albumId,
	recordingId,
	navigate,
	section,
}: {
	albumId?: number | null;
	recordingId?: number | null;
	navigate: NavigateFunction;
	section: string;
}) => {
	const newPath = recordingId
		? replacePathVariables(ROUTES.RecordingFiles.path, {
				recordingId,
				section,
		  })
		: replacePathVariables(ROUTES.AlbumFiles.path, {
				albumId,
				section,
		  });

	navigate(newPath);
};

export const countTotalFileSize = (files: FileMetadata[]) =>
	files?.reduce((acc, file) => acc + file?.fileSize, 0);

export const filterFilesBySearchTerm = (
	searchTerm: string,
	files: FileMetadata[],
	fileLabelDetails: FileLabelDetailsType
) =>
	filterItemsBySearchTerm(
		searchTerm,
		files.map(file => ({
			...file,
			labelDetail: fileLabelDetails[file.label],
		})),
		['filename', 'labelDetail']
	);

export const isAudioFile = (filename: string) =>
	AUDIO_FORMATS.includes(getFileExtension(filename));

export const isVideoFile = (filename: string) =>
	VIDEO_FORMATS.includes(getFileExtension(filename));

export const isMediaFile = (filename: string) =>
	isAudioFile(filename) || isVideoFile(filename);

export const getFileTypeFromFileObj = async (file: File) =>
	// ! old browsers don't support file.stream() (as old as Safari 14.1, which came out in 2021)
	// ! (read more https://developer.mozilla.org/en-US/docs/Web/API/Blob/stream#browser_compatibility)
	file.stream
		? // @ts-ignore
		  await fileTypeFromStream(file.stream())
		: {
				ext: getFileExtension(file.name),
				mime: file.type,
		  };

export const isSupportedAudioFile = async (file: File) => {
	const fastFileExtension = getFileExtension(file.name);

	if (!AUDIO_FORMATS.includes(fastFileExtension)) {
		return false;
	}

	// Now we need to check if it's a valid audio file using the file-type library,
	// which checks the file's magic number
	const fileExtension = (await getFileTypeFromFileObj(file))?.ext;

	return AUDIO_FORMATS.includes(fileExtension || '');
};

const getVideoDuration = (file: File) => {
	const url = URL.createObjectURL(file);
	return new Promise<number>(resolve => {
		const video = document.createElement('video');
		video.preload = 'metadata';

		video.onloadedmetadata = () => {
			resolve(video.duration * 1000);
		};

		video.src = url;
	});
};

/**
 *
 * @param {Blob} file
 * @returns {Promise<number | null>} duration in milliseconds or null if error
 */
export const getAudioDuration = async (file: File) => {
	// Check if file is a video

	const isVideo = isVideoFile(file.name);
	if (isVideo) {
		return await getVideoDuration(file);
	}

	// if it's not a supported audio file, return null
	const isSupported = await isSupportedAudioFile(file);
	if (!isSupported) {
		return null;
	}

	const url = URL.createObjectURL(file);

	return new Promise<number | null>(resolve => {
		const audio = document.createElement('audio');
		audio.muted = true;
		const source = document.createElement('source');
		source.src = url; //--> blob URL
		audio.preload = 'metadata';
		audio.appendChild(source);
		audio.onloadedmetadata = function () {
			try {
				if (
					(!audio.duration && audio.duration !== 0) ||
					isNaN(audio.duration)
				) {
					resolve(null);
					return;
				}

				const millis = Math.floor(audio?.duration * 1000);
				// console.log('audio duration', audio?.duration, 'millis', millis);
				resolve(millis);
			} catch (err) {
				console.error('error getting audio duration', err);
				resolve(null);
			}
		};
	}).catch(err => {
		console.error('error getting audio duration', err);
		return null;
	});
};

export const AUDIO_FORMATS = [
	'mp3',
	'wav',
	'ogg',
	'flac',
	'aac',
	'm4a',
	'wma',
	'aiff',
];

export const VIDEO_FORMATS = ['mp4', 'webm'];

export const PREVIEWABLE_FILE_FORMATS = [...PREVIEWABLE_UPLOAD_FILE_TYPES];
export const sha256FromBlob = async (blob: Blob) =>
	new Promise((resolve, reject) => {
		const r = new FileReader();
		r.readAsArrayBuffer(blob);
		r.onloadend = function () {
			if (!r.result) {
				reject('No result when computing SHA-256 from Blob');
			} else if (!(r.result instanceof ArrayBuffer)) {
				reject('Result is not an ArrayBuffer when computing SHA-256 from Blob');
			} else {
				Promise.resolve(crypto.subtle.digest('SHA-256', r.result)).then(
					hash => {
						resolve(
							Array.from(new Uint8Array(hash))
								.map(b => b.toString(16).padStart(2, '0'))
								.join('')
						);
					}
				);
			}
		};
	});

export const isValidFile = async (file: File) => {
	if (file.size === 0 && !isSafari) {
		return false;
	}

	const fastFileExtension = getFileExtension(file.name);

	// if it's a previewable file, validate the file type
	if (!PREVIEWABLE_UPLOAD_FILE_TYPES.includes(fastFileExtension)) {
		return true;
	}

	// check if it's a valid file by comparing the file's magic number to the file extension
	const type = await getFileTypeFromFileObj(file);

	// console.log('detected type', type);
	// console.log('file type', file.type);

	const fastMimeType = file.type;

	if (!type) {
		// if we can't determine the file type, we need to check if the format is supported
		// by the file-type library. Then, if it's supported, it means it's an invalid file,
		// since it should have detected the type using the magic number

		return !(
			supportedExtensions.has(fastFileExtension as FileExtension) ||
			supportedMimeTypes.has(fastMimeType as MimeType)
		);
	}

	return fastFileExtension === type.ext || fastMimeType === type.mime;
};

export const getFileProject = <T extends PlaylistTableFile>(file: T) => {
	if (file.recording) {
		return file.recording;
	}

	if (file.album) {
		return file.album;
	}
};

export const isFileUploading = (
	file: PlaylistTableFile
): file is UploadingPlaylistFile => 'isUploading' in file;

export const dragEventContainsFiles = (e: DragEvent) =>
	e.dataTransfer?.types.includes('Files');

// Made generic to support both VersionMatch and the formik form type
export const detectActiveVersionsInVersionMatches = <
	T extends VersionMatchBase
>(
	matches: T[],
	inputFiles: File[] // needed for lastModified date
) =>
	// We group the matches by the detected file id so that we can
	// find the active version for each group by reading the last modified date metadata
	// Then we create a map of <fileIndex, isActiveVersion> to be used in the formik values computation
	Object.entries(
		groupBy(
			matches.map((match, index) => ({
				...match,
				fileIndex: index, // need to preserve index to identify active version
			})),
			match => match.detectedFileId
		)
	).reduce((_activeVersions, [detectedFileId, inputFilesMatches]) => {
		// some files may not have been matched to any playlist file
		if (!detectedFileId) return _activeVersions;

		const activeVersionIndex = inputFilesMatches.reduce(
			(prevIndex, currentMatch) => {
				const prevFile = inputFiles[prevIndex];
				const currentFile = inputFiles[currentMatch.fileIndex];

				return prevFile.lastModified > currentFile.lastModified
					? prevIndex
					: currentMatch.fileIndex;
			},
			inputFilesMatches[0].fileIndex
		);

		return {
			..._activeVersions,
			[activeVersionIndex]: true,
		};
	}, {} as Record<number, boolean>);

export const validateStorageUsageAndCorruptedFiles = async ({
	existingInputFiles = [],
	newSelectedFiles,
	uploadingStorageUsage,
	storageUsage,
	dispatch,
}: {
	existingInputFiles?: FileList | File[];
	newSelectedFiles: FileList | File[];
	uploadingStorageUsage: number;
	storageUsage: FilesState['storageUsage'];
	dispatch: AppDispatch;
}) => {
	// check if input size exceeds storage limit
	if (
		hasReachedStorageLimit({
			...storageUsage!,
			used: storageUsage!.used + uploadingStorageUsage,
		})
	) {
		dispatch(
			showModalAction(STORAGE_LIMIT_MODAL, {
				size: 'md',
				message:
					'You currently do not have enough storage space to upload the files you selected.',
			})
		);

		return;
	}

	// console.log('selectedFiles', newSelectedFiles);
	// console.log(existingInputFiles);

	if (newSelectedFiles.length === 0) {
		return;
	}

	let [validFiles, invalidFiles] = (await async.reduce(
		[...newSelectedFiles],
		[[], []] as [File[], File[]],
		([validFiles, invalidFiles]: any, file, callback) => {
			isValidFile(file)
				.then(isValid => {
					if (isValid) {
						validFiles.push(file);
					} else {
						invalidFiles.push(file);
					}
				})
				.then(() => callback(null, [validFiles, invalidFiles]));
		}
	)) as [File[], File[]];

	if (invalidFiles.length > 0) {
		dispatch(
			showModalAction(CORRUPTED_FILES_MODAL, {
				size: 'md',
				// corruptedFilenames: invalidFiles.map(f => f.name),
				corruptedFiles: invalidFiles,
			})
		);
	}

	if (isSafari && validFiles.some(file => file.size >= SAFARI_MAX_FILE_SIZE)) {
		validFiles = validFiles.filter(file => file.size < SAFARI_MAX_FILE_SIZE);

		dispatch(
			showModalAction(CONFIRMATION_MODAL, {
				size: 'md',
				title:
					"Whoops! Your Safari Browser won't allow uploads larger than 4GB!",
				description: (
					<div>
						It looks like some of the files you were trying to upload are larger
						than 4GB. <br />
						In order to upload files larger than 4GB, please get our desktop app
						or use the Google Chrome browser.
					</div>
				),
				confirmAction: {
					label: 'Get Desktop App',
					onClick: () =>
						window.open(
							process.env.REACT_APP_DOWNLOAD_DESKTOP_APP_URL,
							'_blank'
						),
				},
				cancelAction: {
					label: 'Dismiss',
					onClick: () => dispatch(hideModal()),
				},
			})
		);
	}

	const allFiles = [...existingInputFiles, ...validFiles];

	if (allFiles.length === 0) {
		return;
	}

	return allFiles;
};

export const getUploadingVersionsForFile = (
	fileId: FileMetadata['id'],
	uploadsById: Dictionary<ProjectFileUpload>
) =>
	Object.values(uploadsById).filter(
		upload => upload.versionMetadata?.fileId === fileId
	);

export const isFileVersionUploading = (
	item: FileVersionListItem
): item is FileVersionUpload => 'uploading' in item;

export const setFileTimestampsMetadata = async (
	file: File,
	{
		createdAt,
		updatedAt,
	}: {
		createdAt: number;
		updatedAt: number;
	}
) => {
	// this is only available in electron, so we skip it in the browser
	if (!isElectron) return;

	// @ts-ignore
	console.log('setting file timestamps', file.path, createdAt, updatedAt);

	window.electronApi.setFileTimestamps(
		// @ts-ignore
		file.path, // electron provides the path property for File objects
		{
			atime: updatedAt,
			mtime: updatedAt,
			btime: createdAt,
		}
	);
};

export const fetchFileTimestampsMetadata = async (
	file: File
): Promise<FileTimestamp> => {
	if (isElectron) {
		// @ts-ignore
		console.log('fetching file timestamps', file.path);

		const timestamps = await window.electronApi.getFileTimestamps(
			// @ts-ignore
			file.path // electron provides the path property for File objects
		);

		return {
			createdAt: timestamps.createdAt.getTime(),
			updatedAt: timestamps.updatedAt.getTime(),
		};
	}

	// ---- FALLBACK FOR BROWSERS ----
	// browsers don't support fetching file info, which leverages the fs module in Node
	// but at least we have access to the file's last modified date
	return {
		createdAt: file.lastModified,
		updatedAt: file.lastModified,
	};
};

export const openNativeSaveDialog = async ({
	defaultPath,
}: {
	defaultPath?: string;
}) => {
	const transactionId = await window.electronApi.openSaveDialog({
		defaultPath,
	});

	if (!transactionId) {
		throw new Error('No path was selected');
	}

	return new WritableNativeFileHandle(transactionId);
};

export const isFileMetadata = (
	file:
		| PlaylistTableFile
		| FileMetadata
		| ExportMetadata
		| ProjectFileUpload
		| GenericFile
): file is FileMetadata | PlaylistFileMetadata => 'displayFile' in file;

export const isExportFile = (
	file: PlaylistTableFile | FileMetadata | ExportMetadata | ProjectFileUpload
): file is ExportMetadata => 'exportType' in file;

export const getImageSrcUrlFromMetadata = (metadata: IAudioMetadata) => {
	const pictures = metadata.common.picture;

	if (!pictures) return null;

	const picture = pictures[0];

	return `data:${picture.format};base64,${picture.data.toString('base64')}`;
};

export const isZipFile = (fileName: string) => {
	return fileName.toLowerCase().endsWith('.zip');
};

export const removeLastNumberInParentheses = (filename: string): string => {
	// Regular expression to match the last " (number)" pattern
	const regex = /\s\(\d+\)(?!.*\s\(\d+\))/;

	// Replace the pattern with an empty string
	return filename.replace(regex, '');
};

async function getFile(fileEntry: any) {
	try {
		return await new Promise((resolve, reject) =>
			fileEntry.file(resolve, reject)
		);
	} catch (err) {
		console.log(err);
	}
}

export const convertFileEntryToFile = async (entry: any) => {
	let file: any = await getFile(entry);
	Object.defineProperty(file, 'path', {
		value: null,
	});
	Object.defineProperty(file, 'webkitRelativePath', {
		value: entry.fullPath.substring(0, entry.fullPath.lastIndexOf('/')),
	});
	return file;
};

export function traverse_directory(
	entry: any,
	result: { [key: string]: File[] },
	key: string,
	folderFiles: File[]
) {
	let reader = entry.createReader();
	const errorHandler = (e: any) => console.log('Error:', e);
	// Resolved when the entire directory is traversed
	return new Promise(resolve_directory => {
		var iteration_attempts: any[] = [];
		(function read_entries() {
			// According to the FileSystem API spec, readEntries() must be called until
			// it calls the callback with an empty array.  Seriously??
			reader.readEntries((entries: any) => {
				if (!entries.length) {
					// Done iterating this particular directory
					resolve_directory(Promise.all(iteration_attempts));
				} else {
					// Add a list of promises for each directory entry.  If the entry is itself
					// a directory, then that promise won't resolve until it is fully traversed.
					iteration_attempts.push(
						Promise.all(
							entries.map(async (entry: any) => {
								if (entry.isFile) {
									let file: any = await convertFileEntryToFile(entry);

									result[key].push(file);
									folderFiles.push(file);

									return entry;
								} else {
									// Folder
									let file = new File([], entry.fullPath);
									Object.defineProperty(file, 'type', {
										value: 'folder',
									});
									result[key].push(file);
									// file.type = "folder"
									return traverse_directory(entry, result, key, folderFiles);
								}
							})
						)
					);
					// Try calling readEntries() again for the same dir, according to spec
					read_entries();
				}
			}, errorHandler);
		})();
	});
}
