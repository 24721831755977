import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generateNewContractNumber } from '../../../../../helpers/afmFormTools';
import { getLatestContractNumberAction } from '../../../../../store/exports/actions';
import TextField from '../../../../form/TextField';

const UnionContractNumber = ({ finalizeFormik }) => {
	/*
	 * Redux Hooks
	 */
	const dispatch = useDispatch();

	/*
	 * Local State
	 */
	const [latestContractNumber, setLatestContractNumber] = useState('');
	const [initialLatestContractNumber, setInitialLatestContractNumber] =
		useState('');

	/*
	 * Callbacks
	 */
	const handleGenerate = useCallback(() => {
		setLatestContractNumber(prevNumber => {
			const newContractNumber = generateNewContractNumber(prevNumber);

			finalizeFormik.setFieldValue('contractNumber', newContractNumber);

			return newContractNumber;
		});
	}, [finalizeFormik]);

	/*
	 * Effects
	 */
	// Effect for fetching latest contract number
	useEffect(() => {
		if (!latestContractNumber) {
			// The value will be set within the redux action
			dispatch(
				getLatestContractNumberAction(number => {
					setLatestContractNumber(number);
					setInitialLatestContractNumber(number);
				})
			);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [latestContractNumber]);

	return (
		<TextField
			label='Contract Number (RPNo)'
			name='contractNumber'
			buttonLabel={latestContractNumber ? 'Generate' : null}
			informationText={
				latestContractNumber
					? `The last RPNo entered on this device was ${initialLatestContractNumber}`
					: null
			}
			value={finalizeFormik.values.contractNumber}
			onChange={finalizeFormik.handleChange}
			onBlur={finalizeFormik.handleBlur}
			onButtonClick={handleGenerate}
			isInvalid={
				finalizeFormik.touched.contractNumber &&
				finalizeFormik.errors.contractNumber
			}
			errorMessage={finalizeFormik.errors.contractNumber}
			maxLength={40}
		/>
	);
};

export default UnionContractNumber;
