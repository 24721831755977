import React, { useMemo } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import ExportButton from '../../../../layout/ExportButton';
import b4Types from '../../../../../constants/unionForm/b4Types';
import exportTypes from '../../../../../constants/exportType';
import { useSelector } from 'react-redux';

const SelectB4TypeModal = ({ show, onSelect, onHide }) => {
	const { savedExportFormsById } = useSelector(state => state.session);
	const { currentRecordingId, currentAlbumId } = useSelector(
		state => state.projects
	);

	const b4TypesInProgress = useMemo(() => {
		const b4TypesInProgress = {
			[b4Types.PRODUCER_B4.id]: false,
			[b4Types.REGULAR_B4.id]: false,
		};

		Object.values(savedExportFormsById).forEach(savedExport => {
			if (
				(savedExport.recordingId === currentRecordingId ||
					(currentAlbumId && savedExport.albumId === currentAlbumId)) &&
				savedExport.exportType === exportTypes.AFM_B4
			) {
				if (savedExport.form.b4Type) {
					b4TypesInProgress[savedExport.form.b4Type.id] = true;
				}
			}
		});

		return b4TypesInProgress;
	}, [savedExportFormsById, currentRecordingId, currentAlbumId]);

	return (
		<Modal centered show={show} onHide={onHide} size='md'>
			<Modal.Header className='p-4'>
				<Modal.Title>
					<h2 className='m-0'>SELECT AFM B4 REPORT TYPE </h2>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body className='px-4'>
				<Row>
					{Object.values(b4Types).map((b4Type, index) => (
						<Col xs={6} key={index}>
							<ExportButton
								label={b4Type.label}
								onClick={() => onSelect(b4Type)}
								centerLabel
								inProgress={b4TypesInProgress[b4Type.id]}
							/>
						</Col>
					))}
				</Row>
			</Modal.Body>
		</Modal>
	);
};

export default SelectB4TypeModal;
