import { cloudProfilesApi } from '../cloudProfilesApi';
import { checkInvalidToken } from '../../helpers/checkInvalidToken';
import axiosRetry, { exponentialDelay } from 'axios-retry';
import { shouldRetry } from '../../helpers/networkTools';

axiosRetry(cloudProfilesApi, {
	retries: 30,
	retryDelay: exponentialDelay,
	shouldResetTimeout: true,
	retryCondition: shouldRetry,
});

export const getCloudProfileById = (userProfileId: string | number) =>
	cloudProfilesApi
		.post<Omit<CloudProfile, 'id'>>(`fetch`, {
			id: userProfileId,
		})
		.then(
			res =>
				({
					...res.data,
					id: userProfileId,
				} as CloudProfile)
		);

export const getCloudProfileSuggestions = (query: string) =>
	cloudProfilesApi
		.post(`suggest`, {
			lookup: `${query}`,
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

export const searchCloudProfilesByTerm = (term: string) =>
	cloudProfilesApi
		.post<{
			results: CloudProfileSearchResult[];
		}>(`search`, {
			lookup: `${term}`,
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});
