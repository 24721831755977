import React, { useCallback } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import '../PlaylistsDashboard/PlaylistsDashboard.scss';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@/store/hooks';
import { replacePathVariables } from '@/helpers/routeTools';
import ROUTES from '@/router/routes';
import {
	createPlaylistAction,
	handleUploadFromPlaylistAction,
} from '@/store/playlists/actions';
import FileStorageStatus from '../../ProjectFiles/FileStorageStatus';
import UploadFilesToPlaylist from '../PlaylistDetails/UploadFilesToPlaylist';
import Button from '../../../layout/Button';
import FilesDragAndDropContainer from '../../../layout/FilesDragAndDropContainer';
import InfoTooltip from '../../../layout/InfoTooltip';
import TextField from '../../../form/TextField';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { showModalAction } from '../../../../store/modal/actions';
import { SHARE_PLAYLIST_MODAL } from '../../../../constants/modalTypes';
import { UploadFilesToPlaylistForm } from '../../../../helpers/playlistUploadTools';

const validationSchema = yup.object().shape({
	recipientEmail: yup.string().email('Invalid email'),
});

const TransferFiles = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [isCreating, setIsCreating] = React.useState(false);
	const [initialFiles, setInitialFiles] = React.useState<File[]>([]);
	// const handleNewPlaylist = () => {
	// 	dispatch(showModalAction(NEW_PLAYLIST_MODAL, { size: 'md' }));
	// };

	const transferFormik = useFormik({
		initialValues: {
			recipientEmail: '',
		},
		validationSchema,
		onSubmit: values => {},
	});

	const handleUpload = async (
		playlistId: Playlist['id'],
		values: UploadFilesToPlaylistForm,
		inputFiles: File[]
	) => {
		debugger;

		await Promise.resolve(
			dispatch(
				handleUploadFromPlaylistAction({
					playlistFilesMetadata: values.files,
					inputFiles,
					playlistId,
					includeInAlbumData: values.includeInAlbum
						? {
								album: values.album,
								albumTitle: values.albumTitle,
								albumArtist: values.albumArtist,
								isExistingAlbum: values.isExistingAlbum,
						  }
						: undefined,
				})
			)
		);

		navigate(replacePathVariables(ROUTES.PlaylistDetails.path, { playlistId }));
	};

	const handleSubmit = async (
		form: UploadFilesToPlaylistForm,
		inputFiles: File[]
	) => {
		setIsCreating(true);

		try {
			await dispatch(
				createPlaylistAction(
					{
						recipientEmail: transferFormik.values.recipientEmail.trim() || null,
					},
					async playlistId => {
						await handleUpload(playlistId, form, inputFiles);

						if (transferFormik.values.recipientEmail.trim()) {
							// show security modal warning about playlist being public
							dispatch(
								showModalAction(SHARE_PLAYLIST_MODAL, {
									playlistId,
									showTransferSecurityWarning: true,
									size: 'md',
								})
							);
						}
					}
				)
			);
		} finally {
			setIsCreating(false);
		}
	};

	const handleDrop = useCallback((e: DragEvent) => {
		const files = e.dataTransfer?.files;

		if (!files || !files.length) {
			return;
		}

		e.preventDefault();
		e.stopPropagation();

		setInitialFiles(Array.from(files));

		// const showUploadVersionsOption = playlistFiles.length > 0;

		// if (showUploadVersionsOption) {
		// 	dispatch(
		// 		showModalAction(ADD_FILES_TO_PLAYLIST_MODAL, {
		// 			playlistId: currentPlaylistId,
		// 			size: 'md',
		// 			initialFiles: Array.from(files),
		// 		})
		// 	);

		// 	return;
		// }

		// dispatch(
		// 	showModalAction(UPLOAD_FILES_TO_PLAYLIST_MODAL, {
		// 		playlistId: currentPlaylistId,
		// 		size: 'xl',
		// 		initialFiles: Array.from(files),
		// 	})
		// );
	}, []);

	// const inputFiles = uploadFilesToPlaylistElem.current?.getInputFiles();

	// const fileCount = inputFiles?.length || 0;

	return (
		<FilesDragAndDropContainer
			onDrop={handleDrop}
			className='h-100'
			message="Drop 'em anywhere!"
		>
			<div
				className='d-flex flex-column h-100'
				style={{
					gap: '1rem',
				}}
			>
				<div className='playlists-dashboard__banner'>
					<div className='playlists-dashboard__banner__text'>
						<h2 className='w-100'>
							<i className='fas fa-cloud-upload-alt mr-2'></i>
							TRANSFER FILES{' '}
						</h2>
						{/* <div className='playlists-dashboard__banner__text__description'>
							Ready to see how your file transfer will look? Upload now for a
							peek - no sending required. Your preview link awaits!
						</div> */}
					</div>
					{/* <img
						src={getAssetPath('img/mesh-666.png')}
						alt='Abstract Mesh'
						// alt='Computer with Sound Credit Playlists'
					/> */}
				</div>
				<Card className='playlists-dashboard__container'>
					<div className='playlists-dashboard__content'>
						{/* <div className='playlists-dashboard__content__banner'>
							<div className='playlists-dashboard__content__banner__text'>
								<h2 className='w-100'>
									<i className='fas fa-cloud-upload-alt mr-2'></i>
									TRANSFER YOUR FILES </h2>
								<div className='playlists-dashboard__content__banner__text__description'>
									Ready to see how your file transfer will look? Upload now for
									a peek - no sending required. Your preview link awaits!
								</div>
							</div>
							<img
								src={getAssetPath('img/mesh-666.png')}
								alt='Abstract Mesh'
								// alt='Computer with Sound Credit Playlists'
							/>
						</div> */}
						<div className='playlists-dashboard__content__files'>
							<Row>
								<Col xs={6}></Col>
								<Col xs={6} className='d-flex justify-content-end'>
									<FileStorageStatus />
								</Col>
							</Row>

							<Row>
								<Col xs={12}>
									<UploadFilesToPlaylist
										initialFiles={initialFiles}
										fromNewPlaylist
										onSubmit={handleSubmit}
										hideToggles
										renderFooter={({ formik, inputFiles, isLoading }) =>
											inputFiles.length > 0 &&
											!isLoading && (
												<>
													<hr />
													<TextField
														value={transferFormik.values.recipientEmail}
														onChange={transferFormik.handleChange}
														name='recipientEmail'
														label='Send To (optional)'
														placeholder='Enter Email Address'
														className='mb-5'
														onBlur={transferFormik.handleBlur}
														isInvalid={
															transferFormik.touched.recipientEmail &&
															!!transferFormik.errors.recipientEmail
														}
														errorMessage={transferFormik.errors.recipientEmail}
													/>

													<div className='d-flex justify-content-end'>
														<Button
															label={
																<div className='d-flex'>
																	<span className='mr-2'>
																		Create New Playlist
																	</span>
																	<InfoTooltip
																		message='Playlists are the way to organize and transfer your files in Sound Credit. Once you create a playlist, you can keep adding and changing files, and share it with links for viewing and downloading in browsers.'
																		iconStyle={{ color: 'white' }}
																	/>
																</div>
															}
															theme='dark'
															onClick={() => formik.submitForm()}
															size='lg'
															isLoading={isCreating}
														/>
													</div>
												</>
											)
										}

										// className='w-100'
									/>
									{/* <FileDragAndDropInput /> */}
								</Col>
							</Row>
							{/* <p>
							With Sound Credit Playlists, your music files are transferred and
							stored alongside their information, making it the best way for the
							music industry to transfer files.
							<br />
							<br />
							Processing all music industry file transfers through Sound Credit
							ensures that they can be connected to royalty registration and
							credit databases at the end of the project.
							<br />
							<br />
							By
							<strong> always using Sound Credit to transfer your files</strong>
							, you're protecting your project assets and streamlining your
							workflow alongside the top organizations and indies in the music
							industry.
						</p>
					*/}
							{/* {fileCount > 0 && (
							<Button
								leftIcon='fas fa-plus-circle'
								label='Create New Playlist'
								theme='dark'
								onClick={() => uploadFilesToPlaylistElem.current?.submitForm()}
								size='lg'
								isLoading={isCreating}
							/>
						)} */}
						</div>
					</div>
				</Card>
			</div>
		</FilesDragAndDropContainer>
	);
};

export default TransferFiles;
