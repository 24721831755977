import _ from 'lodash';
import {
	ADD_FETCHED_FILES,
	ADD_LOCAL_FILES,
	ADD_TO_UPLOAD_QUEUE,
	DELETE_LOCAL_FILES,
	DELETE_UPLOADS_IN_PROGRESS,
	SET_FILE_UPLOAD_PROGRESS,
	UPDATE_LOCAL_FILE,
	FILE_UPLOAD_ERROR,
	FILE_UPLOAD_SUCCESS,
	CANCEL_FILE_UPLOAD,
	RETRY_FILE_UPLOAD,
	START_FILE_UPLOAD,
	SET_FILE_UPLOAD_ABORT_CONTROLLER,
	CLEAR_UPLOAD_FROM_QUEUE,
	CLEAR_FILES_STATE,
	SET_IMAGE_FILE_THUMBNAIL,
	SET_STORAGE_LIMIT,
	SET_STORAGE_USAGE,
	SET_FILE_LABEL_DETAILS,
	ADD_TO_DOWNLOAD_QUEUE,
	CANCEL_FILE_DOWNLOAD,
	DELETE_DOWNLOADS_IN_PROGRESS,
	FILE_DOWNLOAD_ERROR,
	FILE_DOWNLOAD_SUCCESS,
	RETRY_FILE_DOWNLOAD,
	SET_FILE_DOWNLOAD_ABORT_CONTROLLER,
	SET_FILE_DOWNLOAD_PROGRESS,
	START_FILE_DOWNLOAD,
	CLEAR_DOWNLOAD_FROM_QUEUE,
	ADD_UPLOAD_FILES_FOLDERS,
	ADD_TO_FOLDER_DOWNLOAD_QUEUE,
	DELETE_FOLDER_DOWNLOAD_QUEUE,
	ADD_FOLDER_TO_DOWNLOAD_QUEUE,
} from '../actionTypes';
import UPLOAD_STATUS from '../../constants/uploadStatus.json';
import DownloadStatus from '../../constants/downloadStatus';
import {
	addLocalFiles,
	countTotalFileSize,
	deleteLocalFiles,
	flattenToFilesById,
	mergeFetchedFiles,
	updateLocalFile,
	updateLocalFileById,
} from '../../helpers/fileTools';
import { createQueueDownload } from '../../helpers/downloadTools';
import { AnyAction } from 'redux';

export type FilesState = {
	uploadsQueueById: Record<string, ProjectFileUpload>;
	downloadQueueById: Record<string, ProjectFileDownload>;
	folderDownloadQueueById: Record<string, PlaylistFolder>;
	uploadFilesFolders: {
		[key: string]: { [key: string]: number[] };
	};
	filesByProjectId: FilesByProjectIdType;
	fileLabelDetails: FileLabelDetailsType | null;
	requestLabel: string | null;
	requestStatus: string | null;
	errorMessage: string | null;
	storageUsage: StorageUsage | null;
};

const initialState = {
	uploadsQueueById: {},
	downloadQueueById: {},
	folderDownloadQueueById: {},
	filesByProjectId: {
		byAlbumId: {},
		byRecordingId: {},
	},
	requestLabel: '',
	requestStatus: '',
	errorMessage: '',
	storageUsage: null,
	fileLabelDetails: null,
} as FilesState;

const reducer = (state = initialState, action: AnyAction): FilesState => {
	const currentStorageUsage = state.storageUsage as StorageUsage;
	const filesById = flattenToFilesById(state.filesByProjectId) as Record<
		number,
		FileMetadata
	>;

	switch (action.type) {
		case ADD_LOCAL_FILES:
			if (currentStorageUsage.used === null) {
				console.error('Used storage is null');
				return state;
			}

			return {
				...state,
				filesByProjectId: addLocalFiles(
					action.files,
					state.filesByProjectId,
					action.recordingId,
					action.albumId
				),
				storageUsage: {
					...currentStorageUsage,
					used: currentStorageUsage.used + countTotalFileSize(action.files),
				},
			};
		case ADD_FETCHED_FILES:
			console.log('ADD FETCHED FILES', action.files);
			return {
				...state,
				filesByProjectId: mergeFetchedFiles(
					action.files,
					state.filesByProjectId,
					action.recordingId,
					action.albumId
				),
			};
		case DELETE_LOCAL_FILES:
			if (currentStorageUsage.used === null) {
				console.error('Used storage is null');
				return state;
			}

			const filesToDelete = action.fileIds.map((id: number) => filesById[id]);

			const totalSize = countTotalFileSize(filesToDelete);

			return {
				...state,
				filesByProjectId: deleteLocalFiles(
					action.fileIds,
					state.filesByProjectId
				),
				storageUsage: {
					...currentStorageUsage,
					used: currentStorageUsage.used - totalSize,
				},
			};
		case UPDATE_LOCAL_FILE:
			return {
				...state,
				filesByProjectId: updateLocalFile(action.file, state.filesByProjectId),
			};
		case ADD_TO_UPLOAD_QUEUE:
			console.log('Adding to update queue');
			return {
				...state,
				uploadsQueueById: {
					...state.uploadsQueueById,
					...action.uploads,
				},
			};
		case DELETE_UPLOADS_IN_PROGRESS:
			return {
				...state,
				uploadsQueueById: _.omit(state.uploadsQueueById, action.uploadIds),
			};
		case SET_FILE_UPLOAD_PROGRESS:
			return {
				...state,
				uploadsQueueById: {
					...state.uploadsQueueById,
					[action.uploadId]: {
						...state.uploadsQueueById[action.uploadId],
						progress: action.progress,
					},
				},
			};
		case RETRY_FILE_UPLOAD:
			return {
				...state,
				uploadsQueueById: {
					...state.uploadsQueueById,
					[action.uploadId]: {
						...state.uploadsQueueById[action.uploadId],
						status: UPLOAD_STATUS.READY_TO_UPLOAD,
						progress: 0,
						errorMessage: '',
						abort: null,
					},
				},
			};
		case START_FILE_UPLOAD:
			return {
				...state,
				uploadsQueueById: {
					...state.uploadsQueueById,
					[action.uploadId]: {
						...state.uploadsQueueById[action.uploadId],
						status: UPLOAD_STATUS.IN_PROGRESS,
						progress: 0,
					},
				},
			};
		case SET_FILE_UPLOAD_ABORT_CONTROLLER:
			return {
				...state,
				uploadsQueueById: {
					...state.uploadsQueueById,
					[action.uploadId]: {
						...state.uploadsQueueById[action.uploadId],
						abort: action.abort,
					},
				},
			};
		case CLEAR_UPLOAD_FROM_QUEUE:
			return {
				...state,
				uploadsQueueById: _.omit(state.uploadsQueueById, action.uploadId),
			};
		case CANCEL_FILE_UPLOAD:
			return {
				...state,
				uploadsQueueById: {
					...state.uploadsQueueById,
					[action.uploadId]: {
						...state.uploadsQueueById[action.uploadId],
						status: UPLOAD_STATUS.CANCELLED,
						errorMessage: 'Upload canceled',
					},
				},
			};
		case FILE_UPLOAD_SUCCESS:
			return {
				...state,
				uploadsQueueById: {
					...state.uploadsQueueById,
					[action.uploadId]: {
						...state.uploadsQueueById[action.uploadId],
						status: UPLOAD_STATUS.SUCCESS,
					},
				},
			};
		case FILE_UPLOAD_ERROR:
			return {
				...state,
				uploadsQueueById: {
					...state.uploadsQueueById,
					[action.uploadId]: {
						...state.uploadsQueueById[action.uploadId],
						status: UPLOAD_STATUS.ERROR,
						errorMessage: action.errorMessage,
					},
				},
			};
		case ADD_UPLOAD_FILES_FOLDERS:
			console.log('ADD UPLOAD FILES FOLDERS', action.files);
			// uploadFilesFolders: mergeFetchedFiles(
			// 	action.files,
			// 	state.filesByProjectId,
			// 	action.recordingId,
			// 	action.albumId
			// ),
			return {
				...state,
				uploadFilesFolders: { ...action.uploads },
			};
		case ADD_TO_FOLDER_DOWNLOAD_QUEUE:
			const folder = action.folder;
			return {
				...state,
				folderDownloadQueueById: {
					...state.folderDownloadQueueById,
					[folder.id]: folder,
				},
			};
		case DELETE_FOLDER_DOWNLOAD_QUEUE:
			return {
				...state,
				folderDownloadQueueById: {},
			};
		case ADD_TO_DOWNLOAD_QUEUE:
			const file = action.file;
			const version = file?.versions?.[action.versionId];

			if (!file || !version) {
				console.error('File or version not found', action);
				return state;
			}

			const newQueueDownload = createQueueDownload({
				fileId: file.id,
				versionId: action.versionId || file.activeVersion,
				filename: version?.filename ?? file.filename,
				fileHandle: action.fileHandle,
				fileSize: file.fileSize,
				nativeHandle: action.nativeHandle,
			});

			return {
				...state,
				downloadQueueById: {
					...state.downloadQueueById,
					[newQueueDownload.id]: newQueueDownload,
				},
			};
		case ADD_FOLDER_TO_DOWNLOAD_QUEUE:
			const actionFolder = action.folder;
			if (!actionFolder) {
				console.error('File or version not found', action);
				return state;
			}
			return {
				...state,
				downloadQueueById: {
					...state.downloadQueueById,
					[actionFolder.id]: actionFolder,
				},
			};
		case DELETE_DOWNLOADS_IN_PROGRESS:
			return {
				...state,
				downloadQueueById: _.omit(state.downloadQueueById, action.downloadIds),
			};
		case SET_FILE_DOWNLOAD_PROGRESS:
			return {
				...state,
				downloadQueueById: {
					...state.downloadQueueById,
					[action.downloadId]: {
						...state.downloadQueueById[action.downloadId],
						progress: action.progress,
					},
				},
			};
		case RETRY_FILE_DOWNLOAD:
			return {
				...state,
				downloadQueueById: {
					...state.downloadQueueById,
					[action.downloadId]: {
						...state.downloadQueueById[action.downloadId],
						status: DownloadStatus.READY_TO_DOWNLOAD,
						progress: 0,
						errorMessage: '',
						abort: null,
					},
				},
			};
		case START_FILE_DOWNLOAD:
			return {
				...state,
				downloadQueueById: {
					...state.downloadQueueById,
					[action.downloadId]: {
						...state.downloadQueueById[action.downloadId],
						status: DownloadStatus.IN_PROGRESS,
						progress: 0,
					},
				},
			};
		case SET_FILE_DOWNLOAD_ABORT_CONTROLLER:
			return {
				...state,
				downloadQueueById: {
					...state.downloadQueueById,
					[action.downloadId]: {
						...state.downloadQueueById[action.downloadId],
						abortController: action.abortController,
					},
				},
			};
		case CLEAR_DOWNLOAD_FROM_QUEUE:
			const download = state.downloadQueueById[action.downloadId];

			// manage native handle used in Electron
			// close the handle if it exists
			if (download?.nativeHandle) {
				download.nativeHandle.close();
			}

			return {
				...state,
				downloadQueueById: _.omit(state.downloadQueueById, action.downloadId),
			};
		case CANCEL_FILE_DOWNLOAD:
			return {
				...state,
				downloadQueueById: {
					...state.downloadQueueById,
					[action.downloadId]: {
						...state.downloadQueueById[action.downloadId],
						status: DownloadStatus.CANCELLED,
						errorMessage: 'Download canceled',
					},
				},
			};
		case FILE_DOWNLOAD_SUCCESS:
			return {
				...state,
				downloadQueueById: {
					...state.downloadQueueById,
					[action.downloadId]: {
						...state.downloadQueueById[action.downloadId],
						status: DownloadStatus.SUCCESS,
					},
				},
			};
		case FILE_DOWNLOAD_ERROR:
			return {
				...state,
				downloadQueueById: {
					...state.downloadQueueById,
					[action.downloadId]: {
						...state.downloadQueueById[action.downloadId],
						status: DownloadStatus.ERROR,
						errorMessage: action.errorMessage,
					},
				},
			};
		case SET_IMAGE_FILE_THUMBNAIL:
			return {
				...state,
				filesByProjectId: updateLocalFileById(
					action.fileId,
					state.filesByProjectId,
					{ thumbnail: action.thumbnail } // base64 string for image thumbnail
				),
			};
		case SET_STORAGE_LIMIT:
			return {
				...state,
				storageUsage: {
					...currentStorageUsage,
					limit: action.storageLimit,
				},
			};
		case SET_STORAGE_USAGE:
			return {
				...state,
				storageUsage: {
					...currentStorageUsage,
					used: action.storageUsage,
				},
			};
		case CLEAR_FILES_STATE:
			return {
				...initialState,
			};
		case SET_FILE_LABEL_DETAILS:
			return {
				...state,
				fileLabelDetails: {
					...action.labels,
				},
			};
		default:
			return state;
	}
};

export default reducer;
