import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import styles from './PlaylistJuly2023PromoTimer.module.scss';
import TimeFormat from 'hh-mm-ss';
import clsx from 'clsx';
import { showModalAction } from '../../../store/modal/actions';
import { PLAYLIST_CTA_MODAL_JULY_2023 } from '../../../constants/modalTypes';
import { setPromoActiveAction } from '../../../store/promos/actions';
import PromoType from '../../../store/promos/promoType';

export type PlaylistJuly2023PromoTimerProps = {
	className?: string;
};

const PlaylistJuly2023PromoTimer = ({
	className = '',
}: PlaylistJuly2023PromoTimerProps) => {
	const dispatch = useAppDispatch();

	const {
		playlistJuly2023PromoTimerExpiration,
		extendedPlaylistJuly2023Promo,
	} = useAppSelector(state => state.promos);

	const [timerSecs, setTimerSecs] = useState<number | null>(null); // number of seconds until promo timer expires

	const handleClick = () => {
		// setShowShockwave(false);
		dispatch(
			showModalAction(PLAYLIST_CTA_MODAL_JULY_2023, {
				size: 'xl',
				removeHeaderPadding: true,
			})
		);
	};

	useEffect(() => {
		if (!playlistJuly2023PromoTimerExpiration) return;

		const timer = setInterval(() => {
			const now = new Date().getTime();
			const distance =
				new Date(playlistJuly2023PromoTimerExpiration).getTime() - now;
			const totalSeconds = Math.floor(distance / 1000);

			if (totalSeconds < 0) {
				clearInterval(timer);
				setTimerSecs(-1);

				if (extendedPlaylistJuly2023Promo) {
					dispatch(setPromoActiveAction(PromoType.PlaylistJuly2023, false));
				}
			} else {
				setTimerSecs(totalSeconds);
			}
		}, 1000);

		return () => clearInterval(timer);
	}, [
		playlistJuly2023PromoTimerExpiration,
		extendedPlaylistJuly2023Promo,
		dispatch,
	]);

	if (timerSecs == null) return null;

	return (
		<div
			className={clsx(
				styles['promo-timer'],
				className,
				{
					[styles['show-shockwave']]: timerSecs < 0,
				} // we don't want to show the shockwave while the timer is running. We only want to show it if the timer has expired
			)}
			onClick={handleClick}
		>
			<i className='fas fa-stopwatch mr-1'></i>
			{timerSecs < 0 ? "TIME'S UP!" : TimeFormat.fromS(timerSecs)}
		</div>
	);
};

export default PlaylistJuly2023PromoTimer;
