import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import { setModalTitle, showModalAction } from '@/store/modal/actions';
import Button from '@/components/layout/Button';
import './SharePlaylistModal.scss';
import PlaylistAccessControlType from '@/constants/playlistAccessControl';
import { setPlaylistAccessControlAction } from '@/store/playlists/actions';
import * as yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import {
	computeLocalPlaylistAccessControl,
	getPlaylistShareLink,
} from '@/helpers/playlistTools';
import ToggleInput from '@/components/layout/ToggleInput';
import { SET_PLAYLIST_PASSWORD_MODAL } from '@/constants/modalTypes';
import PillButton from '@/components/layout/PillButton/PillButton';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { Toast } from 'primereact/toast';

const schema = yup.object().shape({
	accessControlType: yup.number(),
	password: yup.string().when('accessControlType', {
		is: PlaylistAccessControlType.PASSWORD,
		then: yup.string().required('Required'),
	}),
	allowDownloads: yup.boolean(),
	expandVersions: yup.boolean(),
});

export type SharePlaylistModalProps = {
	playlistId: Playlist['id'];
	showTransferSecurityWarning?: boolean;
};

type PlaylistAccessControlForm = {
	accessControlType: number;
	password: string;
	allowDownloads: boolean;
	expandVersions: boolean;
	hideTrackchatLinks: boolean;
	enableCreditRequests: boolean;
};

const SharePlaylistModal = ({
	playlistId,
	showTransferSecurityWarning = false,
}: SharePlaylistModalProps) => {
	const dispatch = useAppDispatch();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { playlistsById } = useAppSelector(state => state.playlists);
	const [copyLinkTooltipText, setCopyLinkTooltipText] = useState('');

	const transferSecurityWarningToast = useRef<Toast>(null);
	const showedSecurityWarning = useRef(false);

	useEffect(() => {
		if (showTransferSecurityWarning && !showedSecurityWarning.current) {
			transferSecurityWarningToast.current?.show({
				severity: 'warn',
				summary: 'Security Tip',
				detail: `You just transferred this playlist via email, which means it's now accessible to the recipient and anyone with access to the link. For an extra layer of security, you can set a password.`,
				sticky: true,
			});

			showedSecurityWarning.current = true;
		}
	}, [showTransferSecurityWarning]);

	const playlist = useMemo(
		() => playlistsById?.[playlistId],
		[playlistId, playlistsById]
	);

	const renderAccessControlButton = useCallback(
		({
			onSelect,
			title,
			description,
			icon,
			isSelected,
		}: {
			onSelect: () => void;
			title: string;
			description: string;
			icon: string;
			isSelected: boolean;
		}) => {
			return (
				<div
					onClick={onSelect}
					className={`share-playlist__access-control__button ${
						isSelected ? 'share-playlist__access-control__button--selected' : ''
					}`}
				>
					<i
						className={`${icon} mr-3 share-playlist__access-control__button__icon`}
					></i>
					<div>
						<div className='share-playlist__access-control__button__title'>
							{title}
						</div>
						<div className='share-playlist__access-control__button__description'>
							{description}
						</div>
					</div>
				</div>
			);
		},
		[]
	);

	const handleSubmit = useCallback(
		(accessControlForm: PlaylistAccessControlForm) => {
			setIsSubmitting(true);
			const { password, accessControlType } = accessControlForm;

			Promise.resolve(
				dispatch(
					setPlaylistAccessControlAction({
						playlistId,
						accessControlType,
						password,
						allowDownloads: accessControlForm.allowDownloads,
						expandVersions: accessControlForm.expandVersions,
						hideTrackchatLinks: accessControlForm.hideTrackchatLinks,
						enableCreditRequests: accessControlForm.enableCreditRequests,
					})
				)
			).finally(() => {
				setIsSubmitting(false);
				// handleClose();
			});
		},
		[dispatch, playlistId]
	);

	const initialValues = useMemo(
		() => ({
			password: '', // users should not be able to see the password, if they forget it, they can reset it
			accessControlType: computeLocalPlaylistAccessControl(playlist?.playlist),
			allowDownloads: playlist?.playlist?.allowDownloads || false,
			expandVersions: playlist?.playlist?.expandVersions || false,
			hideTrackchatLinks: playlist?.playlist?.hideTrackchatLinks || false,
			enableCreditRequests: playlist?.playlist?.enableCreditRequests || false,
		}),
		[playlist]
	);

	const formik = useFormik({
		initialValues,
		validationSchema: schema,
		validateOnMount: true,
		onSubmit: handleSubmit,
		enableReinitialize: true,
	});

	const handleCopyLink = useCallback(() => {
		if (!playlist) {
			return;
		}

		const link = getPlaylistShareLink(playlist);
		navigator.clipboard.writeText(link);

		setCopyLinkTooltipText('Copied!');
		setTimeout(() => {
			setCopyLinkTooltipText('');
		}, 1000);
	}, [playlist]);

	const handleSelectAccessControl = useCallback(
		(accessControl: number) => () => {
			if (formik?.values?.accessControlType === accessControl) {
				return;
			}
			// if it's the password option, we need to set the password before setting the access control
			if (accessControl === PlaylistAccessControlType.PASSWORD) {
				dispatch(
					showModalAction(SET_PLAYLIST_PASSWORD_MODAL, {
						onSubmit: (password: string) => {
							formik?.setFieldValue('password', password);
							formik?.setFieldValue('accessControlType', accessControl);
						},
						size: 'md',
					})
				);

				return;
			}

			formik?.setFieldValue('accessControlType', accessControl);
		},
		[formik, dispatch]
	);

	useEffect(() => {
		dispatch(setModalTitle('SHARE SETTINGS'));
	}, [dispatch]);

	useEffect(() => {
		if (formik.dirty && formik.isValid) {
			formik.handleSubmit();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.dirty, formik.isValid, formik.handleSubmit, formik.values]);

	return (
		<FormikProvider value={formik}>
			<Form onSubmit={formik.handleSubmit} placeholder={null}>
				<Modal.Body>
					<div className='px-4'>
						<Toast
							ref={transferSecurityWarningToast}
							position='top-right'
							pt={{
								icon: {
									style: {
										width: '5rem',
										height: '5rem',
									},
								},
							}}
						/>
						<div className='share-playlist__access-control'>
							{renderAccessControlButton({
								title: 'Hidden for Everyone',
								description:
									'The playlist is only visible to you in the Portal and the link is disabled',
								icon: 'fas fa-eye-slash',
								onSelect: handleSelectAccessControl(
									PlaylistAccessControlType.PRIVATE
								),
								isSelected:
									formik?.values?.accessControlType ===
									PlaylistAccessControlType.PRIVATE,
							})}

							<div className='my-3' />
							{renderAccessControlButton({
								title: 'Anyone with the Link',
								description: 'Anyone with the link to the playlist can view',
								icon: 'fas fa-globe',
								onSelect: handleSelectAccessControl(
									PlaylistAccessControlType.PUBLIC
								),
								isSelected:
									formik?.values?.accessControlType ===
									PlaylistAccessControlType.PUBLIC,
							})}

							<div className='my-3' />

							{renderAccessControlButton({
								title: 'Password Protected',
								description:
									'Anyone with a link to the playlist needs a password to view',
								icon: 'fas fa-lock',
								onSelect: handleSelectAccessControl(
									PlaylistAccessControlType.PASSWORD
								),
								isSelected:
									formik?.values?.accessControlType ===
									PlaylistAccessControlType.PASSWORD,
							})}

							{formik?.values?.accessControlType ===
								PlaylistAccessControlType.PASSWORD && (
								<div className='mt-4'>
									<PillButton
										label='Change Password'
										onClick={() => {
											dispatch(
												showModalAction(SET_PLAYLIST_PASSWORD_MODAL, {
													onSubmit: (password: string) => {
														formik?.setFieldValue('password', password);
													},
													size: 'md',
												})
											);
										}}
										leftIcon='fas fa-key'
									/>
								</div>
							)}

							<hr className='w-100' />

							<ToggleInput
								name='allowDownloads'
								label='Allow Downloads from Playlist Link'
								value={formik?.values?.allowDownloads}
								checked={formik?.values?.allowDownloads}
								onChange={formik.handleChange}
								style={{ alignSelf: 'flex-start' }}
								className='mb-2'
							/>

							<ToggleInput
								name='expandVersions'
								label={
									<span>
										Include All Versions as Separate Tracks <br />
										<span className='text-muted'>
											(Only Visible from Playlist Link)
										</span>
									</span>
								}
								value={formik?.values?.expandVersions}
								checked={formik?.values?.expandVersions}
								onChange={formik.handleChange}
								style={{ alignSelf: 'flex-start' }}
								className='mb-2'
							/>

							<ToggleInput
								name='hideTrackchatLinks'
								label={<>Hide Links to TrackChat in Playlist Link</>}
								value={formik?.values?.hideTrackchatLinks}
								checked={formik?.values?.hideTrackchatLinks}
								onChange={formik.handleChange}
								style={{ alignSelf: 'flex-start' }}
								className='mb-2'
							/>

							<ToggleInput
								name='enableCreditRequests'
								label={
									<span>
										Allow Public Users to Submit Credit <br />
										Requests to Recordings from the Playlist Link
									</span>
								}
								value={formik?.values?.enableCreditRequests}
								checked={formik?.values?.enableCreditRequests}
								onChange={formik.handleChange}
								style={{ alignSelf: 'flex-start' }}
							/>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<div className='d-flex justify-content-between w-100 px-4'>
						{isSubmitting ? (
							<div className='d-flex align-items-center'>
								<Spinner size='sm' animation='border' className='mr-2' />
								<div
									style={{
										fontSize: '0.9rem',
									}}
								>
									Saving changes...
								</div>
							</div>
						) : (
							<div />
						)}
						<Button
							label='Copy Link'
							leftIcon='fas fa-link'
							onClick={handleCopyLink}
							tooltipText={copyLinkTooltipText}
						/>
					</div>
				</Modal.Footer>
			</Form>
		</FormikProvider>
	);
};

export default SharePlaylistModal;
