import React from 'react';

const ImagePreview = ({ imgSrc }) => {
	// eslint-disable-next-line jsx-a11y/img-redundant-alt
	return (
		<div>
			<img
				style={{
					maxHeight: '80vh',
					maxWidth: '80vw',
					objectFit: 'contain',
				}}
				src={imgSrc}
				alt='file preview'
			/>
		</div>
	);
};

export default ImagePreview;
