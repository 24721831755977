import React, {
	ChangeEvent,
	HTMLProps,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Button from '../../../layout/Button';
import {
	useTable,
	useRowSelect,
	useExpanded,
	Row as RowType,
	UseExpandedRowProps,
	UseRowSelectRowProps,
	ColumnWithStrictAccessor,
} from 'react-table';
import './ProjectsTable.scss';
import albumsColumns from './albumsColumns';
import projectsColumns from './projectsColumns';
import {
	DragDropContext,
	Draggable,
	Droppable,
	OnDragEndResponder,
} from 'react-beautiful-dnd';
import { showModalAction } from '../../../../store/modal/actions';
import {
	CREATE_NEW_RECORDING_MODAL,
	PROJECT_LIMIT_MODAL,
	SELECT_RECORDING_MODAL,
} from '../../../../constants/modalTypes';
import {
	addRecordingsToCloudAlbumAction,
	reorderCloudAlbumRecordingsAction,
	reorderLocalAlbumRecordingsAction,
	swapRecordingBetweenCloudAlbumsAction,
} from '../../../../store/projects/actions';
import { reorderAlbumRecordings } from '../../../../helpers/albumTools';
import { getAlbumEditors } from '../../../../api/services/editorService';
import { hasReachedProjectLimit } from '../../../../helpers/tiersTools';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

interface IndeterminateCheckboxProps extends HTMLProps<HTMLInputElement> {
	indeterminate?: boolean;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
}

const IndeterminateCheckbox = React.forwardRef<
	HTMLInputElement,
	IndeterminateCheckboxProps
>(({ indeterminate, ...rest }, ref) => {
	const defaultRef = useRef<HTMLInputElement>(null);
	const refToUse = ref || defaultRef;

	useEffect(() => {
		const currentRef =
			typeof refToUse === 'function' ? refToUse(defaultRef.current) : refToUse;
		if (currentRef?.current) {
			currentRef.current.indeterminate = !!indeterminate;
		}
	}, [refToUse, indeterminate]);

	return (
		<div className='checkbox-container'>
			<input type='checkbox' ref={refToUse} {...rest} />
		</div>
	);
});

export type AlbumTableRow = RowType<AlbumRow> &
	UseExpandedRowProps<AlbumRecordingRow>;
export type ProjectTableRow = RowType<ProjectRow> | AlbumTableRow;
export type SelectableProjectTableRow = ProjectTableRow &
	UseRowSelectRowProps<ProjectRow>;

export type ProjectsTableProps = {
	projects: ProjectRow[];
	setSelectedProjects?: (projects: ProjectRow[]) => void;
};

function ProjectsTable({ projects, setSelectedProjects }: ProjectsTableProps) {
	// Use the state and functions returned from useTable to build your UI
	const columns = React.useMemo(
		() => projectsColumns as ColumnWithStrictAccessor<ProjectRow>[],
		[]
	);

	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		rows,
		prepareRow,
		visibleColumns,
		state: { selectedRowIds },
	} = useTable(
		{
			columns,
			data: projects,
			expandSubRows: false,
			autoResetExpanded: false,
		},
		useExpanded,
		useRowSelect,
		hooks => {
			hooks.visibleColumns.push(columns => [
				{
					id: 'selection',

					Header: ({ getToggleAllRowsSelectedProps }) => (
						<div>
							<IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
						</div>
					),
					Cell: ({ row }: { row: SelectableProjectTableRow }) => (
						<div>
							<IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
						</div>
					),
				},
				...columns,
			]);
		}
	);

	useEffect(() => {
		let values = [];

		for (const num in selectedRowIds) {
			const res = rows.find(r => r.id === num);

			if (res) values.push(res.original);
		}
		if (setSelectedProjects) setSelectedProjects(values);
	}, [selectedRowIds, rows, setSelectedProjects]);

	const renderRecordingRow = useCallback(
		(row: RowType<RecordingRow>, key: string) => {
			return (
				<tr {...row.getRowProps()} key={key} className='projects-table-row'>
					{row.cells.map(cell => {
						return (
							<td className={`projects-table-cell`} {...cell.getCellProps()}>
								{cell.render('Cell')}
							</td>
						);
					})}
				</tr>
			);
		},
		[]
	);

	const renderAlbumRow = useCallback(
		(row: AlbumTableRow, key: string) => {
			return (
				<React.Fragment key={key}>
					<tr
						{...row.getRowProps()}
						className={
							'projects-table-row' +
							(row.isExpanded ? ' expanded-album-header' : '')
						}
					>
						{row.cells.map(cell => {
							return (
								<td className={`projects-table-cell`} {...cell.getCellProps()}>
									{cell.render('Cell')}
								</td>
							);
						})}
					</tr>
					{row.isExpanded ? (
						<tr className='expanded-album-body'>
							<td colSpan={visibleColumns.length}>
								<AlbumSubTable albumData={row.original} />
							</td>
						</tr>
					) : (
						<></>
					)}
				</React.Fragment>
			);
		},
		[visibleColumns.length]
	);

	return (
		<table className='projects-table' {...getTableProps()}>
			<thead className='projects-table-header'>
				{headerGroups.map(headerGroup => (
					<tr {...headerGroup.getHeaderGroupProps()}>
						{headerGroup.headers.map(column => (
							<th {...column.getHeaderProps()}>{column.render('Header')}</th>
						))}
					</tr>
				))}
			</thead>
			<tbody className='projects-table-body' {...getTableBodyProps()}>
				{rows.map(row => {
					const key = row.original.id
						? `${row.original.id}-${
								'subRows' in row.original ? 'album' : 'rec'
						  }`
						: '';
					prepareRow(row);
					return 'subRows' in row.original
						? renderAlbumRow(row as AlbumTableRow, key)
						: renderRecordingRow(row as RowType<RecordingRow>, key);
				})}
			</tbody>
		</table>
	);
}

const AlbumSubTable = ({ albumData }: { albumData: AlbumRow }) => {
	const columns = React.useMemo(
		() => albumsColumns as ColumnWithStrictAccessor[],
		[]
	);
	const dispatch = useAppDispatch();
	const [recordings, setRecordings] = useState<(AlbumRecordingRow | '')[]>([]);
	const [isCoOwner, setIsCoOwner] = useState(false);
	const { albumsById, recordingsById, myEditorProfile, projectUsage } =
		useAppSelector(state => state.projects);
	const { userId } = useAppSelector(state => state.auth);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
		useTable({
			columns,
			data: recordings.filter(
				(recording): recording is AlbumRecordingRow =>
					typeof recording === 'object'
			),
		});

	useEffect(() => {
		if (albumData && albumData.subRows) {
			setRecordings(albumData.subRows);
		}
	}, [albumData]);

	useEffect(() => {
		const getEditorPermission = async () => {
			try {
				if (!albumData?.owner?.projectUserId) return;

				const { data } = await getAlbumEditors(albumData.id!);
				const { editors } = data;
				const myEditorProfile = editors.find(
					(editor) => editor.user_id === userId
				);

				// We just take into count if the user is a co-owner, otherwise the UI is disabled.
				if (myEditorProfile?.is_owner || !myEditorProfile?.is_read_only)
					setIsCoOwner(true);
			} catch (error) {
				console.log(error);
			}
		};
		getEditorPermission();
	}, [userId, albumData]);

	const handleImport = useCallback(() => {
		dispatch(
			showModalAction(SELECT_RECORDING_MODAL, {
				onSubmit: (selectedRecordings: number[]) => {
					const swapAlbumRecordings = selectedRecordings.filter(
						recId =>
							recordingsById?.[recId].albumId &&
							recordingsById?.[recId].albumId !== albumData.id
					);
					const nonSwapAlbumRecordings = selectedRecordings.filter(
						recId => !swapAlbumRecordings.includes(recId)
					);

					swapAlbumRecordings.forEach(recordingId => {
						const recording = recordingsById?.[recordingId];
						if (!recording) {
							throw new Error(
								`Recording with id ${recordingId} not found in recordingsById`
							);
						}

						if (!albumData.id) {
							throw new Error(
								`Album data prop does not have an id, cannot swap recording`
							);
						}

						dispatch(
							swapRecordingBetweenCloudAlbumsAction(recording.id, albumData.id)
						);
					});

					if (nonSwapAlbumRecordings.length > 0) {
						dispatch(
							addRecordingsToCloudAlbumAction(
								albumData.id as number,
								nonSwapAlbumRecordings
							)
						);
					}
				},

				title: 'SELECT RECORDING(S) TO IMPORT',
				recordingFilter: (recording: Recording) =>
					recording.albumId !== albumData.id,
			})
		);
	}, [albumData, dispatch, recordingsById]);

	const reorder = useCallback(
		(sourceIndex: number, destinationIndex: number) => {
			const newRecordings = [...recordings];
			const [removed] = newRecordings.splice(sourceIndex, 1);
			newRecordings.splice(destinationIndex, 0, removed);
			setRecordings(newRecordings);
		},
		[recordings]
	);

	const handleDragEnd: OnDragEndResponder = useCallback(
		result => {
			if (!result.destination) {
				return;
			}

			if (result.type === 'albumDroppable') {
				const destinationAlbumId = parseInt(
					result.destination.droppableId.replace('album-table-', '')
				);

				if (!albumsById?.[destinationAlbumId].recordings) {
					throw new Error(
						`Album with id ${destinationAlbumId} does not have a recordings object`
					);
				}

				const recordingId = parseInt(result.draggableId.replace('-rec', ''));

				// dragged recording is in an album
				if (result.source.droppableId.includes('album')) {
					const sourceAlbumId = parseInt(
						result.source.droppableId.replace('album-table-', '')
					);

					// change track numbers
					if (sourceAlbumId === destinationAlbumId) {
						if (result.source.index === result.destination.index) {
							return;
						}

						reorder(result.source.index, result.destination.index);

						const reorderedRecordings = reorderAlbumRecordings(
							recordingId,
							result.destination.index + 1, // the album recordings object is 1-indexed
							albumsById?.[destinationAlbumId].recordings
						);

						dispatch(
							reorderLocalAlbumRecordingsAction(
								destinationAlbumId,
								reorderedRecordings
							)
						);
						dispatch(
							reorderCloudAlbumRecordingsAction(
								destinationAlbumId,
								reorderedRecordings
							)
						);
					}
				}
			}
		},
		[reorder, dispatch, albumsById]
	);

	const handleCreateNew = useCallback(() => {
		if (hasReachedProjectLimit(projectUsage!)) {
			dispatch(showModalAction(PROJECT_LIMIT_MODAL, { size: 'md' }));
			return;
		}

		dispatch(
			showModalAction(CREATE_NEW_RECORDING_MODAL, {
				size: 'lg',
				albumId: albumData.id,
			})
		);
	}, [albumData, dispatch, projectUsage]);

	const handleUpClick = useCallback(
		(row: RowType<AlbumRecordingRow>, index: number) => {
			if (myEditorProfile && myEditorProfile.is_read_only) return;
			if (!albumData.id) {
				throw new Error(`Album data prop does not have an id, cannot reorder`);
			}

			if (!albumsById) {
				throw new Error(`Albums by id not found, cannot reorder`);
			}

			if (!row.original.id) {
				throw new Error(
					`Row original does not have an id, cannot reorder recording`
				);
			}

			if (index > 0) {
				const newIndex = index - 1;
				reorder(index, newIndex);

				const reorderedRecordings = reorderAlbumRecordings(
					row.original.id,
					newIndex + 1, // tracks are 1-indexed in the album recordings object, so we need to add 1
					albumsById[albumData.id].recordings
				);
				dispatch(
					reorderCloudAlbumRecordingsAction(albumData.id, reorderedRecordings)
				);
				dispatch(
					reorderLocalAlbumRecordingsAction(albumData.id, reorderedRecordings)
				);
			}
		},
		[albumData, dispatch, myEditorProfile, reorder, albumsById]
	);

	const handleDownClick = useCallback(
		(row: RowType<AlbumRecordingRow>, index: number) => {
			if (myEditorProfile && myEditorProfile.is_read_only) return;
			if (!albumData.id) {
				throw new Error(`Album data prop does not have an id, cannot reorder`);
			}
			if (!albumsById?.[albumData.id]?.recordings) {
				throw new Error(
					`Album with id ${albumData.id} does not have a recordings object`
				);
			}
			if (!row.original.id) {
				throw new Error(
					`Row original does not have an id, cannot reorder recording`
				);
			}

			if (index === rows.length - 1) return;

			const newIndex = index + 1;
			reorder(index, newIndex);

			const reorderedRecordings = reorderAlbumRecordings(
				row.original.id,
				newIndex + 1, // tracks are 1-indexed in the album recordings object, so we need to add 1 to the index
				albumsById[albumData.id].recordings
			);

			dispatch(
				reorderCloudAlbumRecordingsAction(albumData.id, reorderedRecordings)
			);
			dispatch(
				reorderLocalAlbumRecordingsAction(albumData.id, reorderedRecordings)
			);
		},
		[albumData, dispatch, myEditorProfile, reorder, albumsById, rows]
	);

	return (
		<Container className='p-4'>
			<Row className='mb-4'>
				<Col xs={12} md={6} className='d-flex justify-content-between'>
					<h2>{albumData.title.toUpperCase()}</h2>
				</Col>
				{isCoOwner && (
					<Col xs={12} md={6} className='d-flex justify-content-end'>
						<Button
							className='mr-2'
							label='Add Existing Recording(s)'
							onClick={handleImport}
						/>
						<Button label='Create New Recording' onClick={handleCreateNew} />
					</Col>
				)}
			</Row>
			<DragDropContext onDragEnd={handleDragEnd}>
				<Droppable
					droppableId={`album-table-${albumData.id}`}
					type={`albumDroppable`}
					isDropDisabled={!isCoOwner}
				>
					{provided => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							{rows?.length ? (
								<Row>
									<Col xs={12}>
										<table className='projects-table' {...getTableProps()}>
											<thead className='projects-table-header'>
												{headerGroups.map(headerGroup => (
													<tr {...headerGroup.getHeaderGroupProps()}>
														{headerGroup.headers.map(column => (
															<th {...column.getHeaderProps()}>
																{column.render('Header')}
															</th>
														))}
													</tr>
												))}
											</thead>
											<tbody {...getTableBodyProps()}>
												{rows.map((row, index) => {
													const key = `${row.original.id}-rec-${index}`;
													prepareRow(row);
													return (
														<Draggable
															key={key}
															draggableId={key}
															index={index}
															isDragDisabled={!isCoOwner}
														>
															{provided => (
																<tr
																	className='albums-table-row'
																	{...row.getRowProps()}
																	{...provided.draggableProps}
																	ref={provided.innerRef}
																>
																	{row.cells.map(cell => {
																		return (
																			<td
																				className='projects-table-cell'
																				{...cell.getCellProps()}
																			>
																				{cell.render('Cell', {
																					dragHandleProps:
																						provided.dragHandleProps,
																					isFirst: index === 0,
																					isLast: index === rows.length - 1,
																					onUpClick: handleUpClick,
																					onDownClick: handleDownClick,
																					row,
																					index,
																					isCoOwner,
																				})}
																			</td>
																		);
																	})}
																</tr>
															)}
														</Draggable>
													);
												})}
												{provided.placeholder}
											</tbody>
										</table>
									</Col>
								</Row>
							) : (
								<></>
							)}
						</div>
					)}
				</Droppable>
			</DragDropContext>
		</Container>
	);
};

export default ProjectsTable;
