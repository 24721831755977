import { FormikProps, FormikProvider, useFormik } from 'formik';
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import * as yup from 'yup';
import Button from '../../layout/Button/Button';
import TextField from '../../form/TextField';
import DatePicker from '../../form/DatePicker';
// import { setAlbum } from '../../../store/album/actions';
import genreOptions from '../../../constants/genreOptions.json';
import { useEffect, useMemo, useState } from 'react';
import {
	fetchAlbumByIdAction,
	generateGRidAction,
	updateCloudAlbumAction,
} from '../../../store/projects/actions';
import albumSchema from '../../../constants/album.json';
import SoundCreditLoader from '../SoundCreditLoader';
import { hideModal, setModalTitle } from '../../../store/modal/actions';
import { getAlbumEditors } from '../../../api/services/editorService';
import React from 'react';
import { Toast } from 'primereact/toast';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import CreatableSelect from '../../form/CreatableSelect';
import recordLabelOptions from '@/constants/recordLabelOptions.json';
import { matchSorter } from 'match-sorter';

const schema = yup.object().shape({
	title: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === null ? '' : curr))
		.required('Title is required'),
	artistName: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === null ? '' : curr))
		.required('Artist Name is required'),
	version: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === null ? '' : curr)),
	recordLabel: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === null ? '' : curr)),
	releaseDate: yup
		.date()
		.nullable()
		.transform((curr, orig) => (orig === '' ? null : curr)),
	upcEan: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === null ? '' : curr)),
	grid: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === null ? '' : curr)),
	catalogId: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === null ? '' : curr)),
	genre: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === null ? '' : curr)),
});

export type AlbumDetailsModalProps = {
	albumId: Album['id'];
	afterSubmit?: () => void;
	toastErrors?: string[];
};

const AlbumDetailsModal = ({
	albumId,
	afterSubmit,
	toastErrors,
}: AlbumDetailsModalProps) => {
	const dispatch = useAppDispatch();
	const selectedAlbum = useAppSelector(
		state => state.projects.albumsById![albumId]
	);
	const { userId, userPermissions } = useAppSelector(state => state.auth);

	const [isGenerateLoading, setIsGenerateLoading] = useState(false);

	const [isCoOwner, setIsCoOwner] = useState(false);
	const [coOwnerLoading, setCoOwnerLoading] = useState(true);

	const disableGrid = useMemo(
		() => isGenerateLoading || !Object.keys(selectedAlbum?.recordings)?.length,
		[isGenerateLoading, selectedAlbum.recordings]
	);

	const toastRef = React.useRef<Toast>(null);

	const showLoading = !(
		selectedAlbum &&
		selectedAlbum.album &&
		!coOwnerLoading
	);

	useEffect(() => {
		const getEditorPermission = async () => {
			try {
				if (!('userId' in selectedAlbum)) return;

				const { data } = await getAlbumEditors(selectedAlbum.id);
				const { editors } = data;
				const myEditorProfile = editors.find(
					editor => editor.user_id === userId
				);

				if (myEditorProfile?.is_owner || !myEditorProfile?.is_read_only) {
					setIsCoOwner(true);
				}

				setCoOwnerLoading(false);
			} catch (error) {
				console.log(error);
				setCoOwnerLoading(false);
			}
		};

		getEditorPermission();
	}, [userId, selectedAlbum]);

	const formik = useFormik<AlbumContent>({
		initialValues: selectedAlbum?.album || albumSchema,
		validationSchema: schema,
		enableReinitialize: true,
		onSubmit: async values => {
			console.log(values);
			await dispatch(updateCloudAlbumAction({ ...values }));

			if (afterSubmit) afterSubmit();

			dispatch(hideModal());
		},
	});

	const [recordLabels, setRecordLabels] = useState([
		...recordLabelOptions.options,
		{
			label: formik.values.recordLabel,
			value: formik.values.recordLabel,
		},
	]);

	const [genres, setGenres] = useState([
		...genreOptions.options,
		{
			label: formik.values.genre,
			value: formik.values.genre,
		},
	]);

	useEffect(() => {
		if (selectedAlbum && !selectedAlbum.album && albumId) {
			dispatch(fetchAlbumByIdAction(albumId));
		}
	}, [selectedAlbum, albumId, dispatch]);

	const handleCancel = () => dispatch(hideModal());

	const handleGenerateGrid = async (
		releaseFormik: FormikProps<AlbumContent>
	) => {
		try {
			setIsGenerateLoading(true);
			await dispatch(
				generateGRidAction({
					albumForm: releaseFormik,
					albumId,
				})
			);
		} catch (e) {
			console.error(e);
		} finally {
			setIsGenerateLoading(false);
		}
	};

	useEffect(() => {
		dispatch(setModalTitle('Album Info'));
	}, [dispatch]);

	const renderAlbumReleaseDetails = () => {
		return (
			<>
				<Row>
					<Col xs={6}>
						<TextField
							label='Album Title'
							type='text'
							id='title'
							name='title'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.title}
							errorMessage={formik.errors.title}
							isInvalid={Boolean(formik.touched.title && formik.errors.title)}
							readOnly={!isCoOwner}
						/>
					</Col>

					<Col xs={6}>
						<TextField
							label='Artist Name *'
							type='text'
							id='artistName'
							name='artistName'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.artistName}
							errorMessage={formik.errors.artistName}
							isInvalid={Boolean(
								formik.touched.artistName && formik.errors.artistName
							)}
							readOnly={!isCoOwner}
						/>
					</Col>

					<Col xs={6}>
						<CreatableSelect
							label='Genre'
							name='genre'
							id='genre'
							options={genres}
							placeholder='Search...'
							value={genres.find(obj => formik.values.genre === obj.value)}
							onCreateOption={value => {
								const newOption = {
									value: value,
									label: value,
								};
								setGenres(options => [...options, newOption]);
								formik.setFieldValue('genre', value);
							}}
							onChange={option =>
								formik.setFieldValue('genre', option?.value ?? '')
							}
							isDisabled={!isCoOwner}
							errorFieldName='genre'
						/>
					</Col>

					<Col xs={6}>
						<CreatableSelect
							label='Record Label'
							name='recordLabel'
							id='recordLabel'
							options={recordLabels}
							placeholder='Search...'
							value={recordLabels.find(
								obj => formik.values.recordLabel === obj.value
							)}
							onCreateOption={value => {
								const newOption = {
									value: value,
									label: value,
								};
								setRecordLabels(options => [...options, newOption]);
								formik.setFieldValue('recordLabel', value);
							}}
							onChange={option =>
								formik.setFieldValue('recordLabel', option?.value ?? '')
							}
							onInputChange={val => {
								setRecordLabels(
									matchSorter([...recordLabelOptions.options], val, {
										keys: ['label', 'value'],
									})
								);
							}}
							errorFieldName='recordLabel'
							isDisabled={!isCoOwner}
						/>
					</Col>

					<Col xs={6}>
						<DatePicker
							label='Release Date'
							id='releaseDate'
							name='releaseDate'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.releaseDate}
							readOnly={!isCoOwner}
						/>
					</Col>

					<Col xs={6}>
						<TextField
							label='UPC/EAN'
							type='number'
							id='upcEan'
							name='upcEan'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.upcEan}
							placeholder='Enter all numbers'
							readOnly={!isCoOwner}
						/>
					</Col>

					<Col xs={6}>
						<TextField
							label='GRid'
							type='text'
							id='grid'
							name='grid'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.grid}
							placeholder='e.g. A1-2425G-AB1234002-M'
							buttonLabel={isCoOwner ? 'Generate' : undefined}
							isDisabled={disableGrid}
							onButtonClick={() => handleGenerateGrid(formik)}
							readOnly={!isCoOwner}
							isButtonLocked={!userPermissions?.canGenerateCodes}
							buttonTooltipText={
								disableGrid && !isGenerateLoading
									? 'To generate a GRid, the album must contain at least one track'
									: ''
							}
						/>
					</Col>

					<Col xs={6}>
						<TextField
							label='Catalog ID'
							type='text'
							id='catalogId'
							name='catalogId'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.catalogId}
							placeholder='Often found on the album spine'
							readOnly={!isCoOwner}
						/>
					</Col>

					<Col xs={6}>
						<TextField
							label='Version'
							type='text'
							id='version'
							name='version'
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.version}
							placeholder='(Optional)'
							readOnly={!isCoOwner}
						/>
					</Col>
				</Row>
			</>
		);
	};

	useEffect(() => {
		if (
			toastErrors &&
			toastErrors.length > 0 &&
			toastRef.current &&
			!showLoading
		) {
			toastRef.current?.show(
				toastErrors.map(e => ({ severity: 'error', detail: e, life: 10000000 }))
			);
		}
	}, [toastErrors, showLoading]);

	return showLoading ? (
		<Modal.Body>
			<Container style={{ height: '60vh' }}>
				<SoundCreditLoader />
			</Container>
		</Modal.Body>
	) : (
		<>
			<Modal.Body>
				<FormikProvider value={formik}>
					<Container>
						<Row className='px-4'>
							<Col xs={12}>
								<Form onSubmit={formik.handleSubmit}>
									{renderAlbumReleaseDetails()}
								</Form>
							</Col>
						</Row>
					</Container>
				</FormikProvider>
			</Modal.Body>
			<Modal.Footer>
				<Row>
					{isCoOwner ? (
						<Col xs={12} className='d-flex justify-content-end'>
							<Button label='Cancel' className='mr-3' onClick={handleCancel} />
							<Button
								label='Save & Proceed'
								theme='dark'
								className='btn btn-purple'
								onClick={() => formik.handleSubmit()}
							/>
						</Col>
					) : (
						<div className='py-2' />
					)}
				</Row>
			</Modal.Footer>
			<Toast ref={toastRef} />
		</>
	);
};

export default AlbumDetailsModal;
