import React, { useMemo, useState } from 'react';
import { filterPlaylistsBySearchTerm } from '@/helpers/playlistTools';
import PillSearchBar from '@/components/form/PillSearchBar';
import SubLink from '@/components/layout/SubLink';
import PlaylistList from './PlaylistList';
import './PlaylistSubmenu.scss';
import { useNavigate } from 'react-router-dom';
import { replacePathVariables } from '@/helpers/routeTools';
import ROUTES from '@/router/routes';
import { createPlaylistAction } from '@/store/playlists/actions';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

const PlaylistSubmenu = () => {
	const { playlistsById } = useAppSelector(state => state.playlists);
	const dispatch = useAppDispatch();

	const [isCreating, setIsCreating] = useState(false);

	const navigate = useNavigate();

	const playlists = useMemo(
		() =>
			playlistsById
				? (Object.values(playlistsById) as Playlist[]).sort(
						(a, b) => b.id - a.id
				  )
				: [],
		[playlistsById]
	);
	const [searchTerm, setSearchTerm] = useState('');

	const filteredPlaylists = useMemo(
		() => filterPlaylistsBySearchTerm(playlists, searchTerm),
		[playlists, searchTerm]
	);

	const isLoading = useMemo(() => !playlistsById, [playlistsById]);
	// const isLoading = true;

	const afterCreate = (playlistId: Playlist['id']) => {
		navigate(replacePathVariables(ROUTES.PlaylistDetails.path, { playlistId }));
	};

	const handleNewPlaylist = () => {
		setIsCreating(true);

		dispatch(createPlaylistAction({}, afterCreate)).finally(() => {
			setIsCreating(false);
		});
	};

	return (
		<div className='playlist-submenu-container'>
			<div className='playlist-top-submenu'>
				{/* <SubLink
					label='Transfer Files'
					isAction
					onClick={handleNewPlaylist}
					icon='fas fa-file-import'
					className='playlist-submenu-action mt-4 py-1'
					isLoading={isCreating}
				/> */}

				<SubLink
					label='Transfer Files'
					// isAction
					fullPath={[ROUTES.TransferFiles.path, ROUTES.Playlists.path]}
					// onClick={handleTransferFiles}
					icon='fas fa-file-import'
					className='playlist-submenu-action mt-4 py-1'
					innerStyle={{
						paddingLeft: '1.35rem',
					}}
					to={ROUTES.TransferFiles.path}
					// isLoading={isCreating}
				/>

				<hr />

				<SubLink
					label='New Playlist'
					isAction
					onClick={handleNewPlaylist}
					icon='fas fa-plus-square'
					className='playlist-submenu-action py-1'
					isLoading={isCreating}
				/>
				<div className='my-3' />

				<h4 className='playlist-submenu-header mb-2'>Playlists</h4>
				<div className='w-100 d-flex justify-content-center'>
					<PillSearchBar
						isLoading={isLoading}
						className='w-100 mx-3'
						placeholder='Search Playlists...'
						value={searchTerm}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
							setSearchTerm(e.target.value)
						}
					/>
				</div>

				<hr />
			</div>

			{!isLoading && filteredPlaylists?.length === 0 ? (
				<div
					className='h-100 px-4'
					style={{ textAlign: 'center', fontSize: '0.9rem' }}
				>
					{searchTerm ? (
						<div className='playlist-submenu__no-search-results'>
							No playlists found matching{' '}
							<span className='fw-600'>'{searchTerm}'</span>
						</div>
					) : (
						<>
							You don't have any playlists yet. Get started by clicking the{' '}
							<span className='fw-600'>
								<i className='fas fa-plus-square' /> New Playlist
							</span>{' '}
							button.
						</>
					)}
				</div>
			) : (
				<PlaylistList
					className='playlist-bottom-submenu'
					playlists={filteredPlaylists}
				/>
			)}
		</div>
	);
};

export default PlaylistSubmenu;
