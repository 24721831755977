import React, { useMemo, useRef, useState } from 'react';
import { Badge, Breadcrumb, Col, Row, Tab, Tabs } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ROUTES from '../../../router/routes';
import ProjectFilesBrowser from './ProjectFilesBrowser';
import ProjectUploadQueue from './ProjectUploadQueue';
import ProjectExportsBrowser from './ProjectExportsBrowser';
import './ProjectFiles.scss';
import FILE_TABS from '../../../constants/fileTabs.json';
import { useEffect } from 'react';
import {
	getFilesByProjectId,
	getProjectStorageUsage,
	getUploadsByProjectId,
} from '../../../store/files/selectors';
import SoundCreditLoader from '../SoundCreditLoader/SoundCreditLoader';
import UPLOAD_STATUS from '../../../constants/uploadStatus.json';
import { getExportsByProjectId } from '../../../store/exports/selectors';
import { getProjectById } from '../../../store/projects/selectors';
import {
	fetchMyAlbumEditorProfileAction,
	fetchMyRecordingEditorProfileAction,
	fetchProjectUploadsAndExportsAction,
} from '../../../store/projects/actions';
import { navigateToProjectFiles } from '../../../helpers/fileTools';
import FileStorageStatus from './FileStorageStatus';
import { getStorageUsageAction } from '../../../store/files/actions';
import { Helmet } from 'react-helmet';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import PillButton from '../../layout/PillButton';
import { navigateToProjectEditor } from '../../../helpers/projectTools';

type ProjectFilesTab = {
	title:
		| (({
				projectUploads,
				activeKey,
		  }: {
				projectUploads: ProjectFileUpload[];
				activeKey: any;
		  }) => JSX.Element)
		| (() => JSX.Element);
	key: string;
	render: (props: any) => JSX.Element;
};

const tabs: ProjectFilesTab[] = [
	{
		title: () => <>UPLOADED FILES</>,
		key: FILE_TABS.FILES,
		render: props => <ProjectFilesBrowser {...props} />,
	},
	{
		title: () => <>EXPORTS</>,
		key: FILE_TABS.EXPORTS,
		render: props => <ProjectExportsBrowser {...props} />,
	},
	{
		title: ({ projectUploads, activeKey }) => {
			const inProgressCount = projectUploads.filter(
				upload => upload.status === UPLOAD_STATUS.IN_PROGRESS
			).length;

			return (
				<div className='d-flex align-items-center'>
					UPLOADS IN PROGRESS{' '}
					{inProgressCount > 0 ? (
						<Badge
							bg={`${activeKey === FILE_TABS.UPLOADS ? 'light' : 'secondary'}`}
							className='ml-2'
						>
							{inProgressCount}
						</Badge>
					) : (
						<></>
					)}
				</div>
			);
		},
		key: FILE_TABS.UPLOADS,
		render: props => <ProjectUploadQueue {...props} />,
	},
];

const ProjectFiles = () => {
	/*
	 * React Router Hooks
	 */
	const params = useParams();
	const navigate = useNavigate();

	/*
	 * State
	 */

	const activeTabKey = useMemo(() => {
		const tabKey = params.section;
		return tabs.find(tab => tab.key === tabKey)?.key ?? FILE_TABS.FILES;
	}, [params]);

	const { recordingId, albumId } = useMemo(() => {
		const { recordingId, albumId } = params;

		return {
			recordingId: recordingId ? parseInt(recordingId) : null,
			albumId: albumId ? parseInt(albumId) : null,
		};
	}, [params]);

	const project = useAppSelector(state =>
		getProjectById(state, { albumId, recordingId })
	);

	const assetsCount = useMemo(() => project?.assetsCount ?? 0, [project]);

	const [isLoading, setIsLoading] = useState(false);
	const fetchedFiles = useRef(false);
	/*
	 * Redux Hooks
	 */
	const { userId } = useAppSelector(state => state.auth);
	const projectFilesById = useAppSelector(state =>
		getFilesByProjectId(state, { albumId, recordingId })
	);

	const exports = useAppSelector(state =>
		getExportsByProjectId(state, { albumId, recordingId })
	);

	const projectUploads = useAppSelector(state =>
		getUploadsByProjectId(state, { albumId, recordingId })
	);

	const { albumsById, myEditorProfile } = useAppSelector(
		state => state.projects
	);

	const dispatch = useAppDispatch();

	const isOwner = useMemo(() => project?.userId === userId, [project, userId]);

	const fileCount = useMemo(
		() => Object.keys(projectFilesById).length,
		[projectFilesById]
	);

	const isReadOnly = useMemo(
		() => (myEditorProfile ? myEditorProfile.is_read_only : true),
		[myEditorProfile]
	);

	const userTabs = useMemo(() => {
		if (isReadOnly) {
			return tabs.filter(tab => tab.key !== FILE_TABS.UPLOADS);
		}

		return tabs;
	}, [isReadOnly]);

	const projectStorageUsage = useAppSelector(state =>
		getProjectStorageUsage(state, { albumId, recordingId })
	);

	const pageTitle = useMemo(() => {
		if (!project) {
			return 'Project Files';
		}

		let title = project.title + ' -';

		switch (activeTabKey) {
			case FILE_TABS.FILES:
				title += ' Files';
				break;
			case FILE_TABS.EXPORTS:
				title += ' Exports';
				break;
			case FILE_TABS.UPLOADS:
				title += ' Uploads';
				break;
			default:
				break;
		}

		return title;
	}, [activeTabKey, project]);

	useEffect(() => {
		if (!isLoading && !fetchedFiles.current) {
			console.log('fetching files', fileCount, exports.length, assetsCount);
			setIsLoading(true);

			Promise.all([
				Promise.resolve(
					dispatch(
						fetchProjectUploadsAndExportsAction({ albumId, recordingId })
					)
				),
				Promise.resolve(
					recordingId
						? dispatch(fetchMyRecordingEditorProfileAction(recordingId))
						: dispatch(fetchMyAlbumEditorProfileAction(albumId!))
				),
				Promise.resolve(dispatch(getStorageUsageAction())),
			]).then(() => {
				setIsLoading(false);
				fetchedFiles.current = true;
			});
		}
	}, [
		albumId,
		dispatch,
		projectFilesById,
		isLoading,
		recordingId,
		assetsCount,
		exports,
		fileCount,
	]);

	const onTabSelect = (eventKey: string | null) => {
		navigateToProjectFiles({
			albumId,
			recordingId,
			section: eventKey!,
			navigate,
		});
	};

	return (
		<>
			{
				<Helmet>
					<title>
						{pageTitle} {process.env.REACT_APP_TAB_TITLE}
					</title>
				</Helmet>
			}
			<div className='container-horizontal-padding pb-4 pt-4'>
				<Row>
					<Col xs={12} sm={8}>
						<div className='d-flex align-items-center h-100'>
							<Breadcrumb className='project-files__breadcrumb mr-4'>
								<Breadcrumb.Item
									linkAs={Link}
									linkProps={{ to: ROUTES.Projects.path }}
								>
									PROJECTS
								</Breadcrumb.Item>
								<Breadcrumb.Item active>FILES</Breadcrumb.Item>
							</Breadcrumb>

							<PillButton
								label='View Project'
								leftIcon='fas fa-share'
								theme='text'
								onClick={() => {
									navigateToProjectEditor({
										albumId: albumId,
										recordingId: recordingId,
										navigate,
										dispatch,
										route: ROUTES.EditRecording.path,
										albumsById,
									});
								}}
							/>
						</div>
					</Col>
					{!isLoading && (
						<Col
							xs={12}
							sm={4}
							className='d-flex align-items-end justify-content-end flex-column'
						>
							<FileStorageStatus
								currentProjectStorageUsage={projectStorageUsage}
								isShared={!isOwner}
							/>
						</Col>
					)}
				</Row>
				{!projectFilesById || isLoading ? (
					<div style={{ height: '75vh' }}>
						<SoundCreditLoader message='Fetching Files...' />
					</div>
				) : (
					<Tabs
						className='project-files-tab'
						activeKey={activeTabKey}
						onSelect={onTabSelect}
					>
						{userTabs.map(tab => (
							<Tab
								key={tab.key}
								eventKey={tab.key}
								title={tab.title({ projectUploads, activeKey: activeTabKey })}
							>
								{tab.render({
									recordingId,
									albumId,
								})}
							</Tab>
						))}
					</Tabs>
				)}
			</div>
		</>
	);
};

export default ProjectFiles;
