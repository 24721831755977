import ProductType from '../../constants/productTypes';
import { shouldRetry } from '../../helpers/networkTools';
import authApi from '../authApi';
import axiosRetry from 'axios-retry';
import { Buffer } from 'buffer';

axiosRetry(authApi, {
	retries: 3,
	retryDelay: retryCount => {
		return retryCount * 1000;
	},
	shouldResetTimeout: true,
	retryCondition: shouldRetry,
});

export const login = (email: string, password: string) => {
	return authApi.post<{
		// TODO: Migrate to camelCase
		id: number;
		has_seen_timer_promotion: boolean;
		permissions: PermissionsResponse;
		portal_first_login: boolean;
		profileId: number;
		project_count: number;
		storage_usage: number;
		token: string;
		is_email_verified: boolean;
	}>('/', {
		user_email: email?.trim(),
		user_password: Buffer.from(password).toString('base64'),
		product: ProductType.PORTAL,
	});
};

export const validateToken = (userToken: string | null, userId: number) => {
	return authApi.post<{
		// TODO: Migrate to camelCase
		auth_passed: boolean;
		is_email_verified: boolean;
		has_seen_timer_promotion: boolean;
		permissions: PermissionsResponse;
		project_count: number;
		storage_usage: number;
	}>('/check-token', {
		token: userToken,
		user_id: userId,
		user_email: null,
	});
};

export const validateMagicLink = (token: string) => {
	return authApi.post<{
		// TODO: Migrate to camelCase
		id: number;
		has_seen_timer_promotion: boolean;
		permissions: PermissionsResponse;
		portal_first_login: boolean;
		profileId: number;
		project_count: number;
		storage_usage: number;
		token: string;
		is_email_verified: boolean;
	}>('/magic', {
		magic_link: token,
		product: 0,
	});
};

export const togglePlaylistJuly2023PromoSeen = (hasSeen: boolean) =>
	authApi.put('/seen-timer-promotion', {
		has_seen: hasSeen,
	});

export const reportPlaylistJuly2023PromoPass = () =>
	authApi.put('/passed-timer-promotion');
