import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { padInteger } from '../../../helpers/numberFormatters';
import './DurationPicker.scss';

const DurationPicker = ({ label, value, onChange, readOnly }) => {
	const parseDuration = duration =>
		duration && duration !== '' ? value.split(':') : [0, 0, 0];
	const [hourValue, minuteValue, secondValue] = parseDuration(value);
	const [hour, setHour] = useState(hourValue);
	const [minute, setMinute] = useState(minuteValue);
	const [second, setSecond] = useState(secondValue);

	const formattedDuration = useMemo(
		() =>
			`${padInteger(Number(hour))}:${padInteger(Number(minute))}:${padInteger(
				Number(second)
			)}`,
		[hour, minute, second]
	);

	useEffect(() => {
		onChange(formattedDuration);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formattedDuration]);

	return (
		<Form.Group className='mb-4'>
			<Form.Label>{label}</Form.Label>
			<Row>
				<Col xs={4}>
					<InputGroup>
						<Form.Control
							type='number'
							onWheel={e => e.target.blur()} // Prevents the input from being changed by scrolling
							onChange={e => setHour(e.currentTarget.value)}
							value={hour}
							min='0'
							placeholder='hh'
							readOnly={readOnly}
						/>
						<InputGroup.Text className='duration-input-group-text'>
							HH
						</InputGroup.Text>
					</InputGroup>
				</Col>
				<Col xs={4}>
					<InputGroup>
						<Form.Control
							type='number'
							onChange={e => {
								if (Number(e.target.value) > 59) setMinute('59');
								else setMinute(e.currentTarget.value);
							}}
							onWheel={e => e.target.blur()}
							value={minute}
							min='0'
							max='59'
							placeholder='mm'
							readOnly={readOnly}
						/>
						<InputGroup.Text className='duration-input-group-text'>
							MM
						</InputGroup.Text>
					</InputGroup>
				</Col>
				<Col xs={4}>
					<InputGroup>
						<Form.Control
							type='number'
							onChange={e => {
								if (Number(e.target.value) > 59) setSecond('59');
								else setSecond(e.currentTarget.value);
							}}
							onWheel={e => e.target.blur()}
							value={second}
							min='0'
							max='59'
							placeholder='ss'
							readOnly={readOnly}
						/>
						<InputGroup.Text className='duration-input-group-text'>
							SS
						</InputGroup.Text>
					</InputGroup>
				</Col>
			</Row>
		</Form.Group>
	);
};

export default DurationPicker;
