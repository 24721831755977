import axios from 'axios';
import { store } from '../store';

export const aiAnalysisApi = axios.create({
	baseURL:
		process.env.NODE_ENV === 'production'
			? process.env.REACT_APP_AI_ANALYSIS_API
			: '/ai-analysis',
});

aiAnalysisApi.interceptors.request.use(config => {
	const { userId, userToken } = store.getState().auth;

	if (userId && userToken && config.headers) {
		config.headers['publisher-user-id'] = userId;
		config.headers['publisher-user-token'] = userToken;
	}

	return config;
});
