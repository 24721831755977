import { FormikProvider, useFormik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import TextField from '../../../../../form/TextField/TextField';
import Button from '../../../../../layout/Button/Button';
import { useAppDispatch } from '../../../../../../store/hooks';
import {
	hideModal,
	setModalTitle,
} from '../../../../../../store/modal/actions';

const validationSchema = Yup.object().shape({
	password: Yup.string().required('Required'),
});

export type SetPlaylistPasswordModalProps = {
	onSubmit: (password: string) => void;
};

const SetPlaylistPasswordModal = ({
	onSubmit,
}: SetPlaylistPasswordModalProps) => {
	const dispatch = useAppDispatch();

	const formik = useFormik({
		initialValues: {
			password: '',
		},
		validationSchema,
		onSubmit: values => {
			onSubmit(values.password);
			handleClose();
		},
	});

	const handleClose = useCallback(() => {
		dispatch(hideModal());
	}, [dispatch]);

	useEffect(() => {
		dispatch(setModalTitle('CHANGE PLAYLIST PASSWORD'));
	}, [dispatch]);

	return (
		<FormikProvider value={formik}>
			<Modal.Body>
				<div className='px-4'>
					<TextField
						type='text'
						name='username'
						value=''
						style={{
							opacity: 0,
							position: 'absolute',
							pointerEvents: 'none',
						}}
						onChange={() => {}}
					/>

					<TextField
						name='password'
						id='password'
						placeholder='Enter Password'
						type='password'
						label='Playlist Password'
						autoComplete='new-password'
						isButtonDisabled={!formik?.values?.password}
						showEyeToggle
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.password}
						errorMessage={formik?.errors?.password}
						isInvalid={formik?.touched?.password && !!formik?.errors?.password}
						informationText='When you set a password for this playlist, please ensure to share it securely with all intended recipients.'
					/>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					theme='light'
					className='me-2'
					onClick={handleClose}
					label='Cancel'
				/>
				<Button
					theme='dark'
					onClick={formik.handleSubmit as any}
					label='Save'
					isDisabled={!formik.isValid}
				/>
			</Modal.Footer>
		</FormikProvider>
	);
};

export default SetPlaylistPasswordModal;
