import React, { useMemo } from 'react';
import { Button, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import './IconButton.scss';
import '../Header/Header.scss';
import theme from '../../../theme.module.scss';

export type IconButtonProps = {
	icon: string;
	onClick: (() => any | Promise<any>) | React.MouseEventHandler<HTMLElement>;
	color?: string;
	className?: string;
	isDisabled?: boolean;
	isLoading?: boolean;
	style?: React.CSSProperties;
	counter?: number;
	counterStyle?: React.CSSProperties;
	iconSize?: string | number;
	tooltipText?: string | React.ReactNode;
	backgroundColor?: string;
	loadingVariant?: 'dark' | 'light';
	outerStyle?: React.CSSProperties;
	hideTooltipOnDisabled?: boolean;
};

const IconButton = React.forwardRef(
	(
		{
			icon,
			onClick,
			color = 'black',
			className = '',
			isDisabled = false,
			isLoading = false,
			style = {},
			counter = 0,
			counterStyle = {
				backgroundColor: 'red',
				// boxShadow: '0 0 0 1px white',
			},
			iconSize = '1rem',
			tooltipText = '',
			backgroundColor = '',
			loadingVariant = 'dark',
			outerStyle = {},
			hideTooltipOnDisabled = false,
		}: IconButtonProps,
		ref
	) => {
		const counterText = useMemo(
			() => (counter > 99 ? '99+' : counter),
			[counter]
		);

		const backgroundColorWithCounter = useMemo(
			() => (counter > 0 ? theme.primaryTransparent : backgroundColor),
			[counter, backgroundColor]
		);

		const iconColorWithCounter = useMemo(
			() => (color === 'white' ? 'white' : counter > 0 ? theme.primary : color),
			[counter, color]
		);

		const showTooltip = tooltipText && (!isDisabled || !hideTooltipOnDisabled);

		return (
			<OverlayTrigger
				placement='auto'
				overlay={showTooltip ? <Tooltip>{tooltipText}</Tooltip> : <></>}
			>
				<div
					style={{
						// display: 'inline-block',
						...outerStyle,
					}}
				>
					<Button
						ref={ref as any}
						style={{
							position: counter ? 'relative' : undefined,
							backgroundColor: backgroundColorWithCounter,
							...style,
						}}
						disabled={isDisabled || isLoading}
						className={`${className} icon-button ${
							backgroundColor ? 'filled' : ''
						}`}
						onClick={onClick}
					>
						{isLoading ? (
							<Spinner animation='border' variant={loadingVariant} size='sm' />
						) : (
							<i
								style={{ color: iconColorWithCounter, fontSize: iconSize }}
								className={icon}
							></i>
						)}
						{counter > 0 && (
							<span
								className='badge header-badge notification-badge'
								style={{
									fontSize: '0.65rem',
									position: 'absolute',
									top: 3,
									right: -7,
									...counterStyle,
								}}
							>
								{counterText}
							</span>
						)}
					</Button>
				</div>
			</OverlayTrigger>
		);
	}
);

export default IconButton;
