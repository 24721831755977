import React from 'react';
import PillButton from '../PillButton';
import './DescriptionContainer.scss';

export type DescriptionContainerProps = {
	image: string;
	title: string;
	description: React.ReactNode | string;
	buttonLabel?: string;
	buttonRightIcon?: string;
	buttonLeftIcon?: string;
	onButtonClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

const DescriptionContainer = ({
	image,
	title,
	description,
	buttonLabel,
	buttonRightIcon,
	buttonLeftIcon,
	onButtonClick,
}: DescriptionContainerProps) => {
	return (
		<div className='description-container'>
			<div className='description-container__image'>
				<img src={image} alt='' />
			</div>
			<div className='description-container__content'>
				<h3>{title}</h3>
				{description}
				{buttonLabel && (
					<PillButton
						fullWidth
						className='px-3 py-2 w-100'
						theme='solid-light'
						label={buttonLabel}
						rightIcon={buttonRightIcon}
						leftIcon={buttonLeftIcon}
						onClick={onButtonClick}
					/>
				)}
			</div>
		</div>
	);
};

export default DescriptionContainer;
