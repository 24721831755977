import { FieldArray, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { Col, Form, Modal, Row } from 'react-bootstrap';
import CreatableSelect from '../../../form/CreatableSelect';
import TextField from '../../../form/TextField';
import Button from '../../../layout/Button';
import IconButton from '../../../layout/IconButton';
import styles from './CreateNewAlbumModal.module.scss';
import theme from '../../../../theme.module.scss';
import {
	hideAllModalsAction,
	setModalTitle,
	showModalAction,
} from '../../../../store/modal/actions';
import genreOptions from '../../../../constants/genreOptions.json';
import * as yup from 'yup';
import { createCloudAlbumWithRecordingsAction } from '../../../../store/projects/actions';
import recordLabelOptions from '../../../../constants/recordLabelOptions.json';
import { matchSorter } from 'match-sorter';
import emptyAlbum from '../../../../constants/album.json';
import { PROJECT_LIMIT_MODAL } from '../../../../constants/modalTypes';
import { hasReachedProjectLimit } from '../../../../helpers/tiersTools';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { SingleValue } from 'react-select';

const schema = yup.object().shape({
	// projectName: yup.string().trim().required('Project name is required'),
	artistName: yup.string().trim().required('Artist name is required'),
	title: yup.string().trim().required('Album title is required'),
	// title: yup.string().required('Album title is required').oneOf([yup.ref('projectName'), null], "Does not match with Project Name!"),
	recordLabel: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === null ? '' : curr)),
	recordingNames: yup
		.array()
		.of(yup.string().trim().required('Recording title is required')),
});

export type CreateNewAlbumModalProps = {
	onCreate?: (albumId: number) => void;
	recordingIds?: number[];
};

type FormValues = AlbumContent & {
	recordingNames?: string[];
};

type SelectOption = {
	value: string;
	label: string;
};

/**
 * Use recordingIds to add existing recordings to the new album
 */
const CreateNewAlbumModal = ({
	onCreate,
	recordingIds,
}: CreateNewAlbumModalProps) => {
	const [recordLabels, setRecordLabels] = useState([
		...recordLabelOptions.options,
	]);
	const { projectUsage } = useAppSelector(state => state.projects);

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setModalTitle('Create New Album'));
	}, [dispatch]);

	const handleOnCreate = async (albumId: number) => {
		if (onCreate) {
			onCreate(albumId);
		}
	};

	const albumSubmitHandler = async (values: FormValues) => {
		const recordingNames = values.recordingNames;
		const finalValues = {
			...values,
		};
		delete finalValues.recordingNames;

		await Promise.resolve(
			dispatch(
				createCloudAlbumWithRecordingsAction({
					album: finalValues,
					newRecordingTitles: recordingNames,
					onCreate: handleOnCreate,
					addRecordingIdsToAlbum: recordingIds,
				})
			)
		);
	};

	const closeModalHandler = () => dispatch(hideAllModalsAction());

	const formik = useFormik({
		initialValues: {
			...emptyAlbum,
			recordingNames:
				hasReachedProjectLimit(projectUsage!) || recordingIds ? [] : [''],
		} as FormValues,
		validationSchema: schema,
		enableReinitialize: true,
		onSubmit: async values => {
			// alert(JSON.stringify(values, null, 2));
			await albumSubmitHandler(values);
			closeModalHandler();
		},
	});

	const [genres, setGenres] = useState([
		...genreOptions.options,
		{
			label: formik.values.genre,
			value: formik.values.genre,
		},
	]);

	const handleCreateProject = () => formik.handleSubmit();

	const handleAddTrack = (push: (value: string) => void) => () => {
		if (
			formik?.values?.recordingNames &&
			hasReachedProjectLimit({
				used: formik?.values?.recordingNames?.length + projectUsage!.used,
				limit: projectUsage!.limit,
			})
		) {
			dispatch(
				showModalAction(PROJECT_LIMIT_MODAL, {
					message: `You have reached your limit of ${
						projectUsage!.limit
					} projects.`,
					size: 'md',
				})
			);
			return;
		}

		push('');
	};

	return (
		<FormikProvider value={formik}>
			<Modal.Body>
				<Row className='px-5'>
					<Col xs={12}>
						<Form>
							{/* <TextField
									label='Project Name'
									name='projectName'
									id='projectName'
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.projectName}
									isInvalid={formik.touched.projectName && formik.errors.projectName}
									errorMessage={formik.errors.projectName}
								/> */}

							<TextField
								label='Album Title'
								name='title'
								id='title'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.title}
								isInvalid={Boolean(formik.touched.title && formik.errors.title)}
								errorMessage={formik.errors.title}
								showRequiredAsterisk
							/>

							<TextField
								label='Artist Name'
								name='artistName'
								id='artistName'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								value={formik.values.artistName}
								isInvalid={Boolean(
									formik.touched.artistName && formik.errors.artistName
								)}
								errorMessage={formik.errors.artistName}
								showRequiredAsterisk
							/>

							<CreatableSelect<SelectOption>
								label='Genre'
								name='genre'
								id='genre'
								options={genres}
								placeholder='Search...'
								value={genres.find(obj => obj.value === formik.values.genre)}
								onCreateOption={(value: string) => {
									let newOption = { value: value, label: value };
									setGenres(options => [...options, newOption]);
									formik.setFieldValue(
										'genre',
										formik.values.genre.concat(value)
									);
								}}
								onChange={(option: SingleValue<SelectOption>) =>
									formik.setFieldValue('genre', option?.value ?? '')
								}
								onInputChange={(val: string) => {
									if (!val) return;

									setGenres(
										matchSorter([...genreOptions.options], val, {
											keys: ['value', 'label'],
										})
									);
								}}
								errorFieldName='genre'
								getOptionLabel={option => option.label}
								getOptionValue={option => option.value}
							/>

							<CreatableSelect<SelectOption>
								label="Album's Record Label"
								name='recordLabel'
								id='recordLabel'
								options={recordLabels}
								placeholder='Search...'
								value={recordLabels.find(
									obj => formik.values.recordLabel === obj.value
								)}
								onCreateOption={(value: string) => {
									const newOption = { value: value, label: value };
									setRecordLabels(options => [...options, newOption]);
									formik.setFieldValue('recordLabel', value);
								}}
								onChange={(option: SingleValue<SelectOption>, _) => {
									console.log('option', option);
									formik.setFieldValue('recordLabel', option?.value ?? '');
								}}
								errorFieldName='recordLabel'
								getOptionLabel={option => option.label}
								getOptionValue={option => option.value}
							/>
						</Form>
					</Col>
				</Row>

				{!recordingIds && (
					<Row className={`${styles.tracklist} mx-5 mt-4`}>
						<Col xs={12} className='p-4'>
							<FieldArray name='recordingNames'>
								{({ push, remove }) => (
									<>
										<div className='d-flex justify-content-between'>
											<h2>ALBUM RECORDINGS</h2>
											<Button
												label='Add Track'
												leftIcon='fas fa-plus'
												onClick={handleAddTrack(push)}
											/>
										</div>
										{formik?.values?.recordingNames?.map((track, index) => (
											<Row
												id={`recording-name-${index}`}
												key={`recording-name-${index}`}
												className='align-items-center mt-3'
											>
												<Col xs={1} className='d-flex align-items-center'>
													<h3>{index + 1}</h3>
												</Col>
												<Col xs={10}>
													<TextField
														dense
														name={`recordingNames[${index}]`}
														id={`recordingNames[${index}]`}
														value={formik.values.recordingNames![index]}
														placeholder='Track Name'
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														errorMessage={
															formik.errors.recordingNames &&
															formik.errors.recordingNames![index]
														}
														isInvalid={Boolean(
															formik.touched.recordingNames &&
																formik.errors.recordingNames &&
																formik.errors.recordingNames![index]
														)}
													/>
												</Col>
												<Col xs={1}>
													<IconButton
														icon='fas fa-trash'
														color={theme.error}
														onClick={() => {
															remove(index);
														}}
													/>
												</Col>
											</Row>
										))}
									</>
								)}
							</FieldArray>
						</Col>
					</Row>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Row>
					<Col xs={12} className='d-flex justify-content-end'>
						<Button
							label='Cancel'
							className='mr-3 '
							onClick={closeModalHandler}
						/>
						<Button
							label='Create'
							theme='dark'
							onClick={handleCreateProject}
							isLoading={formik.isSubmitting}
						/>
					</Col>
				</Row>
			</Modal.Footer>
		</FormikProvider>
	);
};

export default CreateNewAlbumModal;
