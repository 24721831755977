import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { showModalAction } from '../../../store/modal/actions';
import { FILE_VERSION_LIST_MODAL } from '../../../constants/modalTypes';
import PillButton from '../PillButton';

export type FileVersionsButtonProps = {
	file: FileMetadata;
	playlistId?: number | null;
	isDisabled?: boolean;
};

const FileVersionsButton = ({
	file,
	playlistId = null,
	isDisabled = false,
}: FileVersionsButtonProps) => {
	const dispatch = useDispatch();
	const versionCount = useMemo(
		() => Object.keys(file?.versions ?? {})?.length,
		[file]
	);

	const handleViewVersions = useCallback(
		(e: React.MouseEvent) => {
			e.stopPropagation();

			dispatch(
				showModalAction(FILE_VERSION_LIST_MODAL, {
					fileId: file.id,
					playlistId,
					size: 'xl',
				})
			);
		},
		[file, dispatch, playlistId]
	);

	return (
		<PillButton
			tooltipText='View Versions'
			leftIcon='fas fa-history'
			onClick={handleViewVersions}
			theme='text'
			outerClassName='d-flex'
			label={versionCount.toString()}
			leftIconClassName='mr-1'
			className='d-flex align-items-center'
			leftIconStyle={{ fontSize: '1rem' }}
			isDisabled={isDisabled || !versionCount}
		/>
	);
};

export default FileVersionsButton;
