import { AppDispatch, GetState } from '..';
import {
	deleteNotification,
	getNotifications,
	readNotification,
} from '../../api/services/notificationService';
import { getCloudProfileById } from '../../api/services/cloudProfilesService';
import {
	DELETE_NOTIFICATION_REQUEST,
	FETCH_USER_DATA_REQUEST,
	READ_NOTIFICATION_REQUEST,
} from '../../constants/requestLabelTypes';
import {
	CLEAR_USER_DATA,
	DELETE_LOCAL_NOTIFICATIONS,
	READ_LOCAL_NOTIFICATION,
	SET_ONBOARDING_CLICKED_SEND_PLAYLIST_LINK,
	START_USER_REQUEST,
	STORE_NOTIFICATIONS,
	STORE_USER_PROFILE,
	USER_REQUEST_AUTH_ERROR,
	USER_REQUEST_ERROR,
	USER_REQUEST_SUCCESS,
} from '../actionTypes';
import { showErrorAlert } from '../alertToast/actions';
import { fetchUserProfilesAction } from '../profiles/actions';
import AccountService from '../../api/services/accountService';
import { aliases } from 'browserslist';

const _handleUserError = (
	error: any,
	dispatch: AppDispatch,
	message: string
) => {
	console.log(error);
	if (error.response) {
		switch (error.response.status) {
			case 401:
				dispatch(userRequestAuthErrorAction());
				break;
			default:
				console.log(error.response);
				const errorMessage =
					message ??
					'Hiccup detected while syncing user data with the cloud. Try clicking Refresh Projects.';
				dispatch(showErrorAlert(`${errorMessage} (${error.response.status})`));
				dispatch(userRequestErrorAction(errorMessage));
		}
	} else {
		throw error;
	}
};

export const userRequestAuthErrorAction = () => ({
	type: USER_REQUEST_AUTH_ERROR,
});

export const userRequestErrorAction = (errorMessage: string) => ({
	type: USER_REQUEST_ERROR,
	errorMessage,
});

export const userRequestSuccessAction = () => ({
	type: USER_REQUEST_SUCCESS,
});

export const startUserRequestAction = (requestLabel: string) => ({
	type: START_USER_REQUEST,
	requestLabel,
});

export const storeUserProfileAction = (
	userProfile: CloudProfile,
	username: string
) => ({
	type: STORE_USER_PROFILE,
	userProfile,
	username,
});

// TODO: Migrate notifications to TS
export const storeNotificationsAction = (notifications: any) => ({
	type: STORE_NOTIFICATIONS,
	notifications,
});

export const clearUserAction = () => ({
	type: CLEAR_USER_DATA,
});

export const deleteLocalNotificationsAction = (ids: string[]) => ({
	type: DELETE_LOCAL_NOTIFICATIONS,
	ids,
});

export const fetchNotificationsAction = () => (dispatch: AppDispatch) => {
	return getNotifications()
		.then(notifications => {
			dispatch(storeNotificationsAction(notifications));
		})
		.catch(error =>
			// _handleUserError(
			// 	error,
			// 	dispatch,
			// 	'An error occurred while fetching notifications.'
			// )
			console.error(error)
		);
};

export const deleteNotificationAction =
	(id: string) => (dispatch: AppDispatch) => {
		dispatch(startUserRequestAction(DELETE_NOTIFICATION_REQUEST));

		return deleteNotification(id)
			.then(() => {
				dispatch(userRequestSuccessAction());
			})
			.catch(error =>
				_handleUserError(
					error,
					dispatch,
					'Hiccup detected while deleting a notification.'
				)
			);
	};

export const deleteNotificationsAction =
	(ids: string[]) => (dispatch: AppDispatch) => {
		dispatch(deleteLocalNotificationsAction(ids));

		return Promise.resolve(
			Promise.all(ids.map(id => dispatch(deleteNotificationAction(id))))
		);
	};

export const readLocalNotificationAction = (id: string) => ({
	type: READ_LOCAL_NOTIFICATION,
	id,
});

export const readNotificationAction =
	(id: string) => (dispatch: AppDispatch) => {
		dispatch(startUserRequestAction(READ_NOTIFICATION_REQUEST));

		dispatch(readLocalNotificationAction(id));

		return readNotification(id)
			.then(() => {
				dispatch(userRequestSuccessAction());
			})
			.catch(error =>
				_handleUserError(
					error,
					dispatch,
					'Hiccup detected while marking a notification as read.'
				)
			);
	};

export const fetchUserDataAction =
	() => (dispatch: AppDispatch, getState: GetState) => {
		dispatch(startUserRequestAction(FETCH_USER_DATA_REQUEST));

		const userProfileId = getState().auth.userProfileId;

		if (!userProfileId) {
			console.error('No user profile id found.');
			dispatch(
				userRequestErrorAction(
					'Something went wrong while getting your profile data. (No user profile ID found)'
				)
			);
			return;
		}

		return getCloudProfileById(userProfileId.toString())
			.then(profile => {
				dispatch(userRequestSuccessAction());

				if (!profile.aliases && !profile.legalName) {
					console.error(
						'No aliases or legal name found in user profile',
						userProfileId,
						profile
					);
					throw new Error(
						'Whoops! Hiccup detected while getting your user data.'
					);
				}

				console.log('PROFILE DATA: ', profile);
				const username = profile.legalName ?? aliases[0] ?? '';

				dispatch(storeUserProfileAction(profile, username));
				dispatch(fetchUserProfilesAction());
			})
			.catch(error => {
				_handleUserError(
					error,
					dispatch,
					'Whoops! Hiccup detected while getting your data. Try logging out and back in.'
				);
			});
	};

export const claimUserIsniAction =
	(isni: string) => async (dispatch: AppDispatch) => {
		try {
			await AccountService.claimIsni({ isni });
			return dispatch(fetchUserDataAction());
		} catch (error) {
			_handleUserError(
				error,
				dispatch,
				'Whoops! Hiccup detected while claiming your ISNI.'
			);
		}
	};

export const setOnboardingClickedSendPlaylistLinkAction = () => ({
	type: SET_ONBOARDING_CLICKED_SEND_PLAYLIST_LINK,
});
