import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
	getPlaylistUploadsById,
} from '../../../store/files/selectors';
import { getUploadingVersionsForFile } from '../../../helpers/fileTools';
import { Modal } from 'react-bootstrap';
import FileUploadItem from '../../layout/FileUploadMenu/FileUploadItem';
import { setModalTitle } from '../../../store/modal/actions';

type ManageUploadingVersionsModalProps = {
	playlistId: Playlist['id'];
	fileId: FileMetadata['id'];
};

const ManageUploadingVersionsModal = ({
	playlistId,
	fileId,
}: ManageUploadingVersionsModalProps) => {
	const dispatch = useAppDispatch();
	const playlistUploads = useAppSelector(state =>
		getPlaylistUploadsById(state, { playlistId, includeCanceledUploads: true })
	);
	const playlist = useAppSelector(
		state => state?.playlists?.playlistsById?.[playlistId]
	);
	const file = playlist?.playlist?.files?.find(f => f.id === fileId);

	const uploadingVersions = getUploadingVersionsForFile(
		fileId,
		playlistUploads
	);

	useEffect(() => {
		dispatch(setModalTitle(`Manage Uploading Versions For ${file?.filename}`));
	}, [dispatch, file]);

	return (
		<Modal.Body>
			{uploadingVersions.map((upload, index) => (
				<React.Fragment key={upload.id}>
					<FileUploadItem file={upload} key={upload.id} />
					{index < uploadingVersions.length - 1 && <hr 
                    className='my-1'
                    />}
				</React.Fragment>
			))}
		</Modal.Body>
	);
};

export default ManageUploadingVersionsModal;
