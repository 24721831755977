export const SINGLE_RELEASE_DETAILS_MODAL = 'SINGLE_RELEASE_DETAILS_MODAL';
export const SEND_PROJECT_MODAL = 'SEND_PROJECT_MODAL';
export const PROJECTS_FILE_PREVIEW_MODAL = 'PROJECTS_FILE_PREVIEW_MODAL';
export const EXPORT_SELECTION_MODAL = 'EXPORT_SELECTION_MODAL';
export const SELECT_RECORDING_MODAL = 'SELECT_RECORDING_MODAL';
export const CREATE_NEW_ALBUM_MODAL = 'CREATE_NEW_ALBUM_MODAL';
export const CREATE_NEW_RECORDING_MODAL = 'CREATE_NEW_RECORDING_MODAL';
export const GENERATE_CODE_MODAL = 'GENERATE_CODE_MODAL';
export const ALBUM_DETAILS_MODAL = 'ALBUM_DETAILS_MODAL';
export const DELETE_RECORDING_MODAL = 'DELETE_RECORDING_MODAL';
export const DELETE_ALBUM_MODAL = 'DELETE_ALBUM_MODAL';
export const DELETE_PARTICIPANT_MODAL = 'DELETE_PARTICIPANT_MODAL';
export const EXPORT_IMAGE_INPUT_MODAL = 'EXPORT_IMAGE_INPUT_MODAL';
export const SHARE_MODAL = 'SHARE_MODAL';
export const CHAT_MODAL = 'CHAT_MODAL';
export const PROJECTS_SHARE_MODAL = 'PROJECTS_SHARE_MODAL';
export const DELETE_ALL_PROFILES_MODAL = 'DELETE_ALL_PROFILES_MODAL';
export const IMPORT_RIN_MODAL = 'IMPORT_RIN_MODAL';
export const EDIT_ADDRESS_MODAL = 'EDIT_ADDRESS_MODAL';
export const VALIDATE_GRID_MODAL = 'VALIDATE_GRID_MODAL';
export const EXIT_UNION_FORM_MODAL = 'EXIT_UNION_FORM_MODAL';
export const VALIDATE_MOODS_GENERATOR = 'VALIDATE_MOODS_GENERATOR';
export const DELETE_FILE_MODAL = 'DELETE_FILE_MODAL';
export const RENAME_FILE_MODAL = 'RENAME_FILE_MODAL';
export const UPLOAD_FILES_MODAL = 'UPLOAD_FILES_MODAL';
export const SHARE_FILES_MODAL = 'SHARE_FILES_MODAL';
export const UPLOAD_PREVIEW_MODAL = 'UPLOAD_PREVIEW_MODAL';
export const EXPORT_PREVIEW_MODAL = 'EXPORT_PREVIEW_MODAL';
export const LOADING_MODAL = 'LOADING_MODAL';
export const COPY_CREDITS_FROM_RECORDING_MODAL =
	'COPY_CREDITS_FROM_RECORDING_MODAL';
export const COPY_CREDITS_TO_RECORDINGS_MODAL =
	'COPY_CREDITS_TO_RECORDINGS_MODAL';
export const SELECT_REPRESENTATIVE_MODAL = 'SELECT_REPRESENTATIVE_MODAL';
export const TIER_LOCK_MODAL = 'TIER_LOCK_MODAL';
export const UPGRADE_TIER_MODAL = 'UPGRADE_TIER_MODAL';
export const PROJECT_LIMIT_MODAL = 'PROJECT_LIMIT_MODAL';
export const STORAGE_LIMIT_MODAL = 'STORAGE_LIMIT_MODAL';
export const CHANGE_OWNER_LIMIT_MODAL = 'CHANGE_OWNER_LIMIT_MODAL';
export const EDIT_FILE_TAG_MODAL = 'EDIT_FILE_TAG_MODAL';
export const SHARE_PLAYLIST_MODAL = 'SHARE_PLAYLIST_MODAL';
export const NEW_PLAYLIST_MODAL = 'NEW_PLAYLIST_MODAL';
export const DELETE_PLAYLIST_ITEM_MODAL = 'DELETE_PLAYLIST_ITEM_MODAL';
export const ADD_FILES_TO_PLAYLIST_MODAL = 'ADD_FILES_TO_PLAYLIST_MODAL';
export const EDIT_PLAYLIST_DETAILS_MODAL = 'EDIT_PLAYLIST_DETAILS_MODAL';
export const UPLOAD_FILES_TO_PLAYLIST_MODAL = 'UPLOAD_FILES_TO_PLAYLIST_MODAL';
export const SELECT_PROJECT_FILES_MODAL = 'SELECT_PROJECT_FILES_MODAL';
export const DELETE_MODAL = 'DELETE_MODAL';
export const EDIT_PLAYLIST_FILE_MODAL = 'EDIT_PLAYLIST_FILE_MODAL';
export const FRESH_INSTALL_INFO_MODAL = 'FRESH_INSTALL_INFO_MODAL';
export const PLAYLISTS_RUNDOWN_MODAL = 'PLAYLISTS_RUNDOWN_MODAL';
export const IMAGE_CROP_MODAL = 'IMAGE_CROP_MODAL';
export const CORRUPTED_FILES_MODAL = 'CORRUPTED_FILES_MODAL';
export const CREATE_DRIP_MODAL = 'CREATE_DRIP_MODAL';
export const WHAT_IS_A_DRIP_MODAL = 'WHAT_IS_A_DRIP_MODAL';
export const DRIP_DASHBOARD_MODAL = 'DRIP_DASHBOARD_MODAL';
export const DRIP_AGREEMENT_MODAL = 'DRIP_AGREEMENT_MODAL';
export const TRANSFER_PLAYLIST_MODAL = 'TRANSFER_PLAYLIST_MODAL';
export const FILE_VERSION_LIST_MODAL = 'FILE_VERSION_LIST_MODAL';
export const UPLOAD_FILE_VERSION_MODAL = 'UPLOAD_FILE_VERSION_MODAL';
export const EDIT_FILE_VERSION_MODAL = 'EDIT_FILE_VERSION_MODAL';
export const DUPLICATE_PLAYLIST_MODAL = 'DUPLICATE_PLAYLIST_MODAL';
export const PLAYLIST_COLLAB_MODAL = 'PLAYLIST_COLLAB_MODAL';
export const INVITE_PLAYLIST_EDITOR_MODAL = 'INVITE_PLAYLIST_EDITOR_MODAL';
export const CONFIRMATION_MODAL = 'CONFIRMATION_MODAL';
export const SET_PLAYLIST_PASSWORD_MODAL = 'SET_PLAYLIST_PASSWORD_MODAL';
export const GROUP_TO_ALBUM_MODAL = 'GROUP_TO_ALBUM_MODAL';
export const BENEFITS_CODE_MODAL = 'BENEFITS_CODE_MODAL';
export const CONFIRM_UPGRADE_MODAL = 'CONFIRM_UPGRADE_MODAL';
export const CHECK_EMAIL_SIGNUP_MODAL = 'CHECK_EMAIL_SIGNUP_MODAL';
export const ARE_YOU_UNION_MEMBER_MODAL = 'ARE_YOU_UNION_MEMBER_MODAL';
export const ACCOUNT_DETAILS_MODAL = 'ACCOUNT_DETAILS_MODAL';
export const ISNI_REGISTRATION_MODAL = 'ISNI_REGISTRATION_MODAL';
export const PLAYLIST_CTA_MODAL_JULY_2023 = 'PLAYLIST_CTA_MODAL_JULY_2023';
export const EDIT_FILE_PROJECT_MODAL = 'EDIT_FILE_PROJECT_MODAL';
export const UPLOAD_VERSIONS_TO_PLAYLIST_MODAL =
	'UPLOAD_VERSIONS_TO_PLAYLIST_MODAL';
export const MANAGE_UPLOADING_VERSIONS_MODAL =
	'MANAGE_UPLOADING_VERSIONS_MODAL';
export const PRODUCER_AGREEMENT_SHARE_MODAL = 'PRODUCER_AGREEMENT_SHARE_MODAL';
export const EXPORT_ESIGNATURE_MODAL = 'EXPORT_ESIGNATURE_MODAL';
export const CREDIT_REQUESTS_MODAL = 'CREDIT_REQUESTS_MODAL';
export const CREATE_PLAYLIST_FOLDER_MODAL = 'CREATE_PLAYLIST_FOLDER_MODAL';
export const RENAME_PLAYLIST_FOLDER_MODAL = 'RENAME_PLAYLIST_FOLDER_MODAL';
export const SEND_PLAYLIST_LINK_MODAL = 'SEND_PLAYLIST_LINK_MODAL';

