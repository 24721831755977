import { createApiInstance } from './utils';

const docsApi = createApiInstance(process.env.REACT_APP_DOCS_API!, {
	translateCase: true,
	devProxyPath: '/docs',
	usePortalAuthHeaders: false,
	retryOnTimeout: true,
	bearerToken: process.env.REACT_APP_DOCS_API_TOKEN!,
});

export default docsApi;
