import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { getFilesById } from '../../../../store/files/selectors';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import TextField from '../../../form/TextField/TextField';
import { hideModal, setModalTitle } from '../../../../store/modal/actions';
import Button from '../../../layout/Button';
import { updateFileVersionMetadataAction } from '../../../../store/files/actions';

const validationSchema = Yup.object().shape({
	comment: Yup.string(),
});

const EditFileVersionModal = ({ fileId, versionId }) => {
	const dispatch = useDispatch();

	const filesById = useSelector(getFilesById);
	const file = useMemo(() => filesById?.[fileId], [filesById, fileId]);
	const version = useMemo(() => file?.versions?.[versionId], [file, versionId]);

	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = useCallback(
		async formValues => {
			console.log(formValues);

			setIsLoading(true);
			try {
				await dispatch(
					updateFileVersionMetadataAction({
						fileId: file?.id,
						versionId: version?.id,
						comment: formValues?.comment,
					})
				);
			} catch (error) {
				console.error(error);
			} finally {
				setIsLoading(false);
				dispatch(hideModal());
			}

			dispatch(hideModal());
		},
		[dispatch, file, version]
	);

	const formik = useFormik({
		initialValues: {
			comment: version?.comment ?? '',
		},
		validationSchema,
		onSubmit: handleSubmit,
	});

	const handleCancel = useCallback(() => {
		dispatch(hideModal());
	}, [dispatch]);

	useEffect(() => {
		dispatch(setModalTitle(`EDIT FILE VERSION #${version?.versionNo}`));
	}, [dispatch, version]);

	return (
		<>
			<Modal.Body>
				<div className='px-4'>
					<TextField
						label='Version Description'
						placeholder='Enter a name or description for this version'
						name='comment'
						value={formik.values.comment}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						isInvalid={formik?.touched?.comment && formik?.errors?.comment}
						errorMessage={formik?.errors?.comment}
						maxLength={70}
						showMaxLength
					/>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button label='Cancel' onClick={handleCancel} className='mr-2' />
				<Button
					label='Save'
					onClick={formik.handleSubmit}
					theme='dark'
					isLoading={isLoading}
				/>
			</Modal.Footer>
		</>
	);
};

export default EditFileVersionModal;
