import React from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import Button from '../../../../layout/Button';

const ExportWarningModal = ({
	show,
	onCancel,
	onConfirm,
	titleText,
	bodyText,
	cancelText,
	confirmText,
}) => {
	return (
		<Modal centered show={show} onHide={onCancel} size='md'>
			<Modal.Header className='p-4'>
				<Modal.Title>
					<h2 className='m-0'>{titleText}</h2>
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='px-4'>{bodyText}</div>
			</Modal.Body>
			<Modal.Footer>
				<Row>
					<Col
						xs={12}
						className='d-flex justify-content-end align-items-center'
					>
						<Button label={cancelText} onClick={onCancel} className='mr-2' />
						<Button label={confirmText} theme='dark' onClick={onConfirm} />
					</Col>
				</Row>
			</Modal.Footer>
		</Modal>
	);
};

export default ExportWarningModal;
