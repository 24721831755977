import { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import Button from '../../../layout/Button';
import { setModalTitle, hideModal } from '../../../../store/modal/actions';

const ValidateGRidModal = ({ errorList }) => {
	const dispatch = useDispatch();
	const [errorMessage, setErrorMessage] = useState();
	console.log('errorList', errorList);

	useEffect(() => {
		dispatch(setModalTitle('INFORMATION NEEDED FOR GRid CODE'));
		setErrorMessage(errorList.map(el => <p>{el}</p>));

		return () => {
			setErrorMessage('');
		};
	}, [errorList, dispatch]);

	return (
		<>
			<Modal.Body>
				<Alert key='danger' variant='danger'>
					{errorMessage}
				</Alert>
			</Modal.Body>
			<Modal.Footer>
				<Button
					label='Close'
					theme='dark'
					onClick={() => dispatch(hideModal())}
				/>
			</Modal.Footer>
		</>
	);
};

export default ValidateGRidModal;
