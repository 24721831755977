import axiosRetry from 'axios-retry';
import ProjectEditorRoles from '../../constants/projectEditorRoles';
import camelize from '../../helpers/camelize';
import {
	playlistArrayToObject,
	translateApiPlaylistEditorsToLocal,
	translateApiPlaylistToLocal,
	translateToPlaylistRequestFile,
} from '../../helpers/playlistTools';
import snakeize from '../../helpers/snakeize';
import { filesApi } from '../filesApi';
import translateApiFileToLocal from '../../helpers/translateApiFileToLocal';
import { translateApiObjectToLocal } from '../../helpers/apiTools';
import { shouldRetry } from '../../helpers/networkTools';

axiosRetry(filesApi, {
	retries: 3,
	retryDelay: retryCount => {
		return retryCount * 1000;
	},
	shouldResetTimeout: true,
	retryCondition: shouldRetry,
});

export const getFileMetadata = (fileId: FileMetadata['id']) =>
	filesApi
		.get(`/file`, {
			params: {
				file_id: fileId,
			},
		})
		.then(response => translateApiFileToLocal(response.data.file));

export const uploadFileMetadata = ({
	recordingId,
	albumId,
	path,
	label,
	fileSize,
	duration,
	filename,
	sourceCreatedAt,
	sourceUpdatedAt,
}: CreateFileMetadataRequest) =>
	filesApi
		.post('/file', {
			recording_id: recordingId,
			album_id: recordingId ? null : albumId, // if the file is being uploaded to a recording within an album, the albumId should be null
			path: path,
			label: label,
			file_size: fileSize,
			duration,
			filename,
			source_created_at: sourceCreatedAt,
			source_updated_at: sourceUpdatedAt,
		})
		.then(response => translateApiFileToLocal(response.data.file));

export const updateFileMetadata = ({
	fileId,
	label,
}: UpdateFileMetadataRequest) =>
	filesApi
		.put(`/file`, {
			file_id: fileId,
			label: label,
		})
		.then(response => translateApiFileToLocal(response.data.file));

export const deleteFile = (fileId: FileMetadata['id']) =>
	filesApi.delete(`/file`, {
		params: {
			file_id: fileId,
		},
	});

export const getProjectFilesMetadata = ({
	albumId,
	recordingId,
	fetchAlbumRecordings = false,
}: GetProjectFilesMetadataRequest) => {
	return filesApi.get(`/file/project`, {
		params: {
			album_id: albumId,
			recording_id: recordingId,
			fetch_album_recordings: fetchAlbumRecordings,
		},
	});
};

// TODO: Change to camelCase
export const getFileDownloadLink = ({
	fileId,
	versionId,
	displayId,
}: {
	fileId: FileMetadata['id'];
	versionId: FileVersion['id'] | null;
	displayId?: FileMetadata['id'] | null;
}) => {
	return filesApi.get<{
		asset_link: string;
	}>(`/file/download`, {
		params: {
			file_id: fileId,
			version_id: versionId,
			display_id: displayId,
		},
	});
};

export const getFileLabelDescriptions = () => {
	return filesApi.get(`/file/describe-labels`);
};

/**
 * @deprecated
 */
export const updateFilesFolderStructure = (files: any) => {
	return filesApi.put(`/file/update-folder-structure`, {
		files,
	});
};

export const shareFilesTemp = ({
	fileIds,
	recipientEmail,
	recordingId = null,
	albumId = null,
}: {
	fileIds: number[];
	recipientEmail: string;
	recordingId?: number | null;
	albumId?: number | null;
}) => {
	return filesApi.post(`/file/temp-share`, {
		files: fileIds,
		recipient_email: recipientEmail,
		main_recording_id: recordingId,
		main_album_id: albumId,
	});
};

export const getSignedUrlForUpload = (path: string) => {
	return filesApi.get(`/user/url`, {
		params: {
			key: path,
		},
	});
};

export const getAwsCredentialsForUpload = (): Promise<S3Credentials> =>
	filesApi.get(`/user/session`).then(res => ({
		accessKeyId: res.data.access_key,
		secretAccessKey: res.data.secret_key,
		sessionToken: res.data.session_token,
		expiration: new Date(res.data.expiration_timestamp), // The AWS SDK expects this to be a Date object
	}));

export const getStorageUsage = () => filesApi.get('/user/file-usage');

export const getFileLabelDetails = () =>
	// create object from list, using id as key
	filesApi
		.get<{
			labels: {
				id: number;
				label: string;
			}[];
		}>('/meta/detail-labels')
		.then(res =>
			res.data?.labels.reduce((acc, label) => {
				acc[label.id] = label.label;
				return acc;
			}, {} as FileLabelDetailsType)
		);

export const getUserPlaylists = () =>
	filesApi.get('/share').then(res =>
		playlistArrayToObject(
			res.data?.share_links.map(
				(playlist: any) =>
					({
						...camelize(playlist),
						playlist: null,
					} as Playlist)
			)
		)
	);

export const createPlaylist = ({
	name,
	allowDownloads,
	isPublished,
	isPasswordProtected,
	password,
	playLimit = null,
	headerImagePath = null,
	thumbnailImagePath = null,
	files,
	recipientEmail = null,
}: CreatePlaylistForm) => {
	return filesApi
		.post('/share', {
			name,
			header_image_path: headerImagePath,
			thumbnail_image_path: thumbnailImagePath,
			allow_downloads: allowDownloads,
			is_published: isPublished,
			is_password_protected: isPasswordProtected,
			password: password,
			play_limit: playLimit,
			files: files?.map(snakeize),
			recipient_email: recipientEmail,
		})
		.then(res => translateApiPlaylistToLocal(res?.data?.share_link));
};

export const updatePlaylist = ({
	id,
	name,
	headerImagePath,
	thumbnailImagePath,
	allowDownloads,
	isPublished,
	isPasswordProtected,
	password,
	playLimit,
	files,
	expandVersions,
	hideTrackchatLinks,
	artist,
	description,
	enableCreditRequests,
}: UpdatePlaylistForm) => {
	return filesApi
		.put(`/share`, {
			share_link_id: id,
			name,
			header_image_path: headerImagePath,
			thumbnail_image_path: thumbnailImagePath,
			allow_downloads: allowDownloads,
			is_published: isPublished,
			is_password_protected: isPasswordProtected,
			password: password,
			play_limit: playLimit,
			files: files?.map(translateToPlaylistRequestFile).map(snakeize),
			expand_versions: expandVersions,
			hide_trackchat_links: hideTrackchatLinks,
			artist,
			description,
			enable_credit_requests: enableCreditRequests,
		})
		.then(res => translateApiPlaylistToLocal(res?.data?.share_link));
};

export const deletePlaylist = (id: number) => {
	return filesApi.delete(`/share`, {
		params: {
			share_link_id: id,
		},
	});
};

export const getPlaylist = (id: number) => {
	return filesApi
		.get(`/share/link`, {
			params: {
				share_link_id: id,
			},
		})
		.then(res => translateApiPlaylistToLocal(res?.data?.share_link));
};

export const changePlaylistFilenames = <
	T extends {
		id: FileMetadata['id'];
		displayName: string | null;
	}
>({
	id,
	files,
}: {
	id: Playlist['id'];
	files: T[];
}) => {
	return filesApi
		.put(`/share/change-file-names`, {
			share_link_id: id,
			file_names: files?.reduce((acc, file) => {
				// compute object with file id as key and file name as value
				acc[file.id] = file.displayName;

				return acc;
			}, {} as Record<FileMetadata['id'], string | null>),
		})
		.then(res => translateApiPlaylistToLocal(res?.data?.share_link));
};

export const deletePlaylistFile = (
	playlistId: Playlist['id'],
	fileId: FileMetadata['id']
) => {
	return filesApi
		.delete(`/share/file`, {
			params: {
				share_link_id: playlistId,
				file_id: fileId,
			},
		})
		.then(res => translateApiPlaylistToLocal(res?.data?.share_link));
};

export const reorderPlaylistFiles = ({
	playlistId,
	files,
}: {
	playlistId: Playlist['id'];
	files: FileMetadata[];
}) =>
	filesApi
		.put(`/share/reorder-files`, {
			share_link_id: playlistId,
			files: files.reduce((acc, file, index) => {
				// compute object with file id as key and file position as value
				acc[file.id] = index;
				return acc;
			}, {} as Record<number, number>),
		})
		.then(res => translateApiPlaylistToLocal(res?.data?.share_link));

export const addFileCoverImage = ({
	fileId,
	coverImagePath,
}: {
	fileId: FileMetadata['id'];
	coverImagePath: string | null;
}) => {
	return filesApi.put(`/file/cover`, {
		file_id: fileId,
		path: coverImagePath,
	});
};

export const addPlaylistCoverImage = ({
	playlistId,
	coverImagePath,
}: {
	playlistId: Playlist['id'];
	coverImagePath: string | null;
}) =>
	filesApi
		.put(`/share/add-cover-image`, {
			share_link_id: playlistId,
			path: coverImagePath,
		})
		.then(res => translateApiPlaylistToLocal(res?.data?.share_link));

export const addPlaylistHeaderImage = ({
	playlistId,
	headerImagePath,
}: {
	playlistId: Playlist['id'];
	headerImagePath: string | null;
}) =>
	filesApi
		.put(`/share/add-header-image`, {
			share_link_id: playlistId,
			path: headerImagePath,
		})
		.then(res => translateApiPlaylistToLocal(res?.data?.share_link));

export type InitiatePlaylistTransferResponse = {
	userExists: boolean;
	canAssignCoadmin?: boolean;
	canAssignOwner?: boolean;
	includedProjects?: {
		recordings: Record<string, string>;
		albums: Record<string, string>;
	};
};

export const initiatePlaylistTransfer = ({
	playlistId,
	email,
}: {
	playlistId: Playlist['id'];
	email: string;
}) =>
	filesApi
		.post<{
			user_exists: boolean;
			can_assign_coadmin?: boolean;
			can_assign_owner?: boolean;
			included_projects?: {
				recordings: Record<string, string>;
				albums: Record<string, string>;
			};
		}>('/transfer/initiate', {
			share_link_id: playlistId,
			recipient_email: email,
			recipient_phone: null,
		})
		.then(res => camelize(res?.data) as InitiatePlaylistTransferResponse);

export const completePlaylistTransfer = ({
	playlistId,
	email,
	editorRole,
}: {
	playlistId: Playlist['id'];
	email: string;
	editorRole: string; // TODO: Change to enum
}) =>
	filesApi
		.post('/transfer/complete', {
			share_link_id: playlistId,
			recipient_email: email,
			recipient_phone: null,
			is_read_only: editorRole === ProjectEditorRoles.READ_ONLY,
			transfer_ownership: editorRole === ProjectEditorRoles.OWNER,
			invite_user: !!editorRole,
		})
		.then(res => camelize(res?.data));

export const acceptPlaylistTransfer = ({
	shareLinkSlug,
	isAccepting,
}: {
	shareLinkSlug: string;
	isAccepting: boolean;
}) =>
	filesApi
		.post('/transfer/accept', {
			share_link_slug: shareLinkSlug,
			is_accepting: isAccepting,
		})
		.then(res => camelize(res?.data));

export const publishDrip = ({
	playlistId,
	ticketCount,
}: {
	playlistId: Playlist['id'];
	ticketCount: number;
}) =>
	filesApi
		.put(`/share/publish-drip`, {
			share_link_id: playlistId,
			ticket_count: ticketCount,
		})
		.then(res => translateApiPlaylistToLocal(res?.data?.share_link));

export const unpublishDrip = ({ playlistId }: { playlistId: Playlist['id'] }) =>
	filesApi
		.put(`/share/unpublish-drip`, {
			share_link_id: playlistId,
		})
		.then(res => translateApiPlaylistToLocal(res?.data?.share_link));

export const uploadFileVersionMetadata = ({
	fileId,
	path,
	setActive = true,
	comment,
	fileSize,
	duration,
	filename,
	sourceCreatedAt,
	sourceUpdatedAt,
}: {
	fileId: FileMetadata['id'];
	path: string;
	setActive?: boolean;
	comment?: string;
	fileSize: number;
	duration: number | null;
	filename: string;
	sourceCreatedAt?: string | null;
	sourceUpdatedAt?: string | null;
}) =>
	filesApi
		.post('/file/version', {
			file_id: fileId,
			path,
			set_active: setActive,
			comment,
			file_size: fileSize,
			duration,
			filename,
			source_created_at: sourceCreatedAt,
			source_updated_at: sourceUpdatedAt,
		})
		.then(res => translateApiFileToLocal(res?.data?.file));

export const deleteFileVersion = ({
	fileId,
	versionId,
}: {
	fileId: FileMetadata['id'];
	versionId: FileVersion['id'];
}) =>
	filesApi
		.delete('/file/version', {
			params: {
				file_id: fileId,
				version_id: versionId,
			},
		})
		.then(res => translateApiFileToLocal(res?.data?.file));

export const updateFileVersionMetadata = ({
	fileId,
	versionId,
	comment,
	makeActive,
	filename,
}: {
	fileId: FileMetadata['id'];
	versionId: FileVersion['id'];
	comment?: string;
	makeActive?: boolean;
	filename?: string;
}) =>
	filesApi
		.put('/file/version', {
			file_id: fileId,
			version_id: versionId,
			comment,
			make_active: makeActive,
			filename,
		})
		.then(res => translateApiFileToLocal(res?.data?.file));

export const duplicatePlaylist = ({
	playlistId,
	name,
}: {
	playlistId: Playlist['id'];
	name: string;
}) =>
	filesApi
		.post('/share/duplicate', {
			share_link_id: playlistId,
			name,
		})
		.then(res => translateApiPlaylistToLocal(res?.data?.share_link));

export const saveDripAIAnalysis = ({
	playlistId,
	analysis,
}: {
	playlistId: Playlist['id'];
	analysis: any;
}) =>
	filesApi
		.put(`/share/save-ai-analysis`, {
			share_link_id: playlistId,
			analysis,
		})
		.then(res => translateApiPlaylistToLocal(res?.data?.share_link));

export const pollPlaylistUpdates = ({
	playlistSlug,
}: PollPlaylistUpdatesRequest) =>
	filesApi
		.get(`/share/editor/updates/${playlistSlug}/poll`)
		.then(res => translateApiObjectToLocal(res?.data)?.updatedAt);

export const getPlaylistEditors = ({ playlistId }: GetPlaylistEditorsRequest) =>
	filesApi
		.get('/share/editor', {
			params: {
				share_link_id: playlistId,
			},
		})
		.then(res => translateApiPlaylistEditorsToLocal(res?.data?.editors));

export const addNewPlaylistEditor = ({
	playlistId,
	email,
	message,
}: AddPlaylistEditorRequest) =>
	filesApi
		.post<AddPlaylistEditorResponse>('/share/editor', {
			share_link_id: playlistId,
			editor_email: email,
			message,
		})
		.then(res => translateApiPlaylistEditorsToLocal(res?.data?.editors));

export const removePlaylistEditor = ({
	playlistId,
	editorId,
	editorEmail,
}: RemovePlaylistEditorRequest) =>
	filesApi
		.delete('/share/editor', {
			params: {
				share_link_id: playlistId,
				editor_id: editorId,
				editor_email: editorId ? null : editorEmail,
			},
		})
		.then(res => translateApiPlaylistEditorsToLocal(res?.data?.editors));

export const acceptPlaylistEditorInvite = ({
	playlistId,
}: AcceptPlaylistEditorInviteRequest) =>
	filesApi.put(`/share/editor/accept-invite`, { share_link_id: playlistId });

export const declinePlaylistEditorInvite = ({
	playlistId,
}: DeclinePlaylistEditorInviteRequest) =>
	filesApi.put(`/share/editor/decline-invite`, { share_link_id: playlistId });

export const getDripEmails = ({ playlistId }: { playlistId: Playlist['id'] }) =>
	filesApi
		.get('/share/emails', {
			params: {
				share_link_id: playlistId,
			},
		})
		.then(res => translateApiObjectToLocal(res?.data));

type ApiCreditRequest = {
	id: number;
	created_at: string;
	updated_at: string;
	requester_email: string;
	credited_name: string;
	legal_name: string;
	roles: {
		category: string;
		detail: string;
		studio: string;
	}[];
	publishers: {
		name: string;
		ipi: string;
		email: string;
	}[];
	pro: string;
	ipi: string;
	share_link_id: Playlist['id'];
	asset_file_id: FileMetadata['id'];
};

export const getPlaylistCreditRequests = ({
	playlistId,
}: {
	playlistId: Playlist['id'];
}) =>
	filesApi
		.get<{
			credit_requests: ApiCreditRequest[];
		}>('/credit-request/share-link', {
			params: {
				share_link_id: playlistId,
			},
		})
		.then(
			res =>
				translateApiObjectToLocal(res?.data.credit_requests) as CreditRequest[]
		);

export const approveCreditRequest = ({
	creditRequestId,
	playlistId,
}: {
	creditRequestId: CreditRequest['id'];
	playlistId: Playlist['id'];
}) =>
	filesApi
		.put<{
			credit_requests: ApiCreditRequest[];
		}>('/credit-request/accept', {
			credit_request_id: creditRequestId,
			share_link_id: playlistId,
		})
		.then(
			res =>
				translateApiObjectToLocal(res?.data.credit_requests) as CreditRequest[]
		);

export const denyCreditRequest = ({
	creditRequestId,
	playlistId,
}: {
	creditRequestId: CreditRequest['id'];
	playlistId: Playlist['id'];
}) =>
	filesApi
		.delete<{
			credit_requests: ApiCreditRequest[];
		}>('/credit-request', {
			params: {
				credit_request_id: creditRequestId,
				share_link_id: playlistId,
			},
		})
		.then(
			res =>
				translateApiObjectToLocal(res?.data.credit_requests) as CreditRequest[]
		);

export const sendPlaylistEmail = ({
	playlistId,
	recipientEmail,
}: {
	playlistId: Playlist['id'];
	recipientEmail: string;
}) =>
	filesApi.post('/share/email', {
		share_link_id: playlistId,
		recipient_email: recipientEmail,
	});

export const createPlaylistFileGroup = ({
	playlistId,
	fileIds,
	folderIds,
	name,
	parentGroupId = null,
}: {
	playlistId: Playlist['id'];
	fileIds: FileMetadata['id'][];
	folderIds: PlaylistFolder['id'][];
	name: string;
	parentGroupId?: PlaylistFolder['id'] | null;
}) =>
	filesApi
		.post('/share/group', {
			share_link_id: playlistId,
			file_ids: fileIds,
			folder_ids: folderIds,
			name,
			parent_group_id: parentGroupId,
		})
		.then(res => translateApiPlaylistToLocal(res?.data?.share_link));

export const updatePlaylistFileGroup = ({
	playlistId,
	groupId,
	name,
}: {
	playlistId: Playlist['id'];
	groupId: PlaylistFolder['id'];
	name: string;
}) =>
	filesApi
		.put('/share/group', {
			share_link_id: playlistId,
			group_id: groupId,
			name,
		})
		.then(res => translateApiPlaylistToLocal(res?.data?.share_link));

export const deletePlaylistFileGroup = ({
	playlistId,
	groupId,
}: {
	playlistId: Playlist['id'];
	groupId: PlaylistFolder['id'];
}) =>
	filesApi
		.delete('/share/group', {
			params: {
				share_link_id: playlistId,
				group_id: groupId,
			},
		})
		.then(res => translateApiPlaylistToLocal(res?.data?.share_link));

export const updatePlaylistFileGroupFiles = ({
	playlistId,
	groupId,
	fileIds,
}: {
	playlistId: Playlist['id'];
	groupId: PlaylistFolder['id'];
	fileIds: FileMetadata['id'][];
}) =>
	filesApi
		.put('/share/group/files', {
			share_link_id: playlistId,
			group_id: groupId,
			file_ids: fileIds,
		})
		.then(res => translateApiPlaylistToLocal(res?.data?.share_link));

export const reorderPlaylistFileGroups = ({
	playlistId,
	groupIds,
	parentGroupId = null,
}: {
	playlistId: Playlist['id'];
	groupIds: PlaylistFolder['id'][];
	parentGroupId?: number | null;
}) =>
	filesApi
		.put('/share/group/order', {
			share_link_id: playlistId,
			group_ids: groupIds,
			parent_group_id: parentGroupId,
		})
		.then(res => translateApiPlaylistToLocal(res?.data?.share_link));

export const updatePlaylistSubGroups = ({
	playlistId,
	groupId,
	subGroupIds,
}: {
	playlistId: Playlist['id'];
	groupId: PlaylistFolder['id'];
	subGroupIds: PlaylistFolder['id'][];
}) =>
	filesApi
		.put('/share/group/groups', {
			share_link_id: playlistId,
			group_id: groupId,
			subgroup_ids: subGroupIds,
		})
		.then(res => translateApiPlaylistToLocal(res?.data?.share_link));
