import { useFormikContext } from 'formik';
import './RecordingDetails.scss';
import Button from '../../layout/Button';
import { useMemo, useState } from 'react';
import { Card, Col, Collapse, Container, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useEffect } from 'react';
import TextField from '../../form/TextField';
import CreatableSelect from '../../form/CreatableSelect';
import Select from '../../form/Select';
import DurationPicker from '../../form/DurationPicker';
import DatePicker from '../../form/DatePicker';
import { showModalAction } from '../../../store/modal/actions';
import { GENERATE_CODE_MODAL } from '../../../constants/modalTypes';
import genreOptions from '../../../constants/genreOptions.json';
import ToggleInput from '../../layout/ToggleInput';

import countries from '../../../constants/countries.json';
import { matchSorter } from 'match-sorter';
import { saveSelectedCountry } from '../../../store/session/actions';
import React from 'react';
import ROUTES from '../../../router/routes';
import { Helmet } from 'react-helmet';
import { useAppSelector } from '../../../store/hooks';
import { getCurrentRecording } from '../../../store/projects/selectors';

const countryOptions = countries.map(country => ({
	label: country.name,
	name: country.name,
}));

function RecordingDetails({ onSave }) {
	const currentRecording = useAppSelector(getCurrentRecording);
	const [advancedCollapsed, setAdvancedCollapsed] = useState(true);
	// const [releaseCollapsed, setReleaseCollapsed] = useState(true);
	// state => state.projects.recordingsById[state.projects.currentRecordingId]
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const formik = useFormikContext();
	const [genres, setGenres] = useState([
		...genreOptions.options,
		{
			label: formik.values.genre,
			value: formik.values.genre,
		},
	]);
	const { myEditorProfile } = useSelector(state => state.projects);
	// const [isLoading, setIsLoading] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const { userPermissions } = useSelector(state => state.auth);

	const { savedCountry } = useSelector(state => state.session);

	const initialFilteredOptions = countryOptions.filter(
		c => c.name !== savedCountry
	);
	const initialCountryOptions = [
		{ name: savedCountry, label: savedCountry },
		...initialFilteredOptions,
	];

	const [filteredCountryOptions, setFilteredCountryOptions] = useState(
		initialCountryOptions
	);

	const isReadOnly = useMemo(
		() => myEditorProfile && myEditorProfile.is_read_only,
		[myEditorProfile]
	);

	const showGenerateCodeModal = () =>
		dispatch(showModalAction(GENERATE_CODE_MODAL));

	useEffect(() => {
		let excludeSelection = countryOptions.filter(c => c.name !== savedCountry);
		setFilteredCountryOptions(excludeSelection);
	}, [savedCountry]);

	// run update on unmount
	useEffect(() => {
		return () => {
			onSave(formik.values, formik.dirty, formik.validateForm);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleProceed = () => {
		if (!Object.values(formik.errors).length) {
			setIsSaving(true);
			setTimeout(() => {
				formik.submitForm();
				navigate(`../${ROUTES.EditReleaseDetails.relativePath}`);
			}, 1000);
		} else {
			console.log(formik.errors, Object.values(formik.errors));
		}
	};

	const renderRecordingDetails = () => {
		return (
			<Card>
				<Card.Body>
					<h2>SONG INFO</h2>
					<Row>
						<Col xs={6}>
							<TextField
								label='Main Artist Name *'
								type='text'
								name='mainArtist'
								value={formik.values.mainArtist}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								isValid={formik.touched.mainArtist && !formik.errors.mainArtist}
								isInvalid={
									formik.touched.mainArtist && !!formik.errors.mainArtist
								}
								errorMessage={formik.errors.mainArtist}
								readOnly={isReadOnly}
							/>
						</Col>

						<Col xs={6}>
							<TextField
								type='text'
								name='title'
								value={formik.values.title}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								isValid={formik.touched.title && !formik.errors.title}
								isInvalid={formik.touched.title && !!formik.errors.title}
								label='Song Name *'
								errorMessage={formik.errors.title}
								readOnly={isReadOnly}
							/>
						</Col>

						<Col xs={6}>
							<CreatableSelect
								label='Song Genre'
								name='genre'
								id='genre'
								options={genres}
								placeholder='Search...'
								value={genres.find(obj => formik.values.genre === obj.value)}
								onCreateOption={value => {
									let newOption = { value: value, label: value };
									formik.setFieldValue('genre', value);
									setGenres([...genreOptions.options, newOption]);
								}}
								onInputChange={val => {
									if (!val) return;
									setGenres(
										matchSorter(genreOptions.options, val, {
											keys: ['value', 'label'],
										})
									);
								}}
								onChange={option => formik.setFieldValue('genre', option.value)}
								isDisabled={isReadOnly}
							/>
						</Col>

						<Col xs={6}>
							<Select
								label='Country of Recording'
								name='country'
								id='country'
								options={[
									{ name: savedCountry, label: savedCountry },
									...filteredCountryOptions,
								]}
								placeholder='Search...'
								errorFieldName='country'
								value={
									formik.values.country &&
									countryOptions.find(c => c.name === formik.values.country)
								}
								getOptionValue={option => option.name}
								getOptionLabel={option => option.label}
								onChange={option => {
									setFilteredCountryOptions(
										countryOptions.filter(c => c.name !== option.name)
									);
									dispatch(saveSelectedCountry(option.name));
									formik.setFieldValue('country', option.name);
								}}
								isDisabled={isReadOnly}
								onInputChange={val => {
									setFilteredCountryOptions(
										matchSorter(countryOptions, val, {
											keys: ['name', 'label'],
										})
									);
								}}
							/>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		);
	};

	const renderAdvancedInformationContent = () => {
		return (
			<>
				<Col xs={6}>
					<TextField
						label='Recording Version'
						type='text'
						name='version'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						placeholder='e.g. Live, Studio Version, etc.'
						value={formik.values.version}
						readOnly={isReadOnly}
					/>
				</Col>

				<Col xs={6}>
					<TextField
						label='Key Signature'
						type='text'
						id='keySignature'
						name='keySignature'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.keySignature}
						readOnly={isReadOnly}
					/>
				</Col>

				<Col xs={6}>
					<TextField
						label='Time Signature'
						type='text'
						id='timeSignature'
						name='timeSignature'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.timeSignature}
						readOnly={isReadOnly}
					/>
				</Col>

				<Col xs={6}>
					<TextField
						label='Tempo'
						type='text'
						id='tempo'
						name='tempo'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.tempo}
						readOnly={isReadOnly}
					/>
				</Col>

				<Col xs={6}>
					<CreatableSelect
						label='Moods'
						isMulti
						name='moods'
						id='moods'
						options={moodOptions}
						placeholder='Search...'
						value={moodOptions.filter(
							obj =>
								formik.values.moods && formik.values.moods.includes(obj.value)
						)}
						onCreateOption={value => {
							let newOption = { value: value, label: value };
							moodOptions = moodOptions.concat(newOption);
							formik.setFieldValue('moods', formik.values.moods.concat(value));
						}}
						onChange={option =>
							formik.setFieldValue(
								'moods',
								option.map(element => element.value)
							)
						}
						isDisabled={isReadOnly}
					/>
				</Col>

				<Col xs={6}>
					<TextField
						label='Original Artist (for cover songs)'
						type='text'
						id='originalArtist'
						name='originalArtist'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.originalArtist}
						readOnly={isReadOnly}
					/>
				</Col>

				<Col xs={6}>
					<DurationPicker
						label='Duration'
						value={formik.values.duration}
						onChange={value => formik.setFieldValue('duration', value)}
						readOnly={isReadOnly}
					/>
				</Col>

				<Col xs={6}>
					<DatePicker
						label='Date Mastered'
						id='dateMastered'
						name='dateMastered'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.dateMastered}
						isDisabled={isReadOnly}
					/>
				</Col>

				<Col xs={6}>
					<DatePicker
						label='Date of Recording'
						id='recordingDate'
						name='recordingDate'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.recordingDate}
						isDisabled={isReadOnly}
					/>
				</Col>

				<Col xs={6}>
					<TextField
						label='Legal Lines / ℗ ©'
						type='text'
						name='legalLines'
						id='legalLines'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						placeholder='(Symbols in label above can be copy/pasted and used here)'
						value={formik.values.legalLines}
						readOnly={isReadOnly}
						selectableLabel
					/>
				</Col>

				<Col xs={6}>
					<Select
						label='Special Purpose (optional)'
						id='specialPurpose'
						name='specialPurpose'
						options={specialPurposeOptions}
						onChange={option =>
							formik.setFieldValue('specialPurpose', option.value)
						}
						value={specialPurposeOptions.find(
							option => option.value === formik.values.specialPurpose
						)}
						isDisabled={isReadOnly}
					/>
				</Col>
			</>
		);
	};

	const renderDescriptionAndNotes = () => {
		return (
			<>
				<Col xs={12}>
					<hr />
					<div className='my-3' />
					<h2>DESCRIPTION & NOTES</h2>

					<TextField
						label='Acknowledgements'
						name='acknowledgements'
						id='acknowledgements'
						rows={5}
						as='textarea'
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.acknowledgements}
						readOnly={isReadOnly}
					/>

					<TextField
						label='Liner Notes for Sound Credit Indie Database'
						as='textarea'
						name='notes'
						id='notes'
						rows={5}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.notes}
						placeholder='Optional song description for website'
						readOnly={isReadOnly}
					/>
				</Col>
			</>
		);
	};

	const renderCodesAndFlags = () => {
		return (
			<>
				<Col xs={12}>
					<hr />
					<div className='my-3' />
					<h2>CODES & FLAGS</h2>
				</Col>
				<Container fluid>
					<Row>
						<Col>
							<Row>
								<Col>
									<TextField
										label='ISWC'
										type='text'
										id='iswc'
										name='iswc'
										onChange={formik.handleChange}
										onBlur={e => {
											const str = `${e.target.value}`;

											if (str.length === 11) {
												e.target.value = `${str.slice(0, 1)}-${str.slice(
													1,
													4
												)}.${str.slice(4, 7)}.${str.slice(7, 10)}-${str.slice(
													10,
													11
												)}`.toUpperCase();
											}
											formik.handleChange(e);
											formik.handleBlur(e);
										}}
										value={formik.values.iswc}
										placeholder='e.g. T-034.524.680-C'
										readOnly={isReadOnly}
										errorMessage={formik.errors.iswc}
										isInvalid={formik.errors.iswc}
									/>
								</Col>
								<Col>
									<TextField
										label='ISRC'
										type='text'
										id='isrc'
										name='isrc'
										onChange={formik.handleChange}
										onBlur={e => {
											const str = `${e.target.value}`;

											if (str.length === 12) {
												e.target.value = `${str.slice(0, 2)}-${str.slice(
													2,
													5
												)}-${str.slice(5, 7)}-${str.slice(
													7,
													12
												)}`.toUpperCase();
											}
											formik.handleChange(e);
											formik.handleBlur(e);
										}}
										value={formik.values.isrc}
										placeholder='e.g. US-SKG-19-12345'
										readOnly={isReadOnly}
										errorMessage={formik.errors.isrc}
										isInvalid={formik.errors.isrc}
										buttonLabel='Generate'
										onButtonClick={showGenerateCodeModal}
										isButtonLocked={!userPermissions?.canGenerateCodes}
									/>
								</Col>
							</Row>
							<Row>
								<Col xs={7}>
									<ToggleInput
										label='Contains Samples'
										name='containsSamples'
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										checked={formik.values.containsSamples}
										value={formik.values.containsSamples}
										isDisabled={isReadOnly}
										style={{ marginBottom: '5px' }}
									/>

									<ToggleInput
										label='Explicit Content'
										name='explicit'
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										checked={formik.values.explicit}
										value={formik.values.explicit}
										isDisabled={isReadOnly}
										style={{ marginBottom: '5px' }}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</>
		);
	};

	const renderAdvancedInformation = () => {
		return (
			<Card>
				<Card.Body>
					<Row>
						<Col xs={11}>
							<h2>RECORDING INFO</h2>
						</Col>
						<Col xs={1}>
							<button
								type='button'
								className='btn'
								aria-controls='collapseInformation'
								aria-expanded={advancedCollapsed}
								onClick={() =>
									setAdvancedCollapsed(prevCollapsed => !prevCollapsed)
								}
								style={{ fontSize: '1.5em', paddingTop: '0px' }}
							>
								<i
									className={`fa ${
										advancedCollapsed ? 'fa-chevron-down' : 'fa-chevron-up'
									} text-purple`}
								></i>
							</button>
						</Col>
					</Row>
					<Collapse in={!advancedCollapsed}>
						<Row id='collapseInformation'>
							{renderAdvancedInformationContent()}

							{renderCodesAndFlags()}

							{renderDescriptionAndNotes()}
						</Row>
					</Collapse>
				</Card.Body>
			</Card>
		);
	};

	return (
		<>
			<Helmet>
				<title>
					{currentRecording?.title ?? ''} - Song Info{' '}
					{process.env.REACT_APP_TAB_TITLE}
				</title>
			</Helmet>
			<div className='pt-3' />

			<Row>
				<Col xs={12} className='padding-7by3'>
					<Form onSubmit={formik.handleSubmit}>
						{renderRecordingDetails()}
						{/* <div className='my-3' />
						 {renderReleaseDetails()} */}
						<div className='my-3' />
						{renderAdvancedInformation()}
						<div className='my-3' />
						<Col>
							<Col
								xs={{ span: 4, offset: 8 }}
								className='d-flex justify-content-end'
							>
								{/* <Button
									label='Save & Proceed'
									onClick={handleProceed}
									theme='dark'
									isDisabled={isReadOnly}
								/> */}

								<Button
									// label={Object.values(formik.errors).length ? 'Whoops!' : saved ? 'Saved!' : 'Save & Proceed'}
									// theme={Object.values(formik.errors).length ? 'danger-filled' : saved ? 'success' : 'dark'}
									label={'Save & Proceed'}
									theme={'dark'}
									onClick={handleProceed}
									isDisabled={isReadOnly}
									isLoading={isSaving}
								/>
							</Col>
						</Col>
						<div className='my-3' />
						{/* <Card>
							<Card.Body>
								<pre>{JSON.stringify(formik.values, null, 2)}</pre>
							</Card.Body>
						</Card> */}
					</Form>
				</Col>
			</Row>
		</>
	);
}

export default RecordingDetails;

const specialPurposeOptions = [
	{ value: '', label: 'None' },
	{ value: 'Film', label: 'Film' },
	{ value: 'Television', label: 'Television' },
	{ value: 'Library', label: 'Library' },
	{ value: 'Theatre', label: 'Theatre' },
];

let moodOptions = [
	{ value: 'happy', label: 'Happy' },
	{ value: 'energetic', label: 'Energetic' },
	{ value: 'somber', label: 'Somber' },
];
