import React, { useMemo } from 'react';
import './FileTagChip.scss';
import { showModalAction } from '../../../../store/modal/actions';
import { EDIT_FILE_TAG_MODAL } from '../../../../constants/modalTypes';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

export type FileTagChipProps = {
	tag: number | null;
	onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
	isReadOnly?: boolean;
	style?: React.CSSProperties;
	fileId?: number | null;
	playlistId?: number | null;
};

const FileTagChip = ({
	tag,
	onClick,
	isReadOnly = false,
	style = {},
	fileId = null,
	playlistId = null,
}: FileTagChipProps) => {
	const dispatch = useAppDispatch();
	const { fileLabelDetails } = useAppSelector(state => state.files);
	const tagDetail = useMemo(
		() =>
			!tag ? (isReadOnly ? 'No Tag' : 'Add Tag') : fileLabelDetails?.[tag],
		[fileLabelDetails, tag, isReadOnly]
	);

	const handleTagClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation();

		dispatch(
			showModalAction(EDIT_FILE_TAG_MODAL, {
				fileId,
				size: 'md',
				playlistId,
			})
		);
	};

	const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		e.stopPropagation();

		if (isReadOnly) return;

		if (onClick) {
			onClick(e);
			return;
		}

		if (fileId) handleTagClick(e);
	};

	return (
		<div
			onClick={handleClick}
			className={`file-tag-chip-container ${isReadOnly ? 'read-only' : ''}`}
			style={{
				cursor: isReadOnly ? 'default' : 'pointer',
				...style,
			}}
		>
			<i className='fas fa-tag mr-2' />
			<span>{tagDetail}</span>
		</div>
	);
};

export default FileTagChip;
