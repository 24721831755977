const isniStrings = {
	BasicInfo: {
		stepperLabel: {
			credit: 'Basic Info',
			user: 'Basic Info',
		},
		title: {
			credit: 'Get an ISNI for your credits',
			user: 'Get your ISNI',
		},
		description: {
			credit:
				'An International Standard Name Identifier (ISNI) helps creators to be accurately identified, credited, and paid.',
			user: 'An International Standard Name Identifier (ISNI) helps creators to be accurately identified, credited, and paid.',
		},
		form: {
			title: {
				label: {
					credit: 'Title',
					user: 'Title',
				},
				info: {
					credit:
						'E.G. "Sir", "Dr.". Only include this if it\'s necessary to identify the person. Do not include job titles.',
					user: 'E.G. "Sir", "Dr.". Only include this if it\'s necessary to identify you. Do not include job titles.',
				},
			},
			firstName: {
				label: {
					credit: 'First Name',
					user: 'First Name',
				},
				info: {
					credit: '',
					user: '',
				},
			},
			middleName: {
				label: {
					credit: 'Middle Name',
					user: 'Middle Name',
				},
				info: {
					credit: '',
					user: '',
				},
			},
			lastName: {
				label: {
					credit: 'Last Name',
					user: 'Last Name',
				},
				info: {
					credit: '',
					user: '',
				},
			},
			suffix: {
				label: {
					credit: 'Suffix',
					user: 'Suffix',
				},
				info: {
					credit: 'E.G. "jr.", "III"',
					user: 'E.G. "jr.", "III"',
				},
			},
		},
	},
	Search: {
		stepperLabel: {
			credit: 'Search',
			user: 'Search',
		},
		foundTitle: {
			credit: "We've found some ISNIs matching the creator's name",
			user: "We've found some ISNIs matching your name",
		},
		foundDescription: {
			user: "It's possible that you may have already been assigned an ISNI, so if an ISNI is found for your name you'll need to confirm if it's you. Only claim an ISNI if you are absolutely certain you're the person in the result.",
			credit:
				"It's possible that the creator may have already been assigned an ISNI, so if an ISNI is found for their name you'll need to confirm if it's them. Only claim an ISNI if you are absolutely certain they're the person in the result.",
		},
		notFoundTitle: {
			user: 'No ISNIs were found for your name',
			credit: "No ISNIs were found for the creator's name",
		},
		notFoundDescription: {
			user: 'Continue to get a new ISNI assigned in just a few short steps.',
			credit: 'Continue to get a new ISNI assigned in just a few short steps.',
		},
		claimButton: {
			credit: 'This is the creator',
			user: 'This is me',
		},
		ifNoMatch: {
			user: 'If none of the results look like you, click the button below to get a new, unique ISNI assigned in just a few short steps.',
			credit:
				'If none of the results look like the creator, click the button below to get a new, unique ISNI assigned in just a few short steps.',
		},
		noMatchButton: {
			user: 'None of these are me',
			credit: 'None of these are the creator',
		},
	},
	PriorWork: {
		stepperLabel: {
			credit: 'Prior Work',
			user: 'Prior Work',
		},
		title: {
			user: 'Enter your most noted prior work',
			credit: "Enter the creator's most noted prior work",
		},
		description: {
			user: 'Provide the title and artist of a musical project you have worked on in the past and your role in that work.',
			credit:
				'Provide the title and artist of a musical project the creator has worked on in the past and their role in that work.',
		},
		form: {
			title: {
				label: {
					credit: 'Title of Work',
					user: 'Title of Work',
				},
				info: {
					credit: '',
					user: '',
				},
			},
			artistName: {
				label: {
					credit: 'Artist Name',
					user: 'Artist Name',
				},
				info: {
					credit: '',
					user: '',
				},
			},
			role: {
				label: {
					credit: "Creator's Role",
					user: 'Your Role',
				},
				info: {
					user: 'Select the role that most closely describes your contribution to the work.',
					credit:
						"Select the role that most closely describes the creator's contribution to the work.",
				},
			},
		},
	},
	Career: {
		stepperLabel: {
			credit: 'Career',
			user: 'Career',
		},
		title: {
			user: 'Website Resource',
			credit: 'Website Resource',
		},
		description: {
			user: 'Enter a web resource, such as your own website or a qualified Wikipedia article about you or a project or organization you have been a part of, that provides the best substantiation of your career.',
			credit:
				'Enter a web resource, such as a qualified Wikipedia article about the creator or a project or organization they have been a part of, that provides the best substantiation of their career.',
		},
		form: {
			websiteTitle: {
				label: {
					credit: 'Title of Website',
					user: 'Title of Website',
				},
				info: {
					credit: '',
					user: '',
				},
			},
			websiteAddress: {
				label: {
					credit: 'URL',
					user: 'URL',
				},
				info: {
					credit: '',
					user: '',
				},
			},
		},
	},
	WorkRelation: {
		stepperLabel: {
			credit: 'Work Relation',
			user: 'Work Relation',
		},
		title: {
			user: 'Related Person or Organization',
			credit: 'Related Person or Organization',
		},
		description: {
			user: 'Enter a person or organization you have worked with professionally.',
			credit:
				'Enter a person or organization the creator has worked with professionally.',
		},
		form: {
			relatedPersonOrOrganization: {
				label: {
					credit: 'Would you like to enter a person or an organization?',
					user: 'Would you like to enter a person or an organization?',
				},
				options: {
					person: {
						credit: 'Person (such as an artist, musician, or producer)',
						user: 'Person (such as an artist, musician, or producer)',
					},
					organization: {
						credit:
							'Organization (such as a record label, studio, publishing company, or music group)',
						user: 'Organization (such as a record label, studio, publishing company, or music group)',
					},
				},
			},
			relatedForename: {
				label: {
					credit: "Related person's first name",
					user: "Related person's first name",
				},
				info: {
					credit: '',
					user: '',
				},
			},
			relatedSurname: {
				label: {
					credit: "Related person's last name",
					user: "Related person's last name",
				},
				info: {
					credit: '',
					user: '',
				},
			},
			relatedOrganizationName: {
				label: {
					credit: "Related organization's name",
					user: "Related organization's name",
				},
				info: {
					credit: '',
					user: '',
				},
			},
		},
	},
	IsniRetrieved: {
		title: {
			credit: 'Creator ISNI retrieved!',
			user: 'Your ISNI has been retrieved!',
		},
		description: (isni: string): IsniString => ({
			user: `Your ISNI code ${isni} has been retrieved and added to your profile. You are now ready to start getting full credit on recordings with Sound Credit.`,
			credit: `The creator's ISNI code ${isni} has been retrieved and added to the credit. They are now ready to start getting full credit on recordings with Sound Credit.`,
		}),
	},
	RequestIsni: {
		processing: {
			title: {
				credit: 'Your ISNI request is processing',
				user: 'Your ISNI request is processing',
			},
			description: {
				credit: 'This may take a few moments.',
				user: 'This may take a few moments.',
			},
		},
		timeoutError: {
			title: {
				credit: 'We were unable to complete your request at this time.',
				user: 'We were unable to complete your request at this time.',
			},
			description1: {
				user: 'We apologize for the inconvenience. Due to an unknown error during the registration process, your request will need to be reviewed manually.',
				credit:
					'We apologize for the inconvenience. Due to an unknown error during the registration process, your request could not be completed at this time.',
			},
			description2: {
				user: 'You may or may not have been assigned an ISNI during this process. You do not need to complete this form again. Your application will be manually reviewed by Sound Credit to ensure your ISNI gets assigned as soon as possible.',
				credit: '',
			},
		},
		otherError: {
			title: {
				user: 'We were unable to automatically complete your request at this time.',
				credit:
					'We were unable to automatically complete your request at this time.',
			},
			description1: {
				user: 'We apologize for the inconvenience. Your request may need to be completed manually, or you may have already received an ISNI in the past.',
				credit:
					'We apologize for the inconvenience. The creator may have already received an ISNI in the past.',
			},
		},
	},
	IsniCreated: {
		title: (isni: string) => ({
			user: `You have been assigned ISNI: ${isni}`,
			credit: `The creator has been assigned ISNI: ${isni}`,
		}),
		description1: {
			user: 'Congrats, you have officially received an ISNI — a special code that will help you secure the benefits of credits on Sound Credit and beyond.',
			credit:
				'The creator has officially received an ISNI — a special code that will help them secure the benefits of credits on Sound Credit and beyond.',
		},
		description2: {
			user: 'Your official ISNI is now listed in your Sound Credit profile in the ID tab.',
			credit: '',
		},
		description3: (email: string) => ({
			user: `You will receive an email at ${email} containing a certificate with your ISNI.`,
			credit: '',
		}),
	},
};

export type IsniString = {
	credit: string;
	user: string;
};

export const getIsniString = (str: IsniString, isUserClaimingIsni: boolean) =>
	str[isUserClaimingIsni ? 'user' : 'credit'];

export default isniStrings;
