import React, { useEffect, useCallback, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useAppDispatch } from '../../../../../../store/hooks';
import {
	hideModal,
	setModalTitle,
} from '../../../../../../store/modal/actions';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import TextField from '../../../../../form/TextField/TextField';
import Button from '../../../../../layout/Button';

const validationSchema = Yup.object({
	email: Yup.string().email('Invalid email address').required('Required'),
	message: Yup.string(),
});

export type InvitePlaylistEditorProps = {
	onSubmit: (email: string, message: string) => Promise<void>;
};

const InvitePlaylistEditorModal = ({ onSubmit }: InvitePlaylistEditorProps) => {
	const dispatch = useAppDispatch();
	const [requestError, setRequestError] = useState<string | null>(null);

	const handleClose = useCallback(() => {
		dispatch(hideModal());
	}, [dispatch]);

	const formik = useFormik({
		initialValues: {
			email: '',
			message: '',
		},
		onSubmit: async values => {
			try {
				setRequestError(null);
				await onSubmit(values.email, values.message);
				handleClose();
			} catch (e: any) {
				setRequestError(e?.response?.data?.detail ?? 'Something went wrong');
			}
		},
		validationSchema,
		validateOnMount: true,
	});

	useEffect(() => {
		dispatch(setModalTitle('INVITE NEW COLLABORATOR'));
	}, [dispatch]);

	return (
		<FormikProvider value={formik}>
			<Modal.Body>
				<div className='px-4'>
					<TextField
						name='email'
						label='Collaborator Email'
						value={formik.values.email}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						errorMessage={formik?.errors?.email}
						isInvalid={formik?.touched?.email && !!formik?.errors?.email}
					/>

					<TextField
						name='message'
						label='Message (Optional)'
						value={formik.values.message}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						errorMessage={formik?.errors?.message}
						isInvalid={formik?.touched?.message && !!formik?.errors?.message}
					/>

					{requestError && (
						<div
							className='text-danger text-center mt-3'
							style={{
								fontSize: '0.8rem',
							}}
						>
							{requestError}
						</div>
					)}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button theme='light' label='Cancel' onClick={handleClose} />

				<Button
					theme='dark'
					label='Invite'
					onClick={formik.handleSubmit as any}
					isLoading={formik.isSubmitting}
					isDisabled={!formik.isValid}
				/>
			</Modal.Footer>
		</FormikProvider>
	);
};

export default InvitePlaylistEditorModal;
