import React, { useMemo } from 'react';
import './UpgradeTierModal.scss';
import PillButton from '../../layout/PillButton';
import { useAppSelector } from '../../../store/hooks';
import { TierDescriptions } from '../../../constants/tierDescriptions';
import { PaymentInterval } from './UpgradeTierModal';
import clsx from 'clsx';
import { Tag } from 'primereact/tag';

type TierOptionProps = {
	tier: TierDescription;
	onUpgrade: (tier: TierDescription) => void | Promise<void>;
	paymentInterval: PaymentInterval | null;
	showNewUserStrings?: boolean;
	isLoading?: boolean;
	discountPercentage?: number;
};

const calculateFinalPrice = (price: string, discountPercentage?: number) => {
	// check if price string is a number
	const priceFloat = parseFloat(price);
	if (isNaN(priceFloat)) return price;

	if (!discountPercentage) return price;

	const final = priceFloat - priceFloat * (discountPercentage / 100);

	return final.toFixed(2);
};

const TierOption = ({
	tier,
	onUpgrade,
	paymentInterval,
	showNewUserStrings = false,
	isLoading = false,
	discountPercentage,
}: TierOptionProps) => {
	const { userPermissions } = useAppSelector(state => state.auth);

	const isCurrentTier = userPermissions!.subscriptionTier === tier.id;

	const finalMonthlyPrice = useMemo(
		() => calculateFinalPrice(tier.monthlyPrice, discountPercentage),
		[tier.monthlyPrice, discountPercentage]
	);

	const finalYearlyPrice = useMemo(
		() => calculateFinalPrice(tier.yearlyPrice, discountPercentage),
		[tier.yearlyPrice, discountPercentage]
	);

	const isLowerTier =
		userPermissions!.subscriptionTier ===
			TierDescriptions.ENTERPRISE.ENTERPRISE.id || // Enterprise is the highest tier
		(userPermissions!.subscriptionTier === TierDescriptions.LITE.LITE_PLUS.id &&
			tier.id === TierDescriptions.LITE.LITE.id) || // Lite Plus is the highest Lite tier, but the number is higher than Paid tiers
		(userPermissions!.subscriptionTier > tier.id &&
			userPermissions?.subscriptionTier !== TierDescriptions.LITE.LITE_PLUS.id);

	const handleUpgrade = () => {
		if (isCurrentTier) return;

		onUpgrade(tier);
	};

	return (
		<div className='upgrade-tier-option'>
			<div className='tier-name'>
				{tier.name}{' '}
				{discountPercentage && (
					<Tag
						className='tier-price-amount__discount-percentage ml-2'
						value={`${discountPercentage}% OFF FOREVER`}
					/>
				)}
			</div>
			<div className='tier-body'>
				<div className='tier-price mt-4'>
					<span
						className={clsx('tier-price-amount', {
							'tier-price-amount__discounted': !!discountPercentage,
						})}
					>
						<span className='tier-price-amount'>
							{tier?.currency?.symbol && tier.currency.symbol}
							{paymentInterval === PaymentInterval.Monthly
								? tier.monthlyPrice
								: tier.yearlyPrice}
						</span>
					</span>
					{discountPercentage && (
						<>
							<span className='tier-price-amount tier-price-amount__final-price ml-2'>
								{tier?.currency?.symbol && tier.currency.symbol}
								{paymentInterval === PaymentInterval.Monthly
									? finalMonthlyPrice
									: finalYearlyPrice}
							</span>
						</>
					)}
					{/* {tier?.currency?.code && (
						<span className='tier-price-currency'> {tier?.currency?.code}</span>
					)} */}
					{paymentInterval && (
						<p className='tier-payment-interval mb-0'>
							/ {paymentInterval === PaymentInterval.Monthly ? 'month' : 'year'}
						</p>
					)}
				</div>
				<div className='tier-subtitle my-2'>
					{tier.storage} storage + {tier.exports === 0 ? 'No' : tier.exports}{' '}
					Export
					{tier.exports !== 1 && 's'}
				</div>
				<div className='w-100 px-4 my-4'>
					<PillButton
						fullWidth
						isLoading={isLoading}
						theme={
							isCurrentTier
								? 'solid-light'
								: isLowerTier
								? 'solid-dark'
								: 'solid-pink'
						}
						label={
							isCurrentTier ? (
								'ACTIVE'
							) : isLowerTier ? (
								'DOWNGRADE'
							) : showNewUserStrings ? (
								<div>
									GET STARTED
									<i className='fas fa-chevron-right ml-2' />
								</div>
							) : (
								'UPGRADE'
							)
						}
						className='px-3 py-2 w-100 fw-bold'
						onClick={handleUpgrade}
						isDisabled={isCurrentTier || isLowerTier}
					/>
				</div>
				{tier.generalFeatures.map((feature, index) => (
					<p
						key={index}
						className={`tier-feature ${
							feature.isSpecial ? 'special-tier-feature' : ''
						}`}
					>
						{feature.text}
					</p>
				))}

				{tier.featuresWithHeadlines.length > 0 && <hr />}

				{tier.featuresWithHeadlines.map((feature, index) => (
					<div key={index}>
						<h3
							className={`tier-feature-headline ${
								feature.isSpecial ? 'special-tier-feature-headline' : ''
							}`}
						>
							{feature.headline}
						</h3>
						<p className='tier-feature'>{feature.text}</p>
					</div>
				))}
				{tier.smallPrint && (
					<div className='tier-small-print'>
						<hr />
						{tier.smallPrint}
					</div>
				)}
			</div>
		</div>
	);
};

export default TierOption;
