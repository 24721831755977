import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useAppDispatch } from '../../../store/hooks';
import { setModalTitle } from '../../../store/modal/actions';
import Button from '../../layout/Button';

type AreYouUnionMemberModalProps = {
	onPositiveClick: () => void;
	onNegativeClick: () => void;
};

const AreYouUnionMemberModal = ({
	onPositiveClick,
	onNegativeClick,
}: AreYouUnionMemberModalProps) => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setModalTitle('Are you a union member?'));
	}, [dispatch]);

	return (
		<>
			<Modal.Body>
				<div className='px-4'>
					<p>
						If you belong to a musician's union, you can add your union
						information to Sound Credit to make it easier to share your credits
						and session information with your union.
					</p>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button label='No' onClick={onNegativeClick} />
				<Button label='Yes' theme='dark' onClick={onPositiveClick} />
			</Modal.Footer>
		</>
	);
};

export default AreYouUnionMemberModal;
