const ROUTES = {
	Login: {
		id: 'login',
		path: '/login',
		label: 'Login',
	},
	Projects: {
		id: 'projects',
		path: '/projects',
		label: 'Details',
	},
	Editor: {
		id: 'editor',
		path: '/recordings',
		label: 'Editor',
	},
	EditRecording: {
		id: 'edit-recording',
		path: '/recordings/:recordingId',
		relativePath: ':recordingId',
		label: 'Edit Recording',
	},
	EditorError: {
		id: 'editor-error',
		path: '/recordings/:recordingId/error',
		relativePath: 'error',
		label: 'Editor Error',
	},
	EditRecordingDetails: {
		id: 'edit-recording-details',
		path: '/recordings/:recordingId/recording-details',
		relativePath: 'recording-details',
		label: 'Edit Recording Details',
	},
	EditReleaseDetails: {
		id: 'edit-release-details',
		path: '/recordings/:recordingId/release-details',
		relativePath: 'release-details',
		label: 'Edit Release Details',
	},
	EditRecordingCredits: {
		id: 'edit-recording-credits',
		path: '/recordings/:recordingId/credits',
		relativePath: 'credits',
		label: 'Edit Recording Credits',
	},
	EditLyrics: {
		id: 'edit-lyrics',
		path: '/recordings/:recordingId/lyrics',
		relativePath: 'lyrics',
		label: 'Edit Lyrics',
	},
	SyncedLyrics: {
		id: 'syncedLyrics',
		path: '/recordings/:recordingId/synced-lyrics',
		relativePath: 'synced-lyrics',
		label: 'Synced Lyrics',
	},
	EditRecordingNotes: {
		id: 'edit-recording-notes',
		path: '/recordings/:recordingId/notes',
		relativePath: 'notes',
		label: 'Edit Notes',
	},
	ExportValidation: {
		id: 'export-validation',
		path: '/recordings/:recordingId/export/validation',
		relativePath: 'export/validation',
		label: 'Export Validation',
	},
	ExportPreview: {
		id: 'export-preview',
		path: '/recordings/:recordingId/export/preview',
		relativePath: 'export/preview',
		label: 'Export Preview',
	},
	UnionFormExport: {
		id: 'union-form-export',
		path: '/recordings/:recordingId/export/union-form',
		relativePath: 'export/union-form',
		label: 'Union Form Export',
	},
	SelectExportParticipants: {
		id: 'select-export-participants',
		path: '/recordings/:recordingId/export/participants',
		relativePath: 'export/participants',
		label: 'Select Participants',
	},
	RecordingFiles: {
		id: 'recordingFiles',
		path: '/files/recordings/:recordingId/:section',
		label: 'Recording Files',
	},
	AlbumFiles: {
		id: 'albumFiles',
		path: '/files/albums/:albumId/:section',
		label: 'Album Files',
	},
	IndieUpload: {
		id: 'indieUpload',
		path: '/indie-upload',
		label: 'Indie Upload',
	},
	PublishedCredits: {
		id: 'published-credits',
		path: '/indie-upload/published-credits',
		label: 'Published Credits',
	},
	GeneratedCodes: {
		id: 'generatedCodes',
		path: '/generated-codes',
		label: 'Generated Codes',
	},

	SavedProfiles: {
		id: 'savedProfiles',
		path: '/profiles',
		label: 'Saved Profiles',
	},
	Settings: {
		id: 'settings',
		path: '/settings',
		label: 'Settings',
	},
	Help: {
		id: 'help',
		path: '/help',
		label: 'Help',
	},
	Playlists: {
		id: 'playlists',
		path: '/playlists',
		label: 'File Sharing',
	},
	TransferFiles: {
		id: 'transferFiles',
		path: '/playlists/transfer',
		label: 'Transfer Files',
		relativePath: 'transfer',
	},
	PlaylistDetails: {
		id: 'playlistDetails',
		path: '/playlists/:playlistId',
		label: 'Playlists',
		relativePath: ':playlistId',
	},
	PlaylistStats: {
		id: 'playlistStats',
		path: '/playlists/:playlistId/stats',
		label: 'Playlists',
		relativePath: 'stats',
	},
	DripStats: {
		id: 'dripStats',
		path: '/playlists/:playlistId/drip-stats',
		label: 'Drip Stats',
		relativePath: 'drip-stats',
	},
	SignUp: {
		id: 'signUp',
		path: '/sign-up',
		label: 'Create Account',
	},
};

export default ROUTES;
