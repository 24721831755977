import { POP_MODALS_UNTIL_TYPE } from '../actionTypes';
import {
	SHOW_MODAL,
	HIDE_MODAL,
	SET_MODAL_TITLE,
	REMOVE_MODAL,
	HIDE_ALL_MODALS,
	HIDE_MODAL_BY_TYPE,
	REMOVE_MODAL_BY_ID,
} from '../actionTypes';

export const showModalAction = (modalType: string, props = {}) => {
	return {
		type: SHOW_MODAL,
		modalType,
		props,
	};
};

export const hideModal = () => ({
	type: HIDE_MODAL,
});

export const setModalTitle = (title: string) => ({
	type: SET_MODAL_TITLE,
	title,
});

export const removeModalAction = () => ({
	type: REMOVE_MODAL,
});

export const hideAllModalsAction = () => ({
	type: HIDE_ALL_MODALS,
});

export const hideModalByTypeAction = (modalType: string) => ({
	type: HIDE_MODAL_BY_TYPE,
	modalType,
});

export const removeModalByIdAction = (id: string) => ({
	type: REMOVE_MODAL_BY_ID,
	id,
});

export const popModalsUntilTypeAction = (modalType: string) => ({
	type: POP_MODALS_UNTIL_TYPE,
	modalType,
});
