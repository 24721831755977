import React from 'react';

import {
	CellMeasurer,
	CellMeasurerCache,
	List,
	WindowScroller,
} from 'react-virtualized';

const rowHeight = 40;

const MenuList = ({ children: rows }) => {
	const cache = new CellMeasurerCache({
		defaultHeight: rowHeight,
		fixedWidth: true,
	});

	const rowRenderer = ({
		key,
		index,
		isScrolling,
		isVisible,
		style,
		parent,
	}) => {
		return (
			<CellMeasurer
				cache={cache}
				columnIndex={0}
				key={key}
				rowIndex={index}
				parent={parent}
			>
				<div style={style}>{rows[index]}</div>
			</CellMeasurer>
		);
	};

	const fixedHeight = rows.length > 6;

	return (
		<WindowScroller>
			{({ height, isScrolling, onChildScroll, scrollTop }) => (
				<List
					autoHeight={!fixedHeight && rows.length > 0}
					style={{ width: '100%' }}
					width={500}
					height={!rows.length ? rowHeight : fixedHeight ? 250 : height}
					rowHeight={cache.rowHeight}
					rowCount={rows.length || 1}
					rowRenderer={rowRenderer}
					// scrollTop={scrollTop}
					onScroll={onChildScroll}
					isScrolling={isScrolling}
				/>
			)}
		</WindowScroller>
	);
};

export default MenuList;
