import projectsReducer from './projects/reducer';
import modalReducer from './modal/reducer';
import participantsReducer from './participants/reducer';
import profilesReducer from './profiles/reducer';
import exportReducer from './exports/reducer';
import authReducer from './auth/reducer';
import storage from 'redux-persist/lib/storage';
import alertToastReducer from './alertToast/reducer';
import sessionReducer from './session/reducer';
import filesReducer from './files/reducer';
import userReducer from './user/reducer';
import helpReducer from './help/reducer';

import { AnyAction, combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import persistStore from 'redux-persist/es/persistStore';
import playlistsReducer from './playlists/reducer';
import localDataReducer from './localData/reducer';
import promosReducer from './promos/reducer';
import playerReducer from './player/reducer';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['auth', 'session', 'localData'],
	blacklist: ['auth', 'session', 'localData'],
};

const rootReducer = combineReducers({
	projects: projectsReducer,
	modal: modalReducer,
	participants: participantsReducer,
	profiles: profilesReducer,
	exports: exportReducer,
	auth: authReducer,
	alertToast: alertToastReducer,
	session: sessionReducer,
	files: filesReducer,
	user: userReducer,
	playlists: playlistsReducer,
	localData: localDataReducer,
	promos: promosReducer,
	player: playerReducer,
	help: helpReducer,
});

export type RootReducer = ReturnType<typeof rootReducer>;

export const persistedReducers = persistReducer<RootReducer, AnyAction>(
	persistConfig,
	rootReducer
);

export const store = configureStore({
	reducer: persistedReducers,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
			immutableCheck: false,
		}).concat(thunk),
	devTools: process.env.REACT_APP_ENV !== 'production',
});

export const persistedStore = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type GetState = typeof store.getState;

export default persistedReducers;
