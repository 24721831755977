import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { importRinAction } from '../../../../store/exports/actions';
import {
	hideAllModalsAction,
	hideModal,
	setModalTitle,
} from '../../../../store/modal/actions';
import {
	setCurrentAlbumIdAction,
} from '../../../../store/projects/actions';
import TextField from '../../../form/TextField';
import Button from '../../../layout/Button';
import { replacePathVariables } from '../../../../helpers/routeTools';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../router/routes';

const ImportRinModal = ({
	albumId,
	clearCurrentAlbumIdAfterImport = false,
}) => {
	/*
	 * React Router Hooks
	 */
	const navigate = useNavigate();

	/*
	 * Redux Hooks
	 */
	const dispatch = useDispatch();

	/*
	 * React Hooks
	 */
	const [rin, setRin] = useState('');
	const [isLoading, setIsLoading] = useState(false);

	const isImportDisabled = useMemo(() => rin === '', [rin]);

	/*
	 * Effects
	 */
	useEffect(() => {
		dispatch(setModalTitle('Import RIN'));
	}, [dispatch]);

	/*
	 * Callbacks
	 */
	const handleFileInputChange = useCallback(e => {
		if (e.target.files && e.target.files.length > 0) {
			const reader = new FileReader();

			reader.addEventListener('load', () => {
				console.log(reader.result);
				setRin(reader.result.toString());
			});

			reader.readAsText(e.target.files[0]);
		}
	}, []);

	const handleCancel = useCallback(() => {
		dispatch(hideModal());
	}, [dispatch]);

	const handleImport = useCallback(() => {
		setIsLoading(true);

		const onCreate = id => {
			if (clearCurrentAlbumIdAfterImport) {
				dispatch(setCurrentAlbumIdAction(null));
			}

			navigate(
				replacePathVariables(ROUTES.EditRecording.path, {
					recordingId: id,
				})
			);

			setIsLoading(false);
			dispatch(hideAllModalsAction());
		};

		dispatch(importRinAction(rin, albumId === '' ? null : albumId, onCreate));
	}, [dispatch, rin, albumId, clearCurrentAlbumIdAfterImport, navigate]);

	return (
		<>
			<Modal.Body className='px-5'>
				<TextField
					label='Select RIN file'
					type='file'
					onChange={handleFileInputChange}
					style={{ height: '100%', padding: 0 }}
					// isInvalid={fileValidations.length > 0}
					// errorMessage={fileValidations.join('\n')}
				/>
			</Modal.Body>
			<Modal.Footer>
				<Row>
					<Col
						xs={12}
						className='d-flex justify-content-end align-items-center'
					>
						<Button label='Cancel' className='mr-2' onClick={handleCancel} />
						<Button
							label='Import'
							isDisabled={isImportDisabled}
							onClick={handleImport}
							theme='dark'
							isLoading={isLoading}
						/>
					</Col>
				</Row>
			</Modal.Footer>
		</>
	);
};

export default ImportRinModal;
