import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import isniStrings, { IsniString } from '../isniStrings';
import { Form } from 'react-bootstrap';
import Button from '../../../layout/Button';
import TextField from '../../../form/TextField/TextField';
import { RadioButton } from 'primereact/radiobutton';

type WorkRelationStepProps = {
	onBack: (values: WorkRelationFormikValues) => void;
	onSubmit: (values: WorkRelationFormikValues) => Promise<void>;
	initialFormValues?: WorkRelationFormikValues;
	getIsniStringFn: (isniString: IsniString) => string;
};

export type WorkRelationFormikValues = {
	relatedForename: string;
	relatedSurname: string;
	relatedOrganizationName: string;
	relatedPersonOrOrganization: 'person' | 'organization';
};

const validationSchema = Yup.object({
	relatedForename: Yup.string().when('relatedPersonOrOrganization', {
		is: 'person',
		then: Yup.string().required('Required'),
	}),
	relatedSurname: Yup.string().when('relatedPersonOrOrganization', {
		is: 'person',
		then: Yup.string().required('Required'),
	}),
	relatedOrganizationName: Yup.string().when('relatedPersonOrOrganization', {
		is: 'organization',
		then: Yup.string().required('Required'),
	}),
	relatedPersonOrOrganization: Yup.string()
		.oneOf(['person', 'organization'])
		.required('Required'),
});

const WorkRelationStep = ({
	onBack,
	onSubmit,
	initialFormValues,
	getIsniStringFn,
}: WorkRelationStepProps) => {
	const formik = useFormik<WorkRelationFormikValues>({
		initialValues: initialFormValues ?? {
			relatedForename: '',
			relatedSurname: '',
			relatedOrganizationName: '',
			relatedPersonOrOrganization: 'person',
		},
		onSubmit: values => {
			console.log('values', values);
			onSubmit(values);
		},
		validationSchema,
		validateOnMount: true,
		enableReinitialize: true,
	});

	const personOrOrganizationOptions = [
		{
			label: getIsniStringFn(
				isniStrings.WorkRelation.form.relatedPersonOrOrganization.options.person
			),
			value: 'person',
		},
		{
			label: getIsniStringFn(
				isniStrings.WorkRelation.form.relatedPersonOrOrganization.options
					.organization
			),
			value: 'organization',
		},
	];

	return (
		<FormikProvider value={formik}>
			<h3>{getIsniStringFn(isniStrings.WorkRelation.title)}</h3>

			<h4>{getIsniStringFn(isniStrings.WorkRelation.description)}</h4>

			<Form.Label>Would you like to enter a person or organization?</Form.Label>

			{personOrOrganizationOptions.map(option => (
				<div key={option.value} className='d-flex align-items-center mb-2'>
					<RadioButton
						inputId={option.value}
						name='relatedPersonOrOrganization'
						value={option.value}
						onChange={e =>
							formik.setFieldValue('relatedPersonOrOrganization', e.value)
						}
						checked={formik.values.relatedPersonOrOrganization === option.value}
					/>
					<label htmlFor={option.value} className='ml-2 mb-0'>
						{option.label}
					</label>
				</div>
			))}

			<div className='my-3' />

			{formik.values.relatedPersonOrOrganization === 'person' ? (
				<>
					<TextField
						label={getIsniStringFn(
							isniStrings.WorkRelation.form.relatedForename.label
						)}
						name='relatedForename'
						placeholder={getIsniStringFn(
							isniStrings.WorkRelation.form.relatedForename.label
						)}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.relatedForename}
						errorMessage={formik.errors.relatedForename}
						isInvalid={
							formik.touched.relatedForename && !!formik.errors.relatedForename
						}
					/>
					<TextField
						label={getIsniStringFn(
							isniStrings.WorkRelation.form.relatedSurname.label
						)}
						name='relatedSurname'
						placeholder={getIsniStringFn(
							isniStrings.WorkRelation.form.relatedSurname.label
						)}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.relatedSurname}
						errorMessage={formik.errors.relatedSurname}
						isInvalid={
							formik.touched.relatedSurname && !!formik.errors.relatedSurname
						}
					/>
				</>
			) : (
				<TextField
					label={getIsniStringFn(
						isniStrings.WorkRelation.form.relatedOrganizationName.label
					)}
					name='relatedOrganizationName'
					value={formik.values.relatedOrganizationName}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					errorMessage={formik.errors.relatedOrganizationName}
					isInvalid={
						formik.touched.relatedOrganizationName &&
						!!formik.errors.relatedOrganizationName
					}
					placeholder={getIsniStringFn(
						isniStrings.WorkRelation.form.relatedOrganizationName.label
					)}
				/>
			)}

			<div className='isni-registration__buttons mt-4'>
				<Button
					label='Back'
					leftIcon='fas fa-arrow-left'
					onClick={() => onBack(formik.values)}
				/>

				<Button
					label='Next'
					rightIcon='fas fa-arrow-right'
					onClick={formik.submitForm}
					theme='dark'
				/>
			</div>
		</FormikProvider>
	);
};

export default WorkRelationStep;
