import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
	ALBUM_DETAILS_MODAL,
	DELETE_ALBUM_MODAL,
	DELETE_RECORDING_MODAL,
	CHAT_MODAL,
	UPLOAD_FILES_MODAL,
} from '../../../../constants/modalTypes';
import {
	showModalAction,
	setModalTitle,
} from '../../../../store/modal/actions';
import IconButton from '../../../layout/IconButton';
import PillButton from '../../../layout/PillButton';
import theme from '../../../../theme.module.scss';
import openShareModal from '../../../../helpers/openShareModal';
import { navigateToProjectEditor } from '../../../../helpers/projectTools';

function ActionsCell({ cell }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { userId } = useSelector(state => state.auth);
	const { albumsById } = useSelector(state => state.projects);
	// const { albumsById, recordingsById } = useSelector(state => state.projects);

	const isOwner = useMemo(
		() => cell.row.original.owner.projectUserId === userId,
		[userId, cell]
	);
	const isAlbumTrack = useMemo(
		() =>
			cell.row.original.albumId &&
			!albumsById[cell.row.original.albumId]?.isSingle,
		[cell, albumsById]
	);

	const isLoading = useMemo(
		() => !(cell && cell.row.original.createdAt),
		[cell]
	);

	const isEditable = useMemo(
		() => isOwner || cell.row.original.isEditable,
		[isOwner, cell]
	);

	const handlePrepareCredits = () => {
		const recordingId = cell.row.original.id;
		const albumId = cell.row.original.albumId;

		navigateToProjectEditor({
			albumId,
			recordingId,
			navigate,
			dispatch,
			albumsById,
		});
	};

	const handleDelete = () => {
		const projectId = cell.row.original.id;
		const isAlbum = cell.row.canExpand;

		if (isAlbum) {
			dispatch(
				showModalAction(DELETE_ALBUM_MODAL, { id: projectId, size: 'lg' })
			);
		} else {
			dispatch(
				showModalAction(DELETE_RECORDING_MODAL, {
					id: projectId,
					project: cell.row.original,
					size: 'lg',
				})
			);
		}
	};

	const openChatModal = () => {
		const recordingId = cell.row.original.id;

		dispatch(setModalTitle('Chat'));
		dispatch(
			showModalAction(CHAT_MODAL, {
				size: 'lg',
				recordingId,
			})
		);
	};
	// TODO: implement album details modal
	const handleAlbumDetails = () =>
		dispatch(
			showModalAction(ALBUM_DETAILS_MODAL, { albumId: cell.row.original.id })
		);

	const handleUpload = () => {
		const projectId = cell.row.original.id;
		const recordingId = cell.row.canExpand ? null : projectId;
		const albumId = cell.row.canExpand ? projectId : null;

		const projectTitle = cell.row.original.title;

		dispatch(
			showModalAction(UPLOAD_FILES_MODAL, {
				size: 'lg',
				recordingId,
				albumId,
				navigateToFilesOnUpload: false,
				title: projectTitle,
			})
		);
	};

	return (
		<div className='action-icons'>
			<div
				className='d-flex align-items-center justify-content-center'
				style={{ gap: '.5em' }}
			>
				{isEditable && (
					<IconButton
						icon='fas fa-upload'
						onClick={handleUpload}
						color={theme.primary}
						tooltipText='Upload Files'
					/>
				)}
				<IconButton
					icon={isAlbumTrack ? 'fas fa-minus-circle' : 'fas fa-trash-alt'}
					color={isAlbumTrack ? theme.primary : theme.error}
					onClick={handleDelete}
					isDisabled={isLoading}
					tooltipText={isAlbumTrack ? 'Remove From Album' : 'Delete'}
				/>
				{!cell.row.canExpand && (
					<IconButton
						icon={'far fa-comment-alt'}
						color={theme.primary}
						onClick={openChatModal}
						isDisabled={isLoading}
						tooltipText={'Chat'}
					/>
				)}
				<>
					<IconButton
						icon={'fas fa-share-alt'}
						color={theme.primary}
						onClick={() => openShareModal(cell, dispatch)}
						isDisabled={isLoading}
						tooltipText={'Share'}
					/>
				</>
			</div>
			<div className='pr-2'>
				{cell.row.canExpand ? (
					<PillButton
						borderRadius={10}
						label='Album Info'
						leftIcon={`fas ${isOwner ? 'fa-edit' : 'fa-info-circle'}`}
						onClick={handleAlbumDetails}
						isDisabled={isLoading}
					/>
				) : (
					<PillButton
						borderRadius={10}
						label='Edit'
						rightIcon='fas fa-chevron-right'
						onClick={handlePrepareCredits}
						isDisabled={isLoading}
					/>
				)}
			</div>
		</div>
	);
}

export default ActionsCell;
