import translateApiRecordingToLocal from './translateApiRecordingToLocal';

const mergeFetchedRecordings = (fetchedRecordings, recordingsById) => {
	const mergedRecordings = { ...recordingsById };

	fetchedRecordings.forEach(fetchedRec => {
		fetchedRec = translateApiRecordingToLocal(fetchedRec);
		const localRec = mergedRecordings[fetchedRec.id];

		// We only want to merge the recordings if they don't already exist
		// thus we avoid overwriting the properly cached recording objects
		// If they're stale, then they will be overwritten with a null recording field,
		// forcing a re-fetch of the specific recording the next time it's needed
		const fetchedUpdatedAt = new Date(fetchedRec.updatedAt);
		const localUpdatedAt = new Date(localRec?.updatedAt || 0);

		if (!localRec || fetchedUpdatedAt > localUpdatedAt) {
			mergedRecordings[fetchedRec.id] = fetchedRec;
		} else if (localRec && !localRec.createdAt) {
			// for new recordings
			mergedRecordings[fetchedRec.id].createdAt = fetchedRec.createdAt;
			mergedRecordings[fetchedRec.id].updatedAt = fetchedRec.updatedAt;
			mergedRecordings[fetchedRec.id].userId = fetchedRec.userId;
		}
	});

	// if some fetched recordings are null but we have them locally, we want to delete them
	// as they might have been deleted on the server
	const fetchedRecordingsById = fetchedRecordings.reduce((acc, rec) => {
		acc[rec.id] = rec;
		return acc;
	}, {});

	Object.keys(mergedRecordings).forEach(recId => {
		if (!fetchedRecordingsById[recId]) {
			delete mergedRecordings[recId];
		}
	});

	return mergedRecordings;
};

export default mergeFetchedRecordings;
