import { Modal } from 'react-bootstrap';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useEffect } from 'react';
import {
	hideModal,
	popModalsUntilTypeAction,
	setModalTitle,
	showModalAction,
} from '../../../store/modal/actions';
import SoundCreditLoader from '../SoundCreditLoader/SoundCreditLoader';
import styles from './AccountDetailsModal.module.scss';
import clsx from 'clsx';
import PillButton from '../../layout/PillButton/PillButton';
import IconButton from '../../layout/IconButton/IconButton';
import { fetchUserDataAction } from '../../../store/user/actions';
import {
	ACCOUNT_DETAILS_MODAL,
	CONFIRMATION_MODAL,
	ISNI_REGISTRATION_MODAL,
} from '../../../constants/modalTypes';
import { Button } from 'primereact/button';
import { Helmet } from 'react-helmet';

const AccountDetailsModal = () => {
	const dispatch = useAppDispatch();
	const { userProfile } = useAppSelector(state => state.user);

	useEffect(() => {
		dispatch(setModalTitle('MY ACCOUNT'));
	}, [dispatch]);

	useEffect(() => {
		if (!userProfile) dispatch(fetchUserDataAction());
	}, [dispatch, userProfile]);

	if (!userProfile)
		return (
			<Modal.Body>
				<SoundCreditLoader />
			</Modal.Body>
		);

	return (
		<>
			<Helmet>
				<title>My Account {process.env.REACT_APP_TAB_TITLE}</title>
			</Helmet>
			<Modal.Body>
				<div className={clsx('px-4', styles['account-details-modal'])}>
					<h3>Account Details</h3>
					<h4>LEGAL NAME</h4>
					<p>{userProfile.legalName}</p>
					<h4>ALIASES</h4>
					<p>{userProfile.aliases.join(', ')}</p>
					<h4>COUNTRY</h4>
					<p>{userProfile.country}</p>
					<h4>PUBLISHERS</h4>
					{!userProfile.publishers.length ? (
						<p className='text-muted'>
							You have no publishers saved in your profile
						</p>
					) : (
						userProfile.publishers.map(publisher => (
							<div>
								<strong>{publisher.name}</strong>
								<div className='text-muted'>PRO {publisher.pro}</div>
								{publisher.ipi && (
									<div className='text-muted'>IPI {publisher.ipi}</div>
								)}
							</div>
						))
					)}
					<h4>ROLES</h4>
					{!userProfile.publishers.length ? (
						<p className='text-muted'>
							You have no roles saved in your profile
						</p>
					) : (
						userProfile.roles.map(role => role.detail).join(', ')
					)}

					<hr />
					<h3>ISNI</h3>

					<h4>YOUR ISNI</h4>
					{!userProfile.isni ? (
						<div className='d-flex flex-column my-2 w-100 align-items-center'>
							<p className='mb-2'>You currently don't have an ISNI</p>
							<PillButton
								onClick={() => {
									// dispatch(hideModal());
									dispatch(
										showModalAction(ISNI_REGISTRATION_MODAL, {
											isUserClaimingIsni: true,
											size: 'lg',
											onHide: () =>
												dispatch(
													showModalAction(CONFIRMATION_MODAL, {
														size: 'md',
														title: 'ARE YOU SURE YOU WANT TO EXIT?',
														description:
															'You will lose all progress in the ISNI registration process',
														confirmAction: {
															label: 'Exit',
															onClick: () =>
																dispatch(
																	popModalsUntilTypeAction(
																		ACCOUNT_DETAILS_MODAL
																	)
																),
														},
														cancelAction: {
															label: 'Cancel',
															onClick: () => dispatch(hideModal()),
														},
													})
												),
										})
									);
								}}
								label='Claim/Create ISNI'
								theme='solid-light'
							/>
						</div>
					) : (
						<div className='d-flex align-items-center'>
							<p className='mb-0'>{userProfile.isni}</p>
							<IconButton
								icon='fas fa-copy'
								onClick={() => navigator.clipboard.writeText(userProfile.isni!)}
								tooltipText='Copy ISNI'
							/>
						</div>
					)}

					<hr />

					<div className='d-flex justify-content-center'>
						<Button
							onClick={() => window.open('https://sound.credit/account')}
							className='p-button-link'
						>
							<span>
								For more information and tweaks, please visit{' '}
								<strong>https://sound.credit/account</strong>
							</span>
						</Button>
					</div>
				</div>
			</Modal.Body>
		</>
	);
};

export default AccountDetailsModal;
