import React from 'react';
import Lottie from 'react-lottie';
import './SoundCreditLoader.scss';
import darkAnimationData from '../../../assets/animations/whiteLoaderOnDarkGrey.json';
import purpleAnimationData from '../../../assets/animations/whiteLoaderOnPurple.json';
import lightAnimationData from '../../../assets/animations/blackLoaderOnLightGrey.json';
import aiLoaderData from '../../../assets/animations/aiLoader.json';

export type SoundCreditLoaderTheme = 'dark' | 'purple' | 'light' | 'ai-light';

const selectAnimationStyle = (theme: SoundCreditLoaderTheme) => {
	switch (theme) {
		case 'dark':
			return darkAnimationData;
		case 'purple':
			return purpleAnimationData;
		case 'ai-light':
			return aiLoaderData;
		case 'light':
		default:
			return lightAnimationData;
	}
};

export type SoundCreditLoaderProps = {
	theme?: SoundCreditLoaderTheme;
	message?: string | null;
	style?: React.CSSProperties;
	progress?: number | null;
	iconWidth?: React.CSSProperties['width'];
	iconHeight?: React.CSSProperties['height'];
	width?: React.CSSProperties['width'];
	height?: React.CSSProperties['height'];
};

const SoundCreditLoader = ({
	theme = 'light',
	message = null,
	style = {},
	progress = null,
	iconWidth = 150,
	iconHeight = 150,
	width = '100%',
	height = '100%',
}: SoundCreditLoaderProps) => {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: selectAnimationStyle(theme),
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	return (
		<div
			style={{
				height,
				width,
				...style,
			}}
			className={`d-flex flex-column justify-content-center align-items-center fullscreen-loader`}
		>
			{theme === 'ai-light' ? (
				<div
					className='ai-loader-container'
					style={{
						height: iconHeight,
						width: iconWidth,
					}}
				>
					<div className='ai-loader-text'>AI</div>
					{message && <p className='mb-0 loading-screen-message'>{message}</p>}
				</div>
			) : null}
			<Lottie
				options={defaultOptions}
				isClickToPauseDisabled
				height={iconHeight}
				width={iconWidth}
				style={{ cursor: 'default' }}
			/>

			{theme !== 'ai-light' && message ? (
				<p className='mb-0 loading-screen-message'>{message}</p>
			) : null}
			{progress !== null && (
				<progress
					className='loading-screen-progress'
					value={progress}
					max='100'
				/>
			)}
		</div>
	);
};

export default SoundCreditLoader;
