import IconButton from '../../../../layout/IconButton';
import FileThumbnail from '../../FileThumbnail/FileThumbnail';
import '../../FileListItem/FileListItem.scss';
import theme from '../../../../../theme.module.scss';
import OverflowMenu from '../../../../layout/OverflowMenu';
import React, { useCallback, useMemo, useState } from 'react';
import FileListItem from '../../FileListItem';
import { downloadExportAction } from '../../../../../store/exports/actions';
import { getExportsOverflowMenuOptions } from '../../../../../helpers/fileTools';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { BrowserFileSelection } from '../../FilesBrowser/FilesBrowser';
import ExportESignatureChip from '../../../../layout/ExportESignatureChip';

export type ProjectExportsListItemProps = {
	file: ExportMetadata;
	isSelected: boolean;
	setSelectedFiles: React.Dispatch<React.SetStateAction<BrowserFileSelection>>;
};

const ProjectExportsListItem = ({
	file: exportData,
	isSelected,
	setSelectedFiles,
}: ProjectExportsListItemProps) => {
	const dispatch = useAppDispatch();
	const { myEditorProfile } = useAppSelector(state => state.projects);

	const isReadOnly = useMemo(
		() => (myEditorProfile ? myEditorProfile.is_read_only : true),
		[myEditorProfile]
	);

	const [isDownloading, setIsDownloading] = useState(false);

	const overflowMenuOptions = useMemo(
		() => getExportsOverflowMenuOptions(exportData, dispatch, !isReadOnly),
		[exportData, dispatch, isReadOnly]
	);

	const handlePreview: React.MouseEventHandler<HTMLElement> = useCallback(
		e => {
			document.body.click(); // close the overflow menu

			overflowMenuOptions.find(option => option.name === 'Preview')?.onClick(e);
		},
		[overflowMenuOptions]
	);

	const handleTitleClick: React.MouseEventHandler<HTMLDivElement> = useCallback(
		e => handlePreview(e),
		[handlePreview]
	);

	const handleExportDownload: React.MouseEventHandler<HTMLElement> =
		useCallback(
			async e => {
				e.stopPropagation();
				setIsDownloading(true);
				await Promise.resolve(
					dispatch(downloadExportAction(exportData.id, exportData.filename))
				);

				setIsDownloading(false);
			},
			[dispatch, exportData]
		);

	const renderThumbnail = useCallback(
		() => (
			<FileThumbnail
				forceLabelValue={0}
				forceIcon={exportData.esignature ? 'fas fa-file-signature' : ''}
				iconStyle={{
					marginRight: '-0.275rem',
					marginBottom: '-0.2rem',
					fontSize: '1.3rem',
				}}
			/>
		),
		[exportData.esignature]
	);

	const renderActions = useCallback(
		() => (
			<>
				<IconButton
					icon='fas fa-download'
					onClick={handleExportDownload}
					color={theme.primary}
					tooltipText='Download'
					isLoading={isDownloading}
				/>
				<OverflowMenu
					options={overflowMenuOptions}
					id={`id-${exportData.id}`}
				/>
			</>
		),
		[exportData.id, handleExportDownload, isDownloading, overflowMenuOptions]
	);

	const renderESignatureChip = useCallback(
		() =>
			exportData.esignature ? (
				<ExportESignatureChip exportData={exportData} className='mt-1' />
			) : null,
		[exportData]
	);

	return (
		<>
			<FileListItem
				id={exportData.id}
				filename={exportData.filename}
				creationDate={exportData.createdAt}
				isSelected={isSelected}
				setSelectedFiles={setSelectedFiles}
				thumbnail={renderThumbnail} // forces the default icon
				actions={renderActions}
				onBodyClick={handlePreview}
				onTitleClick={handleTitleClick}
				bottomContent={renderESignatureChip}
			/>
		</>
	);
};

export default ProjectExportsListItem;
