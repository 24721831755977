import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Button from '../../layout/Button';
import { useDispatch, useSelector } from 'react-redux';
import { showModalAction } from '../../../store/modal/actions';
import {
	hideAlertToast,
	showAlertToast,
} from '../../../store/alertToast/actions';
import { setModalTitle } from '../../../store/modal/actions';
import './Projects.scss';
import SearchBar from '../../layout/SearchBar';
import {
	CREATE_NEW_ALBUM_MODAL,
	CREATE_NEW_RECORDING_MODAL,
	GROUP_TO_ALBUM_MODAL,
	PROJECTS_SHARE_MODAL,
	PROJECT_LIMIT_MODAL,
} from '../../../constants/modalTypes';
import {
	fetchUserEditableProjectsAction,
	getUserProjectsAction,
	searchUserProjectsAction,
	setAlbumArchivedAction,
	setRecordingArchivedAction,
} from '../../../store/projects/actions';
import SoundCreditLoader from '../SoundCreditLoader';
import PillsTabs, { ProjectsTab } from '../../layout/PillsTabs/PillsTabs';
import { setProjectsSearchFilter } from '../../../store/projects/actions';
import ProjectsTable from './ProjectsTable/ProjectsTable';
import GetStarted from '../Editor/GetStarted/GetStarted';
import ProjectUsageStatus from './ProjectUsageStatus';
import {
	openUpgradeWebsite,
	hasReachedProjectLimit,
} from '../../../helpers/tiersTools';
import { getProjectsForTable } from '../../../store/projects/selectors';
import PillButton from '../../layout/PillButton/PillButton';
import { useAppSelector } from '../../../store/hooks';
import { debounce } from 'lodash';
import { AppDispatch } from '../../../store';
import { Helmet } from 'react-helmet';

const debouncedSearch = debounce(
	async (dispatch: AppDispatch, searchFilter: string, setIsSearching) => {
		setIsSearching(true);
		await dispatch(searchUserProjectsAction(searchFilter));
		setIsSearching(false);
	},
	500
);

const Projects = () => {
	const dispatch = useDispatch();
	const [isSearching, setIsSearching] = useState(false);
	const [activeKey, setActiveKey] = useState<string | null>('current');
	const {
		recordingsById,
		albumsById,
		searchFilter,
		projectUsage,
		userEditableRecordingIds,
		userEditableAlbumIds,
		searchedAlbumsById,
		searchedRecordingsById,
	} = useAppSelector(state => state.projects);
	const { userPermissions } = useAppSelector(state => state.auth);
	const [selectedProjects, setSelectedProjects] = useState<ProjectRow[]>([]);
	const [projectsAreLoading, setProjectsAreLoading] = useState(false);
	const projectRows = useSelector(getProjectsForTable);
	const filteredProjectRows = useSelector(state =>
		getProjectsForTable(state, {
			searchedAlbumsById,
			searchedRecordingsById,
		})
	);
	const displayProjectRows = useMemo(
		() =>
			(searchFilter ? filteredProjectRows : projectRows)
				?.filter(p => (activeKey === 'vault' ? p.isArchived : !p.isArchived))
				.filter(p => !(p.title === 'None' && p.artist === 'None')),
		[searchFilter, filteredProjectRows, projectRows, activeKey]
	);

	const canGroupToAlbum = useMemo(
		() =>
			selectedProjects.length > 0 &&
			!selectedProjects.some(p => 'subRows' in p),
		[selectedProjects]
	);

	const onSetFilter = (value: string) => {
		dispatch(setProjectsSearchFilter(value));
	};

	const newAlbumHandler = () =>
		dispatch(showModalAction(CREATE_NEW_ALBUM_MODAL));

	const newRecordingHandler = () => {
		if (hasReachedProjectLimit(projectUsage!)) {
			dispatch(
				showModalAction(PROJECT_LIMIT_MODAL, {
					size: 'md',
				})
			);

			return;
		}

		dispatch(
			showModalAction(CREATE_NEW_RECORDING_MODAL, {
				size: 'lg',
			})
		);
	};

	const moveProjects = (activeKey: string | null) => {
		// if we're in vault, we're unarchiving, otherwise we're archiving
		const newIsArchived = activeKey === 'vault' ? false : true;

		try {
			if (!selectedProjects.length) {
				dispatch(
					showAlertToast('A project must be selected on the table first.')
				);
				return;
			}

			selectedProjects.forEach(selectedProject => {
				if ('subRows' in selectedProject) {
					// if it's an album
					selectedProject.subRows
						.filter(
							// keep only AlbumRecordingRows
							(albumRecording): albumRecording is AlbumRecordingRow =>
								albumRecording !== ''
						)
						.forEach(albumRecording => {
							if (!albumRecording.id) {
								throw new Error(
									'No recording id found for album recording.  This should never happen.'
								);
							}

							dispatch(
								setRecordingArchivedAction(albumRecording.id, newIsArchived)
							);
						});

					if (!selectedProject.id) {
						throw new Error(
							'No album id found for album.  This should never happen.'
						);
					}

					// update the album row
					dispatch(setAlbumArchivedAction(selectedProject.id, newIsArchived));

					// continue
					return;
				}

				if (!selectedProject.id) {
					throw new Error(
						'No project id found for project.  This should never happen.'
					);
				}

				// if it's a recording
				dispatch(setRecordingArchivedAction(selectedProject.id, newIsArchived));

				// if it's a single release, update the release album
				if (
					'albumId' in selectedProject &&
					selectedProject.albumId &&
					albumsById?.[selectedProject.albumId]?.isSingle
				) {
					dispatch(
						setAlbumArchivedAction(selectedProject.albumId, newIsArchived)
					);
				}
			});

			console.log(
				activeKey === 'current'
					? 'PROJECTS MOVED TO VAULT!'
					: 'PROJECTS MOVED TO CURRENT PROJECTS!'
			);
		} catch (e) {
			dispatch(
				showAlertToast(
					"Whoops!  For some reason that project couldn't be moved to the vault.  Refresh and give it another spin."
				)
			);
			console.log(e);
		}
	};

	const reloadProjects = async () => {
		setProjectsAreLoading(true);
		dispatch(showAlertToast('Reloading projects...', 'editor'));

		await Promise.resolve();
		await Promise.resolve(dispatch(getUserProjectsAction()));

		dispatch(showAlertToast('Projects reloaded!', 'editor'));
		setProjectsAreLoading(false);

		setTimeout(() => {
			dispatch(hideAlertToast());
		}, 2000);
	};

	const handleGroupToAlbum = () => {
		if (!canGroupToAlbum) return;

		dispatch(
			showModalAction(GROUP_TO_ALBUM_MODAL, {
				recordingIds: selectedProjects.map(p => p.id),
				size: 'lg',
			})
		);
	};

	useEffect(() => {
		dispatch(fetchUserEditableProjectsAction()); // fetch recording and album editable permissions
		if (!recordingsById) {
			dispatch(getUserProjectsAction());
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		debouncedSearch(dispatch, searchFilter, setIsSearching);

		return () => {
			debouncedSearch.cancel();
		};
	}, [searchFilter, dispatch]);

	if (
		!displayProjectRows ||
		!recordingsById ||
		!userEditableAlbumIds ||
		!userEditableRecordingIds ||
		!userPermissions
	) {
		return <SoundCreditLoader theme='light' message='Loading Projects...' />;
	}

	return (
		<>
			<Helmet>
				<title>Projects {process.env.REACT_APP_TAB_TITLE}</title>
			</Helmet>
			{projectRows && projectRows.length === 0 ? (
				<GetStarted />
			) : (
				<div className='projects-container'>
					<div className='table-nav-container'>
						<Row>
							<Col xs={8}>
								<div className='create-project column-one'>
									<Button
										leftIcon='fas fa-plus-circle'
										className='mr-3 project-action-btn'
										label='New Album'
										onClick={newAlbumHandler}
									/>
									<Button
										className='project-action-btn'
										leftIcon='fas fa-plus-circle'
										label='New Recording / Song'
										onClick={newRecordingHandler}
									/>
								</div>
							</Col>
							<Col xs={4}>
								{/* <div > */}
								<Row className='column-second vault-share-container'>
									<SearchBar
										value={searchFilter}
										placeholder='Search by title or artist...'
										onChange={onSetFilter}
										style={{ flex: '1 !important' }}
										renderWithoutRow
										isLoading={isSearching}
										fullWidth
									/>
								</Row>
								{/* </div> */}
							</Col>
							<hr className='projects-context-bar-divider' />
							<Col
								xs={12}
								className='d-flex align-items-center justify-content-center'
							>
								<div className='projects-context-bar'>
									<div>
										<PillButton
											// className='mr-3'
											label='Group to Album'
											leftIcon='fas fa-folder-plus'
											className='mr-3 project-context-btn'
											onClick={handleGroupToAlbum}
											isDisabled={!canGroupToAlbum}
										/>
										<PillButton
											// className='mr-3'
											label={
												activeKey !== 'vault'
													? 'Move to Vault'
													: 'Move to Current Projects'
											}
											leftIcon={
												activeKey !== 'vault'
													? 'fas fa-archive'
													: 'fas fa-box-open'
											}
											className='mr-3 project-context-btn'
											onClick={() => moveProjects(activeKey)}
											isLocked={!userPermissions?.canVaultProjects}
											isDisabled={selectedProjects.length === 0}
										/>
										<PillButton
											className='project-context-btn'
											label='Share'
											leftIcon='fas fa-share-alt'
											onClick={() => {
												dispatch(setModalTitle('SHARE / REQUESTS'));
												dispatch(
													showModalAction(PROJECTS_SHARE_MODAL, {
														size: 'lg',
														values: selectedProjects,
													})
												);
											}}
											isDisabled={selectedProjects.length === 0}
										/>
									</div>

									<PillButton
										onClick={reloadProjects}
										label='Refresh Projects'
										isDisabled={projectsAreLoading}
										isLoading={projectsAreLoading}
										leftIcon='fas fa-sync-alt'
										className='project-action-btn'
									/>
								</div>
							</Col>
						</Row>
					</div>

					<div className='table-container'>
						<PillsTabs
							defaultActiveKey='current'
							activeKey={activeKey || 'current'}
							onSelect={(key: string | null) => setActiveKey(key)}
						>
							<ProjectsTab eventKey='current' title='Current Projects'>
								<Row className='mb-4'>
									<Col xs={12} sm={9} className='d-flex align-items-center'>
										<h2 className='mb-0'>RECORDINGS / SONGS</h2>
									</Col>

									{userPermissions.projectLimit > 0 && (
										<Col
											xs={12}
											sm={3}
											className='d-flex align-items-center justify-content-end'
										>
											<ProjectUsageStatus />
										</Col>
									)}
								</Row>

								<ProjectsTable
									setSelectedProjects={setSelectedProjects}
									projects={displayProjectRows}
								/>
							</ProjectsTab>
							<ProjectsTab eventKey='vault' title='Vault'>
								<Row className='mb-4'>
									<Col xs={12} sm={9} className='d-flex align-items-center'>
										<h2 className='mb-0'>VAULT &trade;</h2>
									</Col>

									{userPermissions.projectLimit > 0 && (
										<Col
											xs={12}
											sm={3}
											className='d-flex align-items-center justify-content-end'
										>
											<ProjectUsageStatus />
										</Col>
									)}
								</Row>
								{userPermissions.canVaultProjects ? (
									<ProjectsTable
										setSelectedProjects={setSelectedProjects}
										projects={displayProjectRows}
									/>
								) : (
									<NoVaultAccess />
								)}
							</ProjectsTab>
						</PillsTabs>
					</div>
				</div>
			)}
		</>
	);
};

const NoVaultAccess = () => {
	return (
		<div
			className='d-flex justify-content-center flex-column align-items-center'
			style={{ height: '30vh' }}
		>
			<div className='d-flex justify-content-center align-items-center'>
				<i className='fas fa-lock px-4' style={{ fontSize: '2.5rem' }} />
				<h3 style={{ width: '30vw' }}>
					Access to the Vault &trade; is just a step away! Upgrade your
					subscription now to unlock this exclusive feature.
				</h3>
			</div>
			<Button
				label='Upgrade Subscription'
				leftIcon='fas fa-angle-double-up'
				className='mt-3'
				onClick={() => openUpgradeWebsite()}
				theme='dark'
			/>
		</div>
	);
};

export default Projects;
