import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import './PlaylistStats.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import Select from 'react-select';
import PlaylistStatsHeader from './PlaylistStatsHeader';
import {
	getCurrentDripStats,
	getCurrentPlaylistStats,
} from '../../../../store/playlists/selectors';
import {
	fetchDripStatAction,
	fetchPlaylistStatAction,
	generateDripStatsAIAnalysisAction,
} from '../../../../store/playlists/actions';
import SoundCreditLoader from '../../SoundCreditLoader';
import PlaylistStatMap from './PlaylistStatsMap';
import PlaylistStatsRanking from './PlaylistStatsRanking';
import { getCurrentPlaylist } from './../../../../store/playlists/selectors';
import PlaylistStatsChart from './PlaylistStatsChart';

// import html2pdf from 'html2pdf.js';
import { replacePathVariables } from '../../../../helpers/routeTools';
import ROUTES from '../../../../router/routes';
import Button from '../../../layout/Button/Button';
import PillButton from '../../../layout/PillButton';
import { Helmet } from 'react-helmet';
import { getFileProject } from '../../../../helpers/fileTools';

const PlaylistStats = ({ isDrip = false }) => {
	const pdfPrintRef = useRef(null);
	const dispatch = useDispatch();
	const params = useParams();

	const currentPlaylistId = useMemo(() => {
		const { playlistId } = params;

		return playlistId ? parseInt(playlistId) : null;
	}, [params]);

	const currentPlaylistStat = useSelector(
		isDrip ? getCurrentDripStats : getCurrentPlaylistStats
	);
	const currentPlaylist = useSelector(getCurrentPlaylist);

	const [isGeneratingAnalysis, setIsGeneratingAnalysis] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	// const [isPrinting, setIsPrinting] = useState(false);
	// const [pdfRect, setPdfRect] = useState({
	// 	width: 0,
	// 	height: 0,
	// });

	const selectMusicOptions = useMemo(
		() => [
			{ value: null, label: 'All Tracks' },
			...(currentPlaylist?.playlist?.files ?? []).map(file => {
				return { value: file.id, label: getFileProject(file).title };
			}),
		],
		[currentPlaylist]
	);

	const [selectedMusic, setSelectedMusic] = useState(selectMusicOptions[0]);

	// const handlePrint = async () => {
	// 	try {
	// 		setIsPrinting(true);
	// 	} catch (error) {
	// 		console.log(error);
	// 	} finally {
	// 		setIsPrinting(false);
	// 	}
	// };

	const dripEnded = useMemo(() => {
		if (!isDrip || !currentPlaylist?.playlist?.dripEnd) {
			return false;
		}

		return new Date() > new Date(currentPlaylist.playlist.dripEnd);
	}, [currentPlaylist, isDrip]);

	const rankingData = useMemo(() => {
		if (!currentPlaylistStat?.data) {
			return {
				country: [],
				city: [],
			};
		}

		if (!selectedMusic.value) {
			return currentPlaylistStat.data.locationRankings.overall;
		}

		return currentPlaylistStat.data.locationRankings.byFile[
			selectedMusic.value
		];
	}, [currentPlaylistStat, selectedMusic]);

	const generateAnalysis = useCallback(async () => {
		if (!dripEnded) {
			return;
		}

		setIsGeneratingAnalysis(true);

		try {
			await dispatch(generateDripStatsAIAnalysisAction(currentPlaylistId));
		} catch (error) {
			console.error(error);
		} finally {
			setIsGeneratingAnalysis(false);
		}
	}, [currentPlaylistId, dispatch, dripEnded]);

	const fetchStats = useCallback(async () => {
		// always fetch playlist on mount, or force fetch if playlist is not loaded
		try {
			setIsLoading(true);
			await Promise.resolve(
				dispatch(
					(isDrip ? fetchDripStatAction : fetchPlaylistStatAction)(
						currentPlaylistId
					)
				)
			);
		} finally {
			setIsLoading(false);
		}
	}, [currentPlaylistId, dispatch, isDrip]);

	useEffect(() => {
		fetchStats();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// const handleResize = () => {
	// 	if (pdfPrintRef.current) {
	// 		setPdfRect({
	// 			width: pdfPrintRef.current.offsetWidth,
	// 			height: pdfPrintRef.current.offsetHeight,
	// 		});
	// 	}
	// };

	// useEffect(() => {
	// 	window.addEventListener('resize', handleResize);

	// 	const timerId = setTimeout(() => handleResize(), 100);

	// 	return () => {
	// 		window.removeEventListener('resize', handleResize);
	// 		clearTimeout(timerId);
	// 	};
	// }, []);

	return !currentPlaylistStat?.data || !currentPlaylistStat?.data ? (
		<SoundCreditLoader />
	) : (
		<>
			<Helmet>
				<title>
					{currentPlaylist?.playlist?.name ?? (isDrip ? 'Drip' : 'Playlist')}{' '}
					Stats {process.env.REACT_APP_TAB_TITLE}
				</title>
			</Helmet>
			<div className='p-5' ref={pdfPrintRef}>
				<div className='w-100 d-flex justify-content-between align-items-center'>
					<Link
						to={replacePathVariables(ROUTES.PlaylistDetails.path, {
							playlistId: currentPlaylistId,
						})}
						className='back-to-playlist__link'
					>
						<i className='fas fa-arrow-left mr-2'></i>
						<span>Back to Playlist</span>
					</Link>

					<PillButton
						label='Refresh'
						leftIcon='fas fa-sync-alt'
						onClick={fetchStats}
						isLoading={isLoading}
					/>
				</div>

				<Card
					className='p-4 playlist-stats-header__container'
					style={{ width: '100%' }}
				>
					<PlaylistStatsHeader isDrip={isDrip} />
					{/* <div className='print-btn__container'>
					<ReactToPdf
						targetRef={pdfPrintRef}
						x={0}
						y={0}
						options={{
							unit: 'px',
							format: [pdfRect.width / 1.8, pdfRect.height / 1.8],
						}}
					>
						{({ toPdf }) => (
							<Button
								label='Print'
								leftIcon='fas fa-print'
								onClick={toPdf}
								isLoading={isPrinting}
								isDisabled={!pdfRect.width || !pdfRect.height}
							/>
						)}
					</ReactToPdf>
				</div> */}
				</Card>
				<Card className='p-4'>
					<PlaylistStatMap isDrip={isDrip} />
				</Card>

				{isDrip && (
					<Card className='p-4'>
						<div className='stats-card-title'>
							<i className='fas fa-chart-bar'></i>
							<div>
								<h3>AI Analysis</h3>
							</div>
						</div>
						<div className='drip-ai-analysis__container mt-2'>
							{currentPlaylist?.playlist?.aiAnalysis ? (
								<span
									className='drip-ai-analysis__text'
									style={{
										'--ai-analysis-n':
											currentPlaylist?.playlist?.aiAnalysis?.length,
									}}
								>
									{currentPlaylist?.playlist?.aiAnalysis}
								</span>
							) : (
								<div className='drip-ai-analysis-placeholder__content'>
									{!dripEnded && (
										<p className='drip-ai-analysis-placeholder__text'>
											Available after this Drip is complete
										</p>
									)}
									<Button
										label='Generate'
										leftIcon='fas fa-robot'
										onClick={generateAnalysis}
										isLoading={isGeneratingAnalysis}
										isDisabled={!dripEnded}
									/>
								</div>
							)}
						</div>
					</Card>
				)}
				<Card className='p-4'>
					<PlaylistStatsChart
						isDrip={isDrip}
						dataKey='plays'
						title='Plays Per Track'
					/>
				</Card>
				<Card className='p-4'>
					<PlaylistStatsChart
						isDrip={isDrip}
						dataKey='uniqueListeners'
						title='Unique Listeners Per Track'
					/>
				</Card>
				<Card className='p-4'>
					<PlaylistStatsChart
						isDrip={isDrip}
						dataKey='averageDuration'
						title='Average Play Duration Per Track'
						isTime={true}
					/>
				</Card>
				{!isDrip && (
					<Card className='p-4'>
						<PlaylistStatsChart
							isDrip={isDrip}
							dataKey='downloads'
							title='Downloads Per Track'
						/>
					</Card>
				)}

				{isDrip && (
					<Card className='p-4'>
						<PlaylistStatsChart
							isDrip={isDrip}
							dataKey='likes'
							title='Likes Per Track'
						/>
					</Card>
				)}
				{/* <Card className='p-4'>
				<PlaylistPieChart
					isDrip={isDrip}
					dataKey='plays'
					title='Plays Per Track'
				/>
			</Card>
			<Card className='p-4'>
				<PlaylistPieChart
					isDrip={isDrip}
					dataKey='averageDuration'
					title='Average Play Duration Per Track'
				/>
			</Card> */}
				<Card className='p-4 playlist-stats-ranking'>
					<div className='select-group-title__container'>
						<div className='select-group-title'>
							<i className='fas fa-headphones-alt'></i>
							<div>
								<h3>Play Locations</h3>
							</div>
						</div>
						<Select
							defaultValue={selectedMusic}
							onChange={setSelectedMusic}
							options={selectMusicOptions}
							classNamePrefix='music-select'
							maxMenuHeight={200}
							theme={theme => ({
								...theme,
								colors: {
									...theme.colors,
									primary: '#8500d1',
									primary50: '#8500d170',
									primary25: '#8500d138',
								},
							})}
						/>
					</div>

					<Card className='p-4'>
						<PlaylistStatsRanking type='Country' data={rankingData?.country} />
					</Card>
					<Card className='p-4'>
						<PlaylistStatsRanking type='City' data={rankingData?.city} />
					</Card>
				</Card>

				{/* <Card className='p-4 playlist-stats-chart__container'>
				<PlaylistStatsChart />
			</Card> */}
			</div>
		</>
	);
};

export default PlaylistStats;
