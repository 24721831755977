import Notification from '../Notification';
import './NotificationList.scss';
import Button from '../Button/Button';
import React, { useMemo } from 'react';
import { Dropdown, Spinner } from 'react-bootstrap';
import { showAlertToast } from '../../../store/alertToast/actions';
import { deleteNotificationsAction } from '../../../store/user/actions';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

export type NotificationListProps = {
	show: boolean;
	toggleShow: () => void;
};

const NotificationList = ({ show, toggleShow }: NotificationListProps) => {
	const { notifications } = useAppSelector(state => state.user);
	const dispatch = useAppDispatch();

	const notificationsSortedByRead = useMemo(() => {
		if (!notifications) return null;

		return notifications.sort((a, b) => {
			if (a.read && !b.read) return 1;
			if (!a.read && b.read) return -1;
			return 0;
		});
	}, [notifications]);

	const readNotificationCount = useMemo(() => {
		if (!notifications) return 0;

		return notifications.filter(notification => notification.read).length;
	}, [notifications]);

	const clearReadNotifications = async (
		e: React.MouseEvent<Element, MouseEvent>
	) => {
		try {
			e.stopPropagation();

			if (!notifications || !notifications.length) {
				dispatch(showAlertToast('There are no notifications to clear.'));
				return;
			}

			const readNotifications = notifications?.filter(
				notification => notification.read
			);

			await Promise.resolve(
				dispatch(
					deleteNotificationsAction(
						readNotifications?.map(notification => notification.uuid)
					)
				)
			);
		} catch (error) {
			console.log(error);
		}
	};

	if (!notificationsSortedByRead)
		return (
			<Dropdown
				className='notifications-container'
				onToggle={toggleShow}
				show={show}
			>
				<Dropdown.Menu align='end' style={{ marginRight: '20vh' }}>
					<div
						style={{ width: '40vh', height: '20vh' }}
						className='d-flex justify-content-center align-items-center flex-column'
					>
						<Spinner animation='border' />
						<p className='mb-0 mt-2' style={{ fontSize: '0.9rem' }}>
							Loading Notifications...
						</p>
					</div>
				</Dropdown.Menu>
			</Dropdown>
		);

	if (notificationsSortedByRead.length > 0)
		return (
			<Dropdown
				className='notifications-container'
				onToggle={toggleShow}
				show={show}
			>
				<Dropdown.Menu align='end' style={{ marginRight: '20vh' }}>
					<div className='w-100 text-center notifications-clear-btn notifications-header'>
						<Button
							label='Clear Read Notifications'
							onClick={clearReadNotifications}
							className='clear-notifications-button'
							isDisabled={readNotificationCount === 0}
						/>
					</div>
					<div className='notifications-list'>
						{notifications!.map((item, i) => (
							<div key={`notification-${i}`}>
								<Notification notification={item} />
								{i !== notifications!.length - 1 ? (
									<div className='dropdown-divider mb-2' />
								) : null}
							</div>
						))}
					</div>
				</Dropdown.Menu>
			</Dropdown>
		);

	return (
		<Dropdown
			className='notifications-container'
			onToggle={toggleShow}
			show={show}
		>
			<Dropdown.Menu align='end' style={{ marginRight: '20vh' }}>
				<div className='text-secondary text-bold no-notifications-container'>
					You don't have any notifications
				</div>
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default NotificationList;
