import { Button } from 'primereact/button';
import { Spinner } from 'react-bootstrap';
import {
	setPlayerFileSrcAction,
	setPlayerTrackDataAction,
} from '../../../../store/player/actions';
import { Menu } from 'primereact/menu';
import { getFileProject, isAudioFile } from '../../../../helpers/fileTools';
import OptionalLink from '../../OptionalLink';
import { replacePathVariables } from '../../../../helpers/routeTools';
import Image from '../../Image';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useEffect, useMemo, useRef, useState } from 'react';
import ROUTES from '../../../../router/routes';
import styles from '../AudioPlayerBar.module.scss';
import FallbackSongThumbnail from '../../FallbackSongThumbnail';
import clsx from 'clsx';
import useWindowSize from '../../../../hooks/useWindowSize';
import { getCurrentPlaylist } from '@/store/playlists/selectors';

interface AudioPlayerMetadataProps {
	file?: PlaylistFileMetadata | null;
}

const THUMBNAIL_SIZE = '3.5rem';

const AudioPlayerMetadata = ({ file }: AudioPlayerMetadataProps) => {
	const dispatch = useAppDispatch();
	const { playlistId } = useAppSelector(state => state.player);
	const { albumsById } = useAppSelector(state => state.projects);
	const currentPlaylist = useAppSelector(getCurrentPlaylist);
	const { currentTrackVersionNumber } = useAppSelector(state => state.player);
	const project = file ? getFileProject(file) : null;
	const isRecordingFile = Boolean(file?.recordingId);
	const isAlbumFile = Boolean(file?.albumId);
	const firstAlbumRecordingId = useMemo(
		() =>
			(isAlbumFile &&
				project?.id &&
				Object.values(albumsById![project?.id].recordings)[0]) ||
			null,
		[albumsById, project, isAlbumFile]
	);
	const canLinkToRecording = isRecordingFile || Boolean(firstAlbumRecordingId);
	const versionSelectorOverlay = useRef<Menu>(null);
	const [isLoadingVersion, setIsLoadingVersion] = useState<boolean>(false);
	const titleRef = useRef<HTMLSpanElement>(null);
	const containerRef = useRef<HTMLDivElement>(null);
	const [screenWidth] = useWindowSize();

	useEffect(() => {
		// animate title if it overflows
		if (titleRef.current && containerRef.current) {
			const title = titleRef.current;
			const container = containerRef.current;
			const titleWidth = title.getBoundingClientRect().width;
			const containerWidth = container.getBoundingClientRect().width!;

			if (titleWidth > containerWidth) {
				title.classList.add(styles['animate']);
			} else {
				title.classList.remove(styles['animate']);
				// title.style.animation = 'none';
			}
		}
	}, [file, screenWidth]);

	return file && project ? (
		<div className={styles['current-audio-wrapper']}>
			<Link
				to={replacePathVariables(ROUTES.PlaylistDetails.path, {
					playlistId,
				})}
			>
				{file.coverUrl || currentPlaylist?.playlist?.coverUrl ? (
					<Image
						width={THUMBNAIL_SIZE}
						height={THUMBNAIL_SIZE}
						src={
							file.coverUrl ??
							currentPlaylist?.playlist?.coverUrl ??
							'img/audio-grey.svg'
						}
						alt='song artwork'
					/>
				) : (
					<FallbackSongThumbnail
						width={THUMBNAIL_SIZE}
						height={THUMBNAIL_SIZE}
					/>
				)}
			</Link>

			<div ref={containerRef} className={styles['current-audio-metadata']}>
				<OptionalLink
					to={
						isRecordingFile || (isAlbumFile && firstAlbumRecordingId)
							? replacePathVariables(ROUTES.EditRecording.path, {
									recordingId: isRecordingFile
										? project.id
										: firstAlbumRecordingId!,
							  })
							: null
					}
					className={styles['title-container']}
				>
					<span
						className={clsx(styles['title'], {
							[styles['clickable']]: canLinkToRecording,
						})}
						ref={titleRef}
					>
						{project?.title ?? 'Unknown Title'}
					</span>
					{/* <div className={clsx(styles['fader'], styles['fader-left'])} /> */}
					{/* <div className={clsx(styles['fader'], styles['fader-right'])} /> */}
				</OptionalLink>

				<span className={styles['artist']}>
					{project.artist ?? 'Unknown Artist'}
				</span>

				<Menu
					style={{
						width: '6rem',
					}}
					ref={versionSelectorOverlay}
					popup
					model={Object.values(file.versions).map((version, index) => ({
						disabled: !isAudioFile(version.filename),
						icon:
							version.versionNo === currentTrackVersionNumber
								? 'fas fa-check'
								: undefined,
						label: `V${version.versionNo}`,
						data: version.id,
						command: async c => {
							Promise.resolve(
								versionSelectorOverlay.current?.hide(c.originalEvent)
							).then(async () => {
								if (version.versionNo === currentTrackVersionNumber) {
									return;
								}

								try {
									setIsLoadingVersion(true);
									dispatch(setPlayerFileSrcAction(null));
									await dispatch(
										setPlayerTrackDataAction(file, false, c.item.data)
									);
								} finally {
									setIsLoadingVersion(false);
								}
							});
						},
					}))}
				>
					<div className={styles['version-selector']}>test</div>
				</Menu>
				<Button
					label={`V${currentTrackVersionNumber}`}
					className={styles['version']}
					onClick={e => versionSelectorOverlay.current?.toggle(e)}
					loading={isLoadingVersion}
					loadingIcon={<Spinner animation='border' size='sm' />}
					icon={isLoadingVersion ? undefined : 'fas fa-history'}
					text
					outlined
				/>
			</div>
		</div>
	) : (
		<div />
	);
};

export default AudioPlayerMetadata;
