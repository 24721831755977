import _, { isArray } from 'lodash';
import { containsWriterRoles, isProfileEqual } from './participantTools';
import {
	getAllAssociatedStudios,
	getParticipantAssociatedStudios,
	mergeParticipantAndAssociatedRoles,
	mergeParticipantAndAssociatedStudios,
} from './studioTools';
import { searchCloudProfilesByTerm } from '../api/services/cloudProfilesService';
import generateRandomId from './generateRandomId';
import {
	createFullPublisherFromFormPublisher,
	createParticipantPublisher,
	createPublisher,
	flattenPublishers,
	getUniqueAssociatedPublishers,
	isPublisherEqual,
} from './publisherTools';
import {
	formatDateToApi,
	formatDateToHtml,
} from '../components/utils/dateFormatters';
import { initialProfileForm, initialFullProfile } from '../constants/profile';
import { convertOtherAliasesToString } from './participantTools';
import { printLocation } from './addressTools';
import { v4 as uuidv4 } from 'uuid';
import { uniqBy } from 'lodash';

export const translateApiPersonalProfileToLocal = (
	apiProfile: LocalProfileDTO
) => {
	const localProfile: LocalProfile = {
		publishers: apiProfile.publishers,
		roles: apiProfile.roles,
		id: apiProfile.profileId,
		isni: apiProfile.isni,
		creditedName: apiProfile.name,
		isCreatorID: false,
		profile: null,
	};

	return localProfile;
};

const isProfileMissingAssociatedPublishers = (profile: FullLocalProfile) => {
	return profile.publishers.some(
		publisher =>
			!profile.associatedPublishers.find(ap => ap?.id === publisher.publisherId)
	);
};

const sanitizeApiProfilePublishers = (profile: FullLocalProfile) => {
	const fixedAssociatedPublishers = computeProfileAssociatedPublishers(profile);
	const fixedPublishers = deleteUnnecessaryProfilePublisherData(
		profile!.publishers
	);

	return {
		fixedAssociatedPublishers,
		fixedPublishers,
	};
};

const isProfileMissingAssociatedStudios = (profile: FullLocalProfile) => {
	return profile.roles.some(
		role =>
			!profile.associatedStudios.find(as => as?.id === role?.studio?.studioId)
	);
};

const sanitizeRole = (role: any): LocalProfileRole => {
	let studio: ParticipantStudio | null = role.studio ?? null;

	if ('studios' in role) {
		studio = role.studios?.[0] ?? null;
	}

	if (_.isEmpty(studio)) {
		studio = null;
	}

	const sanitizedStudio = studio
		? {
				studioId: studio.studioId,
				label: studio?.label ?? '',
				sessionType: studio?.sessionType ?? null,
		  }
		: null;

	return {
		category: role.category ?? '',
		detail: role.detail ?? '',
		studio: sanitizedStudio,
	};
};

const sanitizeOtherAliases = (otherAliases: any) => {
	let sanitized: string | null = null;

	if (typeof otherAliases === 'string') {
		sanitized = otherAliases;
	}

	if (isArray(otherAliases) && otherAliases.every(a => typeof a === 'string')) {
		sanitized = otherAliases.join(', ');
	}

	return sanitized ?? '';
};

// * Used for sanitizing all local profiles fetched from the API
export const translateApiFullPersonalProfileToLocal = (
	apiProfile: FullLocalProfile,
	profileId?: string
) => {
	if (!apiProfile.id && !profileId) {
		throw new Error(
			'Cannot translate full profile without an ID. Please provide a profile ID.'
		);
	}

	const fullLocalProfile = {
		...createNewProfile(apiProfile),
		id: profileId!, // some profiles don't have an ID or have the wrong ID
		dateOfBirth: formatDateToHtml(apiProfile.dateOfBirth),
	};

	// DATA INTEGRITY CHECKS
	fullLocalProfile.otherAliases = sanitizeOtherAliases(
		fullLocalProfile.otherAliases
	);

	if (isProfileMissingAssociatedPublishers(fullLocalProfile)) {
		const { fixedAssociatedPublishers, fixedPublishers } =
			sanitizeApiProfilePublishers(fullLocalProfile);

		fullLocalProfile.associatedPublishers = fixedAssociatedPublishers;
		fullLocalProfile.publishers = fixedPublishers;
	}

	// some profiles have an array for studios called "studios", they need to be converted to an object called "studio"
	fullLocalProfile.roles = fullLocalProfile.roles?.map(sanitizeRole);

	if (isProfileMissingAssociatedStudios(fullLocalProfile)) {
		// recompute studio IDs
		fullLocalProfile.associatedStudios = mergeParticipantAndAssociatedStudios(
			[],
			getAllAssociatedStudios(fullLocalProfile)
		);

		const oldAssociatedStudios = getAllAssociatedStudios(fullLocalProfile); // extract studios with old IDs from roles

		// reassign new studio IDs to roles
		fullLocalProfile.roles = mergeParticipantAndAssociatedRoles(
			fullLocalProfile.associatedStudios,
			oldAssociatedStudios,
			fullLocalProfile.roles
		);
	}

	return createProfileFromFullProfile(fullLocalProfile);
};

export const deleteUnnecessaryProfilePublisherData = (
	publishers: ProfileFormPublisher[] | LocalProfilePublisher[]
): LocalProfilePublisher[] =>
	publishers.map(formPublisher => ({
		publisherId: formPublisher.publisherId,
		territory: formPublisher.territory,
		subPublishers: formPublisher.subPublishers?.map(formSubPublisher => ({
			publisherId: formSubPublisher.publisherId,
			territory: formSubPublisher.territory,
		})),
	}));

export const translateLocalFullPersonalProfileToApi = <
	ProfileType extends FullLocalProfile | NewFullLocalProfile
>(
	fullProfile: ProfileType
) => {
	const apiProfile: ProfileType = {
		...fullProfile,
		dateOfBirth: fullProfile.dateOfBirth
			? formatDateToApi(fullProfile.dateOfBirth)
			: '',
	};

	return apiProfile;
};

export const computeProfileAssociatedPublishers = (
	profile: FullLocalProfile
): LocalProfileAssociatedPublisher[] =>
	flattenPublishers(profile.publishers).map(p => ({
		id:
			typeof p.publisherId !== 'number'
				? parseInt(p.publisherId)
				: p.publisherId,
		name: '',
		pro: '',
		email: '',
		ipi: '',
	}));

export const createNewProfileFromParticipant = ({
	participant,
	recordingPublishers,
	recordingStudios,
	removeStudios = false,
}: {
	participant: Participant;
	recordingPublishers: RecordingPublisher[];
	recordingStudios: RecordingStudio[];
	removeStudios?: boolean;
}): NewFullLocalProfile => {
	// First delete unused publisher fields that don't belong in a profile
	const profilePublishers: LocalProfilePublisher[] = participant.publishers.map(
		pub => ({
			publisherId: pub.publisherId,
			territory: pub.territory,
			subPublishers: pub.subPublishers?.length
				? pub.subPublishers.map(s => ({
						publisherId: s.publisherId,
						territory: s.territory,
				  }))
				: [],
		})
	);

	const participantPublishers = flattenPublishers(participant.publishers);

	// Although in many other places this comparison is done with isPublisherEqual,
	// doing it by ID here makes sense since the participant publishers IDs weren't
	// modified when they were added to the recording publishers
	// We also need a deep clone because the participant publishers will be modified later

	const newAssociatedPublishers = _.cloneDeep(
		participantPublishers
			.map(p =>
				recordingPublishers.find(
					fullPublisher => p.publisherId === fullPublisher.id
				)
			)
			.filter((p): p is RecordingPublisher => p != null)
	);

	const newAssociatedStudios = removeStudios
		? []
		: getParticipantAssociatedStudios(participant.roles, recordingStudios);

	const profileRoles = participant.roles.map(
		r =>
			({
				category: r.category,
				detail: r.detail,
				studio: removeStudios
					? null
					: r.studio
					? ({
							studioId: r.studio.studioId,
							label: r.studio.label ?? null,
							sessionType: r.studio.sessionType ?? null,
					  } as ParticipantStudio)
					: null,
			} as LocalProfileRole)
	);

	return createNewProfile({
		creditedName: participant.creditedName,
		associatedPublishers: newAssociatedPublishers,
		associatedStudios: newAssociatedStudios,
		roles: profileRoles,
		publishers: profilePublishers,
		email: participant.email,
		isni: participant.isni,
		type: participant.type,
		city: participant.city,
		country: participant.country,
		ipiCae: participant.ipiCae,
		otherAliases: participant.otherAliases,
		postalCode: participant.postalCode,
		isSavedProfile: true,
		isCopyrightOwner: participant.isCopyrightOwner,
		socialLastFour: participant.socialLastFour,
		dateOfBirth: participant.dateOfBirth,
		isFeatured: participant.isFeatured,
		state: participant.state,
		legalName: participant.legalName,
		notes: participant.notes,
		address1: participant.address1,
		address2: participant.address2,
		ipn: participant.ipn,
		partDropped: participant.partDropped,
		pro: participant.pro,
		phone: participant.phone,
		copyrightOwnerClaim: {
			cmo: participant.copyrightOwnerClaim?.cmo,
			cmoId: participant.copyrightOwnerClaim?.cmoId,
		},
	});
};

export const createNewProfileForm = (
	values?: Partial<ProfileForm>
): ProfileForm => ({
	id: values?.id ?? undefined,
	creditedName: values?.creditedName ?? initialProfileForm.creditedName,
	associatedPublishers:
		values?.associatedPublishers?.map(publisher => ({
			id: publisher.id,
			name: publisher.name ?? '',
			pro: publisher.pro ?? '',
			ipi: publisher.ipi ?? '',
			email: publisher.email ?? '',
		})) ?? initialProfileForm.associatedPublishers,
	associatedStudios:
		values?.associatedStudios?.map(studio => ({
			id: studio.id,
			name: studio.name ?? '',
		})) ?? initialProfileForm.associatedStudios,
	roles:
		values?.roles?.map(role => ({
			category: role.category ?? '',
			detail: role.detail ?? '',
			studio: role.studio
				? {
						studioId: role.studio.studioId,
						label: role.studio.label ?? null,
						sessionType: role.studio.sessionType ?? null,
				  }
				: null,
		})) ?? initialProfileForm.roles,
	publishers:
		values?.publishers?.map(publisher => ({
			publisherId: publisher.publisherId,
			territory: publisher.territory ?? [],
			pro: publisher.pro ?? '',
			name: publisher.name ?? '',
			ipi: publisher.ipi ?? '',
			email: publisher.email ?? '',
			subPublishers:
				publisher.subPublishers?.map(subPublisher => ({
					publisherId: subPublisher.publisherId,
					territory: subPublisher.territory ?? [],
					pro: subPublisher.pro ?? '',
					name: subPublisher.name ?? '',
					ipi: subPublisher.ipi ?? '',
					email: subPublisher.email ?? '',
				})) ?? [],
		})) ?? initialProfileForm.publishers,
	email: values?.email ?? initialProfileForm.email,
	isni: values?.isni ?? initialProfileForm.isni,
	type: values?.type ?? initialProfileForm.type,
	city: values?.city ?? initialProfileForm.city,
	country: values?.country ?? initialProfileForm.country,
	ipiCae: values?.ipiCae ?? initialProfileForm.ipiCae,
	otherAliases: values?.otherAliases ?? initialProfileForm.otherAliases,
	postalCode: values?.postalCode ?? initialProfileForm.postalCode,
	isSavedProfile: values?.isSavedProfile ?? initialProfileForm.isSavedProfile,
	isCopyrightOwner:
		values?.isCopyrightOwner ?? initialProfileForm.isCopyrightOwner,
	socialLastFour: values?.socialLastFour ?? initialProfileForm.socialLastFour,
	dateOfBirth: values?.dateOfBirth ?? initialProfileForm.dateOfBirth,
	isFeatured: values?.isFeatured ?? initialProfileForm.isFeatured,
	state: values?.state ?? initialProfileForm.state,
	legalName: values?.legalName ?? initialProfileForm.legalName,
	notes: values?.notes ?? initialProfileForm.notes,
	address1: values?.address1 ?? initialProfileForm.address1,
	address2: values?.address2 ?? initialProfileForm.address2,
	ipn: values?.ipn ?? initialProfileForm.ipn,
	partDropped: values?.partDropped ?? initialProfileForm.partDropped,
	pro: values?.pro ?? initialProfileForm.pro,
	phone: values?.phone ?? initialProfileForm.phone,
	copyrightOwnerClaim: {
		cmo:
			values?.copyrightOwnerClaim?.cmo ??
			initialProfileForm.copyrightOwnerClaim?.cmo,
		cmoId:
			values?.copyrightOwnerClaim?.cmoId ??
			initialProfileForm.copyrightOwnerClaim?.cmoId,
	},
});

export const createNewProfile = (
	values?: Partial<FullLocalProfile>
): NewFullLocalProfile => ({
	creditedName: values?.creditedName ?? initialFullProfile.creditedName,
	associatedPublishers:
		values?.associatedPublishers?.map((publisher: any) => ({
			id: publisher.id,
			name: publisher.name ?? '',
			pro: publisher.pro ?? '',
			ipi: publisher.ipi ?? '',
			email: publisher.email ?? '',
		})) ?? initialFullProfile.associatedPublishers,
	associatedStudios:
		values?.associatedStudios?.map((studio: any) => ({
			id: studio.id,
			name: studio.name ?? '',
		})) ?? initialFullProfile.associatedStudios,
	roles:
		values?.roles?.map((role: any) => ({
			category: role.category ?? '',
			detail: role.detail ?? '',
			studio: role.studio
				? {
						studioId: role.studio.studioId,
						label: role.studio.label ?? null,
						sessionType: role.studio.sessionType ?? null,
				  }
				: null,
		})) ?? initialFullProfile.roles,
	publishers:
		values?.publishers?.map((publisher: any) => ({
			publisherId: publisher.publisherId,
			territory: publisher.territory ?? [],
			subPublishers:
				publisher.subPublishers?.map((subPublisher: any) => ({
					publisherId: subPublisher.publisherId,
					territory: subPublisher.territory ?? [],
				})) ?? [],
		})) ?? initialFullProfile.publishers,
	email: values?.email ?? initialFullProfile.email,
	isni: values?.isni ?? initialFullProfile.isni,
	type: values?.type ?? initialFullProfile.type,
	city: values?.city ?? initialFullProfile.city,
	country: values?.country ?? initialFullProfile.country,
	ipiCae: values?.ipiCae ?? initialFullProfile.ipiCae,
	otherAliases: values?.otherAliases ?? initialFullProfile.otherAliases,
	postalCode: values?.postalCode ?? initialFullProfile.postalCode,
	isSavedProfile: values?.isSavedProfile ?? initialFullProfile.isSavedProfile,
	isCopyrightOwner:
		values?.isCopyrightOwner ?? initialFullProfile.isCopyrightOwner,
	socialLastFour: values?.socialLastFour ?? initialFullProfile.socialLastFour,
	dateOfBirth: values?.dateOfBirth ?? initialFullProfile.dateOfBirth,
	isFeatured: values?.isFeatured ?? initialFullProfile.isFeatured,
	state: values?.state ?? initialFullProfile.state,
	legalName: values?.legalName ?? initialFullProfile.legalName,
	notes: values?.notes ?? initialFullProfile.notes,
	address1: values?.address1 ?? initialFullProfile.address1,
	address2: values?.address2 ?? initialFullProfile.address2,
	ipn: values?.ipn ?? initialFullProfile.ipn,
	partDropped: values?.partDropped ?? initialFullProfile.partDropped,
	pro: values?.pro ?? initialFullProfile.pro,
	phone: values?.phone ?? initialFullProfile.phone,
	copyrightOwnerClaim: {
		cmo:
			values?.copyrightOwnerClaim?.cmo ??
			initialFullProfile.copyrightOwnerClaim?.cmo,
		cmoId:
			values?.copyrightOwnerClaim?.cmoId ??
			initialFullProfile.copyrightOwnerClaim?.cmoId,
	},
});

export const translateCloudProfilePublishers = (
	cloudPublishers: LocalProfileDTOPublisher[]
): {
	associatedPublishers: LocalProfileAssociatedPublisher[];
	publishers: LocalProfilePublisher[];
} => {
	const associatedPublishers = cloudPublishers.map(p =>
		createPublisher({
			...p,
			id: generateRandomId(),
		})
	);

	const publishers = associatedPublishers.map(ap =>
		createParticipantPublisher(ap)
	);

	return {
		associatedPublishers,
		publishers,
	};
};

export const translateCloudProfileSearchResultToLocal = (
	cloudProfile: CloudProfileSearchResult
): LocalProfile => {
	const localProfile = createProfileFromFullProfile(
		{
			id: cloudProfile.id,
			...createNewProfile({
				creditedName: cloudProfile.name,
				isni: cloudProfile.isni ?? '',
			}),
		},
		true
	);

	// delete other fields that are not needed

	return localProfile;
};

/**
 *
 * @note Credited Name should be legal name if first_alias_is_primary is false and/or if there are no aliases.
 * If there are aliases, and first_alias_is_primary, Credited Name should be the first alias by default.
 * Where there are any aliases which would not be primary, there should be a modal after pulling the Creator ID
 * asking with which name the profile should be credited, with the primary name (legal or first alias) being pre-selected
 * This case will be picked up whenever the creditedName is empty
 */
export const computeCreatorIdCreditedName = (cloudProfile: CloudProfile) => {
	if (!cloudProfile.aliases?.length || !cloudProfile.firstAliasIsPrimary) {
		return cloudProfile.legalName ?? '';
	}

	if (cloudProfile.firstAliasIsPrimary) {
		return cloudProfile.aliases[0];
	}

	return '';
};

export const translateCloudProfileToLocal = (
	cloudProfile: CloudProfile
): LocalProfile => {
	const isWriter = containsWriterRoles(cloudProfile.roles);

	const { associatedPublishers, publishers } = translateCloudProfilePublishers(
		cloudProfile.publishers ?? []
	);

	return createProfileFromFullProfile(
		{
			id: cloudProfile.id,
			...createNewProfile({
				isni: cloudProfile.isni,
				otherAliases: convertOtherAliasesToString(cloudProfile.aliases),
				country: cloudProfile.country,
				legalName: cloudProfile.legalName ?? '',
				publishers: isWriter ? publishers : [],
				associatedPublishers: isWriter ? associatedPublishers : [],
				roles: cloudProfile.roles.map(r => ({
					category: r.category,
					detail: r.detail,
					studio: null,
				})),
				creditedName: computeCreatorIdCreditedName(cloudProfile),
			}),
		},
		true
	);
};

export const deleteUnnecessaryCloudProfileFields = (
	cloudProfile: CloudProfile & {
		isCloudProfile: boolean;
	}
) => _.omit(cloudProfile, ['imagePath', 'isCloudProfile']);

export const createProfileSelectOptionFromLocalProfile = (
	profile: LocalProfile,
	disabled: boolean = false
): ProfileSelectOption => ({
	name: profile.creditedName,
	id: profile.id,
	imagePath: null,
	isni: profile.isni ?? null,
	isCreatorID: false,
	location:
		printLocation({
			city: profile.profile?.city,
			country: profile.profile?.country,
		}) ?? null,
	roles: profile.roles.map(r => r.detail),
	disabled,
});

export const createProfileSelectOptionFromCloudProfileSearch = (
	profile: CloudProfileSearchResult,
	disabled: boolean = false
): ProfileSelectOption => ({
	name: profile.name,
	id: profile.id,
	imagePath: profile.imagePath,
	isni: profile.isni,
	location: profile.location,
	isCreatorID: true,
	disabled,
	roles: profile.roles ?? [],
});

export const fetchCloudProfileSuggestions = async (
	username: string,
	personalProfiles: LocalProfile[],
	controller: AbortController,
	recordingParticipants: Participant[] = []
) => {
	controller?.abort();

	if (!username) {
		return [
			{
				label: 'Saved Profiles',
				options: personalProfiles.map(profile =>
					createProfileSelectOptionFromLocalProfile(profile)
				),
			},
		];
	}

	const { data } = await searchCloudProfilesByTerm(username);
	let results = data.results
		.slice(0, 5)
		.map(result => createProfileSelectOptionFromCloudProfileSearch(result));

	if (results.length > 5) results = results.slice(0, 5);

	const regex = new RegExp(`${username}`, 'i');

	let newStoredProfiles = personalProfiles
		.filter(profile => profile.creditedName.match(regex))
		.filter(
			profile =>
				!recordingParticipants.find(participant =>
					isProfileEqual(profile, participant)
				)
		)
		.slice(0, 5)
		.map(profile => createProfileSelectOptionFromLocalProfile(profile));

	return [
		{
			label: 'Saved Profiles',
			options: newStoredProfiles,
		},
		{
			label: 'Creator IDs',
			options: results,
		},
	];
};

export const joinProfilePublishersWithAssociatedPublishers = (
	profilePublishers: LocalProfilePublisher[],
	associatedPublishers: LocalProfileAssociatedPublisher[]
): ProfileFormPublisher[] =>
	profilePublishers.map(profilePublisher => {
		const foundPublisher = associatedPublishers.find(
			recPublisher =>
				profilePublisher &&
				profilePublisher.publisherId &&
				recPublisher.id === profilePublisher.publisherId
		);

		if (!foundPublisher)
			throw new Error('Publisher not found in profile associated publishers', {
				profilePublisher,
				associatedPublishers,
			} as any);

		return {
			publisherId: foundPublisher.id,
			pro: foundPublisher.pro,
			name: foundPublisher.name,
			ipi: foundPublisher.ipi ?? '',
			email: foundPublisher.email ?? '',
			territory: profilePublisher.territory,
			subPublishers:
				profilePublisher.subPublishers?.map(profileSubP => {
					const subPublisherFound = associatedPublishers.find(
						recPublisher => recPublisher.id === profileSubP.publisherId
					);

					if (!subPublisherFound)
						throw new Error(
							'Sub-publisher not found in profile associated publishers',
							{ profileSubP, associatedPublishers } as any
						);

					return {
						publisherId: subPublisherFound.id,
						pro: subPublisherFound.pro,
						name: subPublisherFound.name,
						ipi: subPublisherFound.ipi ?? '',
						email: subPublisherFound.email ?? '',
						territory: profileSubP.territory,
					};
				}) ?? [],
		};
	});

export const unnecessaryProfileFields = [
	'isClaimed',
	'ssnLastFour',
	'associatedStudioName',
	'firstAliasIsPrimary',
	'associatedStudioCountry',
	'suggestionId',
	'imagePath',
	'isSavedProfile',
	'profileId',
	'aliases',
];

export const convertFullLocalProfileToProfileForm = (
	profile: FullLocalProfile
): ProfileForm => {
	const associatedPublishers = profile.associatedPublishers;

	const profileForm = createNewProfileForm({
		...profile,
		publishers: joinProfilePublishersWithAssociatedPublishers(
			profile.publishers,
			associatedPublishers
		),
	});

	if (!containsWriterRoles(profile.roles)) {
		profileForm.publishers = [];
		profileForm.ipiCae = '';
	}

	return profileForm;
};

/*
 * The profile form contains the full publisher data in the form.publishers field,
 * thus we use that data to create the associated publishers
 */
export const computeNewAssociatedPublishersFromProfileForm = (
	profileFormPublishers: ProfileFormPublisher[],
	oldAssociatedPublishers: LocalProfileAssociatedPublisher[] = []
) => {
	const allPublishers = flattenPublishers(profileFormPublishers);

	// If a publisher with the same ID already exists in the associated publishers, we need to check
	// if they're equal. If they are, then we merge them, otherwise we create a new one with a different ID

	const fullNewParticipantPublishers = allPublishers.map(p =>
		createFullPublisherFromFormPublisher<LocalProfileAssociatedPublisher>(p)
	);

	// Compute unique new publishers (remove duplicates and assign new IDs)
	const uniqueNewPublishers = getUniqueAssociatedPublishers(
		fullNewParticipantPublishers
	);

	return uniqueNewPublishers;
};

export const computeProfilePublishersFromProfileForm = (
	profileFormPublishers: ProfileFormPublisher[],
	newAssociatedPublishers: LocalProfileAssociatedPublisher[]
): LocalProfilePublisher[] =>
	profileFormPublishers.map(p => {
		const associatedPublisher = newAssociatedPublishers.find(np =>
			isPublisherEqual(p, np)
		);

		if (!associatedPublisher) {
			throw new Error('Associated publisher not found');
		}

		return {
			...p,
			publisherId: associatedPublisher.id,
		};
	});

/*
 * The inverse of convertFullLocalProfileToProfileForm
 */
export const convertProfileFormToFullLocalProfile = (
	profileForm: ProfileForm
): NewFullLocalProfile => {
	// ProfileForms are the same as FullLocalProfile, except for the publishers and the optional ID
	// We need to recompute associatedStudios and associatedPublishers

	const isWriter = containsWriterRoles(profileForm.roles);
	// This step creates new IDs for publishers that are equal in name and PRO but differ in IPI or email
	const newAssociatedPublishers = isWriter
		? computeNewAssociatedPublishersFromProfileForm(
				profileForm.publishers,
				profileForm.associatedPublishers
		  )
		: [];
	const newProfilePublishers = isWriter
		? computeProfilePublishersFromProfileForm(
				profileForm.publishers,
				newAssociatedPublishers
		  )
		: [];

	// --------- STUDIO LOGIC ---------
	const fullParticipantStudios = getAllAssociatedStudios(profileForm);

	// Like with publishers, we need to keep studios that are already in use, following the same logic
	// add new integer IDs to all newly selected studios within the roles
	const newAssociatedStudios = mergeParticipantAndAssociatedStudios(
		profileForm.associatedStudios,
		fullParticipantStudios
	);

	// then overwrite the studios in the roles with the new studio IDs
	const newProfileRoles = mergeParticipantAndAssociatedRoles(
		newAssociatedStudios,
		fullParticipantStudios,
		profileForm.roles
	);

	return createNewProfile({
		associatedPublishers: newAssociatedPublishers,
		associatedStudios: newAssociatedStudios,
		publishers: newProfilePublishers,
		roles: newProfileRoles,
		creditedName: profileForm.creditedName,
		email: profileForm.email,
		isni: profileForm.isni,
		type: profileForm.type,
		city: profileForm.city,
		country: profileForm.country,
		ipiCae: profileForm.ipiCae,
		otherAliases: profileForm.otherAliases,
		postalCode: profileForm.postalCode,
		isSavedProfile: true,
		isCopyrightOwner: profileForm.isCopyrightOwner,
		socialLastFour: profileForm.socialLastFour,
		dateOfBirth: profileForm.dateOfBirth,
		isFeatured: profileForm.isFeatured,
		state: profileForm.state,
		legalName: profileForm.legalName,
		notes: profileForm.notes,
		address1: profileForm.address1,
		address2: profileForm.address2,
		ipn: profileForm.ipn,
		partDropped: profileForm.partDropped,
		pro: profileForm.pro,
		phone: profileForm.phone,
		copyrightOwnerClaim: {
			cmo: profileForm.copyrightOwnerClaim?.cmo,
			cmoId: profileForm.copyrightOwnerClaim?.cmoId,
		},
	});
};

export const computeReducedProfilePublishers = (
	fullProfile: FullLocalProfile
): LocalProfileDTOPublisher[] =>
	flattenPublishers(fullProfile.publishers).map(p => {
		const fullPublisher = fullProfile.associatedPublishers.find(
			ap => ap.id === p.publisherId
		);

		if (!fullPublisher) {
			throw new Error('Publisher not found');
		}

		return {
			name: fullPublisher.name,
			pro: fullPublisher.pro,
			ipi: fullPublisher.ipi,
		};
	});

export const computeReducedProfileRoles = (
	fullProfile: FullLocalProfile
): LocalProfileDTORole[] =>
	fullProfile.roles.map(r => ({
		category: r.category,
		detail: r.detail,
	}));

export const createProfileFromFullProfile = (
	fullProfile: FullLocalProfile,
	isCreatorID: boolean = false
): LocalProfile => ({
	creditedName: fullProfile.creditedName,
	id: fullProfile.id,
	publishers: computeReducedProfilePublishers(fullProfile),
	roles: computeReducedProfileRoles(fullProfile),
	isni: fullProfile.isni,
	profile: fullProfile,
	isCreatorID,
});

export const createFullProfileFromCreditRequest = (
	creditRequest: CreditRequest
): FullLocalProfile => {
	const associatedPublishers = creditRequest.publishers.map(p =>
		createPublisher({
			...p,
			id: generateRandomId(),
		})
	);

	const publishers: ParticipantPublisher[] = associatedPublishers.map(ap =>
		createParticipantPublisher(ap)
	);

	// filter out empty studios
	// and remove duplicates
	const associatedStudios: LocalProfileAssociatedStudio[] = uniqBy(
		creditRequest.roles.filter(r => r.studio),
		'studio'
	).map(r => ({
		id: generateRandomId(),
		name: r.studio,
	}));

	const roles: ParticipantRole[] = creditRequest.roles.map(r => {
		const studio = r.studio
			? associatedStudios.find(as => as.name === r.studio)
			: null;

		if (r.studio && !studio) throw new Error('Studio not found');

		return {
			category: r.category,
			detail: r.detail,
			studio: studio
				? {
						studioId: studio.id,
						label: studio.name,
						sessionType: null,
				  }
				: null,
		};
	});

	return {
		id: uuidv4(),
		...createNewProfile({
			creditedName: creditRequest.creditedName,
			associatedPublishers,
			publishers,
			associatedStudios,
			ipiCae: creditRequest.ipi,
			pro: creditRequest.pro,
			roles,
		}),
	};
};
