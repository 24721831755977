import { Form, FormikProvider, useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import TextField from '../../../form/TextField';
import styles from './SignUpStep.module.scss';
import Button from '../../../layout/Button/Button';
import AccountService from '../../../../api/services/accountService';
import { useAppDispatch } from '../../../../store/hooks';
import { hideModal, showModalAction } from '../../../../store/modal/actions';
import { CHECK_EMAIL_SIGNUP_MODAL } from '../../../../constants/modalTypes';

export type CheckEmailStepProps = {
	onSubmit: (values: CheckEmailFormikValues) => Promise<void>;
	initialFormValues?: CheckEmailFormikValues | null;
};

export type CheckEmailFormikValues = {
	email: string;
	claimingProfileId: string | null;
	claimingProfile: IndieProfile | null;
};

const validationSchema = Yup.object({
	email: Yup.string().email('Invalid email address').required('Required'),
});
const CheckEmailStep = ({
	onSubmit,
	initialFormValues,
}: CheckEmailStepProps) => {
	const dispatch = useAppDispatch();

	const [isCheckingEmail, setIsCheckingEmail] = React.useState(false);
	const [accountExists, setAccountExists] = React.useState(false);

	const formik = useFormik<CheckEmailFormikValues>({
		initialValues: initialFormValues ?? {
			email: '',
			claimingProfileId: null,
			claimingProfile: null,
		},
		onSubmit: values => {
			onSubmit(values);
		},
		validationSchema,
		validateOnMount: true,
	});

	const handleCheckEmail = async (email: string) => {
		setIsCheckingEmail(true);
		try {
			setAccountExists(false);

			const res = await AccountService.checkEmail(email);
			console.log(res);

			if (res.data.accountExists) {
				setAccountExists(true);
				return;
			}

			if (res.data.hasExistingProfile) {
				// show modal
				dispatch(
					showModalAction(CHECK_EMAIL_SIGNUP_MODAL, {
						foundProfile: res.data.foundProfile,
						size: 'md',
						onConfirm: () => {
							Promise.resolve(dispatch(hideModal())).then(() => {
								formik.setFieldValue(
									'claimingProfileId',
									res.data.foundProfileId
								);
								formik.setFieldValue('claimingProfile', res.data.foundProfile);
								formik.handleSubmit();
							});
						},
						onDecline: () => {
							Promise.resolve(dispatch(hideModal())).then(() => {
								formik.setFieldValue('claimingProfileId', null);
								formik.setFieldValue('claimingProfile', null);
								formik.handleSubmit();
							});
						},
					})
				);
				return;
			}

			formik.handleSubmit();
		} catch (err) {
			console.error(err);
		} finally {
			setIsCheckingEmail(false);
		}
	};

	return (
		<FormikProvider value={formik}>
			<Form
				placeholder={null}
				onKeyDown={e => {
					if (e.key === 'Enter' && !formik.isSubmitting) {
						e.preventDefault();
						handleCheckEmail(formik.values.email);
					}
				}}
			>
				<div className={styles['sign-up-step-container']}>
					<h2> Create an account to start with Sound Credit </h2>
					<p>Just a few more steps and you're done! We hate paperwork, too.</p>
					<div className={styles['form']}>
						<TextField
							name='email'
							label='Email address'
							type='email'
							placeholder='e.g. johndoe@gmail.com'
							value={formik.values.email!}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							isInvalid={formik.touched.email! && !!formik.errors.email!}
							errorMessage={formik.errors.email!}
						/>

						{accountExists && (
							<p
								className='text-danger m-0'
								style={{
									textAlign: 'center',
								}}
							>
								An account with this email already exists.
							</p>
						)}

						<div className='my-3' />
						<Button
							label='Next'
							onClick={() => handleCheckEmail(formik.values.email)}
							isDisabled={!formik.isValid}
							isLoading={formik.isSubmitting || isCheckingEmail}
							theme='dark'
							size='lg'
						/>
					</div>
				</div>
			</Form>
		</FormikProvider>
	);
};

export default CheckEmailStep;
