import React, { ElementType, useCallback, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import Button from '../../layout/Button';
import IconButton from '../../layout/IconButton';

export type TextFieldProps = {
	style?: React.CSSProperties;
	className?: string;
	inputClassName?: string;
	value?: string | number;
	type?: string;
	as?: ElementType<any>;
	placeholder?: string;
	id?: string;
	rows?: number;
	cols?: number;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
	isValid?: boolean;
	isInvalid?: boolean;
	label?: string | React.ReactNode;
	name?: string;
	errorMessage?: string;
	min?: number | string;
	max?: number | string;
	step?: number;
	dense?: boolean;
	isDisabled?: boolean;
	readOnly?: boolean;
	onClick?: (e?: React.MouseEvent<Element, MouseEvent>) => void;
	buttonLabel?: string;
	onButtonClick?: (e?: React.MouseEvent<Element, MouseEvent>) => void;
	informationText?: string;
	buttonLeftIcon?: string;
	buttonRightIcon?: string;
	required?: boolean;
	selectableLabel?: boolean;
	maxLength?: number;
	showMaxLength?: boolean;
	buttonTooltipText?: string;
	isButtonDisabled?: boolean;
	isButtonLoading?: boolean;
	isButtonLocked?: boolean;
	autoComplete?: string;
	showEyeToggle?: boolean;
	outerStyle?: React.CSSProperties;
	labelStyle?: React.CSSProperties;
	buttonStyle?: React.CSSProperties;
	forceEnableButton?: boolean;
	showRequiredAsterisk?: boolean; // only works if label is a string
	iconButton?: {
		icon: string;
		onClick: () => void;
		tooltipText?: string;
		isDisabled?: boolean;
		isLoading?: boolean;
	};
};

const TextField = ({
	style = {},
	className = '',
	inputClassName = '',
	value,
	type,
	as,
	placeholder,
	id,
	rows,
	cols,
	onChange,
	onBlur,
	isValid,
	isInvalid,
	label,
	name,
	errorMessage,
	min,
	max,
	step,
	dense,
	isDisabled,
	readOnly,
	onClick,
	buttonLabel,
	onButtonClick,
	informationText,
	buttonLeftIcon = '',
	buttonRightIcon = '',
	required,
	selectableLabel,
	maxLength,
	showMaxLength = false,
	buttonTooltipText = '',
	isButtonDisabled = false,
	isButtonLoading = false,
	isButtonLocked = false,
	autoComplete = 'on',
	showEyeToggle = false,
	outerStyle = {},
	labelStyle = {},
	buttonStyle = {},
	forceEnableButton = false,
	showRequiredAsterisk = false,
	iconButton,
}: TextFieldProps) => {
	const [showPassword, setShowPassword] = React.useState(false);

	const finalType = useMemo(
		() => (showEyeToggle ? (showPassword ? 'text' : 'password') : type),
		[showEyeToggle, showPassword, type]
	);

	const toggleShowPassword = useCallback(() => {
		setShowPassword(prevShow => !prevShow);
	}, []);

	return (
		<Form.Group
			className={`${className} ${dense ? 'mb-0' : ''} `}
			style={{ marginBottom: '1.5rem', ...outerStyle }}
		>
			{label && (
				<Form.Label
					style={{
						...(selectableLabel ? { userSelect: 'text', cursor: 'text' } : {}),
						...labelStyle,
					}}
					htmlFor={name}
				>
					{typeof label === 'string' && showRequiredAsterisk ? (
						<>{label}*</>
					) : (
						label
					)}
					{maxLength && showMaxLength && (
						<span className='ml-2 text-muted'>
							({value?.toString().length ?? 0}/{maxLength})
						</span>
					)}
				</Form.Label>
			)}

			<div className={buttonLabel ? 'd-flex' : ''}>
				<div style={{ flex: 1, position: 'relative' }}>
					<Form.Control
						style={{
							paddingRight: showEyeToggle ? 40 : 0,
							...style,
						}}
						readOnly={readOnly}
						disabled={isDisabled}
						id={id}
						rows={rows}
						autoComplete={autoComplete}
						cols={cols}
						type={finalType}
						name={name}
						value={value}
						as={as}
						min={min}
						max={max}
						step={step}
						placeholder={placeholder}
						onChange={onChange}
						onBlur={onBlur}
						isValid={isValid}
						isInvalid={isInvalid}
						onClick={onClick}
						required={required}
						onWheel={e => (e.target as HTMLInputElement).blur()}
						maxLength={maxLength}
						className={inputClassName}
					/>
					{errorMessage && (
						<Form.Control.Feedback
							style={{ height: informationText ? 'auto' : 0 }}
							type='invalid'
						>
							{errorMessage}
						</Form.Control.Feedback>
					)}
					{showEyeToggle && (
						<IconButton
							onClick={toggleShowPassword}
							icon={`fas fa-${showPassword ? 'eye-slash' : 'eye'}
				`}
							style={{ position: 'absolute', right: 0, top: 0, width: 40 }}
						/>
					)}
					{iconButton && (
						<IconButton
							onClick={iconButton.onClick}
							icon={iconButton.icon}
							tooltipText={iconButton.tooltipText}
							isDisabled={iconButton.isDisabled || isDisabled || readOnly}
							isLoading={iconButton.isLoading}
							// isLocked={iconButton.isLocked}
							style={{ position: 'absolute', right: 0, top: 0, width: 40 }}
						/>
					)}
				</div>
				{buttonLabel && onButtonClick && (
					<Button
						label={buttonLabel}
						onClick={onButtonClick}
						className='ml-3'
						isDisabled={
							!forceEnableButton && (isDisabled || isButtonDisabled || readOnly)
						}
						tooltipText={buttonTooltipText}
						isLoading={isButtonLoading}
						isLocked={isButtonLocked}
						leftIcon={buttonLeftIcon}
						rightIcon={buttonRightIcon}
						style={buttonStyle}
					/>
				)}
			</div>
			{informationText && (
				<Form.Text className='text-muted'>{informationText}</Form.Text>
			)}
		</Form.Group>
	);
};

export default TextField;
