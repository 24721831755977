import React, { useMemo } from 'react';
import '../ProjectsTable.scss';

const AdministratorColumn = ({ adminData, onClick, row }) => {
	const { currentUserId, projectUserId, projectOwnerName } = useMemo(
		() => ({
			...adminData,
		}),
		[adminData]
	);

	const isAdmin = useMemo(
		() => currentUserId === projectUserId,
		[currentUserId, projectUserId]
	);

	const ownerString = useMemo(
		() => (isAdmin ? '' : projectOwnerName),
		[isAdmin, projectOwnerName]
	);

	const isLoading = useMemo(() => !(row && row.original.createdAt), [row]);

	return (
		<div
			onClick={onClick}
			className='d-flex align-items-center justify-content-center'
		>
			<div className='Name1'>{ownerString ?? ''}</div>
			{
				<div
					className={`owner-type-tag clickable no-highlight ${
						isLoading ? 'disabled' : ''
					}`}
				>
					<i
						className={`fas ${isAdmin ? 'fa-user' : 'fa-user-friends'} mr-1`}
					/>

					{isAdmin ? 'ME' : 'SHARED'}
				</div>
			}
		</div>
	);
};

export default AdministratorColumn;
