import profilesApi from '../localProfilesApi';
import { checkInvalidToken } from '../../helpers/checkInvalidToken';
import axiosRetry from 'axios-retry';
import { shouldRetry } from '../../helpers/networkTools';

axiosRetry(profilesApi, {
	retries: 3,
	retryDelay: retryCount => {
		return retryCount * 1000;
	},
	shouldResetTimeout: true,
	retryCondition: shouldRetry,
});

export const getLocalProfiles = () =>
	profilesApi.get<{
		profiles: LocalProfileDTO[];
	}>('/');

export const createLocalProfile = (profile: NewFullLocalProfile) =>
	profilesApi
		.post<{
			profileId: string;
		}>('/', {
			name: profile.creditedName,
			profile: {
				...profile,
			},
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

export const getLocalProfileById = (profileId: string) =>
	profilesApi
		.get<FullLocalProfile>('/', {
			params: {
				profileId,
			},
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

export const deletePersonalProfile = (profileId: string) =>
	profilesApi
		.post<{
			message: string;
		}>('/', {
			profile_id: profileId,
			delete: true,
		})
		.then(res => console.log('PROFILE DELETED: ', res))
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

export const editPersonalProfile = (profile: FullLocalProfile) =>
	profilesApi
		.post<{
			message: string;
		}>('/', {
			profile_id: profile.id,
			name: profile.creditedName,
			profile: {
				...profile,
			},
		})
		.then(res => res)
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});
