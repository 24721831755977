import { shouldRetry } from '../../helpers/networkTools';
import { explicitApi } from '../explicitApi';
import axiosRetry from 'axios-retry';

axiosRetry(explicitApi, {
	retries: 3,
	retryDelay: retryCount => {
		return retryCount * 1000;
	},
	shouldResetTimeout: true,
	retryCondition: shouldRetry,
});

export const explicitLyrics = (payload: {
	title: string;
	mainArtist: string;
	genre: string;
	lyrics: string;
}) =>
	explicitApi.post('/Explicit-Lyric-Detector', {
		recording: { ...payload },
	});
