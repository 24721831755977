import { AnyAction } from 'redux';
import {
	REQUEST_FAILURE,
	REQUEST_FORBIDDEN,
	REQUEST_LOADING,
	REQUEST_SUCCESS,
} from '../../constants/requestStatusTypes';
import {
	CLEAR_USER_DATA,
	DELETE_LOCAL_NOTIFICATIONS,
	READ_LOCAL_NOTIFICATION,
	SET_ONBOARDING_CLICKED_SEND_PLAYLIST_LINK,
	START_USER_REQUEST,
	STORE_NOTIFICATIONS,
	STORE_USER_PROFILE,
	USER_REQUEST_AUTH_ERROR,
	USER_REQUEST_ERROR,
	USER_REQUEST_SUCCESS,
} from '../actionTypes';

type OnboardingState = {
	clickedSendPlaylistLink: boolean;
};

export type UserState = {
	notifications: UserNotification[] | null;
	userProfile: (CloudProfile & { username: string }) | null;
	requestStatus: string;
	requestLabel: string;
	errorMessage: string;
	onboarding: OnboardingState;
};

const initialState: UserState = {
	notifications: null,
	userProfile: null,
	requestStatus: '',
	requestLabel: '',
	errorMessage: '',
	onboarding: {
		clickedSendPlaylistLink: false,
	},
};

const reducer = (state = initialState, action: AnyAction): UserState => {
	switch (action.type) {
		case START_USER_REQUEST:
			return {
				...state,
				requestStatus: REQUEST_LOADING,
				requestLabel: action.requestLabel,
				errorMessage: '',
			};
		case USER_REQUEST_ERROR:
			return {
				...state,
				requestStatus: REQUEST_FAILURE,
				errorMessage: action.errorMessage,
			};
		case USER_REQUEST_AUTH_ERROR:
			return {
				...state,
				requestStatus: REQUEST_FORBIDDEN,
				errorMessage: action.errorMessage,
			};
		case USER_REQUEST_SUCCESS:
			return {
				...state,
				requestStatus: REQUEST_SUCCESS,
				errorMessage: '',
			};
		case STORE_USER_PROFILE:
			return {
				...state,
				userProfile: {
					...action.userProfile,
					username: action.username,
				},
			};
		case STORE_NOTIFICATIONS:
			return {
				...state,
				notifications: action.notifications,
			};
		case DELETE_LOCAL_NOTIFICATIONS:
			if (!state?.notifications) {
				console.error(
					'Cannot delete notifications, notifications not found in state.'
				);
				return state;
			}

			return {
				...state,
				notifications: state?.notifications?.filter(
					notification => action.ids.indexOf(notification.uuid) === -1
				),
			};
		case READ_LOCAL_NOTIFICATION:
			if (!state?.notifications) {
				console.error(
					'Cannot read notification, notifications not found in state.'
				);
				return state;
			}

			return {
				...state,
				notifications: state?.notifications?.map(notification => {
					if (notification.uuid === action.id) {
						return {
							...notification,
							read: true,
						};
					}

					return notification;
				}),
			};
		case SET_ONBOARDING_CLICKED_SEND_PLAYLIST_LINK:
			return {
				...state,
				onboarding: {
					...state.onboarding,
					clickedSendPlaylistLink: true,
				},
			};
		case CLEAR_USER_DATA:
			return {
				...initialState,
			};
		default:
			return state;
	}
};

export default reducer;
