import blankRecording from '../constants/unionForm/afmRecording.json';
import blankParticipant from '../constants/unionForm/afmParticipant.json';
import blankAfmForm from '../constants/unionForm/afmForm.json';
import { getTrackNumberById } from './albumTools';
import _ from 'lodash';
import overtimeOptions from '../constants/unionForm/overtimeOptions.json';
import { findParticipantRecordingReferences } from './unionFormTools';

export const createAfmForm = (
	selectedRecordings: RecordingContent[],
	album: AlbumContent | null,
	studioForm: UnionFormStudioForm,
	completeParticipantsForm: UnionFormCompleteParticipantsForm,
	finalizeForm: UnionFormFinalizeForm
) => {
	const [afmRecordings, recordingIdToReferenceMap] = convertRecordingsToAfm(
		selectedRecordings,
		album
	);

	console.log('completeparticipantsform', completeParticipantsForm);

	const afmParticipants = convertParticipantsToAfm(
		completeParticipantsForm.participants,
		selectedRecordings,
		recordingIdToReferenceMap,
		completeParticipantsForm.differentLeaderOnPriorSession
	);

	return {
		...blankAfmForm.union_form,
		form_type: 'regular',
		release_name: album ? album.title : selectedRecordings[0].title,
		rpno: finalizeForm.contractNumber,
		afm_local_no: studioForm.afmLocalNumber,
		date: new Date().toISOString().split('T')[0],
		record_co: finalizeForm.recordCompany,
		record_co_label_rep: finalizeForm.recordCompanyRepName,
		record_co_address_1: finalizeForm.recordCompanyAddress,
		record_co_rep_phone: finalizeForm.recordCompanyRepPhone,
		recording_date: studioForm.recordingDate,
		recording_studio: studioForm.studio?.label ?? null,
		city: studioForm.city,
		state: studioForm.state,
		production_type: finalizeForm.productionType,
		payment_type: finalizeForm.paymentType,
		name_of_artist_or_group: album
			? album.artistName
			: selectedRecordings[0].mainArtist,
		producer_name: finalizeForm.sessionProducer?.label ?? '',
		producer_address: finalizeForm.sessionProducerAddress,
		recordings: afmRecordings,
		session_times: finalizeForm.sessionTimes.map(sessionTime => ({
			start_time: sessionTime.startTime,
			end_time: sessionTime.endTime,
			recording_ids: sessionTime.recordingIds.map(
				id => recordingIdToReferenceMap[id]
			),
		})),
		professionals: afmParticipants,
		hours_of_employment: finalizeForm.sessionTimes
			.map(sessionTime => `${sessionTime.startTime} - ${sessionTime.endTime}`)
			.join('; '),
	};
};

export const convertRecordingsToAfm = (
	recordings: RecordingContent[],
	album: AlbumContent | null
): [AfmRecording[], Record<number, number>] => {
	const recordingIdToReferenceMap: Record<number, number> = {};

	const afmRecordings = recordings.map((recording, index) => {
		const afmRecording: AfmRecording = {
			...blankRecording,
			title_of_tunes_pieces: recording.title,
			track_number: (album
				? getTrackNumberById(album.recordings, recording.id!)
				: 1
			).toString(),
			no_of_minutes: recording.duration,
			recording_reference: index,
		};

		recordingIdToReferenceMap[recording.id!] = afmRecording.recording_reference;

		return afmRecording;
	});

	return [afmRecordings, recordingIdToReferenceMap];
};

export const convertParticipantsToAfm = (
	participants: UnionFormParticipant[],
	recordings: RecordingContent[],
	recordingIdToReferenceMap: Record<number, number>,
	differentLeaderOnPriorSession?: boolean
) =>
	participants.map(participant => ({
		...blankParticipant,
		employees_name: participant.legalName
			? participant.legalName
			: participant.creditedName,
		home_address: participant.address1,
		city: participant.city,
		state: participant.state,
		post_code: participant.postalCode ?? null,
		is_featured: participant.isFeatured ?? null,
		social_security_number:
			participant.socialLastFour?.length === 4
				? `XXX-XX-${participant.socialLastFour}`
				: participant.socialLastFour?.toString(), // if a full SSN is entered, then we use that,
		no_of_doubles: participant.numberOfDoubles?.toString(),
		cartage_type: participant.cartage,
		scale_type: participant.scale,
		overtime: participant.overtime ?? overtimeOptions[0].value, // default to 0:00
		is_leader: participant.isSessionLeader,
		roles: participant.roles.map(role => role.detail),
		id_of_tunes: findParticipantRecordingReferences(
			participant,
			recordings,
			recordingIdToReferenceMap
		),
		lower_hw: differentLeaderOnPriorSession
			? !participant.notOnPriorSession
			: false,
	}));

export const generateNewContractNumber = (latestContractNumber: string) => {
	// extract latest digits from contract number and add 1
	const latestDigitsMatch = latestContractNumber.match(/\d+$/);

	if (latestDigitsMatch) {
		const latestDigit = parseInt(latestDigitsMatch[0]);
		const newLatestDigits = String(latestDigit + 1);
		const newPaddedLatestDigits = _.padStart(
			newLatestDigits,
			latestDigitsMatch[0].length < newLatestDigits.length // if the new length is longer than the old, it means we need one more digit for padding
				? newLatestDigits.length
				: latestDigitsMatch[0].length, // otherwise, we pad with the same length
			'0'
		);

		const contractNumberPrefix = latestContractNumber.slice(
			0,
			latestDigitsMatch.index
		);

		const newContractNumber = contractNumberPrefix + newPaddedLatestDigits;

		return newContractNumber;
	}
};
