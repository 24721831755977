import _ from 'lodash';
import exportTypes from '../constants/exportType';
import overtimeOptions from '../constants/unionForm/overtimeOptions.json';
import ExportType from '../constants/exportType';

const initializeCompleteParticipantsForm = (
	selectedParticipants: Participant[],
	exportType: ExportType,
	previousForm?: Partial<UnionFormCompleteParticipantsForm>
): UnionFormCompleteParticipantsForm => {
	console.log(
		'initializeCompleteParticipantsForm',
		selectedParticipants,
		exportType,
		previousForm
	);
	switch (exportType) {
		case exportTypes.AFM_B4:
		case exportTypes.AFM_B9:
			return {
				participants: selectedParticipants.map(selectedParticipant => {
					return {
						numberOfDoubles: 0,
						isSessionLeader: false,
						cartage: null,
						scale: 'single',
						overtime: overtimeOptions[0].value,
						notOnPriorSession: false,
						recordingSocialLastFour: selectedParticipant.socialLastFour, // since socialLastFour may be overwritten in the union form,
						// we need the original value to compare against the socialLastFour present in the recording participant, which determines
						// participant/musician equality within the union form logic.
						...mergeParticipant(selectedParticipant, previousForm),
					};
				}),
				differentLeaderOnPriorSession:
					!!previousForm?.differentLeaderOnPriorSession,
			};
		case exportTypes.SAG_AFTRA:
			return {
				participants: selectedParticipants.map(selectedParticipant => ({
					recordingSocialLastFour: selectedParticipant.socialLastFour, // same as above
					...mergeParticipant(selectedParticipant, previousForm),
				})),
			};
		default:
			return {
				participants: selectedParticipants.map(selectedParticipant => ({
					recordingSocialLastFour: selectedParticipant.socialLastFour, // same as above
					...mergeParticipant(selectedParticipant, previousForm),
				})),
			};
	}
};

const mergeParticipant = (
	selectedParticipant: Participant,
	previousForm?: Partial<UnionFormCompleteParticipantsForm>
) => {
	let participant = { ...selectedParticipant };

	if (!previousForm || _.isEmpty(previousForm)) {
		return participant;
	}

	const initialParticipant = previousForm?.participants?.find(
		p => p.id === selectedParticipant.id
	);

	if (initialParticipant) {
		participant = { ...participant, ...initialParticipant };
	}

	return participant;
};

export default initializeCompleteParticipantsForm;
