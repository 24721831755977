import { FormikProvider, useFormik } from 'formik';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal, Row, Col } from 'react-bootstrap';
import { fetchPlaylistAction } from '../../../../../../store/playlists/actions';
import * as yup from 'yup';
import Button from '../../../../../layout/Button';
import {
	hideModal,
	setModalTitle,
} from '../../../../../../store/modal/actions';
import TextField from '../../../../../form/TextField';
import { editProjectTitleAndArtistAction } from '../../../../../../store/projects/actions';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';

const schema = yup.object().shape({
	title: yup.string().required('Required'),
	artist: yup.string().required('Required'),
});

type EditFileProjectForm = {
	title: string;
	artist: string;
};

export type EditFileProjectModalProps = {
	playlistId?: number | null;
	albumId?: number | null;
	recordingId?: number | null;
};

const EditFileProjectModal = ({
	playlistId,
	albumId,
	recordingId,
}: EditFileProjectModalProps) => {
	const { recordingsById, albumsById } = useAppSelector(
		state => state.projects
	);
	const dispatch = useAppDispatch();

	const [isLoading, setIsLoading] = useState(false);

	const project = useMemo(
		() =>
			albumId
				? albumsById![albumId]
				: recordingId
				? recordingsById![recordingId]
				: null,
		[albumId, albumsById, recordingId, recordingsById]
	);

	const initialValues = useMemo(
		() => ({
			title: project?.title || '',
			artist: project?.artist || '',
		}),
		[project]
	);

	const handleClose = useCallback(() => {
		dispatch(hideModal());
	}, [dispatch]);

	const handleSubmit = useCallback(
		(form: EditFileProjectForm) => {
			setIsLoading(true);

			dispatch(
				editProjectTitleAndArtistAction({
					albumId,
					recordingId,
					title: form.title,
					artist: form.artist,
				})
			)
				.then(() =>
					playlistId ? dispatch(fetchPlaylistAction(playlistId)) : null
				)
				.finally(() => {
					setIsLoading(false);
					handleClose();
				});
		},
		[dispatch, playlistId, handleClose, albumId, recordingId]
	);

	const formik = useFormik<EditFileProjectForm>({
		initialValues,
		onSubmit: handleSubmit,
		validationSchema: schema,
		validateOnMount: true,
	});

	useEffect(() => {
		dispatch(
			setModalTitle(`EDIT ASSOCIATED PROJECT INFO`)
		);
	}, [dispatch]);

	return (
		<FormikProvider value={formik}>
			<Modal.Body>
				<div className='px-4'>
					{
						<Row>
							<Col xs={12} className='mb-2'>
								<TextField
									showMaxLength
									name='title'
									label='Project Title'
									placeholder='Song or Album Title'
									value={formik?.values?.title}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									errorMessage={formik.errors.title}
									isInvalid={formik?.touched?.title && !!formik?.errors?.title}
									dense
								/>
							</Col>
							<Col xs={12}>
								<TextField
									showMaxLength
									name='artist'
									label='Project Main Artist'
									placeholder='Song or Album Main Artist'
									value={formik?.values?.artist}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									errorMessage={formik.errors.artist}
									isInvalid={
										formik?.touched?.artist && !!formik?.errors?.artist
									}
									dense
								/>
							</Col>
						</Row>
					}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div className='d-flex justify-content-end'>
					<Button label='Cancel' onClick={handleClose} className='mr-2' />
					<Button
						label='Save'
						theme='dark'
						type='submit'
						isLoading={isLoading}
						onClick={formik.handleSubmit as any}
					/>
				</div>
			</Modal.Footer>
		</FormikProvider>
	);
};

export default EditFileProjectModal;
