import { AnyAction } from 'redux';
import {
	SET_PLAYER_PLAYING,
	SET_PLAYER_CURRENT_TIME,
	SET_PLAYER_DURATION,
	SET_PLAYER_LOOP_MODE,
	TOGGLE_PLAYER_SHUFFLE,
	SET_PLAYER_PLAYLIST,
	SET_PLAYER_TRACKLIST,
	SET_PLAYER_CURRENT_TRACK_INDEX,
	SET_SHOW_PLAYER,
	TOGGLE_LOSSLESS,
	SET_PLAYER_TRACK_VERSION_NUMBER,
	SET_PLAYER_AUDIO_LOADED,
	SET_PLAYER_AUDIO_SRC,
	SET_SEEK_TIME_FUNCTION,
	RESET_PLAYER,
	SET_PLAYER_GROUP_INDEXES,
} from '../actionTypes';
import LoopMode from '../../constants/loopMode';

const initialState: PlayerState = {
	playing: false,
	currentTime: 0,
	duration: 0,
	loopMode: LoopMode.DISABLED,
	shuffle: false,
	playlistId: null,
	tracklist: [],
	currentTrackIndex: -1,
	showPlayer: false,
	lossless: false,
	currentTrackVersionNumber: null,
	audioLoaded: false,
	audioSrc: null,
	seekTime: null,
	groupIndexes: {
		playlistId: null, // to keep track of the last time the group indexes were updated
		fileIdToGroupIdIndex: null,
		groupIdToParentGroupIdIndex: null,
		groupIdToGroupContentIndex: null,
	},
};

export function playerReducer(
	state = initialState,
	action: AnyAction
): PlayerState {
	switch (action.type) {
		case SET_PLAYER_PLAYING:
			return { ...state, playing: action.playing };
		case SET_PLAYER_CURRENT_TIME:
			return { ...state, currentTime: action.currentTime };
		case SET_PLAYER_DURATION:
			return { ...state, duration: action.duration };
		case SET_PLAYER_LOOP_MODE:
			return { ...state, loopMode: action.loopMode };
		case TOGGLE_PLAYER_SHUFFLE:
			return { ...state, shuffle: !state.shuffle };
		case SET_PLAYER_PLAYLIST:
			return { ...state, playlistId: action.playlistId };
		case SET_PLAYER_TRACKLIST:
			return { ...state, tracklist: action.tracklist };
		case SET_PLAYER_CURRENT_TRACK_INDEX:
			return { ...state, currentTrackIndex: action.currentTrackIndex };
		case SET_SHOW_PLAYER:
			return { ...state, showPlayer: action.showPlayer };
		case TOGGLE_LOSSLESS:
			return { ...state, lossless: !state.lossless };
		case SET_PLAYER_TRACK_VERSION_NUMBER:
			return { ...state, currentTrackVersionNumber: action.versionNumber };
		case SET_PLAYER_AUDIO_LOADED:
			return { ...state, audioLoaded: action.audioLoaded };
		case SET_PLAYER_AUDIO_SRC:
			return { ...state, audioSrc: action.audioSrc };
		case SET_SEEK_TIME_FUNCTION:
			return { ...state, seekTime: action.seekTime };
		case SET_PLAYER_GROUP_INDEXES:
			return {
				...state,
				groupIndexes: {
					playlistId: action.playlistId,
					fileIdToGroupIdIndex: action.fileIdToGroupIdIndex,
					groupIdToParentGroupIdIndex: action.groupIdToParentGroupIdIndex,
					groupIdToGroupContentIndex: action.groupIdToGroupContentIndex,
				},
			};
		case RESET_PLAYER:
			return initialState;
		default:
			return state;
	}
}

export default playerReducer;
