import { FieldArray, ErrorMessage, useFormik, FormikProvider } from 'formik';
import { Modal, Row, Col, Accordion, Form } from 'react-bootstrap';
import CreatableSelect from '../../../form/CreatableSelect';
import Select from '../../../form/Select';
import Button from '../../../layout/Button';
import { useEffect, useMemo, useState } from 'react';
import TextField from '../../../form/TextField';
import BooleanRadioInput from '../../../form/BooleanRadioInput';
import IconButton from '../../../layout/IconButton';
import ToggleInput from '../../../layout/ToggleInput';
import theme from '../../../../theme.module.scss';
import '../../Credits/ParticipantModal/ParticipantModal.scss';
import DatePicker from '../../../form/DatePicker';
import roleList from '../../../../constants/roles.json';
import defaultStudios from '../../../../constants/studios.json';
import {
	editLocalProfileAction,
	createLocalProfileAction,
} from '../../../../store/profiles/actions';
import WriterDetails from '../../Credits/ParticipantModal/WriterDetails';
import CopyrightOwnerDetails from '../../Credits/ParticipantModal/CopyrightOwnerDetails';
import { getCurrentRecording } from '../../../../store/projects/selectors';
import usStates from '../../../../constants/usStates.json';
import { matchSorter } from 'match-sorter';
import countries from '../../../../constants/countries.json';
import { saveSelectedCountry } from '../../../../store/session/actions';
import {
	hideAllModalsAction,
	hideModal,
	showModalAction,
} from '../../../../store/modal/actions';
import {
	CONFIRMATION_MODAL,
	DELETE_PARTICIPANT_MODAL,
	ISNI_REGISTRATION_MODAL,
} from '../../../../constants/modalTypes';
import React from 'react';
import { participantValidationSchema } from '../../../../helpers/participantTools';
import {
	convertFullLocalProfileToProfileForm,
	convertProfileFormToFullLocalProfile,
	createNewProfileForm,
	createProfileFromFullProfile,
} from '../../../../helpers/profileTools';
import { isStudioEqual } from '../../../../helpers/studioTools';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { v4 as uuidv4 } from 'uuid';
import {
	createNewFormikProfilePublisher,
	createNewFormikProfileSubPublisher,
} from '../../../../helpers/publisherTools';

const statesOptions = usStates.map(({ abbreviation, name }) => ({
	value: abbreviation,
	label: name,
}));

const countryOptions = countries.map(country => ({
	label: country.name,
	name: country.name,
}));

const isDebug =
	process.env.NODE_ENV === 'development' ||
	process.env.REACT_APP_STAGING === 'true';

export type ProfileModalProps = {
	show: boolean;
	toggleShow: () => void;
	profile?: LocalProfile | null;
	enableDelete?: boolean;
	editing?: boolean;
	setProfile?: (profile: LocalProfile | null) => void;
};

export type CountryOption = {
	label: string;
	name: string;
};

export default function ProfileModal({
	show,
	toggleShow,
	profile,
	enableDelete = true,
	editing = false,
	setProfile = (_: LocalProfile | null) => {},
}: ProfileModalProps) {
	/*
	 * Redux Hooks
	 */
	const dispatch = useAppDispatch();

	const { savedCountry } = useAppSelector(state => state.session);
	const currentRecording = useAppSelector(getCurrentRecording);

	/*
	 * React State
	 */
	const [initialValues, setInitialValues] = useState<ProfileForm>(
		createNewProfileForm()
	);

	const [errorMsg, setErrorMsg] = useState('');
	const [roleOptions, setRoleOptions] = useState(defaultRoleOptions);
	const [customStudios, setCustomStudios] = useState<FormikStudio[]>([]);
	const [publishers, setPublishers] = useState<PublisherSelectOption[]>([]);
	const [loading, setLoading] = useState(false);

	const initialFilteredOptions = useMemo(
		() => countryOptions.filter(c => c.name !== savedCountry),
		[savedCountry]
	);

	const initialCountryOptions = useMemo(
		() => [
			{ name: savedCountry, label: savedCountry },
			...initialFilteredOptions,
		],
		[savedCountry, initialFilteredOptions]
	);

	const [filteredCountryOptions, setFilteredCountryOptions] = useState(
		initialCountryOptions
	);

	const selectCountryOptions: CountryOption[] = useMemo(
		() => [
			{ name: savedCountry, label: savedCountry },
			...filteredCountryOptions,
		],
		[savedCountry, filteredCountryOptions]
	);

	const [statesOpts, setStatesOpts] = useState(statesOptions);

	const studioOptions = useMemo(() => {
		const associatedStudios: RecordingStudio[] =
			profile?.profile?.associatedStudios || [];

		const projectStudios = [...customStudios, ...associatedStudios];

		return [
			{
				label: `Studios in this Profile`,
				options: projectStudios,
			},
			{
				label: 'Cloud Studios',
				options: defaultStudios.filter(
					cloudStudio =>
						!projectStudios.some(projectStudio =>
							isStudioEqual(cloudStudio, projectStudio)
						) // remove studios referenced in this recording
				),
			},
		];
	}, [customStudios, profile]);

	const flattenedStudioOptions = useMemo(
		() => studioOptions.flatMap(o => o.options),
		[studioOptions]
	);

	/*
	 * Effects
	 */
	useEffect(() => {
		const excludeSelection = countryOptions.filter(
			c => c.name !== savedCountry
		);
		setFilteredCountryOptions(excludeSelection);
	}, [savedCountry]);

	// Sets initial values for the form and PROs for each publisher
	useEffect(() => {
		console.log(profile, initialValues.creditedName);

		// For new participants, set the initial values to the default values
		if (!profile) {
			setInitialValues(createNewProfileForm());
			return;
		}

		// For existing profiles, set the initial values to the profile values
		if (profile.id) {
			const profileForm = convertFullLocalProfileToProfileForm(
				profile.profile!
			);
			setInitialValues(profileForm);
		}
	}, [profile, initialValues.creditedName]);

	/*
	 * Callbacks
	 */
	const handleSubmit = (values: ProfileForm) => {
		setErrorMsg('');
		setLoading(true);

		if (!editing) handleCreateProfile(values);
		else handleEditProfile(values);
	};

	const handleEditProfile = async (profileForm: ProfileForm) => {
		const newProfile = convertProfileFormToFullLocalProfile(profileForm);
		const localProfile = createProfileFromFullProfile({
			id: profile!.id,
			...newProfile,
		});

		try {
			await dispatch(editLocalProfileAction(localProfile));
		} finally {
			setLoading(false);
			toggleShow();

			if (setProfile) setProfile(null);
			setInitialValues(createNewProfileForm());
			profileFormik.resetForm();
		}
	};

	const handleCreateProfile = async (profileForm: ProfileForm) => {
		const newProfile = convertProfileFormToFullLocalProfile(profileForm);

		try {
			await dispatch(createLocalProfileAction(newProfile));
		} finally {
			setLoading(false);
			toggleShow();

			if (setProfile) setProfile(null);
			setInitialValues(createNewProfileForm());
			profileFormik.resetForm();
		}
	};

	const handleDelete = () => {
		dispatch(
			showModalAction(DELETE_PARTICIPANT_MODAL, {
				participant: profile,
				onClose: () => {
					dispatch(hideModal());
				},
				deleteFromRecording: false,
			})
		);
	};

	const handleHide = () => {
		toggleShow();
		setCustomStudios([]);
		setPublishers([]);
	};

	const profileFormik = useFormik<ProfileForm>({
		initialValues,
		onSubmit: values => handleSubmit(values),
		validationSchema: participantValidationSchema,
		enableReinitialize: true,
	});

	return (
		<>
			<FormikProvider value={profileFormik}>
				<form onSubmit={profileFormik.handleSubmit}>
					<Modal
						show={show}
						size='xl'
						onHide={handleHide}
						className='participant-modal'
					>
						<Modal.Header closeButton>
							<Modal.Title>{profileFormik.values.creditedName}</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<div className='participant-modal__tips'>
								<div className='fw-500'>
									<i className='fas fa-lightbulb mr-2'></i>
									Quick Tip: Add info as you learn it, it's okay to leave
									blanks!
								</div>
								<div className='text-muted mb-4'>
									(we'll let you know if something's missing)
								</div>
								<div className='text-muted mb-4'>
									* Indicates required field
								</div>
							</div>
							<Row>
								<Col xs={6}>
									<TextField
										label='Credited Name *'
										type='text'
										name='creditedName'
										value={profileFormik.values.creditedName}
										onChange={profileFormik.handleChange}
										onBlur={profileFormik.handleBlur}
										errorMessage={profileFormik.errors.creditedName}
										isInvalid={
											profileFormik.touched.creditedName &&
											!!profileFormik.errors.creditedName
										}
									/>

									<Form.Group className='mb-4'>
										<Row>
											<Col xs={12}>
												<ToggleInput
													label='Copyright Owner?'
													name='isCopyrightOwner'
													onChange={profileFormik.handleChange}
													onBlur={profileFormik.handleBlur}
													checked={profileFormik.values.isCopyrightOwner}
													value={profileFormik.values.isCopyrightOwner}
												/>
											</Col>
										</Row>
										<Row>
											<Col xs={12}>
												<small
													style={{
														color: theme.disclaimerTextColor,
														fontStyle: 'italic',
													}}
												>
													Copyright Owners are participants that can make a
													copyright owner claim with a CMO such as Sound
													Exchange or PPL
												</small>
											</Col>
										</Row>
									</Form.Group>
								</Col>
								<Col xs={6}>
									<TextField
										label='Legal Name (if different from credited name)'
										type='text'
										name='legalName'
										onChange={profileFormik.handleChange}
										onBlur={profileFormik.handleBlur}
										value={profileFormik.values.legalName}
									/>
								</Col>
							</Row>

							<Row className='mb-3'>
								<Col xs={12}>
									<FieldArray name='roles'>
										{({ remove, push }) => (
											<div>
												<Row>
													<Col xs={12}>
														<div className='form-label d-flex align-items-center mb-2'>
															Role and Studio
															<button
																className='ml-2 btn text-purple border-purple rounded-circle'
																style={{ padding: '1px 6px' }}
																type='button'
																onClick={() =>
																	push({
																		studio: null,
																		category: '',
																		detail: '',
																	})
																}
															>
																<i className='fa fa-plus'></i>
															</button>
														</div>
													</Col>
												</Row>
												{profileFormik.values.roles &&
													profileFormik.values.roles.map((element, index) => (
														<Row
															className='row rounded p-3 bg-purple-light'
															key={`roles${index}`}
														>
															<Col xs={5}>
																<Select
																	label='Role'
																	name={`roles[${index}].detail`}
																	id={`roles[${index}].detail`}
																	options={roleOptions}
																	getOptionValue={(option: RoleOption) =>
																		option.detail
																	}
																	value={roleOptions.find(
																		option =>
																			option.detail ===
																			profileFormik.values.roles[index].detail
																	)}
																	placeholder='Search...'
																	onChange={option => {
																		profileFormik.setFieldValue(
																			`roles[${index}]`,
																			{
																				studio:
																					profileFormik.values.roles[index]
																						.studio || null,
																				category: option?.category,
																				detail: option?.detail,
																			}
																		);
																	}}
																	errorFieldName={`roles[${index}].detail`}
																	// filterOption={createFilter({
																	// 	matchFrom: 'any',
																	// })}

																	onInputChange={val =>
																		setRoleOptions(
																			matchSorter(defaultRoleOptions, val, {
																				keys: ['detail', 'label'],
																			})
																		)
																	}
																/>
															</Col>
															<Col xs={6}>
																<CreatableSelect
																	label='Studio (with location in parenthesis)'
																	name={`roles[${index}].studio`}
																	id={`roles[${index}].studio`}
																	isGrouped
																	options={studioOptions}
																	placeholder='Search...'
																	errorFieldName={`roles[${index}].studio`}
																	value={
																		profileFormik.values.roles[index].studio &&
																		flattenedStudioOptions.find(
																			studio =>
																				studio &&
																				studio.id &&
																				studio.id ===
																					profileFormik.values?.roles?.[index]
																						?.studio?.studioId
																		)
																	}
																	getOptionValue={option =>
																		option?.id?.toString() ?? ''
																	}
																	getOptionLabel={option => option?.name ?? ''}
																	onChange={pickedStudio => {
																		profileFormik.setFieldValue(
																			`roles[${index}].studio`,
																			pickedStudio
																				? {
																						studioId: pickedStudio.id,
																						sessionType: null,
																						label: pickedStudio.name,
																				  }
																				: null
																		);
																	}}
																	onCreateOption={value => {
																		const studioId = uuidv4();

																		const newOption = {
																			id: studioId,
																			name: value,
																		};

																		setCustomStudios(prevStudios => [
																			...prevStudios,
																			newOption,
																		]);

																		profileFormik.setFieldValue(
																			`roles[${index}].studio`,
																			{
																				studioId: studioId,
																				label: value,
																				sessionType: null,
																			}
																		);
																	}}
																/>
															</Col>
															<Col xs={1} style={{ top: '30px' }}>
																<IconButton
																	onClick={() => remove(index)}
																	color={theme.error}
																	icon='fa fa-trash'
																/>
															</Col>
														</Row>
													))}
											</div>
										)}
									</FieldArray>
								</Col>

								<ErrorMessage name='roles'>
									{msg => {
										return (
											<small style={{ color: theme.error }}>
												{typeof msg === 'string' && msg}
											</small>
										);
									}}
								</ErrorMessage>
							</Row>

							<hr />
							<Row>
								<Col xs={12}>
									<WriterDetails<LocalProfile, ProfileForm>
										participant={profile}
										publishers={publishers}
										setPublishers={setPublishers}
										createPublisher={createNewFormikProfilePublisher as any}
										createSubpublisher={
											createNewFormikProfileSubPublisher as any
										}
									/>
									<hr />

									<CopyrightOwnerDetails<ProfileForm> />
								</Col>
							</Row>

							<hr />
							<Row>
								<Col xs={12}>
									<Accordion defaultActiveKey=''>
										<Accordion.Item eventKey='0'>
											<Accordion.Header>
												<h2>MORE DETAILS</h2>
											</Accordion.Header>
											<Accordion.Body>
												<Row>
													<Col xs={12}>
														<Row>
															<Col xs={6}>
																<BooleanRadioInput
																	label='Part Dropped'
																	name='partDropped'
																	trueLabel='Yes'
																	falseLabel='No'
																	onChange={profileFormik.handleChange}
																	onBlur={profileFormik.handleBlur}
																	value={profileFormik.values.partDropped}
																/>
															</Col>
														</Row>
													</Col>
													<Col xs={6}>
														<TextField
															label='Email'
															type='email'
															name='email'
															onChange={profileFormik.handleChange}
															onBlur={profileFormik.handleBlur}
															value={profileFormik.values.email}
															isInvalid={Boolean(
																profileFormik.touched.email &&
																	profileFormik.errors.email
															)}
															errorMessage={profileFormik.errors.email}
														/>
														<TextField
															label='Phone Number'
															type='text'
															name='phone'
															onChange={profileFormik.handleChange}
															onBlur={profileFormik.handleBlur}
															value={profileFormik.values.phone}
														/>

														<DatePicker
															disableFuture
															label='Date of Birth'
															name='dateOfBirth'
															onChange={profileFormik.handleChange}
															onBlur={profileFormik.handleBlur}
															value={profileFormik.values.dateOfBirth}
														/>

														<TextField
															label='ISNI'
															type='text'
															name='isni'
															onChange={profileFormik.handleChange}
															onBlur={profileFormik.handleBlur}
															value={profileFormik.values.isni}
															buttonLabel='Create / Find'
															isButtonDisabled={!!profileFormik.values.isni}
															onButtonClick={() => {
																if (profileFormik.values.isni) return;

																dispatch(
																	showModalAction(ISNI_REGISTRATION_MODAL, {
																		size: 'lg',
																		profileId: profile?.id,
																		onAssignIsni: (isni: string) => {
																			// necessary to update form in real time
																			profileFormik.setFieldValue('isni', isni);
																		},
																		onHide: () =>
																			dispatch(
																				showModalAction(CONFIRMATION_MODAL, {
																					size: 'md',
																					title:
																						'ARE YOU SURE YOU WANT TO EXIT?',
																					description:
																						'You will lose all progress in the ISNI registration process',
																					confirmAction: {
																						label: 'Exit',
																						onClick: () => {
																							dispatch(hideAllModalsAction());
																						},
																					},
																					cancelAction: {
																						label: 'Cancel',
																						onClick: () =>
																							dispatch(hideModal()),
																					},
																				})
																			),
																	})
																);
															}}
														/>
														<TextField
															label='SSN Last 4'
															type='text'
															name='socialLastFour'
															onChange={profileFormik.handleChange}
															onBlur={profileFormik.handleBlur}
															value={profileFormik.values.socialLastFour}
															isInvalid={Boolean(
																profileFormik?.touched?.socialLastFour &&
																	profileFormik?.errors?.socialLastFour
															)}
															errorMessage={
																profileFormik?.errors?.socialLastFour
															}
															informationText='Do not use dashes or spaces'
															dense
														/>
														<TextField
															label='IPN'
															type='text'
															name='ipn'
															onChange={profileFormik.handleChange}
															onBlur={profileFormik.handleBlur}
															value={profileFormik.values.ipn}
														/>
													</Col>
													<Col xs={6}>
														<TextField
															label='Address Line 1'
															type='text'
															name='address1'
															onChange={profileFormik.handleChange}
															onBlur={profileFormik.handleBlur}
															value={profileFormik.values.address1}
														/>
														<TextField
															label='Address Line 2'
															type='text'
															name='address2'
															onChange={profileFormik.handleChange}
															onBlur={profileFormik.handleBlur}
															value={profileFormik.values.address2}
														/>
														<TextField
															label='City'
															type='text'
															name='city'
															onChange={profileFormik.handleChange}
															onBlur={profileFormik.handleBlur}
															value={profileFormik.values.city}
														/>
														<Row>
															<Col xs={6}>
																{/* <Select */}
																<CreatableSelect
																	label='State / Province / Region'
																	name='state'
																	id='state'
																	options={statesOpts}
																	onChange={option => {
																		profileFormik.setFieldValue(
																			'state',
																			option?.value ?? ''
																		);
																	}}
																	value={statesOpts.find(
																		option =>
																			option.value ===
																			profileFormik.values.state
																	)}
																	onCreateOption={value => {
																		let newOption = {
																			value: value,
																			label: value,
																		};

																		setStatesOpts([...statesOpts, newOption]);
																		profileFormik.setFieldValue('state', value);
																	}}
																/>
															</Col>
															<Col xs={6}>
																<TextField
																	label='Zip / Postal Code'
																	type='text'
																	name='postalCode'
																	onChange={profileFormik.handleChange}
																	onBlur={profileFormik.handleBlur}
																	value={profileFormik.values.postalCode}
																/>
															</Col>
														</Row>
														<Select<CountryOption>
															label='Country'
															name='country'
															id='country'
															options={selectCountryOptions}
															placeholder='Search...'
															errorFieldName='country'
															value={
																// formik.values.country &&
																countryOptions.find(
																	c =>
																		c.name === profileFormik.values.country ||
																		c.name === savedCountry
																)
															}
															getOptionValue={option => option.name}
															getOptionLabel={option => option.label}
															onChange={option => {
																if (!option) return;

																setFilteredCountryOptions(
																	countryOptions.filter(
																		c => c.name !== option?.name
																	)
																);
																dispatch(saveSelectedCountry(option.name));
																profileFormik.setFieldValue(
																	'country',
																	option?.name
																);
															}}
															onInputChange={val =>
																setFilteredCountryOptions(
																	matchSorter(countryOptions, val, {
																		keys: ['name', 'label'],
																	})
																)
															}
														/>
														<TextField
															label='Other Aliases'
															type='text'
															name='otherAliases'
															onChange={profileFormik.handleChange}
															onBlur={profileFormik.handleBlur}
															value={profileFormik.values.otherAliases}
														/>
													</Col>
													<Col xs={12}>
														<TextField
															label='Notes'
															as='textarea'
															name='notes'
															id='notes'
															cols={30}
															rows={4}
															onChange={profileFormik.handleChange}
															onBlur={profileFormik.handleBlur}
															value={profileFormik.values.notes}
														/>
													</Col>
												</Row>
											</Accordion.Body>
										</Accordion.Item>
									</Accordion>
								</Col>
							</Row>
							{isDebug && (
								<>
									<pre>{JSON.stringify(profileFormik.values, null, 2)}</pre>
									<pre>
										{currentRecording &&
											currentRecording.recording &&
											JSON.stringify(
												currentRecording.recording.publishers,
												null,
												2
											) +
												'\n' +
												JSON.stringify(
													currentRecording.recording.studios,
													null,
													2
												) +
												'\n' +
												JSON.stringify([...customStudios], null, 2)}
									</pre>
								</>
							)}
						</Modal.Body>
						<Modal.Footer className='modal-footer'>
							<div className='d-flex flex-column w-100'>
								<span
									style={{
										transition: 'none',
									}}
									className={`share-table-error  mb-2 ${
										errorMsg && 'show-share-error'
									}`}
								>
									{errorMsg}
								</span>
								<div className='d-flex justify-content-end'>
									<Button label='Cancel' onClick={() => toggleShow()} />

									{enableDelete && profile?.id && (
										<Button
											label='Delete'
											theme='danger'
											onClick={handleDelete}
											className='ml-2'
										/>
									)}
									{/* <Button
										label='Copy to All Loaded RINs'
										onClick={onCopyToRecordings}
									/> */}
									<Button
										label='Save & Proceed'
										theme='dark'
										type='submit'
										onClick={() => {
											profileFormik.submitForm();
										}}
										isLoading={loading}
										className='ml-2'
									/>
								</div>
							</div>
						</Modal.Footer>
					</Modal>
				</form>
			</FormikProvider>
		</>
	);
}

type RoleOption = {
	label: string;
	detail: string;
	category: string;
};

const defaultRoleOptions: RoleOption[] = Object.entries(roleList)
	.flatMap(([category, subRoles]) =>
		subRoles.map(detail => ({
			label: `${detail}`,
			detail,
			category,
		}))
	)
	.sort((a, b) => {
		if (a.label < b.label) {
			return -1;
		} else if (a.label > b.label) {
			return 1;
		}

		return 0;
	});
