import React, { useCallback, useEffect, useMemo } from 'react';
import CreateAccountStep, {
	CreateAccountFormikValues,
} from './Steps/CreateAccountStep';
import VerifyEmailStep, {
	VerifyEmailFormikValues,
} from './Steps/VerifyEmailStep';
import styles from './SignUp.module.scss';
import UnionStep, { UnionStepFormikValues } from './Steps/UnionStep';
import SignUpHeader from '../../layout/SignUpHeader';
import CheckEmailStep, { CheckEmailFormikValues } from './Steps/CheckEmailStep';
import IconButton from '../../layout/IconButton/IconButton';
import { useNavigate } from 'react-router-dom';
import AccountService from '../../../api/services/accountService';
import { Buffer } from 'buffer';
import {
	finishSignUpProcessAction,
	loginAction,
	logoutAction,
	setEmailVerifiedAction,
	setSignUpFormStepAction,
	setUserPermissionsAction,
	startSignUpProcessAction,
	validateTokenAction,
} from '../../../store/auth/actions';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { hideModal, showModalAction } from '../../../store/modal/actions';
import {
	ARE_YOU_UNION_MEMBER_MODAL,
	CONFIRMATION_MODAL,
} from '../../../constants/modalTypes';
import { Helmet } from 'react-helmet';
import { fetchUserDataAction } from '../../../store/user/actions';
import { Button } from 'primereact/button';

type SignUpProps = {};

export enum SignUpFormStep {
	CHECK_EMAIL = 'check-email',
	CREATE_ACCOUNT = 'create-account',
	VERIFY_EMAIL = 'verify-email',
	UNION_STEP = 'union-step',
}

const SignUp = (props: SignUpProps) => {
	const dispatch = useAppDispatch();

	const navigate = useNavigate();

	const [checkEmailForm, setCheckEmailForm] =
		React.useState<CheckEmailFormikValues | null>(null);
	const [createAccountForm, setCreateAccountForm] =
		React.useState<Partial<CreateAccountFormikValues> | null>(null);

	const {
		signUpFormStep: formStep,
		isSigningUp,
		userEmail,
		userToken,
		userId,
	} = useAppSelector(state => state.auth);
	const { userProfile } = useAppSelector(state => state.user);

	const setFormStep = useCallback(
		(step: SignUpFormStep) => dispatch(setSignUpFormStepAction(step)),
		[dispatch]
	);

	const handleFinishSignUp = useCallback(() => {
		dispatch(finishSignUpProcessAction());
		navigate('/', { replace: true });
	}, [dispatch, navigate]);

	const handleCheckEmailSubmit = useCallback(
		async (values: CheckEmailFormikValues) => {
			setCheckEmailForm(values);
			setCreateAccountForm({
				email: values.email,
				legalName: values.claimingProfile?.name || '',
				creditedName: values.claimingProfile?.creditedName || '',
			});
			setFormStep(SignUpFormStep.CREATE_ACCOUNT);
		},
		[setFormStep]
	);

	const handleCreateAccountSubmit = useCallback(
		async (values: CreateAccountFormikValues) => {
			setCreateAccountForm(values);

			const res = await AccountService.createAccount({
				email: checkEmailForm?.email!,
				legalName: values.legalName!,
				creditedName: values.creditedName!,
				password: Buffer.from(values.password!).toString('base64'),
				dateOfBirth: values.dateOfBirth!,
				claimingProfileId: checkEmailForm?.claimingProfileId!,
				claimingProfile: !!checkEmailForm?.claimingProfile!,
				country: values.country!,
			});

			if (!res.data.creationSuccessful) {
				console.error('Account creation failed', res);
				return;
			}

			// hit login endpoint to retrieve token and user ID
			await dispatch(loginAction(checkEmailForm?.email!, values.password!));

			console.log(res);

			setFormStep(SignUpFormStep.VERIFY_EMAIL);
		},
		[checkEmailForm, dispatch, setFormStep]
	);

	const showUnionMemberQuestion = useCallback(() => {
		dispatch(
			showModalAction(ARE_YOU_UNION_MEMBER_MODAL, {
				size: 'md',
				noHide: true,
				onPositiveClick: () => {
					setFormStep(SignUpFormStep.UNION_STEP);
					dispatch(hideModal());
				},
				onNegativeClick: () => {
					handleFinishSignUp();
					dispatch(hideModal());
				},
			})
		);
	}, [dispatch, handleFinishSignUp, setFormStep]);

	const handleVerifyEmailSubmit = useCallback(
		async (values: VerifyEmailFormikValues) => {
			const res = await AccountService.verifyEmail(
				checkEmailForm?.email! || userEmail,
				values.code!
			);

			if (!res.data.verified) {
				console.error('Email verification failed', res);
				dispatch(setEmailVerifiedAction(false));
				throw new Error('Invalid verification code');
			}

			dispatch(setEmailVerifiedAction(true));

			debugger;

			// needed to retrieve country for union step in case the user entered the sign up flow
			// straight from the verify email step (i.e. tried to login with an unverified email)
			await dispatch(fetchUserDataAction());
			// needed to retrieve userPermissions and other tier limits needed for the app to work
			await dispatch(validateTokenAction(userToken, userId!));

			if (res.data.details.userPermissions) {
				dispatch(setUserPermissionsAction(res.data.details.userPermissions));
			}

			if (res.data.details.claimedPromo) {
				dispatch(
					showModalAction(CONFIRMATION_MODAL, {
						size: 'md',
						title: `YOU'VE GOT A SPECIAL BENEFIT FROM SOUND CREDIT`,
						description: res.data.details.promoText,
						onHide: () => {
							dispatch(hideModal());
							showUnionMemberQuestion();
						},
						confirmAction: {
							label: 'CONTINUE',
							onClick: () => {
								dispatch(hideModal());
								showUnionMemberQuestion();
							},
						},
					})
				);

				return;
			}

			showUnionMemberQuestion();
		},
		[checkEmailForm, dispatch, showUnionMemberQuestion, userEmail, userToken, userId]
	);

	const handleUnionStepSubmit = useCallback(
		async (values: UnionStepFormikValues) => {
			let res = await AccountService.updateProfileAddress({
				address1: values.address1!,
				address2: values.address2!,
				city: values.city!,
				state: values.state!,
				postCode: values.postCode!,
				country: values.country!,
			});

			console.log(res);

			if (values.ssnLastFour) {
				res = await AccountService.updateUnionSsn({
					ssnLastFour: values.ssnLastFour!,
				});
			}

			handleFinishSignUp();
		},
		[handleFinishSignUp]
	);

	const stepTitle = useMemo(() => {
		switch (formStep) {
			case SignUpFormStep.CHECK_EMAIL:
			case SignUpFormStep.CREATE_ACCOUNT:
				return 'Create Account';
			case SignUpFormStep.VERIFY_EMAIL:
				return 'Verify Email';
			case SignUpFormStep.UNION_STEP:
				return 'Your Union Info';
			default:
				return 'Unknown Step';
		}
	}, [formStep]);

	const handleBack = useMemo(() => {
		switch (formStep) {
			case SignUpFormStep.CHECK_EMAIL:
				return () => navigate(-1);
			case SignUpFormStep.CREATE_ACCOUNT:
				return () => setFormStep(SignUpFormStep.CHECK_EMAIL);
			case SignUpFormStep.VERIFY_EMAIL:
				return null;
			case SignUpFormStep.UNION_STEP:
				return null;
			default:
				return null;
		}
	}, [formStep, navigate, setFormStep]);

	const renderStep = useCallback(() => {
		switch (formStep) {
			case SignUpFormStep.CHECK_EMAIL:
				return (
					<CheckEmailStep
						onSubmit={handleCheckEmailSubmit}
						initialFormValues={checkEmailForm}
					/>
				);
			case SignUpFormStep.CREATE_ACCOUNT:
				return (
					<CreateAccountStep
						onSubmit={handleCreateAccountSubmit}
						initialFormValues={createAccountForm!}
					/>
				);
			case SignUpFormStep.VERIFY_EMAIL:
				return (
					<VerifyEmailStep
						onSubmit={handleVerifyEmailSubmit}
						email={checkEmailForm?.email!}
					/>
				);
			case SignUpFormStep.UNION_STEP:
				return (
					<UnionStep
						onSubmit={handleUnionStepSubmit}
						initialFormValues={{
							country: createAccountForm?.country || userProfile?.country || '',
						}}
					/>
				);
			default:
				console.error('Unknown step', formStep);
				return null;
		}
	}, [
		formStep,
		handleCreateAccountSubmit,
		handleVerifyEmailSubmit,
		handleCheckEmailSubmit,
		checkEmailForm,
		createAccountForm,
		handleUnionStepSubmit,
		userProfile,
	]);

	useEffect(() => {
		// isSigningUp may come pre-activated
		// from other places such as trying to
		// login with an unverified email
		// and we need to do a check so as not
		// to erase the existing form step (e.g the
		// previous example sets an initial step different
		// than the one that's set by default)
		if (!isSigningUp) {
			dispatch(startSignUpProcessAction());
		}

		return () => {
			dispatch(finishSignUpProcessAction());
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [dispatch]);

	return (
		<>
			<Helmet>
				<title>Create Account {process.env.REACT_APP_TAB_TITLE}</title>
			</Helmet>
			<div className={styles['sign-up-container']}>
				<SignUpHeader
					className={styles['sign-up-header']}
					title={
						<h2
							style={{
								whiteSpace: 'nowrap',
							}}
						>
							{stepTitle}
						</h2>
					}
					rightMenu={
						formStep === SignUpFormStep.VERIFY_EMAIL ? (
							<Button
								color='white'
								label='Back to Login'
								onClick={() => {
									dispatch(logoutAction());
									navigate('/login', { replace: true });
								}}
								icon='fas fa-arrow-left'
							/>
						) : null
					}
				/>
				<div className={styles['sign-up-content']}>
					{handleBack && (
						<IconButton
							icon='fas fa-arrow-left'
							onClick={handleBack}
							outerStyle={{
								alignSelf: 'flex-start',
							}}
							color='white'
						/>
					)}
					<div className={styles['sign-up-form-container']}>{renderStep()}</div>
				</div>
				<div></div>
			</div>
		</>
	);
};

export default SignUp;
