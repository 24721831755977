import { useState } from 'react'
import TextField from '../../../form/TextField'
import Button from '../../../layout/Button';
import './ShareModal.scss'
import Select from '../../../form/Select'

const statusOptions = [
  { label: 'Co-Admin', status: "true" },
  { label: 'Read Only', status: "" }
]

const ShareForm = ({ 
  handleSubmit,
  isSubmitting,
  informationMessage,
  email,
  message,
  handleMessageChange,
  handleEmailChange,
  informationText
 }) => {
  const [ selectValue, setSelectValue ] = useState(statusOptions[ 0 ])

  return <form onSubmit={handleSubmit}>
    <section className='share-email-grid'>
      <TextField
        label='Email'
        name='email'
        id='email'
        required
        errorMessage={informationMessage}
        informationText={informationText}
        isInvalid={informationMessage}
        value={email}
        onChange={handleEmailChange}
      />
      <Select
        label='Permission'
        name='editor'
        onChange={val => setSelectValue(val)}
        options={statusOptions}
        value={selectValue}
        // isDisabled={editor.is_read_only && true}
        getOptionValue={option => option.status}
        getOptionLabel={option => option.label}
        isSearchable={false}
      />
    </section>
    <section>
      <TextField
        label='Message / Request'
        name='message'
        id='message'
        rows={10}
        as='textarea'
        value={message}
        onChange={handleMessageChange}
      />
    </section>
    <section className='share-submit my-4'>
      <Button
        label='Done'
        theme='dark'
        type="submit"
        isLoading={isSubmitting}
      // onClick={handleSubmit} 
      />
    </section>
  </form>
}

export default ShareForm