import React from 'react';

import './Marker.scss';

const Marker = props => {
	const { name, count } = props;
	return (
		<div className='playlist-stats-map__marker'>
			<div
				className='pin bounce'
				style={{ cursor: 'pointer' }}
				title={name}
			></div>
			<div className='title'>{count}</div>
			<div className='pulse' />
		</div>
	);
};

export default Marker;
