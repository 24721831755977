import React, { useEffect, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import Button from '../../../layout/Button';
import theme from '../../../../theme.module.scss';
import { CREATE_NEW_RECORDING_MODAL } from '../../../../constants/modalTypes';
import { showModalAction } from '../../../../store/modal/actions';
import { useNavigate, useLocation } from 'react-router-dom';
import './GetStarted.scss';
import { useCallback } from 'react';
import ROUTES from '../../../../router/routes';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { replacePathVariables } from '@/helpers/routeTools';
import { Helmet } from 'react-helmet';
import ProjectsPlaceholderButton from './ProjectsPlaceholderButton';
import { getAssetPath } from '../../../../helpers/assetTools';
import { createPlaylistAction } from '../../../../store/playlists/actions';

const GetStarted = () => {
	const { currentRecordingId } = useAppSelector(state => state.projects);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const location = useLocation();

	const [isCreatingPlaylist, setIsCreatingPlaylist] = React.useState(false);

	const isEditorDashboard = useMemo(
		() => location && !location.pathname.includes(ROUTES.Projects.path),
		[location]
	);

	const handleNewPlaylist = useCallback(async () => {
		setIsCreatingPlaylist(true);

		try {
			await dispatch(
				createPlaylistAction({}, playlistId => {
					navigate(
						replacePathVariables(ROUTES.PlaylistDetails.path, { playlistId })
					);
				})
			);
		} finally {
			setIsCreatingPlaylist(false);
		}
	}, [dispatch, navigate]);

	const handleNewRecording = useCallback(() => {
		dispatch(
			showModalAction(CREATE_NEW_RECORDING_MODAL, {
				size: 'lg',
				onCreate: (id: number) => {
					if (isEditorDashboard) {
						navigate(
							replacePathVariables(ROUTES.EditRecording.path, {
								recordingId: id,
							})
						);
					}
				},
			})
		);
	}, [navigate, dispatch, isEditorDashboard]);

	// const handleNewAlbum = () => {
	// 	if (isEditorDashboard) {
	// 		navigate(ROUTES.Projects.path);
	// 	}

	// 	dispatch(showModalAction(CREATE_NEW_ALBUM_MODAL));
	// };

	const handleViewProjects = () => {
		navigate(ROUTES.Projects.path);
	};

	useEffect(() => {
		if (isEditorDashboard && currentRecordingId) {
			navigate(
				replacePathVariables(ROUTES.EditRecording.path, {
					recordingId: currentRecordingId,
				}),
				{
					replace: true,
				}
			);
		}
	}, [currentRecordingId, navigate, isEditorDashboard]);

	return (
		<>
			{isEditorDashboard && (
				<Helmet>
					<title>Editor {process.env.REACT_APP_TAB_TITLE}</title>
				</Helmet>
			)}
			<Container className='d-flex justify-content-center align-items-center h-100'>
				<div className='d-flex flex-column justify-content-center card get-started-container'>
					<h2 className='w-100 text-center'>
						{isEditorDashboard ? 'GET STARTED' : 'WELCOME TO SOUND CREDIT!'}
					</h2>

					<ProjectsPlaceholderButton
						title='Create a Recording'
						imageSrc={getAssetPath('img/recording-art1.png')}
						featureList={[
							'Add recording metadata',
							'Add credits',
							'Generate reports',
							'Upload files for this recording',
							'Add to a playlist later',
							'Edit credits and metadata with collaborators',
						]}
						onClick={handleNewRecording}
						className='mb-4'
					/>

					<ProjectsPlaceholderButton
						title='Create a Playlist'
						imageSrc={getAssetPath('img/playlist-art1.png')}
						featureList={[
							'Transfer your files',
							'Do revisions from TrackChat',
							'View usage stats',
							'Upload files to this playlist',
							'Edit playlist with collaborators',
							'Group multiple recording files',
						]}
						onClick={handleNewPlaylist}
						loading={isCreatingPlaylist}
					/>

					{/* <Button
						leftIcon='fas fa-plus-circle'
						label='Create New Recording'
						theme='dark'
						className='mb-3'
						onClick={handleNewRecording}
						size='lg'
					/>
					<Button
						leftIcon='fas fa-compact-disc'
						label='Create New Album'
						theme='dark'
						className='mb-3'
						onClick={handleNewAlbum}
						size='lg'
					/> */}
					{isEditorDashboard && (
						<>
							<p
								style={{
									width: '100%',
									textAlign: 'center',
									borderBottom: `1px solid ${theme.primary}`,
									lineHeight: '0.1em',
									margin: '20px 0 20px',
								}}
							>
								<span
									className='px-3'
									style={{
										backgroundColor: '#fff',
										color: theme.primary,
										fontWeight: 600,
									}}
								>
									OR
								</span>
							</p>
							<Button
								leftIcon='fas fa-folder'
								label='View Projects'
								className='mt-3'
								onClick={handleViewProjects}
								size='lg'
							/>
						</>
					)}
				</div>
			</Container>
		</>
	);
};

export default GetStarted;
