import { exportsApi } from '../exportsApi';
import { checkInvalidToken } from '../../helpers/checkInvalidToken';
import axiosRetry from 'axios-retry';
import ExportId from '../../constants/exportsIds';
import { shouldRetry } from '../../helpers/networkTools';
import translateApiExportToLocal from '../../helpers/translateApiExportToLocal';

axiosRetry(exportsApi, {
	retries: 3,
	retryDelay: retryCount => {
		return retryCount * 1000;
	},
	shouldResetTimeout: true,
	retryCondition: shouldRetry,
});

export const createExport = (payload: any, isPreview: boolean) =>
	exportsApi
		.post<{
			export: ApiExportMetadata | null;
			detail: string | null;
		}>('/export', payload, {
			responseType: isPreview ? 'blob' : 'json',
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

export const deleteExport = (id: number) =>
	exportsApi.delete('/export', { params: { export_id: id } }).catch(err => {
		checkInvalidToken(err);

		return Promise.reject(err);
	});

export const fetchRecordingExports = (recordingId: number) =>
	exportsApi
		.get('/user/recording', {
			params: {
				recording_id: recordingId,
			},
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

export const fetchAlbumExports = (albumId: number) =>
	exportsApi
		.get('/user/album', {
			params: {
				album_id: albumId,
			},
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

export const downloadExport = (exportId: number) =>
	exportsApi
		.get<Blob>('/export/download', {
			params: {
				export_id: exportId,
			},
			responseType: 'blob',
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

export const exportToRin = (recording: RecordingContent) =>
	exportsApi
		.post('/export/export-rin', {
			recording_id: recording.id,
			recording: recording,
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

export const importRin = (rin: string) =>
	exportsApi
		.post('/export/import-rin', {
			rin,
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

export const indieUpload = ({
	recordings,
	album_cover_mime_type = '',
	album_cover_image = '',
}: {
	recordings: RecordingContent[];
	album_cover_mime_type?: string;
	album_cover_image?: string;
}) =>
	exportsApi
		.post('/indie-upload', {
			recordings,
			album_cover_mime_type,
			album_cover_image,
		})
		.catch(err => {
			checkInvalidToken(err);

			return Promise.reject(err);
		});

export const getLatestContractNumber = () => exportsApi.get('/union-contracts');

export const setLatestContractNumber = (contractNumber: string) =>
	exportsApi.post('/union-contracts', null, {
		params: { contract_number: contractNumber },
	});

export const generateDripImages = ({
	playlistId,
	image,
	imageMimeType,
}: {
	playlistId: Playlist['id'];
	image: string;
	imageMimeType: string;
}): Promise<ExportMetadata> =>
	createExport(
		{
			report_type: ExportId.DRIP_IMAGES,
			share_link_id: playlistId,
			image,
			image_mime_type: imageMimeType,
			is_final_version: true,
			setLatestContractNumber,
		},
		false
	).then(res => {
		if (!res.data.export) {
			throw new Error('No export data found');
		}

		return translateApiExportToLocal(res.data.export);
	});

export const getDripImageExport = (playlistId: Playlist['id']) =>
	exportsApi.get<ApiExportMetadata>('/user/drip', {
		params: {
			share_link_id: playlistId,
		},
	});

export const getExportById = (exportId: number) =>
	exportsApi
		.get<{
			export: ApiExportMetadata;
		}>('/user/export', {
			params: {
				export_id: exportId,
			},
		})
		.then(res => res.data.export);

export const downloadExportESignature = (exportId: number) =>
	exportsApi.get<Blob>('/export/download/esignature', {
		params: {
			export_id: exportId,
		},
		responseType: 'blob',
	});
