import {
	deleteFile,
	shareFilesTemp,
	getAwsCredentialsForUpload,
	getFileDownloadLink,
	getFileMetadata,
	getProjectFilesMetadata,
	updateFileMetadata,
	uploadFileMetadata,
	getStorageUsage,
	getFileLabelDetails,
	addFileCoverImage,
	updateFileVersionMetadata,
	uploadFileVersionMetadata,
	deleteFileVersion,
	acceptPlaylistTransfer,
} from '../../api/services/filesService';
import {
	ACCEPT_PLAYLIST_TRANSFER_INVITE_REQUEST,
	ADD_FILE_COVER_IMAGE_REQUEST,
	DELETE_FILES_REQUEST,
	DELETE_FILE_VERSION_REQUEST,
	GENERATE_TEMP_SHARE_REQUEST,
	GET_FILE_LABEL_DETAILS_REQUEST,
	GET_FILE_METADATA_REQUEST,
	GET_PROJECT_FILES_METADATA_REQUEST,
	GET_STORAGE_USAGE_REQUEST,
	UPDATE_FILE_METADATA_REQUEST,
	UPDATE_FILE_VERSION_METADATA_REQUEST,
	UPLOAD_FILE_METADATA_REQUEST,
	UPLOAD_FILE_VERSION_METADATA_REQUEST,
} from '../../constants/requestLabelTypes';
import translateApiFileToLocal from '../../helpers/translateApiFileToLocal';
import {
	ADD_FETCHED_FILES,
	ADD_LOCAL_FILES,
	ADD_TO_UPLOAD_QUEUE,
	CANCEL_FILE_UPLOAD,
	CLEAR_FILES_STATE,
	CLEAR_UPLOAD_FROM_QUEUE,
	DELETE_LOCAL_FILES,
	DELETE_UPLOADS_IN_PROGRESS,
	FILE_REQUEST_AUTH_ERROR,
	FILE_REQUEST_ERROR,
	FILE_REQUEST_SUCCESS,
	FILE_UPLOAD_ERROR,
	FILE_UPLOAD_SUCCESS,
	RETRY_FILE_UPLOAD,
	SET_FILE_LABEL_DETAILS,
	SET_FILE_UPLOAD_ABORT_CONTROLLER,
	SET_FILE_UPLOAD_PROGRESS,
	SET_IMAGE_FILE_THUMBNAIL,
	SET_STORAGE_LIMIT,
	SET_STORAGE_USAGE,
	START_FILE_REQUEST,
	START_FILE_UPLOAD,
	UPDATE_LOCAL_FILE,
	ADD_TO_DOWNLOAD_QUEUE,
	CANCEL_FILE_DOWNLOAD,
	DELETE_DOWNLOADS_IN_PROGRESS,
	FILE_DOWNLOAD_ERROR,
	FILE_DOWNLOAD_SUCCESS,
	RETRY_FILE_DOWNLOAD,
	SET_FILE_DOWNLOAD_ABORT_CONTROLLER,
	SET_FILE_DOWNLOAD_PROGRESS,
	START_FILE_DOWNLOAD,
	CLEAR_DOWNLOAD_FROM_QUEUE,
	ADD_UPLOAD_FILES_FOLDERS,
	ADD_TO_FOLDER_DOWNLOAD_QUEUE,
	DELETE_FOLDER_DOWNLOAD_QUEUE,
	ADD_FOLDER_TO_DOWNLOAD_QUEUE,
} from '../actionTypes';
import { showErrorAlert } from '../alertToast/actions';
import {
	invalidTokenAction,
	setS3FileUploadCredentialsAction,
} from '../auth/actions';
import axios from 'axios';
import { Upload } from '@aws-sdk/lib-storage';
import { S3Client } from '@aws-sdk/client-s3';
import {
	generateCoverImagePath,
	isAudioFile,
	removeLastNumberInParentheses,
} from '../../helpers/fileTools';
import { Buffer } from 'buffer';
import {
	decrementLocalAlbumAssetsCountAction,
	decrementLocalRecordingAssetsCountAction,
	fetchRecordingByIdAction,
	incrementLocalAlbumAssetsCountAction,
	incrementLocalRecordingAssetsCountAction,
} from '../projects/actions';
import { getFilesById } from './selectors';
import {
	addFilesToPlaylistAction,
	deleteUploadsFromPlaylistAction,
	fetchPlaylistAction,
	fetchUserPlaylistsAction,
	updatePlaylistFileGroupFilesAction,
} from '../playlists/actions';
import { AppDispatch, GetState } from '..';
import { downloadToFileSystem } from '../../helpers/downloadTools';
import {
	S3_DOWNLOAD_METADATA_HEADERS,
	S3_UPLOAD_METADATA_HEADERS,
} from '../../helpers/uploadTools';
import { AxiosResponse } from 'axios';
import { browserName } from 'react-device-detect';
import * as mm from 'music-metadata-browser';
import { useAppSelector } from '../hooks';

axios.defaults.adapter = require('axios/lib/adapters/http');

const DOWNLOAD_CLEAR_TIMEOUT = 10000;

export const handleFileError = (
	error: any,
	dispatch: AppDispatch,
	message: string
) => {
	console.error(error);
	if (error.response) {
		switch (error.response.status) {
			case 401:
				dispatch(fileRequestAuthErrorAction());
				break;
			default:
				console.log(error.response);
				const errorMessage =
					message ??
					'Hiccup detected while syncing files with the cloud. Try clicking Refresh Projects.';
				dispatch(showErrorAlert(`${errorMessage} (${error.response.status})`));
				dispatch(fileRequestErrorAction(errorMessage));
		}
	} else {
		throw error;
	}
};

export const startFileRequestAction = (requestLabel: string) => ({
	type: START_FILE_REQUEST,
	requestLabel,
});

export const fileRequestErrorAction = (errorMessage: string) => ({
	type: FILE_REQUEST_ERROR,
	errorMessage,
});

export const fileRequestSuccessAction = () => ({
	type: FILE_REQUEST_SUCCESS,
});

export const fileRequestAuthErrorAction = () => (dispatch: AppDispatch) => {
	dispatch(invalidTokenAction());

	return {
		type: FILE_REQUEST_AUTH_ERROR,
	};
};

export const clearFilesAction = () => ({
	type: CLEAR_FILES_STATE,
});

export const updateCloudFileMetadataAction =
	({ id, label }: { id: number; label: number }) =>
	(dispatch: AppDispatch) => {
		dispatch(startFileRequestAction(UPDATE_FILE_METADATA_REQUEST));

		return updateFileMetadata({
			fileId: id,
			label: label,
		})
			.then(newFile => {
				dispatch(fileRequestSuccessAction());

				dispatch(
					updateLocalFileAction({
						...newFile,
					})
				);
			})
			.catch(error =>
				handleFileError(
					error,
					dispatch,
					'Whoops! Hiccup detected while updating the file.'
				)
			);
	};

export const uploadFileMetadataAction =
	(
		fileMetadata: CreateFileMetadataRequest,
		playlistFileMetadata: null | {
			displayName: string | null;
			playlistId: Playlist['id'];
		} = null,
		onUpload?: (file: FileMetadata) => Promise<void>
	) =>
	(dispatch: AppDispatch) => {
		dispatch(startFileRequestAction(UPLOAD_FILE_METADATA_REQUEST));

		return uploadFileMetadata(fileMetadata)
			.then(file => {
				dispatch(fileRequestSuccessAction());

				dispatch(
					addLocalFilesAction({
						files: [file],
					})
				);

				if (playlistFileMetadata) {
					const { playlistId, displayName } = playlistFileMetadata;
					const playlistFile = {
						displayName,
						id: file.id,
					};

					dispatch(addFilesToPlaylistAction(playlistId, [playlistFile]));
				}

				if (onUpload) {
					return onUpload(file);
				}
			})
			.catch(error =>
				handleFileError(
					error,
					dispatch,
					'Whoops! Hiccup detected while uploading the file!'
				)
			);
	};

export const getAwsCredentialsForUploadAction =
	() => (dispatch: AppDispatch) => {
		return getAwsCredentialsForUpload()
			.then(({ accessKeyId, secretAccessKey, sessionToken, expiration }) => {
				dispatch(
					setS3FileUploadCredentialsAction({
						accessKeyId,
						secretAccessKey,
						sessionToken,
						expiration,
					})
				);
			})
			.catch(error =>
				handleFileError(
					error,
					dispatch,
					'Whoops! Hiccup detected while getting permissions for the file upload.'
				)
			);
	};

// ! Untested method for signed URLs
// export const uploadFilesToBucketWithSignedUrlAction =
// 	(files: ProjectUpload[]) => (dispatch: AppDispatch) => {
// 		files.forEach(async file => {
// 			// first we need to get a signed url for the file upload
// 			try {
// 				dispatch(startFileUploadAction(file.id));
// 				const signedUrlRes = await getSignedUrlForUpload(file.path);
// 				const signedUrl = signedUrlRes.data;

// 				const formPayload = new FormData();
// 				formPayload.append('file', file.file);

// 				await axios({
// 					url: signedUrl,
// 					method: 'put',
// 					data: formPayload,
// 					cancelToken: file.cancelSource.token,
// 					onUploadProgress: progress => {
// 						const { loaded, total } = progress;
// 						const percentage = Math.floor((loaded / total) * 100);
// 						dispatch(setFileUploadProgressAction(file.id, percentage));
// 					},
// 				});

// 				dispatch(fileUploadSuccessAction(file.id));
// 				dispatch(uploadFileMetadataAction(file.metadata));
// 			} catch (error) {
// 				if (axios.isCancel(error)) {
// 					dispatch(cancelFileUploadAction(file.id));
// 				} else {
// 					dispatch(fileUploadErrorAction(file.id, 'Error: Funk not found'));
// 				}
// 			}
// 		});
// 	};

export const addFileCoverImageAction =
	(fileId: FileMetadata['id'], coverImagePath: string | null) =>
	(dispatch: AppDispatch) => {
		dispatch(startFileRequestAction(ADD_FILE_COVER_IMAGE_REQUEST));

		return addFileCoverImage({ fileId, coverImagePath })
			.then(res => {
				dispatch(fileRequestSuccessAction());

				const file = translateApiFileToLocal(res.data.file);

				dispatch(updateLocalFileAction(file));
			})
			.catch(error =>
				handleFileError(
					error,
					dispatch,
					`Whoops! Hiccup detected while adding the cover image.`
				)
			);
	};

/**
 * Uploads file cover image to the S3 bucket
 * and upload metadata to the API
 */
export const uploadFileCoverImageAction =
	(fileId: FileMetadata['id'], image: File) =>
	async (dispatch: AppDispatch, getState: GetState) => {
		const userId = getState().auth.userId!;
		const path = await generateCoverImagePath({
			image,
			fileId,
			userId,
			isHeader: false,
		});

		const imageObj: ImageUpload = {
			file: image,
			metadata: {
				fileId,
				path,
			},
		};

		await dispatch(uploadImagesToBucketWithCredentialsAction([imageObj]));

		await dispatch(addFileCoverImageAction(fileId, path));
	};

export const uploadImagesToBucketWithCredentialsAction =
	(images: ImageUpload[], onUpload?: (img: ImageUpload) => Promise<void>) =>
	async (dispatch: AppDispatch) => {
		try {
			const client = new S3Client({
				credentials: getAwsCredentialsForUpload,
				region: 'us-west-2',
				maxAttempts: 5,
			});

			for (const image of images) {
				const upload = new Upload({
					client,
					params: {
						Bucket: process.env.REACT_APP_FILE_UPLOAD_S3_ARN,
						Key: image.metadata.path, // same as file.id
						Body: image.file,
					},
					leavePartsOnError: false,
				});

				// upload the file asynchronously
				try {
					await upload.done();

					if (onUpload) {
						return onUpload(image);
					}
				} catch (error: any) {
					// check if the upload was cancelled
					console.log('UPLOAD ERROR', JSON.stringify(error));
					switch (error.name) {
						default:
							dispatch(
								showErrorAlert(
									`Whoops! Hiccup detected while uploading the image(s). ${error.name}`
								)
							);
							throw error;
					}
				}
			}
		} catch (error) {
			dispatch(
				showErrorAlert('Whoops! Hiccup detected while uploading the file(s).')
			);

			console.error(error);
			throw error;
		}
	};

const moveFileToFolder = async (
	dispatch: AppDispatch,
	playlistId: Playlist['id'],
	groupId: PlaylistFolder['id'],
	fileIds: FileMetadata['id'][]
) => {
	await dispatch(
		updatePlaylistFileGroupFilesAction({
			playlistId: playlistId,
			groupId: groupId,
			fileIds: fileIds,
		})
	);
};

const findFolderById = (
	folders: PlaylistFolder[],
	targetId: number
): PlaylistFolder | null => {
	// Iterate through each folder
	for (let folder of folders) {
		// Check if the current folder's id matches the targetId
		if (folder.id === targetId) {
			return folder; // Return the folder if found
		}

		// If the current folder has nested folders, recursively search through them
		if (folder.folders.length > 0) {
			let foundFolder: PlaylistFolder | null = findFolderById(
				folder.folders,
				targetId
			);
			if (foundFolder) {
				return foundFolder; // Return the folder if found in nested folders
			}
		}
	}

	// If no folder with the targetId is found, return null or handle accordingly
	return null;
};

// * Tested method used with temporary credentials
export const uploadFilesToBucketWithCredentialsAction =
	(
		files: ProjectFileUpload[],
		uploadFilesFolders: {
			[key: string]: { [key: string]: number[] };
		} = {}
	) =>
	async (dispatch: AppDispatch) => {
		// set the "IN_PROGRESS" upload state for each file
		files.forEach(file => {
			dispatch(startFileUploadAction(file.id));
		});

		try {
			const client = new S3Client({
				credentials: getAwsCredentialsForUpload, // the SDK will fetch the credentials at first and 5 minutes before expiration
				region: 'us-west-2',
				maxAttempts: 5,
			});

			for (const file of files) {
				console.log('FILE TYPE', typeof file.file);

				const upload = new Upload({
					client,
					params: {
						Bucket: process.env.REACT_APP_FILE_UPLOAD_S3_ARN,
						Key: file.metadata.path, // same as file.id
						Body: file.file,
						ContentDisposition: `attachment; filename="${encodeURIComponent(
							file.metadata.filename
						)}"`, // needed for file name to be correct when downloading from S3
						ContentType: file.file.type, // needed for correct playback in the browser
						Metadata: {
							[S3_UPLOAD_METADATA_HEADERS.createdAt]:
								file.metadata.sourceCreatedAt?.toString() ?? '',
							[S3_UPLOAD_METADATA_HEADERS.updatedAt]:
								file.metadata.sourceUpdatedAt?.toString() ?? '',
						},
					},
					leavePartsOnError: false, // if an error occurs, delete the parts that have been uploaded, since we don't support resuming uploads yet
				});

				// dispatch the upload state change
				dispatch(
					setFileUploadAbortFnAction(file.id, upload.abort.bind(upload))
				);

				try {
					// setup a progress listener
					upload.on('httpUploadProgress', progress => {
						const { loaded, total, Key } = progress;
						if (!loaded || !total || !Key) return;
						const percentage = Math.floor((loaded / total) * 100);

						dispatch(setFileUploadProgressAction(Key, percentage));
					});
					// upload the file asynchronously
					await upload.done();
					if (file.versionMetadata)
						// Check if it's a file version upload (for an existing file)
						// If so, we need to update the file version metadata
						await dispatch(
							uploadFileVersionMetadataAction({
								path: file.metadata.path,
								fileId: file.versionMetadata.fileId,
								duration: file.metadata.duration,
								fileSize: file.metadata.fileSize,
								comment: file.versionMetadata.comment,
								filename: file.metadata.filename,
								setActive: file.versionMetadata.isActiveVersion,
								sourceUpdatedAt: new Date(
									file.metadata.sourceUpdatedAt!
								).toISOString(),
								sourceCreatedAt: new Date(
									file.metadata.sourceCreatedAt!
								).toISOString(),
							})
						);
					// otherwise, we need to upload the file metadata
					else {
						await dispatch(
							uploadFileMetadataAction(
								{
									...file.metadata,
									sourceCreatedAt: new Date(
										file.metadata.sourceCreatedAt!
									).toISOString(),
									sourceUpdatedAt: new Date(
										file.metadata.sourceUpdatedAt!
									).toISOString(),
								},
								file.playlistMetadata,
								async assetFile => {
									if (!isAudioFile(assetFile.filename)) return;

									// upload file cover image if it exists in metadata
									const metadata = await mm.parseBlob(file.file);

									if (metadata.common.picture) {
										const coverImage = metadata.common.picture[0];
										const imageFile = new File(
											[coverImage.data],
											`${assetFile.id}.jpg`,
											{ type: coverImage.format }
										);

										await dispatch(
											uploadFileCoverImageAction(assetFile.id, imageFile)
										);
									}
								}
							)
						);
					}

					await dispatch(deleteUploadsInProgressAction([file.id]));

					console.log('On End Upload:');
					console.log('File:', file);
					if (file.playlistMetadata && file.playlistMetadata.playlistId) {
						let fetchedFiles: PlaylistFileMetadata[] | undefined = undefined;
						let counter: number = 0;
						let fetchedPlaylist:
							| void
							| { type: string; playlists: Playlist[] }
							| undefined = undefined;
						// Sometimes the file is not yet added to the fetchedPlaylist so we need to retry
						while (!fetchedFiles || fetchedFiles.length === 0) {
							console.log('Counter:', counter);
							counter++;
							fetchedPlaylist = await dispatch(
								fetchPlaylistAction(file.playlistMetadata.playlistId)
							);
							console.log('fetchedPlaylist:', fetchedPlaylist);
							fetchedFiles =
								fetchedPlaylist?.playlists[0].playlist?.files.filter(
									(f: any) =>
										file.file.name === f.filename &&
										file.metadata.recordingId === f.recordingId
								);
						}
						console.log('fetchedFiles:', fetchedFiles);
						if (fetchedFiles && fetchedFiles[0].recordingId) {
							let fetchedFile = fetchedFiles[0];
							console.log('fetchedFile:', fetchedFile);
							if (fetchedFile.recordingId) {
								console.log('File Id:', fetchedFile.id);
								const fileName: string = removeLastNumberInParentheses(
									file.file.name
								);
								const fileSize: string = file.file.size.toString();
								const fileKey: string = fileName + '|' + fileSize;
								const recordingKey =
									fetchedFile.recording?.title +
									'/' +
									fetchedFile.recording?.artist;
								console.log('uploadFilesFolders:', uploadFilesFolders);
								console.log(
									'uploadFilesFolders keys Length:',
									Object.keys(uploadFilesFolders).length
								);
								if (Object.keys(uploadFilesFolders).length === 0) return;
								console.log('recordingKey:', recordingKey);
								console.log('fileKey:', fileKey);
								if (
									!uploadFilesFolders.hasOwnProperty(recordingKey) ||
									!uploadFilesFolders[recordingKey].hasOwnProperty(fileKey) ||
									uploadFilesFolders[recordingKey][fileKey].length === 0
								)
									return;
								const folderId: number =
									uploadFilesFolders[recordingKey][fileKey].shift()!;
								console.log('recordingKey:', recordingKey);
								console.log('FOr recording:', uploadFilesFolders[recordingKey]);
								console.log('Name:', fileName);
								console.log('FOlderID Number:', folderId);
								console.log(
									'Fodler Id Any:',
									uploadFilesFolders[recordingKey][fileKey]
								);
								console.log('File:', fileName, ' Folder Id:', folderId);

								// Need name or id of root folder

								// Search for my folder in Folders to obtain fileIds
								if (fetchedPlaylist?.playlists[0].playlist?.folders) {
									const folder = findFolderById(
										fetchedPlaylist?.playlists[0].playlist?.folders,
										folderId
									);
									if (folder) {
										const currentFolderFileIds = [
											...folder.fileIds,
											fetchedFile.id,
										];
										console.log('currentFolderFileIds:', currentFolderFileIds);
										await moveFileToFolder(
											dispatch,
											file.playlistMetadata.playlistId,
											folderId,
											currentFolderFileIds
										);
										console.log('Finished moving file:', file.file.name);
									}
								}
								// const recording = await dispatch(
								// 	fetchRecordingByIdAction({
								// 		id: fetchedFile.recordingId,
								// 		onFetch: (recording: Recording | undefined) => {
								// 			return recording;
								// 		},
								// 	})
								// );
								// console.log('recording:', recording);
							}
						}
					}

					if (file?.versionMetadata?.playlistId) {
						let fetchedPlaylist = await dispatch(
							fetchPlaylistAction(file.versionMetadata.playlistId)
						);
						console.log('fetchedPlaylist:', fetchedPlaylist);
					}
				} catch (error: any) {
					let errorMessage = error.message;
					// check if the upload was cancelled
					console.error('UPLOAD ERROR', JSON.stringify(error));
					switch (error.name) {
						case 'AbortError':
							dispatch(cancelFileUploadAction(file.id));
							break;
						case 'NotReadableError':
							errorMessage = `${browserName} does not support 4GB+ file uploads. Please try using Chrome or the desktop app.`;
						// eslint-disable-next-line no-fallthrough
						default:
							dispatch(fileUploadErrorAction(file.id, errorMessage));
					}
				}
			}
		} catch (error) {
			console.log('UPLOAD ERROR', error);

			dispatch(
				showErrorAlert('Whoops! Hiccup detected while uploading the file(s).')
			);

			files.forEach(file => {
				dispatch(fileUploadErrorAction(file.id, 'Error: Funk not found'));
			});

			console.log(error);
		}
	};

export const startFileUploadAction = (uploadId: string) => ({
	type: START_FILE_UPLOAD,
	uploadId,
});

export const setFileUploadAbortFnAction = (
	uploadId: string,
	abort: () => Promise<void>
) => ({
	type: SET_FILE_UPLOAD_ABORT_CONTROLLER,
	uploadId,
	abort,
});

export const addToUploadQueueAction = (
	uploads: Record<string, ProjectFileUpload>
) => ({
	type: ADD_TO_UPLOAD_QUEUE,
	uploads,
});

export const abortFileUploadAction =
	(uploadId: string) => async (dispatch: AppDispatch, getState: GetState) => {
		const { uploadsQueueById } = getState().files;
		const upload = uploadsQueueById[uploadId];

		if (upload?.abort) {
			// the S3 upload error handler will handle the cancellation
			// and dispatch the cancelFileUploadAction over there
			await upload.abort();
		} else {
			return dispatch(cancelFileUploadAction(uploadId));
		}
	};

/**
 *
 * ! Only use after calling the upload's abort() method. Otherwise, the S3 upload will continue
 */
export const cancelFileUploadAction = (uploadId: string) => ({
	type: CANCEL_FILE_UPLOAD,
	uploadId,
});

export const fileUploadSuccessAction = (uploadId: string) => ({
	type: FILE_UPLOAD_SUCCESS,
	uploadId,
});

export const fileUploadErrorAction = (
	uploadId: string,
	errorMessage: string
) => ({
	type: FILE_UPLOAD_ERROR,
	uploadId,
	errorMessage,
});

export const setFileUploadProgressAction = (
	uploadId: string,
	progress: number
) => ({
	type: SET_FILE_UPLOAD_PROGRESS,
	uploadId,
	progress,
});

export const deleteUploadsInProgressAction =
	(uploadIds: string[]) => (dispatch: AppDispatch, getState: GetState) => {
		const { uploadsQueueById } = getState().files;
		const uploads = uploadIds.map(id => uploadsQueueById[id]).filter(Boolean);

		uploads.forEach(upload => {
			if (upload.playlistMetadata) {
				const { playlistId } = upload.playlistMetadata;
				dispatch(deleteUploadsFromPlaylistAction(playlistId, [upload.id]));
			}
		});

		return dispatch({
			type: DELETE_UPLOADS_IN_PROGRESS,
			uploadIds,
		});
	};

export const deleteFilesAction =
	(fileIds: number[]) => (dispatch: AppDispatch, getState: GetState) => {
		dispatch(startFileRequestAction(DELETE_FILES_REQUEST));

		// check if the project referenced by the file has no other files
		// if so, refetch user projects since the project may have been deleted

		return deleteFile(fileIds[0])
			.then(res => {
				dispatch(fileRequestSuccessAction());

				return dispatch(deleteLocalFilesAction(fileIds));
			})
			.catch(error =>
				handleFileError(
					error,
					dispatch,
					`Whoops! Hiccup detected while deleting the file${
						fileIds.length > 1 ? 's' : ''
					}.`
				)
			);
	};

export const getProjectFilesMetadataAction =
	({
		albumId,
		recordingId,
		fetchAlbumRecordings = false,
	}: {
		albumId?: number | null;
		recordingId?: number | null;
		fetchAlbumRecordings?: boolean;
	}) =>
	(dispatch: AppDispatch) => {
		dispatch(startFileRequestAction(GET_PROJECT_FILES_METADATA_REQUEST));

		return getProjectFilesMetadata({
			albumId: recordingId ? null : albumId, // if we're fetching a recording's files, we don't need to pass the albumId
			recordingId,
			fetchAlbumRecordings,
		})
			.then(res => {
				dispatch(fileRequestSuccessAction());
				console.log('GET PROJECT FILES METADATA', res);
				dispatch(
					addFetchedFilesAction({ files: res.data.files, albumId, recordingId })
				);
			})
			.catch(error =>
				handleFileError(
					error,
					dispatch,
					"Whoops! Hiccup detected while fetching the project's files."
				)
			);
	};

export const getFileMetadataAction =
	(fileId: FileMetadata['id']) => (dispatch: AppDispatch) => {
		dispatch(startFileRequestAction(GET_FILE_METADATA_REQUEST));

		return getFileMetadata(fileId)
			.then(file => {
				dispatch(fileRequestSuccessAction());

				return dispatch(
					addFetchedFilesAction({
						files: [file],
						recordingId: file.recordingId,
						albumId: file.albumId,
					})
				);
			})
			.catch(error =>
				handleFileError(
					error,
					dispatch,
					'Whoops! Hiccup detected while fetching the file.'
				)
			);
	};

export const shareFilesTempAction =
	({
		fileIds,
		recipientEmail,
		recordingId = null,
		albumId = null,
	}: {
		fileIds: number[];
		recipientEmail: string;
		recordingId?: number | null;
		albumId?: number | null;
	}) =>
	(dispatch: AppDispatch) => {
		dispatch(startFileRequestAction(GENERATE_TEMP_SHARE_REQUEST));

		return shareFilesTemp({ fileIds, recipientEmail, recordingId, albumId })
			.then(_ => {
				dispatch(fileRequestSuccessAction());
			})
			.catch(error =>
				handleFileError(
					error,
					dispatch,
					'Whoops! Hiccup detected while sharing the files.'
				)
			);
	};

export const setImageFileThumbnailAction = (
	fileId: FileMetadata['id'],
	thumbnail: string
) => ({
	type: SET_IMAGE_FILE_THUMBNAIL,
	fileId,
	thumbnail,
});

export const downloadImageThumbnailAction =
	({ id, displayFile, activeVersion }: FileMetadata) =>
	async (dispatch: AppDispatch) => {
		// first we need to get the download URL
		try {
			const fileLinkRes = await getFileDownloadLink({
				fileId: displayFile?.id ?? id,
				versionId: displayFile?.activeVersion ?? activeVersion,
			});
			const assetLink = fileLinkRes.data.asset_link;

			// then download the file as base64
			const thumbnailRes = await axios.get(assetLink, {
				responseType: 'arraybuffer',
			});
			const base64Image = Buffer.from(thumbnailRes.data, 'binary').toString(
				'base64'
			);
			const dataUrl = `data:${thumbnailRes.headers['content-type']};base64,${base64Image}`;
			dispatch(setImageFileThumbnailAction(id, dataUrl));
		} catch (e) {
			console.log(e);
			handleFileError(
				e,
				dispatch,
				'Whoops! Hiccup detected while getting file thumbnails.'
			);
		}
	};

export const updateLocalFileAction = (file: FileMetadata) => ({
	type: UPDATE_LOCAL_FILE,
	file,
});

export const addLocalFilesAction =
	({
		files,
		recordingId,
		albumId,
	}: {
		files: FileMetadata[];
		recordingId?: number | null;
		albumId?: number | null;
	}) =>
	(dispatch: AppDispatch) => {
		files?.forEach(file => {
			if (file.recordingId) {
				dispatch(
					incrementLocalRecordingAssetsCountAction(file?.recordingId, 1)
				);
			} else if (file.albumId) {
				dispatch(incrementLocalAlbumAssetsCountAction(file?.albumId, 1));
			}
		});

		dispatch({
			type: ADD_LOCAL_FILES,
			files,
			recordingId,
			albumId,
		});
	};

export const addFetchedFilesAction = ({
	files,
	recordingId,
	albumId,
}: {
	files: FileMetadata[];
	recordingId?: number | null;
	albumId?: number | null;
}) => ({
	type: ADD_FETCHED_FILES,
	files,
	recordingId,
	albumId,
});

export const deleteLocalFilesAction =
	(fileIds: number[]) => (dispatch: AppDispatch, getState: GetState) => {
		console.log('filesByProjectId', getState());
		const filesById = getFilesById(getState());

		const filesToDelete = fileIds.map(id => filesById[id]);

		filesToDelete.forEach(file => {
			if (file?.recordingId) {
				dispatch(
					decrementLocalRecordingAssetsCountAction(file?.recordingId, 1)
				);
			} else if (file?.albumId) {
				dispatch(decrementLocalAlbumAssetsCountAction(file?.albumId, 1));
			}
		});

		dispatch({
			type: DELETE_LOCAL_FILES,
			fileIds,
		});
	};

export const clearUploadFromQueueAction = (uploadId: string) => ({
	type: CLEAR_UPLOAD_FROM_QUEUE,
	uploadId,
});

export const retryFileUploadAction = (uploadId: string) => ({
	type: RETRY_FILE_UPLOAD,
	uploadId,
});

export const setStorageLimitAction = (storageLimit: number) => ({
	type: SET_STORAGE_LIMIT,
	storageLimit,
});

export const setStorageUsageAction = (storageUsage: number) => ({
	type: SET_STORAGE_USAGE,
	storageUsage,
});

export const getStorageUsageAction = () => (dispatch: AppDispatch) => {
	dispatch(startFileRequestAction(GET_STORAGE_USAGE_REQUEST));

	return getStorageUsage()
		.then(res => {
			dispatch(fileRequestSuccessAction());
			dispatch(setStorageUsageAction(res.data));
		})
		.catch(error => {
			handleFileError(
				error,
				dispatch,
				'Whoops! Hiccup detected while getting profile information.'
			);
		});
};

export const setFileLabelDetails = (labels: FileLabelDetailsType) => ({
	type: SET_FILE_LABEL_DETAILS,
	labels,
});

export const fetchFileLabelDetailsAction = () => (dispatch: AppDispatch) => {
	dispatch(startFileRequestAction(GET_FILE_LABEL_DETAILS_REQUEST));

	return getFileLabelDetails()
		.then(labels => {
			dispatch(fileRequestSuccessAction());
			dispatch(setFileLabelDetails(labels));
		})
		.catch(error => {
			handleFileError(
				error,
				dispatch,
				'Whoops! Hiccup detected while getting file label details.'
			);
		});
};

export const startFileDownloadAction = (downloadId: string) => ({
	type: START_FILE_DOWNLOAD,
	downloadId,
});

export const setFileDownloadAbortControllerAction = (
	downloadId: string,
	abortController: AbortController
) => ({
	type: SET_FILE_DOWNLOAD_ABORT_CONTROLLER,
	downloadId,
	abortController,
});

export const addUploadFilesFolders = (
	// uploads: Record<string, ProjectFileUpload>
	uploads: {
		[key: string]: { [key: string]: number[] };
	}
) => ({
	type: ADD_UPLOAD_FILES_FOLDERS,
	uploads,
});

export const addToFolderDownloadQueueAction = ({
	// file,
	// versionId,
	// fileHandle,
	// nativeHandle,
	folder,
}: {
	// versionId?: number;
	// fileHandle: FileSystemFileHandle | null;
	// file: FileMetadata;
	// nativeHandle?: WritableNativeFileHandle | null;
	folder: PlaylistFolder;
}) => ({
	type: ADD_TO_FOLDER_DOWNLOAD_QUEUE,
	folder,
});

export const deleteFolderDownloadQueueAction = () => ({
	type: DELETE_FOLDER_DOWNLOAD_QUEUE,
});

export const addToDownloadQueueAction = ({
	file,
	versionId,
	fileHandle,
	nativeHandle,
}: {
	versionId?: number;
	fileHandle: FileSystemFileHandle | null;
	file: FileMetadata;
	nativeHandle?: WritableNativeFileHandle | null;
}) => ({
	type: ADD_TO_DOWNLOAD_QUEUE,
	fileHandle,
	versionId: versionId ?? file.activeVersion,
	file,
	nativeHandle,
});

export const addFolderToDownloadQueueAction = ({
	folder,
}: {
	folder: any;
}) => ({
	type: ADD_FOLDER_TO_DOWNLOAD_QUEUE,
	folder,
});

export const cancelFileDownloadAction = (downloadId: string) => ({
	type: CANCEL_FILE_DOWNLOAD,
	downloadId,
});

export const fileDownloadSuccessAction = (downloadId: string) => ({
	type: FILE_DOWNLOAD_SUCCESS,
	downloadId,
});

export const fileDownloadErrorAction = (
	downloadId: string,
	errorMessage: string
) => ({
	type: FILE_DOWNLOAD_ERROR,
	downloadId,
	errorMessage,
});

export const setFileDownloadProgressAction = (
	downloadId: string,
	progress: number
) => ({
	type: SET_FILE_DOWNLOAD_PROGRESS,
	downloadId,
	progress,
});

export const clearDownloadFromQueueAction = (downloadId: string) => ({
	type: CLEAR_DOWNLOAD_FROM_QUEUE,
	downloadId,
});

export const retryFileDownloadAction = (downloadId: string) => ({
	type: RETRY_FILE_DOWNLOAD,
	downloadId,
});

export const deleteDownloadsInProgressAction = (downloadIds: string[]) => ({
	type: DELETE_DOWNLOADS_IN_PROGRESS,
	downloadIds,
});

export const downloadFileFromQueueAction =
	(downloadId: string) => async (dispatch: AppDispatch, getState: GetState) => {
		dispatch(startFileDownloadAction(downloadId));

		const { downloadQueueById } = getState().files;
		const download = downloadQueueById[downloadId];
		try {
			const assetLinkRes = await getFileDownloadLink({
				fileId: download.metadata.fileId,
				versionId: download.metadata.versionId,
			});

			const assetLink = assetLinkRes.data.asset_link;

			const abortController = new AbortController();

			dispatch(
				setFileDownloadAbortControllerAction(downloadId, abortController)
			);

			// then we can download the file
			const downloadRes = await axios.get<Blob>(assetLink, {
				responseType: 'blob',
				signal: abortController.signal,
				onDownloadProgress: progressEvent => {
					const progress = Math.round(
						(progressEvent.loaded * 100) / progressEvent.total
					);

					dispatch(setFileDownloadProgressAction(downloadId, progress));

					if (progress === 100) {
						dispatch(fileDownloadSuccessAction(downloadId));

						setTimeout(() => {
							dispatch(clearDownloadFromQueueAction(downloadId));
						}, DOWNLOAD_CLEAR_TIMEOUT);
					}
				},
			});

			// downloadRes.data.responseType = 'stream';

			const downloadBlob = downloadRes.data;

			// console.log('downloadStream', downloadStream);
			console.log('type', typeof downloadBlob);

			const fileTimestamps =
				extractTimestampsFromS3DownloadResponse(downloadRes);

			console.log('PARSED FILE TIMESTAMPS', fileTimestamps);

			console.log('File handle:', download.fileHandle);
			console.log('Native Handle:', download.nativeHandle);
			console.log('fileTimestamps:', fileTimestamps);

			await downloadToFileSystem({
				fileHandle: download.fileHandle,
				blob: downloadBlob,
				suggestedName: download.metadata.filename,
				suggestedTimestamps: fileTimestamps,
				nativeHandle: download.nativeHandle,
			});

			// if (!download.fileHandle) {
			// 	// use fallback if no fileHandle
			// }
			// // const fileStream = await download.fileHandle.createWritable();

			// downloadStream.pipeTo(fileStream);
		} catch (e: any) {
			switch (e.message) {
				case 'canceled': // AbortController throws this error when the request is canceled
					dispatch(cancelFileDownloadAction(downloadId));
					return;
				default:
					dispatch(fileDownloadErrorAction(downloadId, e.message));

					handleFileError(
						e,
						dispatch,
						'Whoops! Hiccup detected while downloading the file.'
					);
			}
		}
	};

export const extractTimestampsFromS3DownloadResponse = (
	response: AxiosResponse<Blob, any>
) => {
	const updatedAt = response.headers[S3_DOWNLOAD_METADATA_HEADERS.updatedAt];
	const createdAt = response.headers[S3_DOWNLOAD_METADATA_HEADERS.createdAt];

	if (!updatedAt || !createdAt) return null;

	return {
		updatedAt: parseInt(updatedAt),
		createdAt: parseInt(createdAt),
	};
};

export const updateFileVersionMetadataAction =
	({
		fileId,
		versionId,
		comment = undefined,
		makeActive = false,
		filename = undefined,
	}: {
		fileId: FileMetadata['id'];
		versionId: number;
		comment?: string;
		makeActive?: boolean;
		filename?: string;
	}) =>
	(dispatch: AppDispatch) => {
		dispatch(startFileRequestAction(UPDATE_FILE_VERSION_METADATA_REQUEST));

		return updateFileVersionMetadata({
			fileId,
			versionId,
			comment,
			makeActive,
			filename,
		})
			.then(file => {
				dispatch(fileRequestSuccessAction());

				return dispatch(updateLocalFileAction(file));
			})
			.catch(error => {
				handleFileError(
					error,
					dispatch,
					'Whoops! Hiccup detected while updating file version metadata.'
				);
			});
	};

export const uploadFileVersionMetadataAction =
	({
		fileId,
		path,
		comment,
		fileSize,
		duration,
		setActive = true,
		filename,
		sourceCreatedAt,
		sourceUpdatedAt,
	}: {
		fileId: FileMetadata['id'];
		path: string;
		comment: string;
		fileSize: number;
		duration: number | null;
		setActive?: boolean;
		filename: string;
		sourceCreatedAt: string;
		sourceUpdatedAt: string;
	}) =>
	(dispatch: AppDispatch) => {
		dispatch(startFileRequestAction(UPLOAD_FILE_VERSION_METADATA_REQUEST));

		return uploadFileVersionMetadata({
			fileId,
			path,
			comment,
			fileSize,
			duration,
			setActive,
			filename,
			sourceCreatedAt,
			sourceUpdatedAt,
		})
			.then(file => {
				dispatch(fileRequestSuccessAction());

				return dispatch(updateLocalFileAction(file));
			})
			.catch(error => {
				handleFileError(
					error,
					dispatch,
					'Whoops! Hiccup detected while uploading file version metadata.'
				);
			});
	};

export const deleteFileVersionAction =
	({
		fileId,
		versionId,
		playlistId,
	}: {
		fileId: FileMetadata['id'];
		versionId: number;
		playlistId?: number | null;
	}) =>
	(dispatch: AppDispatch) => {
		dispatch(startFileRequestAction(DELETE_FILE_VERSION_REQUEST));

		return deleteFileVersion({ fileId, versionId })
			.then(async file => {
				dispatch(fileRequestSuccessAction());

				if (playlistId) {
					await dispatch(fetchPlaylistAction(playlistId));
				}

				await dispatch(updateLocalFileAction(file));
			})
			.catch(error => {
				handleFileError(
					error,
					dispatch,
					'Whoops! Hiccup detected while deleting file version.'
				);
			});
	};

export const deleteFileCoverImageAction =
	(fileId: FileMetadata['id']) => (dispatch: AppDispatch) =>
		dispatch(addFileCoverImageAction(fileId, null));

export const acceptPlaylistTransferInviteAction =
	(playlistSlug: string, isAccepting: boolean) => (dispatch: AppDispatch) => {
		dispatch(startFileRequestAction(ACCEPT_PLAYLIST_TRANSFER_INVITE_REQUEST));

		return acceptPlaylistTransfer({ shareLinkSlug: playlistSlug, isAccepting })
			.then(async () => {
				dispatch(fileRequestSuccessAction());

				if (isAccepting) {
					await dispatch(fetchUserPlaylistsAction());
				}
			})
			.catch(error => {
				handleFileError(
					error,
					dispatch,
					'Whoops! Hiccup detected while accepting that playlist invite.'
				);
			});
	};
