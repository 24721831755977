import fileSize from 'filesize';
import React, { useEffect, useMemo } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { openUpgradeWebsite } from '../../../../helpers/tiersTools';
import { hideModal, setModalTitle } from '../../../../store/modal/actions';
import Button from '../../../layout/Button';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

const StorageLimitModal = ({ message }: { message?: string }) => {
	const dispatch = useAppDispatch();
	const { storageUsage } = useAppSelector(state => state.files);

	const limitMessage = useMemo(
		() =>
			storageUsage!.limit === storageUsage!.used
				? `You have reached your file storage limit of ${fileSize(
						storageUsage!.limit
				  )}.`
				: `Your current subscription plan allows you to store up to
        ${fileSize(
					storageUsage!.limit
				)} of data. Currently, you've used ${fileSize(storageUsage!.used)}.`,
		[storageUsage]
	);

	const modalTitle = useMemo(
		() =>
			storageUsage!.limit === storageUsage!.used
				? 'Storage Limit Reached'
				: 'Storage Limit',
		[storageUsage]
	);

	useEffect(() => {
		dispatch(setModalTitle(modalTitle));
	}, [dispatch, modalTitle]);

	return (
		<>
			<Modal.Body>
				<div className='px-4 fw-500'>
					<p>{message ?? limitMessage}</p>
					<p>
						Gain additional storage space and more benefits! Upgrade your plan
						now for enhanced capacity!
					</p>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Row>
					<Col xs={!2}>
						<Button label='Dismiss' onClick={() => dispatch(hideModal())} />
						<Button
							label='Upgrade'
							onClick={() => openUpgradeWebsite()}
							leftIcon='fas fa-angle-double-up'
							className='ml-2'
							theme='dark'
						/>
					</Col>
				</Row>
			</Modal.Footer>
		</>
	);
};

export default StorageLimitModal;
