import { isEqual, keyBy } from 'lodash';
import { createSelector } from 'reselect';
import { flattenToFilesById } from '../../helpers/fileTools';
import UPLOAD_STATUS from '../../constants/uploadStatus.json';
import { RootState } from '..';

const uploadsQueueById = (state: RootState) => state.files.uploadsQueueById;
const filesByRecordingId = (state: RootState) =>
	state.files.filesByProjectId.byRecordingId;
const filesByAlbumId = (state: RootState) =>
	state.files.filesByProjectId.byAlbumId;
const filesByProjectId = (state: RootState) => state.files.filesByProjectId;

export const getFilesByProjectId = createSelector(
	[
		filesByRecordingId,
		filesByAlbumId,
		(
			_: RootState,
			{
				albumId,
				recordingId,
			}: {
				albumId?: number | null;
				recordingId?: number | null;
			}
		) => ({ albumId, recordingId }),
	],
	(filesByRecordingId, filesByAlbumId, { albumId, recordingId }) => {
		if (recordingId) {
			return filesByRecordingId[recordingId] ?? {};
		} else if (albumId) {
			return filesByAlbumId[albumId] ?? {};
		} else {
			throw new Error('Must provide either albumId or recordingId');
		}
	},
	{
		memoizeOptions: {
			resultEqualityCheck: isEqual,
			equalityCheck: isEqual,
			maxSize: 1,
		},
	}
);

export const getUploadsByProjectId = createSelector(
	[
		uploadsQueueById,
		(
			_: RootState,
			{
				albumId,
				recordingId,
			}: {
				albumId?: number | null;
				recordingId?: number | null;
			}
		) => ({ albumId, recordingId }),
	],
	(uploadsQueueById, { albumId, recordingId }) => {
		if (recordingId) {
			return Object.values(uploadsQueueById).filter(
				(upload: ProjectFileUpload) =>
					upload.metadata.recordingId === recordingId
			);
		} else if (albumId) {
			return Object.values(uploadsQueueById).filter(
				(upload: ProjectFileUpload) => upload.metadata.albumId === albumId
			);
		}

		return [];
	},
	{
		memoizeOptions: {
			resultEqualityCheck: isEqual,
			equalityCheck: isEqual,
			maxSize: 1,
		},
	}
);

export const groupUploadsByProjectId = createSelector(
	uploadsQueueById,
	(uploadsQueueById): UploadsByProjectIdType =>
		Object.values(uploadsQueueById).reduce(
			(acc, upload) => {
				// console.log('uploadsQueueById:', uploadsQueueById);
				// console.log('Upload before metadata:', upload);
				// console.log('Upload MEtadata:', upload.metadata);
				const { recordingId, albumId } = upload.metadata;
				if (recordingId) {
					acc.byRecordingId[recordingId] = [
						...(acc.byRecordingId[recordingId] ?? []),
						upload,
					];
				} else if (albumId) {
					acc.byAlbumId[albumId] = [...(acc.byAlbumId[albumId] ?? []), upload];
				}
				return acc;
			},
			{ byRecordingId: {}, byAlbumId: {} } as {
				byRecordingId: { [recordingId: number]: ProjectFileUpload[] };
				byAlbumId: { [albumId: number]: ProjectFileUpload[] };
			}
		)
);

export const getFilesById = createSelector(
	[filesByProjectId],
	filesByProjectId => flattenToFilesById(filesByProjectId)
);

export const getUploadingStorageUsage = createSelector(
	uploadsQueueById,
	uploads =>
		Object.values(uploads).reduce(
			(acc, upload) =>
				acc +
				([
					UPLOAD_STATUS.READY_TO_UPLOAD,
					UPLOAD_STATUS.IN_PROGRESS,
					UPLOAD_STATUS.SUCCESS,
				].includes(upload.status)
					? upload?.metadata?.fileSize
					: 0),
			0
		)
);

export const getProjectStorageUsage = createSelector(
	[
		filesByProjectId,
		(
			_: RootState,
			{
				albumId,
				recordingId,
			}: {
				albumId?: number | null;
				recordingId?: number | null;
			}
		) => ({ albumId, recordingId }),
	],
	(filesByProjectId, { albumId, recordingId }) => {
		if (recordingId) {
			return Object.values(filesByProjectId.byRecordingId[recordingId] ?? {})
				.map(file => file.fileSize)
				.reduce((acc, size) => acc + size, 0);
		} else if (albumId) {
			return Object.values(filesByProjectId.byAlbumId[albumId] ?? {})
				.map(file => file.fileSize)
				.reduce((acc, size) => acc + size, 0);
		}
	}
);

export const getUploadsByFileId = createSelector(
	[
		uploadsQueueById,
		(
			_: RootState,
			{
				fileId,
				includeCanceledUploads = true,
			}: { fileId: FileMetadata['id']; includeCanceledUploads: boolean }
		) => ({
			fileId,
			includeCanceledUploads,
		}),
	],
	(uploadsQueueById, { fileId, includeCanceledUploads = true }) =>
		Object.values(uploadsQueueById).filter(
			upload =>
				upload?.versionMetadata?.fileId === fileId &&
				(includeCanceledUploads ||
					[UPLOAD_STATUS.READY_TO_UPLOAD, UPLOAD_STATUS.IN_PROGRESS].includes(
						upload.status
					))
		)
);

export const getPlaylistUploadsById = createSelector(
	[
		uploadsQueueById,
		(
			_: RootState,
			{
				playlistId,
				includeCanceledUploads = false,
			}: {
				playlistId: Playlist['id'];
				includeCanceledUploads?: boolean;
			}
		) => ({ playlistId, includeCanceledUploads }),
	],
	(uploadsQueueById, { playlistId, includeCanceledUploads }) =>
		keyBy(
			Object.values(uploadsQueueById).filter(
				upload =>
					(upload?.playlistMetadata?.playlistId ||
						upload?.versionMetadata?.playlistId) === playlistId &&
					(includeCanceledUploads ||
						[UPLOAD_STATUS.READY_TO_UPLOAD, UPLOAD_STATUS.IN_PROGRESS].includes(
							upload.status
						))
			),
			'id'
		)
);
