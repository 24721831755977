import React, { useCallback, useEffect, useState } from 'react';
import { dragEventContainsFiles } from '../../../helpers/fileTools';
import styles from './FilesDragAndDropContainer.module.scss';
import clsx from 'clsx';

type DragEventHandler = (e: DragEvent) => void;

type DragAndDropContainerProps = {
	onDrop: DragEventHandler;
	onDragEnter?: DragEventHandler;
	onDragLeave?: DragEventHandler;
	onDragOver?: DragEventHandler;
	children?: React.ReactNode;
	className?: string;
	style?: React.CSSProperties;
	message?: React.ReactNode;
};

const FilesDragAndDropContainer = ({
	onDrop,
	onDragEnter,
	onDragLeave,
	onDragOver,
	children,
	className = '',
	style = {},
	message = 'Drop file(s) anywhere to upload to playlist',
}: DragAndDropContainerProps) => {
	const [dragging, setDragging] = useState(false);
	const drop = React.useRef<HTMLDivElement>(null);
	const drag = React.useRef<HTMLDivElement>(null);

	const handleDragOver = useCallback(
		(e: DragEvent) => {
			if (!dragEventContainsFiles(e)) {
				return;
			}

			e.preventDefault();
			e.stopPropagation();

			onDragOver?.(e);
		},
		[onDragOver]
	);

	const handleDrop = useCallback(
		(e: DragEvent) => {
			const files = e.dataTransfer?.files;

			if (!files || !files.length) {
				return;
			}

			e.preventDefault();
			e.stopPropagation();

			setDragging(false);

			onDrop(e);
		},
		[onDrop]
	);

	const handleDragEnter = useCallback(
		(e: DragEvent) => {
			console.log('drag enter');

			if (!dragEventContainsFiles(e)) {
				return;
			}

			e.preventDefault();
			e.stopPropagation();

			if (e.target !== drag.current) {
				setDragging(true);
			}

			onDragEnter?.(e);
		},
		[onDragEnter]
	);

	const handleDragLeave = useCallback(
		(e: DragEvent) => {
			if (!dragEventContainsFiles(e)) {
				return;
			}

			e.preventDefault();
			e.stopPropagation();

			if (e.target === drag.current) {
				setDragging(false);
			}

			onDragLeave?.(e);
		},
		[onDragLeave]
	);

	useEffect(() => {
		drop?.current?.addEventListener('dragover', handleDragOver);
		drop?.current?.addEventListener('drop', handleDrop);
		drop?.current?.addEventListener('dragenter', handleDragEnter);
		drop?.current?.addEventListener('dragleave', handleDragLeave);

		const dropCurrent = drop.current;

		return () => {
			dropCurrent?.removeEventListener('dragover', handleDragOver);
			dropCurrent?.removeEventListener('drop', handleDrop);
			dropCurrent?.addEventListener('dragenter', handleDragEnter);
			dropCurrent?.addEventListener('dragleave', handleDragLeave);
		};
	}, [handleDrop, drop, handleDragEnter, handleDragLeave, handleDragOver]);

	return (
		<div className={className} style={style} ref={drop}>
			{dragging && (
				<div className={styles['overlay']} ref={drag}>
					<div className={styles['content']}>
						<i
							className={clsx(
								'fas fa-cloud-upload-alt fa-3x',
								styles['overlay-icon']
							)}
						/>
						<p className='mb-0'>{message}</p>
					</div>
				</div>
			)}
			{children}
		</div>
	);
};

export default FilesDragAndDropContainer;
