import React from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../router/routes';
import Button from '../../../layout/Button';

import './ErrorPage.scss';
import { getAssetPath } from '@/helpers/assetTools';
//TODO: This should work with different pages like error/404 error/500 and so?
const ErrorPage = ({ errorCode = 'UNEXPECTED' }) => {
	const navigate = useNavigate();
	const errorMessages = {
		404: {
			title: 'Error 404: Page not found',
			src: getAssetPath('img/pageNotFound.svg'),
			msg: (
				<p>
					Uh oh, something's out of tune. If this keeps happening, give us a
					shout at <a href='https://soundcredit.com'>soundcredit.com</a>
				</p>
			),
		},
		UNEXPECTED: {
			title: 'Whoops!',
			src: getAssetPath('img/whoops.svg'),
			msg: (
				<p>
					Uh oh, something's out of tune. If this keeps happening,{' '}
					<strong>
						give us a shout at{' '}
						<a href='mailto: support@soundcredit.com'>
							support@soundcredit.com
						</a>
					</strong>
				</p>
			),
		},
	};

	return (
		<Container className='d-flex justify-content-center align-items-center h-100'>
			<div className='error-page-content card get-started-container'>
				<header>
					<img src={errorMessages[errorCode].src} alt='Error' />
					<h2>{errorMessages[errorCode].title}</h2>
				</header>
				<main>
					{errorMessages[errorCode].msg && (
						<p
							style={{
								maxWidth: '45ch',
							}}
							className=''
						>
							{errorMessages[errorCode].msg}
						</p>
					)}
				</main>
				<footer className='d-flex justify-content-center '>
					<Button
						label='Return to Projects Page'
						theme='dark'
						onClick={() => navigate(ROUTES.Projects.path)}
					/>
				</footer>
			</div>
		</Container>
	);
};

export default ErrorPage;
