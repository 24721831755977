import _ from 'lodash';

const camelize = obj =>
	_.transform(obj, (acc, value, key, target) => {
		const camelKey = _.isArray(target) ? key : _.camelCase(key);

		acc[camelKey] = _.isObject(value) ? camelize(value) : value;
	});

export default camelize;
