import React from 'react';
import { Button } from 'react-bootstrap';
import './HeaderButton.scss';

const HeaderButton = ({
	label,
	icon,
	className = '',
	onClick,
	isLoading = false,
	isDisabled = false,
}) => {
	return (
		<Button
			variant={`${isLoading && 'light'}`}
			onClick={onClick}
			className={`header-button ${className}`}
			disabled={isLoading || isDisabled}
		>
			<i className={`${icon} mr-2`}></i>
			{label}
		</Button>
	);
};

export default HeaderButton;
