import { Col, Nav, Row, Tab } from 'react-bootstrap';
import React from 'react';
import './PillsTabs.scss';

export type ProjectsTabProps = {
	eventKey: string;
	title: string;
	className?: string;
	children: React.ReactNode;
};

export const ProjectsTab = ({
	eventKey,
	title,
	className = '',
	children,
}: ProjectsTabProps) => <div />;

export type PillsTabsProps = {
	activeKey?: string;
	onSelect?: (
		eventKey: string | null,
		e: React.SyntheticEvent<unknown>
	) => void;
	defaultActiveKey?: string;
	children: React.ReactElement<ProjectsTabProps>[];
};

const PillsTabs = ({
	activeKey,
	onSelect,
	defaultActiveKey,
	children,
	...props
}: PillsTabsProps) => (
	<div>
		<Tab.Container activeKey={activeKey} onSelect={onSelect} {...props}>
			<Row>
				<Col>
					<Nav variant='pills'>
						{children.map(
							({ props: { eventKey, title, className } }, index) => (
								<Nav.Item
									key={index}
									className={`btn p-1 ${className ?? ''} projects-nav-link`}
									style={{ cursor: 'default' }}
								>
									<Nav.Link eventKey={eventKey}>{title}</Nav.Link>
								</Nav.Item>
							)
						)}
					</Nav>
				</Col>
			</Row>
			<hr />
			<Row>
				<Col>
					<Tab.Content className='p-2'>
						{children.map(({ props: { eventKey, children } }, index) => (
							<div
								key={index}
								style={{ display: activeKey === eventKey ? 'block' : 'none' }}
							>
								{children}
							</div>
						))}
					</Tab.Content>
				</Col>
			</Row>
		</Tab.Container>
	</div>
);

export default PillsTabs;
