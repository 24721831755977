import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { Context, useContext } from 'react';
import DndPlaylistFileTableRow from './DndPlaylistFileTableRow';
import { UniqueIdentifier } from '@dnd-kit/core';
import { isGroupDndId } from '../utilities';
import { SelectedPlaylistContext } from '../../../../../../context/SelectedPlaylistContext';

type SortableDndPlaylistFileTableRowProps = {
	file: PlaylistTableFile;
	rowIndex: number;
	id: UniqueIdentifier;
	clone?: boolean; // used for component to be able to tell if it's a clone, and render differently in that case
};

const SortableDndPlaylistFileTableRow = ({
	file,
	rowIndex,
	id,
	clone = false,
}: SortableDndPlaylistFileTableRowProps) => {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
		isDragging,
		over,
	} = useSortable({ id });

	const { isAbovePointerDistanceThreshold } =
		useContext<SelectedPlaylistContextType>(
			SelectedPlaylistContext as Context<SelectedPlaylistContextType>
		);

	const isOverFolder =
		isAbovePointerDistanceThreshold &&
		isDragging &&
		over?.id &&
		isGroupDndId(over.id as string);

	// if it's being dragged over a folder, we want to hide the file since otherwise it generates a gap before the folder,
	// and that looks like the file can be inserted on top of the folder, and we want to show that can be inserted inside the folder
	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
		height: isOverFolder ? '0' : 'auto',
		opacity: isOverFolder ? '0' : '1',
	};

	return (
		<DndPlaylistFileTableRow
			ref={setNodeRef}
			style={style}
			{...attributes}
			{...listeners}
			file={file}
			rowIndex={rowIndex}
			isDragging={isDragging}
			clone={clone}
		/>
	);
};

export default SortableDndPlaylistFileTableRow;
