import React, { useCallback } from 'react';
import UPLOAD_STATUS from '../../../../../constants/uploadStatus.json';
import FILE_TABS from '../../../../../constants/fileTabs.json';
import theme from '../../../../../theme.module.scss';
import IconButton from '../../../IconButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
	abortFileUploadAction,
	clearUploadFromQueueAction,
	retryFileUploadAction,
} from '../../../../../store/files/actions';
import { navigateToProjectFiles } from '../../../../../helpers/fileTools';

export type FileUploadItemActionsProps = {
	upload: ProjectFileUpload;
	showViewInFolder?: boolean;
};

const FileUploadItemActions = ({
	upload,
	showViewInFolder = true,
}: FileUploadItemActionsProps) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleCancelUpload = useCallback(
		() => dispatch(abortFileUploadAction(upload.id)),
		[dispatch, upload]
	);

	const handleViewInFolder = useCallback(() => {
		const section =
			upload.status === UPLOAD_STATUS.SUCCESS
				? FILE_TABS.FILES
				: FILE_TABS.UPLOADS;

		navigateToProjectFiles({
			albumId: upload.metadata.albumId,
			recordingId: upload.metadata.recordingId,
			section,
			navigate,
		});
	}, [upload, navigate]);

	const handleClearUpload = useCallback(() => {
		dispatch(clearUploadFromQueueAction(upload.id));
	}, [dispatch, upload]);

	const handleRetryUpload = useCallback(() => {
		dispatch(retryFileUploadAction(upload.id));
	}, [dispatch, upload]);

	const renderViewInFolderButton = useCallback(
		() =>
			showViewInFolder ? (
				<IconButton
					icon='far fa-folder'
					tooltipText='View in Folder'
					onClick={handleViewInFolder}
				/>
			) : (
				<></>
			),
		[handleViewInFolder, showViewInFolder]
	);

	const renderCancelButton = useCallback(
		() => (
			<IconButton
				icon='fas fa-times'
				tooltipText='Cancel Upload'
				color={theme.error}
				onClick={handleCancelUpload}
			/>
		),
		[handleCancelUpload]
	);

	const renderClearButton = useCallback(
		() => (
			<IconButton
				icon='fas fa-times'
				tooltipText='Clear'
				onClick={handleClearUpload}
			/>
		),
		[handleClearUpload]
	);

	const renderRetryButton = useCallback(
		() => (
			<IconButton
				icon='fas fa-redo'
				tooltipText='Retry Upload'
				onClick={handleRetryUpload}
			/>
		),
		[handleRetryUpload]
	);

	switch (upload.status) {
		case UPLOAD_STATUS.IN_PROGRESS:
			return (
				<>
					{renderViewInFolderButton()}
					{renderCancelButton()}
				</>
			);
		case UPLOAD_STATUS.ERROR:
			return (
				<>
					{renderViewInFolderButton()}

					{renderClearButton()}
				</>
			);
		case UPLOAD_STATUS.SUCCESS:
			return (
				<>
					{renderViewInFolderButton()}
					{renderClearButton()}
				</>
			);
		case UPLOAD_STATUS.CANCELLED:
			return (
				<>
					{renderRetryButton()}
					{renderClearButton()}
				</>
			);
		case UPLOAD_STATUS.READY_TO_UPLOAD:
			return <>{renderCancelButton()}</>;
		default:
			return <></>;
	}
};

export default FileUploadItemActions;
