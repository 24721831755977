import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Card, Container, Modal } from 'react-bootstrap';
import { isProfileEqual } from '../../../../helpers/participantTools';
import { hideModal, setModalTitle } from '../../../../store/modal/actions';
import {
	copyCreditsToOtherRecordingsAction,
	fetchRecordingByIdAction,
} from '../../../../store/projects/actions';
import { getCurrentRecording } from '../../../../store/projects/selectors';
import Button from '../../../layout/Button';
import SearchTable from '../../../layout/SearchTable';
import SoundCreditLoader from '../../SoundCreditLoader';
import SelectRecordings from '../../SelectRecordings/SelectRecordings';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { sortRecordingsInAlbumDesc } from '../../../../helpers/projectTools';

enum CopyCreditsFromOtherRecordingsSteps {
	SelectRecording,
	SelectParticipants,
}

type SelectRecordingProps = {
	onSelect: (ids: number[]) => void;
	onSubmit: () => void;
	selectedRecordingId: number | null;
	isLoading: boolean;
	recordingOptionsIds: number[] | null;
};

const SelectRecording = ({
	onSelect,
	selectedRecordingId,
	onSubmit,
	isLoading,
	recordingOptionsIds,
}: SelectRecordingProps) => {
	const dispatch = useAppDispatch();
	const { recordingsById, currentAlbumId } = useAppSelector(state => state.projects);

	const recordingOptions = useMemo(
		() =>
			sortRecordingsInAlbumDesc(
				recordingOptionsIds
					?.map(id => recordingsById?.[id])
					?.filter((r): r is Recording => !!r) ?? [],
				currentAlbumId
			),
		[recordingOptionsIds, recordingsById, currentAlbumId]
	);

	if (!recordingOptionsIds)
		return (
			<div style={{ height: '50vh' }}>
				<SoundCreditLoader />;
			</div>
		);

	return (
		<>
			<Modal.Body>
				{!recordingOptions.length ? (
					<NoRecordings />
				) : (
					<div className='px-3'>
						<SelectRecordings
							onSelect={onSelect}
							projectRows={recordingOptions}
							customRows
							singleSelect
						/>
					</div>
				)}
			</Modal.Body>
			<Modal.Footer>
				<Button label='Cancel' onClick={() => dispatch(hideModal())} />
				<Button
					label='Next'
					onClick={onSubmit}
					isLoading={isLoading}
					isDisabled={!recordingOptions?.length || !selectedRecordingId}
					className='ml-2'
					theme='dark'
				/>
			</Modal.Footer>
		</>
	);
};

type SelectParticipantsProps = {
	onSelect: (ids: number[]) => void;
	onSubmit: () => void;
	selectedParticipants: number[];
	isLoading: boolean;
	options: Participant[];
	onBack: () => void;
};

const SelectParticipants = ({
	onSubmit,
	onSelect,
	onBack,
	isLoading,
	options,
	selectedParticipants,
}: SelectParticipantsProps) => {
	const columns = useMemo(
		() => [
			{
				Header: () => <span className='Project-Name'>Name</span>,
				accessor: 'creditedName',
				Cell: (col: any) => <span className='Name1'>{col.value}</span>,
			},
			{
				Header: () => <span className='Project-Name'>Roles</span>,
				accessor: 'roles',
				Cell: (col: any) => (
					<ul className='Name1 m-0 p-0'>
						{col.value.map((role: ParticipantRole) => (
							<li className='my-2' style={{ listStyleType: 'none' }}>
								{role.detail}
							</li>
						))}
					</ul>
				),
			},
		],
		[]
	);

	if (!options)
		return (
			<div style={{ height: '50vh' }}>
				<SoundCreditLoader />;
			</div>
		);

	return (
		<>
			<Modal.Body>
				<Container>
					{!options.length ? (
						<NoParticipants />
					) : (
						<Card>
							<Card.Body>
								<SearchTable
									data={options}
									onSelect={onSelect}
									columns={columns}
									rowMapper={row => row.original.id}
									searchBarPlaceholder='Search by Name or Role'
								/>
							</Card.Body>
						</Card>
					)}
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Button label='Back' onClick={onBack} isDisabled={isLoading} />
				<Button
					label='Copy'
					onClick={onSubmit}
					isLoading={isLoading}
					isDisabled={!selectedParticipants?.length}
					theme='dark'
					className='ml-2'
				/>
			</Modal.Footer>
		</>
	);
};

const NoParticipants = () => {
	return (
		<div
			style={{ height: '40vh' }}
			className='d-flex justify-content-center align-items-center flex-column'
		>
			<i className='fas fa-users-slash mb-3' style={{ fontSize: '5rem' }}></i>
			<div>
				<h4>No suitable participants found for copying</h4>
			</div>
		</div>
	);
};

const NoRecordings = () => {
	return (
		<div
			style={{ height: '40vh' }}
			className='d-flex justify-content-center align-items-center flex-column'
		>
			<i className='fas fa-compact-disc mb-3' style={{ fontSize: '5rem' }}></i>
			<div>
				<h4>
					You currently have no other recordings to copy participants from
				</h4>
			</div>
		</div>
	);
};

const CopyCreditsFromOtherRecording = () => {
	const dispatch = useAppDispatch();
	const { currentRecordingId, recordingsById } = useAppSelector(
		state => state.projects
	);
	const currentRecording = useAppSelector(getCurrentRecording);

	const [isLoading, setIsLoading] = useState(false);
	const [selectedRecordingId, setSelectedRecordingId] = useState<number | null>(
		null
	);
	const [selectedParticipantIds, setSelectedParticipantIds] = useState<
		number[]
	>([]);
	const [currentStep, setCurrentStep] = useState(
		CopyCreditsFromOtherRecordingsSteps.SelectRecording
	);
	const [recordingOptionsIds, setRecordingOptionsIds] = useState<
		number[] | null
	>(null);

	const recordingParticipants = useMemo(() => {
		const recording = recordingsById?.[selectedRecordingId!]?.recording ?? null;

		// remove duplicates by filtering out participants that are already in the current recording
		return recording?.participants?.filter(
			participantOption =>
				!currentRecording?.recording?.participants?.find(existingParticipant =>
					isProfileEqual(existingParticipant, participantOption)
				)
		) ?? [];
	}, [recordingsById, selectedRecordingId, currentRecording]);

	const handleSelectRecording = useCallback((selection: number[]) => {
		if (selection && selection.length) {
			setSelectedRecordingId(selection[0]);
		}
	}, []);

	const handleSubmitRecording = useCallback(() => {
		setIsLoading(true);
		Promise.resolve(
			dispatch(fetchRecordingByIdAction({ id: selectedRecordingId! }))
		).then(() => {
			setCurrentStep(CopyCreditsFromOtherRecordingsSteps.SelectParticipants);
			setIsLoading(false);
		});
	}, [selectedRecordingId, dispatch]);

	const handleSubmitParticipants = useCallback(() => {
		setIsLoading(true);

		Promise.resolve(
			dispatch(
				copyCreditsToOtherRecordingsAction({
					fromRecordingId: selectedRecordingId!,
					toRecordingIds: [currentRecordingId!],
					participantIds: selectedParticipantIds,
				})
			)
		).finally(() => {
			setIsLoading(false);
			dispatch(hideModal());
		});
	}, [
		selectedRecordingId,
		currentRecordingId,
		selectedParticipantIds,
		dispatch,
	]);

	const handleBack = () => {
		setCurrentStep(CopyCreditsFromOtherRecordingsSteps.SelectRecording);
	};

	const renderStep = useCallback(() => {
		switch (currentStep) {
			case CopyCreditsFromOtherRecordingsSteps.SelectRecording:
				return (
					<SelectRecording
						onSelect={handleSelectRecording}
						onSubmit={handleSubmitRecording}
						recordingOptionsIds={recordingOptionsIds}
						selectedRecordingId={selectedRecordingId}
						isLoading={isLoading}
					/>
				);
			case CopyCreditsFromOtherRecordingsSteps.SelectParticipants:
				return (
					<SelectParticipants
						onBack={handleBack}
						onSelect={setSelectedParticipantIds}
						onSubmit={handleSubmitParticipants}
						isLoading={isLoading}
						options={recordingParticipants}
						selectedParticipants={selectedParticipantIds}
					/>
				);
			default:
				return <></>;
		}
	}, [
		currentStep,
		isLoading,
		handleSubmitParticipants,
		handleSubmitRecording,
		recordingOptionsIds,
		selectedRecordingId,
		handleSelectRecording,
		recordingParticipants,
		selectedParticipantIds,
	]);

	useEffect(() => {
		setRecordingOptionsIds(
			Object.keys(recordingsById!)
				.map(id => parseInt(id))
				.filter(recId => recId !== currentRecordingId)
		);
	}, [currentRecordingId, recordingsById]);

	useEffect(() => {
		dispatch(setModalTitle('COPY CREDITS FROM ANOTHER RECORDING'));
	}, [dispatch]);

	return renderStep();
};

export default CopyCreditsFromOtherRecording;
