import { useCallback, useEffect, useRef } from 'react';

const useOutsideClick = <T extends HTMLElement = HTMLElement>(
	callback: (event?: MouseEvent) => void
) => {
	const ref = useRef<T | null>(null);
	const handleClick = useCallback(
		(event: MouseEvent) => {
			if (ref.current && !ref.current.contains(event.target as Node)) {
				callback(event);
			}
		},
		[callback]
	);

	useEffect(() => {
		// Add event listener
		document.addEventListener('click', handleClick, true);

		// Remove event listener on cleanup
		return () => {
			document.removeEventListener('click', handleClick, true);
		};
	}, [handleClick]); // Depend on handleClick

	return ref;
};

export default useOutsideClick;
