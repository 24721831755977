export enum NotificationTypes {
	PROJECT_INVITE = 1,
	PROJECT_TRANSFER = 2,
	COMMENT = 3,
	EDITOR_CHANGE = 4,
	SERVER_NOTIFICATION = 5,
	ALBUM_INVITE = 6,
	ALBUM_TRANSFER = 7,
	PLAYLIST_TRANSFER = 8,
	PLAYLIST_INVITE = 9,
	ESIGNATURE_SIGNED = 10,
	ESIGNATURE_COMPLETE = 11,
	TRACKCHAT_COMMENT = 12,
	PLAYLIST_CREDIT_REQUEST = 13,
}
