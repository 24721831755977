import React, { useEffect } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setModalTitle } from '../../../../../store/modal/actions';
import Button from '../../../../layout/Button';

const ExitUnionFormModal = ({ onSave, onDiscard, onDismiss }) => {
	/*
	 * Redux Hooks
	 */
	const dispatch = useDispatch();

	/*
	 * Effects
	 */
	useEffect(() => {
		dispatch(setModalTitle('EXIT UNION FORM'));
	}, [dispatch]);

	return (
		<>
			<Modal.Body>
				<div className='px-4'>
					<p style={{ fontWeight: 500 }}>
						You're exiting the Union Form. Do you want to save your work and
						exit or do you wish to discard it?
					</p>
					<p style={{ fontWeight: 700, marginBottom: 0 }}>
						This action cannot be undone.
					</p>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Row>
					<Col
						xs={12}
						className='d-flex justify-content-end align-items-center'
						style={{
							gap: '0.5rem',
						}}
					>
						<Button label='Cancel' onClick={onDismiss} theme='dark' />
						<Button
							label='Discard & Exit'
							theme='danger-filled'
							onClick={onDiscard}
						/>

						<Button label='Save & Exit' onClick={onSave} />
					</Col>
				</Row>
			</Modal.Footer>
		</>
	);
};

export default ExitUnionFormModal;
