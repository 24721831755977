import { ButtonNext } from 'pure-react-carousel';
import React, { useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Carousel from '../../layout/Carousel';
import './FreshInstallInfoModal.scss';
import '../../layout/PillButton/PillButton.scss';
import { hideModal } from '../../../store/modal/actions';
import { useDispatch } from 'react-redux';
import PillButton from '../../layout/PillButton';
import { getAssetPath } from '@/helpers/assetTools';

const PlaylistsRundownSlide = ({
	title,
	description,
	image,
	buttonLabel,
	rightIcon,
}) => {
	return (
		<div className='playlists-rundown-slide'>
			<div className='playlists-rundown-slide__image'>
				<img src={image} alt='' />
			</div>
			<div className='playlists-rundown-slide__content'>
				{title}
				{description}
				{buttonLabel && (
					<ButtonNext className='pill-button-solid-light px-3 py-2'>
						{buttonLabel}

						{rightIcon && <i className={rightIcon + ' ml-2'}></i>}
					</ButtonNext>
				)}
			</div>
		</div>
	);
};

const PlaylistsRundownModal = () => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const dispatch = useDispatch();

	const slides = useMemo(
		() => [
			<PlaylistsRundownSlide
				title={
					<div className='playlists-rundown-title'>
						<span>Now you're in...</span>
						<h3>Always transfer files with Sound Credit</h3>
					</div>
				}
				description={
					<>
						<p>
							To ensure that all contributors are properly credited and
							compensated, use Sound Credit's Playlists feature for every file
							transfer.
						</p>

						<p>
							Other services do not have systems in place to track credits and
							royalties, so Sound Credit is a reliable choice to make sure that
							all contributors are fairly compensated.
						</p>
					</>
				}
				image={getAssetPath('img/playlists-rundown1.png')}
				buttonLabel={"Got it, let's credit em all"}
				rightIcon='fas fa-chevron-right'
			/>,
			<PlaylistsRundownSlide
				title={<h3>Learn as you go</h3>}
				description={
					<>
						<p>
							As you get started with using Sound Credit Playlists you can learn
							the other features and functionality of the platform at your own
							pace.
						</p>

						<p>
							This includes adding credits, adding co-admins and registering for
							royalties
						</p>

						<p>
							Whether you're a seasoned pro or just starting out in the music
							industry, Sound Credit has a lot to offer for everyone.
						</p>
					</>
				}
				image={getAssetPath('img/playlists-rundown2.png')}
				buttonLabel='Onwards to learning!'
				rightIcon='fas fa-chevron-right'
			/>,
			<PlaylistsRundownSlide
				title={<h3>Playlists & Projects</h3>}
				description={
					<>
						<p>
							When you create a playlist and upload songs and files to it,
							you'll notice that it also creates projects. In the projects you
							can:
						</p>

						<ul>
							<li>Add credits</li>
							<li>Enter and sync lyrics</li>
							<li>Generate ISRC and GRid codes</li>
							<li>Export the data to many destinations and formats</li>
							<li>Invite other collaborators to edit or view the projects</li>
						</ul>

						<p>
							Note that projects can also be created independently of playlists
						</p>

						<PillButton
							fullWidth
							className='px-3 py-2 w-100'
							onClick={() => {
								dispatch(hideModal());
							}}
							label='Show me the projects!'
							rightIcon='fas fa-chevron-right'
							theme='solid-light'
						/>
					</>
				}
				image={getAssetPath('img/playlists-rundown3.png')}
			/>,
		],
		[dispatch]
	);

	return (
		<Modal.Body>
			<Carousel
				slides={slides}
				currentIndex={currentIndex}
				onChangeIndex={setCurrentIndex}
				showDots
			/>
		</Modal.Body>
	);
};

export default PlaylistsRundownModal;
