import { stringify } from 'csv-stringify/sync';
import JSZip from 'jszip';

export interface CsvData {
	filename: string;
	data: string;
}

export const zipCsvs = async (csvData: CsvData[]): Promise<Blob> => {
	const zip = new JSZip();
	csvData.forEach(({ filename, data }) => {
		zip.file(filename, data);
	});
	const blob = await zip.generateAsync({ type: 'blob' });
	return blob;
};

export const arrayToCsv = (array: string[]): string => stringify([array]);
