import { shuffle } from 'lodash';
import { getFileExtension, isAudioFile } from './fileTools';
import { formatTime } from '@/components/utils/dateFormatters';
import LoopMode from '../constants/loopMode';
import { getItemsInGroup } from '../components/screens/Playlists/PlaylistDetails/PlaylistFileTable/utilities';

export const HIGH_RES_AUDIO_EXTENSIONS = ['flac', 'wav'];

export const isHighResAudio = (file: FileMetadata | FileVersion) => {
	const fileExtension = getFileExtension(file.filename);

	return HIGH_RES_AUDIO_EXTENSIONS.includes(fileExtension);
};

// if we're shuffling, we want the fromTrackId to be the first track in the tracklist
export const shuffleTracklist = (
	tracklist: FileMetadata['id'][],
	// we start from a random track if fromTrackId is null
	fromTrackId: number,
	randomFirstTrack: boolean = false
) => {
	const shuffledTracklist = shuffle(tracklist);

	const fromTrackIndex = shuffledTracklist.findIndex(
		trackId => trackId === fromTrackId
	);

	if (fromTrackIndex === -1)
		throw new Error(
			`Track with ID ${fromTrackId} not found in tracklist ${shuffledTracklist}`
		);

	const fromTrack = randomFirstTrack
		? []
		: shuffledTracklist.splice(fromTrackIndex, 1);

	return [...fromTrack, ...shuffledTracklist];
};

export const removeNonAudioFiles = (files: FileMetadata[]) =>
	files.filter(file => isAudioFile(file.filename));

export const getTracklistFromPlaylist = ({
	playlist,
	isShuffle,
	currentGroupId,
	fileIdToGroupIdIndex,
	groupIdToGroupContentIndex,
	fromTrackId,
	randomFirstTrack = false,
}: {
	playlist: Playlist;
	isShuffle: boolean;
	currentGroupId: number | null;
	fileIdToGroupIdIndex: FileIdToGroupIdIndex;
	groupIdToGroupContentIndex: GroupIdToGroupContentIndex;
	fromTrackId?: number;
	randomFirstTrack?: boolean;
}) => {
	if (!playlist?.playlist) return [];

	const { files: groupFiles } = getItemsInGroup({
		sortedFolders: playlist.playlist.folders,
		playlistFiles: playlist.playlist.files,
		groupIdToGroupContentIndex,
		parentGroupId: currentGroupId,
		fileIdToGroupIdIndex: fileIdToGroupIdIndex,
	});

	const tracklist = removeNonAudioFiles(groupFiles).map(
		file => file.id
	);

	return isShuffle
		? shuffleTracklist(tracklist, fromTrackId ?? tracklist[0], randomFirstTrack)
		: tracklist;
};

export const formatFileTime = (millis: number) =>
	formatTime(millis, { isMilli: true });

export const getNextTrackIndex = ({
	loopMode,
	tracklist,
	currentTrackIndex,
}: {
	loopMode: PlayerState['loopMode'];
	tracklist: PlayerState['tracklist'];
	currentTrackIndex: PlayerState['currentTrackIndex'];
}) => {
	if (tracklist.length === 0) {
		return -1;
	}

	switch (loopMode) {
		case LoopMode.DISABLED:
			if (currentTrackIndex === tracklist.length - 1) {
				return -1;
			}
			return currentTrackIndex + 1;
		case LoopMode.LOOP_TRACKLIST:
			return (currentTrackIndex + 1) % tracklist.length;
		case LoopMode.LOOP_TRACK:
			return currentTrackIndex;
	}
};

export const getPreviousTrackIndex = (state: PlayerState) => {
	if (state.tracklist.length === 0) {
		return -1;
	}

	// if we're at the beginning of the tracklist, return the same index
	return state.currentTrackIndex === 0
		? state.currentTrackIndex
		: state.currentTrackIndex - 1;
};

export const cycleLoopMode = (loopMode: LoopMode) => {
	switch (loopMode) {
		case LoopMode.DISABLED:
			return LoopMode.LOOP_TRACKLIST;
		case LoopMode.LOOP_TRACKLIST:
			return LoopMode.LOOP_TRACK;
		case LoopMode.LOOP_TRACK:
			return LoopMode.DISABLED;
	}
};

export const SKIP_TO_PREVIOUS_THRESHOLD = 2 * 1000;

/**
 * Checks if two tracklists are equal, taking into account shuffle mode.
 * If shuffle mode is enabled, the tracklists are equal if they contain the same tracks, without regard to order.
 * Otherwise, the tracklists are equal if they contain the same tracks in the same order.
 */
export const isTracklistEqual = (
	tracklist1: FileMetadata['id'][],
	tracklist2: FileMetadata['id'][],
	shuffle: boolean
) => {
	if (shuffle) {
		return (
			tracklist1.length === tracklist2.length &&
			tracklist1.every(trackId => tracklist2.includes(trackId))
		);
	}

	return (
		tracklist1.length === tracklist2.length &&
		tracklist1.every((trackId, index) => trackId === tracklist2[index])
	);
};
