import { formatDateToApi } from '../components/utils/dateFormatters';

const translateAlbumFormToApi = (albumForm: AlbumContent) => {
	const apiAlbum = {
		album_id: albumForm.id,
		title: albumForm.title,
		artist: albumForm.artistName,
		recordings: albumForm.recordings,
		album: {
			...albumForm,
			releaseDate: formatDateToApi(albumForm.releaseDate),
		},
		is_single: !!albumForm.isSingle,
	};
	return apiAlbum;
};

export default translateAlbumFormToApi;
