import { AnyAction } from 'redux';
import {
	REQUEST_LOADING,
	REQUEST_FAILURE,
	REQUEST_FORBIDDEN,
	REQUEST_SUCCESS,
} from '../../constants/requestStatusTypes';
import {
	ADD_PROFILES,
	EDIT_PROFILE,
	DELETE_PROFILE,
	SET_PROFILES_SEARCH_FILTER,
	CLEAR_PROFILES,
	PROFILE_REQUEST_AUTH_ERROR,
	PROFILE_REQUEST_ERROR,
	PROFILE_REQUEST_SUCCESS,
	START_PROFILE_REQUEST,
} from '../actionTypes';

type ProfilesState = {
	profiles: LocalProfile[] | null;
	searchFilter: string;
	errorMessage: string;
	requestLabel: string;
	requestStatus: string;
};

const initialState: ProfilesState = {
	profiles: null,
	searchFilter: '',
	errorMessage: '',
	requestLabel: '',
	requestStatus: '',
};

const reducer = (state = initialState, action: AnyAction): ProfilesState => {
	const currentProfiles = state.profiles ?? [];

	switch (action.type) {
		case START_PROFILE_REQUEST:
			return {
				...state,
				requestStatus: REQUEST_LOADING,
				requestLabel: action.requestLabel,
				errorMessage: '',
			};
		case PROFILE_REQUEST_ERROR:
			return {
				...state,
				requestStatus: REQUEST_FAILURE,
				errorMessage: action.errorMessage,
			};
		case PROFILE_REQUEST_AUTH_ERROR:
			return {
				...state,
				requestStatus: REQUEST_FORBIDDEN,
				errorMessage: action.errorMessage,
			};
		case PROFILE_REQUEST_SUCCESS:
			return {
				...state,
				requestStatus: REQUEST_SUCCESS,
				errorMessage: '',
			};
		case ADD_PROFILES:
			return {
				...state,
				profiles: [...currentProfiles, ...action.profiles],
			};
		case EDIT_PROFILE:
			return {
				...state,
				profiles: currentProfiles.map(profile =>
					profile.id === action.profile.id ? action.profile : profile
				),
			};
		case DELETE_PROFILE:
			return {
				...state,
				profiles: currentProfiles.filter(
					profile => profile.id !== action.profile.id
				),
			};
		case SET_PROFILES_SEARCH_FILTER:
			return {
				...state,
				searchFilter: action.searchFilter,
			};
		case CLEAR_PROFILES:
			return {
				...initialState,
			};
		default:
			return state;
	}
};

export default reducer;
