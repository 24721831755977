import React from 'react';
import styles from './FallbackSongThumbnail.module.scss';
import clsx from 'clsx';

const FallbackSongThumbnail = ({
	className = '',
	style = {},
	width = '2rem',
	height = '2rem',
}) => {
	return (
		<div
			className={clsx(styles['fallback-song-thumbnail'], className)}
			style={{
				width,
				height,
				...style,
			}}
		>
			<i className='fas fa-music' style={{ lineHeight: 0 }} />
		</div>
	);
};

export default FallbackSongThumbnail;
