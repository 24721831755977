import moment from 'moment';

export const duration2TimeFormat = (duration, format) => {
	return moment
		.utc(moment.duration(duration, 'seconds').asMilliseconds())
		.format(format);
};

export const export2Ttml = lyrics => {
	const ttmlFormatLyrics = lyrics.map(lyric => {
		const startTime = duration2TimeFormat(lyric.startTime, 'HH:mm:ss.SSS');
		const endTime = duration2TimeFormat(lyric.endTime, 'HH:mm:ss.SSS');
		return `<p begin="${startTime}" end="${endTime}">${lyric.lyric}</p>`;
	});

	const data = [
		`<tt xmlns="http://www.w3.org/ns/ttml"
  xmlns:tts="http://www.w3.org/ns/ttml#styling"
  xmlns:ttm="http://www.w3.org/ns/ttml#metadata"
  xml:lang="en">
  <head>
  </head>
  <body>
  <div>` +
			ttmlFormatLyrics.join('\n') +
			`</div>
  </body>
  </tt>`,
	];

	exportFile(data, 'lyric.ttml');
};

export const export2Srt = lyrics => {
	const srtFormatLyrics = lyrics.map((lyric, ind) => {
		const startTime = duration2TimeFormat(lyric.startTime, 'HH:mm:ss.SSS');
		const endTime = duration2TimeFormat(lyric.endTime, 'HH:mm:ss.SSS');
		const data = [];
		data.push(ind + 1);
		data.push(`${startTime} --> ${endTime}`);
		data.push(lyric.lyric);
		return data.join('\n');
	});

	exportFile(srtFormatLyrics, 'lyric.srt');
};

export const export2Lrc = lyrics => {
	const lrcFormatLyrics = lyrics.map(lyric => {
		const startTime = lyric.startTime;
		return `[${duration2TimeFormat(startTime, 'mm:ss.SS')}]${lyric.lyric}`;
	});

	exportFile(lrcFormatLyrics, 'lyric.lrc');
};

export const exportFile = (data, filename) => {
	const textDoc = document.createElement('a');

	const file = new Blob([data.join('\n')], { type: 'text/plain' });
	textDoc.href = URL.createObjectURL(file);
	textDoc.target = '_blank';
	textDoc.download = filename;
	textDoc.click();
};
