import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import isniStrings, { IsniString } from '../isniStrings';
import TextField from '../../../form/TextField/TextField';
import Button from '../../../layout/Button';

type CareerStepProps = {
	onBack: (values: CareerFormikValues) => void;
	onSubmit: (values: CareerFormikValues) => Promise<void>;
	initialFormValues?: CareerFormikValues | null;
	getIsniStringFn: (isniString: IsniString) => string;
};

export type CareerFormikValues = {
	websiteTitle: string;
	websiteAddress: string;
};

const validationSchema = Yup.object({
	websiteTitle: Yup.string().required('Required'),
	websiteAddress: Yup.string().required('Required'),
});

const CareerStep = ({
	onSubmit,
	onBack,
	initialFormValues,
	getIsniStringFn,
}: CareerStepProps) => {
	const formik = useFormik<CareerFormikValues>({
		initialValues: initialFormValues ?? {
			websiteTitle: '',
			websiteAddress: '',
		},
		onSubmit: async values => {
			console.log('values', values);
			await onSubmit(values);
		},
		validationSchema,
		validateOnMount: true,
		enableReinitialize: true,
	});

	return (
		<FormikProvider value={formik}>
			<h3>{getIsniStringFn(isniStrings.Career.title)}</h3>

			<h4>{getIsniStringFn(isniStrings.Career.description)}</h4>

			<TextField
				label={getIsniStringFn(isniStrings.Career.form.websiteTitle.label)}
				name='websiteTitle'
				placeholder={getIsniStringFn(
					isniStrings.Career.form.websiteTitle.label
				)}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				value={formik.values.websiteTitle}
				errorMessage={formik.errors.websiteTitle}
				isInvalid={formik.touched.websiteTitle && !!formik.errors.websiteTitle}
			/>

			<TextField
				label={getIsniStringFn(isniStrings.Career.form.websiteAddress.label)}
				name='websiteAddress'
				placeholder={getIsniStringFn(
					isniStrings.Career.form.websiteAddress.label
				)}
				onChange={formik.handleChange}
				onBlur={formik.handleBlur}
				value={formik.values.websiteAddress}
				errorMessage={formik.errors.websiteAddress}
				isInvalid={
					formik.touched.websiteAddress && !!formik.errors.websiteAddress
				}
			/>

			<div className='isni-registration__buttons mt-5'>
				<Button
					label='Back'
					leftIcon='fas fa-arrow-left'
					onClick={() => onBack(formik.values)}
				/>

				<Button
					label='Next'
					rightIcon='fas fa-arrow-right'
					onClick={formik.submitForm}
					isLoading={formik.isSubmitting}
					theme='dark'
				/>
			</div>
		</FormikProvider>
	);
};

export default CareerStep;
