import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { hideModal, setModalTitle } from '../../../store/modal/actions';
import * as yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { Col, Modal, Row } from 'react-bootstrap';
import TextField from '../../../components/form/TextField';
import Select from '../../form/Select';
import countryOptions from '../../../constants/countries.json';
import usStates from '../../../constants/usStates.json';
import { matchSorter } from 'match-sorter';
import Button from '../../layout/Button';
import './EditAddressModal.scss';

const schema = yup.object().shape({
	address1: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === null ? '' : curr))
		.required('Street Address Is Required'),
	address2: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === null ? '' : curr)),
	city: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === null ? '' : curr))
		.required('City Is Required'),
	state: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === null ? '' : curr))
		.required('State Is Required'),
	postalCode: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === null ? '' : curr)),
	country: yup
		.string()
		.nullable()
		.transform((curr, orig) => (orig === null ? '' : curr)),
});

const EditAddressModal = ({
	initialValues,
	onSubmit,
	title = 'EDIT HOME ADDRESS',
	validate = false,
}) => {
	/*
	 * Redux Hooks
	 */
	const dispatch = useDispatch();

	/*
	 * React State
	 */
	const [filteredCountryOptions, setFilteredCountryOptions] =
		React.useState(countryOptions);

	/*
	 * Effects
	 */
	useEffect(() => {
		dispatch(setModalTitle(title));
	}, [dispatch, title]);

	/*
	 * Formik Hooks
	 */
	const formik = useFormik({
		initialValues,
		validationSchema: validate ? schema : {},
		onSubmit,
	});

	return (
		<FormikProvider value={formik}>
			<Form placeholder={null} onSubmit={formik.handleSubmit}>
				<>
					<Modal.Body>
						<Row className='px-4'>
							<Col xs={12} md={6}>
								<TextField
									label='Street Address'
									name='address1'
									type='text'
									value={formik.values.address1}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									isInvalid={formik.errors.address1 && formik.touched.address1}
									errorMessage={formik.errors.address1}
								/>
							</Col>

							<Col xs={12} md={6}>
								<TextField
									label={
										<>
											Apt, Suite, etc.{' '}
											<i className='optional-italic-blue-label'>(optional)</i>
										</>
									}
									name='address2'
									type='text'
									value={formik.values.address2}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									isInvalid={formik.errors.address2 && formik.touched.address2}
									errorMessage={formik.errors.address2}
								/>
							</Col>

							<Col xs={12} md={6}>
								<TextField
									label='City'
									name='city'
									type='text'
									value={formik.values.city}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									isInvalid={formik.errors.city && formik.touched.city}
									errorMessage={formik.errors.city}
								/>
							</Col>

							<Col xs={12} md={6}>
								<TextField
									label={
										<>
											Post Code{' '}
											<i className='optional-italic-blue-label'>(optional)</i>
										</>
									}
									name='postalCode'
									type='text'
									value={formik.values.postalCode}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									isInvalid={
										formik.errors.postalCode && formik.touched.postalCode
									}
									errorMessage={formik.errors.postalCode}
								/>
							</Col>

							<Col xs={12} md={6}>
								<Select
									label={
										<>
											Country{' '}
											<i className='optional-italic-blue-label'>(optional)</i>
										</>
									}
									name='country'
									id='country'
									options={filteredCountryOptions}
									errorFieldName='country'
									value={
										formik.values.country &&
										countryOptions.find(c => c.name === formik.values.country)
									}
									getOptionValue={option => option.code}
									getOptionLabel={option => option.name}
									onChange={option => {
										formik.setFieldValue('country', option.name);
									}}
									onInputChange={val =>
										setFilteredCountryOptions(
											matchSorter(countryOptions, val, {
												keys: ['name', 'label'],
											})
										)
									}
								/>
							</Col>

							<Col xs={12} md={6}>
								<Select
									label='State / Province / Region'
									name='state'
									errorFieldName='state'
									options={usStates}
									value={
										formik.values.state &&
										usStates.find(s => s.abbreviation === formik.values.state)
									}
									getOptionValue={option => option.abbreviation}
									getOptionLabel={option => option.name}
									onChange={option => {
										formik.setFieldValue('state', option.abbreviation);
									}}
								/>
							</Col>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button label='Cancel' onClick={() => dispatch(hideModal())} />
						<Button theme='dark' label='Save' type='submit' />
					</Modal.Footer>
				</>
			</Form>
		</FormikProvider>
	);
};

export default EditAddressModal;
