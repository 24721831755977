import {
	ADD_EXPORT_VALIDATION_ERRORS,
	ADD_LOCAL_EXPORTS,
	CLEAR_EXPORT,
	CLEAR_EXPORT_REQUEST,
	CLEAR_EXPORT_VALIDATION_ERRORS,
	CONFIRM_EXPORT,
	DELETE_LOCAL_EXPORT,
	EXPORT_REQUEST_AUTH_ERROR,
	EXPORT_REQUEST_ERROR,
	EXPORT_REQUEST_SUCCESS,
	SET_EXPORT_LEAD_WRITER_NAME,
	SET_EXPORT_PAYLOAD,
	SET_UNION_FORM_EXPORT_STEP,
	SET_PREVIEW_EXPORT_DATA,
	START_EXPORT_REQUEST,
	SET_EXPORT_TYPE,
	NEXT_UNION_FORM_EXPORT_STEP,
	PREVIOUS_UNION_FORM_EXPORT_STEP,
	SET_UNION_FORM,
	SET_SAG_FORM,
	SET_UNION_EXPORT_STUDIO_FORM,
	SET_UNION_EXPORT_COMPLETE_PARTICIPANTS_FORM,
	SET_UNION_EXPORT_FINALIZE_FORM,
	SET_UNION_EXPORT_PARTICIPANTS,
	SET_UNION_EXPORT_RECORDING_ROWS,
	INITIALIZE_UNION_EXPORT_COMPLETE_PARTICIPANTS_FORM,
	SET_B4_TYPE,
	SET_UNION_EXPORT_ID,
	SET_EXPORT_PARAMS,
	SET_EXPORT_MAIN_ARTISTS,
	SET_EXPORT_RECORDING_ERRORS,
} from '../actionTypes';
import {
	createExport,
	deleteExport,
	downloadExport,
	downloadExportESignature,
	exportToRin,
	fetchAlbumExports,
	fetchRecordingExports,
	generateDripImages,
	getExportById,
	getLatestContractNumber,
	importRin,
	setLatestContractNumber,
} from '../../api/services/exportsService';
import { invalidTokenAction } from '../auth/actions';
import {
	CREATE_EXPORT_REQUEST,
	DELETE_EXPORT_REQUEST,
	DOWNLOAD_EXPORT_REQUEST,
	EXPORT_TO_RIN_REQUEST,
	FETCH_ALBUM_EXPORTS_REQUEST,
	FETCH_RECORDING_BY_ID_REQUEST,
	FETCH_RECORDING_EXPORTS_REQUEST,
	GET_LATEST_CONTRACT_NUMBER_REQUEST,
	IMPORT_RIN_REQUEST,
	SET_LATEST_CONTRACT_NUMBER_REQUEST,
} from '../../constants/requestLabelTypes';
import { showErrorAlert } from '../alertToast/actions';
import {
	addExportsToLocalAlbumAction,
	addExportsToLocalRecordingAction,
	createCloudRecordingAction,
	deleteExportFromLocalAlbumAction,
	deleteExportFromLocalRecordingAction,
	setLocalAlbumExportsAction,
	setLocalRecordingExportsAction,
} from '../projects/actions';
import FileSaver from 'file-saver';
import translateImportedRecordingToLocal from '../../helpers/translateImportedRecordingToLocal';
import { cloneDeep } from 'lodash';
import { AppDispatch, GetState } from '..';
import { UnionFormStep } from '../../constants/unionFormExportSteps';
import ExportType from '../../constants/exportType';
import { fetchPlaylistAction } from '../playlists/actions';
import { generateDripImagesFilename } from '../../helpers/dripTools';
import { getPlaylistById } from '../playlists/selectors';
import path from 'path-browserify';
import { hideModal, showModalAction } from '../modal/actions';
import { CONFIRMATION_MODAL } from '../../constants/modalTypes';
import { clearActiveExportFormAction } from '../session/actions';
import * as Sentry from '@sentry/react';

const _handleExportError = (
	error: any,
	dispatch: AppDispatch,
	message?: string
) => {
	Sentry.captureException(error);

	let errorMessage =
		message ||
		'An error occurred while syncing exports with the cloud. Please try again.';

	if (error?.response) {
		switch (error.response.status) {
			case 401:
				dispatch(exportRequestAuthErrorAction());
				break;
			case 512:
				dispatch(addExportValidationErrorsAction(error.response.data.detail));
				dispatch(exportRequestErrorAction());

				break;
			case 402:
				errorMessage =
					message ||
					"Your current subscription doesn't allow generation of this type of report. Time to upgrade!";
			// intentional fallthrough
			default:
				dispatch(showErrorAlert(`${errorMessage} (${error.response.status})`));
				dispatch(exportRequestErrorAction(errorMessage));
		}
	} else {
		dispatch(
			showErrorAlert(
				errorMessage ??
					'Whoops! Something went wrong in our Exports department!'
			)
		);
		console.error('EXPORT ERROR', error, errorMessage);
	}
};

export const importRinAction =
	(
		rin: string,
		albumId: number,
		onCreate?: ((recId: number) => Promise<any> | any) | null
	) =>
	(dispatch: AppDispatch) => {
		startExportRequestAction(IMPORT_RIN_REQUEST);

		return importRin(rin)
			.then(res => {
				console.log('IMPORTED RIN RESPONSE', res);
				const recording = res.data.recording;
				// ignore album data for now
				delete recording.album;

				dispatch(
					createCloudRecordingAction(
						translateImportedRecordingToLocal(recording), // TODO: fix typing
						onCreate,
						albumId
					)
				);
			})
			.catch(err => _handleExportError(err, dispatch));
	};

export const exportToRinAction =
	(recording: RecordingContent, onCreate: () => void) =>
	(dispatch: AppDispatch) => {
		dispatch(startExportRequestAction(EXPORT_TO_RIN_REQUEST));

		return exportToRin(recording)
			.then(res => {
				const newExport = res.data.export;
				const recordingId = newExport.recording_id;

				// add to exportsById
				dispatch(addLocalExportsAction([newExport]));

				// add to recording
				dispatch(addExportsToLocalRecordingAction(recordingId, [newExport.id]));

				dispatch(exportRequestSuccessAction());

				if (onCreate) {
					console.log('ON CREATE', onCreate);
					onCreate();
				}
			})
			.catch(err => _handleExportError(err, dispatch));
	};

export const loadUnionFormAction =
	(unionForm: UnionFormData) => (dispatch: AppDispatch) => {
		const {
			selectedRecordingRows,
			studioForm,
			selectedParticipants,
			completeParticipantsForm,
			finalizeForm,
			activeUnionFormStep,
			id,
		} = unionForm;

		dispatch(setUnionExportRecordingRowsAction(selectedRecordingRows));
		dispatch(setUnionExportStudioFormAction(studioForm));
		dispatch(setUnionExportParticipantsAction(selectedParticipants));
		dispatch(
			setUnionExportCompleteParticipantsFormAction(completeParticipantsForm)
		);
		dispatch(setUnionExportFinalizeFormAction(finalizeForm));
		dispatch(setUnionExportIdAction(id));
		dispatch(initUnionFormExportStepAction(activeUnionFormStep));
	};

export const setUnionExportStudioFormAction = (
	studioForm: Partial<UnionFormStudioForm>
) => ({
	type: SET_UNION_EXPORT_STUDIO_FORM,
	form: studioForm,
});

export const setUnionExportCompleteParticipantsFormAction = (
	completeParticipantsForm: Partial<UnionFormCompleteParticipantsForm>
) => ({
	type: SET_UNION_EXPORT_COMPLETE_PARTICIPANTS_FORM,
	form: completeParticipantsForm,
});

export const initializeCompleteParticipantsFormAction = () => ({
	type: INITIALIZE_UNION_EXPORT_COMPLETE_PARTICIPANTS_FORM,
});

export const setUnionExportFinalizeFormAction = (
	finalizeForm: Partial<UnionFormFinalizeForm>
) => ({
	type: SET_UNION_EXPORT_FINALIZE_FORM,
	form: finalizeForm,
});

export const setUnionExportParticipantsAction = (
	participants: Participant[]
) => ({
	type: SET_UNION_EXPORT_PARTICIPANTS,
	participants,
});

export const setUnionExportRecordingRowsAction = (
	recordingRows: UnionFormRecordingRow[]
) => {
	console.log('setUnionExportRecordingRowsAction', recordingRows);
	return {
		type: SET_UNION_EXPORT_RECORDING_ROWS,
		recordingRows,
	};
};

export const setUnionFormAction = (form: any) => ({
	// TODO: TS MIGRATION
	type: SET_UNION_FORM,
	form,
});

export const setSagFormAction = (form: any) => ({
	// TODO: TS MIGRATION
	type: SET_SAG_FORM,
	form,
});

export const setExportLeadWriterNameAction = (name: string) => ({
	type: SET_EXPORT_LEAD_WRITER_NAME,
	name,
});

export const confirmExportAction = () => ({
	type: CONFIRM_EXPORT,
});

export const clearExportValidationErrorsAction = () => ({
	type: CLEAR_EXPORT_VALIDATION_ERRORS,
});

export const addExportValidationErrorsAction = (
	errors: ExportValidationErrorsState
) => ({
	type: ADD_EXPORT_VALIDATION_ERRORS,
	errors,
});

export const downloadExportAction =
	(exportId: number, filename: string, throwError = false) =>
	(dispatch: AppDispatch) => {
		dispatch(startExportRequestAction(DOWNLOAD_EXPORT_REQUEST));

		return downloadExport(exportId)
			.then(response => {
				dispatch(exportRequestSuccessAction());
				FileSaver.saveAs(response.data, filename);
			})
			.catch(error => {
				if (throwError) {
					throw error;
				}

				_handleExportError(error, dispatch);
			});
	};

export const downloadExportESignatureAction =
	(exportId: number, throwError = false) =>
	(dispatch: AppDispatch, getState: GetState) => {
		const exportData = getState().exports.exportsById[exportId];

		return downloadExportESignature(exportId)
			.then(response => {
				const { name } = path.parse(exportData?.filename!);
				// TODO: Make sure filename is picked up from header instead of hardcoding the PDF extension
				FileSaver.saveAs(response.data, `${name}-signed.pdf`);
			})
			.catch(error => {
				// 409 is used to indicate that the signed document is not ready for download yet
				if (error?.response?.status === 409) {
					dispatch(
						showModalAction(CONFIRMATION_MODAL, {
							size: 'md',
							title: 'Whoops! Download Not Available Yet',
							description:
								'It looks like the signed document is not ready for download just yet. Sorry about that. Please try again in a few seconds!',
							confirmAction: {
								label: 'Dismiss',
								onClick: () => {
									dispatch(hideModal());
								},
							},
						})
					);

					return;
				}

				if (throwError) {
					throw error;
				}

				_handleExportError(error, dispatch);
			});
	};

export const clearExportRequestAction = () => ({
	type: CLEAR_EXPORT_REQUEST,
});

export const startExportRequestAction = (requestLabel: string) => ({
	type: START_EXPORT_REQUEST,
	requestLabel,
});

export const exportRequestSuccessAction = () => ({
	type: EXPORT_REQUEST_SUCCESS,
});

export const exportRequestErrorAction = (errorMessage?: string) => ({
	type: EXPORT_REQUEST_ERROR,
	errorMessage,
});

export const exportRequestAuthErrorAction = () => (dispatch: AppDispatch) => {
	dispatch(invalidTokenAction());

	return {
		type: EXPORT_REQUEST_AUTH_ERROR,
	};
};

export const initUnionFormExportStepAction = (initStep: UnionFormStep) => ({
	type: SET_UNION_FORM_EXPORT_STEP,
	step: initStep,
});

export const setB4TypeAction = (b4Type: B4Type) => ({
	type: SET_B4_TYPE,
	b4Type,
});

export const nextExportStepAction = () => ({
	type: NEXT_UNION_FORM_EXPORT_STEP,
});

export const previousExportStepAction = () => ({
	type: PREVIOUS_UNION_FORM_EXPORT_STEP,
});

export const clearExportAction = () => ({
	type: CLEAR_EXPORT,
});

export const clearAllCurrentExportDataAction =
	() => (dispatch: AppDispatch) => {
		dispatch(clearExportValidationErrorsAction());
		dispatch(clearExportRequestAction());
		// clearExportAction resets the reducer state to initialState except for exportsById
		dispatch(clearExportAction());
		dispatch(clearActiveExportFormAction());
	};

export const setExportPayloadAction = (
	payload: any,
	exportType: ExportType | null
) => ({
	// TODO: TS MIGRATION
	type: SET_EXPORT_PAYLOAD,
	exportPayload: payload,
	exportType,
});

export const setExportTypeAction = (exportType: ExportType) => ({
	type: SET_EXPORT_TYPE,
	exportType,
});

export const addLocalExportsAction = (exports: ApiExportMetadata[]) => ({
	// TODO: TS MIGRATION
	type: ADD_LOCAL_EXPORTS,
	exports,
});

export const setPreviewExportDataAction = (data: any) => ({
	// TODO: TS MIGRATION
	type: SET_PREVIEW_EXPORT_DATA,
	data,
});

export const setUnionExportIdAction = (id: string | null) => ({
	type: SET_UNION_EXPORT_ID,
	id,
});

export const createExportAction =
	(
		onCreate: ((_export?: any) => void) | null, // TODO: TS MIGRATION
		isPreview = false,
		removeRecordings = false
	) =>
	(dispatch: AppDispatch, getState: GetState) => {
		dispatch(startExportRequestAction(CREATE_EXPORT_REQUEST));
		const payload = getState().exports.exportPayload;

		if (removeRecordings) {
			delete payload.recordings;
		}

		return createExport(payload, isPreview)
			.then(res => {
				if (isPreview) {
					console.log('PREVIEW');
					dispatch(setPreviewExportDataAction(res.data));
					dispatch(exportRequestSuccessAction());

					if (onCreate) {
						onCreate();
					}

					return;
				}

				const newExport = res.data.export;

				if (!newExport) {
					_handleExportError(
						null,
						dispatch,
						res.data.detail ||
							'Whoops! Hiccup detected while creating the export. Maybe try again?'
					);
					return;
				}
				const recordingId = newExport.recording_id;
				const albumId = newExport.album_id;

				// add to exportsById
				dispatch(addLocalExportsAction([newExport]));

				// add to recording
				if (recordingId) {
					dispatch(
						addExportsToLocalRecordingAction(recordingId, [newExport.id])
					);
				}

				if (albumId) {
					// add to album
					dispatch(addExportsToLocalAlbumAction(albumId, [newExport.id]));
				}

				dispatch(exportRequestSuccessAction());

				if (onCreate) {
					// Get export with local front-end format
					const savedExport = getState().exports.exportsById[newExport.id];

					onCreate(savedExport);
				}
			})
			.catch(err => _handleExportError(err, dispatch));
	};

export const deleteExportAction =
	(id: number) => (dispatch: AppDispatch, getState: GetState) => {
		dispatch(startExportRequestAction(DELETE_EXPORT_REQUEST));

		const exportData = cloneDeep(getState().exports.exportsById[id]);

		if (!exportData) {
			_handleExportError(
				null,
				dispatch,
				'Whoops! Hiccup detected while deleting the export. Maybe try again?'
			);
			return;
		}

		return deleteExport(id)
			.then(_ => {
				dispatch(exportRequestSuccessAction());

				if (exportData.albumId) {
					dispatch(
						deleteExportFromLocalAlbumAction(exportData.albumId, exportData.id)
					);
				}

				if (exportData.recordingId) {
					dispatch(
						deleteExportFromLocalRecordingAction(
							exportData.recordingId,
							exportData.id
						)
					);
				}

				dispatch(deleteLocalExportAction(exportData.id));
			})
			.catch(err => _handleExportError(err, dispatch));
	};

export const deleteLocalExportAction = (id: number) => ({
	type: DELETE_LOCAL_EXPORT,
	id,
});

export const fetchRecordingExportsAction =
	(recordingId: number) => (dispatch: AppDispatch) => {
		dispatch(startExportRequestAction(FETCH_RECORDING_EXPORTS_REQUEST));

		return fetchRecordingExports(recordingId)
			.then(res => {
				const exports = res.data.exports;

				// add to exportsById
				dispatch(addLocalExportsAction(exports));

				// add to recording
				dispatch(
					setLocalRecordingExportsAction(
						recordingId,
						exports.map((ex: any) => ex.id) // TODO: TS MIGRATION
					)
				);

				dispatch(exportRequestSuccessAction());
			})
			.catch(err => _handleExportError(err, dispatch));
	};

export const fetchAlbumExportsAction =
	(albumId: number) => (dispatch: AppDispatch) => {
		dispatch(startExportRequestAction(FETCH_ALBUM_EXPORTS_REQUEST));

		return fetchAlbumExports(albumId)
			.then(res => {
				const exports = res.data.exports;

				dispatch(addLocalExportsAction(exports));

				dispatch(
					setLocalAlbumExportsAction(
						albumId,
						exports.map((ex: any) => ex.id) // TODO: TS MIGRATION
					)
				);

				dispatch(exportRequestSuccessAction());
			})
			.catch(err => _handleExportError(err, dispatch));
	};

export const fetchProjectExportsAction =
	({
		albumId,
		recordingId,
	}: {
		albumId?: number | null;
		recordingId?: number | null;
	}) =>
	(dispatch: AppDispatch) => {
		if (recordingId) {
			return dispatch(fetchRecordingExportsAction(recordingId));
		}

		if (albumId) {
			return dispatch(fetchAlbumExportsAction(albumId));
		}
	};

export const getLatestContractNumberAction =
	(setContractNumber: React.Dispatch<React.SetStateAction<string>>) =>
	(dispatch: AppDispatch) => {
		dispatch(startExportRequestAction(GET_LATEST_CONTRACT_NUMBER_REQUEST));

		return getLatestContractNumber()
			.then(res => {
				// setContractNumber is a React state setter
				setContractNumber(res.data.contract_number);
				dispatch(exportRequestSuccessAction());
			})
			.catch(err => {
				// 404 is used whenever a user has no latest contract number, so we can ignore it
				if (err.response.status !== 404) {
					_handleExportError(
						err,
						dispatch,
						'Whoops! Hiccup detected while fetching the latest contract number.'
					);
				}
			});
	};

export const setCloudLatestContractNumberAction =
	(number: string) => (dispatch: AppDispatch) => {
		dispatch(startExportRequestAction(SET_LATEST_CONTRACT_NUMBER_REQUEST));

		return setLatestContractNumber(number)
			.then(res => {
				dispatch(exportRequestSuccessAction());
			})
			.catch(err => {
				_handleExportError(
					err,
					dispatch,
					'Whoops! Hiccup detected while setting the contract number. Maybe enter it manually this time.'
				);
			});
	};

export const setExportParamsAction = (params: any) => ({
	// TODO: TS MIGRATION
	type: SET_EXPORT_PARAMS,
	params,
});

export const generateDripImagesAction =
	(playlistId: Playlist['id'], image: string, imageMimeType: string) =>
	(dispatch: AppDispatch, getState: GetState) => {
		dispatch(startExportRequestAction(CREATE_EXPORT_REQUEST));
		const playlist = getPlaylistById(getState(), { playlistId });

		if (!playlist) {
			_handleExportError(
				null,
				dispatch,
				'Whoops! Hiccup detected while generating the promotional image. Maybe try again?'
			);
			return;
		}

		return generateDripImages({
			playlistId,
			image,
			imageMimeType,
		})
			.then(async exportData => {
				dispatch(exportRequestSuccessAction());
				// refresh playlist to get new dripImageId

				await dispatch(fetchPlaylistAction(playlistId));
				await dispatch(
					downloadExportAction(
						exportData.id,
						generateDripImagesFilename(playlist)
					)
				);
				// await downloadToFileSystem({
				// 	blob: images,
				// 	suggestedName: generateDripImagesFilename(playlist),
				// });
			})
			.catch(err =>
				_handleExportError(
					err,
					dispatch,
					'Whoops! Hiccup detected while generating the promotional image. Maybe try again?'
				)
			);
	};

export const downloadDripImagesAction =
	(playlistId: Playlist['id']) =>
	(dispatch: AppDispatch, getState: GetState) => {
		const { playlistsById } = getState().playlists;
		const playlist = playlistsById?.[playlistId];

		if (!playlist) {
			_handleExportError(
				new Error(`Playlist with ID ${playlistId} not found`),
				dispatch,
				'Whoops! Hiccup detected while downloading the promotional image. Maybe try again?'
			);
			return;
		}

		return dispatch(
			downloadExportAction(
				playlist?.playlist?.dripImageId!,
				generateDripImagesFilename(playlist)
			)
		)
			.then(() => {
				dispatch(exportRequestSuccessAction());

				// downloadToFileSystem({
				// 	blob: response.data,
				// 	suggestedName: generateDripImagesFilename(playlist),
				// });
			})
			.catch((error: any) => {
				_handleExportError(error, dispatch);
			});
	};

export const setExportMainArtistsAction = (
	mainArtists: {
		name: string;
		email: string;
	}[]
) => ({
	type: SET_EXPORT_MAIN_ARTISTS,
	mainArtists,
});

export const fetchExportByIdAction =
	(exportId: number) => (dispatch: AppDispatch) => {
		dispatch(startExportRequestAction(FETCH_RECORDING_BY_ID_REQUEST));

		return getExportById(exportId)
			.then(fetchedExport => {
				const recordingId = fetchedExport.recording_id;
				const albumId = fetchedExport.album_id;

				// add to exportsById
				dispatch(addLocalExportsAction([fetchedExport]));

				// add to recording
				if (recordingId) {
					dispatch(
						addExportsToLocalRecordingAction(recordingId, [fetchedExport.id])
					);
				}

				if (albumId) {
					// add to album
					dispatch(addExportsToLocalAlbumAction(albumId, [fetchedExport.id]));
				}

				dispatch(exportRequestSuccessAction());
			})
			.catch(err => _handleExportError(err, dispatch));
	};

export const refreshExportPayloadAction =
	() => (dispatch: AppDispatch, getState: GetState) => {
		const { exportPayload: previousPayload, exportType } = getState().exports;
		const referencedRecordingIds: number[] =
			previousPayload?.recordings?.map(
				(recording: any) => recording.id as number
			) ?? [];
		const referencedAlbumId: number | null =
			previousPayload?.recordings?.[0]?.albums?.[0]?.id ?? null;

		const { recordingsById, albumsById } = getState().projects;

		const freshRecordings = referencedRecordingIds.map(
			(recordingId: number) => recordingsById![recordingId]
		);
		const freshAlbum = referencedAlbumId
			? albumsById![referencedAlbumId]
			: null;

		dispatch(
			setExportPayloadAction(
				{
					...previousPayload,
					recordings: freshRecordings.map((recording, index) => ({
						...recording.recording,
						albums: previousPayload.recordings[index].albums
							? [
									{
										...previousPayload.recordings[index].albums[0],
										...(freshAlbum?.album ?? {}),
										// the trackNumber field is set to empty string in the albums database
										// it is only set dynamically on each export request
										trackNumber:
											previousPayload.recordings[index].albums[0].trackNumber,
									},
							  ]
							: null,
					})),
				},
				exportType
			)
		);
	};

export const setExportRecordingErrorsAction = (
	exportRecordingErrors: {
		recordingId: Recording['id'];
		albumId?: Album['id'] | null;
		errors: string[];
	} | null
) => ({
	type: SET_EXPORT_RECORDING_ERRORS,
	exportRecordingErrors,
});
