import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Card, Col, Container, Fade, Row, Spinner } from 'react-bootstrap';
import './ExportValidation.scss';
import { validateExport } from './utils/validation';
import Button from '../../../layout/Button';
import {
	addExportValidationErrorsAction,
	clearAllCurrentExportDataAction,
	clearExportValidationErrorsAction,
	createExportAction,
	refreshExportPayloadAction,
	setExportParamsAction,
	setExportRecordingErrorsAction,
} from '../../../../store/exports/actions';
import Lottie from 'react-lottie';
import checkmarkAnimation from '../../../../assets/animations/successCheckmark.json';
import { useNavigate } from 'react-router-dom';
import { showModalAction } from '../../../../store/modal/actions';
import {
	ALBUM_DETAILS_MODAL,
	EXPORT_PREVIEW_MODAL,
} from '../../../../constants/modalTypes';
import SoundCreditLoader from '../../SoundCreditLoader';
import exportTypes from '../../../../constants/exportType';
import { deleteExportFormAction } from '../../../../store/session/actions';
import ROUTES from '../../../../router/routes';
import {
	getExtensionFromFileMetadata,
	navigateToProjectFiles,
	PREVIEWABLE_EXPORT_FILE_TYPES,
} from '../../../../helpers/fileTools';
import FILE_TABS from '../../../../constants/fileTabs.json';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import ExportType from '../../../../constants/exportType';
import { Helmet } from 'react-helmet';
import * as yup from 'yup';
import { getExportValidationErrors } from './utils/yupExportValidationTools';
import { getCurrentAlbum } from '../../../../store/projects/selectors';
import ExportValidationSection from './ExportValidationSection';
import ParticipantModal from '../../Credits/ParticipantModal';
import { replacePathVariables } from '../../../../helpers/routeTools';

const defaultOptions = {
	loop: false,
	autoplay: true,
	animationData: checkmarkAnimation,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice',
	},
};

export type ExportParamsType = {
	nextRoute?: string;
	createOnValidation?: boolean;
	nextExportParams?: Record<string, string>;
	hasPreview?: boolean;
	overrideCreate?: (
		getOnCreate: (recordingId: number, albumId: number | null) => void
	) => void;
	skipValidation?: boolean;
	afterCreate?: (recordingId: number, albumId: number | null) => void;
};

const ExportValidation = () => {
	const { exportPayload, exportType, validationErrors, exportParams } =
		useAppSelector(state => state.exports);
	const { recordingsById } = useAppSelector(state => state.projects);
	const currentAlbum = useAppSelector(getCurrentAlbum);

	const { activeExportFormId } = useAppSelector(state => state.session);

	const [showEditParticipantModal, setShowEditParticipantModal] =
		useState(false);
	const [editingParticipant, setEditingParticipant] =
		useState<Participant | null>(null);
	const [participantToastErrors, setParticipantToastErrors] = useState<
		string[] | null
	>(null);

	const [isValidating, setIsValidating] = useState(true);

	const hasErrors = useMemo(() => {
		if (!validationErrors) return null;

		return (
			(validationErrors.other?.length || 0) +
				Object.values(validationErrors.recordings ?? {}).length >
			0
		);
	}, [validationErrors]);

	// const flattenedValidationErrors = useMemo(() => {
	// 	if (!validationErrors) return [];
	// 	if (typeof validationErrors === 'string')
	// 		return [{ message: validationErrors }];

	// 	return flattenExportValidationErrors(validationErrors);
	// }, [validationErrors]);
	const createdExport = useRef(false); // ref to keep track of whether the export has been created
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		console.log(validationErrors);
	}, [validationErrors]);

	const {
		nextRoute,
		// * createOnValidation: whether to create the export on validation success,
		// * outside of the handleGenerateExport callback (used mostly for exports
		// * that contain previews, which set is_final_version to false)
		createOnValidation,
		nextExportParams,
		hasPreview,
		overrideCreate,
		skipValidation,
		afterCreate,
		skipGenerate,
	} = useMemo(() => {
		return exportParams || {};
	}, [exportParams]);

	const isSuccess = useMemo(
		() => !isValidating && !validationErrors,

		[validationErrors, isValidating]
	);

	// const isExporting = useMemo(
	// 	() =>
	// 		!!(
	// 			[CREATE_EXPORT_REQUEST, EXPORT_TO_RIN_REQUEST].includes(requestLabel) &&
	// 			requestStatus === REQUEST_LOADING
	// 		),
	// 	[requestLabel, requestStatus]
	// );

	useEffect(() => {
		if (isSuccess && nextRoute) {
			const onCreate = () => {
				dispatch(
					setExportParamsAction({
						...nextExportParams,
					})
				);

				navigate(nextRoute, { replace: true });
			};

			if (createOnValidation && !createdExport.current) {
				dispatch(createExportAction(onCreate, hasPreview));
				createdExport.current = true;
			} else {
				onCreate();
			}
		}
	}, [
		isSuccess,
		navigate,
		nextRoute,
		createOnValidation,
		dispatch,
		hasPreview,
		nextExportParams,
	]);

	const handleGenerateExport = useCallback(() => {
		const getOnCreate =
			(recordingId: number, albumId: number | null) => (exportData: any) => {
				// TODO: TS MIGRATION: export file type
				console.log('ON CREATE');

				if (afterCreate) {
					console.log('AFTER CREATE');
					afterCreate(recordingId, albumId);
				}

				dispatch(deleteExportFormAction(activeExportFormId!));

				navigateToProjectFiles({
					albumId,
					recordingId,
					navigate,
					section: FILE_TABS.EXPORTS,
				});

				dispatch(clearAllCurrentExportDataAction());
				// if the file's previewable, show the preview modal
				if (
					PREVIEWABLE_EXPORT_FILE_TYPES.includes(
						getExtensionFromFileMetadata(exportData)
					)
				) {
					dispatch(
						showModalAction(EXPORT_PREVIEW_MODAL, {
							size: 'lg',
							hideHeader: true,
							exportId: exportData.id,
							fullscreen: true,
						})
					);
				}
			};
		// Callback passed from ExportSelectionModal to handle export differently (e.g. RIN Export uses it)
		if (overrideCreate) {
			overrideCreate(getOnCreate);
			return;
		}

		let removeRecordings = false;

		if (
			[exportTypes.AFM_B4, exportTypes.AFM_B9, exportTypes.SAG_AFTRA].includes(
				exportType as ExportType
			)
		) {
			removeRecordings = true;
		}

		dispatch(
			createExportAction(
				getOnCreate(exportPayload.recording_id, exportPayload.album_id),
				false,
				removeRecordings
			)
		);
		createdExport.current = true;
	}, [
		overrideCreate,
		exportPayload,
		exportType,
		navigate,
		dispatch,
		afterCreate,
		activeExportFormId,
	]);

	const handleCancelExport = () => {
		dispatch(clearAllCurrentExportDataAction());
		navigate(ROUTES.Editor.path, { replace: true });
	};

	const handleRefreshPayload = useCallback(() => {
		dispatch(refreshExportPayloadAction());
		setIsValidating(true);
	}, [dispatch]);

	const renderValidationSections = useCallback(() => {
		const sections: JSX.Element[] = [];

		/* Other errors (general errors that do not adjust to a specific recording) */
		/* They typically aren't fixable by the user, like API errors, etc.*/
		if (
			validationErrors?.other?.length ||
			validationErrors?.recordings?.other?.length
		) {
			sections.push(
				<ExportValidationSection
					errors={[
						...(validationErrors.other ?? []),
						...(validationErrors?.recordings?.other ?? []),
					]}
				/>
			);
		}

		// Album-level errors
		if (validationErrors?.album?.length) {
			sections.push(
				<ExportValidationSection
					headerTitle={`Issues with ${
						currentAlbum?.isSingle ? 'Release' : 'Album'
					}`}
					headerSubtitle={
						<>
							<i className='fas fa-record-vinyl mr-2' />
							{currentAlbum?.title} - {currentAlbum?.artist}
						</>
					}
					errors={validationErrors.album}
					onFixClick={() => {
						dispatch(
							showModalAction(ALBUM_DETAILS_MODAL, {
								size: 'lg',
								albumId: currentAlbum?.id,
								toastErrors: validationErrors.album!.map(e => e.message),
								afterSubmit: handleRefreshPayload,
							})
						);
					}}
				/>
			);
		}

		// Recording-level Errors
		Object.entries(validationErrors?.recordings?.byId ?? {}).forEach(
			([recordingId, errors], index) => {
				const recording = recordingsById![parseInt(recordingId)];

				const generalRecordingErrors = [
					...(errors.other ?? []),
					// including general errors for participants
					...(errors.participants?.other ?? []),
				];

				const listOfReleaseDetailsAttributes = ['recordLabel', 'releaseDate'];

				const shouldRedirectToReleaseInfo = !!(
					errors.other &&
					errors.other.length >= 1 &&
					listOfReleaseDetailsAttributes.includes(errors.other[0].path)
				);

				sections.push(
					<ExportValidationSection
						errors={generalRecordingErrors}
						key={recordingId}
						headerTitle={index === 0 ? 'Issues with Recordings' : ''} // only show header title for first recording
						headerSubtitle={
							<>
								<i className='fas fa-music mr-2' />
								{recording?.title} - ({recording?.artist})
							</>
						}
						onFixClick={() => {
							debugger;
							dispatch(clearAllCurrentExportDataAction());

							dispatch(
								setExportRecordingErrorsAction({
									recordingId: recording.id,
									albumId: recording.albumId,
									errors: generalRecordingErrors.map(e => e.message),
								})
							);

							navigate(
								replacePathVariables(
									errors.other
										? shouldRedirectToReleaseInfo
											? ROUTES.EditReleaseDetails.path
											: // if there are general errors, go to the recording details page
											  ROUTES.EditRecordingDetails.path
										: // otherwise, solve participant-level errors from the credits page
										  ROUTES.EditRecordingCredits.path,
									{
										recordingId,
									}
								),
								{
									replace: true,
								}
							);
						}}
						// Participant-level Errors
						nestedSections={Object.entries(errors.participants?.byId ?? {}).map(
							([participantId, participantErrors]) => {
								const participant = recording.recording!.participants.find(
									p => p.id === parseInt(participantId)
								);
								return {
									headerTitle: 'Issues with Participant',
									headerSubtitle: (
										<>
											<i className='fas fa-user mr-2' />
											{participant?.creditedName ?? ''}
										</>
									),
									errors: participantErrors ?? [],
									onFixClick: () => {
										if (!participant) throw new Error('Participant not found');

										setEditingParticipant(participant);
										setParticipantToastErrors(
											participantErrors?.map(e => e.message) ?? []
										);
										setShowEditParticipantModal(true);
										console.log('fix!');
									},
								};
							}
						)}
					/>
				);
			}
		);

		// Now we create a new array with <hr/> between sections
		const sectionWithDividers: JSX.Element[] = [];
		sections.forEach((section, index) => {
			sectionWithDividers.push(section);
			// Add <hr/> if it's not the last element
			if (index < sections.length - 1) {
				sectionWithDividers.push(<hr />);
			}
		});

		return sectionWithDividers;
	}, [
		validationErrors,
		recordingsById,
		dispatch,
		currentAlbum,
		handleRefreshPayload,
		setShowEditParticipantModal,
		setEditingParticipant,
		setParticipantToastErrors,
		navigate,
	]);

	/*
	 * Validate the export payload on first render
	 */
	useEffect(() => {
		if (skipValidation) {
			setIsValidating(false);
			handleGenerateExport();
			return;
		}

		if (exportPayload && exportType && !createdExport.current) {
			setTimeout(
				() => {
					validateExport(exportPayload, exportType)
						.then(_ => {
							setIsValidating(false);

							dispatch(clearExportValidationErrorsAction());

							// creation is handled in a separate effect when createOnValidation is true
							if (createOnValidation || skipGenerate) return;

							handleGenerateExport();
						})
						.catch(err => {
							console.log('VALIDATION ERROR', err);

							const errors =
								err instanceof yup.ValidationError
									? (err.inner?.[0]?.params?.result as Record<
											Recording['id'],
											yup.ValidationError[]
									  >)
									: 'There was an error validating the project. Please try again.';

							console.log('ERRORS', errors);
							dispatch(
								addExportValidationErrorsAction(
									getExportValidationErrors(errors)
								)
							);

							setIsValidating(false);
						});
				},
				nextRoute ? 0 : 1000
			);
		}
		// TODO: Check if dependencies are correct
	}, [
		exportPayload,
		exportType,
		skipValidation,
		nextRoute,
		dispatch,
		handleGenerateExport,
		createOnValidation,
		skipGenerate,
	]);

	return (
		<>
			<Helmet>
				<title>Export {process.env.REACT_APP_TAB_TITLE}</title>
			</Helmet>
			<Container fluid className='container-horizontal-padding pt-5'>
				<Card>
					<Card.Body>
						{isValidating ? (
							<h2>PERFORMING REQUIRED INFORMATION CHECK...</h2>
						) : (
							<></>
						)}
						<div className='export-validation-container'>
							{isValidating || (nextRoute && !hasErrors) ? (
								<div className='w-100 d-flex justify-content-center align-items-center'>
									<SoundCreditLoader
										message={
											!nextRoute
												? 'Validating Information...'
												: !isValidating && hasPreview
												? 'Generating Preview...'
												: 'Processing Export...'
										}
									/>
								</div>
							) : (
								<>
									{!hasErrors && !nextRoute ? (
										<>
											<div className='d-flex justify-content-center align-items-center flex-column h-100 w-100 '>
												<Lottie
													options={defaultOptions}
													isClickToPauseDisabled
													height={250}
													width={250}
													style={{ cursor: 'default' }}
												/>
												<Fade in={isSuccess}>
													<>
														<p className='ready-to-export-text'>
															🎉 EXPORTING 🎉
														</p>
														<Spinner
															className='mt-2'
															animation='border'
															size='sm'
														/>
													</>
												</Fade>
											</div>
										</>
									) : (
										hasErrors && (
											<div className='w-100 h-100'>
												<p className='export-validation-failure-text'>
													Whoops! We need more info.
												</p>

												{renderValidationSections()}
											</div>
										)
									)}
								</>
							)}
						</div>
					</Card.Body>
				</Card>

				<Row>
					<Col className='d-flex justify-content-end align-items-center'>
						<Button
							onClick={handleCancelExport}
							label='Return to Song'
							className='mr-2'
						/>
					</Col>
				</Row>
			</Container>

			{showEditParticipantModal && (
				<ParticipantModal
					show={showEditParticipantModal}
					toggleShow={() => setShowEditParticipantModal(s => !s)}
					participant={editingParticipant}
					editing={true}
					setParticipant={setEditingParticipant}
					toastErrors={participantToastErrors}
					afterSubmit={() => {
						setParticipantToastErrors(null);
						handleRefreshPayload();
					}}
				/>
			)}
		</>
	);
};

export default ExportValidation;
