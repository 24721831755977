import React from 'react';

const ProjectInfoCell = ({ project }) => {
	return (
		<div className='d-flex flex-column align-items-center select-project-files__project-info'>
			<p className='mb-0 '>
				<i className='fas fa-music mr-2' />
				{project.title}
			</p>

			<p className='mb-0'>
				<i className='fas fa-user mr-2' />
				{project.artist}
			</p>
		</div>
	);
};

export default ProjectInfoCell;
