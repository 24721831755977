import { useSelector } from 'react-redux';
import SubLink from '../SubLink';
import SongTitle from './SongTitle';
import { Row, Col } from 'react-bootstrap';
import {
	CREATE_NEW_RECORDING_MODAL,
	EXPORT_SELECTION_MODAL,
	CHAT_MODAL,
} from '../../../constants/modalTypes';
import { useState, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import {
	clearSelectedProjectAction,
	getUserProjectsAction,
} from '../../../store/projects/actions';
import './SubMenu.scss';
import { showModalAction, setModalTitle } from '../../../store/modal/actions';
import { useFormikContext } from 'formik';
import _ from 'lodash';
import { useMatch, useNavigate } from 'react-router-dom';
import {
	getAlbumRecordingIds,
	getTrackNumberById,
} from '../../../helpers/albumTools';
import React from 'react';
import {
	getCurrentAlbum,
	getCurrentRecording,
} from '../../../store/projects/selectors';
import ROUTES from '../../../router/routes';
import { navigateToProjectFiles } from '../../../helpers/fileTools';
import FILE_TABS from '../../../constants/fileTabs.json';
import { replaceRecordingIdInCurrentUrl } from '../../../helpers/projectTools';

const Separator = () => (
	<div>
		<hr style={{ borderColor: 'rgb(197,197,197)' }} />
	</div>
);

function EditorSubMenu() {
	const { albumsById, recordingsById, currentRecordingId, currentAlbumId } =
		useSelector(state => state.projects);
	const currentRecording = useSelector(getCurrentRecording);
	const currentAlbum = useSelector(getCurrentAlbum);
	const dispatch = useDispatch();
	const [isInactive, setIsInactive] = useState(true);
	const formik = useFormikContext();
	const { myEditorProfile } = useSelector(state => state.projects);
	const { savedExportFormsById } = useSelector(state => state.session);
	const { profiles } = useSelector(state => state.profiles);
	const navigate = useNavigate();
	const match = useMatch('/recordings/:recordingId/*');

	const exportInProgress = useMemo(() => {
		const projectExports = Object.values(savedExportFormsById).filter(form => {
			if (form.recordingId === currentRecordingId) return true;
			if (currentAlbumId) return form.albumId === currentAlbumId;

			return false;
		});

		return projectExports.length > 0;
	}, [savedExportFormsById, currentRecordingId, currentAlbumId]);

	const releaseDetailsTitle = useMemo(() => {
		if (currentAlbumId && !currentAlbum?.isSingle) {
			// single releases are also albums, so we need to check for that
			return 'Album Info';
		}

		return 'Release Info';
	}, [currentAlbumId, currentAlbum]);

	const projectRecordings = useMemo(() => {
		if (!currentAlbumId && currentRecordingId) {
			return [{ ...recordingsById[currentRecordingId], trackNumber: 1 }];
		} else if (currentAlbumId && albumsById) {
			const albumTracks = albumsById[currentAlbumId].recordings;

			return getAlbumRecordingIds(albumsById[currentAlbumId]).map(
				recordingId => ({
					...recordingsById[recordingId],
					trackNumber: getTrackNumberById(albumTracks, recordingId),
				})
			);
		} else return [];
	}, [currentAlbumId, currentRecordingId, recordingsById, albumsById]);

	const isLoadingRecording = useMemo(
		() => !currentRecording?.recording,
		[currentRecording]
	);

	const isLoadingProfiles = useMemo(() => !profiles, [profiles]);

	useEffect(() => {
		if (_.isEmpty(recordingsById)) {
			dispatch(getUserProjectsAction());
		}
	}, [recordingsById, dispatch]);

	useEffect(() => {
		if (currentRecordingId) setIsInactive(false);
	}, [currentRecordingId]);

	const handleCloseAll = () => {
		dispatch(clearSelectedProjectAction());
		navigate(ROUTES.Projects.path);
	};

	const handleNew = () => {
		dispatch(
			showModalAction(CREATE_NEW_RECORDING_MODAL, {
				size: 'lg',
				albumId: currentAlbum?.isSingle ? null : currentAlbumId,
				onCreate: id => {
					navigate(replaceRecordingIdInCurrentUrl(id, match));
				},
				clearCurrentAlbumIdAfterImport: currentAlbum?.isSingle,
			})
		);
	};

	const handleExport = () => dispatch(showModalAction(EXPORT_SELECTION_MODAL));

	const handleSave = () => {
		const saveIcon = document.querySelector('.save-icon');

		saveIcon.classList.toggle('hidden-icon');

		formik.submitForm();

		setTimeout(() => saveIcon.classList.toggle('hidden-icon'), 1000);
	};

	const openChat = () => {
		dispatch(setModalTitle('Chat'));
		dispatch(
			showModalAction(CHAT_MODAL, {
				size: 'lg',
				recordingId: currentRecordingId,
			})
		);
	};

	const navigateToRecordingFiles = () => {
		navigateToProjectFiles({
			albumId: null,
			recordingId: currentRecordingId,
			navigate,
			section: FILE_TABS.FILES,
		});
	};

	const navigateToAlbumFiles = () => {
		navigateToProjectFiles({
			albumId: currentAlbumId,
			recordingId: null,
			navigate,
			section: FILE_TABS.FILES,
		});
	};

	return (
		<div className='sub-menu-container'>
			<Row>
				<Col xs={12} style={{ minHeight: '5vh', WebkitAppRegion: 'drag' }} />
			</Row>

			<nav className='action-links'>
				<SubLink
					to={ROUTES.EditRecordingDetails.relativePath}
					fullPath={ROUTES.EditRecordingDetails.path}
					label='Song Info'
				/>
				<SubLink
					to={ROUTES.EditReleaseDetails.relativePath}
					fullPath={ROUTES.EditReleaseDetails.path}
					label={releaseDetailsTitle}
					isInactive={isInactive}
				/>
				<SubLink
					to={ROUTES.EditRecordingCredits.relativePath}
					fullPath={ROUTES.EditRecordingCredits.path}
					label='Credits'
					isInactive={isInactive}
				/>
				<SubLink
					to={ROUTES.EditLyrics.relativePath}
					fullPath={ROUTES.EditLyrics.path}
					label='Lyrics'
					isInactive={isInactive}
				/>
				<SubLink
					to={ROUTES.EditRecordingNotes.relativePath}
					fullPath={ROUTES.EditRecordingNotes.path}
					label='Notes'
					isInactive={isInactive}
				/>
			</nav>

			<Separator />

			<nav className='mt-2'>
				{myEditorProfile && !myEditorProfile.is_read_only && (
					<>
						<SubLink label='New Recording' isAction onClick={handleNew} />
						<SubLink
							label={
								<>
									Save Recording
									<i className='hidden-icon save-icon ml-2 fas fa-check-circle'></i>
								</>
							}
							isAction
							onClick={handleSave}
							isDisabled={isLoadingRecording || isLoadingProfiles}
						/>
						<SubLink label='Close All' isAction onClick={handleCloseAll} />
					</>
				)}
				<SubLink onClick={openChat} label='Chat' isAction />
				<SubLink
					onClick={navigateToRecordingFiles}
					label='Recording Files'
					isAction
				/>
				{currentAlbumId && !currentAlbum?.isSingle && (
					<SubLink
						onClick={navigateToAlbumFiles}
						label='Album Files'
						isAction
					/>
				)}
				{myEditorProfile && !myEditorProfile.is_read_only && (
					<SubLink
						onClick={handleExport}
						label={
							<div className='d-flex justify-content-between align-items-center'>
								Exports
								{exportInProgress && (
									<div className='sub-menu-in-progress-chip ml-2'>
										IN PROGRESS
									</div>
								)}
							</div>
						}
						isAction
						isDisabled={isLoadingRecording || isLoadingProfiles}
					/>
				)}
			</nav>

			<Separator />

			<p className='text-white fw-bold pl-4'>RECORDINGS</p>
			<div className='sub-menu-recording-list'>
				{projectRecordings.map((recording, index) => (
					<SongTitle
						recording={recording}
						trackNumber={recording.trackNumber}
						key={`title-${index}`}
					/>
				))}
			</div>
		</div>
	);
}

export default EditorSubMenu;
