import React, { useEffect } from 'react';
import isniStrings, { IsniString } from '../isniStrings';
import SoundCreditLoader from '../../SoundCreditLoader/SoundCreditLoader';
import { Message } from 'primereact/message';
import Button from '../../../layout/Button/Button';
import Lottie from 'react-lottie';
import checkmarkAnimation from '../../../../assets/animations/successCheckmark.json';

const defaultAnimationOptions = {
	loop: false,
	autoplay: true,
	animationData: checkmarkAnimation,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice',
	},
};

type IsniRetrievedStepProps = {
	isni: string;
	onDismiss: () => void;
	onAssignIsni: () => Promise<void>;
	getIsniStringFn: (isniString: IsniString) => string;
};

const IsniRetrievedStep = ({
	isni,
	onDismiss,
	getIsniStringFn,
	onAssignIsni,
}: IsniRetrievedStepProps) => {
	const [loading, setLoading] = React.useState(false);
	const [isAssigned, setIsAssigned] = React.useState(false);
	const [error, setError] = React.useState<string | null>(null);

	useEffect(() => {
		if (!loading && !isAssigned) {
			setLoading(true);
			onAssignIsni()
				.then(() => {
					setIsAssigned(true);
				})
				.catch(err => {
					setError('Something went wrong. Please try again.');
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [loading, isAssigned, onAssignIsni, isni]);

	if (loading || !isAssigned) {
		return <SoundCreditLoader message='Assigning ISNI to profile...' />;
	}

	if (error) {
		return (
			<div className='d-flex align-items-center flex-column justify-content-center'>
				<Message
					severity='error'
					text={error}
					style={{
						width: '50%',
					}}
					className='mb-3'
				/>

				<Button
					label='Try again'
					onClick={() => setError(null)}
					leftIcon='fas fa-redo'
				/>
			</div>
		);
	}

	return (
		<>
			<Lottie
				options={defaultAnimationOptions}
				isClickToPauseDisabled
				height={250}
				width={250}
				style={{ cursor: 'default' }}
			/>
			<h3>{getIsniStringFn(isniStrings.IsniRetrieved.title)}</h3>

			<h4>{getIsniStringFn(isniStrings.IsniRetrieved.description(isni))}</h4>

			<div className='d-flex justify-content-center'>
				<Button label='Dismiss' onClick={onDismiss} theme='dark' />
			</div>
		</>
	);
};

export default IsniRetrievedStep;
