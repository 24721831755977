import { Navigate, Outlet } from 'react-router-dom';

interface GuardedRoutesProps {
	/**
	 * Permission check for route
	 * @default false
	 */
	isRouteAccessible?: boolean;
	/**
	 * Route to be redirected to
	 * @default '/'
	 */
	redirectRoute?: string;
}

/**
 * Component for guarding restricted routes
 *
 * @example Default usage
 * ```ts
 * <GuardedRoutes
 *	 isRouteAccessible={true}
 * />
 * ```
 *
 * @example Usage with custom redirected route
 * ```ts
 * <GuardedRoutes
 *	 isRouteAccessible={false}
 *	 redirectRoute={'/login'}
 * />
 * ```
 */
const GuardedRoutes = ({
	isRouteAccessible = false,
	redirectRoute = '/',
}: GuardedRoutesProps): JSX.Element =>
	isRouteAccessible ? <Outlet /> : <Navigate to={redirectRoute} replace />;

export default GuardedRoutes;
