import { getDripEmails } from '../api/services/filesService';
import NoCollectedEmailsError from '../errors/NoCollectedEmailsError';
import { arrayToCsv, zipCsvs } from './csvTools';
import { saveAs } from 'file-saver';

export const downloadDripEmailsAsCsvZip = async (playlist: Playlist) => {
	const { attendedDrip, missedDrip } = await getDripEmails({
		playlistId: playlist.id,
	});

	if (!attendedDrip.length && !missedDrip.length) {
		throw new NoCollectedEmailsError();
	}

	const [attendedDripCsv, missedDripCsv] = [attendedDrip, missedDrip].map(
		data => arrayToCsv(data)
	);

	const zip = await zipCsvs([
		{ filename: 'attended.csv', data: attendedDripCsv },
		{ filename: 'missed.csv', data: missedDripCsv },
	]);

	const timestamp = new Date().toLocaleString();
	const zipFilename = `${playlist.name} - Drip Emails - ${timestamp}.zip`;
	saveAs(zip, zipFilename);
};

export const generateDripImagesFilename = (
	playlist: Playlist,
	extension: string = 'zip'
) =>
	`${
		playlist.name
	} - Drip Images - ${new Date().toLocaleString()}.${extension}`;
