import React from 'react';
import IconButton from '../../../../../layout/IconButton';
import '../../UploadFilesModal.scss';
import theme from '../../../../../../theme.module.scss';
import fileSize from 'filesize';
import FileTagSelect from '../../../../../form/FileTagSelect';

const InputFileItem = ({
	inputFile,
	onDelete,
	fileLabel,
	setFileLabel,
	hideLabel = false,
}) => {
	return (
		<div className='input-file-item'>
			<div className='d-flex align-items-center'>
				<i
					className='far fa-file mr-3'
					style={{ color: theme.primary, fontSize: '1.5rem' }}
				/>
				<div className='d-flex flex-column'>
					<span className='input-file-item-name'>{inputFile.name}</span>
					<span className='input-file-item-size'>
						{fileSize(inputFile.size)}
					</span>
				</div>
			</div>
			<div className='d-flex align-items-center'>
				{!hideLabel && (
					<div style={{ width: '200px' }}>
						<FileTagSelect
							fileTag={fileLabel}
							setFileTag={setFileLabel}
							dense
						/>
					</div>
				)}

				<IconButton
					icon='fas fa-trash'
					color={theme.error}
					onClick={onDelete}
				/>
			</div>
		</div>
	);
};

export default InputFileItem;
