import './ShareModal.scss';
import { Overlay } from 'react-bootstrap';
import Button from '../../../layout/Button';
import { Button as BButton } from 'react-bootstrap';
import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	deleteRecordingEditor,
	deleteAlbumEditor,
	changeRecordingReadOnly,
	changeAlbumReadOnly,
	claimActiveEditor,
} from '../../../../api/services/editorService';
import Select from '../../../form/Select';
import { hideModal, showModalAction } from '../../../../store/modal/actions';
import {
	changeAlbumOwnerAction,
	changeRecordingOwnerAction,
	clearProjectsAction,
	getUserProjectsAction,
} from '../../../../store/projects/actions';
import React from 'react';
import IconButton from '@/components/layout/IconButton/IconButton';
import theme from '../../../../theme.module.scss';
import { CONFIRMATION_MODAL } from '@/constants/modalTypes';

const EditorRow = ({
	editors,
	recordingId,
	albumId,
	editor,
	setErrorMsg,
	fetchEditors,
	toggleLoading,
	errorRef
}) => {
	const [show, setShow] = useState(false);
	const ref = useRef(null);
	const { userId } = useSelector(state => state.auth);
	const { myEditorProfile } = useSelector(state => state.projects);
	const [isUpdatingEditor, setIsUpdatingEditor] = useState(false);
	const [isDeletingEditor, setIsDeletingEditor] = useState(false);
	let shareError = null;
	const dispatch = useDispatch();

	const reloadProjects = async () => {
		dispatch(clearProjectsAction());
		await Promise.resolve(dispatch(getUserProjectsAction()));
	};

	const handleDelete = async () => {
		try {
			if (myEditorProfile.is_read_only) return;

			setIsDeletingEditor(true);

			if (!myEditorProfile.is_active_editor && !albumId) {
				await claimActiveEditor(userId, recordingId);
				console.log('USER ACTIVE CLAIMED WHEN DELETING!');
			}

			if (albumId) {
				await deleteAlbumEditor({
					album_id: albumId,
					editor_id: editor.user_id,
					editor_email: editor.email || editor.invited_email,
				});
			} else {
				await deleteRecordingEditor({
					recording_id: recordingId,
					editor_id: editor.user_id,
					editor_email: editor.email || editor.invited_email,
				});
			}

			fetchEditors();

			if (editor.user_id === userId) {
				dispatch(hideModal());
				reloadProjects();
			}
		} catch (e) {
			dispatch(
				showModalAction(CONFIRMATION_MODAL, {
					title: 'Oops!',
					description:
						e?.response?.data?.detail ||
						'Whoops! Something went wrong while removing the editor.',
					confirmAction: {
						label: 'Dismiss',
						onClick: () => dispatch(hideModal()),
					},
					size: 'md',
				})
			);
			// dispatch(
			// 	showErrorAlert(
			// 		e?.response?.data?.detail ||
			// 			'Whoops! Something went wrong while removing the editor.'
			// 	)
			// );
		} finally {
			setIsDeletingEditor(false);
		}
	};

	const handlePermissionChange = async option => {
		try {
			if (!editor.user_id || !editor.is_accepted) {
				showError('Something went wrong changing the role, try again later.');
				console.error("editor doesn't have and id or it's not accepted.");
				return;
			}

			const payload = {
				editorId: editor.user_id,
				readOnly: option.value,
			};

			setIsUpdatingEditor(true);

			if (!myEditorProfile.is_active_editor && !albumId) {
				await claimActiveEditor(userId, recordingId);
			}

			if (albumId) {
				await changeAlbumReadOnly({ ...payload, albumId });
			} else {
				await changeRecordingReadOnly({ ...payload, recordingId });
			}

			fetchEditors();
		} catch (e) {
			await fetchEditors();

			dispatch(
				showModalAction(CONFIRMATION_MODAL, {
					title: 'Whoops!',
					description:
						e?.response?.data?.detail ||
						'Whoops! Something went wrong while changing the role.',
					confirmAction: {
						label: 'Dismiss',
						onClick: () => dispatch(hideModal()),
					},
					size: 'md',
				})
			);
			console.log({ e });
		} finally {
			setIsUpdatingEditor(false);
		}
	};

	const showError = errMessage => {
		setErrorMsg(errMessage);
		shareError = errorRef.current;

		if (shareError) {
			shareError.classList.toggle('show-share-error');

			setTimeout(() => {
				shareError = errorRef.current;
				if (shareError && shareError.classList)
					shareError.classList.toggle('show-share-error');
			}, 3000);
		}
	};

	const changeOwner = async newOwnerId => {
		try {
			if (albumId) {
				await Promise.resolve(
					dispatch(changeAlbumOwnerAction(albumId, newOwnerId))
				);
			} else {
				await Promise.resolve(
					dispatch(changeRecordingOwnerAction(recordingId, newOwnerId))
				);
			}

			fetchEditors();
		} catch (e) {
			dispatch(
				showModalAction(CONFIRMATION_MODAL, {
					title: 'Whoops!',
					description:
						e?.response?.data?.detail ||
						'Whoops! Something went wrong while changing the owner.',
					confirmAction: {
						label: 'Dismiss',
						onClick: () => dispatch(hideModal()),
					},
					size: 'md',
				})
			);
		} finally {
			setShow(false);
		}
	};

	if (!editor.is_accepted && !editor.is_owner)
		return (
			<tr className='px-1'>
				<td className='share-participant'>
					<section>
						<span className='pl-1 text-black-50 editor-name'>
							{editor.name}
						</span>
						<span className='pl-1 text-black-50'>{editor.invited_email}</span>
					</section>
				</td>
				<td>
					<section className='share-options'>
						<span className='text-black-50'>Invited</span>
						{!myEditorProfile.is_read_only && (
							<Button
								label='Cancel'
								onClick={handleDelete}
								fontSize='0.9rem'
								isLoading={isDeletingEditor}
							/>
						)}
					</section>
				</td>
			</tr>
		);

	return (
		<tr className='px-1'>
			<td className='share-participant'>
				{editor.is_owner ? (
					<>
						{editor.name && <span className='pl-1'>{editor.name}</span>}
						<span className='pl-1'>{editor.invited_email || editor.email}</span>
					</>
				) : (
					<>
						{editor.name && <span className='pl-1'>{editor.name}</span>}
						<span className='pl-1'>{editor.invited_email}</span>
					</>
				)}
			</td>
			<td className='share-options'>
				{editor.is_owner && myEditorProfile.is_owner ? (
					<>
						<BButton
							style={{ position: 'relative', backgroundColor: 'transparent' }}
							className='btn owner-btn text-purple'
							ref={ref}
							onClick={() => setShow(!show)}
						>
							<span style={{ color: '#666666' }}>Administrator</span>
							<i
								className='ml-1 fas fa-chevron-right'
								style={{ transform: 'rotate(90deg)' }}
							></i>
						</BButton>
						<Overlay target={ref.current} show={show} container={ref}>
							{({ placement, arrowProps, show: _show, popper, ...props }) => (
								<div className='owner-overlay'>
									{editors.length &&
									editors.filter(
										item =>
											!item.is_read_only && !item.is_owner && item.is_accepted
									).length ? (
										<>
											<hgroup>
												<h3 className='text-left mb-4 owner-overlay-header'>
													You can only transfer your adminsitration role to
													editors
												</h3>
												<h3 className='text-secondary text-left owner-overlay-header'>
													Editors:
												</h3>
											</hgroup>
											<hr />
											{editors.map(option => {
												if (
													!option.is_read_only &&
													!option.is_owner &&
													option.is_accepted
												)
													return (
														<div
															onClick={() => changeOwner(option.user_id)}
															className='d-flex align-items-center editor'
														>
															{option.image_path ? (
																<img
																	className='mr-3 rounded-circle'
																	width={40}
																	height={40}
																	src={option.image_path}
																	alt='editor'
																/>
															) : (
																<div className='mr-3 fallback-image'>
																	<span>
																		{option.name &&
																			option.name.length &&
																			option.name.slice(0, 1).toUpperCase()}
																	</span>
																</div>
															)}
															<div className='d-flex gap-2 flex-column'>
																<span className='editor-name text-left'>
																	{option.name}
																</span>
																<small className='text-secondary'>
																	{option.invited_email}
																</small>
															</div>
														</div>
													);
												else return <></>;
											})}
											<hr />
											<h3 className='text-left'>
												We'll notify them via email!
											</h3>
										</>
									) : (
										<>
											<h3 className='text-left mb-4 owner-overlay-header'>
												There are no editors in this project.
											</h3>
										</>
									)}
								</div>
							)}
						</Overlay>
					</>
				) : editor.is_owner && !myEditorProfile.is_owner ? (
					<span className='' style={{ backgroundColor: 'transparent' }}>
						Administrator
					</span>
				) : (
					<Select
						defaultValue={
							editor.is_read_only ? permissionOptions[1] : permissionOptions[0]
						}
						className='editor-select'
						onChange={handlePermissionChange}
						isDisabled={
							(myEditorProfile.user_id === editor.user_id &&
								!myEditorProfile.is_owner) ||
							myEditorProfile.is_read_only
						}
						options={permissionOptions}
						getOptionLabel={option => option.label}
						getOptionValue={option => option.value}
						dense
						isLoading={isUpdatingEditor}
					/>
				)}
				{!editor.is_owner && !myEditorProfile.is_read_only && (
					<IconButton
						icon='fas fa-trash'
						color={theme.error}
						onClick={handleDelete}
						isLoading={isDeletingEditor}
						tooltipText='Remove editor'
					/>
				)}
			</td>
		</tr>
	);
};

const permissionOptions = [
	{ label: 'Co-Admin', value: false },
	{ label: 'Read only', value: true },
];

export default EditorRow;
