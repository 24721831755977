import { AppDispatch, GetState } from '..';
import { getFileDownloadLink } from '../../api/services/filesService';
import {
	cycleLoopMode,
	getNextTrackIndex,
	getPreviousTrackIndex,
	getTracklistFromPlaylist,
	removeNonAudioFiles,
} from '../../helpers/audioTools';
import {
	SET_PLAYER_PLAYING,
	SET_PLAYER_CURRENT_TIME,
	SET_PLAYER_DURATION,
	SET_PLAYER_LOOP_MODE,
	TOGGLE_PLAYER_SHUFFLE,
	SET_PLAYER_PLAYLIST,
	SET_PLAYER_TRACKLIST,
	SET_PLAYER_CURRENT_TRACK_INDEX,
	SET_SHOW_PLAYER,
	TOGGLE_LOSSLESS,
	SET_PLAYER_TRACK_VERSION_NUMBER,
	SET_PLAYER_AUDIO_LOADED,
	SET_PLAYER_AUDIO_SRC,
	SET_SEEK_TIME_FUNCTION,
	RESET_PLAYER,
	SET_PLAYER_GROUP_INDEXES,
} from '../actionTypes';
import { showErrorAlert } from '../alertToast/actions';
import { fetchPlaylistAction } from '../playlists/actions';
import LoopMode from '../../constants/loopMode';
import {
	createParentGroupIndexes,
	getItemsInGroup,
} from '../../components/screens/Playlists/PlaylistDetails/PlaylistFileTable/utilities';

export const setPlayerPlayingAction = (playing: boolean) => ({
	type: SET_PLAYER_PLAYING,
	playing,
});

export const setPlayerCurrentTimeAction = (currentTime: number) => ({
	type: SET_PLAYER_CURRENT_TIME,
	currentTime,
});

export const setPlayerDurationAction = (duration: number) => ({
	type: SET_PLAYER_DURATION,
	duration,
});

export const togglePlayerLoopAction =
	() => (dispatch: AppDispatch, getState: GetState) =>
		dispatch(
			setPlayerLoopModeAction(cycleLoopMode(getState().player.loopMode))
		);

export const setPlayerLoopModeAction = (loopMode: LoopMode) => ({
	type: SET_PLAYER_LOOP_MODE,
	loopMode,
});

export const togglePlayerShuffleAction = () => ({
	type: TOGGLE_PLAYER_SHUFFLE,
});

// helper method for getting and/or creating the group indexes (if they're not updated)
const _getPlayerGroupIndexes = ({
	playlistId,
	getState,
	dispatch,
}: {
	playlistId: Playlist['id'];
	getState: GetState;
	dispatch: AppDispatch;
}) => {
	const playerState = getState().player;
	const playlist = getState().playlists.playlistsById?.[playlistId];

	if (!playlist?.playlist)
		throw new Error(`Could not find playlist with ID ${playlistId}`);

	const {
		playlistId: oldPlaylistId,
		fileIdToGroupIdIndex,
		groupIdToGroupContentIndex,
		groupIdToParentGroupIdIndex,
	} = playerState.groupIndexes;

	let indexes = {
		fileIdToGroupIdIndex,
		groupIdToGroupContentIndex,
		groupIdToParentGroupIdIndex,
	};

	if (
		!fileIdToGroupIdIndex ||
		!groupIdToGroupContentIndex ||
		!groupIdToParentGroupIdIndex ||
		oldPlaylistId !== playlistId
	) {
		indexes = createParentGroupIndexes({
			playlistFiles: playlist.playlist.files,
			playlistFolders: playlist.playlist.folders,
		});

		dispatch(
			setPlayerGroupIndexesAction({
				playlistId,
				fileIdToGroupIdIndex: indexes.fileIdToGroupIdIndex,
				groupIdToParentGroupIdIndex: indexes.groupIdToParentGroupIdIndex,
				groupIdToGroupContentIndex: indexes.groupIdToGroupContentIndex,
			})
		);
	}

	return indexes as {
		fileIdToGroupIdIndex: FileIdToGroupIdIndex;
		groupIdToGroupContentIndex: GroupIdToGroupContentIndex;
		groupIdToParentGroupIdIndex: GroupIdToParentGroupIdIndex;
	};
};

// TODO: add group content logic
export const computePlayerTracklistAction =
	({
		playlistId,
		fromTrackId,
		playlistFilesChanged = false,
		randomFirstTrack = false,
	}: {
		playlistId?: Playlist['id'];
		fromTrackId?: number;
		// used for edge cases where the playlist files have changed
		// such as deleting a track, adding a track, etc.
		playlistFilesChanged?: boolean;
		// used for when starts a playlist with shuffle enabled
		// and wants to start from a random track
		randomFirstTrack?: boolean;
	} = {}) =>
	(dispatch: AppDispatch, getState: GetState): any => {
		const playerState = getState().player;
		const {
			playlistId: oldPlaylistId,
			currentTrackIndex,
			shuffle,
			tracklist,
		} = playerState;

		const _playlistId = playlistId ?? oldPlaylistId;

		const playlist = getState().playlists.playlistsById?.[_playlistId!];

		if (!playlist?.playlist)
			throw new Error(`Could not find playlist with ID ${_playlistId}`);

		// WARNING: currentTrackIndex will be positive when switching playlists
		// And tracklist will exist too. This case needs to be handled by comparing
		// playlist IDs
		const isSwitchingPlaylists = _playlistId !== oldPlaylistId;

		const currentTrackId =
			fromTrackId ??
			(currentTrackIndex > -1 && !isSwitchingPlaylists
				? tracklist[currentTrackIndex]
				: null);

		const { fileIdToGroupIdIndex, groupIdToGroupContentIndex } =
			_getPlayerGroupIndexes({
				playlistId: _playlistId!,
				getState,
				dispatch,
			});

		const parentGroupId = !currentTrackId
			? null
			: fileIdToGroupIdIndex[currentTrackId];

		const { files: filesInGroup } = getItemsInGroup({
			sortedFolders: playlist.playlist.folders,
			playlistFiles: playlist.playlist.files,
			parentGroupId,
			fileIdToGroupIdIndex: fileIdToGroupIdIndex,
			groupIdToGroupContentIndex: groupIdToGroupContentIndex,
		});

		const groupAudioFiles = removeNonAudioFiles(filesInGroup);

		// patch for when the playlist files have changed
		if (playlistFilesChanged) {
			// if the playlist files have changed, we want to recompute the tracklist
			// depending on whether we're shuffling or not

			// WORST CASE SCENARIO (1): there are no audio files in the playlist
			// SOLUTION: reset the player state, closing the menu and stopping the audio

			// 2ND WORST CASE SCENARIO (2): the current track is no longer in the playlist
			// SOLUTION: recompute the tracklist and start playing from the first track

			// OTHER NON-CRITICAL SCENARIOS:
			// (3): one or more tracks (different than the current) have been deleted
			// (4): one or more tracks have been added
			// (5): one or more tracks have been moved
			//		SOLUTION to (3), (4), (5):
			//			we exit this block and let the tracklist be computed normally
			//			This means that if shuffle is enabled, the tracklist will be reshuffled
			//			and if not, the current track will remain the same, but change index
			//			to its new position in the tracklist

			// (1)
			if (groupAudioFiles.length === 0) {
				return dispatch(resetPlayerStateAction());
			}

			// (2)
			if (
				currentTrackIndex > -1 &&
				!groupAudioFiles.find(file => file.id === tracklist[currentTrackIndex])
			) {
				const firstFileId = groupAudioFiles[0].id;

				return dispatch(
					setPlayerPlaylistAction({
						playlistId: _playlistId!,
						fromTrackId: firstFileId,
					})
				);
			}

			// (3), (4), (5)
			// continue with the normal tracklist computation
		}

		// fromTrackId is used whenever you start a playlist from a specific track
		// it will not be used whenever the shuffle mode changes and there's already a playlist playing
		// if we're shuffling and there's already a current track (and tracklist), use that as the fromTrackId
		// otherwise, use the first track in the playlist

		const _fromTrackId =
			fromTrackId ??
			(currentTrackIndex > -1 && !isSwitchingPlaylists
				? tracklist[currentTrackIndex]
				: groupAudioFiles[0].id);

		const newTracklist = getTracklistFromPlaylist({
			playlist,
			isShuffle: shuffle,
			fromTrackId: _fromTrackId,
			randomFirstTrack,
			currentGroupId: parentGroupId,
			groupIdToGroupContentIndex,
			fileIdToGroupIdIndex,
		});

		if (shuffle) {
			dispatch(setPlayerCurrentTrackIndexAction(0));
		} else {
			const newCurrentTrackIndex = newTracklist.findIndex(
				trackId => trackId === _fromTrackId
			);
			dispatch(setPlayerCurrentTrackIndexAction(newCurrentTrackIndex));
		}

		return dispatch(setPlayerTracklistAction(newTracklist));
	};

export const setPlayerPlaylistAction =
	({
		playlistId,
		fromTrackId,
	}: {
		playlistId: Playlist['id'];
		fromTrackId?: number;
	}) =>
	async (dispatch: AppDispatch, getState: GetState) => {
		// Set the audio src to null as fast as possible to stop the audio element
		dispatch(setPlayerFileSrcAction(null));
		dispatch(setPlayerPlayingAction(true));

		// SET ALL PLAYER DATA RELATED TO PLAYLIST

		try {
			let playlist = getState().playlists.playlistsById?.[playlistId];

			if (!playlist?.playlist) {
				await dispatch(fetchPlaylistAction(playlistId));
				playlist = getState().playlists.playlistsById?.[playlistId];

				if (!playlist?.playlist) {
					throw new Error(`Could not find playlist with ID ${playlistId}`);
				}
			}

			const { fileIdToGroupIdIndex, groupIdToGroupContentIndex } =
				_getPlayerGroupIndexes({
					playlistId,
					getState,
					dispatch,
				});

			const parentGroupId = !fromTrackId
				? null
				: fileIdToGroupIdIndex![fromTrackId];

			const { files: filesInGroup } = getItemsInGroup({
				sortedFolders: playlist.playlist.folders,
				playlistFiles: playlist.playlist.files,
				parentGroupId,
				fileIdToGroupIdIndex: fileIdToGroupIdIndex!,
				groupIdToGroupContentIndex: groupIdToGroupContentIndex!,
			});

			const groupAudioFiles = removeNonAudioFiles(filesInGroup);

			if (groupAudioFiles.length === 0) {
				return;
			}

			const { shuffle } = getState().player;

			dispatch(
				computePlayerTracklistAction({
					playlistId,
					fromTrackId,
					randomFirstTrack: shuffle && !fromTrackId,
				})
			);

			dispatch({
				type: SET_PLAYER_PLAYLIST,
				playlistId,
			});

			const { currentTrackIndex, tracklist } = getState().player;

			if (currentTrackIndex === -1) {
				throw new Error(
					'Could not find current track index after computing tracklist'
				);
			}

			const newCurrentTrackId = tracklist[currentTrackIndex];

			const newCurrentTrack = groupAudioFiles.find(
				file => file.id === newCurrentTrackId
			);

			if (!newCurrentTrack)
				throw new Error(
					`Could not find track with ID ${newCurrentTrackId} in playlist with ID ${playlistId}`
				);

			dispatch(setPlayerTrackDataAction(newCurrentTrack));
		} catch (error) {
			// show error alert or modal
			dispatch(setPlayerPlayingAction(false));
			throw error;
		}
	};

export const setPlayerTrackDataAction =
	(
		file: FileMetadata,
		isReplay: boolean = false,
		versionId?: FileVersion['id']
	) =>
	async (dispatch: AppDispatch, getState: GetState) => {
		dispatch(setPlayerCurrentTimeAction(0)); // this only updates the UI

		// is replay is used for optimizing the audio src fetching
		// this means we don't need to fetch the audio src again
		// and instead we want to replay the current audio src
		if (isReplay) {
			const { seekTime } = getState().player;

			// this changes the currentTime of the audio element and the UI
			// needed for audio to actually replay
			if (seekTime) seekTime(0, true);

			return;
		}

		if (isReplay) return; // no need to fetch audio src again

		dispatch(
			setPlayerTrackVersionNumberAction(
				versionId ? file.versions[versionId].versionNo : file.activeVersionNo
			)
		);
		dispatch(setPlayerAudioLoadedAction(false));
		dispatch(setPlayerDurationAction(file.duration ?? 0));
		await dispatch(getPlayerFileSrcAction(file));
	};

export const setPlayerFileSrcAction = (src: string | null) => ({
	type: SET_PLAYER_AUDIO_SRC,
	audioSrc: src,
});

export const getPlayerFileSrcAction =
	(file: FileMetadata) => async (dispatch: AppDispatch, getState: GetState) => {
		const { lossless } = getState().player;
		const versionNumber =
			getState().player.currentTrackVersionNumber ?? file.activeVersionNo;
		const versionId = Object.values(file.versions).find(
			version => version.versionNo === versionNumber
		)?.id;

		if (!versionId) {
			throw new Error(
				`Could not find version with version number ${versionNumber} for file with ID ${file.id}`
			);
		}

		const versionFileDisplayId = file.versions[versionId].displayFileId;
		const hasCompressedVersion = Boolean(versionFileDisplayId);

		try {
			if (!versionId) {
				throw new Error(
					`Could not find version with version number ${versionNumber} for file with ID ${file.id}`
				);
			}

			// versionId and displayId are mutually exclusive
			const fileSrc = await getFileDownloadLink({
				fileId: file.id,
				versionId: lossless || !hasCompressedVersion ? versionId : null,
				displayId:
					!lossless && hasCompressedVersion ? versionFileDisplayId : null,
			});

			// check if the file ID is still the same after the request
			// if it's not, we don't want to set the audio src
			// this avoids race conditions where the user skips to the next track before the audio src is set
			const { currentTrackIndex, tracklist, currentTrackVersionNumber } =
				getState().player;
			const currentFileId = tracklist[currentTrackIndex];

			if (
				currentFileId !== file.id ||
				(currentTrackVersionNumber ?? file.activeVersionNo) !== versionNumber
			) {
				return;
			}

			return dispatch(setPlayerFileSrcAction(fileSrc.data.asset_link));
		} catch (error: any) {
			// show error alert or modal
			dispatch(
				showErrorAlert(
					error?.message ??
						'Whoops! Something went sideways while geting audio data'
				)
			);
			console.error(error);
		}
	};

export const setPlayerTracklistAction = (tracklist: FileMetadata['id'][]) => ({
	type: SET_PLAYER_TRACKLIST,
	tracklist,
});

export const skipPlayerTrackAction =
	({
		next = false,
		prev = false,
		forceSkip = false,
	}: {
		// for skipping to the next track
		next?: boolean;
		// for skipping to the previous track
		prev?: boolean;
		// set to true when user clicks the next or prev button
		// if forceSkip is true and loopMode is set to LOOP_TRACK, it is downgraded to LOOP_TRACKLIST
		forceSkip?: boolean;
	}) =>
	async (dispatch: AppDispatch, getState: GetState) => {
		// --- GUARD CLAUSES ---
		if (next && prev) {
			throw new Error(
				'Cannot skip to next and previous track at the same time'
			);
		}

		if (!next && !prev) {
			throw new Error('Must specify next or prev');
		}

		const playerState = getState().player;
		const { playlistId, currentTrackIndex, tracklist, loopMode } = playerState;

		if (!playlistId) return;

		const playlist = getState().playlists.playlistsById?.[playlistId];

		if (!playlist)
			throw new Error(`Could not find playlist with ID ${playlistId}`);
		// --- END GUARD CLAUSES ---

		const newLoopMode =
			forceSkip && loopMode === LoopMode.LOOP_TRACK
				? LoopMode.LOOP_TRACKLIST
				: loopMode;

		dispatch(setPlayerLoopModeAction(newLoopMode));

		const nextTrackIndex = next
			? getNextTrackIndex({
					loopMode: newLoopMode,
					tracklist,
					currentTrackIndex,
			  })
			: getPreviousTrackIndex(playerState);

		if (nextTrackIndex === -1) {
			// we've reached the end of the tracklist, we reset the player
			dispatch(resetPlayerStateAction());
			return;
		}

		// the only way the user has requested to replay the current track is if they're
		// skipping to the previous track. There's no way to replay if you're skipping to the next track
		// unless you're looping
		const isReplay =
			currentTrackIndex === nextTrackIndex &&
			(prev || loopMode !== LoopMode.DISABLED);

		if (!isReplay) {
			// we want to remove the current audioSrc as fast as possible if we need to refetch it
			// since this is what pauses the audio element
			dispatch(setPlayerFileSrcAction(null));
		}

		dispatch(setPlayerCurrentTrackIndexAction(nextTrackIndex));

		const nextTrackId = tracklist[nextTrackIndex];
		const nextTrack = playlist.playlist!.files.find(
			file => file.id === nextTrackId
		);

		if (!nextTrack)
			throw new Error(`Could not find track with ID ${nextTrackId}`);

		// Now that we've updated the UI, we can fetch the audio src
		await dispatch(setPlayerTrackDataAction(nextTrack, isReplay));

		dispatch(setPlayerPlayingAction(true));
	};

export const setPlayerCurrentTrackIndexAction = (
	currentTrackIndex: number
) => ({
	type: SET_PLAYER_CURRENT_TRACK_INDEX,
	currentTrackIndex,
});

export const setShowPlayerAction = (showPlayer: boolean) => ({
	type: SET_SHOW_PLAYER,
	showPlayer,
});

export const toggleLosslessAction =
	() => async (dispatch: AppDispatch, getState: GetState) => {
		dispatch({
			type: TOGGLE_LOSSLESS,
		});

		const { playlistId, currentTrackIndex, tracklist } = getState().player;

		// re-set player audio src
		const playlist = getState().playlists.playlistsById?.[playlistId!];

		if (!playlist)
			throw new Error(`Could not find playlist with ID ${playlistId}`);

		const file = playlist.playlist!.files.find(
			file => file.id === tracklist[currentTrackIndex]
		);

		if (!file)
			throw new Error(
				`Could not find file with ID ${tracklist[currentTrackIndex]}`
			);

		await dispatch(getPlayerFileSrcAction(file));
	};

export const setPlayerTrackVersionNumberAction = (versionNumber: number) => ({
	type: SET_PLAYER_TRACK_VERSION_NUMBER,
	versionNumber,
});

export const setPlayerAudioLoadedAction = (audioLoaded: boolean) => ({
	type: SET_PLAYER_AUDIO_LOADED,
	audioLoaded,
});

export const setSeekTimeFunctionAction = (
	seekTime: PlayerState['seekTime']
) => ({
	type: SET_SEEK_TIME_FUNCTION,
	seekTime,
});

export const setPlayerGroupIndexesAction = (params: {
	playlistId: Playlist['id'] | null;
	fileIdToGroupIdIndex: FileIdToGroupIdIndex | null;
	groupIdToParentGroupIdIndex: GroupIdToParentGroupIdIndex | null;
	groupIdToGroupContentIndex: GroupIdToGroupContentIndex | null;
}) => ({
	type: SET_PLAYER_GROUP_INDEXES,
	...params,
});

export const resetPlayerStateAction = () => ({
	type: RESET_PLAYER,
});
