import PROJECT_TYPES from '@/constants/projectTypes.json';
import Fuse from 'fuse.js';
import * as mm from 'music-metadata-browser';
import path from 'path-browserify';
import { aiDetectTitleAndArtist } from '../api/services/detectTitleAndArtistService';
import { FormikProps } from 'formik';
import {
	ProjectSelectOption,
	createProjectOption,
} from '../components/form/CreatableProjectSelect/CreatableProjectSelect';
import { SingleValue } from 'react-select';
import { v4 as uuid } from 'uuid';
import React, { useContext } from 'react';
import PlaylistFileUploadContext, {
	PlaylistFileUploadContextType,
} from '@/components/screens/Playlists/PlaylistDetails/UploadFilesToPlaylist/PlaylistFileUploadContext';

export enum AssociatedProjectStatus {
	DetectedExisting,
	DetectedNew,
	ManualNew,
	ManualExisting,
	None,
}

export const getImageSrcUrlFromMetadata = (metadata: mm.IAudioMetadata) => {
	const pictures = metadata.common.picture;

	if (!pictures) return null;

	const picture = pictures[0];

	return `data:${picture.format};base64,${picture.data.toString('base64')}`;
};

export type AudioMetadata = {
	picture: string | null;
	title: string | null;
	artist: string | null;
};

export type PlaylistUploadFileForm = {
	filename: string;
	displayName: string;
	isLinkedToProject: boolean;
	associatedProjectId: {
		recordingId: number | string | null;
		albumId: number | string | null;
	} | null;
	projectTitle: string;
	projectArtist: string;
	projectType: string;
	isAudioFile: boolean;
	useDifferentDisplayName: boolean;
	label: FileMetadata['label'];
	isDetecting: boolean;
	size: File['size'];
	metadata: AudioMetadata | null;
	associatedProjectStatus: AssociatedProjectStatus;
};

export type IncludeInAlbumForm = {
	isExistingAlbum: boolean;
	album: null | {
		albumId: number;
		recordingId: null;
	};
	albumTitle: string;
	albumArtist: string;
};

export type UploadFilesToPlaylistForm = {
	includeInAlbum: boolean;
	files: PlaylistUploadFileForm[];
} & IncludeInAlbumForm;

// ! Unfortunately I need to copy and paste a ton of code and variables from fileTools, since importing it means that
// ! the workers that import stuff from
// ! this file will end up a ton of code that's imported in fileTools and so, and it actually ends up leading to errors and crashes
// ! causing the worker to end unexpectedly when the bundle is fetched
// ! CAUTION: Now any change done to the supported file extensions list must be copied over here too

const DEFAULT_FILE_LABEL = 0;

const getFileExtension = (filename: string) =>
	path
		.extname(filename ?? '')
		.replace('.', '')
		.toLowerCase();

const AUDIO_FORMATS = [
	'mp3',
	'wav',
	'ogg',
	'flac',
	'aac',
	'm4a',
	'wma',
	'aiff',
];

const isAudioFile = (filename: string) =>
	AUDIO_FORMATS.includes(getFileExtension(filename));

export const extractAudioFileMetadata = async (file: File) => {
	const fullMetadata = await mm.parseBlob(file);
	return {
		picture: getImageSrcUrlFromMetadata(fullMetadata),
		title: fullMetadata.common.title ?? null,
		artist: fullMetadata.common.artist ?? null,
	};
};

export const createFileForm = async (
	file: File,
	detectByDefault: boolean,
	skipMetadataDetection: boolean = false
): Promise<PlaylistUploadFileForm> => ({
	filename: file.name,
	displayName: '',
	isLinkedToProject: false,
	associatedProjectId: null,
	projectTitle: '',
	projectArtist: '',
	projectType: PROJECT_TYPES.RECORDING,
	isAudioFile: isAudioFile(file.name),
	useDifferentDisplayName: false,
	label: DEFAULT_FILE_LABEL,
	isDetecting: detectByDefault && isAudioFile(file.name), // non-audio files do not undergo any detection process
	size: file.size,
	metadata:
		!skipMetadataDetection && isAudioFile(file.name)
			? await extractAudioFileMetadata(file)
			: null,
	associatedProjectStatus: AssociatedProjectStatus.None,
});

export const detectTitleAndArtistFromFileForm = async (
	fileForm: PlaylistUploadFileForm,
	{
		useFilenameAsTitle = false,
	}: {
		useFilenameAsTitle?: boolean;
	} = { useFilenameAsTitle: false }
) => {
	if (useFilenameAsTitle) {
		return {
			title: fileForm.filename,
			mainArtistName: '',
		};
	}

	// if the file contains audio metadata, see if there's any title and artist info
	const { title: metadataTitle, artist: metadataArtist } =
		fileForm.metadata ?? {};

	// if both fields are present, we can avoid using AI detection
	if (metadataTitle && metadataArtist) {
		return {
			title: metadataTitle,
			mainArtistName: metadataArtist,
		};
	}

	// if there's no title or artist in the metadata, we need to use AI detection
	const { title: aiTitle, mainArtistName: aiArtist } =
		await aiDetectTitleAndArtist(fileForm.filename);

	return {
		title: metadataTitle || aiTitle,
		mainArtistName: metadataArtist || aiArtist,
	};
};

export const handleChangeProjectOption = ({
	detected,
	index,
	setFieldValue,
	option,
}: {
	detected: boolean;
	index: number;
	setFieldValue: FormikProps<UploadFilesToPlaylistForm>['setFieldValue'];
	option: SingleValue<ProjectSelectOption>;
}) => {
	setFieldValue(`files[${index}].associatedProjectId`, option?.value ?? null);

	const isExistingProject =
		typeof (option?.value?.recordingId || option?.value?.albumId) === 'number';

	// if id is number, then it is an existing project
	// if id is string, then it is a new project
	setFieldValue(`files[${index}].isLinkedToProject`, isExistingProject);

	setFieldValue(`files[${index}].projectTitle`, option?.title ?? '');

	setFieldValue(`files[${index}].projectArtist`, option?.artist ?? '');

	let associatedStatus = AssociatedProjectStatus.None;

	if (!option) {
		associatedStatus = AssociatedProjectStatus.None;
	} else if (detected) {
		associatedStatus = isExistingProject
			? AssociatedProjectStatus.DetectedExisting
			: AssociatedProjectStatus.DetectedNew;
	} else {
		associatedStatus = isExistingProject
			? AssociatedProjectStatus.ManualExisting
			: AssociatedProjectStatus.ManualNew;
	}

	setFieldValue(`files[${index}].associatedProjectStatus`, associatedStatus);
};

export const handleCreateProjectOption = ({
	title,
	setFieldValue,
	detected,
	setNewProjects,
	index,
	artist = '',
}: {
	title: string;
	artist?: string;
	detected: boolean;
	setNewProjects: React.Dispatch<React.SetStateAction<ProjectSelectOption[]>>;
	index: number;
	setFieldValue: FormikProps<UploadFilesToPlaylistForm>['setFieldValue'];
}) => {
	const id = uuid();
	const newOption = createProjectOption({
		recordingId: id,
		albumId: null,
		title,
		artist,
		isAlbum: false,
	});

	setNewProjects(prev => [...prev, newOption]);

	setFieldValue(`files[${index}].associatedProjectId`, {
		recordingId: id,
		albumId: null,
	});

	setFieldValue(`files[${index}].isLinkedToProject`, false);

	setFieldValue(`files[${index}].projectTitle`, title);

	setFieldValue(`files[${index}].projectArtist`, artist);

	setFieldValue(
		`files[${index}].associatedProjectStatus`,

		detected
			? AssociatedProjectStatus.DetectedNew
			: AssociatedProjectStatus.ManualNew
	);
};

export type FileInfoDetection = {
	projectMatch: ProjectSelectOption | null;
	prediction: { mainArtistName: string | null; title: string };
};

export type SerializedFuseIndex = {
	keys: readonly string[];
	records: Fuse.FuseIndexRecords;
};

export const detectFileInfo = async ({
	itemFormValues,
	useFilenameAsTitle,
	userProjects,
	fuseIndex,
}: {
	itemFormValues: PlaylistUploadFileForm;
	useFilenameAsTitle: boolean;
	userProjects: ProjectSelectOption[];
	fuseIndex: SerializedFuseIndex;
}): Promise<FileInfoDetection> => {
	if (!itemFormValues.isAudioFile) {
		return {
			projectMatch: null,
			prediction: {
				title: '',
				mainArtistName: null,
			},
		};
	}

	console.debug(
		`[${itemFormValues.filename.toUpperCase()}]: begin detecting title and artist`
	);

	const prediction = await detectTitleAndArtistFromFileForm(itemFormValues, {
		useFilenameAsTitle,
	});
	console.debug(
		`[${itemFormValues.filename.toUpperCase()}]: title and artist detected`
	);

	const { mainArtistName, title } = prediction;

	if (!title) {
		return {
			projectMatch: null,
			prediction: {
				...prediction,
				title: itemFormValues.filename,
			},
		};
	}

	console.debug(
		`[${itemFormValues.filename.toUpperCase()}]: start fuse search`
	);

	const fuseThreshold = 0.2; // 0 means perfect match, 1 means any match

	// after detecting title and artist, perform fuzzy search to see if there is a match
	// in user's projects
	const fuse = new Fuse(
		userProjects,
		{
			keys: ['title'],
			threshold: fuseThreshold,
			findAllMatches: true,
		},
		Fuse.parseIndex(fuseIndex) // have to send it as plain object due to serialization issues in workers
	);

	const titleResult = fuse.search(title);
	let match = null;

	if (titleResult.length) {
		if (!mainArtistName) {
			match = titleResult[0].item;
		} else {
			const filteredProjects = titleResult.map(result => result.item);

			const artistFuse = new Fuse(filteredProjects, {
				keys: ['artist'],
				threshold: fuseThreshold,
			});

			const artistResult = artistFuse.search(mainArtistName);

			if (artistResult.length) {
				match = artistResult[0].item;
			}
		}
	}

	console.debug(
		`[${itemFormValues.filename.toUpperCase()}]: finished fuse search`
	);

	return {
		prediction,
		projectMatch: match,
	};
};
