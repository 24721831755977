import { persistReducer } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2';
import storage from 'redux-persist/lib/storage';
import {
	REQUEST_FAILURE,
	REQUEST_FORBIDDEN,
	REQUEST_LOADING,
	REQUEST_SUCCESS,
} from '../../constants/requestStatusTypes';
import {
	INVALID_TOKEN,
	LOGIN_FAILURE,
	LOGIN_INVALID,
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	LOGOUT,
	TOKEN_VALIDATION_ERROR,
	VALIDATING_TOKEN,
	VALID_TOKEN,
	SET_S3_FILE_UPLOAD_CREDENTIALS,
	CLEAR_S3_FILE_UPLOAD_CREDENTIALS,
	SET_USER_PERMISSIONS,
	FINISH_SIGNUP_PROCESS,
	START_SIGNUP_PROCESS,
	SET_SIGNUP_FORM_STEP,
	SET_EMAIL_VERIFIED,
	MAGIC_LINK_INVALID,
	MAGIC_LINK_VALID,
} from '../actionTypes';
import { AnyAction } from 'redux';
import { SignUpFormStep } from '../../components/screens/SignUp/SignUp';

interface AuthState {
	userEmail: string;
	userToken: string;
	userId: number | null;
	userProfileId: number | null;
	s3FileUploadCredentials: S3Credentials | null;
	loginRequestStatus: string;
	magicLinkStatus: string;
	tokenStatus: string;
	errorMessage: string;
	magicLinkErrorMessage: string;
	isSigningUp: boolean;
	userPermissions: UserPermissions | null;
	signUpFormStep: SignUpFormStep | null;
	isEmailVerified: boolean | null;
}

const initialState = {
	userEmail: '',
	userToken: '',
	userId: null,
	userProfileId: null,
	s3FileUploadCredentials: null,
	loginRequestStatus: '',
	magicLinkStatus: '',
	tokenStatus: '',
	errorMessage: '',
	magicLinkErrorMessage: '',
	isSigningUp: false,
	signUpFormStep: null,
	userPermissions: null,
	isEmailVerified: null,
} as AuthState;

const reducer = (state = initialState, action: AnyAction): AuthState => {
	switch (action.type) {
		case LOGOUT:
			return { ...initialState };
		case LOGIN_REQUEST:
			return {
				...state,
				userEmail: action.userEmail,
				loginRequestStatus: REQUEST_LOADING,
				errorMessage: '',
			};
		case LOGIN_SUCCESS:
			console.log(action);
			return {
				...state,
				userId: action.userId,
				userProfileId: action.profileId,
				userToken: action.userToken,
				isEmailVerified: action.isEmailVerified,
				tokenStatus: VALID_TOKEN,
				loginRequestStatus: REQUEST_SUCCESS,
				errorMessage: '',
			};
		case LOGIN_FAILURE:
			return {
				...state,
				loginRequestStatus: REQUEST_FAILURE,
				errorMessage: action.errorMessage,
			};
		case LOGIN_INVALID:
			return {
				...state,
				loginRequestStatus: REQUEST_FORBIDDEN,
				errorMessage: action.errorMessage,
			};
		case MAGIC_LINK_INVALID:
			return {
				...state,
				magicLinkStatus: MAGIC_LINK_INVALID,
				// loginRequestStatus: REQUEST_FORBIDDEN,
				magicLinkErrorMessage: action.errorMessage,
			};
		case MAGIC_LINK_VALID:
			return {
				...state,
				magicLinkStatus: MAGIC_LINK_VALID,
				magicLinkErrorMessage: '',
				// loginRequestStatus: REQUEST_FORBIDDEN,
				// errorMessage: action.errorMessage,
			};

		case VALIDATING_TOKEN:
			return {
				...state,
				tokenStatus: VALIDATING_TOKEN,
			};
		case VALID_TOKEN:
			return {
				...state,
				tokenStatus: VALID_TOKEN,
			};
		case INVALID_TOKEN:
			return {
				...initialState,
				tokenStatus: INVALID_TOKEN,
				errorMessage: action.errorMessage,
			};
		case TOKEN_VALIDATION_ERROR:
			return {
				...state,
				tokenStatus: TOKEN_VALIDATION_ERROR,
				errorMessage: action.errorMessage,
				userEmail: '',
				userToken: '',
				userId: null,
				userProfileId: null,
			};
		case SET_S3_FILE_UPLOAD_CREDENTIALS:
			return {
				...state,
				s3FileUploadCredentials: action.credentials,
			};
		case CLEAR_S3_FILE_UPLOAD_CREDENTIALS:
			return {
				...state,
				s3FileUploadCredentials: null,
			};
		case SET_USER_PERMISSIONS:
			return {
				...state,
				userPermissions: action.userPermissions,
			};
		case START_SIGNUP_PROCESS:
			return {
				...state,
				isSigningUp: true,
				signUpFormStep: action.step ?? SignUpFormStep.CHECK_EMAIL,
			};
		case SET_SIGNUP_FORM_STEP:
			return {
				...state,
				signUpFormStep: action.step,
			};
		case FINISH_SIGNUP_PROCESS:
			return {
				...state,
				isSigningUp: false,
				signUpFormStep: null,
			};
		case SET_EMAIL_VERIFIED:
			return {
				...state,
				isEmailVerified: action.verified,
			};
		default:
			return state;
	}
};

const persistConfig = {
	key: 'auth',
	storage,
	stateReconciler: autoMergeLevel2,
	blacklist: [
		'loginRequestStatus',
		'tokenStatus',
		'errorMessage',
		'userPermissions',
		'isSigningUp',
	],
};

export default persistReducer<AuthState, AnyAction>(persistConfig, reducer);
