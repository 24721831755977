import React from 'react';
import './PlaylistImage.scss';
import Image from '../../../../../layout/Image/Image';
import clsx from 'clsx';

export type PlaylistImageProps = {
	src?: string | null;
	height?: string;
	width?: string;
	fontSize?: string;
	onClickToUpload?: () => void;
	onHoverContent?: React.ReactNode;
	forceLoadingPlaceholder?: boolean;
	className?: string;
};

const PlaylistImage = ({
	src,
	height = '18vh',
	width = '18vh',
	fontSize = '4rem',
	className = '',
	onClickToUpload,
	onHoverContent = null,
	forceLoadingPlaceholder = false,
}: PlaylistImageProps) => {
	const [isHovered, setIsHovered] = React.useState(false);

	const style = className ? {} : { height, width };

	return (
		<div
			className={clsx('playlist-image__container', className)}
			style={style}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			{isHovered && onClickToUpload && (
				<div
					className='playlist-image__upload-overlay'
					onClick={onClickToUpload}
				>
					{onHoverContent || (
						<>
							<i className='fas fa-upload mr-2' />
							UPLOAD
						</>
					)}
				</div>
			)}
			{src || forceLoadingPlaceholder ? (
				<Image
					className='h-100 w-100'
					style={{ objectFit: 'cover' }}
					src={src || undefined}
					alt='playlist cover'
					draggable={false}
					forcePlaceholder={forceLoadingPlaceholder}
				/>
			) : (
				<FallbackPlaylistCoverImage fontSize={fontSize} />
			)}
		</div>
	);
};

export const FallbackPlaylistCoverImage = ({ fontSize = '4rem' }) => {
	return (
		<div
			className='d-flex justify-content-center align-items-center fallback-playlist-image h-100 w-100'
			style={{
				backgroundColor: '#DCD1FF',
			}}
		>
			<i
				className='fas fa-music'
				style={{
					fontSize,
					color: '#997DE9',
				}}
			/>
		</div>
	);
};

export default PlaylistImage;
