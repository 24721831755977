import React, { useCallback, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import {
	EDIT_PLAYLIST_DETAILS_MODAL,
	SEND_PLAYLIST_LINK_MODAL,
	SHARE_PLAYLIST_MODAL,
} from '@/constants/modalTypes';
import { showModalAction } from '@/store/modal/actions';
import { getCurrentPlaylist } from '@/store/playlists/selectors';
import PillButton from '@/components/layout/PillButton';
import PlaylistImage from './PlaylistImage';
import theme from '@/theme.module.scss';
import './PlaylistDetailsHeader.scss';
import {
	computeLocalPlaylistAccessControl,
	getPlaylistShareLink,
	showOnboardingPrivatePlaylistModal,
	tryShareAction,
} from '@/helpers/playlistTools';
import PlaylistAccessControlType from '@/constants/playlistAccessControl';
import { useAppSelector } from '@/store/hooks';
import { isPlaylistJuly2023PromoActive } from '@/helpers/promoTools';
import IconButton from '../../../../layout/IconButton';
import { reportPlaylistJuly2023PromoPassAction } from '../../../../../store/promos/actions';
import { setOnboardingClickedSendPlaylistLinkAction } from '../../../../../store/user/actions';

const PlaylistDetailsHeader = () => {
	const currentPlaylist = useAppSelector(getCurrentPlaylist);
	const { currentPlaylistId } = useAppSelector(state => state.playlists);
	const { clickedSendPlaylistLink } = useAppSelector(
		state => state.user.onboarding
	);
	const { userId } = useAppSelector(state => state.auth);
	const { activePromos, playlistJuly2023PromoTimerExpiration } = useAppSelector(
		state => state.promos
	);
	const dispatch = useDispatch();

	const [copyTooltipText, setCopyTooltipText] =
		React.useState('Copy Playlist Link');

	const isOwner = useMemo(
		() => currentPlaylist?.playlist?.userId === userId,
		[currentPlaylist, userId]
	);

	const isOnboardingActive = useMemo(
		() =>
			Boolean(
				isOwner &&
					((currentPlaylist?.playlist?.files?.length &&
						currentPlaylist?.playlist.files.length > 0) ||
						(currentPlaylist?.uploads?.length &&
							currentPlaylist.uploads.length > 0)) &&
					isPlaylistJuly2023PromoActive(
						activePromos,
						playlistJuly2023PromoTimerExpiration
					)
			),
		[
			activePromos,
			playlistJuly2023PromoTimerExpiration,
			currentPlaylist,
			isOwner,
		]
	);
	const showFlashySendButton = useMemo(
		() => isOnboardingActive && !clickedSendPlaylistLink,
		[isOnboardingActive, clickedSendPlaylistLink]
	);

	const showFlashyLinkButton = useMemo(
		() => isOnboardingActive && clickedSendPlaylistLink,
		[isOnboardingActive, clickedSendPlaylistLink]
	);

	const playlistShareLink = useMemo(
		() => (currentPlaylist ? getPlaylistShareLink(currentPlaylist) : ''),
		[currentPlaylist]
	);

	const generateDisplayLink = useCallback(
		(fullLink: string) => fullLink?.replace('https://', ''),
		[]
	);

	const handleShare = useCallback(() => {
		dispatch(
			showModalAction(SHARE_PLAYLIST_MODAL, {
				size: 'md',
				playlistId: currentPlaylistId,
			})
		);
	}, [dispatch, currentPlaylistId]);

	const handleEditDetails = useCallback(() => {
		dispatch(
			showModalAction(EDIT_PLAYLIST_DETAILS_MODAL, {
				size: 'lg',
				playlistId: currentPlaylistId,
			})
		);
	}, [dispatch, currentPlaylistId]);

	const accessControlType = useMemo(
		() => computeLocalPlaylistAccessControl(currentPlaylist?.playlist),
		[currentPlaylist]
	);

	const renderAccessControl = useCallback(() => {
		switch (accessControlType) {
			case PlaylistAccessControlType.PASSWORD:
				return (
					<>
						<i className='fas fa-lock playlist-details-icon' />
						<p onClick={handleShare}>Password Locked Playlist</p>
					</>
				);
			case PlaylistAccessControlType.PUBLIC:
				return (
					<>
						<i className='fas fa-globe playlist-details-icon' />
						<p onClick={handleShare}>Public Playlist</p>
					</>
				);
			case PlaylistAccessControlType.PRIVATE:
				return (
					<>
						<i className='fas fa-eye-slash playlist-details-icon' />
						<p onClick={handleShare}>Hidden for Everyone</p>
					</>
				);
			default:
				return null;
		}
	}, [handleShare, accessControlType]);

	return (
		<Row className='w-100'>
			<Col sm={12} className='d-flex'>
				<div className='mr-3'>
					<PlaylistImage
						className='playlist-details-image'
						src={currentPlaylist?.playlist?.coverUrl}
						onHoverContent={
							<>
								<i className='fas fa-pen mr-2' />
								EDIT
							</>
						}
						onClickToUpload={handleEditDetails}
					/>
				</div>

				<div className='d-flex justify-content-end flex-column'>
					<div className='playlist-details-security-container'>
						{renderAccessControl()}
					</div>

					<h4 className='playlist-details-title' onClick={handleEditDetails}>
						{currentPlaylist?.playlist?.name}
					</h4>

					{currentPlaylist?.playlist?.artist ? (
						<div
							className='playlist-details-artist mb-2'
							onClick={handleEditDetails}
						>
							{currentPlaylist?.playlist?.artist}
						</div>
					) : null}

					{currentPlaylist?.playlist?.description ? (
						<div className='playlist-details-description'>
							{currentPlaylist?.playlist?.description}
						</div>
					) : null}

					<div className='d-flex align-items-center mt-1'>
						<div className='playlist-details-link-container'>
							<i className='fas fa-link playlist-details-icon' />
							<PillButton
								label={generateDisplayLink(playlistShareLink)}
								rightIcon='fas fa-external-link-alt'
								theme='solid-light'
								className={showFlashyLinkButton ? 'playlist-flashy-button' : ''}
								tooltipText={
									showFlashyLinkButton ? (
										<span>
											🙌 Only one step to go! <br /> Open the playlist in your
											browser
										</span>
									) : (
										'Open Playlist Link'
									)
								}
								onClick={() => {
									// only show this modal if playlist is hidden in some way and in the onboarding flow (i.e. showFlashyLinkButton is true)
									if (
										[
											PlaylistAccessControlType.PRIVATE,
											PlaylistAccessControlType.PASSWORD,
										].includes(accessControlType) &&
										showFlashyLinkButton
									) {
										showOnboardingPrivatePlaylistModal({
											dispatch,
											playlist: currentPlaylist!,
										});
										return;
									}

									tryShareAction({
										action: () => {
											dispatch(
												reportPlaylistJuly2023PromoPassAction(
													currentPlaylistId!
												)
											);
											window.open(
												getPlaylistShareLink(currentPlaylist!),
												'_blank'
											);
										},
										playlist: currentPlaylist!,
										dispatch,
									});
								}}
							/>
							<IconButton
								icon='fas fa-copy'
								tooltipText={copyTooltipText}
								onClick={() => {
									tryShareAction({
										action: () => {
											navigator.clipboard.writeText(playlistShareLink);

											setCopyTooltipText('Copied!');
											setTimeout(() => {
												setCopyTooltipText('Copy Playlist Link');
											}, 2000);
										},
										playlist: currentPlaylist!,
										dispatch,
									});
								}}
								color={theme.primary}
							/>
							<IconButton
								icon='fas fa-paper-plane'
								tooltipText='Send Playlist Link'
								className={showFlashySendButton ? 'playlist-flashy-button' : ''}
								color={showFlashySendButton ? 'white' : theme.primary}
								onClick={() => {
									if (isOnboardingActive && !clickedSendPlaylistLink) {
										dispatch(setOnboardingClickedSendPlaylistLinkAction());
									}

									dispatch(
										showModalAction(SEND_PLAYLIST_LINK_MODAL, {
											size: 'md',
											isOnboarding: isOnboardingActive,
											playlistId: currentPlaylistId,
										})
									);
								}}
							/>
						</div>
					</div>
				</div>
			</Col>
		</Row>
	);
};

export default PlaylistDetailsHeader;
