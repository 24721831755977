import Chip from '../Chip';
import ESignatureSigneeStatus from '../../../constants/esignatureSigneeStatus';
import { ChipProps } from '../Chip/Chip';
import { useAppDispatch } from '../../../store/hooks';
import { showModalAction } from '../../../store/modal/actions';
import { EXPORT_ESIGNATURE_MODAL } from '../../../constants/modalTypes';

type ExportESignatureChipProps = {
	exportData: ExportMetadata;
} & Partial<ChipProps>;

const ExportESignatureChip = ({
	exportData,
	...props
}: ExportESignatureChipProps) => {
	const dispatch = useAppDispatch();

	const esignature = exportData.esignature!;

	const amountSigned = Object.values(esignature.signees).filter(
		signee => signee.status === ESignatureSigneeStatus.SIGNED
	).length;

	const chipLabel =
		Object.values(esignature.signees).length === 0
			? 'AWAITING SIGNATURES'
			: `${amountSigned}/${Object.values(esignature.signees).length} SIGNED`;

	const handleClick = () => {
		dispatch(
			showModalAction(EXPORT_ESIGNATURE_MODAL, {
				size: 'md',
				exportId: exportData.id,
			})
		);
	};

	return (
		<Chip
			{...props}
			style={{
				fontWeight: 600,
				padding: '0.1rem 0.5rem',
				fontSize: '0.725rem',
				...(props.style ?? {}),
			}}
			className={`${props.className ?? ''}`}
			leftIcon='fas fa-signature'
			label={chipLabel}
			onClick={handleClick}
		/>
	);
};

export default ExportESignatureChip;
