import React, { useEffect, useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
import './FileUploadMenu.scss';
import FileUploadItem from './FileUploadItem';
import { uploadFilesToBucketWithCredentialsAction } from '../../../store/files/actions';
import UPLOAD_STATUS from '../../../constants/uploadStatus.json';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
// const uploadsQueueById = {
// 	'5f6f8f8f-f8f8-f8f8-f8f8-f8f8f8f8f8f8': {
// 		id: '5f6f8f8f-f8f8-f8f8-f8f8-f8f8f8f8f8f8',
// 		status: UPLOAD_STATUS.IN_PROGRESS,
// 		errorMessage: 'An error occurred',
// 		progress: 40,
// 		metadata: {
// 			recordingId: 1656,
// 			filename: 'NAME.wav',
// 		},
// 	},
// 	'5f6f8f8f-f8f8-f8f8-f8f8-f8f8f8f8f8f1': {
// 		id: '5f6f8f8f-f8f8-f8f8-f8f8-f8f8f8f8f8f1',
// 		status: UPLOAD_STATUS.ERROR,
// 		errorMessage: 'An error occurred',
// 		progress: 40,
// 		metadata: {
// 			recordingId: 1656,
// 			filename: 'NAME.wav',
// 		},
// 	},
// };

export type FileUploadMenuProps = {
	show: boolean;
	toggleShow: () => void;
};

const FileUploadMenu = ({ show, toggleShow }: FileUploadMenuProps) => {
	const dispatch = useAppDispatch();
	const { uploadsQueueById, uploadFilesFolders } = useAppSelector(
		state => state.files
	);

	const uploads = useMemo(
		() => Object.values(uploadsQueueById),
		[uploadsQueueById]
	);

	// Detect when new files are added to the queue and start uploading them
	useEffect(() => {
		console.log('UPLOADS', uploads);

		const filesReadyToUpload = uploads.filter(
			upload => upload.status === UPLOAD_STATUS.READY_TO_UPLOAD
		);

		const filesInProgress = uploads.filter(
			upload => upload.status === UPLOAD_STATUS.IN_PROGRESS
		);

		if (filesReadyToUpload.length > 0 && filesInProgress.length === 0) {
			// only upload one file at a time (sequential uploads)
			const firstFile = filesReadyToUpload[0];
			dispatch(
				uploadFilesToBucketWithCredentialsAction(
					[firstFile],
					uploadFilesFolders
				)
			);
		}
	}, [uploads, dispatch]);

	if (uploads.length > 0)
		return (
			<Dropdown
				className='file-upload-menu-container'
				onToggle={toggleShow}
				show={show}
			>
				<Dropdown.Menu
					align='end'
					style={{ marginRight: '20vh' }}
					className='p-0'
				>
					<div className='file-upload-menu-list'>
						{uploads.map((upload, i) => (
							<div key={i}>
								<FileUploadItem file={upload} />
								{i !== uploads.length - 1 && <hr className='m-0' />}
							</div>
						))}
					</div>
				</Dropdown.Menu>
			</Dropdown>
		);

	return (
		<Dropdown
			className='file-upload-menu-container'
			onToggle={toggleShow}
			show={show}
		>
			<Dropdown.Menu align='end'>
				<div className='text-secondary text-bold no-uploads-container px-3'>
					You don't have any files currently uploading
				</div>
			</Dropdown.Menu>
		</Dropdown>
	);
};

export default FileUploadMenu;
