import React, { useCallback } from 'react';
import InputFileItem from './InputFileItem';
import '../UploadFilesModal.scss';

const InputFileList = ({
	inputFiles,
	setInputFiles,
	className = '',
	hideLabel = false,
	...props
}) => {
	const handleDelete = useCallback(
		index => {
			setInputFiles(prevFiles => {
				const a = [...prevFiles];
				a.splice(index, 1);

				return a;
			});
		},
		[setInputFiles]
	);

	const handleSetFileLabel = fileIndex => label => {
		setInputFiles(prevFiles => {
			console.log('label', label);
			const newInputFiles = [...prevFiles];
			newInputFiles[fileIndex].label = label;

			return newInputFiles;
		});
	};

	return (
		<div className={`w-100 input-file-list ${className}`} {...props}>
			{inputFiles?.length > 0 && (
				<div>
					{inputFiles.map((file, index) => (
						<div key={index}>
							<InputFileItem
								inputFile={file}
								onDelete={() => handleDelete(index)}
								fileLabel={file.label}
								setFileLabel={handleSetFileLabel(index)}
								hideLabel={hideLabel}
							/>
							{index !== inputFiles.length - 1 && <hr className='m-0' />}
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export default InputFileList;
