import axios from 'axios';
import { store } from '../store';

export const codesApi = axios.create({
	baseURL:
		process.env.NODE_ENV === 'production'
			? process.env.REACT_APP_CODES_API
			: '/code-generator',
});

codesApi.interceptors.request.use(config => {
	const { userId, userToken } = store.getState().auth;

	if (userId && userToken && config.headers) {
		config.headers['publisher-user-id'] = userId;
		config.headers['publisher-user-token'] = userToken;
	}

	return config;
});
