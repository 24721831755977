import React, { useEffect, useState } from 'react';
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import TextField from '../../../form/TextField';
import * as yup from 'yup';
import {
	hideModal,
	setModalTitle,
	showModalAction,
} from '../../../../store/modal/actions';
import Button from '../../../layout/Button';
import { FormikProvider, useFormik } from 'formik';
import { createCloudRecordingAction } from '../../../../store/projects/actions';
import recordingSchema from '../../../../constants/recording.json';
import {
	IMPORT_RIN_MODAL,
	PROJECT_LIMIT_MODAL,
} from '../../../../constants/modalTypes';
import { hasReachedProjectLimit } from '../../../../helpers/tiersTools';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

const schema = yup.object().shape({
	title: yup.string().trim().required('Song Name is required'),
	mainArtist: yup.string().trim().required('Main Artist is required'),
});

export type NewRecordingModalProps = {
	onCreate?: (id: Recording['id']) => void;
	albumId?: number | null;
	clearCurrentAlbumIdAfterImport?: boolean;
};

const NewRecordingModal = ({
	onCreate,
	albumId = null,
	clearCurrentAlbumIdAfterImport = false,
}: NewRecordingModalProps) => {
	const dispatch = useAppDispatch();
	const { projectUsage } = useAppSelector(state => state.projects);

	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		dispatch(setModalTitle('New Recording'));
	}, [dispatch]);

	const closeModalHandler = () => dispatch(hideModal());

	const recordingSubmitHandler = (values: RecordingContent) => {
		return Promise.resolve(
			dispatch(createCloudRecordingAction(values, onCreate, albumId))
		);
	};

	const formik = useFormik<RecordingContent>({
		initialValues: {
			...recordingSchema,
		},
		validationSchema: schema,
		enableReinitialize: true,
		onSubmit: async values => {
			setIsLoading(true);

			await recordingSubmitHandler(values);

			setIsLoading(false);

			closeModalHandler();
		},
	});

	const handleImport = () => {
		if (hasReachedProjectLimit(projectUsage!)) {
			dispatch(showModalAction(PROJECT_LIMIT_MODAL, { size: 'md' }));
			return;
		}

		dispatch(
			showModalAction(IMPORT_RIN_MODAL, {
				size: 'md',
				albumId,
				clearCurrentAlbumIdAfterImport,
			})
		);
	};

	useEffect(() => {
		if (hasReachedProjectLimit(projectUsage!)) {
			Promise.resolve(dispatch(hideModal())).then(() =>
				dispatch(showModalAction(PROJECT_LIMIT_MODAL, { size: 'md' }))
			);
		}
	}, [projectUsage, dispatch]);

	return (
		<>
			<Modal.Body>
				<Container className='px-4'>
					<FormikProvider value={formik}>
						<Form>
							<Row>
								<Col xs={12}>
									<TextField
										name='title'
										label='Song Name'
										value={formik.values.title}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										isInvalid={Boolean(
											formik.touched.title && formik.errors.title
										)}
										errorMessage={formik.errors.title}
									/>
								</Col>

								<Col xs={12}>
									<TextField
										label='Main Artist Name'
										name='mainArtist'
										value={formik.values.mainArtist}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										isInvalid={Boolean(
											formik.touched.mainArtist && formik.errors.mainArtist
										)}
										errorMessage={formik.errors.mainArtist}
									/>
								</Col>
							</Row>
						</Form>
					</FormikProvider>
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Row className='w-100'>
					<Col className='d-flex justify-content-between w-100'>
						<Button
							label='Import from RIN'
							theme='dark'
							onClick={handleImport}
							className='mr-2'
						/>

						<div>
							<Button label='Cancel' onClick={closeModalHandler} />
							<Button
								label='Create'
								theme='dark'
								isLoading={isLoading}
								onClick={async () => await formik.handleSubmit()}
								className='ml-2'
							/>
						</div>
					</Col>
				</Row>
			</Modal.Footer>
		</>
	);
};

export default NewRecordingModal;
