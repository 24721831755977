import { invalidTokenAction } from '../store/auth/actions'
import { store } from '../store'

export const checkInvalidToken = (err: any) => {
  console.error(err)
  if (err?.response?.status === 401) {
    // INVALID TOKEN
    store.dispatch(invalidTokenAction())

    return true
  }

  return false
}