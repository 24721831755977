enum IsniRoles {
	ARRANGER = 'Arranger',
	CONDUCTOR = 'Conductor',
	CONTRIBUTOR = 'Contributor',
	COMPOSER = 'Composer',
	CREATOR = 'Creator',
	DIRECTOR = 'Director',
	GRAPHIC_DESIGN = 'Graphic Design',
	PERFORMER = 'Performer',
	PRODUCER = 'Producer',
	PUBLISHER = 'Publisher',
	LINER_NOTES = 'Liner Notes',
	LYRICIST = 'Lyricist',
	MUSICIAN = 'Musician',
	NARRATOR = 'Narrator',
	SINGER = 'Singer',
	SOUND_ENGINEER = 'Sound Engineer',
	SPEAKER = 'Speaker',
	STORY_TELLER = 'Story Teller',
	VOCALIST = 'Vocalist',
}

export default IsniRoles;
