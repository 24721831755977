import React, { useCallback, useMemo } from 'react';
import '../ProjectsTable.scss';

const ReorderTrackArrows = ({
	onUpClick,
	onDownClick,
	isFirst,
	isLast,
	index,
	row,
}) => {
	const isLoading = useMemo(() => !(row && row.original.createdAt), [row]);

	const handleUpClick = useCallback(
		() => (isFirst || isLoading ? {} : onUpClick(row, index)),
		[isFirst, onUpClick, row, index, isLoading]
	);
	const handleDownClick = useCallback(
		() => (isLast || isLoading ? {} : onDownClick(row, index)),
		[isLast, onDownClick, row, index, isLoading]
	);

	return (
		<div className='d-flex flex-column '>
			<i
				className={`fas fa-caret-up ${
					isFirst || isLoading
						? 'track-number-arrow-disabled'
						: 'track-number-arrow'
				}`}
				onClick={handleUpClick}
			></i>
			<i
				className={`fas fa-caret-down ${
					isLast || isLoading
						? 'track-number-arrow-disabled'
						: 'track-number-arrow'
				}`}
				onClick={handleDownClick}
			></i>
		</div>
	);
};

export default ReorderTrackArrows;
