import React, { useEffect } from 'react';
import isniStrings, { IsniString } from '../isniStrings';
import IsniService from '../../../../api/services/isniService';
import { ProgressSpinner } from 'primereact/progressspinner';
import Button from '../../../layout/Button';
import { Message } from 'primereact/message';
import { hideModal } from '../../../../store/modal/actions';
import { useAppDispatch } from '../../../../store/hooks';

type RequestIsniStepProps = {
	onSubmit: () => Promise<void>;
	getIsniStringFn: (isniString: IsniString) => string;
	generateIsni: () => Promise<void>;
	isUserClaimingIsni: boolean;
};

const RequestIsniStep = ({
	onSubmit,
	getIsniStringFn,
	generateIsni,
	isUserClaimingIsni,
}: RequestIsniStepProps) => {
	const dispatch = useAppDispatch();

	const [loading, setLoading] = React.useState(false);
	const [generated, setGenerated] = React.useState(false);
	const [error, setError] = React.useState<boolean>(false);
	const [isTimeoutError, setIsTimeoutError] = React.useState(false);
	const [reported, setReported] = React.useState(false);

	const handleReport = async () => {
		if (reported) return;

		setLoading(true);
		try {
			await IsniService.reportError();
			setReported(true);
		} catch (e) {
			console.error(e);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!loading && !generated && !error) {
			setLoading(true);

			generateIsni()
				.then(async () => {
					setGenerated(true);

					await onSubmit();
				})
				.catch((e: any) => {
					console.error(e);
					setError(true);

					if (e.response?.status === 404) {
						// OCLC timeout error, show generic error message
						setIsTimeoutError(true);
						return;
					}

					// will be handled manually by sound credit, must be reported
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [loading, generated, onSubmit, generateIsni, error]);

	if (error && isTimeoutError) {
		return (
			<div className='d-flex align-items-center flex-column justify-content-center'>
				<Message
					severity='error'
					pt={{
						text: {
							style: {
								width: '90%',
								marginLeft: '1rem',
							},
						},
					}}
					text={
						<>
							<strong>
								{getIsniStringFn(isniStrings.RequestIsni.timeoutError.title)}
							</strong>
							<div className='my-3' />

							{getIsniStringFn(
								isniStrings.RequestIsni.timeoutError.description1
							)}
						</>
					}
				/>
				{isUserClaimingIsni && (
					<div
						style={{
							textAlign: 'center',
						}}
					>
						<div className='my-3' />
						{getIsniStringFn(isniStrings.RequestIsni.timeoutError.description2)}
					</div>
				)}

				<Button
					label='Dismiss'
					onClick={() => dispatch(hideModal())}
					className='mt-4'
					theme='dark'
				/>
			</div>
		);
	}

	if (error) {
		return (
			<div className='d-flex align-items-center flex-column justify-content-center'>
				<Message
					severity='error'
					pt={{
						text: {
							style: {
								width: '90%',
								marginLeft: '1rem',
							},
						},
					}}
					text={
						<>
							<strong>
								{getIsniStringFn(isniStrings.RequestIsni.otherError.title)}
							</strong>

							<div className='my-3' />

							{getIsniStringFn(isniStrings.RequestIsni.otherError.description1)}
						</>
					}
				/>

				{isUserClaimingIsni && (
					<>
						<div className='my-3' />
						<span
							style={{
								textAlign: 'center',
							}}
						>
							<a
								href='https://isni.org/page/search-database/'
								target='_blank'
								rel='noreferrer'
							>
								Click here
							</a>{' '}
							to check the ISNI database for an existing ISNI, or click the
							button below to send your form data to Sound Credit to manually
							complete your request.
						</span>

						<div className='my-3' />
						<Button
							label={
								reported ? 'Requested!' : 'Request free manual ISNI review'
							}
							isDisabled={reported}
							onClick={handleReport}
							isLoading={loading}
							theme='dark'
						/>
					</>
				)}

				<Button
					label='Dismiss'
					onClick={() => dispatch(hideModal())}
					className='mt-3'
				/>
			</div>
		);
	}

	return (
		<>
			<h3>{getIsniStringFn(isniStrings.RequestIsni.processing.title)}</h3>

			<h4>{getIsniStringFn(isniStrings.RequestIsni.processing.description)}</h4>

			<div className='w-100 d-flex align-items-center justify-content-center'>
				<ProgressSpinner />
			</div>
		</>
	);
};

export default RequestIsniStep;
