import isniApi from '../isniApi';

/**
 * Searches the user's name on the ISNI database to see if they may already have one.
 * The response should be formatted and shown to the user, so that they can identify if they already have an ISNI.
 * This function is part of the 5a. ISNI registration - Name Search process.
 *
 * @function nameSearch
 *
 * @example
 * ```
 * const response = await nameSearch("John", "Smith");
 * console.log(response.data.names[0].isniCode);  // Outputs: "000011112222333X"
 * ```
 */
const nameSearch = (
	forename: string, // The applicant's forename
	surname: string // The applicant's surname
) =>
	isniApi.post<{
		names: IsniNameSearchResult[];
	}>('/name-search', { forename, surname });

/**
 * Asynchronously generates and returns a new ISNI (International Standard Name Identifier)
 * for a registrant, using the supplied registration data.
 * This is part of the 5b-3 ISNI Registration process.
 *
 * @function generateIsni
 *
 * @example
 * ```
 * const result = await generateIsni({
 *    forename: "John",
 *    surname: "Doe",
 *    birthdate: "1990-05-30",
 *    artistName: "John Doe Band",
 *    role: "Sound Engineer",
 *    websiteAddress: "https://johndoe.com",
 *    websiteTitle: "John Doe's Website",
 *    relatedForename: "Jane",
 *    relatedSurname: "Doe",
 *    relatedOrganizationName: null,
 *    isnotIsniList: ["123412341234123X"]
 * });
 * console.log(result); // Outputs: { "isni": "000011112222333X" }
 * ```
 *
 * Note: Handle errors appropriately as per the specification.
 * 403 errors inform the user about a conflict that will be resolved by a Sound Credit member
 * and they will be emailed later about their ISNI. A 404 error should retry the request as
 * OCLCs servers sometimes go offline for a minute or two. We generally allow up to 4 minutes of retries.
 */
const generateToken = ({
	forename, // The registrant's forename.
	surname, // The registrant's surname.
	birthdate, // The registrant's birthdate in yyyy-mm-dd format.
	artistName, // The artist of the prior work the registrant performed on.
	role, // The role the registrant performed on the prior work. Must be a valid ISNI role.
	websiteAddress, // The URL of a website which confirms the registrant's identity. Can be any online source.
	websiteTitle, // The name/title of the website source.
	relatedForename, // The forename of a professional the registrant has worked with. Must not be null if relatedSurname is supplied.
	relatedSurname, // The surname of a professional the registrant has worked with. Must not be null if relatedForename is supplied.
	relatedOrganizationName, // The name of an organization the registrant has worked with. Must be null if relatedForename and relatedSurname are supplied.
	isnotIsniList, // An array of strings, representing every ISNI found in the name search.
	personOrOrganization, // Denotes the registrant type.  Must be either `"person"` or `"organization"`.
	requestingIsniForSelf, //  Should be true if requested for the user's account and false if requested from credits.  Must be false if `personOrOrganization` is `"organization"`.
	mainName, // The registrant's organization name.  Must be null if `forename` and `surname` are not null.
	title, // Prior Work Title
}: {
	forename: string; // aaaaa
	surname: string;
	birthdate: string;
	artistName: string;
	title: string;
	role: IsniRole;
	websiteAddress: string;
	websiteTitle: string;
	relatedForename: string | null;
	relatedSurname: string | null;
	relatedOrganizationName: string | null;
	isnotIsniList: string[];
	personOrOrganization: 'person' | 'organization';
	mainName: string | null;
	requestingIsniForSelf: boolean;
}) =>
	isniApi.post<{
		isniToken: string;
	}>('/generate', {
		forename,
		surname,
		birthdate,
		artistName,
		role,
		websiteAddress,
		websiteTitle,
		relatedForename,
		relatedSurname,
		relatedOrganizationName,
		isnotIsniList,
		personOrOrganization,
		mainName,
		requestingIsniForSelf,
		title,
	});

const reportError = () => isniApi.post('/report');

const retrieveIsni = (isniToken: string) =>
	isniApi.post<{
		isni: string;
		isniCertificate: string;
	}>('/retrieve', { token: isniToken });

const reportTimeout = (isniToken: string) =>
	isniApi.post('/report-timeout', { payload: { token: isniToken } });

const IsniService = {
	nameSearch,
	generateToken,
	reportError,
	retrieveIsni,
	reportTimeout,
};

export default IsniService;
