import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import '../ShareModal/ShareModal.scss';
import {
	getRecordingEditors,
	addRecordingEditor,
	addAlbumEditor,
} from '../../../../api/services/editorService';
import ShareForm from '../ShareModal/ShareForm';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../../../store/modal/actions';
import { claimActiveEditor } from '../../../../api/services/editorService';
import { setModalTitle } from '../../../../store/modal/actions';

const ProjectsShareModal = ({ values, shareAlbum }) => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { userId } = useSelector(state => state.auth);
	const { currentAlbumId } = useSelector(state => state.projects);
	const [informationMessage, setInformationMessage] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');

	const dispatch = useDispatch();

	useEffect(() => {
		const title = shareAlbum ? 'SHARE / REQUESTS' : 'ALBUM SHARE / REQUESTS';
		dispatch(setModalTitle(title));
	}, [dispatch, shareAlbum]);

	const handleSubmit = async e => {
		try {
			e.preventDefault();
			setIsSubmitting(true);
			setInformationMessage('');

			const form = e.target;
			const status = !Boolean(form[2].value);
			const emailRegex =
				// eslint-disable-next-line no-control-regex
				/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

			if (!emailRegex.test(email)) {
				setInformationMessage('Invalid email. Try again.');
				setIsSubmitting(false);
				return;
			}

			const validRecordings = await fetchValidRecordings();
			await Promise.all(
				validRecordings.map(async recording => {
					let addEditorPayload = {
						editor_email: email,
						is_read_only: status,
						message,
					};

					if (shareAlbum) addEditorPayload.album_id = currentAlbumId;
					else addEditorPayload.recording_id = recording.id;

					await claimActiveEditor(userId, recording.id);

					if (shareAlbum) await addAlbumEditor(addEditorPayload);
					else await addRecordingEditor(addEditorPayload);
				})
			);

			setIsSubmitting(false);
			dispatch(hideModal());
		} catch (e) {
			console.log({ e });
			if (e && e.response) {
				if (e.response.status === 404)
					setInformationMessage(
						'Something went wrong, please try again later.'
					);
				if (e.response.status === 400)
					setInformationMessage(
						'One of the recordings already has that editor.'
					);
			}

			setIsSubmitting(false);
			console.log({ e }, e.code);
		}
	};

	const fetchValidRecordings = async e => {
		try {
			setIsSubmitting(true);
			let res = null;
			let recordings = [];
			let validRecordings = [];

			values.forEach(project => {
				if (project.subRows) {
					// If it is an album
					project.subRows.forEach(recording => recordings.push(recording));
					return;
				} else {
					recordings.push(project);
				}
			});

			const addValidRecordings = async recording => {
				try {
					if (!recording) return;
					res = await getRecordingEditors(recording.id);

					console.log(res);
					const editors = res.data.editors;
					let myNewEditorProfile = {
						...editors.find(editor => editor.user_id === userId),
					};

					if (!myNewEditorProfile.is_read_only) validRecordings.push(recording);
				} catch (e) {
					console.log(e);
				}
			};

			await Promise.all(recordings.map(addValidRecordings));

			return validRecordings;
		} catch (e) {
			setIsSubmitting(false);
			setInformationMessage('Something went wrong, please try again later.');
			console.log(e);
		}
	};

	const handleEmailChange = event => setEmail(event.target.value);

	const handleMessageChange = event => setMessage(event.target.value);

	if (!values.length)
		return (
			<>
				<Modal.Body className='p-5'>
					<h2 className='w-100 text-center'>
						You need to select a recording first.
					</h2>
				</Modal.Body>
			</>
		);

	return (
		<>
			<Modal.Body className='p-5'>
				<section className='w-100'>
					<ShareForm
						handleSubmit={handleSubmit}
						isSubmitting={isSubmitting}
						informationMessage={informationMessage}
						informationText={
							'You can only share the projects where you have editor/administrator permissions.'
						}
						email={email}
						message={message}
						handleEmailChange={handleEmailChange}
						handleMessageChange={handleMessageChange}
					/>
				</section>
			</Modal.Body>
		</>
	);
};

export default ProjectsShareModal;
