import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import FileDragAndDropInput from '../../../layout/FileDragAndDropInput';
import FileStorageStatus from '../FileStorageStatus/FileStorageStatus';
import InputFileList from '../UploadFilesModal/InputFileList';
import { hideModal, setModalTitle } from '../../../../store/modal/actions';
import Button from '../../../layout/Button';
import {
	addToUploadQueueAction,
	getProjectFilesMetadataAction,
	getStorageUsageAction,
} from '../../../../store/files/actions';
import {
	getFilesById,
	getUploadingStorageUsage,
	groupUploadsByProjectId,
} from '../../../../store/files/selectors';
import SoundCreditLoader from '../../SoundCreditLoader';
import {
	computeFilenames,
	convertFileEntryToFile,
	traverse_directory,
	validateStorageUsageAndCorruptedFiles,
} from '../../../../helpers/fileTools';
import { createUploads } from '../../../../helpers/uploadTools';
import * as Yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import TextField from '../../../form/TextField/TextField';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

export type UploadFileVersionForm = {
	comment: string;
};

const validationSchema = Yup.object().shape({
	comment: Yup.string(),
});

export type UploadFileVersionModalProps = {
	fileId: FileMetadata['id'];
	playlistId?: Playlist['id'] | null;
};

const UploadFileVersionModal = ({
	fileId,
	playlistId = null,
}: UploadFileVersionModalProps) => {
	const dispatch = useAppDispatch();
	const filesById = useAppSelector(getFilesById);
	const { storageUsage, filesByProjectId } = useAppSelector(
		state => state.files
	);
	const uploadsByProjectId = useAppSelector(groupUploadsByProjectId);
	const uploadingStorageUsage = useAppSelector(getUploadingStorageUsage);

	const [inputFile, setInputFile] = useState<File | null>(null);

	const inputFiles = useMemo(() => (inputFile ? [inputFile] : []), [inputFile]);
	const setInputFiles = useCallback(
		(files: FileList) => setInputFile(files?.length ? files[0] : null),
		[]
	);
	const [isLoading, setIsLoading] = useState(true);

	const file = useMemo(() => filesById?.[fileId] ?? null, [filesById, fileId]);

	const { recordingId, albumId } = useMemo(
		() => ({
			recordingId: file?.recordingId ?? null,
			albumId: file?.albumId ?? null,
		}),
		[file]
	);

	// only run on mount
	useEffect(() => {
		if (!file) return;

		console.log('FIL', file);
		dispatch(setModalTitle(`UPLOAD NEW VERSION OF ${file.filename}`));

		Promise.resolve(
			Promise.all([
				dispatch(getStorageUsageAction()), // update storage usage as this will be opened from Projects
				dispatch(
					getProjectFilesMetadataAction({
						recordingId,
						albumId,
					})
				), // update filesByProjectId as computing new file names will need this to check for duplicates
			])
		).then(() => setIsLoading(false));
	}, [dispatch, file, albumId, recordingId]);

	const handleDropzoneInputChange = useCallback(
		async (acceptedFiles: any[]) => {
			let individualFiles: File[] = [];

			let webkitItems = acceptedFiles;
			let directories: { [key: string]: File[] } = {};

			const promises: any[] = [];

			const folderFilesDict: { [key: string]: File[] } = {};

			// First, we take a look at all the files/folders uploaded
			// We traverse each folder received in drag and drop/select
			let directoriesCount: number = 0;

			for (var i = 0; i < webkitItems.length; i++) {
				if (webkitItems[i].isDirectory) {
					let folderFiles: File[] = [];
					console.log('Webkit Dir:', webkitItems[i]);
					const key = webkitItems[i].name;
					directories[key] = [];
					directoriesCount++;

					const promise = traverse_directory(
						webkitItems[i],
						directories,
						key,
						folderFiles
					).then((items: any) => {
						// AT THIS POINT THE DIRECTORY SHOULD BE FULLY TRAVERSED.

						folderFilesDict[key] = folderFiles;
						// return result;
					});
					promises.push(promise);
				} else if (webkitItems[i].isFile) {
					const file = await convertFileEntryToFile(webkitItems[i]);
					individualFiles.push(file);
				}
			}

			if (directoriesCount === 0 && individualFiles.length === 0) {
				individualFiles = acceptedFiles;
			}

			await Promise.all(promises);

			const folderFiles = Object.values(folderFilesDict).flat();

			const allFilesList = [...individualFiles, ...folderFiles];

			let selectedFiles = acceptedFiles;

			const allFiles = await validateStorageUsageAndCorruptedFiles({
				dispatch,
				storageUsage,
				uploadingStorageUsage,
				newSelectedFiles: allFilesList,
			});

			if (!allFiles) return; // if some validation failed, then return

			setInputFile(allFilesList[0]);
		},
		[dispatch, storageUsage, uploadingStorageUsage, inputFiles]
	);

	const handleFileInputChange = async (selectedFile: File[]) => {
		// check if input size exceeds storage limit
		// and if the file is corrupted
		const allFiles = await validateStorageUsageAndCorruptedFiles({
			dispatch,
			storageUsage,
			uploadingStorageUsage,
			newSelectedFiles: selectedFile,
		});

		if (!allFiles) return; // if some validation failed, then return

		setInputFile(selectedFile[0]);
	};

	const handleUpload = async (formValues: UploadFileVersionForm) => {
		if (!file || !filesByProjectId || !inputFile) return;

		const filenames = computeFilenames(
			// add recordingId and albumId to each file as the computeFilenames function needs it
			[
				{
					filename: inputFile?.name,
					recordingId,
					albumId,
				},
			],
			filesByProjectId,
			uploadsByProjectId
		);

		const uploads = await createUploads({
			inputFiles: [inputFile],
			projectId: {
				recordingId,
				albumId,
			},
			filenames,
			fileVersionsMetadata: [
				{
					comment: formValues.comment,
					fileId,
					playlistId,
				},
			],
		});

		// Add files to upload queue in Redux
		// The FileUploadMenu component will pick up the files from the queue and upload them
		dispatch(addToUploadQueueAction(uploads));

		dispatch(hideModal());
	};

	const formik = useFormik<UploadFileVersionForm>({
		initialValues: {
			comment: '',
		},
		validationSchema,
		onSubmit: handleUpload,
	});

	return !file || isLoading ? (
		<div
			style={{
				height: '40vh',
			}}
		>
			<SoundCreditLoader />
		</div>
	) : (
		<FormikProvider value={formik}>
			<Modal.Body>
				<div>
					<Row className='mb-4 mx-4'>
						<Col
							xs={12}
							className='d-flex justify-content-end align-items-center'
						>
							<FileStorageStatus />
						</Col>
					</Row>
					<div className='px-4'>
						<FileDragAndDropInput
							className='upload-files-modal-drop-area'
							inputFiles={inputFile}
							onSelect={handleDropzoneInputChange}
							onDrop={handleFileInputChange}
							multiple={false}
						/>
					</div>

					{inputFile && (
						<>
							<InputFileList
								inputFiles={inputFiles}
								setInputFiles={setInputFiles}
								className='px-4'
								hideLabel
							/>

							<hr />

							<div className='px-4'>
								<TextField
									label='Version Description'
									name='comment'
									placeholder='Enter a name or description for this version'
									className='mb-4'
									value={formik?.values?.comment}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									errorMessage={formik?.errors?.comment}
									isInvalid={Boolean(
										formik?.touched?.comment && formik?.errors?.comment
									)}
									maxLength={70}
									showMaxLength
								/>
							</div>
						</>
					)}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button label='Close' onClick={() => dispatch(hideModal())} />
				<Button
					label='Upload'
					theme='dark'
					onClick={() => formik.handleSubmit()}
					isDisabled={inputFiles.length === 0}
				/>
			</Modal.Footer>
		</FormikProvider>
	);
};

export default UploadFileVersionModal;
