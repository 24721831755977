import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import {
	REQUEST_FORBIDDEN,
	REQUEST_LOADING,
} from '../../../constants/requestStatusTypes';
import { loginAction, validMagicLinkAction } from '../../../store/auth/actions';
import TextField from '../../form/TextField';
import Button from '../../layout/Button';
import './Login.scss';
import ROUTES from '../../../router/routes';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Helmet } from 'react-helmet';
import { MAGIC_LINK_INVALID } from '@/store/actionTypes';

const FORGOT_PASSWORD_URL = process.env.REACT_APP_FORGOT_PASSWORD;

const schema = yup.object().shape({
	email: yup.string().required('Please enter an email address'),
	password: yup.string().required('Please enter a password'),
});

const Login = () => {
	const dispatch = useAppDispatch();
	const {
		loginRequestStatus,
		errorMessage,
		magicLinkStatus,
		magicLinkErrorMessage,
	} = useAppSelector(state => state.auth);

	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
		},
		validationSchema: schema,
		onSubmit: values => {
			dispatch(
				loginAction(
					values.email,
					values.password,
					(isEmailVerified: boolean) => {
						if (isEmailVerified) {
							navigate('/', { replace: true });
						} else {
							navigate(ROUTES.SignUp.path);
						}
					}
				)
			);
		},
	});

	const handleCreateAccount = () => {
		navigate(ROUTES.SignUp.path);
	};

	const handleForgotPassword = () => {
		window.open(FORGOT_PASSWORD_URL, '_blank');
	};

	// navigate to Projects if logged in correctly
	// useEffect(() => {
	// 	if (userToken) {
	// 		navigate(ROUTES.Playlists.path);
	// 	}
	// }, [navigate, userToken]);

	useEffect(() => {
		return () => {
			dispatch(validMagicLinkAction());
		};
	}, [dispatch]);

	return (
		<FormikProvider value={formik}>
			<>
				<Helmet>
					<title>Login {process.env.REACT_APP_TAB_TITLE}</title>
				</Helmet>
				<div className='login-container'>
					<Form
						placeholder={null}
						className='white-bg login-form-container'
						onKeyDown={e => {
							if (e.key === 'Enter') {
								formik.handleSubmit();
							}
						}}
					>
						<div className='logo-container'>
							<svg
								width='3.125rem'
								height='3.012rem'
								viewBox='0 0 50 49'
								xmlns='http://www.w3.org/2000/svg'
							>
								<defs>
									<linearGradient
										x1='-133.8%'
										y1='262.3%'
										x2='100%'
										y2='10.4%'
										id='21841pri8a'
									>
										<stop stopColor='#8205D2' offset='0%' />
										<stop stopColor='#60A3F1' offset='100%' />
									</linearGradient>
								</defs>
								<path
									d='M536.609 270.433a13.629 13.629 0 0 0-7.986-9.201 16.255 16.255 0 0 0-18.77 4.71 13.846 13.846 0 0 0-3.089 8.485h-.005l-.211 6.01v13.322h-.01c-.103 1.645-1.614 3.229-3.762 3.769-2.518.632-4.949-.41-5.431-2.33-.483-1.921 1.17-3.987 3.687-4.618a5.293 5.293 0 0 1 3.987.473v-10.936a15.477 15.477 0 0 0-6.312.294 16.03 16.03 0 0 0-8.608 5.452 13.482 13.482 0 0 0 5.292 21.084 15.615 15.615 0 0 0 6.146 1.24c1.353-.002 2.7-.17 4.013-.499a16.026 16.026 0 0 0 8.607-5.452 13.82 13.82 0 0 0 3.093-8.808l.212-5.623v-13.38h.01c.102-1.645 1.612-3.228 3.76-3.767 2.518-.633 4.951.409 5.432 2.329.48 1.92-1.169 3.987-3.687 4.617a5.293 5.293 0 0 1-3.987-.473v10.984c.762.107 1.53.158 2.298.152 1.353 0 2.701-.168 4.013-.498a16.017 16.017 0 0 0 8.606-5.452 13.627 13.627 0 0 0 2.702-11.884z'
									transform='translate(-487 -260)'
									fill='url(#21841pri8a)'
									fillRule='nonzero'
								/>
							</svg>
						</div>
						<div className='title-container'>
							<h2>SOUND CREDIT</h2>
						</div>

						<div className='username-container'>
							{loginRequestStatus !== REQUEST_FORBIDDEN &&
								magicLinkStatus === MAGIC_LINK_INVALID && (
									<span style={{ color: 'red', fontSize: '12px' }}>
										{magicLinkErrorMessage}
									</span>
								)}
							<TextField
								name='email'
								placeholder='Username'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								isInvalid={
									(formik.touched.email && !!formik.errors.email) ||
									(formik.touched.password && !!errorMessage)
								}
								errorMessage={formik.errors.email || errorMessage}
								value={formik.values.email}
							/>
						</div>
						<div className='password-container'>
							<TextField
								name='password'
								type='password'
								placeholder='Password'
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								isInvalid={formik.touched.password && !!formik.errors.password}
								errorMessage={formik.errors.password}
								showEyeToggle
								value={formik.values.password}
							/>
						</div>
						<div className='login-btn-container'>
							<Button
								label='Sign In'
								onClick={formik.handleSubmit as any}
								theme='dark'
								size='lg'
								isLoading={loginRequestStatus === REQUEST_LOADING}
							/>
						</div>
						<div className='text-purple fw-600 d-flex justify-content-end'>
							<Button
								label='Forgot Password?'
								style={{ border: 0 }}
								className='text-right btn forgot-pass-container mb-4 border-0'
								onClick={handleForgotPassword}
							/>
						</div>
						<div style={{ flexGrow: 1 }}></div>
						<div>
							<Button
								label='Create Account'
								size='lg'
								onClick={handleCreateAccount}
							/>
						</div>
					</Form>
				</div>
			</>
		</FormikProvider>
	);

	// return (
	// 	<>
	// 		<div className='draggable-bar' />
	// 		<div className='login-container'>
	// 			<Row className='login-form-container'>
	// 				<Col xs={12} className='mb-3'>
	// 					<img
	// 						style={{ width: '100%' }}
	// 						src={getAssetPath('img/full-logo.png')}
	// 						alt='Sound Credit'
	// 						draggable={false}
	// 					/>
	// 				</Col>
	// 				{errorMessage && (
	// 					<Col xs={12}>
	// 						<Alert variant='danger'>{errorMessage}</Alert>
	// 					</Col>
	// 				)}

	// 				<Col xs={12}>
	// 					<TextField
	// 						label='Email Address'
	// 						name='email'
	// 						placeholder='Enter email'
	// 						onChange={formik.handleChange}
	// 						onBlur={formik.handleBlur}
	// 						isInvalid={formik.touched.email && formik.errors.email}
	// 						errorMessage={formik.errors.email}
	// 					/>
	// 				</Col>

	// 				<Col xs={12}>
	// 					<TextField
	// 						label='Password'
	// 						name='password'
	// 						type='password'
	// 						placeholder='Enter password'
	// 						onChange={formik.handleChange}
	// 						onBlur={formik.handleBlur}
	// 						isInvalid={formik.touched.password && formik.errors.password}
	// 						errorMessage={formik.errors.password}
	// 					/>
	// 				</Col>

	// 				<Col xs={12} className='d-flex justify-content-end'>
	// 					<Button
	// 						label='LOG IN'
	// 						onClick={formik.handleSubmit}
	// 						theme='dark'
	// 						isLoading={loginRequestStatus === REQUEST_LOADING}
	// 					/>
	// 				</Col>
	// 			</Row>
	// 		</div>
	// 	</>
	// );
};

export default Login;
