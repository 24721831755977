import mergeFetchedAlbums from '../../helpers/mergeFetchedAlbums';
import mergeFetchedRecordings from '../../helpers/mergeFetchedRecordings';
import {
	REQUEST_FAILURE,
	REQUEST_FORBIDDEN,
	REQUEST_LOADING,
	REQUEST_SUCCESS,
} from '../../constants/requestStatusTypes';
import {
	ADD_LOCAL_RECORDING,
	CLOUD_PROJECTS_REQUEST_AUTH_ERROR,
	CLOUD_PROJECTS_REQUEST_ERROR,
	CLOUD_PROJECTS_REQUEST_SUCCESS,
	DELETE_PARTICIPANT,
	EDIT_PARTICIPANT,
	SET_PROJECTS_SEARCH_FILTER,
	SET_USER_PROJECTS,
	START_CLOUD_PROJECTS_REQUEST,
	SET_CURRENT_ALBUM_ID,
	SET_CURRENT_RECORDING_ID,
	UPDATE_LOCAL_RECORDING,
	ADD_LOCAL_RECORDINGS_TO_ALBUM,
	ADD_LOCAL_ALBUM,
	UPDATE_LOCAL_ALBUM,
	DELETE_LOCAL_RECORDING_BY_ID,
	DELETE_LOCAL_ALBUM_BY_ID,
	REORDER_LOCAL_ALBUM_RECORDINGS,
	CLEAR_SELECTED_PROJECT,
	ADD_EXPORTS_TO_LOCAL_RECORDING,
	ADD_EXPORTS_TO_LOCAL_ALBUM,
	DELETE_EXPORT_FROM_LOCAL_RECORDING,
	DELETE_EXPORT_FROM_LOCAL_ALBUM,
	REMOVE_RECORDING_FROM_LOCAL_ALBUM,
	SAVE_EDITOR_PROFILE,
	CLEAR_PROJECTS,
	SET_ALBUM_ARCHIVED,
	SET_EXPORTS_TO_LOCAL_ALBUM,
	SET_EXPORTS_TO_LOCAL_RECORDING,
	INCREMENT_LOCAL_RECORDING_ASSETS_COUNT,
	DECREMENT_LOCAL_RECORDING_ASSETS_COUNT,
	SET_LOCAL_RECORDING_ASSETS_COUNT,
	SET_LOCAL_ALBUM_ASSETS_COUNT,
	INCREMENT_LOCAL_ALBUM_ASSETS_COUNT,
	DECREMENT_LOCAL_ALBUM_ASSETS_COUNT,
	SET_PROJECT_USAGE,
	SET_PROJECT_LIMIT,
	SET_USER_EDITABLE_PROJECTS,
	SET_SEARCHED_ALBUMS,
	SET_SEARCHED_RECORDINGS,
	CLEAR_SEARCHED_ALBUMS,
	CLEAR_SEARCHED_RECORDINGS,
	SET_EDITOR_PROFILE_ACTIVE,
} from '../actionTypes';
import deleteByValue from '../../helpers/deleteByValue';
import {
	deleteRecordingFromAlbum,
	getAlbumRecordingIds,
} from '../../helpers/albumTools';
import _ from 'lodash';
import { AnyAction } from 'redux';

export type ProjectsState = {
	recordingsById: RecordingsById | null;
	albumsById: Record<number, Album> | null;
	searchedRecordingsById: Record<number, Recording> | null;
	searchedAlbumsById: Record<number, Album> | null;
	userEditableAlbumIds: number[] | null;
	userEditableRecordingIds: number[] | null;
	currentRecordingId: number | null;
	currentAlbumId: number | null;
	searchFilter: string;
	requestStatus: string | null;
	requestLabel: string | null;
	errorMessage: string | null;
	yScrollPosition: number | null;
	expandedAlbumIds: number[];
	projectUsage: ProjectUsage | null;
	myEditorProfile?: ApiRecordingEditor | ApiAlbumEditor | null;
	errorCode: number | null;
};

const initialState: ProjectsState = {
	recordingsById: null,
	albumsById: null,
	userEditableAlbumIds: null,
	userEditableRecordingIds: null,
	searchedRecordingsById: null,
	searchedAlbumsById: null,
	currentRecordingId: null,
	currentAlbumId: null,
	searchFilter: '',
	requestStatus: '',
	requestLabel: '',
	errorMessage: '',
	errorCode: null,
	projectUsage: null,
	myEditorProfile: null,
	yScrollPosition: null,
	expandedAlbumIds: [] as number[],
};

const reducer = (state = initialState, action: AnyAction): ProjectsState => {
	// Aux variables for null safety
	const currentRecordingsById = state.recordingsById ?? {};
	const currentAlbumsById = state.albumsById ?? {};

	const currentRecording = state.currentRecordingId
		? currentRecordingsById[state.currentRecordingId]
		: null;
	const newRecordingsById = { ...currentRecordingsById };
	const newAlbumsById = { ...currentAlbumsById };

	const currentProjectUsage = state.projectUsage as ProjectUsage;

	switch (action.type) {
		case START_CLOUD_PROJECTS_REQUEST:
			return {
				...state,
				requestStatus: REQUEST_LOADING,
				requestLabel: action.requestLabel,
				errorMessage: '',
			};
		case CLOUD_PROJECTS_REQUEST_ERROR:
			return {
				...state,
				requestStatus: REQUEST_FAILURE,
				errorMessage: action.errorMessage,
				errorCode: action.errorCode ?? null,
			};
		case CLOUD_PROJECTS_REQUEST_AUTH_ERROR:
			return {
				...state,
				requestStatus: REQUEST_FORBIDDEN,
				errorMessage: action.errorMessage,
			};
		case CLOUD_PROJECTS_REQUEST_SUCCESS:
			return {
				...state,
				requestStatus: REQUEST_SUCCESS,
				errorMessage: '',
			};
		case REMOVE_RECORDING_FROM_LOCAL_ALBUM:
			const _albumId = currentRecordingsById[action.recordingId]?.albumId;

			if (!_albumId) {
				return state;
			}

			const _newAlbumRecordings = deleteRecordingFromAlbum(
				action.recordingId,
				currentAlbumsById[_albumId].recordings
			);
			return {
				...state,
				albumsById: {
					...currentAlbumsById,
					[_albumId]: {
						...currentAlbumsById[_albumId],
						recordings: _newAlbumRecordings,
						album: currentAlbumsById[_albumId].album
							? ({
									...currentAlbumsById[_albumId].album,
									recordings: _newAlbumRecordings,
							  } as AlbumContent)
							: null,
					},
				},
				recordingsById: {
					...currentRecordingsById,
					[action.recordingId]: {
						...currentRecordingsById[action.recordingId],
						albumId: null,
					},
				},
			};
		case ADD_EXPORTS_TO_LOCAL_RECORDING:
			const newRecordingExports = [
				...new Set([
					...currentRecordingsById[action.recordingId].exports,
					...action.exports,
				]),
			];

			return {
				...state,
				recordingsById: {
					...currentRecordingsById,
					[action.recordingId]: {
						...currentRecordingsById[action.recordingId],
						assetsCount:
							currentRecordingsById[action.recordingId]?.assetsCount +
							action.exports.length,
						exports: newRecordingExports,
					},
				},
			};
		case SET_EXPORTS_TO_LOCAL_RECORDING:
			return {
				...state,
				recordingsById: {
					...currentRecordingsById,
					[action.recordingId]: {
						...currentRecordingsById[action.recordingId],
						exports: action.exports,
					},
				},
			};

		case SET_EXPORTS_TO_LOCAL_ALBUM:
			return {
				...state,
				albumsById: {
					...currentAlbumsById,
					[action.albumId]: {
						...currentAlbumsById[action.albumId],
						exports: action.exports,
					},
				},
			};
		case INCREMENT_LOCAL_RECORDING_ASSETS_COUNT:
			return {
				...state,
				recordingsById: {
					...currentRecordingsById,
					[action.recordingId]: {
						...currentRecordingsById[action.recordingId],
						assetsCount:
							(currentRecordingsById[action.recordingId]?.assetsCount ?? 0) +
							action.increment,
					},
				},
			};
		case DECREMENT_LOCAL_RECORDING_ASSETS_COUNT:
			return {
				...state,
				recordingsById: {
					...currentRecordingsById,
					[action.recordingId]: {
						...currentRecordingsById[action.recordingId],
						assetsCount:
							currentRecordingsById[action.recordingId]?.assetsCount -
							action.decrement,
					},
				},
			};
		case INCREMENT_LOCAL_ALBUM_ASSETS_COUNT:
			return {
				...state,
				albumsById: {
					...currentAlbumsById,
					[action.albumId]: {
						...currentAlbumsById[action.albumId],
						assetsCount:
							(currentAlbumsById[action.albumId]?.assetsCount ?? 0) +
							action.increment,
					},
				},
			};
		case DECREMENT_LOCAL_ALBUM_ASSETS_COUNT:
			return {
				...state,
				albumsById: {
					...currentAlbumsById,
					[action.albumId]: {
						...currentAlbumsById[action.albumId],
						assetsCount:
							currentAlbumsById[action.albumId]?.assetsCount - action.decrement,
					},
				},
			};
		case SET_LOCAL_RECORDING_ASSETS_COUNT:
			return {
				...state,
				recordingsById: {
					...currentRecordingsById,
					[action.recordingId]: {
						...currentRecordingsById[action.recordingId],
						assetsCount: action.assetsCount,
					},
				},
			};
		case SET_LOCAL_ALBUM_ASSETS_COUNT:
			return {
				...state,
				albumsById: {
					...currentAlbumsById,
					[action.albumId]: {
						...currentAlbumsById[action.albumId],
						assetsCount: action.assetsCount,
					},
				},
			};
		case ADD_EXPORTS_TO_LOCAL_ALBUM:
			const newAlbumExports = [
				...new Set([
					...currentAlbumsById[action.albumId].exports,
					...action.exports,
				]),
			];

			return {
				...state,
				albumsById: {
					...currentAlbumsById,
					[action.albumId]: {
						...currentAlbumsById[action.albumId],
						assetsCount:
							currentAlbumsById[action.albumId]?.assetsCount +
							action.exports.length,
						exports: newAlbumExports,
					},
				},
			};
		case DELETE_EXPORT_FROM_LOCAL_RECORDING:
			return {
				...state,
				recordingsById: {
					...currentRecordingsById,
					[action.recordingId]: {
						...currentRecordingsById[action.recordingId],
						assetsCount:
							currentRecordingsById[action.recordingId].assetsCount - 1,
						exports: [
							...currentRecordingsById[action.recordingId].exports.filter(
								exportId => exportId !== action.exportId
							),
						],
					},
				},
			};

		case DELETE_EXPORT_FROM_LOCAL_ALBUM:
			return {
				...state,
				albumsById: {
					...currentAlbumsById,
					[action.albumId]: {
						...currentAlbumsById[action.albumId],
						assetsCount: currentAlbumsById[action.albumId].assetsCount - 1,
						exports: [
							...currentAlbumsById[action.albumId].exports.filter(
								exportId => exportId !== action.exportId
							),
						],
					},
				},
			};
		case SET_USER_PROJECTS:
			return {
				...state,
				recordingsById: mergeFetchedRecordings(
					action.recordings,
					currentRecordingsById
				),
				albumsById: mergeFetchedAlbums(action.albums, currentAlbumsById),
				requestStatus: REQUEST_SUCCESS,
			};
		case DELETE_LOCAL_RECORDING_BY_ID:
			// delete recording from album
			const currentRecordingId =
				state.currentRecordingId === action.id
					? null
					: state.currentRecordingId;

			const albumId = currentRecordingsById[action.id].albumId;

			if (albumId) {
				console.log('ALBUM ID', albumId, 'albums', newAlbumsById);

				const newAlbumTracks = deleteRecordingFromAlbum(
					action.id,
					newAlbumsById[albumId].recordings
				);

				newAlbumsById[albumId].recordings = newAlbumTracks;

				if (newAlbumsById[albumId].album) {
					newAlbumsById[albumId].album!.recordings = newAlbumTracks;
				}
			}

			// delete recording from state
			delete newRecordingsById[action.id];

			return {
				...state,
				currentRecordingId,
				recordingsById: newRecordingsById,
				albumsById: newAlbumsById,
				projectUsage: {
					...currentProjectUsage,
					used: Math.max(0, (currentProjectUsage.used ?? 1) - 1),
				},
			};
		case DELETE_LOCAL_ALBUM_BY_ID:
			const currentAlbumId =
				state.currentAlbumId === action.albumId ? null : state.currentAlbumId;

			let recordingsDeleted = 0;

			if (newAlbumsById[action.albumId]) {
				const albumRecordingIds = getAlbumRecordingIds(
					newAlbumsById[action.albumId]
				);

				recordingsDeleted = albumRecordingIds.length;

				// remove album from recordings referenced by album
				albumRecordingIds.forEach(recordingId => {
					delete newRecordingsById[recordingId];
				});

				// remove album from state
				delete newAlbumsById[action.albumId];
			}

			return {
				...state,
				currentAlbumId,
				albumsById: newAlbumsById,
				recordingsById: newRecordingsById,
				projectUsage: {
					...currentProjectUsage,
					used: Math.max(
						0,
						(currentProjectUsage.used ?? 0) - recordingsDeleted
					),
				},
			};
		case CLEAR_SELECTED_PROJECT:
			return {
				...state,
				currentRecordingId: null,
				currentAlbumId: null,
			};
		case ADD_LOCAL_RECORDING:
			console.log('ADD_LOCAL_RECORDING', action.recording);
			console.log('PREV RECORDING', currentRecordingsById[action.recording.id]);
			return {
				...state,
				recordingsById: {
					...currentRecordingsById,
					[action.recording.id]: {
						...action.recording,
						assetsCount:
							action.recording.assetsCount ??
							currentRecordingsById[action.recording.id]?.assetsCount,
						exports: currentRecordingsById[action.recording.id]?.exports
							? [...currentRecordingsById[action.recording.id].exports]
							: [],
					},
				},
				projectUsage: {
					...currentProjectUsage,
					// if the recording already exists, then don't increment the used count
					used: currentRecordingsById[action.recording.id]
						? currentProjectUsage.used
						: (currentProjectUsage.used ?? 0) + 1,
				},
			};
		case ADD_LOCAL_ALBUM:
			return {
				...state,
				albumsById: {
					...currentAlbumsById,
					[action.album.id]: {
						...action.album,
						assetsCount:
							action.album.assetsCount ??
							currentAlbumsById[action.album.id]?.assetsCount,
						exports: currentAlbumsById[action.album.id]?.exports
							? [...currentAlbumsById[action.album.id].exports]
							: [],
					},
				},
			};
		case ADD_LOCAL_RECORDINGS_TO_ALBUM:
			console.log('ACTION', action);

			// check if recordings are already in album
			// if so, ignore the action
			if (
				action.recordings &&
				action.recordings.filter((rec: number) =>
					getAlbumRecordingIds(newAlbumsById[action.albumId]).includes(rec)
				).length > 0
			) {
				return state;
			}

			// first, delete the recordings from their old albums
			// then assign the new album to each recording
			action.recordings.forEach((recordingId: number) => {
				const recording = newRecordingsById[recordingId];
				if (recording.albumId !== action.albumId) {
					// remove the recording from the old album
					if (recording.albumId) {
						const oldAlbum = newAlbumsById[recording.albumId];
						console.log('OLD ALBUM', oldAlbum);
						const oldAlbumTracks = { ...oldAlbum.recordings };

						deleteByValue(oldAlbumTracks, recordingId);

						console.log('OLD ALBUM 2', oldAlbum);
						oldAlbum.recordings = oldAlbumTracks;

						if (oldAlbum.album) oldAlbum.album.recordings = oldAlbumTracks;

						newAlbumsById[recording.albumId] = oldAlbum;
					}

					// add the recording to the new album
					const newAlbum = newAlbumsById[action.albumId];

					const lastTrackPosition = parseInt(
						_.last(Object.keys(newAlbum.recordings)) ?? '0'
					);

					newAlbum.recordings = {
						...newAlbum.recordings,
						[lastTrackPosition + 1]: recordingId,
					};

					if (newAlbum.album)
						newAlbum.album.recordings = { ...newAlbum.recordings };

					newAlbumsById[action.albumId] = newAlbum;

					// update the recording
					recording.albumId = action.albumId;

					if (recording.recording) {
						recording.recording.albumId = action.albumId;
					}

					newRecordingsById[recordingId] = { ...recording };
				}
			});

			return {
				...state,
				albumsById: newAlbumsById,
				recordingsById: newRecordingsById,
			};
		case REORDER_LOCAL_ALBUM_RECORDINGS:
			// swap the track number of the recording with the recording at the given index

			return {
				...state,
				albumsById: {
					...currentAlbumsById,
					[action.albumId]: {
						...newAlbumsById[action.albumId],
						recordings: action.newRecordingOrder,
						album: currentAlbumsById[action.albumId].album
							? ({
									...currentAlbumsById[action.albumId].album,
									recordings: action.newRecordingOrder,
							  } as AlbumContent)
							: null,
					},
				},
			};
		case UPDATE_LOCAL_ALBUM:
			return {
				...state,
				albumsById: {
					...currentAlbumsById,
					[action.album.id]: {
						...currentAlbumsById[action.album.id],
						title: action.album.title,
						artist: action.album.artistName,
						recordings: { ...action.album.recordings },
						album: {
							...action.album,
						},
					},
				},
			};
		case UPDATE_LOCAL_RECORDING:
			console.log('UPDATE_LOCAL_RECORDING', action);
			if (!action.fullRecording) {
				action.fullRecording = currentRecordingsById[action.recordingForm.id];
			} else if (!action.recordingForm) {
				action.recordingForm =
					currentRecordingsById[action.fullRecording.id].recording; // ! may be undefined if it's not been fetched yet
			}

			return {
				...state,
				recordingsById: {
					...currentRecordingsById,
					[action.fullRecording.id]: {
						...currentRecordingsById[action.fullRecording.id],
						title: action.recordingForm?.title ?? action.fullRecording.title,
						artist:
							action.recordingForm?.mainArtist ?? action.fullRecording.artist,
						albumId: action.fullRecording.albumId,
						recording: action.recordingForm && { ...action.recordingForm },
						isArchived: action.fullRecording.isArchived,
					},
				},
			};
		case SET_ALBUM_ARCHIVED:
			return {
				...state,
				albumsById: {
					...currentAlbumsById,
					[action.albumId]: {
						...currentAlbumsById[action.albumId],
						isArchived: action.isArchived,
					},
				},
			};
		case SET_PROJECTS_SEARCH_FILTER:
			return { ...state, searchFilter: action.payload.filter };
		case SET_CURRENT_ALBUM_ID:
			return {
				...state,
				currentAlbumId: action.albumId,
				currentRecordingId: action.recordingId,
			};
		case SET_CURRENT_RECORDING_ID:
			return {
				...state,
				currentRecordingId: action.id,
			};
		case EDIT_PARTICIPANT:
			if (!state.currentRecordingId) return state;

			return {
				...state,
				recordingsById: {
					...currentRecordingsById,
					[state.currentRecordingId]: {
						...currentRecording,
						recording: {
							...currentRecording?.recording,
							participants: [
								...(currentRecording?.recording?.participants ?? []).map(
									participant =>
										participant.id === action.participant.id
											? action.participant
											: participant
								),
							],
						},
					} as Recording,
				},
			};
		case DELETE_PARTICIPANT:
			if (!state.currentRecordingId) return state;

			return {
				...state,
				recordingsById: {
					...currentRecordingsById,
					[state.currentRecordingId]: {
						...currentRecording,
						recording: {
							...currentRecording?.recording,
							participants: [
								...(currentRecording?.recording?.participants ?? []).filter(
									participant => participant.id !== action.participantId
								),
							],
						},
					} as Recording,
				},
			};

		case SAVE_EDITOR_PROFILE:
			return {
				...state,
				myEditorProfile: action.myEditorProfile,
			};
		case SET_EDITOR_PROFILE_ACTIVE:
			if (!state.myEditorProfile) return state;

			return {
				...state,
				myEditorProfile: {
					...state.myEditorProfile,
					is_active_editor: action.isActive,
				},
			};
		case SET_PROJECT_USAGE:
			return {
				...state,
				projectUsage: {
					...currentProjectUsage,
					used: action.projectUsage,
				},
			};
		case SET_PROJECT_LIMIT:
			return {
				...state,
				projectUsage: {
					...currentProjectUsage,
					limit: action.projectLimit,
				},
			};
		case SET_USER_EDITABLE_PROJECTS:
			return {
				...state,
				userEditableAlbumIds: action.albumIds,
				userEditableRecordingIds: action.recordingIds,
			};

		case SET_SEARCHED_ALBUMS:
			return {
				...state,
				searchedAlbumsById: action.albums,
			};
		case SET_SEARCHED_RECORDINGS:
			return {
				...state,
				searchedRecordingsById: action.recordings,
			};
		case CLEAR_SEARCHED_ALBUMS:
			return {
				...state,
				searchedAlbumsById: {},
			};
		case CLEAR_SEARCHED_RECORDINGS:
			return {
				...state,
				searchedRecordingsById: {},
			};
		case CLEAR_PROJECTS:
			return {
				...initialState,
			};
		default:
			return state;
	}
};

export default reducer;
