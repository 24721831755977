import {
	login,
	validateMagicLink,
	validateToken,
} from '../../api/services/authService';
import { translateApiUserPermissions } from '../../helpers/authTools';
import {
	INVALID_TOKEN,
	LOGIN_FAILURE,
	LOGIN_INVALID,
	LOGIN_REQUEST,
	LOGIN_SUCCESS,
	LOGOUT,
	TOKEN_VALIDATION_ERROR,
	VALIDATING_TOKEN,
	VALID_TOKEN,
	SET_S3_FILE_UPLOAD_CREDENTIALS,
	CLEAR_S3_FILE_UPLOAD_CREDENTIALS,
	SET_USER_PERMISSIONS,
	START_SIGNUP_PROCESS,
	FINISH_SIGNUP_PROCESS,
	SET_SIGNUP_FORM_STEP,
	SET_EMAIL_VERIFIED,
	MAGIC_LINK_INVALID,
	MAGIC_LINK_VALID,
} from '../actionTypes';
import { clearExportAction } from '../exports/actions';
import {
	clearFilesAction,
	setStorageLimitAction,
	setStorageUsageAction,
} from '../files/actions';
// import { clearPlaylistsAction } from '../playlists/actions';
import { clearParticipantsAction } from '../participants/actions';
import { clearProfilesAction } from '../profiles/actions';
import {
	clearProjectsAction,
	setProjectLimitAction,
	setProjectUsageAction,
} from '../projects/actions';
import { clearSessionDataAction } from '../session/actions';
import { clearUserAction } from '../user/actions';
import { clearPlaylistsAction } from '../playlists/actions';
import { AppDispatch } from '..';
import { clearPromosAction, setPromoSeenAction } from '../promos/actions';
import PromoType from '../promos/promoType';
import * as Sentry from '@sentry/react';
import { resetPlayerStateAction } from '../player/actions';
import { SignUpFormStep } from '../../components/screens/SignUp/SignUp';

export const clearAllReduxDataAction = () => (dispatch: AppDispatch) => {
	dispatch(clearExportAction());
	dispatch(clearProjectsAction());
	dispatch(clearParticipantsAction());
	dispatch(clearProfilesAction());
	dispatch(clearSessionDataAction());
	dispatch(clearFilesAction());
	dispatch(clearUserAction());
	dispatch(clearPlaylistsAction());
	dispatch(clearPromosAction());
	dispatch(resetPlayerStateAction());
};

export const validateTokenRequestAction = () => ({ type: VALIDATING_TOKEN });

export const validateTokenSuccessAction = () => ({ type: VALID_TOKEN });

export const invalidTokenAction = () => (dispatch: AppDispatch) => {
	dispatch(clearAllReduxDataAction());

	dispatch({
		type: INVALID_TOKEN,
		errorMessage: 'User session expired. Please log in again.',
	});
};

export const validateTokenErrorAction = (errorMessage?: string) => ({
	type: TOKEN_VALIDATION_ERROR,
	errorMessage,
});

export const validateTokenAction =
	(userToken: string, userId: number) => (dispatch: AppDispatch) => {
		dispatch(validateTokenRequestAction());

		Sentry.setUser({
			id: userId,
		});

		return validateToken(userToken, userId)
			.then(response => {
				const { is_email_verified: isEmailVerified, auth_passed: authPassed } =
					response.data;

				dispatch(setEmailVerifiedAction(isEmailVerified));

				if (!authPassed && isEmailVerified) {
					dispatch(invalidTokenAction());
					return;
				}

				if (!isEmailVerified) {
					dispatch(startSignUpProcessAction(SignUpFormStep.VERIFY_EMAIL));
				}

				dispatch(validateTokenSuccessAction());

				const {
					permissions,
					project_count: projectUsage,
					storage_usage: storageUsage,
					has_seen_timer_promotion: hasSeenTimerPromotion,
				} = response.data;

				dispatch(
					setUserPermissionsAndLimitsAction(
						translateApiUserPermissions(permissions),
						storageUsage,
						projectUsage
					)
				);

				dispatch(
					setPromoSeenAction(PromoType.PlaylistJuly2023, hasSeenTimerPromotion)
				);
			})
			.catch(() => {
				dispatch(validateTokenErrorAction());
			});
	};

export const validateMagicLinkErrorAction = (errorMessage?: string) => ({
	type: TOKEN_VALIDATION_ERROR,
	errorMessage,
});

export const validateMagicLinkAction =
	(token: string) => (dispatch: AppDispatch) => {
		return validateMagicLink(token)
			.then(async response => {
				if (!response.data.profileId)
					return dispatch(
						invalidLoginAction(
							'Whooops!, Something went wrong when trying to log in. Please try again.'
						)
					);

				dispatch(logoutAction());
				Sentry.setUser({
					id: response.data.id,
				});

				if (!response.data.is_email_verified) {
					dispatch(startSignUpProcessAction(SignUpFormStep.VERIFY_EMAIL));
				}

				dispatch(loginSuccessAction(response.data));

				const {
					permissions,
					project_count: projectUsage,
					storage_usage: storageUsage,
					has_seen_timer_promotion: hasSeenTimerPromotion,
				} = response.data;

				dispatch(
					setUserPermissionsAndLimitsAction(
						translateApiUserPermissions(permissions),
						storageUsage,
						projectUsage
					)
				);

				dispatch(
					setPromoSeenAction(PromoType.PlaylistJuly2023, hasSeenTimerPromotion)
				);

				// if (onLoginSuccess)
				// 	await onLoginSuccess(response.data.is_email_verified);
			})
			.catch(error => {
				const errorStatus = error?.response?.status;

				switch (errorStatus) {
					case 401:
						console.log('Link expired');
						dispatch(
							invalidMagicLinkAction('Magic link has expired. Please log in.')
						);
						break;
					case 404:
						console.log('No matching magic link');
						dispatch(
							invalidMagicLinkAction('No matching magic link. Please log in.')
						);
						break;
					default:
						dispatch(
							loginFailureAction(
								'Hiccup detected while logging in. Please try again later.'
							)
						);
						break;
				}

				if (!error.response) {
					dispatch(loginFailureAction());
					return;
				}

				// console.log(error.response);

				// switch (error.response.status) {
				// 	case 401:
				// 		dispatch(
				// 			invalidLoginAction('Invalid email address and/or password')
				// 		);
				// 		break;
				// 	case 422:
				// 		dispatch(
				// 			invalidLoginAction('Invalid email address and/or password')
				// 		);
				// 		break;
				// 	default:
				// 		dispatch(
				// 			loginFailureAction(
				// 				'Hiccup detected while logging in. Please try again later.'
				// 			)
				// 		);
				// 		break;
				// }
			});
	};

export const logoutAction = () => (dispatch: AppDispatch) => {
	dispatch(clearAllReduxDataAction());

	dispatch({ type: LOGOUT });
};

export const loginRequestAction = (userEmail: string) => ({
	type: LOGIN_REQUEST,
	userEmail,
});

export const loginFailureAction = (errorMessage?: string) => ({
	type: LOGIN_FAILURE,
	errorMessage,
});

export const invalidLoginAction = (errorMessage?: string) => ({
	type: LOGIN_INVALID,
	errorMessage,
});

export const invalidMagicLinkAction = (errorMessage?: string) => ({
	type: MAGIC_LINK_INVALID,
	errorMessage,
});

export const validMagicLinkAction = () => ({
	type: MAGIC_LINK_VALID,
});

export const loginSuccessAction = (response: {
	id: number;
	token: string;
	profileId: number;
	is_email_verified: boolean;
}) => ({
	type: LOGIN_SUCCESS,
	userId: response.id,
	userToken: response.token,
	profileId: response.profileId,
	isEmailVerified: response.is_email_verified,
});

export const loginAction =
	(
		email: string,
		password: string,
		onLoginSuccess?:
			| ((isEmailVerified: boolean) => Promise<any>)
			| ((isEmailVerified: boolean) => void)
	) =>
	(dispatch: AppDispatch) => {
		dispatch(loginRequestAction(email));
		dispatch(clearAllReduxDataAction());

		return login(email, password)
			.then(async response => {
				if (!response.data.profileId)
					return dispatch(
						invalidLoginAction(
							'Whooops!, Something went wrong when trying to log in. Please try again.'
						)
					);

				Sentry.setUser({
					id: response.data.id,
				});

				if (!response.data.is_email_verified) {
					dispatch(startSignUpProcessAction(SignUpFormStep.VERIFY_EMAIL));
				}

				dispatch(loginSuccessAction(response.data));

				const {
					permissions,
					project_count: projectUsage,
					storage_usage: storageUsage,
					has_seen_timer_promotion: hasSeenTimerPromotion,
				} = response.data;

				dispatch(
					setUserPermissionsAndLimitsAction(
						translateApiUserPermissions(permissions),
						storageUsage,
						projectUsage
					)
				);

				dispatch(
					setPromoSeenAction(PromoType.PlaylistJuly2023, hasSeenTimerPromotion)
				);

				if (onLoginSuccess)
					await onLoginSuccess(response.data.is_email_verified);
			})
			.catch(error => {
				if (!error.response) {
					dispatch(loginFailureAction());
					return;
				}

				console.log(error.response);

				switch (error.response.status) {
					case 401:
						dispatch(
							invalidLoginAction('Invalid email address and/or password')
						);
						break;
					case 422:
						dispatch(
							invalidLoginAction('Invalid email address and/or password')
						);
						break;
					default:
						dispatch(
							loginFailureAction(
								'Hiccup detected while logging in. Please try again later.'
							)
						);
						break;
				}
			});
	};

export const setUserPermissionsAndLimitsAction =
	(
		permissions: UserPermissions,
		storageUsage?: number,
		projectUsage?: number
	) =>
	(dispatch: AppDispatch) => {
		dispatch(setUserPermissionsAction(permissions));

		dispatch(setStorageLimitAction(permissions.storageLimit));

		dispatch(setProjectLimitAction(permissions.projectLimit));

		if (storageUsage != null && projectUsage != null) {
			dispatch(setProjectUsageAction(projectUsage));
			dispatch(setStorageUsageAction(storageUsage));
		}
	};

export const setS3FileUploadCredentialsAction = (
	credentials: S3Credentials
) => ({
	type: SET_S3_FILE_UPLOAD_CREDENTIALS,
	credentials,
});

export const clearS3FileUploadCredentialsAction = () => ({
	type: CLEAR_S3_FILE_UPLOAD_CREDENTIALS,
});

export const setUserPermissionsAction = (userPermissions: UserPermissions) => ({
	type: SET_USER_PERMISSIONS,
	userPermissions,
});

export const startSignUpProcessAction = (step?: SignUpFormStep) => ({
	type: START_SIGNUP_PROCESS,
	step,
});

export const finishSignUpProcessAction = () => ({
	type: FINISH_SIGNUP_PROCESS,
});

export const setSignUpFormStepAction = (step: SignUpFormStep) => ({
	type: SET_SIGNUP_FORM_STEP,
	step,
});

export const setEmailVerifiedAction = (verified: boolean) => ({
	type: SET_EMAIL_VERIFIED,
	verified,
});
