import '../Export.scss';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col, Container, Modal, Row } from 'react-bootstrap';
import Button from '../../../layout/Button';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal, setModalTitle } from '../../../../store/modal/actions';
import SearchTable from '../../../layout/SearchTable';
import SoundCreditLoader from '../../SoundCreditLoader';
import { showErrorAlert } from '../../../../store/alertToast/actions';
import { getUserEditableProjects } from '../../../../api/services/projectsService';

const searchTableColumns = [
	{
		Header: () => <span className='Project-Name'>Artist Name</span>,
		accessor: 'artist',
		Cell: col => <span className='Name1'>{col.value}</span>,
	},
	{
		Header: () => <span className='Project-Name'>Song Name</span>,
		accessor: 'title',
		Cell: col => <span className='Name1'>{col.value}</span>,
	},
];

const SelectRecordingModal = ({
	onSubmit = async recordingIds => {},
	title = 'SELECT RECORDING(S) TO EXPORT',
	recordingFilter = recording => true,
	recordingSortFn = (
		a,
		b // sort by id in ascending order by default
	) => b.id - a.id,
}) => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);
	const { recordingsById } = useSelector(state => state.projects);
	const [selectedRecordings, setSelectedRecordings] = useState([]);
	const [editableRecordingIds, setEditableRecordingIds] = useState(null);
	const columns = useMemo(() => searchTableColumns, []);

	const fetchProjectEditors = useCallback(async () => {
		try {
			const { data } = await getUserEditableProjects();

			const editableAlbumIds = data.albums.map(album => album.id);
			const editableRecIds = data.recordings
				.map(r => r.id)
				.filter(
					recordingId =>
						!recordingsById[recordingId]?.albumId ||
						editableAlbumIds.includes(recordingsById[recordingId]?.albumId)
				);

			setEditableRecordingIds(editableRecIds);

			setIsLoading(false);
		} catch (e) {
			dispatch(hideModal());
			dispatch(
				showErrorAlert(
					'Oops! We missed a beat getting your project permissions. Try clicking Refresh Projects.'
				)
			);

			console.log(e);
		}
	}, [recordingsById, dispatch]);

	useEffect(() => {
		fetchProjectEditors();
	}, [fetchProjectEditors]);

	useEffect(() => {
		dispatch(setModalTitle(title));
	}, [dispatch, title]);

	const closeModal = () => dispatch(hideModal());

	const handleSubmit = async () => {
		setIsLoading(true);
		try {
			await onSubmit(selectedRecordings);
		} finally {
			setIsLoading(false);
			closeModal();
		}
	};

	useEffect(() => {
		console.log('UPDATE SELECTED RECORDINGS');
	}, [selectedRecordings]);

	const onSelect = useCallback(
		recordings => {
			setSelectedRecordings(recordings);
		},
		[setSelectedRecordings]
	);

	const recordings = useMemo(
		() =>
			editableRecordingIds
				?.map(recId => recordingsById[recId])
				?.filter(recordingFilter)
				?.sort(recordingSortFn) ?? [],
		[editableRecordingIds, recordingFilter, recordingsById, recordingSortFn]
	);

	if (isLoading)
		return (
			<>
				<Modal.Body>
					<SoundCreditLoader />
				</Modal.Body>
			</>
		);

	return (
		<>
			<Modal.Body>
				<Container>
					<Card>
						<Card.Body>
							<Row>
								<Col>
									<div>
										<SearchTable
											columns={columns}
											data={recordings}
											onSelect={onSelect}
											rowMapper={row => row.original.id}
										/>
									</div>
								</Col>
							</Row>
						</Card.Body>
					</Card>
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Row>
					<Col
						xs={{ span: 4, offset: 8 }}
						className='d-flex justify-content-end'
					>
						<Button
							onClick={closeModal}
							className='btn border-purple text-purple bg-white fw-600 mr-3'
							type='button'
							label='Cancel'
						/>

						<Button
							onClick={handleSubmit}
							className='btn btn-purple'
							label='Continue'
							theme='dark'
						/>
					</Col>
				</Row>
			</Modal.Footer>
		</>
	);
};

export default SelectRecordingModal;
