export const formatDateToHtml = (date: Date | string | null) => {
	if (!date) return '';

	if (!(date instanceof Date)) {
		date = new Date(date);
	}

	let dd = date.getDate().toString();
	let mm = (date.getMonth() + 1).toString(); //January is 0!
	const yyyy = date.getFullYear();

	if (dd.length < 2) {
		dd = '0' + dd;
	}

	if (mm.length < 2) {
		mm = '0' + mm;
	}
	return `${yyyy}-${mm}-${dd}`;
};

export const formatDateToStr = (date?: Date | string | null) => {
	if (!date) return '';
	let _date = date instanceof Date ? date : new Date(date);

	return _date?.toLocaleDateString(undefined, {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
		hour: 'numeric',
		minute: '2-digit',
	});
};

export const formatDateToApi = (date?: string | null) =>
	date ? new Date(date).toISOString().replace('Z', '') : '';

/**
 *
 * @param time - time in seconds
 * @param isMilli - if true, time is in milliseconds
 * @returns
 */
export const formatTime = (time: number = 0, config = { isMilli: false }) => {
	const seconds = config?.isMilli ? time / 1000 : time;

	var hh = Math.floor(seconds / 3600),
		mm = Math.floor(seconds / 60) % 60,
		ss = Math.floor(seconds) % 60;
	return (
		(hh ? (hh < 10 ? '0' : '') + hh + ':' : '') +
		(mm < 10 ? '0' : '') +
		mm +
		':' +
		(ss < 10 ? '0' : '') +
		ss
	);
};
