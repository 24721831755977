const filterProjects =
	(searchFilter: string, activeKey: string | null) =>
	({ title, artist, isArchived, createdAt, owner }: ProjectRow) => {
		const archivedStatus = activeKey === 'vault';

		if (searchFilter === '') {
			return isArchived === archivedStatus;
		}

		const regex = new RegExp(searchFilter, 'gi');
		const rowString = `${title}${artist}${createdAt}${owner}`;

		return isArchived === archivedStatus && regex.test(rowString);
	};

const filterProjectsBySearchTerm = (
	searchFilter: string,
	projectRows: ProjectRow[],
	activeKey: string | null
) => {
	return projectRows?.filter(filterProjects(searchFilter, activeKey));
};

export default filterProjectsBySearchTerm;
