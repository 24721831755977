import React, { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getFilesById } from '../../../store/files/selectors';
import { hideModal, setModalTitle } from '../../../store/modal/actions';
import Button from '../../layout/Button';
import SelectProjectFilesLeftTable from './SelectProjectFilesLeftTable';
import SelectProjectFilesRightTable from './SelectProjectFilesRightTable';

const SelectProjectFilesModal = ({ onSubmit }) => {
	const dispatch = useDispatch();

	const filesById = useSelector(getFilesById);
	const [selectedFileIds, setSelectedFileIds] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const handleSubmit = async () => {
		setIsLoading(true);

		const selectedFiles = selectedFileIds.map(id => filesById[id]);

		try {
			await onSubmit(selectedFiles);

			dispatch(hideModal());
		} finally {
			setIsLoading(false);
		}
	};

	const handleClose = () => {
		dispatch(hideModal());
	};

	useEffect(() => {
		dispatch(setModalTitle('ADD FROM SOUND CREDIT PROJECT FILES'));
	}, [dispatch]);

	return (
		<>
			<Modal.Body>
				<div className='px-4'>
					<Row>
						<Col xs={6}>
							<SelectProjectFilesLeftTable
								setSelectedFileIds={setSelectedFileIds}
								selectedFileIds={selectedFileIds}
							/>
						</Col>
						<Col xs={6}>
							<SelectProjectFilesRightTable
								setSelectedFileIds={setSelectedFileIds}
								selectedFileIds={selectedFileIds}
							/>
						</Col>
					</Row>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<div>
					<Button label='Cancel' className='mr-2' onClick={handleClose} />
					<Button
						label='Add'
						theme='dark'
						onClick={handleSubmit}
						isLoading={isLoading}
						isDisabled={selectedFileIds.length === 0}
					/>
				</div>
			</Modal.Footer>
		</>
	);
};

export default SelectProjectFilesModal;
