import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useAppDispatch } from '../../../../store/hooks';
import { hideModal, setModalTitle } from '../../../../store/modal/actions';
import { useFormik } from 'formik';
import { RadioButton } from 'primereact/radiobutton';
import TextField from '../../../form/TextField';
import Button from '../../../layout/Button';
import * as Yup from 'yup';

type ProducerAgreementShareModalProps = {
	onSubmit: (share: number) => void;
};

const validationSchema = Yup.object().shape({
	customShare: Yup.number()
		.min(1, 'Minimum value is 1')
		.max(100, 'Maximum value is 100')
		.when('useDefault', {
			is: false,
			then: Yup.number().required('Please enter a value'),
		}),
});

const DEFAULT_SHARE = 17;

const ProducerAgreementShareModal = ({
	onSubmit,
}: ProducerAgreementShareModalProps) => {
	const dispatch = useAppDispatch();

	const formik = useFormik({
		initialValues: {
			useDefault: true,
			customShare: 17,
		},
		validationSchema,
		onSubmit: values => {
			console.log(values);
			onSubmit(values.useDefault ? DEFAULT_SHARE : values.customShare);
		},
	});

	useEffect(() => {
		dispatch(setModalTitle('PRODUCER ROYALTY'));
	}, [dispatch]);

	return (
		<>
			<Modal.Body>
				<div className='px-4'>
					<p
						style={{
							fontSize: '0.9rem',
						}}
					>
						Do you wish to use the default share of {DEFAULT_SHARE}% or a
						different value?
					</p>

					<div>
						<div className='d-flex align-items-center mb-2'>
							<RadioButton
								inputId='useDefault'
								name='useDefault'
								onChange={e => formik.setFieldValue('useDefault', e.checked)}
								checked={formik.values.useDefault}
							/>
							<label htmlFor='useDefault' className='mb-0 ml-2'>
								Use default share ({DEFAULT_SHARE}%)
							</label>
						</div>

						<div className='d-flex align-items-center'>
							<RadioButton
								inputId='doNotUseDefault'
								name='useDefault'
								onChange={e => formik.setFieldValue('useDefault', !e.checked)}
								checked={!formik.values.useDefault}
							/>
							<label htmlFor='doNotUseDefault' className='mb-0 ml-2'>
								Use different share
							</label>
						</div>

						{!formik.values.useDefault && (
							<TextField
								className='mt-3'
								type='number'
								name='customShare'
								id='customShare'
								label='Enter custom producer royalty share'
								value={formik.values.customShare ?? ''}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								isInvalid={
									formik.touched.customShare && !!formik.errors.customShare
								}
								errorMessage={formik.errors.customShare}
							/>
						)}
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					theme='light'
					className='btn btn-primary'
					onClick={() => dispatch(hideModal())}
					label='Cancel'
				/>

				<Button
					theme='dark'
					className='btn btn-primary'
					onClick={() => formik.handleSubmit()}
					label='Continue'
				/>
			</Modal.Footer>
		</>
	);
};

export default ProducerAgreementShareModal;
