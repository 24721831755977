import _ from 'lodash';
import React, { FocusEventHandler, useContext, useMemo } from 'react';
import theme from '../../../theme.module.scss';
import { useAppSelector } from '../../../store/hooks';
import {
	ActionMeta,
	FormatOptionLabelMeta,
	GetOptionLabel,
	OnChangeValue,
} from 'react-select';
import CreatableSelect from '../CreatableSelect';
import ProjectTypeIcon from '../../layout/ProjectTypeIcon';
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters';
import PlaylistFileUploadContext, {
	PlaylistFileUploadContextType,
} from '@/components/screens/Playlists/PlaylistDetails/UploadFilesToPlaylist/PlaylistFileUploadContext';

export type ProjectSelectOption = {
	value: {
		recordingId: number | string | null;
		albumId: number | string | null;
	};
	label: string;
	title: string;
	artist: string;
	isAlbum: boolean;
};

export const createProjectOption = ({
	recordingId,
	albumId,
	title,
	artist,
	isAlbum,
}: {
	recordingId: number | string | null;
	albumId: number | string | null;
	title: string;
	artist: string;
	isAlbum: boolean;
}): ProjectSelectOption => ({
	value: {
		recordingId,
		albumId,
	},
	label: `${title} - ${artist}`,
	title,
	artist,
	isAlbum,
});

const formatOptionLabel =
	({
		labelText,
		showArtistName,
	}: {
		labelText: string;
		showArtistName: boolean;
	}) =>
	(
		{ value, title, artist, isAlbum, __isNew__ }: any,
		{ context }: FormatOptionLabelMeta<ProjectSelectOption>
	) => {
		return __isNew__ ? (
			<div
				style={{ color: theme.primary, fontWeight: 600, fontSize: '0.8rem' }}
			>
				<i className='fas fa-plus mr-2' />
				<span>
					{labelText} "{value}"
				</span>
			</div>
		) : (
			<div className='d-flex align-items-center'>
				{context === 'menu' && <ProjectTypeIcon isAlbum={isAlbum} />}
				<div className='d-flex flex-column'>
					{/* <span className="text-primary">{data?.id}</span> */}
					<span
						style={{
							fontWeight: 500,
							fontSize: '0.8rem',
							lineHeight: '0.9rem',
						}}
					>
						{title}
					</span>
					{(showArtistName || context === 'menu') && (
						<span
							style={{
								fontWeight: 400,
								fontSize: '0.75rem',
								lineHeight: '0.85rem',
							}}
							className='text-secondary'
						>
							{artist}
						</span>
					)}
				</div>
			</div>
		);
	};

export type CreatableProjectSelectProps = {
	name?: string;
	filename?: string;
	label?: string;
	id?: string;
	isOptionDisabled?: (option: any) => boolean;
	onChange?: (
		newValue: OnChangeValue<ProjectSelectOption, false>,
		actionMeta: ActionMeta<ProjectSelectOption>
	) => void;
	onBlur?: FocusEventHandler<HTMLInputElement>;
	placeholder?: string;
	filterOption?: (
		option: FilterOptionOption<ProjectSelectOption>,
		rawInput: string
	) => boolean;
	getOptionValue?: (option: ProjectSelectOption) => string;
	errorFieldName?: string;
	isDisabled?: boolean;
	dense?: boolean;
	fullWidth?: boolean;
	className?: string;
	onInputChange?: (value: string) => void;
	closeMenuOnSelect?: boolean;
	defaultValue?: ProjectSelectOption;
	isSearchable?: boolean;
	noOptionsMessage?: (obj: { inputValue: string }) => string;
	isClearable?: boolean;
	getOptionLabel?: GetOptionLabel<ProjectSelectOption>;
	value?: {
		recordingId: number | string | null;
		albumId: number | string | null;
	} | null;
	style?: React.CSSProperties;
	labelStyle?: React.CSSProperties;
	recordingFilterFn?: (recording: Recording) => boolean;
	albumFilterFn?: (album: Album) => boolean;
	removeReadOnly?: boolean;
	onCreateOption: (value: string) => void;
	createdOptions?: ProjectSelectOption[];
	playlistRecordingIds?: number[];
	playlistAlbumIds?: number[];
	playlistProjectsLabel?: string;
	createNewProjectLabel?: string;
	createdOptionsLabel?: string;
	showArtistName?: boolean;
	createOnBlur?: boolean;
	informationText?: React.ReactNode;
	isInvalid?: boolean;
};

const CreatableProjectSelect = ({
	name,
	filename,
	label,
	id,
	isOptionDisabled,
	onChange,
	onBlur,
	placeholder,
	filterOption,
	getOptionValue,
	errorFieldName,
	isDisabled = false,
	dense,
	fullWidth,
	className = '',
	onInputChange,
	closeMenuOnSelect,
	defaultValue,
	isSearchable,
	noOptionsMessage,
	isClearable,
	getOptionLabel = (option: ProjectSelectOption) => `${option.title}`,
	value,
	style = {},
	labelStyle = {},
	recordingFilterFn = _ => true,
	albumFilterFn = _ => true,
	removeReadOnly = false,
	showArtistName = true,
	onCreateOption,
	createdOptions = [],
	playlistRecordingIds = [],
	playlistAlbumIds = [],
	playlistProjectsLabel = 'In Current Playlist',
	createNewProjectLabel = 'Create New Recording With Title',
	createdOptionsLabel = 'New Recordings',
	createOnBlur = true,
	informationText,
	isInvalid = false,
}: CreatableProjectSelectProps) => {
	const {
		recordingsById,
		albumsById,
		userEditableAlbumIds,
		userEditableRecordingIds,
	} = useAppSelector(state => state.projects);

	const { useFilenameAsTitle, setUseFilenameAsTitle } =
		useContext<PlaylistFileUploadContextType | null>(
			PlaylistFileUploadContext
		) as PlaylistFileUploadContextType;

	const options = useMemo(() => {
		let _options = [] as ProjectSelectOption[];

		if (!recordingsById || !albumsById) return [];

		Object.values(recordingsById)
			.filter(recordingFilterFn)
			.filter(recording =>
				removeReadOnly ? userEditableRecordingIds?.includes(recording.id) : true
			)
			.forEach(recording => {
				_options.push(
					createProjectOption({
						recordingId: recording.id,
						albumId: null,
						title: recording.title,
						artist: recording.artist,
						isAlbum: false,
					})
				);
			});

		Object.values(albumsById)
			.filter(albumFilterFn)
			.filter(album =>
				removeReadOnly ? userEditableAlbumIds?.includes(album.id) : true
			)
			.forEach(album => {
				if (album.isSingle) return;

				_options.push(
					createProjectOption({
						recordingId: null,
						albumId: album.id,
						title: album.title,
						artist: album.artist,
						isAlbum: true,
					})
				);
			});

		const existingOptions = _options.sort((a, b) => {
			if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
			if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
			return 0;
		});

		const playlistOptions = [
			...playlistRecordingIds.map(recordingId => {
				const recording = recordingsById[recordingId];
				return createProjectOption({
					recordingId,
					albumId: null,
					title: recording.title,
					artist: recording.artist,
					isAlbum: false,
				});
			}),
			...playlistAlbumIds.map(albumId => {
				const album = albumsById[albumId];
				return createProjectOption({
					recordingId: null,
					albumId,
					title: album.title,
					artist: album.artist,
					isAlbum: true,
				});
			}),
		].sort((a, b) => {
			if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
			if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
			return 0;
		});

		const finalOptions = [
			{
				label: createdOptionsLabel,
				options: createdOptions,
			},
			{
				label: 'Existing Projects',
				options: existingOptions,
			},
		];

		if (playlistRecordingIds.length || playlistAlbumIds.length) {
			// add to middle of array
			finalOptions.splice(1, 0, {
				label: playlistProjectsLabel,
				options: playlistOptions,
			});
		}

		return finalOptions;
	}, [
		recordingsById,
		albumsById,
		userEditableAlbumIds,
		userEditableRecordingIds,
		removeReadOnly,
		recordingFilterFn,
		albumFilterFn,
		createdOptions,
		playlistRecordingIds,
		playlistAlbumIds,
		createdOptionsLabel,
		playlistProjectsLabel,
	]);

	const defaultFilterOption = (
		option: FilterOptionOption<ProjectSelectOption>,
		rawInput: string
	) => {
		if (!option?.data?.title) {
			// for new options, it must show the "Create new ..."
			return true;
		}

		const words = rawInput.split(' ');
		let title = option.data.title;
		let artist = option.data.artist;

		if (title) {
			title = title.toLowerCase();
		} else {
			title = '';
		}

		if (artist) {
			artist = artist.toLowerCase();
		} else {
			artist = '';
		}

		return words.every(word => title.includes(word) || artist.includes(word));
	};

	const selectedValue = useMemo(() => {
		if (value) {
			// flat array of all options
			const allOptions = options.reduce((acc: ProjectSelectOption[], curr) => {
				return [...acc, ...curr.options];
			}, []);

			// find the option that matches the value
			const selectedOption = allOptions.find(option =>
				_.isEqual(option.value, value)
			);
			if (selectedOption && filename) {
				selectedOption.title = filename;
			}
			return selectedOption;
		}
	}, [value, options, useFilenameAsTitle]);

	return (
		<CreatableSelect<ProjectSelectOption>
			className={className}
			// fullWidth={fullWidth}
			// style={style}
			label={label}
			dense={dense}
			createOptionPosition='first'
			isClearable={isClearable}
			// styles={selectStyles}
			name={name}
			id={id}
			onChange={onChange}
			onBlur={onBlur}
			value={selectedValue}
			options={options}
			placeholder={placeholder}
			getOptionValue={getOptionValue}
			isDisabled={isDisabled}
			isOptionDisabled={isOptionDisabled}
			onInputChange={onInputChange}
			closeMenuOnSelect={closeMenuOnSelect}
			defaultValue={defaultValue}
			isSearchable={isSearchable}
			noOptionsMessage={noOptionsMessage}
			filterOption={filterOption || defaultFilterOption}
			getOptionLabel={getOptionLabel}
			formatOptionLabel={formatOptionLabel({
				labelText: createNewProjectLabel,
				showArtistName,
			})}
			onCreateOption={onCreateOption}
			errorFieldName={errorFieldName}
			createOnBlur={createOnBlur}
			informationText={informationText}
			isInvalid={isInvalid}
		/>
	);
};

export default CreatableProjectSelect;
