import React, { useEffect } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { hideModal, setModalTitle } from '../../../../store/modal/actions';
import TextField from '../../../form/TextField';
import Button from '../../../layout/Button';
import DEFAULT_PLAYLIST from '../../../../constants/playlist.json';
import * as yup from 'yup';
import { Form, FormikProvider, useFormik } from 'formik';
import { createPlaylistAction } from '../../../../store/playlists/actions';
import { useNavigate } from 'react-router-dom';
import { replacePathVariables } from '../../../../helpers/routeTools';
import ROUTES from '../../../../router/routes';

const schema = yup.object().shape({
	name: yup.string().required('Required'),
});

/**
 * @deprecated
 */
const NewPlaylistModal = () => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = React.useState(false);
	const navigate = useNavigate();

	const handleClose = () => {
		dispatch(hideModal());
	};

	const afterCreate = playlistId => {
		navigate(replacePathVariables(ROUTES.PlaylistDetails.path, { playlistId }));
	};

	const handleCreate = playlistForm => {
		setIsLoading(true);

		Promise.resolve(
			dispatch(createPlaylistAction(playlistForm, afterCreate))
		).finally(() => {
			setIsLoading(false);
			handleClose();
		});
	};

	useEffect(() => {
		dispatch(setModalTitle('CREATE NEW PLAYLIST'));
	}, [dispatch]);

	const formik = useFormik({
		initialValues: DEFAULT_PLAYLIST,
		validationSchema: schema,
		validateOnMount: true,
		onSubmit: handleCreate,
	});

	return (
		<FormikProvider value={formik}>
			<Form onSubmit={formik.handleSubmit}>
				<Modal.Body>
					<Row>
						<Col xs={12} className='px-4'>
							<TextField
								label='Playlist Name'
								placeholder='Enter Playlist Name'
								className='mb-3'
								name='name'
								value={formik?.values?.name}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
								isInvalid={formik?.touched?.name && formik?.errors?.name}
								errorMessage={formik?.errors?.name}
							/>
						</Col>
						{/* <Col xs={12}>image input</Col> next iteration */}
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<div className='d-flex justify-content-end'>
						<Button label='Cancel' className='mr-2' onClick={handleClose} />
						<Button
							label='Create'
							theme='dark'
							isLoading={isLoading}
							type='submit'
						/>
					</div>
				</Modal.Footer>
			</Form>
		</FormikProvider>
	);
};

export default NewPlaylistModal;
