import fileSize from 'filesize';
import React, { useMemo, useRef, useState } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
import { MAX_IMAGE_SIZE } from '../../../helpers/fileTools';
import { useDebounceEffect } from '../../../hooks/useDebounceEffect';
import TextField from '../TextField';

export const IMAGE_FILE_TYPES = {
	base64: 'base64',
	blob: 'blob',
};

const MAX_HEIGHT = 400;

const ImageCropInput = ({
	setCroppedImage,
	inputMimeType,
	setInputMimeType,
	allowedFileFormats = ['image/jpeg', 'image/png'],
	aspectRatio = 1,
	maxFileSize = MAX_IMAGE_SIZE,
	formatDisclaimer = `Image must be a JPEG or PNG file`,
	sizeDisclaimer = `Image file size must be less than ${fileSize(maxFileSize)}`,
	inputLabel = 'Select image',
	outputType = IMAGE_FILE_TYPES.base64,
}) => {
	const [imageToCrop, setImageToCrop] = useState();
	const [completedCrop, setCompletedCrop] = useState();
	const imgRef = useRef(null);
	const [cropConfig, setCropConfig] = useState();
	const [fileValidations, setFileValidations] = useState([]);

	const [maxCropWidth, maxCropHeight] = useMemo(
		() => [aspectRatio, 1].map(size => Math.floor(size * MAX_HEIGHT)),
		[aspectRatio]
	);

	const [minCropWidth, minCropHeight] = useMemo(
		() => [maxCropWidth, maxCropHeight].map(size => Math.floor(size * 0.2)),
		[maxCropWidth, maxCropHeight]
	);

	useDebounceEffect(
		async () => {
			if (
				completedCrop &&
				completedCrop?.width &&
				completedCrop?.height &&
				imgRef.current
			) {
				// We use canvasPreview as it's much faster than imgPreview.
				const encodedCroppedImage = await getCroppedImageBase64(
					imgRef.current,
					completedCrop
				);

				setCroppedImage(encodedCroppedImage);
			}
		},
		100,
		[completedCrop]
	);

	const handleFileInputChange = e => {
		if (e.target.files && e.target.files.length > 0) {
			setFileValidations([]);
			setImageToCrop(null);

			const validations = [];

			if (!allowedFileFormats.includes(e.target.files[0].type)) {
				validations.push('Image file must be a JPEG or PNG');
			}

			if (e.target.files[0].size > maxFileSize) {
				validations.push(
					`Image file size must be less than ${maxFileSize / 1000000}MB`
				);
			}

			if (validations.length > 0) {
				setFileValidations(validations);
				return;
			}

			const reader = new FileReader();

			reader.addEventListener('load', () => {
				console.log(reader.result);
				setImageToCrop(reader.result);
				setInputMimeType(e.target.files[0].type);
			});

			reader.readAsDataURL(e.target.files[0]);
		}
	};

	const getCroppedImageBase64 = (sourceImage, cropConfig) => {
		// creating the cropped image from the source image
		const scaleX = sourceImage.naturalWidth / sourceImage.width;
		const scaleY = sourceImage.naturalHeight / sourceImage.height;

		const cropHeight =
			cropConfig.height * scaleY > maxCropHeight
				? maxCropHeight
				: cropConfig.height * scaleY;
		const cropWidth =
			cropConfig.width * scaleX > maxCropWidth
				? maxCropWidth
				: cropConfig.width * scaleX;

		const canvasHeight = Math.ceil(cropHeight);
		const canvasWidth = Math.ceil(cropWidth);

		const canvas = document.createElement('canvas');
		canvas.width = canvasWidth;
		canvas.height = canvasHeight;
		const ctx = canvas.getContext('2d');

		if (canvas)
			ctx.drawImage(
				sourceImage,
				cropConfig.x * scaleX,
				cropConfig.y * scaleY,
				cropConfig.width * scaleX,
				cropConfig.height * scaleY,
				0,
				0,
				cropWidth,
				cropHeight
			);

		return new Promise(resolve => {
			switch (outputType) {
				case IMAGE_FILE_TYPES.blob:
					canvas.toBlob(blob => {
						resolve(blob);
					}, inputMimeType);
					break;
				case IMAGE_FILE_TYPES.base64:
				default:
					resolve(canvas.toDataURL(inputMimeType).split(';base64,')[1]);
			}
		});
	};

	function onImageLoad(e) {
		const { width, height } = e.currentTarget;

		const crop = centerCrop(
			makeAspectCrop(
				{
					// You don't need to pass a complete crop into
					// makeAspectCrop or centerCrop.
					unit: '%',
					width: 90,
				},
				aspectRatio,
				width,
				height
			),
			width,
			height
		);

		setCropConfig(crop);
	}

	return (
		<>
			<TextField
				label={inputLabel}
				informationText={
					<>
						{' '}
						{formatDisclaimer} <div className='my-3' /> {sizeDisclaimer}{' '}
					</>
				}
				type='file'
				onChange={handleFileInputChange}
				style={{ height: '100%', padding: 0 }}
				isInvalid={fileValidations.length > 0}
				errorMessage={fileValidations.join('\n')}
			/>
			{imageToCrop ? (
				<div className='d-flex justify-content-center align-items-center'>
					<ReactCrop
						aspect={aspectRatio}
						crop={cropConfig}
						onChange={(_, percentCrop) => setCropConfig(percentCrop)}
						onComplete={c => setCompletedCrop(c)}
						minHeight={minCropHeight}
						minWidth={minCropWidth}
						maxHeight={maxCropHeight}
						maxWidth={maxCropWidth}
					>
						<img
							src={imageToCrop}
							ref={imgRef}
							style={{ maxHeight: MAX_HEIGHT }}
							onLoad={onImageLoad}
							alt='crop preview'
						/>
					</ReactCrop>
				</div>
			) : (
				<></>
			)}
		</>
	);
};

export default ImageCropInput;
