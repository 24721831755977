import React, { useMemo } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import formatListToLanguage from '../../../helpers/formatListToLanguage';
import './FileDragAndDropInput.scss';
import Button from '../Button/Button';
import { getAssetPath } from '../../../helpers/assetTools';
import Dropzone from '../Dropzone';

export type FileDragAndDropInputProps = {
	// onSelect:
	// 	| ((files: FileList) => Promise<void> | void)
	// 	| ((file: File) => Promise<void> | void);
	onSelect: (acceptedFiles: any) => Promise<void> | void;
	// onDrop:
	// 	| ((files: FileList) => Promise<void> | void)
	// 	| ((file: File) => Promise<void> | void);
	// onDrop: // | ((files: any[]) => Promise<void> | void)
	// | ((file: any) => Promise<void> | void);
	onDrop: (e: any) => void;
	inputFiles: File[] | File | null;
	className?: string;
	multiple?: boolean;
	types?: string[];
	fullHeight?: boolean;
	getMessage?: (multiple: boolean) => React.ReactNode;
};

const FileDragAndDropInput = ({
	onSelect,
	onDrop,
	inputFiles,
	className = '',
	multiple = true,
	types,
	fullHeight = true,
	getMessage = (multiple: boolean) => (
		<>
			<span>Drag & Drop to Upload File{multiple && <>(s)</>}</span>
			<br />
			<span>OR</span>
			<br />
			<Button label='Browse' onClick={() => {}} theme='dark' />
		</>
	),
}: FileDragAndDropInputProps) => {
	const typesString = useMemo(() => {
		if (!types) return '';
		const upperCaseTypes = types.map(type => type.toUpperCase());

		return `Only ${formatListToLanguage(upperCaseTypes)} files are allowed`;
	}, [types]);

	return (
		<>
			<div>
				<Dropzone multiple={multiple} onSelect={onSelect}>
					<div
						className={`file-dnd-area ${className}}`}
						style={{
							height: fullHeight ? '100%' : 'auto',
						}}
					>
						<img
							src={getAssetPath('img/drag-drop-files-art-1.svg')}
							alt='drag and drop files of any format'
							className='mb-5'
						/>

						<br />
						{getMessage(multiple)}

						{types && (
							<>
								<div className='my-3' />
								<span className='file-dnd-area__types'>{typesString}</span>
							</>
						)}
					</div>
				</Dropzone>
			</div>
		</>
	);
};

export default FileDragAndDropInput;
