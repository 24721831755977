import { TIER_LOCK_MODAL } from '../constants/modalTypes';
import { AppDispatch } from '../store';
import { showModalAction } from '../store/modal/actions';
import { TierDescriptions } from '../constants/tierDescriptions';

export const handleLockedClick = ({
	e,
	isLocked,
	onLockedClick,
	onClick,
	dispatch,
	isDisabled = false,
}: {
	e: React.MouseEvent<HTMLButtonElement, MouseEvent>;
	isLocked: boolean;
	onLockedClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;

	dispatch: AppDispatch;
	isDisabled?: boolean;
}) => {
	if (isDisabled) return;

	if (!isLocked) {
		if (onClick) onClick(e);

		return;
	}

	if (onLockedClick) {
		onLockedClick(e);
		return;
	}

	dispatch(
		showModalAction(TIER_LOCK_MODAL, {
			size: 'md',
		})
	);
};

export const hasReachedProjectLimit = (projectUsage: ProjectUsage) =>
	projectUsage?.limit !== 0 && projectUsage?.used >= projectUsage?.limit;

export const hasExceededProjectLimit = (projectUsage: ProjectUsage) =>
	projectUsage?.limit !== 0 && projectUsage?.used > projectUsage?.limit;

export const hasReachedStorageLimit = (storageUsage: StorageUsage) =>
	storageUsage.used >= storageUsage.limit;

type OpenUpgradeWebsiteParams = {
	email: string;
	product: string;
	coupon: string;
};

export const openUpgradeWebsite = (params?: OpenUpgradeWebsiteParams) => {
	let link = `https://www.soundcredit.com/subscribe`;

	if (params) {
		link += `?coupon=${params.coupon}&email=${params.email}&product=${params.product}`;
	}

	// shell.openExternal('https://soundcredit.com/subscribe');
	window.open(link, '_blank');
};

export const canUpgradeInPortal = (subscriptionTier: TierId) =>
	[
		TierDescriptions.PAID.STARTER.id,
		TierDescriptions.PAID.PRO.id,
		TierDescriptions.PAID.BUSINESS.id,
	].includes(subscriptionTier);
