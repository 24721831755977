import React from 'react';
import { useDispatch } from 'react-redux';
import { TIER_LOCK_MODAL } from '../../../constants/modalTypes';
import { showModalAction } from '../../../store/modal/actions';
import './ExportButton.scss';
import { Spinner } from 'react-bootstrap';

export type ExportButtonProps = {
	label: string;
	sublabel?: string;
	icon?: string;
	className?: string;
	onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	theme?: 'light' | 'dark';
	isDisabled?: boolean;
	inProgress?: boolean;
	centerLabel?: boolean;
	isLocked?: boolean;
	onLockedClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	isLoading?: boolean;
};

const ExportButton = ({
	label,
	sublabel,
	icon,
	className = '',
	onClick,
	theme = 'light',
	isDisabled = false,
	inProgress = false,
	centerLabel = false,
	isLocked = false,
	onLockedClick,
	isLoading = false,
}: ExportButtonProps) => {
	const dispatch = useDispatch();

	const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		if (!isLocked) {
			onClick(e);
			return;
		}
		if (onLockedClick) {
			onLockedClick(e);
			return;
		}

		dispatch(showModalAction(TIER_LOCK_MODAL, { size: 'md' }));
	};

	return (
		<button
			className={`export-button 
				${
					theme === 'dark'
						? `btn text-white bg-purple fw-600`
						: `btn border-purple text-purple bg-white fw-600`
				} ${className}`}
			onClick={handleClick}
			type='button'
			disabled={isDisabled || isLoading}
		>
			{inProgress && (
				<div className='export-button-in-progress'>IN PROGRESS</div>
			)}
			{isLocked && (
				<div className='export-button-in-progress'>
					<i className='fas fa-lock' />
				</div>
			)}
			<div
				className='export-button-container'
				style={{
					justifyContent: centerLabel ? 'center' : 'flex-start',
				}}
			>
				{icon && (
					<div className='icon-container pr-3'>
						{isLoading ? (
							<Spinner animation='border'  />
						) : (
							<img src={icon} alt='icon' />
						)}
					</div>
				)}
				<div className='label-container'>
					<span
						className='label'
						style={{
							display: centerLabel ? 'flex' : 'block',
							justifyContent: centerLabel ? 'center' : 'flex-start',
						}}
					>
						{label}
					</span>
					{sublabel && <span className='sublabel'>{sublabel}</span>}
				</div>
			</div>
		</button>
	);
};

export default ExportButton;
