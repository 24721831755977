import docsApi from '../docsApi';

type PortalHelpArticlesResponse = {
	data: {
		attributes: PortalHelpArticle;
		id: number;
	}[];
};

const getPortalHelpArticles = () =>
	docsApi
		.get<PortalHelpArticlesResponse>('/portal-help-articles')
		.then(response => response.data.data.map(({ attributes }) => attributes));

const DocsService = {
	getPortalHelpArticles,
};

export default DocsService;
