import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import SoundCreditLoader from './components/screens/SoundCreditLoader';
import { store, persistedStore } from './store';
import './assets/themes/light-theme/theme.scss';
import 'primereact/resources/primereact.min.css';
import theme from './theme.module.scss';
import { PrimeReactProvider } from 'primereact/api';
import {
	BrowserRouter,
	HashRouter,
	createRoutesFromChildren,
	matchRoutes,
	useLocation,
	useNavigationType,
} from 'react-router-dom';
import AuthWrapper from './components/AuthWrapper/AuthWrapper';
import { isElectron } from 'react-device-detect';
import { init } from '@sentry/electron/renderer';
import { init as reactInit } from '@sentry/react';
import * as Sentry from '@sentry/react';
import { DndContext } from '@dnd-kit/core';

if (process.env.NODE_ENV === 'production') {
	const sentryInitConfig = {
		dsn: process.env.REACT_APP_SENTRY_DSN,
		environment: process.env.REACT_APP_ENV,
		includeLocalVariables: true,
		integrations: [
			new Sentry.BrowserTracing({
				// See docs for support of different versions of variation of react router
				// https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
				routingInstrumentation: Sentry.reactRouterV6Instrumentation(
					React.useEffect,
					useLocation,
					useNavigationType,
					createRoutesFromChildren,
					matchRoutes
				),
			}),
		],
		release: process.env.REACT_APP_VERSION,
	};

	// we want to disable console logging in production deployments
	if (process.env.REACT_APP_ENV === 'production') {
		console.log = () => {};
		console.debug = () => {};
	}

	if (isElectron) {
		console.debug('ELECTRON SENTRY INIT');
		init(sentryInitConfig, reactInit);
	} else {
		console.debug('BROWSER SENTRY INIT');
		reactInit(sentryInitConfig);
	}
}

const Router = isElectron ? HashRouter : BrowserRouter;

ReactDOM.render(
	<React.StrictMode>
		<Sentry.ErrorBoundary fallback={'An error has occurred'}>
			<Provider store={store}>
				<PrimeReactProvider>
					<PersistGate
						loading={
							<div
								style={{
									height: '100vh',
									// backgroundColor: theme.backgroundDark,
									backgroundColor: '#121128',
								}}
							>
								<SoundCreditLoader theme='dark' />
							</div>
						}
						persistor={persistedStore}
					>
						<DndContext>
							<Router>
								<AuthWrapper>
									<App />
								</AuthWrapper>
							</Router>
						</DndContext>
					</PersistGate>
				</PrimeReactProvider>
			</Provider>
		</Sentry.ErrorBoundary>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
