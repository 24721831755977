import React, { useCallback, useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { hideModal, setModalTitle } from '../../../store/modal/actions';
import Button from '../../layout/Button';

const DeleteModal = ({
	onDelete,
	onCancel = null,
	onClose = null,
	title,
	showUndoWarning = true,
	body,
	confirmText = 'Delete',
	cancelText = 'Cancel',
}) => {
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);

	const handleClose = useCallback(() => {
		if (onClose) {
			onClose();
			return;
		}

		dispatch(hideModal());
	}, [dispatch, onClose]);

	const handleCancel = useCallback(() => {
		if (onCancel) {
			onCancel();
		}

		handleClose();
	}, [onCancel, handleClose]);

	const handleDelete = useCallback(async () => {
		try {
			if (onDelete) {
				// hide preview modal to avoid showing deleted file
				setIsLoading(true);
				await Promise.resolve(onDelete());
			}
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
			handleClose();
		}
	}, [onDelete, handleClose]);

	useEffect(() => {
		dispatch(setModalTitle(title));
	}, [dispatch, title]);

	return (
		<>
			<Modal.Body>
				<div className='px-4'>
					<p style={{ fontWeight: 500 }}>{body}</p>
					{showUndoWarning && (
						<p style={{ fontWeight: 700, marginBottom: 0 }}>
							This action cannot be undone.
						</p>
					)}
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Row>
					<Col
						xs={12}
						className='d-flex justify-content-end align-items-center'
					>
						<Button label={cancelText} onClick={handleCancel} />
						<Button
							label={confirmText}
							isLoading={isLoading}
							theme='danger-filled'
							onClick={handleDelete}
							className='ml-2'
						/>
					</Col>
				</Row>
			</Modal.Footer>
		</>
	);
};

export default DeleteModal;
