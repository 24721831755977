import {
	computeFilenames,
	generateCoverImagePath,
} from '../../helpers/fileTools';
import {
	ADD_UPLOADS_TO_PLAYLIST,
	CLEAR_PLAYLISTS,
	DELETE_LOCAL_PLAYLIST,
	DELETE_LOCAL_PLAYLIST_FILE,
	DELETE_UPLOADS_FROM_PLAYLIST,
	PLAYLIST_REQUEST_AUTH_ERROR,
	PLAYLIST_REQUEST_ERROR,
	PLAYLIST_REQUEST_SUCCESS,
	SET_CURRENT_PLAYLIST_EDITORS,
	SET_CURRENT_PLAYLIST_ID,
	SET_FULL_DRIP_STATS,
	SET_FULL_PLAYLISTS,
	SET_FULL_PLAYLIST_STATS,
	SET_PLAYLIST_CREDIT_REQUEST_COUNT,
	SET_USER_PLAYLISTS,
	SET_USER_PLAYLIST_STATS,
	START_PLAYLIST_REQUEST,
} from '../actionTypes';
import {
	addToUploadQueueAction,
	getProjectFilesMetadataAction,
	getStorageUsageAction,
	uploadImagesToBucketWithCredentialsAction,
} from '../files/actions';
import { hideModal, showModalAction } from '../modal/actions';
import {
	addRecordingsToCloudAlbumAction,
	createCloudAlbumAction,
	createCloudAlbumWithRecordingsAction,
	createCloudRecordingAction,
	fetchRecordingByIdAction,
	fetchUserEditableProjectsAction,
	getProjectUsageAction,
	getUserProjectsAction,
} from '../projects/actions';
import recordingSchema from '../../constants/recording.json';
import albumSchema from '../../constants/album.json';
import PROJECT_TYPES from '../../constants/projectTypes.json';
import _, { cloneDeep, uniq, uniqWith } from 'lodash';
import { showAlertToast, showErrorAlert } from '../alertToast/actions';
import { invalidTokenAction } from '../auth/actions';
import {
	ADD_PLAYLIST_COVER_IMAGE_REQUEST,
	ADD_PLAYLIST_HEADER_IMAGE_REQUEST,
	CHANGE_PLAYLIST_FILE_NAMES_REQUEST,
	CREATE_PLAYLIST_REQUEST,
	DELETE_PLAYLIST_FILE_REQUEST,
	DELETE_PLAYLIST_REQUEST,
	FETCH_PLAYLIST_REQUEST,
	FETCH_PLAYLIST_STAT_REQUEST,
	FETCH_USER_PLAYLISTS_REQUEST,
	PUBLISH_DRIP_REQUEST,
	REORDER_PLAYLIST_FILES_REQUEST,
	UNPUBLISH_DRIP_REQUEST,
	DUPLICATE_PLAYLIST_REQUEST,
	UPDATE_PLAYLIST_REQUEST,
	GENERATE_DRIP_STATS_AI_ANALYSIS_REQUEST,
	ACCEPT_PLAYLIST_EDITOR_INVITE_REQUEST,
	FETCH_PLAYLIST_EDITORS_REQUEST,
	ADD_PLAYLIST_EDITOR_REQUEST,
	REMOVE_PLAYLIST_EDITOR_REQUEST,
	CREATE_PLAYLIST_FILE_GROUP_REQUEST,
	DELETE_PLAYLIST_FILE_GROUP_REQUEST,
	RENAME_PLAYLIST_FILE_GROUP_REQUEST,
	REORDER_PLAYLIST_FILE_GROUPS_REQUEST,
	UPDATE_PLAYLIST_FILE_GROUP_FILES_REQUEST,
	UPDATE_PLAYLIST_SUB_GROUPS_REQUEST,
} from '../../constants/requestLabelTypes';
import {
	addPlaylistCoverImage,
	addPlaylistHeaderImage,
	changePlaylistFilenames,
	createPlaylist,
	deletePlaylist,
	deletePlaylistFile,
	duplicatePlaylist,
	getPlaylist,
	getUserPlaylists,
	publishDrip,
	reorderPlaylistFiles,
	saveDripAIAnalysis,
	unpublishDrip,
	updatePlaylist,
	acceptPlaylistEditorInvite,
	getPlaylistEditors,
	addNewPlaylistEditor,
	removePlaylistEditor,
	approveCreditRequest,
	createPlaylistFileGroup,
	deletePlaylistFileGroup,
	updatePlaylistFileGroup,
	reorderPlaylistFileGroups,
	updatePlaylistFileGroupFiles,
	updatePlaylistSubGroups,
} from '../../api/services/filesService';
import {
	getDripStat,
	getDripStatsForAI,
	getPlaylistStat,
} from '../../api/services/metricsService';
import { ERRORS, ErrorLevel } from '../../helpers/errors';
import { createUploads } from '../../helpers/uploadTools';
import { computeApiPlaylistAccessControl } from '../../helpers/playlistTools';
import { generateAIAnalysis } from '../../api/services/aiAnalysisService';
import { pollPlaylistUpdates } from '../../api/services/filesService';
import { groupUploadsByProjectId } from '../files/selectors';
import { PROJECT_LIMIT_MODAL } from '../../constants/modalTypes';
import { AppDispatch, GetState } from '..';
import { EditPlaylistDetailsForm } from '../../components/screens/Playlists/PlaylistDetails/EditPlaylistDetailsModal/EditPlaylistDetailsModal';
import PlaylistAccessControlType from '../../constants/playlistAccessControl';
import emptyAlbum from '../../constants/album.json';
import {
	createFullProfileFromCreditRequest,
	createProfileFromFullProfile,
} from '../../helpers/profileTools';
import { importLocalProfilesIntoRecordingAction } from '../profiles/actions';
import { claimActiveEditor } from '../../api/services/editorService';
import { getAlbumRecordingIds } from '../../helpers/albumTools';
import {
	IncludeInAlbumForm,
	PlaylistUploadFileForm,
} from '../../helpers/playlistUploadTools';

export const startPlaylistRequestAction = (requestLabel?: string | null) => ({
	type: START_PLAYLIST_REQUEST,
	requestLabel,
});

export const playlistRequestSuccessAction = () => ({
	type: PLAYLIST_REQUEST_SUCCESS,
});

export const playlistRequestErrorAction = (errorMessage: string) => ({
	type: PLAYLIST_REQUEST_ERROR,
	errorMessage,
});

export const playlistNotFoundAction = () => async (dispatch: AppDispatch) => {
	await Promise.resolve(dispatch(fetchUserPlaylistsAction()));
	dispatch(setCurrentPlaylistAction(null));
};

export const playlistRequestAuthErrorAction = () => (dispatch: AppDispatch) => {
	dispatch(invalidTokenAction());

	return {
		type: PLAYLIST_REQUEST_AUTH_ERROR,
	};
};

export const handlePlaylistError = (
	error: any,
	dispatch: AppDispatch,
	errorObj: {
		[key: number | string]: { message: string; level: ErrorLevel };
	}
) => {
	if (error.response) {
		let errorMessage =
			errorObj?.GENERIC?.message ||
			'Hiccup detected while syncing playlists with the cloud. Please try again.';
		let level = errorObj?.GENERIC?.level || ErrorLevel.ERROR;

		switch (error.response.status) {
			case 401:
				dispatch(playlistRequestAuthErrorAction());
				break;
			case 404:
				//
				return dispatch(playlistNotFoundAction());
			case 402:
				dispatch(getProjectUsageAction()); // update project and storage usage in case front-end is out of sync
				dispatch(getStorageUsageAction());
				errorMessage =
					errorObj?.[402]?.message || ERRORS.PLAYLISTS.GENERAL[402].message;
				level = errorObj?.[402]?.level || ErrorLevel.WARNING;
			// intentional fallthrough
			case 403:
				errorMessage =
					errorObj?.[403]?.message || ERRORS.PLAYLISTS.GENERAL[403].message;
				level = errorObj?.[403]?.level || ErrorLevel.WARNING;
			// intentional fallthrough
			default:
				dispatch(
					showAlertToast(`${errorMessage} (${error.response.status})`, level)
				);
				dispatch(playlistRequestErrorAction(errorMessage));
		}
	} else {
		throw error;
	}
};

export const setCurrentPlaylistAction = (
	playlistId: Playlist['id'] | null
) => ({
	type: SET_CURRENT_PLAYLIST_ID,
	id: playlistId,
});

export const handleUploadFromPlaylistAction =
	({
		playlistFilesMetadata,
		inputFiles,
		playlistId,
		includeInAlbumData,
	}: {
		playlistFilesMetadata: PlaylistUploadFileForm[];
		inputFiles: File[];
		playlistId: Playlist['id'];
		includeInAlbumData?: IncludeInAlbumForm;
	}) =>
	async (dispatch: AppDispatch, getState: GetState) => {
		// if there are multiple files associated with the same project, then we only want to create one project
		// so we group associatedProjects by projectType, title and artist

		try {
			// create a deep clone as we don't want to mutate the original objects
			// console.log('PREV METADATA:', playlistFilesMetadata);
			const newProjects = cloneDeep(
				uniqWith(
					playlistFilesMetadata.filter(f => !f.isLinkedToProject),
					(a, b) =>
						a.projectType === b.projectType &&
						a.projectTitle === b.projectTitle &&
						a.projectArtist === b.projectArtist
				)
			);

			// create new projects and store their ids
			const results = await Promise.all(
				newProjects.map(async f => {
					try {
						if (f.projectType === PROJECT_TYPES.RECORDING) {
							const newRecording = {
								...recordingSchema,
								title: f.projectTitle,
								mainArtist: f.projectArtist,
							};

							await dispatch(
								createCloudRecordingAction(newRecording, id => {
									f.associatedProjectId = {
										recordingId: id,
										albumId: null,
									};
								})
							);
						} else if (f.projectType === PROJECT_TYPES.ALBUM) {
							const newAlbum = {
								...albumSchema,
								title: f.projectTitle,
								artistName: f.projectArtist,
							};

							await dispatch(
								createCloudAlbumAction(newAlbum, id => {
									f.associatedProjectId = {
										recordingId: null,
										albumId: id,
									};
								})
							);
						}
					} catch (error) {
						return {
							error,
							file: f,
						};
					}
				})
			);

			const errors = results.filter(
				(r): r is { error: any; file: PlaylistUploadFileForm } => !!r?.error
			) as { error: any; file?: PlaylistUploadFileForm }[];
			let validNewProjects = newProjects;
			let validPlaylistFilesMetadata = playlistFilesMetadata;
			let validInputFiles = inputFiles;

			if (errors.length) {
				// and redefine playlistFilesMetadata and inputFiles to filter out failed projects
				// by comparing associatedProjectId with playlistFilesMetadata and then using the index
				const failedIndexes = playlistFilesMetadata.reduce((acc, f, i) => {
					if (
						errors.some(e =>
							_.isEqual(e.file?.associatedProjectId, f.associatedProjectId)
						)
					) {
						acc.push(i);
					}

					return acc;
				}, [] as number[]);

				//first, remove all failed projects from newProjects
				validNewProjects = newProjects.filter(
					f =>
						!errors.some(e =>
							_.isEqual(e.file?.associatedProjectId, f.associatedProjectId)
						)
				);
				validPlaylistFilesMetadata = playlistFilesMetadata.filter(
					(_, i) => !failedIndexes.includes(i)
				);

				validInputFiles = inputFiles.filter(
					(_, i) => !failedIndexes.includes(i)
				);
			}

			const existingProjects = validPlaylistFilesMetadata.filter(
				f => f.isLinkedToProject
			);

			// we only need to fetch files for existing projects
			// since new projects will have no files

			const projectFilesToFetch = uniqWith(
				existingProjects.map(f => f.associatedProjectId),
				(a, b) => {
					// if either one's got a recordingId, then they should match by recordingId
					if (a?.recordingId || b?.recordingId) {
						return a?.recordingId === b?.recordingId;
					}

					return a?.albumId === b?.albumId;
				}
			) as { recordingId: number | null; albumId: number | null }[];

			await Promise.resolve(
				Promise.all([
					...projectFilesToFetch.map(({ recordingId, albumId }) =>
						dispatch(getProjectFilesMetadataAction({ recordingId, albumId }))
					), // update filesByProjectId as computing new file names will need this to check for duplicates
				])
			);

			// now we need to match inputFiles to associatedProjects,
			// taking into account that the new project IDs are stored in newProjects

			const allProjectIds = validPlaylistFilesMetadata.map(f => {
				let associatedProjectId;

				if (f.isLinkedToProject) {
					associatedProjectId = f.associatedProjectId;
				} else {
					const newProject = validNewProjects.find(
						np =>
							np.projectType === f.projectType &&
							np.projectTitle === f.projectTitle &&
							np.projectArtist === f.projectArtist
					);

					associatedProjectId = newProject?.associatedProjectId;
				}

				return {
					recordingId: (associatedProjectId?.recordingId as number) || null,
					albumId: (associatedProjectId?.albumId as number) || null,
				};
			});

			// console.log(JSON.stringify(allProjectIds, null, 2));
			// console.log(
			// 	validInputFiles.map((f, i) => ({
			// 		filename: f.name,
			// 		recordingId: allProjectIds[i].recordingId,
			// 		albumId: allProjectIds[i].albumId,
			// 	}))
			// );

			const filenames = computeFilenames(
				validInputFiles.map((f, i) => ({
					filename: f.name,
					recordingId: allProjectIds[i].recordingId,
					albumId: allProjectIds[i].albumId,
				})),
				getState().files.filesByProjectId, // refresh filesByProjectId
				groupUploadsByProjectId(getState())
			);

			const playlistDisplayNames = validPlaylistFilesMetadata.map(f =>
				f.useDifferentDisplayName ? f.displayName : ''
			);

			const uploads = await createUploads({
				inputFiles: inputFiles.map((f, index) => {
					// can't use spread operator with file objects as their properties are not enumerable
					const fileWithLabel: FileWithLabel = f;
					fileWithLabel.label = validPlaylistFilesMetadata[index].label;

					return fileWithLabel;
				}),
				filenames,
				playlistId,
				projectIds: allProjectIds,
				playlistDisplayNames,
			});

			// Add files to upload queue in Redux
			// The FileUploadMenu component will pick up the files from the queue and upload them
			dispatch(addToUploadQueueAction(uploads));

			// Add upload IDs to playlist for cross-referencing
			dispatch(addUploadsToPlaylistAction(playlistId, Object.keys(uploads)));

			// Include recording projects in album if indicated
			if (includeInAlbumData) {
				debugger;
				const { isExistingAlbum, album, albumArtist, albumTitle } =
					includeInAlbumData;

				const referencedRecordingIds = uniq(
					allProjectIds.map(p => p.recordingId).filter(id => !!id)
				) as number[];

				// if the album exists, we simply need to add all the recording projects to it
				if (isExistingAlbum && album) {
					const { albumsById } = getState().projects;
					const fullAlbum = albumsById?.[album.albumId];

					if (!fullAlbum)
						throw new Error(`Album with ID ${album.albumId} not found`);

					const albumRecordingIds = getAlbumRecordingIds(fullAlbum);
					// first we need to filter out any recordings that are already in the album
					const recordingsNotInAlbum = referencedRecordingIds.filter(
						recId => !albumRecordingIds.includes(recId)
					);

					if (recordingsNotInAlbum.length) {
						await dispatch(
							addRecordingsToCloudAlbumAction(
								album.albumId,
								recordingsNotInAlbum
							)
						);
					}
				} else {
					// we need to create a new album, and check for 402 errors
					try {
						await dispatch(
							createCloudAlbumWithRecordingsAction({
								album: {
									...emptyAlbum,
									title: albumTitle,
									artistName: albumArtist,
								},
								addRecordingIdsToAlbum: referencedRecordingIds,
							})
						);
					} catch (e: any) {
						if (e.response?.status === 402) {
							console.warn('402 error detected in album creation');

							errors.push({
								error: e,
							});
						} else {
							throw e;
						}
					}
				}
			}

			await dispatch(fetchUserEditableProjectsAction());

			// if there are any 402 errors, we show the user a modal with the option to upgrade
			const has402Errors = errors.some(e => e.error.response?.status === 402);

			Promise.resolve(dispatch(hideModal())).then(() => {
				if (has402Errors) {
					console.warn('402 errors detected');
					dispatch(
						showModalAction(PROJECT_LIMIT_MODAL, {
							size: 'md',
						})
					);
				}
			});
		} catch (e) {
			console.error(e);

			// show error only if alert is not already showing
			if (!getState().alertToast.message) {
				dispatch(
					showErrorAlert(
						'Whoops! Hiccup detected while uploading files to the playlist. Please try again.'
					)
				);
			}

			dispatch(hideModal());
		}
	};

export const addUploadsToPlaylistAction = (
	playlistId: Playlist['id'],
	uploadIds: string[]
) => ({
	type: ADD_UPLOADS_TO_PLAYLIST,
	playlistId,
	uploadIds,
});

export const setUserPlaylistsAction = (
	playlists: Record<number, Playlist>
) => ({
	// used for reduced playlists (user playlists endpoint)
	type: SET_USER_PLAYLISTS,
	playlists,
});

export const setUserPlaylistStatsAction = (playlistStats: any) => ({
	// used for reduced playlist stats (user playlists endpoint)
	type: SET_USER_PLAYLIST_STATS,
	playlistStats,
});

export const setFullPlaylistsAction = (playlists: Playlist[]) => ({
	// used for full playlists (playlist endpoint)
	type: SET_FULL_PLAYLISTS,
	playlists,
});

export const setFullPlaylistStatsAction = (playlistStats: any) => ({
	// used for full playlist stats (playlist stats endpoint)
	type: SET_FULL_PLAYLIST_STATS,
	playlistStats,
});

export const setFullDripStatsAction = (dripStats: any) => ({
	// used for full playlist stats (playlist stats endpoint)
	type: SET_FULL_DRIP_STATS,
	dripStats,
});

export const addFilesToPlaylistAction =
	(playlistId: Playlist['id'], files: Partial<FileMetadata>[]) =>
	async (dispatch: AppDispatch, getState: GetState) => {
		const { playlistsById } = getState().playlists;

		const playlist = playlistsById?.[playlistId];

		if (!playlist?.playlist) {
			throw new Error('playlist not found');
		}

		const newFiles = files;
		const currentFiles = playlist.playlist.files || [];

		await dispatch(
			updatePlaylistAction({
				...playlist.playlist,
				files: [...newFiles, ...currentFiles],
			})
		);
	};

export const fetchUserPlaylistsAction = () => (dispatch: AppDispatch) => {
	dispatch(startPlaylistRequestAction(FETCH_USER_PLAYLISTS_REQUEST));

	return new Promise<void>(resolve =>
		getUserPlaylists()
			.then(playlists => {
				dispatch(setUserPlaylistsAction(playlists));
				dispatch(playlistRequestSuccessAction());
				resolve();
			})
			.catch(error => {
				return handlePlaylistError(
					error,
					dispatch,
					ERRORS.PLAYLISTS.GET_USER_PLAYLISTS
				);
			})
	);
};

export const fetchPlaylistAction =
	(playlistId: Playlist['id']) => (dispatch: AppDispatch) => {
		dispatch(startPlaylistRequestAction(FETCH_PLAYLIST_REQUEST));

		return getPlaylist(playlistId)
			.then(playlist => {
				dispatch(playlistRequestSuccessAction());
				return dispatch(setFullPlaylistsAction([playlist]));
			})
			.catch(error => {
				return handlePlaylistError(
					error,
					dispatch,
					ERRORS.PLAYLISTS.GET_PLAYLIST
				);
			});
	};

export const fetchPlaylistStatAction =
	(playlistId: Playlist['id']) => (dispatch: AppDispatch) => {
		dispatch(startPlaylistRequestAction(FETCH_PLAYLIST_STAT_REQUEST));

		return getPlaylistStat(playlistId)
			.then(playlistStat => {
				dispatch(setFullPlaylistStatsAction([playlistStat]));
				dispatch(playlistRequestSuccessAction());
			})
			.catch(error => {
				return handlePlaylistError(
					error,
					dispatch,
					ERRORS.PLAYLISTS.GET_PLAYLIST
				);
			});
	};

export const fetchDripStatAction =
	(playlistId: Playlist['id']) => (dispatch: AppDispatch) => {
		dispatch(startPlaylistRequestAction(FETCH_PLAYLIST_STAT_REQUEST));

		return getDripStat(playlistId)
			.then(dripStats => {
				dispatch(setFullDripStatsAction([dripStats]));
				dispatch(playlistRequestSuccessAction());
			})
			.catch(error => {
				return handlePlaylistError(
					error,
					dispatch,
					ERRORS.PLAYLISTS.GET_PLAYLIST
				);
			});
	};

export const createPlaylistAction =
	(
		playlist: CreatePlaylistForm,
		onCreate?: (playlistId: Playlist['id']) => Promise<any> | void
	) =>
	(dispatch: AppDispatch) => {
		dispatch(startPlaylistRequestAction(CREATE_PLAYLIST_REQUEST));

		return createPlaylist(playlist)
			.then(async newPlaylist => {
				dispatch(setFullPlaylistsAction([newPlaylist]));
				dispatch(playlistRequestSuccessAction());

				if (onCreate) {
					await onCreate(newPlaylist?.id);
				}
			})
			.catch(error => {
				return handlePlaylistError(
					error,
					dispatch,
					ERRORS.PLAYLISTS.CREATE_PLAYLIST
				);
			});
	};

export const updatePlaylistAction =
	(playlist: UpdatePlaylistForm) => (dispatch: AppDispatch) => {
		dispatch(startPlaylistRequestAction(UPDATE_PLAYLIST_REQUEST));

		return updatePlaylist(playlist)
			.then(updatedPlaylist => {
				dispatch(setFullPlaylistsAction([updatedPlaylist]));
				dispatch(playlistRequestSuccessAction());
			})
			.catch(error => {
				return handlePlaylistError(
					error,
					dispatch,
					ERRORS.PLAYLISTS.UPDATE_PLAYLIST
				);
			});
	};

export const deleteLocalPlaylistFileAction = (
	playlistId: Playlist['id'],
	fileId: FileMetadata['id']
) => ({
	type: DELETE_LOCAL_PLAYLIST_FILE,
	playlistId,
	fileId,
});

export const deletePlaylistFileAction =
	(playlistId: Playlist['id'], fileId: FileMetadata['id']) =>
	(dispatch: AppDispatch) => {
		dispatch(startPlaylistRequestAction(DELETE_PLAYLIST_FILE_REQUEST));

		return deletePlaylistFile(playlistId, fileId)
			.then(playlist => {
				dispatch(setFullPlaylistsAction([playlist]));
				dispatch(playlistRequestSuccessAction());

				dispatch(getUserProjectsAction());
			})
			.catch(error => {
				return handlePlaylistError(
					error,
					dispatch,
					ERRORS.PLAYLISTS.DELETE_PLAYLIST_FILE
				);
			});
	};

export const deleteLocalPlaylistAction = (id: Playlist['id']) => ({
	type: DELETE_LOCAL_PLAYLIST,
	id,
});

export const deletePlaylistAction =
	(playlistId: Playlist['id']) => (dispatch: AppDispatch) => {
		dispatch(startPlaylistRequestAction(DELETE_PLAYLIST_REQUEST));

		return deletePlaylist(playlistId)
			.then(() => {
				dispatch(playlistRequestSuccessAction());
				dispatch(deleteLocalPlaylistAction(playlistId));

				return dispatch(getUserProjectsAction());
			})
			.catch(error => {
				return handlePlaylistError(
					error,
					dispatch,
					ERRORS.PLAYLISTS.DELETE_PLAYLIST
				);
			});
	};

export const editPlaylistDetailsAction =
	(playlistId: Playlist['id'], details: EditPlaylistDetailsForm) =>
	(dispatch: AppDispatch, getState: GetState) => {
		const { playlistsById } = getState().playlists;

		const playlist = playlistsById?.[playlistId];

		if (!playlist?.playlist) {
			throw new Error('Playlist not found');
		}

		return dispatch(
			updatePlaylistAction({
				...playlist.playlist,
				...details,
			})
		);
	};

export const setPlaylistAccessControlAction =
	({
		playlistId,
		accessControlType,
		password,
		allowDownloads,
		expandVersions,
		hideTrackchatLinks,
		enableCreditRequests,
	}: {
		playlistId: Playlist['id'];
		accessControlType: PlaylistAccessControlType;
		password?: string | null;
		allowDownloads: boolean;
		expandVersions: boolean;
		hideTrackchatLinks: boolean;
		enableCreditRequests: boolean;
	}) =>
	(dispatch: AppDispatch, getState: GetState) => {
		const { playlistsById } = getState().playlists;

		const playlist = playlistsById?.[playlistId];

		if (!playlist?.playlist) {
			throw new Error('Playlist not found');
		}

		const accessControl = computeApiPlaylistAccessControl(
			accessControlType,
			password
		);

		return dispatch(
			updatePlaylistAction({
				...playlist?.playlist,
				...accessControl,
				allowDownloads,
				expandVersions,
				hideTrackchatLinks,
				enableCreditRequests,
			})
		);
	};

/**
 * Previously used to change a file's "displayName", which is now called "Note" in the UI.
 */
export const changePlaylistNoteAction =
	(playlistId: Playlist['id'], fileId: FileMetadata['id'], newNote: string) =>
	async (dispatch: AppDispatch) => {
		return dispatch(
			changePlaylistFilenamesAction(playlistId, [
				{ id: fileId, displayName: newNote },
			])
		);
	};

export const changePlaylistFilenamesAction =
	(
		playlistId: Playlist['id'],
		files: { id: number; displayName: string | null }[]
	) =>
	(dispatch: AppDispatch) => {
		dispatch(startPlaylistRequestAction(CHANGE_PLAYLIST_FILE_NAMES_REQUEST));

		return changePlaylistFilenames({ id: playlistId, files })
			.then(playlist => {
				dispatch(setFullPlaylistsAction([playlist]));
				dispatch(playlistRequestSuccessAction());
			})
			.catch(error => {
				return handlePlaylistError(
					error,
					dispatch,
					ERRORS.PLAYLISTS.CHANGE_PLAYLIST_FILE_NAMES
				);
			});
	};

export const deleteUploadsFromPlaylistAction = (
	playlistId: Playlist['id'],
	uploadIds: string[]
) => ({
	type: DELETE_UPLOADS_FROM_PLAYLIST,
	playlistId,
	uploadIds,
});

export const reorderPlaylistFilesAction =
	(playlistId: Playlist['id'], files: FileMetadata[]) =>
	(dispatch: AppDispatch) => {
		dispatch(startPlaylistRequestAction(REORDER_PLAYLIST_FILES_REQUEST));

		return reorderPlaylistFiles({ playlistId, files })
			.then(playlist => {
				dispatch(setFullPlaylistsAction([playlist]));
				dispatch(playlistRequestSuccessAction());
			})
			.catch(error => {
				return handlePlaylistError(
					error,
					dispatch,
					ERRORS.PLAYLISTS.REORDER_PLAYLIST_FILES
				);
			});
	};

export const addPlaylistCoverImageAction =
	(playlistId: Playlist['id'], coverImagePath: string | null) =>
	(dispatch: AppDispatch) => {
		dispatch(startPlaylistRequestAction(ADD_PLAYLIST_COVER_IMAGE_REQUEST));

		return addPlaylistCoverImage({ playlistId, coverImagePath })
			.then(newPlaylist => {
				dispatch(playlistRequestSuccessAction());

				return dispatch(setFullPlaylistsAction([newPlaylist]));
			})
			.catch(error => {
				return handlePlaylistError(
					error,
					dispatch,
					ERRORS.PLAYLISTS.ADD_PLAYLIST_COVER_IMAGE
				);
			});
	};

export const addPlaylistHeaderImageAction =
	(playlistId: Playlist['id'], headerImagePath: string | null) =>
	(dispatch: AppDispatch) => {
		dispatch(startPlaylistRequestAction(ADD_PLAYLIST_HEADER_IMAGE_REQUEST));

		return addPlaylistHeaderImage({ playlistId, headerImagePath })
			.then(newPlaylist => {
				dispatch(playlistRequestSuccessAction());

				return dispatch(setFullPlaylistsAction([newPlaylist]));
			})
			.catch(error => {
				return handlePlaylistError(
					error,
					dispatch,
					ERRORS.PLAYLISTS.ADD_PLAYLIST_HEADER_IMAGE
				);
			});
	};

/**
 * Uploads playlist cover image to the S3 bucket
 * and upload metadata to the API
 * @param {number} playlistId - id of the playlist to upload cover image for
 * @param {File} image - image file to upload
 */
export const uploadPlaylistImageAction =
	(playlistId: Playlist['id'], image: File, isHeader: boolean) =>
	async (dispatch: AppDispatch, getState: GetState) => {
		const userId = getState().auth.userId!;
		const path = await generateCoverImagePath({
			image,
			playlistId,
			userId,
			isHeader,
		});

		const imageObj = {
			file: image,
			metadata: {
				playlistId,
				path,
			},
		};

		try {
			await dispatch(uploadImagesToBucketWithCredentialsAction([imageObj]));

			if (isHeader) {
				await dispatch(addPlaylistHeaderImageAction(playlistId, path));
				return;
			}

			await dispatch(addPlaylistCoverImageAction(playlistId, path));
		} catch (error) {}
	};

export const publishDripAction =
	(playlistId: Playlist['id'], ticketCount: number) =>
	(dispatch: AppDispatch) => {
		dispatch(startPlaylistRequestAction(PUBLISH_DRIP_REQUEST));

		return publishDrip({ playlistId, ticketCount })
			.then(playlist => {
				dispatch(setFullPlaylistsAction([playlist]));
				dispatch(playlistRequestSuccessAction());
			})
			.catch(error => {
				return handlePlaylistError(
					error,
					dispatch,
					ERRORS.PLAYLISTS.PUBLISH_DRIP
				);
			});
	};

export const unpublishDripAction =
	(playlistId: Playlist['id']) => (dispatch: AppDispatch) => {
		dispatch(startPlaylistRequestAction(UNPUBLISH_DRIP_REQUEST));

		return unpublishDrip({ playlistId })
			.then(playlist => {
				dispatch(setFullPlaylistsAction([playlist]));
				dispatch(playlistRequestSuccessAction());
			})
			.catch(error => {
				return handlePlaylistError(
					error,
					dispatch,
					ERRORS.PLAYLISTS.UNPUBLISH_DRIP
				);
			});
	};

export const duplicatePlaylistAction =
	(playlistId: Playlist['id'], name: string) => (dispatch: AppDispatch) => {
		dispatch(startPlaylistRequestAction(DUPLICATE_PLAYLIST_REQUEST));

		return duplicatePlaylist({ playlistId, name })
			.then(playlist => {
				dispatch(setFullPlaylistsAction([playlist]));
				dispatch(playlistRequestSuccessAction());
			})
			.catch(error => {
				return handlePlaylistError(error, dispatch, ERRORS.PLAYLISTS.DUPLICATE);
			});
	};

export const generateDripStatsAIAnalysisAction =
	(playlistId: Playlist['id']) => (dispatch: AppDispatch) => {
		dispatch(
			startPlaylistRequestAction(GENERATE_DRIP_STATS_AI_ANALYSIS_REQUEST)
		);
		console.log('DRIP AI BASE URL', process.env.REACT_APP_AI_ANALYSIS_API);
		return getDripStatsForAI(playlistId)
			.then(statsRes => {
				// generate AI analysis
				return generateAIAnalysis({ stats: statsRes.data });
			})
			.then(analysis => {
				// save AI analysis
				return saveDripAIAnalysis({
					playlistId,
					analysis,
				});
			})
			.then(playlist => {
				dispatch(playlistRequestSuccessAction());
				dispatch(setFullPlaylistsAction([playlist]));
			})
			.catch(error => {
				return handlePlaylistError(
					error,
					dispatch,
					ERRORS.PLAYLISTS.GENERATE_DRIP_STATS_AI_ANALYSIS
				);
			});
	};

export const pollPlaylistUpdatesAction =
	(playlistId: Playlist['id']) =>
	(dispatch: AppDispatch, getState: GetState) => {
		const { playlistsById } = getState().playlists;
		const playlist = playlistsById?.[playlistId];

		if (!playlist?.playlist) {
			console.error(
				`Playlist with id ${playlistId} not found in playlistsById state`
			);
			return;
		}

		const { slug } = playlist.playlist;

		return pollPlaylistUpdates({
			playlistSlug: slug,
		})
			.then(updatedAt => {
				const latestPlaylistsById = getState().playlists.playlistsById;
				const latestPlaylist = latestPlaylistsById?.[playlistId];

				if (
					!latestPlaylist ||
					new Date(latestPlaylist.playlist!.updatedAt) < new Date(updatedAt)
				) {
					dispatch(fetchPlaylistAction(playlistId));
				}
			})
			.catch(error => {
				return handlePlaylistError(
					error,
					dispatch,
					ERRORS.PLAYLISTS.POLL_PLAYLIST_UPDATES
				);
			});
	};

export const acceptPlaylistEditorInviteAction =
	({ playlistId }: { playlistId: Playlist['id'] }) =>
	(dispatch: AppDispatch) => {
		dispatch(startPlaylistRequestAction(ACCEPT_PLAYLIST_EDITOR_INVITE_REQUEST));

		return acceptPlaylistEditorInvite({ playlistId })
			.then(async () => {
				dispatch(playlistRequestSuccessAction());
				// refetch user projects
				await dispatch(getUserProjectsAction());
				// refetch user playlists
				await dispatch(fetchUserPlaylistsAction());
			})
			.catch(error => {
				return handlePlaylistError(
					error,
					dispatch,
					ERRORS.PLAYLISTS.ACCEPT_PLAYLIST_EDITOR_INVITE
				);
			});
	};

export const setCurrentPlaylistEditorsAction = (
	playlistId: Playlist['id'],
	editors: PlaylistEditor[]
) => ({
	type: SET_CURRENT_PLAYLIST_EDITORS,
	editors,
	playlistId,
});

export const fetchPlaylistEditorsAction =
	(playlistId: Playlist['id']) => (dispatch: AppDispatch) => {
		dispatch(startPlaylistRequestAction(FETCH_PLAYLIST_EDITORS_REQUEST));

		return getPlaylistEditors({ playlistId })
			.then(editors => {
				dispatch(playlistRequestSuccessAction());
				dispatch(setCurrentPlaylistEditorsAction(playlistId, editors));
			})
			.catch(error => {
				return handlePlaylistError(
					error,
					dispatch,
					ERRORS.PLAYLISTS.FETCH_PLAYLIST_EDITORS
				);
			});
	};

export const addPlaylistEditorAction =
	({
		playlistId,
		email,
		message,
	}: {
		playlistId: Playlist['id'];
		email: string;
		message: string;
	}) =>
	(dispatch: AppDispatch) => {
		dispatch(startPlaylistRequestAction(ADD_PLAYLIST_EDITOR_REQUEST));

		return addNewPlaylistEditor({ playlistId, email, message })
			.then(editors => {
				dispatch(playlistRequestSuccessAction());
				dispatch(setCurrentPlaylistEditorsAction(playlistId, editors));
			})
			.catch(error => {
				return handlePlaylistError(
					error,
					dispatch,
					ERRORS.PLAYLISTS.ADD_PLAYLIST_EDITOR
				);
			});
	};

export const removePlaylistEditorAction =
	({
		playlistId,
		editorId = null,
		editorEmail = null,
	}: {
		playlistId: Playlist['id'];
		editorId?: number | null;
		editorEmail?: string | null;
	}) =>
	(dispatch: AppDispatch, getState: GetState) => {
		if (!editorId && !editorEmail) {
			throw new Error(
				'Must provide either editorId or editorEmail when removing Editor'
			);
		}

		dispatch(startPlaylistRequestAction(REMOVE_PLAYLIST_EDITOR_REQUEST));

		return removePlaylistEditor({ playlistId, editorId, editorEmail })
			.then(editors => {
				dispatch(playlistRequestSuccessAction());
				const userId = getState().auth.userId;

				if (userId === editorId) {
					return;
				}

				dispatch(setCurrentPlaylistEditorsAction(playlistId, editors));
			})
			.catch(error => {
				return handlePlaylistError(
					error,
					dispatch,
					ERRORS.PLAYLISTS.REMOVE_PLAYLIST_EDITOR
				);
			});
	};

export const clearPlaylistsAction = () => ({
	type: CLEAR_PLAYLISTS,
});

export const approveCreditRequestAction =
	({
		creditRequest,
		playlistId,
	}: {
		creditRequest: CreditRequest;
		playlistId: number;
	}) =>
	(dispatch: AppDispatch, getState: GetState) => {
		return approveCreditRequest({
			creditRequestId: creditRequest.id,
			playlistId,
		})
			.then(async () => {
				const fullProfile = createFullProfileFromCreditRequest(creditRequest);
				// we use the LocalProfile type in the import profiles into recording action,
				// so we convert it (it's a wrapper type with some extra fields that also accommodates to Creator ID profiles)
				const localProfile = createProfileFromFullProfile(fullProfile);

				const playlist = getState()!.playlists!.playlistsById![playlistId];

				if (!playlist) {
					throw new Error('Playlist not found');
				}

				const file = playlist!.playlist!.files.find(
					f => f.id === creditRequest.assetFileId
				);

				if (!file) {
					throw new Error('File not found');
				}

				const recordingId = file.recordingId!;

				const { userId } = getState().auth;

				await claimActiveEditor(userId!, recordingId);
				await dispatch(fetchRecordingByIdAction({ id: recordingId }));

				await dispatch(
					importLocalProfilesIntoRecordingAction(
						[localProfile],
						recordingId,
						false
					)
				);
			})
			.catch(error => {
				return handlePlaylistError(
					error,
					dispatch,
					ERRORS.PLAYLISTS.APPROVE_CREDIT_REQUEST
				);
			});
	};

export const setPlaylistCreditRequestCountAction = ({
	playlistId,
	requestCount,
}: {
	playlistId: Playlist['id'];
	requestCount: number;
}) => ({
	type: SET_PLAYLIST_CREDIT_REQUEST_COUNT,
	playlistId,
	requestCount,
});

export const createPlaylistFileGroupAction =
	({
		playlistId,
		fileIds,
		folderIds,
		name,
		parentGroupId = null,
	}: {
		playlistId: Playlist['id'];
		fileIds: FileMetadata['id'][];
		folderIds: PlaylistFolder['id'][];
		name: string;
		parentGroupId?: PlaylistFolder['id'] | null;
	}) =>
	(dispatch: AppDispatch) => {
		dispatch(startPlaylistRequestAction(CREATE_PLAYLIST_FILE_GROUP_REQUEST));

		return createPlaylistFileGroup({
			playlistId,
			fileIds,
			name,
			parentGroupId,
			folderIds,
		})
			.then(playlist => {
				dispatch(setFullPlaylistsAction([playlist]));
				dispatch(playlistRequestSuccessAction());
				return playlist;
			})
			.catch(error => {
				return handlePlaylistError(
					error,
					dispatch,
					ERRORS.PLAYLISTS.CREATE_PLAYLIST_FILE_GROUP
				);
			});
	};

export const deletePlaylistFileGroupAction =
	({
		playlistId,
		groupId,
	}: {
		playlistId: Playlist['id'];
		groupId: PlaylistFolder['id'];
	}) =>
	(dispatch: AppDispatch) => {
		dispatch(startPlaylistRequestAction(DELETE_PLAYLIST_FILE_GROUP_REQUEST));

		return deletePlaylistFileGroup({ playlistId, groupId })
			.then(playlist => {
				dispatch(setFullPlaylistsAction([playlist]));
				dispatch(playlistRequestSuccessAction());
			})
			.catch(error => {
				return handlePlaylistError(
					error,
					dispatch,
					ERRORS.PLAYLISTS.DELETE_PLAYLIST_FILE_GROUP
				);
			});
	};

export const renamePlaylistFileGroupAction =
	({
		playlistId,
		groupId,
		name,
	}: {
		playlistId: Playlist['id'];
		groupId: PlaylistFolder['id'];
		name: string;
	}) =>
	(dispatch: AppDispatch) => {
		dispatch(startPlaylistRequestAction(RENAME_PLAYLIST_FILE_GROUP_REQUEST));

		return updatePlaylistFileGroup({ playlistId, groupId, name })
			.then(playlist => {
				dispatch(setFullPlaylistsAction([playlist]));
				dispatch(playlistRequestSuccessAction());
			})
			.catch(error => {
				return handlePlaylistError(
					error,
					dispatch,
					ERRORS.PLAYLISTS.RENAME_PLAYLIST_FILE_GROUP
				);
			});
	};

export const reorderPlaylistFileGroupsAction =
	({
		playlistId,
		groupIds,
		parentGroupId,
	}: {
		playlistId: Playlist['id'];
		groupIds: PlaylistFolder['id'][];
		parentGroupId?: PlaylistFolder['id'] | null;
	}) =>
	(dispatch: AppDispatch) => {
		dispatch(startPlaylistRequestAction(REORDER_PLAYLIST_FILE_GROUPS_REQUEST));

		return reorderPlaylistFileGroups({ playlistId, groupIds, parentGroupId })
			.then(playlist => {
				dispatch(setFullPlaylistsAction([playlist]));
				dispatch(playlistRequestSuccessAction());
			})
			.catch(error => {
				return handlePlaylistError(
					error,
					dispatch,
					ERRORS.PLAYLISTS.REORDER_PLAYLIST_FILE_GROUPS
				);
			});
	};

export const updatePlaylistFileGroupFilesAction =
	({
		playlistId,
		groupId,
		fileIds,
	}: {
		playlistId: Playlist['id'];
		groupId: PlaylistFolder['id'];
		fileIds: FileMetadata['id'][];
	}) =>
	(dispatch: AppDispatch) => {
		dispatch(
			startPlaylistRequestAction(UPDATE_PLAYLIST_FILE_GROUP_FILES_REQUEST)
		);

		return updatePlaylistFileGroupFiles({ playlistId, groupId, fileIds })
			.then(playlist => {
				dispatch(setFullPlaylistsAction([playlist]));
				dispatch(playlistRequestSuccessAction());
			})
			.catch(error => {
				return handlePlaylistError(
					error,
					dispatch,
					ERRORS.PLAYLISTS.UPDATE_PLAYLIST_FILE_GROUP_FILES
				);
			});
	};

export const updatePlaylistSubGroupsAction =
	({
		playlistId,
		groupId,
		subGroupIds,
	}: {
		playlistId: Playlist['id'];
		groupId: PlaylistFolder['id'];
		subGroupIds: PlaylistFolder['id'][];
	}) =>
	(dispatch: AppDispatch) => {
		dispatch(startPlaylistRequestAction(UPDATE_PLAYLIST_SUB_GROUPS_REQUEST));

		return updatePlaylistSubGroups({ playlistId, groupId, subGroupIds })
			.then(playlist => {
				dispatch(setFullPlaylistsAction([playlist]));
				dispatch(playlistRequestSuccessAction());
			})
			.catch(error => {
				return handlePlaylistError(
					error,
					dispatch,
					ERRORS.PLAYLISTS.UPDATE_PLAYLIST_SUB_GROUPS
				);
			});
	};
