import React from 'react';
import Sidebar from '../layout/Sidebar';
import DownloadToast from '../layout/DownloadToast';
import Page from '../layout/Page/Page';
import FolderZipToast from '../layout/FolderZipToast';

type AuthenticatedLayoutProps = {
	children: React.ReactNode;
};

const AuthenticatedLayout = ({ children }: AuthenticatedLayoutProps) => {
	return (
		<>
			<FolderZipToast />
			<DownloadToast />
			<Sidebar />
			<Page>{children}</Page>
		</>
	);
};

export default AuthenticatedLayout;
