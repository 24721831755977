import PromoType from '../store/promos/promoType';
import { isDateExpired } from './dateTools';

export const isPlaylistJuly2023PromoActive = (
	activePromos: ActivePromos,
	timerExpirationDate: string | null | undefined
) =>
	activePromos[PromoType.PlaylistJuly2023] &&
	timerExpirationDate &&
	!isDateExpired(new Date(timerExpirationDate));
