import React from 'react';
import { Modal } from 'react-bootstrap';
import { useAppDispatch } from '../../../store/hooks';
import { hideModal } from '../../../store/modal/actions';
import Button from '../../layout/Button';



const ConfirmUpgradeModal = () => {
	const dispatch = useAppDispatch();

	return (
		<>
			<Modal.Body>
				<div className='confirm-upgrade-modal'></div>
			</Modal.Body>
			<Modal.Footer>
				<Button
					onClick={() => dispatch(hideModal())}
					className='mr-2'
					label='Cancel'
				/>

				<Button
					onClick={() => dispatch(hideModal())}
					className='mr-2'
					label='Confirm'
				/>
			</Modal.Footer>
		</>
	);
};

export default ConfirmUpgradeModal;
