import { ErrorMessage } from 'formik';
import React from 'react';
import { Form } from 'react-bootstrap';
import ReactSelect, { ActionMeta, OnChangeValue } from 'react-select';
import selectStyles from './selectStyles';
import theme from '../../../theme.module.scss';
import MenuList from '../MenuList';

export type SelectProps<OptionType = unknown> = {
	name: string;
	label?: string;
	id?: string;
	isMulti?: boolean;
	isOptionDisabled?: (option: OptionType) => boolean;
	onChange?: (
		newValue: OnChangeValue<OptionType, false>,
		actionMeta: ActionMeta<OptionType>
	) => void;
	onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
	value: any;
	options: any[];
	placeholder?: string | React.ReactNode;
	filterOption?: (option: OptionType, inputValue: string) => boolean;
	getOptionLabel?: (option: OptionType) => string;
	getOptionValue?: (option: OptionType) => string;
	errorFieldName?: string;
	isDisabled?: boolean;
	dense?: boolean;
	fullWidth?: boolean;
	className?: string;
	onInputChange?: (value: string) => void;
	closeMenuOnSelect?: boolean;
	defaultValue?: any;
	isSearchable?: boolean;
	noOptionsMessage?: (obj: any) => string;
	isClearable?: boolean;
	style?: React.CSSProperties;
	labelStyle?: React.CSSProperties;
	isLoading?: boolean;
};

const Select = <OptionType extends unknown>({
	name,
	label,
	id,
	isMulti,
	isOptionDisabled,
	onChange,
	onBlur,
	value,
	options,
	placeholder,
	filterOption,
	getOptionLabel,
	getOptionValue,
	errorFieldName,
	isDisabled = false,
	dense = false,
	fullWidth = false,
	className = '',
	onInputChange,
	closeMenuOnSelect,
	defaultValue,
	isSearchable,
	noOptionsMessage,
	isClearable = false,
	style = {},
	labelStyle = {},
	isLoading = false,
}: SelectProps<OptionType>) => {
	return (
		<Form.Group
			className={`${dense ? '' : 'mb-4'} ${
				fullWidth ? 'w-100' : ''
			} ${className}`}
			style={style}
		>
			{dense ? (
				<></>
			) : (
				<Form.Label htmlFor={name} style={labelStyle}>
					{label}
				</Form.Label>
			)}
			<ReactSelect<OptionType>
				isClearable={isClearable}
				components={{ MenuList }}
				isMulti={isMulti as any}
				styles={selectStyles as any}
				name={name}
				id={id}
				onChange={onChange}
				onBlur={onBlur}
				value={value}
				options={options}
				placeholder={placeholder}
				getOptionLabel={getOptionLabel}
				getOptionValue={getOptionValue}
				isDisabled={isDisabled}
				isOptionDisabled={isOptionDisabled}
				onInputChange={onInputChange}
				closeMenuOnSelect={closeMenuOnSelect}
				defaultValue={defaultValue}
				isSearchable={isSearchable}
				noOptionsMessage={noOptionsMessage}
				filterOption={filterOption as any}
				isLoading={isLoading}
			/>
			{errorFieldName && (
				<ErrorMessage name={errorFieldName}>
					{msg => {
						return (
							<small
								style={{ color: theme.error, height: 0, position: 'absolute' }}
								className='mt-1'
							>
								{msg}
							</small>
						);
					}}
				</ErrorMessage>
			)}
		</Form.Group>
	);
};

export default Select;
