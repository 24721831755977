import React from 'react';
import { useDispatch } from 'react-redux';
import { PLAYLISTS_RUNDOWN_MODAL } from '../../../constants/modalTypes';
import { hideModal, showModalAction } from '../../../store/modal/actions';
import PillButton from '../../layout/PillButton';
import './FreshInstallInfoModal.scss';
import { getAssetPath } from '@/helpers/assetTools';

const InvitationModal = () => {
	const dispatch = useDispatch();

	const handleButtonClick = () => {
		Promise.resolve(dispatch(hideModal())).then(() => {
			dispatch(
				showModalAction(PLAYLISTS_RUNDOWN_MODAL, {
					size: 'lg',
					removeHeaderPadding: true,
				})
			);
		});
	};

	return (
		<div className='fresh-install-modal__slide'>
			<h2>Invitation</h2>
			<p>
				Music creators should be fairly compensated for their hard work and
				creativity, but today's file-sharing services don't have systems in
				place to track and record metadata.
				<br />
				<br />
				Sound Credit is a platform used by over 15,000 labels, publishers,
				administrators and indies to support music creators and end the 20-year
				data crisis in our industry.
				<br />
				<br />
				By using this platform, starting with consistently using Playlists for
				transfers, we can make a positive impact and create opportunities for
				growth and success.
			</p>

			<div className='d-flex justify-content-end w-100'>
				<div className='fresh-install-modal__slide__signature'>
					<img
						src={getAssetPath('img/GW-Signature-(Transparent-PNG).png')}
						alt="Gebre Waddell's Signature"
					/>
					<div>
						<p className='fresh-install-modal__slide__signature__name'>
							Gebre Waddell
						</p>
						<p className='fresh-install-modal__slide__signature__title'>
							Founder & CEO
						</p>
						<p className='fresh-install-modal__slide__signature__company'>
							Sound Credit
						</p>
					</div>
				</div>
			</div>

			<div className='d-flex justify-content-center w-100 '>
				<PillButton
					theme='solid-light'
					label='Join the Movement'
					rightIcon='fas fa-chevron-right'
					className='px-3 py-2'
					onClick={handleButtonClick}
				/>
			</div>
		</div>
	);
};

export default InvitationModal;
