import React from 'react';
import { Card, Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { openUpgradeWebsite } from '../../../../helpers/tiersTools';
import Button from '../../../layout/Button';
import GeneratedGRidCodes from './GeneratedGRidCodes/GeneratedGRidCodes';
import GeneratedISRCCodes from './GeneratedISRCCodes';
import './ViewCodes.scss';
import { Helmet } from 'react-helmet';

const gridCodes = () => (
	<Card>
		<Card.Body>
			<h2>GENERATED GRid CODES</h2>
			<GeneratedGRidCodes />
		</Card.Body>
	</Card>
);

const isrcCodes = () => (
	<Card>
		<Card.Body>
			<h2>GENERATED ISRC CODES</h2>
			<GeneratedISRCCodes />
		</Card.Body>
	</Card>
);

const CannotGenerateCodes = () => (
	<div className='h-100 d-flex justify-content-center align-items-center'>
		<Card style={{ width: '40vw' }}>
			<Card.Body>
				<h2>GENERATED ISRC & GRID CODES</h2>
				<div
					style={{ height: '30vh' }}
					className='d-flex justify-content-center align-items-center flex-column'
				>
					<p className='fw-500 w-50' style={{ textAlign: 'center' }}>
						Your current subscription plan does not allow you to generate ISRC
						and GRid codes.
						<br /> <br />
						Start enjoying this feature and many more by upgrading your
						subscription plan!
					</p>
					<Button
						label='Upgrade Subscription'
						leftIcon='fas fa-angle-double-up'
						theme='dark'
						onClick={() => openUpgradeWebsite()}
					/>
				</div>
			</Card.Body>
		</Card>
	</div>
);

const ViewCodes = () => {
	const { userPermissions } = useSelector(state => state.auth);

	return (
		<>
			<Helmet>
				<title> Generated Codes {process.env.REACT_APP_TAB_TITLE}</title>
			</Helmet>
			<Container className='h-100'>
				{userPermissions.canGenerateCodes ? (
					<>
						<div className='my-3' />
						{gridCodes()}
						{isrcCodes()}
					</>
				) : (
					<CannotGenerateCodes />
				)}
			</Container>
		</>
	);
};

export default ViewCodes;
