import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Card, Col, Form, Row } from 'react-bootstrap';
import Select from '../../../../form/Select';
import TextField from '../../../../form/TextField';
import IconButton from '../../../../layout/IconButton';
import theme from '@/theme.module.scss';
import Button from '../../../../layout/Button';
import {
	previousExportStepAction,
	setUnionExportFinalizeFormAction,
} from '@/store/exports/actions';
import { FieldArray, FormikProvider, useFormik } from 'formik';
import paymentTypes from '@/constants/unionForm/paymentTypesB4.json';
import productionTypes from '@/constants/unionForm/productionTypes.json';
import _ from 'lodash';
import blankFinalizeB4Form from '@/constants/unionForm/finalizeB4Form.json';
import * as yup from 'yup';
import { useDebounceEffect } from '@/hooks/useDebounceEffect';
import CreatableSelect from '../../../../form/CreatableSelect';
import { createLocalProfileAction } from '@/store/profiles/actions';
import SessionTimesPicker from '../SessionTimesPicker';
import UnionContractNumber from '../UnionContractNumber/UnionContractNumber';
import { useNavigate } from 'react-router-dom';
import {
	hideAlertToast,
	showAlertToast,
	showErrorAlert,
} from '@/store/alertToast/actions';
import ROUTES from '@/router/routes';
import { showModalAction } from '@/store/modal/actions';
import { SELECT_REPRESENTATIVE_MODAL } from '@/constants/modalTypes';
import { createNewProfile } from '@/helpers/profileTools';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

const schema = yup.object().shape({
	contractNumber: yup
		.string()
		.matches(/^[a-zA-Z-./_\d]*\d{3,}$/, 'Invalid Contract Number')
		.required('Contract Number (RPNo) Is Required'),
	sessionTimes: yup.array().of(
		yup.object().shape({
			startTime: yup.string().required('Start Time Is Required'),
			endTime: yup.string().required('End Time Is Required'),
			recordingIds: yup
				.array()
				.min(1, 'Please Select At Least One Session Track'),
		})
	),
	paymentType: yup.string().required('Payment Type Is Required'),
	productionType: yup.string().required('Production Type Is Required'),
	sessionProducer: yup
		.object()
		.shape({
			label: yup.string(),
			value: yup.string(),
		})
		.nullable(),
	sessionProducerAddress: yup.string(),
	recordCompany: yup.string().required('Record Company Is Required'),
	recordCompanyAddress: yup
		.string()
		.required('Record Company Address Is Required'),
	recordCompanyRepPhone: yup
		.string()
		.required('Record Company Rep. Phone Is Required'),
	recordCompanyRepName: yup
		.string()
		.required('Record Company Rep. Name Is Required'),
});

export type FinalizeStepB4Props = {
	onFormSubmit: (values: Partial<UnionFormFinalizeForm>) => void;
};

const FinalizeStepB4 = ({ onFormSubmit }: FinalizeStepB4Props) => {
	/*
	 * Redux Hooks
	 */
	const dispatch = useAppDispatch();
	const { recordingsById } = useAppSelector(state => state.projects);
	const { finalizeForm, selectedRecordingRows } = useAppSelector(
		state => state.exports.unionForm
	);

	/*
	 * React Hooks
	 */
	const [sessionProducerOptions, setSessionProducerOptions] = useState<
		{ label: string; value: number }[]
	>([]);

	const selectedRecordingIds = useMemo(
		() => selectedRecordingRows!.map(row => row.recordingId),
		[selectedRecordingRows]
	);

	const sessionTracksOptions = useMemo(
		() =>
			selectedRecordingIds.map(recordingId => ({
				label: recordingsById![recordingId].recording?.title,
				value: recordingId,
			})),
		[selectedRecordingIds, recordingsById]
	);

	const setInitialValues = useRef(false);

	const initialValues = useMemo(() => {
		if (setInitialValues.current && finalizeForm) {
			return finalizeForm!;
		}

		return blankFinalizeB4Form;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setInitialValues.current, finalizeForm]);

	/*
	 * Formik Hooks
	 */
	const finalizeFormik = useFormik<Partial<UnionFormFinalizeForm>>({
		initialValues: initialValues,
		enableReinitialize: true,
		validationSchema: schema,
		onSubmit: values => {
			dispatch(setUnionExportFinalizeFormAction(values));
			onFormSubmit(values);
		},
	});

	/*
	 * React Router Hooks
	 */
	const navigate = useNavigate();

	/*
	 * Effects
	 */
	// Save form when values change
	useDebounceEffect(
		() => {
			if (finalizeFormik.dirty && !_.isEmpty(finalizeFormik.values)) {
				dispatch(setUnionExportFinalizeFormAction(finalizeFormik.values));
			}

			// const newProfile = {
			// 	...selectedProfile,
			// 	creditedName: finalizeFormik.values.recordCompanyRepName,
			// 	phone: finalizeFormik.values.recordCompanyRepPhone,
			// };

			// console.log(newProfile);
			// console.log(selectedProfileId);
			// if (selectedProfileId) {
			// 	console.log('SAVE EXPORT PROFILE');
			// 	dispatch(editProfile(newProfile));
			// 	editPersonalProfile(newProfile);
			// }
		},
		500,
		[finalizeFormik.values, dispatch]
	);

	// initialize form values
	useEffect(() => {
		if (_.isEmpty(finalizeForm)) {
			const initialForm: UnionFormFinalizeForm = { ...blankFinalizeB4Form };

			// force session tracks to contain only recording if only one recording is selected
			if (selectedRecordingRows!.length === 1) {
				initialForm.sessionTimes = [
					{
						...initialForm.sessionTimes[0],
						recordingIds: [selectedRecordingRows![0].recordingId],
					},
				];
			}

			dispatch(setUnionExportFinalizeFormAction(initialForm));
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [finalizeForm, dispatch]);

	useEffect(() => {
		const recordings = selectedRecordingIds.map(
			recordingId => recordingsById![recordingId].recording
		);

		// get unique producers from all selected recordings
		const producersById: Record<
			number,
			{
				label: string;
				value: number;
			}
		> = {};

		recordings
			.flatMap(recording => recording!.participants)
			.filter(participant =>
				participant.roles.some(role => role.detail === 'Producer')
			)
			.forEach(participant => {
				const name = participant.legalName
					? participant.legalName
					: participant.creditedName;

				producersById[participant.id] = {
					label: name,
					value: participant.id,
				};
			});

		// add custom producer in case it's not in the list
		if (
			finalizeFormik.values.sessionProducer &&
			!producersById[finalizeFormik.values.sessionProducer?.value]
		) {
			producersById[finalizeFormik.values.sessionProducer?.value] = {
				label: finalizeFormik.values.sessionProducer.label,
				value: finalizeFormik.values.sessionProducer.value,
			};
		}

		setSessionProducerOptions([...Object.values(producersById)]);
	}, [selectedRecordingIds, recordingsById, finalizeFormik.values]);

	useEffect(() => {
		if (!setInitialValues.current && !_.isEmpty(finalizeForm)) {
			setInitialValues.current = true;
		}
	}, [finalizeForm]);

	/*
	 * Callbacks
	 */
	const navigateToPrevStep = () => {
		dispatch(setUnionExportFinalizeFormAction(finalizeFormik.values));
		dispatch(previousExportStepAction());
	};

	const handleSaveForLater = () => {
		dispatch(setUnionExportFinalizeFormAction(finalizeFormik.values));
		navigate(ROUTES.Editor.path, { replace: true });
	};

	const saveRepresentativeProfile = () => {
		const newProfile = createNewProfile({
			creditedName: finalizeFormik.values.recordCompanyRepName,
			phone: finalizeFormik.values.recordCompanyRepPhone,
			address1: finalizeFormik.values.recordCompanyAddress,
			otherAliases: finalizeFormik.values.recordCompany,
		});

		console.log('SAVING REPRESENTATIVE PROFILE', newProfile);

		dispatch(showAlertToast('Saving Profile...', 'editor'));
		dispatch(
			createLocalProfileAction(
				newProfile,
				() => {
					dispatch(showAlertToast('Profile Saved', 'editor'));

					setTimeout(() => {
						dispatch(hideAlertToast());
					}, 3000);
				},
				() =>
					dispatch(
						showErrorAlert(
							"Whoops! Something went awry while saving your profile. Let's try that again and get you back rockin'."
						)
					)
			)
		);
	};

	return (
		<FormikProvider value={finalizeFormik}>
			<Form onSubmit={finalizeFormik.handleSubmit}>
				<>
					<Card>
						<Card.Body>
							<h2>CONTRACT NUMBER & RECORD COMPANY INFORMATION</h2>
							<p className='body-text'>
								Contact your union to get a prefix and begin entering RPNos.
							</p>

							<Row>
								<Col xs={8}>
									<UnionContractNumber finalizeFormik={finalizeFormik} />
								</Col>
							</Row>

							<div className='my-3' />

							<FieldArray
								name='sessionTimes'
								render={arrayHelpers => (
									<div>
										{finalizeFormik.values.sessionTimes &&
											finalizeFormik.values.sessionTimes.map(
												(sessionTime, index) => (
													<Row key={index} className='justify-content-between'>
														<SessionTimesPicker
															formik={finalizeFormik}
															index={index}
															sessionTime={sessionTime}
															errorMessage='Session Time Is Required'
														/>
														{selectedRecordingRows!.length > 1 && (
															<Col xs={5}>
																<Select
																	label='Session Tracks'
																	name={`sessionTimes[${index}].recordingIds`}
																	id={`sessionTimes[${index}].recordingIds`}
																	isMulti
																	options={sessionTracksOptions}
																	value={sessionTracksOptions.filter(option =>
																		finalizeFormik.values.sessionTimes![
																			index
																		].recordingIds.includes(option.value)
																	)}
																	onChange={(selections: any) => {
																		// TODO: Verify type
																		finalizeFormik.setFieldValue(
																			`sessionTimes[${index}].recordingIds`,
																			selections.map(
																				(selection: any) => selection.value
																			)
																		);
																	}}
																	onBlur={finalizeFormik.handleBlur}
																	errorFieldName={`sessionTimes[${index}].recordingIds`}
																/>
															</Col>
														)}

														<Col
															xs={1}
															className='d-flex align-items-center justify-content-start'
														>
															<IconButton
																icon='fas fa-plus-circle'
																color={theme.primary}
																onClick={() => {
																	arrayHelpers.push({
																		startTime: '',
																		endTime: '',
																		recordingIds:
																			selectedRecordingRows!.length === 1
																				? [
																						selectedRecordingRows![0]
																							.recordingId,
																				  ]
																				: [],
																	});
																}}
															/>
															{(index !== 0 ||
																finalizeFormik.values.sessionTimes!.length >
																	1) && (
																<IconButton
																	icon='fas fa-trash'
																	color={theme.error}
																	onClick={() => arrayHelpers.remove(index)}
																/>
															)}
														</Col>
													</Row>
												)
											)}
									</div>
								)}
							/>

							<hr />

							<h2>PRODUCER INFORMATION</h2>

							<div className='my-3' />

							<Row>
								<Col xs={6}>
									<CreatableSelect
										label={
											<>
												Session Producer{' '}
												<i className='optional-italic-blue-label'>(optional)</i>
											</>
										}
										name='sessionProducer'
										options={sessionProducerOptions}
										value={
											sessionProducerOptions.find(
												(
													option: any // TODO: Verify type
												) =>
													option.value ===
													finalizeFormik.values.sessionProducer?.value
											) || ''
										}
										onChange={option => {
											finalizeFormik.setFieldValue('sessionProducer', option);
										}}
										onCreateOption={newOption => {
											const newProducer = {
												value: newOption,
												label: newOption,
											};

											finalizeFormik.setFieldValue(
												'sessionProducer',
												newProducer
											);
										}}
										errorFieldName='sessionProducer'
									/>
								</Col>

								<Col xs={6}>
									<TextField
										label={
											<>
												Producer Address{' '}
												<i className='optional-italic-blue-label'>(optional)</i>
											</>
										}
										name='sessionProducerAddress'
										value={finalizeFormik.values.sessionProducerAddress!}
										onChange={finalizeFormik.handleChange}
										onBlur={finalizeFormik.handleBlur}
									/>
								</Col>
							</Row>

							<hr />

							<h2>PRODUCTION INFORMATION</h2>

							<div className='my-3' />

							<Row>
								<Col xs={6}>
									<Select
										label='Production Type'
										name='productionType'
										options={productionTypes}
										value={productionTypes.find(
											p => p.value === finalizeFormik.values.productionType
										)}
										onChange={(selection: any) => {
											// TODO: Verify type
											finalizeFormik.setFieldValue(
												'productionType',
												selection.value
											);
										}}
										onBlur={finalizeFormik.handleBlur}
										errorFieldName='productionType'
									/>
								</Col>

								<Col xs={6}>
									<Select
										label='Payment Type'
										name='paymentType'
										options={paymentTypes}
										value={paymentTypes.find(
											p => p.value === finalizeFormik.values.paymentType
										)}
										onChange={(selection: any) => {
											// TODO: Verify type
											finalizeFormik.setFieldValue(
												'paymentType',
												selection.value
											);
										}}
										onBlur={finalizeFormik.handleBlur}
										errorFieldName='paymentType'
									/>
								</Col>
							</Row>

							<hr />

							<Row>
								<Col xs={12} md={4}>
									<h2>REPRESENTATIVE PROFILES</h2>
								</Col>
								<Col xs={12} md={8} className='d-flex justify-content-end'>
									<Button
										label='Import from Saved Profiles'
										className='ml-3'
										onClick={() =>
											dispatch(
												showModalAction(SELECT_REPRESENTATIVE_MODAL, {
													formik: finalizeFormik,
												})
											)
										}
										leftIcon='fas fa-save'
									/>
									<Button
										label='Save as New Profile'
										className='ml-3'
										onClick={saveRepresentativeProfile}
										leftIcon='fas fa-file-import'
									/>
								</Col>
							</Row>

							<div className='my-3' />
							<div className='my-3' />

							<Row>
								<Col xs={6}>
									<TextField
										label='Record Company'
										name='recordCompany'
										value={finalizeFormik.values.recordCompany!}
										onChange={finalizeFormik.handleChange}
										onBlur={finalizeFormik.handleBlur}
										isInvalid={
											finalizeFormik.touched.recordCompany &&
											!!finalizeFormik.errors.recordCompany
										}
										errorMessage={finalizeFormik.errors.recordCompany}
									/>
								</Col>

								<Col xs={6}>
									<TextField
										label='Record Company Address'
										name='recordCompanyAddress'
										value={finalizeFormik.values.recordCompanyAddress!}
										onChange={finalizeFormik.handleChange}
										onBlur={finalizeFormik.handleBlur}
										isInvalid={
											finalizeFormik.touched.recordCompanyAddress &&
											!!finalizeFormik.errors.recordCompanyAddress
										}
										errorMessage={finalizeFormik.errors.recordCompanyAddress}
									/>
								</Col>

								<Col xs={6}>
									<TextField
										label='Record Company Rep. Name'
										name='recordCompanyRepName'
										value={finalizeFormik.values.recordCompanyRepName!}
										onChange={finalizeFormik.handleChange}
										onBlur={finalizeFormik.handleBlur}
										isInvalid={
											finalizeFormik.touched.recordCompanyRepName &&
											!!finalizeFormik.errors.recordCompanyRepName
										}
										errorMessage={finalizeFormik.errors.recordCompanyRepName}
									/>
								</Col>

								<Col xs={6}>
									<TextField
										label='Record Company Rep. Phone Number'
										name='recordCompanyRepPhone'
										value={finalizeFormik.values.recordCompanyRepPhone!}
										onChange={finalizeFormik.handleChange}
										onBlur={finalizeFormik.handleBlur}
										isInvalid={
											finalizeFormik.touched.recordCompanyRepPhone &&
											!!finalizeFormik.errors.recordCompanyRepPhone
										}
										errorMessage={finalizeFormik.errors.recordCompanyRepPhone}
									/>
								</Col>
							</Row>
						</Card.Body>
					</Card>

					<Row>
						<Col
							xs={12}
							className='d-flex justify-content-end align-items-center pb-3'
						>
							<Button label='Back' onClick={navigateToPrevStep} />
							<Button
								label='Save for Later'
								className='ml-3'
								onClick={handleSaveForLater}
							/>
							<Button
								label='Export'
								theme='dark'
								className='ml-3'
								type='submit'
							/>
						</Col>
					</Row>

					{/* <Card>
						<Card.Body>
							<pre>{JSON.stringify(finalizeFormik.values, null, 2)}</pre>
						</Card.Body>
					</Card> */}
				</>
			</Form>
		</FormikProvider>
	);
};

export default FinalizeStepB4;
