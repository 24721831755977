import { useFormikContext } from 'formik';
import React, { useEffect, useRef } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import Select from '../../../../form/Select';
import TextField from '../../../../form/TextField';
import theme from '@/theme.module.scss';

const CopyrightOwnerDetails = <
	FormType extends ParticipantForm | ProfileForm
>() => {
	const formik = useFormikContext<FormType>();
	const accordionHeaderRef = useRef<HTMLHeadingElement>(null);

	useEffect(() => {
		if (accordionHeaderRef.current) {
			accordionHeaderRef.current.getElementsByTagName('button')[0].disabled =
				!formik.values.isCopyrightOwner;
		}
	}, [accordionHeaderRef, formik.values]);

	const disableAccordionClickPropagation = () =>
		!formik.values.isCopyrightOwner
			? (e: React.MouseEvent<HTMLHeadingElement, MouseEvent>) =>
					e.stopPropagation()
			: undefined;

	const accordionKey = '0';

	const retrieveOwnership = () => {
		if (formik.values?.copyrightOwnerClaim) {
			const retrieved = territoryOptions.find(
				option =>
					option.value ===
					(
						formik.values
							?.copyrightOwnerClaim as ParticipantForm['copyrightOwnerClaim']
					)?.territoryOwnership
			);

			if (retrieved) return retrieved;
		}
		return '';
	};

	return (
		<Accordion
			defaultActiveKey={!formik.values.isCopyrightOwner ? accordionKey : ''}
		>
			<Accordion.Item
				eventKey={
					!formik.values.isCopyrightOwner ? (null as any) : accordionKey
				}
			>
				<Accordion.Header ref={accordionHeaderRef}>
					<h2 onClick={disableAccordionClickPropagation()}>
						COPYRIGHT OWNER DETAILS
					</h2>

					<small className='ml-2' style={{ color: theme.disclaimerTextColor }}>
						<i onClick={disableAccordionClickPropagation()}>
							(requires <strong>Copyright Owner?</strong> field)
						</i>
					</small>
				</Accordion.Header>
				<Accordion.Body>
					<Row>
						<Col xs={6}>
							<Select<CmoOptions>
								label='C.M.O. Representative'
								name='copyrightOwnerClaim.cmo'
								id='copyrightOwnerClaim.cmo'
								options={cmoOptions}
								value={cmoOptions.find(
									option =>
										option.value === formik.values?.copyrightOwnerClaim?.cmo
								)}
								onChange={option =>
									formik.setFieldValue('copyrightOwnerClaim.cmo', option?.value)
								}
								onBlur={formik.handleBlur}
							/>
						</Col>
						<Col xs={6}>
							<TextField
								label='Sound Exchange Payee ID or IPN '
								name='copyrightOwnerClaim.cmoId'
								id='copyrightOwnerClaim.cmoId'
								type='text'
								value={formik?.values?.copyrightOwnerClaim?.cmoId}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</Col>
						<Col xs={6}>
							<TextField
								label='Copyright Ownership Percentage'
								name='copyrightOwnerClaim.splitPercentage'
								id='copyrightOwnerClaim.splitPercentage'
								type='number'
								min={0}
								max={100}
								value={
									(
										formik?.values
											?.copyrightOwnerClaim as ParticipantForm['copyrightOwnerClaim']
									)?.splitPercentage ?? ''
								}
								onChange={formik.handleChange}
								onBlur={formik.handleBlur}
							/>
						</Col>

						<Col xs={6}>
							<Select<TerritoryOptions>
								label='Is the ownership applicable to the US or the entire world?'
								name='copyrightOwnerClaim.territoryOwnership'
								id='copyrightOwnerClaim.territoryOwnership'
								options={territoryOptions}
								value={retrieveOwnership()}
								onChange={option =>
									formik.setFieldValue(
										'copyrightOwnerClaim.territoryOwnership',
										option?.value
									)
								}
								onBlur={formik.handleBlur}
							/>
						</Col>
					</Row>
				</Accordion.Body>
			</Accordion.Item>
		</Accordion>
	);
};

export default CopyrightOwnerDetails;

type CmoOptions = {
	label: string;
	value: string;
};

type TerritoryOptions = {
	label: string;
	value: string;
};

const cmoOptions = [
	{ label: 'SoundExchange', value: 'SoundExchange' },
	{ label: 'PPL', value: 'PPL' },
	{ label: 'Multiple CMOs', value: 'MultipleCMOs' },
];

const territoryOptions = [
	{ label: 'US', value: 'US' },
	{ label: 'Entire World', value: 'Entire World' },
];
