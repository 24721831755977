import { FormikContextType, getIn } from 'formik';
import { uniqBy } from 'lodash';
import { matchSorter } from 'match-sorter';
import { useMemo } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { getPublishers } from '../../../../../api/services/publishersService';
import generateRandomId from '../../../../../helpers/generateRandomId';
import { containsWriterRoles } from '../../../../../helpers/participantTools';
import TextField from '../../../../form/TextField';
import { createPublisherSelectOption } from '../../../../../helpers/publisherTools';
import AsyncCreatableSelect from '../../../../form/AsyncCreatableSelect';
import { GroupBase, MenuListProps, components } from 'react-select';

const SELF_PUBLISHED_OPTION_NAME = 'Self Published';

export type PublisherSelectProps<
	FormType extends ParticipantForm | ProfileForm
> = {
	index: number;
	formik: FormikContextType<FormType>;
	isSubPublisher?: boolean;
	subIndex?: number;
	publishers: PublisherSelectOption[];
	setPublishers: React.Dispatch<React.SetStateAction<PublisherSelectOption[]>>;
};

const PublisherSelect = <FormType extends ParticipantForm | ProfileForm>({
	index,
	formik,
	isSubPublisher,
	subIndex,
	publishers,
	setPublishers,
}: PublisherSelectProps<FormType>) => {
	const publisherFieldNamePrefix = useMemo(
		() =>
			`publishers[${index}]${
				isSubPublisher ? `.subPublishers[${subIndex}]` : ''
			}`,
		[index, subIndex, isSubPublisher]
	);

	const isDisabled = useMemo(
		() => !containsWriterRoles(formik.values.roles),
		[formik.values.roles]
	);

	const selectedOption = useMemo(
		() =>
			isSubPublisher
				? createPublisherSelectOption({
						...formik.values.publishers[index].subPublishers[subIndex!],
						id: formik.values.publishers[index].subPublishers[subIndex!]
							.publisherId,
				  })
				: createPublisherSelectOption({
						...formik.values.publishers[index],
						id: formik.values.publishers[index].publisherId,
				  }),
		[index, subIndex, isSubPublisher, formik.values.publishers]
	);

	const handleChange = (option: PublisherSelectOption) => {
		formik.setFieldValue(
			`${publisherFieldNamePrefix}.publisherId`,
			option?.id ? parseInt(option.id.toString()) : '' // ID is either a number or a string in suggestions, we force it to be a number
		);

		formik.setFieldValue(
			`${publisherFieldNamePrefix}.name`,
			option?.name ?? ''
		);

		formik.setFieldValue(`${publisherFieldNamePrefix}.pro`, option?.pro ?? '');

		formik.setFieldValue(`${publisherFieldNamePrefix}.ipi`, option?.ipi ?? '');

		formik.setFieldValue(
			`${publisherFieldNamePrefix}.email`,
			option?.email ?? ''
		);
	};

	const MenuList = (props: MenuListProps<any, boolean, GroupBase<any>>) => {
		return (
			<components.MenuList {...props}>
				{props.children}
				{selectedOption.name !== SELF_PUBLISHED_OPTION_NAME && (
					<button
						className='btn font-weight-bold'
						style={{
							width: '100%',
							display: 'grid',
							placeItems: 'center',
							color: '#212132',
						}}
						onClick={() =>
							handleChange(
								createPublisherSelectOption({
									id: generateRandomId(), // We want users to be able to create multiple self published publishers
									name: SELF_PUBLISHED_OPTION_NAME,
								})
							)
						}
					>
						SELF PUBLISHED
					</button>
				)}
			</components.MenuList>
		);
	};

	const getPublishersSuggestion = async (value: string) => {
		try {
			if (!value) return [];

			const res = await getPublishers(value);
			const { suggestions } = res.data.suggest;

			return uniqBy(
				matchSorter(
					[
						...publishers,
						...suggestions.map(s =>
							createPublisherSelectOption({
								name: s.suggestion,
								id: s.id,
							})
						),
					],
					value,
					{ keys: ['name', 'id'] }
				),
				'id'
			);
		} catch (e) {
			console.error(
				'Something happened while obtaining publisher suggestions: \n',
				e
			);
			return [];
		}
	};

	return (
		<Container className='m-0 p-0'>
			<Row>
				<Col xs={isSubPublisher ? 12 : 6}>
					<AsyncCreatableSelect
						components={{
							MenuList,
						}}
						label='Select Publisher'
						name={`${publisherFieldNamePrefix}.publisherId`}
						id={`${publisherFieldNamePrefix}.publisherId`}
						options={publishers}
						placeholder='Search...'
						getOptionLabel={option => option.name}
						getOptionValue={option => option.id.toString()}
						value={selectedOption}
						getNewOptionData={(inputValue, optionLabel) => ({
							...createPublisherSelectOption({
								id: '',
								name: 'Create ' + inputValue,
							}),
							__isNew__: true,
						})}
						onCreateOption={value => {
							const newOption = createPublisherSelectOption({
								// Temporal fix to make a INT ID
								id: generateRandomId(),
								name: value,
							});

							setPublishers(prevPublishers => [...prevPublishers, newOption]);
							formik.setFieldValue(
								`${publisherFieldNamePrefix}.publisherId`,
								newOption.id
							);
							formik.setFieldValue(
								`${publisherFieldNamePrefix}.name`,
								newOption.name
							);
						}}
						onChange={handleChange}
						loadOptions={getPublishersSuggestion}
						errorFieldName={`${publisherFieldNamePrefix}.publisherId`}
					/>
				</Col>
				{!isSubPublisher && (
					<Col xs={6}>
						<TextField
							label='Publisher email (optional)'
							type='text'
							name={`${publisherFieldNamePrefix}.email`}
							id={`${publisherFieldNamePrefix}.email`}
							isDisabled={isDisabled}
							value={formik?.values?.publishers?.[index]?.email}
							// value={isSubPublisher ?
							// 	formik.values.publishers[index].subPublishers[subIndex].email :
							// 	formik.values.publishers[index].email
							// }
							onBlur={formik.handleBlur}
							placeholder='example@hotmail.com'
							onChange={formik.handleChange}
							isInvalid={Boolean(
								getIn(formik.touched, `publishers[${index}].email`) &&
									getIn(formik.errors, `publishers[${index}].email`)
							)}
							errorMessage={getIn(formik.errors, `publishers[${index}].email`)}
						/>
					</Col>
				)}
			</Row>
		</Container>
	);
};

export default PublisherSelect;
