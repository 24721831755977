import React, { useCallback, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import './ShareModal.scss';
import {
	getAlbumEditors,
	getRecordingEditors,
	addRecordingEditor,
	addAlbumEditor,
} from '../../../../api/services/editorService';
import ShareForm from './ShareForm';
import EditorTable from './EditorTable';
import { saveEditorProfile } from '../../../../store/projects/actions';
import { useDispatch } from 'react-redux';
import { setModalTitle } from '../../../../store/modal/actions';

const ShareModal = ({ albumId, recordingId }) => {
	const [editors, setEditors] = useState(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');
	const { userId } = useSelector(state => state.auth);
	const [myEditorProfile, setMyEditorProfile] = useState(null);
	const [informationMessage, setInformationMessage] = useState('');
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');

	const dispatch = useDispatch();

	// TODO: MOVE THIS LOGIC TO REDUX OR SEPARATE FUNCTION
	const handleSubmit = async e => {
		try {
			e.preventDefault();
			setIsSubmitting(true);
			setInformationMessage('');

			const form = e.target;
			const status = !Boolean(form[2].value);
			const emailRegex =
				// eslint-disable-next-line no-control-regex
				/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
			let res = null;

			if (!emailRegex.test(email)) {
				setInformationMessage('Invalid email. Try again.');
				setIsSubmitting(false);
				return;
			}

			let values = {
				editor_email: email,
				is_read_only: status,
				message,
			};

			if (albumId) {
				values.album_id = albumId;

				res = await addAlbumEditor(values);
			} else {
				values.recording_id = recordingId;

				res = await addRecordingEditor(values);
			}

			const newEditors = res.data.editors;
			const filteredEditors = newEditors.sort((a, b) => {
				if (a.is_owner > b.is_owner) return -1;
				if (a.is_owner < b.is_owner) return 1;
				return 0;
			});

			setMessage('');
			setEmail('');
			setInformationMessage('');
			setIsSubmitting(false);
			setEditors(filteredEditors);
		} catch (e) {
			console.log({ e });
			// const { status, data } = e.response

			// if (status == 404) {
			//   setInformationMessage("Something went wrong, please try again later.")
			// } else {
			//   setInformationMessage(data.detail)
			// }

			//   setErrorMsg(data.detail)
			setIsSubmitting(false);
			// console.log({e}, e.code)
		}
	};

	const fetchEditors = useCallback(async () => {
		try {
			let res = null;

			if (albumId) res = await getAlbumEditors(albumId);
			else res = await getRecordingEditors(recordingId);

			const editors = res.data.editors;
			let myNewEditorProfile = {
				...editors.find(editor => editor.user_id === userId),
			};

			const filteredEditors = editors.sort((a, b) => {
				if (a.is_owner > b.is_owner) return -1;
				if (a.is_owner < b.is_owner) return 1;
				return 0;
			});

			setMyEditorProfile(myNewEditorProfile);
			dispatch(saveEditorProfile(myNewEditorProfile));
			setEditors(filteredEditors);
			setIsLoading(false);
		} catch (e) {
			setEditors([]);
			setErrorMsg(
				"Whoops, hiccup detected, refresh projects and give it another go."
			);
			setIsLoading(false);
			console.log(e);
		}
	}, [albumId, dispatch, recordingId, userId]);

	const handleEmailChange = event => setEmail(event.target.value);

	const handleMessageChange = event => setMessage(event.target.value);

	const toggleLoading = () => setIsLoading(prev => !prev);

	useEffect(() => {
		if (albumId) {
			dispatch(setModalTitle('SHARE / REQUESTS ( ALBUM )'));
		} else {
			dispatch(setModalTitle('SHARE / REQUESTS'));
		}
	}, [albumId, dispatch]);

	const fetchedEditors = useRef(false);

	useEffect(() => {
		if (!fetchedEditors.current) {
			fetchedEditors.current = true;
			fetchEditors();
		}
	}, [albumId, dispatch, fetchEditors]);

	return (
		<>
			<Modal.Body className='p-5'>
				<section className='w-100'>
					{/* // If I'm an editor. */}
					{myEditorProfile && !myEditorProfile.is_read_only && (
						<ShareForm
							handleSubmit={handleSubmit}
							editor={myEditorProfile}
							isSubmitting={isSubmitting}
							informationMessage={informationMessage}
							email={email}
							message={message}
							handleEmailChange={handleEmailChange}
							handleMessageChange={handleMessageChange}
						/>
					)}
				</section>

				<section>
					<EditorTable
						isLoading={isLoading}
						editors={editors}
						albumId={albumId}
						recordingId={recordingId}
						setEditors={setEditors}
						errorMsg={errorMsg}
						setErrorMsg={setErrorMsg}
						fetchEditors={fetchEditors}
						myEditorProfile={myEditorProfile}
						toggleLoading={toggleLoading}
					/>
				</section>
			</Modal.Body>
		</>
	);
};

export default ShareModal;
