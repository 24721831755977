export const TierDescriptions = {
	PAID: {
		STARTER: {
			id: 2,
			name: 'Starter',
			storage: '300 GB',
			exports: 8,
			monthlyPrice: '5',
			yearlyPrice: '55',
			currency: {
				symbol: '$',
				code: 'USD',
			},
			generalFeatures: [
				{
					text: 'Cutting-edge music file transfers & streaming share links',
					isSpecial: false,
				},
				{
					text: 'TrackChat music timestamped comment engine',
					isSpecial: false,
				},
				{
					text: 'Collaboration with access controls',
					isSpecial: false,
				},
				{
					text: "World's leading music crediting system",
					isSpecial: false,
				},
				{ text: 'Create up to 200 recordings', isSpecial: false },
				{ text: 'High security TLS/AES 256 encryption', isSpecial: false },
				{ text: 'ISRC & GRid code generation', isSpecial: false },
				{
					text: 'Download controls (disable download until payment)',
					isSpecial: false,
				},
				{ text: 'AI-Based version control', isSpecial: false },
				{ text: 'AI-Based file organization', isSpecial: false },
				{ text: 'AI-Based mood detection', isSpecial: false },
				{
					text: 'End the awful era of using Dropbox and WeTransfer for music 👋',
					isSpecial: false,
				},
			],
			featuresWithHeadlines: [],
		} as TierDescription,

		PRO: {
			id: 3,
			name: 'Pro',
			storage: '1.5 TB',
			exports: 15,
			monthlyPrice: '12',
			yearlyPrice: '120',
			currency: {
				symbol: '$',
				code: 'USD',
			},
			generalFeatures: [
				{
					text: 'Cutting-edge music file transfers & streaming share links',
					isSpecial: false,
				},
				{
					text: 'TrackChat music timestamped comment engine',
					isSpecial: false,
				},
				{
					text: 'Collaboration with access controls',
					isSpecial: false,
				},
				{
					text: "World's leading music crediting system",
					isSpecial: false,
				},
				{ text: 'Create unlimited recordings', isSpecial: false },

				{ text: 'High security TLS/AES 256 encryption', isSpecial: false },
				{ text: 'ISRC & GRid code generation', isSpecial: false },
				{
					text: 'Download controls (disable download until payment)',
					isSpecial: false,
				},
				{ text: 'AI-Based version control', isSpecial: false },
				{ text: 'AI-Based file organization', isSpecial: false },
				{ text: 'AI-Based mood detection', isSpecial: false },
				{
					text: 'End the awful era of using Dropbox and WeTransfer for music 👋',
					isSpecial: false,
				},
			],
			featuresWithHeadlines: [
				{
					headline: 'Exports & Integrations',
					text: 'ASCAP/BMI/SOCAN registration, Sound Exchange registration, PPL export, Split Sheets, Label Copy, The Orchard, The MLC, CD Sleeve, Vinyl Sleeve, AllMusic, Metadata for Mastering, Lyric Sheet, Side Artist Release, Producer Agreement and Social Media exports',
					isSpecial: true,
				},
			],
		} as TierDescription,

		BUSINESS: {
			id: 4,
			name: 'Business',
			storage: '3 TB',
			exports: 17,
			monthlyPrice: '14',
			yearlyPrice: '145',
			currency: {
				symbol: '$',
				code: 'USD',
			},
			paymentInterval: 'month',
			generalFeatures: [
				{
					text: 'Cutting-edge music file transfers & streaming share links',
					isSpecial: false,
				},
				{
					text: 'TrackChat music timestamped comment engine',
					isSpecial: false,
				},
				{
					text: 'Collaboration with access controls',
					isSpecial: false,
				},
				{
					text: "World's leading music crediting system",
					isSpecial: false,
				},
				{ text: 'Create unlimited recordings', isSpecial: false },
				{ text: 'High security TLS/AES 256 encryption', isSpecial: false },
				{ text: 'ISRC & GRid code generation', isSpecial: false },
				{
					text: 'Download controls (disable download until payment)',
					isSpecial: false,
				},
				{ text: 'AI-Based version control', isSpecial: false },
				{ text: 'AI-Based file organization', isSpecial: false },
				{ text: 'AI-Based mood detection', isSpecial: false },
				{
					text: 'End the awful era of using Dropbox and WeTransfer for music 👋',
					isSpecial: false,
				},
			],
			featuresWithHeadlines: [
				{
					headline: 'Exports & Integrations',
					text: 'ASCAP/BMI/SOCAN registration, Sound Exchange registration, PPL export, Split Sheets, Label Copy, The Orchard, The MLC, CD Sleeve, Vinyl Sleeve, AllMusic, Metadata for Mastering, Lyric Sheet, Side Artist Release, Producer Agreement and Social Media exports',
					isSpecial: true,
				},
				{
					headline: 'Major Exports',
					text: 'AFM & SAG-AFTRA Forms, RIN Files',
					isSpecial: true,
				},
			],
		} as TierDescription,
	},
	LITE: {
		LITE: {
			id: 1,
			name: 'Lite',
			storage: '1 GB',
			exports: 0,
			monthlyPrice: 'FREE',
			yearlyPrice: 'FREE',
			currency: null,
			generalFeatures: [
				{
					text: 'File storage and sharing — Masters, mixes, contracts, promo graphics, music videos + everything related to your recordings',
					isSpecial: false,
				},
				{ text: 'High security TLS/AES 256 encryption', isSpecial: false },
				{ text: 'Chat', isSpecial: false },
				{ text: 'Project collaboration', isSpecial: false },
				{ text: 'Create up to 3 recordings', isSpecial: false },

			],
			featuresWithHeadlines: [],
		} as TierDescription,
		LITE_PLUS: {
			id: 5,
			name: 'Lite+',
			storage: '5 GB',
			exports: 1,
			monthlyPrice: 'FREE*',
			yearlyPrice: 'FREE*',
			currency: null,
			generalFeatures: [
				{
					text: 'File storage and sharing — Masters, mixes, contracts, promo graphics, music videos + everything related to your recordings',
					isSpecial: false,
				},
				{ text: 'High security TLS/AES 256 encryption', isSpecial: false },
				{ text: 'Chat', isSpecial: false },
				{ text: 'Project collaboration', isSpecial: false },
				{ text: 'Create up to 20 recordings', isSpecial: false },

			],
			featuresWithHeadlines: [],
			smallPrint: '*Free using Avid or partner code',
			canRedeemCode: true,
		} as TierDescription,
	},
	ENTERPRISE: {
		ENTERPRISE: {
			id: 0,
		},
	},
};
