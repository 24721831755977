import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import IconButton from '../../../../../../layout/IconButton';
import TextField from '../../../../../../form/TextField';
import { getIn, useFormikContext } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import './PlaylistInputFileItem.scss';
import InfoTooltip from '../../../../../../layout/InfoTooltip';
// import { isSupportedAudioFile } from '../../../../../../../helpers/fileTools';
import CreatableProjectSelect, {
	ProjectSelectOption,
} from '../../../../../../form/CreatableProjectSelect/CreatableProjectSelect';
import _ from 'lodash';
import { useAppSelector } from '../../../../../../../store/hooks';
import { SingleValue } from 'react-select';
import FileThumbnail from '../../../../../ProjectFiles/FileThumbnail';
import fileSize from 'filesize';
import { SparklesIcon } from '@heroicons/react/16/solid';
import { Tag } from 'primereact/tag';
import {
	AssociatedProjectStatus,
	UploadFilesToPlaylistForm,
	handleChangeProjectOption,
	handleCreateProjectOption,
} from '../../../../../../../helpers/playlistUploadTools';
import PlaylistFileUploadContext, {
	PlaylistFileUploadContextType,
} from '../../../UploadFilesToPlaylist/PlaylistFileUploadContext';
import Fuse from 'fuse.js';
import { isSafari } from 'react-device-detect';
import { isZipFile } from '@/helpers/fileTools';

// const textFieldLabelStyle = {
// 	fontSize: '0.8rem',
// 	marginBottom: '0.2rem',
// };

const THUMBNAIL_SIZE = '3rem';

export type PlaylistInputFileItemProps = {
	index: number;
	formikRemove: (index: number) => void;
	onDelete: (index: number) => void;
	file: File;
};

const PlaylistInputFileItem = ({
	index,
	formikRemove,
	onDelete,
	file,
}: PlaylistInputFileItemProps) => {
	const { detectTitleAndArtistByDefault } = useAppSelector(
		state => state.session
	);
	const formik = useFormikContext<UploadFilesToPlaylistForm>();
	const { playlistsById } = useAppSelector(state => state.playlists);
	const [recordingTitle, setRecordingTitle] = useState<string>('');
	const [wasFilename, setWasFilename] = useState<boolean>(false);
	const [shouldChangeToFilename, setShouldChangeToFilename] =
		useState<boolean>(false);

	const [computedProjectStatus, setComputedProjectStatus] =
		useState<AssociatedProjectStatus>();

	const {
		newProjects,
		setNewProjects,
		playlistId,
		userProjects,
		useFilenameAsTitle,
		setUseFilenameAsTitle,
		titleIndex,
	} = useContext<PlaylistFileUploadContextType | null>(
		PlaylistFileUploadContext
	) as PlaylistFileUploadContextType;

	// const { useFilenameAsTitle, setUseFilenameAsTitle } =
	// 	useContext<PlaylistFileUploadContextType | null>(
	// 		PlaylistFileUploadContext
	// 	) as PlaylistFileUploadContextType;

	const itemFormValues = useMemo(
		() => formik.values?.files?.[index],
		[formik.values, index]
	);
	const itemFormErrors = useMemo(
		() => formik.errors?.files?.[index],
		[formik.errors, index]
	);
	const itemFormTouch = useMemo(
		() => formik.touched?.files?.[index],
		[formik.touched, index]
	);

	const isDetecting = useMemo(
		() => itemFormValues?.isDetecting,
		[itemFormValues]
	);

	const handleProjectSelectBlur = useCallback(
		(e: React.FocusEvent<HTMLInputElement>) => {
			// debugger;
			// if a new project is selected, then we edit the project title
			// otherwise, a new project should be created (nothing needs to be done here for this last condition)

			// by default, a new project will be created on blur. We need to disable this by setting "createOnBlur" to false
			// in the CreatableSelect component, only when a new project is selected
			if (
				!itemFormValues?.isLinkedToProject &&
				itemFormValues?.associatedProjectId
			) {
				// we need the text value from the blur event
				const { value } = e.target;
				// only edit it if it's non-empty and different from the current value
				if (value && value !== itemFormValues?.projectTitle) {
					formik.setFieldValue(`files[${index}].projectTitle`, value);

					setNewProjects(prev =>
						prev.map(option =>
							_.isEqual(option.value, itemFormValues?.associatedProjectId)
								? {
										...option,
										title: value,
								  }
								: option
						)
					);
				}
			}

			formik.handleBlur(e);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[formik, index, itemFormValues]
	);

	const handleDelete = () => {
		onDelete(index);

		formikRemove(index);
	};

	const [playlistAlbumIds, playlistRecordingIds] = useMemo(() => {
		if (!playlistId) return [[], []];

		const playlist = playlistsById?.[playlistId]?.playlist;

		if (!playlist?.files) {
			return [[], []];
		}

		return playlist.files
			.reduce<[number[], number[]]>(
				(
					[albums, recordings]: [number[], number[]],
					playlistFile: FileMetadata
				) => {
					if (playlistFile?.albumId) {
						albums.push(playlistFile.albumId);
					}

					if (playlistFile?.recordingId) {
						recordings.push(playlistFile.recordingId);
					}

					return [albums, recordings];
				},
				[[], []] as [number[], number[]]
			)
			.map(ids => Array.from(new Set(ids)));
	}, [playlistsById, playlistId]);

	const handleChangeOption = useCallback(
		(option: SingleValue<ProjectSelectOption>) => {
			setShouldChangeToFilename(false);
			handleChangeProjectOption({
				detected: false,
				option,
				index,
				setFieldValue: formik.setFieldValue,
			});
		},
		[formik, index]
	);

	const handleCreateOption = useCallback(
		(projectTitle: string) =>
			handleCreateProjectOption({
				title: projectTitle,
				setNewProjects,
				setFieldValue: formik.setFieldValue,
				index,
				detected: false,
			}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[formik, index]
	);

	// useEffect(() => {
	// 	if (
	// 		detectTitleAndArtistByDefault &&
	// 		!detectedTitleAndArtist.current &&
	// 		itemFormValues?.isAudioFile &&
	// 		!itemFormValues?.projectTitle &&
	// 		!itemFormValues?.projectArtist &&
	// 		titleIndex
	// 	) {
	// 		detectedTitleAndArtist.current = true;
	// 	}
	// }, [
	// 	detectTitleAndArtistByDefault,
	// 	itemFormValues,
	// 	index,
	// 	formik,
	// 	titleIndex,
	// 	userProjects,
	// 	handleChangeOption,
	// 	handleCreateOption,
	// 	useFilenameAsTitle,
	// ]);

	const projectArtistValue = useMemo(
		() =>
			itemFormValues?.isLinkedToProject
				? itemFormValues?.projectArtist
				: newProjects.find(
						option =>
							option.value.recordingId ===
							itemFormValues?.associatedProjectId?.recordingId
				  )?.artist ?? '',
		[itemFormValues, newProjects]
	);

	const projectSelectInformationText = useMemo(() => {
		switch (itemFormValues?.associatedProjectStatus) {
			case AssociatedProjectStatus.DetectedExisting:
				return (
					<span className='playlist-input-file-item__project-select-info-text existing-recording'>
						<i className='fas fa-music' />
						Matched to Existing Project
						<Tag severity='info'>AI</Tag>
					</span>
				);
			case AssociatedProjectStatus.DetectedNew:
				return (
					<span className='playlist-input-file-item__project-select-info-text new-recording'>
						<SparklesIcon style={{ height: '1rem' }} />
						New Recording Detected
						<Tag severity='info'>AI</Tag>
					</span>
				);
			case AssociatedProjectStatus.ManualExisting:
				return (
					<span className='playlist-input-file-item__project-select-info-text existing-recording'>
						<i className='fas fa-music' />
						Existing Project Selected
					</span>
				);
			case AssociatedProjectStatus.ManualNew:
				return (
					<span className='playlist-input-file-item__project-select-info-text new-recording'>
						<SparklesIcon style={{ height: '1rem' }} />
						New Recording Selected
					</span>
				);
			default:
				return undefined;
		}
	}, [itemFormValues]);

	const folderUploadInformation = useMemo(() => {
		// if (isZipFile(itemFormValues.filename)) {
		if (isSafari) {
			return '* Change browser to upload as a folder';
		}
		return '* Use Drag & Drop to upload as a folder instead';
		// }
		// return undefined
	}, []);

	useEffect(() => {
		if (useFilenameAsTitle && !shouldChangeToFilename) {
			let fuseIndex = titleIndex.toJSON();
			const fuseThreshold = 0.2;

			const fuse = new Fuse(
				userProjects,
				{
					keys: ['title'],
					threshold: fuseThreshold,
					findAllMatches: true,
				},
				Fuse.parseIndex(fuseIndex)
			);

			const filenameResult = fuse.search(file.name);
			let matchFilename = null;
			let mainArtistName = null;

			if (filenameResult.length) {
				if (!mainArtistName) {
					matchFilename = filenameResult[0].item;
				} else {
					const filteredProjects = filenameResult.map(result => result.item);

					const artistFuse = new Fuse(filteredProjects, {
						keys: ['artist'],
						threshold: fuseThreshold,
					});

					const artistResult = artistFuse.search(mainArtistName);

					if (artistResult.length) {
						matchFilename = artistResult[0].item;
					}
				}
			}

			setComputedProjectStatus(itemFormValues?.associatedProjectStatus);

			if (matchFilename) {
				formik.setFieldValue(
					`files[${index}].associatedProjectStatus`,
					AssociatedProjectStatus.DetectedExisting
				);

				handleChangeProjectOption({
					detected: !useFilenameAsTitle,
					option: matchFilename,
					setFieldValue: formik.setFieldValue,
					index,
				});
			} else {
				formik.setFieldValue(
					`files[${index}].associatedProjectStatus`,
					AssociatedProjectStatus.DetectedNew
				);
				handleCreateProjectOption({
					title: file.name,
					artist: projectArtistValue,
					detected: !useFilenameAsTitle,
					setFieldValue: formik.setFieldValue,
					index,
					setNewProjects,
				});
			}
		} else if (!useFilenameAsTitle && wasFilename) {
			formik.setFieldValue(
				`files[${index}].associatedProjectStatus`,
				computedProjectStatus
			);
		}
	}, [
		itemFormValues?.filename,
		shouldChangeToFilename,
		titleIndex,
		useFilenameAsTitle,
		userProjects,
		computedProjectStatus,
		projectArtistValue,
	]);

	useEffect(() => {
		if (useFilenameAsTitle) {
			// if (!shouldChangeToFilename) setShouldChangeToFilename(true);
			// else setShouldChangeToFilename(false);
			if (!shouldChangeToFilename) {
				setShouldChangeToFilename(true);
				setRecordingTitle(itemFormValues.projectTitle);
				formik.setFieldValue(`files[${index}].projectTitle`, file.name);
				itemFormValues.projectTitle = file.name;

				setWasFilename(true);
			}
		} else if (!useFilenameAsTitle && wasFilename) {
			formik.setFieldValue(`files[${index}].projectTitle`, recordingTitle);
			itemFormValues.projectTitle = recordingTitle;
			setWasFilename(false);
			setShouldChangeToFilename(false);
		}
	}, [
		file.name,
		formik,
		index,
		itemFormValues,
		recordingTitle,
		shouldChangeToFilename,
		useFilenameAsTitle,
		wasFilename,
	]);

	return (
		<Row
			className='input-file-item m-0 align-items-stretch playlist-input-file-item__container'
			style={{
				height: 'auto',
			}}
		>
			<Col
				xs={4}
				className={`playlist-input-file-item__file-name-container pl-2 pr-0`}
			>
				<FileThumbnail
					style={{
						width: THUMBNAIL_SIZE,
						height: THUMBNAIL_SIZE,
						color: '#7e7e7e',
						backgroundColor: '#e1e1e1',
					}}
					iconStyle={{
						fontSize: `calc(${THUMBNAIL_SIZE} / 2)`,
					}}
					file={{
						filename: file.name,
						coverUrl: itemFormValues?.metadata?.picture ?? null,
					}}
					forceAudio={
						itemFormValues?.isAudioFile && !itemFormValues?.metadata?.picture
					}
				/>
				<div
					style={{
						flex: 1,
					}}
				>
					<p className='m-0 file-name'>{itemFormValues?.filename}</p>
					{/* file size */}
					{itemFormValues?.size && (
						<p className='m-0 file-size'>
							{itemFormValues?.size <= 1 ? '-' : fileSize(itemFormValues?.size)}
							{isZipFile(itemFormValues.filename) && (
								<div
									style={{
										flex: 1,
									}}
								>
									<Form.Text
										className='text-muted'
										style={{ height: 0, position: 'absolute' }}
									>
										<span
											className='playlist-input-file-item__project-select-info-text existing-recording'
											style={{ color: '#007bff' }}
										>
											{folderUploadInformation}
										</span>
									</Form.Text>
								</div>
							)}
						</p>
					)}
				</div>
			</Col>
			<Col
				style={{
					position: 'relative',
				}}
				xs={8}
			>
				<Row
					className='h-100 p-3 justify-content-between align-items-center'
					style={{
						gap: '0.5rem',
					}}
				>
					<>
						<Col md={11} lg={5}>
							<CreatableProjectSelect
								key={itemFormValues.projectTitle}
								filename={
									useFilenameAsTitle
										? file.name
										: wasFilename
										? recordingTitle
										: itemFormValues.projectTitle
								}
								showArtistName={false}
								name={`files[${index}].associatedProjectId`}
								label='Recording Title or Existing Project'
								placeholder={isDetecting ? 'Detecting...' : 'Select Project'}
								value={itemFormValues?.associatedProjectId}
								isDisabled={isDetecting}
								onChange={handleChangeOption}
								onBlur={handleProjectSelectBlur}
								errorFieldName={`files[${index}].associatedProjectId`}
								// labelStyle={textFieldLabelStyle}
								removeReadOnly
								playlistAlbumIds={playlistAlbumIds}
								playlistRecordingIds={playlistRecordingIds}
								onCreateOption={handleCreateOption}
								createdOptions={newProjects}
								isClearable
								// only create new project on blur if the user has not selected an existing project
								// or if the user selected an existing project (as those cannot be edited from this menu, only new projects
								// can be edited)
								createOnBlur={
									!itemFormValues?.associatedProjectId ||
									itemFormValues?.isLinkedToProject
								}
								informationText={projectSelectInformationText}
								isInvalid={
									getIn(formik.errors, `files[${index}].associatedProjectId`) &&
									getIn(formik.touched, `files[${index}].associatedProjectId`)
								}
								// dense
							/>
						</Col>
						<Col lg={5}>
							<TextField
								value={projectArtistValue}
								isDisabled={
									((detectTitleAndArtistByDefault && isDetecting) ||
										itemFormValues?.isLinkedToProject ||
										!itemFormValues?.associatedProjectId) &&
									!(
										(useFilenameAsTitle &&
											itemFormValues?.associatedProjectStatus ===
												AssociatedProjectStatus.DetectedNew) ||
										(!useFilenameAsTitle &&
											itemFormValues?.associatedProjectStatus ===
												AssociatedProjectStatus.DetectedNew)
									)
								}
								name={`files[${index}].projectArtist`}
								label={
									<div className='d-flex align-items-center'>
										Main Artist
										<InfoTooltip
											iconClassName='ml-1'
											message='These details will be used to create a new recording under the Projects tab'
										/>
									</div>
								}
								placeholder='Enter Artist'
								// labelStyle={textFieldLabelStyle}
								errorMessage={
									typeof itemFormErrors !== 'string'
										? itemFormErrors?.projectArtist
										: ''
								}
								isInvalid={
									itemFormTouch?.projectArtist &&
									!!(typeof itemFormErrors !== 'string'
										? itemFormErrors?.projectArtist
										: '')
								}
								onChange={({ target: { value } }) => {
									const isLinkedToProject = itemFormValues?.isLinkedToProject;

									// if linked to project, then don't update the value
									if (isLinkedToProject) {
										return;
									}

									formik.setFieldValue(`files[${index}].projectArtist`, value);

									setNewProjects(prev =>
										prev.map(option =>
											_.isEqual(
												option.value,
												itemFormValues?.associatedProjectId
											)
												? {
														...option,
														artist: value,
												  }
												: option
										)
									);
								}}
								onBlur={formik.handleBlur}
								iconButton={{
									icon: 'fas fa-copy',
									tooltipText: 'Copy to All',
									onClick: () => {
										// copy artist name to all files in form
										// that are not linked to a project

										const files = formik?.values?.files?.map((file, i) => {
											if (file.isLinkedToProject) {
												return file;
											}

											return {
												...file,
												projectArtist: itemFormValues?.projectArtist,
											};
										});

										setNewProjects(prev => {
											const newProjects = prev.map(option => {
												const formikFile = files.find(file =>
													_.isEqual(file.associatedProjectId, option.value)
												);

												return {
													...option,
													artist: formikFile?.projectArtist,
												} as ProjectSelectOption;
											});

											return newProjects;
										});

										formik.setFieldValue('files', files);
									},
								}}
							/>
						</Col>

						<IconButton
							icon='fas fa-times'
							onClick={handleDelete}
							tooltipText='Delete'
							// color='white'
						/>
					</>
				</Row>
			</Col>
		</Row>
	);
};

export default PlaylistInputFileItem;
