import { useEffect } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { openUpgradeWebsite } from '../../../helpers/tiersTools';
import { hideModal, setModalTitle } from '../../../store/modal/actions';
import Button from '../../layout/Button';
import { useAppDispatch } from '../../../store/hooks';

const TierLockModal = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setModalTitle('FEATURE UNAVAILABLE'));
	}, [dispatch]);

	const closeModal = () => dispatch(hideModal());

	return (
		<>
			<Modal.Body>
				<div className='d-flex justify-content-center align-items-center px-4'>
					<i
						className='fas fa-lock m-0 px-2'
						style={{ fontSize: '2.5rem', flex: 1 }}
					/>
					<div style={{ textAlign: 'left', fontWeight: 600, flex: 5 }}>
						Get started on getting serious. Upgrade your subscription to use
						this feature!
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Row>
					<Col xs={12} className='d-flex justify-content-end'>
						<Button label='Close' onClick={closeModal} />
						<Button
							label='Upgrade'
							onClick={() => openUpgradeWebsite()}
							leftIcon='fas fa-angle-double-up'
							theme='dark'
							className='ml-2'
						/>
					</Col>
				</Row>
			</Modal.Footer>
		</>
	);
};

export default TierLockModal;
