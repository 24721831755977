import _ from 'lodash';

const snakeize = obj =>
	_.transform(obj, (acc, value, key, target) => {
		const snakeKey = _.isArray(target) ? key : _.snakeCase(key);

		acc[snakeKey] = _.isObject(value) ? snakeize(value) : value;
	});

export default snakeize;
