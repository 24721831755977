import camelize from './camelize';

// TODO: Add type for code data
export const translateApiIsrcCodes = (codes: any[]) =>
	codes.map(codeData => camelize(codeData));

export const createCodeResourceGroup = ({
	userId,
	recording,
	isSequenced,
	sequenceNumber,
}: {
	userId: number;
	recording: RecordingContent;
	isSequenced: boolean;
	sequenceNumber: number | null;
}): CodeResourceGroup => ({
	id: null,
	identifier: null,
	title: recording.title,
	version: recording.version || null,
	main_artist: recording.mainArtist,
	date: recording.recordingDate || null,
	duration: _formatDuration(recording.duration),
	medium: 'SoundRecording',
	work_identifier: null,
	authorizing_user: userId,
	is_sequenced: isSequenced,
	sequence_number: sequenceNumber,
});

const _formatDuration = (duration: string) => {
	const durationValues = duration.split(':').map(str => {
		if (str.length === 1) {
			str = '0' + str;
		}

		return str;
	});

	return `${durationValues[0]}:${durationValues[1]}:${durationValues[2]}`;
};

export const translateApiGridCodes = translateApiIsrcCodes;
