import { FormikProvider, useFormik } from 'formik';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import * as yup from 'yup';
import { useAppDispatch } from '../../../../../../store/hooks';
import TextField from '../../../../../form/TextField';
import Button from '../../../../../layout/Button';
import {
	hideModal,
	setModalTitle,
} from '../../../../../../store/modal/actions';
import { renamePlaylistFileGroupAction } from '../../../../../../store/playlists/actions';

type RenamePlaylistFolderModalProps = {
	playlistId: Playlist['id'];
	folderId: PlaylistFolder['id'];
	initialName: string;
};

const validationSchema = yup.object().shape({
	name: yup.string().required('Required'),
});

const RenamePlaylistFolderModal = ({
	playlistId,
	folderId,
	initialName,
}: RenamePlaylistFolderModalProps) => {
	const dispatch = useAppDispatch();

	const formik = useFormik({
		initialValues: {
			name: initialName,
		},
		validationSchema,
		onSubmit: async values => {
			await dispatch(
				renamePlaylistFileGroupAction({
					playlistId,
					groupId: folderId,
					name: values.name,
				})
			);

			dispatch(hideModal());
		},
	});

	useEffect(() => {
		dispatch(setModalTitle('EDIT FOLDER NAME'));
	}, [dispatch]);

	return (
		<FormikProvider value={formik}>
			<Modal.Body>
				<div className='mx-4'>
					<TextField
						name='name'
						label='Folder Name'
						placeholder='Enter folder name'
						value={formik.values.name}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						isInvalid={!!formik.touched.name && !!formik.errors.name}
						errorMessage={formik.errors.name}
					/>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button label='Cancel' onClick={() => dispatch(hideModal())} />
				<Button
					onClick={() => formik.handleSubmit()}
					isLoading={formik.isSubmitting}
					label='Change Name'
					theme='dark'
				/>
			</Modal.Footer>
		</FormikProvider>
	);
};

export default RenamePlaylistFolderModal;
