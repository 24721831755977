const leadWriterSplitSheetColumns = [
	{
		Header: () => <span className='Project-Name'>Participant Name</span>,
		accessor: 'creditedName',
		Cell: col => <span className='Name1'>{col.value}</span>,
	},
	{
		Header: () => <span className='Project-Name'>Participant Role(s)</span>,
		accessor: 'roles',
		Cell: col => (
			<span className='Name1'>
				{col.value.map(role => (
					<p className='m-0'>{role.detail}</p>
				))}
			</span>
		),
	},
];

export default leadWriterSplitSheetColumns;
