import {
	DndContext,
	DragEndEvent,
	DragOverlay,
	DragStartEvent,
	PointerSensor,
	UniqueIdentifier,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import {
	Context,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import DndPlaylistFileTableRow from './PlaylistFileTableRow/DndPlaylistFileTableRow';
import {
	SortableContext,
	arrayMove,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import SortableDndPlaylistFileTableRow from './PlaylistFileTableRow/SortableDndPlaylistFileTableRow';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { getCurrentPlaylist } from '../../../../../store/playlists/selectors';
import { SelectedPlaylistContext } from '../../../../../context/SelectedPlaylistContext';
import { ContextMenu } from 'primereact/contextmenu';
import { getPlaylistUploadsById } from '../../../../../store/files/selectors';
import EmptyPlaceholder from '../../../../layout/EmptyPlaceholder';
import useOutsideClick from '../../../../../hooks/useOutsideClick';
import SortableDndPlaylistFileTableFolder from './PlaylistFileTableFolder/SortableDndPlaylistFileTableFolder';
import PlaylistFileTableUpload from './PlaylistFileTableUpload/PlaylistFileTableUpload';
import DndPlaylistFileTableFolder from './PlaylistFileTableFolder/DndPlaylistFileTableFolder';
import {
	createDndItemId,
	createDndItems,
	getItemsInGroup,
	getSelectedItemsParentGroupId,
	isFileGroup,
	isGroupDndId,
	pointerWithinWithCenterLineThreshold,
	moveFile,
	moveGroup,
	isAncestorOf,
} from './utilities';
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import { getContextMenuOptions } from './contextMenu';
import {
	reorderPlaylistFileGroupsAction,
	reorderPlaylistFilesAction,
	updatePlaylistFileGroupFilesAction,
} from '../../../../../store/playlists/actions';

// type DndPlaylistFileTableProps = {
// 	files: PlaylistTableFile[];
// };

// * inspired by dnd-kit's SortableTree
// * https://github.com/clauderic/dnd-kit/blob/master/stories/3%20-%20Examples/Tree/SortableTree.tsx

const DndPlaylistFileTable = () => {
	const dispatch = useAppDispatch();
	const currentPlaylist = useAppSelector(getCurrentPlaylist);
	const playlistUploads = useAppSelector(state =>
		getPlaylistUploadsById(state, { playlistId: currentPlaylist?.id! })
	);
	// ref used to copy the selectedItemIds into an auxiliary array, since
	// the useOutsideClick hook will clear the selectedItemIds array
	// when clicking in the context menu options, making the options disappear
	// before the commands are executed. This way, the selectedItemIds can be cleared
	// without affecting the context menu options
	const contextMenuItemIds = useRef<string[]>([]);

	const {
		filteredUploads,
		sortedUngroupedFiles,
		setSortedUngroupedFiles,
		sortedFolders,
		setSortedFolders,
		contextMenuRef,
		selectedItemIds,
		searchTerm,
		setSelectedItemIds,
		playlistFiles,
		setPlaylistFiles,
		setIsDeletingGroupIds,
		groupIdToParentGroupIdIndex,
		groupIdToGroupContentIndex,
		fileIdToGroupIdIndex,
		isAbovePointerDistanceThreshold,
		setIsAbovePointerDistanceThreshold,
		uploadingFiles,
		filteredFiles,
	} = useContext<SelectedPlaylistContextType>(
		SelectedPlaylistContext as Context<SelectedPlaylistContextType>
	);

	const isEmpty = useMemo(
		() =>
			(!sortedUngroupedFiles.length &&
				!sortedFolders.length &&
				!uploadingFiles.length) ||
			(searchTerm && !filteredFiles.length),
		[
			sortedUngroupedFiles,
			sortedFolders,
			uploadingFiles,
			searchTerm,
			filteredFiles,
		]
	);

	const handleClickOutside = useCallback(() => {
		setSelectedItemIds([]);
	}, [setSelectedItemIds]);

	const outsideClickRef =
		useOutsideClick<HTMLTableSectionElement>(handleClickOutside);

	const sensors = useSensors(
		useSensor(PointerSensor, {
			activationConstraint: {
				distance: 10,
			},
		})
	);

	const [activeDndItemId, setActiveDndItemId] =
		useState<UniqueIdentifier | null>(null);

	const { folders: topLevelFolders, files: topLevelFiles } = useMemo(
		() =>
			getItemsInGroup({
				playlistFiles,
				sortedFolders,
				groupIdToGroupContentIndex,
				fileIdToGroupIdIndex,
				parentGroupId: null,
			}),
		[
			playlistFiles,
			sortedFolders,
			groupIdToGroupContentIndex,
			fileIdToGroupIdIndex,
		]
	);

	// update sortedUngroupedFiles when the playlist changes
	useEffect(
		() => setSortedUngroupedFiles([...topLevelFiles]),
		[topLevelFiles, setSortedUngroupedFiles]
	);

	// for optimistically updating the playlist file list
	// we must add an additional id property so that all items can coexist in the same array
	const dndItems = useMemo(
		() =>
			currentPlaylist?.playlist
				? createDndItems({
						playlist: currentPlaylist,
						sortedFolders,
						fileIdToGroupIdIndex,
				  })
				: [],
		[currentPlaylist, fileIdToGroupIdIndex, sortedFolders]
	);

	const activeDndItem = useMemo(() => {
		if (!activeDndItemId) {
			return null;
		}

		const item = dndItems.find(item => item.id === activeDndItemId);

		if (!item) {
			console.error(
				'activeDndItemId not found in dndItems',
				activeDndItemId,
				dndItems
			);
			return null;
		}

		return item;
	}, [activeDndItemId, dndItems]);

	const handleDragEnd = (event: DragEndEvent) => {
		const { active, over } = event;
		// TODO: check that the logic for allowing dropping is correct
		// TODO: the biggest case we need to avoid is a user trying to drag a parent folder into a child folder

		try {
			// do not allow reordering if there is an active filter query
			if (!over || searchTerm) {
				return;
			}

			// TODO: MAKE COMPATIBLE WITH FOLDERS AND UPLOADS
			const activeItem = dndItems.find(item => item.id === active.id)!;
			const overItem = dndItems.find(item => item.id === over.id)!;

			if (activeItem.id === overItem.id) {
				return;
			}
			// if it's a group being dragged over another group, there's two cases:
			// 1 - and they're in the same parent group, resort groups
			// if (
			// 	isFileGroup(activeItem.data) &&
			// 	isFileGroup(overItem.data) &&
			// 	activeItem.groupId === overItem.groupId
			// ) {
			// 	const { newFolders, reorderedParentFolder } = reorderGroupsRecursive({
			// 		playlistFolders: sortedFolders,
			// 		overItem,
			// 		fileIdToGroupIdIndex,
			// 		groupIdToParentGroupIdIndex,
			// 		groupIdToGroupContentIndex,
			// 		groupId: activeItem.data.id,
			// 		dispatch,
			// 		playlistFiles: currentPlaylist?.playlist?.files!,
			// 		parentGroupId: overItem.groupId,
			// 	});

			// 	dispatch(
			// 		reorderPlaylistFileGroupsAction({
			// 			playlistId: currentPlaylist?.id!,
			// 			groupIds: reorderedParentFolder.folders.map(folder => folder.id),
			// 			parentGroupId: overItem.groupId,
			// 		})
			// 	);

			// 	setSortedFolders(newFolders);

			// 	return;
			// }

			// if both are groups insert the group into the destination group
			// if it's hovering above (threshold = true, and the active folder
			// can't be an ancestor of the over folder), otherwise the
			// moveGroup function will resort the groups
			// if the over item is not a group, then we'll move it to the parent group of the over item
			if (
				isFileGroup(activeItem.data) &&
				// overItem.groupId !== activeItem.groupId &&
				!isAncestorOf({
					folderAId: activeItem.data.id,
					folderBId: overItem.data.id,
					groupIdToParentGroupIdIndex,
				})
			) {
				const isFolderInGroup = activeItem.groupId === overItem.data.id;

				if (isFolderInGroup && isAbovePointerDistanceThreshold) {
					// in this case, the folder item will be highlighted, which means "drag into the same group", so ignore
					return;
				}

				const { newFolders, newParentGroupFolderIds } = moveGroup({
					playlistFolders: sortedFolders,
					playlistFiles: currentPlaylist?.playlist?.files!,
					fileIdToGroupIdIndex,
					groupIdToParentGroupIdIndex,
					groupIdToGroupContentIndex,
					groupId: activeItem.data.id,
					overItem,
					removeFromFolder: isFolderInGroup,
					insertInOverItem:
						isFileGroup(overItem.data) && isAbovePointerDistanceThreshold,
				});

				// 1- if they were resorted, if the over item is a file,
				//	  or if isFolderInGroup (i.e. removeFromFolder is true)
				//    then we need to use the parent group ID of the overItem
				//    (which will be the same as the activeItem's parentGroupId)
				//    this will only happen if both groups are in the same parent group and isAbovePointerDistanceThreshold is false
				// 2- otherwise, we use the overItem's data ID as the new parent group ID

				let modifiedGroupId: PlaylistFolder['id'] | null = null;
				let finalGroupIds: PlaylistFolder['id'][] | null = null;

				if (
					!isAbovePointerDistanceThreshold || // this pretty much handles the case of resorted groups
					// and inserting a folder from a different parent group by dragging it over a folder
					// (but not over its center, otherwise isAbovePointerDistanceThreshold would be true)
					!isFileGroup(overItem.data) ||
					isFolderInGroup
				) {
					modifiedGroupId = overItem.groupId;
					finalGroupIds = newParentGroupFolderIds;
				} else {
					// this condition is wrong, it should be if isAbovePointerDistanceThreshold is true
					modifiedGroupId = overItem.data.id;
					finalGroupIds = newParentGroupFolderIds;
				}

				dispatch(
					reorderPlaylistFileGroupsAction({
						playlistId: currentPlaylist?.id!,
						groupIds: finalGroupIds,
						parentGroupId: modifiedGroupId,
					})
				);

				setSortedFolders(newFolders);

				return;
			}

			// if it's a file being dragged over a group, insert the file into the group at the end
			// unless it's already in the group
			if (!isFileGroup(activeItem.data) && isFileGroup(overItem.data)) {
				const isFileInGroup = activeItem.groupId === overItem.data.id;

				if (
					(isFileInGroup && isAbovePointerDistanceThreshold) ||
					(activeItem.groupId === overItem.groupId &&
						!isAbovePointerDistanceThreshold)
				) {
					// in this case, the folder item will be highlighted, which means "drag into the same group"
					// or, it will be a file hovering between two folders in the same parent group,
					// indicating "place between these two folders in the same group", which makes no sense,
					// so we ignore both cases
					return;
				}

				// otherwise, we've got 2 more cases to consider:
				// 1- if it's slightly above its own group,
				// 	then we move it to 1 level higher than the destination group (indicated in removeFromFolder property)
				// 2 - if it's above a different group, then we move it to the destination group
				const { newParentGroupFileIds, newFolders } = moveFile({
					playlistFolders: sortedFolders,
					playlistFiles: currentPlaylist?.playlist?.files!,
					fileIdToGroupIdIndex,
					groupIdToParentGroupIdIndex,
					groupIdToGroupContentIndex,
					fileId: activeItem.data.id,
					overItem,
					insertInOverItem: !isFileInGroup && isAbovePointerDistanceThreshold,
					removeFromFolder: isFileInGroup,
				});

				let modifiedGroupId: PlaylistFolder['id'] | null = null;
				let newFileIds: PlaylistFileMetadata['id'][] | null = null;

				// there are three possible cases:
				// 1- we need to modify the active item's old parent group (activeItem.groupId) (remove the file)
				//    this should only happen if the file is in the folder described by overItem,
				//    and is being moved to the root folder
				// 2- we need to modify the over item's parent group (overItem.groupId) (add the file)
				// 3- we need to modify the over item's group (overItem.data.id) (add the file)

				// examples of each case:
				// 1.i - moving a file from a group to the top-level (null overItem.groupId) (over a group,
				//       but not in it, i.e. isAbovePointerDistanceThreshold is false)
				// 2.i - removing a file from a group and adding it to another ancestor folder
				// 2.ii - dragging a file between two folders (and file is from a different parent group)
				// 3.i - moving a file directly into a folder (isAbovePointerDistanceThreshold is true)

				// case 1 and 3
				if (!overItem.groupId && activeItem.groupId) {
					const oldParentGroup = groupIdToGroupContentIndex[activeItem.groupId];
					// in this case, we'll either have a grouped file being moved into a top-level folder,
					// or trying to be removed from a top-level folder.
					// in the former, we want to add the file to the top-level folder
					// in the latter, we want to remove the file from the top-level folder -> filter it out
					// the only way to tell is with isAbovePointerDistanceThreshold (former true, latter false)

					modifiedGroupId = isAbovePointerDistanceThreshold
						? overItem.data.id
						: activeItem.groupId;
					newFileIds = isAbovePointerDistanceThreshold
						? newParentGroupFileIds
						: oldParentGroup.fileIds.filter(
								fileId => fileId !== activeItem.data.id
						  );

					// if it's being removed, we need to make sure to update the orderIndex of the file
					// so that the playlistFiles array state is updated correctly
					if (!isAbovePointerDistanceThreshold) {
						const ungroupedFiles = getItemsInGroup({
							playlistFiles,
							sortedFolders,
							parentGroupId: null,
							fileIdToGroupIdIndex,
							groupIdToGroupContentIndex,
						}).files;

						setPlaylistFiles(
							playlistFiles.map(file =>
								file.id === activeItem.data.id
									? {
											...file,
											orderIndex: ungroupedFiles.length + 1, // 1-based
									  }
									: file
							)
						);
					}
				} else if (isFileInGroup || !isAbovePointerDistanceThreshold) {
					// case 2
					// in this case, we want to add the file to the overItem's group
					// this is done when removeFromFolder is true, or when dragging a file between two folders
					// and the file is from a different parent group
					modifiedGroupId = overItem.groupId;
					newFileIds = newParentGroupFileIds;
				} else {
					// case 3
					// in this case, we want to add the file to the overItem's group
					// this will only happen is isAbovePointerDistanceThreshold is true
					// i.e. drag the file near the center of the folder row
					modifiedGroupId = overItem.data.id;
					newFileIds = newParentGroupFileIds;
				}

				// TODO: TEST MOVING GROUPS

				dispatch(
					updatePlaylistFileGroupFilesAction({
						playlistId: currentPlaylist?.id!,
						groupId: modifiedGroupId!,
						fileIds: newFileIds,
					})
				);

				setSortedFolders(newFolders);

				return;
			}

			// no need to check if both are files, because the previous checks guarantee that

			// if the files are both in the root folder, then reorder the playlist files
			// instead of the folder files
			if (!activeItem.groupId && !overItem.groupId) {
				const activeIndex =
					(activeItem.data as PlaylistFileMetadata).orderIndex! - 1; // orderIndex is 1-based
				const overIndex =
					(overItem.data as PlaylistFileMetadata).orderIndex! - 1; // orderIndex is 1-based

				// if the file is being dragged over itself, do nothing
				if (activeIndex === overIndex) {
					return;
				}

				// if the file is being dragged to a different position in the same group
				const newFiles = arrayMove(
					sortedUngroupedFiles,
					activeIndex,
					overIndex
				);

				dispatch(reorderPlaylistFilesAction(currentPlaylist!.id, newFiles));

				setSortedUngroupedFiles([...newFiles]);
				return;
			}

			// otherwise, this is a file being dragged over another file
			// (both in the same group or in different groups, at least one of them is in a group)
			// reordering is covered by moveFile too
			const { newFolders, newParentGroupFileIds } = moveFile({
				playlistFolders: sortedFolders,
				playlistFiles: currentPlaylist?.playlist?.files!,
				fileIdToGroupIdIndex,
				groupIdToParentGroupIdIndex,
				groupIdToGroupContentIndex,
				fileId: activeItem.data.id,
				overItem,
			});

			// if the overItem is in the root folder, then we need to hit the reorder playlist files
			// action, that will automatically remove it from the old folder and insert it at the correct position
			// in the top level.
			if (!overItem.groupId) {
				const { files: ungroupedFiles } = getItemsInGroup({
					playlistFiles,
					sortedFolders,
					parentGroupId: null,
					fileIdToGroupIdIndex,
					groupIdToGroupContentIndex,
				});
				// if it's being dragged between folders, then move it to the end of the ungrouped files
				const overIndex = isFileGroup(overItem.data)
					? ungroupedFiles.length
					: overItem.data.orderIndex!;
				// orderIndex is 1-based, but we actually want it 0-based + 1, so it's fine

				ungroupedFiles.splice(
					overIndex,
					0,
					activeItem.data as PlaylistFileMetadata
				);

				dispatch(
					reorderPlaylistFilesAction(currentPlaylist!.id, [...ungroupedFiles])
				);
			} else {
				dispatch(
					updatePlaylistFileGroupFilesAction({
						playlistId: currentPlaylist?.id!,
						groupId: overItem.groupId,
						fileIds: newParentGroupFileIds,
					})
				);
			}

			setSortedFolders(newFolders);

			return;
		} finally {
			setActiveDndItemId(null);
		}
	};

	const handleDragStart = (event: DragStartEvent) => {
		setActiveDndItemId(event.active.id);
	};

	const contextMenuOptions = useMemo(
		() =>
			getContextMenuOptions({
				selectedItemIds,
				contextMenuItemIdsRef: contextMenuItemIds,
				playlist: currentPlaylist!,
				allFiles: playlistFiles,
				groupIdToGroupContentIndex,
				playlistUploads,
				dispatch,
				selectedItemsParentGroupId: getSelectedItemsParentGroupId({
					selectedItemIds: contextMenuItemIds.current,
					fileIdToGroupIdIndex,
					groupIdToParentGroupIdIndex,
				}),
				setIsDeletingGroupIds,
			}),
		[
			selectedItemIds,
			contextMenuItemIds,
			currentPlaylist,
			playlistUploads,
			dispatch,
			playlistFiles,
			groupIdToGroupContentIndex,
			setIsDeletingGroupIds,
			fileIdToGroupIdIndex,
			groupIdToParentGroupIdIndex,
		]
	);

	const handleFolderDistanceThresholdChange = useCallback(
		(isAboveThreshold: boolean) => {
			setIsAbovePointerDistanceThreshold(isAboveThreshold);
		},
		[setIsAbovePointerDistanceThreshold]
	);

	const collisionDetectorFn = useMemo(
		() =>
			pointerWithinWithCenterLineThreshold(handleFolderDistanceThresholdChange),
		[handleFolderDistanceThresholdChange]
	);

	return (
		<>
			<ContextMenu
				ref={contextMenuRef}
				model={contextMenuOptions}
				style={{
					width: '20rem',
				}}
				pt={{
					action: {
						style: {
							display: 'grid',
							gridTemplateColumns: '2rem 1fr',
						},
					},
				}}
			/>

			<table className='playlist-file-table'>
				<thead>
					<tr>
						{/* previously used for row reordering, might not be necessary now */}
						{/* <th></th> */}
						<th>#</th>
						<th>Project & Artist</th>
						<th>Note</th>
						<th>Version & Tag</th>
						{/* used for ellipsis menu */}
						<th></th>
					</tr>
				</thead>

				{isEmpty && (
					<>
						<div
							className='w-100 d-flex justify-content-center'
							style={{
								height: '40vh',
							}}
						>
							<EmptyPlaceholder
								icon={`fas fa-${searchTerm ? 'search' : 'file'} fa-4x`}
								title={searchTerm ? 'NO RESULTS FOUND' : 'NO FILES IN PLAYLIST'}
								description={
									searchTerm ? (
										<></>
									) : (
										<>
											Start adding files to this playlist by clicking the{' '}
											<span
												style={{
													fontWeight: 600,
												}}
											>
												<i className='fas fa-plus' /> Upload
											</span>{' '}
											button,
											<br />
											or dragging and dropping them anywhere!
										</>
									)
								}
							/>
						</div>
					</>
				)}

				<tbody ref={outsideClickRef}>
					{searchTerm ? (
						filteredFiles.map((file, index) => (
							<DndPlaylistFileTableRow
								key={file.id}
								file={file}
								rowIndex={index + 1}
								id={createDndItemId(file)}
								// isSortingActive={activeDndItemId === file.id}
							/>
						))
					) : (
						<>
							{filteredUploads.map((upload, index) => (
								<PlaylistFileTableUpload key={upload.id} upload={upload} />
							))}
							<DndContext
								onDragEnd={handleDragEnd}
								onDragStart={handleDragStart}
								sensors={sensors}
								collisionDetection={collisionDetectorFn}
								modifiers={[snapCenterToCursor]}
							>
								<SortableContext
									strategy={verticalListSortingStrategy}
									items={dndItems}
								>
									{/* // TODO: FIX SEARCHING and REORDERING (these properties are not 
							 	from useState, they're derived from the playlist object) */}
									{topLevelFolders.map((group, index) => (
										<SortableDndPlaylistFileTableFolder
											key={group.id}
											folder={group}
											rowIndex={index}
											depth={0}
											id={createDndItemId(group)}
											// isSortingActive={activeDndItemId === file.id}
										/>
									))}
									{sortedUngroupedFiles.map((file, index) => (
										<SortableDndPlaylistFileTableRow
											key={file.id}
											file={file}
											rowIndex={index + sortedFolders.length}
											id={createDndItemId(file)}
											// isSortingActive={activeDndItemId === file.id}
										/>
									))}
								</SortableContext>
								<DragOverlay>
									{activeDndItem ? (
										isGroupDndId(activeDndItemId as string) ? (
											<DndPlaylistFileTableFolder
												folder={activeDndItem.data as PlaylistFileGroup}
												clone
											/>
										) : (
											<DndPlaylistFileTableRow
												clone
												file={activeDndItem.data as PlaylistFileMetadata}
											/>
										)
									) : null}
								</DragOverlay>
							</DndContext>
						</>
					)}
				</tbody>
			</table>
		</>
	);
};

export default DndPlaylistFileTable;
