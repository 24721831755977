import { SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
	setMutedAction,
	setVolumeAction,
} from '../../../../store/session/actions';
import { debounce } from 'lodash';
import { Slider, SliderChangeEvent } from 'primereact/slider';
import { Button } from 'primereact/button';
import styles from '../AudioPlayerBar.module.scss';
import clsx from 'clsx';

export type VolumeControlProps = {
	localVolume: number;
	setLocalVolume: React.Dispatch<SetStateAction<number>>;
};

const VolumeControl = ({ localVolume, setLocalVolume }: VolumeControlProps) => {
	const dispatch = useAppDispatch();
	const { muted } = useAppSelector(state => state.session);

	const toggleMute = useCallback(
		(mute: boolean) => dispatch(setMutedAction(mute)),
		[dispatch]
	);

	const debouncedSetSessionVolume = useMemo(
		() =>
			debounce(volume => {
				dispatch(setVolumeAction(volume));
			}, 1000),
		[dispatch]
	);

	const handleVolumeChange = (e: SliderChangeEvent) => {
		if (Array.isArray(e.value)) {
			return;
		}

		const value = e.value as number;

		setLocalVolume(value);

		debouncedSetSessionVolume(value);
	};

	useEffect(() => {
		// If the new value is 0 and the volume is not muted, toggle mute (mute)
		// but don't store the value, as we want to restore it when unmuting
		if (localVolume === 0 && !muted) {
			toggleMute(true);
			return;
		}

		// If the new value is greater than 0 and the volume is muted, toggle mute (unmute)
		if (localVolume > 0 && muted) {
			toggleMute(false);
		}
		// we don't want to add muted to the dependency array, as it will cause involuntary muting
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [localVolume, toggleMute]);

	return (
		<div className={styles['volume-control']}>
			<Button
				onClick={() => toggleMute(!muted)}
				icon={muted ? 'fas fa-volume-mute' : 'fas fa-volume-up'}
				rounded
				text
				className={styles['button']}
			/>
			<Slider
				pt={{
					range: {
						className: styles['slider-range'],
					},
					handle: {
						className: styles['slider-handle'],
					},
				}}
				className={clsx(styles['slider'], styles['volume-slider'])}
				min={0}
				max={100}
				onChange={handleVolumeChange}
				value={muted ? 0 : localVolume}
			/>
		</div>
	);
};

export default VolumeControl;