import { isElectron } from 'react-device-detect';

/**
 *
 * @param {string} path - The path to the asset, with no leading slash
 * @returns {string} - The same path if in Electron, or a path with a leading slash if in browser
 *
 * @example
 * ```
 * getAssetPath('assets/images/logo.png') // returns '/assets/images/logo.png' in browser, 'assets/images/logo.png' in Electron
 * ```
 */

export const getAssetPath = (path: string) =>
	isElectron ? `${path}` : `/${path}`;
