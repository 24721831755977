import blankRecording from '../constants/recording.json';
import blankParticipant from '../constants/participant.json';

// TODO: type
const translateImportedRecordingToLocal = (importedRecording: any) => {
	const translatedRecording = translateImportedToLocal(
		blankRecording,
		importedRecording
	);

	translatedRecording.participants = translatedRecording.participants.map(
		(participant: any) => {
			const formattedParticipant = translateImportedToLocal(
				blankParticipant,
				participant
			);

			// ad-hoc rule for id
			formattedParticipant.id = participant.id.toString();

			// ad-hoc rules for roles
			if (formattedParticipant.roles && formattedParticipant.roles.length > 0) {
				formattedParticipant.roles = formattedParticipant.roles.map(
					(role: any) => {
						return {
							category: role.category,
							detail: role.detail,
							studio: role.studio && role.studio.studioId ? role.studio : null,
						};
					}
				);
			}

			return formattedParticipant;
		}
	);

	return translatedRecording;
};

// TODO: type
const translateImportedToLocal = (
	emptyLocalEntity: any,
	importedEntity: any
) => {
	const translatedEntity: any = {};

	Object.keys(emptyLocalEntity).forEach(key => {
		// get the correct default value (e.g. empty string instead of null for strings)
		if (!importedEntity[key]) {
			translatedEntity[key] = emptyLocalEntity[key];
		} else {
			translatedEntity[key] = importedEntity[key];
		}
	});

	return translatedEntity;
};

export default translateImportedRecordingToLocal;
