import React, { useEffect, useState, useRef } from 'react';
import { Col, Nav, Row } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import ROUTES from '@/router/routes';
import { logoutAction } from '@/store/auth/actions';
import NavLink from '../NavLink';
import './Sidebar.scss';
import { showModalAction } from '../../../store/modal/actions';
import { PLAYLIST_CTA_MODAL_JULY_2023 } from '@/constants/modalTypes';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import PromoType from '@/store/promos/promoType';
import { replacePathVariables } from '@/helpers/routeTools';
import { getAssetPath } from '@/helpers/assetTools';
import { ReactComponent as CloudMusicIcon } from '@/assets/img/icons/cloud-music-1.svg';

function Sidebar() {
	const { currentPlaylistId } = useAppSelector(state => state.playlists);
	const [hidden, setHidden] = useState(false);
	const toggleMenuButton = useRef<HTMLAnchorElement>(null);
	const dispatch = useAppDispatch();
	const { seenPromos } = useAppSelector(state => state.promos);

	const hasSeenPlaylistJuly2023Promotion =
		seenPromos[PromoType.PlaylistJuly2023];

	const isMobile = useMediaQuery({ query: '(max-width: 1280px)' });

	// remove 'active' Bootstrap class from button on every re-render
	useEffect(() => {
		if (toggleMenuButton.current) {
			toggleMenuButton.current.classList.remove('active');
		}
	});

	useEffect(() => {
		if (isMobile) {
			return;
		}

		if (hidden) {
			document.body.classList.add('sidebar-collapsed');
		} else {
			document.body.classList.remove('sidebar-collapsed');
		}
	}, [hidden]); //eslint-disable-line

	useEffect(() => {
		if (
			typeof hasSeenPlaylistJuly2023Promotion !== 'undefined' &&
			!hasSeenPlaylistJuly2023Promotion
		) {
			dispatch(
				showModalAction(PLAYLIST_CTA_MODAL_JULY_2023, {
					size: 'xl',
					removeHeaderPadding: true,
				})
			);
		}
	}, [hasSeenPlaylistJuly2023Promotion, dispatch]);

	return (
		// <aside
		// 	className={`m-sidebar sidebar-dark-primary elevation-4 overflow-hidden`}
		// >
		<aside className={`m-sidebar sidebar-dark-primary overflow-hidden`}>
			<Row
				className='d-flex justify-content-center pt-5'
				// @ts-ignore
				style={{ WebkitAppRegion: 'drag' }}
			>
				<Col xs={6} className='d-flex justify-content-center'>
					<img
						src={getAssetPath('img/logo.png')}
						className='img-fluid'
						alt='logo'
						draggable={false}
					/>
				</Col>
			</Row>

			<div className='my-3' />

			<div
				className='sidebar overflow-hidden'
				style={{ margin: '0 5 px !important' }}
			>
				<Nav className='nav-sidebar flex-column mb-5'>
					<Nav.Item>
						<Nav.Link
							ref={toggleMenuButton}
							role='button'
							href='# '
							onClick={() => {
								if (!isMobile) {
									setHidden(!hidden);
								}
							}}
						>
							<i className='fas fa-bars nav-icon mr-4'></i>
							<p style={{ userSelect: 'none' }}>{!hidden ? 'HIDE MENU' : ''}</p>
						</Nav.Link>
					</Nav.Item>
				</Nav>

				<Nav className='nav-sidebar flex-column mt-2'>
					<NavLink
						to={
							currentPlaylistId
								? replacePathVariables(ROUTES.PlaylistDetails.path, {
										playlistId: currentPlaylistId,
								  })
								: ROUTES.Playlists.path
						}
						label={ROUTES.Playlists.label.toUpperCase()}
						hidden={hidden}
						enableWhiteSpaceWrap
						svgIcon={
							<CloudMusicIcon
								style={{
									width: '1.4rem',
									height: '1.4rem',
								}}
							/>
						}
						// imageIcon='cloud-music-1.svg'
						// textIcon='fas fa-file-export'
					/>
					<NavLink
						to={ROUTES.Projects.path}
						label={ROUTES.Projects.label.toUpperCase()}
						textIcon='fas fa-list'
						hidden={hidden}
					/>
					<NavLink
						to={ROUTES.Editor.path}
						label={ROUTES.Editor.label.toUpperCase()}
						imageIcon='add-profile.svg'
						hidden={hidden}
					/>
					{/* <NavLink
					
						
						
						to={ROUTES.IndieUpload.path}
						label={ROUTES.IndieUpload.label.toUpperCase()}
						imageIcon='upload.svg'
						hidden={hidden}
					/> */}
					<NavLink
						to={ROUTES.GeneratedCodes.path}
						label={ROUTES.GeneratedCodes.label.toUpperCase()}
						imageIcon='key.svg'
						hidden={hidden}
					/>
					<NavLink
						to={ROUTES.SavedProfiles.path}
						label={ROUTES.SavedProfiles.label.toUpperCase()}
						imageIcon='profile.svg'
						hidden={hidden}
					/>
					{/* <NavLink
						to={ROUTES.Settings.path}
						label={ROUTES.Settings.label.toUpperCase()}
						imageIcon='gear.svg'
						hidden={hidden}
					/> */}
					<NavLink
						to={ROUTES.Help.path}
						label={ROUTES.Help.label.toUpperCase()}
						imageIcon='help.svg'
						hidden={hidden}
					/>
				</Nav>

				<Nav className='logout-nav nav-sidebar flex-column w-100'>
					<NavLink
						to={ROUTES.Login.path}
						label='LOGOUT'
						onClick={() => dispatch(logoutAction())}
						imageIcon='logout.svg'
					/>
				</Nav>
			</div>
		</aside>
	);
}

export default Sidebar;
