import { Form, FormikProps, FormikProvider } from 'formik';
import React from 'react';
import { Modal } from 'react-bootstrap';
import TextField from '../../../../../form/TextField';
import Button from '../../../../../layout/Button';
import { InitiateTransferForm } from '../TransferPlaylistModal';

const InitiatePlaylistTransferStep = ({
	formik,
	onBack,
}: {
	formik: FormikProps<InitiateTransferForm>;
	onBack: () => void;
}) => {
	return (
		<>
			<Modal.Body>
				<FormikProvider value={formik}>
					<Form className='px-4' placeholder={null}>
						<TextField
							name='email'
							label='Recipient Email'
							placeholder='Enter email'
							value={formik?.values?.email}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							errorMessage={formik?.errors?.email}
							isInvalid={formik?.touched?.email && !!formik?.errors?.email}
						/>
					</Form>
				</FormikProvider>
			</Modal.Body>
			<Modal.Footer>
				<Button label='Cancel' onClick={onBack} className='mr-2' />
				<Button
					label='Next'
					onClick={formik.handleSubmit as any}
					theme='dark'
					isLoading={formik.isSubmitting}
				/>
			</Modal.Footer>
		</>
	);
};

export default InitiatePlaylistTransferStep;
