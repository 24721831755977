import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import {
	addUploadFilesFolders,
	getStorageUsageAction,
} from '../../../../../store/files/actions';
import { hideModal, setModalTitle } from '../../../../../store/modal/actions';
import Button from '../../../../layout/Button';
import {
	createPlaylistFileGroupAction,
	fetchPlaylistAction,
	handleUploadFromPlaylistAction,
	reorderPlaylistFileGroupsAction,
	updatePlaylistFileGroupFilesAction,
	updatePlaylistSubGroupsAction,
} from '../../../../../store/playlists/actions';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import UploadFilesToPlaylist from '../UploadFilesToPlaylist';
import { UploadFilesToPlaylistForm } from '../../../../../helpers/playlistUploadTools';

export type UploadFilesToPlaylistModalProps = {
	playlistId: Playlist['id'];
	initialFiles?: File[];
};

function readFileContents(file: File) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = event => {
			const content = event.target?.result;
			resolve(content); // Resolve with the file content
		};

		reader.onerror = error => {
			reject(error); // Reject with the error
		};

		// Start reading the file
		reader.readAsText(file);
	});
}

const UploadFilesToPlaylistModal = ({
	playlistId,
	initialFiles = [],
}: UploadFilesToPlaylistModalProps) => {
	const dispatch = useAppDispatch();

	const createEmptyFolder = async (
		playlistId: number,
		fileIds: number[],
		name: string,
		parentGroupId: number | null = null,
		folderIds: number[]
	) => {
		return await dispatch(
			createPlaylistFileGroupAction({
				playlistId,
				fileIds,
				name,
				parentGroupId: parentGroupId,
				folderIds,
			})
		);
	};

	const reorderGroup = async (
		playlistId: number,
		groupIds: number[],
		parentGroupId: number | null = null
	) => {
		return await dispatch(
			reorderPlaylistFileGroupsAction({
				playlistId,
				groupIds: groupIds,
				parentGroupId: parentGroupId,
			})
		);
	};

	const getFileFolders = (file: any) => {
		const folders: string[] = [];
		const parts = file.path.split('/');
		for (let i = 1; i < parts.length; i++) {
			const folderPath = parts[i];
			folders.push(folderPath);
		}
		return folders.slice(0, -1);
	};

	function parsePath(path: string) {
		// Split the path by "/"
		const parts: any = path.split('/').filter((part: string) => part !== '');

		// Get the last part of the path
		const currentFolder = parts.pop();

		// Join the remaining parts to get the path before the last part
		const parentFolder = parts.length > 0 ? '/' + parts.join('/') : null;

		return {
			parentFolder,
			currentFolder,
		};
	}

	const handleUpload = async (
		values: UploadFilesToPlaylistForm,
		inputFiles: File[],
		folderDict: { [key: string]: { [key: string]: File[] } },
		folderFiles: {
			[key: string]: File[];
		},
		folderFilesMetadata: {
			[key: string]: any[];
		}
	) => {
		// console.log('Values:', values.files);
		// console.log('inputFiles:', inputFiles);
		// console.log('Folder Dict', folderDict);
		// console.log('Two');
		// console.log('FOLDER FILES:', folderFiles);
		// console.log('FOLDER FILES METADATA', folderFilesMetadata);

		let allFolders = new Set<string>();
		const foldersToCreate: { [key: string]: string[] } = {};

		// Assign Associated Project Data to folder files, obtained from the root folder input file
		for (let i = 0; i < inputFiles.length; i++) {
			const inputFile: any = inputFiles[i];
			if (inputFile.type === 'folder') {
				const inputFileMetadata: any = values.files[i];
				folderFilesMetadata[inputFile.name] = folderFilesMetadata[
					inputFile.name
				].map((file: any) => {
					Object.defineProperty(file, 'associatedProjectId', {
						value: inputFileMetadata.associatedProjectId,
					});
					Object.defineProperty(file, 'associatedProjectStatus', {
						value: inputFileMetadata.associatedProjectStatus,
					});
					Object.defineProperty(file, 'isLinkedToProject', {
						value: inputFileMetadata.isLinkedToProject,
					});
					Object.defineProperty(file, 'projectArtist', {
						value: inputFileMetadata.projectArtist,
					});
					Object.defineProperty(file, 'projectTitle', {
						value: inputFileMetadata.projectTitle,
					});
					Object.defineProperty(file, 'projectType', {
						value: inputFileMetadata.projectType,
					});

					return file;
				});
			}
		}

		// console.log('Modified folderFilesMetadata', folderFilesMetadata);

		const individualFiles: File[] = inputFiles.filter(
			(f: any) => f.type !== 'folder'
		);
		const individualFilesMetadata = values.files.filter(
			(f: any) => !folderFiles.hasOwnProperty(f.filename)
		);

		// console.log('Individual Files:', individualFiles);
		// console.log('Individual Files Metadata:', individualFilesMetadata);

		const allFiles: File[] = [
			...individualFiles,
			...Object.values(folderFiles).flat(),
		];
		let allFileMetadata: any[] = [
			...individualFilesMetadata,
			...Object.values(folderFilesMetadata).flat(),
		];

		// Reorder allFileMetadata to respect allFiles order
		let metadataMap: Map<string, FileMetadata> = new Map();
		allFileMetadata.forEach(metadata => {
			metadataMap.set(metadata.filename + '|' + metadata.size, metadata);
		});
		allFileMetadata = allFiles.map(file =>
			metadataMap.get(file.name + '|' + file.size.toString())
		);

		// console.log('allFiles:', allFiles);
		// console.log('allFileMetadata:', allFileMetadata);

		// console.log('FolderDict:', folderDict);

		const folderIdsToAssign: { [key: string]: number[] } = {};
		const folderIds: { [key: string]: number } = {};

		// Save folderId for each file per recordingId
		const folderPerFilePerRecording: {
			[key: string]: { [key: string]: number[] };
		} = {};

		// Create all necessary folders
		const folderCreationPromises: Promise<void>[] = [];

		Object.keys(folderDict).forEach((folderKey: string) => {
			// createEmptyFolder(playlistId, [], key, null, []);
			Object.keys(folderDict[folderKey]).forEach(async (key: string) => {
				const promise = (async () => {
					const { parentFolder, currentFolder } = parsePath(key);

					// console.log('upperFolder:', parentFolder);
					// console.log('currentFolder:', currentFolder);
					let playlist: any;
					let resultFolders: any[];
					let id: any;
					if (parentFolder) {
						const parentId = folderIds[parentFolder];
						playlist = await createEmptyFolder(
							playlistId,
							[],
							currentFolder,
							parentId,
							[]
						);
						console.log('Playlist:', playlist);

						resultFolders = playlist.playlist.folders.filter(
							(folder: any) => folder.name === currentFolder
						);
						id = resultFolders[resultFolders.length - 1].id;

						folderIds[key] = id;
						if (!folderIdsToAssign.hasOwnProperty(parentFolder)) {
							folderIdsToAssign[parentFolder] = [];
						}
						folderIdsToAssign[parentFolder].push(id);
					} else {
						playlist = await createEmptyFolder(
							playlistId,
							[],
							currentFolder,
							null,
							[]
						);

						resultFolders = playlist.playlist.folders.filter(
							(folder: any) => folder.name === currentFolder
						);
						id = resultFolders[resultFolders.length - 1].id;

						folderIds[key] = id;
					}
					folderDict[folderKey][key].forEach((file: File) => {
						// const recordingId =
						// 	folderFilesMetadata[folderKey][0].associatedProjectId.recordingId;
						const folderElem = folderFilesMetadata[folderKey][0];
						const recordingKey =
							folderElem.projectTitle + '/' + folderElem.projectArtist;
						const fileKey: string = file.name + '|' + file.size.toString();

						if (!folderPerFilePerRecording.hasOwnProperty(recordingKey)) {
							// Use title/artist as key because id is not available yet
							folderPerFilePerRecording[recordingKey] = {};
						}

						if (
							folderPerFilePerRecording[recordingKey].hasOwnProperty(fileKey)
						) {
							folderPerFilePerRecording[recordingKey][fileKey].push(id);
						} else {
							folderPerFilePerRecording[recordingKey][fileKey] = [id];
						}
					});
				})();
				folderCreationPromises.push(promise);
			});
		});
		// console.log('folderPerFilePerRecording:', folderPerFilePerRecording);

		await Promise.all(folderCreationPromises);
		// console.log('folderIds:', folderIds);
		// console.log('folderIdsToAssign:', folderIdsToAssign);
		// console.log('folderPerFilePerRecording:', folderPerFilePerRecording);
		if (Object.keys(folderPerFilePerRecording).length > 0)
			await dispatch(addUploadFilesFolders(folderPerFilePerRecording));

		const reorderFolderPromises: any[] = [];
		// Reassign folders inside each other
		Object.keys(folderIdsToAssign).forEach(async (key: string) => {
			const promise = (async () => {
				console.log(
					'Key:',
					key,
					' id:',
					folderIds[key],
					' groups to assign:',
					folderIdsToAssign[key]
				);
				await reorderGroup(playlistId, folderIdsToAssign[key], folderIds[key]);
			})();
			reorderFolderPromises.push(promise);
		});
		await Promise.all(reorderFolderPromises);
		// console.log('Finished reordering');

		debugger;

		await Promise.resolve(
			dispatch(
				handleUploadFromPlaylistAction({
					playlistFilesMetadata: allFileMetadata,
					inputFiles: allFiles,
					playlistId,
					includeInAlbumData: values.includeInAlbum
						? {
								album: values.album,
								albumTitle: values.albumTitle,
								albumArtist: values.albumArtist,
								isExistingAlbum: values.isExistingAlbum,
						  }
						: undefined,
				})
			)
		);
	};

	// only run on mount
	useEffect(() => {
		dispatch(setModalTitle('UPLOAD FILES TO PLAYLIST'));
		dispatch(getStorageUsageAction()); // update storage usage as this will be opened from Projects
	}, [dispatch]);

	return (
		<>
			<Modal.Body className='px-2 py-1'>
				<UploadFilesToPlaylist
					className='px-4'
					playlistId={playlistId}
					initialFiles={initialFiles}
					onSubmit={handleUpload}
					renderFooter={({ formik, isLoading }) => (
						<Modal.Footer>
							<Button label='Close' onClick={() => dispatch(hideModal())} />
							<Button
								label='Upload'
								theme='dark'
								onClick={async () => {
									formik.submitForm();
								}}
								isDisabled={isLoading}
								isLoading={formik.isSubmitting}
							/>
						</Modal.Footer>
					)}
				/>
			</Modal.Body>
		</>
	);
};

export default UploadFilesToPlaylistModal;
