import React, { useEffect, useMemo } from 'react';
import { Alert, Col, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal, setModalTitle } from '../../../../store/modal/actions';
import Button from '../../../layout/Button';
import BSButton from 'react-bootstrap/Button';
import { deleteParticipantAction } from '../../../../store/projects/actions';
import { deletePersonalProfile as deleteCloudProfile } from '../../../../api/services/localProfilesService';
import { deleteProfile } from '../../../../store/profiles/actions';
import {
	getSavedExportParticipantIds,
	getSavedExportRecordingIds,
} from '../../../../helpers/savedExportsTools';
import {
	enterpriseExportTypes,
	proExportTypes,
	regularExportTypes,
} from '../../../../constants/extendedExportTypes';
import { deleteExportFormAction } from '../../../../store/session/actions';

const extendedExportTypes = {
	...proExportTypes,
	...regularExportTypes,
	...enterpriseExportTypes,
};

const DeleteParticipantModal = ({
	participant,
	onClose,
	deleteFromRecording = true,
	recordingId,
}) => {
	const dispatch = useDispatch();
	const { savedExportFormsById } = useSelector(state => state.session);
	const { recordingsById } = useSelector(state => state.projects);

	const referencedSavedExports = useMemo(
		() =>
			Object.values(savedExportFormsById).filter(
				savedExport =>
					(savedExport.recordingId === recordingId ||
						(savedExport.albumId === recordingsById[recordingId]?.albumId &&
							getSavedExportRecordingIds(savedExport).includes(recordingId))) &&
					getSavedExportParticipantIds(savedExport).includes(participant.id)
			),

		[savedExportFormsById, recordingsById, recordingId, participant]
	);

	useEffect(() => {
		console.log('PARTICIPANT IN DELETE PARTICIPANT MODAL: ', participant);
		dispatch(setModalTitle('DELETE PARTICIPANT'));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleClose = () => {
		// Added prop and validation in case of specific handler upon close event.
		dispatch(hideModal());

		if (onClose) onClose();
	};

	const handleDelete = async () => {
		handleClose();

		// Delete all persisted export forms that reference this participant
		referencedSavedExports.forEach(savedExport => {
			dispatch(deleteExportFormAction(savedExport.id));
		});

		if (deleteFromRecording) {
			dispatch(deleteParticipantAction(participant));
		} else {
			// TODO: MOVE API CALL TO REDUX ACTION
			await deleteCloudProfile(participant.id);
			dispatch(deleteProfile(participant));
		}
	};

	return (
		<>
			<Modal.Body>
				<div className='px-3'>
					<p style={{ fontWeight: 500 }}>
						Are you sure you want to delete <i>{participant?.creditedName}</i>{' '}
						from the recording credits?
					</p>
					{referencedSavedExports.length > 0 && (
						<Alert variant='danger'>
							<p>
								<strong>WARNING</strong>: If you delete this participant, all
								progress made in the following export forms that reference this
								participant will be lost:
							</p>
							<ul>
								{referencedSavedExports.map(savedExport => (
									<li key={savedExport.id}>
										{extendedExportTypes[savedExport.exportType].label}
									</li>
								))}
							</ul>
							<p>
								If you wish to keep the progress, go into the listed export
								forms and unselect the participants.
							</p>
						</Alert>
					)}
					<p style={{ fontWeight: 500 }}>This action cannot be undone</p>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Row>
					<Col
						xs={12}
						className='d-flex justify-content-end align-items-center'
					>
						<Button label='Cancel' onClick={handleClose} />
						<BSButton
							className='ml-2'
							variant='danger'
							onClick={handleDelete}
							style={{ fontWeight: 600 }}
						>
							Delete
						</BSButton>
					</Col>
				</Row>
			</Modal.Footer>
		</>
	);
};

export default DeleteParticipantModal;
