import React, { useEffect } from 'react';
import isniStrings, { IsniString } from '../isniStrings';
import SoundCreditLoader from '../../SoundCreditLoader/SoundCreditLoader';
import { Message } from 'primereact/message';
import Button from '../../../layout/Button/Button';
import Lottie from 'react-lottie';
import checkmarkAnimation from '../../../../assets/animations/successCheckmark.json';
import { useAppSelector } from '../../../../store/hooks';

const defaultAnimationOptions = {
	loop: false,
	autoplay: true,
	animationData: checkmarkAnimation,
	rendererSettings: {
		preserveAspectRatio: 'xMidYMid slice',
	},
};

type IsniCreatedStepProps = {
	isni: string;
	onDismiss: () => void;
	onAssignIsni: () => Promise<void>;
	getIsniStringFn: (isniString: IsniString) => string;
	isniCertificateLink?: string | null;
};

const IsniCreatedStep = ({
	isni,
	onDismiss,
	getIsniStringFn,
	onAssignIsni,
	isniCertificateLink,
}: IsniCreatedStepProps) => {
	const { userEmail } = useAppSelector(state => state.auth);
	const [loading, setLoading] = React.useState(false);
	const [isAssigned, setIsAssigned] = React.useState(false);
	const [error, setError] = React.useState<string | null>(null);

	useEffect(() => {
		if (!loading && !isAssigned && !error) {
			setLoading(true);
			onAssignIsni()
				.then(() => {
					setIsAssigned(true);
				})
				.catch(err => {
					setError('Something went wrong. Please try again.');
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [loading, isAssigned, onAssignIsni, isni, error]);

	if (error) {
		return (
			<div className='d-flex align-items-center flex-column justify-content-center'>
				<Message
					severity='error'
					text={error}
					style={{
						width: '50%',
					}}
					className='mb-3'
				/>

				<Button
					label='Try again'
					onClick={() => setError(null)}
					leftIcon='fas fa-redo'
				/>
			</div>
		);
	}

	if (loading || !isAssigned) {
		return <SoundCreditLoader message='Assigning ISNI to profile...' />;
	}

	return (
		<>
			<Lottie
				options={defaultAnimationOptions}
				isClickToPauseDisabled
				height={250}
				width={250}
				style={{ cursor: 'default' }}
			/>
			<h3>{getIsniStringFn(isniStrings.IsniCreated.title(isni))}</h3>

			<h4>{getIsniStringFn(isniStrings.IsniCreated.description1)}</h4>

			{getIsniStringFn(isniStrings.IsniCreated.description2) && (
				<h4>{getIsniStringFn(isniStrings.IsniCreated.description2)}</h4>
			)}

			{getIsniStringFn(isniStrings.IsniCreated.description3(userEmail)) && (
				<h4>
					{getIsniStringFn(isniStrings.IsniCreated.description3(userEmail))}
				</h4>
			)}

			{isniCertificateLink && (
				<Button
					label='View ISNI Certificate'
					leftIcon='fas fa-file-alt'
					onClick={() => window.open(isniCertificateLink)}
					theme='dark'
					className='mb-3'
				/>
			)}

			<div className='d-flex justify-content-center'>
				<Button label='Dismiss' onClick={onDismiss} theme='dark' />
			</div>
		</>
	);
};

export default IsniCreatedStep;
