import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
	downloadExportESignatureAction,
	fetchAlbumExportsAction,
	fetchExportByIdAction,
	fetchRecordingExportsAction,
} from '../../../store/exports/actions';
import { Modal } from 'react-bootstrap';
import { size } from 'lodash';
import SoundCreditLoader from '../SoundCreditLoader';
import { setModalTitle } from '../../../store/modal/actions';
import PillButton from '../../layout/PillButton';
import styles from './ExportESignatureModal.module.scss';
import clsx from 'clsx';
import { Badge } from 'primereact/badge';
import ESignatureSigneeStatus from '../../../constants/esignatureSigneeStatus';

type ExportESignatureModalProps = {
	exportId: ExportMetadata['id'];
};

const getBadgeSeverity = (status: ESignatureSigneeStatus) => {
	switch (status) {
		case ESignatureSigneeStatus.SIGNED:
			return 'info';
		case ESignatureSigneeStatus.EXPIRED:
			return 'warning';
		case ESignatureSigneeStatus.DECLINED:
			return 'danger';
		case ESignatureSigneeStatus.AWAITING_SIGNATURE:
		default:
			return undefined;
	}
};

const ExportESignatureModal = ({ exportId }: ExportESignatureModalProps) => {
	const [fetchedExport, setFetchedExport] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const [isDownloading, setIsDownloading] = useState(false);

	const dispatch = useAppDispatch();
	const exportData = useAppSelector(
		state => state.exports.exportsById[exportId]
	);

	const signeesLoaded = size(exportData?.esignature?.signees) > 0;

	const downloadSignedDocument = () => {
		if (!exportData || !exportData.esignature?.isDownloadable) return;

		setIsDownloading(true);
		dispatch(downloadExportESignatureAction(exportData?.id))
			.finally(() => {
				setIsDownloading(false);
			})
			.then(() => {
				if (exportData.recordingId) {
					dispatch(fetchRecordingExportsAction(exportData.recordingId));
				} else if (exportData.albumId) {
					dispatch(fetchAlbumExportsAction(exportData.albumId));
				}
			});
	};

	useEffect(() => {
		if (fetchedExport || isFetching) {
			return;
		}

		setIsFetching(true);

		dispatch(fetchExportByIdAction(exportId)).then(() => {
			setIsFetching(false);
		});

		setFetchedExport(true);
	}, [dispatch, exportId, isFetching, fetchedExport]);

	useEffect(() => {
		dispatch(setModalTitle('DOCUMENT SIGNATURES'));
	}, [dispatch]);

	return (
		<Modal.Body>
			{isFetching && !signeesLoaded ? (
				<SoundCreditLoader />
			) : (
				<div className={clsx('px-4', styles['esignature-modal'])}>
					<div
						className='w-100 d-flex justify-content-end align-items-center mb-3'
						style={{
							gap: '0.5rem',
						}}
					>
						<PillButton
							label='Download Signed Document'
							isDisabled={!exportData?.esignature?.isDownloadable}
							onClick={downloadSignedDocument}
							tooltipText={
								exportData?.esignature?.isDownloadable
									? ''
									: 'All signees must sign before the signed document can be downloaded.'
							}
							leftIcon='fas fa-download'
							theme='solid-light'
							isLoading={isDownloading}
						/>

						<PillButton
							label='Refresh'
							onClick={() => {
								setFetchedExport(false);
							}}
							leftIcon='fas fa-sync-alt'
							isLoading={isFetching}
						/>
					</div>

					{signeesLoaded ? (
						<>
							<div className={styles['signees-label']}>
								Signees
								<hr />
							</div>
							{Object.values(exportData!.esignature!.signees)?.map(
								(signee, index) => (
									<React.Fragment key={signee.signatureId}>
										{index !== 0 && <hr />}
										<div className={styles['signee-row']}>
											<div
												className='d-flex align-items-center'
												style={{
													gap: '0.5rem',
												}}
											>
												<div>
													<p className={styles['signee-name']}>{signee.name}</p>

													<p className={styles['signee-email']}>
														{signee.emailAddress ?? 'Invited'}
													</p>
												</div>
											</div>

											<div>
												<Badge
													value={signee.status.replace('_', ' ').toUpperCase()}
													severity={getBadgeSeverity(signee.status)}
												/>
											</div>
										</div>
									</React.Fragment>
								)
							)}
						</>
					) : (
						<div
							className='w-100 d-flex justify-content-center align-items-center'
							style={{
								height: '20vh',
								textAlign: 'center',
								fontWeight: 600,
                                gap: '1rem'
							}}
						>
								<i className='fas fa-file-contract fa-3x' />
							<div>
								The list of signees is still being processed. <br />
								Please refresh in a few seconds.
							</div>
						</div>
					)}
				</div>
			)}
		</Modal.Body>
	);
};

export default ExportESignatureModal;
