import React, { useMemo } from 'react';
import './TierStatusChip.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { showModalAction } from '../../../store/modal/actions';
import { UPGRADE_TIER_MODAL } from '../../../constants/modalTypes';
import tiersById from '../../../constants/tiersById.json';
import { TierDescriptions } from '../../../constants/tierDescriptions';
// import PromoType from '../../../store/promos/promoType';
// import TierId from '../../../constants/tierIds';
// import { setPromoActiveAction } from '../../../store/promos/actions';
import clsx from 'clsx';

const TierStatusChip = ({ className = '' }) => {
	const { userPermissions } = useAppSelector(state => state.auth);
	// const { activePromos } = useAppSelector(state => state.promos);
	const dispatch = useAppDispatch();

	const isEnterprise =
		userPermissions?.subscriptionTier ===
		TierDescriptions.ENTERPRISE.ENTERPRISE.id;

	// only show if the promo is active and the user has a free tier
	// const showUpgradePromoAnimation =
	// 	activePromos[PromoType.NewUserDiscountJanuary2024] &&
	// 	userPermissions?.subscriptionTier &&
	// 	[TierId.LITE, TierId.LITE_PLUS].includes(userPermissions?.subscriptionTier);

	const handleClick = () => {
		if (isEnterprise) return;

		dispatch(
			showModalAction(UPGRADE_TIER_MODAL, {
				size: 'xl',
			})
		);
	};
	const tierName = useMemo(
		() =>
			userPermissions?.subscriptionTier != null
				? tiersById[
						userPermissions.subscriptionTier.toString() as keyof typeof tiersById
				  ]
				: '',
		[userPermissions]
	);

	return (
		<div
			className={clsx('tier-status-chip', className, {
				'is-upgradable': !isEnterprise,
			})}
			onClick={handleClick}
		>
			<>{tierName} TIER</>
		</div>
	);
};

export default TierStatusChip;
