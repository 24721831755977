import React from 'react';
import {
	HIDE_ALERT_TOAST,
	SHOW_ALERT_TOAST,
	SHOW_ERROR_ALERT,
} from '../actionTypes';
import { AlertToastState } from './reducer';

export const showAlertToast = (
	message: string | React.ReactNode,
	level?: AlertToastState['level']
) => ({
	type: SHOW_ALERT_TOAST,
	message,
	level,
});

export const hideAlertToast = () => ({
	type: HIDE_ALERT_TOAST,
});

export const showErrorAlert = (message: string) => ({
	type: SHOW_ERROR_ALERT,
	message,
});
