import React from 'react';
import Stepper from 'react-stepper-horizontal';
import theme from '../../../../../theme.module.scss';
import './ExportStepper.scss';

const ExportStepper = ({ steps, activeStep }) => {
	return (
		<div className='export-stepper'>
			<Stepper
				steps={steps}
				activeStep={activeStep}
				titleFontSize={14}
				completeColor={theme.primary}
				activeColor={theme.primary}
				circleFontColor='transparent'
				size={16}
				defaultBorderColor={theme.primary}
				defaultColor='white'
				defaultBorderStyle='solid'
				defaultBorderWidth={2}
			/>
		</div>
	);
};

export default ExportStepper;
