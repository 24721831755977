import UPLOAD_STATUS from '../constants/uploadStatus.json';
import {
	fetchFileTimestampsMetadata,
	generateFilePath,
	getAudioDuration,
} from './fileTools';

export const S3_UPLOAD_METADATA_HEADERS = {
	updatedAt: 'updated-at',
	createdAt: 'created-at',
};

export const S3_DOWNLOAD_METADATA_HEADERS = {
	updatedAt: `x-amz-meta-${S3_UPLOAD_METADATA_HEADERS.updatedAt}`,
	createdAt: `x-amz-meta-${S3_UPLOAD_METADATA_HEADERS.createdAt}`,
};

/**
 *
 * @param {{
 * 	inputFiles: File[],
 * 	playlistId: Playlist['id'] | null | undefined,
 *	filenames: string[],
 *	projectIds: { recordingId: number, albumId: number }[] | null | undefined,
 *	projectId: { recordingId: number | null, albumId: number | null },
 *	playlistDisplayNames: string[] | null | undefined,
 *	labels: FileMetadata['label'][] | null | undefined,
 * }} param0
 * @returns object containing necessary data for adding files to uploads queue
 */
export const createUploads = async ({
	inputFiles,
	playlistId = null,
	filenames,
	projectIds = null, // if the recordingId and albumId are different for each file, they can be passed in as an array of objects
	projectId = { recordingId: null, albumId: null }, // if the same recordingId and albumId are used for all files, they can be passed in as a single object}
	playlistDisplayNames = null,
	fileVersionsMetadata = null, // used for uploading file versions
}: {
	inputFiles: FileWithLabel[];
	playlistId?: number | null;
	filenames: string[];
	projectIds?: { recordingId: number | null; albumId: number | null }[] | null;
	projectId?: { recordingId: number | null; albumId: number | null };
	playlistDisplayNames?: string[] | null;
	fileVersionsMetadata?:
		| NonNullable<ProjectFileUpload['versionMetadata']>[]
		| null;
}): Promise<Record<string, ProjectFileUpload>> => {
	console.log('Creating Uploads');

	const durations = await Promise.all(inputFiles.map(f => getAudioDuration(f)));

	console.log(durations, 'DURATIONS');

	const filesWithMetadata = await Promise.all(
		inputFiles.map(async (f, index) => {
			let recId = projectId.recordingId;
			let albId = projectId.albumId;

			if (projectIds) {
				recId = projectIds[index]?.recordingId;
				albId = projectIds[index]?.albumId;
			}

			const fileTimestamps = await fetchFileTimestampsMetadata(f);
			let curFile: File = new File([f], filenames[index], {
				type: f.type,
			});

			// curFile.webkitRelativePath = f.webkitRelativePath;

			return {
				file: curFile,
				versionMetadata: fileVersionsMetadata?.[index] ?? null,
				metadata: {
					recordingId: recId,
					albumId: albId,
					path: generateFilePath({
						filename: filenames[index],
					}),
					label: f.label ?? 0,
					duration: durations[index],
					filename: filenames[index],
					fileSize: f.size,
					sourceCreatedAt: fileTimestamps?.createdAt ?? null,
					sourceUpdatedAt: fileTimestamps?.updatedAt ?? null,
				},
			};
		})
	);

	const newUploads = {} as Record<string, ProjectFileUpload>;

	filesWithMetadata.forEach((file, index) => {
		const id = file.metadata.path;

		newUploads[id] = {
			id,
			playlistMetadata:
				playlistId && playlistDisplayNames
					? {
							playlistId, // used for cross-referencing with playlist files that are being uploaded
							displayName: playlistDisplayNames?.[index],
					  }
					: null,
			metadata: file.metadata,
			file: file.file,
			status: UPLOAD_STATUS.READY_TO_UPLOAD,
			progress: 0,
			versionMetadata: file.versionMetadata,
			// cancelSource: axios.CancelToken.source(), // used for signed URL uploads, which are currently not supported
		};
	});

	return newUploads;
};
