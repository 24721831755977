import { useCallback, useMemo, useState } from 'react';
import { useEffect } from 'react';
import ParticipantModal from '../ParticipantModal';
import { getCurrentRecording } from '../../../../store/projects/selectors';
import { Card, Col, Row } from 'react-bootstrap';
import { useFormikContext } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
	ParticipantTableColumn,
	RoleWithStudio,
	extractRolesWithStudiosFromParticipant,
	extractUniquePublishersFromParticipant,
	extractUniqueRolesFromParticipant,
	filterParticipants,
} from '../../../../helpers/participantTools';
import { MultiSelect, MultiSelectChangeEvent } from 'primereact/multiselect';
import { DataTable, DataTableRowReorderEvent } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { debounce, groupBy, kebabCase } from 'lodash';
import { Tooltip } from 'primereact/tooltip';
import { Chip } from 'primereact/chip';
import styles from './ParticipantsTable.module.scss';
import isWriterRole from '../../../../helpers/isSpecialRole';
import { setParticipantsSearchFilter } from '../../../../store/participants/actions';
import SearchBar from '../../../layout/SearchBar';
import { DELETE_PARTICIPANT_MODAL } from '../../../../constants/modalTypes';
import { showModalAction } from '../../../../store/modal/actions';
import clsx from 'clsx';
import theme from '@/theme.module.scss';
import { setVisibleParticipantTableColumnsAction } from '../../../../store/session/actions';
import { Button } from 'primereact/button';

const getRoleWithStudioChipId = (
	participantId: Participant['id'],
	role: RoleWithStudio,
	index: number
) =>
	kebabCase(`p${participantId}-${role.roleDetail}-${role.studioName}-${index}`);

const getRoleChipId = (
	participantId: Participant['id'],
	role: string,
	index: number
) => kebabCase(`p${participantId}-${role}-${index}`);

const getPublisherChipId = (
	participantId: Participant['id'],
	publisherId: number
) => kebabCase(`p${participantId}-${publisherId}`);

const getRoleChipContent = ({ roleDetail }: { roleDetail: string }) =>
	roleDetail;

const columns = [
	ParticipantTableColumn.STUDIOS,
	ParticipantTableColumn.PUBLISHERS,
];

const HEADER_INPUT_HEIGHT = '2.5rem';

function ParticipantsTable() {
	const dispatch = useAppDispatch();
	const recordingFormik = useFormikContext<RecordingContent>();
	const [currentParticipants, setCurrentParticipants] = useState<Participant[]>(
		[]
	);
	const currentRecording = useAppSelector(getCurrentRecording);
	const [showEditParticipantModal, setShowEditParticipantModal] =
		useState(false);
	const [editingParticipant, setEditingParticipant] =
		useState<Participant | null>(null);
	const [searchFilter, setSearchFilter] = useState('');

	const { visibleParticipantTableColumns } = useAppSelector(
		state => state.session
	);
	const { roleFilters } = useAppSelector(state => state.participants);
	const { myEditorProfile } = useAppSelector(state => state.projects);

	const showStudios = visibleParticipantTableColumns.includes(
		ParticipantTableColumn.STUDIOS
	);
	const showPublishers = visibleParticipantTableColumns.includes(
		ParticipantTableColumn.PUBLISHERS
	);

	const toggleShowEditParticipantModal = useCallback(
		() => setShowEditParticipantModal(p => !p),
		[]
	);

	const isReadOnly = myEditorProfile ? myEditorProfile.is_read_only : true;

	const onDragEnd = (event: DataTableRowReorderEvent<Participant[]>) => {
		const { dragIndex, dropIndex } = event;

		if (dragIndex === dropIndex) {
			return;
		}

		const newValues = event.value;

		if (recordingFormik) {
			console.log(
				'recordingFormik'.toUpperCase(),
				recordingFormik.values.participants
			);
			recordingFormik.setFieldValue('participants', newValues);
		}

		setCurrentParticipants(newValues);
	};

	const dispatchSearchFilter = (filter: string) => {
		dispatch(setParticipantsSearchFilter(filter));
	};

	const debouncedDispatchSearchFilter = useMemo(
		() => debounce(dispatchSearchFilter, 250),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const onColumnToggle = (event: MultiSelectChangeEvent) => {
		let selectedColumns = event.value as ParticipantTableColumn[];
		let orderedSelectedColumns = columns.filter(col =>
			selectedColumns.some(selected => selected === col)
		);

		dispatch(setVisibleParticipantTableColumnsAction(orderedSelectedColumns));
	};

	const handleDelete = useCallback(
		(participant: Participant) => {
			dispatch(
				showModalAction(DELETE_PARTICIPANT_MODAL, {
					participant,
					size: 'md',
					deleteFromRecording: true,
					recordingId: currentRecording?.id,
				})
			);
		},
		[currentRecording?.id, dispatch]
	);

	const showEditModal = useCallback(
		(participant: Participant) => {
			setEditingParticipant(participant);
			// setEditing(true);
			toggleShowEditParticipantModal();
		},
		[toggleShowEditParticipantModal]
	);

	const header = (
		<Row
			className='align-items-stretch justify-content-between'
			style={{
				gap: '1rem',
			}}
		>
			<Col xs={12} lg={5}>
				<div className={styles['header-input-label']}>Show/Hide Columns</div>
				<MultiSelect
					placeholder='Show Studios and Publishing Companies'
					value={visibleParticipantTableColumns}
					options={[
						{ header: 'Studios', value: ParticipantTableColumn.STUDIOS },
						{
							header: 'Publishing Companies',
							value: ParticipantTableColumn.PUBLISHERS,
						},
					]}
					optionLabel='header'
					onChange={onColumnToggle}
					className='w-100'
					display='chip'
					style={{
						height: HEADER_INPUT_HEIGHT,
					}}
					pt={{
						item: {
							style: {
								fontSize: '0.8rem',
							},
						},
						label: {
							style: {
								display: 'flex',
								height: '100%',
								fontSize: '0.8rem',
								alignItems: 'center',
							},
						},
						token: {
							style: {
								color: 'white',
								fontSize: '0.8rem',
								backgroundColor: theme.primary,
								display: 'flex',
								alignItems: 'center',
							},
						},
						tokenLabel: {
							style: {},
						},
					}}
				/>
			</Col>

			<Col xs={12} lg={5}>
				<div className='d-flex align-items-end w-100 h-100'>
					<SearchBar
						value={searchFilter}
						onChange={setSearchFilter}
						height={HEADER_INPUT_HEIGHT}
						fullWidth
					/>
				</div>
			</Col>
		</Row>
	);

	const renderRolesWithStudios = useCallback(
		(participant: Participant) => {
			const rolesWithStudios = extractRolesWithStudiosFromParticipant(
				participant,
				recordingFormik.values.studios
			);

			if (rolesWithStudios.length === 0) {
				return (
					<>
						<p className='mb-0'>No roles</p>
					</>
				);
			}

			const rolesGroupedByStudio = groupBy(
				rolesWithStudios,
				role => role.studioName
			);

			return (
				<div className={styles['studio-roles-list-container']}>
					{Object.entries(rolesGroupedByStudio).map(([studio, studioRoles]) => {
						return (
							<div className={styles['studio-roles-container']} key={studio}>
								<div className={styles['studio-header']}>
									{studio || 'No Studio Listed'}{' '}
								</div>
								<div className={styles['chips-container']}>
									{studioRoles.map((role, index) => {
										const roleChipId = getRoleWithStudioChipId(
											participant.id,
											role,
											index
										);

										return (
											<>
												<Tooltip
													content={getRoleChipContent(role)}
													showDelay={1000}
													target={`#${roleChipId}`}
												/>
												<Chip
													className={styles['role-chip']}
													id={roleChipId}
													key={roleChipId}
													label={getRoleChipContent(role)}
												/>
											</>
										);
									})}
								</div>
							</div>
						);
					})}
				</div>
			);
		},
		[recordingFormik.values.studios]
	);

	const renderRolesWithoutStudios = useCallback((participant: Participant) => {
		const roles = extractUniqueRolesFromParticipant(participant);

		if (roles.length === 0) {
			return (
				<>
					<p className='mb-0'>No roles</p>
				</>
			);
		}

		return (
			<div className={styles['chips-container']}>
				{roles.map((role, index) => {
					const roleChipId = getRoleChipId(participant.id, role, index);
					return (
						<>
							<Tooltip
								content={getRoleChipContent({ roleDetail: role })}
								showDelay={1000}
								target={`#${roleChipId}`}
							/>
							<Chip
								className={styles['role-chip']}
								id={roleChipId}
								key={roleChipId}
								label={role}
							/>
						</>
					);
				})}
			</div>
		);
	}, []);

	const renderPublishers = useCallback(
		(participant: Participant) => {
			const uniquePublishers = extractUniquePublishersFromParticipant(
				participant,
				recordingFormik.values.publishers
			);

			return !participant?.roles?.find(isWriterRole) ? (
				<p className='mb-0'>—</p>
			) : uniquePublishers.length > 0 ? (
				<div className={styles['chips-container']}>
					{uniquePublishers.map(publisher => {
						const publisherChipId = getPublisherChipId(
							participant.id,
							publisher.id
						);

						return (
							<>
								<Tooltip
									content={publisher.name}
									showDelay={1000}
									target={`#${publisherChipId}`}
								/>
								<Chip
									className={styles['role-chip']}
									key={publisher.id}
									label={publisher.name}
									id={publisherChipId}
								/>
							</>
						);
					})}
				</div>
			) : (
				<>
					<span className='mb-0'>No publishing company</span>
					<Button
						rounded
						text
						icon='fas fa-plus'
						tooltip='Add publishing company'
						className='p-0'
						onClick={() => {
							setEditingParticipant(participant);
							toggleShowEditParticipantModal();
						}}
					/>
				</>
			);
		},
		[recordingFormik.values.publishers, toggleShowEditParticipantModal]
	);

	const renderActions = useCallback(
		(participant: Participant) => (
			<div
				className='row d-flex justify-content-around w-100'
				style={{
					gap: '0.5rem',
				}}
			>
				{participant.notes && (
					<>
						<Tooltip
							position='left'
							content={participant.notes}
							target={`#p${participant.id}-note`}
						/>
						<button
							className='btn p-0'
							id={`p${participant.id}-note`}
							type='button'
						>
							<i
								className='far fa-sticky-note'
								style={{ color: '#6b70e7' }}
							></i>
						</button>
					</>
				)}
				{!isReadOnly && (
					<button
						className='btn p-0'
						type='button'
						onClick={() => handleDelete(participant)}
					>
						<i className='fa fa-trash-alt text-red'></i>
					</button>
				)}
				{!isReadOnly && (
					<button
						className='btn p-0'
						type='button'
						onClick={() => showEditModal(participant)}
					>
						<i className='fa fa-edit text-purple'></i>
					</button>
				)}
			</div>
		),
		[isReadOnly, showEditModal, handleDelete]
	);

	useEffect(() => {
		console.log(searchFilter);
		debouncedDispatchSearchFilter(searchFilter);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchFilter]);

	useEffect(() => {
		if (currentRecording?.recording?.participants) {
			const filteredParticipants = filterParticipants(
				currentRecording.recording.participants,
				roleFilters,
				searchFilter
			);
			setCurrentParticipants(filteredParticipants);
		}
	}, [currentRecording, roleFilters, searchFilter]);

	return (
		<>
			<Card className='p-4'>
				<h2>CREDITS</h2>
				<DataTable
					value={currentParticipants}
					header={header}
					reorderableRows={!isReadOnly}
					onRowReorder={onDragEnd}
					tableClassName={clsx(styles['participants-table'], {
						[styles['row-readonly']]: isReadOnly,
						[styles['show-publishers']]: showPublishers,
					})}
				>
					{!isReadOnly && <Column rowReorder style={{ width: '3rem' }} />}

					<Column
						field='creditedName'
						header='Name'
						bodyClassName={styles['participant-name']}
					/>

					{}
					{showStudios ? (
						<Column header='Roles' body={renderRolesWithStudios} />
					) : (
						<Column header='Roles' body={renderRolesWithoutStudios} />
					)}

					{showPublishers ? (
						<Column header='Publishing Companies' body={renderPublishers} />
					) : null}

					<Column header='' body={renderActions} />
				</DataTable>
			</Card>

			{showEditParticipantModal && (
				<ParticipantModal
					show={showEditParticipantModal}
					toggleShow={toggleShowEditParticipantModal}
					participant={editingParticipant}
					editing={true}
					setParticipant={setEditingParticipant}
				/>
			)}
		</>
	);
}

export default ParticipantsTable;
