export type EmptyPlaceholderProps = {
	title: string | JSX.Element;
	icon: string;
	description?: string | JSX.Element;
};

const EmptyPlaceholder = ({
	title,
	icon,
	description = '',
}: EmptyPlaceholderProps) => {
	return (
		<div className='select-project-files__empty-placeholder'>
			<i className={icon} />
			<h3>{title}</h3>
			<p>{description}</p>
		</div>
	);
};

export default EmptyPlaceholder;
