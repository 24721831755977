import React from 'react';
import { Col, Row } from 'react-bootstrap';
import 'react-circular-progressbar/dist/styles.css';
import '../FileUploadMenu.scss';
import theme from '../../../../theme.module.scss';
import FileUploadItemProgress from './FileUploadItemProgress';
import FileUploadItemActions from './FileUploadItemActions';

export type FileUploadItemProps = {
	file: ProjectFileUpload;
	showViewInFolder?: boolean;
};

const FileUploadItem = ({
	file,
	showViewInFolder = true,
}: FileUploadItemProps) => {
	return (
		<Row className='file-upload-item-container'>
			<Col xs={2} className='d-flex justify-content-center align-items-center '>
				<FileUploadItemProgress upload={file} />
			</Col>
			<Col
				xs={6}
				className='d-flex justify-content-center align-items-start flex-column'
			>
				<div
					className='fw-600 file-upload-item-name'
					style={{ lineHeight: '1.1rem', fontSize: '0.9rem' }}
				>
					{file.metadata?.filename}
				</div>
				{file.errorMessage ? (
					<small
						style={{
							color: theme.error,
							lineHeight: '0.8rem',
							fontSize: '0.75rem',
						}}
					>
						{file.errorMessage}
					</small>
				) : (
					<></>
				)}
			</Col>
			<Col xs={4} className='d-flex align-items-center justify-content-end'>
				<FileUploadItemActions
					upload={file}
					showViewInFolder={showViewInFolder}
				/>
			</Col>
		</Row>
	);
};

export default FileUploadItem;
