import React from 'react';
import { ValidationError } from 'yup';
import { Button } from 'primereact/button';
import clsx from 'clsx';

type SectionProps = {
	headerTitle?: string | JSX.Element;
	headerSubtitle?: string | JSX.Element;
	errors: ValidationError[];
	onFixClick?: () => void;
	className?: string;
};

type Props = SectionProps & {
	nestedSections?: SectionProps[];
};

const ExportValidationSection = ({
	headerTitle = '',
	headerSubtitle = '',
	errors,
	className = '',
	onFixClick,
	nestedSections,
}: Props) => {
	return (
		<div>
			<header className='validation-error-section-header'>
				<div className='d-flex align-items-center justify-content-between'>
					{headerTitle || <div />}
					{onFixClick ? (
						<Button
							rounded
							label='Fix Issue(s)'
							onClick={onFixClick}
							className='ml-2 py-1 px-2'
							icon='fas fa-wrench'
							size='small'
							severity='secondary'
						/>
					) : null}
				</div>
				{headerSubtitle ? (
					<div className='validation-error-section-subheader'>
						{headerSubtitle}
					</div>
				) : null}
				{/* <hr /> */}
			</header>

			<div className={clsx('validation-error-container', className)}>
				<div className='w-100'>
					<ul>
						{errors?.map((error, index) => (
							<li key={index}>{error.message}</li>
						))}
					</ul>

					{nestedSections
						? nestedSections.map((section, index) => (
								<div className='w-100 px-2' key={`nested-${index}`}>
									<ExportValidationSection {...section} className='validation-error-container--nested' />
								</div>
						  ))
						: null}
				</div>
			</div>
		</div>
	);
};

export default ExportValidationSection;
