import isWriterRole from './isSpecialRole';
import * as yup from 'yup';
import roleFilterOptions from '@/constants/roleFilterOptions.json';

import {
	createFullPublisherFromFormPublisher,
	flattenPublishers,
	getUniqueAssociatedPublishers,
	isPublisherEqual,
} from './publisherTools';
import _, { uniq } from 'lodash';
import {
	getAllAssociatedStudios,
	mergeParticipantAndAssociatedRoles,
	mergeParticipantAndAssociatedStudios,
} from './studioTools';
import { ipiCaeRegex, lastFourOrFullSsnRegex } from './regex';

// Information that should not be there, or information that
// comes from the cloud that we need to filter.

export const unnecessaryParticipantFields = [
	'isClaimed',
	'ssnLastFour',
	'associatedStudioName',
	'firstAliasIsPrimary',
	'associatedStudioCountry',
	'suggestionId',
	'postCode',
	'imagePath',
	'isSavedProfile',
	'profileId',
	'aliases',
	'associatedPublishers',
];

export const convertParticipantToFormParticipant = (
	participant: Participant & {
		otherAliases: string | string[];
		firstAliasIsPrimary?: boolean;
	},
	associatedPublishers: RecordingPublisher[]
): ParticipantForm => {
	const participantForm = _.cloneDeep(
		_.omit(participant, unnecessaryParticipantFields)
	) as ParticipantForm;

	if (!containsWriterRoles(participant.roles)) {
		participantForm.publishers = [];
		participantForm.ipiCae = '';
	} else if (participant.publishers) {
		// Join the publishers from the recording with the ones from the participant
		// The join is only done for local use in the form
		participantForm.publishers = joinParticipantsWithFullPublishers(
			participant.publishers,
			associatedPublishers
		);
	} else {
		// For null safety
		// This should never happen, as all participants with writer roles
		// should at least have 1 publisher
		participantForm.publishers = [];
	}

	if (!participant.creditedName)
		participantForm.creditedName = extractCreditedName(participant);
	// console.log('PM FLAT 1');
	//

	return participantForm;
};

export const convertOtherAliasesToArray = (otherAliases: string | null) =>
	otherAliases?.split(',').map(s => s.trim()) ?? [];

export const convertOtherAliasesToString = (otherAliases: string[] | null) =>
	otherAliases?.join(', ') ?? '';

const extractCreditedName = (
	participant: Participant & {
		otherAliases: string | string[];
		firstAliasIsPrimary?: boolean;
	}
) => {
	let otherAliasesList: string[] = [];

	if (Array.isArray(participant.otherAliases)) {
		otherAliasesList = participant.otherAliases;
	} else if (typeof participant.otherAliases === 'string') {
		otherAliasesList = convertOtherAliasesToArray(participant.otherAliases);
	}

	if (
		(participant.firstAliasIsPrimary || !participant.creditedName) &&
		otherAliasesList.length > 0
	)
		return otherAliasesList[0];

	return participant.legalName ?? '';
};

export const containsWriterRoles = (
	roles:
		| ParticipantRole[]
		| LocalProfileRole[]
		| LocalProfileDTORole[]
		| CreditRequestRole[]
) => roles?.some(isWriterRole);

// Two profiles are equal when they have the same creditedName and the same roles (without considering studios)
export const isProfileEqual = (
	profile1: FullLocalProfile | Participant | ParticipantForm | LocalProfile,
	profile2: FullLocalProfile | Participant | ParticipantForm | LocalProfile
) => {
	return (
		profile1?.creditedName?.trim()?.toLowerCase() ===
			profile2?.creditedName?.trim()?.toLowerCase() &&
		_.isEqual(
			profile1?.roles?.map(role => role.detail).sort(), // we sort because order doesn't matter for equality
			profile2?.roles?.map(role => role.detail).sort()
		)
	);
};

/*
 * Removes extra publisher properties from participant, as they are referenced from the recording's publishers
 */
export const deleteUnnecessaryParticipantPublisherData = (
	publishers: ParticipantFormPublisher[]
): ParticipantPublisher[] =>
	publishers.map(formPublisher => ({
		publisherId: formPublisher.publisherId,
		territory: formPublisher.territory,
		splitPercentage: formPublisher.splitPercentage,
		subPublishers: formPublisher.subPublishers?.map(formSubPublisher => ({
			publisherId: formSubPublisher.publisherId,
			territory: formSubPublisher.territory,
			splitPercentage: formSubPublisher.splitPercentage,
		})),
	}));

export const convertProfilePublishersToParticipantForm = (
	profilePublishers: LocalProfilePublisher[],
	associatedPublishers: RecordingPublisher[] | LocalProfileAssociatedPublisher[]
): ParticipantFormPublisher[] =>
	profilePublishers.map(profilePublisher => {
		const foundPublisher = associatedPublishers.find(
			recPublisher =>
				profilePublisher &&
				profilePublisher.publisherId &&
				recPublisher.id === profilePublisher.publisherId
		);

		if (!foundPublisher)
			throw new Error(
				'Publisher not found in recording associated publishers',
				{ participantPublisher: profilePublisher, associatedPublishers } as any
			);

		return {
			publisherId: foundPublisher.id,
			pro: foundPublisher.pro,
			name: foundPublisher.name,
			ipi: foundPublisher.ipi ?? '',
			email: foundPublisher.email ?? '',
			territory: profilePublisher.territory,
			splitPercentage: null,
			subPublishers:
				profilePublisher.subPublishers?.map(participantSubP => {
					const subPublisherFound = associatedPublishers.find(
						recPublisher => recPublisher.id === participantSubP.publisherId
					);

					if (!subPublisherFound)
						throw new Error(
							'Sub-publisher not found in recording associated publishers',
							{ participantSubP, associatedPublishers } as any
						);

					return {
						publisherId: subPublisherFound.id,
						pro: subPublisherFound.pro,
						name: subPublisherFound.name,
						ipi: subPublisherFound.ipi ?? '',
						email: subPublisherFound.email ?? '',
						territory: participantSubP.territory,
						splitPercentage: null,
					};
				}) ?? [],
		};
	});

export const joinParticipantsWithFullPublishers = (
	participantPublishers: ParticipantPublisher[],
	associatedPublishers: RecordingPublisher[] | LocalProfileAssociatedPublisher[]
): ParticipantFormPublisher[] =>
	participantPublishers.map(participantPublisher => {
		const foundPublisher = associatedPublishers.find(
			recPublisher =>
				participantPublisher &&
				participantPublisher.publisherId &&
				recPublisher.id === participantPublisher.publisherId
		);

		if (!foundPublisher)
			throw new Error(
				'Publisher not found in recording associated publishers',
				{ participantPublisher, associatedPublishers } as any
			);

		return {
			publisherId: foundPublisher.id,
			pro: foundPublisher.pro,
			name: foundPublisher.name,
			ipi: foundPublisher.ipi ?? '',
			email: foundPublisher.email ?? '',
			territory: participantPublisher.territory,
			splitPercentage: participantPublisher.splitPercentage,
			subPublishers:
				participantPublisher.subPublishers?.map(participantSubP => {
					const subPublisherFound = associatedPublishers.find(
						recPublisher => recPublisher.id === participantSubP.publisherId
					);

					if (!subPublisherFound)
						throw new Error(
							'Sub-publisher not found in recording associated publishers',
							{ participantSubP, associatedPublishers } as any
						);

					return {
						publisherId: subPublisherFound.id,
						pro: subPublisherFound.pro,
						name: subPublisherFound.name,
						ipi: subPublisherFound.ipi ?? '',
						email: subPublisherFound.email ?? '',
						territory: participantSubP.territory,
						splitPercentage: participantSubP.splitPercentage,
					};
				}) ?? [],
		};
	});

/*
 * The participant form contains the full publisher data in the form.publishers field,
 * thus we use that data to create the recording's publishers
 */
export const mergeParticipantAndRecordingPublishers = (
	participantPublishers: ParticipantFormPublisher[],
	recordingPublishers: RecordingPublisher[] = []
) => {
	const allParticipantPublishers = (flattenPublishers(participantPublishers) ??
		[]) as ParticipantFormPublisher[];

	// If a publisher with the same ID already exists in the recording, we need to check
	// if they're equal. If they are, then we merge them, otherwise we create a new one with a different ID
	const fullNewParticipantPublishers = allParticipantPublishers.map(p =>
		createFullPublisherFromFormPublisher(p)
	);

	// Compute unique new publishers
	const uniqueNewPublishers = getUniqueAssociatedPublishers(
		fullNewParticipantPublishers
	);

	// Now we need to merge the new publishers with the existing ones running the same computation
	const mergedPublishers = getUniqueAssociatedPublishers([
		...recordingPublishers,
		...uniqueNewPublishers,
	]);

	return mergedPublishers;
};

export const participantValidationSchema = yup.object().shape({
	creditedName: yup
		.string()
		.nullable()
		.transform((curr, orig) => (!orig ? '' : curr))
		.required('Credited name is required'),
	email: yup
		.string()
		.nullable()
		.transform((curr, orig) => (!orig ? '' : curr))
		.email('Invalid email address'),
	ipiCae: yup
		.string()
		.nullable()
		.transform((curr, orig) => (!orig ? '' : curr))
		.matches(ipiCaeRegex, {
			message: 'Must be only digits',
			excludeEmptyString: true,
		})
		.min(9, 'Must be 9 - 11 digits long')
		.max(11, 'Must be 9 - 11 digits long')
		.nullable(),
	socialLastFour: yup
		.string()
		.nullable()
		.transform((curr, orig) => (!orig ? '' : curr))
		.matches(lastFourOrFullSsnRegex, {
			message: 'Invalid Social Security Number',
			excludeEmptyString: true,
		}),
	participationCountry: yup.string().nullable(),
	publishers: yup.array().of(
		yup.object().shape({
			publisherId: yup.number().required('Publisher is required'),
			pro: yup
				.string()
				.nullable()
				.transform((curr, orig) => (!orig ? '' : curr)),
			splitPercentage: yup.number().nullable(),
			email: yup
				.string()
				.nullable()
				.transform((curr, orig) => (!orig ? '' : curr))
				.email('Invalid email address'),
			name: yup
				.string()
				.nullable()
				.transform((curr, orig) => (!orig ? '' : curr)),
			ipi: yup
				.string()
				.nullable()
				.transform((curr, orig) => (!orig ? '' : curr))
				.matches(ipiCaeRegex, {
					message: 'Must be only digits',
					excludeEmptyString: true,
				})
				.min(9, 'Must be 9 - 11 digits long')
				.max(11, 'Must be 9 - 11 digits long')
				.nullable(),
			subPublishers: yup.array().of(
				yup.object().shape({
					publisherId: yup.number().required('Publisher is required'),
					pro: yup
						.string()
						.nullable()
						.transform((curr, orig) => (!orig ? '' : curr)),
					splitPercentage: yup.number().nullable(),
					name: yup
						.string()
						.nullable()
						.transform((curr, orig) => (!orig ? '' : curr)),
					ipi: yup
						.string()
						.nullable()
						.transform((curr, orig) => (!orig ? '' : curr))
						.matches(ipiCaeRegex, {
							message: 'Must be only digits',
							excludeEmptyString: true,
						})
						.min(9, 'Must be 9 - 11 digits long')
						.max(11, 'Must be 9 - 11 digits long')
						.nullable(),
					// email: yup.string().email('Invalid email address')
				})
			),
		})
	),

	copyrightOwnerClaim: yup.object().when('isCopyrightOwner', {
		is: true,
		then: yup.object().shape({
			cmo: yup
				.string()
				.nullable()
				.transform((curr, orig) => (!orig ? '' : curr)),
			cmoId: yup
				.string()
				.nullable()
				.transform((curr, orig) => (!orig ? '' : curr)),
			splitPercentage: yup
				.number()
				.min(0, 'Split Percentage must be greater than or equal to 0')
				.max(100, 'Split Percentage must be less than or equal to 100')
				.nullable(),
			territoryOwnership: yup
				.string()
				.nullable()
				.transform((curr, orig) => (!orig ? '' : curr)),
		}),
	}),
	roles: yup
		.array()
		.of(
			yup.object().shape({
				category: yup.string().nullable(),
				detail: yup
					.string()
					.nullable()
					.transform((curr, orig) => (!orig ? '' : curr))
					.required('Role is required'),
				studio: yup.object().nullable(),
				country: yup
					.string()
					.nullable()
					.transform((curr, orig) => (!orig ? '' : curr)),
			})
		)
		.min(1, 'At least one role is required'),
});

/*
 * In this function, participant may also be a profile
 *
 * When used for calculating a recording's participant, the newAsssociatedStudios are meant for recording.studios
 * and the newAssociatedPublishers are meant for recording.publishers
 * and the newParticipant is meant for recording.participants
 *
 * When used for calculating a profile's participant, the newAssociatedStudios are meant for profile.associatedStudios
 * and the newAssociatedPublishers are meant for profile.associatedPublishers
 * and the newParticipant is meant to be the profile itself
 */

export const calculateParticipantWithStudiosAndPublishers = ({
	participantForm,
	associatedStudios,
	associatedPublishers,
	studioIdsInUse,
	publisherIdsInUse,
}: {
	participantForm: ParticipantForm;
	associatedStudios: RecordingStudio[];
	associatedPublishers: RecordingPublisher[];
	studioIdsInUse: number[];
	publisherIdsInUse: number[];
}): {
	newParticipant: Participant;
	newAssociatedStudios: RecordingStudio[];
	newAssociatedPublishers:
		| RecordingPublisher[]
		| LocalProfileAssociatedPublisher[];
} => {
	const newParticipant = {
		...participantForm,
		publishers: _.cloneDeep(participantForm.publishers) ?? [],
	} as Participant;

	let newAssociatedPublishers = associatedPublishers ?? [];
	let newAssociatedStudios = associatedStudios ?? [];

	// --------- PUBLISHER LOGIC ---------

	// Clear writer details if participant is not a writer
	if (!containsWriterRoles(newParticipant.roles)) {
		newParticipant.publishers = [];
		newParticipant.ipiCae = '';
	} else {
		// First, keep publishers that are already in use
		// For editing/creating participants, the publishersInUse will be the recording's participant's publishers
		// without including the new participant's publishers
		// For editing/creating profiles, the publishersInUse will be an empty array since it's like a recording with 1 participant
		// ! This is necessary in case we're editing a participant and references to publishers are removed from the participant
		const associatedPublishersInUse = associatedPublishers.filter(p =>
			publisherIdsInUse.includes(p.id)
		);

		// If we're creating a participant, we need to extract the existing publishers from the recording's publishers
		// and join them with the new publishers in the participant

		// If we're creating a profile, the associatedPublishers will be an empty array, since the only associated publishers
		// are the profile's publishers
		// This step also creates new IDs for publishers that are equal in name and PRO but differ in IPI or email
		newAssociatedPublishers = mergeParticipantAndRecordingPublishers(
			participantForm.publishers,
			associatedPublishersInUse
		);

		// overwrite the participant's publishers with the new publishers
		newParticipant.publishers = participantForm.publishers.map(p => {
			const associatedPublisher = newAssociatedPublishers.find(np =>
				isPublisherEqual(p, np)
			);

			if (!associatedPublisher) {
				throw new Error('Associated publisher not found');
			}

			return {
				...p,
				publisherId: associatedPublisher.id,
			};
		});

		console.log('newAssociatedPublishers', newAssociatedPublishers);
		console.log('new Publishers', newParticipant.publishers);
	}

	// Delete unnecessary fields from participant publishers, as we
	// only needed them for the mergeParticipantAndRecordingPublishers function

	newParticipant.publishers = deleteUnnecessaryParticipantPublisherData(
		participantForm.publishers
	);

	// --------- STUDIO LOGIC ---------
	const fullParticipantStudios = getAllAssociatedStudios(newParticipant);

	// Like with publishers, we need to keep studios that are already in use, following the same logic
	const associatedStudiosInUse = associatedStudios.filter(s =>
		studioIdsInUse.includes(s.id)
	);

	// add new integer IDs to all newly selected studios within the roles
	newAssociatedStudios = mergeParticipantAndAssociatedStudios(
		associatedStudiosInUse,
		fullParticipantStudios
	);

	// then overwrite the studios in the roles with the new studio IDs
	newParticipant.roles = mergeParticipantAndAssociatedRoles(
		newAssociatedStudios,
		fullParticipantStudios,
		newParticipant.roles
	);

	return { newParticipant, newAssociatedStudios, newAssociatedPublishers };
};

export const convertProfileToParticipantForm = (
	profile: LocalProfile
): ParticipantForm => {
	if (!profile.profile) {
		throw new Error('Profile is incomplete (not fetched)');
	}

	const isWriter = containsWriterRoles(profile.profile.roles);

	const participantPublishers = isWriter
		? convertProfilePublishersToParticipantForm(
				profile.profile.publishers,
				profile.profile.associatedPublishers
		  )
		: [];

	return {
		creditedName: profile.profile.creditedName,
		legalName: profile.profile.legalName,
		otherAliases: profile.profile.otherAliases,
		isFeatured: profile.profile.isFeatured,
		isCopyrightOwner: profile.profile.isCopyrightOwner,
		partDropped: profile.profile.partDropped,
		email: profile.profile.email,
		address1: profile.profile.address1,
		address2: profile.profile.address2,
		country: profile.profile.country,
		dateOfBirth: profile.profile.dateOfBirth,
		city: profile.profile.city,
		state: profile.profile.state,
		postalCode: profile.profile.postalCode,
		phone: profile.profile.phone,
		socialLastFour: profile.profile.socialLastFour,
		participationCountry: '',
		ipn: profile.profile.ipn,
		ipiCae: isWriter ? profile.profile.ipiCae : '',
		pro: profile.profile.pro,
		notes: profile.profile.notes,
		type: profile.profile.type,
		isni: profile.profile.isni,
		participationDate: '',
		copyrightOwnerClaim: {
			cmo: profile.profile.copyrightOwnerClaim.cmo,
			cmoId: profile.profile.copyrightOwnerClaim.cmoId,
			splitPercentage: null,
			territoryOwnership: '',
		},
		publishers: participantPublishers,
		roles: profile.profile.roles.map(r => ({
			category: r.category ?? '',
			detail: r.detail ?? '',
			studio: r.studio
				? {
						studioId: r.studio.studioId,
						label: r.studio.label,
						sessionType: r.studio.sessionType ?? null,
				  }
				: null,
		})),
	};
};

export const sortParticipants = (
	participants: Participant[],
	sortOrder: 'asc' | 'desc'
) => {
	return [...participants].sort((a, b) => {
		const comparison = a.creditedName.localeCompare(b.creditedName, undefined, {
			sensitivity: 'base',
		});
		return sortOrder === 'asc' ? comparison : -comparison;
	});
};

export const filterParticipants = (
	participants: Participant[],
	roleFilters: RoleFilter[],
	searchFilter: string
) => {
	const newRoleFilters = roleFilters.filter(item => item.value !== 'IGNORE');
	let filteredParticipants = [...participants];

	if (roleFilters.some(item => item.label === 'A - Z')) {
		filteredParticipants = sortParticipants(filteredParticipants, 'asc');
	}

	if (roleFilters.some(item => item.label === 'Z - A')) {
		filteredParticipants = sortParticipants(filteredParticipants, 'desc');
	}

	if (newRoleFilters.length > 0) {
		const roleFilterValues = newRoleFilters.map(({ value }) => value);
		filteredParticipants = filteredParticipants.filter(p =>
			p.roles.some(
				({ category, detail }) =>
					roleFilterValues.includes(
						roleFilterOptions.mapToFilterOption[
							category as keyof typeof roleFilterOptions.mapToFilterOption
						]
					) ||
					roleFilterValues.includes(
						roleFilterOptions.mapToFilterOption[
							detail as keyof typeof roleFilterOptions.mapToFilterOption
						] // necessary for writer roles, which are only a subset of Contributors
						// differentiated by the detail field
					)
			)
		);
	}

	if (searchFilter) {
		const normalizedSearchFilter = searchFilter
			.toLowerCase()
			.trim()
			.normalize();
		filteredParticipants = filteredParticipants.filter(p =>
			`${p.creditedName} ${p.legalName} ${p.roles
				.map(r => r.detail + ' ' + r.studio?.label)
				.join(' ')}` // search by credited name, legal name, role detail and studio name
				.toLowerCase()
				.normalize()
				.includes(normalizedSearchFilter)
		);
	}

	return filteredParticipants;
};

export type RoleWithStudio = {
	studioId: number | string | null;
	studioName: string;
	roleDetail: string;
};

export const extractRolesWithStudiosFromParticipant = (
	participant: Participant,
	recordingStudios?: RecordingStudio[]
) =>
	participant.roles
		.reduce((acc, role) => {
			const studio = role.studio?.studioId
				? recordingStudios?.find(studio => studio.id === role.studio?.studioId)
				: null;

			if (role.studio?.studioId && !studio) {
				console.warn(
					'Could not find studio for role',
					role,
					'participant',
					participant,
					'recordingStudios',
					recordingStudios
				);
				// throw new Error(
				// 	`Studio not found for role ${role.detail} in participant ${participant.id}, recording ${currentRecordingId} and studio ${role.studio?.studioId}`
				// );
			}

			acc.push({
				studioName: studio?.name || '',
				studioId: role.studio?.studioId || null,
				roleDetail: role.detail,
			});

			return acc;
		}, [] as RoleWithStudio[])
		.sort((a, b) => {
			// first, sort by studio name in alphabetical order
			if (a.studioName < b.studioName) {
				return -1;
			}
			if (a.studioName > b.studioName) {
				return 1;
			}

			// then, sort by role detail, in alphabetical order
			if (a.roleDetail < b.roleDetail) {
				return -1;
			}

			if (a.roleDetail > b.roleDetail) {
				return 1;
			}

			return 0;
		});

export const extractUniqueRolesFromParticipant = (participant: Participant) =>
	uniq(participant.roles.map(role => role.detail));

export const extractUniquePublishersFromParticipant = (
	participant: Participant,
	recordingPublishers?: RecordingPublisher[]
) => {
	// Non-full profiles have a publishers array with name and PRO

	const uniquePublisherIds =
		participant && participant.publishers
			? [
					...new Set(
						flattenPublishers(participant.publishers)
							.map(publisher => {
								return publisher?.publisherId;
							})
							.filter(Boolean)
					),
			  ]
			: [];

	const fullPublishers = recordingPublishers ?? [];

	const publishers = uniquePublisherIds
		.map(publisherId =>
			fullPublishers.find(publisher => publisher.id === publisherId)
		)
		.filter((publisher): publisher is RecordingPublisher => Boolean(publisher));

	return publishers;
};

export enum ParticipantTableColumn {
	STUDIOS,
	PUBLISHERS,
}
