import { formatDateToApi } from '../components/utils/dateFormatters';

const translateRecordingFormToApi = (fullRecording, recordingForm) => {
	const formattedRec = {
		title: recordingForm.title,
		artist: recordingForm.mainArtist,
		album_id: fullRecording.albumId,
		recording_id: fullRecording.id,
		is_archived: fullRecording.isArchived,
		recording: {
			...recordingForm,
			dateMastered: formatDateToApi(recordingForm.dateMastered),
			recordingDate: formatDateToApi(recordingForm.recordingDate),
			participants: recordingForm.participants.map(p => ({
				...p,
				dateOfBirth: formatDateToApi(p.dateOfBirth),
				participationDate: formatDateToApi(p.participationDate),
			})),
		},
	};

	return formattedRec;
};

export default translateRecordingFormToApi;
