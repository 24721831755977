import React, { useCallback, useEffect, useState } from 'react';
import {
	InitiatePlaylistTransferResponse,
	completePlaylistTransfer,
	initiatePlaylistTransfer,
} from '../../../../../api/services/filesService';
import { hideModal, setModalTitle } from '../../../../../store/modal/actions';
import CompletePlaylistTransferStep, {
	CompleteTransferForm,
} from './steps/CompletePlaylistTransferStep';
import InitiatePlaylistTransferStep from './steps/InitiatePlaylistTransferStep';
import UserDoesNotExistStep from './steps/UserDoesNotExistStep';
import * as Yup from 'yup';
import { FormikHelpers, useFormik } from 'formik';
import { showErrorAlert } from '../../../../../store/alertToast/actions';
import { useAppDispatch } from '../../../../../store/hooks';

const TransferPlaylistSteps = {
	INITIATE: 'INITIATE',
	COMPLETE: 'COMPLETE',
	USER_DOES_NOT_EXIST: 'USER_DOES_NOT_EXIST',
};

export type TransferPlaylistModalProps = {
	playlistId: Playlist['id'];
};

export type InitiateTransferForm = {
	email: string;
};

const TransferPlaylistModal = ({ playlistId }: TransferPlaylistModalProps) => {
	const dispatch = useAppDispatch();

	const [step, setStep] = useState(TransferPlaylistSteps.INITIATE);
	const [initiateResponse, setInitiateResponse] =
		useState<InitiatePlaylistTransferResponse | null>(null);

	const handleInitiateSubmit = useCallback(
		async (
			formValues: InitiateTransferForm,
			{ setSubmitting }: FormikHelpers<InitiateTransferForm>
		) => {
			const { email } = formValues;

			try {
				const res = await initiatePlaylistTransfer({
					playlistId,
					email,
				});

				if (!res.userExists) {
					setStep(TransferPlaylistSteps.USER_DOES_NOT_EXIST);
					return;
				}

				console.log('INITIATE TRANSFER SUBMIT', res);

				setInitiateResponse({ ...res });
				setSubmitting(false);
				setStep(TransferPlaylistSteps.COMPLETE);
			} catch (e: any) {
				dispatch(
					showErrorAlert(
						`Whoops! That transfer didn't go quite right. Please try again. (${e?.response?.status})`
					)
				);

				dispatch(hideModal());
			}
		},
		[playlistId, dispatch]
	);

	const initiateFormik = useFormik<InitiateTransferForm>({
		initialValues: {
			email: '',
		},
		validationSchema: Yup.object().shape({
			email: Yup.string().email('Invalid email address').required('Required'),
		}),
		onSubmit: handleInitiateSubmit,
	});

	const handleCancel = useCallback(() => {
		dispatch(hideModal());
	}, [dispatch]);

	const handleCompleteBack = useCallback(() => {
		setStep(TransferPlaylistSteps.INITIATE);
	}, []);

	const handleUserDoesNotExistSubmit = useCallback(
		() => dispatch(hideModal()),
		[dispatch]
	);

	const handleUserDoesNotExistBack = useCallback(() => {
		setStep(TransferPlaylistSteps.INITIATE);
	}, []);

	const handleCompleteSubmit = useCallback(
		async (formValues: CompleteTransferForm) => {
			console.log('COMPLETE TRANSFER SUBMIT', formValues);

			try {
				await completePlaylistTransfer({
					playlistId,
					email: initiateFormik?.values?.email,
					editorRole: formValues.editorRole,
				});
			} catch (e: any) {
				dispatch(
					showErrorAlert(
						`Whoops! That transfer didn't go quite right. Please try again. (${e?.response?.status})`
					)
				);
			} finally {
				dispatch(hideModal());
			}
		},
		[dispatch, initiateFormik?.values?.email, playlistId]
	);

	const renderCurrentStep = useCallback(() => {
		switch (step) {
			case TransferPlaylistSteps.INITIATE:
				return (
					<InitiatePlaylistTransferStep
						formik={initiateFormik}
						onBack={handleCancel}
					/>
				);
			case TransferPlaylistSteps.COMPLETE:
				return (
					<CompletePlaylistTransferStep
						onSubmit={handleCompleteSubmit}
						onBack={handleCompleteBack}
						initiateResponse={initiateResponse}
					/>
				);
			case TransferPlaylistSteps.USER_DOES_NOT_EXIST:
				return (
					<UserDoesNotExistStep
						onSubmit={handleUserDoesNotExistSubmit}
						onBack={handleUserDoesNotExistBack}
					/>
				);
			default:
				return <></>;
		}
	}, [
		step,
		handleCompleteSubmit,
		handleCancel,
		initiateResponse,
		handleCompleteBack,
		initiateFormik,
		handleUserDoesNotExistSubmit,
		handleUserDoesNotExistBack,
	]);

	useEffect(() => {
		dispatch(setModalTitle('SEND A COPY OF THIS PLAYLIST'));
	}, [dispatch]);

	return renderCurrentStep();
};

export default TransferPlaylistModal;
