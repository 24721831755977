export const printLocation = ({
	city,
	country,
}: {
	city?: string | null;
	country?: string | null;
}) => {
	if (city && country) {
		return `${city}, ${country}`;
	}
	if (city) {
		return city;
	}
	if (country) {
		return country;
	}
	return '';
};
