import { FormikProvider, useFormik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import Button from '../../../../layout/Button';
import { useDispatch } from 'react-redux';
import { hideModal, setModalTitle } from '../../../../../store/modal/actions';
import TextField from '../../../../form/TextField/TextField';
import { duplicatePlaylistAction } from '../../../../../store/playlists/actions';

const validationSchema = Yup.object().shape({
	playlistName: Yup.string().required('Required'),
});

const DuplicatePlaylistModal = ({ playlistId }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setModalTitle('DUPLICATE PLAYLIST'));
	}, [dispatch]);

	const handleSubmit = useCallback(
		async (values, { setSubmitting }) => {
			setSubmitting(true);
			try {
				await dispatch(
					duplicatePlaylistAction(playlistId, values?.playlistName)
				);
			} catch (e) {
				console.error(e);
			} finally {
				setSubmitting(false);
				dispatch(hideModal());
			}
		},
		[dispatch, playlistId]
	);

	const handleCancel = useCallback(() => {
		dispatch(hideModal());
	}, [dispatch]);

	const formik = useFormik({
		initialValues: {
			playlistName: '',
		},
		onSubmit: handleSubmit,
		validationSchema,
	});

	return (
		<FormikProvider value={formik}>
			<Modal.Body>
				<div className='px-4'>
					<TextField
						label='New Playlist Name'
						name='playlistName'
						placeholder='Playlist Name'
						value={formik?.values?.playlistName}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						isInvalid={
							formik?.touched?.playlistName && formik?.errors?.playlistName
						}
						errorMessage={formik?.errors?.playlistName}
					/>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Button label='Cancel' onClick={handleCancel} className='mr-2' />
				<Button
					label='Duplicate'
					onClick={formik.handleSubmit}
					theme='dark'
					isLoading={formik?.isSubmitting}
				/>
			</Modal.Footer>
		</FormikProvider>
	);
};

export default DuplicatePlaylistModal;
