import React, { useEffect, useMemo, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal, setModalTitle } from '../../../../store/modal/actions';
import Button from '../../../layout/Button';
import {
	deleteCloudAlbumByIdAction,
	deleteLocalAlbumByIdAction,
} from '../../../../store/projects/actions';
import { deleteAlbumEditor } from '../../../../api/services/editorService';
import { fetchUserPlaylistsAction } from '../../../../store/playlists/actions';

const DeleteAlbumModal = ({ id }) => {
	const dispatch = useDispatch();
	const { userId } = useSelector(state => state.auth);
	const { albumsById } = useSelector(state => state.projects);
	const [isDeleting, setIsDeleting] = useState(false);

	useEffect(() => {
		dispatch(setModalTitle('DELETE ALBUM'));
	}, [dispatch]);

	const handleClose = () => {
		dispatch(hideModal());
	};

	const isOwner = useMemo(
		() => albumsById[id]?.userId === userId,
		[userId, id, albumsById]
	);

	const warningText = useMemo(
		() =>
			isOwner
				? 'Are you sure you want to delete this album? All recordings and files included in the album will be deleted too, along with their references in playlists.'
				: 'Are you sure you want to unshare this album? All recordings and files included in the album will be unshared too, along with their references in playlists.',
		[isOwner]
	);

	const handleDelete = async () => {
		try {
			setIsDeleting(true);
			const albumFetched = albumsById[id];

			// We AREN'T the owner
			if (albumFetched.userId !== userId) {
				await deleteAlbumEditor({
					album_id: id,
					editor_id: userId,
					editor_email: null,
				});

				dispatch(fetchUserPlaylistsAction()); // invalidate playlists cache to avoid null references
				dispatch(deleteLocalAlbumByIdAction(id, true));
				dispatch(hideModal());
				return;
			}

			// We ARE the owner
			dispatch(deleteLocalAlbumByIdAction(id, true));
			await Promise.resolve(dispatch(deleteCloudAlbumByIdAction(id)));
			dispatch(fetchUserPlaylistsAction());
			dispatch(hideModal());
		} catch (e) {
			console.log(e);
			setIsDeleting(false);
		}
	};

	return (
		<>
			<Modal.Body>
				<div className='px-4'>
					<p style={{ fontWeight: 500 }}>{warningText}</p>
					<p style={{ fontWeight: 700, marginBottom: 0 }}>
						This action cannot be undone.
					</p>
				</div>
			</Modal.Body>
			<Modal.Footer>
				<Row>
					<Col
						xs={12}
						className='d-flex justify-content-end align-items-center'
					>
						<Button label='Cancel' onClick={handleClose} />
						<Button
							theme={'danger-filled'}
							onClick={handleDelete}
							isDisabled={isDeleting}
							isLoading={isDeleting}
							label={isDeleting ? 'Loading...' : 'Delete'}
							className='ml-2'
						></Button>
					</Col>
				</Row>
			</Modal.Footer>
		</>
	);
};

export default DeleteAlbumModal;
