import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import SearchTable from '../../layout/SearchTable';

const selectColumns = [
	{
		Header: () => <span className='Project-Name'>Artist Name</span>,
		accessor: 'artist',
		Cell: col => <span className='Name1'>{col.value}</span>,
	},
	{
		Header: () => <span className='Project-Name'>Song Name</span>,
		accessor: 'title',
		Cell: col => <span className='Name1'>{col.value}</span>,
	},
];

const SelectRecordings = ({
	onSelect,
	singleSelect = false,
	customRows,
	projectRows,
	recordingFilter = recording => true,
	initialSelectedRowIds = {},
	rowMapper = row => row.original.id,
	memoizeInitialSelectedRowIds = true,
}) => {
	const { recordingsById } = useSelector(state => state.projects);
	const columns = useMemo(() => selectColumns, []);

	const recordings = useMemo(
		() =>
			customRows && projectRows
				? projectRows
				: Object.values(recordingsById).filter(recordingFilter),
		[customRows, projectRows, recordingFilter, recordingsById]
	);

	return (
		<Row>
			<Col>
				<div>
					<SearchTable
						singleSelect={singleSelect}
						columns={columns}
						data={recordings}
						rowMapper={rowMapper}
						onSelect={onSelect}
						initialSelectedRowIds={initialSelectedRowIds}
						memoizeInitialSelectedRowIds={memoizeInitialSelectedRowIds}
					/>
				</div>
			</Col>
		</Row>
	);
};

export default SelectRecordings;
