import React from 'react';
import SoundCreditLoader from '../SoundCreditLoader/SoundCreditLoader';
import theme from '../../../theme.module.scss';

const LoadingModal = ({ message }) => {
	return (
		<div
			style={{
				backgroundColor: 'white',
				borderRadius: theme.modalBorderRadius,
			}}
		>
			<div className='m-2'>
				<SoundCreditLoader message={message} />
			</div>
		</div>
	);
};

export default LoadingModal;
