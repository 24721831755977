import { FormikProvider, useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import TextField from '../../../form/TextField';
import styles from './SignUpStep.module.scss';
import Button from '../../../layout/Button/Button';
import DatePicker from '../../../form/DatePicker/DatePicker';
import Select from '../../../form/Select/Select';
import countries from '../../../../constants/countries.json';
import { formatDateToHtml } from '../../../utils/dateFormatters';

export type CreateAccountStepProps = {
	onSubmit: (values: CreateAccountFormikValues) => Promise<void>;
	initialFormValues: Partial<CreateAccountFormikValues>;
};

export type CreateAccountFormikValues = Partial<CreateAccountFormValues> & {
	repeatPassword: string;
};

const validationSchema = Yup.object({
	email: Yup.string().email('Invalid email address').required('Required'),
	password: Yup.string()
		.min(8, 'Password must be at least 8 characters')
		.required('Required'),
	repeatPassword: Yup.string()
		.required('Required')
		.oneOf([Yup.ref('password')], 'Passwords must match'),
	legalName: Yup.string().required('Required'),
	creditedName: Yup.string().required('Required'),
	country: Yup.string().required('Required'),
	dateOfBirth: Yup.date().max(new Date(), 'Invalid date').required('Required'),
});

const countriesOptions = countries.map(country => ({
	label: country.name,
	value: country.name,
}));

const CreateAccountStep = ({
	onSubmit,
	initialFormValues,
}: CreateAccountStepProps) => {
	const [isLoading, setIsLoading] = React.useState(false);

	const formik = useFormik<CreateAccountFormikValues>({
		initialValues: {
			email: '',
			password: '',
			repeatPassword: '',
			legalName: '',
			creditedName: '',
			country: '',
			dateOfBirth: '',
			...initialFormValues,
		},
		onSubmit: async values => {
			setIsLoading(true);
			try {
				console.log('values', values);
				await onSubmit(values);
			} catch (e) {
				console.error(e);
			} finally {
				setIsLoading(false);
			}
		},
		validationSchema,
		validateOnMount: true,
		enableReinitialize: true,
	});

	return (
		<FormikProvider value={formik}>
			<div className={styles['sign-up-step-container']}>
				<h2> Create an account to start with Sound Credit </h2>
				<p>Just a few more steps and you're done! We hate paperwork, too.</p>
				<div className={styles['form']}>
					<TextField
						name='legalName'
						label='Legal name'
						type='text'
						placeholder='e.g. John Doe'
						value={formik.values.legalName!}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						isInvalid={formik.touched.legalName! && !!formik.errors.legalName!}
						errorMessage={formik.errors.legalName!}
					/>

					<TextField
						name='creditedName'
						label='Credited name'
						type='text'
						placeholder='e.g. John Doe'
						value={formik.values.creditedName!}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						isInvalid={
							formik.touched.creditedName! && !!formik.errors.creditedName!
						}
						errorMessage={formik.errors.creditedName!}
					/>

					<DatePicker
						name='dateOfBirth'
						label='Date of birth'
						placeholder='Select date of birth'
						value={formik.values.dateOfBirth!}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						max={formatDateToHtml(new Date())}
						isInvalid={
							formik.touched.dateOfBirth! && !!formik.errors.dateOfBirth!
						}
						errorMessage={formik.errors.dateOfBirth!}
					/>

					<Select
						name='country'
						label='Country of residence'
						placeholder='Select country'
						value={countriesOptions.find(
							option => option.value === formik.values.country
						)}
						onChange={(option: any) =>
							formik.setFieldValue('country', option.value)
						}
						onBlur={formik.handleBlur}
						errorFieldName='country'
						options={countriesOptions}
					/>

					<TextField
						name='password'
						label='Password'
						type='password'
						showEyeToggle
						placeholder='Enter password'
						value={formik.values.password!}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						isInvalid={formik.touched.password! && !!formik.errors.password!}
						errorMessage={formik.errors.password!}
						informationText='min. 8 characters'
						autoComplete='new-password'
					/>

					<TextField
						name='repeatPassword'
						label='Repeat password'
						type='password'
						showEyeToggle
						placeholder='Repeat password'
						value={formik.values.repeatPassword!}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						isInvalid={
							formik.touched.repeatPassword! && !!formik.errors.repeatPassword!
						}
						errorMessage={formik.errors.repeatPassword!}
						autoComplete='new-password'
					/>

					<div className='my-3' />
					<Button
						label='Create'
						onClick={() => formik.handleSubmit()}
						isDisabled={!formik.isValid}
						isLoading={isLoading}
						theme='dark'
						size='lg'
					/>
				</div>
			</div>
		</FormikProvider>
	);
};

export default CreateAccountStep;
