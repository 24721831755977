import React, { useEffect, useState } from 'react';
import PillButton from '../../layout/PillButton/PillButton';
import styles from './PlaylistJuly2023PromoModal.module.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
	reportPlaylistJuly2023PromoSeenAction,
	extendPlaylistJuly2023PromoAction,
} from '../../../store/promos/actions';
import PromoType from '../../../store/promos/promoType';
import { isDateExpired } from '../../../helpers/dateTools';
import { hideModal } from '../../../store/modal/actions';
import { createPlaylistAction } from '../../../store/playlists/actions';
import { useNavigate } from 'react-router-dom';
import { replacePathVariables } from '../../../helpers/routeTools';
import ROUTES from '../../../router/routes';
import { getAssetPath } from '../../../helpers/assetTools';

const PlaylistJuly2023PromoModal = () => {
	const dispatch = useAppDispatch();
	const {
		extendedPlaylistJuly2023Promo,
		playlistJuly2023PromoTimerExpiration,
		seenPromos,
	} = useAppSelector(state => state.promos);
	const navigate = useNavigate();
	const [isCreating, setIsCreating] = useState(false);

	const hasSeenPlaylistJuly2023Promotion =
		seenPromos[PromoType.PlaylistJuly2023];

	const timerExpired =
		playlistJuly2023PromoTimerExpiration &&
		isDateExpired(new Date(playlistJuly2023PromoTimerExpiration));

	const handleExtend = () => {
		if (!extendedPlaylistJuly2023Promo) {
			dispatch(extendPlaylistJuly2023PromoAction());
		}

		dispatch(hideModal());
	};

	const afterCreate = (playlistId: Playlist['id']) => {
		navigate(replacePathVariables(ROUTES.PlaylistDetails.path, { playlistId }));
	};

	const handleCreatePlaylist = () => {
		setIsCreating(true);

		dispatch(createPlaylistAction({}, afterCreate)).finally(() => {
			setIsCreating(false);

			dispatch(hideModal());
		});
	};

	useEffect(() => {
		return () => {
			if (!hasSeenPlaylistJuly2023Promotion) {
				dispatch(reportPlaylistJuly2023PromoSeenAction());
			}
		};
	}, [hasSeenPlaylistJuly2023Promotion, dispatch]);

	return (
		<div className={styles['playlist-july-2023-modal']}>
			<div className={styles['banner-container']}>
				<div className={styles['banner-content']}>
					<img
						className={styles['devices']}
						src={getAssetPath('img/playlist-devices.png')}
						alt='Devices with Sound Credit Playlists'
					/>
					{!timerExpired ? (
						<>
							<h2>Revolutionize your file transfers!</h2>
							<h3>
								Upload a file to a playlist in the next 10 minutes for an award!
							</h3>

							<p className='mt-3'>Hurry, the clock is ticking!</p>

							<PillButton
								label='Create Playlist Now'
								theme='solid-dark'
								className={styles['create-playlist-button']}
								onClick={handleCreatePlaylist}
								isLoading={isCreating}
							/>
						</>
					) : (
						<>
							<h2>Time's up! But all is not lost...</h2>
							<h3
								style={{
									maxWidth: '60ch',
								}}
							>
								Don't hit the pause button on your journey with Sound Credit
								just yet...
							</h3>
							<p>
								Click the button below and we'll rewind the clock, giving you an
								extra 5 minutes to create your first playlist and add a song or
								file to it.
							</p>
							<PillButton
								label='Give me 5 more minutes!'
								theme='solid-dark'
								className={styles['create-playlist-button']}
								onClick={handleExtend}
							/>
						</>
					)}
				</div>

				<div className={styles['blur-filter']} />

				<img
					className={styles['bg']}
					src={getAssetPath('img/purple-blurred-bg-2.png')}
					alt='Purple blurred background'
				/>
			</div>
		</div>
	);
};

export default PlaylistJuly2023PromoModal;
