import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import Select from 'react-select';
import useSuperCluster from 'use-supercluster';
import {
	getCurrentPlaylistStats,
	getCurrentPlaylist,
	getCurrentDripStats,
} from '../../../../../store/playlists/selectors';
import Marker from './Marker';
import React from 'react';
import { getFileProject } from '../../../../../helpers/fileTools';

const PlaylistStatsMap = ({ isDrip = false }) => {
	const [bounds, setBounds] = useState();
	const [zoom, setZoom] = useState(10);

	const { data } = useSelector(
		isDrip ? getCurrentDripStats : getCurrentPlaylistStats
	);
	const { playlist } = useSelector(getCurrentPlaylist);

	const selectMusicOptions = useMemo(
		() => [
			{ value: null, label: 'All Tracks' },
			...(playlist?.files ?? []).map(file => {
				return { value: file.id, label: getFileProject(file).title };
			}),
		],
		[playlist]
	);

	const [currentTrack, setCurrentTrack] = useState(selectMusicOptions[0]);

	const points = useMemo(() => {
		let result = [];
		for (const trackListener of data?.listeners) {
			if (
				!currentTrack?.value ||
				currentTrack?.value === trackListener.fileId
			) {
				result = [...result, ...(trackListener?.positions ?? [])];
			}
		}

		return result;
	}, [data, currentTrack]).map(point => ({
		type: 'Feature',
		properties: {
			cluster: false,
			category: 'playerlist-stats',
		},
		geometry: { type: 'Point', coordinates: [point.lng, point.lat] },
	}));

	const { clusters } = useSuperCluster({
		points: points,
		bounds,
		zoom,
		options: {
			radius: 75,
			maxZoom: 25,
		},
	});

	return (
		<div className='playlist-stats-map__container'>
			<div className='select-group-title__container'>
				<div className='select-group-title'>
					<i className='fas fa-map-marked'></i>
					<div>
						<h3>Listener Approximate Geolocation</h3>
					</div>
				</div>
				<Select
					defaultValue={currentTrack}
					onChange={setCurrentTrack}
					options={selectMusicOptions}
					classNamePrefix='music-select'
					theme={theme => ({
						...theme,
						colors: {
							...theme.colors,
							primary: '#8500d1',
							primary50: '#8500d170',
							primary25: '#8500d138',
						},
					})}
				/>
			</div>
			<div className='map-box'>
				<GoogleMapReact
					bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
					defaultCenter={data?.mapCenter}
					defaultZoom={2}
					yesIWantToUseGoogleMapApiInternals
					onChange={({ zoom, bounds }) => {
						setZoom(zoom);
						setBounds([
							bounds.nw.lng,
							bounds.se.lat,
							bounds.se.lng,
							bounds.nw.lat,
						]);
					}}
				>
					{clusters.map((cluster, index) => {
						const [longitude, latitude] = cluster.geometry.coordinates;
						const { cluster: isCluster, point_count: pointCount } =
							cluster.properties;

						return (
							<Marker
								key={index}
								lat={latitude}
								lng={longitude}
								count={isCluster ? pointCount : 1}
								name='Listeners'
							/>
						);
					})}
				</GoogleMapReact>
			</div>
		</div>
	);
};

export default PlaylistStatsMap;
