const filterFn = (item, searchTerm, searchProperties) => {
	const regex = new RegExp(searchTerm, 'gi');
	const itemString = searchProperties.map(prop => item[prop]).join();

	return regex.test(itemString);
};

export const filterItemsBySearchTerm = (
	searchTerm,
	items,
	searchProperties
) => {
	if (!searchTerm) {
		return items;
	}

	return items.filter(item => filterFn(item, searchTerm, searchProperties));
};
