import React from 'react';
import { Link } from 'react-router-dom';
import { LinkProps } from 'react-router-dom';

interface OptionalLinkProps extends Omit<LinkProps, 'to'> {
	to?: string | null;
	isDisabled?: boolean;
}

const OptionalLink = ({
	to,
	isDisabled = false,
	children,
	...props
}: OptionalLinkProps) => {
	if (!to || isDisabled) {
		return <>{children}</>;
	}

	return (
		<Link to={to} {...props}>
			{children}
		</Link>
	);
};

export default OptionalLink;
