import React, { useEffect, useMemo } from 'react';
import { PROJECT_LIMIT_MODAL } from '../../../../constants/modalTypes';
import { showModalAction } from '../../../../store/modal/actions';
import './ProjectUsageStatus.scss';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getProjectUsageAction } from '../../../../store/projects/actions';
import { getAssetPath } from '../../../../helpers/assetTools';

const ProjectUsageStatus = ({ className = '', fetchOnMount = false }) => {
	const { projectUsage } = useAppSelector(state => state.projects);
	const dispatch = useAppDispatch();

	const [usedProjects, remainingProjects] = useMemo(
		() =>
			projectUsage!.limit != null && projectUsage!.used != null
				? [projectUsage!.used, projectUsage!.limit - projectUsage!.used]
				: [0, 0],
		[projectUsage]
	);

	const showAlert = useMemo(() => remainingProjects <= 2, [remainingProjects]);
	// const showAlert = true;
	const usageMessage = useMemo(
		() =>
			remainingProjects
				? `${usedProjects} project${
						usedProjects !== 1 ? 's' : ''
				  } (${remainingProjects} remaining)`
				: 'No projects remaining',
		[usedProjects, remainingProjects]
	);

	const handleClick = () => {
		dispatch(
			showModalAction(PROJECT_LIMIT_MODAL, {
				size: 'md',
			})
		);
	};

	useEffect(() => {
		if (fetchOnMount) {
			dispatch(getProjectUsageAction());
		}
	}, [fetchOnMount, dispatch]);

	return (
		<div
			className={`project-usage-status-pill ${
				showAlert ? 'project-usage-status-danger' : ''
			} ${className}`}
			onClick={handleClick}
		>
			<img
				className={`mr-2 ${
					showAlert ? 'dark-red-svg-filter' : 'primary-svg-filter'
				}`}
				src={getAssetPath('img/music-video-black-24-dp.svg')}
				alt='recording'
				draggable='false'
			/>
			<p
				className={`fw-500 mb-0`}
				style={{
					fontSize: '0.9rem',
				}}
			>
				{usageMessage}
			</p>
		</div>
	);
};

export default ProjectUsageStatus;
