import axiosRetry from 'axios-retry';
import camelize from '../../helpers/camelize';
import { detectTitleAndArtistApi } from '../detectTitleAndArtistApi';
import { shouldRetry } from '../../helpers/networkTools';

axiosRetry(detectTitleAndArtistApi, {
	retries: 3,
	retryDelay: retryCount => {
		return retryCount * 1000;
	},
	shouldResetTimeout: true,
	retryCondition: shouldRetry,
});

export const aiDetectTitleAndArtist = (filename: string) =>
	detectTitleAndArtistApi
		.post<{
			main_artist_name: string | null;
			title: string | null;
		}>('/filename-artist-title-parser', {
			playlist_filename: filename,
		})
		.then(
			res =>
				camelize(res.data) as Promise<{ mainArtistName: string; title: string }>
		);
