import * as _ from 'lodash';
import deleteByValue from './deleteByValue';

export const getTrackNumberById = (recordings: AlbumRecordings, id: number) => {
	return parseInt(
		_.findKey(recordings, recordingId => recordingId === id) ?? '-1'
	);
};

export const getAlbumRecordingIds = (album: Album | AlbumContent) => {
	return Object.values(album.recordings);
};

export const reorderAlbumRecordings = (
	recordingId: number,
	newTrackNumber: number,
	recordings: AlbumRecordings
) => {
	const reorderedRecordings = { ...recordings };

	const oldTrackNumber = getTrackNumberById(recordings, recordingId);

	const swapTrackId = recordings[newTrackNumber];

	// swap the track numbers
	reorderedRecordings[newTrackNumber] = recordingId;
	reorderedRecordings[oldTrackNumber] = swapTrackId;

	return reorderedRecordings;
};

export const removeUnauthorizedRecordings = (
	album: Album,
	recordingsById: RecordingsById
) => {
	const authorizedRecordings: AlbumRecordings = {};

	for (const trackNumber in album.recordings) {
		const recordingId = album.recordings[trackNumber];

		if (recordingsById[recordingId]) {
			authorizedRecordings[trackNumber] = recordingId;
		}
	}

	return {
		...album,
		recordings: authorizedRecordings,
		album: {
			...album.album,
			recordings: authorizedRecordings,
		},
	};
};

export const albumRecordingsArrayToObject = (recordingIds: number[]) => {
	const recordingsObject: AlbumRecordings = {};

	recordingIds.forEach((recordingId, index) => {
		recordingsObject[index + 1] = recordingId;
	});

	return recordingsObject;
};

export const deleteRecordingFromAlbum = (
	recordingId: number,
	recordings: AlbumRecordings
) => {
	let newRecordings = { ...recordings };

	deleteByValue(newRecordings, recordingId);

	// collapse the track numbers
	const newRecordingIds = Object.values(newRecordings);
	newRecordings = albumRecordingsArrayToObject(newRecordingIds);
	console.log('NEW RECORDINGS AFTER DELETION', newRecordings);

	return newRecordings;
};
