import exportTypes from '../constants/exportType';

export const isMusicianEqual = (m1, m2) => {
	if (m1?.creditedName !== m2?.creditedName) return false;
	if (
		(m1?.socialLastFour?.toString() ?? '').replaceAll('-', '').trim() !==
		(m2?.socialLastFour?.toString() ?? '').replaceAll('-', '').trim()
	)
		return !m1?.socialLastFour || !m2?.socialLastFour; // if one of the socials is null, then they are equal

	return true;
};

export const getStudioParticipants = (recordings, selectedStudioId) => {
	// remove duplicate participants as they may be referenced in multiple recordings
	// we do this by using a map and overwriting the previous value if it exists (O(n) time)
	const studioParticipantsById = {};

	recordings.forEach(recording =>
		recording.participants.forEach(participant => {
			// check if participant references the selected studio
			if (
				participant.roles.some(
					role => role.studio.studioId === selectedStudioId
				)
			) {
				// only keep the roles that reference the selected studio
				studioParticipantsById[participant.id] = {
					...participant,
					roles: participant.roles.filter(
						role => role.studio.studioId === selectedStudioId
					),
				};
			}
		})
	);

	return Object.values(studioParticipantsById);
};

export const getUnionFormFormikPrefix = exportType => {
	switch (exportType) {
		case exportTypes.AFM_B4:
		case exportTypes.AFM_B9:
			return 'union_form';

		case exportTypes.SAG_AFTRA:
			return 'sag_form';

		default:
			return null;
	}
};

export const getUnionFormFormikFieldNames = exportType => {
	switch (exportType) {
		case exportTypes.AFM_B4:
		case exportTypes.AFM_B9:
			return {
				studio: `recording_studio`,
				recordingDate: `recording_date`,
				city: `city`,
				state: `state`,
				afmLocalNumber: 'afm_local_no',
			};
		case exportTypes.SAG_AFTRA:
			return {
				studio: `recording_studio`,
				recordingDate: `recording_date`,
				postalCode: `record_co_post_code`,
				city: `record_co_city`,
				state: `record_co_state`,
			};
		default:
			return null;
	}
};

export const findParticipantRecordingReferences = (
	formParticipant,
	recordings,
	recordingIdToReferenceMap
) => {
	const formParticipantWithRecordingSsn = {
		...formParticipant,
		socialLastFour: formParticipant.recordingSocialLastFour, // in case the socialLastFour field was overwritten during the Union Form
	};

	const participantRecordings = recordings.filter(recording =>
		recording.participants.some(recP =>
			isMusicianEqual(recP, formParticipantWithRecordingSsn)
		)
	);

	return participantRecordings.map(rec => recordingIdToReferenceMap[rec.id]);
};
