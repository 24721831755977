import { shouldRetry } from '../../helpers/networkTools';
import { moodsApi } from '../moodsApi';
import axiosRetry from 'axios-retry';

axiosRetry(moodsApi, {
	retries: 3,
	retryDelay: retryCount => {
		return retryCount * 1000;
	},
	shouldResetTimeout: true,
	retryCondition: shouldRetry,
});

export const generateMoods = (recording: RecordingContent) =>
	moodsApi.post('/moods-ai-2', {
		rins: [recording],
	});
