import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { useAppDispatch } from '../../../store/hooks';
import * as Yup from 'yup';
import { FormikProvider, useFormik } from 'formik';
import { createPlaylistFileGroupAction } from '../../../store/playlists/actions';
import TextField from '../../form/TextField';
import Button from '../../layout/Button';
import { hideModal, setModalTitle } from '../../../store/modal/actions';

type CreatePlaylistFolderModalProps = {
	playlistId: Playlist['id'];
	fileIds: FileMetadata['id'][];
	folderIds: PlaylistFolder['id'][];
	parentGroupId?: PlaylistFileGroup['id'] | null;
};

const validationSchema = Yup.object().shape({
	name: Yup.string().required('Required'),
});

const CreatePlaylistFolderModal = ({
	playlistId,
	fileIds,
	folderIds,
	parentGroupId = null,
}: CreatePlaylistFolderModalProps) => {
	const dispatch = useAppDispatch();
	const formik = useFormik({
		initialValues: {
			name: '',
		},
		validationSchema,
		onSubmit: async values => {
			await dispatch(
				createPlaylistFileGroupAction({
					playlistId,
					fileIds,
					name: values.name,
					parentGroupId,
					folderIds,
				})
			);

			onHide();
		},
	});

	const onHide = () => {
		dispatch(hideModal());
	};

	useEffect(() => {
		dispatch(setModalTitle('Create Folder'));
	}, [dispatch]);

	return (
		<FormikProvider value={formik}>
			<form onSubmit={formik.handleSubmit}>
				<Modal.Body>
					<div className='px-4'>
						<TextField
							value={formik.values.name}
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							name='name'
							label='Folder Name'
							placeholder='Enter Folder Name'
							isInvalid={!!formik.touched.name && !!formik.errors.name}
							errorMessage={formik.errors.name}
						/>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button label='Cancel' onClick={onHide} />
					<Button
						label='Create Folder'
						type='submit'
						isLoading={formik.isSubmitting}
						theme='dark'
					/>
				</Modal.Footer>
			</form>
		</FormikProvider>
	);
};

export default CreatePlaylistFolderModal;
