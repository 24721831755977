import React, { useMemo } from 'react';
import IconButton from '../../../layout/IconButton';
import '../../Projects/ProjectsTable/ProjectsTable.scss';
import theme from '../../../../theme.module.scss';
import FileTagChip from '../../ProjectFiles/FileTagChip';

const projectFilesColumns = [
	{
		Header: () => <span className='Project-Name'>File Name</span>,
		accessor: 'filename',
		Cell: col => (
			<span className='Name1 select-project-files__file-name'>{col.value}</span>
		),
		width: '55%',
	},
	{
		Header: () => <span className='Project-Name'>Tag</span>,
		accessor: 'label',
		Cell: col =>
			col.value ? (
				<span className='Name1'>
					<FileTagChip tag={col.value} isReadOnly />
				</span>
			) : (
				<>—</>
			),
		width: '25%',
	},
	{
		Header: () => <></>,
		accessor: 'actions',
		Cell: ({ onFileSelect, row }) => {
			const isSelected = useMemo(() => {
				return row.original.selected;
			}, [row.original.selected]);

			return (
				<IconButton
					color={!isSelected ? theme.primary : theme.error}
					icon={`fas ${isSelected ? 'fa-minus-circle' : 'fa-plus-circle'}`}
					iconSize='1.3rem'
					onClick={() => onFileSelect(row.original.id)}
					tooltipText={isSelected ? 'Unselect' : 'Select'}
					outerStyle={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				/>
			);
		},
		width: '20%',
	},
];

export default projectFilesColumns;
