import React from 'react';
import { ListGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import IconButton from '../IconButton';
import theme from '../../../theme.module.scss';
import OverflowMenuButton from './OverflowMenuButton';

export type OverflowMenuOption = {
	name: string | React.ReactNode;
	onClick: (() => void) | ((e: React.MouseEvent) => void);
	style?: React.CSSProperties;
	className?: string;
	leftIcon?: string | null;
	rightIcon?: string | null;
	isLocked?: boolean;
};

export type OverflowMenuProps = {
	options: OverflowMenuOption[];
	id: string;
	tooltipText?: string;
	color?: string;
	vertical?: boolean;
	className?: string;
	counter?: number;
	counterStyle?: React.CSSProperties;
};

const OverflowMenu = ({
	options,
	id,
	tooltipText = 'More Options',
	color = theme.primary,
	vertical = true,
	className = 'ml-2',
	counter,
	counterStyle,
}: OverflowMenuProps) => {
	const renderOverflowPopover = (props: any) => (
		<Popover style={{ zIndex: 3000 }} id={id} {...props}>
			<ListGroup>
				{options.map((option, index) => (
					<OverflowMenuButton
						key={index}
						onClick={option.onClick}
						style={{
							display: "flex",
							alignItems: "center",
							fontSize: '0.8rem',
							padding: '0.5rem 0.5rem',
							...(option.style ?? {}),
						}}
						className={option.className}
						label={option.name}
						leftIcon={option.leftIcon ?? ''}
						isLocked={option.isLocked ?? false}
						rightIcon={option.rightIcon ?? ''}
					/>
				))}
			</ListGroup>
		</Popover>
	);

	return (
		<div className='d-flex' onClick={e => e.stopPropagation()}>
			<OverlayTrigger
				placement='auto-end'
				trigger='click'
				rootClose
				overlay={renderOverflowPopover}
			>
				{/* Custom components must be wrapped inside divs when using OverlayTrigger for some reason 
						https://github.com/react-bootstrap/react-bootstrap/issues/2208 */}
				<div>
					<IconButton
						className={`${className}`}
						icon={`fas fa-ellipsis-${vertical ? 'v' : 'h'}`}
						color={color}
						onClick={() => {}}
						tooltipText={tooltipText}
						counter={counter}
						counterStyle={counterStyle}
					/>
				</div>
			</OverlayTrigger>
		</div>
	);
};

export default OverflowMenu;
