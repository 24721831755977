import React from 'react';
import {
	CircularProgressbar,
	CircularProgressbarWithChildren,
} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import UPLOAD_STATUS from '../../../../../constants/uploadStatus.json';
import theme from '../../../../../theme.module.scss';
import '../../FileUploadMenu.scss';
import './FileUploadItemProgress.scss';

export type FileUploadItemProgressProps = {
	id?: string;
	upload?: ProjectFileUpload;
	progressBarWidth?: string | number;
	// Used to override the progress value in the upload object
	progress?: number;
	// Used to override the status value in the upload object
	status?: ProjectFileUpload['status'];
	content?: React.ReactNode;
};

const FileUploadItemProgress = ({
	id,
	upload,
	progressBarWidth = '100%',
	progress,
	status,
	content,
}: FileUploadItemProgressProps) => {
	if (!upload && !progress && !status && !content) {
		throw new Error(
			'FileUploadItemProgress requires either an upload object or a progress and status value'
		);
	}

	const _progress = progress ?? upload?.progress!;
	const _status = status ?? upload?.status!;

	if (content) {
		return (
			<div style={{ width: progressBarWidth, maxWidth: 50 }} id={id}>
				<CircularProgressbarWithChildren
					value={_progress ?? 0}
					strokeWidth={11}
				>
					{content}
				</CircularProgressbarWithChildren>
			</div>
		);
	}

	switch (_status) {
		case UPLOAD_STATUS.IN_PROGRESS:
		case UPLOAD_STATUS.READY_TO_UPLOAD:
			return (
				<div style={{ width: progressBarWidth, maxWidth: 50 }} id={id}>
					<CircularProgressbar
						value={_progress}
						text={`${Math.floor(_progress)}%`}
						strokeWidth={11}
					/>
				</div>
			);
		case UPLOAD_STATUS.ERROR:
			return (
				<i
					id={id}
					className='fas fa-exclamation-circle'
					style={{ fontSize: '1.5rem', color: theme.error }}
				/>
			);
		case UPLOAD_STATUS.CANCELLED:
			return (
				<i
					id={id}
					className='fas fa-times-circle'
					style={{ fontSize: '1.5rem', color: theme.error }}
				/>
			);
		case UPLOAD_STATUS.SUCCESS:
			return (
				<i
					id={id}
					className='fas fa-check-circle'
					style={{ fontSize: '1.5rem', color: theme.success }}
				/>
			);
		default:
			return <></>;
	}
};

export default FileUploadItemProgress;
