import {
	REQUEST_FAILURE,
	REQUEST_FORBIDDEN,
	REQUEST_LOADING,
	REQUEST_SUCCESS,
} from '../../constants/requestStatusTypes';
import convertExportsArrayToObject from '../../helpers/convertExportsArrayToObject';
import {
	ADD_EXPORT_VALIDATION_ERRORS,
	ADD_LOCAL_EXPORTS,
	CLEAR_EXPORT,
	CLEAR_EXPORT_REQUEST,
	CLEAR_EXPORT_VALIDATION_ERRORS,
	CONFIRM_EXPORT,
	DELETE_LOCAL_EXPORT,
	EXPORT_REQUEST_AUTH_ERROR,
	EXPORT_REQUEST_ERROR,
	EXPORT_REQUEST_SUCCESS,
	SET_EXPORT_LEAD_WRITER_NAME,
	SET_EXPORT_PAYLOAD,
	SET_PREVIEW_EXPORT_DATA,
	START_EXPORT_REQUEST,
	SET_EXPORT_TYPE,
	NEXT_UNION_FORM_EXPORT_STEP,
	PREVIOUS_UNION_FORM_EXPORT_STEP,
	SET_UNION_FORM_EXPORT_STEP,
	SET_UNION_FORM,
	SET_SAG_FORM,
	SET_UNION_EXPORT_STUDIO_FORM,
	SET_UNION_EXPORT_COMPLETE_PARTICIPANTS_FORM,
	SET_UNION_EXPORT_FINALIZE_FORM,
	SET_UNION_EXPORT_PARTICIPANTS,
	SET_UNION_EXPORT_RECORDING_ROWS,
	INITIALIZE_UNION_EXPORT_COMPLETE_PARTICIPANTS_FORM,
	SET_B4_TYPE,
	SET_UNION_EXPORT_ID,
	SET_EXPORT_PARAMS,
	SET_EXPORT_MAIN_ARTISTS,
	SET_EXPORT_RECORDING_ERRORS,
} from '../actionTypes';
import initializeCompleteParticipantsForm from '../../helpers/initializeCompleteParticipantsForm';
import { AnyAction } from 'redux';
import ExportType from '../../constants/exportType';
import { UnionFormStep } from '../../constants/unionFormExportSteps';

// TODO: Add proper types
export type ExportState = {
	activeUnionFormStep: number;
	exportType: ExportType | null;
	// used to store the payload of the export that will be sent to the server
	// in the exact format that the server expects
	// TODO: we really need to properly migrate exportPayload to TS,
	// possibly making different types for the different types of payloads, and have them all
	// extend a common interface, etc.
	exportPayload: any;
	exportsById: Partial<Record<ExportMetadata['id'], ExportMetadata>>;
	unionForm: Partial<UnionFormData>; // TODO: Remove Partial and correct types
	previewExportData: any;
	errorMessage: string | null;
	requestLabel: string | null;
	requestStatus: string | null;
	validationErrors: ExportValidationErrorsState | null;
	exportParams: any;
	// These are meant to be showed as toasts to the user
	// whenever they're in the project referenced by this object
	exportRecordingErrors: null | {
		recordingId: Recording['id'] | null;
		albumId: Album['id'] | null;
		errors: string[];
	};
};

const initialState: ExportState = {
	activeUnionFormStep: UnionFormStep.LOAD_RECORDINGS,
	exportType: null,
	exportPayload: null,
	exportsById: {},
	unionForm: {},
	previewExportData: null,
	errorMessage: '',
	requestLabel: '',
	requestStatus: '',
	validationErrors: null,
	exportParams: null,
	exportRecordingErrors: null,
};

const reducer = (state = initialState, action: AnyAction): ExportState => {
	switch (action.type) {
		case START_EXPORT_REQUEST:
			return {
				...state,
				requestStatus: REQUEST_LOADING,
				requestLabel: action.requestLabel,
				errorMessage: '',
			};
		case EXPORT_REQUEST_ERROR:
			return {
				...state,
				requestStatus: REQUEST_FAILURE,
				errorMessage: action.errorMessage,
			};
		case EXPORT_REQUEST_AUTH_ERROR:
			return {
				...state,
				requestStatus: REQUEST_FORBIDDEN,
				errorMessage: action.errorMessage,
			};
		case EXPORT_REQUEST_SUCCESS:
			return {
				...state,
				requestStatus: REQUEST_SUCCESS,
				errorMessage: '',
			};
		case CLEAR_EXPORT_REQUEST:
			return {
				...state,
				requestStatus: '',
				requestLabel: '',
				errorMessage: '',
			};
		case SET_UNION_FORM_EXPORT_STEP:
			return {
				...state,
				activeUnionFormStep: action.step,
			};
		case NEXT_UNION_FORM_EXPORT_STEP:
			return {
				...state,
				activeUnionFormStep: state.activeUnionFormStep + 1,
			};
		case PREVIOUS_UNION_FORM_EXPORT_STEP:
			return {
				...state,
				activeUnionFormStep: state.activeUnionFormStep - 1,
			};
		case SET_EXPORT_PAYLOAD:
			return {
				...state,
				exportType: action.exportType,
				exportPayload: action.exportPayload,
			};
		case ADD_LOCAL_EXPORTS:
			return {
				...state,
				exportsById: {
					...state.exportsById,
					...convertExportsArrayToObject(action.exports as ApiExportMetadata[]),
				},
			};
		case DELETE_LOCAL_EXPORT:
			return {
				...state,
				exportsById: {
					...state.exportsById,
					[action.id]: undefined,
				},
			};
		case ADD_EXPORT_VALIDATION_ERRORS:
			return {
				...state,
				validationErrors: action.errors,
			};
		case CLEAR_EXPORT_VALIDATION_ERRORS:
			return {
				...state,
				validationErrors: null,
			};
		case SET_EXPORT_LEAD_WRITER_NAME:
			return {
				...state,
				exportPayload: {
					...state.exportPayload,
					lead_writer_name: action.name,
				},
			};
		case SET_PREVIEW_EXPORT_DATA:
			return {
				...state,
				previewExportData: action.data,
			};
		case CLEAR_EXPORT:
			console.log('CLEAR_EXPORT');
			return {
				...initialState,
				exportsById: { ...state.exportsById },
			};
		case CONFIRM_EXPORT:
			return {
				...state,
				exportPayload: {
					...state.exportPayload,
					is_final_version: true,
				},
			};
		case SET_EXPORT_TYPE:
			return {
				...state,
				exportType: action.exportType,
			};
		case SET_UNION_FORM:
			return {
				...state,
				exportPayload: {
					...state.exportPayload,
					union_form: action.form,
				},
			};
		case SET_SAG_FORM:
			return {
				...state,
				exportPayload: {
					...state.exportPayload,
					sag_form: action.form,
				},
			};
		case SET_UNION_EXPORT_STUDIO_FORM:
			return {
				...state,
				unionForm: {
					...state.unionForm,
					studioForm: {
						...action.form,
					},
				},
			};
		case SET_UNION_EXPORT_COMPLETE_PARTICIPANTS_FORM:
			return {
				...state,
				unionForm: {
					...state.unionForm,
					completeParticipantsForm: {
						...action.form,
					},
				},
			};
		case INITIALIZE_UNION_EXPORT_COMPLETE_PARTICIPANTS_FORM:
			return {
				...state,
				unionForm: {
					...state.unionForm,
					completeParticipantsForm: {
						...initializeCompleteParticipantsForm(
							state.unionForm.selectedParticipants || [],
							state.exportType as ExportType,
							state.unionForm.completeParticipantsForm || {}
						),
					},
				},
			};
		case SET_UNION_EXPORT_FINALIZE_FORM:
			console.log('SET_UNION_EXPORT_FINALIZE_FORM', action.form);

			return {
				...state,
				unionForm: {
					...state.unionForm,
					finalizeForm: {
						...action.form,
					},
				},
			};
		case SET_UNION_EXPORT_PARTICIPANTS:
			return {
				...state,
				unionForm: {
					...state.unionForm,
					selectedParticipants: [...action.participants],
				},
			};
		case SET_UNION_EXPORT_RECORDING_ROWS:
			console.log('SET_UNION_EXPORT_RECORDING_ROWS', action);
			return {
				...state,
				unionForm: {
					...state.unionForm,
					selectedRecordingRows: [...action.recordingRows],
				},
			};
		case SET_UNION_EXPORT_ID:
			return {
				...state,
				unionForm: {
					...state.unionForm,
					id: action.id,
				},
			};
		case SET_B4_TYPE:
			return {
				...state,
				unionForm: {
					...state.unionForm,
					b4Type: action.b4Type,
				},
			};
		case SET_EXPORT_PARAMS:
			return {
				...state,
				exportParams: action.params,
			};
		case SET_EXPORT_MAIN_ARTISTS:
			return {
				...state,
				exportPayload: {
					...state.exportPayload,
					main_artists: action.mainArtists,
				},
			};
		case SET_EXPORT_RECORDING_ERRORS:
			return {
				...state,
				exportRecordingErrors: action.exportRecordingErrors,
			};
		default:
			return state;
	}
};

export default reducer;
