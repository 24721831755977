/*
 * ALERT TOAST ACTIONS
 */
export const SHOW_ALERT_TOAST = 'SHOW_ALERT_TOAST';
export const HIDE_ALERT_TOAST = 'HIDE_ALERT_TOAST';

export const SHOW_ERROR_ALERT = 'SHOW_ERROR_ALERT';

export const EDIT_PARTICIPANT = 'EDIT_PARTICIPANT';
export const DELETE_PARTICIPANT = 'DELETE_PARTICIPANT';

/*
 * PROFILE ACTIONS
 */
export const ADD_PROFILE = 'ADD_PROFILE';
export const ADD_PROFILES = 'ADD_PROFILES';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const DELETE_PROFILE = 'DELETE_PROFILE';
export const SET_PROFILES_LOADING = 'SET_PROFILES_LOADING';
export const CLEAR_PROFILES = 'CLEAR_PROFILES';
export const START_PROFILE_REQUEST = 'START_PROFILE_REQUEST';
export const PROFILE_REQUEST_SUCCESS = 'PROFILE_REQUEST_SUCCESS';
export const PROFILE_REQUEST_ERROR = 'PROFILE_REQUEST_ERROR';
export const PROFILE_REQUEST_AUTH_ERROR = 'PROFILE_REQUEST_AUTH_ERROR';

/*
 * PROJECT ACTIONS
 */
export const START_CLOUD_PROJECTS_REQUEST = 'START_CLOUD_PROJECTS_REQUEST';
export const CLOUD_PROJECTS_REQUEST_ERROR = 'CLOUD_PROJECTS_REQUEST_ERROR';
export const CLOUD_PROJECTS_REQUEST_AUTH_ERROR =
	'CLOUD_PROJECTS_REQUEST_AUTH_ERROR';
export const CLOUD_PROJECTS_REQUEST_SUCCESS = 'CLOUD_PROJECTS_REQUEST_SUCCESS';

export const CLEAR_SELECTED_PROJECT = 'CLEAR_SELECTED_PROJECT';
export const SET_USER_EDITABLE_PROJECTS = 'SET_USER_EDITABLE_PROJECTS';

/*
 * RECORDING ACTIONS
 */
export const ADD_LOCAL_RECORDING = 'ADD_LOCAL_RECORDING';
export const UPDATE_LOCAL_RECORDING = 'UPDATE_LOCAL_RECORDING';
export const RESET_RECORDING = 'RESET_RECORDING';
export const SET_CURRENT_RECORDING_ID = 'SET_CURRENT_RECORDING_ID';
export const DELETE_LOCAL_RECORDING_BY_ID = 'DELETE_LOCAL_RECORDING_BY_ID';
export const CHANGE_LOCAL_RECORDING_OWNER = 'CHANGE_LOCAL_RECORDING_OWNER';

/*
 * ALBUM ACTIONS
 */
export const ADD_LOCAL_ALBUM = 'ADD_LOCAL_ALBUM';
export const UPDATE_LOCAL_ALBUM = 'UPDATE_LOCAL_ALBUM';
export const ADD_LOCAL_RECORDINGS_TO_ALBUM = 'ADD_LOCAL_RECORDINGS_TO_ALBUM';
export const SET_CURRENT_ALBUM_ID = 'SET_CURRENT_ALBUM_ID';
export const DELETE_LOCAL_ALBUM_BY_ID = 'DELETE_LOCAL_ALBUM_BY_ID';
export const REORDER_LOCAL_ALBUM_RECORDINGS = 'REORDER_LOCAL_ALBUM_RECORDINGS';
export const REMOVE_RECORDING_FROM_LOCAL_ALBUM =
	'REMOVE_RECORDING_FROM_LOCAL_ALBUM';
export const SET_ALBUM_ARCHIVED = 'SET_ALBUM_ARCHIVED';
export const CHANGE_LOCAL_ALBUM_OWNER = 'CHANGE_LOCAL_ALBUM_OWNER';

/*
 * USER PROJECT ACTIONS
 */
export const FETCHING_USER_PROJECTS = 'FETCHING_USER_PROJECTS';
export const SET_USER_PROJECTS = 'SET_USER_PROJECTS';
export const USER_PROJECTS_FETCH_ERROR = 'USER_PROJECTS_FETCH_ERROR';
export const USER_PROJECTS_AUTH_ERROR = 'USER_PROJECTS_AUTH_ERROR';
export const SET_SEARCHED_RECORDINGS = 'SET_SEARCHED_RECORDINGS';
export const SET_SEARCHED_ALBUMS = 'SET_SEARCHED_ALBUMS';
export const CLEAR_SEARCHED_RECORDINGS = 'CLEAR_SEARCHED_RECORDINGS';
export const CLEAR_SEARCHED_ALBUMS = 'CLEAR_SEARCHED_ALBUMS';
export const SET_PROJECTS_Y_SCROLL_POSITION = 'SET_PROJECTS_Y_SCROLL_POSITION';
export const SET_PROJECTS_SEARCH_FILTER = 'SET_PROJECTS_SEARCH_FILTER';
export const ADD_EXPANDED_ALBUM_ID = 'ADD_EXPANDED_ALBUM_ID';
export const REMOVE_EXPANDED_ALBUM_ID = 'REMOVE_EXPANDED_ALBUM_ID';
export const CLEAR_EXPANDED_ALBUM_IDS = 'CLEAR_EXPANDED_ALBUM_IDS';
export const CLEAR_PROJECTS = 'CLEAR_PROJECTS';
export const SET_PROJECT_LIMIT = 'SET_PROJECT_LIMIT';
export const SET_PROJECT_USAGE = 'SET_PROJECT_USAGE';

/*
 * MODAL ACTIONS
 */
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const SET_MODAL_TITLE = 'SET_MODAL_TITLE';
export const REMOVE_MODAL = 'REMOVE_MODAL';
export const HIDE_ALL_MODALS = 'HIDE_ALL_MODALS';
export const HIDE_MODAL_BY_TYPE = 'HIDE_MODAL_BY_TYPE';
export const REMOVE_MODAL_BY_ID = 'REMOVE_MODAL_BY_ID';
export const POP_MODALS_UNTIL_TYPE = 'POP_MODALS_UNTIL_TYPE';

/*
 * PARTICIPANT ACTIONS
 */
export const SET_PARTICIPANTS_ROLE_FILTERS = 'SET_PARTICIPANTS_ROLE_FILTERS';
export const SET_PARTICIPANTS_SEARCH_FILTER = 'SET_PARTICIPANTS_SEARCH_FILTER';
export const CLEAR_PARTICIPANTS = 'CLEAR_PARTICIPANTS';

export const SET_PROFILES_SEARCH_FILTER = 'SET_PROFILES_SEARCH_FILTER';

/*
 * EXPORT ACTIONS
 */
export const START_EXPORT_REQUEST = 'START_EXPORT_REQUEST';
export const EXPORT_REQUEST_ERROR = 'EXPORT_REQUEST_ERROR';
export const EXPORT_REQUEST_AUTH_ERROR = 'EXPORT_REQUEST_AUTH_ERROR';
export const EXPORT_REQUEST_SUCCESS = 'EXPORT_REQUEST_SUCCESS';
export const CLEAR_EXPORT_REQUEST = 'CLEAR_EXPORT_REQUEST';
export const NEXT_UNION_FORM_EXPORT_STEP = 'NEXT_UNION_FORM_EXPORT_STEP';
export const PREVIOUS_UNION_FORM_EXPORT_STEP =
	'PREVIOUS_UNION_FORM_EXPORT_STEP';
export const SET_UNION_FORM_EXPORT_STEP = 'SET_UNION_FORM_EXPORT_STEP';
export const SET_EXPORT_PAYLOAD = 'SET_EXPORT_PAYLOAD';
export const ADD_LOCAL_EXPORTS = 'ADD_LOCAL_EXPORTS';
export const DELETE_LOCAL_EXPORT = 'DELETE_LOCAL_EXPORT';
export const ADD_EXPORTS_TO_LOCAL_RECORDING = 'ADD_EXPORTS_TO_LOCAL_RECORDING';
export const ADD_EXPORTS_TO_LOCAL_ALBUM = 'ADD_EXPORTS_TO_LOCAL_ALBUM';
export const DELETE_EXPORT_FROM_LOCAL_RECORDING =
	'DELETE_EXPORT_FROM_LOCAL_RECORDING';
export const DELETE_EXPORT_FROM_LOCAL_ALBUM = 'DELETE_EXPORT_FROM_LOCAL_ALBUM';
export const ADD_EXPORT_VALIDATION_ERRORS = 'ADD_EXPORT_VALIDATION_ERRORS';
export const CLEAR_EXPORT_VALIDATION_ERRORS = 'CLEAR_EXPORT_VALIDATION_ERRORS';
export const SET_EXPORT_LEAD_WRITER_NAME = 'SET_EXPORT_LEAD_WRITER_NAME';
export const SET_PREVIEW_EXPORT_DATA = 'SET_PREVIEW_EXPORT_DATA';
export const CLEAR_EXPORT = 'CLEAR_EXPORT';
export const CONFIRM_EXPORT = 'CONFIRM_EXPORT';
export const SET_EXPORT_TYPE = 'SET_EXPORT_TYPE';
export const SET_UNION_EXPORT_STUDIO_FORM = 'SET_UNION_EXPORT_STUDIO_FORM';
export const SET_UNION_EXPORT_COMPLETE_PARTICIPANTS_FORM =
	'SET_UNION_EXPORT_COMPLETE_PARTICIPANTS_FORM';
export const INITIALIZE_UNION_EXPORT_COMPLETE_PARTICIPANTS_FORM =
	'INITIALIZE_UNION_EXPORT_COMPLETE_PARTICIPANTS_FORM';
export const SET_UNION_EXPORT_FINALIZE_FORM = 'SET_UNION_EXPORT_FINALIZE_FORM';
export const SET_UNION_EXPORT_PARTICIPANTS = 'SET_UNION_EXPORT_PARTICIPANTS';
export const SET_UNION_EXPORT_RECORDING_ROWS =
	'SET_UNION_EXPORT_RECORDING_ROWS';
export const SET_UNION_FORM = 'SET_UNION_FORM';
export const SET_UNION_EXPORT_ID = 'SET_UNION_EXPORT_ID';
export const SET_SAG_FORM = 'SET_SAG_FORM';
export const EXPORT_TO_RIN = 'EXPORT_TO_RIN';
export const IMPORT_RIN = 'IMPORT_RIN';
export const SET_B4_TYPE = 'SET_B4_TYPE';
export const INCREMENT_LOCAL_RECORDING_ASSETS_COUNT =
	'INCREMENT_LOCAL_RECORDING_ASSETS_COUNT';
export const DECREMENT_LOCAL_RECORDING_ASSETS_COUNT =
	'DECREMENT_LOCAL_RECORDING_ASSETS_COUNT';
export const INCREMENT_LOCAL_ALBUM_ASSETS_COUNT =
	'INCREMENT_LOCAL_ALBUM_ASSETS_COUNT';
export const DECREMENT_LOCAL_ALBUM_ASSETS_COUNT =
	'DECREMENT_LOCAL_ALBUM_ASSETS_COUNT';
export const SET_EXPORTS_TO_LOCAL_ALBUM = 'SET_EXPORTS_TO_LOCAL_ALBUM';
export const SET_EXPORTS_TO_LOCAL_RECORDING = 'SET_EXPORTS_TO_LOCAL_RECORDING';
export const SET_LOCAL_RECORDING_ASSETS_COUNT =
	'SET_LOCAL_RECORDING_ASSETS_COUNT';
export const SET_LOCAL_ALBUM_ASSETS_COUNT = 'SET_LOCAL_ALBUM_ASSETS_COUNT';
export const SET_EXPORT_PARAMS = 'SET_EXPORT_PARAMS';
export const SET_EXPORT_MAIN_ARTISTS = 'SET_EXPORT_MAIN_ARTISTS';
export const SET_EXPORT_RECORDING_ERRORS = 'SET_EXPORT_RECORDING_ERRORS';
/*
 * AUTH ACTIONS
 */
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_INVALID = 'LOGIN_INVALID';

export const MAGIC_LINK_INVALID = 'MAGIC_LINK_INVALID';
export const MAGIC_LINK_VALID = 'MAGIC_LINK_VALID';

export const LOGOUT = 'LOGOUT';

export const VALIDATING_TOKEN = 'VALIDATING_TOKEN';
export const VALID_TOKEN = 'VALID_TOKEN';
export const INVALID_TOKEN = 'INVALID_TOKEN';
export const TOKEN_VALIDATION_ERROR = 'TOKEN_VALIDATION_ERROR';
export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS';
export const START_SIGNUP_PROCESS = 'START_SIGNUP_PROCESS';
export const FINISH_SIGNUP_PROCESS = 'FINISH_SIGNUP_PROCESS';
export const SET_SIGNUP_FORM_STEP = 'SET_SIGNUP_FORM_STEP';
export const SET_EMAIL_VERIFIED = 'SET_EMAIL_VERIFIED';

/*
 * USER ACTIONS
 */
export const START_USER_REQUEST = 'START_USER_REQUEST';
export const USER_REQUEST_ERROR = 'USER_REQUEST_ERROR';
export const USER_REQUEST_AUTH_ERROR = 'USER_REQUEST_AUTH_ERROR';
export const USER_REQUEST_SUCCESS = 'USER_REQUEST_SUCCESS';
export const STORE_USER_PROFILE = 'USER_PROFILE';
export const STORE_NOTIFICATIONS = 'NOTIFICATIONS';

export const SAVE_EDITOR_PROFILE = 'SAVE_EDITOR_PROFILE';
export const CLEAR_USER_DATA = 'CLEAR_USER_DATA';
export const DELETE_LOCAL_NOTIFICATIONS = 'DELETE_LOCAL_NOTIFICATIONS';
export const READ_LOCAL_NOTIFICATION = 'READ_LOCAL_NOTIFICATION';
export const SET_EDITOR_PROFILE_ACTIVE = 'SET_EDITOR_PROFILE_ACTIVE';
export const SET_ONBOARDING_CLICKED_SEND_PLAYLIST_LINK =
	'SET_ONBOARDING_CLICKED_SEND_PLAYLIST_LINK';

/*
 * SESSION ACTIONS
 */
export const SAVE_EXPORT_FORM = 'SAVE_EXPORT_FORM';
export const DELETE_EXPORT_FORM = 'DELETE_EXPORT_FORM';
export const CLEAR_SESSION_DATA = 'CLEAR_SESSION_DATA';
export const SET_ACTIVE_EXPORT_FORM = 'SET_ACTIVE_EXPORT_FORM';
export const CLEAR_ACTIVE_EXPORT_FORM = 'CLEAR_ACTIVE_EXPORT_FORM';
export const SAVE_SELECTED_COUNTRY = 'SAVE_SELECTED_COUNTRY';
export const SET_VOLUME = 'SET_VOLUME';
export const SET_MUTE = 'SET_MUTE';
export const SET_VISIBLE_PARTICIPANT_TABLE_COLUMNS =
	'SET_VISIBLE_PARTICIPANT_TABLE_COLUMNS';
export const SET_DETECT_TITLE_AND_ARTIST = 'SET_DETECT_TITLE_AND_ARTIST';

/*
 * FILE ACTIONS
 */
export const START_FILE_REQUEST = 'START_FILE_REQUEST';
export const FILE_REQUEST_ERROR = 'FILE_REQUEST_ERROR';
export const FILE_REQUEST_AUTH_ERROR = 'FILE_REQUEST_AUTH_ERROR';
export const FILE_REQUEST_SUCCESS = 'FILE_REQUEST_SUCCESS';
export const ADD_LOCAL_FILES = 'ADD_LOCAL_FILES';
export const ADD_FETCHED_FILES = 'ADD_FETCHED_FILES';
export const DELETE_LOCAL_FILES = 'DELETE_LOCAL_FILES';
export const UPDATE_LOCAL_FILE = 'UPDATE_LOCAL_FILE';
export const ADD_TO_UPLOAD_QUEUE = 'ADD_TO_UPLOAD_QUEUE';
export const ADD_UPLOAD_FILES_FOLDERS = 'ADD_UPLOAD_FILES_FOLDERS';
export const START_FILE_UPLOAD = 'START_FILE_UPLOAD';
export const DELETE_UPLOADS_IN_PROGRESS = 'DELETE_UPLOADS_IN_PROGRESS';
export const SET_FILE_UPLOAD_PROGRESS = 'SET_FILE_UPLOAD_PROGRESS';
export const FILE_UPLOAD_SUCCESS = 'FILE_UPLOAD_SUCCESS';
export const FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR';
export const SET_FILE_UPLOAD_ABORT_CONTROLLER =
	'SET_FILE_UPLOAD_ABORT_CONTROLLER';
export const CANCEL_FILE_UPLOAD = 'CANCEL_FILE_UPLOAD';
export const RETRY_FILE_UPLOAD = 'RETRY_FILE_UPLOAD';
export const SET_S3_FILE_UPLOAD_CREDENTIALS = 'SET_S3_FILE_UPLOAD_CREDENTIALS';
export const CLEAR_S3_FILE_UPLOAD_CREDENTIALS =
	'CLEAR_S3_FILE_UPLOAD_CREDENTIALS';
export const CLEAR_UPLOAD_FROM_QUEUE = 'CLEAR_UPLOAD_FROM_QUEUE';
export const CLEAR_FILES_STATE = 'CLEAR_FILES_STATE';
export const SET_IMAGE_FILE_THUMBNAIL = 'SET_IMAGE_FILE_THUMBNAIL';
export const SET_STORAGE_LIMIT = 'SET_STORAGE_LIMIT';
export const SET_STORAGE_USAGE = 'SET_STORAGE_USAGE';
export const SET_FILE_LABEL_DETAILS = 'SET_FILE_LABEL_DETAILS';
export const ADD_TO_FOLDER_DOWNLOAD_QUEUE = 'ADD_TO_FOLDER_DOWNLOAD_QUEUE';
export const DELETE_FOLDER_DOWNLOAD_QUEUE = 'DELETE_FOLDER_DOWNLOAD_QUEUE';
export const ADD_TO_DOWNLOAD_QUEUE = 'ADD_TO_DOWNLOAD_QUEUE';
export const ADD_FOLDER_TO_DOWNLOAD_QUEUE = 'ADD_FOLDER_TO_DOWNLOAD_QUEUE';
export const START_FILE_DOWNLOAD = 'START_FILE_DOWNLOAD';
export const DELETE_DOWNLOADS_IN_PROGRESS = 'DELETE_DOWNLOADS_IN_PROGRESS';
export const SET_FILE_DOWNLOAD_PROGRESS = 'SET_FILE_DOWNLOAD_PROGRESS';
export const FILE_DOWNLOAD_SUCCESS = 'FILE_DOWNLOAD_SUCCESS';
export const FILE_DOWNLOAD_ERROR = 'FILE_DOWNLOAD_ERROR';
export const SET_FILE_DOWNLOAD_ABORT_CONTROLLER =
	'SET_FILE_DOWNLOAD_ABORT_CONTROLLER';
export const CANCEL_FILE_DOWNLOAD = 'CANCEL_FILE_DOWNLOAD';
export const RETRY_FILE_DOWNLOAD = 'RETRY_FILE_DOWNLOAD';
export const CLEAR_DOWNLOAD_FROM_QUEUE = 'CLEAR_DOWNLOAD_FROM_QUEUE';

/*
 * PLAYLIST ACTIONS
 */

export const START_PLAYLIST_REQUEST = 'START_PLAYLIST_REQUEST';
export const PLAYLIST_REQUEST_ERROR = 'PLAYLIST_REQUEST_ERROR';
export const PLAYLIST_REQUEST_AUTH_ERROR = 'PLAYLIST_REQUEST_AUTH_ERROR';
export const PLAYLIST_REQUEST_SUCCESS = 'PLAYLIST_REQUEST_SUCCESS';
export const SET_CURRENT_PLAYLIST_ID = 'SET_CURRENT_PLAYLIST_ID';
export const SET_USER_PLAYLISTS = 'SET_USER_PLAYLISTS';
export const SET_USER_PLAYLIST_STATS = 'SET_USER_PLAYLIST_STATS';
export const SET_FULL_PLAYLISTS = 'SET_FULL_PLAYLISTS';
export const SET_FULL_PLAYLIST_STATS = 'SET_FULL_PLAYLIST_STATS';
export const SET_FULL_DRIP_STATS = 'SET_FULL_DRIP_STATS';
export const ADD_UPLOADS_TO_PLAYLIST = 'ADD_UPLOADS_TO_PLAYLIST';
export const DELETE_LOCAL_PLAYLIST_FILE = 'DELETE_LOCAL_PLAYLIST_FILE';
export const DELETE_LOCAL_PLAYLIST = 'DELETE_LOCAL_PLAYLIST';
export const DELETE_UPLOADS_FROM_PLAYLIST = 'DELETE_UPLOADS_FROM_PLAYLIST';
export const SET_USER_DRIP_AGREEMENT = 'SET_USER_DRIP_AGREEMENT';
export const SET_PLAYLIST_CREDIT_REQUEST_COUNT =
	'SET_PLAYLIST_CREDIT_REQUEST_COUNT';

export const SET_SHOW_FRESH_INSTALL_INFO = 'SET_SHOW_FRESH_INSTALL_INFO';
export const SET_CURRENT_PLAYLIST_EDITORS = 'SET_CURRENT_PLAYLIST_EDITORS';
export const CLEAR_PLAYLISTS = 'CLEAR_PLAYLISTS';

/* PROMO ACTIONS */
export const SET_PROMO_ACTIVE = 'SET_PROMO_ACTIVE';
export const SET_PROMO_SEEN = 'SET_PROMO_SEEN';
export const START_PLAYLIST_JULY_2023_PROMO_TIMER =
	'START_PLAYLIST_JULY_2023_PROMO_TIMER';
export const CLEAR_PROMOS = 'CLEAR_PROMOS';
export const EXTEND_PLAYLIST_JULY_2023_PROMO =
	'EXTEND_PLAYLIST_JULY_2023_PROMO';

/* PLAYER ACTIONS */
export const SET_PLAYER_PLAYING = 'SET_PLAYER_PLAYING';
export const SET_PLAYER_CURRENT_TIME = 'SET_PLAYER_CURRENT_TIME';
export const SET_PLAYER_DURATION = 'SET_PLAYER_DURATION';
export const SET_PLAYER_LOOP_MODE = 'SET_PLAYER_LOOP_MODE';
export const TOGGLE_PLAYER_SHUFFLE = 'TOGGLE_PLAYER_SHUFFLE';
export const SET_PLAYER_PLAYLIST = 'SET_PLAYER_PLAYLIST';
export const SET_PLAYER_CURRENT_TRACK_INDEX = 'SET_PLAYER_CURRENT_TRACK_INDEX';
export const SET_PLAYER_TRACKLIST = 'SET_PLAYER_TRACKLIST';
export const SET_SHOW_PLAYER = 'SET_SHOW_PLAYER';
export const TOGGLE_LOSSLESS = 'TOGGLE_LOSSLESS';
export const SET_PLAYER_TRACK_VERSION_NUMBER =
	'SET_PLAYER_TRACK_VERSION_NUMBER';
export const SET_PLAYER_AUDIO_LOADED = 'SET_PLAYER_AUDIO_LOADED';
export const SET_PLAYER_AUDIO_SRC = 'SET_PLAYER_AUDIO_SRC';
export const SET_SEEK_TIME_FUNCTION = 'SET_SEEK_TIME_FUNCTION';
export const SET_PLAYER_GROUP_INDEXES = 'SET_PLAYER_GROUP_INDEXES';
export const RESET_PLAYER = 'RESET_PLAYER';

export const SET_HELP_ARTICLES = 'SET_HELP_ARTICLES';
